import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import App from './App';
import reportWebVitals from './reportWebVitals';

import './assets/css/material-dashboard-pro-react.css';
import { store } from './redux/store';
import { refreshAccessTokenApi } from './features/auth/services/serverApi.service';
import { setUserDataAction } from './features/auth/redux/actions/auth.action';
import Swal from 'sweetalert2';

import './assets/scss/material-dashboard-pro-react.scss';

import './assets/css/global.css';

/*
axios interceptors
 */
const PopUpContent = () => {
  return (
    <div>
      I will logout in <strong></strong> seconds.
      <br />
      <br />'
      <button id="refresh" class="btn btn-warning">
        ' + 'Refresh session' + '
      </button>
      <br />
      <br />
    </div>
  );
};
const showAlert = () => {
  let timerInterval;
  let clickForRefrsh = false;
  const logoutFunction = () => {
    localStorage.clear();
    window.location.reload();
  };
  Swal.fire({
    html:
      '<div>' +
      '<div style=color:white;background-color:#4caf50;padding:5px;border-radius:8px;margin-bottom:22px>your session will expire in</div>' +
      '<strong style=font-weight:bolder;background-color:#3480ff;margin-top:20px;padding:15px;color:white;font-size:35px;margin-bottom:15px;border-radius:8px>30</strong><br/><br/>' +
      '</div>' +
      '<div style=margin-bottom:-9%;width:100%;display:inline-block>' +
      '<button id="close" class="btn btn-danger" style=width:50%;color:white>' +
      'close session' +
      '</button>' +
      '<button id="refresh" class="btn btn-warning" style=width:50%;color:white>' +
      'Refresh session' +
      '</button>' +
      '</div><br/><br/>',
    timer: 30000,
    showCancelButton: false,
    showConfirmButton: false,
    didOpen: () => {
      const content = Swal.getHtmlContainer();
      const $ = content.querySelector.bind(content);

      const refresh = $('#refresh');
      const close = $('#close');

      refresh.addEventListener('click', async () => {
        clickForRefrsh = true;
        const reduxState = store.getState();

        reduxState?.authReducer?.userData?.refreshToken &&
          (await refreshAccessTokenApi(
            reduxState?.authReducer?.userData?.refreshToken,
          )
            .then(async (res) => {
              await store.dispatch(setUserDataAction(res?.data));
              window.location.reload();
              clearInterval(timerInterval);
              Swal.close();
            })
            .catch((error) => {
              clearInterval(timerInterval);
              logoutFunction();
            }));
      });
      close.addEventListener('click', () => Swal.close());

      timerInterval = setInterval(() => {
        if (!clickForRefrsh) {
          Swal.getHtmlContainer().querySelector('strong').textContent = (
            Swal.getTimerLeft() / 1000
          ).toFixed(0);
        }
      }, 1000);
    },
    willClose: () => {
      !clickForRefrsh && logoutFunction();
      clearInterval(timerInterval);
    },
  });
};
axios.interceptors.request.use((config) => {
  const reduxState = store.getState();
  // config.headers['Access-Control-Allow-Origin'] = '*';
  // config.headers['Access-Control-Allow-Methods'] = 'GET, POST, PUT, DELETE';
  // config.headers['Access-Control-Allow-Headers'] = 'Content-Type';
  // config.headers['Access-Control-Allow-Credentials'] = 'true';
  if (reduxState.authReducer?.userData) {
    if (!config.headers.Authorization) {
      config.headers.Authorization = `Bearer ${reduxState.authReducer.userData.accessToken}`;
    }
  }
  config.headers.isfromkaalix = true;

  return config;
});

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    console.log('error', error);
    if (error?.response?.status === 403) {
      showAlert();
    }

    return Promise.reject(error);
  },
);
/*
end axios interceptors code
 */

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
