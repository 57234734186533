import React from 'react'
import PropTypes from 'prop-types'
import InputLabel from '@material-ui/core/InputLabel';
import { Box } from '@material-ui/core';
import CustomInput from '../../../components/material-dashboard-pro-react/CustomInput/CustomInput';


export default function ViewPublicityComponent({ item }) {
    
    return (
        <div>
            {item &&
                <>
                    <CustomInput
                        labelText="Name"
                        formControlProps={{
                            fullWidth: true,
                        }}
                        type="text"
                        inputProps={{
                            value: item.name,
                        }}
                    />
                    <CustomInput
                        labelText="Name"
                        formControlProps={{
                            fullWidth: true,
                        }}
                        type="text"
                        inputProps={{
                            value: item.name,
                        }}
                    />
                    <CustomInput
                        labelText="Description"
                        formControlProps={{
                            fullWidth: true,
                        }}
                        type="text"
                        inputProps={{
                            value: item.description,
                        }}
                    />
                    <CustomInput
                        labelText="city"
                        formControlProps={{
                            fullWidth: true,
                        }}
                        type="text"
                        inputProps={{
                            value: item.cityCol,
                        }}
                    />
                    <CustomInput
                        labelText="externalLink"
                        formControlProps={{
                            fullWidth: true,
                        }}
                        type="text"
                        inputProps={{
                            value: item.externalLink,
                        }}
                    />
                    <CustomInput
                        labelText="assignedTo"
                        formControlProps={{
                            fullWidth: true,
                        }}
                        type="text"
                        inputProps={{
                            value: item.assignedTo,
                        }}
                    />
                    <CustomInput
                        labelText="format"
                        formControlProps={{
                            fullWidth: true,
                        }}
                        type="text"
                        inputProps={{
                            value: item.format,
                        }}
                    />
                    <CustomInput
                        labelText="createdAt"
                        formControlProps={{
                            fullWidth: true,
                        }}
                        type="text"
                        inputProps={{
                            value: item.createdAt,
                        }}
                    />
                    <CustomInput
                        labelText="deletedAt"
                        formControlProps={{
                            fullWidth: true,
                        }}
                        type="text"
                        inputProps={{
                            value: item.deletedAt,
                        }}
                    />
                    publicitySubGroups :
                    {item.publicitySubGroups.length > 0 && item.publicitySubGroups.map((element, index) => (
                        <div key={index} style={{ border: '1.3px solid #000', borderRadius: "10px", padding: "15px", marginTop: '15px' }}>
                            <CustomInput
                                labelText="Pub Group Name"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                type="text"
                                inputProps={{
                                    value: element.name,
                                }}
                            />
                            <CustomInput
                                labelText="Pub Group Description"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                type="text"
                                inputProps={{
                                    value: element.description,
                                }}
                            />
                            <CustomInput
                                labelText="Pub Group externalLink"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                type="text"
                                inputProps={{
                                    value: element.position,
                                }}
                            />
                            <div style={{ display: 'flex', overflowX: 'auto' }}>
                                {element.pubs.length > 0 && element.pubs.map((pub, i) => (

                                    <div style={{ flex: '0 0 30%', border: "1px solid #000", borderRadius: "10px", padding: " 0 15px", margin: '10px 5px', }}>
                                        <InputLabel style={{ marginTop: '10px' }}>Pub :{i + 1} </InputLabel>

                                        <Box display="flex" justifyContent="center">
                                            <img src={pub.imageUrl} alt="" style={{ height: '50px', width: '50px' }} />

                                        </Box>

                                        <CustomInput
                                            labelText="Pub Group name"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            type="text"
                                            inputProps={{
                                                value: pub.name,
                                            }}
                                        />
                                        <CustomInput
                                            labelText="Pub Group description"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            type="text"
                                            inputProps={{
                                                value: pub.description,
                                            }}
                                        />
                                        <CustomInput
                                            labelText="Pub Group externalLink"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            type="text"
                                            inputProps={{
                                                value: pub.externalLink ? pub.externalLink : '--',
                                            }}
                                        />
                                        <CustomInput
                                            labelText="Pub Group type"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            type="text"
                                            inputProps={{
                                                value: pub.type,
                                            }}
                                        />
                                        <CustomInput
                                            labelText="Pub Group store"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            type="text"
                                            inputProps={{
                                                value: pub.storeId,
                                            }}
                                        />
                                    </div>
                                ))}
                            </div>

                        </div>
                    ))
                    }

                </>
            }
        </div>
    )
}

ViewPublicityComponent.propTypes = {
    item: PropTypes.any
}
