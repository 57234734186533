import React, { useState, useEffect } from 'react';
import { getCities } from '../../delivery-info/services/serverApi.service';
import {
  getCanCancelConfig,
  editCanCancelConfig,
} from '../services/serverApi.service';
import { Grid } from '@material-ui/core';
import SelectBoxComponent from '../../../components/SelectBox.component';
import { canCancelConfigTypes } from '../mocks';

export default function CanCancelComponenet() {
  const [cities, setCities] = useState([]);
  const [config, setConfig] = useState([]);
  const loadCitiesList = () => {
    getCities(0, 0, false)
      .then(({ data }) => setCities(data))
      .catch((error) => console.log(error));
  };
  const loadCanCancelConfig = () => {
    getCanCancelConfig()
      .then(({ data }) => {
        setConfig(data?.cancelOrderConfig?.config);
        loadCitiesList();
      })
      .catch((error) => console.log(error));
  };
  const changeSelect = (cityId, value) => {
    const tempConfig = config;
    const findIndex = config.findIndex((x) => x.cityId == cityId);
    if (findIndex == -1) {
      console.log('123');
      tempConfig.push({
        cityId: cityId,
        cancelConfig: value,
      });
    } else {
      tempConfig[findIndex].cancelConfig = value;
    }
    editCanCancelConfig(tempConfig)
      .then(({ data }) => setConfig(data?.cancelOrderConfig?.config))
      .catch((error) => console.log(error));
  };
  const findValue = (cityId) => {
    // console.log(cityId, value);
    // console.log(config.find((x) => x.cityId?.toString() == cityId));
    return config.find((x) => x.cityId?.toString() == cityId)?.cancelConfig;
  };
  useEffect(() => {
    loadCanCancelConfig();
  }, []);
  return (
    <Grid container spacing={2}>
      {(cities || []).map((city) => (
        <Grid item={12} container key={city._id}>
          <Grid item xs={4} style={{ paddingTop: 40 }}>
            {city.name}
          </Grid>
          <Grid item xs={6}>
            <SelectBoxComponent
              items={canCancelConfigTypes.map((item) => ({
                value: item,
                label: item,
              }))}
              inputLabel="Type"
              onValueChange={(item) => changeSelect(city._id, item)}
              value={findValue(city._id)}
            />
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}
