import React, { useEffect, useState } from 'react';
import TablePagination from '@material-ui/core/TablePagination';
import { getCountries } from '../services/serverApi.service';
import DataTableContainerComponent from '../../../components/DataTableContainer.component';
// import DataTableContainerComponent from '../../../components/DataTableSortingContainer';
import EditCountryComponent from '../components/EditCountry.component';
import CreateCountryComponent from '../components/CreateCountry.component';
import ViewItemComponent from '../components/ViewItem.component';


export default function CountriesPage() {
  const [resultsData, setResultsData] = useState({
    total: 0,
    items: [],
    tableItems: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  console.log(isLoading);
  // const classes = useStyles();
  const pages = [ 25,50,100];
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(pages[page])
  // console.log(page+'page ',rowsPerPage);

  const loadCountriesList = () => {
    setIsLoading(true);
    getCountries(page,rowsPerPage , true)
      .then(({ data }) => {
        console.log(data);
        setResultsData({
          total: data.total,
          items: data.countries.map((item) => {
            return {
              ...item,
              _id: item._id,
              name: item.name,
              currency: item.currency
            };
          }),
          tableItems: data.countries.map((item) => [
            // eslint-disable-next-line no-underscore-dangle
            item._id,
            item.name,
            item.currency,
          ]),
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  }
  const handleChangeRowsPerPage = event => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value,10))
  }
  useEffect(() => {
    loadCountriesList();
  }, [page,rowsPerPage]);
  // console.log(page,rowsPerPage);
  console.log(resultsData.items);
  return (
    <>
      {resultsData &&
        <>
        <TablePagination
            component="div"
            page={page}
            rowsPerPageOptions={pages}
            rowsPerPage={rowsPerPage}
            count={resultsData.total}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <DataTableContainerComponent
            resultsData={resultsData}
            tableHead={['Name', 'Currency']}
            title="Countries"
            isLoading={isLoading}
            loadItemsList={loadCountriesList}
            CreateItemFormComponent={CreateCountryComponent}
            actionButtons={{
              view: {
                color: 'info',
                type: 'view',
                component: ViewItemComponent,
              },
              edit: {
                color: 'success',
                type: 'edit',
                component: EditCountryComponent,
              },
              delete: { color: 'danger', type: 'delete' },
            }}
          />

        </>
      }
    </>
  );
}
