import React, { useEffect, useState, useRef } from 'react';
import { getKaalixUpHistory } from '../services/serverApi.customer';
import DataTableContainerComponent from '../../../components/DataTableSortingContainer.component';
import CustomButton from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import { Box, Grid } from '@material-ui/core';
import { getCustmore } from '../services/serverApi.customer';
import AsyncSelect from 'react-select/async';
import SelectBoxComponent from '../../../components/SelectBox.component';
import { changesMock, kaalixUpHistoryTypes } from '../mock';
import moment from 'moment';

export default function kaalixUpHistoryPage() {
  const inpRef = useRef();
  const [inputValue, setInputValue] = useState('');
  const [customerId, setCustomerId] = useState('');
  const [type, setType] = useState('');
  const [searchText, setSearchText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState('');
  const [kaalixPayHistory, setKaalixPayHistory] = useState([]);
  const [page, setPage] = useState(0);
  let numberOfRows = [100, 500, 1000];
  const [rowsPerPage, setRowsPerPage] = useState(numberOfRows[0]);
  const [resultsData, setResultsData] = useState({
    total: 0,
    item: [],
  });

  const onSearchChange = (selectedOption, whoChange) => {
    page > 0 && setPage(0);
    switch (whoChange) {
      case changesMock.changeId:
        if (selectedOption) {
          setCustomerId(selectedOption._id);
          loadKaalixPayHistoryList(
            page > 0 ? 0 : page,
            rowsPerPage,
            selectedOption._id,
            type,
          );
        }
        break;
      case changesMock.changeType:
        if (selectedOption) {
          setType(
            selectedOption == kaalixUpHistoryTypes[0] ? '' : selectedOption,
          );
          loadKaalixPayHistoryList(
            page > 0 ? 0 : page,
            rowsPerPage,
            customerId,
            selectedOption == kaalixUpHistoryTypes[0] ? '' : selectedOption,
          );
        }
        break;

      default:
        break;
    }
  };
  const fetchData = (inputValue, callback) => {
    setTimeout(() => {
      // inputValue &&
      getCustmore(0, 10, true, inputValue)
        .then(({ data }) => {
          // console.log(data);
          const tempArray = [];
          if (data.customers) {
            if (data.customers.length) {
              data.customers.forEach((element) => {
                tempArray.push({
                  label: `${element.name}`,
                  _id: element._id,
                });
              });
            }

            // else {
            //   tempArray.push({
            //     label: `${data.name}`,
            //     _id: data._id,
            //   });
            // }
          }
          callback(tempArray);
        })
        .catch((error) => {
          console.log(error);
        });
    }, 1000);
  };
  const SearchComponent = () => {
    return (
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={5}>
          <AsyncSelect
            single
            value={inputValue}
            loadOptions={fetchData}
            placeholder="find customer"
            onChange={(e) => {
              onSearchChange(e, changesMock.changeId);
            }}
          />
        </Grid>
        <Grid item xs={5} className="pb-3">
          <SelectBoxComponent
            items={kaalixUpHistoryTypes.map((type) => ({
              value: type,
              label: type,
            }))}
            inputLabel="Type"
            onValueChange={(item) =>
              onSearchChange(item, changesMock.changeType)
            }
            value={type}
          />
        </Grid>
        <Grid item xs={2} container justify="flex-end">
          <Box textAlign="left">
            <CustomButton
              round
              type="submit"
              color="google"
              size="sm"
              loaderColor="white"
              onClick={() => {
                setType('');
                setCustomerId('');
                page > 0 && setPage(0);
                loadKaalixPayHistoryList(
                  page > 0 ? 0 : page,
                  rowsPerPage,
                  '',
                  '',
                );
              }}
            >
              clear
            </CustomButton>
          </Box>
        </Grid>
      </Grid>
    );
  };
  const prepareShowDataInTable = (item, index) => {
    return {
      ...item,
      createdCell:
        item.createdAt && moment(item.createdAt).format('DD/MM hh:mm'),
      typeCell: item.type || '--',
      storeNameCell: item.storeName || '--',
    };
  };
  const loadKaalixPayHistoryList = (skip, limit, id, type) => {
    setIsLoading(true);
    getKaalixUpHistory(skip, limit, id, type)
      .then(({ data }) => {
        setTotal(data.total);
        setKaalixPayHistory(data.RewardPointsHistory);
      })
      .catch((error) =>
        setResultsData({
          total: 0,
          items: [],
          tableItems: [],
        }),
      )
      .finally(() => {
        setIsLoading(false);
      });
  };
  const handlePaginate = (val) => {
    setResultsData({
      items: [],
      tableItems: [],
    });
    loadKaalixPayHistoryList(
      val.pageSelect,
      val.numberOfRows,
      customerId,
      type,
    );
    setPage(val.pageSelect);
    setRowsPerPage(val.numberOfRows);
  };
  useEffect(() => {
    kaalixPayHistory &&
      kaalixPayHistory.length > 0 &&
      setResultsData({
        items: kaalixPayHistory.map((item, index) => {
          return prepareShowDataInTable(item, index);
        }),
      });
  }, [kaalixPayHistory]);
  return (
    <div>
      <DataTableContainerComponent
        total={total}
        page={page + 1}
        resultsData={resultsData}
        initData={resultsData.items || []}
        columns={[
          {
            Header: 'Type',
            accessor: 'typeCell',
          },
          {
            Header: 'Store',
            accessor: 'storeNameCell',
          },
          {
            Header: 'Customer',
            accessor: 'customer_detail.name',
          },
          {
            Header: 'Amount',
            accessor: 'amount',
          },
          {
            Header: 'Comment',
            accessor: 'comment',
          },
          {
            Header: 'Created At',
            accessor: 'createdCell',
          },
        ]}
        title="kaalixUP History List"
        isLoading={isLoading}
        loadItemsList={loadKaalixPayHistoryList}
        onPaginate={handlePaginate}
        isPaginate={true}
        searchInp={true}
        SearchComponent={SearchComponent}
      />
    </div>
  );
}
