import React, { useState, useEffect, useRef } from 'react';
import { useAlert } from 'react-alert';
import moment from 'moment';
import { Box, Grid } from '@material-ui/core';
import {
  getUnapprouvedProvider,
  logProvider,
  setProvider,
} from '../service/serverAPI.service';
import DataTableContainerComponent from '../../../components/DataTableSortingContainer.component';
import SwitchComponent from '../../../components/Switch.component';
import { formatTime } from '../../../helpers/index';
import CustomButton from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import SelectBoxComponent from '../../../components/SelectBox.component';
import { getCities } from '../../delivery-info/services/serverApi.service';
import GridContainer from '../../../components/material-dashboard-pro-react/Grid/GridContainer';
import Card from '../../../components/material-dashboard-pro-react/Card/Card';
import CardBody from '../../../components/material-dashboard-pro-react/Card/CardBody';
import { useSelector } from 'react-redux';
import { getUserDataState } from 'features/auth/redux/selectors';
import { DATA } from '../../../utils/logAudit';
import { resetObj } from 'utils/resetObj';

export default function UnapprouvedProvider() {
  const [resultsData, setResultsData] = useState({
    total: 0,
    items: [],
    tableItems: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const inpRef = useRef();
  const [providers, setProviders] = useState([]);
  const [page, setPage] = useState(0);
  let numberOfRows = [100, 500, 1000];
  const [rowsPerPage, setRowsPerPage] = useState(numberOfRows[0]);
  const [searchText, setSearchText] = useState('');
  const [total, setTotal] = useState('');
  const [cities, setCities] = useState([]);
  const alert = useAlert();
  const [cityId, setCityId] = useState('');
  // admin data :
  const adminData = useSelector(getUserDataState);
  const [admin, setAdmin] = useState({
    _id: adminData.user._id,
    email: adminData.user.email,
    username: adminData.user.username
  });
  // handle de-lock a delivery man action :
  const deliveryManDelock = (value, item) => {
    // reset object before setting data :
    resetObj(DATA);
    // for logger audit data :
    DATA.admin = admin;
    DATA.actionType = 'Bloquer/Debloquer Livreur';
    DATA.targetId = item._id;
    DATA.target = 'Delivery-man';
    DATA.loggerDetails = {
      beforeValue: 'Bloquer',
      afterValue: 'Debloquer'
    }
    DATA.beforeValue = 'Bloquer';
    DATA.afterValue = 'Debloquer';
    // console.log(item, afterValue, admin);
    // console.log(data);
    // our post here :
    logProvider(DATA)
      .then(({ data }) => {
        console.log(data);
      })
      .catch((error) => {
        console.log(error.message, {
          type: 'error',
        });
      })
  }

  const getCity = () => {
    getCities()
      .then(({ data }) => {
        setCities(data);
      })
      .catch((error) => console.log(error));
  };
  const updateProvider = (id) => {
    setProvider(id, { approved: true }, 'approve')
      .then(() => {
        alert.show('provider approuved', {
          type: 'success',
        });
        loadUnapprouvedProviderList(page, rowsPerPage, searchText);
      })
      .catch((error) => {
        alert.show(error.message, {
          type: 'error',
        });
      });
  };
  const prepareShowProvider = (item, index) => {
    return {
      ...item,
      name: item.name,
      phone: item.phone ? item.phone : '--',
      email: item.email,
      approved: (
        <SwitchComponent
          onChange={(value) => {
            updateProvider(item._id)
            deliveryManDelock(value, item)
          }}
          value="checked"
          checked={item.approved}
          label="approved"
        />
      ),
      online: (
        <SwitchComponent
          onChange={(value) => {}}
          value="checked"
          checked={item.available}
          label="available"
        />
      ),
      createdAt: item.createdAt ? formatTime(item.createdAt) : '--',
      dateDisapprovedCell:
        item.dateDisapproved &&
        moment(item.dateDisapproved).format('DD/MM hh:mm'),
    };
  };
  const loadUnapprouvedProviderList = (skip, limit, txt, cityId) => {
    setIsLoading(true);
    getUnapprouvedProvider(skip, limit, txt, cityId)
      .then(({ data }) => {
        setTotal(data.total);
        setProviders(data.deliveryMan);
      })
      .catch((error) => {
        alert.show(error.response.data?.message, {
          type: 'info',
        }),
          setResultsData({
            total: 0,
            items: [],
            tableItems: [],
          });
      })

      .finally(() => {
        setIsLoading(false);
      });
  };

  const handlePaginate = (val) => {
    setResultsData({
      items: [],
      tableItems: [],
    });
    loadUnapprouvedProviderList(
      val.pageSelect,
      val.numberOfRows,
      searchText,
      cityId,
    );
    setPage(val.pageSelect);
    setRowsPerPage(val.numberOfRows);
  };

  useEffect(() => {
    getCity();
  }, []);

  useEffect(() => {
    providers &&
      providers.length > 0 &&
      setResultsData({
        items: providers.map((item, index) => {
          return prepareShowProvider(item, index);
        }),
      });
  }, [providers]);
  return (
    <>
      <GridContainer>
        <Card>
          <CardBody>
            <Grid container>
              <Grid item xs={12}>
                {cities && (
                  <SelectBoxComponent
                    items={cities.map((city) => ({
                      value: city._id,
                      label: city.name,
                    }))}
                    inputLabel="city list"
                    onValueChange={(items) => {
                      setCityId(items);
                    }}
                    defaultValue={
                      cities.find((item) => item._id == cityId)?.name
                    }
                  />
                )}
              </Grid>
              <Grid item xs={8}>
                <input
                  type="text"
                  ref={inpRef}
                  placeholder="provider search"
                  style={{
                    border: 'none',
                    borderBottom: '1px solid black',
                  }}
                  defaultValue={searchText}
                />
              </Grid>
              <Grid item xs={4} container justify="flex-end">
                <Box textAlign="left">
                  <CustomButton
                    round
                    type="submit"
                    color="success"
                    size="sm"
                    loaderColor="white"
                    onClick={() => {
                      setSearchText(inpRef.current.value);
                      page > 0 && setPage(0);
                      loadUnapprouvedProviderList(
                        page > 0 ? 0 : page,
                        rowsPerPage,
                        inpRef.current.value,
                        cityId,
                      );
                    }}
                  >
                    find Provider
                  </CustomButton>
                </Box>
                <Box textAlign="left">
                  <CustomButton
                    round
                    type="submit"
                    color="google"
                    size="sm"
                    loaderColor="white"
                    onClick={() => {
                      setSearchText('');
                      setCityId('');
                      page > 0 && setPage(0);
                      loadUnapprouvedProviderList(
                        page > 0 ? 0 : page,
                        rowsPerPage,
                        '',
                        '',
                      );
                    }}
                  >
                    clear
                  </CustomButton>
                </Box>
              </Grid>
            </Grid>
          </CardBody>
        </Card>
      </GridContainer>
      <DataTableContainerComponent
        total={total}
        resultsData={resultsData}
        initData={resultsData.items}
        page={page + 1}
        columns={[
          {
            Header: 'Name',
            accessor: 'name',
          },
          {
            Header: 'phone',
            accessor: 'phone',
          },
          {
            Header: 'email',
            accessor: 'email',
          },
          {
            Header: 'Approved',
            accessor: 'approved',
          },
          {
            Header: 'online',
            accessor: 'online',
          },
          {
            Header: 'Plan',
            accessor: 'plan',
          },
          {
            Header: 'rate',
            accessor: 'rate',
          },
          {
            Header: 'appVersion',
            accessor: 'appVersion',
          },
          {
            Header: 'deliveryType',
            accessor: 'deliveryType',
          },
          {
            Header: 'reasonDisapproved',
            accessor: 'reasonDisapproved',
          },
          {
            Header: 'dateDisapproved',
            accessor: 'dateDisapprovedCell',
          },
          {
            Header: 'created At',
            accessor: 'createdAt',
          },
        ]}
        title="UnApprouved Provider List"
        isLoading={isLoading}
        loadItemsList={loadUnapprouvedProviderList}
        onPaginate={handlePaginate}
        isPaginate={true}
      />
    </>
  );
}
