import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import {Button, TextField} from '@material-ui/core';
import { createPricing, updatePricing } from '../services/serverApi.service';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import Switch from '@material-ui/core/Switch/Switch';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import PropTypes from 'prop-types';

const validationSchema = yup.object({
  deliveryPriceName: yup.string().required(),
  max_delivery_price: yup.number(),
});

export default function CreateDeliveryPrice({
  data,
  onItemEdited,
  onItemCreated,
  close,
  ...props
}) {
  const [interval, setInterval] = useState([
    {
      ds: 0,
      de: 1,
      basePrice: 0,
      distancePrice: 0,
    },
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [isCreate, setIsCreate] = useState(true);
  const [deliveryPrice, setDeliveryPrice] = useState(0);
  const [distanceStoreUser, setDistanceStoreUser] = useState(0);
  const formik = useFormik({
    initialValues: {
      deliveryPriceName: '',
      max_delivery_price: 0,
    },
    validationSchema,
    onSubmit: (values) => {
      isCreate ? create_pricing() : update_pricing();
    },
  });

  const addInterval = () => {
    const newInterval = interval;
    newInterval.push({
      ds: newInterval[newInterval.length - 1].de,
      de: newInterval[newInterval.length - 1].de + 1,
      basePrice: 0,
      distancePrice: 0,
    });
    setInterval([...newInterval]);
  };

  const editInterval = (index, item) => {
    const newInterval = interval;
    newInterval[index] = item;
    setInterval([...newInterval]);
  };

  const deleteInterval = (index) => {
    const newInterval = interval;
    newInterval.splice(index, 1);
    setInterval([...newInterval]);
  };

  const create_pricing = () => {
    setIsLoading(true);
    createPricing({
      name: formik.values.deliveryPriceName,
      priceIntervals: interval,
      maxDeliveryPrice: formik.values.max_delivery_price,
    })
      .then(({ data }) => {
        console.log('pricing has been created', {
          type: 'success',
        });
      })
      .catch((error) => {
        console.log(error.message, {
          type: 'error',
        });
      })
      .finally(() => {
        setIsLoading(false);
        close();
        onItemCreated();
      });
  };

  const update_pricing = () => {
    setIsLoading(true);
    updatePricing(data._id, {
      name: formik.values.deliveryPriceName,
      priceIntervals: interval,
      maxDeliveryPrice: formik.values.max_delivery_price,
    })
      .then(({ data }) => {
        console.log('pricing has been updated', {
          type: 'success',
        });
      })
      .catch((error) => {
        console.log(error.message, {
          type: 'error',
        });
      })
      .finally(() => {
        setIsLoading(false);
        close();
        onItemEdited();
      });
  };

  const testInterval = async () => {
    let distance = distanceStoreUser;
    let delivery_price = 0;
    await interval.forEach((item) => {
      if (distance > 0) {
        if (distance > item.de - item.ds) {
          distance -= item.de - item.ds;
          delivery_price +=
            parseFloat(item.basePrice) +
            (item.de - item.ds) * parseFloat(item.distancePrice);
        } else {
          delivery_price +=
            parseFloat(item.basePrice) +
            distance * parseFloat(item.distancePrice);
          distance -= item.de - item.ds;
        }
      }
    });
    delivery_price = Math.min(
      delivery_price,
      Number(formik.values.max_delivery_price),
    );
    setDeliveryPrice(delivery_price);
  };

  useEffect(() => {
    if (data) {
      setIsCreate(false);
      formik.setValues({
        deliveryPriceName: data.name,
        max_delivery_price: data.maxDeliveryPrice,
      });
      setInterval(data.priceIntervals);
    }
  }, [data]);
  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className="card">
          <div className="card-header">Delivery price distance</div>
          <div style={{ padding: '10px 20px', 'font-size': '16px' }}>
            <div className="form-group row">
              <label
                className="col-sm-2 col-form-label"
                style={{ color: 'black' }}
              >
                name
              </label>
              <div className="col-sm-10">
                <TextField
                  className={'w-25'}
                  id={'outlined-basic-deliveryPriceName'}
                  variant="outlined"
                  name={'deliveryPriceName'}
                  // value={formik.values.deliveryPriceName}
                  error={
                    //!formik.dirty ||
                    formik.touched.deliveryPriceName &&
                    Boolean(formik.errors.deliveryPriceName)
                  }
                  inputProps={{
                    onBlur: formik.handleBlur,
                  }}
                  helperText={
                    // !formik.dirty ||
                    formik.touched.deliveryPriceName &&
                    Boolean(formik.errors.deliveryPriceName)
                      ? 'name is required.'
                      : ''
                  }
                  onChange={formik.handleChange}
                  value={formik.values.deliveryPriceName}
                />
              </div>
            </div>
            <div className="form-group row">
              <label
                className="col-sm-2 col-form-label"
                style={{ color: 'black' }}
              >
                max delivery price
              </label>
              <div className="col-sm-10">
                <TextField
                  className={'w-25'}
                  id={'outlined-basic-price'}
                  label=""
                  variant="outlined"
                  type={'number'}
                  onChange={formik.handleChange}
                  value={formik.values.max_delivery_price}
                  name="max_delivery_price"
                />
              </div>
            </div>
          </div>
          <div className="px-2">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">DS</th>
                  <th scope="col">DE</th>
                  <th scope="col">Price</th>
                  <th scope="col">Price of distance</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {interval.map((item, index) => (
                  <tr>
                    <th scope="row">Interval {index + 1}</th>
                    <td>
                      <div>
                        <TextField
                          id={'outlined-basic-ds' + index}
                          label=""
                          variant="outlined"
                          type={'number'}
                          value={item.ds}
                          InputProps={{
                            readOnly: true,
                          }}
                          onChange={($event) => {
                            item.ds = parseFloat($event.target.value);
                            editInterval(index, item);
                          }}
                        />
                      </div>
                    </td>
                    <td>
                      <div style={{ display: 'inline-flex' }}>
                        <TextField
                          id={'outlined-basic-de' + index}
                          label=""
                          variant="outlined"
                          type={'number'}
                          value={item.de}
                          InputProps={{
                            readOnly: interval.length !== index + 1,
                            inputProps: { min: item.ds },
                          }}
                          onChange={($event) => {
                            item.de = parseFloat($event.target.value);
                            editInterval(index, item);
                          }}
                        />
                      </div>
                    </td>
                    <td>
                      <div style={{ display: 'inline-flex' }}>
                        <TextField
                          id={'outlined-basic-' + index}
                          label=""
                          variant="outlined"
                          type={'number'}
                          value={item.basePrice}
                          onChange={($event) => {
                            item.basePrice = parseFloat($event.target.value);
                            editInterval(index, item);
                          }}
                        />
                      </div>
                    </td>
                    <td>
                      <div style={{ display: 'inline-flex' }}>
                        <TextField
                          id={'outlined-basic-' + index}
                          label=""
                          variant="outlined"
                          type={'number'}
                          value={item.distancePrice}
                          onChange={($event) => {
                            item.distancePrice = parseFloat(
                              $event.target.value,
                            );
                            editInterval(index, item);
                          }}
                        />
                      </div>
                    </td>{' '}
                    <td>
                      {interval.length > 1 && interval.length == index + 1 && (
                        <Fab
                          size="medium"
                          color="secondary"
                          aria-label="add"
                          onClick={deleteInterval}
                        >
                          <DeleteIcon />
                        </Fab>
                      )}
                    </td>
                  </tr>
                ))}
                <tr>
                  <td colSpan={6}>
                    <Fab color="primary" aria-label="add" onClick={addInterval}>
                      <AddIcon />
                    </Fab>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="row px-3">
            <div className="col-4">
              <div className="row">
                <div className="col-4">
                  <h5 style={{ 'font-weight': 'bold' }}>distance StoreUser</h5>
                </div>
                <div className="col-8">
                  <TextField
                    type={'number'}
                    className={'w-50'}
                    variant="outlined"
                    id="d_StoreUser"
                    value={distanceStoreUser}
                    onChange={($event) => {
                      setDistanceStoreUser($event.target.value);
                    }}
                    InputProps={{
                      onBlur: testInterval,
                      inputProps: { min: 0 },
                    }}
                    label={'KM'}
                  />
                </div>
              </div>
            </div>
            <div className="col-4">
              <h5 style={{ 'font-weight': 'bold' }}>
                commission : {deliveryPrice + ' DH'}
              </h5>
            </div>
          </div>
          <div className={'d-flex justify-content-end p-2'}>
            <Button variant="contained" className={'mx-2'} onClick={close}>
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={'mx-2'}
            >
              {isCreate ? 'create' : 'update'}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
}

CreateDeliveryPrice.propTypes = {
  onItemEdited: PropTypes.func,
  close: PropTypes.func,
  data: PropTypes.any,
};
