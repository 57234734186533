import React, { useEffect, useState } from 'react';
import { getCities } from '../../delivery-info/services/serverApi.service';
import { Button, Grid } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useAlert } from 'react-alert';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';
import SaveIcon from '@material-ui/icons/Save';
import SendIcon from '@material-ui/icons/Send';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  getApprouvedProvider,
  getProviderDetails,
  getWorkingCalendar,
  insertWorkingCalendar,
  pastWorkingCalendar,
  updateWorkingCalendar,
} from '../service/serverAPI.service';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import Checkbox from '@material-ui/core/Checkbox';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Box from '@material-ui/core/Box';
import AsyncSelect from 'react-select/async';
import TablePagination from '@material-ui/core/TablePagination';
import { citiesPlaningByDay } from '../../orders/services/serverApi.service';
import Swal from 'sweetalert2';
import Input from '@material-ui/core/Input';
import ModalComponent from '../../../components/Modal.component';
import { closeModelAction } from '../../orders/redux/actions/order.actions';
import FormGroup from '@material-ui/core/FormGroup/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';

export default function WorkingCalendarConfig() {
  const alert = useAlert();
  const [resultsData, setResultsData] = useState({
    total: 0,
    items: [],
    tableItems: [],
  });
  const [isLoading, setIsLoading] = useState(true);
  const pages = [25, 50, 100];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [cities, setCities] = useState([]);
  const [providers, setProviders] = useState([]);
  const [providerCount, setProviderCount] = useState(0);
  const [newProviders, setNewProviders] = useState([]);
  const [selectIdCity, setSelectIdCity] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [workingCalendarData, setWorkingCalendarData] = useState(null);
  const [deliveryType, setDeliveryType] = useState(['Driver']);
  const [workingCalendarDataSaved, setWorkingCalendarDataSaved] =
    useState(null);
  const [hours, setHours] = useState([]);
  const [planningError, setPlanningError] = useState([]);
  const [planning, setPlanning] = useState([]);
  const [planningConfigText, setPlanningConfigText] = useState(null);
  const [IsService, setIsService] = useState(false);
  const [IsJocker, setIsJocker] = useState(false);
  const [selectAllProvider, setSelectAllProvider] = useState(false);
  const [tabsValue, setTabsValue] = React.useState(0);
  const [saved, setSaved] = React.useState(-1);
  const [days, setDays] = React.useState([]);
  const [tabsDate, setTabsDate] = useState('');
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(23);
  const [planingDay, setPlaningDay] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [dayschecked, setDaysChecked] = useState([]);
  const [nbSemaine, setNbSemaine] = useState(1);
  const useStyles = makeStyles({
    table: {
      width: '100%',
    },
    input_edit: {
      width: 80,
    },
    border: {
      borderRight: '1px solid black',
    },
    'MuiOutlinedInput-input': {
      padding: '6px 14px !important',
    },
  });
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const theme = useTheme();
  const getStyles = (item, data) => {
    return {
      fontWeight:
        data.indexOf(item) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  };
  const classes = useStyles();
  let timer1;
  const fetchData = (inputValue, callback) => {
    clearTimeout(timer1);
    setInputValue('');
    timer1 = setTimeout(() => {
      inputValue &&
        getApprouvedProvider(0, 10, selectIdCity, inputValue)
          .then(({ data }) => {
            const tempArray = [];
            if (data.deliveryMan) {
              if (data.deliveryMan.length) {
                data.deliveryMan.forEach((element) => {
                  tempArray.push({
                    label: `${element.name}`,
                    ...element,
                  });
                });
              }
            }
            callback(tempArray);
          })
          .catch((error) => {
            console.log(error);
          });
    }, 1000);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    loadApprouvedProviderList(newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    loadApprouvedProviderList(0, parseInt(event.target.value, 10));
  };
  const paginate = () => {
    console.log('asa *********');
  };
  const loading = () => {
    let timerInterval;
    Swal.fire({
      title: 'loading',
      html: '',
      timer: 10000,
      timerProgressBar: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
        console.log('I was closed by the timer');
      }
    });
  };

  useEffect(() => {
    loadCitiesList();
    setHours([...range(start, end)]);
    let date = new Date();
    let new_days = [];
    for (let i = 0; i < 7 * nbSemaine; i++) {
      let new_date = moment(date).add(i, 'days');
      new_days.push({
        date: new_date.format('DD-MM-YYYY'),
        name: new_date.format('ddd'),
      });
    }
    setDays([...new_days]);
    setTabsValue(0);
    setTabsDate(moment().format('DD-MM-YYYY').toString());
  }, [start, end, nbSemaine]);
  const getTotalDeliveryMenInCity = () => {
    citiesPlaningByDay(moment().format('DD-MM-YYYY').toString())
      .then(({ data }) => {
        console.log(data);
        setPlaningDay(data.citiesPlanning.cities);
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    getTotalDeliveryMenInCity();
  }, []);
  useEffect(() => {
    if (selectIdCity !== '' && !isLoading) {
      setIsLoading(true);
      // setDeliveryType([]);
      loadApprouvedProviderList(0, 25);
      console.log('change cities');
    }
  }, [selectIdCity]);
  let timer2;
  // const loadProviderByDeliveryType = (skip, limit, deliveryType) => {
  //   timer2 = clearTimeout(timer2);
  //   setProviders([]);
  //   setNewProviders([]);
  //   setTimeout(() => {
  //     loading();
  //     getDeliveryMenList(skip, limit, deliveryType);
  //   }, 1000);
  // };

  const getDeliveryMenList = (skip, limit) => {
    getApprouvedProvider(skip, limit, selectIdCity, '', deliveryType)
      .then(({ data }) => {
        setProviderCount(data.total);
        setProviders([...data.deliveryMan]);
      })
      .catch((error) => {
        if (error.response)
          alert.show(error.response.data?.message, {
            type: 'info',
          }),
            setProviders([]);
      })
      .finally(() => {
        Swal.close();
      });
  };

  useEffect(() => {
    if (providers.length > 0) {
      get_working_calendar();
      console.log('asa********************', providers);
    }
  }, [providers]);
  const [myCalendar, setMyCalendar] = useState();
  useEffect(() => {
    if (workingCalendarDataSaved) {
      console.log('update in db ok !! **** ');
      const { _id = '', ...data } = workingCalendarDataSaved;
      // if (_id !== '') {
      updateWorkingCalendar(_id, data)
        .then(({ data }) => {
          console.log('MY RESULT ==>', data);
          // setMyCalendar(data.workingCalendar)
          // getTotalDeliveryMenInCity()
        })
        .catch()
        .finally(() => {
          setSaved(-1);
          setIsLoading(false);
        });
    }
  }, [workingCalendarDataSaved]);
  //
  const insert_working_calendar = () => {
    let data = {
      cityId: selectIdCity,
      day: tabsDate,
      start: start,
      end: end,
    };

    insertWorkingCalendar(data)
      .then(({ data }) => {
        console.log('*********************** insert ', data);
        setWorkingCalendarData(data.workingCalendar);
      })
      .catch((e) => console.log(e));
  };
  useEffect(() => {
    if (saved !== -1) {
      setWorkingCalendarDataSaved(null);
      setTimeout(() => {
        update_Working_Calendar();
      }, 1500);
    }
  }, [saved]);

  const loadApprouvedProviderList = (skip, limit) => {
    console.log(skip, limit);
    setProviders([]);
    setNewProviders([]);
    getDeliveryMenList(skip, limit);
  };
  const loadCitiesList = () => {
    getCities(page, rowsPerPage, true)
      .then(({ data }) => {
        data = data.cities;
        setCities([...data]);
      })
      .finally(() => {});
  };
  const prepareData = () => {
    return new Promise((resolve) => {
      const providersList = providers.map((provider) => {
        //
        let planningMap = [];
        let new_planning = [];
        let planningData = range(start, end);
        new_planning = planningData.map((item) => {
          return { time: item, value: '' };
        });
        provider.checked = false;
        let data =
          workingCalendarData && workingCalendarData.deliveryManList
            ? workingCalendarData?.deliveryManList.find(
                (elem) => elem.deliveryManId === provider._id,
              )
            : null;
        if (data) {
          provider.workingCalendar = data;
          if (data.planning.length === 0) {
            provider.workingCalendar.new_planning = new_planning;
          } else {
            data.planning.map((item) => {
              let index_planning = new_planning.findIndex(
                (elem) => parseInt(elem.time) === parseInt(item.time),
              );
              if (index_planning !== -1)
                new_planning[index_planning].value = item.value;
            });
            provider.workingCalendar.new_planning = new_planning;
          }
        } else {
          provider.workingCalendar = {
            isJocker: false,
            isService: false,
            planning: planningMap,
            new_planning: new_planning,
          };
        }
        return provider;
      });
      resolve(providersList);
    });
  };
  const get_working_calendar = () => {
    selectIdCity !== '' &&
      getWorkingCalendar(selectIdCity, tabsDate)
        .then(({ data }) => {
          if (data) {
            const wc = data.workingCalendar;
            if (wc) {
              setWorkingCalendarData(wc);
            } else {
              insert_working_calendar();
            }
          }
        })
        .catch(() => {
          //insert_working_calendar();
        })
        .finally(() => {
          Swal.close();
        });
  };
  useEffect(() => {
    if (providers)
      prepareData().then((res) => {
        setNewProviders([...res]);
        setIsLoading(false);
      });
  }, [providers, workingCalendarData]);
  const range = (start, end) => {
    let value = [];
    for (let i = start; i <= end; i++) {
      value.push(i);
      if (i === end) {
        return value;
      }
    }
  };
  const configPlanning = async (index, type) => {
    let error = false;
    let value = planningConfigText;
    if (!!value) {
      value = value.split('/');
      let data = await value.map((item) => {
        const val = item.split('-');
        if (val.length < 2) {
          error = true;
        } else {
          if (val[0] < start || val[1] > end) {
            console.log('error 1');
            error = true;
          }
          return val;
        }
      });
      let listHours = data.flat(1);
      const isSorted = !!listHours.reduce(
        (previousValue, currentValue) => previousValue < currentValue,
      );
      if (!isSorted) {
        error = true;
        console.log('error 2', listHours);
      }

      if (!error && listHours.length % 2 !== 1) {
        let planningData = [];
        let new_planning = [];
        await data.map((item) => {
          let newItem = range(parseInt(item[0]), parseInt(item[1]));
          return newItem.map((elem) => {
            planningData[elem + 'h'] = 'P';
          });
        });
        //console.log('planning', planningData);
        setPlanning(planningData ? planningData : []);
        ////
        new_planning = await data.map((item) => {
          let newItem = range(parseInt(item[0]), parseInt(item[1]));
          return newItem.map((elem) => {
            return {
              time: elem,
              value: 'P',
            };
          });
        });
        new_planning = new_planning.flat(1);

        let origin_planning = range(start, end).map((item) => {
          return { time: item, value: '' };
        });
        //console.log(new_planning);
        //console.log(origin_planning);
        new_planning.map((item) => {
          let index_planning = origin_planning.findIndex(
            (elem) => elem.time === item.time,
          );
          if (!(index_planning === -1)) {
            origin_planning[index_planning].value = item.value;
          }
        });
        ///
        planningError[index] = false;
        setPlanningError([...planningError]);
        //saveConfigPlanning(index, type, planningData);
        saveConfigPlanning(index, type, origin_planning);
      } else {
        planningError[index] = true;
        setPlanningError([...planningError]);
      }
    }
  };

  const saveConfigPlanning = async (index, type, new_planning) => {
    let new_providers = providers;
    if (type && type === 'all') {
      let deliveryManListUpdated = await new_providers.map((item) => {
        if (item.checked) {
          new_planning.map((elem, index) => {
            item.workingCalendar.new_planning[index] = {
              time: elem.time,
              value: elem.value,
            };
          });
          item.workingCalendar.isService = IsService;
          item.workingCalendar.isJocker = IsJocker;
          return {
            deliveryManId: item._id,
            planning: new_planning,
            isJocker: item.workingCalendar.isJocker,
            isService: item.workingCalendar.isService,
          };
        }
      });
      workingCalendarData.deliveryManList = deliveryManListUpdated.filter(
        (item) => item,
      );

      setWorkingCalendarDataSaved({
        end: workingCalendarData.end,
        start: workingCalendarData.start,
        _id: workingCalendarData._id,
        cityId: workingCalendarData.cityId,
        deliveryManList: workingCalendarData.deliveryManList,
      });
    } else {
      // change planning
      new_providers[index].workingCalendar.new_planning = new_planning;
      setSaved(index);
      setNewProviders([...new_providers]);
    }
  };

  const edit_Provider_Planning = (val, index, index_planning) => {
    let provider = newProviders[index];
    let new_planning = provider.workingCalendar.new_planning;
    new_planning[index_planning].value = val;
    provider.workingCalendar.new_planning = new_planning;
    newProviders[index] = provider;
    setNewProviders([...newProviders]);
    setSaved(index);

    //update_Working_Calendar(providers);
  };

  const selectAllProviders = async () => {
    let newProviders = providers;
    newProviders = await newProviders.map((item) => {
      item.checked = !selectAllProvider;
      return item;
    });
    setNewProviders([...newProviders]);
  };

  const selectProvider = (index) => {
    setSelectAllProvider(false);
    let newProviders = providers;
    newProviders[index].checked = !newProviders[index].checked;
    setNewProviders([...newProviders]);
  };

  const handleTabs = (event, newValue) => {
    setTabsValue(newValue);
    setSelectAllProvider(false);
    setWorkingCalendarDataSaved(null);
    setWorkingCalendarData(null);
    setTabsDate(days[newValue].date.toString());
    setPage(0);
    setRowsPerPage(25);
    loading();
    loadApprouvedProviderList(0, 25);
    //get_working_calendar();
  };

  const update_Working_Calendar = async () => {
    if (workingCalendarData) {
      let provider = newProviders[saved];
      const planning = provider.workingCalendar.new_planning;
      provider = {
        deliveryManId: provider._id,
        planning: planning,
        isJocker: provider.workingCalendar.isJocker,
        isService: provider.workingCalendar.isService,
      };

      console.log('provider updated !! ', {
        end: workingCalendarData.end,
        start: workingCalendarData.start,
        _id: workingCalendarData._id,
        providerUpdated: provider,
      });
      setWorkingCalendarDataSaved({
        end: workingCalendarData.end,
        start: workingCalendarData.start,
        _id: workingCalendarData._id,
        cityId: workingCalendarData.cityId,
        deliveryManList: [provider],
      });
    }
  };

  const edit_provider = (index, tag) => {
    let newproviders = providers;
    newproviders[index].workingCalendar[tag] =
      !newproviders[index].workingCalendar[tag];
    setNewProviders([...newproviders]);
    setSaved(index);
  };

  function insert_working_calendar_by_Days() {
    console.log('tabsDate', {
      originCalendarDay: tabsDate,
      originCalendarcityId: selectIdCity,
      pastDays: dayschecked,
    });
    pastWorkingCalendar({
      originCalendarDay: tabsDate,
      originCalendarcityId: selectIdCity,
      pastDays: dayschecked,
    })
      .then(({ data }) => {
        console.log(data);
      })
      .catch(() => {
        setShowModal(false);
      })
      .finally(() => {
        setShowModal(false);
      });
    // console.log(myCalendar);
    // if (!myCalendar) {
    //   return;
    // }
    // console.log(dayschecked);
    // const { _id = '', ...data } = myCalendar;
    // dayschecked?.map((day) => {
    //   let calandar = {
    //     cityId: selectIdCity,
    //     day: day,
    //     start: data?.start,
    //     end: data?.end,
    //     deliveryManList: data?.deliveryManList,
    //   };
    //   console.log(calandar);
    //   insertWorkingCalendar(calandar)
    //     .then(({ data }) => {
    //       console.log('*********************** insert ', data);
    //     })
    //     .catch((e) => console.log(e));
    // });
  }

  return (
    <>
      <div className={'p-3 my-3'} style={{ background: '#ffffff' }}>
        <div className="row">
          <div className="col-6">
            <div className="row">
              {/*<div className="col-12">*/}
              <div className="col-6">
                <FormControl style={{ minWidth: '200px', padding: 10 }}>
                  <TextField
                    id="outlined-basic"
                    label="start"
                    type={'number'}
                    variant="outlined"
                    value={start}
                    onChange={(e) => setStart(parseInt(e.target.value))}
                    InputProps={{
                      inputProps: {
                        max: end - 1,
                        min: 7,
                      },
                    }}
                  />
                </FormControl>
              </div>
              <div className="col-6">
                <FormControl style={{ minWidth: '200px', padding: 10 }}>
                  <TextField
                    id="outlined-basic"
                    label="end"
                    type={'number'}
                    variant="outlined"
                    value={end}
                    onChange={(e) => setEnd(parseInt(e.target.value))}
                    InputProps={{
                      inputProps: {
                        max: 23,
                        min: start + 1,
                      },
                    }}
                  />
                </FormControl>
              </div>
              <div className="col-6">
                <div className="row">
                  <FormControl style={{ minWidth: '300px', padding: 10 }}>
                    <InputLabel>Cities</InputLabel>
                    <Select
                      value={selectIdCity}
                      onChange={($event) => {
                        // TODO CHANGE CITIES
                        setSelectIdCity($event.target.value);
                        loading();
                        // setDeliveryType([]);
                        //loadOrderList($event.target.value);
                      }}
                    >
                      {cities.map((item, index) => (
                        <MenuItem value={item._id}>{item.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                {/*{selectIdCity !== '' && (*/}
                {/*  <div className="row">*/}
                {/*    <FormControl*/}
                {/*      style={{ minWidth: '300px', padding: 10 }}*/}
                {/*      fullWidth*/}
                {/*    >*/}
                {/*      <InputLabel>delivery type</InputLabel>*/}
                {/*      <Select*/}
                {/*        labelId="demo-mutiple-name-label"*/}
                {/*        id="demo-mutiple-name"*/}
                {/*        multiple*/}
                {/*        value={deliveryType}*/}
                {/*        onChange={(items) => {*/}
                {/*          items = items.target.value;*/}
                {/*          if (*/}
                {/*            items.length > 1 &&*/}
                {/*            items.includes('all') &&*/}
                {/*            deliveryType.includes('all')*/}
                {/*          ) {*/}
                {/*            const index = items.indexOf('all');*/}
                {/*            index >= 0 && items.splice(index, 1);*/}
                {/*          } else if (*/}
                {/*            items.length &&*/}
                {/*            items.includes('all') &&*/}
                {/*            !deliveryType.includes('all')*/}
                {/*          ) {*/}
                {/*            items = ['all'];*/}
                {/*          }*/}
                {/*          setDeliveryType(items);*/}
                {/*          loadProviderByDeliveryType(0, 25, items);*/}
                {/*        }}*/}
                {/*        input={<Input />}*/}
                {/*        MenuProps={MenuProps}*/}
                {/*      >*/}
                {/*        {['all', 'Food', 'Partners', 'Hybrid'].map((item) => (*/}
                {/*          <MenuItem*/}
                {/*            key={item}*/}
                {/*            value={item}*/}
                {/*            name={item}*/}
                {/*            style={getStyles(item, deliveryType)}*/}
                {/*          >*/}
                {/*            {item}*/}
                {/*          </MenuItem>*/}
                {/*        ))}*/}
                {/*      </Select>*/}
                {/*    </FormControl>*/}
                {/*  </div>*/}
                {/*)}*/}
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="row">
              {selectIdCity !== '' && (
                <div className="col-12">
                  <FormControl style={{ minWidth: '300px', padding: 10 }}>
                    <InputLabel>Provider</InputLabel>
                    <AsyncSelect
                      single
                      isClearable
                      defaultValue={inputValue}
                      loadOptions={fetchData}
                      placeholder="find deliveryMan"
                      onChange={(e) => {
                        console.log([e]);
                        if (e) {
                          setProviders([e]);
                        } else {
                          setProviders([]);
                          setIsLoading(true);
                          loadApprouvedProviderList(0, 25);
                        }
                      }}
                    />
                    {/*<Button*/}
                    {/*  variant="contained"*/}
                    {/*  endIcon={<DeleteIcon />}*/}
                    {/*  onClick={() => {*/}
                    {/*    setProviders([]);*/}
                    {/*    setInputValue('');*/}
                    {/*  }}*/}
                    {/*>*/}
                    {/*  clear*/}
                    {/*</Button>*/}
                  </FormControl>
                </div>
              )}
            </div>
            <form>
              <div className="form-group row">
                <label
                  htmlFor="inputPassword"
                  className="col-sm-2 col-form-label"
                >
                  Planning
                </label>
                <div className="col-sm-10">
                  <TextField
                    label=""
                    variant="outlined"
                    type="text"
                    name="planning"
                    onChange={(e) => {
                      setPlanningConfigText(e.target.value);
                    }}
                    inputProps={{
                      style: {
                        padding: '6px 10px',
                      },
                    }}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label
                  htmlFor="inputPassword"
                  className="col-sm-2 col-form-label"
                >
                  Service
                </label>
                <div className="col-sm-10">
                  <Switch
                    checked={IsService}
                    onChange={(e) => {
                      setIsService(!IsService);
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label
                  htmlFor="inputPassword"
                  className="col-sm-2 col-form-label"
                >
                  Jocker
                </label>
                <div className="col-sm-10">
                  <Switch
                    checked={IsJocker}
                    onChange={(e) => {
                      setIsJocker(!IsJocker);
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                </div>
              </div>
              <div className="form-group row">
                <Button
                  variant="contained"
                  endIcon={<SendIcon />}
                  onClick={() => {
                    configPlanning(-1, 'all');
                  }}
                >
                  Appliquer
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div>
        {!!workingCalendarData && (
          <Button
            variant="contained"
            onClick={() => {
              setShowModal(true);
              setDaysChecked([]);
            }}
          >
            Copie / coller
          </Button>
        )}
        <Box sx={{ bgcolor: 'background.paper' }}>
          <Tabs
            value={tabsValue}
            onChange={handleTabs}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            {days.map((item, index) => (
              <Tab label={item.name} tabIndex={index}></Tab>
            ))}
          </Tabs>
        </Box>
      </div>
      <div>
        <TableContainer
          component={Paper}
          style={{ width: '100%', height: '80vh' }}
        >
          <Table
            className={classes.table}
            aria-label="spanning table"
            style={{ width: '100%' }}
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <TableCell align="left">
                  <Checkbox
                    checked={selectAllProvider}
                    onChange={() => {
                      setSelectAllProvider(!selectAllProvider);
                      selectAllProviders();
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                </TableCell>
                <TableCell align="left">Drive</TableCell>
                <TableCell align="left">Service</TableCell>
                <TableCell align="left">Jocker</TableCell>
                <TableCell align="left">Planning</TableCell>
                {hours &&
                  hours.map((item) => (
                    <TableCell align="left">
                      {item}h<br />
                      {
                        (planingDay || []).find((x) => x.cityId == selectIdCity)
                          ?.times[item]
                      }
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {!isLoading &&
                newProviders.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Checkbox
                        checked={item.checked}
                        onChange={() => selectProvider(index)}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    </TableCell>
                    <TableCell align="left">{item.name}</TableCell>
                    {item.workingCalendar && (
                      <TableCell align="left">
                        <Switch
                          checked={item.workingCalendar.isService}
                          onChange={(e) => {
                            edit_provider(index, 'isService');
                          }}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      </TableCell>
                    )}
                    {item.workingCalendar && (
                      <TableCell align="left">
                        <Switch
                          checked={item.workingCalendar.isJocker}
                          onChange={(e) => {
                            edit_provider(index, 'isJocker');
                          }}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      </TableCell>
                    )}
                    <TableCell
                      align="left"
                      style={{ padding: 0, whiteSpace: 'nowrap' }}
                    >
                      <TextField
                        error={planningError[index]}
                        label=""
                        variant="outlined"
                        type="text"
                        name="planning"
                        id={'planning' + index}
                        inputProps={{
                          style: {
                            padding: '6px 10px',
                            width: 'auto',
                          },
                        }}
                        onChange={(e) => {
                          setPlanningConfigText(e.target.value);
                        }}
                      />
                      <IconButton
                        style={{ padding: '6px 10px' }}
                        color="primary"
                        component="span"
                        onClick={(e) => {
                          configPlanning(index);
                        }}
                      >
                        <SendIcon />
                      </IconButton>
                    </TableCell>
                    {item.workingCalendar &&
                      item.workingCalendar.new_planning.map(
                        (item, index_planning) =>
                          index_planning < hours.length && (
                            <TableCell align="left">
                              {
                                <Select
                                  id={'select_' + index + '_' + item.time}
                                  style={{
                                    backgroundColor:
                                      item.value === 'A'
                                        ? 'red'
                                        : item.value === 'J'
                                        ? '#ffeb3b'
                                        : '',
                                  }}
                                  value={item.value}
                                  onChange={(e) => {
                                    edit_Provider_Planning(
                                      e.target.value,
                                      index,
                                      index_planning,
                                    );
                                  }}
                                >
                                  <MenuItem value={''}>---</MenuItem>
                                  <MenuItem value={'P'}>P</MenuItem>
                                  <MenuItem value={'J'}>J</MenuItem>
                                  <MenuItem value={'A'}>A</MenuItem>
                                </Select>
                              }
                            </TableCell>
                          ),
                      )}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          {providerCount && (
            <TablePagination
              component="div"
              rowsPerPageOptions={[10, 25, 50]}
              count={providerCount}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              onChange={paginate}
            />
          )}
        </TableContainer>
      </div>
      {showModal && (
        <ModalComponent
          maxWidth={'lg'}
          close={() => setShowModal(false)}
          visible={showModal}
          title="copie / coller"
        >
          <div className={'row col-12'}>
            <FormControl
              // style={{ width: '100%' }}
              variant="outlined"
              style={{
                width: '100%',
                padding: '5px',
                border: '1px solid #f0ffea',
                margin: '20px 0px',
                background: 'rgba(0,206,255,0.31)',
              }}
            >
              <InputLabel>copie day</InputLabel>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={true}
                    value={days[tabsValue].date}
                    style={{ color: 'red' }}
                  />
                }
                label={days[tabsValue].name}
              />
            </FormControl>
          </div>
          <div className="row">
            <div className="col-4">
              <input
                defaultValue={nbSemaine}
                type="number"
                placeholder={'nombre semaine'}
                onClick={(event) => {
                  console.log(event.target.value);
                  if (parseInt(event.target.value) > 0) {
                    setNbSemaine(parseInt(event.target.value));
                    setDaysChecked([]);
                  }
                }}
              />
            </div>
          </div>
          <div
            className={'row col-12'}
            style={{
              padding: '5px',
              border: '1px solid red',
              margin: '20px 0px',
              background: '#f0ffea',
              display: 'flex',
            }}
          >
            <div sx={{ m: 3 }} component="fieldset" variant="standard">
              <label htmlFor="">Coller</label>
              <div className="row">
                {[...days.filter((e, index) => index !== tabsValue)].map(
                  (item, index) => (
                    <FormControlLabel
                      className={'col-2 p-0 m-0'}
                      style={{ whiteSpace: 'nowrap' }}
                      control={
                        <Checkbox
                          // checked={!!item.checked}
                          value={item.date}
                          onChange={(event, checked) => {
                            let dd = [...dayschecked];
                            if (checked) {
                              dd.push(item.date);
                            } else {
                              dd.splice(index, 1);
                            }
                            setDaysChecked([...dd]);
                            console.log(dayschecked);
                          }}
                        />
                      }
                      label={item.name + ' ' + item.date}
                    />
                  ),
                )}
              </div>
            </div>
          </div>
          <div>
            <Button
              variant="contained"
              endIcon={<SendIcon />}
              onClick={() => {
                insert_working_calendar_by_Days();
              }}
            >
              valider
            </Button>
          </div>
        </ModalComponent>
      )}
    </>
  );
}
