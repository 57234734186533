import { actions } from 'react-table';
import {
    GET_ADMINS_LIST_REQUEST,
    GET_ADMINS_LIST_SUCCESS,
    GET_ADMINS_LIST_FAIL,
} from '../constants/adminsList.constants';

const initialState = {
    loading: false,
    adminsList: [],
    error: '',
};

export default function ordersReducer(state = initialState, action) {

    switch (action.type) {
        case GET_ADMINS_LIST_REQUEST:
            return {
                loading: true,
            };

        case GET_ADMINS_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                adminsList: action.adminsList
            };
        case GET_ADMINS_LIST_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error?.message ||
                    (action?.error?.errors || [{ message: '' }])[0].message,
            };

        default:
            return state;
    }
}
