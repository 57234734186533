import React ,{useEffect,useState} from 'react'
import DataTableContainerComponent from '../../../components/DataTableSortingContainer.component';
import {formatTimeForOrder} from '../../../helpers'


export default function LastKnowLocationComponent({lastLocation,close}) {
    const [newLastLocation,setNewLastLocation] = useState([])
    
    const columns = [
        {
          Header: 'lat',
          accessor: 'location[0]',
        },
        {
          Header: 'lng',
          accessor: 'location[1]',
        },
        {
          Header: 'time',
          accessor: 'timestampCol',
        },
    ]
    useEffect(() => {
        setNewLastLocation(

            lastLocation&&lastLocation.length&&lastLocation.map(location=>{
                return {
                    ...location,
                    timestampCol : formatTimeForOrder(location.timestamp)
                }
            })
        )
    },[lastLocation])
    return (
        <div>
            {(newLastLocation&&newLastLocation.length)?
            // newLastLocation.map((location ,index)=>(
            //     <p>{(index+1)+"---->"+JSON.stringify(location.location)}</p>
            // ))
            <DataTableContainerComponent
            // total={resultsData.total}
            resultsData={newLastLocation}
            initData={newLastLocation}
            columns={columns}
            title=""
            // isLoading={isLoading}
            // loadItemsList={loadApprouvedProviderList}
            // onPaginate={handlePaginate}
            isPaginate={false}
            />
        :'no Location saved'}
        </div>
    )
}
