import axios from 'axios';
import io from 'socket.io-client';
import {
  backendServerConfig,
  backendSocketConfig,
  SOCKET_URL,
  MICROSERVICE_BASE_URL,
} from '../../../config/backendServer.config';
import { store } from '../../../redux/store';

export function getCurrentOrders(cityId) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.DISPATCHING}/order/city/${cityId}/current-orders`,
  );
}
export function validateOrder(orderId, adminId) {
  return axios.put(
    `${MICROSERVICE_BASE_URL.DISPATCHING}/order/${orderId}/validate`,
    { ...adminId },
  );
}
export function getHistoryOrders(
  skip,
  limit,
  paged,
  txt,
  searchBy,
  startDate,
  endDate,
  useDate,
  shortId,
) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.ORDERS}/order/completed?page=${skip || 0}&limit=${limit || 5
    }&paged=${paged || false}&searchText=${txt || ''}&searchBy=${searchBy || ''
    }${shortId && `&shortId=${shortId || ''}`}&${useDate && `startDate=${startDate || ''}&endDate=${endDate || ''}`
    }`,
  );
}
export function getDeliveryMan(skip, limit, paged) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.DELIVERY_MAN}/deliveryMan?page=${skip || 0
    }&limit=${limit || 0}&paged=${paged || false}`,
  );
}

export function getCities(skip, limit, paged) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.ADMIN}/city?page=${skip || 0}&limit=${limit || 0
    }&paged=${paged || false}`,
  );
}
export function getOrdersByCities(cities) {
  return axios.post(
    `${MICROSERVICE_BASE_URL.DISPATCHING}/order/city/current-orders`,
    { cities: cities },
  );
}
export function getDeliveryManByCities(cities) {
  return axios.post(`${MICROSERVICE_BASE_URL.DISPATCHING}/delivery-man/cities`, {
    cities: cities,
  });
}
export function acceptOrRejectOrder(orderId, data) {
  // const reduxState = store.getState();
  // data.admin =reduxState.authReducer.userData ? reduxState.authReducer.userData.user : null;
  return axios.put(
    `${MICROSERVICE_BASE_URL.DISPATCHING}/order/${orderId}/accept`,
    data,
  );
}

export function updateOrderStatus(orderId, data) {
  return axios.put(
    `${MICROSERVICE_BASE_URL.DISPATCHING}/order/${orderId}/status`,
    { ...data, byAdmin: true },
  );
}
export function cancelOrder(orderId, data) {
  return axios.put(
    `${MICROSERVICE_BASE_URL.DISPATCHING}/order/${orderId}/cancel`,
    data,
  );
}
export function addComment(orderId, data) {
  return axios.put(
    `${MICROSERVICE_BASE_URL.DISPATCHING}/order/${orderId}/comment`,
    data,
  );
}

// start socket
export function socketConnect(id) {
  return io(backendSocketConfig.appUrl, {
    path: '/api/v1/sockets/connect',
    query: { token: 'Bearer ' + id },
    transports: ['websocket'],
  });
}
export function socketPartnerConnect(id) {
  return io(backendSocketConfig.appUrl, {
    path: '/api/v1/socketspartners/connect',
    query: { token: 'Bearer ' + id },
    transports: ['websocket'],
  });
}
export function socketGpsSdConnect(id) {
  console.log('her', id);
  return io(backendSocketConfig.socketGpsSd, {
    path: '/api/v1/sockets-gps-sd/connect',
    query: { token: 'Bearer ccc4d35706c9bab63815f38bf78e4a22246bcedc' },
    // query: { token: 'Bearer ' + id },
    transports: ['websocket'],
  });
}
export function socketGpsUpdateConnect(id) {
  console.log('her', id);
  return io(backendSocketConfig.appUrl, {
    path: '/api/v1/sockets-gps-update/connect',
    query: { token: 'Bearer ccc4d35706c9bab63815f38bf78e4a22246bcedc' },
    // query: { token: 'Bearer ' + id },
    transports: ['websocket'],
  });
}

export function getCustomerAddresses(customerId) {
  return axios.get(`${MICROSERVICE_BASE_URL.CUSTOMER}/${customerId}/address`);
}
export function editCustomerAddresses(customerId, addressId, body) {
  return axios.put(
    `${MICROSERVICE_BASE_URL.CUSTOMER}/${customerId}/address/${addressId}`,
    body,
  );
}
export function getDispatchingStatus() {
  return axios.get(`${MICROSERVICE_BASE_URL.DISPATCHING}/dispatching-state`);
}
export function getDeliveryManDispatchedById(id) {
  return axios.get(`${MICROSERVICE_BASE_URL.DISPATCHING}//delivery-man/${id}`);
}
export function getOrderRating(skip, limit) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.ORDERS}/order-rating?page=${skip || 0}&limit=${limit || 0
    }&paged=true`,
  );
}
export function addCommentInOrderRating(orderId, payload) {
  return axios.put(
    `${MICROSERVICE_BASE_URL.ORDERS}/order-rating/valid/${orderId}`,
    payload,
  );
}
export function getAlarms(skip, limit, deliveryManId, shortId, name) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.DISPATCHING}/alarm/get_alarms?page=${skip || 0
    }&limit=${limit || 0}&paged=true&deliveryManId=${deliveryManId || ''
    }&name=${name || ''}&shortId=${shortId || ''}`,
  );
}
export function updateAlarm(alarmId, data) {
  return axios.put(
    `${MICROSERVICE_BASE_URL.DISPATCHING}/alarm/${alarmId}`,
    data,
  );
}
export function getAlarmConfig() {
  return axios.get(`${MICROSERVICE_BASE_URL.DISPATCHING}/alarm-config`);
}

export function updateAlarmConfig(data) {
  return axios.put(`${MICROSERVICE_BASE_URL.DISPATCHING}/alarm-config`, data);
}
export function citiesPlaningByDay(day) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.DELIVERY_MAN}/workingcalendar/citiesplanning?day=${day}`,
  );
}
// for logger audit
export function logOrder(body) {
  return axios.post(`${MICROSERVICE_BASE_URL.ADMIN}/logger`, { ...body });
}

// for non qualities :

// get data for none qualities :
export function getTemplateNonQualities(page, limit, paged) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.ADMIN}/template-non-quality?page=${page || 0
    }&limit=${limit || 0}&paged=${paged || false}`,
  );
}
// get single template by ID :
export function getSingleTemplate(id, limit, paged) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.ADMIN}/template-non-quality?limit=${limit || 0
    }&templateId=${id}&paged=${paged || false}`,
  );
}

// post new non quality :
export function createNonQuality(body) {
  return axios.post(`${MICROSERVICE_BASE_URL.ADMIN}/non-quality`, { ...body });
}

// edit on customer kaalixUP :
export function editKaalixUp(body) {
  return axios.post(`${MICROSERVICE_BASE_URL.CUSTOMER_PAYMENT}/kaalixup`, {
    ...body,
  });
}

// edit on delivryMan balance :
export function createTransferSalaryFromQuality(data) {
  return axios.post(
    `${MICROSERVICE_BASE_URL.DELIVERY_MAN_PAYMENT}/money-transfer/create_salary_transfer_from_quality`,
    data,
  );
}
export function getStores(searchText) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.STORES
    }/store?page=0&limit=50&paged=true&searchText=${searchText || ''}`,
  );
}
export function getOrders(storeId, startDate, endDate) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.ORDERS}/order/completedByStore?storeId=${storeId || ''
    }&startDate=${startDate || ''}&endDate=${endDate || ''}`,
  );
}
export function refreshRedis() {
  return axios.get(`${MICROSERVICE_BASE_URL.DISPATCHING}/redis/refresh`);
}

export function getAlarmsCurrentOrders(cities) {
  return axios.post(
    `${MICROSERVICE_BASE_URL.DISPATCHING}/alarm/alarms_current_orders`,
    { cities: cities },
  );
}
export function recreateOrder(id) {
  return axios.post(
    `${MICROSERVICE_BASE_URL.PROXY}/order/recreate/${id}`,
  );
}
