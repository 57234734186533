import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import {
  useFormik,
  ErrorMessage,
  Field,
  FieldArray,
  Form,
  Formik,
} from 'formik';
import { useAlert } from 'react-alert';
import InputLabel from '@material-ui/core/InputLabel';
import { Box, TextField } from '@material-ui/core';
// import { Autocomplete } from '@material-ui/lab'
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormHelperText from '@material-ui/core/FormHelperText';
import GridContainer from '../../../components/material-dashboard-pro-react/Grid/GridContainer';
import Card from '../../../components/material-dashboard-pro-react/Card/Card';
import CardHeader from '../../../components/material-dashboard-pro-react/Card/CardHeader';
import CardBody from '../../../components/material-dashboard-pro-react/Card/CardBody';

// material-dashboard-pro-react/Grid/GridContainer

// import PropTypes from 'prop-types';
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import CustomInput from '../../../components/material-dashboard-pro-react/CustomField/CustomField';
import CustomSelect from '../../../components/material-dashboard-pro-react/CustomSelect/CustomSelect';
import ImageUpload from '../../../components/material-dashboard-pro-react/CustomUpload/CustomMultiUpload';
import {
  createPublicity,
  getCities,
  getServiceCategories,
  getStores,
  getStoresBySearch,
} from '../services/serverApi.service';
// import SelectBoxComponent from '../../../components/SelectBox.component';
// import { serviceTypes } from '../mocks';
// import { uploadMediaApi } from '../../../services/serverApi.service';
import { uploadImageResetAction } from '../../../redux/uploadRedux/upload.action';

const validationSchema = yup.object({
  name: yup.string().required('name is a required field'),
  description: yup.string().required('description is a required field'),
  ExternalLink: yup.string(),
  assignedTo: yup.string().required('assignedTo is a required field'),
  serviceId: yup.string().when('assignedTo', {
    is: (value) => value && value == 'service',
    then: yup
      .string()
      .required('service is required when choise service as assigned'),
    otherwise: yup.string().nullable(),
  }),
  cityId: yup.string().when('assignedTo', {
    is: (value) => value && value == 'city',
    then: yup
      .string()
      .required('city is required when choise city as assigned'),
    otherwise: yup.string(),
  }),
  format: yup.string().required('format is a required field'),
  publicitySubGroups: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string().required('required'),
        description: yup.string().required('required'),
        position: yup
          .number()
          .typeError('position is number')
          .required('required'),
        pubs: yup
          .array()
          .of(
            yup
              .object()
              .shape({
                name: yup.string().required('required'),
                description: yup.string().required('required'),
                type: yup.string().required('required'),
                storeId: yup.string().when('type', {
                  is: (value) => value == 'Store',
                  then: yup
                    .string()
                    .required(
                      'store is required when choise store as pub type',
                    ),
                  otherwise: yup.string(),
                }),
                externalLink: yup.string().when('type', {
                  is: (value) => value == 'ExternalLink',
                  then: yup
                    .string()
                    .required(
                      'ExternalLinkis required when choise ExternalLink as type',
                    ),
                  otherwise: yup.string(),
                }),
              })
              .required(),
          )
          .required(),
      }),
    )
    .required(),
});

export default function CreatePublicityComponent() {
  const history = useHistory();
  const formRef = React.useRef();
  const formValues = formRef.current?.values;
  console.log(formValues?.publicitySubGroups);
  const upload = useSelector((state) => state.multiUploadReducer);
  const { multiIconUrl, error, loading, newUpload } = upload;
  // const initialState = { default: 'defaule message', }
  const [errorsImage, setErrors] = useState({});
  const [text, setText] = useState('a');

  const setToFromValues = (key, value) =>
    formRef.current.setFieldValue(key, value);

  const iconUrls = ['image1.jpg'];
  const initialValues = {
    name: '',
    description: '',
    ExternalLink: '',
    assignedTo: '',
    serviceId: null,
    cityId: '',
    format: '',
  };

  const alert = useAlert();
  const [cities, setCities] = useState([]);
  const [stores, setStores] = useState([]);
  const [serviceCategories, setServiceCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const assignedTo = ['city', 'service'];
  const format = ['Banner', 'Tabs'];
  const PubType = ['Store', 'ExternalLink', 'NONE'];

  useEffect(() => {
    getCities()
      .then(({ data }) => {
        setCities(data);
      })
      .catch((error) => {
        alert.show(error.message, {
          type: 'error',
        });
      });
    // getStoresBySearch(0,5, true,text)
    //     .then(({ data: { stores: storeData } }) => {
    //         setStores(storeData);
    //     })
    //     .catch((error) => {
    //         alert.show(error.message, {
    //             type: 'error',
    //         });
    //     });

    getServiceCategories()
      .then(({ data }) => {
        setServiceCategories(data);
      })
      .catch((error) => {
        alert.show(error.message, {
          type: 'error',
        });
      });
  }, []);
  useEffect(() => {
    getStoresBySearch(0, 50, true, text)
      .then(({ data }) => {
        // { data: { stores: storeData } }
        console.log(data.stores);
        setStores(data.stores);
      })
      .catch((error) => {
        // alert.show(error.message, {
        //   type: 'error',
        // });
      });
  }, [text]);
  // formRef.current?.errors
  const submitForm = (values) => {
    console.log(values);
    console.log(values.publicitySubGroups[0]);
    // console.log(formValues)
    // console.log('formValues')
    // setErrors({})
    values.publicitySubGroups.map((parentItem, index) =>
      parentItem.pubs.map(
        (childItem, i) => (
          // console.log(childItem)
          (childItem.imageUrl = multiIconUrl[`${index}-${i}`]),
          (!childItem.imageUrl ||
            childItem.imageUrl == '' ||
            childItem.imageUrl == undefined) &&
            setErrors({
              ...errorsImage,
              [`${index}-${i}`]: 'image not uploaded correctly',
            })
        ),
      ),
    );

    Object.keys(errorsImage).length == 0 && setIsLoading(true);
    createPublicity(values)
      .then(
        (data) => console.log(data),
        history.push('/admin/delivery-info/publicity'),
      )
      .catch((error) => {
        alert.show(error.message, {
          type: 'error',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });

    // console.log(values)
    console.log(JSON.stringify(values));
  };
  // console.log('stores',stores[0].name);
  return (
    <GridContainer>
      <Card>
        <CardHeader>Create Publicity</CardHeader>
        <CardBody>
          <Formik
            innerRef={formRef}
            initialValues={initialValues}
            validationSchema={validationSchema}
            // onSubmit={values => submitForm(values)}
            render={({
              values,
              isValid,
              errors,
              dirty,
              touched,
              setFieldValue,
            }) => (
              <>
                <Form>
                  <CustomInput
                    labelText="Name"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    type="text"
                    name="name"
                    helperText={<ErrorMessage name="name" />}
                  />
                  <CustomInput
                    labelText="Description"
                    name="description"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    type="text"
                    helperText={<ErrorMessage name="description" />}
                  />
                  <CustomInput
                    labelText="External Link"
                    name="ExternalLink"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    type="text"
                    helperText={<ErrorMessage name="ExternalLink" />}
                  />
                  <CustomSelect
                    name="assignedTo"
                    items={assignedTo}
                    label="assignedTo"
                    helperText={<ErrorMessage name="assignedTo" />}
                  />
                  <CustomSelect
                    name="serviceId"
                    items={serviceCategories}
                    label="Service"
                  />
                  {(errors.serviceId || touched.assignedTo) && (
                    <FormHelperText style={{ color: 'red' }}>
                      {errors.serviceId}
                    </FormHelperText>
                  )}

                  <CustomSelect
                    name="cityId"
                    items={cities}
                    label="city "
                    //     helperText={<ErrorMessage name='cityId' />
                    // }
                  />
                  {(errors.cityId || touched.assignedTo) && (
                    <FormHelperText style={{ color: 'red' }}>
                      {errors.cityId}
                    </FormHelperText>
                  )}

                  <CustomSelect
                    name="format"
                    items={format}
                    label="Format"
                    helperText={<ErrorMessage name="format" />}
                  />

                  <FieldArray
                    name="publicitySubGroups"
                    render={(arrayHelpers) => {
                      const publicitySubGroups = values.publicitySubGroups;
                      return (
                        <div>
                          {publicitySubGroups && publicitySubGroups.length > 0
                            ? publicitySubGroups.map(
                                (publicitySubGroup, index) => (
                                  <>
                                    <InputLabel style={{ marginTop: '10px' }}>
                                      publicity Sub Groups: {index + 1}{' '}
                                    </InputLabel>
                                    <div
                                      key={index}
                                      style={{
                                        border: '1.3px solid #000',
                                        borderRadius: '10px',
                                        padding: '15px',
                                        marginTop: '15px',
                                      }}
                                    >
                                      <CustomInput
                                        labelText="Pub Group Name"
                                        name={`publicitySubGroups.${index}.name`}
                                        formControlProps={{
                                          fullWidth: true,
                                        }}
                                        type="text"
                                        helperText={
                                          <ErrorMessage
                                            name={`publicitySubGroups.${index}.name`}
                                          />
                                        }
                                        value="aaaa"
                                      />
                                      <CustomInput
                                        labelText="Pub Group Description"
                                        name={`publicitySubGroups.${index}.description`}
                                        formControlProps={{
                                          fullWidth: true,
                                        }}
                                        type="text"
                                        helperText={
                                          <ErrorMessage
                                            name={`publicitySubGroups.${index}.description`}
                                          />
                                        }
                                      />
                                      <CustomInput
                                        labelText="Position"
                                        name={`publicitySubGroups.${index}.position`}
                                        formControlProps={{
                                          fullWidth: true,
                                        }}
                                        type="text"
                                        helperText={
                                          <ErrorMessage
                                            name={`publicitySubGroups.${index}.position`}
                                          />
                                        }
                                      />

                                      <FieldArray
                                        name={`publicitySubGroups.${index}.pubs`}
                                        render={(arrayHelpersForPubs) => {
                                          const Pubs =
                                            values.publicitySubGroups[index]
                                              .pubs;

                                          return (
                                            <>
                                              {Pubs && Pubs.length > 0 && (
                                                <div
                                                  style={{
                                                    display: 'flex',
                                                    overflowX: 'auto',
                                                  }}
                                                >
                                                  {Pubs && Pubs.length > 0
                                                    ? Pubs.map((pub, i) => (
                                                        <div
                                                          style={{
                                                            flex: '0 0 30%',
                                                            border:
                                                              '1px solid #000',
                                                            borderRadius:
                                                              '10px',
                                                            padding: ' 0 15px',
                                                            margin: '10px 5px',
                                                          }}
                                                        >
                                                          <InputLabel
                                                            style={{
                                                              marginTop: '10px',
                                                            }}
                                                          >
                                                            Pub :{i + 1}{' '}
                                                          </InputLabel>

                                                          <Box
                                                            display="flex"
                                                            justifyContent="center"
                                                          >
                                                            <ImageUpload
                                                              label="Select icon"
                                                              folder="service"
                                                              index={`${index}-${i}`}
                                                              errorMessage={
                                                                errorsImage[
                                                                  `${index}-${i}`
                                                                ]
                                                              }
                                                              avatar
                                                            />
                                                          </Box>

                                                          <CustomInput
                                                            labelText="pub Name"
                                                            name={`publicitySubGroups.${index}.pubs.${i}.name`}
                                                            formControlProps={{
                                                              fullWidth: true,
                                                            }}
                                                            type="text"
                                                            helperText={
                                                              <ErrorMessage
                                                                name={`publicitySubGroups.${index}.pubs.${i}.name`}
                                                              />
                                                            }
                                                          />
                                                          <CustomInput
                                                            labelText="Pub Description"
                                                            name={`publicitySubGroups.${index}.pubs.${i}.description`}
                                                            formControlProps={{
                                                              fullWidth: true,
                                                            }}
                                                            type="text"
                                                            helperText={
                                                              <ErrorMessage
                                                                name={`publicitySubGroups.${index}.pubs.${i}.description`}
                                                              />
                                                            }
                                                          />

                                                          <CustomSelect
                                                            name={`publicitySubGroups.${index}.pubs.${i}.type`}
                                                            items={PubType}
                                                            label="Pub Type"
                                                            helperText={
                                                              <ErrorMessage
                                                                name={`publicitySubGroups.${index}.pubs.${i}.type`}
                                                              />
                                                            }
                                                          />
                                                          {/* <CustomSelect
                                                                                                        name={`publicitySubGroups.${index}.pubs.${i}.storeId`}
                                                                                                        items={stores}
                                                                                                        label="Store"
                                                                                                        helperText={<ErrorMessage name={`publicitySubGroups.${index}.pubs.${i}.storeId`} />}
                                                                                                    /> */}
                                                          <Autocomplete
                                                            id="combo-box-demo"
                                                            options={stores}
                                                            defaultValue={[
                                                              stores[3],
                                                            ]}
                                                            getOptionLabel={(
                                                              option,
                                                            ) =>
                                                              option.name +
                                                              ' ' +
                                                              option.email
                                                            }
                                                            style={{
                                                              width: 300,
                                                            }}
                                                            onChange={(
                                                              event,
                                                              newValue,
                                                            ) => {
                                                              newValue &&
                                                                setFieldValue(
                                                                  `publicitySubGroups.${index}.pubs.${i}.storeId`,
                                                                  newValue._id,
                                                                );
                                                            }}
                                                            renderInput={(
                                                              stores,
                                                            ) => (
                                                              <TextField
                                                                {...stores}
                                                                label="Combo box"
                                                                variant="outlined"
                                                                onChange={(e) =>
                                                                  setText(
                                                                    e.target
                                                                      .value,
                                                                  )
                                                                }
                                                              />
                                                            )}
                                                          />
                                                          <CustomInput
                                                            labelText="Pub External Link"
                                                            name={`publicitySubGroups.${index}.pubs.${i}.externalLink`}
                                                            formControlProps={{
                                                              fullWidth: true,
                                                            }}
                                                            type="text"
                                                            helperText={
                                                              <ErrorMessage
                                                                name={`publicitySubGroups.${index}.pubs.${i}.externalLink`}
                                                              />
                                                            }
                                                          />

                                                          <Box textAlign="right">
                                                            <Button
                                                              size="sm"
                                                              color="danger"
                                                              loaderColor="white"
                                                              onClick={() =>
                                                                arrayHelpersForPubs.remove(
                                                                  i,
                                                                  1,
                                                                )
                                                              }
                                                            >
                                                              delete Pub
                                                            </Button>
                                                          </Box>
                                                        </div>
                                                      ))
                                                    : null}
                                                </div>
                                              )}
                                              <Box textAlign="left">
                                                <Button
                                                  size="sm"
                                                  color="google"
                                                  loaderColor="white"
                                                  onClick={
                                                    () =>
                                                      // isValid &&
                                                      arrayHelpersForPubs.push(
                                                        {},
                                                      )
                                                    // console.log(arrayHelpers[index].Pubs)
                                                  }
                                                >
                                                  Add Pub
                                                </Button>
                                              </Box>
                                              <Box textAlign="right">
                                                <Button
                                                  size="sm"
                                                  color="danger"
                                                  loaderColor="white"
                                                  onClick={() =>
                                                    arrayHelpers.remove(index)
                                                  }
                                                >
                                                  delete Pub Group
                                                </Button>
                                              </Box>
                                            </>
                                          );
                                        }}
                                      />
                                    </div>
                                  </>
                                ),
                              )
                            : null}

                          <Box textAlign="left">
                            <Button
                              size="sm"
                              color="primary"
                              loaderColor="white"
                              onClick={() =>
                                // isValid &&
                                arrayHelpers.push({})
                              }
                            >
                              Add Pub Group
                            </Button>
                          </Box>
                        </div>
                      );
                    }}
                  />

                  <Box textAlign="right">
                    <Button
                      round
                      type="submit"
                      color="success"
                      disabled={
                        !formRef?.current?.dirty ||
                        isLoading ||
                        !formRef?.current?.isValid
                          ? true
                          : false
                      }
                      loading={isLoading}
                      loaderColor="white"
                      onClick={() => submitForm(values)}
                    >
                      Add Pub Group
                    </Button>
                  </Box>
                </Form>
              </>
            )}
          />
        </CardBody>
      </Card>
    </GridContainer>
  );
}
