import React, { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { useFormik } from 'formik';
import * as yup from 'yup';
import CustomInput from '../../../components/material-dashboard-pro-react/CustomInput/CustomInput';
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import { Box } from '@mui/material';
import { updateSetting } from '../services/serverApi.service';

const validationSchema = yup.object({
  deliveryManMeanSpeed: yup.number().required(),
  timeToArriveToStore: yup.number().required(),
});

export default function StorePreparationTimeComponent({ setting }) {
  const alert = useAlert();
  const [isLoading, setIsLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      deliveryManMeanSpeed: '',
      timeToArriveToStore: '',
    },
    validationSchema,
  });
  const saveSetting = () => {
    const { deliveryManMeanSpeed, timeToArriveToStore } = formik.values;
    const body = setting[0];
    body.deliveryManMeanSpeed = deliveryManMeanSpeed;
    body.timeToArriveToStore = timeToArriveToStore;
    updateSetting(body._id, body)
      .then(({ data }) => {
        alert.show('saved', {
          type: 'success',
        });
      })
      .catch((error) => console.log(error));
  };
  const setValues = () => {
    formik.setValues({
      deliveryManMeanSpeed: setting[0]?.deliveryManMeanSpeed,
      timeToArriveToStore: setting[0]?.timeToArriveToStore,
    });
  };
  useEffect(() => {
    setting && setting.length > 0 && setValues();
  }, []);
  return (
    <div>
      <CustomInput
        labelText="deliveryManMeanSpeed "
        formControlProps={{
          fullWidth: true,
        }}
        type="number"
        error={
          formik.touched.deliveryManMeanSpeed &&
          Boolean(formik.errors.deliveryManMeanSpeed)
        }
        inputProps={{
          value: formik.values.deliveryManMeanSpeed,
          onBlur: formik.handleBlur('deliveryManMeanSpeed'),
          onChange: formik.handleChange('deliveryManMeanSpeed'),
        }}
      />
      <CustomInput
        labelText="timeToArriveToStore"
        formControlProps={{
          fullWidth: true,
        }}
        type="number"
        error={
          formik.touched.timeToArriveToStore &&
          Boolean(formik.errors.timeToArriveToStore)
        }
        inputProps={{
          value: formik.values.timeToArriveToStore,
          onBlur: formik.handleBlur('timeToArriveToStore'),
          onChange: formik.handleChange('timeToArriveToStore'),
        }}
      />
      <Box textAlign="right">
        <Button
          round
          type="submit"
          color="success"
          disabled={isLoading}
          loading={isLoading}
          loaderColor="white"
          onClick={saveSetting}
        >
          save
        </Button>
      </Box>
    </div>
  );
}
