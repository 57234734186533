import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import { FormLabel, Box } from '@material-ui/core';
import SelectBoxComponent from '../../../components/SelectBox.component';
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import SwitchComponent from '../../../components/Switch.component';
import { updateStore } from '../services/serverApi.service';
import { ClassTypes } from '../mocks/index';
import styles from '../../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle';

const useStyles = makeStyles(styles);

const validationSchema = yup.object({});

export default function StoreOpningComponent({
  store,
  index,
  stores,
  setStores,
  close,
  textList,
}) {
  console.log(textList);
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();
  const formik = useFormik({
    initialValues: {
      opnin: false,
      opningTextList: [],
    },
    validationSchema,
  });
  const editStore = () => {
    store.opning = formik.values.opning;
    store.opningTextList = formik.values.opningTextList;
    setIsLoading(true);
    updateStore(store._id, store)
      .then(({ data }) => {
        let newStores = [...stores];
        newStores[index] = data.store;
        setStores(newStores);
        close();
      })
      .catch((error) => {
        console.log(error.message);
      })
      .finally(() => setIsLoading(true));
  };
  const setValues = () => {
    formik.setValues({
      opning: store.opning || false,
      opningTextList: store?.opningTextList || [],
    });
  };

  useEffect(() => {
    setValues();
  }, []);
  return (
    <div>
      <SwitchComponent
        style={{ marginLeft: 15 }}
        onChange={(value) => formik.setFieldValue('opning', value)}
        checked={formik.values.opning ? true : false}
        error={formik.touched.opning && Boolean(formik.errors.opning)}
        label="creneaux"
      />
      <SelectBoxComponent
        items={textList.map((type) => ({
          value: type.text,
          label: type.text,
        }))}
        onValueChange={(items) => {
          formik.setFieldValue('opningTextList', items);
        }}
        value={formik.values.opningTextList}
        multiple
      />
      <Box textAlign="center">
        <Button
          round
          type="submit"
          color="success"
          disabled={!formik.dirty || !formik.isValid || isLoading}
          loading={isLoading}
          loaderColor="white"
          onClick={editStore}
        >
          save
        </Button>
      </Box>
    </div>
  );
}
