export const virementListTypes = [
  'none',
  'TRANSFER_COMMISSION',
  'TRANSFERT_SALARY',
  'TRANSFERT_BALANCE',
  'TRANSFERT_BALANCE_TO_ZERO',
  'TRANSFERT_CASH_OUT',
  'REGULARISATION_BALANCE',
  'QUALITY_BALANCE',
];
export const balanceListTypes = [
  'none',
  'PAID_TO_STORE',
  'CASH_PAID_BY_CUSTOMER',
  'TRANSFERT_BALANCE',
  'TRANSFERT_BALANCE_TO_ZERO',
  'TRANSFERT_CASH_OUT',
  'ADJUSTEMENT_BALANCE',
  'REGULARISATION_BALANCE',
];
export const commissionListTypes = [
  'none',
  'ORDER_COMMISSION',
  'BONUS_COMMISSION',
  'ADJUSTEMENT_COMMISSION',
  'COMMISSION_DELIVERY_PARTNER'
];

export const filtersProviders = {
  All: 'All',
  dispatchable: 'dispatchable',
  Ndispatchable: 'Ndispatchable',
  planified: 'planified',
  Nplanified: 'Ndplanified',
  available: 'available',
  Navailable: 'Navailable',
  GPSOn: 'GPSOn',
  GPSOff: 'GPSOff',
  AppActive: 'AppActive',
  AppBackgound: 'AppBackgound',
  AppInactive: 'AppInactive',
};
export const deliveryManTypes = [
  {
    value: 'Food',
    label: 'Food',
  },
  {
    value: 'Partners',
    label: 'Partners',
  },
  {
    value: 'Hybrid',
    label: 'Hybrid',
  },
  {
    value: 'allPartners',
    label: 'allPartners',
  },
  {
    value: 'Driver',
    label: 'Driver',
  },
]

