import axios from 'axios';
import { MICROSERVICE_BASE_URL } from '../../../config/backendServer.config';

export function getApprouvedProvider(
  page,
  limit,
  cityId,
  txt,
  deliveryType = '',
) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.DELIVERY_MAN}/deliveryMan?page=${page || 0
    }&limit=${limit || 5}&paged=true&approved=true&cityId=${cityId || ''
    }&search_text=${txt || ''}&delivery_type=${JSON.stringify(
      deliveryType || '',
    )}`,
  );
}
export function getProviderDetails(page, limit, cityId, txt) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.DELIVERY_MAN_PAYMENT}/delivery-man?page=${page || 0
    }&limit=${limit || 5}&paged=true&approved=true&cityId=${cityId || ''
    }&search_text=${txt || ''}`,
  );
}
export function getDeliveryMenDetails(page, limit, cityId, approved, txt) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.DELIVERY_MAN_PAYMENT}/delivery-man?page=${page || 0
    }&limit=${limit || 5}&paged=true&approved=${approved}&cityId=${cityId || ''
    }&search_text=${txt || ''}`,
  );
}
export function getUnapprouvedProvider(page, limit, txt, cityId, deliveryType = '',) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.DELIVERY_MAN}/deliveryMan?page=${page || 0
    }&limit=${limit || 5}&paged=true&approved=false&search_text=${txt || ''
    }&cityId=${cityId || ''}&delivery_type=${JSON.stringify(
      deliveryType || '',
    )}`,
  );
}
export function addProvider(payload) {
  return axios.post(`${MICROSERVICE_BASE_URL.AUTH_SERV}/auth/register`, {
    ...payload,
  });
}
export function createDeliveryMan(payload) {
  return axios.post(`${MICROSERVICE_BASE_URL.AUTH_SERV}/auth/register-deliveryMan`, {
    ...payload,
  });
}
export function setProvider(id, payload, type) {
  return axios.put(`${MICROSERVICE_BASE_URL.PROVIDER}/${id}/${type}`, {
    ...payload,
  });
}
export function editProvider(id, payload) {
  return axios.put(`${MICROSERVICE_BASE_URL.DELIVERY_MAN}/${id}/available`, {
    ...payload,
  });
}
export function setCityToProvider(id, cityId) {
  return axios.put(`${MICROSERVICE_BASE_URL.PROVIDER}/${id}/cityId/${cityId}`);
}
export function getBalance(page, limit, deliveryManId, type) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.DELIVERY_MAN_PAYMENT}/balance?page=${page || 0
    }&limit=${limit || 20}&paged=true${deliveryManId && `&deliveryManId=${deliveryManId}`
    }&type=${type}`,
  );
}
export function createMoneyTransfer(data) {
  return axios.post(
    `${MICROSERVICE_BASE_URL.DELIVERY_MAN_PAYMENT}/money-transfer/`,
    data,
  );
}
export function createTransferBalance(data) {
  return axios.post(
    `${MICROSERVICE_BASE_URL.DELIVERY_MAN_PAYMENT}/money-transfer/create_balance_transfer`,
    data,
  );
}
export function createTransferSalaryGeneral(data) {
  return axios.post(
    `${MICROSERVICE_BASE_URL.DELIVERY_MAN_PAYMENT}/money-transfer/create_salary_transfer`,
    data,
  );
}
export function getMoneyTransfer(page, limit, paged, deliveryManId, type) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.DELIVERY_MAN_PAYMENT}/money-transfer?page=${page || 0
    }&limit=${limit || 20}&paged=${paged || true}&deliveryManId=${deliveryManId || ''
    }&type=${type || ''}`,
  );
}

export function getTips(page, limit, paged, deliveryManId, type) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.DELIVERY_MAN_PAYMENT}/tip/?page=${page || 0
    }&limit=${limit || 20}&paged=${paged || true}&deliveryManId=${deliveryManId || ''
    }&type=${type || ''}`,
  );
}
export function validerMoneyTransfer(data) {
  return axios.post(
    `${MICROSERVICE_BASE_URL.DELIVERY_MAN_PAYMENT}/money-transfer/valider`,
    data,
  );
}
export function validerTips(data) {
  return axios.put(
    `${MICROSERVICE_BASE_URL.DELIVERY_MAN_PAYMENT}/tip/valider`,
    data,
  );
}

export function getWorkingCalendar(cityId, day) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.DELIVERY_MAN}/workingCalendar/get_working_calendar?cityId=${cityId}&day=${day}`,
  );
}

export function updateWorkingCalendar(id, data) {
  return axios.put(
    `${MICROSERVICE_BASE_URL.DELIVERY_MAN}/workingCalendar/${id}`,
    data,
  );
}

export function pastWorkingCalendar(data) {
  return axios.post(
    `${MICROSERVICE_BASE_URL.DELIVERY_MAN}/workingCalendar/planning/past`,
    data,
  );
}

export function insertWorkingCalendar(data) {
  return axios.post(
    `${MICROSERVICE_BASE_URL.DELIVERY_MAN}/workingCalendar`,
    data,
  );
}
export function getOrderById(id) {
  return axios.get(`${MICROSERVICE_BASE_URL.DELIVERY_MAN_PAYMENT}/order/${id}`);
}
export function getCommissionHistory(page, limit, paged, txt, type) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.DELIVERY_MAN_PAYMENT}/commission?page=${page || 0
    }&limit=${limit || 20}&paged=${paged || true}${txt && `&deliveryManId=${txt}`
    }&type=${type}`,
  );
}
export function getDeliveryManInCities(cities) {
  return axios.post(
    `${MICROSERVICE_BASE_URL.DISPATCHING}/delivery-man/cities`,
    { cities: cities },
  );
}
export function getlocationsHistoryForDeliverMan(
  page,
  limit,
  paged,
  deliveryManId,
  date,
) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.DELIVERY_MAN}/${deliveryManId}/locations?page=${page || 0
    }&limit=${limit || 20}&paged=${paged || true}&date=${date}`,
  );
}
export function getlocationsHistoryForDeliverManApi(
  page,
  limit,
  paged,
  deliveryManId,
) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.DELIVERY_MAN}/${deliveryManId}/locations?page=${page || 0
    }&limit=${limit || 20}&paged=${paged || true}`,
  );
}
// for logger audit
export function logProvider(body) {
  return axios.post(`${MICROSERVICE_BASE_URL.ADMIN}/logger`, { ...body });
}
export function getTransportations(body) {
  return axios.get(`${MICROSERVICE_BASE_URL.DRIVER}/transportations`);
}
export function setGPSOn(id, payload) {
  return axios.put(`${MICROSERVICE_BASE_URL.PROVIDER}/${id}/updateGpsBatteryStatus`, {
    ...payload,
  });
}

