import React from 'react';
import { Grid, Typography } from '@mui/material';

export default function DeliveryHeaderInfoComponenet({ order }) {
  return (
    <div>
      <Grid container className="mb-2">
        <Grid item xs={6} style={{ display: 'flex' }}>
          orderId : <Typography>{order?.shortId}</Typography>
        </Grid>
        <Grid item xs={6} style={{ display: 'flex' }}>
          store : <Typography>{order?.store?.name}</Typography>
        </Grid>
        <Grid item xs={6} style={{ display: 'flex' }}>
          customer : <Typography>{order?.customer?.name}</Typography>
        </Grid>
        <Grid item xs={6} style={{ display: 'flex' }}>
          deliveryMan : <Typography>{order?.deliveryMan?.name}</Typography>
        </Grid>
      </Grid>
    </div>
  );
}
