import React, { useEffect, useState } from 'react';
import styles from '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
} from '@chatscope/chat-ui-kit-react';
import { addComment } from '../services/server.api.service';
import moment from 'moment';
import { useSelector } from 'react-redux';

import { getUserDataState } from '../../auth/redux/selectors';
import {
  getPartnerDeliveriesDataState,
  seetingSelectore,
} from '../redux/selectors';

export default function CommentDeliveryComponent({ deliveryId, close }) {
  const { setting } = useSelector(seetingSelectore);

  const userData = useSelector(getUserDataState);
  const { deliveries } = useSelector(getPartnerDeliveriesDataState);
  const [delivery, setDelivery] = useState({});
  const [admin, setAdmin] = useState({
    _id: userData.user._id,
    email: userData.user.email,
    userName: userData.user.username,
  });
  const [selectedReason, setSelectedReason] = useState('');

  const sendMesage = (message) => {
    addComment(deliveryId, {
      comment: message,
      adminName: admin.userName,
      adminId: admin._id,
    })
      .then(({ data }) => {
        close();
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    setDelivery((deliveries || []).find((x) => x._id == deliveryId));
  }, []);

  const handleSelectChange = (event) => {
    setSelectedReason(event.target.value);
    sendMesage(event.target.value);
  };

  return (
    <div>
      <div style={{ marginBottom: '10px' }}>
        <select
          value={selectedReason}
          onChange={handleSelectChange}
          style={{
            width: '200px',
            padding: '8px 16px',
            border: '2px solid #009688',
            borderRadius: '8px',
            backgroundColor: 'white',
            color: '#333',
            fontSize: '14px',
            cursor: 'pointer',
            outline: 'none',
            WebkitAppearance: 'none',
            MozAppearance: 'none',
            appearance: 'none',
          }}
        >
          <option value="">Select a comment</option>
          {(setting[0]?.comments || []).map((reason, index) => (
            <option key={index} value={reason}>
              {reason}
            </option>
          ))}
        </select>
      </div>

      <MainContainer>
        <ChatContainer>
          <MessageList>
            {(delivery?.comments || []).map((comment) => (
              <Message
                key={JSON.stringify(comment.date)}
                model={{
                  message: comment.comment,
                  sentTime: moment(comment.date).format('hh:mm'),
                  direction:
                    comment.adminId == admin._id ? 'outgoing' : 'inComming',
                }}
              >
                <Message.Footer
                  sender={comment?.adminName}
                  sentTime={moment(comment.date).format('hh:mm')}
                />
              </Message>
            ))}
          </MessageList>
          <MessageInput
            placeholder="Type comment here"
            attachButton={false}
            value={selectedReason}
            onChange={(value) => setSelectedReason(value)}
            onSend={sendMesage}
          />
        </ChatContainer>
      </MainContainer>
    </div>
  );
}
