import React, { useEffect, useState } from 'react';
import { getCronConfig, editCronConfig } from '../services/serverApi.service';
import SwitchComponent from '../../../components/Switch.component';

export default function CronConfigComponenet() {
  const [cronConfig, setCronConfig] = useState({});

  const switchItems = [
    'dispatchingCron',
    'cron_qualification',
    'alarmCron',
    'partnersDispatchingCron',
    'dispatchingStateCron',
    'deliveryManDispatchabilityCron',
    'syncTimePassCron',
    'itemsSyncDataCron',
  ];
  const switchItemsTime = [
    'timeDispatchingCron',
    'timeAlarmCron',
    'timePartnersDispatchingCron',
    'timeDispatchingStateCron',
    'timeDeliveryManDispatchabilityCron',
    'timeSyncTimePassCron',
  ];
  const editCron = (key, value) => {
    const tempCron = { ...cronConfig };
    tempCron[key] = value;
    editCronConfig(tempCron)
      .then(({ data }) => {
        setCronConfig(data.cronSetting);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getCronConfig()
      .then(({ data }) => setCronConfig(data?.cronSetting))
      .catch((err) => console.log(err));
  }, []);
  return (
    <div>
      <div style={{ overflowX: 'scroll' }}>
        {cronConfig && Object.keys(cronConfig).length > 0 && (
          <table className={'table table-bordered'}>
            <tr>
              {(switchItems || []).map((item) => (
                <td>
                  <SwitchComponent
                    key={item}
                    onChange={(value) => editCron(item, value)}
                    value="checked"
                    checked={cronConfig[item]}
                    label={item}
                  />
                </td>
              ))}
            </tr>
            <tr>
              {(switchItemsTime || []).map((item) => (
                <td>
                  <input
                    type="text"
                    placeholder="minute"
                    style={{
                      border: 'none',
                      borderBottom: '1px solid black',
                    }}
                    defaultValue={cronConfig[item]}
                    onChange={(value) => editCron(item, value.target.value)}
                  />
                </td>
              ))}
            </tr>
          </table>
        )}
      </div>
      <div>
        <table className={'table table-bordered'}>
          <tr>
            <td>TIME TO DISPATCH CONFIG</td>
          </tr>
          <tr>
            <td>
              <input
                type="text"
                placeholder="minute"
                style={{
                  border: 'none',
                  borderBottom: '1px solid black',
                }}
                defaultValue={cronConfig['timeToDispatchConfig']}
                onChange={(value) => {
                  setTimeout(() => {
                    if (value.target.value) {
                      editCron('timeToDispatchConfig', value.target.value);
                    }
                  }, 2000);
                }}
              />
            </td>
          </tr>
        </table>
      </div>
    </div>
  );
}
