import React, { useEffect, useState } from 'react';
// material api
import { Button, Box } from '@mui/material';
// component
import SelectBoxComponent from '../../../components/SelectBox.component';
import OrderHeaderInfoComponenet from './Order.header.info.componenet';

// api
import { cancelOrder } from '../services/serverApi.service';

export default function CancelDeliverieComponent({
  orders,
  orderId,
  admin,
  close,
  cancel,
}) {
  const [order, setOrder] = useState(orders.find((x) => x._id == orderId));
  const [cancelRaison, setCancelRaison] = useState({ cancelReason: '' });
  // const [cancelRaison, setCancelRaison] = useState({ cancelReason: 'test' }); // for dev

  const handleCancel = () => {
    cancelOrder(orderId, {
      ...cancelRaison,
      ...admin,
      status: 'CANCELLED',
    })
      .then(({ data }) => {
        close();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div>
      <OrderHeaderInfoComponenet order={order} />
      <Box sx={{ display: 'flex' }} style={{ padding: '15px' }}>
        <SelectBoxComponent
          items={(cancel || []).map((item) => ({
            value: item,
            label: item,
          }))}
          inputLabel="cancel reason"
          onValueChange={(item) => {
            setCancelRaison({ cancelReason: item });
          }}
          value={cancelRaison?.cancelReason}
        />
        <div style={{ float: 'right', margin: '15px' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              handleCancel();
            }}
          >
            save
          </Button>
        </div>
      </Box>
    </div>
  );
}
