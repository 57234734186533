import React from 'react'
import CustomInput from '../../../components/material-dashboard-pro-react/CustomInput/CustomInput'


export default function ClientDetailComponent({order}) {
    return (
        <div>
            {order &&
            <>
                <CustomInput
                    labelText="client name"
                    formControlProps={{
                        fullWidth: true,
                    }}
                    type="text"
                    inputProps={{
                        value: order?.payment?.customer?.name,
                    }}
                    disabled={true}
                />
                <CustomInput
                    labelText="phone"
                    formControlProps={{
                        fullWidth: true,
                    }}
                    type="text"
                    inputProps={{
                        value: order?.payment?.customer?.phone,
                    }}
                    disabled={true}
                />
                <CustomInput
                    labelText="commandes"
                    formControlProps={{
                        fullWidth: true,
                    }}
                    type="text"
                    inputProps={{
                        value: order?.payment?.customer?.nbOrders,
                    }}
                    disabled={true}
                />
                <CustomInput
                    labelText="status"
                    formControlProps={{
                        fullWidth: true,
                    }}
                    type="text"
                    inputProps={{
                        value: order?.payment?.customer?.status,
                    }}
                    disabled={true}
                />
                <CustomInput
                    labelText="kaalixPay"
                    formControlProps={{
                        fullWidth: true,
                    }}
                    type="text"
                    inputProps={{
                        value: order?.payment?.customer?.kaalixPay,
                    }}
                    disabled={true}
                />
                <CustomInput
                    labelText="kaalixUp"
                    formControlProps={{
                        fullWidth: true,
                    }}
                    type="text"
                    inputProps={{
                        value: order?.payment?.customer?.rewardPoints,
                    }}
                    disabled={true}
                />
                <CustomInput
                    labelText="xpPoints"
                    formControlProps={{
                        fullWidth: true,
                    }}
                    type="text"
                    inputProps={{
                        value: order?.payment?.customer?.xpPoints,
                    }}
                    disabled={true}
                />
                </>
            }
            

        </div>
    )
}
