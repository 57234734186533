import React, { useState, useEffect } from 'react';
import { Tab, Box, Tabs } from '@material-ui/core';
import { TabPanel, TabContext, TabList } from '@material-ui/lab';
import UserNotificationComponent from '../components/UserNotification.component';
import UserCancelComponent from '../components/UserCancel.component';
import CommentComponent from '../components/Comment.component';
import StoreCancelComponent from '../components/StoreCancel.component';
import DispatchingCancelComponent from '../components/DispatchingCancel.component';
import KaalixUpConfig from './kaalixUpConfig.page';
import GridContainer from '../../../components/material-dashboard-pro-react/Grid/GridContainer';
import Card from '../../../components/material-dashboard-pro-react/Card/Card';
import CardHeader from '../../../components/material-dashboard-pro-react/Card/CardHeader';
import CardBody from '../../../components/material-dashboard-pro-react/Card/CardBody';

import { fetchSetting } from '../services/serverApi.service';
import RegularisationBalanceReasonsComponent from '../components/RegularisationBalanceReasons.component';
import CronConfigComponenet from '../components/Cron.config.componenet';
import KallixPayTab from '../components/Kallix.pay.tab';
import StorePreparationTimeComponent from '../components/Store.preparation.time.component';
import PartnerSettingComponent from '../components/Partner.setting.component';
import AllPartnerSettingComponent from '../components/AllPartner.setting.component';
import CanCancelComponenet from '../components/Can.cancel.componenet';
import StoreCreneauTextComponent from '../components/StoreCreneauText.component';
import SocketConfigComponent from '../components/socketConfig.component';
import SocketPathTextComponent from '../components/SocketPathText.component';
import RatingListComponent from '../components/RatingList.component';
import RedisConfig from '../components/RedisConfig.component';
import ConfigContent from '../components/ConfigContent.component';
import CategoryTypeListComponent from '../components/CategoryTypeList.component';
import TransListComponent from '../components/TransPortationList.component';
import DriverConfigComponent from '../components/ConfigNearToCustomer.component';
import AppVersionConfigComponent from '../components/ConfigAppVersion.component';
import IntervalConfigComponent from '../components/ConfigSocketInterval.component';
import ConfigDistanceComponent from '../components/ConfigDistance.component';
import ConfigDistanceAllPartnersComponent from '../components/ConfigDistance.allPartners.component';
import ConfigDistancePartnersComponent from '../components/ConfigDistance.partners.component';
import UseRamadanTimingComponent from '../components/useRamadanTiming.page';

export default function AppSettingPage() {
  const [value, setValue] = useState('1');
  const [setting, setSetting] = useState({});

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const fetchSettingFromAPI = () => {
    fetchSetting()
      .then(({ data }) => {
        setSetting(data);
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    fetchSettingFromAPI();
  }, []);
  // console.log('setting',setting);
  return (
    <GridContainer>
      <Card>
        <CardHeader> App setting</CardHeader>

        <CardBody>
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList
                  variant="scrollable"
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab label="User notification params" value="1" />
                  <Tab label="User cancel reasons" value="2" />
                  <Tab label="Store cancel reasons" value="3" />
                  <Tab label="Dispatching cancel reasons" value="4" />
                  <Tab label="kaalix Up Config" value="5" />
                  <Tab label="Regularisation Balance Reasons" value="6" />
                  <Tab label="Config Cron" value="7" />
                  <Tab label="kaalixPay" value="8" />
                  <Tab label="store preparation Time Params" value="9" />
                  <Tab label="partner setting (Marjane)" value="10" />
                  <Tab label="can Cancel config" value="11" />
                  <Tab label="text store creneau" value="12" />
                  <Tab label="socket config" value="13" />
                  <Tab label="Socket path" value="14" />
                  <Tab label="Rating list " value="15" />
                  <Tab label="Config content " value="16" />
                  <Tab label="CategoryType " value="17" />
                  <Tab label="redis config " value="18" />
                  <Tab label="transportations" value="19" />
                  <Tab label="nearToCustomerConf" value="20" />
                  <Tab label="app version" value="21" />
                  <Tab label="socket Interval" value="22" />
                  <Tab label="distance config (Food)" value="23" />
                  <Tab label="distance config (Paul)" value="24" />
                  <Tab label="distance config (Marjane)" value="25" />
                  <Tab label="Ramadan Timing" value="26" />
                  <Tab label="All Partner Setting (PAUL)" value="27" />
                  <Tab label="Comment" value="28" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <UserNotificationComponent setting={setting} />
              </TabPanel>
              <TabPanel value="2">
                <UserCancelComponent setting={setting} />
              </TabPanel>
              <TabPanel value="3">
                <StoreCancelComponent setting={setting} />
              </TabPanel>
              <TabPanel value="4">
                <DispatchingCancelComponent setting={setting} />
              </TabPanel>
              <TabPanel value="5">
                <KaalixUpConfig />
              </TabPanel>
              <TabPanel value="6">
                <RegularisationBalanceReasonsComponent setting={setting} />
              </TabPanel>
              <TabPanel value="7">
                <CronConfigComponenet />
              </TabPanel>
              <TabPanel value="8">
                <KallixPayTab setting={setting} />
              </TabPanel>
              <TabPanel value="9">
                <StorePreparationTimeComponent setting={setting} />
              </TabPanel>
              <TabPanel value="10">
                <PartnerSettingComponent setting={setting} />
              </TabPanel>
              <TabPanel value="11">
                <CanCancelComponenet />
              </TabPanel>
              <TabPanel value="12">
                <StoreCreneauTextComponent setting={setting} />
              </TabPanel>
              <TabPanel value="13">
                <SocketConfigComponent setting={setting} />
              </TabPanel>
              <TabPanel value="14">
                <SocketPathTextComponent setting={setting} />
              </TabPanel>
              <TabPanel value="15">
                <RatingListComponent setting={setting} />
              </TabPanel>
              <TabPanel value="16">
                <ConfigContent />
              </TabPanel>
              <TabPanel value="17">
                <CategoryTypeListComponent setting={setting} />
              </TabPanel>
              <TabPanel value="18">
                <RedisConfig setting={setting} />
              </TabPanel>
              <TabPanel value="19">
                <TransListComponent />
              </TabPanel>
              <TabPanel value="20">
                <DriverConfigComponent />
              </TabPanel>
              <TabPanel value="21">
                <AppVersionConfigComponent />
              </TabPanel>
              <TabPanel value="22">
                <IntervalConfigComponent />
              </TabPanel>
              <TabPanel value="23">
                <ConfigDistanceComponent />
              </TabPanel>
              <TabPanel value="24">
                <ConfigDistanceAllPartnersComponent />
              </TabPanel>
              <TabPanel value="25">
                <ConfigDistancePartnersComponent />
              </TabPanel>
              <TabPanel value="26">
                <UseRamadanTimingComponent />
              </TabPanel>
              <TabPanel value="27">
                <AllPartnerSettingComponent setting={setting} />
              </TabPanel>
              <TabPanel value="28">
                <CommentComponent setting={setting} />
              </TabPanel>
            </TabContext>
          </Box>
        </CardBody>
      </Card>
    </GridContainer>
  );
}
