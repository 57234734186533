import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Grid,
  Typography,
  Paper,
} from '@mui/material';
import ButtonComponent from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import { Box } from '@material-ui/core';
import SearchBar from 'material-ui-search-bar';
import { styled } from '@mui/material/styles';
import SelectBoxComponent from '../../../components/SelectBox.component';
import SwitchComponent from '../../../components/Switch.component';
import DeliveryHeaderInfoComponenet from './Delivery.header.info.componenet';

import { filterDeliveryMenInAssignTable } from '../../orders/mock';
// import { getDeliveryManInCity } from '../../dashboard/services/serverAPI.service';
import { getDistanceFromLatLonInKm } from '../../../helpers/index';
// call api
import { getPartnerDeliveryManInCities } from '../../AllPatners/services/server.api.service';
import { AssignDeliverie } from '../services/server.api.service';
import { getAppVersion } from '../../setting/services/serverApi.service';
import ModalComponent from '../../../components/Modal.component';

const useStyles = makeStyles({
  table: {
    minWidth: 50,
  },
});
export default function AssignDeliveriesComponent({
  deliveries,
  deliverieId,
  admin,
  close,
}) {
  const classes = useStyles();
  const [deliveryInCity, setDeliverInCity] = useState([]);
  const [deliverie, setDeliverie] = useState({});
  const [rows, setRows] = useState([]);
  const [searched, setSearched] = useState();
  const [filter, setFilter] = useState(filterDeliveryMenInAssignTable.all);
  const [isLoading, setIsLoading] = useState(false);
  const [onlyInThisWarehouse, setOnlyInThisWarehouse] = useState(false);
  const [appVersion, setAppVersion] = useState('');
  const [openSubModal, setOpenSubModal] = useState('');
  const [location, setLocation] = useState([]);
  const [providerId, setProviderId] = useState('');
  const tableHeader = [
    'name',
    'phone',
    'distance Store',
    'distance Client',
    'deliveries',
    'planified',
    'appVers',
    'available',
    'dispa',
    'free From FoodOrders',
    'Orders',
    'assign',
    'wareHouse',
    'approved',
  ];
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  const getInfoAboutDelivery = (deliverMenList) => {
    const counts = {};
    setIsLoading(true);
    const tmpDeliveries = deliveries;
    tmpDeliveries.forEach(function (x) {
      counts[x?.deliveryMan?._id] = (counts[x?.deliveryMan?._id] || 0) + 1;
    });
    deliverie &&
      deliverMenList &&
      deliverMenList.length > 0 &&
      deliverMenList.map((delivery) => {
        delivery['deliveries'] = counts[delivery._id] || 0;
        if (delivery.location) {
          delivery['distanceToStore'] = getDistanceFromLatLonInKm(
            deliverie?.pickupAddress?.latitude,
            deliverie?.pickupAddress?.longitude,
            delivery.location[0],
            delivery.location[1],
          );
          delivery['distanceToClient'] = getDistanceFromLatLonInKm(
            deliverie?.destinationAddress.latitude,
            deliverie?.destinationAddress.longitude,
            delivery.location[0],
            delivery.location[1],
          );
        }
      });
    setDeliverInCity(
      deliverMenList.sort((a, b) => a.distanceToStore - b.distanceToStore),
    );
    filterDeliveryMen(
      filter,
      deliverMenList.sort((a, b) => a.distanceToStore - b.distanceToStore),
    );
    setIsLoading(false);
  };

  const requestSearch = (searchedVal) => {
    let filteredRows = [];
    switch (filter) {
      case filterDeliveryMenInAssignTable.all:
        if (onlyInThisWarehouse)
          filteredRows = deliveryInCity.filter((x) =>
            (x.warehouses || []).find(
              (w) => w?.wareHouseLabel == deliverie?.wareHouse?.wareHouseLabel,
            ),
          );
        else {
          filteredRows = deliveryInCity;
        }
        filteredRows = filteredRows.filter((row) => {
          return row.name.toLowerCase().includes(searchedVal.toLowerCase());
        });
        setRows(filteredRows);
        break;
      case filterDeliveryMenInAssignTable.dispatchabel:
        filteredRows = deliveryInCity
          .filter((x) => x.planified && x.available && x.dispatchable)
          .filter((row) => {
            return row.name.toLowerCase().includes(searchedVal.toLowerCase());
          });
        setRows(filteredRows);
        break;
      case filterDeliveryMenInAssignTable.UnDispatchabel:
        filteredRows = deliveryInCity
          .filter((x) => !x.planified || !x.available || !x.dispatchable)
          .filter((row) => {
            return row.name.toLowerCase().includes(searchedVal.toLowerCase());
          });
        setRows(filteredRows);
        break;

      default:
        break;
    }
  };

  const cancelSearch = () => {
    setSearched('');
    requestSearch(searched);
  };
  const filterDeliveryMen = (value, deliveryInCity) => {
    setFilter(value);
    switch (value) {
      case filterDeliveryMenInAssignTable.all:
        if (onlyInThisWarehouse)
          deliveryInCity = deliveryInCity.filter((x) =>
            (x.warehouses || []).find(
              (w) => w?.wareHouseLabel == deliverie?.wareHouse?.wareHouseLabel,
            ),
          );
        setRows(deliveryInCity);
        break;
      case filterDeliveryMenInAssignTable.dispatchabel:
        if (onlyInThisWarehouse)
          deliveryInCity = deliveryInCity.filter((x) =>
            (x.warehouses || []).find(
              (w) => w?.wareHouseLabel == deliverie?.wareHouse?.wareHouseLabel,
            ),
          );
        setRows(
          deliveryInCity.filter(
            (x) => x.planified && x.available && x.dispatchable,
          ),
        );
        break;
      case filterDeliveryMenInAssignTable.UnDispatchabel:
        if (onlyInThisWarehouse)
          deliveryInCity = deliveryInCity.filter((x) =>
            (x.warehouses || []).find(
              (w) => w?.wareHouseLabel == deliverie?.wareHouse?.wareHouseLabel,
            ),
          );
        setRows(
          deliveryInCity.filter(
            (x) => !x.planified || !x.available || !x.dispatchable,
          ),
        );
        break;

      default:
        break;
    }
  };
  const accept_Or_Reject_Order = (deliverManId, location, confirm) => {
    if (!confirm && location[0] == 0 && location[1] == 0) {
      setOpenSubModal('assignet');
      setLocation(location);
      setProviderId(deliverManId);
    } else {
      if (deliverManId) {
        setIsLoading(true);
        AssignDeliverie(deliverie._id, {
          deliveryManId: deliverManId,
          admin,
        })
          .then(({ data }) => {
            handleSubCloseModal();
            close();
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => setIsLoading(false));
      }
    }
  };
  const handleSubCloseModal = () => {
    setOpenSubModal('');
    setLocation('');
    setProviderId('');
  };
  const fetchAppVersion = () => {
    getAppVersion()
      .then(({ data }) => setAppVersion(data?.findAppversion?.appVersion))
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    fetchAppVersion();
    setDeliverie((deliveries || []).find((x) => x._id == deliverieId));
  }, []);
  const getDeliveryMen = () => {
    getPartnerDeliveryManInCities([deliverie.cityId])
      .then(({ data }) => {
        getInfoAboutDelivery(data.deliveryMen.filter((x) => x.approved));
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const rowColor = (location, appVer) => {
    console.log('appVersion', appVer);
    if (location[0] == 0 && location[1] == 0) {
      return '#DC3545';
    } else if (appVer != appVersion) {
      return '#eed202';
    }
  };
  useEffect(() => {
    deliverie && deliverie.cityId && getDeliveryMen();
  }, [deliverie]);
  useEffect(() => {
    deliverie && filterDeliveryMen(filter, deliveryInCity || []);
  }, [onlyInThisWarehouse]);
  // console.log(deliverie);
  return (
    <div>
      <DeliveryHeaderInfoComponenet delivery={deliverie} />
      <SearchBar
        value={searched}
        onChange={(searchVal) => requestSearch(searchVal)}
        onCancelSearch={() => cancelSearch()}
      />
      <SwitchComponent
        // style={{ marginLeft: 15 }}
        onChange={(value) => setOnlyInThisWarehouse(value)}
        checked={onlyInThisWarehouse}
        label="filter by warehouse"
      />
      <div className=" mt-2">
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table
              className={classes.table}
              stickyHeader
              aria-label="sticky table"
            >
              <TableHead>
                <TableRow>
                  {tableHeader &&
                    tableHeader.map((item) => (
                      <TableCell align="center">{item}</TableCell>
                    ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {(rows || []).map((row) => (
                  <StyledTableRow
                    key={row._id}
                    // style={{ color: 'red' }}
                    style={{
                      // backgroundColor: '#ff5f5c',
                      backgroundColor: rowColor(row?.location, row?.appVersion),
                      // color: red  !important,
                    }}
                  >
                    <TableCell align="center">{row.name}</TableCell>
                    <TableCell align="center">{row.phone}</TableCell>
                    <TableCell align="center">{row.distanceToStore}</TableCell>
                    <TableCell align="center">{row.distanceToClient}</TableCell>
                    <TableCell align="center">{row.deliveries}</TableCell>
                    <TableCell
                      align="center"
                      style={{ color: row.planified ? 'green' : 'red' }}
                    >
                      {row.planified ? 'yes' : 'no'}
                    </TableCell>
                    <TableCell align="center">{row.appVersion}</TableCell>
                    <TableCell
                      align="center"
                      style={{ color: row.available ? 'green' : 'red' }}
                    >
                      {row.available ? 'yes' : 'no'}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ color: row.dispatchable ? 'green' : 'red' }}
                    >
                      {row.dispatchable ? 'yes' : 'no'}
                    </TableCell>

                    <TableCell
                      align="center"
                      style={{
                        color: row.freeFromFoodOrders ? 'green' : 'red',
                      }}
                    >
                      {row.freeFromFoodOrders ? 'yes' : 'no'}
                    </TableCell>
                    <TableCell align="center">
                      {row.ordersRef && row.ordersRef.length}
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        size="sm"
                        variant="text"
                        color="primary"
                        onClick={() => {
                          accept_Or_Reject_Order(row._id, row?.location, false);
                        }}
                      >
                        assigned
                      </Button>
                    </TableCell>
                    <TableCell align="center">
                      {
                        // [
                        //   'MARJANE DERB SULTAN',
                        //   'MARJANE DERB SULTAN',
                        //   'MARJANE DERB SULTAN',
                        // ] ||
                        (row.warehouses || []).map((warehouse) => (
                          <span
                            style={{
                              color:
                                warehouse?.wareHouseLabel?.toLowerCase() ===
                                  deliverie?.wareHouse?.wareHouseLabel?.toLowerCase() &&
                                'green',
                            }}
                          >
                            {warehouse.wareHouseLabel + '\r\n'}
                          </span>
                        ))
                      }
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ color: row.approved ? 'green' : 'red' }}
                    >
                      {row.approved ? 'yes' : 'no'}
                    </TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
      {openSubModal && openSubModal == 'assignet' && (
        <ModalComponent
          maxWidth={'sm'}
          close={handleSubCloseModal}
          visible={true}
          title="deliveryMan with 0 0 GPS location"
        >
          <h3>
            this deliveryMan has a 0 0 location, contact him. are you sure to
            assign
          </h3>
          <Box textAlign="right" className="mt-2">
            <Button
              style={{ marginTop: 40, marginRight: 5 }}
              size="sm"
              variant="contained"
              color="primary"
              onClick={() => {
                accept_Or_Reject_Order(providerId, location, true);
              }}
            >
              assigned
            </Button>
            <Button
              style={{ marginTop: 40 }}
              size="sm"
              variant="contained"
              color="primary"
              onClick={() => {
                handleSubCloseModal();
              }}
            >
              cancel
            </Button>
          </Box>
        </ModalComponent>
      )}
    </div>
  );
}
