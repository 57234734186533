import React, { useEffect, useState } from 'react';
import {
  getCities,
  getDispatchingModes,
  updateCity,
} from '../services/serverApi.service';
import DataTableContainerComponent from '../../../components/DataTableContainer.component';
import { Button } from '@material-ui/core';
import ModalComponent from '../../../components/Modal.component';
import { useAlert } from 'react-alert';
import CreateCommissionConfig from '../components/CreateCommissionConfig.component';

export default function CommissionConfigPage() {
  const alert = useAlert();
  const [resultsData, setResultsData] = useState({
    total: 0,
    items: [],
    tableItems: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const pages = [25, 50, 100];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(pages[page]);
  const [dispatchingMessage, setDispatchingMessage] = useState([]);
  const [city, setCity] = useState(null);
  const [modalName, setModalName] = useState('');
  const [selectDispatchingMessage, setSelectDispatchingMessage] = useState('');
  const [selectDispatchingMode, setSelectDispatchingMode] = useState('');
  const [dataConfig, setDataConfig] = useState('');

  const loadCitiesList = () => {
    setIsLoading(true);
    getCities(page, rowsPerPage, true)
      .then(({ data }) => {
        setResultsData({
          total: data.total,
          items: data.cities,
          tableItems: data.cities.map((item) => [
            item._id,
            item.name,
            item.timeZone,
            item.country?.name,
            <div>
              <Button
                color={'primary'}
                variant="contained"
                style={{ margin: '0px 10px' }}
                onClick={() => {
                  setCity(item);
                  commissionStoreClient(item);
                }}
              >
                Store/Client
              </Button>
              <Button
                color={'inherit'}
                variant="contained"
                style={{ margin: '0px 10px' }}
                onClick={() => {
                  setCity(item);
                  commissionStoreDeliveryMan(item);
                }}
              >
                Store/DeliveryMan
              </Button>
            </div>,
          ]),
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  useEffect(() => {
    loadCitiesList();
    get_Dispatching_Modes();
  }, [page, rowsPerPage]);

  const [openModal, setOpenModal] = useState('');
  const [interval, setInterval] = useState([]);
  const [maxCommissionPrice, setMaxCommissionPrice] = useState(0);
  const handleOpenModal = (city) => {
    setCity(city);
    setOpenModal('modes');
  };
  const closeModal = () => {
    setOpenModal('');
    setMaxCommissionPrice(0);
    setInterval([]);
  };
  const get_Dispatching_Modes = () => {
    getDispatchingModes()
      .then(({ data }) => {
        setDispatchingMessage(data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const handleDispatchingModesMessage = (event) => {
    setSelectDispatchingMessage(event.target.value);
  };
  const handleDispatchingMode = (event) => {
    setSelectDispatchingMode(event.target.value);
  };
  const onEdit = () => {
    setIsLoading(true);
    let newCity = city;
    const newDispatchingMessage = dispatchingMessage.find(
      (item) => item._id === selectDispatchingMessage,
    );
    newCity.dispatchingMessage = newDispatchingMessage;
    newCity.dispatchingMode = selectDispatchingMode;
    const { _id, ...values } = newCity;
    updateCity(_id, values)
      .then(() => {
        alert.show('City has been edited', {
          type: 'success',
        });
        loadCitiesList();
      })
      .catch((error) => {
        alert.show(error.message, {
          type: 'error',
        });
      })
      .finally(() => {
        setIsLoading(false);
        closeModal();
        loadCitiesList();
      });
  };
  const commissionStoreClient = (cityConf) => {
    setOpenModal('commissionStoreClient');
    setModalName('config commission (Store / Client)');
    console.log('her!!!*****', cityConf.commissionStoreClient, interval);
    if (cityConf.commissionStoreClient) {
      const maxCommissionPrice =
        cityConf.commissionStoreClient.maxCommissionPrice || 0;
      const commissionInterval =
        cityConf.commissionStoreClient.commissionInterval;
      setInterval([...commissionInterval]);
      setMaxCommissionPrice(maxCommissionPrice);
    }
  };
  const commissionStoreDeliveryMan = (cityConf) => {
    setOpenModal('commissionStoreDeliveryMan');
    setModalName('config commission (Store / DeliveryMan)');
    if (cityConf.commissionStoreDeliveryMan) {
      const maxCommissionPrice =
        cityConf.commissionStoreDeliveryMan.maxCommissionPrice || 0;
      const commissionInterval =
        cityConf.commissionStoreDeliveryMan.commissionInterval;
      setInterval([...commissionInterval]);
      setMaxCommissionPrice(maxCommissionPrice);
    }
  };
  return (
    <>
      <DataTableContainerComponent
        resultsData={resultsData}
        tableHead={['Name', 'TimeZone', 'Country', 'Actions']}
        title="Cities"
        isLoading={isLoading}
        loadItemsList={loadCitiesList}
      />
      {openModal && (
        <ModalComponent
          maxWidth={'lg'}
          close={closeModal}
          visible={openModal}
          title={modalName}
        >
          <CreateCommissionConfig
            city={city}
            commissionInterval={interval}
            maxCommissionPrice={maxCommissionPrice}
            type={openModal}
            close={closeModal}
          />
        </ModalComponent>
      )}
    </>
  );
}
