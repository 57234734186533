import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useAlert } from 'react-alert';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Box } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import SelectBoxComponent from '../../../components/SelectBox.component';
// import SelectBoxComponent from '../../../components/material-dashboard-pro-react/CustomSelect/CustomSelect';
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import CustomInput from '../../../components/material-dashboard-pro-react/CustomInput/CustomInput';
import { defaultImagesAction } from '../../../redux/uploadRedux/upload.action';
import SwitchComponent from '../../../components/Switch.component';
import ImageUpload from '../../../components/material-dashboard-pro-react/CustomUpload/ImageUpload';
import { updateStore } from '../services/serverApi.service';
import CustomRadioGroup from '../../../components/material-dashboard-pro-react/CustomRadioGroup/CustomRadioGroup';
import { PrimaryTextes } from '../mocks/index';

const validationSchema = yup.object({
  // name: yup.string().required(),
  // storeId: yup.string().required(),
  // productId: yup.string().required(),
  // description: yup.string().required(),
  // price: yup.number().typeError('value is number').required(),
  // imageUrl: yup.string().required(),
  // inStock: yup.bool().required(),
  // specifications: yup.array().of(yup.string()).required(),
  // isMainPromo: yup.bool().required(),
  promoSetting: yup.object().shape({
    type: yup.string().required(),
    // value: yup.number().required(),
    value: yup.number().when('type', {
      is: (value) => value == 'reduction',
      then: yup
        .number()
        .typeError('value is number')
        .required('value is required when choise reduction'),
      // otherwise: yup.number().typeError('value is number')
      otherwise: '',
    }),
    discountType: yup.string().when('type', {
      is: (value) => value == 'reduction',
      then: yup.string().required(),
      // otherwise: yup.number().typeError('value is number')
      otherwise: yup.string().nullable(),
    }),
    // discountType: yup.string().required(),
    // minCartPrice: yup.number().when('type',{
    // is:value=>value=='offered product'||value=='1 buy = 1 free',
    // then:yup.number().typeError('minCartPrice is number').required(),
    // // otherwise: yup.number().typeError('value is number')
    // otherwise: ''
    // }),
    minCartPrice: yup.number(),
    enabled: yup.bool().required(),
    isFromKaalix: yup.bool().required(),
  }),
  promoTextPrimary: yup.string(),
  promoTextSecondary: yup.string(),
});

export default function StorePromoComponent({
  store,
  index,
  stores,
  setStores,
  close,
}) {
  const alert = useAlert();
  const [isLoading, setIsLoading] = useState(false);
  const radioGroupData = [{ name: 'Discount' }, { name: 'FreeDelivery' }];
  const secondRadioGroupData = [{ name: 'Percentage' }, { name: 'Absolute' }];
  const [promoValueIcon, setPromoValueIcon] = useState(
    store?.promoSetting?.discountType == 'Percentage' ||
      !store?.promoSetting?.discountType
      ? '%'
      : 'dh',
  );
  const storeId = localStorage.getItem('storeId');
  const formik = useFormik({
    initialValues: {
      promoSetting: {
        type: '',
        // type: radioGroupData[0].name,
        value: 0,
        discountType: 'Percentage',
        minCartPrice: 0,
        enabled: true,
        promoTextPrimary: '',
        promoTextSecondary: '',
        isFromKaalix: false,
      },
    },
    validationSchema,
  });
  const onEdit = () => {
    store.promoSetting = formik.values.promoSetting;
    store.promoTextPrimary = formik.values.promoTextPrimary;
    store.promoTextSecondary = formik.values.promoTextSecondary;
    console.log(store.promoSetting);
    // console.log(JSON.stringify(formik.values));
    setIsLoading(true);
    updateStore(store._id, store)
      .then(({ data }) => {
        // console.log(data);
        let newStores = [...stores];
        newStores[index] = data.store;
        setStores(newStores);
        alert.show('promo saved', {
          type: 'success',
        });
        close();
      })
      .catch((error) => {
        console.log(error.message);
      })
      .finally(() => setIsLoading(true));
  };
  async function handleClick(event) {
    if (event.target.value != 'Discount') {
      formik.setFieldValue('promoSetting.discountType', 'Percentage');
      formik.setFieldValue('promoSetting.value', 0);
    }
    if (event.target.value == 'Discount') {
      formik.setFieldValue('promoSetting.value', 0);
      formik.setFieldValue(
        'promoSetting.discountType',
        secondRadioGroupData[0].name,
      );
    }
    if (
      event.target.value == 'OfferedAboveMinCartPrice' ||
      event.target.value == 'OneBoughtOneOffered'
    ) {
      // formik.setFieldValue('promoSetting.minCartPrice','')
      // formik.setFieldValue('promoSetting.discountType',secondRadioGroupData[0].name)
    }
  }
  const handleClickDiscountType = (event) => {
    if (event.target.value == 'Percentage') {
      setPromoValueIcon('%');
    }
    if (event.target.value == 'Absolute') {
      setPromoValueIcon('dh');
    }
  };
  const setValue = () => {
    if (store) {
      formik.setValues({
        promoSetting: {
          type: store?.promoSetting?.type,
          value: store?.promoSetting?.value,
          discountType: store?.promoSetting?.discountType || 'Percentage',
          minCartPrice: store?.promoSetting?.minCartPrice,
          enabled: store?.promoSetting?.enabled,
          isFromKaalix: store?.promoSetting?.isFromKaalix || false,
        },
        promoTextPrimary: store?.promoTextPrimary || '',
        promoTextSecondary: store?.promoTextSecondary || '',
      });
    }
  };
  useEffect(() => {
    setValue();
  }, [store]);

  return (
    <div>
      {store && (
        <>
          <CustomRadioGroup
            data={radioGroupData}
            error={
              formik.touched.type && Boolean(formik.errors.promoSetting.type)
            }
            inputProps={{
              value: formik.values.promoSetting.type,
              onBlur: formik.handleBlur('promoSetting.type'),
              onChange: formik.handleChange('promoSetting.type'),
            }}
            handleClick={handleClick}
          />
          {/* <div style={{display: 'flex',flexDirection:'row'}}> */}
          <div
            style={{
              border: '1px solid #000',
              padding: '10px',
              borderRadius: '10px',
            }}
          >
            <CustomRadioGroup
              data={secondRadioGroupData}
              error={
                formik.touched.name &&
                Boolean(formik?.errors?.promoSetting?.discountType)
              }
              inputProps={{
                value: formik.values.promoSetting.discountType,
                onBlur: formik.handleBlur('promoSetting.discountType'),
                onChange: formik.handleChange('promoSetting.discountType'),
              }}
              handleClick={handleClickDiscountType}
              disabled={formik.values.promoSetting.type != 'Discount'}
            />
            <CustomInput
              labelText="value"
              formControlProps={{
                fullWidth: false,
              }}
              type="text"
              error={
                formik.touched?.promoSetting?.value &&
                Boolean(formik?.errors?.promoSetting?.value)
              }
              inputProps={{
                value: formik.values.promoSetting.value,
                onBlur: formik.handleBlur('promoSetting.value'),
                onChange: formik.handleChange('promoSetting.value'),
              }}
              helperText={
                formik.errors?.promoSetting?.value &&
                formik.errors?.promoSetting?.value
              }
              disabled={formik.values.promoSetting.type != 'Discount'}
            />
            {promoValueIcon}
          </div>
          <CustomInput
            labelText="amount"
            formControlProps={{
              fullWidth: false,
            }}
            type="text"
            error={
              formik.touched.name &&
              Boolean(formik?.errors?.promoSetting?.minCartPrice)
            }
            inputProps={{
              value: formik.values.promoSetting.minCartPrice,
              onBlur: formik.handleBlur('promoSetting.minCartPrice'),
              onChange: formik.handleChange('promoSetting.minCartPrice'),
            }}
            // disabled={formik.values.promoSetting.type!="OfferedAboveMinCartPrice"&&formik.values.promoSetting.type!="OneBoughtOneOffered"}
          />
          dh
          <Box textAlign="left">
            <SwitchComponent
              onChange={(value) =>
                formik.setFieldValue('promoSetting.enabled', value)
              }
              checked={formik.values.promoSetting.enabled}
              error={
                formik.touched?.promoSetting?.enabled &&
                Boolean(formik.errors?.promoSetting?.enabled)
              }
              label="enabled"
            />
          </Box>
          <Box textAlign="left">
            <SwitchComponent
              onChange={(value) =>
                formik.setFieldValue('promoSetting.isFromKaalix', value)
              }
              checked={formik.values.promoSetting.isFromKaalix}
              error={
                formik.touched?.promoSetting?.isFromKaalix &&
                Boolean(formik.errors?.promoSetting?.isFromKaalix)
              }
              label="isFromKaalix"
            />
          </Box>
          <SelectBoxComponent
            items={PrimaryTextes.map((PrimaryTexte) => ({
              value: PrimaryTexte.value,
              // label: PrimaryTexte.value,
              label: PrimaryTexte.label,
            }))}
            inputLabel="primary Text"
            onValueChange={(items) => {
              formik.setFieldValue('promoTextPrimary', items);
            }}
            value={formik.values.promoTextPrimary || store?.promoTextPrimary}
            //   multiple
          />
          <CustomInput
            labelText="promo text secondary"
            formControlProps={{
              fullWidth: true,
            }}
            type="text"
            error={
              formik.touched?.promoTextSecondary &&
              Boolean(formik?.errors?.promoTextSecondary)
            }
            inputProps={{
              value: formik.values.promoTextSecondary,
              onBlur: formik.handleBlur('promoTextSecondary'),
              onChange: formik.handleChange('promoTextSecondary'),
            }}
            helperText={
              formik.errors?.promoTextSecondary &&
              formik.errors?.promoTextSecondary
            }
            // disabled={formik.values.promoSetting.type!='Discount'}
            // onChange={(e)=>console.log('e.target.value')}
          />
          <Box textAlign="center">
            <Button
              round
              type="submit"
              color="success"
              disabled={!formik.dirty || !formik.isValid || isLoading}
              loading={isLoading}
              loaderColor="white"
              onClick={onEdit}
            >
              save Promo
            </Button>
          </Box>
        </>
      )}
    </div>
  );
}
