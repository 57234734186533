import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useAlert } from 'react-alert';
import FormLabel from '@material-ui/core/FormLabel';
import { makeStyles } from '@material-ui/core/styles';
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import CustomInput from '../../../components/material-dashboard-pro-react/CustomInput/CustomInput';
import SelectBoxComponent from '../../../components/SelectBox.component';
import GridContainer from '../../../components/material-dashboard-pro-react/Grid/GridContainer';
import GridItem from '../../../components/material-dashboard-pro-react/Grid/GridItem';
import {
  getCities,
  getServices,
} from '../../delivery-info/services/serverApi.service';
// import {defaultImagesAction} from '../../../redux/uploadRedux/upload.action'
import styles from '../../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle';
import SwitchComponent from '../../../components/Switch.component';
import { commissionTypes } from '../mocks';
import SliderComponent from '../../../components/Slider.component';
import { duplicateStore } from '../services/serverApi.service';

const useStyles = makeStyles(styles);

const validationSchema = yup.object({
  name: yup.string().required(),
  email: yup.string().required().dis,
  cityId: yup.string().required()
});

export default function DuplicateStoreComponent({ data, close, props, onItemEdited }) {
  const classes = useStyles();
  const alert = useAlert();
  const [isLoading, setIsLoading] = useState(false);
  const [cities, setCities] = useState([]);
  const [services, setServices] = useState([]);
  const dispatch = useDispatch();
  // const upload = useSelector((state) => state.uploadReducer);
  // const { iconUrl, error, loading } = upload;
  // console.log('props', props);
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      cityId: '',
      // serviceId: '',
    },
    validationSchema,
  });

  const onEdit = () => {
    if (data.email?.props?.children?.props?.value === formik.values.email) {
      console.log("SAME EMAIL");
      return;
    }
    console.log(formik.errors, formik.values);
    console.log(
      'caal Duplicate ',
      data.email?.props?.children?.props?.value,
      formik.values.email,
    );
    setIsLoading(true);
    duplicateStore({
      storeId: data._id,
      newEmail: formik.values.email,
      cityId: formik.values.cityId,
    })
      .then(() => {
        alert.show('Store has been updated', {
          type: 'success',
        });
        formik.resetForm();
        onItemEdited();
      })
      .catch((error) => {
        alert.show(error.message, {
          type: 'error',
        });
      })
      .finally(() => {
        close();
        setIsLoading(false);
      });
  };
  // console.log(data);
  useEffect(() => {
    if (data) {
      console.log({ where: 'Duplicate Store', data: data?.name });
      formik.setValues({
        name: data?.name,
        email: data.email?.props?.children?.props?.value,
        cityId: data.cityId,
        // serviceId: data.serviceId,
      });
    }
  }, [data]);

  useEffect(() => {
    setCities(props.cities);
    setServices(props.services);
  }, [props]);

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={3}>
          <FormLabel className={classes.labelHorizontal}>Name</FormLabel>
        </GridItem>
        <GridItem xs={12} sm={12} md={9}>
          <CustomInput
            formControlProps={{
              fullWidth: true,
            }}
            type="text"
            error={formik.touched.name && Boolean(formik.errors.name)}
            inputProps={{
              value: formik.values.name,
              onBlur: formik.handleBlur('name'),
              onChange: formik.handleChange('name'),
            }}
          />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={3}>
          <FormLabel className={classes.labelHorizontal}>Email</FormLabel>
        </GridItem>
        <GridItem xs={12} sm={12} md={9}>
          <CustomInput
            formControlProps={{
              fullWidth: true,
            }}
            type="email"
            error={formik.touched.email && Boolean(formik.errors.email)}
            inputProps={{
              value: formik.values.email,
              onBlur: formik.handleBlur('email'),
              onChange: formik.handleChange('email'),
            }}
          />
          {data?.email?.props?.children?.props?.value ===
            formik?.values?.email && (
            <p style={{ color: 'red' }}>Utiliser un email different</p>
          )}
        </GridItem>
      </GridContainer>

      {/* <GridContainer>
        <GridItem xs={12} sm={12} md={3}>
          <FormLabel className={classes.labelHorizontal}>Service</FormLabel>
        </GridItem>
        <GridItem xs={12} sm={12} md={9}>
          <SelectBoxComponent
            items={services.map((service) => ({
              value: service._id,
              label: service.name,
            }))}
            onValueChange={formik.handleChange('serviceId')}
            value={formik.values.serviceId}
          />
        </GridItem>
      </GridContainer> */}
      <GridContainer>
        <GridItem xs={12} sm={12} md={3}>
          <FormLabel className={classes.labelHorizontal}>City</FormLabel>
        </GridItem>
        <GridItem xs={12} sm={12} md={9}>
          <SelectBoxComponent
            items={cities.map((city) => ({
              value: city._id,
              label: city.name,
            }))}
            onValueChange={formik.handleChange('cityId')}
            value={formik.values.cityId}
          />
        </GridItem>
      </GridContainer>
      <Box textAlign="center">
        <Button
          round
          type="submit"
          color="success"
          disabled={
            data?.email?.props?.children?.props?.value === formik?.values?.email
          }
          loading={isLoading}
          loaderColor="white"
          onClick={onEdit}
        >
          Duplicate
        </Button>
      </Box>
    </div>
  );
}

DuplicateStoreComponent.propTypes = {
  data: PropTypes.any,
  close: PropTypes.func,
  props: PropTypes.any,
  onItemEdited: PropTypes.func.isRequired,
};
