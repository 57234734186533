import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useAlert } from 'react-alert';
import { deletePulicity } from '../services/serverApi.service';
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';

export default function DeleteComponent({
  dataToDelete,
  close,
  action,
  onItemEdited,
  itemDelete,
  index,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const alert = useAlert();

  const deleteItem = () => {
    setIsLoading(true);
    action(dataToDelete._id)
      .then(() => {
        alert.show('publicity has been deleted', {
          type: 'success',
        });
        onItemEdited ? onItemEdited() : '';
        itemDelete ? itemDelete({ index: index }) : '';
        console.log('deleted');
        close();
      })
      .catch((error) => {
        alert.show(error?.response?.data?.errors[0]?.message, {
          type: 'error',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div>
      <div>Are you sure do delete this item ?</div>
      <br />
      <div style={{ float: 'right', margin: '15px' }}>
        <Button onClick={close}>Cancel</Button>
        <Button onClick={deleteItem} color="success">
          Yes
        </Button>
      </div>
    </div>
  );
}

DeleteComponent.propTypes = {
  dataToDelete: PropTypes.any,
  action: PropTypes.func,
  close: PropTypes.any,
  index: PropTypes.any,
  onItemEdited: PropTypes.func,
  itemDelete: PropTypes.func,
};
