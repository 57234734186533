export const columns = [{
    Header: 'Name',
    accessor: 'name',
},
{
    Header: 'Email',
    accessor: 'email',
},
{
    Header: 'magasins',
    accessor: 'warehousesNbr',
},
{
    Header: 'Webhooks',
    accessor: 'config',
},
{
    Header: 'Commission',
    accessor: 'commission',
},
{
    Header: 'Actions',
    accessor: 'actions',
},]
// 30s, 1min, 2min, 5min, 10min)
export const maxRetry = [1, 2, 3, 4, 5, 10, 20, 25, 50];
export const timeOutInterval = [
    {
        label: '30s',
        value: '30',
    },
    {
        label: '1min',
        value: '60',
    },
    {
        label: '2min',
        value: '120',
    },
    {
        label: '5min',
        value: '300',
    },
    {
        label: '10min',
        value: '600',
    },
]
export const callApiSearchType = ['wareHouse', 'deliveryMan', 'customer', 'shortId', 'partnerId']
export const callApiSearchId = ['kaalixId', 'marjaneId']
export const callApiSearchBySource = ['Marjane', 'kaalix', 'kaalix interne']
export const callApiSearchStatus = [200, 201, 404, 403, 502]
export const callApiSearchStatusForLogin = ['success', 'fail',]
export const callApiSearchCall = [
    'none',
    'createDelivery',
    'updateDelivery',
    'updateDeliveryStatus',
    'AddProviderToOrder',
    'SetOrderAsCompleted',
    'sendNotification',
]
export const callApiSearchMarjaneStatus = [
    'STATUS_ERROR_PAYMENT',
    'STATUS_CANCELLED',
    'STATUS_DELIVERED',
    'STATUS_DELIVERING',
    'STATUS_FINISHED',
    'STATUS_OPEN_PENDING',
    'STATUS_PREPARING',
    'STATUS_READY',
    'STATUS_REFUSED',
    'STATUS_VALIDATED',

]
