import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import { FormLabel, Box } from '@material-ui/core';
import SelectBoxComponent from '../../../components/SelectBox.component';
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import { updateStore } from '../services/serverApi.service';
import { deliveredByTypes } from '../mocks/index';
import styles from '../../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle';

const useStyles = makeStyles(styles);

const validationSchema = yup.object({
  deliveredBy: yup.string(),
});

export default function StoreDeliveredByComponent({
  store,
  index,
  stores,
  setStores,
  close,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();
  const formik = useFormik({
    initialValues: {
      deliveredBy: '',
    },
    validationSchema,
  });
  const editStore = () => {
    store.deliveredBy = formik.values.deliveredBy;
    setIsLoading(true);
    updateStore(store._id, store)
      .then(({ data }) => {
        let newStores = [...stores];
        newStores[index] = data.store;
        setStores(newStores);
        close();
      })
      .catch((error) => {
        console.log(error.message);
      })
      .finally(() => setIsLoading(true));
  };
  const setValues = () => {
    formik.setValues({
      deliveredBy: store?.deliveredBy,
    });
  };

  useEffect(() => {
    setValues();
  }, []);
  return (
    <div>
      <FormLabel className={classes.labelHorizontal}>deliveredBy</FormLabel>
      <SelectBoxComponent
        items={deliveredByTypes.map((type) => ({
          value: type.label,
          label: type.label,
        }))}
        onValueChange={formik.handleChange('deliveredBy')}
        value={formik.values.deliveredBy}
      />
      <Box textAlign="center">
        <Button
          round
          type="submit"
          color="success"
          disabled={!formik.dirty || !formik.isValid || isLoading}
          loading={isLoading}
          loaderColor="white"
          onClick={editStore}
        >
          save
        </Button>
      </Box>
    </div>
  );
}
