import { combineReducers } from 'redux';
import authReducer from '../../features/auth/redux/reducers/auth.reducer';
import uploadReducer from '../uploadRedux/upload.reducer';
import multiUploadReducer from '../multiUploadRedux/upload.reducer';
import ordersReducer from '../../features/orders/redux/reducers/order.reducers'
import modelReducer from '../../features/orders/redux/reducers/model.reducers'
import adminsListReducer from '../../features/orders/redux/reducers/adminList.reducers'
import deliveryMenInCity from '../../features/dashboard/redux/reducers/deliveryMan.reducers'
import partnerOrdersReducer from '../../features/PartnersOrders/redux/reducers/order.reducers'
import filterDeliveriesReducer from '../../features/PartnersOrders/redux/reducers/filter.reducers'
import deliveryMenReducer from '../../features/PartnersOrders/redux/reducers/deliveryMen.reducers'
import AllpartnerOrdersReducer from '../../features/AllPartnersOrders/redux/reducers/order.reducers'
import AllfilterDeliveriesReducer from '../../features/AllPartnersOrders/redux/reducers/filter.reducers'
import AlldeliveryMenReducer from '../../features/AllPartnersOrders/redux/reducers/deliveryMen.reducers'
import ridesReducer from '../../features/driver/redux/reducers/ride.reducers'
import settingReducer from '../../features/PartnersOrders/redux/reducers/setting.reducers'
import ecomDeliveriesReducer from '../../features/EcomPartnersOrders/redux/reducers/order.reducers'
export default combineReducers({
  ecomDeliveries: ecomDeliveriesReducer,
  authReducer: authReducer,
  uploadReducer: uploadReducer,
  multiUploadReducer: multiUploadReducer,
  ordersReducer: ordersReducer,
  deliveryMenInCity: deliveryMenInCity,
  modelReducer: modelReducer,
  deliveriesReducer: partnerOrdersReducer,
  filterDeliveriesReducer: filterDeliveriesReducer,
  deliveryMenReducer: deliveryMenReducer,
  allPatsdeliveriesReducer: AllpartnerOrdersReducer,
  AllfilterDeliveriesReducer: AllfilterDeliveriesReducer,
  AlldeliveryMenReducer: AlldeliveryMenReducer,
  adminsListReducer: adminsListReducer,
  ridesReducer: ridesReducer,
  setting: settingReducer,
});
