import axios from 'axios';
import { MICROSERVICE_BASE_URL } from '../../../config/backendServer.config';

export function getNapsResponse(page, limit, paged ,searchText) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.CUSTOMER_PAYMENT}/naps/get_naps_list?page=${page || 0}&limit=${
      limit || 0
    }&paged=${paged || false}&search_text=${searchText||''}`,
  );
}
export function getCards(page, limit, paged ,customerId,num_carte) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.CUSTOMER_PAYMENT}/card/get_card_list?page=${page || 0}&limit=${
      limit || 0
    }&paged=${paged || false}&customerId=${customerId||''}&num_carte=${num_carte||''}`,
  );
}