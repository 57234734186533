import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getOrdersDataState } from '../redux/selectors';
import {makeStyles} from '@material-ui/core';
// material componenet
import {
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Typography,
  Paper,
  Button,
  Tooltip,
  Box,
} from '@mui/material';
import { dispatchingInfoColums } from '../mock';
const useStyles = makeStyles((theme) => ({ 
   other: { width: '150px'},
}));
export default function InfoOrdersComponent({
  cities,
  setSelectedDispatchingStatus,
  selectedDispatchingStatus,
  filterBy,
  setFilterBy,
}) {
  const classes = useStyles();
  const { orders, loading, error, invalidOrders, watcherCounter } =
    useSelector(getOrdersDataState);
  const [totalOrders, setTotalOrders] = useState({});
  const [selectedCityOrders, setSelectedCityOrders] = useState([]);
  const [totalInvalidOrders, setTotalInvalidOrders] = useState({});
  const [totalOrdersByCity, setTotalOrdersByCity] = useState({});

  const selectedCell = {
    backgroundColor: '#3496ff',
    borderRadius: '50%/50%',
    padding: '5px',
    color: '#FFFFFF',
    cursor: 'pointer',
    textAlign: 'center',
  };
  const checkIfCitySelected = (cityId) => {
    if ((selectedCityOrders || []).find((x) => x == cityId))
      return { ...selectedCell };
    return { cursor: 'pointer' };
  };
  const checkIfSelected = (cityId, status) => {
    if ((selectedDispatchingStatus[cityId] || []).find((x) => x == status))
      return { ...selectedCell };
    return { cursor: 'pointer' };
  };

  const selectOrders = (cityId, status) => {
    const tempselectedDispatchingStatus = { ...selectedDispatchingStatus };
    const tempTotalOrders = { ...totalOrders };
    const tempSelectedCityOrders = [...selectedCityOrders];
    filterBy != 'dispatchingStatus' && setFilterBy('dispatchingStatus');
    const selectedCityIndex = (tempSelectedCityOrders || []).findIndex(
      (x) => x == cityId,
    );
    if (status) {
      if (tempselectedDispatchingStatus[cityId]) {
        if (tempselectedDispatchingStatus[cityId].includes(status)) {
          const findIndex = tempselectedDispatchingStatus[cityId].findIndex(
            (x) => x == status,
          );
          findIndex != -1 &&
            tempselectedDispatchingStatus[cityId].splice(findIndex, 1);
          tempselectedDispatchingStatus[cityId].length == 0 &&
            delete tempselectedDispatchingStatus[cityId];
        } else {
          tempselectedDispatchingStatus[cityId].push(status);
        }
      } else {
        tempselectedDispatchingStatus[cityId] = [status];
      }
    } else {
      if (selectedCityIndex != -1) {
        tempSelectedCityOrders.splice(selectedCityIndex, 1);
        delete tempselectedDispatchingStatus[cityId];
      } else {
        tempSelectedCityOrders.push(cityId);
        tempselectedDispatchingStatus[cityId] = Object.keys(
          tempTotalOrders[cityId].status,
        );
      }
    }
    setSelectedDispatchingStatus(tempselectedDispatchingStatus);
    setSelectedCityOrders(tempSelectedCityOrders);
  };
  const totalOrdersNumber = () => {
    const totalOrdersByCity = {};
    const totalInvalidOrdersByCity = {};
    (orders || []).map((order) => {
      totalOrdersByCity[order.city._id] = {
        total: (totalOrdersByCity[order.city._id]?.total || 0) + 1,
        status: {
          ...totalOrdersByCity[order.city._id]?.status,
          [order.dispatchingStatus]:
            (totalOrdersByCity[order.city._id]?.status[
              order.dispatchingStatus
            ] || 0) + 1,
        },
      };
    });

    (invalidOrders || []).map((order) => {
      totalInvalidOrdersByCity[order.city._id] =
        (totalInvalidOrdersByCity[order.city._id] || 0) + 1;
    });
    setTotalOrders(totalOrdersByCity);
    setTotalInvalidOrders(totalInvalidOrdersByCity);
  };
  const totalOrderInCity = () => {
    const TotalOrdersByCityObj = {};
    const allOrders = orders.concat(invalidOrders);
    (allOrders || []).map((order) => {
      TotalOrdersByCityObj[order.city._id] =
        (TotalOrdersByCityObj[order.city._id] || 0) + 1;
    });
    setTotalOrdersByCity(TotalOrdersByCityObj);
  };
  useEffect(() => {
    if (filterBy != 'dispatchingStatus') {
      setSelectedDispatchingStatus({});
      setSelectedCityOrders([]);
    }
  }, [filterBy]);
  useEffect(() => {
    totalOrdersNumber();
  }, [watcherCounter]);
  useEffect(() => {
    invalidOrders && orders && totalOrderInCity();
  }, [(invalidOrders || []).length, (orders || []).length]);
  return (
    <div>
      <TableContainer component={Paper}>
        <Typography className="mt-2 ml-4">Dispatching info</Typography>
        <Table aria-label="collapsible table" style={{width: 'auto',tableLayout:'auto'}}>
          <TableHead scope='row' colSpan='4'>
            <TableRow  >
              {dispatchingInfoColums.map((item) => (
                <TableCell align="center" className={item!=='Ville' && item!=='Orders' ? classes.other : '' } >{item}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {cities.map((city) => {
              return (
                totalOrdersByCity[city._id] && (
                  <TableRow>
                    <TableCell  >{city?.name?.substring(0, 4)}</TableCell>
                    <TableCell
                      align="center"
                      onClick={(e) =>
                        totalOrders[city._id]?.total &&
                        selectOrders(city._id, '')
                      }
                    >
                      <span style={checkIfCitySelected(city._id)}>
                        {totalOrdersByCity[city?._id] || '--'}
                      </span>
                    </TableCell>
                    <TableCell align="center" >{city?.dispatchingMode}</TableCell>
                    <TableCell align="center">
                      {totalInvalidOrders[city._id] || '--'}
                    </TableCell>
                    {/* <TableCell
                      onClick={(e) =>
                        totalOrders[city._id]?.status?.INVALID &&
                        selectOrders(city._id, 'INVALID')
                      }
                    >
                      {' '}
                      <span style={checkIfSelected(city._id, 'INVALID')}>
                        {totalOrders[city._id]?.status?.INVALID || '--'}
                      </span>
                    </TableCell> */}
                    <TableCell
                     align="center"
                      onClick={(e) =>
                        totalOrders[city._id]?.status?.VALID &&
                        selectOrders(city._id, 'VALID')
                      }
                    >
                      <span style={checkIfSelected(city._id, 'VALID')}>
                        {totalOrders[city._id]?.status?.VALID || '--'}
                      </span>
                    </TableCell>
                    <TableCell
                      align="center"
                      onClick={(e) =>
                        totalOrders[city._id]?.status
                          ?.WAITING_FOR_ACCEPT_STORE &&
                        selectOrders(city._id, 'WAITING_FOR_ACCEPT_STORE')
                      }
                    >
                      <span
                        style={checkIfSelected(
                          city._id,
                          'WAITING_FOR_ACCEPT_STORE',
                        )}
                      >
                        {totalOrders[city._id]?.status
                          ?.WAITING_FOR_ACCEPT_STORE || '--'}
                      </span>
                    </TableCell>
                    <TableCell
                      align="center"
                      onClick={(e) =>
                        totalOrders[city._id]?.status?.SYNC_PREPARATION_TIME &&
                        selectOrders(city._id, 'SYNC_PREPARATION_TIME')
                      }
                    >
                      <span
                        style={checkIfSelected(
                          city._id,
                          'SYNC_PREPARATION_TIME',
                        )}
                      >
                        {totalOrders[city._id]?.status?.SYNC_PREPARATION_TIME ||
                          '--'}
                      </span>
                    </TableCell>
                    <TableCell
                      align="center"
                      onClick={(e) =>
                        totalOrders[city._id]?.status?.MANUEL &&
                        selectOrders(city._id, 'MANUEL')
                      }
                    >
                      <span style={checkIfSelected(city._id, 'MANUEL')}>
                        {totalOrders[city._id]?.status?.MANUEL || '--'}
                      </span>
                    </TableCell>
                    <TableCell
                      align="center"
                      onClick={(e) =>
                        totalOrders[city._id]?.status?.ON_HOLD &&
                        selectOrders(city._id, 'ON_HOLD')
                      }
                    >
                      <span style={checkIfSelected(city._id, 'ON_HOLD')}>
                        {totalOrders[city._id]?.status?.ON_HOLD || '--'}
                      </span>
                    </TableCell>
                    <TableCell
                      align="center"
                      onClick={(e) =>
                        totalOrders[city._id]?.status?.DISPATCHING &&
                        selectOrders(city._id, 'DISPATCHING')
                      }
                    >
                      <span style={checkIfSelected(city._id, 'DISPATCHING')}>
                        {totalOrders[city._id]?.status?.DISPATCHING || '--'}
                      </span>
                    </TableCell>

                    <TableCell
                      align="center"
                      onClick={(e) =>
                        totalOrders[city._id]?.status?.ASSIGNED &&
                        selectOrders(city._id, 'ASSIGNED')
                      }
                    >
                      <span style={checkIfSelected(city._id, 'ASSIGNED')}>
                        {totalOrders[city._id]?.status?.ASSIGNED || '--'}
                      </span>
                    </TableCell>
                    <TableCell
                      align="center"
                      onClick={(e) =>
                        totalOrders[city._id]?.status?.DISPATCHED &&
                        selectOrders(city._id, 'DISPATCHED')
                      }
                    >
                      <span style={checkIfSelected(city._id, 'DISPATCHED')}>
                        {totalOrders[city._id]?.status?.DISPATCHED || '--'}
                      </span>
                    </TableCell>
                  </TableRow>
                )
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
