import React, { useState, useEffect, useRef } from 'react'
import { useAlert } from 'react-alert';
import { Box, Grid } from '@material-ui/core';
import { getCards } from '../services/serverApi.service'
import { getCustmore } from '../../customers/services/serverApi.customer'
import DataTableContainerComponent from '../../../components/DataTableSortingContainer.component';
import { formatTime } from '../../../helpers/index';
import CustomButton from '../../../components/material-dashboard-pro-react/CustomButtons/Button'
import AsyncSelect from 'react-select/async';


export default function CardsPage() {
  const [resultsData, setResultsData] = useState({
    total: 0,
    items: [],
    tableItems: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  // const classes = useStyles();
  // const pages = [10, 250, 500]
  const inpRef = useRef();
  // console.log(inpRef.current.value);
  const [cards, setCards] = useState([]);
  const [page, setPage] = useState(0)
  let numberOfRows = [100, 500, 1000];
  const [rowsPerPage, setRowsPerPage] = useState(numberOfRows[0])
  const [searchText, setSearchText] = useState('a')
  const [total, setTotal] = useState('')
  const alert = useAlert()
  const [deliveryMen, setDeliveryMen] = useState([])
  const [customerId, setCustomerId] = useState('')
  const [inputValue, setInputValue] = useState('')
  const [cardNumber, setCardNumber] = useState('')
  // console.log(page+'page ',rowsPerPage);

  const prepareShowCommissionHistory = (item, index) => {
    return {
      ...item,
      approvedCol: item.approved ? 'YES' : 'NO',
      selectedCol: item.selected ? 'YES' : 'NO',
      isWhiteListedCol: item.isWhiteListed ? 'YES' : 'NO',
      isPhoneValidCol: item.customer_detail?.isPhoneValid ? 'YES' : 'NO',
      createdAtCol: item.createdAt ? formatTime(item.createdAt) : '--',

    };
  }
  // console.log(customerId);
  const loadCardsList = (skip, limit, cientId, num) => {
    // console.log(txt);
    setIsLoading(true);
    getCards(skip, limit, true, cientId, num)
      .then(({ data }) => {
        // console.log(data);
        data.total && setTotal(data.total)
        data.payments && setCards(data.payments)
      })
      .catch(error => {
        // alert.show(error, {
        //     type: 'info',
        // }),
        console.log(error)
        setResultsData({
          total: 0,
          items: [],
          tableItems: [],
        })
      })

      .finally(() => {
        setIsLoading(false);
      });
  };
  // const findDeliveryMen = (txt)=>{
  //   getApprouvedProvider(0,20,'',txt)
  //   .then(({data})=>setDeliveryMen(data.deliveryMan))
  //   .catch((error)=>console.log(error))
  // }
  const fetchData = (inputValue, callback) => {
    setTimeout(() => {
      inputValue && getCustmore(0, 10, true, inputValue)
        .then(({ data }) => {
          // console.log(data);
          const tempArray = [];
          if (data.customers) {
            if (data.customers.length) {
              data.customers.forEach((element) => {
                tempArray.push({
                  label: `${element.name}`,
                  _id: element._id,
                });
              });
            }

            // else {
            //   tempArray.push({
            //     label: `${data.name}`,
            //     _id: data._id,
            //   });
            // }
          }
          callback(tempArray);
        })
        .catch((error) => {
          console.log(error);
        });
    }, 1000);
  };

  const onSearchChange = (selectedOption) => {
    if (selectedOption) {
      // console.log(selectedOption);
      setCustomerId(selectedOption._id);
      page > 0 && setPage(0)
      loadCardsList(page > 0 ? 0 : page, rowsPerPage, selectedOption._id)
    }
  };
  const SearchComponent = () => {
    return (
      <Grid container spacing={2}>

        <Grid item xs={4}>
          <AsyncSelect
            single
            value={inputValue}
            loadOptions={fetchData}
            placeholder="find customer"
            onChange={(e) => {
              onSearchChange(e);
            }}
          />
        </Grid>
        {/* <Grid item xs={4} container justify="flex-end"
        > */}
          <Grid item xs={4}>
            <input type='text'
              ref={inpRef}
              placeholder='crad search'
              style={{
                border: "none",
                borderBottom: '1px solid black'
              }}
              defaultValue={cardNumber}

            />
          </Grid>
          <Grid item xs={4} container justify="flex-end">
          <Box textAlign="left">
            <CustomButton
              round
              type="submit"
              color="success"
              size="sm"
              loaderColor="white"
              // onClick={storeSearch}
              onClick={()=>{
                setCardNumber(inpRef.current.value)
                setCustomerId('')
                page>0&&setPage(0)
                loadCardsList(page > 0 ? 0 : page, rowsPerPage, "",inpRef.current.value)
              }
              }
            >
              find Card
            </CustomButton>
          </Box>
          <Box textAlign="left">
            <CustomButton
              round
              type="submit"
              color="google"
              size="sm"
              loaderColor="white"
              onClick={() => {
                setCustomerId('')
                setCardNumber('')
                page > 0 && setPage(0)
                loadCardsList(page > 0 ? 0 : page, rowsPerPage, "","")
              }}
            >
              clear
            </CustomButton>
          </Box>
          </Grid>
          
        {/* </Grid> */}
      </Grid>
    )
  }
  const handlePaginate = (val) => {
    setResultsData({
      items: [],
      tableItems: [],
    });
    loadCardsList(val.pageSelect, val.numberOfRows, customerId, cardNumber);
    setPage(val.pageSelect);
    console.log(val.pageSelect);
    setRowsPerPage(val.numberOfRows);
  };
  console.log('state',page);
  useEffect(() => {
    cards && cards.length > 0 && setResultsData({
      items: cards.map((item, index) => {
        return prepareShowCommissionHistory(item, index);
      }),
    })
  }, [cards])
  return (
    <>
      <DataTableContainerComponent
        total={total}
        resultsData={resultsData}
        initData={resultsData.items}
        page={page + 1}
        columns={[

          {
            Header: 'numCarte',
            accessor: 'numCarte',
          },
          {
            Header: 'type',
            accessor: 'type',
          },
          {
            Header: 'approved',
            accessor: 'approvedCol',
          },
          {
            Header: 'selected',
            accessor: 'selectedCol',
          },
          {
            Header: 'isWhiteListed',
            accessor: 'isWhiteListedCol',
          },
          {
            Header: 'customer',
            accessor: 'customer_detail.name',
          },
          {
            Header: 'email',
            accessor: 'customer_detail.email',
          },
          {
            Header: 'phone',
            accessor: 'customer_detail.phone',
          },
          {
            Header: 'isPhoneValid',
            accessor: 'isPhoneValidCol',
          },
          {
            Header: 'status',
            accessor: 'customer_detail.status',
          },
          {
            Header: 'nbOrders',
            accessor: 'customer_detail.nbOrders',
          },

        ]}
        title="Cards List"
        isLoading={isLoading}
        loadItemsList={loadCardsList}
        onPaginate={handlePaginate}
        isPaginate={true}
        searchInp={true}
        SearchComponent={SearchComponent}
      />
    </>
  );
}
