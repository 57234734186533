import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  cancelOrderAction,
  changeOrderStatusAction,
  changeOrderAlarmAction,
  getOrderRequestAction,
  initialOrderAction,
  newOrderAction,
  openModelAction,
  closeModelAction,
  changeOrderAlarmDataAction,
} from '../redux/actions/order.actions';
import { getOrdersDataState } from '../redux/selectors';
import { getUserDataState } from '../../auth/redux/selectors';
// formik
import * as yup from 'yup';
import { useFormik } from 'formik';
import DeliveryHeaderInfoComponenet from '../components/Order.header.info.componenet';
import { returnBackroundColorForLivryCmd } from '../utils';

import moment from 'moment';

import {
  acceptOrRejectOrder,
  cancelOrder,
  getCities,
  socketConnect,
  updateAlarm,
  updateOrderStatus,
  recreateOrder,
} from '../services/serverApi.service';
import {
  fetchSetting,
  getAdmins,
} from '../../setting/services/serverApi.service';

import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TableCell, TableRow } from '@mui/material';
import '@progress/kendo-theme-default/dist/all.css';
import DataTableSortingContainer from '../../../components/DataTableSortingContainer.component';
import ButtonComponent from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import SelectBoxComponent from '../../../components/SelectBox.component';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import StoreIcon from '@material-ui/icons/Store';
import CommentIcon from '@mui/icons-material/Comment';
import PersonIcon from '@material-ui/icons/Person';
import LocationOn from '@material-ui/icons/LocationOn';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import CachedIcon from '@mui/icons-material/Cached';
import SportsMotorsportsIcon from '@material-ui/icons/SportsMotorsports';
import MotorcycleIcon from '@material-ui/icons/Motorcycle';
import CloseleIcon from '@material-ui/icons/Close';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import BlockIcon from '@mui/icons-material/Block';

import OrderDetail from '../components/OrderDetail.component';
import { useAlert } from 'react-alert';
import StoreDetailsComponent from '../components/StoreDetails.component';
import ClientDetailsComponent from '../components/ClientDetail.component';
import ProviderDetailsComponent from '../components/ProviderDetails.component';
import DisplayOrderInMap from '../components/DisplayOrderInMap';
import DisplayPositionsMap from '../components/DisplayPositionsMap';
import DisplayPositionsMapFromSocketUpdate from '../components/DisplayPositionsMapFromSocketUpdate';
import ChangeDuration from '../components/ChangeDuration.components';
import CommentComponent from '../components/Comment.component';
import NapsPayloadComponent from '../components/naps.payload.component';
import AssignOrderComponent from '../components/Assign.order.component';
import OrdersModelPage from './Orders.model.page';
import DeliveryMenTableComponent from '../components/DeliveryMen.table.component';
import InfoAlarmComponent from '../components/Info.alarm.component';
import InfoOrdersComponent from '../components/Info.orders.component';
import InfoDispatchingComponent from '../components/Info.dispatching.component';
import ModalComponent from '../../../components/Modal.component';
import CustomInput from '../../../components/material-dashboard-pro-react/CustomInput/CustomInput';
// status
import { styled } from '@mui/material/styles';

import {
  CustomerStatus,
  DeliveryManStatus,
  deliveryStatus,
  orderDispatchingStatus,
  StoreStatus,
} from '../../../config/status.config';
import ButtonMat from '@mui/material/Button';
import { formatTimeWithoutYear } from '../../../helpers/index';
import {
  accptedToArrivedStausArray,
  allArray,
  regroupmentOrderTypes,
  startDeliveryToDelivredStausArray,
  statusTypes,
  switchForAvatarColor,
  statusBackgroundColor,
  statusBackgroundColorNp,
  switchForPaymentMethod,
  columns,
  dispatchedArray,
} from '../mock';
import AvatarGroup from 'react-avatar-group';
import notifSong from '../../../assets/audio/pristine-609.mp3';
import AllPartnerDeliveries from '../../AllPartnersOrders/pages/Current.orders.page';

var selectedCityArry = [];
const validationSchema = yup.object({
  handleMessage: yup.string().required(),
});
const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip arrow {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

export default function CurrentOrderPage() {
  const myRef = useRef(null);
  const formik = useFormik({
    initialValues: {
      handleMessage: '',
    },
    validationSchema,
  });
  const alert = useAlert();
  const selectedCityArray = JSON.parse(
    localStorage.getItem('selectedCityArray'),
  );
  const dispatch = useDispatch();
  const { orders, loading, error, invalidOrders, watcherCounter } =
    useSelector(getOrdersDataState);
  const userData = useSelector(getUserDataState);
  const [adminsList, setAdminsList] = useState([]);
  const [admin, setAdmin] = useState({
    _id: userData.user._id,
    email: userData.user.email,
    username: userData.user.username,
    userName: userData.user.username,
    entityId: userData.user.entityId,
    scope: userData.user.scope,
    by: 'admin',
  });

  const [cities, setCities] = useState([]);
  const [day, setDay] = useState(moment(new Date()).format('DD'));
  const [isLoading, setIsLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [socket, setSocket] = useState(null);
  const [open, setOpen] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [order, setOrder] = useState(null);
  const [pricePaid, setPricePaid] = useState(0);
  const [openModal, setOpenModal] = useState('');
  const [orderId, setOrderId] = useState('');
  const [orderIdForComments, setOrderIdForComments] = useState('');
  const [displayType, setDisplayType] = useState(
    regroupmentOrderTypes.chronologic,
  );
  const [indexStore, setIndexStore] = useState(-1);
  const [orderRow, setOrdeRow] = useState('');
  const [orderIdForModel, setOrderIdForModel] = useState('');
  const [filterBy, setFilterBy] = useState(null);
  const [selectedAlarm, setSelectedAlarm] = useState({});
  const [selectedDispatchingStatus, setSelectedDispatchingStatus] = useState(
    {},
  );
  const [selectedOrders, setSelectedOrders] = useState({});
  const [selectedInvalidAlarm, setSelectedInvalidAlarm] = useState([]);
  const [filterOrder, setFilterOrder] = useState({
    All: true,
    WAITING_FOR_ACCEPT: true,
    ACCEPTED: true,
  });
  const [cancel, setCancel] = useState([]);
  const [cancelRaison, setCancelRaison] = useState({ cancelReason: '' });
  const [resultsIvalidOrdersData, setResultsInvalidOrdersData] = useState({
    invalidOrders: [],
  });
  const [resultsData, setResultsData] = useState({
    total: 0,
    items: [],
    tableItems: [],
    ON_HOLD: [],
    WAITING_FOR_ACCEPT: [],
    ACCEPT_TO_INVOICE_NP: [],
    ACCEPT_TO_INVOICE_P: [],
    STARTED_DELIVERY_TO_DELIVRED: [],
  });

  const [selectedCities, setSelectedCities] = useState(
    selectedCityArray || allArray,
  );
  const customColumnStyle = {
    maxWidth: '90px',
    minWidth: '90px',
    fontSize: '13px',
  };

  const fetchAdmins = () => {
    getAdmins()
      .then(({ data }) => setAdminsList(data))
      .catch((error) => console.log(error));
  };

  const handleOpenModal = (type) => {
    // console.log(order);
    handleClose();
    setOpen(type);
  };
  const handleModal = (item, index, type) => {
    handleClose();
    setOrdeRow(item);
    setIndexStore(index);
    setOpenModal(type);
  };

  const handleCloseModal = () => {
    setOpen('');
    setOpenModal('');
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const accept_Or_Reject_Order = (accept, deliveryId) => {
    if (!!deliveryId) {
      setIsLoading(true);
      acceptOrRejectOrder(order._id, {
        deliveryManId: deliveryId,
        accept: accept,
        admin,
      }).then(({ data }) => {
        setIsLoading(false);
        handleCloseModal();
      });
    }
  };
  const handleStatus = () => {
    const deliveryManId = !!order.deliveryMan ? order.deliveryMan._id : null;

    if (deliveryManId) {
      updateOrderStatus(order._id, {
        deliveryManId: deliveryManId,
        status: selectedStatus,
        invoiceUrl: 'url:///',
        pricePaid: pricePaid,
        admin,
      }).then(({ data }) => {
        handleCloseModal();
      });
    }
  };
  const handleCancel = () => {
    cancelOrder(order._id, { ...cancelRaison, ...admin })
      .then(({ data }) => {
        dispatch(cancelOrderAction(order._id));
      })
      .catch((error) => console.log(error))
      .finally(() => handleCloseModal());
  };
  const getCitiesList = () => {
    getCities()
      .then(({ data }) => {
        const cityArray = [{ name: 'All', _id: 'all' }].concat(data);
        setCities(cityArray);
      })
      .catch((error) => console.log(error));
  };
  const notification = () => {
    const audio = new Audio(notifSong);
    audio.play().catch((error) => {
      console.log('Audio error::', { error });
    });
  };
  const sharedStyleForStatus = {
    width: '120px',
    fontSize: '11px',
    padding: '5px',
    borderRadius: '4px',
    color: 'white',
  };
  const renderStatus = (
    dispatchingStatus,
    deliveryManStatus,
    startedPreparation,
  ) => {
    // if (dispatchingStatus == 'INVALID' || dispatchingStatus == 'ON_HOLD') {
    //   return (
    //     <span
    //       style={{
    //         backgroundColor: dispatchingStatus == 'ON_HOLD' ? '#f246f4' : 'red',
    //         ...sharedStyleForStatus,
    //       }}
    //     >
    //       {dispatchedArray.includes(dispatchingStatus)
    //         ? dispatchingStatus
    //         : deliveryManStatus}
    //       aa
    //       {/* {dispatchingStatus} */}
    //     </span>
    //   );
    // }
    const colors = {
      WAITING_FOR_ACCEPT: '#ffc107',
      ACCEPTED: '#ff7986',
      START_PREPARATION: startedPreparation ? '#3496ff' : '#6c757d',
      COMING_FOR_DELIVERY: startedPreparation ? '#3496ff' : '#6c757d',
      ARRIVED_TO_PICKUP: startedPreparation ? '#3496ff' : '#6c757d',
      UPLOAD_INVOICE: '#46e06a',
      STARTED_DELIVERY: '#46e06a',
      ARRIVED_TO_DESTINATION: '#46e06a',
      DELIVERED: '#46e06a',
      VALID: '',
      ON_HOLD: '#f246f4',
      DISPATCHING: '',
      DISPATCHED: '#3496ff',
      ASSIGNED: '#3496ff',
      SYNC_PREPARATION_TIME: '#6c757d',
      WAITING_FOR_ACCEPT_STORE: '#ffc107',
      MANUEL: '#6c757d',
    };

    // 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning',
    return (
      <span
        style={{
          backgroundColor:
            colors[
              dispatchingStatus === 'DISPATCHED'
                ? deliveryManStatus
                : dispatchingStatus
            ] || 'red',
          width: '120px',
          fontSize: '11px',
          padding: '5px',
          borderRadius: '4px',
          color: 'white',
        }}
      >
        {/* {!dispatchedArray.includes(dispatchingStatus)
          ? dispatchingStatus
          : deliveryManStatus} */}
        {dispatchingStatus === 'DISPATCHED'
          ? deliveryStatus[deliveryManStatus] || deliveryManStatus
          : orderDispatchingStatus[dispatchingStatus] || dispatchingStatus}
        {!startedPreparation &&
          (deliveryManStatus == 'COMING_FOR_DELIVERY' ||
            deliveryManStatus == 'ARRIVED_TO_PICKUP' ||
            deliveryManStatus == 'UPLOAD_INVOICE') &&
          ' NP'}
      </span>
    );
  };

  const iconsSharedStyle = {
    cursor: 'pointer',
    width: 21,
    height: 21,
    margin: 2,
    borderRadius: 2,
    padding: 2,
  };
  const rowColor = (deliveryMan, val1, val2, val3, nbrOfOrders, blocked) => {
    if (blocked) {
      return '#735392';
    }
    if (deliveryMan) {
      if (val1 === val2 && val1 === val3 && val2 === val3) {
        return nbrOfOrders > 1 ? 'red' : '';
      } else {
        return '#FFCC00';
      }
    } else {
      return nbrOfOrders > 1 ? 'red' : '';
    }
  };
  const reCreateOrderWithLivry = (id) => {
    recreateOrder(id)
      .then(({ data }) =>
        alert.show('receated', {
          type: 'success',
        }),
      )
      .catch((err) =>
        // console.log(err?.response?.data?.message),
        alert.show(err?.response?.data?.message, {
          type: 'error',
        }),
      );
  };
  const showOrders = (item, index, nbrOfOrders, validOrder) => {
    if (item) {
      return {
        ...item,
        cityName: item.city.name.substring(0, 4),
        validator:
          (adminsList || []).find((x) => x.userId == item?.validatorId)
            ?.username || item?.validatorId,
        Rowcolor:
          // item.deliveryMan
          //   ? (item?.cityId != item?.payment?.cityId ||
          //       item.deliveryMan?.cityId != item?.payment?.cityId) &&
          //     // ? ''
          //     'green'
          //   : '',
          // ||
          // (nbrOfOrders > 1 ? 'red' : '')
          // if (item.deliveryMan){

          // },
          // item.deliveryMan
          // ?
          // item?.deliveryMan
          //   ? item?.cityId != item?.payment?.cityId ||
          //     item?.deliveryMan?.cityId != item?.payment?.cityId ||
          //     (item?.cityId != item?.payment?.cityId && '#FFCC00')
          //   : '',
          // : ''
          // : '' || (nbrOfOrders > 1 ? 'red' : ''),
          rowColor(
            item?.deliveryMan,
            item?.cityId || item?.city?._id,
            item?.payment?.cityId,
            item?.deliveryMan?.cityId,
            nbrOfOrders,
            item.blocked,
          ),
        backgroundColor: returnBackroundColorForLivryCmd(
          item.withLivry,
          item.disponibilty,
          item.errorLivry,
        ),
        errorMsgLivry: item?.errorLivry && item?.errorMsg,
        lastComment: (
          <HtmlTooltip
            title={
              <React.Fragment>
                <CommentComponent close={() => {}} admin={admin} order={item} />
              </React.Fragment>
            }
          >
            {item?.comments && item.comments.length ? (
              <span
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  handleModal(item, index, 'Comment');
                  setOrderIdForComments(item._id);
                }}
              >
                {' '}
                {item.comments[item.comments.length - 1]?.adminName}
                {' // '}
                {item.comments[item.comments.length - 1]?.comment}{' '}
              </span>
            ) : (
              <CommentIcon
                style={{ cursor: 'pointer', fontSize: '22px' }}
                onClick={() => {
                  handleModal(item, index, 'Comment');
                  setOrderIdForComments(item._id);
                }}
              />
            )}
          </HtmlTooltip>
        ),
        deliveryManStatusInp: (
          <HtmlTooltip
            title={
              <React.Fragment>
                <TableRow>
                  <TableCell align="center">dispatching</TableCell>
                  <TableCell align="center">
                    <span
                      style={{
                        backgroundColor:
                          item.dispatchingStatus == 'ON_HOLD'
                            ? statusBackgroundColor.ON_HOLD
                            : statusBackgroundColor.INVALID,
                        ...sharedStyleForStatus,
                      }}
                    >
                      {orderDispatchingStatus[item.dispatchingStatus] ||
                        item.dispatchingStatus}
                    </span>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">deliveryMan</TableCell>
                  <TableCell align="center">
                    <span
                      style={{
                        backgroundColor:
                          statusBackgroundColor[item.deliveryManStatus] ||
                          'red',
                        ...sharedStyleForStatus,
                      }}
                    >
                      {deliveryStatus[item.deliveryManStatus] ||
                        item.deliveryManStatus}
                    </span>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">store</TableCell>
                  <TableCell align="center">
                    <span
                      style={{
                        backgroundColor:
                          statusBackgroundColor[item.storeStatus] || 'red',
                        ...sharedStyleForStatus,
                      }}
                    >
                      {StoreStatus[item.storeStatus] || item.storeStatus}
                    </span>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">customer</TableCell>
                  <TableCell align="center">
                    <span
                      style={{
                        backgroundColor:
                          statusBackgroundColor[item.customerStatus] || 'red',
                        ...sharedStyleForStatus,
                      }}
                    >
                      {CustomerStatus[item.customerStatus] ||
                        item.customerStatus}
                    </span>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            }
          >
            {renderStatus(
              item.dispatchingStatus,
              item.deliveryManStatus,
              item.startedPreparation,
            )}
          </HtmlTooltip>
        ),

        storeName: item?.store?.name,
        clientName: item?.customer?.name,
        deliveryManInp: item?.deliveryMan && item?.deliveryMan.name,
        createdAtInp: item.createdAt && (
          <div style={{ fontSize: '11px' }}>
            {moment(item.createdAt).format('DD') == day
              ? moment(item.createdAt).format('hh:mm:ss')
              : moment(item.createdAt).format('hh:mm:ss DD/MM')}
            <br />
            <span style={{ color: 'red' }}>
              {item.payment.scheduled &&
                item.payment.shceduleDate !== null &&
                formatTimeWithoutYear(item.payment.shceduleDate)}
            </span>
            <span style={{ color: '#0000fe' }}>
              {item?.withLivryPartner && item?.organisationOrderDetail?.code}
            </span>
          </div>
        ),

        duration: item?.deliveryManStatusTimes && (
          <>
            <ChangeDuration
              deliveryManStatusTime={
                item?.deliveryManStatusTimes &&
                item?.deliveryManStatusTimes.length &&
                item?.deliveryManStatusTimes[
                  item?.deliveryManStatusTimes.length - 1
                ]?.date
              }
              time={item.createdAt}
            />
          </>
        ),
        orderPriceCol: item?.payment?.total && item?.payment?.total.toFixed(2),
        paymentMode:
          item?.payment?.payments &&
          item?.payment?.payments.map(
            (mode) =>
              mode.amount > 0 &&
              (mode.gateway == 'Cash' ? (
                <p>{mode.gateway}</p>
              ) : (
                <ButtonMat
                  variant={'contained'}
                  className="mt-1"
                  style={{
                    maxWidth: '70px',
                    maxHeight: '40px',
                    minWidth: '70px',
                    minHeight: '30px',
                    fontSize: '10px',
                  }}
                  color={switchForPaymentMethod(mode.gateway)}
                  onClick={() => {
                    mode.gateway == 'Card' &&
                      item?.payment?.napsPayload &&
                      handleModal(item, index, 'napsPayload');
                  }}
                >
                  {mode.gateway}
                  {mode.gateway == 'Card' ? (
                    <>
                      <br></br>
                      <p>{item?.payment?.napsPayload ? '' : 'Error'}</p>
                    </>
                  ) : (
                    <p></p>
                  )}
                </ButtonMat>
              )),
          ),
        menu: !validOrder ? (
          <div></div>
        ) : (
          <div>
            <Tooltip arrow title="Detail Commande" placement="top">
              <AspectRatioIcon
                style={{
                  ...iconsSharedStyle,
                  color: '#0705f7',
                  backgroundColor: '#0705f730',
                }}
                onClick={() => {
                  handleOpenModal('detail');
                  setOrder(item);
                }}
              />
            </Tooltip>
            <Tooltip arrow title="Assigner Commande" placement="top">
              <MotorcycleIcon
                style={{
                  ...iconsSharedStyle,
                  color: '#1fd62e',
                  backgroundColor: '#1fd62e30',
                }}
                onClick={() => {
                  // handleOpenModal('assigned');
                  handleModal(item, index, 'assigned');
                  setOrder(item);
                }}
              />
            </Tooltip>
            <Tooltip arrow title="Annuler la commande" placement="top">
              <CloseleIcon
                style={{
                  ...iconsSharedStyle,
                  color: '#ff0000',
                  backgroundColor: '#ff000030',
                }}
                onClick={() => {
                  handleOpenModal('cancel');
                  setOrder(item);
                }}
              />
            </Tooltip>
            <Tooltip arrow title="Aide a la decision" placement="top">
              <LocationOn
                style={{
                  ...iconsSharedStyle,
                  color: '#000000',
                  backgroundColor: '#00000030',
                }}
                onClick={() => {
                  handleModal(item, index, 'Maps');
                }}
              />
            </Tooltip>
            <Tooltip arrow title="Detail Client" placement="bottom">
              <PersonIcon
                style={{
                  ...iconsSharedStyle,
                  color: '#000000',
                  backgroundColor: '#00000030',
                }}
                onClick={() => {
                  handleModal(item, index, 'clinet details');
                }}
              />
            </Tooltip>
            <Tooltip arrow title="position livreur" placement="top">
              <MyLocationIcon
                style={{
                  ...iconsSharedStyle,
                  color: '#000000',
                  backgroundColor: '#00000030',
                }}
                onClick={() => {
                  handleModal(item, index, 'my location');
                }}
              />
            </Tooltip>
            <Tooltip arrow title="position livreur" placement="top">
              <MyLocationIcon
                style={{
                  ...iconsSharedStyle,
                  color: 'red',
                  backgroundColor: '#00000030',
                }}
                onClick={() => {
                  handleModal(item, index, 'myPosition');
                }}
              />
            </Tooltip>

            {/* <Tooltip arrow title="Detail Store" placement="bottom">
              <StoreIcon
                style={{
                  ...iconsSharedStyle,
                  color: '#000000',
                  backgroundColor: '#00000030',
                }}
                onClick={() => {
                  handleModal(item, index, 'store details');
                }}
              />
            </Tooltip> */}
            {/* <Tooltip arrow title="Detail Livreur" placement="bottom">
              <SportsMotorsportsIcon
                style={{
                  ...iconsSharedStyle,
                  color: '#000000',
                  backgroundColor: '#00000030',
                }}
                onClick={() => {
                  handleModal(item, index, 'provider details');
                }}
              />

            </Tooltip> */}
            <Tooltip arrow title="Changer Status Livreur" placement="bottom">
              <SyncAltIcon
                style={{
                  ...iconsSharedStyle,
                  color: '#000000',
                  backgroundColor: '#00000030',
                }}
                onClick={() => {
                  handleOpenModal('status');
                  setOrder(item);
                  setSelectedStatus(item?.deliveryManStatus);
                }}
              />
            </Tooltip>
            {item.blocked && (
              <Tooltip arrow title="Blocked CMD" placement="bottom">
                <BlockIcon
                  style={{
                    ...iconsSharedStyle,
                    color: '#735392',
                    backgroundColor: '#00000030',
                  }}
                  onClick={() => {
                    handleOpenModal('blocked');
                    setOrder(item);
                    setSelectedStatus(item?.deliveryManStatus);
                  }}
                />
              </Tooltip>
            )}
            {item?.withLivry && (
              <Tooltip arrow title="recreate" placement="top">
                <CachedIcon
                  style={{
                    ...iconsSharedStyle,
                    color: 'red',
                    backgroundColor: '#00000030',
                  }}
                  onClick={() => {
                    // handleModal(item, index, 'myPosition');
                    console.log('aaaa');
                    reCreateOrderWithLivry(item?._id);
                  }}
                />
              </Tooltip>
            )}
          </div>
        ),
        alarmes: item.alarms && item.alarms.length > 0 && (
          <div>
            <Grid container spacing={1}>
              {item.alarms.map(
                (alarm) =>
                  alarm?.visible && (
                    <Grid
                      item
                      xs={4}
                      onClick={() => {
                        dispatch(openModelAction(item._id, index, 'Alarm'));
                      }}
                    >
                      <AvatarGroup
                        avatars={[alarm?.name.split('_').join(' ')]}
                        initialCharacters={alarm?.name.split('_').length}
                        max={3}
                        size={25}
                        displayAllOnHover
                        shadow={2}
                        fontSize={0.4}
                        backgroundColor={switchForAvatarColor(alarm?.status)}
                      />
                    </Grid>
                  ),
              )}
            </Grid>
          </div>
        ),
      };
    }
  };
  const setOrderToState = async (data) => {
    const newDataArray = [...data];
    const customerIdObject = {};
    newDataArray.forEach((item) => {
      customerIdObject[item?.customer?._id] =
        (customerIdObject[item?.customer?._id] || 0) + 1;
    });
    switch (displayType) {
      case regroupmentOrderTypes.chronologic:
        newDataArray &&
          setResultsData({
            items: await newDataArray.map((item, index) => {
              return showOrders(
                item,
                index,
                customerIdObject[item?.customer?._id],
                true,
              );
            }),
          });
        break;
      case regroupmentOrderTypes.byStatus:
        newDataArray &&
          setResultsData({
            ON_HOLD: await newDataArray
              .filter((x) => x.dispatchingStatus == statusTypes.ON_HOLD)
              .map((item, index) => {
                return showOrders(
                  item,
                  index,
                  customerIdObject[item.customerId],
                  true,
                );
              }),

            WAITING_FOR_ACCEPT: await newDataArray
              .filter(
                (x) =>
                  x.deliveryManStatus == statusTypes.WAITING_FOR_ACCEPT &&
                  x.dispatchingStatus !== statusTypes.ON_HOLD,
              )
              .map((item, index) => {
                return showOrders(
                  item,
                  index,
                  customerIdObject[item.customerId],
                  true,
                );
              }),

            ACCEPT_TO_INVOICE_NP: await newDataArray
              .filter(
                (x) =>
                  x.deliveryManStatus !== statusTypes.WAITING_FOR_ACCEPT &&
                  accptedToArrivedStausArray.includes(x.deliveryManStatus) &&
                  !x.deliveryManStatusTimes.find(
                    (y) => y.status == 'START_PREPARATION',
                  ),
              )
              .map((item, index) => {
                return showOrders(
                  item,
                  index,
                  customerIdObject[item.customerId],
                  true,
                );
              }),

            ACCEPT_TO_INVOICE_P: await newDataArray
              .filter(
                (x) =>
                  x.deliveryManStatus !== statusTypes.WAITING_FOR_ACCEPT &&
                  accptedToArrivedStausArray.includes(x.deliveryManStatus) &&
                  x.deliveryManStatusTimes.find(
                    (y) => y.status == 'START_PREPARATION',
                  ),
              )
              .map((item, index) => {
                return showOrders(
                  item,
                  index,
                  customerIdObject[item.customerId],
                  true,
                );
              }),

            STARTED_DELIVERY_TO_DELIVRED: await newDataArray
              .filter((x) =>
                startDeliveryToDelivredStausArray.includes(x.deliveryManStatus),
              )
              .map((item, index) => {
                return showOrders(
                  item,
                  index,
                  customerIdObject[item.customerId],
                  true,
                );
              }),
          });
        break;

      default:
        break;
    }
  };
  const setInvalidOrderToState = async (data) => {
    let newDataArray = [...data];
    const customerIdObject = {};
    newDataArray.forEach((item) => {
      customerIdObject[item?.customer?._id] =
        (customerIdObject[item?.customer?._id] || 0) + 1;
    });
    if (selectedInvalidAlarm && selectedInvalidAlarm.length > 0) {
      newDataArray = newDataArray.filter((order) =>
        (selectedInvalidAlarm || []).includes(order.cityId),
      );
    }
    setResultsInvalidOrdersData({
      invalidOrders: await newDataArray.map((item, index) => {
        return showOrders(
          item,
          index,
          customerIdObject[item?.customer?._id],
          false,
        );
      }),
    });
  };
  const fetchSettingFromAPI = () => {
    fetchSetting()
      .then(({ data }) => {
        data && data.length && setCancel(data[0].admin_cancel_reasons);
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    fetchAdmins();
    dispatch(initialOrderAction());
    fetchSettingFromAPI();
    if (!socket) {
      setSocket(socketConnect(userData.accessToken));
    }
    function subscribeToAdminRoom() {
      if (socket) {
        socket.emit('JOIN_ROOM', 'ADMIN_DISPATCHING');
      }
    }
    if (socket) {
      subscribeToAdminRoom();
    }
    getCitiesList();
  }, [socket]);

  const displayOrderUsingFilter = async () => {
    let allOrders = orders;
    let arrayOfCityId;
    switch (filterBy) {
      case 'orders':
        if (
          selectedOrders &&
          Object.keys(selectedOrders) &&
          Object.keys(selectedOrders).length > 0
        ) {
          arrayOfCityId = Object.keys(selectedOrders);
          allOrders = await allOrders.filter(
            (order) =>
              arrayOfCityId.includes(order.cityId) &&
              selectedOrders[order.cityId].includes(order.deliveryManStatus),
          );
        }
        // console.log(allOrders);
        break;
      case 'dispatchingStatus':
        if (
          selectedDispatchingStatus &&
          Object.keys(selectedDispatchingStatus) &&
          Object.keys(selectedDispatchingStatus).length > 0
        ) {
          arrayOfCityId = Object.keys(selectedDispatchingStatus);
          allOrders = await allOrders.filter(
            (order) =>
              arrayOfCityId.includes(order.cityId) &&
              selectedDispatchingStatus[order.cityId].includes(
                order.dispatchingStatus,
              ),
          );
        }
        // console.log(allOrders);
        break;
      case 'alarms':
        if (
          selectedAlarm &&
          Object.keys(selectedAlarm) &&
          Object.keys(selectedAlarm).length > 0
        ) {
          arrayOfCityId = Object.keys(selectedAlarm);
          allOrders = await allOrders.filter(
            (order) =>
              order.alarms &&
              order?.alarms.length > 0 &&
              arrayOfCityId.includes(order.cityId) &&
              order.alarms.find(
                (x) =>
                  x.visible &&
                  (selectedAlarm[order?.cityId] || []).find(
                    (y) => y.type == x.name && y.status == x.status,
                  ),
              ),
          );
        }
        break;

      default:
        break;
    }

    setOrderToState(allOrders && allOrders.length > 0 ? allOrders : orders);
  };
  useEffect(() => {
    !loading && orders && displayOrderUsingFilter();
    // }, [JSON.stringify(orders), selectedAlarm, selectedOrders, displayType]);
  }, [
    watcherCounter,
    selectedAlarm,
    selectedOrders,
    displayType,
    selectedDispatchingStatus,
  ]);
  useEffect(() => {
    if (
      (selectedAlarm || selectedOrders || selectedDispatchingStatus) &&
      // filterBy != 'none' ||
      filterBy
    ) {
      myRef.current.scrollIntoView();
    }
  }, [selectedAlarm, selectedOrders, selectedDispatchingStatus]);
  useEffect(() => {
    !loading && invalidOrders && setInvalidOrderToState(invalidOrders);
  }, [JSON.stringify(invalidOrders), selectedInvalidAlarm]);
  useEffect(() => {
    if (!selectedCityArray && cities.length) {
      localStorage.setItem('selectedCityArray', JSON.stringify(allArray));
      selectedCityArry = allArray;
      dispatch(getOrderRequestAction(allArray));
    } else {
      selectedCityArry = selectedCityArray;

      selectedCityArray &&
        selectedCityArray.length &&
        dispatch(getOrderRequestAction(selectedCityArray));
    }
  }, [cities]);

  useEffect(() => {
    socket &&
      socket.on('ORDER_STATUS_CHANGED', function (data) {
        if (
          selectedCityArry.includes('all') ||
          selectedCityArry.includes(data.city._id)
        ) {
          dispatch(changeOrderStatusAction(data));
        }
      });
    socket &&
      socket.on('NEW_ORDER', function (data) {
        if (
          selectedCityArry.includes('all') ||
          selectedCityArry.includes(data.city._id)
        ) {
          dispatch(newOrderAction(data));
          if (data.dispatchingStatus !== 'INVALID') {
            notification();
            alert.show('new order', {
              type: 'success',
            });
          }
        }
      });

    socket &&
      userData &&
      socket.on('ORDER_CANCELLED', function (data) {
        dispatch(cancelOrderAction(data._id));
      });

    return () => {
      socket && socket.disconnect();
    };
  }, [socket]);

  useEffect(() => {
    if (socket) {
      socket.on('ALARM_UPDATED', function (data) {
        // if (
        //   selectedCityArry.includes('all') ||
        //   selectedCityArry.includes(data.city._id)
        // ) {
        // dispatch(changeOrderStatusAction(data));

        data && dispatch(changeOrderAlarmDataAction(data.orderId, data));
        // }
      });
    }
    return () => {
      socket && socket.disconnect();
    };
  }, [socket]);

  const onChangeSelectBox = (items) => {
    if (
      items.length > 1 &&
      items.includes('all') &&
      selectedCities.includes('all')
    ) {
      const index = items.indexOf('all');
      index >= 0 && items.splice(index, 1);
    } else if (
      items.length &&
      items.includes('all') &&
      !selectedCities.includes('all')
    ) {
      items = allArray;
    }
    localStorage.setItem('selectedCityArray', JSON.stringify(items));
    setSelectedCities(items);
    dispatch(getOrderRequestAction(items));
  };
  return (
    <div>
      <AllPartnerDeliveries />
      {/* <div id="main-root"></div> */}
      <div className={'p-3 my-3'} style={{ background: '#ffffff' }}>
        <Grid container justify="flex-end" className="mb-2">
          <ButtonComponent
            round
            type="submit"
            color="success"
            loading={isLoading}
            loaderColor="white"
            onClick={() => {
              dispatch(getOrderRequestAction(selectedCities));
            }}
            size="sm"
          >
            reload
          </ButtonComponent>
          <ButtonComponent
            round
            type="submit"
            color="google"
            loading={isLoading}
            loaderColor="white"
            onClick={() => {
              setFilterBy(null);
            }}
            size="sm"
          >
            reset
          </ButtonComponent>
        </Grid>
        <Grid container>
          <Grid item xs={4}>
            <FormControl style={{ minWidth: '200px' }}>
              <SelectBoxComponent
                items={Object.keys(regroupmentOrderTypes).map((type) => ({
                  value: type,
                  label: type,
                }))}
                inputLabel="display orders with"
                onValueChange={(items) => setDisplayType(items)}
                value={displayType}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl style={{ minWidth: '200px' }}>
              <SelectBoxComponent
                items={cities.map((city) => ({
                  value: city._id,
                  label: city.name,
                }))}
                inputLabel="cities"
                onValueChange={(items) => onChangeSelectBox(items)}
                value={selectedCities}
                multiple
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              id="combo-box-demo"
              options={orders || []}
              getOptionLabel={(option) => option.shortId}
              style={{ width: 250 }}
              onChange={(event, newValue) => {
                newValue && setOrderId(newValue.shortId);
                !newValue && setOrderId('');
              }}
              renderInput={(orders) => (
                <TextField
                  {...orders}
                  label="orders id"
                  variant="outlined"
                  onChange={(e) => console.log(e.target.value)}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}></Grid>
        </Grid>
      </div>
      <div className="mt-2">
        <InfoAlarmComponent
          selectedCities={selectedCities}
          cities={cities}
          // socket={socket}
          token={userData.accessToken}
          setSelectedAlarm={setSelectedAlarm}
          selectedAlarm={selectedAlarm}
          setSelectedInvalidAlarm={setSelectedInvalidAlarm}
          selectedInvalidAlarm={selectedInvalidAlarm}
          filterBy={filterBy}
          setFilterBy={setFilterBy}
        />
      </div>
      <div className="mt-3">
        <InfoDispatchingComponent
          cities={cities}
          setSelectedDispatchingStatus={setSelectedDispatchingStatus}
          selectedDispatchingStatus={selectedDispatchingStatus}
          setSelectedOrders={setSelectedOrders}
          selectedOrders={selectedOrders}
          filterBy={filterBy}
          setFilterBy={setFilterBy}
        />
      </div>

      <div className="mt-3">
        <InfoOrdersComponent
          cities={cities}
          setSelectedAlarm={setSelectedAlarm}
          selectedAlarm={selectedAlarm}
          setSelectedOrders={setSelectedOrders}
          selectedOrders={selectedOrders}
          filterBy={filterBy}
          setFilterBy={setFilterBy}
        />
      </div>
      <div className="mt-3">
        <DeliveryMenTableComponent
          selectedCities={selectedCities}
          cities={cities}
          socket={socket}
          setSelectedAlarm={setSelectedAlarm}
          selectedAlarm={selectedAlarm}
          setSelectedInvalidAlarm={setSelectedInvalidAlarm}
          selectedInvalidAlarm={selectedInvalidAlarm}
        />
      </div>

      <card>
        {orderId ? (
          <DataTableSortingContainer
            resultsData={resultsData}
            initData={[
              showOrders(
                (orders || []).find((item) => item.shortId == orderId),
                0,
                0,
              ),
            ]}
            columns={columns}
            title={<strong>search by Orde ID</strong>}
            customColumnStyle={customColumnStyle}
          />
        ) : (
          ''
        )}
        {!filterBy &&
        resultsIvalidOrdersData.invalidOrders &&
        resultsIvalidOrdersData.invalidOrders.length ? (
          <DataTableSortingContainer
            resultsData={resultsIvalidOrdersData}
            initData={resultsIvalidOrdersData.invalidOrders || []}
            columns={columns}
            title={<strong>invalid Orders</strong>}
            customColumnStyle={customColumnStyle}
          />
        ) : (
          ''
        )}
        {displayType == regroupmentOrderTypes.chronologic ? (
          <div ref={myRef}>
            <DataTableSortingContainer
              resultsData={resultsData}
              initData={(resultsData.items || []).filter(
                (x) => x.dispatchingStatus !== 'DISPATCHED',
              )}
              columns={columns}
              title={<strong>Dispatching service</strong>}
              customColumnStyle={customColumnStyle}
            />
            <DataTableSortingContainer
              resultsData={resultsData}
              initData={(resultsData.items || []).filter(
                (x) => x.dispatchingStatus === 'DISPATCHED',
              )}
              columns={columns}
              title={<strong>Livreur service</strong>}
              customColumnStyle={customColumnStyle}
            />
          </div>
        ) : (
          Object.keys(statusTypes).map((item) => (
            <DataTableSortingContainer
              resultsData={resultsData}
              initData={resultsData[item] || []}
              columns={columns}
              title={<strong>{item}</strong>}
              customColumnStyle={customColumnStyle}
              displayNbr={true}
            />
          ))
        )}
      </card>
      <OrdersModelPage />
      {openModal && openModal === 'assigned' && (
        <ModalComponent
          maxWidth={'lg'}
          close={handleCloseModal}
          visible={openModal == 'assigned'}
          title="assigned/accepted"
        >
          <AssignOrderComponent
            // close={handleCloseModal}
            orders={orders}
            accept_Or_Reject_Order={accept_Or_Reject_Order}
            order={order}
          />
        </ModalComponent>
      )}
      {open && open === 'status' && (
        <ModalComponent
          maxWidth={'sm'}
          close={handleCloseModal}
          visible={open === 'status'}
          title="status"
        >
          <div>
            <Grid container className="mb-2">
              <Grid item xs={6} style={{ display: 'flex' }}>
                orderID : <Typography>{order?.shortId}</Typography>
              </Grid>
              <Grid item xs={6} style={{ display: 'flex' }}>
                store : <Typography>{order?.store?.name}</Typography>
              </Grid>
              <Grid item xs={6} style={{ display: 'flex' }}>
                customer : <Typography>{order?.customer?.name}</Typography>
              </Grid>
              <Grid item xs={6} style={{ display: 'flex' }}>
                deliveryMan :{' '}
                <Typography>{order?.deliveryMan?.name}</Typography>
              </Grid>
            </Grid>
            <Box sx={{ display: 'flex' }} style={{ padding: '15px' }}>
              <FormControl style={{ width: '100%' }} variant="outlined">
                <InputLabel>Status</InputLabel>
                <Select
                  style={{ background: '#ffffff' }}
                  native
                  value={selectedStatus}
                  onChange={($event) => {
                    setSelectedStatus($event.target.value);
                  }}
                  label={'Status'}
                >
                  <option value="" disabled />
                  {DeliveryManStatus.map((item) => (
                    <option value={item.value}>{item.label}</option>
                  ))}
                </Select>
              </FormControl>
              <div style={{ float: 'right', margin: '15px' }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    handleStatus();
                  }}
                >
                  save
                </Button>
              </div>
            </Box>
          </div>
        </ModalComponent>
      )}
      {open && open === 'blocked' && (
        <ModalComponent
          maxWidth={'sm'}
          close={handleCloseModal}
          visible={open === 'blocked'}
          title="blocked"
        >
          <div>
            {/* <Grid container className="mb-2">
              <Grid item xs={6} style={{ display: 'flex' }}>
                orderID : <Typography>{order?.shortId}</Typography>
              </Grid>
              <Grid item xs={6} style={{ display: 'flex' }}>
                deliveryMan :{' '}
                <Typography>{order?.deliveryMan?.name}</Typography>
              </Grid>
              <Grid item xs={6} style={{ display: 'flex' }}>
                price des articles:{'  '}
                <Typography>
                  {Number(order.payment.originalOrderPrice)}
                </Typography>
              </Grid>
              <Grid item xs={6} style={{ display: 'flex' }}>
                price Invoice :{' '}
                <Typography>{order?.payment?.priceInvoice}</Typography>
              </Grid>
              <Grid item xs={6} style={{ display: 'flex' }}>
                différence:{'  '}
                <Typography>
                  {Number(
                    order.payment.originalOrderPrice -
                      order?.payment?.priceInvoice,
                  )}
                </Typography>
              </Grid>
            </Grid> */}
            <DeliveryHeaderInfoComponenet order={order} />
            {order?.blockArrivedToDestination && (
              <p style={{ color: 'red' }}>block ArrivedToDestination</p>
            )}
            {order?.blockDelivred && (
              <p style={{ color: 'red' }}>block Delivred</p>
            )}
            <Box sx={{ display: 'flex' }} style={{ padding: '15px' }}>
              <FormControl style={{ width: '100%' }} variant="outlined">
                <InputLabel>Status</InputLabel>
                <Select
                  style={{ background: '#ffffff' }}
                  native
                  value={selectedStatus}
                  onChange={($event) => {
                    setSelectedStatus($event.target.value);
                  }}
                  label={'Status'}
                >
                  <option value="" disabled />
                  {DeliveryManStatus.map((item) => (
                    <option value={item.value}>{item.label}</option>
                  ))}
                </Select>
              </FormControl>
              <div style={{ float: 'right', margin: '15px' }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    handleStatus();
                  }}
                >
                  valide
                </Button>
              </div>
            </Box>
          </div>
        </ModalComponent>
      )}
      {open && open === 'detail' && (
        <ModalComponent
          maxWidth={'lg'}
          close={handleCloseModal}
          visible={open === 'detail'}
          title="detail"
          fullHeight={true}
        >
          <OrderDetail item={order} />
        </ModalComponent>
      )}
      {open && open === 'cancel' && (
        <ModalComponent
          maxWidth={'sm'}
          close={handleCloseModal}
          visible={open === 'cancel'}
          title="cancel"
        >
          <div>
            <Box style={{ padding: '15px' }}>
              <Grid container className="mb-2">
                <Grid item xs={6} style={{ display: 'flex' }}>
                  orderID : <Typography>{order?.shortId}</Typography>
                </Grid>
                <Grid item xs={6} style={{ display: 'flex' }}>
                  store : <Typography>{order?.store?.name}</Typography>
                </Grid>
                <Grid item xs={6} style={{ display: 'flex' }}>
                  customer : <Typography>{order?.customer?.name}</Typography>
                </Grid>
                <Grid item xs={6} style={{ display: 'flex' }}>
                  deliveryMan :{' '}
                  <Typography>{order?.deliveryMan?.name}</Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sx={{ display: 'flex' }}>
                  <FormControl style={{ width: '100%' }} variant="outlined">
                    <InputLabel>Cancel</InputLabel>
                    <Select
                      style={{ background: '#ffffff' }}
                      native
                      value={cancelRaison.cancelReason}
                      onChange={(e) => {
                        setCancelRaison({ cancelReason: e.target.value });
                      }}
                      label={'cancel'}
                    >
                      <option value="" disabled />
                      {cancel.map((item) => (
                        <option value={item}>{item}</option>
                      ))}
                    </Select>
                  </FormControl>
                  <div style={{ float: 'right', margin: '15px' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        handleCancel();
                      }}
                    >
                      save
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Box>
          </div>
        </ModalComponent>
      )}
      {open && open === 'handle' && (
        <ModalComponent
          maxWidth={'sm'}
          close={handleCloseModal}
          visible={open === 'handle'}
          title="handle"
        >
          <div>
            <Box style={{ padding: '15px' }}>
              <Grid container className="mb-2">
                <Grid item xs={6} style={{ display: 'flex' }}>
                  orderID : <Typography>{order?.shortId}</Typography>
                </Grid>
                <Grid item xs={6} style={{ display: 'flex' }}>
                  store : <Typography>{order?.store?.name}</Typography>
                </Grid>
                <Grid item xs={6} style={{ display: 'flex' }}>
                  customer : <Typography>{order?.customer?.name}</Typography>
                </Grid>
                <Grid item xs={6} style={{ display: 'flex' }}>
                  deliveryMan :{' '}
                  <Typography>{order?.deliveryMan?.name}</Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sx={{ display: 'flex' }}>
                  <FormControl style={{ width: '100%' }} variant="outlined">
                    <CustomInput
                      labelText="message"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      type="email"
                      error={
                        formik.touched.handleMessage &&
                        Boolean(formik.errors.handleMessage)
                      }
                      inputProps={{
                        value: formik.values.handleMessage,
                        onBlur: formik.handleBlur('handleMessage'),
                        onChange: formik.handleChange('handleMessage'),
                      }}
                    />
                  </FormControl>
                  <div style={{ float: 'right', margin: '15px' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        console.log(formik.values);
                        // handleCancel();
                      }}
                    >
                      save
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Box>
          </div>
        </ModalComponent>
      )}

      {/* {openModal && (
        <ModalComponent
          maxWidth={'false'}
          close={handleCloseModal}
          visible={openModal === 'store details'}
          title="Store Details"
        >
          <StoreDetailsComponent order={orderRow} close={handleCloseModal} />
        </ModalComponent>
      )} */}
      {openModal && openModal === 'clinet details' && (
        <ModalComponent
          maxWidth={'false'}
          close={handleCloseModal}
          visible={openModal === 'clinet details'}
          title="Client Details"
        >
          <ClientDetailsComponent order={orderRow} close={handleCloseModal} />
        </ModalComponent>
      )}
      {/* {openModal && (
        <ModalComponent
          maxWidth={'false'}
          close={handleCloseModal}
          visible={openModal === 'provider details'}
          title="Provider Details"
        >
          <ProviderDetailsComponent order={orderRow} close={handleCloseModal} />
        </ModalComponent>
      )} */}
      {openModal && openModal === 'Maps' && (
        <ModalComponent
          maxWidth={'false'}
          close={handleCloseModal}
          visible={openModal === 'Maps'}
          title="Maps"
        >
          <DisplayOrderInMap
            order={orderRow}
            close={handleCloseModal}
            orders={orders}
            admin={admin}
          />
        </ModalComponent>
      )}
      {openModal && openModal === 'my location' && (
        <ModalComponent
          maxWidth={'false'}
          close={handleCloseModal}
          visible={openModal === 'my location'}
          title="position livreur"
        >
          <DisplayPositionsMap
            order={orderRow}
            close={handleCloseModal}
            orders={orders}
            admin={admin}
            socket={socket}
          />
        </ModalComponent>
      )}
      {openModal && openModal === 'myPosition' && (
        <ModalComponent
          maxWidth={'false'}
          close={handleCloseModal}
          visible={openModal === 'myPosition'}
          title="position livreur"
        >
          <DisplayPositionsMapFromSocketUpdate
            order={orderRow}
            close={handleCloseModal}
            orders={orders}
            admin={admin}
            socket={socket}
          />
        </ModalComponent>
      )}

      {openModal && openModal === 'Comment' && (
        <ModalComponent
          maxWidth={'false'}
          close={handleCloseModal}
          visible={openModal === 'Comment'}
          title="comment"
        >
          <CommentComponent
            close={handleCloseModal}
            admin={admin}
            order={
              (orders || []).find((x) => x._id == orderIdForComments)
                ? (orders || []).find((x) => x._id == orderIdForComments)
                : (invalidOrders || []).find((x) => x._id == orderIdForComments)
            }
          />
        </ModalComponent>
      )}
      {openModal && openModal === 'napsPayload' && (
        <ModalComponent
          maxWidth={'false'}
          close={handleCloseModal}
          visible={openModal === 'napsPayload'}
          title="Naps payload"
        >
          <NapsPayloadComponent
            payload={orderRow?.payment?.napsPayload}
            close={handleCloseModal}
            orders={orders}
            admin={admin}
          />
        </ModalComponent>
      )}
    </div>
  );
}
