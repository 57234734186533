import React, { useState } from 'react';
import GridContainer from '../../../components/material-dashboard-pro-react/Grid/GridContainer';
import Card from '../../../components/material-dashboard-pro-react/Card/Card';
import CardHeader from '../../../components/material-dashboard-pro-react/Card/CardHeader';
import CardBody from '../../../components/material-dashboard-pro-react/Card/CardBody';
import { resendDeliveredApi } from '../services/server.api.service';
import { useAlert } from 'react-alert';
import { Box } from '@material-ui/core';
// import Button from '../../src/material-dashboard-pro-react/CustomButtons/Button';
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';

export default function ResendDeliveredPage() {
  const alert = useAlert();
  const [ids, setIds] = useState([]);
  const handlePaste = (event) => {
    // const pasteText = event.clipboardData.getData("text/plain");
    // const idRegex = /[a-zA-Z0-9--]+/g; // assuming IDs are alphanumeric
    // const pastedIds = pasteText.match(idRegex);
    // console.log("pasteText", pastedIds);
    // setIds([...pastedIds]);
    const pasteText = event.clipboardData.getData('text/plain');
    const pastedIds = pasteText.split(/\s+/);
    setIds([...ids, ...pastedIds]);
  };
  const send = () => {
    resendDeliveredApi(ids)
      .then(({ data }) => {
        // setIds([]);
        alert.show('done', {
          type: 'success',
        });
      })
      .catch((err) => console.log(err));
  };
  console.log('ids', ids);
  return (
    <GridContainer>
      <Card>
        <CardHeader>Resend Delivered</CardHeader>
        <CardBody>
          <div>
            <textarea type="text" onPaste={handlePaste} />
            <ul>
              {ids.map((id, index) => (
                <li key={index}>{id}</li>
              ))}
            </ul>
          </div>
          <Box display="flex" justifyContent="flex-end">
            <Button onClick={() => send()} color="success">
              Send
            </Button>
          </Box>
        </CardBody>
      </Card>
    </GridContainer>
  );
}
