import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useFormik } from 'formik';
import * as yup from 'yup';
import CustomInput from '../../../components/material-dashboard-pro-react/CustomInput/CustomInput';
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';

import { updateSetting } from '../services/serverApi.service';

const validationSchema = yup.object({
  name: yup.string().required(),
});

export default function RegularisationBalanceReasonsComponent({ setting }) {
  const [regularisationBalanceReasons, setRegularisationBalanceReasons] =
    useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [userCancelArray, setStoreCancelArray] = useState([]);
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
  });
  const getReasons = () => {
    console.log('asa ');
    if (setting[0].regularisation_balance_reasons)
      setRegularisationBalanceReasons(
        setting[0].regularisation_balance_reasons,
      );
  };
  const saveReasons = () => {
    if (setting) {
      const data = [...regularisationBalanceReasons];
      data.push(formik.values.name);
      setRegularisationBalanceReasons(data);
      setting[0].regularisation_balance_reasons = data;
      updateSetting(setting[0]._id, setting[0])
        .then(({ data }) => {
          formik.setFieldValue('name', '');
        })
        .catch((error) => console.log(error));
    }
  };
  const deleteReasons = (index) => {
    console.log('deleted');
    const data = [...regularisationBalanceReasons];
    data.splice(index, 1);
    setRegularisationBalanceReasons(data);
    setting[0].regularisation_balance_reasons = data;
    console.log('deleted', data);
    updateSetting(setting[0]._id, setting[0])
      .then(({ data }) => {
        console.log(data);
        setRegularisationBalanceReasons(
          data?.data?.regularisation_balance_reasons,
        );
        formik.resetForm();
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    setting && setting.length > 0 && getReasons();
  }, [setting]);
  return (
    <div>
      {regularisationBalanceReasons &&
        regularisationBalanceReasons.length > 0 &&
        regularisationBalanceReasons.map((item, index) => (
          <p>
            <CloseIcon
              onClick={() => deleteReasons(index)}
              fontSize="small"
              style={{ color: 'red' }}
            />

            {item}
          </p>
        ))}
      <CustomInput
        labelText="Name"
        formControlProps={{
          fullWidth: true,
        }}
        type="text"
        error={formik.touched.name && Boolean(formik.errors.name)}
        inputProps={{
          value: formik.values.name,
          onBlur: formik.handleBlur('name'),
          onChange: formik.handleChange('name'),
        }}
      />
      <Box textAlign="right">
        <Button
          round
          type="submit"
          color="success"
          disabled={isLoading}
          loading={isLoading}
          loaderColor="white"
          onClick={saveReasons}
        >
          save
        </Button>
      </Box>
    </div>
  );
}

RegularisationBalanceReasonsComponent.propTypes = {
  setting: PropTypes.any.isRequired,
};
