import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Stepper, StepLabel, Step, StepContent } from '@mui/material';
import { Typography, Box, Button } from '@material-ui/core';
import {
  formatTimeForOrder,
  calcDurationbetweenTwoTimes,
} from '../../../helpers/index';
import { Howl, Howler } from 'howler';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import ReactImageMagnify from 'react-image-magnify';
import CustomButton from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import ModalComponent from '../../../components/Modal.component';
import StatusMap from './Status.map.component';
import StatusDetailsComponent from './status.details.component';
import PersonIcon from '@mui/icons-material/Person';
import SportsMotorsportsIcon from '@mui/icons-material/SportsMotorsports';
import { orderServiceType } from '../mock';
import ButtonMui from '@mui/material/Button';

const styles = {
  td: {
    verticalAlign: 'middle',
  },
  font: {
    fontWeight: 'bold',
  },
  cardTitle: {
    margin: '10px 0',
    fontWeight: 'bold',
  },
};
export default function OrderDetail({ orderId, orders }) {
  const [modal, setModal] = useState('');
  const [item, setItem] = useState(orders.find((x) => x._id == orderId));
  const [openModal, setOpenModal] = useState(false);
  const playAudio = (src) => {
    var sound = new Howl({
      src: [src],
      autoplay: true,
    });
    sound.play();
  };
  // runSong()
  let counter = 0;
  const handleCloseModal = () => {
    setModal('');
    setOpenModal(false);
  };
  const handleOpenModal = (type) => {
    setModal(type);
    setOpenModal(true);
  };

  return (
    <>
      {!!item && (
        <div className="row">
          <div className="col-10 row">
            <div className="col-3">
              <div style={styles.cardTitle}>Payment details</div>
              <ul className="list-group ">
                <li className="list-group-item">
                  <div className="d-flex justify-content-between">
                    <span>ordersPrice</span>
                    <span style={styles.font}>
                      {item?.payment.originalOrderPrice &&
                        item?.payment.originalOrderPrice.toFixed(2)}
                    </span>
                  </div>
                </li>
                <li className="list-group-item">
                  <div className="d-flex justify-content-between">
                    <span>deliveryPrice</span>
                    <span style={styles.font}>
                      {item?.payment.deliveryPrice &&
                        item?.payment.deliveryPrice.toFixed(2)}
                    </span>
                  </div>
                </li>

                <li className="list-group-item">
                  <div className="d-flex justify-content-between">
                    <span style={styles.font}>total</span>
                    <span style={styles.font}>
                      {item?.payment.total && item?.payment.total.toFixed(2)}
                    </span>
                  </div>
                </li>
                {item.payment.payments && (
                  <>
                    <li className="list-group-item">
                      <div className="d-flex justify-content-between">
                        <span>Cash</span>
                        <span style={styles.font}>
                          {item?.payment.payments[0].amount.toFixed(2)}
                        </span>
                      </div>
                    </li>
                    <li className="list-group-item">
                      <div className="d-flex justify-content-between">
                        <span>Card</span>
                        <span style={styles.font}>
                          {item?.payment.payments[1].amount.toFixed(2)}
                        </span>
                      </div>
                    </li>
                    <li className="list-group-item">
                      <div className="d-flex justify-content-between">
                        <span>KaalixUP</span>
                        <span style={styles.font}>
                          {item?.payment.payments[2].amount.toFixed(2)}
                        </span>
                      </div>
                    </li>
                    <li className="list-group-item">
                      <div className="d-flex justify-content-between">
                        <span>KaalixPay</span>
                        <span style={styles.font}>
                          {item?.payment.payments[3].amount.toFixed(2)}
                        </span>
                      </div>
                    </li>
                  </>
                )}
              </ul>
            </div>
            {item?.payment.serviceType === 'Driver' ? (
              <div className="col-3">
                <div style={styles.cardTitle}>destination Address</div>

                {item?.payment?.destinationAddress && (
                  <ul className="list-group mt-2">
                    <li className="list-group-item">
                      <div className="d-flex justify-content-between">
                        <span>
                          description :{' '}
                          {item?.payment?.destinationAddress?.details}
                        </span>
                      </div>
                    </li>
                    <li className="list-group-item">
                      <div className="d-flex justify-content-between">
                        <span>phone</span>
                        <span>{item?.payment?.destinationAddress?.phone}</span>
                      </div>
                    </li>
                    <li className="list-group-item">
                      <div className="d-flex justify-content-between">
                        <span>
                          address : {item?.payment?.destinationAddress?.address}
                        </span>
                      </div>
                    </li>
                  </ul>
                )}
              </div>
            ) : (
              item?.payment.destinationAddress && (
                <div className="col-3">
                  <div style={styles.cardTitle}>destination Address</div>
                  <ul className="list-group ">
                    <li className="list-group-item">
                      <div className="d-flex justify-content-between">
                        {/* <span>destination</span> */}
                        <span>
                          destination : {item?.payment.destinationAddress.name}
                        </span>
                      </div>
                    </li>
                    <li className="list-group-item">
                      <div className="d-flex justify-content-between">
                        <span>phone</span>
                        <span>{item?.payment.destinationAddress.phone}</span>
                      </div>
                    </li>
                    <li className="list-group-item">
                      <div className="d-flex justify-content-between">
                        <span>Email: {item?.customer.email}</span>
                      </div>
                    </li>
                    <li className="list-group-item">
                      <div className="d-flex justify-content-between">
                        <span>
                          address :{' '}
                          {item?.payment.destinationAddress.address ||
                            item?.payment.destinationAddress.addres}
                        </span>
                      </div>
                    </li>
                    <li className="list-group-item">
                      <div className="d-flex justify-content-between">
                        <span>
                          detail : {item?.payment.destinationAddress?.details}
                        </span>
                      </div>
                    </li>
                  </ul>
                </div>
              )
            )}
            {item?.payment.serviceType === 'Driver' ? (
              <div className="col-3">
                <div style={styles.cardTitle}>pickup Address</div>
                <ul className="list-group ">
                  <li className="list-group-item">
                    <div className="d-flex justify-content-between">
                      {/* <span>destination</span> */}
                      <span>
                        pickup Add :{' '}
                        {item?.payment?.driverDetail?.pickup?.address}
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
            ) : (
              item?.payment.pickupAddress && (
                <div className="col-3">
                  <div style={styles.cardTitle}>pickup Address</div>
                  <ul className="list-group ">
                    <li className="list-group-item">
                      <div className="d-flex justify-content-between">
                        <span>pickup : {item?.payment.pickupAddress.name}</span>
                      </div>
                    </li>
                    <li className="list-group-item">
                      <div className="d-flex justify-content-between">
                        <span>phone</span>
                        <span>{item?.payment.pickupAddress.phone}</span>
                      </div>
                    </li>
                    <li className="list-group-item">
                      <div className="d-flex justify-content-between">
                        <span>
                          address : {item?.payment.pickupAddress.address}
                        </span>
                      </div>
                    </li>
                  </ul>
                </div>
              )
            )}

            {item?.deliveryMan && Object.keys(item.deliveryMan).length && (
              <div className="col-3">
                <div style={styles.cardTitle}>delivery Details</div>
                <ul className="list-group">
                  <li className="list-group-item">
                    <div className="d-flex justify-content-between">
                      <span>name</span>
                      <span>{item?.deliveryMan.name}</span>
                    </div>
                  </li>
                  <li className="list-group-item">
                    <div className="d-flex justify-content-between">
                      {/* <span>email</span> */}
                      <span>{item?.deliveryMan.email}</span>
                    </div>
                  </li>
                  <li className="list-group-item">
                    <div className="d-flex justify-content-between">
                      <span>phone</span>
                      <span>{item?.deliveryMan.phone}</span>
                    </div>
                  </li>
                </ul>
              </div>
            )}

            {item.payment.promoInfo && (
              <div className="col-3">
                <div style={styles.cardTitle}>Promo Details</div>
                <ul className="list-group ">
                  <li className="list-group-item">
                    <div className="d-flex justify-content-between">
                      <span>Store promo</span>
                      <span style={styles.font}>
                        {item?.payment.promoInfo.promoStore ? 'yes' : 'no'}
                      </span>
                    </div>
                  </li>
                  <li className="list-group-item">
                    <div className="d-flex justify-content-between">
                      <span>Total promo kaalix</span>
                      <span style={styles.font}>
                        {item?.payment.promoInfo.totalPromoKaalix}
                      </span>
                    </div>
                  </li>
                  <li className="list-group-item">
                    <div className="d-flex justify-content-between">
                      <span>Total promo store</span>
                      <span style={styles.font}>
                        {item?.payment?.promoInfo?.totalPromoStore &&
                          item?.payment?.promoInfo?.totalPromoStore.toFixed(2)}
                      </span>
                    </div>
                  </li>
                  <li className="list-group-item">
                    <div className="d-flex justify-content-between">
                      <span>Nombre Item promo kaalix</span>
                      <span style={styles.font}>
                        {item?.payment.promoInfo.promoKaalixItemCount}
                      </span>
                    </div>
                  </li>
                  <li className="list-group-item">
                    <div className="d-flex justify-content-between">
                      <span>Nombre Item promo Store</span>
                      <span style={styles.font}>
                        {item?.payment.promoInfo.promoStoreItemCount}
                      </span>
                    </div>
                  </li>
                  <li className="list-group-item">
                    <div className="d-flex justify-content-between">
                      <span>Total Promo</span>
                      <span style={styles.font}>
                        {item?.payment?.promoInfo?.totalPromo &&
                          item?.payment?.promoInfo?.totalPromo.toFixed(2)}
                      </span>
                    </div>
                  </li>
                  <li className="list-group-item">
                    <div className="d-flex justify-content-between">
                      <span>Nombre Promo</span>
                      <span style={styles.font}>
                        {item?.payment.promoInfo.promoItemCount}
                      </span>
                    </div>
                  </li>
                  <li className="list-group-item">
                    <div className="d-flex justify-content-between">
                      <span>Type Promo</span>
                      <span style={styles.font}>
                        {item?.payment.promoInfo.promoType}
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
            )}
            {/* <div className="col-3">
              <div style={styles.cardTitle}>provider commission</div>
              <ul className="list-group">
                <li className="list-group-item">
                  <div className="d-flex justify-content-between">
                    <span>commission Totale</span>
                    <span>
                      {item?.commission && item?.commission.toFixed(2)}
                    </span>
                  </div>
                </li>
                <li className="list-group-item">
                  <div className="d-flex justify-content-between">
                    <span>commissionSD</span>
                    <span>
                      {item?.commissionStoreDeliveryMan &&
                        item?.commissionStoreDeliveryMan.toFixed(2)}
                    </span>
                  </div>
                </li>
                <li className="list-group-item">
                  <div className="d-flex justify-content-between">
                    <span>commissionSC</span>
                    <span>
                      {item?.commissionStoreClient &&
                        item?.commissionStoreClient.toFixed(2)}
                    </span>
                  </div>
                </li>
              </ul>
            </div> */}
            <div className="col-12 mt-3">
              {item.payment.cartDetails.length > 0 && (
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">item</th>
                      <th scope="col">product</th>
                      <th scope="col">price</th>
                      <th scope="col">spécification</th>
                      <th scope="col">spec_price</th>
                      <th scope="col">Qt</th>
                      <th scope="col">note</th>
                      <th scope="col">total</th>
                    </tr>
                  </thead>
                  <tbody
                    style={{
                      textAlign: 'center',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {!!item.payment.cartDetails &&
                      item.payment.cartDetails.map(
                        (val, index) =>
                          val.items.length &&
                          val.items.map(
                            (item, i) => (
                              (counter += 1),
                              (
                                <tr>
                                  <th scope="row" style={styles.td}>
                                    {counter}
                                  </th>
                                  <td style={styles.td}>{item.name}</td>
                                  <td style={styles.td}>{val.name}</td>
                                  <td style={styles.td}>
                                    {item?.originalPrice &&
                                    item?.originalPrice > 0 ? (
                                      <s>{item?.originalPrice.toFixed(2)}</s>
                                    ) : null}{' '}
                                    {item.itemPrice}
                                  </td>
                                  <td>
                                    {item.specifications &&
                                      item.specifications.length &&
                                      item.specifications.map(
                                        (specification) =>
                                          specification.list.length > 0 &&
                                          specification.list.map((list) => {
                                            return <p>{list.name}</p>;
                                          }),
                                      )}
                                  </td>
                                  <td>
                                    {item.specifications &&
                                      item.specifications.length &&
                                      item.specifications.map(
                                        (specification) =>
                                          specification.list.length > 0 &&
                                          specification.list.map((list) => {
                                            return (
                                              <p>
                                                {list.price &&
                                                  list.price.toFixed(2)}
                                              </p>
                                            );
                                          }),
                                      )}
                                  </td>
                                  <td style={styles.td}>{item.quantity}</td>
                                  <td style={styles.td}>{item.note}</td>
                                  <td style={styles.td}>
                                    {item.itemTotalPrice &&
                                      item.itemTotalPrice.toFixed(2)}
                                  </td>
                                </tr>
                              )
                            ),
                          ),
                      )}

                    <tr>
                      <td
                        colspan="8"
                        style={{ fontSize: '18px', ...styles.td }}
                      >
                        total order
                      </td>
                      <td style={{ fontSize: '18px' }}>
                        {item.payment.orderPrice &&
                          item.payment.orderPrice.toFixed(2)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}

              {item.payment?.choubikDetail &&
                Object.keys(item.payment.choubikDetail).length > 0 && (
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">description</th>
                        <th scope="col">city</th>
                        <th scope="col">images</th>
                        <th scope="col">deliveryPrice</th>
                        <th scope="col">audio</th>
                      </tr>
                    </thead>
                    <tbody
                      style={{
                        textAlign: 'center',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      {!!item.payment.choubikDetail && (
                        <tr>
                          <td scope="col" style={styles.td}>
                            {item.payment.choubikDetail?.description}
                          </td>
                          <td scope="col" style={styles.td}>
                            {item.city.name}
                          </td>
                          <td scope="col" style={styles.td}>
                            {(item.payment.choubikDetail?.images || []).length >
                              0 &&
                              item.payment.choubikDetail?.images.map((img) => (
                                <img
                                  className="orderDetailImgOnHover"
                                  src={img}
                                  alt="img"
                                  border="3"
                                  height="50"
                                  width="60"
                                />
                              ))}
                          </td>
                          <td scope="col" style={styles.td}>
                            {item.payment.deliveryPrice}
                          </td>
                          <td scope="col" style={styles.td}>
                            {item.payment.expressDetail?.pickup.recording &&
                              item.payment.expressDetail?.pickup.recording !=
                                'Empty' && (
                                <>
                                  <PlayCircleOutlineIcon
                                    type="button"
                                    onClick={() =>
                                      playAudio(
                                        item.payment.choubikDetail.recording,
                                      )
                                    }
                                  />
                                  <PauseCircleOutlineIcon
                                    type="button"
                                    onClick={() => Howler.stop()}
                                  />
                                  <Box textAlign="center">
                                    {item.payment.choubikDetail.recording &&
                                      item.payment.choubikDetail.recording !=
                                        'Empty' && (
                                        <Button
                                          round
                                          type="submit"
                                          color="success"
                                          loaderColor="white"
                                        >
                                          <a
                                            href={
                                              item.payment.choubikDetail
                                                .recording
                                            }
                                            target="_blank"
                                          >
                                            link
                                          </a>
                                        </Button>
                                      )}
                                  </Box>
                                </>
                              )}
                          </td>
                        </tr>
                      )}
                      <tr>
                        <td
                          colspan="4"
                          style={{ ...styles.td, fontSize: '18px' }}
                        >
                          total order
                        </td>
                        <td style={{ fontSize: '18px' }}>
                          {item.payment.deliveryPrice &&
                            item.payment.deliveryPrice.toFixed(2)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                )}
              {item.payment?.driveDetail &&
                Object.keys(item.payment.driveDetail).length > 0 && (
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">deliveryMan count</th>
                        <th scope="col">districts</th>
                        <th scope="col">is Pro</th>
                        <th scope="col">img</th>
                        <th scope="col">audio</th>
                      </tr>
                    </thead>
                    <tbody
                      style={{
                        textAlign: 'center',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      {!!item.payment.driveDetail && (
                        <tr>
                          <td scope="col" style={styles.td}>
                            {item.payment.driveDetail?.deliveryManCount}
                          </td>
                          <td scope="col" style={styles.td}>
                            {item.payment.driveDetail?.districts.length > 0 &&
                              item.payment.driveDetail?.districts.map(
                                (element) => (
                                  <p>
                                    {element.name + '/' + element.packagesCount}
                                  </p>
                                ),
                              )}
                          </td>
                          <td style={styles.td}>
                            {item.payment.driveDetail?.isPro ? 'YES' : 'NO'}
                          </td>
                          <td scope="col" style={styles.td}>
                            {(item.payment.driveDetail?.pickup?.images || [])
                              .length > 0 &&
                              item.payment.driveDetail?.pickup?.images.map(
                                (img) => (
                                  <img
                                    className="orderDetailImgOnHover"
                                    src={img}
                                    alt="img"
                                    border="3"
                                    height="50"
                                    width="60"
                                  />
                                ),
                              )}
                          </td>
                          <td scope="col" style={styles.td}>
                            {item.payment.driveDetail?.pickup.recording &&
                              item.payment.driveDetail?.pickup.recording !=
                                'Empty' && (
                                <>
                                  <PlayCircleOutlineIcon
                                    type="button"
                                    onClick={() =>
                                      playAudio(
                                        item.payment.driveDetail?.pickup
                                          .recording,
                                      )
                                    }
                                  />
                                  <PauseCircleOutlineIcon
                                    type="button"
                                    onClick={() => Howler.stop()}
                                  />
                                  <Box textAlign="center">
                                    <Button
                                      round
                                      type="submit"
                                      color="success"
                                      loaderColor="white"
                                    >
                                      <a
                                        href={
                                          item.payment.driveDetail?.pickup
                                            .recording
                                        }
                                        target="_blank"
                                      >
                                        link
                                      </a>
                                    </Button>
                                  </Box>
                                </>
                              )}
                          </td>
                        </tr>
                      )}
                      <tr>
                        <td
                          colspan="4"
                          style={{ ...styles.td, fontSize: '18px' }}
                        >
                          total order
                        </td>
                        <td style={{ fontSize: '18px' }}>
                          {item.payment.deliveryPrice &&
                            item.payment.deliveryPrice.toFixed(2)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                )}
              {item.payment?.expressDetail &&
                Object.keys(item.payment.expressDetail).length > 0 && (
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">description</th>
                        <th scope="col">detail</th>
                        <th scope="col">name</th>
                        <th scope="col">phone</th>
                        <th scope="col">img</th>
                        <th scope="col">audio</th>
                      </tr>
                    </thead>
                    <tbody
                      style={{
                        textAlign: 'center',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      {!!item.payment.expressDetail && (
                        <tr>
                          <td scope="col" style={styles.td}>
                            {item.payment.expressDetail?.pickup?.description}
                          </td>
                          <td scope="col" style={styles.td}>
                            {item.payment.expressDetail?.pickup?.detail}
                          </td>
                          <td scope="col" style={styles.td}>
                            {item.payment.expressDetail?.pickup?.name}
                          </td>
                          <td scope="col" style={styles.td}>
                            {item.payment.expressDetail?.pickup?.phone}
                          </td>
                          <td scope="col" style={styles.td}>
                            {(item.payment.expressDetail?.pickup?.images || [])
                              .length > 0 &&
                              item.payment.expressDetail?.pickup?.images.map(
                                (img) => (
                                  <img
                                    className="orderDetailImgOnHover"
                                    src={img?.path || img || ''}
                                    alt="img"
                                    border="3"
                                    height="50"
                                    width="60"
                                  />
                                ),
                              )}
                          </td>
                          <td scope="col" style={styles.td}>
                            {item.payment.expressDetail?.pickup.recording &&
                              item.payment.expressDetail?.pickup.recording !=
                                'Empty' && (
                                <>
                                  <PlayCircleOutlineIcon
                                    type="button"
                                    onClick={() =>
                                      playAudio(
                                        item.payment.expressDetail?.pickup
                                          .recording,
                                      )
                                    }
                                  />
                                  <PauseCircleOutlineIcon
                                    type="button"
                                    onClick={() => Howler.stop()}
                                  />
                                  <Box textAlign="center">
                                    <Button
                                      round
                                      type="submit"
                                      color="success"
                                      loaderColor="white"
                                    >
                                      <a
                                        href={
                                          item.payment.expressDetail?.pickup
                                            .recording
                                        }
                                        target="_blank"
                                      >
                                        link
                                      </a>
                                    </Button>
                                  </Box>
                                </>
                              )}
                          </td>
                        </tr>
                      )}
                      <tr>
                        <td
                          colspan="4"
                          style={{ ...styles.td, fontSize: '18px' }}
                        >
                          total order
                        </td>
                        <td style={{ fontSize: '18px' }}>
                          {item.payment.deliveryPrice &&
                            item.payment.deliveryPrice.toFixed(2)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                )}
              {item.payment?.accompagnements &&
              item.payment?.accompagnements.length ? (
                <>
                  <caption>
                    <strong>Accompagnements</strong>
                  </caption>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">name</th>
                        <th scope="col">Qte</th>
                        <th scope="col">itemPrice</th>
                      </tr>
                    </thead>
                    <tbody>
                      {item.payment?.accompagnements.map((element) => (
                        <tr background="trans">
                          <td>{element.name}</td>
                          <td>{element.quantity}</td>
                          <td>
                            {element.itemPrice && element.itemPrice.toFixed(2)}
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <td
                          colspan="2"
                          style={{ fontSize: '18px', ...styles.td }}
                        >
                          total accompagnement
                        </td>
                        <td style={{ fontSize: '18px' }}>
                          {item.payment?.accompagnementPrice &&
                            item.payment?.accompagnementPrice.toFixed(2)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </>
              ) : null}
            </div>

            {item?.payment?.invoiceUrl && (
              <div className="col-6">
                <div style={styles.cardTitle}>invoiceUrl</div>
                <ul className="list-group ">
                  <li className="list-group-item">
                    <div>
                      {/* <img src={item?.payment?.invoiceUrl} className='orderDetailImgOnHover'  alt="img"  border="3" height="80" width="60"/> */}
                      <ReactImageMagnify
                        {...{
                          smallImage: {
                            alt: 'img',
                            isFluidWidth: true,
                            src: item?.payment?.invoiceUrl,
                          },
                          largeImage: {
                            src: item?.payment?.invoiceUrl,
                            width: 1300,
                            height: 1200,
                          },
                        }}
                      />
                      <Button>
                        <a href={item?.payment?.invoiceUrl} target="_blank">
                          download
                        </a>
                      </Button>
                    </div>
                  </li>
                </ul>
              </div>
            )}
          </div>

          {item.deliveryManStatusTimes &&
            item.deliveryManStatusTimes.length > 0 && (
              <div className="col-2">
                <ButtonMui
                  variant={'contained'}
                  color="primary"
                  onClick={() => {
                    handleOpenModal('all status');
                  }}
                >
                  status details
                </ButtonMui>
                <Stepper orientation="vertical">
                  {item.deliveryManStatusTimes.map((step, index) => (
                    <Step key={`${step?.status + index}`} active={true}>
                      <StepLabel>{step.status}</StepLabel>
                      <StepContent>
                        <strong style={{ fontSize: '12px' }}>
                          {formatTimeForOrder(step.date)}{' '}
                        </strong>
                        <br />
                        {step?.admin &&
                          Object.keys(step.admin).length &&
                          step?.admin?.userName && (
                            <>
                              <PersonIcon
                                style={{ color: '#3f51b5', fontSize: '20px' }}
                              />
                              <strong style={{ fontSize: '12px' }}>
                                {step.name}
                              </strong>
                              {step?.admin?.userName}
                              <br />
                            </>
                          )}
                        {step?.deliveryMan && step?.deliveryMan.name && (
                          <>
                            <SportsMotorsportsIcon
                              style={{ color: '#3f51b5', fontSize: '20px' }}
                            />{' '}
                            <strong style={{ fontSize: '12px' }}>
                              {step?.deliveryMan.name}
                            </strong>
                            <br />
                          </>
                        )}
                        <strong>
                          {index < item.deliveryManStatusTimes.length &&
                          index !== 0
                            ? calcDurationbetweenTwoTimes(
                                item.deliveryManStatusTimes[index - 1].date,
                                step.date,
                              ) + ' min '
                            : calcDurationbetweenTwoTimes(
                                item.createdAt,
                                step.date,
                              ) + ' min'}{' '}
                        </strong>
                      </StepContent>
                    </Step>
                  ))}
                </Stepper>

                <Box textAlign="right" className="mt-2">
                  <CustomButton
                    round
                    type="submit"
                    color="success"
                    loaderColor="white"
                    onClick={() => handleOpenModal('statusMap')}
                  >
                    map
                  </CustomButton>
                </Box>
              </div>
            )}

          <div className="col-12 mt-3 "></div>
        </div>
      )}

      {openModal && (
        <ModalComponent
          maxWidth={true}
          close={handleCloseModal}
          visible={modal === 'statusMap'}
          title="status Map"
          fullHeight={true}
        >
          <StatusMap
            order={item}
            status={item?.deliveryManStatusTimes}
            store={item?.store}
            serviceType={item?.payment.serviceType}
            createdAt={item?.createdAt}
            clientLocation={
              item.payment.serviceType == orderServiceType.Stores
                ? item?.payment?.destinationAddress?.location
                : item?.payment?.pickupAddress?.location
            }
          />
        </ModalComponent>
      )}
      {openModal && (
        <ModalComponent
          maxWidth={true}
          close={handleCloseModal}
          visible={modal === 'all status'}
          title="status details"
        >
          <StatusDetailsComponent order={item} />
        </ModalComponent>
      )}
    </>
  );
}

OrderDetail.propTypes = {
  item: PropTypes.any.isRequired,
};
