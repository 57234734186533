import React from 'react'
import PropTypes from 'prop-types';
import CustomInput from '../../../components/material-dashboard-pro-react/CustomInput/CustomInput';


export default function ViewCitiesComponent({item}) {
    return (
        <div>

            {item &&
            // <div>{JSON.stringify(item)}</div>
                <>
                    <CustomInput
                        labelText="Name"
                        formControlProps={{
                            fullWidth: true,
                        }}
                        type="text"
                        inputProps={{
                            value: item[1],
                        }}
                    />
                    <CustomInput
                        labelText="timeZone"
                        formControlProps={{
                            fullWidth: true,
                        }}
                        type="text"
                        inputProps={{
                            value: item[2],
                        }}
                    />
                    <CustomInput
                        labelText="Country"
                        formControlProps={{
                            fullWidth: true,
                        }}
                        type="text"
                        inputProps={{
                            value: item[3],
                        }}
                    />
                </>
            }

        </div>
    )
}
ViewCitiesComponent.propTypes = {
    item: PropTypes.any,
};
