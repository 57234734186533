import React, { useEffect, useState } from 'react';
// lib
import * as yup from 'yup';
import { useFormik } from 'formik';
import moment from 'moment';

// api
import { editCodePromo, createCodePromo } from '../services/serverApi.customer';

// component
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import CustomInput from '../../../components/material-dashboard-pro-react/CustomInput/CustomInput';
import SelectBoxComponent from '../../../components/SelectBox.component';
import SwitchComponent from '../../../components/Switch.component';

// mat compo
import { Box, TextField } from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DateTimePicker, LocalizationProvider } from '@mui/lab';
// mock
import { promoTypes } from '../mock';

const validationSchema = yup.object({
  enable: yup.boolean().required(),
  newUser: yup.boolean().required(),
  cityId: yup.string().required(),
  type: yup.string().required(),
  code: yup.string().required(),
  value: yup.number().required(),
  minCartPrice: yup.number().required(),
  maxUseByUser: yup.number().required(),
  expiredAt: yup.string().required(),
});
export default function EditCodePromoComponent({
  promo,
  close,
  index,
  codePromo,
  setCodePromo,
  citiesProps,
  props,
  cities,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [errorsMsg, setErrorsMsg] = useState('');
  const formik = useFormik({
    initialValues: {
      enable: false,
      newUser: false,
      cityId: '',
      type: '',
      code: '',
      value: '',
      minCartPrice: '',
      maxUseByUser: '',
      expiredAt: '',
    },
    validationSchema,
  });
  const onCreate = () => {
    setIsLoading(true);

    createCodePromo(formik.values)
      .then(({ data }) => {
        setErrorsMsg('');
        formik.resetForm();
        props.fetchCodePromoFromApi(0, 0, '');
        close();
      })
      .catch((error) => {
        if (
          error.response.status == 400 &&
          error?.response?.data &&
          error?.response?.data?.errors &&
          error?.response?.data?.errors.length > 0
        ) {
          setErrorsMsg(error?.response?.data?.errors[0]?.message);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const onEdit = () => {
    setIsLoading(true);
    editCodePromo(promo?._id, formik.values)
      .then(({ data }) => {
        codePromo[index] = data.promo;
        setCodePromo(codePromo);
        close();
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setIsLoading(false);
      });
  };
  const setValues = (data) => {
    if (data) {
      formik.setValues({
        code: data?.code, //
        enable: data?.enable, //
        newUser: data?.newUser, //
        cityId: data?.cityId,
        type: data?.type, //
        value: data?.value, //
        minCartPrice: data?.minCartPrice,
        maxUseByUser: data?.maxUseByUser,
        expiredAt: data?.expiredAt,
      });
    }
  };
  useEffect(() => {
    promo && setValues(promo);
  }, [promo]);
  return (
    <div>
      <CustomInput
        labelText="code"
        formControlProps={{
          fullWidth: true,
        }}
        type="text"
        error={formik.touched.code && Boolean(formik.errors.code)}
        inputProps={{
          value: formik.values.code,
          onBlur: formik.handleBlur('code'),
          onChange: formik.handleChange('code'),
        }}
        disabled={promo ? true : false}
      />
      <SelectBoxComponent
        items={promoTypes.map((item) => ({
          value: item,
          label: item,
        }))}
        inputLabel="Type"
        onValueChange={formik.handleChange('type')}
        value={formik.values.type}
      />
      <SelectBoxComponent
        items={(props?.cities || cities || []).map((item) => ({
          value: item?._id,
          label: item?.name,
        }))}
        inputLabel="cities"
        onValueChange={formik.handleChange('cityId')}
        value={formik.values.cityId}
      />
      <CustomInput
        labelText="value"
        formControlProps={{
          fullWidth: true,
        }}
        type="text"
        error={formik.touched.value && Boolean(formik.errors.value)}
        inputProps={{
          value: formik.values.value,
          onBlur: formik.handleBlur('value'),
          onChange: formik.handleChange('value'),
        }}
      />

      <CustomInput
        labelText="minCartPrice"
        formControlProps={{
          fullWidth: true,
        }}
        type="text"
        error={
          formik.touched.minCartPrice && Boolean(formik.errors.minCartPrice)
        }
        inputProps={{
          value: formik.values.minCartPrice,
          onBlur: formik.handleBlur('minCartPrice'),
          onChange: formik.handleChange('minCartPrice'),
        }}
      />
      <CustomInput
        labelText="maxUseByUser"
        formControlProps={{
          fullWidth: true,
        }}
        type="text"
        error={
          formik.touched.maxUseByUser && Boolean(formik.errors.maxUseByUser)
        }
        inputProps={{
          value: formik.values.maxUseByUser,
          onBlur: formik.handleBlur('maxUseByUser'),
          onChange: formik.handleChange('maxUseByUser'),
        }}
      />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateTimePicker
          renderInput={(props) => <TextField {...props} />}
          label="expired date"
          ampm={false}
          value={formik.values.expiredAt}
          onChange={(newValue) => {
            formik.setFieldValue(
              'expiredAt',
              moment(newValue).format().split('+')[0],
            );
          }}
        />
      </LocalizationProvider>
      <SwitchComponent
        style={{ marginLeft: 15 }}
        onChange={(value) => formik.setFieldValue('enable', value)}
        checked={formik.values.enable}
        error={formik.touched.enable && Boolean(formik.errors.enable)}
        label="enable"
      />
      <SwitchComponent
        onChange={(value) => formik.setFieldValue('newUser', value)}
        checked={formik.values.newUser}
        error={formik.touched.newUser && Boolean(formik.errors.newUser)}
        label="newUser"
      />
      <div style={{ color: 'red', textAlign: 'center' }}>
        {errorsMsg && <span>{errorsMsg}</span>}
      </div>
      <Box textAlign="center">
        <Button
          round
          type="submit"
          color="success"
          disabled={!formik.dirty || !formik.isValid || isLoading}
          loading={isLoading}
          loaderColor="white"
          onClick={promo ? onEdit : onCreate}
        >
          Save
        </Button>
      </Box>
    </div>
  );
}
