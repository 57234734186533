import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Stepper, StepLabel, Step, StepContent } from '@mui/material';
import { Typography, Box, Button } from '@material-ui/core';
import {
  formatTimeForOrder,
  calcDurationbetweenTwoTimes,
} from '../../../helpers/index';
import { Howl, Howler } from 'howler';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import ReactImageMagnify from 'react-image-magnify';
import CustomButton from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import StatusDetailsComponent from '../../orders/components/status.details.component';
import ModalComponent from '../../../components/Modal.component';
// import StatusMap from './Status.map.component';
// import StatusDetailsComponent from './status.details.component';
import PersonIcon from '@mui/icons-material/Person';
import SportsMotorsportsIcon from '@mui/icons-material/SportsMotorsports';
// import { orderServiceType } from '../mock';
import ButtonMui from '@mui/material/Button';

const styles = {
  td: {
    verticalAlign: 'middle',
  },
  font: {
    fontWeight: 'bold',
  },
  cardTitle: {
    margin: '10px 0',
    fontWeight: 'bold',
  },
};

export default function DetailDeliveryComponent({
  deliveries,
  deliverieId,
  item,
}) {
  const themes = (theme) => ({
    labelContainer: {
      '& $alternativeLabel': {
        marginTop: 0,
      },
    },
    step: {
      '& $completed': {
        color: 'lightgreen',
      },
      '& $active': {
        color: 'pink',
      },
      '& $disabled': {
        color: 'red',
      },
    },
    alternativeLabel: {},
    active: {}, //needed so that the &$active tag works
    completed: {},
    disabled: {},
    labelContainer: {
      '& $alternativeLabel': {
        marginTop: 0,
        marginLeft: 0,
      },
    },
  });
  const [delivery, setDelivery] = useState({});
  const [modal, setModal] = useState('');
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = (type) => {
    setModal(type);
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setModal('');
    setOpenModal(false);
  };
  useEffect(() => {
    setDelivery(item || (deliveries || []).find((x) => x._id == deliverieId));
  }, [item]);
  console.log(delivery);
  return (
    <div>
      {delivery && Object.keys(delivery).length && (
        <div className="row">
          <div className="col-9 row">
            {(delivery?.payment || delivery?.pickupAddress) && (
              <div className="col-3">
                {delivery?.payment && (
                  <div>
                    <div style={styles.cardTitle}>Payment details</div>
                    <ul className="list-group ">
                      <li className="list-group-item">
                        <div className="d-flex justify-content-between">
                          <span>orderPrice</span>
                          <span style={styles.font}>
                            {delivery?.payment?.price &&
                              delivery?.payment.price.toFixed(2)}
                          </span>
                        </div>
                      </li>
                      <li className="list-group-item">
                        <div className="d-flex justify-content-between">
                          <span>method</span>
                          <span style={styles.font}>
                            {delivery?.payment?.method}
                          </span>
                        </div>
                      </li>
                    </ul>
                  </div>
                )}
                {delivery?.pickupAddress && (
                  // <div className="col-3">
                  <div>
                    <div style={styles.cardTitle}>pickup Address</div>
                    <ul className="list-group ">
                      <li className="list-group-item">
                        <div className="d-flex justify-content-between">
                          <span>
                            pickup : {delivery?.pickupAddress?.idMagasin}
                          </span>
                        </div>
                      </li>
                      <li className="list-group-item">
                        <div className="d-flex justify-content-between">
                          <span>phone</span>
                          <span>{delivery?.pickupAddress?.contactPhone}</span>
                        </div>
                      </li>
                      <li className="list-group-item">
                        <div className="d-flex justify-content-between">
                          <span>
                            address : {delivery?.pickupAddress.address}
                          </span>
                        </div>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            )}

            {delivery.destinationAddress && (
              <div className="col-3">
                <div style={styles.cardTitle}>destination Address</div>
                <ul className="list-group ">
                  <li className="list-group-item">
                    <div className="d-flex justify-content-between">
                      {/* <span>destination</span> */}
                      <span>
                        destination :{' '}
                        {delivery?.destinationAddress?.contactPerson}
                      </span>
                    </div>
                  </li>
                  <li className="list-group-item">
                    <div className="d-flex justify-content-between">
                      <span>phone</span>
                      <span>{delivery?.destinationAddress?.contactPhone}</span>
                    </div>
                  </li>
                  <li className="list-group-item">
                    <div className="d-flex justify-content-between">
                      <span>
                        address :{' '}
                        {delivery?.destinationAddress?.address ||
                          delivery?.destinationAddress?.addres}
                      </span>
                    </div>
                  </li>
                  <li className="list-group-item">
                    <div className="d-flex justify-content-between">
                      <span>
                        detail : {delivery?.destinationAddress?.details}
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
            )}

            {delivery?.deliveryMan && Object.keys(delivery.deliveryMan).length && (
              <div className="col-3">
                <div style={styles.cardTitle}>deliverMan Details</div>
                <ul className="list-group">
                  <li className="list-group-item">
                    <div className="d-flex justify-content-between">
                      <span>name</span>
                      <span>{delivery?.deliveryMan.name}</span>
                    </div>
                  </li>
                  <li className="list-group-item">
                    <div className="d-flex justify-content-between">
                      <span>{delivery?.deliveryMan.email}</span>
                    </div>
                  </li>
                  <li className="list-group-item">
                    <div className="d-flex justify-content-between">
                      <span>phone</span>
                      <span>{delivery?.deliveryMan.phone}</span>
                    </div>
                  </li>
                </ul>
              </div>
            )}
          </div>
          {/* <div className="col-2"> */}

          <div
            className="col-3"
            style={{ maxHeight: '440px', overflowY: 'scroll' }}
          >
            <ButtonMui
              variant={'contained'}
              color="primary"
              onClick={() => {
                handleOpenModal('all status');
              }}
            >
              status details
            </ButtonMui>
            <Stepper orientation="vertical" theme={themes}>
              {(delivery.deliveryManStatusTimes || []).map((step, index) => (
                <Step key={`${step?.status + index}`} active={true}>
                  <StepLabel>{step.status}</StepLabel>
                  <StepContent>
                    <strong style={{ fontSize: '12px' }}>
                      {formatTimeForOrder(step.date)}{' '}
                    </strong>
                    <br />
                    {step?.admin &&
                      Object.keys(step.admin).length &&
                      step?.admin?.userName && (
                        <>
                          <PersonIcon
                            style={{ color: '#3f51b5', fontSize: '20px' }}
                          />
                          <strong style={{ fontSize: '12px' }}>
                            {step.name}
                          </strong>
                          {step?.admin?.userName}
                          <br />
                        </>
                      )}
                    {step?.deliveryMan && step?.deliveryMan.name && (
                      <>
                        <SportsMotorsportsIcon
                          style={{ color: '#3f51b5', fontSize: '20px' }}
                        />{' '}
                        <strong style={{ fontSize: '12px' }}>
                          {step?.deliveryMan.name}
                        </strong>
                        <br />
                      </>
                    )}
                    <strong>
                      {index < delivery.deliveryManStatusTimes.length &&
                      index !== 0
                        ? calcDurationbetweenTwoTimes(
                            delivery.deliveryManStatusTimes[index - 1].date,
                            step.date,
                          ) + ' min '
                        : calcDurationbetweenTwoTimes(
                            delivery.createdAt,
                            step.date,
                          ) + ' min'}{' '}
                    </strong>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          </div>
        </div>
      )}
      {openModal && (
        <ModalComponent
          maxWidth={true}
          close={handleCloseModal}
          visible={modal === 'all status'}
          title="status details"
        >
          <StatusDetailsComponent order={delivery} delivery={true} />
        </ModalComponent>
      )}
    </div>
  );
}
