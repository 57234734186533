import React from 'react';
import { Grid } from '@material-ui/core';
import { Stepper, StepLabel, Step, StepContent } from '@mui/material';
import {
  formatTimeForOrder,
  calcDurationbetweenTwoTimes,
} from '../../../helpers/index';
import PersonIcon from '@mui/icons-material/Person';
import SportsMotorsportsIcon from '@mui/icons-material/SportsMotorsports';

export default function statusDetailsComponent({ order, delivery }) {
  // console.log(order);
  // deliveryManStatusTimes dispatchingStatusTimes
  // dispatchingStatus and customerStatus and storeStatus
  return (
    <Grid
      container
      spacing={2}
      style={{ maxHeight: '440px', overflowY: 'scroll' }}
    >
      <Grid item xs={4}>
        {order.deliveryManStatusTimes &&
          order.deliveryManStatusTimes.length > 0 && (
            <div className="col-2">
              <Stepper orientation="vertical">
                {order.deliveryManStatusTimes.map((step, index) => (
                  <Step key={`${step?.status + index}`} active={true}>
                    <StepLabel>{step.status}</StepLabel>
                    <StepContent>
                      <strong style={{ fontSize: '12px' }}>
                        {formatTimeForOrder(step.date)}{' '}
                      </strong>
                      <br />
                      {step?.admin &&
                        Object.keys(step.admin).length &&
                        step?.admin?.userName && (
                          <div
                            style={{ display: 'flex', flexDirection: 'row' }}
                          >
                            <PersonIcon
                              style={{ color: '#3f51b5', fontSize: '20px' }}
                            />
                            <strong>{step?.admin?.userName}</strong>
                            <br />
                          </div>
                        )}
                      {step?.deliveryMan && step?.deliveryMan.name && (
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <SportsMotorsportsIcon
                            style={{ color: '#3f51b5', fontSize: '20px' }}
                          />{' '}
                          <strong>{step?.deliveryMan.name}</strong>
                          <br />
                        </div>
                      )}

                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <span>
                          {index < order.deliveryManStatusTimes.length &&
                          index !== 0
                            ? calcDurationbetweenTwoTimes(
                                order.deliveryManStatusTimes[index - 1].date,
                                step.date,
                              )
                            : calcDurationbetweenTwoTimes(
                                order.createdAt,
                                step.date,
                              )}
                        </span>
                        min
                      </div>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
            </div>
          )}
      </Grid>
      <Grid item xs={4}>
        {order.dispatchingStatusTimes &&
          order.dispatchingStatusTimes.length > 0 && (
            <div className="col-2">
              <Stepper orientation="vertical">
                {order.dispatchingStatusTimes.map((step, index) => (
                  <Step key={`${step?.status + index}`} active={true}>
                    <StepLabel>{step.status}</StepLabel>
                    <StepContent>
                      <strong style={{ fontSize: '12px' }}>
                        {formatTimeForOrder(step.date)}{' '}
                      </strong>
                      <br />
                      {step?.admin &&
                        Object.keys(step.admin).length &&
                        step?.admin?.userName && (
                          <div
                            style={{ display: 'flex', flexDirection: 'row' }}
                          >
                            <PersonIcon
                              style={{ color: '#3f51b5', fontSize: '20px' }}
                            />
                            {step?.admin?.userName}
                            <br />
                          </div>
                        )}
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <span>
                          {index < order.dispatchingStatusTimes.length &&
                          index !== 0
                            ? calcDurationbetweenTwoTimes(
                                order.dispatchingStatusTimes[index - 1].date,
                                step.date,
                              )
                            : calcDurationbetweenTwoTimes(
                                order.createdAt,
                                step.date,
                              )}
                        </span>
                        min
                      </div>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
            </div>
          )}
      </Grid>
      <Grid item xs={4}>
        {delivery &&
          order.deliveryStateTimes &&
          order.deliveryStateTimes.length > 0 && (
            <div className="col-2">
              <Stepper orientation="vertical">
                {order.deliveryStateTimes.map((step, index) => (
                  <Step key={`${step?.status + index}`} active={true}>
                    <StepLabel>{step.status}</StepLabel>
                    <StepContent>
                      <strong style={{ fontSize: '12px' }}>
                        {formatTimeForOrder(step.date)}{' '}
                      </strong>
                      <br />
                      {step?.admin &&
                        Object.keys(step.admin).length &&
                        step?.admin?.userName && (
                          <div
                            style={{ display: 'flex', flexDirection: 'row' }}
                          >
                            <PersonIcon
                              style={{ color: '#3f51b5', fontSize: '20px' }}
                            />
                            {step?.admin?.userName}
                            <br />
                          </div>
                        )}
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <span>
                          {index < order.deliveryStateTimes.length &&
                          index !== 0
                            ? calcDurationbetweenTwoTimes(
                                order.deliveryStateTimes[index - 1].date,
                                step.date,
                              )
                            : calcDurationbetweenTwoTimes(
                                order.createdAt,
                                step.date,
                              )}
                        </span>
                        min
                      </div>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
            </div>
          )}
      </Grid>
    </Grid>
  );
}
