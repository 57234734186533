import React, { useState, useEffect } from 'react';
import { Tab, Box, Tabs } from '@material-ui/core';
import { TabPanel, TabContext, TabList } from '@material-ui/lab';
import GridContainer from '../../../components/material-dashboard-pro-react/Grid/GridContainer';
import Card from '../../../components/material-dashboard-pro-react/Card/Card';
import CardHeader from '../../../components/material-dashboard-pro-react/Card/CardHeader';
import CardBody from '../../../components/material-dashboard-pro-react/Card/CardBody';

import LocationGpsSD from './location.gps.sd.page';
import LocationGpsUpdate from './location.gps.update.page';
import LocationPartner from './location.partners.page';
import LocationPartnerRoom from './location.partners.room.page';
import MapViewDeliveryManSocketPage from '../../dashboard/pages/MapViewDeliveryManSocket.page';

export default function AppSettingPage() {
  const [value, setValue] = useState('1');
  const [setting, setSetting] = useState({});
  const [deliveryMan, setDeliveryMan] = useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // const fetchSettingFromAPI = () => {
  //   fetchSetting()
  //     .then(({ data }) => {
  //       setSetting(data);
  //     })
  //     .catch((error) => console.log(error));
  // };
  // useEffect(() => {
  //   fetchSettingFromAPI();
  // }, []);
  // console.log('setting',setting);
  const handleDeliveryMan = (deliveryMan) => {
    setDeliveryMan(deliveryMan);
  };
  return (
    <GridContainer>
      <Card>
        <CardHeader> App setting</CardHeader>

        <CardBody>
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList
                  variant="scrollable"
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab label="Socket GPS sd" value="1" />
                  <Tab label="Socket GPS update" value="2" />
                  <Tab label="Socket partner" value="3" />
                  <Tab label="Socket partner (marjane event)" value="4" />
                  <Tab label="Socket ALL" value="5" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <LocationGpsSD />
              </TabPanel>
              <TabPanel value="2">
                <LocationGpsUpdate />
              </TabPanel>
              <TabPanel value="3">
                <LocationPartner handleDeliveryMan={handleDeliveryMan} />
              </TabPanel>
              <TabPanel value="4">
                <LocationPartnerRoom />
              </TabPanel>
              <TabPanel value="5">
                <div className="row">
                  <div className="col-4">
                    <LocationGpsSD />
                  </div>
                  <div className="col-4">
                    <LocationGpsUpdate />
                  </div>
                  <div className="col-4">
                    <LocationPartner handleDeliveryMan={handleDeliveryMan} />
                  </div>
                </div>
              </TabPanel>
            </TabContext>
          </Box>
          <Box sx={{ width: '100%', typography: 'body1' }}>
            {deliveryMan?.location && (
              <MapViewDeliveryManSocketPage deliveryMan={deliveryMan} />
            )}
          </Box>
        </CardBody>
      </Card>
    </GridContainer>
  );
}
