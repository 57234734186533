import React, { useState } from 'react';
import SelectBoxComponent from '../../../components/SelectBox.component';
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import { Box } from '@material-ui/core';

export default function AddTransportationComponent({
  transportation,
  formik,
  maxObject,
  close,
}) {
  //   console.log('maxObject', maxObject);
  const [moyens, setMoyens] = useState('');
  const [disabled, setDisabled] = useState(true);
  const addTrans = () => {
    if (!moyens) {
      setDisabled(true);
      return;
    } else if (
      (formik.values.meansOfTransportation || []).length > 0 &&
      formik.values.meansOfTransportation.find((x) => x.name === moyens)
    ) {
      setDisabled(true);
      return;
    }
    setDisabled(false);
    // console.log(
    //   'maxObject.meansOfTransportation',
    //   maxObject.meansOfTransportation,
    // );
    const imgUrl =
      (maxObject.meansOfTransportation || []).find(
        (x) => x.name?.toLowerCase() === moyens?.toLowerCase(),
      )?.info?.imageUrl || '';
    // console.log('imgUrl', imgUrl);
    const dataToUpdate = formik.values.meansOfTransportation || [];
    dataToUpdate.push({
      name: moyens,
      info: {
        enable: false,
        priceIntervals: [
          {
            ds: 0,
            de: 2,
            cof: 3,
          },
        ],
        imageUrl: imgUrl,
      },
    });
    formik.setFieldValue(`meansOfTransportation`, dataToUpdate);
    close();
  };
  return (
    <div>
      <SelectBoxComponent
        items={transportation.map((item) => ({
          value: item,
          label: item,
        }))}
        inputLabel="transp"
        onValueChange={(e) => {
          setMoyens(e);
          setDisabled(false);
        }}
      />
      <Box textAlign="right">
        <Button
          round
          type="submit"
          color="success"
          size="sm"
          loaderColor="white"
          onClick={addTrans}
          disabled={disabled}
        >
          add
        </Button>
      </Box>
    </div>
  );
}
