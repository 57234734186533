import React, { useEffect, useState } from 'react';
import {
  getRamadanTimingConfig,
  setRamadanTimingConfig,
} from '../services/serverApi.service';
import SwitchComponent from '../../../components/Switch.component';

export default function KallixPayTab({ setting }) {
  const [chargeKaalixPay, setChargeKaalixPay] = useState(false);
  const [useRamadanTimingSetting, setUseRamadanTimingSetting] = useState(false);
  const editUseRamadanTiming = (value) => {
    // setting[0].chargeKaalixPay = value;
    setRamadanTimingConfig({ useRamadanTimingSetting: value })
      .then(({ data }) => {
        setUseRamadanTimingSetting(
          data?.storeTimingConfig?.useRamadanTimingSetting,
        );
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    getRamadanTimingConfig()
      .then(({ data }) =>
        setUseRamadanTimingSetting(
          data?.storeTimingConfig?.useRamadanTimingSetting,
        ),
      )
      .catch((err) => console.log(err));

    return () => {};
  }, []);

  return (
    <div>
      <SwitchComponent
        onChange={(value) => editUseRamadanTiming(value)}
        value="checked"
        checked={useRamadanTimingSetting}
        label={'use Ramadan Timing'}
      />
    </div>
  );
}
