import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useAlert } from 'react-alert';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Box } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import CustomInput from '../../../components/material-dashboard-pro-react/CustomInput/CustomInput';
import { defaultImagesAction } from '../../../redux/uploadRedux/upload.action';
import SwitchComponent from '../../../components/Switch.component';
import ImageUpload from '../../../components/material-dashboard-pro-react/CustomUpload/ImageUpload';
import { updateItem } from '../services/serverAPI.service';
import CustomRadioGroup from '../../../components/material-dashboard-pro-react/CustomRadioGroup/CustomRadioGroup';

const validationSchema = yup.object({
  // name: yup.string().required(),
  // storeId: yup.string().required(),
  // productId: yup.string().required(),
  // description: yup.string().required(),
  // price: yup.number().typeError('value is number').required(),
  // imageUrl: yup.string().required(),
  // inStock: yup.bool().required(),
  // specifications: yup.array().of(yup.string()).required(),
  // isMainPromo: yup.bool().required(),
  promoSetting: yup.object().shape({
    type: yup.string().required(),
    // value: yup.number().required(),
    value: yup.number().when('type', {
      is: (value) => value == 'reduction',
      then: yup
        .number()
        .typeError('value is number')
        .required('value is required when choise reduction'),
      // otherwise: yup.number().typeError('value is number')
      otherwise: '',
    }),
    discountType: yup.string().when('type', {
      is: (value) => value == 'reduction',
      then: yup.string().required(),
      // otherwise: yup.number().typeError('value is number')
      otherwise: yup.string().nullable(),
    }),
    // discountType: yup.string().required(),
    // minCartPrice: yup.number().when('type',{
    // is:value=>value=='offered product'||value=='1 buy = 1 free',
    // then:yup.number().typeError('minCartPrice is number').required(),
    // // otherwise: yup.number().typeError('value is number')
    // otherwise: ''
    // }),
    minCartPrice: yup.number(),
    // enabled: yup.bool().required(),
    isFromKaalix: yup.bool().required(),
  }),
});

export default function EditPromo({ data, close, onItemEdited }) {
  // console.log(data?.name?.props?.promoSetting?.type);
  // const {name}=data
  const alert = useAlert();
  const [isLoading, setIsLoading] = useState(false);
  const radioGroupData = [
    { name: 'Discount' },
    { name: 'OfferedAboveMinCartPrice' },
    { name: 'OneBoughtOneOffered' },
  ];
  const secondRadioGroupData = [{ name: 'Percentage' }, { name: 'Absolute' }];
  const [promoValueIcon, setPromoValueIcon] = useState('%');
  const storeId = localStorage.getItem('storeId');
  const formik = useFormik({
    initialValues: {
      name: '',
      storeId: '',
      productId: '',
      description: '',
      price: '',
      imageUrl: '',
      inStock: '',
      specifications: [],
      isMainPromo: '',
      _id: '',
      promoSetting: {
        type: '',
        // type: radioGroupData[0].name,
        value: 0,
        discountType: 'Percentage',
        minCartPrice: 0,
        enabled: true,
        isFromKaalix: false,
      },
    },
    validationSchema,
  });
  const onEdit = () => {
    console.log(JSON.stringify(formik.values));
    setIsLoading(true);
    updateItem(formik.values)
      .then(() => {
        alert.show('item updated', {
          type: 'success',
        });
        close();
        onItemEdited(0, 10);
      })
      .catch((error) => {
        alert.show(error.message, {
          type: 'error',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  async function handleClick(event) {
    if (event.target.value != 'Discount') {
      // const errorObj=formik?.error?.promoSetting
      // delete errorObj?.value
      formik.setFieldValue('promoSetting.discountType', 'Percentage');
      formik.setFieldValue('promoSetting.value', 0);
      // console.log(errorObj?.value);
      // delete errorObj?.value
    }
    if (event.target.value == 'Discount') {
      formik.setFieldValue('promoSetting.value', 0);
      formik.setFieldValue(
        'promoSetting.discountType',
        secondRadioGroupData[0].name,
      );
    }
    if (
      event.target.value == 'OfferedAboveMinCartPrice' ||
      event.target.value == 'OneBoughtOneOffered'
    ) {
      // formik.setFieldValue('promoSetting.minCartPrice','')
      // formik.setFieldValue('promoSetting.discountType',secondRadioGroupData[0].name)
    }

    // if (event.target.value === value) {
    //   setValue("");
    // } else {
    //   setValue(event.target.value);
    // }
  }
  const handleClickDiscountType = (event) => {
    if (event.target.value == 'Percentage') {
      setPromoValueIcon('%');
    }
    if (event.target.value == 'Absolute') {
      setPromoValueIcon('dh');
    }
  };
  //   console.log(data);
  const setValue = () => {
    if (data) {
      console.log(data);
      //   !iconUrl&& dispatch(defaultImagesAction(data.imageUrl.props.src))

      formik.setValues({
        name: data?.name,
        storeId: data?.storeId,
        productId: data?.productId,
        description: data?.description,
        price: data?.price,
        imageUrl: data?.imageUrl,
        inStock: data?.inStock,
        specifications: data?.specifications,
        isMainPromo: data?.isMainPromo,
        _id: data?._id,
        promoSetting: {
          type: data?.promoSetting?.type,
          // type: radioGroupData[0].name,
          value: data?.promoSetting?.value,
          discountType: data?.promoSetting?.discountType || 'Percentage',
          minCartPrice: data?.promoSetting?.minCartPrice,
          isFromKaalix: data?.promoSetting?.isFromKaalix || false,
          // imageUrl:data?.imageUrl
          // enabled: true,
        },
      });
    }
  };
  useEffect(() => {
    setValue();
  }, [data]);
  // console.log('formik.values',formik.values);
  // console.log(data);

  return (
    <div>
      {data && (
        <>
          <CustomRadioGroup
            data={radioGroupData}
            error={
              formik.touched.type && Boolean(formik.errors.promoSetting.type)
            }
            inputProps={{
              value: formik.values.promoSetting.type,
              onBlur: formik.handleBlur('promoSetting.type'),
              onChange: formik.handleChange('promoSetting.type'),
            }}
            handleClick={handleClick}
          />
          {/* <div style={{display: 'flex',flexDirection:'row'}}> */}
          <div
            style={{
              border: '1px solid #000',
              padding: '10px',
              borderRadius: '10px',
            }}
          >
            <CustomRadioGroup
              data={secondRadioGroupData}
              error={
                formik.touched.name &&
                Boolean(formik?.errors?.promoSetting?.discountType)
              }
              inputProps={{
                value: formik.values.promoSetting.discountType,
                onBlur: formik.handleBlur('promoSetting.discountType'),
                onChange: formik.handleChange('promoSetting.discountType'),
              }}
              handleClick={handleClickDiscountType}
              disabled={formik.values.promoSetting.type != 'Discount'}
            />
            <CustomInput
              labelText="value"
              formControlProps={{
                fullWidth: false,
              }}
              type="text"
              error={
                formik.touched?.promoSetting?.value &&
                Boolean(formik?.errors?.promoSetting?.value)
              }
              inputProps={{
                value: formik.values.promoSetting.value,
                onBlur: formik.handleBlur('promoSetting.value'),
                onChange: formik.handleChange('promoSetting.value'),
              }}
              helperText={
                formik.errors?.promoSetting?.value &&
                formik.errors?.promoSetting?.value
              }
              disabled={formik.values.promoSetting.type != 'Discount'}
              // onChange={(e)=>console.log('e.target.value')}
            />
            {promoValueIcon}
          </div>
          <CustomInput
            labelText="amount"
            formControlProps={{
              fullWidth: false,
            }}
            type="text"
            error={
              formik.touched.name &&
              Boolean(formik?.errors?.promoSetting?.minCartPrice)
            }
            inputProps={{
              value: formik.values.promoSetting.minCartPrice,
              onBlur: formik.handleBlur('promoSetting.minCartPrice'),
              onChange: formik.handleChange('promoSetting.minCartPrice'),
            }}
            disabled={
              formik.values.promoSetting.type != 'OfferedAboveMinCartPrice' &&
              formik.values.promoSetting.type != 'OneBoughtOneOffered'
            }
            // onChange={(e)=>console.log('e.target.value')}
          />
          dh
          <Box textAlign="left">
            <SwitchComponent
              onChange={(value) =>
                formik.setFieldValue('promoSetting.isFromKaalix', value)
              }
              checked={formik.values.promoSetting.isFromKaalix}
              error={
                formik.touched?.promoSetting?.isFromKaalix &&
                Boolean(formik.errors?.promoSetting?.isFromKaalix)
              }
              label="isFromKaalix"
            />
          </Box>
          <Box textAlign="center">
            <Button
              round
              type="submit"
              color="success"
              disabled={!formik.dirty || !formik.isValid || isLoading}
              loading={isLoading}
              loaderColor="white"
              onClick={onEdit}
            >
              Edit Promo
            </Button>
          </Box>
        </>
      )}
    </div>
  );
}

EditPromo.propTypes = {
  data: PropTypes.any,
  close: PropTypes.any,
  onItemEdited: PropTypes.func.isRequired,
};
