import React, { useState, useEffect, useRef } from 'react';
import ModalComponent from '../../../components/Modal.component';
import DataTableContainerComponent from '../../../components/DataTableSortingContainer.component';
import GridContainer from '../../../components/material-dashboard-pro-react/Grid/GridContainer';
import Card from '../../../components/material-dashboard-pro-react/Card/Card';
import CardHeader from '../../../components/material-dashboard-pro-react/Card/CardHeader';
import CardBody from '../../../components/material-dashboard-pro-react/Card/CardBody';
import CustomButton from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import SelectBoxComponent from '../../../components/SelectBox.component';
import { getCities } from '../../delivery-info/services/serverApi.service';
import CustomInput from '../../../components/material-dashboard-pro-react/CustomInput/CustomInput';
import CutomeButton from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import {
  getPartnerDeliveryManInCities,
  getPartnerDeliveryManInCity,
  getParners,
  assignDeliveryMan,
  removeWareHouseFromDeliveryManList,
  preferredWareHouse,
  getApprouvedProvider,
  getApprouvedProviderByIds,
  getDeliveryMenBackup,
  getDeliveryMenBackupUsingLabel,
} from '../services/server.api.service';
import { formatTimeForOrder } from '../../../helpers/index';
import { Grid, Checkbox, FormControlLabel } from '@material-ui/core';
import { Box } from '@material-ui/core';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Autocomplete } from '@material-ui/lab';
import {
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Typography,
  Paper,
  Button,
  TextField,
} from '@mui/material';
import ButtonComponent from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import moment from 'moment';
import CloseIcon from '@material-ui/icons/Close';
import EditProvider from '../components/EditProvider';

const validationSchema = yup.object({
  search: yup.string().optional(),
});
export default function DeliveryMenPartnerPage() {
  const inpRef = useRef();
  const [resultsData, setResultsData] = useState({
    total: 0,
    items: [],
  });
  const [cities, setCities] = useState([]);
  const [deliveryMen, setDeliveryMen] = useState([]);
  const [deliveryMan, setDeliveryMan] = useState({});
  const [indexDeliveryMan, setIndexDeliveryMan] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState('');
  const [openSubModal, setOpenSubModal] = useState('');
  const [checkedObj, setCheckedObj] = useState({});
  const [selectedCity, setSelectedCity] = useState([]);
  const [partners, setPartners] = useState([]);
  const [wareHouses, setWareHouses] = useState([]);
  const [total, setTotal] = useState('');
  const [page, setPage] = useState(0);
  let numberOfRows = [100, 500, 1000];
  const [rowsPerPage, setRowsPerPage] = useState(numberOfRows[0]);
  const [searchText, setSearchText] = useState('');
  const [cityId, setCityId] = useState('');
  const [allWarehouses, setAllWarehouses] = useState([]);
  const [filterByWarehouse, setFilterByWarehouse] = useState(false);
  const [warehouseSelected, setWarehouseSelected] = useState([]);
  const formik = useFormik({
    initialValues: {
      search: '',
    },
    validationSchema,
  });
  const wareHousesTableColumns = [
    'city',
    'organisation Name',
    'warehouse Name',
    'contact',
    'location',
    'delete',
  ];
  // console.log('allWarehouses', allWarehouses);
  const getAllCities = () => {
    getCities(0, 0, false)
      .then(({ data }) => {
        const TemCityObj = {
          all: true,
        };
        data.map((city) => {
          TemCityObj[city.name] = true;
        });
        data.unshift({ _id: 'all', name: 'all' });
        const tempSelectedCityArray = [];
        data.map((city) => {
          tempSelectedCityArray.push(city._id);
        });
        setSelectedCity(tempSelectedCityArray);
        setCities(data);
        setCheckedObj(TemCityObj);
        // changeCity(['all']);
      })
      .catch((error) => console.log(error));
  };
  const handleModal = (item, type, index) => {
    setDeliveryMan(item);
    setIndexDeliveryMan(index);
    setOpenModal(type);
  };
  const handleSubModal = (type) => {
    // setDeliveryMan(item);
    // setIndexStore(index);
    setOpenSubModal(type);
    setWareHouses(
      partners
        .reduce(
          (warehousesCumulator, obj) => [
            ...warehousesCumulator,
            ...obj?.warehouses,
          ],
          [],
        )
        .filter((w) => w.cityId == deliveryMan.cityId),
    );
  };
  const handleCloseModal = () => {
    setOpenModal('');
    setOpenSubModal('');
  };
  const handleSubCloseModal = () => {
    setOpenSubModal('');
  };
  const resetDataTabale = () => {
    setResultsData({ items: [] });
  };
  const changeCity = (citiesId) => {
    if (citiesId.includes('all')) {
      getPartnerDeliveryManInCity('all')
        .then(({ data }) => {
          setDeliveryMen(data.deliveryMen);
        })
        .catch((error) => {
          setDeliveryMen([]);
          resetDataTabale();
        });
    } else {
      getPartnerDeliveryManInCities(citiesId)
        .then(({ data }) => {
          setDeliveryMen(data.deliveryMen);
        })
        .catch((error) => {
          setDeliveryMen([]);
          resetDataTabale();
        });
    }
  };
  const getWareHouse = () => {
    getParners()
      .then(({ data }) => {
        setPartners(
          data?.cities.filter((x) => x.warehouses && x.warehouses.length > 0),
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteWarehouse = (id) => {
    removeWareHouseFromDeliveryManList(deliveryMan._id, id)
      .then(({ data }) => {
        const tempDeliveryMen = [...deliveryMen];
        // tempDeliveryMen[indexDeliveryMan] = data?.deliveryMan;
        tempDeliveryMen[indexDeliveryMan].warehouses =
          data?.deliveryMan?.warehouses;
        tempDeliveryMen[indexDeliveryMan].preferedWareHouse =
          data?.deliveryMan?.preferedWareHouse;
        setDeliveryMen(tempDeliveryMen);
        handleCloseModal();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const assignWarehouse = (obj) => {
    assignDeliveryMan(deliveryMan._id, obj)
      .then(({ data }) => {
        const tempDeliveryMen = [...deliveryMen];
        tempDeliveryMen[indexDeliveryMan].warehouses =
          data?.deliveryMan?.warehouses;
        tempDeliveryMen[indexDeliveryMan].preferedWareHouse =
          data?.deliveryMan?.preferedWareHouse;
        setDeliveryMen(tempDeliveryMen);
        handleCloseModal();
      })
      .catch((error) => console.log(error));
  };
  const changePreferredWareHouse = (warehouseId) => {
    const findWarehouse = deliveryMan.warehouses.find(
      (x) => x._id == warehouseId,
    );
    if (findWarehouse) {
      const { name, wareHouseLabel, wareHouseId, _id } = findWarehouse;
      preferredWareHouse(deliveryMan._id, {
        name,
        wareHouseLabel,
        wareHouseId,
        _id,
      })
        .then(({ data }) => {
          const tempDeliveryMen = [...deliveryMen];
          tempDeliveryMen[indexDeliveryMan].preferedWareHouse =
            data?.deliveryMan?.preferedWareHouse;
          setDeliveryMen(tempDeliveryMen);
          handleCloseModal();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  useEffect(() => {
    getAllCities();
    getWareHouse();
  }, []);
  useEffect(() => {
    partners &&
      setAllWarehouses(
        partners.reduce(
          (warehousesCumulator, obj) => [
            ...warehousesCumulator,
            ...obj?.warehouses,
          ],
          [],
        ),
      );
  }, [partners]);
  const prepareToShowDeliveryMen = (item, index) => {
    // console.log('item', item);
    return {
      ...item,
      approvedCol: item.approved ? 'YES' : 'NO',
      cityCol: cities.find((x) => x._id === item.cityId)?.name,
      createdAtCol:
        item.createdAt && moment(item.createdAt).format('DD.MM.YYYY hh:mm'),
      warehousesCell: (
        <span
          style={{ cursor: 'pointer' }}
          onClick={() => handleModal(item, 'warehouses', index)}
        >
          {item.warehouses ? item.warehouses.length : '--'}
        </span>
      ),
      phoneCell: (
        <span
          style={{ cursor: 'pointer' }}
          onClick={() => handleModal(item, 'editPhone', index)}
        >
          {item.phone ? item.phone : '--'}
        </span>
      ),
    };
  };
  const loadApprouvedProviderList = (
    page,
    limit,
    city,
    txt,
    tempFilterByWarhous,
  ) => {
    setIsLoading(true);
    // console.log(page, rowsPerPage)
    if (
      formik.values.search ||
      tempFilterByWarhous === false ||
      !filterByWarehouse
    ) {
      console.log('txt', txt);
      formik.values.search && setFilterByWarehouse(false);
      formik.values.search && setWarehouseSelected([]);
      getApprouvedProvider(page, limit, city, txt)
        .then(async ({ data }) => {
          const DeliveryMenIds = (data.deliveryMan || []).map((x) => x._id);
          console.log('DeliveryMenIds', DeliveryMenIds);
          // const fetchDeliveryMenBackup = await
          const deliveryMenList = data.deliveryMan;
          const deliveryMenListTotal = data.total;
          var mergedList = data.deliveryMan;
          await getDeliveryMenBackup(0, 0, DeliveryMenIds)
            .then(({ data }) => {
              console.log('fetchDeliveryMenBackup', data);
              mergedList = deliveryMenList.map((t1) => ({
                ...t1,
                ...data.find((t2) => t2._id === t1._id),
              }));
              // console.log('mergedList', mergedList);
              return;
            })
            .catch((err) => console.log(err));
          // setTotal(deliveryMenListTotal);
          setDeliveryMen(mergedList);
          setTotal(data.total);
        })
        .catch((error) => {
          setResultsData({
            total: 0,
            items: [],
            tableItems: [],
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      console.log('filterByWarehouse', warehouseSelected);
      getDeliveryMenBackupUsingLabel(page, limit, warehouseSelected)
        .then(async ({ data }) => {
          const DeliveryMenIds = (data.DeliveryManDetailsBackup || []).map(
            (x) => x._id,
          );
          console.log('DeliveryMenIds', DeliveryMenIds);
          // const fetchDeliveryMenBackup = await
          const deliveryMenList = data.DeliveryManDetailsBackup;
          const deliveryMenListTotal = data.total;
          var mergedList = data.deliveryMan;
          await getApprouvedProviderByIds(0, 0, DeliveryMenIds)
            .then(({ data }) => {
              console.log('fetchDeliveryMenBackup', data);
              mergedList = deliveryMenList.map((t1) => ({
                ...t1,
                ...(data.deliveryMen || []).find((t2) => t2._id === t1._id),
              }));
              console.log('mergedList', mergedList);
              return;
            })
            .catch((err) => console.log(err));
          // setTotal(deliveryMenListTotal);
          setDeliveryMen(mergedList);
          setTotal(data.total);
        })
        .catch((error) => {
          setResultsData({
            total: 0,
            items: [],
            tableItems: [],
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    cities &&
      deliveryMen &&
      deliveryMen.length > 0 &&
      setResultsData({
        items: deliveryMen.map((item, index) => {
          return prepareToShowDeliveryMen(item, index);
        }),
      });
  }, [deliveryMen, cities]);

  const handlePaginate = (val) => {
    setResultsData({
      items: [],
      tableItems: [],
    });
    loadApprouvedProviderList(
      val.pageSelect,
      val.numberOfRows,
      cityId,
      // searchText,
      formik.values.search,
    );
    setPage(val.pageSelect);
    setRowsPerPage(val.numberOfRows);
  };

  return (
    <>
      <GridContainer>
        <Card>
          <CardBody>
            <Grid container>
              {cities && (
                <SelectBoxComponent
                  items={cities.map((city) => ({
                    value: city._id,
                    label: city.name,
                  }))}
                  inputLabel="city list"
                  onValueChange={(items) => {
                    setCityId(items);
                    setFilterByWarehouse(false);
                    setWarehouseSelected([]);
                  }}
                  value={cityId}
                />
              )}
              {allWarehouses && (
                <SelectBoxComponent
                  items={(allWarehouses || []).map((warehouse) => ({
                    value: warehouse.wareHouseLabel,
                    label: warehouse.wareHouseLabel,
                  }))}
                  inputLabel="warehouse list"
                  onValueChange={(items) => {
                    formik.setValues({ search: '' });
                    setCityId('');
                    setSearchText('');
                    setFilterByWarehouse(true);
                    setWarehouseSelected(items);
                  }}
                  value={warehouseSelected}
                  multiple
                />
              )}
              <Grid item xs={8}>
                {/* <input
                  type="text"
                  ref={inpRef}
                  placeholder="provider search"
                  style={{
                    border: 'none',
                    borderBottom: '1px solid black',
                  }}
                  defaultValue={searchText}
                  value={searchText}
                /> */}

                <CustomInput
                  labelText="search"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  type="text"
                  // error={formik.touched.search && Boolean(formik.errors.search)}
                  inputProps={{
                    value: formik.values.search,
                    onBlur: formik.handleBlur('search'),
                    onChange: formik.handleChange('search'),
                  }}
                />
              </Grid>
              <Grid item xs={4} container justify="flex-end">
                <Box textAlign="left">
                  <CustomButton
                    round
                    type="submit"
                    color="success"
                    size="sm"
                    loaderColor="white"
                    onClick={() => {
                      // setSearchText(inpRef.current.value);
                      setSearchText(formik.values.search);
                      page > 0 && setPage(0);
                      loadApprouvedProviderList(
                        page > 0 ? 0 : page,
                        rowsPerPage,
                        cityId,
                        // inpRef.current.value,
                        formik.values.search,
                      );
                    }}
                  >
                    find Provider
                  </CustomButton>
                </Box>
                <Box textAlign="left">
                  <CustomButton
                    round
                    type="submit"
                    color="google"
                    size="sm"
                    loaderColor="white"
                    onClick={async () => {
                      setSearchText('');
                      page > 0 && setPage(0);
                      setCityId('');
                      await formik.setValues({ search: '' });
                      await setFilterByWarehouse(false);
                      setWarehouseSelected([]);
                      loadApprouvedProviderList(
                        page > 0 ? 0 : page,
                        rowsPerPage,
                        '',
                        '',
                        false,
                      );
                    }}
                  >
                    clear
                  </CustomButton>
                </Box>
              </Grid>
            </Grid>
          </CardBody>
        </Card>
      </GridContainer>
      <DataTableContainerComponent
        total={total}
        resultsData={resultsData}
        initData={resultsData.items}
        page={page + 1}
        columns={[
          {
            Header: 'Name',
            accessor: 'name',
          },
          {
            Header: 'phone',
            accessor: 'phoneCell',
          },
          {
            Header: 'email',
            accessor: 'email',
          },
          {
            Header: 'Approved',
            accessor: 'approvedCol',
          },
          {
            Header: 'city',
            accessor: 'cityCol',
          },
          {
            Header: 'deliveryType',
            accessor: 'deliveryType',
          },
          {
            Header: 'warehouses',
            accessor: 'warehousesCell',
          },
          // {
          //   Header: 'actions',
          //   accessor: 'actions',
          // },
        ]}
        title="Approuved Provider List"
        isLoading={isLoading}
        loadItemsList={loadApprouvedProviderList}
        onPaginate={handlePaginate}
        isPaginate={true}
        actionButtons={
          {
            // view: {
            //   color: 'info',
            //   type: 'view',
            //   component: HistoryLocationComponent,
            // },
            // edit: {
            //   color: 'success',
            //   type: 'edit',
            //   component: EditProvider,
            //   props: { cities: cities },
            // },
            // delete: {
            //   color: 'danger',
            //   type: 'delete',
            //   component: DeleteProvider,
            // },
          }
        }
      />
      {openModal && openModal == 'warehouses' && (
        <ModalComponent
          maxWidth={'md'}
          close={handleCloseModal}
          visible={true}
          title={`warehouses list (${deliveryMan?.name})`}
        >
          <div>
            <SelectBoxComponent
              items={(deliveryMan.warehouses || []).map((wareHouse) => ({
                value: wareHouse?._id,
                label: wareHouse?.name,
              }))}
              inputLabel="prefered WareHouse"
              onValueChange={(item) => changePreferredWareHouse(item)}
              value={deliveryMan?.preferedWareHouse?._id || ''}
            />
            <CustomButton
              color="success"
              rounded
              onClick={() => handleSubModal('edit')}
            >
              add
            </CustomButton>
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    {wareHousesTableColumns.map((item) => (
                      <TableCell align="center">{item}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(deliveryMan.warehouses || []).map((warehouse) => (
                    <TableRow>
                      <TableCell align="center">
                        {cities.find((x) => x._id == warehouse.cityId)?.name}
                      </TableCell>
                      <TableCell align="center">
                        {warehouse?.organisationName}
                      </TableCell>
                      <TableCell align="center">{warehouse?.name}</TableCell>
                      <TableCell align="center">{warehouse?.contact}</TableCell>
                      <TableCell align="center">
                        {warehouse?.location &&
                          JSON.stringify(warehouse?.location)}
                      </TableCell>
                      <TableCell align="center">
                        <CloseIcon
                          style={{ color: 'red', cursor: 'pointer' }}
                          onClick={() => deleteWarehouse(warehouse._id)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </ModalComponent>
      )}
      {openSubModal && openSubModal == 'edit' && (
        <ModalComponent
          maxWidth={'md'}
          close={handleSubCloseModal}
          visible={true}
          title={`warehouses edit (${deliveryMan?.name})`}
        >
          <Autocomplete
            id="combo-box-demo"
            options={wareHouses || []}
            getOptionLabel={(option) => option.name}
            style={{ width: 250 }}
            onChange={(event, newValue) => {
              newValue && assignWarehouse(newValue);
            }}
            renderInput={(wareHouses) => (
              <TextField
                {...wareHouses}
                label="wareHouse name"
                variant="outlined"
                onChange={(e) => console.log(e.target.value)}
              />
            )}
          />
        </ModalComponent>
      )}
      {openModal && openModal == 'editPhone' && (
        <ModalComponent
          maxWidth={'md'}
          close={handleCloseModal}
          visible={true}
          title="editPhone"
        >
          <EditProvider
            cities={cities}
            close={handleCloseModal}
            data={deliveryMan}
            setDeliveryMen={setDeliveryMen}
            deliveryMen={deliveryMen}
            indexDeliveryMan={indexDeliveryMan}
          />
        </ModalComponent>
      )}
    </>
  );
}
