import axios from 'axios';
import {
  backendServerConfig,
  MICROSERVICE_BASE_URL,
} from '../../../config/backendServer.config';

export function getCountries(skip, limit, paged) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.ADMIN}/country?page=${skip || 0}&limit=${limit || 0
    }&paged=${paged || false}`,
  );
}

export function updateCountry(data) {
  return axios.put(`${MICROSERVICE_BASE_URL.ADMIN}/country/${data._id}`, {
    country: data,
  });
}

export function createCountry(data) {
  return axios.post(`${MICROSERVICE_BASE_URL.ADMIN}/country`, {
    country: data,
  });
}

export function getCities(skip, limit, paged) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.ADMIN}/city?page=${skip || 0}&limit=${limit || 0
    }&paged=${paged || false}`,
  );
}

export function createCity(data) {
  return axios.post(`${MICROSERVICE_BASE_URL.ADMIN}/city`, {
    ...data,
  });
}
export function createFlag(data) {
  return axios.post(`${MICROSERVICE_BASE_URL.ADMIN}/flag`, {
    ...data,
  });
}

export function updateCity(id, data) {
  return axios.put(`${MICROSERVICE_BASE_URL.ADMIN}/city/${id}`, {
    ...data,
  });
}

export function getServices(page, limit, paged) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.ADMIN}/service?page=${page || 0}&limit=${limit || 5
    }&paged=${paged || false}`,
  );
}
export function getFlags(page, limit, paged) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.ADMIN}/flag?page=${page || 0}&limit=${limit || 2
    }&paged=${paged || false}`,
  );
}
export function updateService(id, data) {
  return axios.put(`${MICROSERVICE_BASE_URL.ADMIN}/service/${id}`, {
    ...data,
  });
}
export function deleteService(id) {
  return axios.delete(`${MICROSERVICE_BASE_URL.ADMIN}/service/${id}`);
}

export function createService(data) {
  return axios.post(`${MICROSERVICE_BASE_URL.ADMIN}/service`, {
    ...data,
  });
}

export function getServiceCategories(page, limit, paged) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.ADMIN}/service_category?page=${page || 0}&limit=${limit || 5
    }&paged=${paged || false}`,
  );
}
export function getServicesById(id) {
  return axios.get(`${MICROSERVICE_BASE_URL.ADMIN}/service_category/${id}`);
}

export function createServiceCategory(data) {
  return axios.post(`${MICROSERVICE_BASE_URL.ADMIN}/service_category`, {
    ...data,
  });
}

export function updateServiceCategory(id, data) {
  return axios.put(`${MICROSERVICE_BASE_URL.ADMIN}/service_category/${id}`, {
    ...data,
  });
}

export function getDispatchingModes(page, limit, paged) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.ADMIN}/dispatching_mode?page=${page || 0}&limit=${limit || 5
    }&paged=${paged || false}`,
  );
}
export function updateDispatchingMode(id, data) {
  return axios.put(`${MICROSERVICE_BASE_URL.ADMIN}/dispatching_mode/${id}`, {
    ...data,
  });
}
export function deleteDispatchingMessage(id) {
  return axios.delete(`${MICROSERVICE_BASE_URL.ADMIN}/dispatching_mode/${id}`);
}

export function createPricing(data) {
  return axios.post(`${MICROSERVICE_BASE_URL.CUSTOMER_PAYMENT}/pricing`, {
    ...data,
  });
}

export function getPricings(page, limit, paged) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.CUSTOMER_PAYMENT}/pricing?page=${page || 0
    }&limit=${limit || 5}&paged=${paged || false}`,
  );
}
export function updatePricing(id, data) {
  return axios.put(`${MICROSERVICE_BASE_URL.CUSTOMER_PAYMENT}/pricing/${id}`, {
    ...data,
  });
}
export function deleteServiceCategory(id) {
  return axios.put(`${MICROSERVICE_BASE_URL.ADMIN}/service_category/${id}`);
}

export function createDispatchingMode(data) {
  return axios.post(`${MICROSERVICE_BASE_URL.ADMIN}/dispatching_mode/`, {
    ...data,
  });
}

// start endPoint for publicity
export function createPublicity(data) {
  return axios.post(`${MICROSERVICE_BASE_URL.ADMIN}/publicity_group`, {
    ...data,
  });
}
export function getPublicity() {
  return axios.post(`${MICROSERVICE_BASE_URL.ADMIN}/publicity_group/get`);
}
export function deletePulicity(id) {
  console.log(id);
  return axios.delete(`${MICROSERVICE_BASE_URL.ADMIN}/publicity_group/${id}`);
}
export function EditPulicity(id, values) {
  console.log(id);
  return axios.put(`${MICROSERVICE_BASE_URL.ADMIN}/publicity_group/${id}`, { ...values });
}
// end endPoint for publicity

// start store for publicity
export function getStores(page, limit, paged) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.STORES}/store?page=${page || 0}&limit=${limit || 0
    }&paged=${paged || false}`,
  );
}
export function getStoresBySearch(page, limit, paged, text) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.STORES}/store?page=${page || 0}&limit=${limit || 0
    }&searchText=${text}&paged=${paged || false}`,
  );
}
export function getStoresById(id) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.STORES}/store/${id}`,
  );
}
// end store for publicity
// start tags
export function addTag(data) {
  return axios.post(`${MICROSERVICE_BASE_URL.ADMIN}/tag_category`, { ...data });
}
export function getTag(page, limit, paged) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.ADMIN}/tag_category?page=${page || 0}&limit=${limit || 0
    }&paged=${paged || false}`,
  );
}

export function deleteTag(id) {
  return axios.delete(`${MICROSERVICE_BASE_URL.ADMIN}/tag_category/${id}`);
}
export function updateTag(id, data) {
  console.log('id', id);
  console.log('data', data);
  return axios.put(`${MICROSERVICE_BASE_URL.ADMIN}/tag_category/${id}`, { ...data });
}
// end tags
// ************************ start city mapping
export function getCitiesMapping(cityId) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.CONTENT}/city/${cityId}/services`,
  );
}
export function editCitiesMapping(cityId, services) {
  return axios.post(
    `${MICROSERVICE_BASE_URL.CONTENT}/mapping/city/${cityId}/services`, { services }
  );
}
// ************************ end city mapping
// ************************ start parrinage
export function getSharing() {
  return axios.get(
    `${MICROSERVICE_BASE_URL.CUSTOMER_PAYMENT}/refferal-setting`
  )
}
export function updateCityConfig(payload) {
  console.log('axios payload', payload);
  return axios.post(
    `${MICROSERVICE_BASE_URL.CUSTOMER_PAYMENT}/refferal-setting`, { ...payload }
  )
}
// ************************ end parrinage

// ************************ getChoubikPriceSetting
export function getChoubikPriceSetting(page, limit) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.CUSTOMER_PAYMENT}/choubik-price-setting?page=${page || 0}&limit=${limit || 0
    }`,
  );
}
export function updateChoubikPriceSetting(cityId, data) {
  return axios.post(
    `${MICROSERVICE_BASE_URL.CUSTOMER_PAYMENT}/choubik-price-setting/cityID/${cityId}`,
    data,
  );
}
// ************************ getChoubikCommissionSetting
export function getChoubikCommissionSetting(page, limit) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.DELIVERY_MAN_PAYMENT}/choubik-commission-setting?page=${page || 0}&limit=${limit || 0
    }`,
  );
}
export function updateChoubikCommissionSetting(cityId, data) {
  return axios.post(
    `${MICROSERVICE_BASE_URL.DELIVERY_MAN_PAYMENT}/choubik-commission-setting/cityID/${cityId}`, data
  );
}
// for logger audit
export function logModeDispatching(body) {
  return axios.post(`${MICROSERVICE_BASE_URL.ADMIN}/logger`, { ...body });
}
// -------------------------Category in App User
export function getCategoriesAppUser() {
  return axios.get(
    `${MICROSERVICE_BASE_URL.ADMIN}/category?paged=false`,
  );
}
export function createCategoryAppUser(data) {
  return axios.post(`${MICROSERVICE_BASE_URL.ADMIN}/category`, {
    ...data,
  });
}
export function updateCategoryAppUser(id, data) {
  return axios.put(`${MICROSERVICE_BASE_URL.ADMIN}/category/${id}`, {
    ...data,
  });
}
export function deleteCategoryAppUser(id) {
  return axios.delete(`${MICROSERVICE_BASE_URL.ADMIN}/category/${id}`);
}

export function getStoresServices(page, limit, serviceID, cityId) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.CONTENT}/services/${serviceID}/city/${cityId}/promo?page=${page || 0}&limit=${limit || 0}&paged=true&cityId=${cityId}`,
  );
}
export function getAdminWithList() {
  return axios.get(`${MICROSERVICE_BASE_URL.ADMIN}/adminWithList/`);
}
export function getAdminDatatWithList() {
  return axios.get(`${MICROSERVICE_BASE_URL.ADMIN}/adminWithList/admin-list`);
}
export function setAdminWithList(witheList) {
  return axios.post(`${MICROSERVICE_BASE_URL.ADMIN}/adminWithList/`, { witheList: witheList });
}
export function clearSession() {
  return axios.put(`${MICROSERVICE_BASE_URL.ADMIN}/adminWithList/clear-session`,);
}
export function getAdmins(search_text) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.ADMIN}/admin?page=${0}&limit=${20
    }&paged=${true}&search_text=${search_text || ''}`,
  );
}
export function getAdminPermission(adminId) {
  return axios.get(`${MICROSERVICE_BASE_URL.ADMIN}/adminWithList/${adminId}`);
}
export function setAdminPermissions(adminId, adminPermissions) {
  return axios.put(`${MICROSERVICE_BASE_URL.ADMIN}/adminWithList/${adminId}`, { adminPermissions: adminPermissions });
}