import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useAlert } from 'react-alert';

import CustomInput from '../../../components/material-dashboard-pro-react/CustomInput/CustomInput';
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import SelectBoxComponent from '../../../components/SelectBox.component';
import SwitchComponent from '../../../components/Switch.component';
import GridContainer from '../../../components/material-dashboard-pro-react/Grid/GridContainer';
import Card from '../../../components/material-dashboard-pro-react/Card/Card';
import CardHeader from '../../../components/material-dashboard-pro-react/Card/CardHeader';
import CardBody from '../../../components/material-dashboard-pro-react/Card/CardBody';
import { getCities } from '../../delivery-info/services/serverApi.service';
import { editProvider } from '../services/server.api.service';

const validationSchema = yup.object({
  phone: yup.string().required(),
});
/**deliveryType: {
      type: {
        type: String,
        default: 'Food',
        enum: ['Food', 'Partners', 'Hybrid'],
      },
    }, */
export default function EditProvider({
  data,
  close,
  deliveryMen,
  setDeliveryMen,
  indexDeliveryMan,
}) {
  // console.log(data);
  const alert = useAlert();
  const [isLoading, setIsLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      phone: '',
    },
    validationSchema,
  });
  const setValues = () => {
    data &&
      formik.setValues({
        phone: data?.phone,
      });
  };
  console.log('data', data);
  const onEdit = () => {
    data.phone = formik.values.phone;
    console.log('formik.values', data);
    editProvider(data._id, data)
      .then(({ data }) => {
        const tempDeliveryMen = [...deliveryMen];
        tempDeliveryMen[indexDeliveryMan] = data?.deliveryMan;
        setDeliveryMen(tempDeliveryMen);
        close();
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    setValues();
  }, [data]);
  return (
    <div>
      <div>
        <CustomInput
          labelText="phone"
          formControlProps={{
            fullWidth: true,
          }}
          type="text"
          error={formik.touched.phone && Boolean(formik.errors.phone)}
          helperText={formik.errors.phone}
          inputProps={{
            value: formik.values.phone,
            onBlur: formik.handleBlur('phone'),
            onChange: formik.handleChange('phone'),
          }}
        />
      </div>
      <Box textAlign="center">
        <Button
          round
          type="submit"
          color="success"
          disabled={!formik.dirty || !formik.isValid || isLoading}
          loading={isLoading}
          loaderColor="white"
          onClick={onEdit}
        >
          SAVE
        </Button>
      </Box>
    </div>
  );
}
