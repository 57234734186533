import React from 'react'
import { Box,RadioGroup, FormControlLabel,Radio } from '@material-ui/core';

export default function CustomRadioGroup({data,inputProps,disabled,handleClick}) {
    return (
        <div>
            <RadioGroup
            row
            {...inputProps}
            // onclick={handleClick}
    // value={selectedPivotAttribute && selectedPivotAttribute.id.toString()}
    // onChange={selectPivotAttribute}
  >
  {/* {props.attributes.filter(attribute => attribute.isHierarchical).map(attribute => ( */}
  {/* {data.filter(attribute => attribute.isHierarchical).map(item => ( */}
  {data.map((item,index) => (
    <FormControlLabel
      label={item.name}
      key={index}
      value={item.name}
      control={<Radio onClick={handleClick}/>}
      disabled={disabled}
    />
   )
   )}
    </RadioGroup>
        </div>
    )
}
