import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { validatOrderAction } from '../redux/actions/order.actions';
import { Grid, Box } from '@material-ui/core';
import SwitchComponent from '../../../components/Switch.component';
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import { logOrder, validateOrder } from '../services/serverApi.service';
import { getCustomerAddresses } from '../services/serverApi.service';
import EditIcon from '@mui/icons-material/Edit';
import ModalComponent from '../../../components/Modal.component';
import EditAddressMapComponent from './Edit.address.map.component';
import { getUserDataState } from 'features/auth/redux/selectors';
import { DATA } from '../../../utils/logAudit';
import { resetObj } from 'utils/resetObj';

export default function InvalidOrderComponent({ order, close, adminData }) {
  // console.log(adminData);
  const dispatch = useDispatch();
  const [invalidOrder, setInValidOrder] = useState(false);
  const [inValidPosition, setInValidPosition] = useState(false);
  const [inValidNewUser, setInValidNewUser] = useState(false);
  const [inValidPhone, setInValidPhone] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [addresses, setAddresse] = useState([]);
  const [orderAddress, setOrderAddress] = useState('');
  const [openSubModel, setOpenSubModel] = useState('');
  const [selectedAddress, setIndexSelectedAddress] = useState(-1);
  // admin object :
  const userData = useSelector(getUserDataState);
  const [admin, setAdmin] = useState({
    _id: userData.user._id,
    email: userData.user.email,
    username: userData.user.username,
    by: 'admin',
  });

  const handleOpenModal = (type) => {
    // console.log(order);
    handleClose();
    setOpenSubModel(type);
    // type=="assigned"&&getInfoAboutDelivery()
  };
  const handleModal = (type, index) => {
    // console.log('type', type, index, item);
    // handleClose();
    // setOrdeRow(item);
    setIndexSelectedAddress(index);
    setOpenSubModel(type);
  };

  const handleCloseModal = () => {
    setOpenSubModel('');
  };

  const validatOrder = () => {
    // reset object before setting data :
    resetObj(DATA);
    // for logger audit data :
    DATA.admin = admin;
    DATA.actionType = 'Valider';
    DATA.target = 'Order';
    DATA.targetId = order._id;
    DATA.loggerDetails = {
      beforeValue: !inValidPosition ? 'Champs valider' : 'Champs invalide',
      afterValue: inValidPosition ? 'Champs valider' : 'Champs invalide',
    };
    // DATA.beforeValue = !inValidPosition ? 'Champs valider' : 'Champs invalide';
    // DATA.afterValue = inValidPosition ? 'Champs valider' : 'Champs invalide';
    console.log(DATA);
    logOrder(DATA)
      .then(({ data }) => {
        console.log(data);
      })
      .catch((error) => {
        console.log(error.message, {
          type: 'error',
        });
      });
    if (
      invalidOrder &&
      inValidNewUser &&
      inValidPosition &&
      inValidNewUser &&
      inValidPhone
    ) {
      //     const body = {
      //     inValidPosition:!inValidPosition,
      //     inValidNewUser:!inValidNewUser,
      //     inValidPhone:!inValidPhone
      // }
      // console.log(body);
      setIsLoading(true);
      validateOrder(order._id, {
        adminId: adminData._id,
        adminName: adminData.userName,
        adminEmail: adminData.email,
        admin: adminData,
      })
        .then(({ data }) => {
          // console.log(data);
          dispatch(validatOrderAction(order));
          close();
        })
        .catch((error) => console.log(error))
        .finally(() => setIsLoading(false));
    }
  };
  const getAllCustomerAddresse = () => {
    getCustomerAddresses(order.customer?._id)
      .then(({ data }) => setAddresse(data.addresses))
      .catch((error) => console.log(error));
  };
  const setAddressFromOrder = () => {
    if (order.payment.serviceType == 'Stores') {
      setOrderAddress(order.payment.destinationAddress.address);
    } else {
      setOrderAddress(order.payment.pickupAddress.address);
    }
  };
  useEffect(() => {
    (order.inValidUser ||
      order.inValidPosition ||
      order.inValidNewUser ||
      order.inValidPhone) &&
      setInValidOrder(false);
    order.inValidPosition
      ? setInValidPosition(false)
      : setInValidPosition(true);
    order.inValidNewUser ? setInValidNewUser(false) : setInValidNewUser(true);
    order.inValidPhone ? setInValidPhone(false) : setInValidPhone(true);
    order && getAllCustomerAddresse();
    order && setAddressFromOrder();
  }, [order]);
  // console.log('add',orderAddress);
  // console.log('all add',addresses);
  // console.log(invalidOrder,
  //     inValidPosition,
  //     inValidNewUser,
  //     inValidPhone);
  // console.log(openSubModel);
  return (
    <div>
      {order && (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            order valid
          </Grid>
          <Grid item xs={3}>
            <SwitchComponent
              onChange={(value) => {
                setInValidOrder(value);
                setInValidPosition(value);
                setInValidNewUser(value);
                setInValidPhone(value);
              }}
              checked={invalidOrder}
              // order.inValidUser||
              // order.inValidPosition||
              // order.inValidNewUser||
              // order.inValidPhone}
              // error={formik.touched.inStock && Boolean(formik.errors.inStock)}
              label=""
            />
          </Grid>
          <Grid item xs={6}>
            address detail :
            <div className="ml-2">
              {/* {order.payment.serviceType ==='Stores'?order.payment.destinationAddress.address+"\n"+order.payment.destinationAddress.details:order.payment.pickupAddress.address} */}
              <ul style={{ listStyle: 'none' }}>
                {/* 4caf50 */}
                {addresses &&
                  addresses.length &&
                  addresses.map((address, index) => (
                    <li
                      style={{
                        alignItems: 'center',
                        display: 'flex',
                        backgroundColor:
                          address.address == orderAddress && '#4caf50',
                        color: address.address == orderAddress && '#fff',
                        padding: '3px',
                        borderRadius: '10px',
                      }}
                    >
                      {address.address + '\n'}
                      {address.details}
                      <EditIcon
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          handleModal('editAddress', index);
                        }}
                      />
                    </li>
                  ))}
              </ul>
            </div>
          </Grid>
          <Grid item xs={3}>
            <SwitchComponent
              onChange={(value) => setInValidPosition(value)}
              checked={inValidPosition}
              label=""
            />
          </Grid>

          <Grid item xs={6}>
            User :
            <div className="ml-2">
              <ul style={{ listStyle: 'none' }}>
                <li>Orders Nbr : {order?.payment?.customer?.nbOrders}</li>
                <li>KaalixUp : {order?.payment?.customer?.rewardPoints}</li>
                <li>KaalixPay : {order?.payment?.customer?.kaalixPay}</li>
                <li>Status : {order?.payment?.customer?.status}</li>
              </ul>
            </div>
          </Grid>
          <Grid item xs={3}>
            <SwitchComponent
              onChange={(value) => setInValidNewUser(value)}
              checked={inValidNewUser}
              label=""
            />
          </Grid>
          <Grid item xs={6}>
            Phone : {order?.payment?.customer?.phone}
          </Grid>
          <Grid item xs={3}>
            <SwitchComponent
              onChange={(value) => setInValidPhone(value)}
              checked={inValidPhone}
              label=""
            />
          </Grid>
          <Grid item xs={12}>
            <Box textAlign="right">
              <Button
                round
                type="submit"
                color="success"
                disabled={isLoading}
                loading={isLoading}
                loaderColor="white"
                onClick={validatOrder}
              >
                save
              </Button>
            </Box>
          </Grid>
        </Grid>
      )}
      {openSubModel && (
        <ModalComponent
          maxWidth={false}
          close={handleCloseModal}
          visible={openSubModel === 'editAddress'}
          title="adress Details"
        >
          <EditAddressMapComponent
            index={selectedAddress}
            address={addresses}
            setAddresse={setAddresse}
            // order={orderRow}
            close={handleCloseModal}
            // store={store} close={closeModal}
            // index={indexStore}
            // stores={stores}
            // setStores={setStores}
          />
        </ModalComponent>
      )}
    </div>
  );
}
