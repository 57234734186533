import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Map from '../../../components/Map.component';
import CustomMarker from '../../../components/Markers.component';
import { getUserDataState } from '../../auth/redux/selectors';

import { socketConnectPertner } from '../services/server.api.service';
import { iconsList } from '../../../config/status.config';

export default function MapAlarmsComponent({ order }) {
  const [socket, setSocket] = useState(null);
  const [customerLocation, setCustomerLocation] = useState(
    order.payment?.serviceType == 'Stores'
      ? {
          lat: parseFloat(order.payment?.destinationAddress?.location[0] || 0),
          lng: parseFloat(order.payment?.destinationAddress?.location[1] || 0),
        }
      : {
          lat: parseFloat(order.payment?.pickupAddress?.location[0] || 0),
          lng: parseFloat(order.payment?.pickupAddress?.location[1] || 0),
        },
  );
  const accpetPosition = () => {
    const deliveryManStatusTimes = (order.deliveryManStatusTimes || []).filter(
      (x) =>
        x?.status == 'ACCEPTED' &&
        x?.deliveryMan?.location &&
        x?.deliveryMan?.location.length > 0,
    );

    deliveryManStatusTimes.length > 0 &&
      setAccepteLocation({
        lat: parseFloat(
          deliveryManStatusTimes[deliveryManStatusTimes.length - 1]?.deliveryMan
            ?.location[0] || 0,
        ),
        lng: parseFloat(
          deliveryManStatusTimes[deliveryManStatusTimes.length - 1]?.deliveryMan
            ?.location[1] || 0,
        ),
      });
  };
  const [accepteLocation, setAccepteLocation] = useState({});
  const [deliveryPositionNow, setDeliveryPositionNow] = useState({
    lat:
      order.deliveryMan?.location && order.deliveryMan?.location.length > 0
        ? parseFloat(order.deliveryMan?.location[0])
        : '',
    lng:
      order.deliveryMan?.location && order.deliveryMan?.location.length > 0
        ? parseFloat(order.deliveryMan?.location[1])
        : '',
  });
  const userData = useSelector(getUserDataState);
  useEffect(() => {
    order && accpetPosition();
  }, [order]);
  useEffect(() => {
    if (!socket) {
      setSocket(socketConnectPertner(userData.accessToken));
    }
    function subscribeToAdminRoom() {
      if (socket) {
        socket.emit('JOIN_ROOM', 'ADMIN_DISPATCHING');
      }
    }
    if (socket) {
      subscribeToAdminRoom();
    }
  }, [socket]);
  useEffect(() => {
    order.deliveryManId &&
      socket &&
      socket.on('DELIVERYMAN_LOCATION_CHANGED', function (data) {
        if (order.deliveryManId == data.deliveryManId) {
          setDeliveryPositionNow({
            lat: parseFloat(data.location[0]),
            lng: parseFloat(data.location[1]),
          });
        }
      });

    return () => {
      socket && socket.disconnect();
    };
  }, [socket]);
  return (
    <div>
      <Map
        path={deliveryPositionNow}
        zoom={13}
        mapContainerStyle={{
          height: '600px',
          width: '100%',
        }}
      >
        <CustomMarker
          position={deliveryPositionNow}
          icon={iconsList.deliveryManWithOrder}
          size={40}
          labelPosition="top"
          label={order.deliveryManStatus}
        />
        {order.store && Object.keys(order.store).length > 0 && (
          <CustomMarker
            position={{
              lat: parseFloat(order.store.location[0] || 0),
              lng: parseFloat(order.store.location[1] || 0),
            }}
            icon={iconsList.store}
            size={30}
          />
        )}

        <CustomMarker
          position={customerLocation}
          icon={iconsList.client}
          size={35}
        />
        <CustomMarker
          position={accepteLocation}
          icon={iconsList.deliverManWithoutOrde}
          size={40}
          label="Accep"
        />
      </Map>
    </div>
  );
}
