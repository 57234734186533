import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Box } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';

import PropTypes from 'prop-types';
import { useAlert } from 'react-alert';
import TagsInput from 'react-tagsinput';
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import CustomInput from '../../../components/material-dashboard-pro-react/CustomInput/CustomInput';
import {
  getCities,
  getServiceCategories,
  updateService,
  getTag,
} from '../services/serverApi.service';
import SelectBoxComponent from '../../../components/SelectBox.component';
import ImageUpload from '../../../components/material-dashboard-pro-react/CustomUpload/ImageUpload';
import { serviceTypes } from '../mocks';
import {
  defaultImagesAction,
  uploadImageResetAction,
} from '../../../redux/uploadRedux/upload.action';

const validationSchema = yup.object({
  name: yup.string().required(),
  type: yup.string().required(),
  iconUrl: yup.string().required(),
  cityList: yup.array().required(),
  serviceCategoryId: yup.string().required(),
  tags: yup.array(),
  tagsName: yup.string(),
  categoryType: yup.string(),
  subName: yup.string(),
});

export default function EditServiceComponent({
  data,
  onItemEdited,
  close,
  props,
}) {
  // console.log(data);
  const { categoryTypeList } = props;
  console.log('categoryTypeList', categoryTypeList);
  const alert = useAlert();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [displayImage, setDisplayImage] = useState(false);
  const [newTags, setNewTags] = useState(false);
  const [tagsList, setTagsList] = useState([]);
  const [tagSelected, setTagSelected] = useState('');
  const upload = useSelector((state) => state.uploadReducer);
  const { iconUrl, error, loading } = upload;
  // console.log(tagSelected);
  const formik = useFormik({
    initialValues: {
      name: '',
      type: '',
      iconUrl: '',
      cityList: [],
      serviceCategoryId: '',
      tags: [],
      tagsName: [],
      categoryType: '',
      subName: '',
    },
    validationSchema,
  });
  console.log('formik.err', formik.errors);
  const [cities, setCities] = useState([]);
  const [serviceCategories, setServiceCategories] = useState([]);

  const onUpdate = () => {
    console.log(formik.values);
    setIsLoading(true);

    updateService(data._id, formik.values)
      .then(() => {
        alert.show('Service has been updated', {
          type: 'success',
        });
        formik.resetForm();
        dispatch(uploadImageResetAction());
        setDisplayImage(false);
        onItemEdited();
        close();
      })
      .catch((error) => {
        alert.show(error.message, {
          type: 'error',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const displayTagsList = (e) => {
    // console.log(e.target);
    const index = tagsList.findIndex((item) => item._id == e.target.value);
    console.log(index);
    setTagSelected(index);
    // setNewTags(true)
    // if(formik.values.tags){
    //   console.log('tagsList',tagsList);
    //   // const index = tagsList.findIndex((item)=>JSON.stringify(item.tags)==JSON.stringify(formik.values.tags))
    //   // setTagSelected(index);
    // }
    // console.log(tagsList[index]?.tags);
    formik.setFieldValue('tags', tagsList[index]?.tags);
    // formik.setFieldValue('tagsName', tagsList[index]?.tags);
  };
  const onChangeTags = (items) => {
    items[items.length - 1] = {
      ...items[items.length - 1],
      iconUrl: '',
    };

    formik.setFieldValue('tags', items);
  };

  // const onRemoveImage = () => {
  //   formik.setFieldValue('iconUrl', '');
  // };

  async function setValue() {
    if (data) {
      console.log('data', data);
      const tagList = data?.tags;
      !iconUrl && dispatch(defaultImagesAction(data?.iconUrl));

      formik.setValues({
        name: data.name,
        type: data.type,
        iconUrl,
        cityList:
          data.cities.length == 0 ? [] : data?.cities?.map((item) => item._id),
        serviceCategoryId:
          data?.serviceCategory == ''
            ? ''
            : data?.serviceCategory?.props?.propsId,
        // tagsName: tagsList.findIndex((item)=>JSON.stringify(item.tags)==JSON.stringify(formik.values.tags)),
        // tags: (data.tags.length==0 ? [] : data?.tags?.map((item) => (console.log(item))))
        tags: data?.tags,
        tagsName: 'dqsd',
        categoryType: data?.categoryType || '',
        subName: data?.subName || '',
      });
      // formik.setFieldValue('tags', data?.tags||[]);
      console.log('formik.values', formik.values);
    }
    const index = tagsList.findIndex(
      (item) => JSON.stringify(item.tags) == JSON.stringify(formik.values.tags),
    );
    setTagSelected(index);
  }
  // console.log('tagSelected',tagSelected);
  // console.log('tagsName',formik.values.tagsName);
  // console.log('tagsList[tagSelected]?.name',tagsList[tagSelected]?.name);
  useEffect(() => {
    // displayTagsList()
    setValue();

    getCities()
      .then(({ data }) => {
        setCities(data);
      })
      .catch((error) => {
        alert.show(error.message, {
          type: 'error',
        });
      });

    getServiceCategories()
      // eslint-disable-next-line no-shadow
      .then(({ data }) => {
        setServiceCategories(data);
      })
      .catch((error) => {
        alert.show(error.message, {
          type: 'error',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
    getTag()
      // eslint-disable-next-line no-shadow
      .then(({ data }) => {
        console.log('tag', data);
        setTagsList([{ name: 'none', _id: 'none', tags: [] }].concat(data));
      })
      .catch((error) => {
        console.log(error);
      });
  }, [data, iconUrl]);
  // console.log(tagsList);
  // console.log('fomrmik.values',formik.values);
  return (
    // <div>sds</div>
    <div>
      <div>
        {!displayImage && <span>you need to upload a new image</span>}
        <Switch
          color="primary"
          checked={displayImage}
          value={displayImage}
          onChange={(e) => {
            displayImage ? setDisplayImage(false) : setDisplayImage(true);
          }}
        />
      </div>
      <Box display="flex" justifyContent="center">
        {Boolean(data) &&
          (!displayImage ? (
            <img src={data.iconUrl || ''} alt="dqs" height="100" width="120" />
          ) : (
            <ImageUpload label="Select icon" avatar folder="service" />
          ))}
      </Box>
      {data && (
        <>
          <CustomInput
            labelText="Name"
            formControlProps={{
              fullWidth: true,
            }}
            type="text"
            error={formik.touched.name && Boolean(formik.errors.name)}
            inputProps={{
              value: formik.values.name,
              onBlur: formik.handleBlur('name'),
              onChange: formik.handleChange('name'),
            }}
          />
          {cities && (
            <SelectBoxComponent
              items={cities.map((city) => ({
                value: city._id,
                label: city.name,
              }))}
              inputLabel="City list"
              onValueChange={(items) => {
                formik.setFieldValue('cityList', items);
              }}
              value={formik.values.cityList}
              multiple
            />
          )}
          <SelectBoxComponent
            items={serviceTypes.map((item) => ({
              value: item.value,
              label: item.label,
            }))}
            inputLabel="Type"
            onValueChange={formik.handleChange('type')}
            value={formik.values.type}
          />
          <SelectBoxComponent
            items={
              serviceCategories.length > 0 &&
              serviceCategories.map((item) => ({
                value: item._id,
                label: item.name,
              }))
            }
            inputLabel="Service category"
            onValueChange={formik.handleChange('serviceCategoryId')}
            value={formik.values.serviceCategoryId}
          />
          <CustomInput
            labelText="subName"
            formControlProps={{
              fullWidth: true,
            }}
            type="text"
            error={formik.touched.subName && Boolean(formik.errors.subName)}
            inputProps={{
              value: formik.values.subName,
              onBlur: formik.handleBlur('subName'),
              onChange: formik.handleChange('subName'),
            }}
          />
          <SelectBoxComponent
            items={(categoryTypeList || []).map((item) => ({
              value: item,
              label: item,
            }))}
            inputLabel="categoryType"
            onValueChange={formik.handleChange('categoryType')}
            value={formik.values.categoryType}
          />
          {tagsList && (
            <SelectBoxComponent
              items={
                tagsList.length > 0 &&
                tagsList.map((item) => ({
                  value: item._id,
                  label: item.name,
                }))
              }
              inputLabel="tag"
              // onValueChange={formik.handleChange('tagsName')}
              onChange={(e) => displayTagsList(e)}
              // value={formik.values.tagsName||tagsList[tagSelected]?.name}
            />
          )}
          <input type="hidden" name="tags" />
          {
            // newTags&&
            tagsList && tagsList[tagSelected]?.tags.length > 0
              ? tagsList[tagSelected].tags.map((item) => (
                  <Button color="twitter" size="sm" round>
                    {item.name}
                  </Button>
                  // console.log(item,tagSelected)
                ))
              : data.tags &&
                data.tags.length > 0 &&
                data.tags.map((item) => (
                  <Button color="twitter" size="sm" round>
                    {item.name}
                  </Button>
                  // console.log(item,tagSelected)
                ))
          }
          <Box textAlign="center">
            <Button
              round
              type="submit"
              color="success"
              disabled={!formik.dirty || !formik.isValid || isLoading}
              loading={isLoading}
              loaderColor="white"
              onClick={onUpdate}
            >
              Edit
            </Button>
          </Box>
        </>
      )}
    </div>
  );
}

EditServiceComponent.propTypes = {
  data: PropTypes.any,
  onItemEdited: PropTypes.func.isRequired,
  close: PropTypes.func,
};
