import React, { useEffect, useState } from 'react'
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useAlert } from 'react-alert';
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import CustomInput from '../../../components/material-dashboard-pro-react/CustomInput/CustomInput';
import { updateCityConfig } from '../services/serverApi.service';
import SelectBoxComponent from '../../../components/SelectBox.component';
import { cityConfigTypes } from '../mocks/index'

const validationSchema = yup.object({
  cityId: yup.string().required(),
  minimumAmount: yup.number().required(),
  senderGain: yup.number().required(),
  receiverGain: yup.number().required(),
  senderText: yup.string().required(),
  receiverText: yup.string().required(),
  type: yup.string().required(),
});

export default function CreateCityConfigComponent({ props,close,onItemCreated }) {
  console.log('props', props);
  const { PropsCities, sharing } = props
  const [isLoading, setIsLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      cityId: '',
      minimumAmount: '',
      senderGain: '',
      receiverGain: '',
      senderText: '',
      receiverText: '',
      type: '',
    },
    validationSchema,
  });
  const onCreate = () => {
    
    setIsLoading(true);

    updateCityConfig(formik.values)
      .then(({ data }) => {
        console.log(data);
        // alert.show('City has been created', {
        //   type: 'success',
        // });
        // formik.resetForm();
        onItemCreated();
        close();
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <div>
      <CustomInput
        labelText="minimum Amount"
        formControlProps={{
          fullWidth: true,
        }}
        type="number"
        error={formik.touched.minimumAmount && Boolean(formik.errors.minimumAmount)}
        inputProps={{
          value: formik.values.minimumAmount,
          onBlur: formik.handleBlur('minimumAmount'),
          onChange: formik.handleChange('minimumAmount'),
        }}
      />
      <CustomInput
        labelText="sender Gain"
        formControlProps={{
          fullWidth: true,
        }}
        type="number"
        error={formik.touched.senderGain && Boolean(formik.errors.senderGain)}
        inputProps={{
          value: formik.values.senderGain,
          onBlur: formik.handleBlur('senderGain'),
          onChange: formik.handleChange('senderGain'),
        }}
      />
      <CustomInput
        labelText="receiver Gain"
        formControlProps={{
          fullWidth: true,
        }}
        type="number"
        error={formik.touched.receiverGain && Boolean(formik.errors.receiverGain)}
        inputProps={{
          value: formik.values.receiverGain,
          onBlur: formik.handleBlur('receiverGain'),
          onChange: formik.handleChange('receiverGain'),
        }}
      />
      <CustomInput
        labelText="sender Text"
        formControlProps={{
          fullWidth: true,
        }}
        type="text"
        error={formik.touched.senderText && Boolean(formik.errors.senderText)}
        inputProps={{
          value: formik.values.senderText,
          onBlur: formik.handleBlur('senderText'),
          onChange: formik.handleChange('senderText'),
        }}
      />
      <CustomInput
        labelText="receiver Text"
        formControlProps={{
          fullWidth: true,
        }}
        type="text"
        error={formik.touched.receiverText && Boolean(formik.errors.receiverText)}
        inputProps={{
          value: formik.values.receiverText,
          onBlur: formik.handleBlur('receiverText'),
          onChange: formik.handleChange('receiverText'),
        }}
      />
      <SelectBoxComponent
        items={PropsCities.map((city) => ({
          value: city._id,
          label: city.name,
        }))}
        inputLabel="City"
        onValueChange={formik.handleChange('cityId')}
        value={formik.values.cityId}
      />
      <SelectBoxComponent
        items={cityConfigTypes.map((type) => ({
          value: type.value,
          label: type.value,
        }))}
        inputLabel="type"
        onValueChange={formik.handleChange('type')}
        value={formik.values.type}
      />
      <Box textAlign="center">
        <Button
          round
          type="submit"
          color="success"
          disabled={!formik.dirty || !formik.isValid || isLoading}
          loading={isLoading}
          loaderColor="white"
          onClick={onCreate}
        >
          Create
        </Button>
      </Box>
    </div>
  )
}

