import React from 'react';
import { useDispatch } from 'react-redux';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import CommentComponent from './Comment.delivery.component';
import { openModelAction } from '../../orders/redux/actions/order.actions';
import CommentIcon from '@mui/icons-material/Comment';

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip arrow {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));
export default function CommentPartnerComponent({ _id, index, comments }) {
  const dispatch = useDispatch();
  return (
    <HtmlTooltip
      title={
        <React.Fragment>
          <CommentComponent close={() => {}} deliveryId={_id} />
        </React.Fragment>
      }
    >
      {comments && comments.length ? (
        <span
          style={{ cursor: 'pointer' }}
          onClick={() => {
            dispatch(openModelAction(_id, index, 'comment'));
          }}
        >
          {' '}
          {comments[comments.length - 1]?.adminName}
          {' // '}
          {comments[comments.length - 1]?.comment}{' '}
        </span>
      ) : (
        <CommentIcon
          style={{ cursor: 'pointer', fontSize: '22px' }}
          onClick={() => {
            dispatch(openModelAction(_id, index, 'comment'));
          }}
        />
      )}
    </HtmlTooltip>
  );
}
