import React, { useEffect, useState } from 'react';
import TablePagination from '@material-ui/core/TablePagination';
import { getServices } from '../services/serverApi.service';
// import DataTableContainerComponent from '../../../components/DataTableContainer.component';
import DataTableContainerComponent from '../../../components/DataTableSortingContainer.component';
import ViewItemComponent from '../components/ViewItem.component';
import CreateServiceComponent from '../components/CreateService.component';
import EditServiceComponent from '../components/EditService.component';
import DeleteServiceComponent from '../components/DeleteService.component';
import { formatTime } from '../../../helpers/index';
import { getCategoryTypeList } from '../../setting/services/serverApi.service';
import { useHistory } from "react-router-dom";

export default function ServicesPage() {
  const [resultsData, setResultsData] = useState({
    total: 0,
    items: [],
    tableItems: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const pages = [25, 50, 100];
  const [page, setPage] = useState(0);
  const [categoryTypeList, setCategoryTypeList] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(pages[page]);
  let history = useHistory();

  // const [cities, setCities] = useState([]);
  // const [serviceCategories, setServiceCategories] = useState([]);
  const subNameCol = (name)=>{
        return name !=''?true:false
  }
  const loadServicesList = () => {
    setIsLoading(true);
    getServices(page, rowsPerPage, true)
      .then(({ data }) => {
        console.log(data);
        setResultsData({
          total: data.total,
          items: data.services.map((item,index) => {
            return {
              ...item,
              name: item.name && item.name != null ? item.name : '--',
              type: item.type ? item.type : '--',
              serviceCategory:
                item.serviceCategory &&
                item.serviceCategory._id &&
                item.serviceCategory.name ? (
                  <p propsId={item.serviceCategory._id}>
                    {item.serviceCategory.name || ''}
                  </p>
                ) : (
                  '--'
                ),
              img: item.iconUrl ? (
                <img
                  id="IconUrlImag"
                  propsSrc={item.iconUrl}
                  src={item.iconUrl}
                  alt=""
                  border="3"
                  height="50"
                  width="60"
                />
              ) : (
                '--'
              ),
              city:
                item.cities && item.cities.length > 0
                  ? item.cities.map((city) => (
                      <p propsId={city._id}>{city.name}</p>
                    ))
                  : '--',
              tag:
                item.tags && item.tags != null
                  ? item.tags.map((tag) => <p>{tag?.name}</p>)
                  : '--',
              deleted: item.deleted ? item.deleted : 'no',
              deletedAt: item.deletedAt ? formatTime(item.deletedAt) : '--',
              subNameCol: item?.subName 
              && subNameCol(item?.categoryType)
               ? (<p style={{cursor:'pointer'}}
                 onClick={()=> history.push({
                  pathname: '/admin/delivery-info/Service_store',
                  state: { 
                    city: item.cities && item.cities.length > 0
                    ? item.cities.map((city) =>{
                      return {id:city._id,name:city.name}
                    }):'',
                    serviceId:item?._id,
                   }
                 })}>
                {item?.subName}</p>)
               : item?.subName,

              // }
            };
          }),
          tableItems: data.services.map((item) => {
            return {
              ...item,
              name: item.name && item.name != null ? item.name : '--',
              type: item.type ? item.type : '--',
              serviceCategory:
                item.serviceCategory &&
                item.serviceCategory._id &&
                item.serviceCategory.name ? (
                  <p propsId={item.serviceCategory._id}>
                    {item.serviceCategory.name || ''}
                  </p>
                ) : (
                  '--'
                ),
              img: item.iconUrl ? (
                <img
                  id="IconUrlImag"
                  propsSrc={item.iconUrl}
                  src={item.iconUrl}
                  alt=""
                  border="3"
                  height="50"
                  width="60"
                />
              ) : (
                '--'
              ),
              city:
                item.cities && item.cities.length > 0
                  ? item.cities.map((city) => (
                      <p propsId={city._id}>{city.name}</p>
                    ))
                  : '--',
              tag:
                item.tags && item.tags != null
                  ? item.tags.map((tag) => <p>{tag?.name}</p>)
                  : '--',
              deleted: item.deleted ? item.deleted : 'no',
              deletedAt: item.deletedAt ? formatTime(item.deletedAt) : '--',
              // }
            };
          }),
          // tableItems: data.services.map((item) => {
          // // [
          //   // eslint-disable-next-line no-underscore-dangle
          //   return {

          //   // item._id,
          //   name:(item.name&&item.name!=null)?item.name:'--'
          //   // item.type?item.type:'--',
          //   // (item.serviceCategory&&item.serviceCategory._id&&item.serviceCategory.name)?<p propsId={item.serviceCategory._id}>{item.serviceCategory.name||""}</p>:'--',
          //   // item.iconUrl? <img id="IconUrlImag" propsSrc={item.iconUrl} src={item.iconUrl} alt="" border="3" height="50" width="60" />:'--',
          //   // (item.cities&&item.cities.length>0)?item.cities.map((city)=>(
          //   //   <p propsId={city._id}>{city.name}</p>
          //   // )):'--',
          //   // (item.tags&&item.tags!=null)?item.tags.map((tag)=>(<p>{tag?.name}</p>)):'--',
          //   // item.deleted?item.deleted:'no',
          //   // item.deletedAt?formatTime(item.deletedAt):'--'
          // };
          // // ]
          // ),
        });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };
  useEffect(() => {
    getCategoryTypeList()
      .then(({ data }) => {
        setCategoryTypeList(data?.categoryType?.categoryTypeList || []);
      })
      .catch((err) => console.log(err));
    loadServicesList();
    // getCities()
    //   .then(({ data }) => {
    //     setCities(data);
    //   })
    //   .catch((error) => {
    //     alert.show(error.message, {
    //       type: 'error',
    //     });
    //   });

    // getServiceCategories()
    //   // eslint-disable-next-line no-shadow
    //   .then(({ data }) => {
    //     setServiceCategories(data);
    //   })
    //   .catch((error) => {
    //     alert.show(error.message, {
    //       type: 'error',
    //     });
    //   });
    //   getTag()
    //   // eslint-disable-next-line no-shadow
    //   .then(({ data }) => {
    //     setTags(data);
    //   })
    //   .catch((error) => {
    //     alert.show(error.message, {
    //       type: 'error',
    //     });
    //   });
  }, [page, rowsPerPage]);
  console.log(resultsData);
  const handlePaginate = () => {};
  return (
    <>
      <DataTableContainerComponent
        total={resultsData.total}
        // numberOfRowsProps={pages}
        resultsData={resultsData}
        initData={resultsData.items}
        columns={[
          {
            Header: 'Name',
            accessor: 'name',
          },
          {
            Header: 'subName',
            accessor: 'subNameCol',
          },
          {
            Header: 'Type',
            accessor: 'type',
          },
          {
            Header: 'service',
            accessor: 'serviceCategory',
          },
          {
            Header: 'categoryType',
            accessor: 'categoryType',
          },
          {
            Header: 'image',
            accessor: 'img',
          },
          {
            Header: 'city',
            accessor: 'city',
          },
          {
            Header: 'tags',
            accessor: 'tag',
          },
          {
            Header: 'actions',
            accessor: 'actions',
          },
        ]}
        title="Services List"
        isLoading={isLoading}
        loadItemsList={() => loadServicesList()}
        CreateItemFormComponent={CreateServiceComponent}
        actionButtons={{
          // view: {
          //   color: 'info',
          //   type: 'view',
          //   component: ViewItemComponent,
          // },
          edit: {
            color: 'success',
            type: 'edit',
            component: EditServiceComponent,
            props: { categoryTypeList: categoryTypeList },
          },
          delete: {
            color: 'danger',
            type: 'delete',
            component: DeleteServiceComponent,
          },
        }}
        onPaginate={handlePaginate}
        isPaginate={true}
      />
    </>
  );
}
