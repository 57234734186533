import React, { useEffect, useState } from 'react'
import * as yup from 'yup';
import { useFormik } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import { FormLabel, Box } from '@material-ui/core';
import CustomInput from '../../../components/material-dashboard-pro-react/CustomInput/CustomInput'
import SelectBoxComponent from '../../../components/SelectBox.component'
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button'
import { commissionTypes } from '../mocks/index';
import { updateStore } from '../services/serverApi.service';
import styles from '../../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle';

const useStyles = makeStyles(styles);

const validationSchema = yup.object({
  city: yup.string().required(),
})

export default function StoreCityComponent({ store, index, stores, setStores, close, cities }) {
  //console.log(cities);
  const [isLoading, setIsLoading] = useState(false)
  const classes = useStyles();
  const formik = useFormik({
    initialValues: {
      city: '',
    },
    validationSchema,
  });
  const editStore = () => {
    store.cityId = formik.values.city
    setIsLoading(true)
    updateStore(store._id, store)
      .then(({ data }) => {
        //    console.log(data);
        close()
      })
      .catch((error) => {
        console.log(error.message, {
          type: 'error',
        });
      })
      .finally(() => setIsLoading(true))
  }
  const setValues = () => {
    formik.setValues({
      city: store.cityId,
      commissionType: store.commissionType,
    })
  }

  useEffect(() => {
    setValues()
  }, [])
  return (
    <div>

      <FormLabel className={classes.labelHorizontal}>
        City
      </FormLabel>
      <SelectBoxComponent
        items={cities.map((city) => ({
          value: city._id,
          label: city.name,
        }))}
        onValueChange={formik.handleChange('city')}
        value={formik.values.city}
      />
      <Box textAlign="center">
        <Button
          round
          type="submit"
          color="success"
          disabled={!formik.dirty || !formik.isValid || isLoading}
          loading={isLoading}
          loaderColor="white"
          onClick={editStore}
        >
          save City
        </Button>
      </Box>
    </div>
  )
}
