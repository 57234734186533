import React, { useState, useEffect } from 'react';
import { Polyline, InfoWindow } from '@react-google-maps/api';
import { Grid, Stepper, StepLabel, Step, StepContent } from '@mui/material';
import CustomMarker from '../../../components/Markers.component';
import { iconsList } from '../../../config/status.config';
import {
  formatTimeForOrderOnlyTime,
  abbreviation,
  calcDurationbetweenTwoTimes,
  formatTimeForOrder,
} from '../../../helpers';
import Map from '../../../components/Map.component';
import { orderServiceType } from '../mock';
import ButtonComponent from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import PersonIcon from '@mui/icons-material/Person';
import SportsMotorsportsIcon from '@mui/icons-material/SportsMotorsports';

export default function StatusMapComponent({
  status,
  store,
  serviceType,
  clientLocation,
  createdAt,
  order,
}) {
  const [path, setPath] = useState({});
  const [zoom, setZoom] = useState(13);
  const [polylineForStatus, setPolylineForStatus] = useState([]);
  const [infoBoxData, setInfoBoxData] = useState({});
  const [allInfoBox, setAllInfoBox] = useState([]);
  const [showInfo, setShowInfo] = useState(true);
  const [displayBtn, setDisplayBtn] = useState(false);
  const [selectedStep, setSelectedStep] = useState([]);

  const displayDetails = (index) => {
    !showInfo && setShowInfo(true);
    const infoObj = {};
    // var deliveryIndex = deliveryInCity.findIndex(x => x._id == id)
    if (status[index]) {
      (infoObj.status = status[index].status),
        (infoObj.date = status[index].date),
        (infoObj.location = status[index].deliveryMan.location),
        setInfoBoxData({ ...infoObj });
    }
  };
  const drawLine = () => {
    const polyLineArray = [];
    const pathCoordinates = [];
    const infoBoxArray = [];
    const polyLineObj = {};
    polyLineObj.color = 'black';
    status = status.filter(
      (item) =>
        item?.deliveryMan?.location && item?.deliveryMan?.location.length,
    );

    (status || []).map((item, index) => {
      // item?.deliveryMan?.location && item?.deliveryMan?.location.length &&
      pathCoordinates.push({
        lat: parseFloat(item?.deliveryMan?.location[0]),
        lng: parseFloat(item?.deliveryMan?.location[1]),
      });
      infoBoxArray.push({
        status: item?.status,
        date: item?.date,
        location: item?.deliveryMan?.location,
        time: calcDurationbetweenTwoTimes(
          index == 0 ? (createdAt, item.date) : status[index - 1]?.date,
          item.date,
        ),
      });
    });

    polyLineObj.pathCoordinates = pathCoordinates;
    polyLineArray.push(polyLineObj);

    status.length &&
      setPath({
        lat: parseFloat(status[0].deliveryMan?.location[0]),
        lng: parseFloat(status[0].deliveryMan?.location[1]),
      });

    setZoom(15);
    setPolylineForStatus(polyLineArray);
    setAllInfoBox(infoBoxArray);
  };
  const handleStepClick = (stepNumber) => {
    const tempSelectedStep = [...selectedStep];
    const findStep = tempSelectedStep.indexOf(stepNumber);
    findStep != -1
      ? tempSelectedStep.splice(findStep, 1)
      : tempSelectedStep.push(stepNumber);
    setSelectedStep(tempSelectedStep.sort((a, b) => a - b));
  };
  useEffect(() => {
    if (status && status.length) {
      drawLine();
    }
  }, [status]);

  return (
    <>
      <ButtonComponent
        size="sm"
        color={displayBtn ? 'success' : 'disabled'}
        onClick={() => setDisplayBtn(!displayBtn)}
      >
        display date
      </ButtonComponent>
      <Grid container spacing={1}>
        <Grid item xs={10}>
          <Map
            path={path}
            zoom={zoom}
            mapContainerStyle={{
              height: '600px',
              width: '100%',
            }}
          >
            {status &&
              status.length &&
              status.map((delivery, index) => (
                <CustomMarker
                  icon={iconsList.deliveryManWithOrder}
                  size={40}
                  key={delivery?.deliveryMan?._id || index}
                  onClick={() => displayDetails(index)}
                  position={{
                    lat: parseFloat(
                      delivery?.deliveryMan?.location
                        ? delivery?.deliveryMan.location[0]
                        : '',
                    ),
                    lng: parseFloat(
                      delivery?.deliveryMan?.location
                        ? delivery?.deliveryMan?.location[1]
                        : '',
                    ),
                  }}
                />
              ))}
            {/* store marker  */}
            {serviceType == orderServiceType.Stores &&
              store &&
              store?.location !== 0 && (
                <CustomMarker
                  icon={iconsList.store}
                  size={30}
                  key={store?._id}
                  // onClick={() => displayDetails(index)}
                  position={{
                    lat: parseFloat(store?.location ? store.location[0] : ''),
                    lng: parseFloat(store?.location ? store?.location[1] : ''),
                  }}
                />
              )}
            {/* client marker  */}
            {clientLocation && clientLocation.length && (
              <CustomMarker
                icon={iconsList.client}
                size={40}
                key={1}
                // onClick={() => displayDetails(index)}
                position={{
                  lat: parseFloat(clientLocation ? clientLocation[0] : ''),
                  lng: parseFloat(clientLocation ? clientLocation[1] : ''),
                }}
              />
            )}
            {polylineForStatus.length &&
              polylineForStatus.map((line) => (
                <Polyline
                  path={line.pathCoordinates}
                  geodesic={true}
                  options={{
                    strokeColor: line.color,
                    strokeOpacity: 1,
                    strokeWeight: 2,
                  }}
                />
              ))}
            {(allInfoBox || []).map(
              (info, index) =>
                selectedStep.includes(index) && (
                  <InfoWindow
                    onCloseClick={() => {
                      setShowInfo(false);
                    }}
                    position={{
                      lat: parseFloat(info.location[0]),
                      lng: parseFloat(info.location[1]),
                    }}
                  >
                    <div>
                      <div style={{ fontSize: 12, fontColor: `#08233B` }}>
                        <div>
                          {abbreviation(info?.status, '_')}
                          {info?.date &&
                            displayBtn &&
                            ' ' + formatTimeForOrderOnlyTime(info?.date)}
                          {' ' + info.time + ' min'}
                          <br />
                        </div>
                      </div>
                    </div>
                  </InfoWindow>
                ),
            )}
          </Map>
        </Grid>

        <Grid item xs={2}>
          <Stepper orientation="vertical">
            {status
              .filter(
                (item) =>
                  item?.deliveryMan?.location &&
                  item?.deliveryMan?.location.length,
              )
              .map((step, index) => (
                <Step
                  key={`${step?.status + index}`}
                  active={selectedStep.includes(index)}
                >
                  <StepLabel
                    onClick={() => handleStepClick(index)}
                    icon={index + 1}
                  >
                    {step.status}
                  </StepLabel>
                  <StepContent>
                    <strong style={{ fontSize: '12px' }}>
                      {formatTimeForOrder(step.date)}{' '}
                    </strong>
                    <br />
                    {step?.admin &&
                      Object.keys(step.admin).length &&
                      step?.admin?.userName && (
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <PersonIcon
                            style={{ color: '#3f51b5', fontSize: '20px' }}
                          />
                          <strong>{step?.admin?.userName}</strong>
                          <br />
                        </div>
                      )}
                    {step?.deliveryMan && step?.deliveryMan.name && (
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <SportsMotorsportsIcon
                          style={{ color: '#3f51b5', fontSize: '20px' }}
                        />{' '}
                        <strong>{step?.deliveryMan.name}</strong>
                        <br />
                      </div>
                    )}

                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <span>
                        {index < order.deliveryManStatusTimes.length &&
                        index !== 0
                          ? calcDurationbetweenTwoTimes(
                              order.deliveryManStatusTimes[index - 1].date,
                              step.date,
                            )
                          : calcDurationbetweenTwoTimes(
                              order.createdAt,
                              step.date,
                            )}
                      </span>
                      min
                    </div>
                  </StepContent>
                </Step>
              ))}
          </Stepper>
        </Grid>
      </Grid>
    </>
  );
}
