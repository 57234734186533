import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Visibility from '@material-ui/icons/Visibility';

export default function LinkComponent({ href,icon, target,id,setToLocal}) {
  // console.log(id,'id');
  return (
    <a
      href={href}
      target="_blank"
      
      // onClick={onClick('storeId',id)}
      onClick={(e) =>
        setToLocal &&  localStorage.setItem(
          'storeId',id,
        )
      }

      
    >
      {icon}
    </a>
  );
}

LinkComponent.propTypes = {
  href: PropTypes.any.isRequired,
  onClick: PropTypes.func,
  target: PropTypes.any,
  icon: PropTypes.any,
  setToLocal: PropTypes.any,
};
