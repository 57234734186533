import { UPLOAD_IMAGE_REQUEST, UPLOAD_IMAGE_SUCCESS, UPLOAD_IMAGE_FAIL, UPLOAD_IMAGE_RESET,DEFAULT_IMAGE } from './upload.constant';

export const uploadImageRequestAction = (payload) => ({
    type: UPLOAD_IMAGE_REQUEST,
    payload
})
export const uploadImageSuccessAction = (iconUrl) => ({
    type: UPLOAD_IMAGE_SUCCESS,
    iconUrl
})
export const defaultImagesAction = (url) => ({
    type: DEFAULT_IMAGE,
    url
})
export const uploadImageFailtAction = (error) => ({
    type: UPLOAD_IMAGE_FAIL,
    error
})
export const uploadImageResetAction = () => ({
    type: UPLOAD_IMAGE_RESET,
})