import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Typography,
  Paper,
  Button,
  Grid,
} from '@mui/material';

// selectors
import {
  getPartnerDeliveriesDataState,
  filterSelector,
} from '../redux/selectors';

// actions
import {
  totalDeliveriesByWarehouseUsingDispatchingStatusAction,
  changeFilterByAction,
  selectDeliveriesUsingDispatchingStatusAction,
} from '../redux/actions/filter.actions';

// mock
import { dispatchingInfoTableHeaders } from '../mock';

export default function DispatchingInfoComponenet({
  setSelectedDispatchingStatus,
  // selectedDispatchingStatus,
  // filterBy,
  // setFilterBy,
}) {
  const dispatch = useDispatch();
  const { allDeliveries, loading, error, deliveriesChangeCounter } =
    useSelector(getPartnerDeliveriesDataState);
  const {
    totalDeliveriesUsingDispatchingStatus,
    filterBy,
    selectedDispatchingStatus,
    selectedWarehousseyOrders,
    selectedCities,
  } = useSelector(filterSelector);
  // console.log('selectedCities', selectedCities);
  // const [totalOrders, setTotalOrders] = useState({});
  // const [selectedWarehousseOrders, setSelectedWarehousseOrders] = useState([]);

  const selectedCell = {
    backgroundColor: '#3496ff',
    borderRadius: '50%/50%',
    padding: '5px',
    color: '#FFFFFF',
    cursor: 'pointer',
    textAlign: 'center',
  };
  const checkIfWarehouseSelected = (warehouse) => {
    if ((selectedWarehousseyOrders || []).find((x) => x == warehouse))
      return { ...selectedCell };
    return { cursor: 'pointer' };
  };
  const checkIfSelected = (warehouse, status) => {
    if ((selectedDispatchingStatus[warehouse] || []).find((x) => x == status))
      return { ...selectedCell };
    return { cursor: 'pointer' };
  };
  // const totalOrdersNumber = () => {
  //   // const totalOrdersByWarehouse = {};
  //   // (allDeliveries || []).map((order) => {
  //   //   totalOrdersByWarehouse[order?.wareHouse?.wareHouseLabel] = {
  //   //     total:
  //   //       (totalOrdersByWarehouse[order?.wareHouse?.wareHouseLabel]?.total ||
  //   //         0) + 1,
  //   //     status: {
  //   //       ...totalOrdersByWarehouse[order?.wareHouse?.wareHouseLabel]?.status,
  //   //       [order.dispatchingStatus]:
  //   //         (totalOrdersByWarehouse[order?.wareHouse?.wareHouseLabel]?.status[
  //   //           order.dispatchingStatus
  //   //         ] || 0) + 1,
  //   //     },
  //   //   };
  //   // });
  //   // // console.log(totalOrdersByWarehouse);
  //   // setTotalOrders(totalOrdersByWarehouse);
  //   const totalOrdersByWarehouse = {};

  //   (allDeliveries || []).forEach((order) => {
  //     const warehouseLabel = order?.wareHouse?.wareHouseLabel;
  //     const dispatchingStatus = order?.dispatchingStatus;

  //     totalOrdersByWarehouse[warehouseLabel] = {
  //       total: (totalOrdersByWarehouse[warehouseLabel]?.total || 0) + 1,
  //       status: {
  //         ...totalOrdersByWarehouse[warehouseLabel]?.status,
  //         [dispatchingStatus]:
  //           (totalOrdersByWarehouse[warehouseLabel]?.status[
  //             dispatchingStatus
  //           ] || 0) + 1,
  //       },
  //     };
  //   });
  //   console.log(totalOrdersByWarehouse);
  //   setTotalOrders(totalOrdersByWarehouse);
  // };
  const selectOrders = (warehouse, status) => {
    const tempselectedDispatchingStatus = { ...selectedDispatchingStatus };
    const tempTotalOrders = { ...totalDeliveriesUsingDispatchingStatus };
    const tempSelectedWarehousseyOrders = [...selectedWarehousseyOrders];
    // const filterByv
    filterBy != 'dispatchingStatus' &&
      dispatch(changeFilterByAction('dispatchingStatus'));
    const selectedWarehousseyIndex = (
      tempSelectedWarehousseyOrders || []
    ).findIndex((x) => x == warehouse);
    // console.log(tempSelectedWarehousseyOrders);
    // console.log(selectedWarehousseyIndex);
    if (status) {
      if (tempselectedDispatchingStatus[warehouse]) {
        if (tempselectedDispatchingStatus[warehouse].includes(status)) {
          const findIndex = tempselectedDispatchingStatus[warehouse].findIndex(
            (x) => x == status,
          );
          findIndex != -1 &&
            tempselectedDispatchingStatus[warehouse].splice(findIndex, 1);
          tempselectedDispatchingStatus[warehouse].length == 0 &&
            delete tempselectedDispatchingStatus[warehouse];
        } else {
          tempselectedDispatchingStatus[warehouse].push(status);
        }
      } else {
        tempselectedDispatchingStatus[warehouse] = [status];
      }
    } else {
      if (selectedWarehousseyIndex != -1) {
        tempSelectedWarehousseyOrders.splice(selectedWarehousseyIndex, 1);
        delete tempselectedDispatchingStatus[warehouse];
      } else {
        tempSelectedWarehousseyOrders.push(warehouse);
        tempselectedDispatchingStatus[warehouse] = Object.keys(
          tempTotalOrders[warehouse].status,
        );
      }
    }
    // console.log('tempselectedDispatchingStatus', tempselectedDispatchingStatus);
    // console.log('tempSelectedWarehousseyOrders', tempSelectedWarehousseyOrders);
    dispatch(
      selectDeliveriesUsingDispatchingStatusAction(
        tempselectedDispatchingStatus,
        tempSelectedWarehousseyOrders,
      ),
    );
  };
  useEffect(() => {
    dispatch(
      totalDeliveriesByWarehouseUsingDispatchingStatusAction(
        allDeliveries,
        selectedCities,
      ),
    );
  }, [deliveriesChangeCounter]);
  // useEffect(() => {
  //   deliveries && totalOrderInWarehouse();
  // }, [(deliveries || []).length]);
  return (
    <div className="mb-4">
      <TableContainer component={Paper}>
        <Typography className="mt-2 ml-4">Dispatching info</Typography>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              {dispatchingInfoTableHeaders.map((item) => (
                <TableCell align="center">{item}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {(Object.keys(totalDeliveriesUsingDispatchingStatus) || []).map(
              (item, index) => (
                <TableRow key={item + index}>
                  <TableCell align="center">{item}</TableCell>
                  {/* <TableCell align="center">dispo</TableCell> */}
                  <TableCell
                    align="center"
                    onClick={(e) =>
                      totalDeliveriesUsingDispatchingStatus[item]?.total &&
                      selectOrders(item, '')
                    }
                  >
                    <span style={checkIfWarehouseSelected(item)}>
                      {totalDeliveriesUsingDispatchingStatus[item]?.total}
                    </span>
                  </TableCell>
                  <TableCell
                    align="center"
                    onClick={(e) =>
                      totalDeliveriesUsingDispatchingStatus[item]?.status
                        ?.ON_HOLD && selectOrders(item, 'ON_HOLD')
                    }
                  >
                    <span style={checkIfSelected(item, 'ON_HOLD')}>
                      {totalDeliveriesUsingDispatchingStatus[item]?.status
                        ?.ON_HOLD || 0}
                    </span>
                  </TableCell>
                  <TableCell
                    align="center"
                    onClick={(e) =>
                      totalDeliveriesUsingDispatchingStatus[item]?.status
                        ?.MANUEL && selectOrders(item, 'MANUEL')
                    }
                  >
                    <span style={checkIfSelected(item, 'MANUEL')}>
                      {totalDeliveriesUsingDispatchingStatus[item]?.status
                        ?.MANUEL || 0}
                    </span>
                  </TableCell>
                  <TableCell
                    align="center"
                    onClick={(e) =>
                      totalDeliveriesUsingDispatchingStatus[item]?.status
                        ?.DISPATCHING && selectOrders(item, 'DISPATCHING')
                    }
                  >
                    <span style={checkIfSelected(item, 'DISPATCHING')}>
                      {totalDeliveriesUsingDispatchingStatus[item]?.status
                        ?.DISPATCHING || 0}
                    </span>
                  </TableCell>

                  <TableCell
                    align="center"
                    onClick={(e) =>
                      totalDeliveriesUsingDispatchingStatus[item]?.status
                        ?.DISPATCHED && selectOrders(item, 'DISPATCHED')
                    }
                  >
                    <span style={checkIfSelected(item, 'DISPATCHED')}>
                      {totalDeliveriesUsingDispatchingStatus[item]?.status
                        ?.DISPATCHED || 0}
                    </span>
                  </TableCell>
                </TableRow>
              ),
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
