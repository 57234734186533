import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridItem from '../../../components/material-dashboard-pro-react/Grid/GridItem';
import { Card, CardHeader, Grid } from '@material-ui/core';
import CardText from '../../../components/material-dashboard-pro-react/Card/CardText';
import CardBody from '../../../components/material-dashboard-pro-react/Card/CardBody';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import PropTypes from 'prop-types';
import { updateStore } from '../services/serverApi.service.js';

export default function StoreTimeConfig({ store, close, ...props }) {
  console.log('store props', store);
  const [times, setTimes] = useState([
    {
      disabled: false,
      openTime: '08:00',
      closeTime: '23:59',
    },
  ]);
  const [selectedAllDays, setselectedAllDays] = useState(false);
  const [days, setDays] = useState([
    {
      checked: false,
      name: 'sun',
      day_time: [],
      open: false,
      visible: false,
      day: 0,
    },
    {
      checked: false,
      name: 'mon',
      day_time: [],
      open: false,
      visible: false,
      day: 1,
    },
    {
      checked: false,
      name: 'tue',
      day_time: [],
      open: false,
      visible: false,
      day: 2,
    },
    {
      checked: false,
      name: 'wed',
      day_time: [],
      open: false,
      visible: false,
      day: 3,
    },
    {
      checked: false,
      name: 'thu',
      day_time: [],
      open: false,
      visible: false,
      day: 4,
    },
    {
      checked: false,
      name: 'fri',
      day_time: [],
      open: false,
      visible: false,
      day: 5,
    },
    {
      checked: false,
      name: 'sat',
      day_time: [],
      open: false,
      visible: false,
      day: 6,
    },
  ]);
  const example = [
    {
      openFullTime: true,
      day: 0,
      day_time: [],
    },
    {
      openFullTime: true,
      day: 1,
      dayTime: [],
    },
    {
      openFullTime: true,
      day: 2,
      day_time: [],
    },
    {
      openFullTime: true,
      day: 3,
      day_time: [],
    },
    {
      openFullTime: true,
      day: 4,
      day_time: [],
    },
    {
      openFullTime: true,
      day: 5,
      day_time: [],
    },
    {
      openFullTime: true,
      day: 6,
      day_time: [],
    },
  ];
  const [isValidTime, setIsValidTime] = useState(true);
  const [timeconfigError, setTimeconfigError] = useState(false);
  const addTimelist = () => {
    setIsValidTime(false);
    if (times.length < 3) {
      const newTimes = times;
      newTimes[newTimes.length - 1].disabled = true;
      setTimes([
        ...newTimes,
        {
          openTime: '--:--',
          closeTime: '23:59',
        },
      ]);
    }
  };
  const deleteTimelist = () => {
    setIsValidTime(false);
    if (times.length > 1) {
      const newTimes = times;
      newTimes.splice(-1);
      newTimes[newTimes.length - 1].disabled = false;
      setTimes([...newTimes]);
    }
  };
  const handlingTime = (time, index, type) => {
    setIsValidTime(false);
    time = time.split(':');
    const hours = time[0];
    const min = time[1];
    const newTimes = times;
    if (type === 'open' && index > 0) {
      const closeTime = newTimes[index - 1].closeTime.split(':');
      let d1 = new Date();
      let d2 = new Date();
      d1.setHours(parseInt(hours), parseInt(min));
      d2.setHours(parseInt(closeTime[0]), parseInt(closeTime[1]));
      const is_valid = d1.getTime() > d2.getTime();
      console.log(is_valid, time, closeTime);
      if (!is_valid) {
        return;
      }
    }
    type === 'open' ? (newTimes[index].openTime = hours + ':' + min) : '';
    type === 'close' ? (newTimes[index].closeTime = hours + ':' + min) : '';
    setTimes([...newTimes]);
  };
  const selectedDays = async (index) => {
    setIsValidTime(false);
    const newDays = days;
    newDays[index].checked = !newDays[index].checked;
    setDays([...newDays]);
    setselectedAllDays(false);
  };
  const checkValidateTime = async () => {
    return new Promise(async (resolve) => {
      //let newTimes = times;
      let day_time = await times
        .map((item) => [item.openTime, item.closeTime])
        .reduce(function (acc, curVal) {
          return acc.concat(curVal);
        }, []);
      let is_valid = false;
      await day_time.map((item, index) => {
        if (day_time[index + 1]) {
          let d1 = new Date();
          let d2 = new Date();
          const date1 = day_time[index].split(':');
          const date2 = day_time[index + 1].split(':');
          d1.setHours(parseInt(date1[0]), parseInt(date1[1]));
          d2.setHours(parseInt(date2[0]), parseInt(date2[1]));
          is_valid = d1.getTime() < d2.getTime();
        }
      });
      resolve(is_valid);
    });
  };
  const addTimeToDays = async () => {
    let newDays = days;
    checkValidateTime().then((valid) => {
      console.log('valid==>', valid);
      setTimeconfigError(!valid);
      if (valid) {
        setIsValidTime(valid);
        console.log(newDays, times);
        newDays = newDays.map((item) => {
          if (item.checked) {
            item.day_time = [...times];
          }
          return item;
        });
        console.log('after', newDays);
        setDays([...newDays]);
      }
    });
  };
  const selectedAll = () => {
    setIsValidTime(false);
    setselectedAllDays(!selectedAllDays);
    const newDays = days;
    newDays.map((item) => (item.checked = !selectedAllDays));
    setDays([...newDays]);
  };
  const open = (index) => {
    let newDays = days;
    newDays[index].open = !newDays[index].open;
    setDays([...newDays]);
  };
  const visible = (index) => {
    let newDays = days;
    newDays[index].visible = !newDays[index].visible;
    setDays([...newDays]);
  };
  const useStyles = makeStyles((theme) => ({
    inputText: {
      width: '35px',
      height: '35px',
    },
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
  }));
  const classes = useStyles();
  const prepareStoreTiming = async () => {
    if (!store.ramadanTiming || store.ramadanTiming.length < 1) {
      store.ramadanTiming = days;
    }
    console.log('store.ramadanTiming', store.ramadanTiming);
    let newDays = days;
    newDays = await (store.ramadanTiming || []).map((item) => {
      console.log('item', item);
      newDays[item.day].day_time = item?.day_time || [];
      newDays[item.day].open = item?.open | false;
      newDays[item.day].visible = item?.visible | false;
      return newDays[item.day];
    });
    setDays([...newDays]);
  };
  const formatTime = (time) => {
    if (time && time.length > 10) {
      const javaScriptRelease = Date.parse(time);
      var options = { hour: 'numeric', minute: 'numeric', hourCycle: 'h23' };
      const newFormat = new Intl.DateTimeFormat('en-US', {
        timeZone: 'Africa/Casablanca',
        ...options,
      }).format(javaScriptRelease);
      // console.log(newFormat);
      return newFormat;
    } else return time;
  };
  // console.log(formatTime('Sat Oct 16 2021 00:59:00 GMT+0100'))
  useEffect(() => {
    prepareStoreTiming();
  }, []);
  console.log(store.timing);

  const onEdit = async () => {
    let timing = await (store.ramadanTiming || []).map((item) => {
      const day_time = days[item.day].day_time.map((item) => {
        return { openTime: item.openTime, closeTime: item.closeTime };
      });
      return {
        open: !!days[item.day].open,
        visible: !!days[item.day].visible,
        day_time: day_time,
        day: item.day,
      };
    });
    store.ramadanTiming = timing;
    const {
      _id,
      parentStoreId,
      imageUrl,
      displayType,
      deleted,
      deletedAt,
      createdAt,
      updatedAt,
      __v,
      ...newStore
    } = store;
    console.log('newStore', newStore);
    updateStore(store._id, newStore)
      .then(() => {
        close();
        console.log('Store has been updated', {
          type: 'success',
        });
      })
      .catch((error) => {
        console.log(error.message, {
          type: 'error',
        });
      })
      .finally(() => {});
  };

  return (
    <div>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="rose" text>
            <CardText color="rose">
              <h4 className={classes.cardTitle}>Form Elements</h4>
            </CardText>
          </CardHeader>
          <CardBody>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                {times.map((item, index) => (
                  <div className="col-3">
                    <div
                      className="d-flex justify-content-start p-2"
                      style={{ color: 'black !important' }}
                    >
                      <form
                        className={classes.container}
                        noValidate
                        style={{ background: '#f7f7f7' }}
                      >
                        <TextField
                          disabled={item.disabled}
                          ampm={false}
                          id="time"
                          error={timeconfigError}
                          helperText={timeconfigError ? 'Incorrect config' : ''}
                          label={'open'}
                          type="time"
                          className={classes.textField}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            step: 300, // 5 min
                          }}
                          onChange={(e) => {
                            handlingTime(e.target.value, index, 'open');
                          }}
                          value={item.openTime}
                        />
                      </form>
                      <form
                        className={classes.container}
                        noValidate
                        style={{ background: '#e8e8e8' }}
                      >
                        <TextField
                          disabled={item.disabled}
                          id="time"
                          label={'close'}
                          error={timeconfigError}
                          helperText={timeconfigError ? 'Incorrect config' : ''}
                          type="time"
                          defaultValue={item.closeTime}
                          className={classes.textField}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            step: 300, // 5 min
                          }}
                          onChange={(e) => {
                            var closeTime = parseInt(e.target.value, 10);
                            var openTime = parseInt(item.openTime, 10);
                            console.log(openTime, closeTime);
                            if (openTime < closeTime) {
                              handlingTime(e.target.value, index, 'close');
                            }
                          }}
                          value={item.closeTime}
                        />
                      </form>
                    </div>
                  </div>
                ))}
              </Grid>
              <Grid item xs={6} className="mt-2">
                {/* <div className="col-7 p-2 " > */}
                <Button
                  style={{ margin: '0px 12px' }}
                  variant="contained"
                  color="secondary"
                  onPre
                  className={classes.button}
                  onClick={deleteTimelist}
                  endIcon={<Icon>delete</Icon>}
                >
                  delete
                </Button>
                <Button
                  style={{ margin: '0px 12px' }}
                  variant="contained"
                  color="primary"
                  onPre
                  className={classes.button}
                  onClick={addTimelist}
                  endIcon={<Icon>add</Icon>}
                >
                  add
                </Button>
                {!isValidTime && (
                  <Button
                    style={{
                      margin: '0px 12px',
                      background: '#4caf50',
                      color: '#ffffff',
                    }}
                    variant="contained"
                    onPre
                    className={classes.button}
                    onClick={addTimeToDays}
                    endIcon={<Icon>valider</Icon>}
                  >
                    valider
                  </Button>
                )}
              </Grid>
            </Grid>
            <div className="row">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedAllDays}
                            onChange={() => {
                              selectedAll();
                            }}
                            name={'checkedAll'}
                            color="primary"
                          />
                        }
                        label={''}
                      />
                    </th>
                    <th scope="col" colSpan={3}></th>
                  </tr>
                </thead>
                <tbody>
                  {days.map((item, index) => (
                    <tr>
                      <th scope="row">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={item.checked}
                              onChange={() => {
                                selectedDays(index);
                              }}
                              name={'checked' + index}
                              color="primary"
                            />
                          }
                          label={item.name}
                        />
                      </th>
                      {item.day_time.map((item) => (
                        <td>
                          <div className="row">
                            <form
                              className={classes.container}
                              noValidate
                              style={{ background: '#ddffb6' }}
                            >
                              <TextField
                                id="time"
                                label={'open'}
                                type="text"
                                // defaultValue={item.openTime}
                                className={classes.textField}
                                // InputLabelProps={{
                                //   shrink: true,
                                // }}
                                // inputProps={{
                                //   step: 300, // 5 min
                                //   readOnly: true,
                                // }}
                                // onChange={(e) => {
                                //   // handlingTime(e.target.value, index);
                                // }}
                                value={
                                  item.openTime
                                  // formatTime(item.openTime)
                                }
                              />
                              <label>open: {item.openTime}</label>
                            </form>
                            <form
                              className={classes.container}
                              noValidate
                              style={{ background: '#ffe9e9' }}
                            >
                              <TextField
                                id="time"
                                label={'close'}
                                type="text"
                                // defaultValue={item.closeTime}
                                className={classes.textField}
                                // InputLabelProps={{
                                //   shrink: true,
                                // }}
                                // inputProps={{
                                //   step: 300, // 5 min
                                //   readOnly: true,
                                // }}
                                // onChange={(e) => {
                                //   // handlingTime(e.target.value, index);
                                // }}
                                value={
                                  item.closeTime
                                  // formatTime(item.closeTime)
                                }
                              />
                              <label>close : {item.closeTime}</label>
                            </form>
                          </div>
                        </td>
                      ))}
                      <td colSpan={4 - (item.day_time.length | 0)}>
                        <div className={'float-right'}>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={item.open}
                                onChange={() => open(index)}
                              />
                            }
                            label="open"
                          />
                          <FormControlLabel
                            control={
                              <Switch
                                checked={item.visible}
                                onChange={() => visible(index)}
                              />
                            }
                            label="visibile"
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="row d-flex justify-content-end">
              <Button
                disabled={!isValidTime}
                style={{ padding: '12px' }}
                variant="contained"
                color="primary"
                onPre
                className={classes.button}
                onClick={() => {
                  onEdit();
                }}
                endIcon={<Icon>check</Icon>}
              >
                Save
              </Button>
            </div>
          </CardBody>
        </Card>
      </GridItem>
    </div>
  );
}

StoreTimeConfig.propTypes = {
  store: PropTypes.any.isRequired,
  close: PropTypes.func,
};
