import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useAlert } from 'react-alert';
import moment from 'moment-timezone';
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import CustomInput from '../../../components/material-dashboard-pro-react/CustomInput/CustomInput';
import { getCountries, updateCity } from '../services/serverApi.service';
import SelectBoxComponent from '../../../components/SelectBox.component';
import {
  paymentGateway,
  diplayPhoneUsingDeliveryManStatus,
  partnersList,
} from '../mocks/index';
import SwitchComponent from '../../../components/Switch.component';

const validationSchema = yup.object({
  name: yup.string().required(),
  countryId: yup.string().required(),
  timeZone: yup.string().required(),
  _id: yup.string().required(),
  // paymentGateway: yup.array().required(),
  paymentGateway: yup.array().of(
    yup
      .object()
      .shape({
        name: yup.string().required(),
        enabled: yup.bool().required(),
      })
      .required(),
  ),
  MAS: yup.boolean().optional().default(false),
});

export default function EditCityComponent({
  data,
  props,
  onItemEdited,
  close,
}) {
  const alert = useAlert();
  const [isLoading, setIsLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [dispatchingMessage, setDispatchingMessage] = useState(
    props?.dispatchingMessage || [],
  );
  console.log('dataaaaa', data);
  const formik = useFormik({
    initialValues: {
      name: '',
      countryId: '',
      cityLocations: [],
      timeZone: '',
      paymentGateway: [
        {
          name: 'Cash',
          enabled: '',
        },
        {
          name: 'Card',
          enabled: '',
        },
        {
          name: 'KaalixUP',
          enabled: '',
        },
        {
          name: 'KaalixPay',
          enabled: '',
        },
      ],
      MAS: false,
      displayPhone: '',
      partners: [],
    },
    validationSchema,
  });
  // console.log(formik.values);

  useEffect(() => {
    console.log('my data', data);
  }, [data]);

  const onEdit = async () => {
    // our log function here :

    // update city :
    setIsLoading(true);
    let newCity = formik.values;
    newCity.dispatchingMessage = dispatchingMessage;
    console.log(newCity);
    updateCity(data._id, newCity)
      .then(() => {
        alert.show('City has been edited', {
          type: 'success',
        });
        formik.resetForm();
        onItemEdited();
        close();
      })
      .catch((error) => {
        alert.show(error.message, {
          type: 'error',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    // eslint-disable-next-line no-shadow
    getCountries().then(({ data }) => {
      setCountries(data);
    });
    if (data) {
      formik.setValues({
        name: data.name,
        countryId: data.countryId,
        timeZone: data.timeZone || '',
        MAS: data.MAS || false,
        paymentGateway: [
          {
            name: 'Cash',
            enabled:
              data?.paymentGateway && data.paymentGateway.length > 0
                ? data.paymentGateway.find((x) => x.name == 'Cash').enabled
                : false,
          },
          {
            name: 'Card',
            enabled:
              data?.paymentGateway && data.paymentGateway.length > 0
                ? data.paymentGateway.find((x) => x.name == 'Card').enabled
                : false,
          },
          {
            name: 'KaalixUP',
            enabled:
              data?.paymentGateway && data.paymentGateway.length > 0
                ? data.paymentGateway.find((x) => x.name == 'KaalixUP').enabled
                : false,
          },
          {
            name: 'KaalixPay',
            enabled:
              data?.paymentGateway && data.paymentGateway.length > 0
                ? data.paymentGateway.find((x) => x.name == 'KaalixPay').enabled
                : false,
          },
        ],
        displayPhone: data?.displayPhone,
        partners: data?.partners || [],
        // paymentGateway:[
        //   {name : data?.paymentGateway&&data?.paymentGateway[0]?.name,enabled:data?.paymentGateway?.enabled?data?.paymentGateway[0]?.enabled:false}]
      });
    }
  }, [data]);
  console.log('formik.values.partners', formik.values.partners);
  return (
    <div>
      <CustomInput
        labelText="Name"
        formControlProps={{
          fullWidth: true,
        }}
        type="text"
        error={formik.touched.name && Boolean(formik.errors.name)}
        inputProps={{
          value: formik.values.name,
          onBlur: formik.handleBlur('name'),
          onChange: formik.handleChange('name'),
        }}
      />
      <CustomInput
        labelText="Timezone"
        formControlProps={{
          fullWidth: true,
        }}
        type="text"
        error={formik.touched.timeZone && Boolean(formik.errors.timeZone)}
        inputProps={{
          value: formik.values.timeZone,
          onBlur: formik.handleBlur('timeZone'),
          onChange: formik.handleChange('timeZone'),
        }}
      />
      <SelectBoxComponent
        items={countries.map((country) => ({
          value: country._id,
          label: country.name,
        }))}
        inputLabel="Country"
        onValueChange={formik.handleChange('countryId')}
        value={formik.values.countryId}
      />
      {/* <SelectBoxComponent
        items={paymentGateway.map((mode) => ({
          value: mode,
          label: mode,
        }))}
        inputLabel="Payment"
        onValueChange={formik.handleChange('paymentGateway[0].name')}
        value={formik.values.paymentGateway[0]?.name}
      /> */}
      {formik.values.paymentGateway &&
        formik.values.paymentGateway.length > 0 &&
        formik.values.paymentGateway.map((item, index) => (
          <SwitchComponent
            onChange={(value) =>
              formik.setFieldValue(`paymentGateway[${index}].enabled`, value)
            }
            checked={
              formik.values.paymentGateway &&
              formik.values.paymentGateway[index]?.enabled
            }
            error={
              formik.touched.paymentGateway &&
              formik.touched.paymentGateway[index]?.enabled &&
              Boolean(formik.errors.paymentGateway[index]?.enabled)
            }
            label={item.name}
          />
        ))}
      <SelectBoxComponent
        items={diplayPhoneUsingDeliveryManStatus.map((item) => ({
          value: item,
          label: item,
        }))}
        inputLabel="displayPhone"
        onValueChange={formik.handleChange('displayPhone')}
        value={formik.values.displayPhone}
      />

      <SelectBoxComponent
        items={partnersList.map((item) => ({
          value: item,
          label: item,
        }))}
        inputLabel="partners"
        onValueChange={(items) => {
          formik.setFieldValue('partners', [items]);
        }}
        value={formik.values.partners}
        // multiple
      />
      <SwitchComponent
        onChange={(value) => {
          formik.setFieldValue(`MAS`, value);
        }}
        checked={formik.values.MAS}
        label={'MAS'}
      />
      <Box textAlign="center">
        <Button
          round
          type="submit"
          color="success"
          //disabled={!formik.dirty || !formik.isValid || isLoading}
          loading={isLoading}
          loaderColor="white"
          onClick={onEdit}
        >
          Edit
        </Button>
      </Box>
    </div>
  );
}

EditCityComponent.propTypes = {
  data: PropTypes.any,
  onItemEdited: PropTypes.func.isRequired,
};
