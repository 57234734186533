import React, { useEffect, useState } from 'react'
import * as yup from 'yup';
import { useFormik } from 'formik';
import {  FormLabel,Box } from '@material-ui/core';
import CustomInput from '../../../components/material-dashboard-pro-react/CustomInput/CustomInput'
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button'
import { editCutomerPhone } from '../services/serverApi.customer';

const validationSchema = yup.object({
    phone: yup.number().required(),
})
export default function EditPhoneComponent({phone,customer,index,setCustomers,customers,close}) {
    const [isLoading, setIsLoading] = useState(false)
    const formik = useFormik({
        initialValues: {
            phone: '',
            customerId:customer._id
        },
        validationSchema,
    });

    const editCustomer = () => {
        console.log(formik.values);
        setIsLoading(true)
        editCutomerPhone(formik.values)
          .then(({ data }) => {
            console.log(data);
            customer.phone = data.customer.phone;
            let newCustomers = [...customers];
            newCustomers[index] = customer;
            setCustomers(newCustomers)
            close()
          })
          .catch((error) => {
            console.log(error.message, {
              type: 'error',
            });
          })
          .finally(() => setIsLoading(false))
    }
    const setValues = () => {
        formik.setValues({
            customerId : customer._id ,
            phone :phone,
        })
    }

    useEffect(() => {
        setValues()
    }, [phone,customer])
    return (
        <div>
            <FormLabel >
            example: "660708090"
            </FormLabel>
            <CustomInput
                formControlProps={{
                    fullWidth: true,
                }}
                type="text"
                error={
                    formik.touched.phone && Boolean(formik.errors.phone)
                }
                inputProps={{
                    value: formik.values.phone,
                    onBlur: formik.handleBlur('phone'),
                    onChange: formik.handleChange('phone'),
                }}
            />
            <Box textAlign="center">
                <Button
                    round
                    type="submit"
                    color="success"
                    disabled={!formik.dirty || !formik.isValid || isLoading}
                    loading={isLoading}
                    loaderColor="white"
                    onClick={editCustomer}
                >
                    save
                </Button>
            </Box>
        </div>
    )
}
