import React, { useState } from 'react';
// material api
import { Button, Grid, Typography, Box } from '@mui/material';
// component
import SelectBoxComponent from '../../../components/SelectBox.component';
import DeliveryHeaderInfoComponenet from './Order.header.info.componenet';

// mock
import { DeliveryManStatus } from '../../../config/status.config';
// api
import { updateOrderStatus } from '../services/serverApi.service';

export default function ChangeStatusComponent({
  orders,
  orderId,
  admin,
  close,
}) {
  const [order, setOrder] = useState(
    (orders || []).find((x) => x._id == orderId),
  );
  const [selectedStatus, setSelectedStatus] = useState(
    order.deliveryManStatus || '',
  );
  const handleStatus = () => {
    const deliveryManId = !!order.deliveryMan ? order.deliveryMan._id : null;

    if (deliveryManId) {
      updateOrderStatus(order._id, {
        deliveryManId: deliveryManId,
        status: selectedStatus,
        invoiceUrl: 'url:///',
        pricePaid: 0,
        admin,
      })
        .then(({ data }) => {
          close();
        })
        .catch((err) => console.log(err));
    }
  };
  return (
    <div>
      <DeliveryHeaderInfoComponenet order={order} />
      {order?.blockArrivedToDestination && (
        <p style={{ color: 'red' }}>block ArrivedToDestination</p>
      )}
      {order?.blockDelivred && <p style={{ color: 'red' }}>block Delivred</p>}
      <Box sx={{ display: 'flex' }} style={{ padding: '15px' }}>
        <SelectBoxComponent
          items={DeliveryManStatus.map((item) => ({
            value: item.value,
            label: item.label,
          }))}
          inputLabel="status"
          onValueChange={(item) => {
            setSelectedStatus(item);
          }}
          value={selectedStatus}
        />
        <div style={{ float: 'right', margin: '15px' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              handleStatus();
            }}
          >
            valid
          </Button>
        </div>
      </Box>
    </div>
  );
}
