import React , { useEffect, useState } from 'react'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { makeStyles } from "@material-ui/core/styles";
import { Collapse, Box, Grid } from '@material-ui/core'
import DragHandleIcon from "@material-ui/icons/DragHandle";
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import {updateItem} from '../services/serverAPI.service'


export default function SpecificationMappingComponent({data,close,onItemEdited,allSpecifications}) {
    const [specificationsForThisItems,setSpecificationsForThisItems] = useState([])
    const [isLoading,setIsLoading] = useState(false)
    const useRowStyles = makeStyles({
        root: {
          "& > *": {
            borderBottom: "unset"
          }
        }
      });
      const classes = useRowStyles();
      const grid = 8;

      const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
        padding: grid * 2,
        margin: `0 0 ${grid}px 0`,
    
        // change background colour if dragging
        background: isDragging ? "lightgreen" : "",
    
        // styles we need to apply on draggables
        ...draggableStyle
      });
      const getSpecificationsForThisItems = ()=>{
          console.log('allSpecifications props',allSpecifications);
          console.log('data props',data);
          const specificationArray = []
          data.specifications.length > 0 && data.specifications.map((id)=>{
            const findIndex = allSpecifications.findIndex((item)=>item._id==id)
            findIndex>=0&&specificationArray.push(allSpecifications[findIndex])
          })
          setSpecificationsForThisItems(specificationArray)
      }
      const onEdit = ()=>{
          const newSpecificcationArray =[]
          setIsLoading(true)
          specificationsForThisItems.length>0&&specificationsForThisItems.map(specification=>(
            newSpecificcationArray.push(specification._id)
          ))
            data.specifications = newSpecificcationArray
            // console.log('data after',data);
            updateItem(data)
            .then(()=>{
                onItemEdited()
                close()
            })
            .catch((error)=>{console.log(error)})
            .finally(()=>setIsLoading(false))
      }
      // console.log(specificationsForThisItems);
      const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
    
        return result;
      };
      const onDragEnd = (result) => {
        if (!result.destination) {
          return;
        }
        if (result.type === "droppableItem") {
          const items = reorder(
            specificationsForThisItems,
            result.source.index,
            result.destination.index
          );
          setSpecificationsForThisItems(items)
        }
    
      }
      useEffect(() => {
        getSpecificationsForThisItems()
      },[])
    return (
        <div>
            <Box textAlign="right">
                <Button
                  round
                  type="submit"
                  color="success"
                  disabled={isLoading}
                  loading={isLoading}
                  loaderColor="white"
                  onClick={onEdit}
                >
                  save
                </Button>
              </Box>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable" type="droppableItem">
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                    // style={getListStyle(snapshot.isDraggingOver)}
                    >
                      {specificationsForThisItems && specificationsForThisItems.length > 0 && specificationsForThisItems.map((item, index) => (

                        <Draggable key={item._id} draggableId={item._id} index={index}>
                          {(provided, snapshot) => (
                            <div>
                              <Box className={classes.root} style={{ background: "grey", }} boxShadow={3}>

                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  style={getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style
                                  )}
                                >
                                  <Grid container spacing={3}>
                                    <Grid item xs={10}>
                                      <span style={{ color: "white" }} >{item.name}</span>
                                    </Grid>
                                    <Grid item xs={1}>

                                      <div {...provided.dragHandleProps}>

                                        <DragHandleIcon style={{ color: "white" }} />
                                      </div>

                                    </Grid>
                                  </Grid>
                                </div>
                              </Box>
                              {provided.placeholder}
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
        </div>
    )
}
