import {
  takeEvery,
  takeLatest,
  put,
  all,
  fork,
  call,
} from 'redux-saga/effects';
import axios from 'axios';
import {
  getRideSuccessAction,
  // getRideFailAction,
  getOrderFailV2Action,
  getOrderSuccessV2Action,
} from './actions/order.actions';
import {
  GET_RIDE_REQUEST,
  GET_RIDE_REQUESTV2,
} from './constants/order.constants';
import { getAdminsListSuccessAction, getAdminsListFailAction } from './actions/adminsList.actions'
import { GET_ADMINS_LIST_REQUEST } from './constants/adminsList.constants'
import { MICROSERVICE_BASE_URL } from '../../../config/backendServer.config';
import { getCurrentRides } from '../service/serverAPI.service';

let dataRides = [];
let dataRidesObj = {};
function* getAdminsListFromAPI() {
  const { data } = yield axios.get(
    `${MICROSERVICE_BASE_URL.ADMIN}/admin?page=${0}&limit=${0
    }&paged=${false}`,
  );

  return data
}
function* getRidesAllFromAPI(cityId) {
  const { data } = yield axios.get(
    `${MICROSERVICE_BASE_URL.DRIVER}/ride/city/${cityId}/current-rides`,
  );

  dataRides.push(...data);
  // // const tempObj = {};
  // dataRidesObj[cityId] = data
  // // dataRidesObj.push(tempObj);
  // console.log(dataRidesObj);
  return data;
}
function* getRidesFromAPI(cities) {
  const { data } = yield axios.post(
    `${MICROSERVICE_BASE_URL.DRIVER}/ride/city/current-rides`,
    { cities: cities },
  );

  dataRides.push(...data);
  // // const tempObj = {};
  // dataRidesObj[cityId] = data
  // // dataRidesObj.push(tempObj);
  // console.log(dataRidesObj);
  return data;
}

function* getRidesV2(payload) {
  const { cities } = payload;
  try {
    let data;
    if (cities.includes('all')) {
      data = yield getRidesAllFromAPI('all');
    } else {
      data = yield getRidesFromAPI(cities);
    }
    yield put(getOrderSuccessV2Action(data));
  } catch (error) {
    console.log(error.message);
    yield put(getOrderFailV2Action(error));
  }
}
function* getAdminsList() {
  try {
    let data = yield getAdminsListFromAPI();

    yield put(getAdminsListSuccessAction(data));
  } catch (error) {
    console.log(error.message);
    yield put(getAdminsListFailAction(error));
  }
}

export default function* watcherSage() {
  yield takeLatest(GET_RIDE_REQUESTV2, getRidesV2);
  yield takeLatest(GET_ADMINS_LIST_REQUEST, getAdminsList);
}
