import React, { useState } from 'react';
import { Button, TextField } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

export default function EditIntervalConfigComponent({
  formik,
  intervalId,
  indexItem,
}) {
  const [interval, setInterval] = useState(
    formik.values.meansOfTransportation.find((x) => x._id === intervalId),
  );
  const addInterval = () => {
    var newInterval = interval;
    var obj = newInterval?.info?.priceIntervals;
    // console.log('obj', obj);
    obj.push({
      ds: obj[obj.length - 1].de,
      de: obj[obj.length - 1].de + 1,
      cof: 0,
    });
    setInterval({ ...newInterval });
    formik.setFieldValue(`meansOfTransportation[indexItem]`, newInterval);
    // console.log('formik after', formik.values);
  };
  const editInterval = (index, item, value) => {
    var newInterval = interval;
    var obj = newInterval?.info?.priceIntervals[index];
    obj[item] = value;
    setInterval({ ...newInterval });
    formik.setFieldValue(`meansOfTransportation[indexItem]`, newInterval);
    // console.log('formik after', formik.values);
  };
  const deleteInterval = (index) => {
    var newInterval = interval;
    var obj = newInterval?.info?.priceIntervals;
    obj.splice(-1);
    setInterval({ ...newInterval });
    formik.setFieldValue(`meansOfTransportation[indexItem]`, newInterval);
    // console.log('formik after', formik.values);
  };
  return (
    <div>
      <b>Edit {interval.name} interval</b>
      <div className="px-2">
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">DS</th>
              <th scope="col">DE</th>
              <th scope="col">coeffi</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {(interval.info.priceIntervals || []).map((item, index) => (
              <tr key={index}>
                <th scope="row">Interval {index + 1}</th>
                <td>
                  <div>
                    <TextField
                      id={'outlined-basic-ds' + index}
                      label=""
                      variant="outlined"
                      type={'number'}
                      value={item.ds}
                      InputProps={
                        {
                          // readOnly: true,
                        }
                      }
                      onChange={($event) => {
                        // item.ds = parseFloat($event.target.value);
                        editInterval(
                          index,
                          'ds',
                          parseFloat($event.target.value),
                        );
                      }}
                    />
                  </div>
                </td>
                <td>
                  <div style={{ display: 'inline-flex' }}>
                    <TextField
                      id={'outlined-basic-de' + index}
                      label=""
                      variant="outlined"
                      type={'number'}
                      value={item.de}
                      InputProps={
                        {
                          // readOnly: interval.length !== index + 1,
                          // inputProps: { min: item.ds },
                        }
                      }
                      onChange={($event) => {
                        editInterval(
                          index,
                          'de',
                          parseFloat($event.target.value),
                        );
                      }}
                    />
                  </div>
                </td>
                <td>
                  <div style={{ display: 'inline-flex' }}>
                    <TextField
                      id={'outlined-basic-' + index}
                      label=""
                      variant="outlined"
                      type={'number'}
                      value={item.cof}
                      onChange={($event) => {
                        editInterval(
                          index,
                          'cof',
                          parseFloat($event.target.value),
                        );
                      }}
                    />
                  </div>
                </td>

                <td>
                  {(interval.info.priceIntervals || []).length > 1 &&
                    (interval.info.priceIntervals || []).length ==
                      index + 1 && (
                      <Fab
                        size="medium"
                        color="secondary"
                        aria-label="add"
                        onClick={deleteInterval}
                      >
                        <DeleteIcon />
                      </Fab>
                    )}
                </td>
              </tr>
            ))}
            <tr>
              <td colSpan={6}>
                <Fab color="primary" aria-label="add" onClick={addInterval}>
                  <AddIcon />
                </Fab>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
