import React, { useEffect, useState } from 'react'
import { calcDuration } from '../../../helpers/index'
import { deliveryManStatusObj } from '../../../config/status.config'
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';

export default function DeliveryManStatusTimeComponent({ deliveryManStatusTime, status, notification }) {
  // console.log(deliveryManStatusTime);
  const [statusTime, setStatusTime] = useState(calcDuration(deliveryManStatusTime))
  const [timeColor, setTimeColor] = useState('')
  const [alert, setAlert] = useState(false)
  const handelAlert = (newStatus) => {
    // console.log(newStatus);
    switch (newStatus) {
      case 'ON_HOLD':
        if (statusTime > 2) {

          // notification()
          setAlert(true)
        }
        break;
      case deliveryManStatusObj.ACCEPTED:
        if (statusTime > 1) {

          // notification()
          setAlert(true)
        } else {
          setAlert(false)
        }
        break;
      case deliveryManStatusObj.COMING_FOR_DELIVERY:
        if (statusTime > 10) {

          // notification()
          setAlert(true)
        } else {
          setAlert(false)
        }
        break;
      case deliveryManStatusObj.START_PREPARATION:
        if (statusTime > 15) {

          // notification()
          setAlert(true)
        } else {
          setAlert(false)
        }
        break;
      case deliveryManStatusObj.STARTED_DELIVERY:
        if (statusTime > 15) {

          // notification()
          setAlert(true)
        } else {
          setAlert(false)
        }
        break;
      case deliveryManStatusObj.ARRIVED_TO_DESTINATION:
        if (statusTime > 5) {

          // notification()
          setAlert(true)
        } else {
          setAlert(false)
        }
        break;

      default:
        break;
    }
  }
  const ChangetimeColor = (statusTime) => {
    if (statusTime < 15) {
      setTimeColor('#007fff');
    }
    else if (statusTime >= 15 && statusTime < 35) {
      setTimeColor('#5b5')
    }
    else if (statusTime >= 35) {
      setTimeColor('#f44336')
    }

  }
  //   ChangetimeColor(calcDuration(time))
  useEffect(() => {

    // setChanges(!changes)
    setInterval(() => {
      // setTimeWithMin(calcDuration(time))
      deliveryManStatusTime !== '' && setStatusTime(calcDuration(deliveryManStatusTime))
      deliveryManStatusTime !== '' && ChangetimeColor(calcDuration(deliveryManStatusTime))
    }, 60000)
    deliveryManStatusTime !== '' && setStatusTime(calcDuration(deliveryManStatusTime))
    // handelAlert(status)
    return () => {
      ChangetimeColor(statusTime)
    }
  }, [deliveryManStatusTime, statusTime])
  //  console.log('statusTime',statusTime);
  return (
    <div style={{ display: 'flex', fontSize: '12px', backgroundColor: timeColor, color: timeColor ? 'white' : 'black', width: "60px", padding: "0.5px", textAlign: "center", justifyContent: "center" }}>

      {(statusTime || '--') + ' min'}
      {/* {alert && <NotificationsActiveIcon style={{ fontSize: '18px' }} />} */}
    </div>
  )
}
