import {
  GET_ORDER_REQUEST,
  GET_ORDER_SUCCESS,
  GET_ORDER_FAIL,
  CHANGE_ORDER_STATUS,
  NEW_ORDER,
  ACCEPTED_ORDER,
  VALIDATED_ORDER,
  CANCEL_ORDER,
  INITIAL_STATE,
  OPEN_MODEL,
  CLOSE_MODEL,
  CHANGE_ORDER_ALARMS,
  CHANGE_ORDER_ALARM,
  SET_ORDER_ALARMS, CHANGE_ORDER_ALARMS_DATA,
  // V2  
  GET_ORDER_REQUESTV2,
  GET_ORDER_SUCCESSV2,
  GET_ORDER_FAILV2,
  CHANGE_ORDER_STATUSV2,
  CANCEL_ORDERV2,
  ACCEPTED_ORDERV2,
  NEW_ORDERV2,
  VALIDATED_ORDERV2,
} from '../constants/order.constants';

export const getOrderRequestAction = (cities) => ({
  type: GET_ORDER_REQUEST,
  cities
});
export const getOrderRequestV2Action = (cities) => ({
  type: GET_ORDER_REQUESTV2,
  cities
});
export const getOrderSuccessAction = (orders) => ({
  type: GET_ORDER_SUCCESS,
  orders,
});
export const getOrderSuccessV2Action = (orders) => ({
  type: GET_ORDER_SUCCESSV2,
  orders,
});
export const getOrderFailAction = (error) => ({
  type: GET_ORDER_FAIL,
  error,
});
export const getOrderFailV2Action = (error) => ({
  type: GET_ORDER_FAILV2,
  error,
});
export const changeOrderStatusAction = (order) => ({
  type: CHANGE_ORDER_STATUS,
  order,
});
export const changeOrderAlarmAction = (id, alarms) => ({
  type: CHANGE_ORDER_ALARMS,
  id,
  alarms,
});
export const changeOrderAlarmDataAction = (id, alarm) => ({
  type: CHANGE_ORDER_ALARMS_DATA,
  id,
  alarm,
});
export const changeAlarmAction = (alarm) => ({
  type: CHANGE_ORDER_ALARM,
  alarm,
});
export const setAlarmsAction = (alarms) => ({
  type: SET_ORDER_ALARMS,
  alarms,
});
export const acceptedOrdersAction = (order) => ({
  type: ACCEPTED_ORDER,
  order,
});
export const newOrderAction = (order) => ({
  type: NEW_ORDER,
  order,
});
export const validatOrderAction = (order) => ({
  type: VALIDATED_ORDER,
  order,
});
export const cancelOrderAction = (_id) => ({
  type: CANCEL_ORDER,
  _id,
});
export const initialOrderAction = () => ({
  type: INITIAL_STATE,
});
export const openModelAction = (itemId, index, model) => ({
  type: OPEN_MODEL,
  itemId,
  index,
  model,
});
export const closeModelAction = () => ({
  type: CLOSE_MODEL,
});
// ********************* V2 ******************* 
export const changeOrderStatusV2Action = (order) => ({
  type: CHANGE_ORDER_STATUSV2,
  order,
});
export const cancelOrderV2Action = (_id, customerId) => ({
  type: CANCEL_ORDERV2,
  _id,
  customerId
});
export const acceptedOrdersV2Action = (order) => ({
  type: ACCEPTED_ORDERV2,
  order,
});
export const newOrderV2Action = (order) => ({
  type: NEW_ORDERV2,
  order,
});
export const validatOrderV2Action = (order) => ({
  type: VALIDATED_ORDERV2,
  order,
});
