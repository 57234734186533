import { takeEvery, takeLatest, put, all, fork, call } from 'redux-saga/effects'
import axios from 'axios'
import { getDeliverySuccessAction,getDeliveryFailAction } from './actions/deliveryMan.actions'
import { GET_DELIVERY_MEN_REQUEST} from './constants/deliveryMan.constants'
import { MICROSERVICE_BASE_URL } from '../../../config/backendServer.config'


function* getDeliveryMenFromAPI(cityId) {
  const { data } = yield axios.get(
    `${MICROSERVICE_BASE_URL.DISPATCHING}/delivery-man/city/${cityId}`
  );

//   dataOrders.push(...data);
  return data
}

function* getDeliveryMenInCity(payload) {
  console.log(payload);
  const { cityId } = payload
  console.log(cityId);
  try {
    const data = yield getDeliveryMenFromAPI(cityId)
    
    // console.log(data);
    yield put(getDeliverySuccessAction(data))

  } 
  catch (error) {
    console.log(error.message);
    yield put(getDeliveryFailAction(error))

  }
}


export default function* watcherSage() {
  yield takeLatest(GET_DELIVERY_MEN_REQUEST, getDeliveryMenInCity)

}