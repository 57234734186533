import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Map
import CustomMarker from '../../../components/Markers.component';
import { iconsList } from '../../../config/status.config';
import Map from '../../../components/Map.component';
// compo
import SelectBoxComponent from '../../../components/SelectBox.component';
// api
import { getCities } from '../../orders/services/serverApi.service';
import { socketConnectPertnerGps } from '../services/server.api.service';
import { socketConnect } from '../../orders/services/serverApi.service';

// actions
import {
  getDeliveryRequestAction,
  resetDeliveryMenAction,
  changeDeliveryStatusAction,
} from '../redux/actions/deliveryMen.actions';
// selectors
import { deliveryMenInCity } from '../redux/selectors';
import { getUserDataState } from '../../auth/redux/selectors';

export default function trackingDeliveryMen() {
  const dispatch = useDispatch();
  const userData = useSelector(getUserDataState);
  const { deliveryMen, error } = useSelector(deliveryMenInCity);
  const [path, setPath] = useState({});
  const [zoom, setZoom] = useState(13);
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [socket, setSocket] = useState(null);
  const getCitiesList = () => {
    getCities()
      .then(({ data }) => {
        setCities(data);
      })
      .catch((error) => console.log(error));
  };
  const getDeliveryManList = (city) => {
    if (city?._id) {
      setSelectedCity(city._id);
      setPath({
        lat: parseFloat(city.cityLocations[0][1]),
        lng: parseFloat(city.cityLocations[0][0]),
      });
      dispatch(getDeliveryRequestAction(city._id));
    }
  };
  const onChangeSelectBox = (cityId) => {
    setSelectedCity(cityId);
    const findCity = cities.find((x) => x._id == cityId);
    findCity &&
      setPath({
        lat: parseFloat(findCity.cityLocations[0][1]),
        lng: parseFloat(findCity.cityLocations[0][0]),
      });
    dispatch(getDeliveryRequestAction(cityId));
  };
  useEffect(() => {
    console.log('useEffect');
    cities.length == 0 && dispatch(resetDeliveryMenAction());
    cities.length == 0 && getCitiesList();
    cities.length > 0 &&
      getDeliveryManList((cities || []).find((x) => x.name == 'Casablanca'));
    if (!socket) {
      // setSocket(socketConnect(userData.accessToken));
      setSocket(socketConnectPertnerGps(userData.accessToken));
    }
    if (socket) {
      socket.emit('JOIN_ROOM', 'ADMIN_DISPATCHING');
    }
    return () => {
      socket && socket.disconnect();
    };
  }, [cities]);
  useEffect(() => {
    socket &&
      deliveryMen &&
      socket.removeAllListeners() &&
      socket.on('DELIVERYMAN_LOCATION_CHANGED', function (data) {
        if (deliveryMen.find((x) => x._id == data.deliveryManId)) {
          dispatch(
            changeDeliveryStatusAction(data.deliveryManId, data.location),
          );
        }
      });
  }, [deliveryMen?.length]);

  return (
    <div>
      {
        <SelectBoxComponent
          items={(cities || []).map((city) => ({
            value: city._id,
            label: city.name,
          }))}
          inputLabel="cities"
          onValueChange={(item) => onChangeSelectBox(item)}
          value={selectedCity}
        />
      }
      <Map path={path} zoom={zoom}>
        {(deliveryMen || []).map((deliveryMan) => (
          <CustomMarker
            position={{
              lat: parseFloat(deliveryMan.location[0]),
              lng: parseFloat(deliveryMan.location[1]),
            }}
            icon={iconsList.deliveryManWithOrder}
            size={40}
            smallLabel={deliveryMan?.name}
          />
        ))}
      </Map>
    </div>
  );
}
