const _package = require('../../package.json')
console.log(_package.config.env);
const env = _package.config.env || 'prod'; //'prod', 'staging', 'localhost'
export const frontEndUrl = {
  Link:
    env == 'prod'
      ? 'https://dashboard.kaalix.com/'
      : env == 'staging'
        ? 'https://st.kaalix.com/'
        // ? 'http://3.145.197.127:2000/'
        : 'http://localhost:3001/',
};
