import React from 'react'

export default function ClientCardComponent({cards}) {
    const styles = {
        td: {
          verticalAlign: 'middle',
        },
        font: {
          fontWeight: 'bold',
        },
        cardTitle: {
          margin: '10px 0',
          fontWeight: 'bold',
        }
      }
    return (
        <div className="container row">
        {cards&&cards.length&&cards.map((item,index)=>(
            <div className="col-3">
            <div style={styles.cardTitle}>{'card '+Number(index+1) }</div>
            <ul className="list-group ">
              <li className="list-group-item">
                <div className="d-flex justify-content-between">
                  <span>
                  selected
                  </span>
                  <span style={styles.font}>
                    {item?.selected}
                  </span>
                </div>
              </li>
              <li className="list-group-item">
                <div className="d-flex justify-content-between">
                  <span >numCarte </span>
                  <span style={styles.font}>{item?.numCarte }</span>
                </div>
              </li>

            </ul>
          </div>
        ))
            }
        </div>
    )
}
