import React, { useEffect, useState } from 'react';
import DataTableContainerComponent from '../../../components/DataTableContainer.component';
import DataTableSortingContainer from '../../../components/DataTableSortingContainer.component';
import OrderDetailsComponent from '../components/OrderDetails.component';
import {getBalance, getApprouvedProvider, getProviderDetails} from '../service/serverAPI.service';
import moment from 'moment';
import { Box, Grid } from '@material-ui/core';
import CustomButton from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import AsyncSelect from 'react-select/async';
import SelectBoxComponent from '../../../components/SelectBox.component';
import { balanceListTypes } from '../mock';
import { changesMock } from '../../customers/mock';
import {convertIdToShortId} from '../../../helpers'

export default function BalancePage() {
  const [resultsData, setResultsData] = useState({
    total: 0,
    items: [],
    tableItems: [],
  });
  const [deliveryId, setDeliveryId] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [type, setType] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [balance, setBalance] = useState([]);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const columns = [
    {
      Header: 'createdAt',
      accessor: 'createdAtTxt',
    },
    {
      Header: 'delivery Man',
      accessor: 'deliveryManDetail.name',
    },
    {
      Header: 'Admin',
      accessor: 'Admin',
    },
    {
      Header: 'order Id',
      accessor: 'order',
    },
    {
      Header: 'balanceBefore',
      accessor: 'balanceBefore',
    },
    {
      Header: 'amount',
      accessor: 'amount',
    },
    {
      Header: 'balanceAfter',
      accessor: 'balanceAfter',
    },
    {
      Header: 'type',
      accessor: 'type',
    },
    {
      Header: 'comment',
      accessor: 'comment',
    },
    {
      Header: 'actions',
      accessor: 'actions',
    },
  ];

  const loadBalanceList = (page, limit, id, type) => {
    setIsLoading(true);
    getBalance(page || 0, limit || 20, id, type)
      .then(({ data }) => {
        setTotal(data.total);
        setBalance(data.balances);
      })
      .catch((error) => {
        setResultsData({
          total: 0,
          items: [],
          tableItems: [],
        });
        setTotal(1);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const preparToShowBalance = (item, index) => {
    return {
      ...item,
      createdAtTxt: moment(item.createdAt).format('DD/MM hh:mm'),
      deliveryManTxt: item.delivery_detail?.name,
      Admin: item.createBy,
      order: item.orderId&&convertIdToShortId(item.orderId),
      balanceBefore: item.balanceBefore.toFixed(2),
      amount: item.amount.toFixed(2),
      balanceAfter: item.balanceAfter.toFixed(2),
      type: item.type,
      comment: item.comment,
      // invalid: item.invalid,
      Rowcolor: item.invalid ? 'red' : '',
    };
  };
  const [pageSelect, setPageSelect] = useState(0);
  const [numberOfRows, setNumberOfRows] = useState(0);
  const handlePaginate = (val) => {
    setResultsData({
      total: 0,
      items: [],
      tableItems: [],
    });
    loadBalanceList(val.pageSelect, val.numberOfRows, deliveryId, type);
    setPage(val.pageSelect);
    setRowsPerPage(val.numberOfRows);
  };
  const fetchData = (inputValue, callback) => {
    clearTimeout(timer1);
    timer1 = setTimeout(() => {
      inputValue &&
        getProviderDetails(0, 10, '', inputValue)
          .then(({ data }) => {
            // console.log(data);
            const tempArray = [];
            if (data.deliveryMan) {
              if (data.deliveryMan.length) {
                data.deliveryMan.forEach((element) => {
                  tempArray.push({
                    label: `${element.name}`,
                    _id: element._id,
                  });
                });
              }
            }
            callback(tempArray);
          })
          .catch((error) => {
            console.log(error);
          });
    }, 2000);
  };
  const onItemCreated = (newData) => {
    if (newData) {
      newData = newData.user;
      let data = resultsData.items;
      data = [newData, ...data];
      setResultsData({
        total: resultsData.total + 1,
        items: data,
        tableItems: [
          [newData._id, newData.username, newData.email],
          ...resultsData.tableItems,
        ],
      });
    }
  };
  const onItemUpdated = async (data) => {
    const index = data.index;
    data = data.newData;
    let items = resultsData.items;
    items[index].email = data.email;
    items[index].username = data.username;
    let tableItems = resultsData.tableItems;
    tableItems[index] = [data._id, data.username, data.email];
    setResultsData({
      ...resultsData,
      items: items,
      tableItems: tableItems,
    });
  };
  const onItemDeleted = async ({ index }) => {
    // console.log(index)
    // const items =  resultsData.items;
    // items.splice(index, 1);
    // let tableItems = resultsData.tableItems;
    // tableItems.splice(index, 1);
    // setResultsData({
    //   ...resultsData,
    //   items: items,
    //   tableItems: tableItems,
    // });
  };
  const onSearchChange = (selectedOption, whoChange) => {
    page > 0 && setPage(0);
    switch (whoChange) {
      case changesMock.changeId:
        if (selectedOption) {
          setDeliveryId(selectedOption._id);
          loadBalanceList(
            page > 0 ? 0 : page,
            rowsPerPage,
            selectedOption._id,
            type,
          );
        }
        break;
      case changesMock.changeType:
        if (selectedOption) {
          setType(selectedOption == balanceListTypes[0] ? '' : selectedOption);
          loadBalanceList(
            page > 0 ? 0 : page,
            rowsPerPage,
            deliveryId,
            selectedOption == balanceListTypes[0] ? '' : selectedOption,
          );
        }
        break;

      default:
        break;
    }
  };
  let timer1;
  const SearchComponent = () => {
    return (
      <Grid container align="center" alignItems="center" spacing={2}>
        <Grid item xs={5}>
          <AsyncSelect
            single
            value={inputValue}
            loadOptions={fetchData}
            placeholder="find deliveryMan"
            onChange={(e) => {
              onSearchChange(e, changesMock.changeId);
            }}
          />
        </Grid>
        <Grid item xs={5} className="pb-3">
          <SelectBoxComponent
            items={balanceListTypes.map((type) => ({
              value: type,
              label: type,
            }))}
            inputLabel="Type"
            onValueChange={(item) =>
              onSearchChange(item, changesMock.changeType)
            }
            value={type}
          />
        </Grid>
        <Grid item xs={2} container justify="flex-end">
          <Box textAlign="left">
            <CustomButton
              round
              type="submit"
              color="google"
              size="sm"
              loaderColor="white"
              onClick={() => {
                setDeliveryId('');
                setType('');
                page > 0 && setPage(0);
                loadBalanceList(page > 0 ? 0 : page, rowsPerPage, '', '');
              }}
            >
              clear
            </CustomButton>
          </Box>
        </Grid>
      </Grid>
    );
  };
  const deleteAdmin = (id) => {
    // return deleteDispatchingMessage(id);
  };
  // useEffect(() => {
  //   loadBalanceList()
  // },[])
  useEffect(() => {
    balance &&
      balance.length > 0 &&
      setResultsData({
        items: balance.map((item, index) => {
          return preparToShowBalance(item, index);
        }),
      });
  }, [balance]);
  return (
    <>
      <DataTableSortingContainer
        resultsData={resultsData}
        initData={resultsData.items}
        total={total}
        page={page + 1}
        columns={columns}
        title="Balance"
        isLoading={isLoading}
        isPaginate={true}
        onPaginate={handlePaginate}
        actionButtons={{
          view: {
            color: 'info',
            type: 'view',
            // props: { services: services, cities: cities },
            component: OrderDetailsComponent,
          },
        }}
        // loadItemsList={loadBalanceList}
        // customColumnStyle={customColumnStyle}
        searchInp={true}
        SearchComponent={SearchComponent}
      />
    </>
  );
}
