import {
    GET_ALLPARTNER_ORDER_REQUEST, GET_ALLPARTNER_ORDER_SUCCESS, GET_ALLPARTNER_ORDER_FAIL, ALLPARTNER_CHANGE_DELIVERIE_STATUS, ALLPARTNER_NEW_DELIVERIE, ALLPARTNER_UPDATED_DELIVERIE, ALLPARTNER_ASSIGN_DELIVERIE, ALLPARTNER_COMPLETED_DELIVERIE, ALLPARTNER_CANCEL_DELIVERIE, ALLPARTNER_INITIAL_STATE, ALLPARTNER_CHANGE_DELIVERY_ALARMS
} from '../constants/order.constants'

export const getAllPartnerOrderRequestAction = (cities, warehouses) => ({
    type: GET_ALLPARTNER_ORDER_REQUEST,
    cities, warehouses
})
export const getAllPartnerOrderSuccessAction = (deliveries, cities, warehouses) => ({
    type: GET_ALLPARTNER_ORDER_SUCCESS,
    deliveries,
    cities, warehouses
})
export const getAllPartnerOrderFailAction = (error) => ({
    type: GET_ALLPARTNER_ORDER_FAIL,
    error
})
export const AllPartnerDeliverieStatusChangeAction = (deliverie) => ({
    type: ALLPARTNER_CHANGE_DELIVERIE_STATUS,
    deliverie,
})
export const AllPartnerNewDeliverieAction = (deliverie) => ({
    type: ALLPARTNER_NEW_DELIVERIE,
    deliverie,
})
export const AllPartnerAssignDeliverieAction = (deliverie) => ({
    type: ALLPARTNER_ASSIGN_DELIVERIE,
    deliverie,
})
export const AllPartnerCompletedDeliverieAction = (deliverieId) => ({
    type: ALLPARTNER_COMPLETED_DELIVERIE,
    deliverieId,
})
export const AllPartnerCancelDeliverieAction = (deliverieId) => ({
    type: ALLPARTNER_CANCEL_DELIVERIE,
    deliverieId
})
export const AllPartnerUpdatedDeliverieAction = (deliverie) => ({
    type: ALLPARTNER_UPDATED_DELIVERIE,
    deliverie,
})

export const AllPartnerInitialPartnerOrderAction = () => ({
    type: ALLPARTNER_INITIAL_STATE
})
export const AllPartnerChangeDeliveryAlarmAction = (id, alarms) => ({
    type: ALLPARTNER_CHANGE_DELIVERY_ALARMS,
    id,
    alarms
})