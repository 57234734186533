import React,{ useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import TabRatingList from './TabRatingList.component'


export default function RatingListComponent({ setting }) {

    return (
        <div>
        <TabRatingList />
        </div>
    )
}

RatingListComponent.propTypes = {
    setting: PropTypes.any.isRequired,
}
