import axios from 'axios';
import {MICROSERVICE_BASE_URL} from '../../../config/backendServer.config';


export function getDeliveryManInCity(cityId) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.DISPATCHING}/delivery-man/city/${cityId}`,
  );
}
export function getStoresInCity(cityId) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.CONTENT}/city/${cityId}/stores`,
  );
}