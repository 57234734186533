import axios from 'axios';
import { MICROSERVICE_BASE_URL } from '../../../config/backendServer.config';

export function getCustmore(page, limit, approved, txt) {
    return axios.get(`${MICROSERVICE_BASE_URL.CUSTOMER}?page=${page || 0}&limit=${limit || 100}&paged=true&approved=${approved}&searchText=${txt || ''}`);
}
export function approuvedCustmore(customerId, body) {
    return axios.put(`${MICROSERVICE_BASE_URL.CUSTOMER}/${customerId}/approve`, { ...body });
}
export function searchCustmore(page, limit, txt) {
    return axios.get(`${MICROSERVICE_BASE_URL.CUSTOMER}?page=${page || 0}&limit=${limit || 15}&paged=true&&searchText=${txt}`);
}
export function editKaalixPay(body) {
    return axios.post(`${MICROSERVICE_BASE_URL.CUSTOMER_PAYMENT}/kaalix-pay-history`, { ...body });
}
export function editKaalixUp(body) {
    return axios.post(`${MICROSERVICE_BASE_URL.CUSTOMER_PAYMENT}/kaalixup`, { ...body });
}
export function getKaalixPayHistory(page, limit, customerId, type) {
    return axios.get(`${MICROSERVICE_BASE_URL.CUSTOMER_PAYMENT}/kaalix-pay-history?page=${page || 0}&limit=${limit || 15}&paged=true&customerId=${customerId || ''}&type=${type || ''}`);
}
export function getKaalixUpHistory(page, limit, customerId, type) {
    return axios.get(`${MICROSERVICE_BASE_URL.CUSTOMER_PAYMENT}/kaalixup/all_rewards?page=${page || 0}&limit=${limit || 15}&paged=true&customerId=${customerId || ''}&type=${type || ''}`);
}
export function getCustmorePayment(page, limit, txt) {
    return axios.get(`${MICROSERVICE_BASE_URL.CUSTOMER_PAYMENT}/payment?page=${page || 0}&limit=${limit || 15}&paged=true&search_text=${txt || ''}`);
}
export function editCutomerPhone(data) {
    return axios.put(`${MICROSERVICE_BASE_URL.CUSTOMER}/${data.customerId}/phone`, { phone: data.phone });
}
export function getCodePromo(page, limit, search_text) {
    return axios.get(`${MICROSERVICE_BASE_URL.CUSTOMER_PAYMENT}/promo?page=${page || 0}&limit=${limit || 15}&paged=true&search_text=${search_text || ''}`);
}
export function editCodePromo(_id, body) {
    return axios.put(`${MICROSERVICE_BASE_URL.CUSTOMER_PAYMENT}/promo/${_id}`, { ...body });
}
export function createCodePromo(body) {
    return axios.post(`${MICROSERVICE_BASE_URL.CUSTOMER_PAYMENT}/promo`, { ...body });
}
// for logger audit
export function logCustomer(body) {
    return axios.post(`${MICROSERVICE_BASE_URL.ADMIN}/logger`, { ...body });
}