import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { GoogleMap, LoadScript, Polygon, Marker, Polyline, InfoWindow, InfoBox } from '@react-google-maps/api';
import { FormControl, InputLabel, Select, MenuItem, TextField, Grid, Switch } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import SportsMotorsportsIcon from '@material-ui/icons/SportsMotorsports';
import GridContainer from '../../../components/material-dashboard-pro-react/Grid/GridContainer';
import Card from '../../../components/material-dashboard-pro-react/Card/Card';
import CardHeader from '../../../components/material-dashboard-pro-react/Card/CardHeader';
import CardBody from '../../../components/material-dashboard-pro-react/Card/CardBody';

import { getStoresInCity } from '../services/serverAPI.service'
import { getCities } from '../../delivery-info/services/serverApi.service';
import { getStores } from '../../stores/services/serverApi.service'
import { googleMapsApiKey } from '../../../config/backendServer.config'

import StoreIcon from '../../../assets/img/mapIcons/storePin.png'




export default function MapViewStoresPage() {
    const [stores, setStores] = useState([])
    const [storesInCity, setStoresInCity] = useState([])
    const [cities, setCities] = useState([])
    const [selectedCity, setSelectedCity] = useState({})
    const [path, setPath] = useState({})
    const [infoBoxData, setInfoBoxData] = useState({});
    // lat: 33.56633768022972, lng: -7.658855561167002 
    const [zoom, setZoom] = useState(13);

    const getCitiesFromAPI = () => {
        getCities(0, 0, false)
            .then(({ data }) => setCities(data))
            .catch((error) => console.log(error))
    }

    const getStoresInCityFromApi = (cityId, cityObj) => {
        // getStoresInCity(cityId)
        //   .then(({ data }) => {
        //     const storesArray = []
        //     // const storesArrayId = []
        //     data.length && data.map(store => {
        //       store.cityId == cityId && storesArray.push(store)
        //     })
        //     console.log('store', data)
        //     setStores(storesArray)
        //   })
        //   .catch((e) => console.log(e));

        // mapping to all stores 
        setPath({
            lat: parseFloat(cityObj?.cityLocations[0][1]),
            lng: parseFloat(cityObj?.cityLocations[0][0])
        })
        setZoom(13)
        const storesArray = []
        // const storesArrayId = []
        stores.length && stores.map(store => {
            store.cityId == cityId && storesArray.push(store)
        })
        // console.log('storesArray', storesArray)
        setStoresInCity(storesArray)
    }
    const getAllStore = () => {
        getStores(0, 0, false,false)
            .then(({ data }) => {
                // console.log(data);
                setStores(data)
            })
            .catch((e) => console.log(e));
    }
    const setInfoForInfoBox = (id) => {
        const infoObj = {};
        var storeIndex = storesInCity.findIndex(x => x._id == id)
        if (storeIndex >= 0) {
            infoObj.name = storesInCity[storeIndex]?.name
            infoObj.location = storesInCity[storeIndex]?.location
            infoObj.phone = storesInCity[storeIndex]?.phone
            setInfoBoxData({ ...infoObj })
        }
    }
    const searchSpecificStore = (txt)=>{
        storesInCity && storesInCity.length && storesInCity.map(store => {
            if (store.name.toLowerCase().startsWith(txt.toLowerCase())) {
              setPath({
                lat: parseFloat(store.location[0]),
                lng: parseFloat(store.location[1]),
              });
              setInfoForInfoBox(store?._id)
            }
          })
    }
    useEffect(() => {
        getCitiesFromAPI()
        getAllStore()
    }, [])
    // console.log(infoBoxData);
    // console.log(storesInCity);
    return (
        <div>
            <GridContainer>
                <Card>
                    <CardHeader>Stores</CardHeader>
                    <CardBody>
                        <Grid container spacing={0}>
                            <Grid item xs={3}>
                                <FormControl style={{ minWidth: '180px' }}>
                                    <InputLabel>Cities</InputLabel>
                                    <Select
                                        value={selectedCity._id}
                                        onChange={($event) => {
                                            // console.log($event.target.value);
                                            const citySelected = cities.find(e => e._id == $event.target.value)
                                            //   console.log(citySelected);
                                            setSelectedCity(citySelected);
                                            //   getDeliveryManList($event.target.value)
                                            getStoresInCityFromApi($event.target.value, citySelected)
                                        }}
                                    >
                                        {cities.map((item, index) => (
                                            <MenuItem value={item._id}>{item.name}</MenuItem>
                                        ))}
                                    </Select>

                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                            <Autocomplete
                    id="combo-box-demo"
                    options={storesInCity || []}
                    // defaultValue={[stores[3]]}
                    getOptionLabel={option => option.name}
                    // style={{ marginTop: '10%' }}
                    // disableUnderline={true}
                    // variant="outlined"
                    onChange={(e, newValue) => {
                      // console.log('chnage',e.target.value);
                      // console.log('chnage',newValue?.name);
                      newValue?.name !== '' && newValue?.name !== undefined && searchSpecificStore(newValue?.name)
                    }}
                    renderInput={(specificSelect) => <TextField {...specificSelect} label="find store" variant="outlined"
                      // onChange={(e)=>setText(e.target.value)}
                      onChange={(e) => e.target.value !== '' && searchSpecificStore(e.target.value)}

                    />
                }
                />
                            </Grid>
                        </Grid>
                    </CardBody>
                </Card>
            </GridContainer>
            <LoadScript
                id="script-loader"
                googleMapsApiKey={googleMapsApiKey}
                language="en"
                region="en"
            >
                <GoogleMap
                    mapContainerClassName="map"
                    center={
                        // selectedCity ? { lat: selectedCity.cityLocations[0][1], lng: selectedCity.cityLocations[0][0] } : { lat: 33.589886, lng: -7.603869 }
                        Object.keys(path).length ? path : { lat: 33.56633768022972, lng: -7.658855561167002 }
                        // : { lat: 33.589886, lng: -7.603869 }
                    }
                    zoom={zoom}
                    version="weekly"
                    on
                // onDblClick={ondblclick}
                >
                    {storesInCity && storesInCity.length && storesInCity.map(store => (
                        <Marker
                            key={store?._id}
                            icon={{
                                url: StoreIcon,
                                // url: orders.find(e => e.deliveryMan?._id == delivery._id) ? DeliveryManIcon : DeliveryManWithoutOrderIcon,
                                // anchor: new google.maps.Point(17, 46),
                                scaledSize: new google.maps.Size(50, 50)

                            }}
                            position={{ lat: parseFloat(store.location ? store.location[0] : ''), lng: parseFloat(store.location ? store.location[1] : "") }}
                            // onMouseOver={() => drawLine(store?._id)}
                            // onMouseOut={() => setPolylineDrawer([])}
                            onClick={() => setInfoForInfoBox(store?._id)}
                        // onDblClick={() => setPolylineForSpecificOrder([])}
                        />
                    ))


                    }
                    {infoBoxData && Object.keys(infoBoxData).length !== 0 &&
                        <InfoBox
                            position={{ lat: parseFloat(infoBoxData.location[0]), lng: parseFloat(infoBoxData.location[1]) }}
                        // options={{ closeBoxURL: '', enableEventPropagation: true }}

                        >
                            <div style={{ backgroundColor: 'white', opacity: 1, padding: 12, borderRadius: "10px" }} onClick={() => setInfoBoxData({})}>
                                <div style={{ fontSize: 16, fontColor: `#08233B`, }}>
                                    {infoBoxData.name}<br />
                                    {infoBoxData.phone}<br />

                                </div>
                            </div>

                        </InfoBox  >
                    }
                </GoogleMap>
            </LoadScript>


        </div>
    )
}
