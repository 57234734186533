import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

// Actions
import {
  getPartnerOrderRequestAction,
  initialPartnerOrderAction,
  deliverieStatusChangeAction,
  newDeliverieAction,
  completedDeliverieAction,
  cancelDeliverieAction,
  AssignDeliverieAction,
  updatedDeliverieAction,
  changeDeliveryAlarmAction,
} from '../redux/actions/order.actions';
import { openModelAction } from '../../orders/redux/actions/order.actions';
import MyLocationIcon from '@mui/icons-material/MyLocation';

// Selector
import {
  getPartnerDeliveriesDataState,
  filterSelector,
} from '../redux/selectors';
import { getUserDataState } from '../../auth/redux/selectors';
// component
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import { TableCell, TableRow, Grid } from '@mui/material';

// icons
import LocationOn from '@material-ui/icons/LocationOn';
import MotorcycleIcon from '@material-ui/icons/Motorcycle';
import CloseleIcon from '@material-ui/icons/Close';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import AssessmentIcon from '@mui/icons-material/Assessment';
import CommentIcon from '@mui/icons-material/Comment';
import BlockIcon from '@mui/icons-material/Block';

import DataTableSortingContainer from '../../../components/DataTableSortingContainer.component';
import ChangeDuration from '../../orders/components/ChangeDuration.components';
import ModalComponentsDelivries from '../components/Modal.components.delivries';
import DelivriesFilterComponent from '../components/Delivries.filter.component';
import DeliveryMenInfoComponent from '../components/DeliveryMen.info.component';
import DeliveryMenTableComponent from '../components/DeliveryMen.table.component';
import AlarmsInfoComponenet from '../components/Alarms.info.componenet';
import DispatchingInfoComponenet from '../components/Dispatching.info.componenet';
import CommentComponent from '../components/Comment.delivery.component';
import AvatarGroup from 'react-avatar-group';

// mock
import {
  columns,
  columnsDispatched,
  partnerStatusBackgroundColor,
  deliveryStatusBackgroundColor,
} from '../mock';
import { statusBackgroundColor } from '../../orders/mock';
import {
  deliveryStatus,
  orderDispatchingStatus,
  StoreStatus,
} from '../../../config/status.config';
import { switchForAvatarColor } from '../../orders/mock';
// api
import {
  socketConnectPertner,
  socketConnect,
} from '../services/server.api.service';
// helpers
import { renderStatus } from '../helper/index';
import { styled } from '@mui/material/styles';

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip arrow {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));
export default function CurrentPartnerOrdersPage() {
  const dispatch = useDispatch();
  const { deliveries, loading, error, deliveriesChangeCounter } = useSelector(
    getPartnerDeliveriesDataState,
  );
  const [filterBy, setFilterBy] = useState(null);
  const [alertPosition, setAlertPosition] = useState(null);
  const [selectedAlarm, setSelectedAlarm] = useState({});
  const [selectedDispatchingStatus, setSelectedDispatchingStatus] = useState(
    {},
  );
  const [selectedOrders, setSelectedOrders] = useState({});

  const {
    selectedCities,
    filterChangeCounter,
    filterWarehouseChangeCounter,
    selectedWarehouse,
  } = useSelector(filterSelector);
  const userData = useSelector(getUserDataState);
  const [resultsData, setResultsData] = useState({
    total: 0,
    items: [],
  });
  const [socket, setSocket] = useState(null);
  const iconsSharedStyle = {
    cursor: 'pointer',
    width: 21,
    height: 21,
    margin: 2,
    borderRadius: 2,
    padding: 2,
  };
  const sharedStyleForStatus = {
    width: '120px',
    fontSize: '11px',
    padding: '5px',
    borderRadius: '4px',
    color: 'white',
  };
  const rowColor = (state, OrdeNbr, blocked, rupture) => {
    if (blocked) {
      return '#A4D0A4';
    }
    if (rupture) {
      return '#0A4D68';
    }
    if (state && state === 'STATUS_ERROR_PAYMENT') {
      return '#BF40BF';
    } else if (OrdeNbr > 1) {
      return 'red';
    }
  };
  const showOrders = (item, index, OrdeNbr) => {
    if (item) {
      return {
        ...item,
        cityName: item?.cityName?.substring(0, 4) || item?.cityId,
        deliveryManStatusInp: item?.deliveryManStatus,

        warehouseName:
          item?.pickupAddress?.libeleMagasin || item?.organisationId,
        clientName: item?.destinationAddress?.contactPerson,
        deliveryManInp: item?.deliveryMan && item?.deliveryMan.name,
        createdAtInp:
          item.createdAt && moment(item.createdAt).format('hh:mm:ss'),

        duration: item?.deliveryManStatusTimes && (
          <>
            <ChangeDuration
              deliveryManStatusTime={
                item?.deliveryManStatusTimes &&
                item?.deliveryManStatusTimes.length &&
                item?.deliveryManStatusTimes[
                  item?.deliveryManStatusTimes.length - 1
                ]?.date
              }
              time={item.createdAt}
            />
          </>
        ),
        orderPriceCol: item?.payment?.price,
        // && item?.payment?.price?.toFixed(2)
        paymentMode: (
          <div style={{ textAlign: 'center' }}>
            {item?.payment?.method}
            <p style={{ color: 'red' }}>
              {item?.isPicking && item?.EstimatedTime + 'min'}
            </p>
          </div>
        ),
        menu: (
          <div>
            <Tooltip arrow title="Detail Commande" placement="top">
              <AspectRatioIcon
                style={{
                  ...iconsSharedStyle,
                  color: '#0705f7',
                  backgroundColor: '#0705f730',
                }}
                onClick={() => {
                  dispatch(openModelAction(item._id, index, 'detail'));
                }}
              />
            </Tooltip>
            <Tooltip arrow title="Assigner Commande" placement="top">
              <MotorcycleIcon
                style={{
                  ...iconsSharedStyle,
                  color: '#1fd62e',
                  backgroundColor: '#1fd62e30',
                }}
                onClick={() => {
                  dispatch(openModelAction(item._id, index, 'Assign'));
                }}
              />
            </Tooltip>
            <Tooltip arrow title="Annuler la commande" placement="top">
              <CloseleIcon
                style={{
                  ...iconsSharedStyle,
                  color: '#ff0000',
                  backgroundColor: '#ff000030',
                }}
                onClick={() => {
                  dispatch(openModelAction(item._id, index, 'Cancel'));
                }}
              />
            </Tooltip>
            <Tooltip arrow title="Aide a la decision" placement="top">
              <LocationOn
                style={{
                  ...iconsSharedStyle,
                  color: '#000000',
                  backgroundColor: '#00000030',
                }}
                onClick={() => {
                  dispatch(openModelAction(item._id, index, 'Map'));
                }}
              />
            </Tooltip>
            <Tooltip arrow title="Changer Status Livreur" placement="bottom">
              <SyncAltIcon
                style={{
                  ...iconsSharedStyle,
                  color: '#000000',
                  backgroundColor: '#00000030',
                }}
                onClick={() => {
                  dispatch(openModelAction(item._id, index, 'changeStatus'));
                }}
              />
            </Tooltip>
            <Tooltip arrow title="report" placement="bottom">
              <AssessmentIcon
                style={{
                  ...iconsSharedStyle,
                  color: '#000000',
                  backgroundColor: '#00000030',
                }}
                onClick={() => {
                  dispatch(openModelAction(item._id, index, 'report'));
                }}
              />
            </Tooltip>
            <Tooltip arrow title="position livreur" placement="top">
              <MyLocationIcon
                style={{
                  ...iconsSharedStyle,
                  color: '#000000',
                  backgroundColor: '#00000030',
                }}
                onClick={() => {
                  dispatch(openModelAction(item._id, index, 'currentPosition'));
                }}
              />
            </Tooltip>
            <Tooltip arrow title="position livreur" placement="top">
              <MyLocationIcon
                style={{
                  ...iconsSharedStyle,
                  color: 'red',
                  backgroundColor: '#00000030',
                }}
                onClick={() => {
                  dispatch(openModelAction(item._id, index, 'my location'));
                }}
              />
            </Tooltip>
            {item?.blocked && (
              <Tooltip arrow title="Blocked CMD" placement="bottom">
                <BlockIcon
                  style={{
                    ...iconsSharedStyle,
                    color: '#735392',
                    backgroundColor: '#00000030',
                  }}
                  onClick={() => {
                    // handleOpenModal('blocked');
                    dispatch(openModelAction(item._id, index, 'blocked'));
                  }}
                />
              </Tooltip>
            )}
          </div>
        ),
        statusPartner: (
          <div>
            {item?.deliveryStateTimes &&
              item?.deliveryStateTimes.length > 0 && (
                <HtmlTooltip
                  title={
                    <React.Fragment>
                      {item?.deliveryStateTimes.map((r) => (
                        <TableRow>
                          <TableCell align="center">{r.status}</TableCell>
                          <TableCell align="center">
                            {moment(r.date).format('hh:mm:ss')}
                          </TableCell>
                        </TableRow>
                      ))}
                    </React.Fragment>
                  }
                >
                  <span
                    style={{
                      backgroundColor:
                        partnerStatusBackgroundColor[
                          item?.deliveryStateTimes[
                            item?.deliveryStateTimes.length - 1
                          ]['status']
                        ],
                      width: '100px',
                      fontSize: '11px',
                      padding: '5px',
                      borderRadius: '4px',
                      color: 'white',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {/*{item.deliveryStateTimes}*/}
                    {/*{JSON.stringify(item.deliveryStateTimes)}*/}
                    {
                      item?.deliveryStateTimes[
                        item?.deliveryStateTimes.length - 1
                      ]['status']
                    }
                    {/*{*/}
                    {/*  partnerStatusBackgroundColor[item?.deliveryStateTimes[*/}
                    {/*  item?.deliveryStateTimes.length - 1*/}
                    {/*      ]['status']]*/}
                    {/*}*/}
                  </span>
                </HtmlTooltip>
              )}
          </div>
        ),
        allStatus: (
          <HtmlTooltip
            title={
              <React.Fragment>
                <TableRow>
                  <TableCell align="center">dispatching</TableCell>
                  <TableCell align="center">
                    <span
                      style={{
                        backgroundColor:
                          item.dispatchingStatus == 'ON_HOLD'
                            ? statusBackgroundColor.ON_HOLD
                            : statusBackgroundColor.INVALID,
                        ...sharedStyleForStatus,
                      }}
                    >
                      {orderDispatchingStatus[item.dispatchingStatus] ||
                        item.dispatchingStatus}
                    </span>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">deliveryMan</TableCell>
                  <TableCell align="center">
                    <span
                      style={{
                        backgroundColor:
                          deliveryStatusBackgroundColor[
                            item.deliveryManStatus
                          ] || '#ff9b3b',
                        ...sharedStyleForStatus,
                      }}
                    >
                      {/*{*/}
                      {/*  item.deliveryManStatusTimes[*/}
                      {/*    item.deliveryManStatusTimes.length - 1*/}
                      {/*  ]*/}
                      {/*}*/}
                      {JSON.stringify(
                        item.deliveryManStatusTimes[
                          item.deliveryManStatusTimes.length - 1
                        ]['status'],
                      )}
                    </span>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">delivery</TableCell>
                  <TableCell align="center">
                    <span
                      style={{
                        backgroundColor:
                          deliveryStatusBackgroundColor[item.deliveryStatus] ||
                          '#ccc',
                        ...sharedStyleForStatus,
                      }}
                    >
                      {StoreStatus[item.storeStatus] || item?.deliveryStatus}
                    </span>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">Partner</TableCell>
                  <TableCell align="center">
                    <span
                      style={{
                        backgroundColor:
                          partnerStatusBackgroundColor[
                            item?.orderData?.state
                          ] || '#ccc',
                        ...sharedStyleForStatus,
                      }}
                    >
                      {item?.orderData?.state}
                    </span>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            }
          >
            {renderStatus(
              item.dispatchingStatus,
              item.deliveryManStatus,
              item.deliveryManStatusTimes,
            )}
          </HtmlTooltip>
        ),
        lastComment: (
          <HtmlTooltip
            title={
              <React.Fragment>
                <CommentComponent close={() => {}} deliveryId={item._id} />
              </React.Fragment>
            }
          >
            {item?.comments && item.comments.length ? (
              <span
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  dispatch(openModelAction(item._id, index, 'comment'));
                }}
              >
                {' '}
                {item.comments[item.comments.length - 1]?.adminName}
                {' // '}
                {item.comments[item.comments.length - 1]?.comment}{' '}
              </span>
            ) : (
              <CommentIcon
                style={{ cursor: 'pointer', fontSize: '22px' }}
                onClick={() => {
                  dispatch(openModelAction(item._id, index, 'comment'));
                }}
              />
            )}
          </HtmlTooltip>
        ),
        DeliveryManStatusCell: item?.deliveryManStatus,
        alarmCell: item.alarms && item.alarms.length > 0 && (
          <div>
            <Grid container spacing={1}>
              {item.alarms.map(
                (alarm) =>
                  alarm?.visible && (
                    <Grid
                      item
                      xs={4}
                      onClick={() => {
                        dispatch(openModelAction(item._id, index, 'Alarm'));
                      }}
                    >
                      <AvatarGroup
                        avatars={[alarm?.name.split('_').join(' ')]}
                        initialCharacters={alarm?.name.split('_').length}
                        max={3}
                        size={25}
                        displayAllOnHover
                        shadow={2}
                        fontSize={0.4}
                        backgroundColor={switchForAvatarColor(alarm?.status)}
                      />
                    </Grid>
                  ),
              )}
            </Grid>
          </div>
        ),
        Rowcolor: rowColor(
          item?.orderData?.state,
          OrdeNbr,
          item?.blocked,
          item?.rupture,
        ),
      };
    }
  };
  const setOrderToState = async (data) => {
    const newDataArray = [...data];
    const customerIdObject = {};
    newDataArray.forEach((item) => {
      customerIdObject[item?.destinationAddress?.contactPhone] =
        (customerIdObject[item?.destinationAddress?.contactPhone] || 0) + 1;
    });
    newDataArray &&
      setResultsData({
        items: await newDataArray.map((item, index) => {
          return showOrders(
            item,
            index,
            customerIdObject[item?.destinationAddress?.contactPhone],
          );
        }),
      });
  };
  const displayOrderUsingFilter = async () => {
    let allOrders = deliveries;
    let arrayOfWarehouse;
    switch (filterBy) {
      case 'deliveryManStatus':
        if (
          selectedOrders &&
          Object.keys(selectedOrders) &&
          Object.keys(selectedOrders).length > 0
        ) {
          arrayOfWarehouse = Object.keys(selectedOrders);
          allOrders = await allOrders.filter(
            (order) =>
              arrayOfWarehouse.includes(order?.wareHouse?.wareHouseLabel) &&
              selectedOrders[order?.wareHouse?.wareHouseLabel].includes(
                order.deliveryManStatus,
              ),
          );
        }
        break;
      case 'dispatchingStatus':
        if (
          selectedDispatchingStatus &&
          Object.keys(selectedDispatchingStatus) &&
          Object.keys(selectedDispatchingStatus).length > 0
        ) {
          arrayOfWarehouse = Object.keys(selectedDispatchingStatus);
          allOrders = await allOrders.filter(
            (order) =>
              arrayOfWarehouse.includes(order?.wareHouse?.wareHouseLabel) &&
              selectedDispatchingStatus[
                order?.wareHouse?.wareHouseLabel
              ].includes(order.dispatchingStatus),
          );
        }
        break;
      case 'alarms':
        if (
          selectedAlarm &&
          Object.keys(selectedAlarm) &&
          Object.keys(selectedAlarm).length > 0
        ) {
          arrayOfWarehouse = Object.keys(selectedAlarm);
          allOrders = await allOrders.filter(
            (order) =>
              order.alarms &&
              order?.alarms.length > 0 &&
              arrayOfWarehouse.includes(order.cityId) &&
              order.alarms.find(
                (x) =>
                  x.visible &&
                  (selectedAlarm[order?.cityId] || []).find(
                    (y) => y.type == x.name && y.status == x.status,
                  ),
              ),
          );
        }
        break;

      default:
        break;
    }

    setOrderToState(allOrders && allOrders.length > 0 ? allOrders : deliveries);
  };

  useEffect(() => {
    dispatch(initialPartnerOrderAction());
  }, []);
  useEffect(() => {
    selectedCities &&
      selectedCities.length > 0 &&
      dispatch(getPartnerOrderRequestAction(selectedCities, selectedWarehouse));
  }, [filterChangeCounter, filterWarehouseChangeCounter]);
  // useEffect(() => {
  //   !loading && setOrderToState(deliveries || []);
  // }, [deliveriesChangeCounter]);
  useEffect(() => {
    !loading && deliveries && displayOrderUsingFilter();
  }, [
    deliveriesChangeCounter,
    selectedAlarm,
    selectedOrders,
    // displayType,
    selectedDispatchingStatus,
  ]);
  useEffect(() => {
    if (!socket) {
      // setSocket(socketConnectPertner(userData.accessToken)); //old socket partner GPS + step
      setSocket(socketConnect(userData.accessToken)); // socket etapes Food + partner
    }
    if (socket) {
      socket.emit('JOIN_ROOM', 'ADMIN_DISPATCHING_PARTNER');
    }
  }, [selectedCities]);
  const socketEvents = () => {
    socket.on('DELIVERY_STATUS_CHANGED', function (data) {
      if (
        (selectedCities.includes('all') ||
          selectedCities.includes(data?.cityId)) &&
        (selectedWarehouse.length > 0
          ? selectedWarehouse.includes(data?.pickupAddress.libeleMagasin)
          : true)
      ) {
        // console.log(data.shortId);
        dispatch(deliverieStatusChangeAction(data));
      }
    });
    socket.on('DELIVERY_CREATED', function (data) {
      if (
        (selectedCities.includes('all') ||
          selectedCities.includes(data?.cityId)) &&
        (selectedWarehouse.length > 0
          ? selectedWarehouse.includes(data?.pickupAddress.libeleMagasin)
          : true)
      )
        dispatch(newDeliverieAction(data));
    });
    socket.on('DELIVERY_DISPATCHED_TO_DELIVERYMAN', function (data) {
      if (
        (selectedCities.includes('all') ||
          selectedCities.includes(data?.cityId)) &&
        (selectedWarehouse.length > 0
          ? selectedWarehouse.includes(data?.pickupAddress.libeleMagasin)
          : true)
      )
        dispatch(AssignDeliverieAction(data));
    });
    socket.on('DELIVERY_ASSIGNED_TO_DELIVERYMAN', function (data) {
      if (
        (selectedCities.includes('all') ||
          selectedCities.includes(data?.cityId)) &&
        (selectedWarehouse.length > 0
          ? selectedWarehouse.includes(data?.pickupAddress.libeleMagasin)
          : true)
      )
        dispatch(AssignDeliverieAction(data));
    });
    socket.on('DELIVERY_COMPLETED', function (data) {
      if (
        (selectedCities.includes('all') ||
          selectedCities.includes(data?.cityId)) &&
        (selectedWarehouse.length > 0
          ? selectedWarehouse.includes(data?.pickupAddress.libeleMagasin)
          : true)
      )
        dispatch(completedDeliverieAction(data._id));
    });
    socket.on('DELIVERY_CANCELLED', function (data) {
      if (
        (selectedCities.includes('all') ||
          selectedCities.includes(data?.cityId)) &&
        (selectedWarehouse.length > 0
          ? selectedWarehouse.includes(data?.pickupAddress.libeleMagasin)
          : true)
      )
        dispatch(cancelDeliverieAction(data._id));
    });
    socket.on('DELIVERY_UPDATED_BY_ORGANISATION', function (data) {
      if (
        (selectedCities.includes('all') ||
          selectedCities.includes(data?.cityId)) &&
        (selectedWarehouse.length > 0
          ? selectedWarehouse.includes(data?.pickupAddress.libeleMagasin)
          : true)
      )
        dispatch(updatedDeliverieAction(data));
    });
    socket.on('ALARM_UPDATED', async function (data) {
      if (
        (selectedCities.includes('all') ||
          selectedCities.includes(data?.cityId)) &&
        (selectedWarehouse.length > 0
          ? selectedWarehouse.includes(data?.pickupAddress.libeleMagasin)
          : true)
      ) {
        // dispatch(changeOrderStatusAction(data));
        // console.log('alarm socket', data);
        dispatch(changeDeliveryAlarmAction(data._id, data.alarms));
      }
      // console.log('ALARM_UPDATED', data);
      if (data.name === 'GET_POSITION') {
        if (!alertPosition) {
          setAlertPosition([data].filter((e) => e.visible));
        } else {
          let dd = alertPosition;
          const index = await dd.findIndex(
            (e) => e.deliveryManId + '' === data.deliveryManId + '',
          );
          if (index !== -1) {
            dd[index] = data;
            setAlertPosition([...dd].filter((e) => e.visible));
          } else {
            dd.push(data);
            setAlertPosition([...dd].filter((e) => e.visible));
          }
        }
      }
    });
  };

  useEffect(() => {
    if (socket) {
      socket.removeAllListeners();
      socketEvents();
    }
  }, [selectedCities, selectedWarehouse]);

  return (
    <div>
      <DelivriesFilterComponent />
      <AlarmsInfoComponenet />
      <DispatchingInfoComponenet
        setSelectedDispatchingStatus={setSelectedDispatchingStatus}
        selectedDispatchingStatus={selectedDispatchingStatus}
        filterBy={filterBy}
        setFilterBy={setFilterBy}
      />
      {socket && (
        <DeliveryMenInfoComponent
          // cities={cities}
          // setSelectedAlarm={setSelectedAlarm}
          // selectedAlarm={selectedAlarm}
          setSelectedOrders={setSelectedOrders}
          selectedOrders={selectedOrders}
          filterBy={filterBy}
          setFilterBy={setFilterBy}
          alarm={alertPosition}
        />
      )}
      {/* <DeliveryMenTableComponent
        // cities={cities}
        selectedCities={selectedCities}
        socket={socket}
      /> */}
      <div style={{ marginTop: -40 }}>
        {/* <DataTableSortingContainer
          resultsData={resultsData}
          initData={resultsData.items || []}
          columns={columns}
          title={<strong>Deliveries</strong>}
        /> */}
        <DataTableSortingContainer
          resultsData={resultsData}
          initData={(resultsData.items || []).filter(
            (x) => x.dispatchingStatus !== 'DISPATCHED',
          )}
          columns={columns}
          title={<strong>Dispatching service</strong>}
          // customColumnStyle={customColumnStyle}
        />
        <DataTableSortingContainer
          resultsData={resultsData}
          initData={(resultsData.items || []).filter(
            (x) => x.dispatchingStatus === 'DISPATCHED',
          )}
          columns={columnsDispatched}
          title={<strong>Livreur service</strong>}
          // customColumnStyle={customColumnStyle}
        />
      </div>
      <ModalComponentsDelivries />
    </div>
  );
}
