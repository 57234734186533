import React from 'react';
import { Box } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import MailIcon from '@material-ui/icons/Mail';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import * as yup from 'yup';
import { useFormik } from 'formik';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import CustomInput from '../../../components/material-dashboard-pro-react/CustomInput/CustomInput';
import { grayColor } from '../../../assets/jss/material-dashboard-pro-react';
import { loginApi } from '../services/serverApi.service';
import AuthPage from '../pages/Auth.page';
import useAuth from '../hooks/useAuth.hook';
import { setUserDataAction } from '../redux/actions/auth.action';

const validationSchema = yup.object({
  email: yup.string().email().required(),
  password: yup.string().required(),
});

export default function LoginFormComponent() {
  const {
    isLoading,
    setIsLoading,
    classes,
    responseMessage,
    setResponseMessage,
  } = useAuth();
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
  });

  function onSubmit(e) {
    e.preventDefault();
    setIsLoading(true);
    setResponseMessage(null);
    loginApi(formik.values)
      .then(({ data }) => {
        // console.log(data);
        // console.log(data);
        dispatch(setUserDataAction(data));
        localStorage.setItem('lastConnection', new Date().toISOString());
        window.location.reload();
      })
      .catch((error) => {
        setResponseMessage({
          message: error.message,
          isError: true,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <AuthPage responseMessage={responseMessage} onSubmit={onSubmit}>
      <CustomInput
        labelText="Email"
        id="email"
        formControlProps={{
          fullWidth: true,
        }}
        type="email"
        error={formik.touched.email && Boolean(formik.errors.email)}
        inputProps={{
          value: formik.values.email,
          onBlur: formik.handleBlur('email'),
          onChange: formik.handleChange('email'),
          endAdornment: (
            <InputAdornment position="end">
              <MailIcon style={{ color: grayColor[2] }} />
            </InputAdornment>
          ),
        }}
      />
      <CustomInput
        labelText="Mot de passe"
        id="password"
        formControlProps={{
          fullWidth: true,
        }}
        error={formik.touched.password && Boolean(formik.errors.password)}
        inputProps={{
          value: formik.values.password,
          onBlur: formik.handleBlur('password'),
          onChange: formik.handleChange('password'),
          type: 'password',
          endAdornment: (
            <InputAdornment position="end">
              <LockOpenIcon style={{ color: grayColor[2] }} />
            </InputAdornment>
          ),
        }}
      />

      <Box textAlign="center">
        <Button
          className={classes.loginBtn}
          round
          type="submit"
          color="success"
          disabled={!formik.dirty || !formik.isValid}
          loading={isLoading}
          loaderColor="white"
        >
          LOGIN
        </Button>
      </Box>

      {/* <Typography
        ariant="caption"
        display="block"
        align="center"
        gutterBottom
        color="textSecondary"
      >
        Vous n'avez pas de compte ? <Link to="/register">S'inscrire</Link>
      </Typography> */}
    </AuthPage>
  );
}
