import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useAlert } from 'react-alert';
import { deleteServiceCategory } from '../services/serverApi.service'
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';


export default function DeleteSetviceCategoryComponent({ data, close,onItemEdited }) {
    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();

    const deleteItem = () => {
        setIsLoading(true);
        deleteServiceCategory(data._id)
            .then(() => {
                alert.show('Service has been deleted', {
                    type: 'success',
                });
                onItemEdited();
                close()
            })
            .catch((error) => {
                alert.show(error.message, {
                    type: 'error',
                });
            })
            .finally(() => {
                setIsLoading(false);
            });

    }
    return (
        <div>
            <div>Are you sure do delete this item ?</div>
            {data && data?.name}
            <br/>
            <div style={{float: 'right',margin:'15px'}}>
                <Button onClick={close}>Cancel</Button >
                <Button onClick={deleteItem} color="success" loading={isLoading} disabled={isLoading}>
                    Yes
                </Button>
            </div>


        </div>
    )
}
DeleteSetviceCategoryComponent.propTypes = {
    data: PropTypes.any,
    close: PropTypes.any,
    onItemEdited: PropTypes.func.isRequired,
}
