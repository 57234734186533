import React, { useState, useEffect } from 'react'
import * as yup from 'yup';
import { useFormik } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import { FormLabel, Box } from '@material-ui/core';
import CustomInput from '../../../components/material-dashboard-pro-react/CustomInput/CustomInput'
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button'
import { updateStore } from '../services/serverApi.service';
import styles from '../../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle';

const useStyles = makeStyles(styles);

const validationSchema = yup.object({
  contact: yup.string().required(),
})

export default function StoreContactComponent({ store, index, stores, setStores, close }) {
  const [isLoading, setIsLoading] = useState(false)
  const classes = useStyles();
  const formik = useFormik({
    initialValues: {
      contact: ''
    },
    validationSchema,
  });
  const editStore = () => {
    store.contact = formik.values.contact
    setIsLoading(true)
    updateStore(store._id, store)
      .then(({ data }) => {
        console.log(data);
        let newStores = [...stores]
        newStores[index] = data.store
        setStores(newStores)
        close()
      })
      .catch((error) => {
        console.log(error.message, {
          type: 'error',
        });
      })
      .finally(() => setIsLoading(true))
  }
  const setValues = () => {
    formik.setValues({
      contact: store.contact,
    })
  }

  useEffect(() => {
    setValues()
  }, [])
  return (
    <div>
      <FormLabel className={classes.labelHorizontal}>
        store contact
      </FormLabel>
      <CustomInput
        formControlProps={{
          fullWidth: true,
        }}
        type="text"
        error={
          formik.touched.contact && Boolean(formik.errors.contact)
        }
        inputProps={{
          value: formik.values.contact,
          onBlur: formik.handleBlur('contact'),
          onChange: formik.handleChange('contact'),
        }}
      />


      <Box textAlign="center">
        <Button
          round
          type="submit"
          color="success"
          disabled={!formik.dirty || !formik.isValid || isLoading}
          loading={isLoading}
          loaderColor="white"
          onClick={editStore}
        >
          save contact
        </Button>
      </Box>
    </div>
  )
}
