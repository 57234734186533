import React, { useEffect, useState } from 'react';
import {useAlert} from 'react-alert'
import { TextField, Box } from '@material-ui/core';
import DataTableContainerComponent from '../../../components/DataTableSortingContainer.component';
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';

import {  fetchSetting,updateSetting } from '../services/serverApi.service';
import { getCities } from '../../delivery-info/services/serverApi.service'

export default function ContactPage() {
  const alert = useAlert();
  const [cities, setCities] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const [contact,setContact] = useState([])
  const [otherContact,setOtherContact] = useState([])
  const [initialResponse,setInitialResponse] = useState([])
  const [generalContact, setGeneralContact] = useState({
    city:'',
    adminContactPhone: '',
    adminContactWhatsapp: '',
    adminContactEmail: '',
  });
  const [resultsData, setResultsData] = useState({
    total: 0,
    items: [],
    tableItems: [],
  });
  const displayAndHideElement = (id, what_to_do) => {
    setTimeout(() => {
      document.getElementById(id).style.display = what_to_do;
    }, 1000);

  }
  const saveGeneral = (data,initialResponseObj) => {
    initialResponseObj.adminContactEmail =data.adminContactEmail
    initialResponseObj.adminContactPhone =data.adminContactPhone
    initialResponseObj.adminContactWhatsapp =data.adminContactWhatsapp
    setInitialResponse(initialResponseObj);
    updateSetting(initialResponseObj._id,initialResponseObj)
    .then(({data})=>{
      alert.show('saved', {
            type: 'success',
          })
    })
    .catch(error=>{console.log(error)})
  }
  var contactData = []
  const Btn = ({i,onClickEvent,data,initialResponseObj}) =>{
    return (
      <Button
        style={{ display: "none" }} id={i}
        round
        size="sm"
        type="submit"
        color="success"
        loaderColor="white"
      // onClick={()=>city?.name=='general'?saveGeneral():saveChange(i,contact.contact)}
      onClick={()=>onClickEvent(data,initialResponseObj)}
      >
        save
      </Button>
    )
  }
  
  let OtherContactVar = []
  const loadSettingList = () => {
    cities && cities.length > 0 && fetchSetting()
      .then(({ data }) => {
        const initialResponseObj = data[0];
        const initialResponseVar = data[0];

        const setGeneralContactObj ={ 
          city:'general',
          adminContactPhone:data[0]?.adminContactPhone||'',
          adminContactEmail:data[0]?.adminContactEmail||'',
          adminContactWhatsapp:data[0]?.adminContactWhatsapp||'',
        }
        
        setContact({
          city:'general',
          number: (
            <div >
              <TextField
                // style={{ float: 'left', width: '150px' }}
                type={'text'}
                defaultValue={generalContact.adminContactPhone||data[0]?.adminContactPhone}
                onChange={(event) => {
                  setGeneralContactObj.adminContactPhone =event.target.value
                  setGeneralContact(setGeneralContactObj)
                }}
                InputProps={{ disableUnderline: true }}
                onFocus={() => { displayAndHideElement('generalBtn', 'block') }}
                onBlur={() => { displayAndHideElement('generalBtn', 'none') }}
              />
              <Btn i='generalBtn'  onClickEvent={saveGeneral} data={setGeneralContactObj} initialResponseObj={initialResponseVar}/>
            </div>
          ),
          whatsapp: (
            <div>
              <TextField
                type={'text'}
                defaultValue={generalContact.adminContactWhatsapp||data[0]?.adminContactWhatsapp}
                onChange={(event) => {
                  setGeneralContactObj.adminContactWhatsapp =event.target.value
                  setGeneralContact(setGeneralContactObj)
                }}
                InputProps={{ disableUnderline: true }}
                onFocus={() => { displayAndHideElement('generalBtn1', 'block') }}
                onBlur={() => { displayAndHideElement('generalBtn1', 'none') }}
              />
            <Btn i='generalBtn1'  onClickEvent={saveGeneral} data={setGeneralContactObj} initialResponseObj={initialResponseVar}/>
            </div>
          ),
          email: (
            <div>
              <TextField
                type={'text'}
                defaultValue={generalContact.adminContactEmail||data[0]?.adminContactEmail}
                onChange={(event) => {
                  setGeneralContactObj.adminContactEmail =event.target.value
                  setGeneralContact(setGeneralContactObj)
                }}
                InputProps={{ disableUnderline: true }}
                onFocus={() => { displayAndHideElement('generalBtn2', 'block') }}
                onBlur={() => { displayAndHideElement('generalBtn2', 'none') }}
              />

            <Btn i='generalBtn2'  onClickEvent={saveGeneral} data={setGeneralContactObj} initialResponseObj={initialResponseVar}/>
            </div>
          ),
        })
         OtherContactVar = data[0].contact
         contactData = data[0].contact
        setResultsData({
          items: data[0].contact.length > 0 && data[0].contact.map((contact, index) => (
            cities.length > 0 && cities.map((city, i) => {
              const findIndex = contactData.findIndex((item)=>item.cityId==city._id);
              return {
                ...data.contact,
                city: city?.name,
                number: (
                  <div style={{ display: 'flex' }}>
                    <TextField
                      style={{ float: 'left', width: '150px' }}
                      type={'text'}
                      defaultValue={data[0].contact.find((item) => item.cityId == city._id)?.number || ''}
                      onChange={(event) => {
                        const otherContactCopy = Object.values(contactData)
                        if(contactData.find((item)=>item.cityId==city._id)){
                          otherContactCopy[findIndex].number=event.target.value
                          otherContactCopy[findIndex].whatsapp=contactData[findIndex]?.whatsapp
                          otherContactCopy[findIndex].email=contactData[findIndex]?.email
                          setOtherContact(otherContactCopy)
                        }else{
                          const newItemInContact = {
                            cityId:city._id,
                            number:event.target.value,
                            email:0,
                            whatsapp: 0,
                          };
                           otherContactCopy.push(newItemInContact)
                          contactData=otherContactCopy;
                          setOtherContact(otherContactCopy)
                        }
                      }}
                      InputProps={{ disableUnderline: true }}
                      onFocus={() => { displayAndHideElement(i, 'block') }}
                      onBlur={() => { displayAndHideElement(i, 'none') }}
                    />
                    <Button
                      style={{ display: "none" }} id={i}
                      round
                      size="sm"
                      type="submit"
                      color="success"
                      loaderColor="white"
                    onClick={()=>saveOtherContact(initialResponseObj)}
                    >
                      save
                    </Button>
                  </div>
                ),
                whatsapp: (
                  <div>
                    <TextField
                      type={'text'}
                      onFocus={() => { displayAndHideElement(`${i}second`, 'block') }}
                      onBlur={() => { displayAndHideElement(`${i}second`, 'none') }}
                      defaultValue={data[0].contact.find((item) => item.cityId == city._id)?.whatsapp || ''}
                      onChange={(event) => {
                        const otherContactCopy = [...contactData]
                        if(contactData.find((item)=>item.cityId==city._id)){
                          
                          otherContactCopy[findIndex].whatsapp=event.target.value
                          otherContactCopy[findIndex].number=contactData[findIndex]?.number
                          otherContactCopy[findIndex].email=contactData[findIndex]?.email
                          contactData=otherContactCopy;
                          setOtherContact(otherContactCopy)
                        }else{
                          const newItemInContact = {
                            cityId:city._id,
                            whatsapp:event.target.value,
                            email:'',
                            number: '',
                          };
                          otherContactCopy.push(newItemInContact)
                          contactData=otherContactCopy;
                          setOtherContact(otherContactCopy)
                        }
                      }}
                      InputProps={{ disableUnderline: true }}
                    />
                  <Button
                      style={{ display: "none" }} id={`${i}second`}
                      round
                      size="sm"
                      type="submit"
                      color="success"
                      loaderColor="white"
                    onClick={()=>saveOtherContact(initialResponseObj)}
                    >
                      save
                    </Button>
                  </div>
                ),
                email: (
                  <div>
                    <TextField
                      type={'text'}
                      onFocus={() => { displayAndHideElement(`${i}therth`, 'block') }}
                      onBlur={() => { displayAndHideElement(`${i}therth`, 'none') }}
                      defaultValue={data[0].contact.find((item) => item.cityId == city._id)?.email || ''}

                      onChange={(event) => {
                        const otherContactCopy = [...contactData]
                        if(contactData.find((item)=>item.cityId==city._id)){
                          
                          otherContactCopy[findIndex].email=event.target.value
                          otherContactCopy[findIndex].whatsapp=contactData[findIndex]?.whatsapp
                          otherContactCopy[findIndex].number=contactData[findIndex]?.number
                          contactData=otherContactCopy;
                          setOtherContact(otherContactCopy)
                        }else{
                          const newItemInContact = {
                            cityId:city._id,
                            email:event.target.value,
                            number:'',
                            whatsapp: '',
                          };
                          otherContactCopy.push(newItemInContact)
                          contactData=otherContactCopy;
                          setOtherContact(otherContactCopy)
                        }
                      }}
                      InputProps={{ disableUnderline: true }}
                    />
                    <Button
                      style={{ display: "none" }} id={`${i}therth`}
                      round
                      size="sm"
                      type="submit"
                      color="success"
                      loaderColor="white"
                    onClick={()=>saveOtherContact(initialResponseObj)}
                    >
                      save
                    </Button>

                  </div>
                ),

              }
            })

          )),

        });
      })
      .finally(() => { });
  };
  const getAllCities = () => {
    getCities(0, 0, false)
      .then(({ data }) => {
        setCities(data)
      })
      .catch(error => console.log(error))
  }
  const saveOtherContact=(initialResponseObj)=>{
    initialResponseObj.contact=contactData;
    updateSetting(initialResponseObj._id,initialResponseObj)
    .then(({data})=>{
      alert.show('saved', {
        type: 'success',
      })
    })
    .catch(error=>{console.log(error)})
  }

  useEffect(() => {
    fetchSetting()
    .then(({ data })=>setOtherContact(data[0].contact)).catch(error=>{console.log(error)})
    getAllCities()

  }, [])
  useEffect(() => {
    cities && loadSettingList()
  }, [cities])
  const initialData = [contact].concat(resultsData.items[0])

  return (
    <>

      {resultsData?.items.length > 0 &&
        <DataTableContainerComponent
          resultsData={resultsData}
          initData={initialData}
          columns={[

            {
              Header: 'City',
              accessor: 'city',
            },
            {
              Header: 'Number',
              accessor: 'number',
            },
            {
              Header: 'Whatsapp',
              accessor: 'whatsapp',
            },
            {
              Header: 'Email',
              accessor: 'email',
            },


          ]}
          title="Contact"
          isLoading={isLoading}
          loadItemsList={() => loadServicesList()}
          isPaginate={false}
        />
      }
    </>
  );
}
