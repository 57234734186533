import React, { useEffect, useState } from 'react';
import Map from '../../../components/Map.component';
import CustomMarker from '../../../components/Markers.component';
import { iconsList } from '../../../config/status.config';
import { InfoWindow } from '@react-google-maps/api';

export default function WareHousesMapComponenet({ item, props }) {
  const [path, setPath] = useState({});
  const [wareHousesInCity, setWareHousesInCity] = useState({});
  const [selctedWareHouse, setSelctedWareHouse] = useState(-1);
  const { cities } = props;

  const initialiseMap = () => {
    setPath({
      lat: item.warehouses[0].location[0],
      lng: item.warehouses[0].location[1],
    });
    const cityObje = {};
    item.warehouses.forEach((x) => {
      cityObje[x.cityId] = (cityObje[x.cityId] || 0) + 1;
    });
    setWareHousesInCity(cityObje);
  };
  useEffect(() => {
    item.warehouses && item.warehouses.length > 0 && initialiseMap();
  }, [item]);
  return (
    <div>
      {wareHousesInCity &&
        Object.keys(wareHousesInCity).length > 0 &&
        Object.keys(wareHousesInCity).map(
          (item) =>
            wareHousesInCity[item] +
            ' in ' +
            cities.find((x) => x._id == item)?.name,
        )}
      <Map
        path={path}
        zoom={11}
        mapContainerStyle={{
          height: '600px',
          width: '100%',
        }}
      >
        {(item.warehouses || []).map((warehouse, index) => (
          <CustomMarker
            icon={iconsList.store}
            size={30}
            key={warehouse._id}
            onClick={() => setSelctedWareHouse(index)}
            position={{
              lat: parseFloat(warehouse.location ? warehouse.location[0] : ''),
              lng: parseFloat(warehouse.location ? warehouse.location[1] : ''),
            }}
          />
        ))}
        {selctedWareHouse != -1 && (
          <InfoWindow
            onCloseClick={() => {
              setSelctedWareHouse(-1);
            }}
            position={{
              lat: parseFloat(
                item.warehouses[selctedWareHouse].location[0] || 0,
              ),
              lng: parseFloat(
                item.warehouses[selctedWareHouse].location[1] || 0,
              ),
            }}
          >
            <div>
              <div style={{ fontSize: 12, fontColor: `#08233B` }}>
                <div>
                  {item.warehouses[selctedWareHouse]?.name}
                  {/* {info?.date &&
                    displayBtn &&
                    ' ' + formatTimeForOrderOnlyTime(info?.date)}
                  {' ' + info.time + ' min'} */}
                  <br />
                </div>
              </div>
            </div>
          </InfoWindow>
        )}
      </Map>
    </div>
  );
}
