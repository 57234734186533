import React, { useEffect, useState } from 'react';
import TablePagination from '@material-ui/core/TablePagination';
import DataTableContainerComponent from '../../../components/DataTableContainer.component';
import DataTableSortingContainer from '../../../components/DataTableSortingContainer.component';
import ViewItemComponent from '../components/ViewItem.component';
import { getServiceCategories } from '../services/serverApi.service';
import CreateServiceCategoryComponent from '../components/CreateServiceCategory.component';
import EditServiceCategoryComponent from '../components/EditServiceCategory.component';
import DeleteSetviceCategoryComponent from '../components/DeleteServiceCategory.component';

export default function ServiceCategoriesPage() {
  const [resultsData, setResultsData] = useState({
    total: 0,
    items: [],
    tableItems: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const pages = [ 25,50,100];
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(pages[page])
  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Type',
      accessor: 'typeCol',
    },
    {
      Header: 'city',
      accessor: 'cityCol',
    },
    {
      Header: 'image',
      accessor: 'imgCol',
    },
    {
      Header: 'one ServiceCategory',
      accessor: 'oneServiceCategoryCol',
    },
    {
      Header: 'one StoreCategory',
      accessor: 'oneStoreCategoryCol',
    },
    {
      Header: 'Action',
      accessor: 'actions',
    },

  ]
  const loadServiceCategoriesList = () => {
    
    setIsLoading(true);
    getServiceCategories(page,rowsPerPage , true)
      .then(({ data }) => {
        console.log(data);
        setResultsData({
          total: data.total,
          items: data.serviceCategories.map((item)=>{
            return {
              ...item,
            typeCol:item.type?item.type:'--',
            cityCol:item.cities?item.cities.map((city)=>(<p cityId={city._id}>{city.name}</p>)):'--',
            imgCol:item.iconUrl? <img src={item.iconUrl} alt="" border="3" height="50" width="60" />:'--',
            oneServiceCategoryCol:item?.oneServiceCategory&&'true',
            oneStoreCategoryCol:item?.oneStoreCategory&&'true'
            }
          }),
          tableItems: data.serviceCategories.map((item) => [
            // eslint-disable-next-line no-underscore-dangle
            item._id,
            item.name,
            item.type?item.type:'--',
            item.cities?item.cities.map((city)=>(<p cityId={city._id}>{city.name}</p>)):'--',
            item.iconUrl? <img src={item.iconUrl} alt="" border="3" height="50" width="60" />:'--'
          ]),
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
}
const handleChangeRowsPerPage = event => {
  setPage(0);
  setRowsPerPage(event.target.value)
}
  useEffect(() => {
    loadServiceCategoriesList();
  }, [page, rowsPerPage]);

  return (
    <>
    {/*<TablePagination*/}
    {/*    component="div"*/}
    {/*    page={page}*/}
    {/*    rowsPerPageOptions={pages}*/}
    {/*    rowsPerPage={rowsPerPage}*/}
    {/*    count={resultsData.total}*/}
    {/*    onPageChange={handleChangePage}*/}
    {/*    onRowsPerPageChange={handleChangeRowsPerPage}*/}
    {/*  />*/}
    {/* <DataTableContainerComponent
      resultsData={resultsData}
      tableHead={['Name', 'Type','city','image','Action']}
      title="Service categories"
      isLoading={isLoading}
      loadItemsList={loadServiceCategoriesList}
      CreateItemFormComponent={CreateServiceCategoryComponent}
      actionButtons={{
        edit: {
          color: 'success',
          type: 'edit',
          component: EditServiceCategoryComponent,
        },
        delete: {
          color: 'danger',
           type: 'delete',
          component: DeleteSetviceCategoryComponent
        },
      }}
    /> */}
    <DataTableSortingContainer
          resultsData={resultsData}
          initData={resultsData.items}
          columns={columns}
          title="Service categories"
          isLoading={isLoading}
          loadItemsList={loadServiceCategoriesList}
          CreateItemFormComponent={CreateServiceCategoryComponent}
          actionButtons={{
            edit: {
              color: 'success',
              type: 'edit',
              component: EditServiceCategoryComponent,
            },
            delete: {
              color: 'danger',
               type: 'delete',
              component: DeleteSetviceCategoryComponent
            },
          }}
        />
    {/*<TablePagination*/}
    {/*    component="div"*/}
    {/*    page={page}*/}
    {/*    rowsPerPageOptions={pages}*/}
    {/*    rowsPerPage={rowsPerPage}*/}
    {/*    count={resultsData.total}*/}
    {/*    onPageChange={handleChangePage}*/}
    {/*    onRowsPerPageChange={handleChangeRowsPerPage}*/}
    {/*  />*/}
      </>
  );
}
