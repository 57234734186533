import React, { useState, useEffect, useRef } from 'react';
import { useAlert } from 'react-alert';
import moment from 'moment';
import { Box, Grid, Button } from '@material-ui/core';
import {
  getApprouvedProvider,
  logProvider,
  setProvider,
  getTransportations,
  setGPSOn,
} from '../service/serverAPI.service';
import DataTableContainerComponent from '../../../components/DataTableSortingContainer.component';
import SwitchComponent from '../../../components/Switch.component';
import { formatTime } from '../../../helpers/index';
import DeleteProvider from '../components/DeleteProvider';
import EditProvider from '../components/EditProvider';
import HistoryLocationComponent from '../components/History.location.component';
import { getCities } from '../../delivery-info/services/serverApi.service';
import CustomButton from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import SelectBoxComponent from '../../../components/SelectBox.component';
import GridContainer from '../../../components/material-dashboard-pro-react/Grid/GridContainer';
import Card from '../../../components/material-dashboard-pro-react/Card/Card';
import CardBody from '../../../components/material-dashboard-pro-react/Card/CardBody';
import ModalComponent from '../../../components/Modal.component';

import CustomInput from '../../../components/material-dashboard-pro-react/CustomInput/CustomInput';
import CutomeButton from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { editPasswordAdmin } from '../../setting/services/serverApi.service';
import { useSelector } from 'react-redux';
import { getUserDataState } from 'features/auth/redux/selectors';
import { DATA } from '../../../utils/logAudit';
import { resetObj } from 'utils/resetObj';

const validationSchema = yup.object({
  userId: yup.string().required(),
  entityObject: yup.object().required(),
  entityType: yup.string().required(),
  password: yup
    .string()
    .min(6, 'password length must be at least 6 characters long')
    .required(),
});
export default function ApprouvedProvider() {
  const alert = useAlert();
  const inpRef = useRef();
  const [cities, setCities] = useState([]);
  const [cityId, setCityId] = useState('');
  const [providers, setProviders] = useState([]);
  const [transportation, setTranposrtations] = useState([]);
  const [resultsData, setResultsData] = useState({
    total: 0,
    items: [],
    tableItems: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  let numberOfRows = [100, 500, 1000];
  const [rowsPerPage, setRowsPerPage] = useState(numberOfRows[0]);
  const [searchText, setSearchText] = useState('');
  const [total, setTotal] = useState('');
  const [userId, setUserId] = useState(null);
  const [open, setOpen] = useState(false);
  const formik = useFormik({
    initialValues: {
      userId: '',
      password: '',
      entityType: '',
      entityObject: '',
    },
    validationSchema,
  });
  // admin data :
  const adminData = useSelector(getUserDataState);
  const [admin, setAdmin] = useState({
    _id: adminData.user._id,
    email: adminData.user.email,
    username: adminData.user.username,
  });
  // handle lock a delivery-man :
  const deliveryManLock = (value, item) => {
    // reset object before setting data :
    resetObj(DATA);
    // for logger audit data :
    DATA.admin = admin;
    DATA.actionType = 'Bloquer/Debloquer Livreur';
    DATA.targetId = item._id;
    DATA.target = 'Delivery-man';
    DATA.loggerDetails = {
      beforeValue: 'Debloquer',
      afterValue: 'Bloquer',
    };
    // DATA.beforeValue = 'Debloquer';
    // DATA.afterValue = 'Bloquer';
    // console.log(beforeValue, admin, item);
    // console.log(data);
    // our post here :
    logProvider(DATA)
      .then(({ data }) => {
        console.log(data);
      })
      .catch((error) => {
        console.log(error.message, {
          type: 'error',
        });
      });
  };
  const handleCloseModal = () => {
    setUserId('');
    formik.setValues({
      userId: '',
      password: '',
      entityType: '',
      entityObject: '',
    });
    setOpen(false);
  };
  const changePassword = () => {
    editPasswordAdmin(formik.values)
      .then(({ data }) => {
        console.log(data);
        handleCloseModal();
      })
      .catch((error) => console.log(error));
  };
  const featchTransportation = () => {
    getTransportations()
      .then(({ data }) =>
        setTranposrtations(data.transportationList.transportations || []),
      )
      .catch((error) => console.log(error));
  };
  const getCity = () => {
    getCities()
      .then(({ data }) => {
        setCities(data);
      })
      .catch((error) => console.log(error));
  };
  const updateProvider = (id, payload, type) => {
    setProvider(id, payload, type)
      .then(({ data }) => {
        alert.show(data.message, {
          type: 'success',
        });
        loadApprouvedProviderList(page, rowsPerPage, searchText);
      })
      .catch((error) => {
        alert.show(error.message, {
          type: 'error',
        });
      });
  };
  const updateProviderGPSOn = (id, payload) => {
    setGPSOn(id, payload)
      .then(({ data }) => {
        alert.show(data.message, {
          type: 'success',
        });
        loadApprouvedProviderList(page, rowsPerPage, searchText);
      })
      .catch((error) => {
        alert.show(error.message, {
          type: 'error',
        });
      });
  };

  const loadApprouvedProviderList = (page, limit, city, txt) => {
    setIsLoading(true);
    // console.log(page, rowsPerPage)
    getApprouvedProvider(page, limit, city, txt)
      .then(({ data }) => {
        setTotal(data.total);
        setProviders(data.deliveryMan);
      })
      .catch((error) => {
        setResultsData({
          total: 0,
          items: [],
          tableItems: [],
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const prepareShowProvider = (item, index) => {
    return {
      ...item,
      name: item.name,
      phone: item.phone ? item.phone : '--',
      email: item.email,
      approvedCol: (
        <SwitchComponent
          onChange={(value) => {
            updateProvider(item._id, { approved: value }, 'approve');
            deliveryManLock(value, item);
          }}
          value="checked"
          checked={item.approved}
          label="approved"
        />
      ),
      onlineCol: (
        <SwitchComponent
          onChange={(value) =>
            updateProvider(item._id, { available: value }, 'available')
          }
          value="checked"
          checked={item.available}
          label="available"
        />
      ),
      GPSOnCol: (
        <SwitchComponent
          onChange={(value) =>
            updateProviderGPSOn(item._id, {
              GPSOn: value,
              batteryCritical: item?.batteryCritical || false,
              appVersion: item?.appVersion || '1.0.57',
            })
          }
          value="checked"
          checked={item.GPSOn}
          label="GPSOn"
        />
      ),
      createdAt: item.createdAt ? formatTime(item.createdAt) : '--',
      reset: (
        <Button
          onClick={() => {
            setUserId(item?.userId);
            formik.setValues({
              userId: item?.userId,
              entityObject: item,
              entityType: 'deliveryMan',
            });
            setOpen(true);
          }}
        >
          reset
        </Button>
      ),
      dateDisapprovedCell:
        item.dateDisapproved &&
        moment(item.dateDisapproved).format('DD/MM hh:mm'),
    };
  };
  useEffect(() => {
    getCity();
    featchTransportation();
  }, []);
  useEffect(() => {
    providers &&
      providers.length > 0 &&
      setResultsData({
        items: providers.map((item, index) => {
          return prepareShowProvider(item, index);
        }),
      });
  }, [providers]);

  const handlePaginate = (val) => {
    setResultsData({
      items: [],
      tableItems: [],
    });
    loadApprouvedProviderList(
      val.pageSelect,
      val.numberOfRows,
      cityId,
      searchText,
    );
    setPage(val.pageSelect);
    setRowsPerPage(val.numberOfRows);
  };

  return (
    <>
      <GridContainer>
        <Card>
          <CardBody>
            <Grid container>
              {cities && (
                <SelectBoxComponent
                  items={cities.map((city) => ({
                    value: city._id,
                    label: city.name,
                  }))}
                  inputLabel="city list"
                  onValueChange={(items) => {
                    setCityId(items);
                  }}
                  defaultValue={cities.find((item) => item._id == cityId)?.name}
                />
              )}
              <Grid item xs={8}>
                <input
                  type="text"
                  ref={inpRef}
                  placeholder="provider search"
                  style={{
                    border: 'none',
                    borderBottom: '1px solid black',
                  }}
                  defaultValue={searchText}
                />
              </Grid>
              <Grid item xs={4} container justify="flex-end">
                <Box textAlign="left">
                  <CustomButton
                    round
                    type="submit"
                    color="success"
                    size="sm"
                    loaderColor="white"
                    onClick={() => {
                      setSearchText(inpRef.current.value);
                      page > 0 && setPage(0);
                      loadApprouvedProviderList(
                        page > 0 ? 0 : page,
                        rowsPerPage,
                        cityId,
                        inpRef.current.value,
                      );
                    }}
                  >
                    find Provider
                  </CustomButton>
                </Box>
                <Box textAlign="left">
                  <CustomButton
                    round
                    type="submit"
                    color="google"
                    size="sm"
                    loaderColor="white"
                    onClick={() => {
                      setSearchText('');
                      page > 0 && setPage(0);
                      setCityId('');
                      loadApprouvedProviderList(
                        page > 0 ? 0 : page,
                        rowsPerPage,
                        '',
                        '',
                      );
                    }}
                  >
                    clear
                  </CustomButton>
                </Box>
              </Grid>
            </Grid>
          </CardBody>
        </Card>
      </GridContainer>
      <DataTableContainerComponent
        total={total}
        resultsData={resultsData}
        initData={resultsData.items}
        page={page + 1}
        columns={[
          {
            Header: 'Name',
            accessor: 'name',
          },
          {
            Header: 'phone',
            accessor: 'phone',
          },
          {
            Header: 'email',
            accessor: 'email',
          },
          {
            Header: 'Approved',
            accessor: 'approvedCol',
          },
          {
            Header: 'online',
            accessor: 'onlineCol',
          },
          {
            Header: 'GPSOn',
            accessor: 'GPSOnCol',
          },
          {
            Header: 'Plan',
            accessor: 'plan',
          },
          {
            Header: 'rate',
            accessor: 'rate',
          },
          {
            Header: 'appVersion',
            accessor: 'appVersion',
          },
          {
            Header: 'deliveryType',
            accessor: 'deliveryType',
          },
          {
            Header: 'transportation',
            accessor: 'transportation',
          },
          {
            Header: 'reset Password',
            accessor: 'reset',
          },
          {
            Header: 'reasonDisapproved',
            accessor: 'reasonDisapproved',
          },
          {
            Header: 'dateDisapproved',
            accessor: 'dateDisapprovedCell',
          },
          {
            Header: 'actions',
            accessor: 'actions',
          },
        ]}
        title="Approuved Provider List"
        isLoading={isLoading}
        loadItemsList={loadApprouvedProviderList}
        actionButtons={{
          view: {
            color: 'info',
            type: 'view',
            component: HistoryLocationComponent,
          },
          edit: {
            color: 'success',
            type: 'edit',
            component: EditProvider,
            props: { cities: cities, transportation, transportation },
          },
          delete: {
            color: 'danger',
            type: 'delete',
            component: DeleteProvider,
          },
        }}
        onPaginate={handlePaginate}
        isPaginate={true}
      />
      <ModalComponent
        maxWidth={'sm'}
        close={handleCloseModal}
        visible={open}
        title={
          'reaset password : ' +
          (resultsData.items || []).find((x) => x.userId == userId)?.name
        }
      >
        <div>
          <CustomInput
            labelText="password"
            formControlProps={{
              fullWidth: true,
            }}
            type="text"
            error={formik.touched.password && Boolean(formik.errors.password)}
            inputProps={{
              value: formik.values.password,
              onBlur: formik.handleBlur('password'),
              onChange: formik.handleChange('password'),
            }}
          />
          <Box textAlign="center">
            <CutomeButton
              round
              type="submit"
              color="success"
              disabled={!formik.dirty || !formik.isValid || isLoading}
              loading={isLoading}
              loaderColor="white"
              onClick={changePassword}
            >
              save
            </CutomeButton>
          </Box>
        </div>
      </ModalComponent>
    </>
  );
}
