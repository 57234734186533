import {
    DEFAULT_SELECTED_CITIES, SELECT_CITY,
    SELECT_WAREHOUSE, SELECTED_DELIVERIES_USING_DELIVERYMAN_STATUS,
    TOTAL_DELIVERIES_BY_WAREHOSE_USING_DISPATCHING_STATUS,
    TOTAL_DELIVERIES_BY_WAREHOSE_USING_DELIVERYMAN_STATUS,
    CHANGE_FILTER_BY,
    SELECTED_DELIVERIES_USING_DISPATCHING_STATUS
} from '../constants/filter.constants';

export const defaultSelectedCities = () => ({
    type: DEFAULT_SELECTED_CITIES
})
export const selectCityAction = (cities) => ({
    type: SELECT_CITY,
    cities
})
export const selectWareHouseAction = (select, value) => ({
    type: SELECT_WAREHOUSE,
    select, value
})
export const selectDeliveriesUsingDeliveryManStatusAction = (selectedDeliveryManStatus, selectedWarehousseyOrdersDeliveryManStatus) => ({
    type: SELECTED_DELIVERIES_USING_DELIVERYMAN_STATUS,
    selectedDeliveryManStatus,
    selectedWarehousseyOrdersDeliveryManStatus
})
export const totalDeliveriesByWarehouseUsingDispatchingStatusAction = (allDeliveries, selectedCities) => ({
    type: TOTAL_DELIVERIES_BY_WAREHOSE_USING_DISPATCHING_STATUS,
    allDeliveries, selectedCities
})
export const totalDeliveriesByWarehouseUsingDeliveryManStatusAction = (allDeliveries, selectedCities) => ({
    type: TOTAL_DELIVERIES_BY_WAREHOSE_USING_DELIVERYMAN_STATUS,
    allDeliveries, selectedCities
})
export const changeFilterByAction = (filterBy) => ({
    type: CHANGE_FILTER_BY,
    filterBy
})
export const selectDeliveriesUsingDispatchingStatusAction = (selectedDispatchingStatus, selectedWarehousseyOrders) => ({
    type: SELECTED_DELIVERIES_USING_DISPATCHING_STATUS,
    selectedDispatchingStatus, selectedWarehousseyOrders
})