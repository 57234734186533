import axios from 'axios';
import io from 'socket.io-client';

import {
    MICROSERVICE_BASE_URL,
    backendSocketConfig
} from '../../../config/backendServer.config';

export function getCurrentPartnerOrders() {
    return axios.get(
        `${MICROSERVICE_BASE_URL.ALLPARTNERS}/deliveries/current`,
    );
}
export function AssignDeliverie(deliveryId, payload) {
    return axios.put(
        `${MICROSERVICE_BASE_URL.ALLPARTNERS}/deliveries/${deliveryId}/accept`, payload
    );
}
export function changeStatus(deliveryId, payload) {
    return axios.put(
        `${MICROSERVICE_BASE_URL.ALLPARTNERS}/deliveries/${deliveryId}/status`, { ...payload, byAdmin: true }
    );
}
export function getReport(deliveryId) {
    return axios.get(
        `${MICROSERVICE_BASE_URL.ALLPARTNERS}/deliveries/${deliveryId}/webhooks-report`,
    );
}
export function cancelDelivery(deliveryId, payload) {
    return axios.put(
        `${MICROSERVICE_BASE_URL.ALLPARTNERS}/deliveries/${deliveryId}/cancel`, payload
    );
}

export function getPartnerOrdersHistory(
    skip,
    limit,
    paged,

    startDate,
    endDate,
    useDate,
    txt,
    searchBy,
    // shortId,
) {
    return axios.get(
        `${MICROSERVICE_BASE_URL.ALLPARTNERS}/deliveries/completed?page=${skip || 0}&limit=${limit || 5
        }&paged=${paged || false}&${useDate && `dateFrom=${startDate || ''}&dateTo=${endDate || ''}`
        }&searchText=${txt || ''}&searchBy=${searchBy || ''}`,
    );
}
export function getPartnerOrdersHistoryTerminated(
    skip,
    limit,
    paged,

    startDate,
    endDate,
    useDate,
    txt,
    searchBy,
    // shortId,
) {
    return axios.get(
        `${MICROSERVICE_BASE_URL.ALLPARTNERS}/deliveries/completed?page=${skip || 0}&limit=${limit || 5
        }&paged=${paged || false}&terminated=true&${useDate && `dateFrom=${startDate || ''}&dateTo=${endDate || ''}`
        }&searchText=${txt || ''}&searchBy=${searchBy || ''}`,
    );
}
export function getPartnerOrdersHistoryPending(
    skip,
    limit,
    paged,

    startDate,
    endDate,
    useDate,
    txt,
    searchBy,
    // shortId,
) {
    return axios.get(
        `${MICROSERVICE_BASE_URL.ALLPARTNERS}/deliveries/completed?page=${skip || 0}&limit=${limit || 5
        }&paged=${paged || false}&terminated=false&${useDate && `dateFrom=${startDate || ''}&dateTo=${endDate || ''}`
        }&searchText=${txt || ''}&searchBy=${searchBy || ''}`,
    );
}
export function socketConnectPertner(id) {
    return io(backendSocketConfig.partnerAppUrl, {
        // path: '/api/v1/socketspartners/connect', //GPS + step
        path: '/api/v1/socketspartners-step/connect',  // Step
        query: { token: 'Bearer ' + id },
        transports: ['websocket'],
    });
}
export function socketConnectPertners(id) {
    return io(backendSocketConfig.partnerAppUrl, {
        // path: '/api/v1/socketspartners/connect', //GPS + step
        path: '/api/v1/socketspartners/connect',  // Step
        query: { token: 'Bearer ' + id },
        transports: ['websocket'],
    });
}
export function socketConnectPertnerGps(id) {
    return io(backendSocketConfig.partnerAppUrl, {
        // path: '/api/v1/socketspartners/connect', //GPS + step
        path: '/api/v1/sockets-gps-update/connect',  // Step
        query: { token: 'Bearer ' + id },
        transports: ['websocket'],
    });
}
export function socketConnect(id) {
    return io(backendSocketConfig.appUrl, {
        path: '/api/v1/socketsallpartners/connect',
        query: { token: 'Bearer ' + id },
        transports: ['websocket'],
    });
}
export function addComment(deliveryId, data) {
    return axios.put(
        `${MICROSERVICE_BASE_URL.ALLPARTNERS}/deliveries/${deliveryId}/comment`,
        data,
    );
}
export function getAlarms(skip, limit, deliveryManId, shortId, name) {
    return axios.get(
        `${MICROSERVICE_BASE_URL.ALLPARTNERS}/alarm/get_alarms?page=${skip || 0
        }&limit=${limit || 0}&paged=true&deliveryManId=${deliveryManId || ''
        }&name=${name || ''}&shortId=${shortId || ''}`,
    );
}
export function updateAlarm(alarmId, data) {
    return axios.put(
        `${MICROSERVICE_BASE_URL.ALLPARTNERS}/alarm/${alarmId}`,
        data,
    );
}
export function getAlarmConfig() {
    return axios.get(`${MICROSERVICE_BASE_URL.ALLPARTNERS}/alarmconfig`);
}

export function updateAlarmConfig(data) {
    return axios.put(`${MICROSERVICE_BASE_URL.ALLPARTNERS}/alarmconfig`, data);
}
export function getCallApi(kaalixId) {
    return axios.get(
        `${MICROSERVICE_BASE_URL.ALLPARTNERS}/callapi?kaalixId=${kaalixId || ''}`,
    );
}
export function getDispatchingStatus() {
    return axios.get(`${MICROSERVICE_BASE_URL.ALLPARTNERS}/dispatching_state`);
}
export function citiesPlaningByDay(day) {
    return axios.get(`${MICROSERVICE_BASE_URL.DELIVERY_MAN}/workingcalendar/citiesplanning?day=${day}`,);
}
export function getDeliveryManDispatchedById(id) {
    return axios.get(`${MICROSERVICE_BASE_URL.ALLPARTNERS}/deliveryman/${id}`);
}
export function resendDeliveredApi(paulIds) {
    return axios.post(`${MICROSERVICE_BASE_URL.ALLPARTNERS}/deliveries/resend-webHooks`, { paulIds: paulIds });
}
