import axios from "axios";
import { backendServerConfig, MICROSERVICE_BASE_URL } from "../../../config/backendServer.config";

// get data for none qualities :
export function getTemplateNonQualities(page, limit, paged) {
    return axios.get(
        `${MICROSERVICE_BASE_URL.ADMIN}/template-non-quality?page=${
            page || 0
        }&limit=${
            limit || 0
        }&paged=${
            paged || false
        }`,
    );
}

// post data for none qualities :
export function createTemplateNonQualities(body) {
    return axios.post(
        `${MICROSERVICE_BASE_URL.ADMIN}/template-non-quality`, {...body}
    );
}

// update data for none qualities :
export function updateTemplateNonQualities(id, body) {
    return axios.put(
        `${MICROSERVICE_BASE_URL.ADMIN}/template-non-quality/${id}`, body
    );
}

// delete template by ID :
export function deleteTemplateNonQualities(id) {
    return axios.delete(
        `${MICROSERVICE_BASE_URL.ADMIN}/template-non-quality/${id}`
    );
}

// get single template by ID :
export function getSingleTemplate(id, limit, paged) {
    return axios.get(
        `${MICROSERVICE_BASE_URL.ADMIN}/template-non-quality?limit=${
            limit || 0
        }&templateId=${id}&paged=${paged || false}`,
    );
}

// get non qualities by destination :
export function getNonQualities(page, limit, paged, name, type) {
    return axios.get(
        `${MICROSERVICE_BASE_URL.ADMIN}/non-quality?page=${
            page || 0
        }&limit=${
            limit || 0
        }&paged=${
            paged || false
        }&destination=${
            type || ''
        }`,
    );
}