import React, { useState, useEffect } from 'react';
import ModalComponent from '../../../components/Modal.component';
import DataTableContainerComponent from '../../../components/DataTableSortingContainer.component';
import GridContainer from '../../../components/material-dashboard-pro-react/Grid/GridContainer';
import Card from '../../../components/material-dashboard-pro-react/Card/Card';
import CardHeader from '../../../components/material-dashboard-pro-react/Card/CardHeader';
import CardBody from '../../../components/material-dashboard-pro-react/Card/CardBody';
import CustomButton from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import SelectBoxComponent from '../../../components/SelectBox.component';
import { getCities } from '../../delivery-info/services/serverApi.service';
import {
  getPartnerDeliveryManInCities,
  getPartnerDeliveryManInCity,
  getParners,
  assignDeliveryMan,
  removeWareHouseFromDeliveryManList,
  preferredWareHouse,
} from '../services/server.api.service';
import { formatTimeForOrder } from '../../../helpers/index';
import { Grid, Checkbox, FormControlLabel } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import {
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Typography,
  Paper,
  Button,
  TextField,
} from '@mui/material';
import ButtonComponent from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import moment from 'moment';
import CloseIcon from '@material-ui/icons/Close';
import { getAppVersion } from '../../setting/services/serverApi.service';
import { rowColor } from './../../driver/utils/index';

export default function DeliveryMenPartnerPage() {
  const [resultsData, setResultsData] = useState({
    items: [],
  });
  const [appVersion, setAppVersion] = useState('');
  const [cities, setCities] = useState([]);
  const [deliveryMen, setDeliveryMen] = useState([]);
  const [deliveryMan, setDeliveryMan] = useState({});
  const [indexDeliveryMan, setIndexDeliveryMan] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState('');
  const [openSubModal, setOpenSubModal] = useState('');
  const [checkedObj, setCheckedObj] = useState({});
  const [selectedCity, setSelectedCity] = useState([]);
  const [partners, setPartners] = useState([]);
  const [wareHouses, setWareHouses] = useState([]);

  const wareHousesTableColumns = [
    'city',
    'organisation Name',
    'warehouse Name',
    'contact',
    'location',
    'delete',
  ];
  const columns = [
    {
      Header: 'name',
      accessor: 'name',
      customWidth: '110px',
    },
    {
      Header: 'email',
      accessor: 'email',
      customWidth: '150px',
    },
    {
      Header: 'phone',
      accessor: 'phone',
      customWidth: '100px',
    },
    {
      Header: 'available',
      accessor: 'availableCol',
      customWidth: '80px',
    },
    {
      Header: 'approved',
      accessor: 'approvedCol',
      customWidth: '80px',
    },
    {
      Header: 'dispatchable',
      accessor: 'dispatchableCol',
      customWidth: '100px',
    },
    {
      Header: 'Raison Battery',
      accessor: 'dispatchableRaisonBatteryCol',
      customWidth: '110px',
    },
    {
      Header: 'Raison GPS',
      accessor: 'dispatchableRaisonGpsCol',
      customWidth: '90px',
    },
    {
      Header: 'Raison Planning',
      accessor: 'dispatchableRaisonPlanningAcceptrCol',
      customWidth: '110px',
    },
    {
      Header: 'Raison accept',
      accessor: 'dispatchableRaisonAcceptCol',
      customWidth: '110px',
    },
    {
      Header: 'location',
      accessor: 'locationCol',
      customWidth: '110px',
    },
    {
      Header: 'warehouses',
      accessor: 'warehousesCell',
      customWidth: '110px',
    },
    {
      Header: 'app version',
      accessor: 'appVersion',
      customWidth: '100px',
    },
    {
      Header: 'lastHeartBeatDate',
      accessor: 'lastHeartBeatDateCol',
      customWidth: '130px',
    },
    {
      Header: 'lastKnownLocations',
      accessor: 'lastKnownLocationsCol',
      customWidth: '140px',
    },
    {
      Header: 'createdAt',
      accessor: 'createdAtCol',
      customWidth: '110px',
    },
    {
      Header: 'updatedAt',
      accessor: 'updatedAtCol',
      customWidth: '110px',
    },
  ];
  const fetchAppVersion = () => {
    getAppVersion()
      .then(({ data }) => setAppVersion(data?.findAppversion?.appVersion))
      .catch((err) => console.log(err));
  };
  const getAllCities = () => {
    getCities(0, 0, false)
      .then(({ data }) => {
        const TemCityObj = {
          all: true,
        };
        data.map((city) => {
          TemCityObj[city.name] = true;
        });
        data.unshift({ _id: 'all', name: 'all' });
        const tempSelectedCityArray = [];
        data.map((city) => {
          tempSelectedCityArray.push(city._id);
        });
        setSelectedCity(tempSelectedCityArray);
        setCities(data);
        setCheckedObj(TemCityObj);
        changeCity(['all']);
      })
      .catch((error) => console.log(error));
  };
  const handleModal = (item, type, index) => {
    setDeliveryMan(item);
    setIndexDeliveryMan(index);
    setOpenModal(type);
  };
  const handleSubModal = (type) => {
    // setDeliveryMan(item);
    // setIndexStore(index);
    setOpenSubModal(type);
    setWareHouses(
      partners
        .reduce(
          (warehousesCumulator, obj) => [
            ...warehousesCumulator,
            ...obj?.warehouses,
          ],
          [],
        )
        .filter((w) => w.cityId == deliveryMan.cityId),
    );
  };
  const handleCloseModal = () => {
    setOpenModal('');
    setOpenSubModal('');
  };
  const handleSubCloseModal = () => {
    setOpenSubModal('');
  };
  const resetDataTabale = () => {
    setResultsData({ items: [] });
  };
  const changeCity = (citiesId) => {
    if (citiesId.includes('all')) {
      getPartnerDeliveryManInCity('all')
        .then(({ data }) => {
          setDeliveryMen(data.deliveryMen);
        })
        .catch((error) => {
          setDeliveryMen([]);
          resetDataTabale();
        });
    } else {
      getPartnerDeliveryManInCities(citiesId)
        .then(({ data }) => {
          setDeliveryMen(data.deliveryMen);
        })
        .catch((error) => {
          setDeliveryMen([]);
          resetDataTabale();
        });
    }
  };
  const getWareHouse = () => {
    getParners()
      .then(({ data }) => {
        setPartners(
          data?.organisations.filter(
            (x) => x.warehouses && x.warehouses.length > 0,
          ),
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const deleteWarehouse = (id) => {
    removeWareHouseFromDeliveryManList(deliveryMan._id, id)
      .then(({ data }) => {
        const tempDeliveryMen = [...deliveryMen];
        tempDeliveryMen[indexDeliveryMan] = data?.deliveryMan;
        setDeliveryMen(tempDeliveryMen);
        handleCloseModal();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const assignWarehouse = (obj) => {
    assignDeliveryMan(deliveryMan._id, obj)
      .then(({ data }) => {
        const tempDeliveryMen = [...deliveryMen];
        tempDeliveryMen[indexDeliveryMan] = data?.deliveryMan;
        setDeliveryMen(tempDeliveryMen);
        handleCloseModal();
      })
      .catch((error) => console.log(error));
  };
  const changePreferredWareHouse = (warehouseId) => {
    const findWarehouse = deliveryMan.warehouses.find(
      (x) => x._id == warehouseId,
    );
    if (findWarehouse) {
      const { name, wareHouseLabel, wareHouseId, _id } = findWarehouse;
      preferredWareHouse(deliveryMan._id, {
        name,
        wareHouseLabel,
        wareHouseId,
        _id,
      })
        .then(({ data }) => {
          const tempDeliveryMen = [...deliveryMen];
          tempDeliveryMen[indexDeliveryMan] = data?.deliveryMan;
          setDeliveryMen(tempDeliveryMen);
          handleCloseModal();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  useEffect(() => {
    fetchAppVersion();
    getAllCities();
    getWareHouse();
  }, []);
  // console.log('appVersion', appVersion);
  const rowColor = (location, appVer) => {
    console.log('appVersion', appVer);
    if (location[0] == 0 && location[1] == 0) {
      return '#DC3545';
    } else if (appVer != appVersion) {
      return '#eed202';
    }
  };
  const prepareToShowDeliveryMen = (delivery, index) => {
    return {
      ...delivery,
      locationCol: JSON.stringify(delivery.location || ''),
      availableCol: delivery.available ? 'YES' : 'NO',
      approvedCol: delivery.approved ? 'YES' : 'NO',
      dispatchableCol: delivery.dispatchable ? 'YES' : 'NO',
      lastKnownLocationsCol: (
        <div
          style={{ cursor: 'pointer' }}
          fontSize="small"
          onClick={() => {
            handleModal(delivery, 'lastLocation', index);
          }}
        >
          {delivery.lastKnownLocations.length}
        </div>
      ),
      createdAtCol:
        delivery.createdAt &&
        moment(delivery.createdAt).format('DD.MM.YYYY hh:mm'),
      updatedAtCol:
        delivery.updatedAt &&
        moment(delivery.updatedAt).format('DD.MM.YYYY hh:mm'),
      dispatchableRaisonAcceptCol: delivery.available ? 'Yes' : 'No',
      dispatchableRaisonBatteryCol: delivery.batteryCritical ? 'Yes' : 'No',
      dispatchableRaisonGpsCol: delivery.GPSOn ? 'Yes' : 'No',
      dispatchableRaisonPlanningAcceptrCol: delivery.planified ? 'Yes' : 'No',
      lastHeartBeatDateCol:
        delivery.lastHeartBeatDate &&
        formatTimeForOrder(delivery.lastHeartBeatDate),
      warehousesCell: (
        <span
          style={{ cursor: 'pointer' }}
          onClick={() => handleModal(delivery, 'warehouses', index)}
        >
          {delivery.warehouses && delivery.warehouses.length}
        </span>
      ),
      Rowcolor: rowColor(delivery?.location, delivery?.appVersion),
    };
  };
  useEffect(() => {
    deliveryMen &&
      deliveryMen.length > 0 &&
      setResultsData({
        items: deliveryMen.map((delivery, index) => {
          return prepareToShowDeliveryMen(delivery, index);
        }),
      });
  }, [deliveryMen]);
  return (
    <div>
      <GridContainer>
        <Card>
          <CardHeader>DeliveryMen In :</CardHeader>
          <CardBody>
            <Grid container justify="flex-end">
              <ButtonComponent
                round
                type="submit"
                color="success"
                loading={isLoading}
                loaderColor="white"
                onClick={() => {
                  changeCity(selectedCity.length ? selectedCity : ['all']);
                }}
              >
                reload
              </ButtonComponent>
            </Grid>
            <Grid container>
              {checkedObj &&
                Object.keys(checkedObj).length &&
                cities.map((item, index) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={checkedObj[item.name]}
                        onChange={(e) => {
                          if (e.target.checked) {
                            let tempSelectedCityArray = [...selectedCity];
                            tempSelectedCityArray.includes(item._id)
                              ? tempSelectedCityArray.map(
                                  (elem, index) =>
                                    elem == item._id &&
                                    tempSelectedCityArray.splice(index, 1),
                                )
                              : tempSelectedCityArray.push(item._id);
                            if (item._id == cities[0]._id) {
                              tempSelectedCityArray.length = 0;
                              const newCheckedObj = { ...checkedObj };
                              for (const key in newCheckedObj) {
                                newCheckedObj[key] = true;
                              }
                              setCheckedObj(newCheckedObj);
                              cities.map((e) =>
                                tempSelectedCityArray.push(e._id),
                              );
                            } else {
                              const newCheckedObj = { ...checkedObj };
                              newCheckedObj[item.name] = true;
                              setCheckedObj(newCheckedObj);
                            }

                            setSelectedCity(tempSelectedCityArray);
                            changeCity(tempSelectedCityArray);
                          } else {
                            if (item._id !== cities[0]._id) {
                              const tempSelectedCityArray = [...selectedCity];
                              const newCheckedObj = { ...checkedObj };

                              newCheckedObj[cities[0].name] = false;
                              newCheckedObj[item.name] = false;
                              setCheckedObj(newCheckedObj);
                              const findCityIndex =
                                tempSelectedCityArray.indexOf(item._id);
                              if (findCityIndex >= 0) {
                                tempSelectedCityArray.splice(findCityIndex, 1);
                                const findIndexForAll =
                                  tempSelectedCityArray.indexOf('all');
                                findIndexForAll >= 0 &&
                                  tempSelectedCityArray.splice(
                                    findIndexForAll,
                                    1,
                                  );
                                setSelectedCity(tempSelectedCityArray);
                                tempSelectedCityArray.length &&
                                  changeCity(tempSelectedCityArray);
                              }
                            } else {
                              const newCheckedObj = { ...checkedObj };
                              for (const key in newCheckedObj) {
                                newCheckedObj[key] = false;
                              }
                              setCheckedObj(newCheckedObj);
                              setSelectedCity([]);
                              setResultsData({ items: [] });
                            }
                          }
                        }}
                      />
                    }
                    label={item.name}
                  />
                ))}
            </Grid>
          </CardBody>
        </Card>
      </GridContainer>
      <>
        <DataTableContainerComponent
          resultsData={resultsData.items}
          initData={resultsData.items}
          columns={columns}
          title="partner DeliveryMen"
          isLoading={isLoading}
          isPaginate={false}
        />
      </>
      {openModal && openModal == 'warehouses' && (
        <ModalComponent
          maxWidth={'md'}
          close={handleCloseModal}
          visible={true}
          title={`warehouses list (${deliveryMan?.name})`}
        >
          <div>
            <SelectBoxComponent
              items={(deliveryMan.warehouses || []).map((wareHouse) => ({
                value: wareHouse?._id,
                label: wareHouse?.name,
              }))}
              inputLabel="prefered WareHouse"
              onValueChange={(item) => changePreferredWareHouse(item)}
              value={deliveryMan?.preferedWareHouse?._id || ''}
            />
            <CustomButton
              color="success"
              rounded
              onClick={() => handleSubModal('edit')}
            >
              add
            </CustomButton>
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    {wareHousesTableColumns.map((item) => (
                      <TableCell align="center">{item}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(deliveryMan.warehouses || []).map((warehouse) => (
                    <TableRow>
                      <TableCell align="center">
                        {cities.find((x) => x._id == warehouse.cityId)?.name}
                      </TableCell>
                      <TableCell align="center">
                        {warehouse?.organisationName}
                      </TableCell>
                      <TableCell align="center">{warehouse?.name}</TableCell>
                      <TableCell align="center">{warehouse?.contact}</TableCell>
                      <TableCell align="center">
                        {warehouse?.location &&
                          JSON.stringify(warehouse?.location)}
                      </TableCell>
                      <TableCell align="center">
                        <CloseIcon
                          style={{ color: 'red', cursor: 'pointer' }}
                          onClick={() => deleteWarehouse(warehouse._id)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </ModalComponent>
      )}
      {openSubModal && openSubModal == 'edit' && (
        <ModalComponent
          maxWidth={'md'}
          close={handleSubCloseModal}
          visible={true}
          title={`warehouses edit (${deliveryMan?.name})`}
        >
          <Autocomplete
            id="combo-box-demo"
            options={wareHouses || []}
            getOptionLabel={(option) => option.name}
            style={{ width: 250 }}
            onChange={(event, newValue) => {
              newValue && assignWarehouse(newValue);
            }}
            renderInput={(wareHouses) => (
              <TextField
                {...wareHouses}
                label="wareHouse name"
                variant="outlined"
                onChange={(e) => console.log(e.target.value)}
              />
            )}
          />
        </ModalComponent>
      )}
    </div>
  );
}
