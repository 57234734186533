import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import {
  useFormik,
  ErrorMessage,
  Field,
  FieldArray,
  Form,
  Formik,
} from 'formik';
import { useAlert } from 'react-alert';
import InputLabel from '@material-ui/core/InputLabel';
import { Box, TextField } from '@material-ui/core';
// import { Autocomplete } from '@material-ui/lab'
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormHelperText from '@material-ui/core/FormHelperText';

import { multiUploadImageResetAction } from '../../../redux/multiUploadRedux/upload.action';

import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import CustomInput from '../../../components/material-dashboard-pro-react/CustomField/CustomField';
import CustomSelect from '../../../components/material-dashboard-pro-react/CustomSelect/CustomSelect';
import ImageUpload from '../../../components/material-dashboard-pro-react/CustomUpload/CustomMultiUpload';

import {
  getStoresBySearch,
  getStoresById,
  EditPulicity,
} from '../services/serverApi.service';

const validationSchema = yup.object({
  name: yup.string().required('name is a required field'),
  description: yup.string().required('description is a required field'),
  ExternalLink: yup.string(),
  assignedTo: yup.string().required('assignedTo is a required field'),
  serviceId: yup.string().when('assignedTo', {
    is: (value) => value && value == 'service',
    then: yup
      .string()
      .required('service is required when choise service as assigned'),
    otherwise: yup.string().nullable(),
  }),
  cityId: yup.string().when('assignedTo', {
    is: (value) => value && value == 'city',
    then: yup
      .string()
      .required('city is required when choise city as assigned'),
    otherwise: yup.string(),
  }),
  format: yup.string().required('format is a required field'),
  publicitySubGroups: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string().required('required'),
        description: yup.string().required('required'),
        position: yup
          .number()
          .typeError('position is number')
          .required('required'),
        pubs: yup
          .array()
          .of(
            yup
              .object()
              .shape({
                name: yup.string().required('required'),
                description: yup.string().required('required'),
                type: yup.string().required('required'),
                storeId: yup.string().when('type', {
                  is: (value) => value == 'Store',
                  then: yup
                    .string()
                    .required(
                      'store is required when choise store as pub type',
                    ),
                  otherwise: yup.string(),
                }),
                externalLink: yup.string().when('type', {
                  is: (value) => value == 'ExternalLink',
                  then: yup
                    .string()
                    .required(
                      'ExternalLinkis required when choise ExternalLink as type',
                    ),
                  otherwise: yup.string(),
                }),
              })
              .required(),
          )
          .required(),
      }),
    )
    .required(),
});
export default function EditPublicityComponent({
  data,
  close,
  onItemEdited,
  props,
}) {
  // console.log(props);
  const dispatch = useDispatch();
  const formRef = React.useRef();
  const formValues = formRef.current?.values;
  // console.log(formValues?.publicitySubGroups);
  const upload = useSelector((state) => state.multiUploadReducer);
  const { multiIconUrl, error, loading, newUpload } = upload;
  // const initialState = { default: 'defaule message', }
  const alert = useAlert();
  const [errorsImage, setErrors] = useState({});
  const [text, setText] = useState('a');
  const [cities, setCities] = useState([]);
  const [stores, setStores] = useState([]);
  const [serviceCategories, setServiceCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [change, setChange] = useState(false);
  const [storesInPub, setStoresInPub] = useState({});

  const assignedTo = ['city', 'service'];
  const format = ['Banner', 'Tabs'];
  const PubType = ['Store', 'ExternalLink', 'NONE'];

  const initialValues = {
    name: '',
    description: '',
    ExternalLink: '',
    assignedTo: '',
    serviceId: null,
    cityId: '',
    format: '',
  };

  const getStoresList = (text) => {
    getStoresBySearch(0, 50, true, text || 'a')
      .then(({ data: { stores: storeData } }) => {
        setStores(storeData);
      })
      .catch((error) => {
        alert.show(error.message, {
          type: 'error',
        });
      });
  };

  const submitForm = (values) => {
    // console.log('formRef.value',formRef.current?.values);
    values.publicitySubGroups.map((parentItem, index) =>
      parentItem.pubs.map((childItem, i) => {
        // console.log(childItem)
        if (multiIconUrl[`${index}-${i}`]) {
          (childItem.imageUrl = multiIconUrl[`${index}-${i}`]),
            (!childItem.imageUrl ||
              childItem.imageUrl == '' ||
              childItem.imageUrl == undefined) &&
              setErrors({
                ...errorsImage,
                [`${index}-${i}`]: 'image not uploaded correctly',
              });
        }
      }),
    );
    // console.log('formRef.value',values);
    Object.keys(errorsImage).length == 0 && setIsLoading(true);
    EditPulicity(data._id, values)
      .then((data) => {
        console.log(data);
        dispatch(multiUploadImageResetAction());
        onItemEdited();
        close();
      })
      .catch((error) => {
        alert.show(error.message, {
          type: 'error',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  async function AsycFnc(fns) {
    const values = [];

    await fns.reduce((previous, current, index, array) => {
      const thenable = index === 1 ? previous() : previous;
      return thenable.then((value) => {
        values.push(value);
        return index === array.length - 1
          ? current().then((value) => values.push(value))
          : current();
      });
    });

    return values;
  }
  const call = async (id) => await getStoresById(id);
  const getStoreInPub = async () => {
    if (data) {
      const storesObj = {};
      (await data.publicitySubGroups) &&
        data.publicitySubGroups.length &&
        data.publicitySubGroups.map(async (pub) => {
          // console.log('pub',pub);
          //    await pub.pubs&&pub.pubs.length&&pub.pubs.map(async({storeId})=>{
          //         if( storesObj[storeId] == undefined){
          //             // console.log(123);
          //             storesObj[storeId] = ''
          //             await getStoresById(storeId)
          pub.pubs &&
            pub.pubs.length &&
            AsycFnc(
              pub.pubs.map(
                (val) => () =>
                  // console.log(val),
                  call(val.storeId).then(({ data }) => {
                    console.log(data);
                    storesObj[val.storeId] = data.name;
                  }),
              ),
            );
        });
      // console.log('storesObj', storesObj);
      await setStoresInPub(storesObj);
    }
  };

  const setValue = () => {
    if (data) {
      formRef.current?.setValues({
        name: data.name,
        description: data.description,
        ExternalLink: data.ExternalLink,
        assignedTo: data.assignedTo,
        serviceId: data.serviceId,
        cityId: data?.cityId,
        format: data.format,
        publicitySubGroups: data.publicitySubGroups,
        // iconUrl,
        // cityList: (data.cities.length==0 ? [] : data?.cities?.map((item) => (item._id))),
        // serviceCategoryId: data?.serviceCategory == "" ? '' : data?.serviceCategory?.props?.propsId,
        // // tagsName: tagsList.findIndex((item)=>JSON.stringify(item.tags)==JSON.stringify(formik.values.tags)),
        // // tags: (data.tags.length==0 ? [] : data?.tags?.map((item) => (console.log(item))))
        // tags: data?.tags,
        // tagsName:"dqsd"
      });
      // formik.setFieldValue('tags', data?.tags||[]);
      //   console.log('formik.values',formik.values);
    }
    // const index = tagsList.findIndex((item)=>JSON.stringify(item.tags)==JSON.stringify(formik.values.tags))
    //   setTagSelected(index);
  };
  //   console.log(checkProperties(storesInPub));
  useEffect(async () => {
    await getStoreInPub();
    getStoresList();
    setValue();
  }, [data]);
  useEffect(async () => {
    getStoresList(text);
  }, [text]);
  // console.log(storesInPub);
  return (
    <div>
      {1 == 1 && (
        <Formik
          innerRef={formRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          // onSubmit={values => submitForm(values)}
          render={({
            values,
            isValid,
            errors,
            dirty,
            touched,
            setFieldValue,
          }) => (
            <>
              <Form>
                <CustomInput
                  labelText="Name"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  type="text"
                  name="name"
                  helperText={<ErrorMessage name="name" />}
                />
                <CustomInput
                  labelText="Description"
                  name="description"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  type="text"
                  helperText={<ErrorMessage name="description" />}
                />
                <CustomInput
                  labelText="External Link"
                  name="ExternalLink"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  type="text"
                  helperText={<ErrorMessage name="ExternalLink" />}
                />
                <CustomSelect
                  name="assignedTo"
                  items={assignedTo}
                  label="assignedTo"
                  helperText={<ErrorMessage name="assignedTo" />}
                />
                <CustomSelect
                  name="serviceId"
                  items={props?.serviceCategories}
                  label="Service"
                />
                {(errors.serviceId || touched.assignedTo) && (
                  <FormHelperText style={{ color: 'red' }}>
                    {errors.serviceId}
                  </FormHelperText>
                )}

                <CustomSelect
                  name="cityId"
                  items={props?.cities}
                  label="city "
                  //     helperText={<ErrorMessage name='cityId' />
                  // }
                />
                {(errors.cityId || touched.assignedTo) && (
                  <FormHelperText style={{ color: 'red' }}>
                    {errors.cityId}
                  </FormHelperText>
                )}

                <CustomSelect
                  name="format"
                  items={format}
                  label="Format"
                  helperText={<ErrorMessage name="format" />}
                />

                <FieldArray
                  name="publicitySubGroups"
                  render={(arrayHelpers) => {
                    const publicitySubGroups = values.publicitySubGroups;
                    return (
                      <div>
                        {publicitySubGroups && publicitySubGroups.length > 0
                          ? publicitySubGroups.map(
                              (publicitySubGroup, index) => (
                                <>
                                  <InputLabel style={{ marginTop: '10px' }}>
                                    publicity Sub Groups: {index + 1}{' '}
                                  </InputLabel>
                                  <div
                                    key={index}
                                    style={{
                                      border: '1.3px solid #000',
                                      borderRadius: '10px',
                                      padding: '15px',
                                      marginTop: '15px',
                                    }}
                                  >
                                    <CustomInput
                                      labelText="Pub Group Name"
                                      name={`publicitySubGroups.${index}.name`}
                                      formControlProps={{
                                        fullWidth: true,
                                      }}
                                      type="text"
                                      helperText={
                                        <ErrorMessage
                                          name={`publicitySubGroups.${index}.name`}
                                        />
                                      }
                                      value="aaaa"
                                    />
                                    <CustomInput
                                      labelText="Pub Group Description"
                                      name={`publicitySubGroups.${index}.description`}
                                      formControlProps={{
                                        fullWidth: true,
                                      }}
                                      type="text"
                                      helperText={
                                        <ErrorMessage
                                          name={`publicitySubGroups.${index}.description`}
                                        />
                                      }
                                    />
                                    <CustomInput
                                      labelText="Position"
                                      name={`publicitySubGroups.${index}.position`}
                                      formControlProps={{
                                        fullWidth: true,
                                      }}
                                      type="text"
                                      helperText={
                                        <ErrorMessage
                                          name={`publicitySubGroups.${index}.position`}
                                        />
                                      }
                                    />

                                    <FieldArray
                                      name={`publicitySubGroups.${index}.pubs`}
                                      render={(arrayHelpersForPubs) => {
                                        const Pubs =
                                          values.publicitySubGroups[index].pubs;

                                        return (
                                          <>
                                            {Pubs && Pubs.length > 0 && (
                                              <div
                                                style={{
                                                  display: 'flex',
                                                  overflowX: 'auto',
                                                }}
                                              >
                                                {Pubs && Pubs.length > 0
                                                  ? Pubs.map((pub, i) => (
                                                      // console.log({name:storesInPub[pub.storeId]}),
                                                      // console.log(pub.storeName),
                                                      <div
                                                        style={{
                                                          flex: '0 0 25%',
                                                          border:
                                                            '1px solid #000',
                                                          borderRadius: '10px',
                                                          padding: ' 0 15px',
                                                          margin: '10px 5px',
                                                        }}
                                                      >
                                                        <InputLabel
                                                          style={{
                                                            marginTop: '10px',
                                                          }}
                                                        >
                                                          Pub :{i + 1}{' '}
                                                        </InputLabel>

                                                        <Box
                                                          display="flex"
                                                          justifyContent="center"
                                                        >
                                                          <ImageUpload
                                                            label="Select icon"
                                                            folder="service"
                                                            index={`${index}-${i}`}
                                                            errorMessage={
                                                              errorsImage[
                                                                `${index}-${i}`
                                                              ]
                                                            }
                                                            avatar
                                                            style={{
                                                              img: {
                                                                width: '30px',
                                                                height: '30px',
                                                              },
                                                            }}
                                                            btnSize="sm"
                                                            // errorMessage={errorsImage[`${index}`]}
                                                            // defaultImage={`tags[${index}].iconUrl`}
                                                            // defaultImage={`publicitySubGroups.${index}.pubs.${i}.imageUrl`}
                                                            defaultImage={
                                                              pub.imageUrl
                                                            }
                                                            // displayMessage = {true}
                                                          />
                                                        </Box>

                                                        <CustomInput
                                                          labelText="pub Name"
                                                          name={`publicitySubGroups.${index}.pubs.${i}.name`}
                                                          formControlProps={{
                                                            fullWidth: true,
                                                          }}
                                                          type="text"
                                                          helperText={
                                                            <ErrorMessage
                                                              name={`publicitySubGroups.${index}.pubs.${i}.name`}
                                                            />
                                                          }
                                                        />
                                                        <CustomInput
                                                          labelText="Pub Description"
                                                          name={`publicitySubGroups.${index}.pubs.${i}.description`}
                                                          formControlProps={{
                                                            fullWidth: true,
                                                          }}
                                                          type="text"
                                                          helperText={
                                                            <ErrorMessage
                                                              name={`publicitySubGroups.${index}.pubs.${i}.description`}
                                                            />
                                                          }
                                                        />

                                                        <CustomSelect
                                                          name={`publicitySubGroups.${index}.pubs.${i}.type`}
                                                          items={PubType}
                                                          label="Pub Type"
                                                          helperText={
                                                            <ErrorMessage
                                                              name={`publicitySubGroups.${index}.pubs.${i}.type`}
                                                            />
                                                          }
                                                        />
                                                        {/* <CustomSelect
                                                                                                        name={`publicitySubGroups.${index}.pubs.${i}.storeId`}
                                                                                                        items={stores}
                                                                                                        label="Store"
                                                                                                        helperText={<ErrorMessage name={`publicitySubGroups.${index}.pubs.${i}.storeId`} />}
                                                                                                    /> */}
                                                        {/* {storesInPub[pub.storeId]&& */}
                                                        {/* store selected : {storesInPub[pub.storeId]} */}
                                                        <Autocomplete
                                                          id="combo-box-demo"
                                                          options={stores}
                                                          // value={{name:storesInPub[pub.storeId]}}
                                                          getOptionLabel={(
                                                            option,
                                                          ) =>
                                                            option?.name +
                                                            ' ' +
                                                            option?.email
                                                          }
                                                          defaultValue={{
                                                            name: storesInPub[
                                                              pub.storeId
                                                            ],
                                                          }}
                                                          style={{
                                                            width: 200,
                                                          }}
                                                          onChange={(
                                                            event,
                                                            newValue,
                                                          ) => {
                                                            newValue &&
                                                              setFieldValue(
                                                                `publicitySubGroups.${index}.pubs.${i}.storeId`,
                                                                newValue._id,
                                                              );
                                                          }}
                                                          renderInput={(
                                                            stores,
                                                          ) => (
                                                            <TextField
                                                              {...stores}
                                                              label={
                                                                storesInPub &&
                                                                storesInPub[
                                                                  pub.storeId
                                                                ]
                                                              }
                                                              // value={storesInPub[pub.storeId]}
                                                              variant="outlined"
                                                              // value={storesInPub[pub.storeId]}
                                                              onChange={(e) =>
                                                                getStoresList(
                                                                  e.target
                                                                    .value,
                                                                )
                                                              }
                                                            />
                                                          )}
                                                        />
                                                        {/* } */}
                                                        <CustomInput
                                                          labelText="Pub External Link"
                                                          name={`publicitySubGroups.${index}.pubs.${i}.externalLink`}
                                                          formControlProps={{
                                                            fullWidth: true,
                                                          }}
                                                          type="text"
                                                          helperText={
                                                            <ErrorMessage
                                                              name={`publicitySubGroups.${index}.pubs.${i}.externalLink`}
                                                            />
                                                          }
                                                        />

                                                        <Box textAlign="right">
                                                          <Button
                                                            size="sm"
                                                            color="danger"
                                                            loaderColor="white"
                                                            onClick={() =>
                                                              arrayHelpersForPubs.remove(
                                                                i,
                                                                1,
                                                              )
                                                            }
                                                          >
                                                            delete Pub
                                                          </Button>
                                                        </Box>
                                                      </div>
                                                    ))
                                                  : null}
                                              </div>
                                            )}
                                            <Box textAlign="left">
                                              <Button
                                                size="sm"
                                                color="google"
                                                loaderColor="white"
                                                onClick={
                                                  () =>
                                                    // isValid &&
                                                    arrayHelpersForPubs.push({})
                                                  // console.log(arrayHelpers[index].Pubs)
                                                }
                                              >
                                                Add Pub
                                              </Button>
                                            </Box>
                                            <Box textAlign="right">
                                              <Button
                                                size="sm"
                                                color="danger"
                                                loaderColor="white"
                                                onClick={() =>
                                                  arrayHelpers.remove(index)
                                                }
                                              >
                                                delete Pub Group
                                              </Button>
                                            </Box>
                                          </>
                                        );
                                      }}
                                    />
                                  </div>
                                </>
                              ),
                            )
                          : null}

                        <Box textAlign="left">
                          <Button
                            size="sm"
                            color="primary"
                            loaderColor="white"
                            onClick={() =>
                              // isValid &&
                              arrayHelpers.push({})
                            }
                          >
                            Add Pub Group
                          </Button>
                        </Box>
                      </div>
                    );
                  }}
                />

                <Box textAlign="right">
                  <Button
                    round
                    type="submit"
                    color="success"
                    disabled={
                      !formRef?.current?.dirty ||
                      isLoading ||
                      !formRef?.current?.isValid
                        ? true
                        : false
                    }
                    loading={isLoading}
                    loaderColor="white"
                    onClick={() => submitForm(values)}
                  >
                    save
                  </Button>
                </Box>
              </Form>
            </>
          )}
        />
      )}
    </div>
  );
}
