export const changesMock = {
    changeId: 'changeId',
    changeType: 'changeType',
}
export const kaalixPayHistoryTypes = [
    'none',
    'REFFERAL',
    'CHARGE_WITH_CARD',
    'ORDER_CHARGED',
    'KAALIXPAY_CHARGE',
    'refund_card',
    'refund_kaalixpay',
    'BY_ADMIN',
    'TIP'
]
export const kaalixUpHistoryTypes = [
    'none',
    'BY_ADMIN',
    'refund_rewardPoints',
    'rewardPoints',
    'TIP',
]
export const promoTypes = ['PercentageItem', 'AbsoluteItem', 'PercentageDelivery', 'AbsoluteDelivery']
