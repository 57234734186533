import React,{ useEffect, useState} from 'react'
import GridContainer from '../../../components/material-dashboard-pro-react/Grid/GridContainer';
import Card from '../../../components/material-dashboard-pro-react/Card/Card';
import CardHeader from '../../../components/material-dashboard-pro-react/Card/CardHeader';
import CardBody from '../../../components/material-dashboard-pro-react/Card/CardBody';
import DataTableSortingContainer from '../../../components/DataTableSortingContainer.component'
import CreateCityConfig from '../components/CreateCityConfig.component'
import EditCityConfigComponent from '../components/EditeCityConfig.component'

import {getSharing,getCities} from '../services/serverApi.service'

export default function SharePage() {
    const [sharing,setSharing] = useState({
        items:[]
    })
    // const [resultsData,setResultsData] = ({
    //     items:[]
    // })
    const [cities,setCities] = useState([])
    const [PropsCities,setPropsCities] = useState([])
    const [isLoading,setIsLoading] = useState(false)
    const columns =[
        {
            Header :'city',
            accessor: 'cityName'
        },
        {
            Header :'Type',
            accessor: 'type'
        },
        {
            Header :'Minimum Amount',
            accessor: 'minimumAmount'
        },
        {
            Header :'Sender Gain',
            accessor: 'senderGain'
        },
        {
            Header :'Receiver Gain',
            accessor: 'receiverGain'
        },
        {
            Header :'Sender Text',
            accessor: 'senderText'
        },
        {
            Header :'Receiver Text',
            accessor: 'receiverText'
        },
        {
            Header :'actions',
            accessor: 'actions'
        },
    ]
    const getAllCities = () => {
        getCities(0, 0, false)
          .then(({ data }) => {
            // console.log(data);
            setCities(data)
          })
          .catch(error => console.log(error))
      }
    const getSharingFromAPI = () =>{
        getSharing()
        .then(({ data })=>{
            console.log(data);
            const cityArray =[...cities];
            cities.length>0&&cities.map((city)=>{
                if(data.find((item)=>item.cityId==city._id)){
                    console.log('frind');
                    const cityIndex=cityArray.findIndex(x=>x._id==city._id)
                    cityArray.splice(cityIndex,1)
                    // console.log(cityIndex);
                }
                // const cityIndex=data.find((item)=>item.cityId==city._id)
                // cityIndex&&console.log(cityIndex);
            })
            setPropsCities(cityArray);
            setSharing({
                items:data.length>0&&data.map((item)=>{
                    return {
                        ...item,
                        cityName: cities.find((element)=> element._id==item.cityId).name,
                        // typeInp : (
                        //     <div>{item.type}</div>
                        // ),
                        // minimumAmountInp : (
                        //     <div>{item.minimumAmount}</div>
                        // ),
                        // senderGainInp : (
                        //     <div>{item.senderGain}</div>
                        // ),
                        // receiverGainInp : (
                        //     <div>{item.receiverGain}</div>
                        // ),
                        // senderTextInp : (
                        //     <div>{item.senderText}</div>
                        // ),
                        // receiverTextInp : (
                        //     <div>{item.receiverText}</div>
                        // ),
                    }
                    
                })
                });
        })
        .catch((error) =>console.log(error))
    }
    useEffect(() => {
        getAllCities()
    },[])
    useEffect(() => {
        cities.length > 0&&getSharingFromAPI()
    },[cities])
    console.log('cities',cities);
    cities&&cities.length&&cities.map(city=>{
        console.log(city._id);
    })
    console.log('sharing',sharing);
    return (
        <GridContainer>
            <Card>
               <CardBody>
               {sharing.items&&
               <DataTableSortingContainer
                        resultsData={sharing}
                        initData={sharing.items}
                        columns={columns}
                        title="city config"
                        isLoading={isLoading}
                        loadItemsList={getSharingFromAPI}
                        CreateItemFormComponent={CreateCityConfig}
                        props={{PropsCities:PropsCities,sharing:sharing}}
                        actionButtons={{
                            edit:{
                                color: 'success',
                                type: 'edit',
                                component: EditCityConfigComponent,
                            }
                        }}
                    />}
               </CardBody>
            </Card>
        </GridContainer>
    )
}
