import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import styles from '../../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle';
import { updateStore } from '../services/serverApi.service';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import NativeSelect from '@material-ui/core/NativeSelect';

const useStyles = makeStyles(styles);

export default function StoreEditPreparationComponent({ store, close }) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [delivery_time, setDelivery_time] = useState([]);
  const [selected_delivery_time_min, set_selected_delivery_time_min] = useState(
    store.preparationTimeMin,
  );
  const [selected_delivery_time_max, set_selected_delivery_time_max] = useState(
    store.preparationTimeMax,
  );
  const [selected_delivery_time_moy, set_selected_delivery_time_moy] = useState(
    store.meanPreparationTime,
  );

  const getPrepareTimeList = () => {
    let tmp = [];
    let i = 0;
    for (i = 5; i <= 120; i += 5) {
      tmp.push(i);
    }
    return tmp;
  };
  useEffect(() => {
    setDelivery_time(getPrepareTimeList());
  }, []);

  const onEdit = async () => {
    if (selected_delivery_time_min <= selected_delivery_time_max) {
      store.preparationTimeMin = selected_delivery_time_min;
      store.preparationTimeMax = selected_delivery_time_max;
      store.meanPreparationTime = selected_delivery_time_moy;
      let { _id, ...newStore } = store;
      updateStore(_id, newStore)
        .then(({ data }) => {
          console.log('Store has been updated', {
            type: 'success',
          });
        })
        .catch((error) => {
          console.log(error.message, {
            type: 'error',
          });
        })
        .finally(() => {
          setIsLoading(false);
          close();
        });
    }
  };

  return (
    <div>
      <Box sx={{ display: 'flex' }}>
        <FormControl className={classes.formControl + ' p-2 m-2'} fullWidth>
          <InputLabel shrink>Delivery Time Min</InputLabel>
          <NativeSelect
            value={selected_delivery_time_min}
            onChange={($event) => {
              set_selected_delivery_time_min($event.target.value);
            }}
          >
            {delivery_time.map((item) => (
              <option value={item}>{item}</option>
            ))}
          </NativeSelect>
        </FormControl>
        <FormControl className={classes.formControl + ' p-2 m-2'} fullWidth>
          <InputLabel shrink>Delivery Time MOY</InputLabel>
          <NativeSelect
            value={selected_delivery_time_moy}
            onChange={($event) => {
              set_selected_delivery_time_moy(parseFloat($event.target.value));
            }}
          >
            {delivery_time.map((item) => (
              <option value={item}>{item}</option>
            ))}
          </NativeSelect>
        </FormControl>
        <FormControl className={classes.formControl + ' p-2 m-2'} fullWidth>
          <InputLabel shrink>Delivery Time Max</InputLabel>
          <NativeSelect
              value={selected_delivery_time_max}
              onChange={($event) => {
                set_selected_delivery_time_max($event.target.value);
              }}
          >
            {delivery_time.map((item) => (
                <option value={item}>{item}</option>
            ))}
          </NativeSelect>
        </FormControl>
      </Box>
      <Box textAlign="center">
        <Button variant="contained" color="success" onClick={onEdit}>
          Edit
        </Button>
      </Box>
    </div>
  );
}

StoreEditPreparationComponent.propTypes = {
  store: PropTypes.any.isRequired,
  close: PropTypes.func,
};
