import {
    DEFAULT_SELECTED_CITIES,
    SELECT_CITY,
    SELECT_WAREHOUSE,
    SELECTED_DELIVERIES_USING_DELIVERYMAN_STATUS,
    TOTAL_DELIVERIES_BY_WAREHOSE_USING_DISPATCHING_STATUS,
    TOTAL_DELIVERIES_BY_WAREHOSE_USING_DELIVERYMAN_STATUS,
    CHANGE_FILTER_BY,
    SELECTED_DELIVERIES_USING_DISPATCHING_STATUS
} from '../constants/filter.constants';
import { allArray } from '../../mock';


const initialState = {
    selectedCities: [],
    selectedWarehouse: [],
    filterChangeCounter: 1,
    filterWarehouseChangeCounter: 1,
    error: '',
    loading: false,
    selectedDeliveriesUsingDeliveryManStatus: {},
    selectedDeliveriesUsingDispatchingStatus: {},
    totalDeliveriesUsingDispatchingStatus: {},
    totalDeliveriesUsingDeliveryManStatus: {},
    filterBy: '',
    selectedDeliveryManStatus: {},
    selectedWarehousseyOrders: [],
    selectedDispatchingStatus: {},
    selectedWarehousseyOrdersDeliveryManStatus: [],
    changeFilterData: 1
};
export default function filterReducers(state = initialState, action) {
    let selectedCities = state?.selectedCities || [];
    let selectedWarehouse = state?.selectedWarehouse || [];
    let totalDeliveriesUsingDispatchingStatus = state?.totalDeliveriesUsingDispatchingStatus || {};
    switch (action.type) {
        case DEFAULT_SELECTED_CITIES:
            const selectedCityArray = JSON.parse(
                localStorage.getItem('selectedCityArray'),
            );
            if (!selectedCityArray) {
                localStorage.setItem('selectedCityArray', JSON.stringify(allArray));
                selectedCities = allArray;
            } else {
                selectedCities = selectedCityArray;
            }
            return {
                ...state,
                selectedCities: selectedCities,
                filterChangeCounter: (state.filterChangeCounter + 1) % 30,
                loading: false,
            }
        case SELECT_CITY:

            localStorage.setItem('selectedCityArray', JSON.stringify(action.cities));
            return {
                ...state,
                selectedCities: action.cities,
                filterChangeCounter: (state.filterChangeCounter + 1) % 30,
                filterWarehouseChangeCounter: state.filterWarehouseChangeCounter,
                loading: false,
            }
        case SELECT_WAREHOUSE:
            if (action.select) {
                selectedWarehouse.push(action?.value)
            } else {
                const findIndex = selectedWarehouse.indexOf(action.value)
                findIndex != -1 && selectedWarehouse.splice(findIndex, 1)
            }
            return {
                ...state,
                selectedWarehouse: selectedWarehouse,
                filterChangeCounter: state.filterChangeCounter,
                filterWarehouseChangeCounter: (state.filterWarehouseChangeCounter + 1) % 30,
                loading: false,
            }
        case SELECTED_DELIVERIES_USING_DELIVERYMAN_STATUS:

            return {
                ...state,
                selectedDeliveryManStatus: action?.selectedDeliveryManStatus,
                selectedWarehousseyOrdersDeliveryManStatus: action?.selectedWarehousseyOrdersDeliveryManStatus,
                changeFilterData: (state.changeFilterData + 1) % 30,
                filterChangeCounter: (state.filterChangeCounter + 1) % 30,
                // totalDeliveriesUsingDispatchingStatus: totalOrdersByWarehouse


            }
            break
        case CHANGE_FILTER_BY:

            return {
                ...state,
                filterBy: action.filterBy,
                selectedDeliveryManStatus: {},
                selectedDispatchingStatus: {},
                selectedWarehousseyOrders: [],
                selectedWarehousseyOrdersDeliveryManStatus: [],
            }
        case TOTAL_DELIVERIES_BY_WAREHOSE_USING_DISPATCHING_STATUS:
            const totalOrdersByWarehouse = {};
            (action.allDeliveries || []).forEach((order) => {
                const warehouseLabel = order?.wareHouse?.wareHouseLabel;
                const dispatchingStatus = order?.dispatchingStatus;
                const cityId = order?.cityId;
                if (selectedCities.includes(cityId) || selectedCities.includes('all')) {

                    totalOrdersByWarehouse[warehouseLabel] = {
                        total: (totalOrdersByWarehouse[warehouseLabel]?.total || 0) + 1,
                        status: {
                            ...totalOrdersByWarehouse[warehouseLabel]?.status,
                            [dispatchingStatus]:
                                (totalOrdersByWarehouse[warehouseLabel]?.status[
                                    dispatchingStatus
                                ] || 0) + 1,
                        },
                    };
                }
            });
            return {
                ...state,
                totalDeliveriesUsingDispatchingStatus: totalOrdersByWarehouse


            }
        case TOTAL_DELIVERIES_BY_WAREHOSE_USING_DELIVERYMAN_STATUS:
            const totalOrdersByWarehouseForDeliveryManStatus = {};

            (action.allDeliveries || []).forEach((order) => {
                const warehouseLabel = order?.wareHouse?.wareHouseLabel;
                const deliveryManStatus = order?.deliveryManStatus;
                const cityId = order?.cityId;
                if (selectedCities.includes(cityId) || selectedCities.includes('all')) {
                    totalOrdersByWarehouseForDeliveryManStatus[warehouseLabel] = {
                        total: (totalOrdersByWarehouseForDeliveryManStatus[warehouseLabel]?.total || 0) + 1,
                        status: {
                            ...totalOrdersByWarehouseForDeliveryManStatus[warehouseLabel]?.status,
                            [deliveryManStatus]:
                                (totalOrdersByWarehouseForDeliveryManStatus[warehouseLabel]?.status[
                                    deliveryManStatus
                                ] || 0) + 1,
                        },
                    };
                }
            });
            return {
                ...state,
                totalDeliveriesUsingDeliveryManStatus: totalOrdersByWarehouseForDeliveryManStatus


            }
        case SELECTED_DELIVERIES_USING_DISPATCHING_STATUS:

            return {
                ...state,
                selectedDispatchingStatus: action?.selectedDispatchingStatus,
                selectedWarehousseyOrders: action?.selectedWarehousseyOrders,
                changeFilterData: (state.changeFilterData + 1) % 30,
                filterChangeCounter: (state.filterChangeCounter + 1) % 30,
                // totalDeliveriesUsingDispatchingStatus: totalOrdersByWarehouse


            }
        default:
            return state

    }
}