import { takeLatest, put } from 'redux-saga/effects'
import { getSettingSuccessAction, getSettingFailAction } from './actions/setting.actions'
import { GET_SETTING_REQUEST } from './constants/setting.constants'
import { fetchSetting } from '../../setting/services/serverApi.service';



function* getSettingFromAPI() {
    const { data } = yield fetchSetting()
    return data
}

function* getSetting() {

    try {
        const data = yield getSettingFromAPI()

        yield put(getSettingSuccessAction(data))

    }
    catch (error) {
        console.log(error.message);
        yield put(getSettingFailAction(error))

    }
}


export default function* watcherSage() {
    yield takeLatest(GET_SETTING_REQUEST, getSetting)

}