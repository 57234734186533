import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useAlert } from 'react-alert';
import moment from 'moment-timezone';
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import CustomInput from '../../../components/material-dashboard-pro-react/CustomInput/CustomInput';
import { createFlag} from '../services/serverApi.service';

const validationSchema = yup.object({
  name: yup.string().required(),

});

export default function CreateFlagComponent({ onItemCreated,close }) {
  const alert = useAlert();
  const [isLoading, setIsLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema,
  });

  const onCreate = () => {  
    setIsLoading(true);
    createFlag(formik.values)
      .then(() => {
        alert.show('flag has been created', {
          type: 'success',
        });
        formik.resetForm();
        onItemCreated(0,20);
        close();
        
      })
      .catch((error) => {
        alert.show(error.message, {
          type: 'error',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

 

 
  return (
    <div>
      <CustomInput
        labelText="Name"
        formControlProps={{
          fullWidth: true,
        }}
        type="text"
        error={formik.touched.name && Boolean(formik.errors.name)}
        inputProps={{
          value: formik.values.name,
          onBlur: formik.handleBlur('name'),
          onChange: formik.handleChange('name'),
        }}
      />
      <Box textAlign="center">
        <Button
          round
          type="submit"
          color="success"
          disabled={!formik.dirty || !formik.isValid || isLoading}
          loading={isLoading}
          loaderColor="white"
          onClick={onCreate}
        >
          Create
        </Button>
      </Box>
    </div>
  );
}

CreateFlagComponent.propTypes = {
  onItemCreated: PropTypes.func.isRequired,
};
