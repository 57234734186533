import {
  GET_ADMINS_LIST_REQUEST,
  GET_ADMINS_LIST_SUCCESS,
  GET_ADMINS_LIST_FAIL
} from '../constants/adminsList.constants';

export const getAdminsListRequestAction = () => ({
  type: GET_ADMINS_LIST_REQUEST,

});
export const getAdminsListSuccessAction = (adminsList) => ({
  type: GET_ADMINS_LIST_SUCCESS,
  adminsList,
});
export const getAdminsListFailAction = (error) => ({
  type: GET_ADMINS_LIST_FAIL,
  error,
});

