import React, { useEffect, useState } from 'react'
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useAlert } from 'react-alert';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { FormControlLabel, Checkbox, Grid, Select, MenuItem, TextField, FormControl } from '@material-ui/core';
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button'
import Input from "@material-ui/core/Input";
import SpecificationList from './SpecificationList.component'
import CustomControllerField from '../../../components/material-dashboard-pro-react/customControllerField/customControllerField';
import SwitchComponent from '../../../components/Switch.component'
import CustomeControllSelect from '../../../components/material-dashboard-pro-react/CustomeControllSelect/CustomeControllSelect';
import { addSpecification } from '../services/serverAPI.service'



export default function CreateSpecification({ onItemCreated, close }) {
  // const [checked, setChecked] = useState(false)
  // const formInitialError = {
  //   name: false,
  //   open: false,
  //   maxChoices: false,
  //   priceconfig: false,
  //   required: false,
  //   type: false,
  // }

  const alert = useAlert();
  const storeId = localStorage.getItem('storeId')
  const [isLoading, setIsLoading] = useState(false);
  const [formErrorsName, setFormErrorsName] = useState(false)
  const [formErrorsMaxChoice, setFormErrorsMaxChoice] = useState(false)
  // const [formErrorsRequired, setFormErrorsRequired] = useState(false)
  const [formErrorsType, setFormErrorsType] = useState(false)
  const [formErrorsPriceconfig, setFormErrorsPriceconfig] = useState(false)
  const [formErrorsList, setFormErrorsList] = useState(false)
  const [formErrorsListPrice, setFormErrorsListPrice] = useState(false)
  // const [formErrors, setFormErrors] = useState(false)
  // console.log(formErrors);

  const typeList = [
    { value: "single", text: "single" },
    { value: "multiple", text: "multiple" },
  ];
  const priceConfigList = [
    { value: "add", text: "add" },
    { value: "override", text: "override" }
  ];

  const { register, control, getValues, setValue, errors, watch, handleSubmit } = useForm({
    defaultValues: {
      // open:"",
      // required: "",
      name: "",
      // type: "",
      // priceconfig: "",
      maxChoices: 1,
      storeId: storeId,
      list: [
        {
          name: '',
          price: '',
          defaultselected: false,
          acceptMultiple: false
        },
        {
          name: '',
          price: '',
          defaultselected: false,
          acceptMultiple: false
        },
      ]
    }
  });

  useEffect(() => {
    // console.log(formErrorsType,'Effect');
  }, [errors, formErrorsName])
  // console.log(errors);


  const { fields, append, remove, swap } = useFieldArray({
    control,
    name: "list"
  });
  const setAllAddressData = (data, index) => {
    // console.log(data);
    //set List
    setValue(`list[${index}].name`, data.name);
    setValue(`list[${index}].price`, data.price);
    setValue(`list[${index}].defaultselected`, data.defaultselected);
    setValue(`list[${index}].acceptMultiple`, data.acceptMultiple);
    // setValue('name', data.name);

  };

  const handlePanelDragEnd = result => {
    const { source, destination } = result;
    // console.log(source, destination);
    if (!destination) return;
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    )
      return;

    //swap the fields
    swap(source.index, destination.index);
    console.log("after swap: ", getValues().list);
  };

  const addItemToList = () => {
    append({
      name: "",
      price: "",
      defaultselected: false,
      acceptMultiple: false,
    });
  };

  const handleClick = () => {
    // console.log("vals", getValues({ nest: true }).list);
    // console.log(getValues());
  };


  const setError = (setState, condition) => {
    // console.log(varLabel,'varLabel')
    var newLabel = false
    if (condition) {
      newLabel = true;
      setState(true)
    }
    else {
      newLabel = false;
      setState(false)
    }
    return newLabel;
  }
  const onSubmit = async (data) => {
    data['storeId'] = storeId;
    var errorName = false;
    var errorType = false;
    var errorMaxChoices = false;
    var errorPriceconfig = false;
    var errorList = false;

    errorName = setError(setFormErrorsName, data.name == "" || data.name == undefined)
    errorType = setError(setFormErrorsType, data.type == "" || data.type == undefined)
    errorMaxChoices = setError(setFormErrorsMaxChoice, data.maxChoices < 1)
    errorPriceconfig = setError(setFormErrorsPriceconfig, data.priceconfig == "" || data.priceconfig == undefined)

    if (data.list.length > 0) {
      setFormErrorsList(false)
      setFormErrorsListPrice(false)
      errorList = false
      data.list.map((item) => {
        if (item.name == "" || item.name == undefined || item.price == "" || item.price == undefined) {
          setFormErrorsList(true)
          errorList = true
        }
        if (isNaN(item.price) || item.price < 0) {
          setFormErrorsListPrice(true)
          errorList = true
        }
      }

      )
    } else {
      setFormErrorsList(true)
      errorList = true
    }
    if (!errorName && !errorType && !errorMaxChoices && !errorPriceconfig && !errorList) {
      console.log(data)
      setIsLoading(true);
      addSpecification(data)
        .then(({ data }) => {
          // console.log(data);
          alert.show(data.message, {
            type: 'success',
          });
          // formik.resetForm();
          close()
          onItemCreated();
        })
        .catch((error) => {
          alert.show(error.message, {
            type: 'error',
          });
        })
        .finally(() => {
          setIsLoading(false);
        })
    }


  };
  const renderPanels = () => {
    return fields.map((field, index) => (
      <SpecificationList
        key={field.id}
        index={index}
        length={fields.length}
        setAllAddressData={setAllAddressData}
        form={{ field, register, control, setValue, getValues, remove }}
      />
    ));
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DragDropContext onDragEnd={handlePanelDragEnd}>

          <div>
            <CustomControllerField
              labelText="Name"
              formControlProps={{
                fullWidth: true,
              }}
              type="text"
              name="name"
              control={control}
            />
            {formErrorsName && <span style={{ color: 'red' }}>This field is required</span>}

            <Droppable droppableId="panel-dropzone">
              {provided => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="panels-wrapper"
                >
                  {renderPanels()}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            {formErrorsList && <span style={{ color: 'red' }}>This field is required</span>}
            {formErrorsListPrice && <span style={{ color: 'red' }}>price must not be less than 0</span>}
            <Box textAlign="left">
              <Button
                round
                type="submit"
                color="twitter"
                size="sm"
                loaderColor="white"
                onClick={addItemToList}
              >
                Add new item
              </Button>
            </Box>
            <FormControlLabel
              control={<Checkbox color="primary" />}
              label='obligatory choice for the customer'

              name='required'
              inputRef={register}
            />
            {/* <FormControlLabel
              control={<Checkbox color="primary" onChange={(evt) => setChecked(evt.target.checked)} />}
              label='max choice'
            /> */}

            <CustomControllerField
              labelText="choice number"
              formControlProps={{
                fullWidth: false,
              }}
              type="number"
              name="maxChoices"
              control={control}
            // disabled={!checked}
            // ref={
            //   register({
            //     validate: {
            //       positive: value => parseInt(value) > 0,
            //       lessThanTen: value => parseInt(value) < 10,
            //       asyncValidate: async () => await fetch(url)
            //     }
            //   })
            // }
            />
            {formErrorsMaxChoice && <span style={{ color: 'red' }}>max Choices must not be less than 1</span>}
            <Grid component="label" container alignItems="center" spacing={1}>
              <Grid item>visible to the customer</Grid>
              <Grid item>
                <Controller
                  as={SwitchComponent}
                  name='open'
                  label=""
                  control={control}
                  defaultValue={true}

                />
              </Grid>
              <Grid item>always visible</Grid>
            </Grid>
            <CustomeControllSelect
              list={typeList}
              name='type'
              label='type'
              inputProps={{
                inputRef: (ref) => {
                  if (!ref) return;
                  register({
                    name: "type",
                    value: ref.value,
                  });
                }
              }}
            />
            {formErrorsType && <span style={{ color: 'red' }}>This field is required</span>}
            <CustomeControllSelect
              list={priceConfigList}
              name='priceconfig'
              label='price config'
              inputProps={{
                inputRef: (ref) => {
                  if (!ref) return;
                  register({
                    name: "priceconfig",
                    value: ref.value,
                  });
                }
              }}
            />

            {formErrorsPriceconfig && <span style={{ color: 'red' }}>This field is required</span>}
            <Box textAlign="center">
              <Button
                round
                type="submit"
                color="success"
                // size="sm"
                disabled={isLoading}
                loading={isLoading}
                loaderColor="white"
                onClick={handleClick}
              >
                Add
              </Button>
            </Box>
          </div>
        </DragDropContext>
      </form>
    </div>
  )
}

CreateSpecification.propTypes = {
  onItemCreated: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
};
