import React, { useEffect, useState } from 'react';
import { getFlags } from '../services/serverApi.service';
// import DataTableContainerComponent from '../../../components/DataTableContainer.component';
import DataTableContainerComponent from '../../../components/DataTableSortingContainer.component';
import CreateFlagComponent from '../components/CreateFlag.component';
import { formatTime } from '../../../helpers/index'

export default function FlagPage() {
  const [resultsData, setResultsData] = useState({
    total: 0,
    items: [],
    tableItems: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const pages = [20, 50, 100];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(pages[page])
  const [pageSelect, setPageSelect] = useState(0);
  const [numberOfRows, setNumberOfRows] = useState(0);


  const handlePaginate = (val) => {
    console.log(val, 'val');
    console.log(val.pageSelect, val.numberOfRows);
    setResultsData({
      items: [],
      tableItems: [],
    });
    loadFlagsList(val.pageSelect, val.numberOfRows);
    setPageSelect(val.pageSelect);
    setNumberOfRows(val.numberOfRows);
  };
  const loadFlagsList = (skip,limit) => {
    setIsLoading(true);
    getFlags(skip, limit, true)
      .then(({ data }) => {
        setResultsData({
          total: data.total,
          items: data.tagCategories.map((item) => {
            return {
              ...item,
              name: (item.name && item.name != null) ? item.name : '--',
              createdAt: item.createdAt ? formatTime(item.createdAt) : '--'
              
            };
          }),
          tableItems: data.tagCategories.map((item) => {
            return {
              ...item,
              name: (item.name && item.name != null) ? item.name : '--',
              createdAt: item.createdAt ? formatTime(item.createdAt) : '--'
            };
          }),
         
         
        });

      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // useEffect(() => {
  //   loadFlagsList(page, rowsPerPage);
  // }, []);
  useEffect(() => {
    loadFlagsList(page, rowsPerPage);
  }, [page, rowsPerPage]);

  return (
    <>

      <DataTableContainerComponent
        total={resultsData.total}
        resultsData={resultsData}
        initData={resultsData.items}
        columns={[

          {
            Header: 'Name',
            accessor: 'name',
            customWidth: '500px',
          },
          {
            Header: 'createdAt',
            accessor: 'createdAt',
            customWidth: '500px', 
          },
          
        ]}
        onPaginate={handlePaginate}
        isPaginate={true}
        title="Flag List"
        isLoading={isLoading}
        loadItemsList={loadFlagsList}
        CreateItemFormComponent={CreateFlagComponent}
        
      />

    </>
  );
}
