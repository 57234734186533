import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getUserDataState } from '../features/auth/redux/selectors';

export default function AuthRoute({ children, type, ...rest }) {
  const userData = useSelector(getUserDataState);
  // console.log(rest)
  return (
    <Route
      {...rest}
      render={() => {
        if (type === 'guest' && userData) return <Redirect to="/admin" />;
        if (type === 'private' && !userData) return <Redirect to="/login" />;

        return children;
      }}
    />
  );
}

AuthRoute.propTypes = {
  children: PropTypes.any.isRequired,
  type: PropTypes.oneOf(['guest', 'private']),
};
