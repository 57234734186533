import React, { useState, useEffect, useRef } from 'react';
import { useAlert } from 'react-alert';
import DataTableContainerComponent from '../../../components/DataTableSortingContainer.component';
import {
  getItem,
  updateItem,
  getCategory,
  getSpecification,
} from '../services/serverAPI.service';
import CreateItem from '../components/CreateItem';
import CustomButton from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import { makeStyles, Box, Grid } from '@material-ui/core';
import SwitchComponent from '../../../components/Switch.component';
import DeleteItem from '../components/DeleteItem';
import EditeItem from '../components/EditeItem';
import EditPromo from '../components/EditPromo';
import EditPrice from '../components/EditPrice';
import EditFlag from '../components/EditFlag';
import SpecificationMappingComponent from '../components/SpecificationMapping.component';
import ModalComponent from '../../../components/Modal.component';
import { getFlags } from '../../delivery-info/services/serverApi.service';

export default function Item() {
  const alert = useAlert();
  const id = localStorage.getItem('storeId');
  const [searchText, setSearchText] = useState('');
  const [category, setCategory] = useState([]);
  const [allSpecifications, setAllSpecifications] = useState([]);
  const [flag, setFlag] = useState([]);
  var categoryList = [];
  const [resultsData, setResultsData] = useState({
    total: 0,
    items: [],
    tableItems: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const pages = [25, 50, 100];
  const [rowsPerPage, setRowsPerPage] = useState(pages[page]);
  const [openModal, setOpenModal] = useState('');
  const [total, setTotal] = useState(0);
  const [item, setItem] = useState([]);
  const [items, setItems] = useState([]);
  const [IndexItem, setIndexItem] = useState(-1);
  const [pageSelect, setPageSelect] = useState(0);
  const [numberOfRows, setNumberOfRows] = useState(0);
  const inpRef = useRef();

  const featchFalg = () => {
    getFlags(0, 0, false).then(({ data }) => {
      console.log('flag list', data);
      setFlag(data);
    });
  };
  const getAllSpecification = () => {
    getSpecification(id)
      .then(({ data }) => setAllSpecifications(data))
      .catch((error) => console.log(error));
  };
  const closeModal = () => {
    setOpenModal('');
  };

  const SearchComponent = () => {
    return (
      <Grid container>
        <Grid item xs={8}>
          <input
            type="text"
            ref={inpRef}
            placeholder="Item search"
            style={{
              border: 'none',
              borderBottom: '1px solid black',
            }}
            defaultValue={searchText}
          />
        </Grid>

        <Grid item xs={4} container justify="flex-end">
          <Box textAlign="left">
            <CustomButton
              round
              type="submit"
              color="success"
              size="sm"
              loaderColor="white"
              onClick={() => {
                setSearchText(inpRef.current.value);
                pageSelect > 0 && setPageSelect(0);
                loadItemList(
                  pageSelect > 0 ? 0 : pageSelect,
                  numberOfRows,
                  inpRef.current.value,
                );
              }}
            >
              find Item
            </CustomButton>
          </Box>
          <Box textAlign="left">
            <CustomButton
              round
              type="submit"
              color="google"
              size="sm"
              loaderColor="white"
              onClick={() => {
                setSearchText('');
                pageSelect > 0 && setPageSelect(0);
                loadItemList(pageSelect > 0 ? 0 : pageSelect, numberOfRows);
              }}
            >
              clear
            </CustomButton>
          </Box>
        </Grid>
        <Grid item xs={12} container spacing={2}></Grid>
      </Grid>
    );
  };
  const handlePaginate = (val) => {
    console.log(val, 'val');
    console.log(val.pageSelect, val.numberOfRows);
    setResultsData({
      items: [],
      tableItems: [],
    });
    loadItemList(val.pageSelect, val.numberOfRows, searchText);
    setPageSelect(val.pageSelect);
    setNumberOfRows(val.numberOfRows);
  };

  const updateIemFromSwitch = (key, key1, value, item) => {
    key == 'promoSetting' ? (item[key][key1] = value) : (item[key1] = value);

    console.log(JSON.stringify(item));
    updateItem(item)
      .then(() => {
        alert.show('item updated', {
          type: 'success',
        });
        loadItemList(page, rowsPerPage);
      })
      .catch((error) => {
        alert.show(error.message, {
          type: 'error',
        });
      });
  };
  const handleOpenModal = (item, index, type) => {
    // console.log('type', type, index, item);
    setItem(item);
    setIndexItem(index);
    setOpenModal(type);
  };
  const showItems = (item, index) => {
    return {
      ...item,
      index: index,
      name: item.name || '--',
      description: item.description ? item.description : '--',
      // price: item.price?item.price:'--',
      priceCel: (
        <div
          style={{ cursor: 'pointer', textAlign: 'center' }}
          onClick={() => {
            handleOpenModal(item, index, 'price');
          }}
        >
          {item?.price || '--'}
        </div>
      ),
      imageUrlCell: item.imageUrl ? (
        <img src={item.imageUrl} style={{ width: '50px', height: '50px' }} />
      ) : (
        '--'
      ),

      isMainPromoCol: (
        <SwitchComponent
          onChange={(value) =>
            updateIemFromSwitch('isMainPromo', 'isMainPromo', value, item)
          }
          value="checked"
          checked={item.isMainPromo}
          label="isMainPromo"
        />
      ),
      inStockCol: (
        <SwitchComponent
          onChange={(value) =>
            updateIemFromSwitch('inStock', 'inStock', value, item)
          }
          value="checked"
          checked={item.inStock}
          label="inStock"
        />
      ),
      specification: (
        <div
          style={{ cursor: 'pointer' }}
          variant="outlined"
          onClick={() => {
            handleOpenModal(item, index, 'specification');
          }}
        >
          {item.specifications ? item.specifications.length : 0}
        </div>
      ),
      // type:item.promoSetting?.type?item.promoSetting.type:'--',
      type: (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            handleOpenModal(item, index, 'EditPromo');
          }}
        >
          {item.promoSetting?.type ? item.promoSetting.type : '--'}
        </div>
      ),
      productList: category.find((element) => element._id == item.productId)
        ?.name,

      enabled: (
        <SwitchComponent
          onChange={(value) =>
            updateIemFromSwitch('promoSetting', 'enabled', value, item)
          }
          value="checked"
          checked={item.promoSetting.enabled}
          label="enabled"
        />
      ),
      flagCell: (
        <div
          style={{ cursor: 'pointer', textAlign: 'center' }}
          onClick={() => {
            handleOpenModal(item, index, 'flag');
          }}
        >
          {item?.flag || '--'}
        </div>
      ),
    };
  };
  const loadItemList = (skip, limit, searchText) => {
    setIsLoading(true);
    getItem(id, true, skip, limit, searchText)
      .then(({ data }) => {
        // setItem(data.items);
        console.log('this is search', data);
        if (data) {
          data.items && setItems(data.items);
          data.total && setTotal(data.total);
        }
      })
      .catch((error) => {
        setItem([]);
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const getAllCategory = () => {
    getCategory(id)
      .then(({ data }) => {
        categoryList = data;
        setCategory(data);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    featchFalg();
    getAllCategory();
    getAllSpecification();
    // loadItemList(page, rowsPerPage)
  }, []);
  // useEffect(() => {
  //   // categoryList.length> 0&&
  //   loadItemList(page, rowsPerPage);
  // }, []);
  useEffect(() => {
    category &&
      setResultsData({
        items: (items || []).map((item, index) => {
          return showItems(item, index);
        }),
      });
  }, [category, JSON.stringify(items)]);
  console.log('items', items);

  const customColumnStyle = {
    // wordWrap: "break-word",
    maxWidth: '150px',
    minWidth: '150px',
  };
  return (
    <>
      <DataTableContainerComponent
        total={total}
        resultsData={resultsData}
        initData={resultsData.items}
        customColumnStyle={customColumnStyle}
        columns={[
          {
            Header: 'Name',
            accessor: 'name',
          },
          {
            Header: 'Description',
            accessor: 'description',
          },
          {
            Header: 'Price',
            accessor: 'priceCel',
          },
          {
            Header: 'productList',
            accessor: 'productList',
          },
          {
            Header: 'Image',
            accessor: 'imageUrlCell',
          },
          {
            Header: 'isMainPromo',
            accessor: 'isMainPromoCol',
          },
          {
            Header: 'inStock',
            accessor: 'inStockCol',
          },
          {
            Header: 'partnerId',
            accessor: 'partnerId',
          },
          {
            Header: 'specification',
            accessor: 'specification',
          },
          {
            Header: 'promo(type)',
            accessor: 'type',
          },
          {
            Header: 'promo(enabled)',
            accessor: 'enabled',
          },
          {
            Header: 'flag',
            accessor: 'flagCell',
          },
          {
            Header: 'actions',
            accessor: 'actions',
          },
        ]}
        onPaginate={handlePaginate}
        isPaginate={true}
        title="Item List"
        SearchComponent={SearchComponent}
        searchInp={true}
        isLoading={isLoading}
        loadItemsList={loadItemList}
        page={pageSelect + 1}
        CreateItemFormComponent={CreateItem}
        actionButtons={{
          // miniEdit: {
          //     color: 'success',
          //       type: 'miniEdit',
          //        component:EditPromo
          // },
          edit: {
            color: 'success',
            type: 'edit',
            props: { resultsData: resultsData, setResultsData: setResultsData },
            component: EditeItem,
          },
          delete: {
            color: 'danger',
            type: 'delete',
            component: DeleteItem,
          },
        }}
      />
      {openModal && openModal === 'EditPromo' && (
        <ModalComponent
          maxWidth={false}
          close={closeModal}
          visible={openModal === 'EditPromo'}
          title="promo"
        >
          <EditPromo
            data={item}
            close={closeModal}
            index={IndexItem}
            onItemEdited={loadItemList}
          />
        </ModalComponent>
      )}
      {openModal && openModal === 'price' && (
        <ModalComponent
          maxWidth={'sm'}
          close={closeModal}
          visible={openModal === 'price'}
          title="price"
        >
          <EditPrice
            result={item}
            // row={item}
            setItems={setItems}
            items={items}
            close={closeModal}
            index={IndexItem}
            onItemEdited={loadItemList}
          />
        </ModalComponent>
      )}
      {openModal && openModal === 'specification' && (
        <ModalComponent
          maxWidth={false}
          close={closeModal}
          visible={openModal === 'specification'}
          title="specification Mapping"
        >
          <SpecificationMappingComponent
            data={item}
            close={closeModal}
            index={IndexItem}
            onItemEdited={loadItemList}
            allSpecifications={allSpecifications}
          />
        </ModalComponent>
      )}
      {openModal && openModal === 'flag' && (
        <ModalComponent
          maxWidth={'sm'}
          close={closeModal}
          visible={openModal === 'flag'}
          title="flag"
        >
          <EditFlag
            result={item}
            // row={item}
            setItems={setItems}
            items={items}
            close={closeModal}
            index={IndexItem}
            onItemEdited={loadItemList}
            flags={flag}
          />
        </ModalComponent>
      )}
    </>
  );
}
