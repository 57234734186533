import React, { useEffect, useState } from 'react';
import TablePagination from '@material-ui/core/TablePagination';
import DataTableContainerComponent from '../../../components/DataTableContainer.component';
import DataTableSortingContainer from '../../../components/DataTableSortingContainer.component';
import ViewItemComponent from '../components/ViewItem.component';
import { getCategoriesAppUser } from '../services/serverApi.service';
import CreateCategoryAppUserComponent from '../components/CreateCategoryAppUser.component';
import EditCategoryAppUserComponent from '../components/EditCategoryAppUser.component';
import DeleteCategoryAppUserComponent from '../components/DeleteCategoryAppUser.component';
import { getCategoryTypeList } from '../../setting/services/serverApi.service';

export default function CategoryAppUserPage() {
  const [resultsData, setResultsData] = useState({
    total: 0,
    items: [],
    tableItems: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const pages = [25, 50, 100];
  const [page, setPage] = useState(0);
  const [baseNames, setBaseNames] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(pages[page]);
  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'img',
      accessor: 'iconCol',
    },
    {
      Header: 'baseName',
      accessor: 'baseName',
    },
    {
      Header: 'city',
      accessor: 'cityListCol',
    },
    {
      Header: 'Action',
      accessor: 'actions',
    },
  ];
  const loadCategoriesAppUserList = () => {
    setIsLoading(true);
    getCategoriesAppUser()
      .then(({ data }) => {
        console.log(data);
        setResultsData({
          total: data.total,
          items: data.map((item) => {
            return {
              ...item,
              cityListCol: item.cityList
                ? item.cityList?.map((city) => (
                    <p cityId={city._id}>{city.name}</p>
                  ))
                : '--',
              iconCol: item.iconUrl ? (
                <img
                  id="IconUrlImag"
                  propsSrc={item.iconUrl}
                  src={item.iconUrl}
                  alt=""
                  border="3"
                  height="50"
                  width="60"
                />
              ) : (
                '--'
              ),
            };
          }),
          tableItems: data.map((item) => [
            // eslint-disable-next-line no-underscore-dangle
            item._id,
            item.name,
            item.cityList
              ? item.cityList.map((city) => (
                  <p cityId={city._id}>{city.name}</p>
                ))
              : '--',
          ]),
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
    getCategoryTypeList()
      .then(({ data }) => {
        setBaseNames(data?.categoryType?.baseNames || []);
      })
      .catch((err) => console.log(err));
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(event.target.value);
  };
  // console.log('baseNames', baseNames);
  useEffect(() => {
    loadCategoriesAppUserList();
  }, [page, rowsPerPage]);

  return (
    <>
      {/*<TablePagination*/}
      {/*    component="div"*/}
      {/*    page={page}*/}
      {/*    rowsPerPageOptions={pages}*/}
      {/*    rowsPerPage={rowsPerPage}*/}
      {/*    count={resultsData.total}*/}
      {/*    onPageChange={handleChangePage}*/}
      {/*    onRowsPerPageChange={handleChangeRowsPerPage}*/}
      {/*  />*/}
      {/* <DataTableContainerComponent
      resultsData={resultsData}
      tableHead={['Name', 'Type','city','image','Action']}
      title="Service categories"
      isLoading={isLoading}
      loadItemsList={loadServiceCategoriesList}
      CreateItemFormComponent={CreateServiceCategoryComponent}
      actionButtons={{
        edit: {
          color: 'success',
          type: 'edit',
          component: EditServiceCategoryComponent,
        },
        delete: {
          color: 'danger',
           type: 'delete',
          component: DeleteSetviceCategoryComponent
        },
      }}
    /> */}
      <DataTableSortingContainer
        resultsData={resultsData}
        initData={resultsData.items}
        columns={columns}
        title="Category in App user"
        isLoading={isLoading}
        loadItemsList={loadCategoriesAppUserList}
        CreateItemFormComponent={CreateCategoryAppUserComponent}
        actionButtons={{
          edit: {
            color: 'success',
            type: 'edit',
            component: EditCategoryAppUserComponent,
            props: { baseNames: baseNames },
          },
          delete: {
            color: 'danger',
            type: 'delete',
            component: DeleteCategoryAppUserComponent,
          },
        }}
      />
    </>
  );
}
