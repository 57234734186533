import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Grid,
  Typography,
  Paper,
} from '@mui/material';
import SearchBar from 'material-ui-search-bar';
import { styled } from '@mui/material/styles';
import SelectBoxComponent from '../../../components/SelectBox.component';
import { filterDeliveryMenInAssignTable, switchForAvatarColor } from '../mock';
import { getDeliveryManInCity } from '../../dashboard/services/serverAPI.service';
import { getDistanceFromLatLonInKm } from '../../../helpers/index';
import { useSelector } from 'react-redux';
import { getUserDataState } from 'features/auth/redux/selectors';
import { resetObj } from 'utils/resetObj';
import { DATA } from '../../../utils/logAudit';
import { logOrder } from '../services/serverApi.service';
import AvatarGroup from 'react-avatar-group';
// import { getOrdersDataState } from '../redux/selectors';
import { acceptOrRejectOrder } from '../services/serverApi.service';

const useStyles = makeStyles({
  table: {
    minWidth: 50,
  },
});
export default function AssignOrderComponent({
  orderId,
  orders,
  admin,
  close,
}) {
  const classes = useStyles();
  const [deliveryInCity, setDeliverInCity] = useState([]);
  const [order, setOrder] = useState(orders.find((x) => x._id == orderId));
  const [rows, setRows] = useState([]);
  const [searched, setSearched] = useState();
  const [filter, setFilter] = useState(filterDeliveryMenInAssignTable.all);
  const [isLoading, setIsLoading] = useState(false);
  const tableHeader = [
    'ALARM',
    'name',
    'phone',
    'distance Store',
    'distance Client',
    'orders',
    'planified',
    'available',
    'dispatchable',
    'assign',
    'approved',
    'GPS',
    'BTS',
    'BTN',
    'dispatching Type',
  ];
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const accept_Or_Reject_Order = (accept, deliveryId) => {
    if (!!deliveryId) {
      setIsLoading(true);
      acceptOrRejectOrder(order._id, {
        deliveryManId: deliveryId,
        accept: accept,
        admin,
      }).then(({ data }) => {
        setIsLoading(false);
        close();
      });
    }
  };
  const orderLog = (row) => {
    console.log(order);
    // reset object before setting data :
    resetObj(DATA);
    // for logger audit data :
    DATA.admin = admin;
    DATA.actionType = 'Assigner Order';
    DATA.targetId = order.shortId;
    DATA.loggerDetails = {
      beforeValue: order?.deliveryMan?.name || 'Systeme',
      afterValue: row?.name,
    };
    // DATA.loggerDetails.beforeValue = order.deliveryMan.name !== '' ? order.deliveryMan.name : 'Systeme';
    // DATA.loggerDetails.afterValue = row.name;
    // console.log(DATA);
    // our post here :
    logOrder(DATA)
      .then(({ data }) => {
        console.log(data);
      })
      .catch((error) => {
        console.log(error.message, {
          type: 'error',
        });
      });
  };
  const getInfoAboutDelivery = (deliverMenList) => {
    const counts = {};
    setIsLoading(true);
    const tmpOrders = orders;
    tmpOrders.forEach(function (x) {
      counts[x?.deliveryMan?._id] = (counts[x?.deliveryMan?._id] || 0) + 1;
    });
    order &&
      deliverMenList &&
      deliverMenList.length > 0 &&
      deliverMenList.map((delivery) => {
        delivery['orders'] = counts[delivery._id] || 0;
        if (delivery.location) {
          delivery['distanceToStore'] = getDistanceFromLatLonInKm(
            order.store.location[0],
            order.store.location[1],
            delivery.location[0],
            delivery.location[1],
          );
          switch (order.payment.serviceType) {
            case 'Stores':
              delivery['distanceToClient'] = getDistanceFromLatLonInKm(
                order.payment.destinationAddress.location[0],
                order.payment.destinationAddress.location[1],
                delivery.location[0],
                delivery.location[1],
              );

              break;

            default:
              break;
          }
        }
      });
    setDeliverInCity(
      deliverMenList.sort((a, b) => a.distanceToStore - b.distanceToStore),
    );
    filterDeliveryMen(
      filter,
      deliverMenList.sort((a, b) => a.distanceToStore - b.distanceToStore),
    );
    setIsLoading(false);
  };
  const getDeliveryManList = (id) => {
    id !== 'all' && order && setIsLoading(true);
    getDeliveryManInCity(order.city._id)
      .then(({ data }) => {
        getInfoAboutDelivery(data.deliveryMen.filter((item) => item.approved));
      })
      .catch((e) => console.log(e))
      .finally(() => setIsLoading(false));
  };
  const requestSearch = (searchedVal) => {
    let filteredRows = [];
    switch (filter) {
      case filterDeliveryMenInAssignTable.all:
        filteredRows = deliveryInCity.filter((row) => {
          return row.name.toLowerCase().includes(searchedVal.toLowerCase());
        });
        setRows(filteredRows);
        break;
      case filterDeliveryMenInAssignTable.dispatchabel:
        filteredRows = deliveryInCity
          .filter((x) => x.planified && x.available && x.dispatchable)
          .filter((row) => {
            return row.name.toLowerCase().includes(searchedVal.toLowerCase());
          });
        setRows(filteredRows);
        break;
      case filterDeliveryMenInAssignTable.UnDispatchabel:
        filteredRows = deliveryInCity
          .filter((x) => !x.planified || !x.available || !x.dispatchable)
          .filter((row) => {
            return row.name.toLowerCase().includes(searchedVal.toLowerCase());
          });
        setRows(filteredRows);
        break;

      default:
        break;
    }
  };

  const cancelSearch = () => {
    setSearched('');
    requestSearch(searched);
  };
  const filterDeliveryMen = (value, deliveryInCity) => {
    setFilter(value);
    switch (value) {
      case filterDeliveryMenInAssignTable.all:
        setRows(deliveryInCity);
        break;
      case filterDeliveryMenInAssignTable.dispatchabel:
        setRows(
          deliveryInCity.filter(
            (x) => x.planified && x.available && x.dispatchable,
          ),
        );
        break;
      case filterDeliveryMenInAssignTable.UnDispatchabel:
        setRows(
          deliveryInCity.filter(
            (x) => !x.planified || !x.available || !x.dispatchable,
          ),
        );
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    orders && order && getDeliveryManList(order.city._id);
  }, [order, orders]);
  return (
    <div>
      <Grid container className="mb-2">
        <Grid item xs={6} style={{ display: 'flex' }}>
          orderID : <Typography>{order?.shortId}</Typography>
        </Grid>
        <Grid item xs={6} style={{ display: 'flex' }}>
          store : <Typography>{order?.store?.name}</Typography>
        </Grid>
        <Grid item xs={6} style={{ display: 'flex' }}>
          customer : <Typography>{order?.customer?.name}</Typography>
        </Grid>
        <Grid item xs={6} style={{ display: 'flex' }}>
          deliveryMan : <Typography>{order?.deliveryMan?.name}</Typography>
        </Grid>
        <Grid item xs={6} style={{ display: 'flex' }}>
          <SelectBoxComponent
            items={Object.keys(filterDeliveryMenInAssignTable).map((type) => ({
              value: type,
              label: type,
            }))}
            inputLabel="display deliveryMen with"
            onValueChange={(items) => filterDeliveryMen(items, deliveryInCity)}
            value={filter}
          />
        </Grid>
      </Grid>
      <SearchBar
        value={searched}
        onChange={(searchVal) => requestSearch(searchVal)}
        onCancelSearch={() => cancelSearch()}
      />
      <div className=" mt-2">
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table
              className={classes.table}
              stickyHeader
              aria-label="sticky table"
            >
              <TableHead>
                <TableRow>
                  {tableHeader &&
                    tableHeader.map((item) => (
                      <TableCell align="center">{item}</TableCell>
                    ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {(rows || []).map((row) => (
                  <StyledTableRow key={row._id}>
                    <TableCell
                      align="center"
                      style={{ display: 'inline-flex' }}
                    >
                      {row?.alarms &&
                        row?.alarms?.length > 0 &&
                        row?.alarms?.map((alarm) => (
                          <AvatarGroup
                            avatars={[alarm?.name.split('_').join(' ')]}
                            initialCharacters={alarm?.name.split('_').length}
                            max={3}
                            size={25}
                            displayAllOnHover
                            shadow={2}
                            fontSize={0.4}
                            backgroundColor={switchForAvatarColor(
                              alarm?.status,
                            )}
                          />
                        ))}
                    </TableCell>
                    <TableCell align="center">{row.name}</TableCell>
                    <TableCell align="center">{row.phone}</TableCell>
                    <TableCell align="center">{row.distanceToStore}</TableCell>
                    <TableCell align="center">{row.distanceToClient}</TableCell>
                    <TableCell align="center">{row.orders}</TableCell>
                    <TableCell
                      align="center"
                      style={{ color: row.planified ? 'green' : 'red' }}
                    >
                      {row.planified ? 'yes' : 'no'}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ color: row.available ? 'green' : 'red' }}
                    >
                      {row.available ? 'yes' : 'no'}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ color: row.dispatchable ? 'green' : 'red' }}
                    >
                      {row.dispatchable ? 'yes' : 'no'}
                    </TableCell>

                    <TableCell align="center">
                      <Button
                        size="sm"
                        variant="text"
                        color="primary"
                        onClick={() => {
                          accept_Or_Reject_Order(true, row._id);
                          orderLog(row);
                        }}
                      >
                        assigned
                      </Button>
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ color: row.approved ? 'green' : 'red' }}
                    >
                      {row.approved ? 'yes' : 'no'}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ color: row.GPSOn ? 'green' : 'red' }}
                    >
                      {row.GPSOn ? 'yes' : 'no'}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ color: !row.batteryCritical ? 'green' : 'red' }}
                    >
                      {!row.batteryCritical ? 'yes' : 'no'}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ color: row.batteryLevel < 15 ? 'green' : 'red' }}
                    >
                      {parseInt(row?.batteryLevel * 100)}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        color: row?.dispatchingType == 'AUTO' ? 'green' : 'red',
                      }}
                    >
                      {row?.dispatchingType}
                    </TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </div>
  );
}
