export const virementListTypes = [
  'none',
  'TRANSFER_COMMISSION',
  'TRANSFERT_SALARY',
  'TRANSFERT_BALANCE',
  'TRANSFERT_BALANCE_TO_ZERO',
  'TRANSFERT_CASH_OUT',
  'REGULARISATION_BALANCE',
  'QUALITY_BALANCE',
];
export const balanceListTypes = [
  'none',
  'PAID_TO_STORE',
  'CASH_PAID_BY_CUSTOMER',
  'TRANSFERT_BALANCE',
  'TRANSFERT_BALANCE_TO_ZERO',
  'TRANSFERT_CASH_OUT',
  'ADJUSTEMENT_BALANCE',
  'REGULARISATION_BALANCE',
];
export const commissionListTypes = [
  'none',
  'ORDER_COMMISSION',
  'BONUS_COMMISSION',
  'ADJUSTEMENT_COMMISSION',
  'COMMISSION_DELIVERY_PARTNER'
];

export const filtersProviders = {
  All: 'All',
  dispatchable: 'dispatchable',
  Ndispatchable: 'Ndispatchable',
  planified: 'planified',
  Nplanified: 'Ndplanified',
  available: 'available',
  Navailable: 'Navailable',
  GPSOn: 'GPSOn',
  GPSOff: 'GPSOff',
  AppActive: 'AppActive',
  AppBackgound: 'AppBackgound',
  AppInactive: 'AppInactive',
};
export const invalidTxt = {
  inValidUser: 'IU',
  inValidPosition: 'IA',
  inValidNewUser: 'NU',
  inValidPhone: 'IM',
};
export const searchOrdersHistoryType = ['', 'driver', 'customer'];
export const orderFilterArray = ['All', 'WAITING_FOR_ACCEPT', 'ACCEPTED'];
export const allArray = ['all'];
export const orderServiceType = {
  Stores: 'Stores',
  Express: 'Express',
  Choubik: 'Choubik',
  Drive: 'Drive',
}
export const deliveryManStatusBeforeArrivedToStore = [
  'WAITING_FOR_ACCEPT',
  'ACCEPTED',
  'START_PREPARATION',
  'COMING_FOR_DELIVERY',
  'ARRIVED_TO_PICKUP',
]
export const deliveryManStatusAfterArrivedToStore = [
  'UPLOAD_INVOICE',
  'STARTED_DELIVERY',
  'DELIVERED',
]
export const regroupmentOrderTypes = {
  chronologic: 'chronologic',
  byStatus: 'byStatus'
}
export const statusTypes = {
  ON_HOLD: 'ON_HOLD',
  WAITING_FOR_ACCEPT: 'WAITING_FOR_ACCEPT',
  ACCEPT_TO_INVOICE_NP: 'ACCEPT_TO_iNVOICE_NP',
  ACCEPT_TO_INVOICE_P: 'ACCEPT_TO_iNVOICE_P',
  STARTED_DELIVERY_TO_DELIVRED: 'STARTED_DELIVERY_TO_DELIVRED',

}
export const accptedToArrivedStausArray = [
  'ACCEPTED',
  'START_PREPARATION',
  'COMING_FOR_DELIVERY',
  'ARRIVED_TO_PICKUP',
  'UPLOAD_INVOICE',
]
export const startDeliveryToDelivredStausArray = [
  'STARTED_DELIVERY',
  'ARRIVED_TO_DESTINATION',
]
export const filterDeliveryMenInAssignTable = {
  all: 'all',
  dispatchabel: 'dispatchabel',
  UnDispatchabel: 'UnDispatchabel'
}

export const switchForPaymentMethod = (method) => {
  switch (method) {
    case 'Cash':
      return 'primary'
    case 'Card':
      return 'secondary'
    case 'KaalixUP':
      // return 'inherit'
      return 'warning'
    case 'KaalixPay':
      return 'error'
    default:
      return 'inherit'
  }
}

// {/* disp :#2ecc71 */}
// {/* acqui :#ff7a04 */}
// {/* app :#f44336 */}
export const switchForAvatarColor = (status) => {
  // console.log(status);
  switch (status) {
    case 'apparition':
      return '#f44336'
    case 'disparition':
      return '#2ecc71'
    case 'acquitement':
    case 'Acquittement':
      return '#ff7a04'
    default:
      return '#000'
  }
}
export const statusBackgroundColor = {
  ON_HOLD: '#f246f4',
  INVALID: 'red',
  WAITING_FOR_ACCEPT: '#ffc107',
  ACCEPTED: '#ff7986',
  START_PREPARATION: '#3496ff',

  COMING_FOR_DELIVERY: '#3496ff',
  ARRIVED_TO_PICKUP: '#3496ff',
  UPLOAD_INVOICE: '#46e06a',
  STARTED_DELIVERY: '#46e06a',
  ARRIVED_TO_DESTINATION: '#46e06a',
  DELIVERED: '#46e06a',
  default: '#000',
  END_PREPARATION: '#ff91ae'
}
export const statusBackgroundColorNp = {
  ON_HOLD: '#f246f4',
  INVALID: 'red',
  WAITING_FOR_ACCEPT: '#ffc107',
  ACCEPTED: '#ff7986',
  START_PREPARATION: '#6c757d',
  COMING_FOR_DELIVERY: '#6c757d',
  ARRIVED_TO_PICKUP: '#6c757d',
  UPLOAD_INVOICE: '#46e06a',
  STARTED_DELIVERY: '#46e06a',
  ARRIVED_TO_DESTINATION: '#46e06a',
  DELIVERED: '#46e06a',
  default: '#000',
  END_PREPARATION: '#ff91ae'
}

export const switchForStatusColor = (status) => {
  // console.log(status);
  switch (status) {
    case 'apparition':
      return '#f44336'
    case 'disparition':
      return '#2ecc71'
    case 'acquitement':
      return '#ff7a04'
    default:
      return '#000'
  }
}
export const allStatusForOrder = [
  'WAITING_FOR_ACCEPT',
  'ACCEPTED',
  'START_PREPARATION',
  'COMING_FOR_DELIVERY',
  'ARRIVED_TO_PICKUP',
  'UPLOAD_INVOICE',
  'STARTED_DELIVERY',
  'ARRIVED_TO_DESTINATION',
  'DELIVERED'
];
export const abbreviationForAlarmsName = {
  Retard: 'RETARD',
  WFA: 'WAITING_FOR_ACCEPT',
  AC: 'ACCEPTED',
  CFD: 'COMING_FOR_DELIVERY',
  ATP: 'ARRIVED_TO_PICKUP',
  UI: 'UPLOAD_INVOICE',
  SD: 'STARTED_DELIVERY ',
  ATDP: 'ARRIVED_TO_DESTINATION_POSITION',
  ATDT: 'ARRIVED_TO_DESTINATION_TIME',
  Preparation: 'Preparation',
  SP: 'START_PREPARATION',
  WAS: 'WAITING_FOR_ACCEPT_STORE',
  MANUEL: 'MANUEL',
}
export const columns = [
  {
    Header: 'Id Cmd',
    accessor: 'shortId',
    customWidth: '70px',
  },
  {
    Header: 'Date',
    accessor: 'createdAtInp',
    customWidth: '70px',
  },
  {
    Header: 'Ville',
    accessor: 'cityName',
    customWidth: '60px',
  },
  {
    Header: 'Client',
    accessor: 'clientName',
    customWidth: '120px',
  },
  {
    Header: 'Provider',
    accessor: 'deliveryManInp',
    customWidth: '120px',
  },

  {
    Header: 'Status',
    accessor: 'deliveryManStatusInp',
    customWidth: '120px',
  },
  {
    Header: 'Total',
    accessor: 'orderPriceCol',
    customWidth: '60px',
  },
  {
    Header: 'Payment',
    accessor: 'paymentMode',
    customWidth: '75px',
  },
  {
    Header: 'Trans',
    accessor: 'meanOfTrans',
    customWidth: '75px',
  },

  {
    Header: 'duration',
    accessor: 'duration',
    customWidth: '75px',
  },
  {
    Header: 'Support',
    accessor: 'validator',
    customWidth: '75px',
  },
  {
    Header: 'comment',
    accessor: 'lastComment',
    customWidth: '110px',
  },
  {
    Header: 'Alarmes',
    accessor: 'alarmes',
    customWidth: '110px',
  },
  {
    Header: 'Actions',
    accessor: 'menu',
    customWidth: '110px',
  },
];
export const dispatchingInfoColums = ['Ville', 'Orders', 'Mode', 'Invalide', 'Valide', 'WAS', 'SPPT', 'Manuel', 'On-hold', 'Dispatching', 'Assigned', 'Dispatched'
]
export const dispatchedArray = ['DISPATCHED', 'ASSIGNED']
export const statusCode = {
  NOT_FOUND: 404,
}
export const customColumnStyle = {
  maxWidth: '90px',
  minWidth: '90px',
  fontSize: '13px',
};
export const sharedStyleForStatus = {
  width: '120px',
  fontSize: '11px',
  padding: '5px',
  borderRadius: '4px',
  color: 'white',
};
export const iconsSharedStyle = {
  cursor: 'pointer',
  width: 21,
  height: 21,
  margin: 2,
  borderRadius: 2,
  padding: 2,
};


