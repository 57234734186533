import React, { useState, useEffect, useRef } from 'react';
import { useAlert } from 'react-alert';
import {
  createMoneyTransfer,
  createTransferBalance,
  createTransferSalaryGeneral,
  getDeliveryMenDetails,
  getProviderDetails,
  logProvider,
  setProvider,
  validerMoneyTransfer,
} from '../service/serverAPI.service';
import DataTableContainerComponent from '../../../components/DataTableSortingContainer.component';
import SwitchComponent from '../../../components/Switch.component';
import { formatTime } from '../../../helpers/index';
import DeleteProvider from '../components/DeleteProvider';
import EditProvider from '../components/EditProvider';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu/Menu';
import ModalComponent from '../../../components/Modal.component';
import OrderDetail from '../../orders/components/OrderDetail.component';
import TextField from '@material-ui/core/TextField';
import { Box, FormControl, Input, Grid } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import ButtonPro from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import { store } from '../../../redux/store';
import CustomButton from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import Fab from '@material-ui/core/Fab';
import DeleteIcon from '@material-ui/core/SvgIcon/SvgIcon';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import InputLabel from '@material-ui/core/InputLabel';
import NativeSelect from '@material-ui/core/NativeSelect';
import { fetchSetting } from '../../setting/services/serverApi.service';
import { resetObj } from 'utils/resetObj';
import { DATA } from '../../../utils/logAudit';
import { getUserDataState } from '../../auth/redux/selectors';
import { useSelector } from 'react-redux';

export default function ProviderBalance() {
  const [openModal, setOpenModal] = useState(false);
  const [openModalTarnserSalary, setOpenModalTarnserSalary] = useState(false);
  const [closeModal, setCloseModal] = useState(false);
  const [startIterval, setStartIterval] = useState(0);
  const [endIterval, setEndIterval] = useState(0);
  const [error, setError] = useState(false);
  const [motif, setMotif] = useState('');
  const [setting, setSetting] = useState([]);
  const [amountInterval, setAmountInterval] = useState(0);
  const inpRef = useRef();
  const alert = useAlert();
  const [resultsData, setResultsData] = useState({
    total: 0,
    items: [],
    tableItems: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [transactionType, setTransactionType] = useState('');
  const [page, setPage] = useState(0);
  const [provider, setProvider] = useState(null);
  const [providers, setProviders] = useState([]);
  const [montant, setMontant] = useState(0);
  const [comment, setComment] = useState('');
  let numberOfRows = [100, 500, 1000];
  const [rowsPerPage, setRowsPerPage] = useState(numberOfRows[0]);
  const [searchText, setSearchText] = useState('');
  const [total, setTotal] = useState('');
  const [approved, setApproved] = useState(true);
  const adminData = useSelector(getUserDataState);
  const [admin, setAdmin] = useState({
    _id: adminData.user._id,
    email: adminData.user.email,
    username: adminData.user.username,
  });
  // console.log(page+'page ',rowsPerPage);
  const updateProvider = (id, payload, type) => {
    // console.log(id,payload);
    setProvider(id, payload, type)
      .then(({ data }) => {
        alert.show(data.message, {
          type: 'success',
        });
        loadProviderBalanceList(page, rowsPerPage);
      })
      .catch((error) => {
        alert.show(error.message, {
          type: 'error',
        });
      });
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const modalTransfertSalary = () => {
    return (
      <ModalComponent
        maxWidth={'lg'}
        close={closeModal}
        visible={openModal}
        title={'TRANSFERT SALARY'}
      >
        <div className="col-12"></div>
      </ModalComponent>
    );
  };

  const SearchComponent = () => {
    return (
      <Grid container>
        <div className="col-12">
          <CustomButton
            round
            type="button"
            color="success"
            size="sm"
            loaderColor="white"
            onClick={openCreateTransferSalaryGeneral}
          >
            Transfer salary General
          </CustomButton>
          <CustomButton
            round
            type="button"
            color="google"
            size="sm"
            loaderColor="white"
            onClick={() => {
              setError(false);
              setStartIterval(0);
              setEndIterval(0);
              setAmountInterval(0);
              setOpenModal(true);
            }}
          >
            Transfer Balance by interval
          </CustomButton>
          {/* <Grid item xs={4} container justify="flex-end"> */}
          <SwitchComponent
            style={{ marginLeft: 15 }}
            onChange={(value) => setApproved(value)}
            checked={approved}
            label="Approved"
          />
          {/* </Grid> */}
        </div>
        <Grid item xs={8}>
          <input
            type="text"
            ref={inpRef}
            placeholder="provider search"
            style={{
              border: 'none',
              borderBottom: '1px solid black',
            }}
            defaultValue={searchText}
          />
        </Grid>

        <Grid item xs={4} container justify="flex-end">
          <Box textAlign="left">
            <CustomButton
              round
              type="submit"
              color="success"
              size="sm"
              loaderColor="white"
              // onClick={storeSearch}
              onClick={() => {
                // console.log(inpRef.current.value);
                setSearchText(inpRef.current.value);
                page > 0 && setPage(0);
                loadProviderBalanceList(
                  page > 0 ? 0 : page,
                  rowsPerPage,
                  approved,
                  inpRef.current.value,
                );
              }}
            >
              find Provider
            </CustomButton>
          </Box>
          <Box textAlign="left">
            <CustomButton
              round
              type="submit"
              color="google"
              size="sm"
              loaderColor="white"
              // onClick={loadCustomerList}
              onClick={() => {
                setSearchText('');
                page > 0 && setPage(0);
                loadProviderBalanceList(
                  page > 0 ? 0 : page,
                  rowsPerPage,
                  approved,
                  '',
                );
              }}
            >
              clear
            </CustomButton>
          </Box>
        </Grid>
      </Grid>
    );
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const prepareShowProvider = (item, index) => {
    return {
      ...item,
      name: item.name,
      city: item.cityId ? item.cityId : '--',
      phone: item.phone ? item.phone : '--',
      tips: item.tips ? item.tips : 0,
      email: item.email,
      balance: item.balance,
      commission: item.commission,
      approved: item.approved ? 'oui' : 'non',
      online: item.available ? 'oui' : 'non',
      createdAt: item.createdAt ? formatTime(item.createdAt) : '--',
      menu: (
        <div>
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={($event) => {
              setProvider(item);
              handleClick($event);
            }}
          >
            Open Menu
          </Button>
        </div>
      ),
    };
  };
  const loadProviderBalanceList = (skip, limit, approved, txt) => {
    setIsLoading(true);
    // console.log(skip+'aa', limit);
    getDeliveryMenDetails(skip, limit, '', approved, txt)
      .then(({ data }) => {
        setTotal(data.total);
        setProviders(data.deliveryMan);
      })
      .catch((error) => {
        alert.show(error.response.data?.message, {
          type: 'info',
        }),
          setResultsData({
            total: 0,
            items: [],
            tableItems: [],
          });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setSearchText('');
    page > 0 && setPage(0);
    loadProviderBalanceList(0, 20, approved, '');
  }, [approved]);
  useEffect(() => {
    providers &&
      providers.length > 0 &&
      setResultsData({
        items: providers.map((item, index) => {
          return prepareShowProvider(item, index);
        }),
      });
  }, [providers]);

  const handlePaginate = (val) => {
    setResultsData({
      items: [],
      tableItems: [],
    });
    loadProviderBalanceList(
      val.pageSelect,
      val.numberOfRows,
      approved,
      searchText,
    );
    setPage(val.pageSelect);
    setRowsPerPage(val.numberOfRows);
  };
  const handleOpenModal = (type) => {
    handleClose();
    setTransactionType(type);
  };
  const handleCloseModal = () => {
    setTransactionType('');
  };

  const create_Money_Transfer = () => {
    if (isNaN(montant)) return;
    const reduxState = store.getState();
    const moneyTransfer = {
      amount: montant,
      type: transactionType,
      status: 'PENDING',
      deliveryManId: provider._id,
      initiator: reduxState.authReducer.userData
        ? reduxState.authReducer.userData.user
        : null,
      comment: comment,
      motif: motif,
    };
    createMoneyTransfer(moneyTransfer).then(({ data }) => {
      if (data) {
        handleCloseModal();
        if (
          transactionType === 'TRANSFERT_CASH_OUT' ||
          transactionType === 'TRANSFERT_BALANCE_TO_ZERO'
        ) {
          let moneyTransferData = data.moneyTransfer;
          moneyTransferData.deliveryMan = provider;
          validerVirement(moneyTransferData);
          loadProviderBalanceList(page > 0 ? 0 : page, rowsPerPage, '');
        }
      }
    });
  };
  const create_transfer_balance = () => {
    const reduxState = store.getState();
    const moneyTransfer = {
      amount: amountInterval,
      initiator: reduxState.authReducer.userData
        ? reduxState.authReducer.userData.user
        : null,
      balance_interval: { start: startIterval, end: endIterval },
    };
    // console.log(moneyTransfer);
    createTransferBalance(moneyTransfer).then(({ data }) => {
      console.log(data);
      if (data) {
        setOpenModal(false);
      }
    });
  };
  const validerVirement = (moneyTransfer) => {
    moneyTransfer.status = 'DONE';
    validerMoneyTransfer(moneyTransfer).then(({ data }) => {
      // console.log(data);
      handleCloseModal();
    });
  };

  const createSalaryTransfer = () => {
    const reduxState = store.getState();
    const moneyTransfer = {
      initiator: reduxState.authReducer.userData
        ? reduxState.authReducer.userData.user
        : null,
    };
    createTransferSalaryGeneral(moneyTransfer).then(({ data }) => {
      if (data) {
        setOpenModalTarnserSalary(false);
      }
    });
  };
  const MySwal = withReactContent(Swal);

  const openCreateTransferSalaryGeneral = () => {
    setError(false);
    MySwal.fire({
      title: 'Transfert salaire pour les livreurs',
      text: 'Condition: commission > balance && commission > 0',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui !',
      cancelButtonText: 'Non',
    }).then((result) => {
      if (result.isConfirmed) {
        createSalaryTransfer();
        Swal.fire('Success!', '', 'success');
      }
    });
  };
  const fetchSettingFromAPI = () => {
    fetchSetting()
      .then(({ data }) => {
        if (data && data.length > 0) setSetting(data[0]);
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    fetchSettingFromAPI();
  }, []);
  return (
    <>
      <DataTableContainerComponent
        total={total}
        resultsData={resultsData}
        initData={resultsData.items}
        page={page + 1}
        columns={[
          {
            Header: 'Name',
            accessor: 'name',
          },
          {
            Header: 'city',
            accessor: 'city',
          },
          {
            Header: 'email',
            accessor: 'email',
          },
          {
            Header: 'Pourboire',
            accessor: 'tips',
          },
          {
            Header: 'balance',
            accessor: 'balance',
          },
          {
            Header: 'commission',
            accessor: 'commission',
          },
          {
            Header: 'Approved',
            accessor: 'approved',
          },
          {
            Header: 'created At',
            accessor: 'createdAt',
          },
          {
            Header: '',
            accessor: 'menu',
          },
        ]}
        title=" Provider List"
        isLoading={isLoading}
        loadItemsList={loadProviderBalanceList}
        onPaginate={handlePaginate}
        isPaginate={true}
        searchInp={true}
        SearchComponent={SearchComponent}
      />
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          style={{ color: '#1c9764', backgroundColor: '#ffffff' }}
          onClick={() => {
            setMontant(0);
            setComment('');
            handleOpenModal('TRANSFERT_BALANCE');
          }}
        >
          Virement balance
        </MenuItem>
        <MenuItem
          style={{
            color: provider && provider?.commission > 0 ? '#1c9764' : 'grey',
            backgroundColor: '#ffffff',
          }}
          onClick={() => {
            setMontant(0);
            setComment('');
            if (provider.commission > 0) handleOpenModal('TRANSFER_COMMISSION');
          }}
        >
          Virement commission (c>0)
        </MenuItem>
        <MenuItem
          style={{
            color:
              provider && provider?.commission > provider.balance
                ? '#1c9764'
                : 'grey',
            backgroundColor: '#ffffff',
          }}
          onClick={() => {
            if (provider.commission > provider.balance) {
              setMontant(provider.commission - provider.balance);
              setComment('');
              handleOpenModal('TRANSFERT_SALARY');
            }
          }}
        >
          Virement salaire (c>b)
        </MenuItem>
        <MenuItem
          style={{
            color:
              provider &&
              provider?.commission > provider?.balance &&
              provider?.balance > 0
                ? 'black'
                : 'grey',
            backgroundColor: '#ffe8c4',
          }}
          onClick={() => {
            if (
              provider.commission > provider.balance &&
              provider.balance > 0
            ) {
              setMontant(provider.balance);
              setComment('');
              handleOpenModal('TRANSFERT_BALANCE_TO_ZERO');
            }
          }}
        >
          Commission balance a 0
        </MenuItem>
        <MenuItem
          style={{
            color:
              provider &&
              provider.commission >= provider.balance &&
              provider.balance > 0
                ? 'black'
                : 'grey',
            backgroundColor: '#ffe8c4',
          }}
          onClick={() => {
            if (
              provider.commission >= provider.balance &&
              provider.balance > 0
            ) {
              setMontant(provider.balance);
              setComment('');
              handleOpenModal('TRANSFERT_CASH_OUT');
            }
          }}
        >
          Cash out (C>=B,B>0)
        </MenuItem>
        <MenuItem
          style={{ color: 'black', backgroundColor: '#ffe8c4' }}
          onClick={() => {
            setMontant(0);
            setComment('');
            handleOpenModal('REGULARISATION_BALANCE');
          }}
        >
          Régularisation balance
        </MenuItem>
      </Menu>
      <ModalComponent
        maxWidth={'sm'}
        close={handleCloseModal}
        visible={
          transactionType === 'TRANSFERT_BALANCE' ||
          transactionType === 'REGULARISATION_BALANCE'
        }
        title={'Ajouter un (' + transactionType + ') en attente'}
      >
        {provider && (
          <div style={{ 'font-size': '16px', 'font-weight': '600' }}>
            {error && (
              <div style={{ color: 'red' }}>
                montant !== 0 , comment required
              </div>
            )}
            <div className="row p-2">
              <div className="col-4">Livreur :</div>
              <div className="col-8">{provider.name}</div>
            </div>
            <div className="row  p-2">
              <div className="col-4">Balance :</div>
              <div className="col-8">{provider.balance}</div>
            </div>
            <div className="row  p-2">
              <div className="col-4">commission :</div>
              <div className="col-8">{provider.commission}</div>
            </div>
            <div className="row  p-2">
              <div className="col-4">
                montant <span style={{ color: 'red' }}>(*)</span>
              </div>
              <div className="col-8">
                <FormControl variant="outlined" fullWidth>
                  <OutlinedInput
                    id="outlined-adornment-weight"
                    value={montant}
                    onChange={(e) => setMontant(e.target.value)}
                    endAdornment={
                      <InputAdornment position="end">DH</InputAdornment>
                    }
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      'aria-label': 'weight',
                      inputProps: { min: 0 },
                    }}
                    labelWidth={0}
                    error={error}
                  />
                </FormControl>
              </div>
            </div>
            {transactionType === 'REGULARISATION_BALANCE' && (
              <div className="row  p-2">
                <div className="col-4">
                  motif <span style={{ color: 'red' }}>(*)</span>
                </div>
                <div className="col-8">
                  <FormControl style={{ width: '100%' }}>
                    <NativeSelect
                      value={motif}
                      onChange={(e) => setMotif(e.target.value)}
                    >
                      <option disabled={true} value={''}>
                        --
                      </option>
                      {setting.regularisation_balance_reasons &&
                        setting.regularisation_balance_reasons.map((val) => (
                          <option value={val}>{val}</option>
                        ))}
                      <option value={'autre'}>autre</option>
                    </NativeSelect>
                  </FormControl>
                </div>
              </div>
            )}
            <div className="row  p-2">
              <div className="col-4">new blance</div>
              <div className="col-8">
                {parseFloat(provider.balance) + parseFloat(montant || 0)}
              </div>
            </div>
            <div className="row  p-2">
              <div className="col-4">new Commission</div>
              <div className="col-8">{parseFloat(provider.commission)}</div>
            </div>
            <div className="row  p-2">
              <div className="col-4">
                comment <span style={{ color: 'red' }}>(*)</span>
              </div>
              <div className="col-8">
                <TextField
                  style={{ width: '100%' }}
                  id="outlined-multiline-static"
                  label=""
                  multiline
                  rows={4}
                  defaultValue=""
                  variant="outlined"
                  onChange={(e) => setComment(e.target.value)}
                  error={error}
                  value={comment}
                />
              </div>
            </div>
            <div className="p-2">
              <Box textAlign="center">
                <ButtonPro
                  round
                  type="button"
                  color="success"
                  loading={isLoading}
                  loaderColor="white"
                  onClick={() => {
                    // handle transfer balance action
                    // reset Object before setting data :
                    resetObj(DATA);
                    // for logger audit data :
                    DATA.admin = admin;
                    DATA.targetId = provider._id;
                    DATA.actionType =
                      transactionType == 'REGULARISATION_BALANCE'
                        ? (DATA.motif = motif) &&
                          (DATA.actionType = 'Regularisation Balance')
                        : (DATA.actionType = 'Virment Balance');
                    DATA.target = 'Transaction';
                    DATA.loggerDetails = {
                      amount: montant,
                      beforeBalance: provider.balance,
                      afterBalance:
                        parseFloat(provider.balance) + parseFloat(montant),
                    };
                    // DATA.amount = montant;
                    // DATA.beforeBalance = provider.balance;
                    // DATA.afterBalance = (parseFloat(provider.balance) + parseFloat(montant));
                    // DATA.commissionBefore = provider.commission;
                    // DATA.commissionAfter = provider.commission;
                    DATA.comment = comment;
                    // our post here :
                    logProvider(DATA)
                      .then(({ data }) => {
                        console.log(data);
                      })
                      .catch((error) => {
                        console.log(error.message, {
                          type: 'error',
                        });
                      });
                    // console.log(
                    // DATA,
                    // provider._id,
                    // provider.balance,
                    // provider.commission,
                    // montant,
                    // (parseFloat(provider.balance) + parseFloat(montant)),
                    // comment,
                    // transactionType,
                    // motif,
                    // provider
                    // );
                    if (
                      transactionType === 'REGULARISATION_BALANCE' &&
                      motif === ''
                    ) {
                      return;
                    }
                    if (!isNaN(montant) && montant !== 0 && comment !== '')
                      create_Money_Transfer();
                    else setError(true);
                  }}
                >
                  valider
                </ButtonPro>
              </Box>
            </div>
          </div>
        )}
      </ModalComponent>
      <ModalComponent
        maxWidth={'sm'}
        close={handleCloseModal}
        visible={transactionType === 'TRANSFER_COMMISSION'}
        title="Ajouter un virement commission en attente"
      >
        {provider && (
          <div style={{ 'font-size': '16px', 'font-weight': '600' }}>
            <div>
              {error && (
                <span style={{ color: 'red' }}>commission >= montant</span>
              )}
            </div>
            <div className="row p-2">
              <div className="col-4">Livreur :</div>
              <div className="col-8">{provider.name}</div>
            </div>
            <div className="row  p-2">
              <div className="col-4">Balance :</div>
              <div className="col-8">{provider.balance}</div>
            </div>
            <div className="row  p-2">
              <div className="col-4">commission :</div>
              <div className="col-8">{provider.commission}</div>
            </div>
            <div className="row  p-2">
              <div className="col-4">montant</div>
              <div className="col-8">
                <FormControl variant="outlined" fullWidth>
                  <OutlinedInput
                    id="outlined-adornment-weight"
                    value={montant}
                    onChange={(e) => setMontant(e.target.value)}
                    endAdornment={
                      <InputAdornment position="end">DH</InputAdornment>
                    }
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      'aria-label': 'weight',
                    }}
                    labelWidth={0}
                  />
                </FormControl>
              </div>
            </div>
            <div className="row  p-2">
              <div className="col-4">New commission</div>
              <div className="col-8">
                <FormControl variant="outlined" fullWidth aria-readonly={true}>
                  <OutlinedInput
                    id="outlined-adornment-weight"
                    endAdornment={
                      <InputAdornment position="end">DH</InputAdornment>
                    }
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      'aria-label': 'weight',
                    }}
                    labelWidth={0}
                    value={
                      parseFloat(provider.commission) - parseFloat(montant || 0)
                    }
                  />
                </FormControl>
              </div>
            </div>
            <div className="row  p-2">
              <div className="col-4">comment</div>
              <div className="col-8">
                <TextField
                  style={{ width: '100%' }}
                  id="outlined-multiline-static"
                  label=""
                  multiline
                  rows={4}
                  defaultValue=""
                  variant="outlined"
                  onChange={(e) => setComment(e.target.value)}
                />
              </div>
            </div>
            <div className="row p-2">
              <Box textAlign="center">
                <ButtonPro
                  round
                  type="button"
                  color="success"
                  loading={isLoading}
                  loaderColor="white"
                  onClick={() => {
                    // reset Object before setting data :
                    resetObj(DATA);
                    // for logger audit data :
                    DATA.admin = admin;
                    DATA.targetId = provider._id;
                    DATA.actionType = 'Virment Commission'; // transactionType;
                    DATA.comment = comment;
                    DATA.target = 'Transaction';
                    DATA.loggerDetails = {
                      amount: montant,
                      commissionBefore: provider.commission,
                      commissionAfter:
                        parseFloat(provider.commission) - parseFloat(montant),
                    };
                    // DATA.amount = montant;
                    // DATA.commissionBefore = provider.commission;
                    // DATA.commissionAfter = (parseFloat(provider.commission) - parseFloat(montant));
                    // console.log(
                    //   DATA
                    // );
                    // our post here :
                    logProvider(DATA)
                      .then(({ data }) => {
                        console.log(data);
                      })
                      .catch((error) => {
                        console.log(error.message, {
                          type: 'error',
                        });
                      });
                    if (
                      parseFloat(provider.commission) >=
                      parseFloat(montant || 0)
                    ) {
                      create_Money_Transfer();
                    } else {
                      setError(true);
                    }
                  }}
                >
                  valider
                </ButtonPro>
              </Box>
            </div>
          </div>
        )}
      </ModalComponent>
      <ModalComponent
        maxWidth={'sm'}
        close={handleCloseModal}
        visible={transactionType === 'TRANSFERT_BALANCE_TO_ZERO'}
        title="Ajouter un virement (Balance To Zero) en attente"
      >
        {provider && (
          <div style={{ 'font-size': '16px', 'font-weight': '600' }}>
            <div>
              {error && (
                <span style={{ color: 'red' }}>commission >= balance</span>
              )}
            </div>
            <div className="row p-2">
              <div className="col-4">Livreur :</div>
              <div className="col-8">{provider.name}</div>
            </div>
            <div className="row  p-2">
              <div className="col-4">Balance :</div>
              <div className="col-8">{provider.balance}</div>
            </div>
            <div className="row  p-2">
              <div className="col-4">commission :</div>
              <div className="col-8">{provider.commission}</div>
            </div>
            <div className="row  p-2">
              <div className="col-4">montant</div>
              <div className="col-8">
                <TextField
                  fullWidth
                  value={montant}
                  id="outlined-basic"
                  variant="outlined"
                  inputProps={{
                    readOnly: true,
                    inputProps: { min: 0 },
                  }}
                  labelWidth={0}
                />
              </div>
            </div>
            <div className="row  p-2">
              <div className="col-4">new balance :</div>
              <div className="col-8">0</div>
            </div>
            <div className="row  p-2">
              <div className="col-4">new commission :</div>
              <div className="col-8">
                {(provider.commission - provider.balance || 0).toFixed(2)}
              </div>
            </div>
            <div className="row  p-2">
              <div className="col-4">comment</div>
              <div className="col-8">
                <TextField
                  style={{ width: '100%' }}
                  id="outlined-multiline-static"
                  label=""
                  multiline
                  rows={4}
                  defaultValue=""
                  variant="outlined"
                  onChange={(e) => setComment(e.target.value)}
                />
              </div>
            </div>
            <div className="p-2">
              <Box textAlign="center">
                <ButtonPro
                  round
                  type="button"
                  color="success"
                  loading={isLoading}
                  loaderColor="white"
                  onClick={() => {
                    // reset object before setting data :
                    resetObj(DATA);
                    // for logger audit data :
                    DATA.admin = admin;
                    DATA.targetId = provider._id;
                    DATA.actionType = 'Commission Balance a 0';
                    DATA.comment = comment;
                    DATA.target = 'Transaction';
                    DATA.loggerDetails = {
                      amount: montant,
                      beforeBalance: provider.balance,
                      commissionBefore: provider.commission,
                      commissionAfter: (
                        provider.commission - provider.balance || 0
                      ).toFixed(2),
                    };
                    // DATA.amount = montant;
                    // DATA.beforeBalance = provider.balance;
                    // DATA.commissionBefore = provider.commission;
                    // DATA.commissionAfter = (provider.commission - provider.balance || 0).toFixed(2);
                    // console.log(DATA);
                    // our post here :
                    logProvider(DATA)
                      .then(({ data }) => {
                        console.log(data);
                      })
                      .catch((error) => {
                        console.log(error.message, {
                          type: 'error',
                        });
                      });
                    // provider.commission > provider.balance
                    //   ? create_Money_Transfer()
                    //   : setError(true);
                  }}
                >
                  valider
                </ButtonPro>
              </Box>
            </div>
          </div>
        )}
      </ModalComponent>
      <ModalComponent
        maxWidth={'sm'}
        close={handleCloseModal}
        visible={transactionType === 'TRANSFERT_SALARY'}
        title="Ajouter un virement salaire en attente"
      >
        {provider && (
          <div style={{ 'font-size': '16px', 'font-weight': '600' }}>
            <div className="row p-2">
              <div className="col-4">Livreur :</div>
              <div className="col-8">{provider.name}</div>
            </div>
            <div className="row  p-2">
              <div className="col-4">balance :</div>
              <div className="col-8">{provider.balance}</div>
            </div>
            <div className="row  p-2">
              <div className="col-4">commission :</div>
              <div className="col-8">{provider.commission}</div>
            </div>
            <div className="row  p-2">
              <div className="col-4">montant</div>
              <div className="col-8">
                <TextField
                  fullWidth
                  value={montant}
                  id="outlined-basic"
                  variant="outlined"
                  inputProps={{
                    readOnly: true,
                  }}
                  labelWidth={0}
                />
              </div>
            </div>
            <div className="row  p-2">
              <div className="col-4">new balance :</div>
              <div className="col-8">0</div>
            </div>
            <div className="row  p-2">
              <div className="col-4">new commission :</div>
              <div className="col-8">0</div>
            </div>
            <div className="row  p-2">
              <div className="col-4">comment</div>
              <div className="col-8">
                <TextField
                  style={{ width: '100%' }}
                  id="outlined-multiline-static"
                  label=""
                  multiline
                  rows={4}
                  defaultValue=""
                  variant="outlined"
                  onChange={(e) => setComment(e.target.value)}
                />
              </div>
            </div>
            <div className="p-2">
              <Box textAlign="center">
                <ButtonPro
                  round
                  type="button"
                  color="success"
                  loading={isLoading}
                  loaderColor="white"
                  onClick={() => {
                    // reset object before setting data :
                    resetObj(DATA);
                    // for logger audit data :
                    DATA.admin = admin;
                    DATA.actionType = 'Virment Salaire';
                    DATA.targetId = provider._id;
                    DATA.target = 'Transaction';
                    DATA.loggerDetails = {
                      amount: montant,
                      beforeBalance: provider.balance,
                      commissionBefore: provider.commission,
                    };
                    // DATA.amount = montant;
                    // DATA.beforeBalance = provider.balance;
                    // DATA.commissionBefore = provider.commission;
                    // console.log(DATA);
                    // our post here :
                    logProvider(DATA)
                      .then(({ data }) => {
                        console.log(data);
                      })
                      .catch((error) => {
                        console.log(error.message, {
                          type: 'error',
                        });
                      });
                    create_Money_Transfer();
                  }}
                >
                  valider
                </ButtonPro>
              </Box>
            </div>
          </div>
        )}
      </ModalComponent>
      <ModalComponent
        maxWidth={'sm'}
        close={handleCloseModal}
        visible={transactionType === 'TRANSFERT_CASH_OUT'}
        title="Ajouter un virement (cash_out) en attente"
      >
        {provider && (
          <div style={{ 'font-size': '16px', 'font-weight': '600' }}>
            <div>
              {error && (
                <span style={{ color: 'red' }}>
                  provider.commission >= montant ; provider.commission >=
                  provider.balance ; provider.balance > 0
                </span>
              )}
            </div>
            <div className="row p-2">
              <div className="col-4">Livreur :</div>
              <div className="col-8">{provider.name}</div>
            </div>
            <div className="row  p-2">
              <div className="col-4">Balance :</div>
              <div className="col-8">{provider.balance}</div>
            </div>
            <div className="row  p-2">
              <div className="col-4">commission :</div>
              <div className="col-8">{provider.commission}</div>
            </div>
            <div className="row  p-2">
              <div className="col-4">montant</div>
              <div className="col-8">
                <FormControl variant="outlined" fullWidth>
                  <OutlinedInput
                    id="outlined-adornment-weight"
                    value={montant}
                    onChange={(e) => {
                      setMontant(e.target.value);
                    }}
                    endAdornment={
                      <InputAdornment position="end">DH</InputAdornment>
                    }
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      'aria-label': 'weight',
                    }}
                    labelWidth={0}
                  />
                </FormControl>
              </div>
            </div>
            <div className="row  p-2">
              <div className="col-4">new blance</div>
              <div className="col-8">{provider.balance - montant}</div>
            </div>
            <div className="row  p-2">
              <div className="col-4">new commission</div>
              <div className="col-8">{provider.commission - montant}</div>
            </div>
            <div className="row  p-2">
              <div className="col-4">comment</div>
              <div className="col-8">
                <TextField
                  style={{ width: '100%' }}
                  id="outlined-multiline-static"
                  label=""
                  multiline
                  rows={4}
                  defaultValue=""
                  variant="outlined"
                  onChange={(e) => setComment(e.target.value)}
                />
              </div>
            </div>
            <div className="p-2">
              <Box textAlign="center">
                <ButtonPro
                  round
                  type="button"
                  color="success"
                  loading={isLoading}
                  loaderColor="white"
                  onClick={() => {
                    // reset object before setting data :
                    resetObj(DATA);
                    // for logger audit data :
                    DATA.admin = admin;
                    DATA.actionType = 'Cash Out';
                    DATA.targetId = provider._id;
                    DATA.comment = comment;
                    DATA.target = 'Transaction';
                    DATA.loggerDetails = {
                      amount: montant,
                      beforeBalance: provider.balance,
                      afterBalance: provider.balance - montant,
                      commissionBefore: provider.commission,
                      commissionAfter: provider.commission - montant,
                    };
                    // DATA.amount = montant;
                    // DATA.beforeBalance = provider.balance;
                    // DATA.afterBalance = (provider.balance - montant);
                    // DATA.commissionBefore = provider.commission;
                    // DATA.commissionAfter = (provider.commission - montant);
                    // console.log(DATA);
                    // our post here :
                    logProvider(DATA)
                      .then(({ data }) => {
                        console.log(data);
                      })
                      .catch((error) => {
                        console.log(error.message, {
                          type: 'error',
                        });
                      });
                    if (
                      provider.commission >= montant &&
                      provider.commission >= provider.balance &&
                      provider.balance > 0
                    ) {
                      create_Money_Transfer();
                    } else {
                      setError(true);
                    }
                  }}
                >
                  valider
                </ButtonPro>
              </Box>
            </div>
          </div>
        )}
      </ModalComponent>
      <ModalComponent
        maxWidth={'lg'}
        close={() => {
          setOpenModal(false);
        }}
        visible={openModal}
        title={'TRANSFERT BALANCE'}
      >
        <div className="col-12">
          <p>
            Transfert Balance pour tous les livreur avec balance entre{' '}
            <strong style={{ color: 'green', fontWeight: '700' }}>
              {startIterval}
            </strong>{' '}
            et{' '}
            <strong style={{ color: 'green', fontWeight: '700' }}>
              {endIterval}
            </strong>{' '}
            avec une valeur de{' '}
            <strong style={{ color: 'green', fontWeight: '700' }}>
              {amountInterval}
            </strong>
          </p>
          <div className="px-2">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Start</th>
                  <th scope="col">End</th>
                  <th scope="col">Amount</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row" />
                  <td>
                    <div>
                      <TextField
                        id={'outlined-basic-ds'}
                        label="start"
                        variant="outlined"
                        type={'number'}
                        onChange={($event) => {
                          setStartIterval(parseFloat($event.target.value));
                        }}
                        value={startIterval}
                      />
                    </div>
                  </td>
                  <td>
                    <div style={{ display: 'inline-flex' }}>
                      <TextField
                        id={'outlined-basic-de'}
                        label="end"
                        variant="outlined"
                        type={'number'}
                        onChange={($event) => {
                          setEndIterval(parseFloat($event.target.value));
                        }}
                        value={endIterval}
                      />
                    </div>
                  </td>
                  <td>
                    <div style={{ display: 'inline-flex' }}>
                      <TextField
                        id={'outlined-basic-'}
                        label="amount"
                        variant="outlined"
                        type={'number'}
                        onChange={($event) => {
                          setAmountInterval(parseFloat($event.target.value));
                        }}
                        value={amountInterval}
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {!!error && <span>{'start < end and amount > 0'} </span>}
          <div className={'left'}>
            <ButtonPro
              round
              type="button"
              color="success"
              loading={isLoading}
              loaderColor="white"
              onClick={() => {
                if (amountInterval > 0 && startIterval < endIterval) {
                  create_transfer_balance();
                } else {
                  setError(true);
                }
              }}
            >
              valider
            </ButtonPro>
          </div>
        </div>
      </ModalComponent>
      <ModalComponent
        maxWidth={'lg'}
        close={() => {
          setOpenModal(false);
        }}
        visible={openModalTarnserSalary}
        title={'TRANSFERT SALARY'}
      >
        <div className="col-12">
          <div className={'right'}>
            <ButtonPro
              round
              type="button"
              color="danger"
              loading={isLoading}
              loaderColor="white"
              onClick={() => {
                setOpenModalTarnserSalary(false);
              }}
            >
              non
            </ButtonPro>
          </div>
          <div className={'left'}>
            <ButtonPro
              round
              type="button"
              color="success"
              loading={isLoading}
              loaderColor="white"
              onClick={() => {
                createSalaryTransfer();
              }}
            >
              oui
            </ButtonPro>
          </div>
        </div>
      </ModalComponent>
    </>
  );
}
