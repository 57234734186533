import React,{useState,useEffect} from 'react'
import { getTag } from '../services/serverApi.service';
import DataTableContainerComponent from '../../../components/DataTableSortingContainer.component';
import {formatTime} from '../../../helpers/index'
import DeleteTagsComponent from '../components/DeleteTags.component'
import EditTagsComponent from '../components/EditTags.component'

export default function TagsPage() {
    const [resultsData, setResultsData] = useState({
        total: 0,
        items: [],
      });
      const [isLoading, setIsLoading] = useState(false);
      // const classes = useStyles();
      // const pages = [10, 250, 500]
      const [page, setPage] = useState(0)
      let numberOfRows = [100,500,1000];
      const [rowsPerPage, setRowsPerPage] = useState(numberOfRows[0])
      // console.log(page+'page ',rowsPerPage);
    
      const loadTagsList = (skip, limit) => {
        setIsLoading(true);
        getTag(skip, limit,true)
          .then(({ data }) => {
            setResultsData({
              total: data.total,
              items: data.tagCategories.map((item) => {
                return {
                  ...item,
                  // name: item.name,
                  nbrOfTags: <span id={item._id}>{item.tags.length}</span>,
                };
              }),
           
            });
          })
          .catch(error => console.log(error))
          .finally(() => {
            setIsLoading(false);
          });
      };
      useEffect(() => {
        loadTagsList(page, rowsPerPage)
      }, [page, rowsPerPage]);
    
      const handlePaginate = (val) => {
        console.log(val.pageSelect, val.numberOfRows);
        setResultsData({
          items: [],
        });
        loadTagsList(val.pageSelect, val.numberOfRows);
      };
    
      
      return (
        <>
       <DataTableContainerComponent
       total={resultsData.total}
            resultsData={resultsData}
            initData={resultsData.items}
            columns={[
              
              {
                Header: 'Name',
                accessor: 'name',
              },
              {
                Header: 'nbrOfTags',
                accessor: 'nbrOfTags',
              },
              {
                Header: 'actions',
                accessor: 'actions',
              },
              
              
            ]}
            title="Tags List"
          isLoading={isLoading}
          loadItemsList={()=>loadTagsList(page, rowsPerPage)}
            // actionButtons={{
              actionButtons={{
              //     view: {
              //       color: 'info',
              //       type: 'view',
              //       component: ViewItemComponent,
              //     },
                  edit: {
                    color: 'success',
                    type: 'edit',
                    component: EditTagsComponent,
                  },
                  delete: { color: 'danger', type: 'delete',component: DeleteTagsComponent},
                }}
              onPaginate={handlePaginate}
                isPaginate={true}
          />
          </>
      );
}
