import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useAlert } from 'react-alert';
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import CustomInput from '../../../components/material-dashboard-pro-react/CustomInput/CustomInput';
import {
  createCategoryAppUser,
  getCities,
} from '../services/serverApi.service';
import ImageUpload from '../../../components/material-dashboard-pro-react/CustomUpload/ImageUpload';
import SelectBoxComponent from '../../../components/SelectBox.component';
import { getCategoryTypeList } from '../../setting/services/serverApi.service';

const validationSchema = yup.object({
  name: yup.string().required(),
  baseName: yup.string().required(),
  cityList: yup.array().required(),
  iconUrl: yup.string().optional(),
});

export default function CreateCategoryAppUserComponent({
  onItemCreated,
  close,
}) {
  const alert = useAlert();
  const [isLoading, setIsLoading] = useState(false);
  const [baseNames, setBaseNames] = useState([]);

  const upload = useSelector((state) => state.uploadReducer);
  const { iconUrl, error, loading } = upload;
  const formik = useFormik({
    initialValues: {
      name: '',
      baseName: '',
      cityList: [],
      iconUrl: '',
    },
    validationSchema,
  });
  const [cities, setCities] = useState([]);

  // const changeName = () => {
  //   formik.handleChange('name')

  //   console.log('fromik.values');
  // }
  const onCreate = () => {
    setIsLoading(true);
    createCategoryAppUser(formik.values)
      .then(() => {
        alert.show('Service category has been created', {
          type: 'success',
        });
        formik.resetForm();
        close();
        onItemCreated();
      })
      .catch((error) => {
        alert.show(error.message, {
          type: 'error',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    iconUrl && formik.setFieldValue('iconUrl', iconUrl);
  }, [iconUrl]);
  useEffect(() => {
    getCities()
      .then(({ data }) => {
        setCities(data);
      })
      .catch((error) => {
        alert.show(error.message, {
          type: 'error',
        });
      });
    getCategoryTypeList()
      .then(({ data }) => {
        setBaseNames(data?.categoryType?.baseNames || []);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div>
      <Box display="flex" justifyContent="center">
        <ImageUpload
          // onSubmit={onUploadMedia}
          // onRemove={onRemoveImage}
          label="Select icon"
          folder="service"
          avatar
        />
      </Box>
      <CustomInput
        labelText="Name"
        formControlProps={{
          fullWidth: true,
        }}
        type="text"
        error={formik.touched.name && Boolean(formik.errors.name)}
        inputProps={{
          value: formik.values.name,
          onBlur: formik.handleBlur('name'),
          onChange: formik.handleChange('name'),
        }}
      />
      <SelectBoxComponent
        items={baseNames.map((element) => ({
          value: element,
          label: element,
        }))}
        inputLabel="baseNames"
        onValueChange={(items) => {
          formik.setFieldValue('baseName', items);
        }}
        value={formik.values.baseName}
      />
      <SelectBoxComponent
        items={cities.map((city) => ({
          value: city._id,
          label: city.name,
        }))}
        inputLabel="City list"
        onValueChange={(items) => {
          formik.setFieldValue('cityList', items);
        }}
        value={formik.values.cityList}
        multiple
      />
      {/* <CustomInput
        labelText="baseName"
        formControlProps={{
          fullWidth: true,
        }}
        type="text"
        error={formik.touched.baseName && Boolean(formik.errors.baseName)}
        inputProps={{
          value: formik.values.baseName,
          onBlur: formik.handleBlur('baseName'),
          onChange: formik.handleChange('baseName'),
        }}
      /> */}

      <Box textAlign="center">
        <Button
          round
          type="submit"
          color="success"
          disabled={!formik.dirty || !formik.isValid || isLoading}
          loading={isLoading}
          loaderColor="white"
          onClick={onCreate}
        >
          Create
        </Button>
      </Box>
    </div>
  );
}

CreateCategoryAppUserComponent.propTypes = {
  onItemCreated: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
};
