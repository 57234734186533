import axios from 'axios';
import {
  backendServerConfig,
  MICROSERVICE_BASE_URL,
} from '../../../config/backendServer.config';

// ***************************** start CategoryList ****************************
export function getCategoryList(id, paged, limit) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.STORES}/product_category?limit=${limit || 0
    }&storeId=${id}&paged=${paged || false}`,
  );
}

export function updateStockInCategoryList(item) {
  return axios.put(
    `${MICROSERVICE_BASE_URL.STORES}/product_category/${item._id}`, { ...item }
  );
}
export function createCategoryList(data) {
  return axios.post(
    `${MICROSERVICE_BASE_URL.STORES}/product_category`, { ...data }
  );
}
export function deleteCategoryList(id) {
  return axios.delete(
    `${MICROSERVICE_BASE_URL.STORES}/product_category/${id}`
  );
}
// ***************************** end CategoryList ****************************

// ************************** start Category *******************

export function getCategory(id, limit, paged) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.STORES}/product?limit=${limit || 0
    }&storeId=${id}&paged=${paged || false}`,
  );
}

export function createCategory(data) {
  return axios.post(
    `${MICROSERVICE_BASE_URL.STORES}/product`, { ...data }
  );
}
export function deleteCategory(id) {
  return axios.delete(
    `${MICROSERVICE_BASE_URL.STORES}/product/${id}`
  );
}

export function updateCategory(item) {
  return axios.put(
    `${MICROSERVICE_BASE_URL.STORES}/product/${item._id}`, { ...item }
  );
}

// **************************** end Category **********************


// **************************** start Specification **********************

export function getSpecification(id, limit, paged) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.STORES}/specification?limit=${limit || 0
    }&storeId=${id}&paged=${paged || false}`,
  );
}

export function addSpecification(data) {
  return axios.post(
    `${MICROSERVICE_BASE_URL.STORES}/specification`, { ...data }
  );
}

export function deleteSpecification(id) {
  return axios.delete(
    `${MICROSERVICE_BASE_URL.STORES}/specification/${id}`
  );
}
export function updateSpecification(item) {
  return axios.put(
    `${MICROSERVICE_BASE_URL.STORES}/specification/${item._id}`, { ...item }
  );
}
// **************************** end Specification **********************

// **************************** end Item **********************
export function getItem(id, paged, skip, limit, searchText) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.STORES}/item?limit=${limit || 0
    }&page=${skip || 0}&storeId=${id}&paged=${paged || false}&search_text=${searchText || ''}`,
  );
}

export function createItem(data) {
  return axios.post(
    `${MICROSERVICE_BASE_URL.STORES}/item`, { ...data }
  );
}

export function deleteItem(id) {
  return axios.delete(
    `${MICROSERVICE_BASE_URL.STORES}/item/${id}`
  );
}

export function updateItem(item) {
  // item.flag = ''
  return axios.put(
    `${MICROSERVICE_BASE_URL.STORES}/item/${item._id}`, { ...item }
  );
}

// **************************** end Item **********************
// **************************** start product **********************
export function getProduct(id) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.CONTENT}/store/${id}/products`,
  );
}
export function updateProduct(storeId, payload) {
  console.log(payload);
  console.log(JSON.stringify(payload));
  return axios.post(
    `${MICROSERVICE_BASE_URL.CONTENT}/mapping/store/${storeId}/products`, { products: payload }
  );
}
// **************************** end product **********************
// **************************** start flag **********************
export function addFlagToProduct(id, payload) {
  console.log(payload);
  // console.log(JSON.stringify(payload));
  return axios.put(
    `${MICROSERVICE_BASE_URL.STORES}/product/${id}/add_flag`, payload
  );
}
export function addFlagToProductCategory(id, payload) {
  console.log(payload);
  // console.log(JSON.stringify(payload));
  return axios.put(
    `${MICROSERVICE_BASE_URL.STORES}/product_category/${id}/add_flag`, payload
  );
}
export function addFlagToStore(id, payload) {
  console.log(payload);
  // console.log(JSON.stringify(payload));
  return axios.put(
    `${MICROSERVICE_BASE_URL.STORES}/store/${id}/add_flag`, payload
  );
}
// **************************** end flag **********************