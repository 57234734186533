import React, { useState } from 'react';
import { getUserDataState } from '../../auth/redux/selectors';
import { useSelector } from 'react-redux';
import CommentComponent from '../components/CommentV2.component';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import CommentIcon from '@mui/icons-material/Comment';

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip arrow {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

export default function orderCommentComponenet({ _id, comments }) {
  const userData = useSelector(getUserDataState);
  const [admin, setAdmin] = useState({
    _id: userData.user._id,
    email: userData.user.email,
    username: userData.user.username,
    userName: userData.user.username,
    entityId: userData.user.entityId,
    scope: userData.user.scope,
    by: 'admin',
  });
  return (
    <HtmlTooltip
      title={
        <React.Fragment>
          <CommentComponent
            close={() => {}}
            admin={admin}
            comments={comments}
            _id={_id}
          />
        </React.Fragment>
      }
    >
      {comments && comments.length ? (
        <span
          style={{ cursor: 'pointer' }}
          onClick={() => {
            handleModal(item, index, 'Comment');
            setOrderIdForComments(item._id);
          }}
        >
          {' '}
          {comments[comments.length - 1]?.adminName}
          {' // '}
          {comments[comments.length - 1]?.comment}{' '}
        </span>
      ) : (
        <CommentIcon
          style={{ cursor: 'pointer', fontSize: '22px' }}
          onClick={() => {
            handleModal(item, index, 'Comment');
            setOrderIdForComments(item._id);
          }}
        />
      )}
    </HtmlTooltip>
  );
}
