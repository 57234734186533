import React, { useEffect, useState, useRef } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import EditIcon from '@material-ui/icons/Edit';
import DataTableSortingContainer from '../../../components/DataTableSortingContainer.component';
import ViewItemComponent from '../../delivery-info/components/ViewItem.component';
import { getStores, logStore, updateStore } from '../services/serverApi.service';
import EditStoreComponent from '../components/EditStoreComponent';
import CreateStoreComponent from '../components/CreateStore.component';
import { makeStyles, Box, Grid } from '@material-ui/core';
import StoreTimeConfig from '../components/StoreTimeConfig.component';
import Button from '@material-ui/core/Button';
import TablePagination from '@material-ui/core/TablePagination';
import Visibility from '@material-ui/icons/Visibility';
import { Autocomplete } from '@material-ui/lab'
import Card from '../../../components/material-dashboard-pro-react/Card/Card';
import ModalComponent from '../../../components/Modal.component';
import {
  getCities,
  getServices,
  getDispatchingModes,
  getPricings,
  getStoresBySearch
} from '../../delivery-info/services/serverApi.service';
import StoreTagsComponent from '../components/StoreTagsComponent';
import StoreEditDispatchingModeComponent from '../components/StoreEditDispatchingModeComponent';
import StoreEditPreparationComponent from '../components/StoreEditPreparationComponent';
import StoreDeliveryPriceComponent from '../components/StoreDeliveryPriceComponent';
import StoreCommision from '../components/StoreCommision.component';
import StoreNameComponent from '../components/StoreName.component';
import StoreCityComponent from '../components/storeCity.component';
import StoreServiceComponenet from '../components/StoreService.componenet';
import EditImgStore from '../components/EditImgStore';
import StoreEmailComponenet from '../components/StoreEmail.component';
import StoreContactComponenet from '../components/storeContact.component';
import StoreMaxDisatnceComponenet from '../components/StoreDistance.component';
import StoreMaxTypeComponenet from '../components/StoreType.component';
import EditStoreImgComponent from '../components/EditStoreImg.component';
import StorePromoComponent from '../components/StorePromo.component';
import StorePaymentComponent from '../components/StorePayment.component';
import StoreClassComponent from '../components/StoreClass.component';
import CustomInput from '../../../components/material-dashboard-pro-react/CustomInput/CustomInput';
import { frontEndUrl } from '../../../config/FrontEndUrl';
import LinkComponent from '../components/LinkComponent';
// import {getTag} from '../../delivery-info/services/serverApi.service'
import SwitchComponent from '../../../components/Switch.component';
import tableDataStyles from '../../../assets/jss/material-dashboard-pro-react/views/extendedTablesStyle';
import { modesList } from '../../../config/status.config';
import TextField from '@material-ui/core/TextField';
import DuplicateStoreComponent from '../components/DuplicateStoreComponent';
const useTableDataStyles = makeStyles(tableDataStyles);
import CustomButton from '../../../components/material-dashboard-pro-react/CustomButtons/Button'
import { useSelector } from 'react-redux';
import { getUserDataState } from 'features/auth/redux/selectors';
import { DATA } from '../../../utils/logAudit';
import { resetObj } from 'utils/resetObj';

const validationSchema = yup.object({
  storeName: yup.string().nullable(),
});
export default function UnapprovedStoresPage(key, value) {
  const inpRef = useRef();
  // let inpSearch = inpRef.current
  // console.log(inpRef);
  // console.log(inpSearch);
  const formik = useFormik({
    initialValues: {
      storeName: '',
    },
    validationSchema,
  })
  // const classes = useTableDataStyles();
  const columns = [
    {
      Header: 'Store Name',
      accessor: 'name',
    },
    {
      Header: 'Store master',
      accessor: 'store_master_detail.name',
    },
    {
      Header: 'city',
      accessor: 'city',
    },
    {
      Header: 'payment',
      accessor: 'payment',
    },
    {
      Header: 'service',
      accessor: 'service',
    },
    {
      Header: 'email',
      accessor: 'email',
    },
    {
      Header: 'contact',
      accessor: 'contact',
    },
    {
      Header: 'Image',
      accessor: 'imageUrl',
    },
    {
      Header: 'mode',
      accessor: 'mode',
    },
    {
      Header: 'class',
      accessor: 'class',
    },
    {
      Header: 'type',
      accessor: 'displayType',
    },
    {
      Header: 'open',
      accessor: 'is_open',
    },
    {
      Header: 'isBusiness',
      accessor: 'is_Business',
    },
    {
      Header: 'open',
      accessor: 'open_switch',
    },
    {
      Header: 'visible',
      accessor: 'is_visible',
    },
    {
      Header: 'isPartner',
      accessor: 'is_Partner',
    },
    {
      Header: 'commissionType',
      accessor: 'commissionType',
    },
    {
      Header: 'commission',
      accessor: 'commissionCol',
    },
    // {
    //   Header: 'deliveryPayStore',
    //   accessor: 'deliveryManPayStore',
    // },
    {
      Header: 'Store Promo',
      accessor: 'storePromo',
    },
    {
      Header: 'rewardPoints',
      accessor: 'rewardPointsCol',
    },
    {
      Header: 'storeRewardPoints',
      accessor: 'storeRewardPointsCol',
    },
    {
      Header: 'Delivery price',
      accessor: 'DeliveryPrice',
    },
    {
      Header: 'preparation',
      accessor: 'preparation',
    },
    {
      Header: 'Tags',
      accessor: 'tags',
    },
    {
      Header: 'Max_dist',
      accessor: 'maxDisatnce',
    },
    {
      Header: 'latitude',
      accessor: 'latitude',
    },
    {
      Header: 'longitude',
      accessor: 'longitude',
    },
    {
      Header: 'Actions',
      accessor: 'actions',
    },
  ];

  const useStyles = makeStyles((theme) => ({
    inputText: {
      width: '35px',
      height: '35px',
    },
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
    noBorder: {
      border: "none",
    },
    btn_success: {
      background: '#1976d2',
      color: '#ffffff',
    },
    btn_error: {
      background: '#8bc34a',
      color: '#ffffff',
    },
    link_button: {},
    pointer: {
      cursor: 'pointer'
    }
  }));
  const classes = useStyles();
  // const classes = useStyles();
  const [resultsData, setResultsData] = useState({
    items: [],
    tableItems: [],
  });
  // const [StoreSearch,setStoreSearch]=useState([])
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdated, setIsUpdated] = useState(false);
  const [openModal, setOpenModal] = useState('');
  // const [isOpenDispatchModeModal, setIsOpenDispatchModeModal] = useState(false);
  // const [isOpenPreparationeModal, setIsOpenPreparationeModal] = useState(false);
  // const [isOpenDeliveryPriceModal, setIsOpenDeliveryPriceModal] =
  //   useState(false);
  // const [openTagsModal, setOpenTagsModal] = useState(false);
  // const [modal, setModal] = useState(false);
  const [store, setStore] = useState([]);
  const [indexStore, setIndexStore] = useState(-1);
  // const [page, setPage] = useState(1);
  // const [rowsPerPage, setRowsPerPage] = useState(10);
  const [cities, setCities] = useState([]);
  const [services, setServices] = useState([]);
  const [modes, setModes] = useState([]);
  const [pricing, setPricing] = useState([]);
  const [stores, setStores] = useState([]);
  const [allTag, setAllTag] = useState([]);
  const [total, setTotal] = useState(0);
  const [pageSelect, setPageSelect] = useState(0);
  const [numberOfRows, setNumberOfRows] = useState(0);
  const [searchText, setSearchText] = useState('');
  // admin data :
  const adminData = useSelector(getUserDataState);
  const [admin, setAdmin] = useState({
    _id: adminData.user._id,
    email: adminData.user.email,
    username: adminData.user.username
  });
  // handle de-lock store action :
  const storeDelock = (value, item) => {
    // reset object before setting data :
    resetObj(DATA);
    // for logger audit data :
    DATA.admin = admin;
    DATA.actionType = 'Bloquer/Debloquer Store';
    DATA.targetId = item._id;
    DATA.target = 'Store';
    DATA.loggerDetails = {
      beforeValue: 'Bloquer',
      afterValue: 'Debloquer'
    }
    // DATA.beforeValue = 'Bloquer';
    // DATA.afterValue = 'Debloquer';
    // console.log(DATA);
    // our post here :
    logStore(DATA)
      .then(({ data }) => {
        console.log(data);
      })
      .catch((error) => {
        console.log(error.message, {
          type: 'error',
        });
      })
  }

  // const storeSearch = async (e) => {
  //   if (inpRef.current.value) {
  //     // console.log(e.target.value);
  //     // e.preventDefault();
  //     // inpSearch.focus()
  //     setSearchText(inpRef.current.value)
  //     // inpSearch.focus()
  //     setTimeout(async () => {

  //       await getStoresBySearch(0, 15, true, inpRef.current.value)
  //         .then(({ data: { stores: storeData } }) => {
  //           // console.log(storeData);
  //           // setStoreSearch(storeData);
  //           storeData.length > 0 && setResultsData({
  //             tableItems: resultsData.tableItems,
  //             items: storeData.map((item, index) => {
  //               return prepareShowStore(item, index);
  //             }),
  //           });
  //           // inpSearch.focus()
  //         })
  //         .catch((error) => {
  //           console.log(error);
  //           // setStoreSearch([])
  //         });
  //     }, 500);

  //   } else {
  //     setSearchText('')
  //     loadStoresList()
  //     // setResultsData({
  //     //   items: resultsData.tableItems.map((item, index) => {
  //     //     return prepareShowStore(item, index);
  //     //   }),
  //     //   // tableItems: stores,
  //     // });
  //   }
  //   // inpSearch.focus()
  //   if (inpRef.current) inpRef.current.focus();
  // }

  const SearchComponent = () => {
    return (
      <Grid container>
      <Grid item xs={8}>
        <input type='text'
          ref={inpRef}
          placeholder='store search'
          style={{
            border: "none",
            borderBottom: '1px solid black'
          }}
          defaultValue={searchText}
          
        />
        </Grid>
        <Grid item xs={4} container justify="flex-end"
  >
        
        <Box textAlign="left">
          <CustomButton
            round
            type="submit"
            color="success"
            size="sm"
            loaderColor="white"
            // onClick={storeSearch}
            onClick={()=>{
              setSearchText(inpRef.current.value)
              pageSelect>0&&setPageSelect(0)
              loadStoresList(pageSelect>0?0:pageSelect, numberOfRows,inpRef.current.value)
            }
            }
          >
            find store
          </CustomButton>
        </Box>
        <Box textAlign="left">
          <CustomButton
            round
            type="submit"
            color="google"
            size="sm"
            loaderColor="white"
            // onClick={loadStoresList}
            onClick={() => {
              setSearchText('')
              pageSelect>0&&setPageSelect(0)
              loadStoresList(pageSelect>0?0:pageSelect, numberOfRows,"")
            }}
          >
            clear
          </CustomButton>
        </Box>
        </Grid>
      </Grid>
    )
  }

  

  const handlePaginate = (val) => {
    setResultsData({
      items: [],
      tableItems: [],
    });
    loadStoresList(val.pageSelect, val.numberOfRows,searchText);
    setPageSelect(val.pageSelect);
    setNumberOfRows(val.numberOfRows);
  };
  const closeModal = () => {
    setOpenModal('');
  };
  //console.log(stores);
  const handleOpenModal = (item, index, type) => {
    console.log('type', type, index, item);
    setStore(item);
    setIndexStore(index);
    setOpenModal(type);
  };
  
  const editStore = (key, value, index) => {
    let newStores = stores;
    newStores[index][key] = value;
    setStores([...newStores]);
    const { _id, ...item } = newStores[index];
    // console.log(item,'item');
    updateStore(_id, item)
      .then(({ data }) => {
        console.log(data);
        //let newStores = [...stores];
        // newStores[index] = data.store;
        // setStores(newStores);
        // console.log('Store has been updated', {
        //   type: 'success',
        // });
      })
      .catch((error) => {
        console.log(error.message, {
          type: 'error',
        });
      });
  };

  const handleInputForUpdateStoreElement = (event, index, element) => {
    console.log(event.target.value, index)
    let newStore = stores;
    // newStore[index].maxDisatnce = event.target.value
    // setStores([...newStore])
    editStore(element, event.target.value, index);
  };

  const prepareShowStore = (item, index) => {
    return {
      ...item,
      storeName: (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            handleOpenModal(item, index, 'EditStoreImg');
          }}
        >
          {item?.name}
        </div>
        // <div>
        //     <TextField
        //       type={'text'}
        //       value={item?.name}
        //       onChange={(event) => {
        //         handleInputForUpdateStoreElement(event, index,'name');
        //       }}
        //       InputProps={{ disableUnderline: true }}
        //     />

        // </div>
      ),
      latitude: (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            handleOpenModal(item, index, 'location');
          }}
        >
          {item?.location[0]}
        </div>
      ),
      longitude: (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            handleOpenModal(item, index, 'location');
          }}
        >
          {item?.location[1]}
        </div>
      ),
      email: (
        <div>
          <TextField
            type={'text'}
            value={item?.email}
            onChange={(event) => {
              handleInputForUpdateStoreElement(event, index, 'email');
            }}
            InputProps={{ disableUnderline: true }}
          />

        </div>

      ),
      contact: (
        <div>
          <TextField
            type={'text'}
            value={item?.contact}
            onChange={(event) => {
              handleInputForUpdateStoreElement(event, index, 'contact');
            }}
            InputProps={{ disableUnderline: true }}
          />

        </div>

      ),
      rewardPointsCol: (
        <div>
          <TextField
            type={'number'}
            value={item?.rewardPoints}
            onChange={(event) => {
              handleInputForUpdateStoreElement(event, index, 'rewardPoints');
            }}
            InputProps={{ disableUnderline: true }}
          />

        </div>

      ),
      storeRewardPointsCol: (
        <div>
          <TextField
            type={'number'}
            value={item?.storeRewardPoints}
            onChange={(event) => {
              handleInputForUpdateStoreElement(event, index, 'storeRewardPoints');
            }}
            InputProps={{ disableUnderline: true }}
          />

        </div>

      ),
      commissionCol: (
        <div>
          <TextField
            type={'number'}
            value={item?.commission}
            onChange={(event) => {
              handleInputForUpdateStoreElement(event, index, 'commission');
            }}
            InputProps={{ disableUnderline: true }}
          />

        </div>

      ),
      maxDisatnce: (

        <div>
          <TextField
            // id="outlined-basic"
            type={'number'}
            value={item?.maxDisatnce}
            onChange={(event) => {
              handleInputForUpdateStoreElement(event, index, 'maxDisatnce');
            }}
            InputProps={{ disableUnderline: true }}
          />

        </div>
      ),
      displayType: (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            handleOpenModal(item, index, 'displayType');
          }}
        >
          {item?.displayType}
        </div>
      ),
      city: (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            handleOpenModal(item, index, 'city');
          }}
        >
          {/* {item?.city_detail?.name} */}
          {(cities||[]).find(x=>x._id==item.cityId)?.name}
        </div>
      ),
      payment: (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            handleOpenModal(item, index, 'payment');
          }}
        >
          {item?.deliveryManPayStore || 'null'}
        </div>
      ),
      commissionType: (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            handleOpenModal(item, index, 'commissionType');
          }}
        >
          {item.commissionType}
        </div>
      ),
      is_open: (
        <div>
          <Button
            style={{
              background: item.open ? '#8bc34a' : '#ffb0a4',
              color: '#ffffff',
            }}
            variant="contained"
            onClick={() => {
              handleOpenModal(item, index, 'openStoreConfig');
            }}
          >
            {item.open ? 'Ouvert' : 'Fermer'}
          </Button>
        </div>
      ),
      is_visible: (
        <SwitchComponent
          onChange={(value) => editStore('visible', value, index)}
          value="checked"
          checked={item.visible}
          label="visible"
        />
      ),
      is_Partner: (
        <SwitchComponent
          onChange={(value) => editStore('isPartner', value, index)}
          value="checked"
          checked={item.isPartner}
          label="isPartner"
        />
      ),
      is_Business: (
        <SwitchComponent
          onChange={(value) => {
            editStore('isBusiness', value, index)
            storeDelock(value, item)
          }}
          value="checked"
          checked={item.isBusiness}
          label="isBusiness"
        />
      ),
      open_switch: (
        <SwitchComponent
          onChange={(value) => editStore('open', value, index)}
          value="checked"
          checked={item.open}
          label="open"
        />
      ),
      imageUrl: (
        // <img src={item.imageUrl} style={{width: '50px', height: '50px'}}/>
        <div
          style={{ cursor: 'pointer' }}
          variant="outlined"
          onClick={() => {
            handleOpenModal(item, index, 'imageUrl');
          }}
        >
          <img src={item.imageUrl} style={{ width: '50px', height: '50px' }} />
          {/* {services.find((service) => item.serviceId === service._id)?.name ||
              'service not found'} */}
        </div>
      ),
      service: (
        <div
          style={{ cursor: 'pointer' }}
          variant="outlined"
          onClick={() => {
            handleOpenModal(item, index, 'service');
          }}
        >
          {services.find((service) => item.serviceId === service._id)?.name ||
            'service not found'}
        </div>
      ),
      storePromo: (
        <div
          style={{ cursor: 'pointer', color: item?.promoSetting?.enabled ? 'green' : 'red' }}
          onClick={() => {
            handleOpenModal(item, index, 'storePromo');
          }}
        >
          {item?.promoTextPrimary || 'null'}
        </div>
      ),
      store_master: item.store_master_detail?.name || '',
      mode: (
        <div>
          <ul style={{ listStyle: 'none' }}>
            <li>
              {item.selectedDispatchingModes.length > 0
                ? item.selectedDispatchingModes.map((mode) => {
                  // return <li>{mode ? mode : ''} </li>;
                  return mode ? mode.substring(0, 2) + ' ' : ''
                })
                : ''}
            </li>
            <li>
              <Button
                size="medium"
                style={{
                  color: '#03a9f4 ',
                  'font-weight': '600',
                  'font-size': '15px',
                }}
                onClick={() => {
                  handleOpenModal(item, index, 'dispatchModeModal');
                }}
              >
                +
              </Button>
            </li>
          </ul>
        </div>
      ),
      class: (
        <div
          style={{ cursor: 'pointer',textAlign:'center' }}
          onClick={() => {
            handleOpenModal(item, index, 'class');
          }}
        >
          {item?.class || 'null'}
        </div>
      ),
      tags: (
        <Button
          size="medium"
          style={{
            color: '#03a9f4 ',
            'font-weight': '600',
            'font-size': '15px',
          }}
          onClick={() => {
            handleOpenModal(item, index, 'openTagsModal');
          }}
        >
          {item.tags.length}
        </Button>
      ),
      preparation: (
        <Button
          size="medium"
          style={{
            color: '#03a9f4 ',
            'font-weight': '600',
            'font-size': '15px',
          }}
          onClick={() => {
            handleOpenModal(item, index, 'preparationModal');
          }}
        >
          {item.preparationTimeMin + '-' + item.preparationTimeMax}
        </Button>
      ),
      DeliveryPrice: (
        <Button
          size="medium"
          style={{
            color: '#03a9f4 ',
            'font-weight': '600',
            'font-size': '15px',
          }}
          onClick={() => {
            handleOpenModal(item, index, 'deliveryPriceModal');
          }}
        >
          {item.priceConfig ? item.priceConfig.name : '+'}
        </Button>
      ),
    };
  };

  const loadStoresList = (page,limit,searchTxt) => {
    setIsLoading(true);
    getStores(page || 0, limit || 20, true,false,searchTxt)
    .then(({ data }) => {
      // console.log(data);
      setStores(data.stores);
      setTotal(data.total);
    })
    .finally(() => {
      setIsLoading(false);
      setIsUpdated(!isUpdated);
    });
};

  const onInit = () => {
    return new Promise((resolve) => {
      getCities().then(async ({ data }) => {
        setCities(data);
      });
      getServices().then(async ({ data }) => {
        setServices(data);
      });
      getDispatchingModes().then(async ({ data }) => {
        setModes(data);
      });
      getPricings().then(async ({ data }) => {
        setPricing(data);
      });
      resolve(true);
    });
  };

  useEffect(() => {
    onInit();
    // getTagFromAPI()
  }, []);

  useEffect(() => {
    // const tempStore = []
    // // console.log('stores updated');
    // stores&&stores.map(store=>{
    //   !store.isBusiness&&tempStore.push(store)
    // })
    setResultsData({
      items: stores.map((item, index) => {
        return prepareShowStore(item, index);
      }),
      tableItems: stores,
    });
  }, [stores, modes]);
  // useEffect(() => {
  //   if (inpRef.current) inpRef.current.focus()
  // }, [inpRef])
  const customColumnStyle = {
    // wordWrap: "break-word",
    maxWidth: "180px",
    minWidth: "150px",
  };
  return (
    <div>
      {/*{JSON.stringify(modes)}*/}
      <Card>
        {stores && (
          <DataTableSortingContainer
            resultsData={resultsData}
            initData={resultsData.items}
            total={total}
            page={pageSelect+1}
            columns={columns}
            title="Store list"
            isLoading={isLoading}
            loadItemsList={loadStoresList}
            CreateItemFormComponent={CreateStoreComponent}
            customColumnStyle={customColumnStyle}
            searchInp={true}
            SearchComponent={SearchComponent}
            actionButtons={{
              // view: {
              //   color: 'info',
              //   type: 'view',
              //   component: ViewItemComponent,
              // },
              edit: {
                color: 'success',
                type: 'edit',
                props: { services: services, cities: cities },
                component: EditStoreComponent,
              },
              link: {
                color: 'success',
                type: 'link',
                href: `${frontEndUrl.Link}dashboard-store`,
                icon: <Visibility />,
                component: LinkComponent,
                setToLocal: true,
              },
              delete: { color: 'danger', type: 'delete' },
              duplicate: {
                color: 'danger',
                type: 'duplicate',
                props: { services: services, cities: cities },
                component: DuplicateStoreComponent,
              },
            }}
            onPaginate={handlePaginate}
            isPaginate={true}
          />
        )}
      </Card>
      {openModal && (
        <ModalComponent
          maxWidth={'false'}
          close={closeModal}
          visible={openModal === 'openStoreConfig'}
          title="Store Time Config"
        >
          <StoreTimeConfig store={store} close={closeModal} />
        </ModalComponent>
      )}
      {openModal && (
        <ModalComponent
          maxWidth={'false'}
          close={closeModal}
          visible={openModal === 'EditStoreImg'}
          title="Store Time Config"
        >
          <EditStoreImgComponent
            store={store}
            close={closeModal}
            index={indexStore}
            stores={stores}
            setStores={setStores}
          />
        </ModalComponent>
      )}
      {openModal && (
        <ModalComponent
          maxWidth={'false'}
          close={closeModal}
          visible={openModal === 'commissionType'}
          title="Store Commission"
        >
          <StoreCommision
            store={store}
            close={closeModal}
            index={indexStore}
            stores={stores}
            setStores={setStores}
          />
        </ModalComponent>
      )}
      {openModal && (
        <ModalComponent
          maxWidth={'false'}
          close={closeModal}
          visible={openModal === 'location'}
          title="Store location"
        >
          <StoreNameComponent
            store={store}
            close={closeModal}
            index={indexStore}
            stores={stores}
            setStores={setStores}
          />
        </ModalComponent>
      )}
      {openModal && (
        <ModalComponent
          maxWidth={'false'}
          close={closeModal}
          visible={openModal === 'storePromo'}
          title="Store Promo"
        >
          <StorePromoComponent
            store={store}
            close={closeModal}
            index={indexStore}
            stores={stores}
            setStores={setStores}
          />
        </ModalComponent>
      )}
      {openModal && (
        <ModalComponent
          maxWidth={'false'}
          close={closeModal}
          visible={openModal === 'city'}
          title="Store city"
        >
          <StoreCityComponent
            store={store}
            close={() => {
              setOpenModal('');
              loadStoresList(pageSelect, numberOfRows);
            }}
            index={indexStore}
            stores={stores}
            setStores={setStores}
            cities={cities}
          />
        </ModalComponent>
      )}
      {openModal && (
        <ModalComponent
          maxWidth={false}
          close={closeModal}
          visible={openModal === 'payment'}
          title="Store payment"
        >
          <StorePaymentComponent
            store={store}
            close={() => {
              setOpenModal('');
              loadStoresList(pageSelect, numberOfRows);
            }}
            index={indexStore}
            stores={stores}
            setStores={setStores}
            cities={cities}
          />
        </ModalComponent>
      )}
      {openModal && (
        <ModalComponent
          maxWidth={'false'}
          close={() => {
            setOpenModal('');
            loadStoresList(pageSelect, numberOfRows);
          }}
          visible={openModal === 'service'}
          title="Store service"
        >
          <StoreServiceComponenet
            store={store}
            close={closeModal}
            index={indexStore}
            stores={stores}
            setStores={setStores}
            services={services}
          />
        </ModalComponent>
      )}
      {openModal && (
        <ModalComponent
          maxWidth={false}
          // close={closeModal}
          close={() => {
            setOpenModal('');
            loadStoresList(pageSelect, numberOfRows);
          }}
          visible={openModal === 'imageUrl'}
          title="Store IMG"
        >
          <EditImgStore
            store={store}
            // close={closeModal}
            close={() => {
              setOpenModal('');
              loadStoresList(pageSelect, numberOfRows);
            }}
            index={indexStore}
            stores={stores}
            setStores={setStores}
            services={services}
          />
        </ModalComponent>
      )}
      {openModal && openModal == 'class' && (
        <ModalComponent
          maxWidth={'false'}
          close={closeModal}
          visible={openModal === 'class'}
          title="class"
        >
          <StoreClassComponent
            store={store}
            close={closeModal}
            index={indexStore}
            stores={stores}
            setStores={setStores}
          />
        </ModalComponent>
      )}
      {openModal && (
        <ModalComponent
          maxWidth={'false'}
          close={closeModal}
          visible={openModal === 'email'}
          title="Store email"
        >
          <StoreEmailComponenet
            store={store}
            close={closeModal}
            index={indexStore}
            stores={stores}
            setStores={setStores}
            services={services}
          />
        </ModalComponent>
      )}
      {openModal && (
        <ModalComponent
          maxWidth={'false'}
          close={closeModal}
          visible={openModal === 'contact'}
          title="Store contact"
        >
          <StoreContactComponenet
            store={store}
            close={closeModal}
            index={indexStore}
            stores={stores}
            setStores={setStores}
          />
        </ModalComponent>
      )}
      {openModal && (
        <ModalComponent
          maxWidth={'false'}
          close={closeModal}
          visible={openModal === 'maxDisatnce'}
          title="Store maxDisatnce"
        >
          <StoreMaxDisatnceComponenet
            store={store}
            close={closeModal}
            index={indexStore}
            stores={stores}
            setStores={setStores}
          />
        </ModalComponent>
      )}
      {openModal && (
        <ModalComponent
          maxWidth={'false'}
          close={closeModal}
          visible={openModal === 'displayType'}
          title="Store displayType"
        >
          <StoreMaxTypeComponenet
            store={store}
            close={closeModal}
            index={indexStore}
            stores={stores}
            setStores={setStores}
          />
        </ModalComponent>
      )}
      {openModal && (
        <ModalComponent
          maxWidth={'sm'}
          close={closeModal}
          visible={openModal === 'openTagsModal'}
          title="tags"
        >
          <StoreTagsComponent
            services={services}
            store={store}
            close={() => {
              setOpenModal('');
              loadStoresList(pageSelect, numberOfRows);
            }}
            tag={allTag}
            id={store.serviceId}
          />
        </ModalComponent>
      )}
      {openModal && (
        <ModalComponent
          maxWidth={'sm'}
          close={closeModal}
          visible={openModal === 'dispatchModeModal'}
          title="modes"
        >
          <StoreEditDispatchingModeComponent
            modes={modesList}
            store={store}
            close={() => {
              setOpenModal('');
              loadStoresList(pageSelect, numberOfRows);
            }}
          />
        </ModalComponent>
      )}
      {openModal && (
        <ModalComponent
          maxWidth={'sm'}
          close={closeModal}
          visible={openModal === 'preparationModal'}
          title="tags"
        >
          <StoreEditPreparationComponent
            store={store}
            close={() => {
              setOpenModal('');
              loadStoresList(pageSelect, numberOfRows);
            }}
          />
        </ModalComponent>
      )}
      {openModal && (
        <ModalComponent
          maxWidth={'sm'}
          close={closeModal}
          visible={openModal === 'deliveryPriceModal'}
          title="delivery Price config"
        >
          <StoreDeliveryPriceComponent
            onItemUpdated={loadStoresList}
            pricing={pricing}
            store={store}
            close={() => {
              setOpenModal('');
              loadStoresList(pageSelect, numberOfRows);
            }}
          />
        </ModalComponent>
      )}
    </div>
  );
}
