import React from 'react';
import { useDispatch } from 'react-redux';

import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import PersonIcon from '@material-ui/icons/Person';
import LocationOn from '@material-ui/icons/LocationOn';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import MotorcycleIcon from '@material-ui/icons/Motorcycle';
import CloseleIcon from '@material-ui/icons/Close';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import BlockIcon from '@mui/icons-material/Block';
import { iconsSharedStyle } from '../mock';
import { openModelAction } from '../redux/actions/order.actions';

export default function orderMenuCompenent({ _id, blocked }) {
  const dispatch = useDispatch();
  return (
    <div>
      <Tooltip arrow title="Detail Commande" placement="top">
        <AspectRatioIcon
          style={{
            ...iconsSharedStyle,
            color: '#0705f7',
            backgroundColor: '#0705f730',
          }}
          onClick={() => {
            dispatch(openModelAction(_id, 0, 'detail'));
          }}
        />
      </Tooltip>
      <Tooltip arrow title="Assigner Commande" placement="top">
        <MotorcycleIcon
          style={{
            ...iconsSharedStyle,
            color: '#1fd62e',
            backgroundColor: '#1fd62e30',
          }}
          onClick={() => {
            dispatch(openModelAction(_id, 0, 'Assign'));
          }}
        />
      </Tooltip>
      <Tooltip arrow title="Annuler la commande" placement="top">
        <CloseleIcon
          style={{
            ...iconsSharedStyle,
            color: '#ff0000',
            backgroundColor: '#ff000030',
          }}
          onClick={() => {
            // handleOpenModal('cancel');
            dispatch(openModelAction(_id, 0, 'cancel'));
          }}
        />
      </Tooltip>
      <Tooltip arrow title="Aide a la decision" placement="top">
        <LocationOn
          style={{
            ...iconsSharedStyle,
            color: '#000000',
            backgroundColor: '#00000030',
          }}
          onClick={() => {
            // handleModal(item, index, 'Maps');
            // dispatch(openModelAction(_id, 0, 'changeStatus'));
          }}
        />
      </Tooltip>
      <Tooltip arrow title="Detail Client" placement="bottom">
        <PersonIcon
          style={{
            ...iconsSharedStyle,
            color: '#000000',
            backgroundColor: '#00000030',
          }}
          onClick={() => {
            // handleModal(item, index, 'clinet details');
            // dispatch(openModelAction(_id, 0, 'changeStatus'));
          }}
        />
      </Tooltip>
      <Tooltip arrow title="position livreur" placement="top">
        <MyLocationIcon
          style={{
            ...iconsSharedStyle,
            color: '#000000',
            backgroundColor: '#00000030',
          }}
          onClick={() => {
            // handleModal(item, index, 'my location');
            // dispatch(openModelAction(_id, 0, 'changeStatus'));
          }}
        />
      </Tooltip>
      <Tooltip arrow title="position livreur" placement="top">
        <MyLocationIcon
          style={{
            ...iconsSharedStyle,
            color: 'red',
            backgroundColor: '#00000030',
          }}
          onClick={() => {
            // handleModal(item, index, 'myPosition');
            // dispatch(openModelAction(_id, 0, 'changeStatus'));
          }}
        />
      </Tooltip>
      <Tooltip arrow title="Changer Status Livreur" placement="bottom">
        <SyncAltIcon
          style={{
            ...iconsSharedStyle,
            color: '#000000',
            backgroundColor: '#00000030',
          }}
          onClick={() => {
            // handleOpenModal('status');
            dispatch(openModelAction(_id, 0, 'changeStatus'));
          }}
        />
      </Tooltip>
      {blocked && (
        <Tooltip arrow title="Blocked CMD" placement="bottom">
          <BlockIcon
            style={{
              ...iconsSharedStyle,
              color: '#735392',
              backgroundColor: '#00000030',
            }}
            onClick={() => {
              // handleOpenModal('blocked');
              dispatch(openModelAction(_id, 0, 'blocked'));
            }}
          />
        </Tooltip>
      )}
    </div>
  );
}
