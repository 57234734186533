import React, { useEffect, useState } from 'react';
// imp lib
import AsyncSelect from 'react-select/async';

// imp api call
import { getStoreConfig, editStoreConfig } from '../services/serverApi.service';
import { getCities } from '../../delivery-info/services/serverApi.service';
import { getStores } from '../../stores/services/serverApi.service';
// imp componenet
import DataTableContainerComponent from '../../../components/DataTableSortingContainer.component';
import ModalComponent from '../../../components/Modal.component';

import { Button } from '@mui/material';
import CustomeButton from '../../../components/material-dashboard-pro-react/CustomButtons/Button';

export default function storeConfigPage() {
  const [storeConfig, setStoreConfig] = useState([]);
  const [cities, setCities] = useState([]);
  const [resultsData, setResultsData] = useState({ items: [] });
  const [cityId, setCityId] = useState(null);
  const [inputValue, setInputValue] = useState(null);
  const [open, setOpen] = useState(null);
  const customColumnStyle = {
    maxWidth: '350px',
    minWidth: '350px',
  };
  const columns = [
    {
      Header: 'City',
      accessor: 'cityCell',
    },
    {
      Header: 'Store',
      accessor: 'storeCell',
    },
    {
      Header: 'remove Store',
      accessor: 'removeStore',
    },
  ];
  const handleCloseModal = () => {
    setCityId('');
    setInputValue('');
    setOpen(false);
  };
  let timer1;
  const fetchData = (inputValue, callback) => {
    clearTimeout(timer1);
    timer1 = setTimeout(() => {
      inputValue &&
        getStores(0, 10, true, true, inputValue)
          .then(({ data }) => {
            const tempArray = [];
            if (data.stores) {
              if (data.stores.length) {
                data.stores.forEach((element) => {
                  tempArray.push({
                    label: `${element.name}`,
                    _id: element._id,
                  });
                });
              }
            }
            callback(tempArray);
          })
          .catch((error) => {
            console.log(error);
          });
    }, 2000);
  };
  const onSearchChange = (selectedOption) => {
    console.log(selectedOption, cityId);
    const tempStoreConfig = [...storeConfig];
    const findIndex = tempStoreConfig.findIndex((x) => x.cityId == cityId);
    if (findIndex >= 0) {
      tempStoreConfig[findIndex].storeId = selectedOption._id;
      tempStoreConfig[findIndex].storeName = selectedOption.label;
    } else {
      tempStoreConfig.push({
        storeId: selectedOption._id,
        storeName: selectedOption.label,
        cityId: cityId,
      });
    }
    // console.log(tempStoreConfig);
    // handleCloseModal();
    editStoreConfig(tempStoreConfig)
      .then(({ data }) => {
        console.log('data', data);
        setStoreConfig(tempStoreConfig);
        handleCloseModal();
      })
      .catch((error) => console.log(error));
  };
  const showStoreConfig = (item) => {
    return {
      ...item,
      cityCell: item.name,
      storeCell: (
        <span
          onClick={() => {
            setCityId(item._id);
            setOpen('addStore');
            setInputValue(
              storeConfig.find((x) => x.cityId == item._id)?.storeName || '',
            );
          }}
        >
          {storeConfig.find((x) => x.cityId == item._id)?.storeName || '--'}
        </span>
      ),
      removeStore: (
        <Button
          color="primary"
          onClick={() => {
            setCityId(item._id);
            setOpen('removeStore');
            setInputValue(
              storeConfig.find((x) => x.cityId == item._id)?.storeName || '',
            );
          }}
        >
          remove
        </Button>
      ),
    };
  };
  const fetchStoreConfig = () => {
    getStoreConfig()
      .then(({ data }) => setStoreConfig(data.accompagnementConfig?.config))
      .catch((error) => console.log(error));
  };
  const fetchCity = () => {
    getCities()
      .then(({ data }) => setCities(data))
      .catch((error) => console.log(error));
  };
  const removeStore = () => {
    const tempStoreConfig = [...storeConfig];
    const findIndex = tempStoreConfig.findIndex((x) => x.cityId == cityId);
    findIndex != -1 && tempStoreConfig.splice(findIndex, 1);
    console.log(tempStoreConfig);
    editStoreConfig(tempStoreConfig)
      .then(({ data }) => {
        console.log('data', data);
        setStoreConfig(tempStoreConfig);
        handleCloseModal();
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    fetchCity();
  }, []);
  useEffect(() => {
    cities && cities.length && fetchStoreConfig();
  }, [cities]);
  useEffect(() => {
    cities &&
      cities.length &&
      setResultsData({
        items: (cities || []).map((item) => showStoreConfig(item)),
      });
  }, [cities, storeConfig]);
  return (
    <React.Fragment>
      <DataTableContainerComponent
        resultsData={resultsData}
        initData={resultsData.items || []}
        isPaginate={true}
        columns={columns}
        customColumnStyle={customColumnStyle}
      />
      {open && open == 'addStore' && (
        <ModalComponent
          maxWidth={'md'}
          close={handleCloseModal}
          visible={true}
          title={
            cityId &&
            cities.find((x) => x._id == cityId)?.name + ' / ' + inputValue
          }
        >
          <div
            style={{
              height: '400px',
              overflow: 'auto',
              padding: '15px',
            }}
          >
            <AsyncSelect
              single
              value={''}
              loadOptions={fetchData}
              placeholder="find store"
              onChange={(e) => {
                onSearchChange(e);
              }}
            />
          </div>
        </ModalComponent>
      )}
      {open && open == 'removeStore' && (
        <ModalComponent
          maxWidth={'md'}
          close={handleCloseModal}
          visible={true}
          title={
            cityId &&
            cities.find((x) => x._id == cityId)?.name + ' / ' + inputValue
          }
        >
          Are you sure to remove {inputValue} from{' '}
          {cities.find((x) => x._id == cityId)?.name}?
          <div style={{ float: 'right', margin: '15px' }}>
            <CustomeButton onClick={handleCloseModal}>Cancel</CustomeButton>
            <CustomeButton onClick={removeStore} color="success">
              Yes
            </CustomeButton>
          </div>
        </ModalComponent>
      )}
    </React.Fragment>
  );
}
