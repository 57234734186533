import { takeEvery, takeLatest, put, all, fork, call } from 'redux-saga/effects'
import axios from 'axios'
import { getDeliverySuccessAction, getDeliveryFailAction } from './actions/deliveryMen.actions'
import { GET_DELIVERY_MEN_REQUEST } from './constants/deliveryMen.constants'
import { MICROSERVICE_BASE_URL } from '../../../config/backendServer.config'
import { getPartnerDeliveryManInCities } from '../../Partners/services/server.api.service';



function* getDeliveryMenFromAPI(cityId) {
    const { data } = yield getPartnerDeliveryManInCities([cityId])
    return data
}

function* getDeliveryMenInCity({ cityId }) {
    try {
        const data = yield getDeliveryMenFromAPI(cityId)

        // console.log(data);
        yield put(getDeliverySuccessAction(data))

    }
    catch (error) {
        console.log(error.message);
        yield put(getDeliveryFailAction(error))

    }
}


export default function* watcherSage() {
    yield takeLatest(GET_DELIVERY_MEN_REQUEST, getDeliveryMenInCity)

}