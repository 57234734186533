import React, { useEffect, useState } from 'react';
// lib
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useAlert } from 'react-alert';
// compo
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import CustomInput from '../../../components/material-dashboard-pro-react/CustomInput/CustomInput';

// call api
import { editOrganisation } from '../services/server.api.service';

const validationSchema = yup.object({
  name: yup.string().required(),
  email: yup.string().required(),
});
export default function EditPartnerInfoComponent({
  data,
  onItemEdited,
  close,
}) {
  const alert = useAlert();
  const [isLoading, setIsLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      warehouses: [],
    },
    validationSchema,
  });

  const onEdit = () => {
    console.log(formik.values);
    data.name = formik.values.name;
    data.email = formik.values.email;
    delete data?.warehousesNbr;
    console.log(data);
    // setIsLoading(true);

    editOrganisation(data._id, data)
      .then(() => {
        alert.show('organisation has been edited', {
          type: 'success',
        });
        formik.resetForm();
        onItemEdited();
        close();
      })
      .catch((error) => {
        alert.show(error?.message || 'fail', {
          type: 'error',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  async function setValue() {
    if (data) {
      formik.setValues({
        name: data?.name,
        email: data?.email,
      });
    }
  }
  useEffect(() => {
    setValue();
  }, [data]);
  return (
    <div>
      <CustomInput
        labelText="Name"
        formControlProps={{
          fullWidth: true,
        }}
        type="text"
        error={formik.touched.name && Boolean(formik.errors.name)}
        inputProps={{
          value: formik.values.name,
          onBlur: formik.handleBlur('name'),
          onChange: formik.handleChange('name'),
        }}
        disabled
      />
      <CustomInput
        labelText="Email"
        formControlProps={{
          fullWidth: true,
        }}
        type="text"
        error={formik.touched.email && Boolean(formik.errors.email)}
        inputProps={{
          value: formik.values.email,
          onBlur: formik.handleBlur('email'),
          onChange: formik.handleChange('email'),
        }}
      />
      <Box textAlign="center">
        <Button
          round
          type="submit"
          color="success"
          disabled={!formik.dirty || !formik.isValid || isLoading}
          loading={isLoading}
          loaderColor="white"
          onClick={onEdit}
        >
          Save
        </Button>
      </Box>
    </div>
  );
}
