import axios from 'axios';
import {
  backendServerConfig,
  MICROSERVICE_BASE_URL,
} from '../../../config/backendServer.config';

export function getAdmins(page, limit, paged) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.ADMIN}/admin?page=${page || 0}&limit=${limit || 0
    }&paged=${paged || false}`,
  );
}

export function UpdateUser(id, data) {
  return axios.put(`${MICROSERVICE_BASE_URL.AUTH_SERV}/user/${id}`, data);
}

export function registerApi({ email, password, username }) {
  const params = new URLSearchParams();
  params.append('email', email);
  params.append('password', password);
  params.append('username', username);
  params.append('entityType', 'admin');

  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };

  return axios.post(
    `${MICROSERVICE_BASE_URL.AUTH_SERV}/auth/register`,
    params,
    config,
  );
}

export function getSetting(id, page, limit, paged) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.ADMIN}/admin/${id}?page=${page || 0}&limit=${limit || 0
    }&paged=${paged || false}`,
  );
}

export function fetchSetting() {
  return axios.get(`${MICROSERVICE_BASE_URL.ADMIN}/setting`);
}
export function updateSetting(id, setting) {
  // console.log('setting axios',JSON.stringify(setting));
  return axios.put(`${MICROSERVICE_BASE_URL.ADMIN}/setting/${id}`, {
    ...setting,
  });
}
export function updatePartnerSetting(id, setting) {
  // console.log('setting axios',JSON.stringify(setting));
  return axios.put(`${MICROSERVICE_BASE_URL.PARTNERS}/partnersetting/${id}`, {
    ...setting,
  });
}

export function getPartnerSetting() {
  return axios.get(`${MICROSERVICE_BASE_URL.PARTNERS}/partnersetting`);
}
export function getAllPartnerSetting() {
  return axios.get(`${MICROSERVICE_BASE_URL.ALLPARTNERS}/partnersetting`);
}
export function updateAllPartnerSetting(id, setting) {
  // console.log('setting axios',JSON.stringify(setting));
  return axios.put(`${MICROSERVICE_BASE_URL.ALLPARTNERS}/partnersetting/${id}`, {
    ...setting,
  });
}
// start kaalixUpConfig
export function getKaalixUpConfig() {
  return axios.get(
    `${MICROSERVICE_BASE_URL.CUSTOMER_PAYMENT}/kaalixup/setting`,
  );
}
export function updateKaalixUpConfig(setting) {
  return axios.post(
    `${MICROSERVICE_BASE_URL.CUSTOMER_PAYMENT}/kaalixup/setting`,
    { ...setting },
  );
}

// end kaalixUpConfig

export function editPasswordAdmin(payload) {
  return axios.post(`${MICROSERVICE_BASE_URL.AUTH_SERV}/user/update-password`, {
    ...payload,
  });
}
export function getCronConfig() {
  return axios.get(`${MICROSERVICE_BASE_URL.CRON}/setting`);
}
export function editCronConfig(payload) {
  return axios.post(`${MICROSERVICE_BASE_URL.CRON}/setting`, payload);
}
export function getCanCancelConfig() {
  return axios.get(`${MICROSERVICE_BASE_URL.DISPATCHING}/order/cancel/config`);
}
export function editCanCancelConfig(config) {
  return axios.put(`${MICROSERVICE_BASE_URL.DISPATCHING}/order/cancel/config`, {
    config: config,
  });
}
export function getOpningConfig() {
  return axios.get(`${MICROSERVICE_BASE_URL.ADMIN}/opning`);
}
export function setOpningConfig(payload) {
  return axios.post(`${MICROSERVICE_BASE_URL.ADMIN}/opning`, payload);
}

export function getSocketsPath() {
  return axios.get(`${MICROSERVICE_BASE_URL.ADMIN}/sockets-path`);
}
export function setSocketsPath(payload) {
  return axios.post(`${MICROSERVICE_BASE_URL.ADMIN}/sockets-path`, payload);
}

export function getRatingList() {
  return axios.get(`${MICROSERVICE_BASE_URL.ADMIN}/rating-list`);
}
export function setRatingList(payload) {
  return axios.post(`${MICROSERVICE_BASE_URL.ADMIN}/rating-list`, payload);
}
export function getConfigContent() {
  return axios.get(`${MICROSERVICE_BASE_URL.CONTENT}/config`);
}
export function setConfigContent(payload) {
  return axios.post(`${MICROSERVICE_BASE_URL.CONTENT}/config`, payload);
}
export function getRedisConfig() {
  return axios.get(`${MICROSERVICE_BASE_URL.DISPATCHING}/redis/config`);
}
export function setUseRedisDb(useRedis) {
  return axios.post(`${MICROSERVICE_BASE_URL.DISPATCHING}/redis/use_redis_db`, {
    useRedis: useRedis,
  });
}
export function getCategoryTypeList() {
  return axios.get(`${MICROSERVICE_BASE_URL.ADMIN}/categoryType`);
}
export function setCategoryTypeList(payload) {
  return axios.post(`${MICROSERVICE_BASE_URL.ADMIN}/categoryType`, payload);
}
export function transpList() {
  return axios.get(`${MICROSERVICE_BASE_URL.DRIVER}/transportations`);
}
export function setTranspList(payload) {
  return axios.post(`${MICROSERVICE_BASE_URL.DRIVER}/transportations`, { transportations: payload });
}
export function nearToCustomerConfig() {
  return axios.get(`${MICROSERVICE_BASE_URL.DRIVER}/driver-config`);
}
export function setNearToCustomerConfig(payload) {
  return axios.post(`${MICROSERVICE_BASE_URL.DRIVER}/driver-config`, payload);
}
export function driverConfig(cityId) {
  return axios.get(`${MICROSERVICE_BASE_URL.CUSTOMER_PAYMENT}/driver-config/city/${cityId}`);
}
export function allDriverConfig() {
  return axios.get(`${MICROSERVICE_BASE_URL.CUSTOMER_PAYMENT}/driver-config/`);
}
export function setDriverConfig(payload) {
  return axios.post(`${MICROSERVICE_BASE_URL.CUSTOMER_PAYMENT}/driver-config`, { transportations: payload });
}
export function getAppVersion() {
  return axios.get(`${MICROSERVICE_BASE_URL.ALLPARTNERS}/app-version`,);
}
export function setAppVersion(payload) {
  return axios.post(`${MICROSERVICE_BASE_URL.ALLPARTNERS}/app-version`, payload);
}
export function getSocketInterval() {
  return axios.get(`${MICROSERVICE_BASE_URL.ADMIN}/intervals`,);
}
export function setSocketInterval(payload) {
  return axios.post(`${MICROSERVICE_BASE_URL.ADMIN}/intervals`, payload);
}
export function getDistance() {
  return axios.get(`${MICROSERVICE_BASE_URL.DISPATCHING}/distance-config`,);
}
export function setDistance(payload) {
  return axios.post(`${MICROSERVICE_BASE_URL.DISPATCHING}/distance-config`, payload);
}
export function getDistanceAllPartner() {
  return axios.get(`${MICROSERVICE_BASE_URL.ALLPARTNERS}/distance-config`,);
}
export function setDistanceAllPartner(payload) {
  return axios.post(`${MICROSERVICE_BASE_URL.ALLPARTNERS}/distance-config`, payload);
}
export function getDistancePartner() {
  return axios.get(`${MICROSERVICE_BASE_URL.PARTNERS}/distance-config`,);
}
export function setDistancePartner(payload) {
  return axios.post(`${MICROSERVICE_BASE_URL.PARTNERS}/distance-config`, payload);
}
export function getRamadanTimingConfig() {
  return axios.get(`${MICROSERVICE_BASE_URL.STORES}/storeTiming`);
}
export function setRamadanTimingConfig(payload) {
  return axios.post(`${MICROSERVICE_BASE_URL.STORES}/storeTiming`, payload);
}
