import React, { useState, useEffect } from 'react';
import { Tab, Box } from '@material-ui/core';
import { TabPanel, TabContext, TabList } from '@material-ui/lab';
import AdminWhiteList from './AdminWitheLis.page';
import AdminPermissionPage from './AdminPermission.page';
import GridContainer from '../../../components/material-dashboard-pro-react/Grid/GridContainer';
import Card from '../../../components/material-dashboard-pro-react/Card/Card';
import CardHeader from '../../../components/material-dashboard-pro-react/Card/CardHeader';
import CardBody from '../../../components/material-dashboard-pro-react/Card/CardBody';

export default function AppSettingPage() {
  const [value, setValue] = useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {}, []);
  return (
    <GridContainer>
      <Card>
        <CardHeader> Admin setting</CardHeader>

        <CardBody>
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList
                  variant="scrollable"
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab label="Admin whiteList" value="1" />
                  <Tab label="Admin Permission" value="2" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <AdminWhiteList />
              </TabPanel>
              <TabPanel value="2">
                <AdminPermissionPage />
              </TabPanel>
            </TabContext>
          </Box>
        </CardBody>
      </Card>
    </GridContainer>
  );
}
