import React from 'react'
import PropTypes from 'prop-types'
import { MenuItem,TextField, FormControl,makeStyles,InputLabel} from '@material-ui/core';
// import { makeStyles } from '@material-ui/core/styles';
import styles from '../../../assets/jss/material-dashboard-pro-react/customSelectStyle';

const useStyles = makeStyles(styles);


export default function CustomeControllSelect(props) {
    const classes = useStyles();
    const {id,list,inputProps,label} = props
    return (
        <FormControl fullWidth className={classes.selectFormControl}>
            {/* <InputLabel  className={classes.selectLabel}>{label}</InputLabel> */}
        <TextField
        classes={{
            // root: classes.selectMenuItem,
          }}
          select
          label={label}
          id={id}
        
        {...inputProps}
        >
         
          {
            list.map((item,index)=>(
              // console.log(item)
              <MenuItem key={index} value={item.value}
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected,
                }} 
              >
                {item.text}
              </MenuItem>
            ))
          }
        </TextField>
    </FormControl>
    )
}

CustomeControllSelect.protoType = {
    list:PropTypes.array.isRequired,
    label:PropTypes.string,
    id:PropTypes.string,
    inputProps:PropTypes.any,
}