import React, { useState, useEffect } from 'react';
// component
import DataTableContainerComponent from '../../../components/DataTableSortingContainer.component';
import CreateOrganisationComponent from '../components/Create.organisation.component';
// import EditOrganisationComponent from '../components/Edit.organisation.component';
// import EditPartnerInfoComponent from '../components/Edit.partner.info.component';
// import WareHousesMapComponenet from '../components/WareHouses.map.componenet';
// import ModalComponent from '../../../components/Modal.component';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Button } from '@mui/material';

import { getParners, getDisponibility } from '../services/server.api.service';
import { getCities } from '../../orders/services/serverApi.service';

export default function ServicesDisponibilityPage() {
  const [openModal, setOpenModal] = useState(null);
  const [itemRow, setItemRow] = useState({});
  const [indexOrganisation, setIndexOrganisation] = useState(-1);
  const [cities, setCities] = useState([]);
  const [partners, setPartners] = useState([]);
  const [warehouses, setWarehouses] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [resultsData, setResultsData] = useState({
    items: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const columns = [
    {
      Header: 'warehouse name',
      accessor: 'name',
    },
    {
      Header: 'Disponible',
      accessor: 'disponible',
    },
    {
      Header: 'deliveryMen Nbr',
      accessor: 'deliveryMenCount',
    },
    {
      Header: 'deliveries',
      accessor: 'ordersCount',
    },
    {
      Header: 'refresh',
      accessor: 'refresh',
    },
  ];
  const handleModal = (item, index, type) => {
    handleCloseModal();
    setItemRow(item);
    setIndexOrganisation(index);
    setOpenModal(type);
  };

  const handleCloseModal = () => {
    setOpenModal('');
  };
  const prepareShowPartners = (item, index) => {
    console.log(item);
    return {
      ...item,
      disponible: warehouses[item.wareHouseId] ? (
        warehouses[item.wareHouseId]?.disponible ? (
          'yes'
        ) : (
          'no'
        )
      ) : (
        <RefreshIcon />
      ),
      deliveryMenCount: warehouses[item.wareHouseId] ? (
        warehouses[item.wareHouseId]?.deliveryMenCount
      ) : (
        <RefreshIcon />
      ),
      ordersCount: warehouses[item.wareHouseId] ? (
        warehouses[item.wareHouseId]?.ordersCount
      ) : (
        <RefreshIcon />
      ),
      refresh: (
        <Button
          onClick={() => getDisponibilityBySingleWarehouse(item.wareHouseLabel)}
        >
          <RefreshIcon />
        </Button>
      ),
    };
  };
  const fetchCitiesList = () => {
    getCities()
      .then(({ data }) => setCities(data))
      .catch(({ error }) => console.log(error));
  };
  const fetchPartnersList = (page, limit) => {
    getParners(page, limit)
      .then(({ data }) => {
        setPartners(
          data.cities.length > 0 &&
            data.cities.reduce(
              (cumulator, obj) => [...cumulator, ...obj?.warehouses],
              [],
            ),
        );
        setTotal(data.total);
      })
      .catch(({ error }) => console.log(error));
  };

  const handlePaginate = (val) => {
    setResultsData({
      items: [],
      tableItems: [],
    });
    fetchPartnersList(val.pageSelect, val.numberOfRows);
    setPage(val.pageSelect);
    setRowsPerPage(val.numberOfRows);
  };
  useEffect(() => {
    fetchCitiesList();
    fetchPartnersList();
  }, []);
  const getDisponibiltyByWareHouse = () => {
    let warehousesObj = {};
    Promise.allSettled(
      partners.map((item, index) => {
        return new Promise((resolve, reject) => {
          getDisponibility(item.wareHouseLabel)
            .then(({ data }) => {
              warehousesObj[item.wareHouseId] = { ...data };
              resolve();
            })
            .catch((err) => {
              reject();
            });
        });
      }),
    )
      .then(() => {
        setWarehouses(warehousesObj);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getDisponibilityBySingleWarehouse = (id) => {
    getDisponibility(id)
      .then(({ data }) => {
        const tempWarehouses = { ...warehouses };
        tempWarehouses[id] = { ...data };
        setWarehouses(tempWarehouses);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    partners && partners.length > 0 && getDisponibiltyByWareHouse();
  }, [partners]);
  useEffect(() => {
    warehouses &&
      Object.keys(warehouses).length > 0 &&
      setResultsData({
        items: partners.map((item, index) => {
          return prepareShowPartners(item, index);
        }),
      });
  }, [warehouses]);
  return (
    <div>
      <DataTableContainerComponent
        total={total}
        resultsData={resultsData}
        page={page + 1}
        initData={resultsData.items}
        columns={columns}
        title="Service disponibility"
        isLoading={isLoading}
        CreateItemFormComponent={CreateOrganisationComponent}
        loadItemsList={fetchPartnersList}
        onPaginate={handlePaginate}
        isPaginate={false}
      />
    </div>
  );
}
