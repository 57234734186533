import { DEFAULT_SELECTED_CITIES, SELECT_CITY, SELECT_WAREHOUSE } from '../constants/filter.constants';

export const defaultSelectedCities = () => ({
    type: DEFAULT_SELECTED_CITIES
})
export const selectCityAction = (cities) => ({
    type: SELECT_CITY,
    cities
})
export const selectWareHouseAction = (select, value) => ({
    type: SELECT_WAREHOUSE,
    select, value
})