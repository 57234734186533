import React, { useState, useEffect } from 'react';
import { Tab, Box, Tabs } from '@material-ui/core';
import { TabPanel, TabContext, TabList } from '@material-ui/lab';
import GridContainer from '../../../components/material-dashboard-pro-react/Grid/GridContainer';
import Card from '../../../components/material-dashboard-pro-react/Card/Card';
import CardHeader from '../../../components/material-dashboard-pro-react/Card/CardHeader';
import CardBody from '../../../components/material-dashboard-pro-react/Card/CardBody';
import DeliveryManRating from './DeliveryManRatingList.component';
import StoreRatingList from './StoreRatingList.component';
import KaalixAppRatingList from './KaalixAppRatingList.component';

export default function AppSettingPage() {
  const [value, setValue] = useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <GridContainer>
      <Card>
        <CardHeader> Rating setting</CardHeader>
        <CardBody>
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider'}}>
                <TabList
                  variant="scrollable"
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab label="deliveryMan Rating List" value="1" />
                  <Tab label="kaalix App Rating List" value="2" />
                  <Tab label="store Rating List" value="3" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <DeliveryManRating />
              </TabPanel>
              <TabPanel value="2">
              <KaalixAppRatingList />
              </TabPanel>
              <TabPanel value="3">
                <StoreRatingList />
              </TabPanel>
            </TabContext>
          </Box>
        </CardBody>
      </Card>
    </GridContainer>
  );
}
