/* global google */

import React, { useEffect, useState, MouseEvent } from 'react';
import io from 'socket.io-client';
import {
  getOrderRequestAction,
  changeOrderStatusAction,
  newOrderAction,
  cancelOrderAction,
  initialOrderAction,
} from '../redux/actions/order.actions';
import { useSelector, useDispatch } from 'react-redux';
import { getOrdersDataState } from '../redux/selectors';
import { getUserDataState } from '../../auth/redux/selectors';
import SelectBoxComponent from '../../../components/SelectBox.component';
import {
  acceptOrRejectOrder,
  getCities,
  getCurrentOrders,
  getDeliveryMan,
  getOrders,
  updateOrderStatus,
  cancelOrder,
  socketConnect,
} from '../services/serverApi.service';
import { fetchSetting } from '../../setting/services/serverApi.service';
import { getDeliveryManInCity } from '../../dashboard/services/serverAPI.service';

import {
  Typography,
  Grid,
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  FormControlLabel,
  Button,
  Menu,
  MenuItem,
  Select,
} from '@material-ui/core';
import { MultiColumnComboBox } from '@progress/kendo-react-dropdowns';
import '@progress/kendo-theme-default/dist/all.css';
import DataTableSortingContainer from '../../../components/DataTableSortingContainer.component';
import ButtonComponent from '../../../components/material-dashboard-pro-react/CustomButtons/Button';

import SearchIcon from '@material-ui/icons/Search';
import PhoneIcon from '@material-ui/icons/Phone';
import RoomIcon from '@material-ui/icons/Room';
import StoreIcon from '@material-ui/icons/Store';
import PersonIcon from '@material-ui/icons/Person';
import CommentIcon from '@mui/icons-material/Comment';
import Map from '@material-ui/icons/Map';
import SportsMotorsportsIcon from '@material-ui/icons/SportsMotorsports';
import OrderDetail from '../components/OrderDetail.component';
import { formatTimeForOrder, calcDuration } from '../../../helpers/index';
import Popover from '@material-ui/core/Popover';
import { PopoverHeader } from 'react-bootstrap';
import StoreDetailsComponent from '../components/StoreDetails.component';
import ClientDetailsComponent from '../components/ClientDetail.component';
import ProviderDetailsComponent from '../components/ProviderDetails.component';
import DisplayOrderInMap from '../components/DisplayOrderInMap';
import InvalidOrderComponent from '../components/InvalidOrder.component';
import ChangeDuration from '../components/ChangeDuration.components';
import ModalComponent from '../../../components/Modal.component';
import DeliveryManStatusTimeComponent from '../components/DeliveryManStatusTimeComponent';
import CommentComponent from '../components/Comment.component';

// status
import {
  DeliveryManStatus,
  DispatchingStatus,
  StoreStatus,
  CustomerStatus,
  deliveryStatus,
  orderDispatchingStatus,
} from '../../../config/status.config';
import { getDistanceFromLatLonInKm } from '../../../helpers/index';
import { invalidTxt, allArray } from '../mock/index';
import { useAlert } from 'react-alert';
import notifSong from '../../../assets/audio/pristine-609.mp3';

var selectedCityArry = [];
export default function OrdersPage() {
  const alert = useAlert();
  const selectedCityArray = JSON.parse(
    localStorage.getItem('selectedCityArray'),
  );
  const dispatch = useDispatch();
  const { invalidOrders, loading, error } = useSelector(getOrdersDataState);
  const userData = useSelector(getUserDataState);
  const [admin, setAdmin] = useState({
    _id: userData.user._id,
    entityId: userData.user._id,
    email: userData.user.email,
    userName: userData.user.username,
    username: userData.user.username,
    by: 'admin',
  });
  const [deliveryInCity, setDeliverInCity] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [checkedObj, setCheckedObj] = useState(selectedCityArray);
  const [anchorEl, setAnchorEl] = useState(null);
  const [socket, setSocket] = useState(null);
  const [open, setOpen] = useState('');
  const [anchorElPopover, setAnchorElPopover] = useState(null);
  // const [deliveryMan, setDeliveryMan] = useState([]);
  const [selectedDeliveryMan, setSelectedDeliveryMan] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [order, setOrder] = useState(null);
  const [pricePaid, setPricePaid] = useState(0);
  const [openModal, setOpenModal] = useState('');
  const [indexStore, setIndexStore] = useState(-1);
  const [orderRow, setOrdeRow] = useState('');
  const [orderIdForComments, setOrderIdForComments] = useState('');
  const [filterOrder, setFilterOrder] = useState({
    All: true,
    WAITING_FOR_ACCEPT: true,
    ACCEPTED: true,
  });
  const [cancel, setCancel] = useState([]);
  const [cancelRaison, setCancelRaison] = useState({ cancelReason: '' });
  const [resultsData, setResultsData] = useState({
    total: 0,
    items: [],
    tableItems: [],
  });
  const [selectedCities, setSelectedCities] = useState(
    selectedCityArray || allArray,
  );
  const customColumnStyle = {
    maxWidth: '90px',
    minWidth: '90px',
    fontSize: '13px',
  };
  const columns = [
    {
      Header: 'Id Cmd',
      accessor: 'shortId',
    },
    {
      Header: 'Store',
      accessor: 'storeName',
    },
    {
      Header: 'Client',
      accessor: 'clientName',
    },
    {
      Header: 'Provider',
      accessor: 'deliveryManInp',
    },
    {
      Header: 'Ville',
      accessor: 'city.name',
    },

    {
      Header: 'Provider.S',
      accessor: 'deliveryManStatusInp',
    },
    {
      Header: 'Customer.S',
      accessor: 'customerStatusInp',
    },
    {
      Header: 'Store.S',
      accessor: 'storeStatusInp',
    },
    {
      Header: 'Dispatch.S',
      accessor: 'dispatchingStatusInp',
    },
    {
      Header: 'invalid',
      accessor: 'invalidCol',
    },
    {
      Header: 'Admin',
      accessor: 'admin',
    },
    {
      Header: 'Validator',
      accessor: 'validator',
    },
    {
      Header: 'total',
      accessor: 'orderPrice',
    },
    {
      Header: 'Payment',
      accessor: 'paymentMode',
    },
    {
      Header: 'Created',
      accessor: 'createdAtInp',
    },
    {
      Header: 'duration',
      accessor: 'duration',
    },
    {
      Header: 'comment',
      accessor: 'lastComment',
    },
    {
      Header: 'Raccourci',
      accessor: 'Raccourci',
    },
    {
      Header: 'Actions',
      accessor: 'menu',
    },
  ];
  // console.log(admin);
  const getInfoAboutDelivery = (deliveryInCity) => {
    const counts = {};
    setIsLoading(true);
    resultsData.items.forEach(function (x) {
      counts[x?.deliveryMan?._id] = (counts[x?.deliveryMan?._id] || 0) + 1;
    });
    order &&
      deliveryInCity &&
      deliveryInCity.length > 0 &&
      deliveryInCity.map((delivery) => {
        delivery['orders'] = counts[delivery._id];
        if (delivery.location) {
          delivery['distanceToStore'] = getDistanceFromLatLonInKm(
            order.store.location[0],
            order.store.location[1],
            delivery.location[0],
            delivery.location[1],
          );
          switch (order.payment.serviceType) {
            case 'Stores':
              delivery['distanceToClient'] = getDistanceFromLatLonInKm(
                order.payment.destinationAddress.location[0],
                order.payment.destinationAddress.location[1],
                delivery.location[0],
                delivery.location[1],
              );

              break;

            default:
              break;
          }
        }
      });
    setDeliverInCity(deliveryInCity);
    setIsLoading(false);
  };
  const uploadInvoice = () => {
    handleStatus();
    handleClosePopover();
  };
  const handleOpenModal = (type) => {
    // console.log(order);
    handleClose();
    setOpen(type);
  };
  const handleModal = (item, index, type) => {
    handleClose();
    setOrdeRow(item);
    setIndexStore(index);
    setOpenModal(type);
  };

  const handleCloseModal = () => {
    setOpen('');
    setOpenModal('');
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  // console.log(selectedCity);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickPopover = (e) => {
    setAnchorElPopover(e.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorElPopover(null);
  };
  // console.log(orders);
  const accept_Or_Reject_Order = (accept) => {
    if (!!selectedDeliveryMan) {
      setIsLoading(true);
      acceptOrRejectOrder(order._id, {
        deliveryManId: selectedDeliveryMan._id,
        accept: accept,
      }).then(({ data }) => {
        setIsLoading(false);
        handleCloseModal();
        // loadOrderList(selectedCity);
        dispatch(getOrderRequestAction(selectedCity));
      });
    }
  };
  const handleStatus = () => {
    const deliveryManId = !!order.deliveryMan ? order.deliveryMan._id : null;
    if (deliveryManId) {
      updateOrderStatus(order._id, {
        deliveryManId: deliveryManId,
        status: selectedStatus,
        invoiceUrl: 'url:///',
        pricePaid: pricePaid,
      }).then(({ data }) => {
        handleCloseModal();
      });
    }
  };
  const handleCancel = () => {
    cancelOrder(order._id, { ...cancelRaison, ...admin })
      .then(({ data }) => {
        dispatch(cancelOrderAction(order._id));
      })
      .catch((error) => console.log(error))
      .finally(() => handleCloseModal());
  };
  const getCitiesList = () => {
    getCities()
      .then(({ data }) => {
        const cityArray = [{ name: 'All', _id: 'all' }].concat(data);
        setCities(cityArray);
      })
      .catch((error) => console.log(error));
  };

  const notification = () => {
    const audio = new Audio(notifSong);
    audio.play().catch((error) => {
      console.log('Audio error::', { error });
    });
  };
  const setOrderToState = (data) => {
    const newDataArray = [...data];
    newDataArray &&
      setResultsData({
        items: newDataArray.map((item, index) => {
          return {
            ...item,
            lastComment:
              item?.comments && item.comments.length
                ? item.comments[item.comments.length - 1]?.comment
                : '',
            deliveryManStatusInp:
              deliveryStatus[item.deliveryManStatus] || item.deliveryManStatus,
            storeStatusInp: StoreStatus[item.storeStatus] || item.storeStatus,
            dispatchingStatusInp:
              orderDispatchingStatus[item.dispatchingStatus] ||
              item.dispatchingStatus,
            customerStatusInp:
              CustomerStatus[item.customerStatus] || item.customerStatus,
            Raccourci: (
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <StoreIcon
                    style={{ cursor: 'pointer', fontSize: '17px' }}
                    fontSize="small"
                    onClick={() => {
                      handleModal(item, index, 'store details');
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <PersonIcon
                    style={{ cursor: 'pointer', fontSize: '17px' }}
                    fontSize="small"
                    onClick={() => {
                      handleModal(item, index, 'clinet details');
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <SportsMotorsportsIcon
                    style={{ cursor: 'pointer', fontSize: '17px' }}
                    fontSize="small"
                    onClick={() => {
                      handleModal(item, index, 'provider details');
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Map
                    style={{ cursor: 'pointer', fontSize: '17px' }}
                    fontSize="small"
                    onClick={() => {
                      handleModal(item, index, 'Maps');
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <CommentIcon
                    style={{ cursor: 'pointer', fontSize: '17px' }}
                    onClick={() => {
                      handleModal(item, index, 'Comment');
                      setOrderIdForComments(item._id);
                    }}
                  />
                </Grid>
              </Grid>
            ),
            storeName: item?.store?.name,
            clientName: item?.customer?.name,
            deliveryManInp: item?.deliveryMan && item?.deliveryMan.name,
            createdAtInp: item.createdAt && (
              // <ChangeDuration time={item.createdAt}/>
              <div style={{ fontSize: '11px' }}>
                <span>{formatTimeForOrder(item.createdAt).split('(')[0]}</span>
                <span>
                  {`(`}
                  {formatTimeForOrder(item.createdAt).split('(')[1]}
                </span>
              </div>
            ),
            map: (
              <div>
                <RoomIcon />
                <SearchIcon />
                <PhoneIcon />
              </div>
            ),
            detail: (
              <div>
                <Button
                  aria-describedby={'Popover_' + item._id}
                  onClick={(e) => {
                    setOrder(item);
                    setSelectedStatus('UPLOAD_INVOICE');
                    setPricePaid(0);
                    handleClickPopover(e);
                  }}
                >
                  <SearchIcon />
                </Button>
              </div>
            ),
            menu: (
              <div>
                <Button
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={($event) => {
                    handleClick($event);
                    setOrder(item);
                    setSelectedStatus(item.deliveryManStatus);
                  }}
                >
                  Open Menu
                </Button>
              </div>
            ),
            duration: (
              <>
                <ChangeDuration
                  deliveryManStatusTime={
                    item?.deliveryManStatusTimes &&
                    item?.deliveryManStatusTimes.length &&
                    item?.deliveryManStatusTimes[
                      item?.deliveryManStatusTimes.length - 1
                    ]?.date
                  }
                  time={item.createdAt}
                />
                <DeliveryManStatusTimeComponent
                  deliveryManStatusTime={
                    item?.deliveryManStatusTimes &&
                    item?.deliveryManStatusTimes.length
                      ? item?.deliveryManStatusTimes[
                          item?.deliveryManStatusTimes.length - 1
                        ]?.date
                      : ''
                  }
                />
              </>
            ),
            orderPrice: item?.payment?.total && item?.payment?.total.toFixed(2),
            paymentMode:
              item.payment.payments &&
              item.payment.payments.map(
                (mode) => mode.amount > 0 && <p>{mode.gateway}</p>,
              ),
            invalidCol: (
              <div
                style={{ cursor: 'pointer', padding: '10px' }}
                onClick={() => {
                  handleModal(item, index, 'invalid');
                }}
              >
                {/* {if(item.inValidUser||item.inValidPosition||item.inValidNewUser||){
                
              }} */}
                {item.inValidUser && invalidTxt.inValidUser + ' '}
                {item.inValidPosition && invalidTxt.inValidPosition + ' '}
                {item.inValidNewUser && invalidTxt.inValidNewUser + ' '}
                {item.inValidPhone && invalidTxt.inValidPhone + ' '}
              </div>
            ),
          };
        }),
      });
  };
  const fetchSettingFromAPI = () => {
    fetchSetting()
      .then(({ data }) => {
        data && data.length && setCancel(data[0].admin_cancel_reasons);
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    dispatch(initialOrderAction());
    fetchSettingFromAPI();
    if (!socket) {
      setSocket(socketConnect(userData.accessToken));
    }
    function subscribeToAdminRoom() {
      if (socket) {
        socket.emit('JOIN_ROOM', 'ADMIN_DISPATCHING');
      }
    }
    if (socket) {
      subscribeToAdminRoom();
    }
    getCitiesList();
  }, [socket]);

  useEffect(() => {
    if (!selectedCityArray && cities.length) {
      localStorage.setItem('selectedCityArray', JSON.stringify(allArray));
      cityArrayForTheFirstTime = allArray;
      selectedCityArry = allArray;
      dispatch(getOrderRequestAction(allArray));
    } else {
      selectedCityArry = selectedCityArray;
      selectedCityArray &&
        selectedCityArray.length &&
        dispatch(getOrderRequestAction(selectedCityArray));
    }
  }, [cities]);
  useEffect(() => {
    // console.log(invalidOrders);
    !loading && invalidOrders && setOrderToState(invalidOrders);
  }, [JSON.stringify(invalidOrders)]);
  useEffect(() => {
    const random = Math.floor(Math.random() * 100) + 1;
    socket &&
      socket.on('ORDER_STATUS_CHANGED', function (data) {
        if (
          selectedCityArry.includes('all') ||
          selectedCityArry.includes(data.city._id)
        ) {
          dispatch(changeOrderStatusAction(data));
        }
      });
    socket &&
      socket.on('NEW_ORDER', function (data) {
        // console.log('New Order', data._id);
        if (
          selectedCityArry.includes('all') ||
          selectedCityArry.includes(data.city._id)
        ) {
          if (data.dispatchingStatus == 'INVALID') {
            dispatch(newOrderAction(data));
            notification();
            alert.show('new order', {
              type: 'success',
            });
          }
        }
      });

    socket &&
      userData &&
      socket.on('ORDER_CANCELLED', function (data) {
        dispatch(cancelOrderAction(data._id));
      });
  }, [socket]);
  useEffect(() => {
    return () => {
      socket && socket.disconnect();
    };
  }, [socket]);
  const onChangeSelectBox = (items) => {
    if (
      items.length > 1 &&
      items.includes('all') &&
      selectedCities.includes('all')
    ) {
      const index = items.indexOf('all');
      index >= 0 && items.splice(index, 1);
      // _.without(items,'all')
    } else if (
      items.length &&
      items.includes('all') &&
      !selectedCities.includes('all')
    ) {
      items = allArray;
    }
    localStorage.setItem('selectedCityArray', JSON.stringify(items));
    setSelectedCities(items);
    dispatch(getOrderRequestAction(items));
  };
  return (
    <div>
      <div className={'p-3 my-3'} style={{ background: '#ffffff' }}>
        <Grid container justify="flex-end">
          <ButtonComponent
            round
            type="submit"
            color="success"
            //  disabled={!formik.dirty || !formik.isValid || isLoading}
            loading={isLoading}
            loaderColor="white"
            onClick={() => {
              dispatch(getOrderRequestAction(selectedCities));
            }}
          >
            reload
          </ButtonComponent>
        </Grid>
        <Grid container>
          <SelectBoxComponent
            items={cities.map((city) => ({
              value: city._id,
              label: city.name,
            }))}
            inputLabel="cities"
            onValueChange={(items) => onChangeSelectBox(items)}
            value={selectedCities}
            multiple
          />
        </Grid>
      </div>
      <card>
        <DataTableSortingContainer
          resultsData={resultsData}
          initData={resultsData.items}
          columns={columns}
          title="dispatching service"
          // isLoading={isLoading}
          // loadItemsList={loadOrderList}
          customColumnStyle={customColumnStyle}
        />
      </card>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleOpenModal('detail')}>detail</MenuItem>
        <MenuItem onClick={() => handleOpenModal('cancel')}>cancel</MenuItem>
      </Menu>

      <ModalComponent
        maxWidth={'lg'}
        close={handleCloseModal}
        visible={open === 'detail'}
        title="detail"
        fullHeight={true}
      >
        <OrderDetail item={order} />
      </ModalComponent>
      <Popover
        anchorEl={anchorElPopover}
        open={Boolean(anchorElPopover) ? 'simple-popover' : undefined}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <PopoverHeader>Upload Invoice</PopoverHeader>
        <div className={'p-2'}>
          <form>
            <input
              type="number"
              value={pricePaid}
              onChange={(e) => setPricePaid(e.target.value)}
            />
            <Button type="button" onClick={uploadInvoice}>
              upload
            </Button>
          </form>
        </div>
      </Popover>

      <ModalComponent
        maxWidth={'sm'}
        close={handleCloseModal}
        visible={open === 'cancel'}
        title="cancel"
      >
        <div>
          <Box style={{ padding: '15px' }}>
            <Grid container className="mb-2">
              <Grid item xs={6} style={{ display: 'flex' }}>
                orderID : <Typography>{order?.shortId}</Typography>
              </Grid>
              <Grid item xs={6} style={{ display: 'flex' }}>
                store : <Typography>{order?.store?.name}</Typography>
              </Grid>
              <Grid item xs={6} style={{ display: 'flex' }}>
                customer : <Typography>{order?.customer?.name}</Typography>
              </Grid>
              <Grid item xs={6} style={{ display: 'flex' }}>
                deliveryMan :{' '}
                <Typography>{order?.deliveryMan?.name}</Typography>
              </Grid>
            </Grid>
            <Grid container>
              <FormControl style={{ width: '100%' }} variant="outlined">
                <InputLabel>Cancel</InputLabel>
                <Select
                  style={{ background: '#ffffff' }}
                  native
                  value={cancelRaison.cancelReason}
                  onChange={(e) => {
                    setCancelRaison({ cancelReason: e.target.value });
                  }}
                  label={'cancel'}
                >
                  <option value="" disabled />
                  {cancel.map((item) => (
                    <option value={item}>{item}</option>
                  ))}
                </Select>
              </FormControl>
              <div style={{ float: 'right', margin: '15px' }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    handleCancel();
                  }}
                >
                  save
                </Button>
              </div>
            </Grid>
          </Box>
        </div>
      </ModalComponent>

      {openModal && (
        <ModalComponent
          maxWidth={'false'}
          close={handleCloseModal}
          visible={openModal === 'store details'}
          title="Store Details"
        >
          <StoreDetailsComponent order={orderRow} close={handleCloseModal} />
        </ModalComponent>
      )}
      {openModal && (
        <ModalComponent
          maxWidth={'false'}
          close={handleCloseModal}
          visible={openModal === 'clinet details'}
          title="Client Details"
        >
          <ClientDetailsComponent order={orderRow} close={handleCloseModal} />
        </ModalComponent>
      )}
      {openModal && (
        <ModalComponent
          maxWidth={'false'}
          close={handleCloseModal}
          visible={openModal === 'provider details'}
          title="Provider Details"
        >
          <ProviderDetailsComponent order={orderRow} close={handleCloseModal} />
        </ModalComponent>
      )}
      {openModal && (
        <ModalComponent
          maxWidth={'false'}
          close={handleCloseModal}
          visible={openModal === 'Maps'}
          title="Maps"
        >
          <DisplayOrderInMap
            order={orderRow}
            close={handleCloseModal}
            orders={resultsData.items}
          />
        </ModalComponent>
      )}
      {openModal && (
        <ModalComponent
          maxWidth={false}
          close={handleCloseModal}
          visible={openModal === 'invalid'}
          title="inValid Order"
        >
          <InvalidOrderComponent
            order={orderRow}
            close={handleCloseModal}
            orders={resultsData.items}
            adminData={admin}
          />
        </ModalComponent>
      )}
      {openModal && (
        <ModalComponent
          maxWidth={'false'}
          close={handleCloseModal}
          visible={openModal === 'Comment'}
          title="comment"
        >
          <CommentComponent
            close={handleCloseModal}
            admin={admin}
            order={(invalidOrders || []).find(
              (x) => x._id == orderIdForComments,
            )}
          />
        </ModalComponent>
      )}
    </div>
  );
}
