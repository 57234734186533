import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { Box } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import PropTypes from 'prop-types';
import { useAlert } from 'react-alert';
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import CustomInput from '../../../components/material-dashboard-pro-react/CustomInput/CustomInput';
import SwitchComponent from '../../../components/Switch.component'
import {
  getCities,
  updateServiceCategory,
} from '../services/serverApi.service';
import SelectBoxComponent from '../../../components/SelectBox.component';
import ImageUpload from '../../../components/material-dashboard-pro-react/CustomUpload/ImageUpload';
import { serviceTypes } from '../mocks';
import { uploadMediaApi } from '../../../services/serverApi.service';
import {defaultImagesAction,uploadImageResetAction} from '../../../redux/uploadRedux/upload.action'


const validationSchema = yup.object({
  name: yup.string().required(),
  type: yup.string().required(),
  iconUrl: yup.string(),
  cityList: yup.array().required(),
  oneServiceCategory:yup.boolean().required(),
  oneStoreCategory:yup.boolean().required(),
});

export default function EditServiceCategoryComponent({ data, onItemEdited,close }) {
  
  const alert = useAlert();
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false);
  const [displayImage, setDisplayImage] = useState(false);
  const upload = useSelector((state) => state.uploadReducer)
  const { iconUrl, error, loading } = upload
  const formik = useFormik({
    initialValues: {
      name: '',
      type: '',
      iconUrl: '',
      cityList: [],
      oneServiceCategory:false,
      oneStoreCategory:false
    },
    validationSchema,
  });
  const [cities, setCities] = useState([]);

  const onUpdate = () => {
    setIsLoading(true);

    console.log(JSON.stringify(formik.values));
    updateServiceCategory(data._id, formik.values)
      .then(() => {
        alert.show('Service category has been updated', {
          type: 'success',
        });
        close()
        onItemEdited();
        dispatch(uploadImageResetAction())
      })
      .catch((error) => {
        alert.show(error.message, {
          type: 'error',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // const onUploadMedia = (file) => {
  //   uploadMediaApi(file)
  //     .then(() => {
  //       alert.show('Image has been uploaded', {
  //         type: 'success',
  //       });
  //     })
  //     .catch((error) => {
  //       alert.show(error.message, {
  //         type: 'error',
  //       });
  //     });
  // };

  const onRemoveImage = () => {
    formik.setFieldValue('iconUrl', '');
  };
  console.log(data);
  function setValue(){
    if (data) {
      // console.log(data);

      !iconUrl&& dispatch(defaultImagesAction(data?.iconUrl))
      // if(iconUrl){
        formik.setValues({
          name: data?.name,
          type: data?.type,
          iconUrl,
          cityList: data?.cities.map((city)=>(city._id)),
          oneServiceCategory:data?.oneServiceCategory||false,
          oneStoreCategory:data?.oneStoreCategory||false,
        // tags: [],
      })}
    }
  useEffect(() => {
    setValue()
    
    getCities()
      .then(({ data}) => {
        setCities(data);
      })
      .catch((error) => {
        alert.show(error.message, {
          type: 'error',
        });
      });
  }, [data,iconUrl]);


  return (
    <div>
      <div>{!displayImage && <span>you need to upload a new image</span>}
          <Switch
            color='primary'
            checked={displayImage}
            value={displayImage}
            onChange={e => { displayImage ? setDisplayImage(false) : setDisplayImage(true) }}
          />
        </div>
      <Box display="flex" justifyContent="center">
        {Boolean(data) && (
          !displayImage ?

          <img src={data.iconUrl} alt="img" height="100" width="120" />

          :
          <ImageUpload
            defaultImage={data.iconUrl}
            label="Select icon"
            avatar
            folder='service_category'
          />
        )}
      </Box>
      <CustomInput
        labelText="Name"
        formControlProps={{
          fullWidth: true,
        }}
        type="text"
        error={formik.touched.name && Boolean(formik.errors.name)}
        inputProps={{
          value: formik.values.name,
          onBlur: formik.handleBlur('name'),
          onChange: formik.handleChange('name'),
        }}
      />
      <SelectBoxComponent
        items={cities.map((city) => ({
          value: city._id,
          label: city.name,
        }))}
        inputLabel="City list"
        onValueChange={(items) => {
          formik.setFieldValue('cityList', items);
        }}
        value={formik.values.cityList}
        multiple
      />
      <SelectBoxComponent
        items={serviceTypes.map((item) => ({
          value: item.value,
          label: item.label,
        }))}
        inputLabel="Type"
        onValueChange={formik.handleChange('type')}
        value={formik.values.type}
      />
      <SwitchComponent
                onChange={(value) => formik.setFieldValue('oneServiceCategory', value)}
                checked={formik.values.oneServiceCategory}
                error={formik.touched.oneServiceCategory && Boolean(formik.errors.oneServiceCategory)}
                label="oneServiceCategory"
            />
      <SwitchComponent
                onChange={(value) => formik.setFieldValue('oneStoreCategory', value)}
                checked={formik.values.oneStoreCategory}
                error={formik.touched.oneStoreCategory && Boolean(formik.errors.oneStoreCategory)}
                label="oneStoreCategory"
            />
      <Box textAlign="center">
        <Button
          round
          type="submit"
          color="success"
          disabled={!formik.dirty || !formik.isValid || isLoading}
          loading={isLoading}
          loaderColor="white"
          onClick={onUpdate}
        >
          Edit
        </Button>
      </Box>
    </div>
  );
}

EditServiceCategoryComponent.propTypes = {
  data: PropTypes.any,
  onItemEdited: PropTypes.func.isRequired,
  close: PropTypes.func,
};
