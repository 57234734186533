export const destinationTypes = {
    deliveryMan:'deliveryMan',
    store:'store',
    client:'client',
    search : 'search',
}
export const displayDeliveryMan = [{name:"all"},{name:'withoutOrder'},{name:"withOrder"}]
export const seachBy = [{name:"delivery"},{name:'store'},{name:"client"},{name:"order"}]
export const filterDeliveryManStatus = {
    all:"all",
    COMING_FOR_DELIVERY:"COMING_FOR_DELIVERY",
    ARRIVED_TO_STORE:"ARRIVED_TO_STORE",
    STARTED_DELIVERY:"STARTED_DELIVERY",
}
export const arrayForDeliveryManCommingToStore = [
    'WAITING_FOR_ACCEPT','ACCEPTED','START_PREPARATION','COMING_FOR_DELIVERY',
]
export const arrayForDeliveryManArrivedToStore = [
    'ARRIVED_TO_PICKUP'
]
export const arrayForDeliveryManStartDelivery = [
    'STARTED_DELIVERY','ARRIVED_TO_DESTINATION','DELIVERED'
]