import { takeLatest, put } from 'redux-saga/effects'
import axios from 'axios'
import { getAllPartnerOrderSuccessAction, getAllPartnerOrderFailAction } from './actions/order.actions'
import { GET_ALLPARTNER_ORDER_REQUEST } from './constants/order.constants'
import { MICROSERVICE_BASE_URL } from '../../../config/backendServer.config'

function* getAOrdersAllPatnersFromAPI() {
  const { data } = yield axios.get(
    `${MICROSERVICE_BASE_URL.ALLPARTNERS}/deliveries/current`,
  );

  return data
}
function* getAllPartnerOrders({ cities, warehouses }) {
  try {
    const data = yield getAOrdersAllPatnersFromAPI();
    yield put(getAllPartnerOrderSuccessAction(data, cities, warehouses))

  }
  catch (error) {
    console.log(error);
    yield put(getAllPartnerOrderFailAction(error))
  }

}


export default function* watcherSage() {
  yield takeLatest(GET_ALLPARTNER_ORDER_REQUEST, getAllPartnerOrders)

}