import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import moment from 'moment';
import withReactContent from 'sweetalert2-react-content';

import DataTableSortingContainer from '../../../components/DataTableSortingContainer.component';

import {
  getOrderRating,
  addCommentInOrderRating,
} from '../services/serverApi.service';
import { getUserDataState } from '../../auth/redux/selectors';
import { Button } from '@material-ui/core';

import {
  formatTimeForOrder,
  calcDuration,
  convertIdToShortId,
} from '../../../helpers/index';

export default function OrderRatingPage() {
  const MySwal = withReactContent(Swal);
  const userData = useSelector(getUserDataState);
  const [resultsData, setResultsData] = useState({
    total: 0,
    items: [],
  });
  const [admin, setAdmin] = useState({
    id: userData.user._id,
    email: userData.user.email,
    username: userData.user.username,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  let numberOfRows = [20, 50, 100];
  const [rowsPerPage, setRowsPerPage] = useState(numberOfRows[0]);
  const [total, setTotal] = useState(0);
  const [orderRating, setOrderRating] = useState([]);
  const columns = [
    {
      Header: 'order Id',
      accessor: 'order[0].shortId',
    },
    {
      Header: 'city',
      accessor: 'order[0].city.name',
    },
    {
      Header: 'deliveryMan',
      accessor: 'order[0].deliveryMan.name',
    },
    {
      Header: 'deliveryManRate',
      accessor: 'deliveryManRateCell',
    },
    {
      Header: 'delivery Comment',
      accessor: 'deliveryManComment',
    },
    {
      Header: 'store',
      accessor: 'order[0].store.name',
    },
    {
      Header: 'store Rate',
      accessor: 'storeRateCell',
    },
    {
      Header: 'store Comment',
      accessor: 'storeComment',
    },
    {
      Header: 'customer',
      accessor: 'order[0].customer.name',
    },
    {
      Header: 'kaalix Rate',
      accessor: 'kaalixRate',
    },
    {
      Header: 'kaalixRateComment',
      accessor: 'kaalixRateComment',
    },
    {
      Header: 'comment',
      accessor: 'comment',
    },
    {
      Header: 'validator',
      accessor: 'validator.name',
    },
    {
      Header: 'validateAt',
      accessor: 'validateAt',
    },
    {
      Header: 'addComment',
      accessor: 'addComment',
    },
    {
      Header: 'created',
      accessor: 'createdAtInp',
    },
  ];
  const ratingString = (rating) => {
    switch (rating) {
      case 1:
        return 'Bon';
      case 0:
        return 'Rien';
      case -1:
        return 'Mauvais';

      default:
        break;
    }
  };
  const prepareToShowHistory = (item, index) => {
    return {
      ...item,
      shortId: convertIdToShortId(item.orderId),
      deliveryMan: item.deliveryManId,
      deliveryManRateCell:
        item.deliveryManRate !== '' && ratingString(item.deliveryManRate),
      storeRateCell: item.storeRate !== '' && ratingString(item.storeRate),
      createdAtInp: item.createdAt && formatTimeForOrder(item.createdAt),
      addComment: (
        <Button color="primary" onClick={() => openModalComment(item)}>
          add comment
        </Button>
      ),
      validateAt:
        item.dateValidation &&
        moment(item.dateValidation).format('DD/MM hh:mm'),
    };
  };
  const openModalComment = (order) => {
    MySwal.fire({
      title: 'Add Comment',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Add',
      cancelButtonText: 'close',
      input: 'textarea',
      inputLabel: 'order id : ' + order.order[0].shortId,
      inputValue: '',
      inputPlaceholder: 'Type your comment',
      closeOnConfirm: false,
      animation: 'slide-from-top',
    }).then((result) => {
      if (result.isConfirmed) {
        addCommentInOrderRating(order._id, {
          validator: admin,
          comment: result?.value,
        })
          .then((res) => {
            Swal.fire('Success!', '', 'success');
            getOrdersRatingHistory(page, rowsPerPage);
            setTimeout(() => {
              Swal.close();
            }, 2000);
          })
          .catch((e) => {
            Swal.fire('error!', '', 'error');
          });
      }
    });
  };
  const getOrdersRatingHistory = (skip, limit) => {
    setIsLoading(true);
    getOrderRating(skip, limit)
      .then(({ data }) => {
        setTotal(data.total);
        setOrderRating(data.orderRatings);
      })
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
  };
  const handlePaginate = (val) => {
    setResultsData({
      items: [],
      tableItems: [],
    });
    getOrdersRatingHistory(val.pageSelect, val.numberOfRows);
    setPage(val.pageSelect);
    setRowsPerPage(val.numberOfRows);
  };

  useEffect(() => {
    orderRating &&
      orderRating.length > 0 &&
      setResultsData({
        items: orderRating.map((item, index) => {
          return prepareToShowHistory(item, index);
        }),
      });
  }, [orderRating]);
  return (
    <div>
      <DataTableSortingContainer
        resultsData={resultsData}
        total={total}
        initData={resultsData.items}
        columns={columns}
        title="Orders Rating"
        isLoading={isLoading}
        page={page + 1}
        onPaginate={handlePaginate}
        isPaginate={true}
      />
    </div>
  );
}
