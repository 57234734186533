import React from 'react';
import styles from '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
} from '@chatscope/chat-ui-kit-react';
import { addComment } from '../services/serverApi.service';
import moment from 'moment';

export default function CommentV2Component({ _id, close, admin, comments }) {
  const sendMesage = (message) => {
    addComment(_id, {
      comment: message,
      adminName: admin.userName,
      adminId: admin._id,
    })
      .then(({ data }) => {
        close();
      })
      .catch((err) => console.log(err));
  };
  return (
    <div>
      <MainContainer>
        <ChatContainer>
          <MessageList>
            {(comments || []).map((comment) => (
              <Message
                key={JSON.stringify(comment.date)}
                model={{
                  message: comment.comment,
                  sentTime: moment(comment.date).format('hh:mm'),
                  direction:
                    comment.adminId == admin._id ? 'outgoing' : 'inComming',
                }}
              >
                <Message.Footer
                  sender={comment?.adminName}
                  sentTime={moment(comment.date).format('hh:mm')}
                />
              </Message>
            ))}
          </MessageList>
          <MessageInput
            placeholder="Type comment here"
            attachButton={false}
            onSend={sendMesage}
          />
        </ChatContainer>
      </MainContainer>
    </div>
  );
}
