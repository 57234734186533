import React, { useState, useEffect } from 'react';
// component
import DataTableContainerComponent from '../../../components/DataTableSortingContainer.component';
import CreateOrganisationComponent from '../components/Create.organisation.component';
import EditOrganisationComponent from '../components/Edit.organisation.component';
import ConfigOrganisationComponent from '../components/Config.organisation.component';
import EditPartnerInfoComponent from '../components/Edit.partner.info.component';
import WareHousesMapComponenet from '../components/WareHouses.map.componenet';
import CreateCommissionConfig from '../components/Commission.config';
import ModalComponent from '../../../components/Modal.component';
import { Button } from '@mui/material';

// call api
import { getParners } from '../services/server.api.service';
import { getCities } from '../../orders/services/serverApi.service';

// mock
import { columns } from '../mock';

export default function OrganisationPage() {
  const [openModal, setOpenModal] = useState(null);
  const [itemRow, setItemRow] = useState({});
  const [indexOrganisation, setIndexOrganisation] = useState(-1);
  const [cities, setCities] = useState([]);
  const [partners, setPartners] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [resultsData, setResultsData] = useState({
    items: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState(0);

  const handleModal = (item, index, type) => {
    handleCloseModal();
    setItemRow(item);
    setIndexOrganisation(index);
    setOpenModal(type);
  };

  const handleCloseModal = () => {
    setOpenModal('');
  };
  const prepareShowPartners = (item, index) => {
    return {
      ...item,
      warehousesNbr: (
        <span
          style={{ cursor: 'pointer' }}
          onClick={() => handleModal(item, index, 'edit')}
        >
          {item?.warehouses && item?.warehouses.length}
        </span>
      ),
      config: (
        <Button onClick={() => handleModal(item, index, 'config')}>
          Webhooks
        </Button>
      ),
      commission: (
        <div>
          <Button
            onClick={() => {
              handleModal(item, index, 'commiConfigClient');
            }}
          >
            WareHouse/Client
          </Button>
          <Button
            color="secondary"
            onClick={() => {
              handleModal(item, index, 'commiConfigDeliveryMan');
            }}
          >
            WareHouse/DeliveryMan
          </Button>
        </div>
      ),
    };
  };
  const fetchCitiesList = () => {
    getCities()
      .then(({ data }) => setCities(data))
      .catch(({ error }) => console.log(error));
  };
  const fetchPartnersList = (page, limit) => {
    getParners(page, limit)
      .then(({ data }) => {
        console.log('watch',data)
        setPartners(data.organisations);
        setTotal(data.total);
      })
      .catch(({ error }) => console.log(error));
  };

  const handlePaginate = (val) => {
    setResultsData({
      items: [],
      tableItems: [],
    });
    fetchPartnersList(val.pageSelect, val.numberOfRows);
    setPage(val.pageSelect);
    setRowsPerPage(val.numberOfRows);
  };
  useEffect(() => {
    fetchCitiesList();
    fetchPartnersList();
  }, []);
  useEffect(() => {
    partners &&
      partners.length > 0 &&
      setResultsData({
        items: partners.map((item, index) => {
          return prepareShowPartners(item, index);
        }),
      });
  }, [partners]);
  return (
    <div>
      <DataTableContainerComponent
        total={total}
        resultsData={resultsData}
        page={page + 1}
        initData={resultsData.items}
        columns={columns}
        title="Partners List"
        isLoading={isLoading}
        CreateItemFormComponent={CreateOrganisationComponent}
        loadItemsList={fetchPartnersList}
        onPaginate={handlePaginate}
        isPaginate={true}
        actionButtons={{
          edit: {
            color: 'success',
            type: 'edit',
            component: EditPartnerInfoComponent,
            props: { itemRow: itemRow },
          },
          map: {
            color: 'info',
            type: 'map',
            props: { cities: cities },
            component: WareHousesMapComponenet,
          },
        }}
      />
      {openModal && openModal == 'edit' && (
        <ModalComponent
          maxWidth={'lg'}
          close={handleCloseModal}
          visible={openModal === 'edit'}
          title="edit"
        >
          <EditOrganisationComponent
            item={itemRow}
            indexOrganisation={indexOrganisation}
            cities={cities}
            close={handleCloseModal}
            loadData={fetchPartnersList}
          />
        </ModalComponent>
      )}
      {openModal && openModal == 'config' && (
        <ModalComponent
          maxWidth={'lg'}
          close={handleCloseModal}
          visible={openModal === 'config'}
          title="config"
        >
          <ConfigOrganisationComponent
            organisationId={itemRow._id}
            close={handleCloseModal}
          />
        </ModalComponent>
      )}
      {openModal &&
        (openModal == 'commiConfigClient' ||
          openModal == 'commiConfigDeliveryMan') && (
          <ModalComponent
            maxWidth={'md'}
            close={handleCloseModal}
            visible={true}
            title={
              openModal == 'commiConfigClient'
                ? 'commission Client'
                : 'commission DeliveryMan'
            }
          >
            <CreateCommissionConfig
              organisation={itemRow}
              close={handleCloseModal}
              commissionType={
                openModal == 'commiConfigClient'
                  ? 'commissionWareHouseClient'
                  : 'commissionWareHouseDeliveryMan'
              }
              loadItemsList={fetchPartnersList}
            />
          </ModalComponent>
        )}
    </div>
  );
}
