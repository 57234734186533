import React from 'react'
import CustomInput from '../../../components/material-dashboard-pro-react/CustomInput/CustomInput'


export default function ProviderDetailsComponent({ order}) {
    return (
        <div>
            {order?.deliveryMan &&
            <>
                <CustomInput
                    labelText="provider name"
                    formControlProps={{
                        fullWidth: true,
                    }}
                    type="text"
                    inputProps={{
                        value: order?.deliveryMan?.name,
                    }}
                    disabled={true}
                />
                <CustomInput
                    labelText="phone"
                    formControlProps={{
                        fullWidth: true,
                    }}
                    type="text"
                    inputProps={{
                        value: order?.deliveryMan?.phone,
                    }}
                    disabled={true}
                />
                <CustomInput
                    labelText="balance"
                    formControlProps={{
                        fullWidth: true,
                    }}
                    type="text"
                    inputProps={{
                        value: order?.deliveryMan?.balance,
                    }}
                    disabled={true}
                />
                {/* <CustomInput
                    labelText="status"
                    formControlProps={{
                        fullWidth: true,
                    }}
                    type="text"
                    inputProps={{
                        value: order?.deliveryManStatus,
                    }}
                    disabled={true}
                /> */}
                <CustomInput
                    labelText="commande comm"
                    formControlProps={{
                        fullWidth: true,
                    }}
                    type="text"
                    inputProps={{
                        value: order?.customer?.xp,
                    }}
                    disabled={true}
                />
                <CustomInput
                    labelText="commande perd"
                    formControlProps={{
                        fullWidth: true,
                    }}
                    type="text"
                    inputProps={{
                        value: order?.customer?.points,
                    }}
                    disabled={true}
                />
                <CustomInput
                    labelText="planning"
                    formControlProps={{
                        fullWidth: true,
                    }}
                    type="text"
                    inputProps={{
                        value: order?.customer?.workingCalendarId,
                    }}
                    disabled={true}
                />
                </>
            }
            

        </div>
    )
}
