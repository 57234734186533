import React from 'react'

export default function ClientAddressesComponent({addresses}) {
    const styles = {
        td: {
          verticalAlign: 'middle',
        },
        font: {
          fontWeight: 'bold',
        },
        cardTitle: {
          margin: '10px 0',
          fontWeight: 'bold',
        }
      }
    return (
        <div className="container row">
        {addresses&&addresses.length&&addresses.map((item,index)=>(
            <div className="col-3">
            <div style={styles.cardTitle}>{'address '+Number(index+1) }</div>
            <ul className="list-group ">
              <li className="list-group-item">
                <div className="d-flex justify-content-between">
                  <span>
                    city
                  </span>
                  <span style={styles.font}>
                    {item?.cityName}
                  </span>
                </div>
              </li>
              <li className="list-group-item">
                <div className="d-flex justify-content-between">
                  <span >details </span>
                  <span style={styles.font}>{item?.details }</span>
                </div>
              </li>
              <li className="list-group-item">
                  <span >{item?.address }</span>
              </li>

            </ul>
          </div>
        ))
            }
        </div>
    )
}
