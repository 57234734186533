import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useAlert } from 'react-alert';
// import moment from 'moment-timezone';
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import CustomInput from '../../../components/material-dashboard-pro-react/CustomInput/CustomInput';
import SelectBoxComponent from '../../../components/SelectBox.component';
import { createDispatchingMode } from '../services/serverApi.service';
import ImageUpload from '../../../components/material-dashboard-pro-react/CustomUpload/ImageUpload';
import { useDispatch, useSelector } from 'react-redux';

const validationSchema = yup.object({
  name: yup.string().required(),
  message: yup.string().required(),
  imageUrl: yup.string(),
  showImage: yup.string().required(),
  showMessage: yup.string().required(),
});
export default function CreateDispatchingModes({ itemCreated, close }) {
  const alert = useAlert();
  const [isLoading, setIsLoading] = useState(false);
  // const [countries, setCountries] = useState([]);
  // console.log(onItemCreated);
  const dispatch = useDispatch();
  const [displayImage, setDisplayImage] = useState(false);
  const upload = useSelector((state) => state.uploadReducer);
  const { iconUrl, error, loading } = upload;

  const formik = useFormik({
    initialValues: {
      name: '',
      message: '',
      imageUrl: '',
      showImage: false,
      showMessage: false,
    },
    validationSchema,
  });

  const onCreate = () => {
    console.log(formik.values);
    if (formik.isValid) {
      setIsLoading(true);
      createDispatchingMode(formik.values)
        .then(({ data }) => {
          alert.show('City has been created', {
            type: 'success',
          });
          formik.resetForm();
          //onItemCreated();
          itemCreated(data);
        })
        .catch((error) => {
          alert.show(error.message, {
            type: 'error',
          });
        })
        .finally(() => {
          setIsLoading(false);
          close();
        });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    formik.setValues({
      ...formik.values,
      imageUrl: iconUrl,
    });
  }, [iconUrl]);
  return (
    <div>
      <Box display="flex" justifyContent="center">
        <ImageUpload label="Select icon" avatar folder="service" />
      </Box>
      <CustomInput
        labelText="Name"
        formControlProps={{
          fullWidth: true,
        }}
        type="text"
        error={formik.touched.name && Boolean(formik.errors.name)}
        inputProps={{
          value: formik.values.name,
          onBlur: formik.handleBlur('name'),
          onChange: formik.handleChange('name'),
        }}
      />
      <CustomInput
        labelText="message"
        formControlProps={{
          fullWidth: true,
        }}
        type="text"
        error={formik.touched.message && Boolean(formik.errors.message)}
        inputProps={{
          value: formik.values.message,
          onBlur: formik.handleBlur('message'),
          onChange: formik.handleChange('message'),
        }}
      />
      {/*<CustomInput*/}
      {/*  labelText="image Url"*/}
      {/*  formControlProps={{*/}
      {/*    fullWidth: true,*/}
      {/*  }}*/}
      {/*  type="text"*/}
      {/*  error={formik.touched.imageUrl && Boolean(formik.errors.imageUrl)}*/}
      {/*  inputProps={{*/}
      {/*    value: formik.values.imageUrl,*/}
      {/*    onBlur: formik.handleBlur('imageUrl'),*/}
      {/*    onChange: formik.handleChange('imageUrl'),*/}
      {/*  }}*/}
      {/*/>*/}
      <SelectBoxComponent
        items={['true', 'false'].map((item) => ({
          value: item,
          label: item == 'true' ? 'Yes' : 'No',
        }))}
        inputLabel="show Image"
        onValueChange={formik.handleChange('showImage')}
        value={formik.values.showImage}
      />
      <SelectBoxComponent
        items={['true', 'false'].map((item) => ({
          value: item,
          label: item == 'true' ? 'Yes' : 'No',
        }))}
        inputLabel="show Message"
        onValueChange={formik.handleChange('showMessage')}
        value={formik.values.showMessage}
      />
      <Box textAlign="center">
        <Button
          round
          type="submit"
          color="success"
          // disabled={!formik.dirty || !formik.isValid || isLoading}
          loading={isLoading}
          loaderColor="white"
          onClick={onCreate}
        >
          Create
        </Button>
      </Box>
    </div>
  );
}

CreateDispatchingModes.propTypes = {
  // onItemCreated: PropTypes.func.isRequired,
  itemCreated: PropTypes.func,
  close: PropTypes.func,
};
