import { MULTI_UPLOAD_IMAGE_REQUEST,MULTI_UPLOAD_IMAGE_SUCCESS,MULTI_UPLOAD_IMAGE_FAIL,MULTI_UPLOAD_IMAGE_RESET,MULTI_DEFAULT_IMAGE } from './upload.constant';

export const multiUploadImageRequestAction = (payload) => ({
    type: MULTI_UPLOAD_IMAGE_REQUEST,
    payload
})
export const multiUploadImageSuccessAction = (multiIconUrl,oldObject,index) => ({
    type: MULTI_UPLOAD_IMAGE_SUCCESS,
    multiIconUrl,
    oldObject,
    index
})
export const multiDefaultImagesAction = (url) => ({
    type: MULTI_DEFAULT_IMAGE,
    url
})
export const multiUploadImageFailtAction = (error) => ({
    type: MULTI_UPLOAD_IMAGE_FAIL,
    error
})
export const multiUploadImageResetAction = () => ({
    type: MULTI_UPLOAD_IMAGE_RESET,
})