import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// mat api
import { Grid, Typography, Box, Slider, Switch } from '@mui/material';

// Map
import CustomMarker from '../../../components/Markers.component';
import { iconsList } from '../../../config/status.config';
import Map from '../../../components/Map.component';
import { Polyline, InfoWindow } from '@react-google-maps/api';
import ButtonComponent from '../../../components/material-dashboard-pro-react/CustomButtons/Button';

// api
import { getPartnerDeliveryManInCities } from '../../AllPatners/services/server.api.service';
import {
  socketConnectPertner,
  AssignDeliverie,
} from '../services/server.api.service';
import { socketConnect } from '../../orders/services/serverApi.service';

// helpers
import {
  getDistanceFromLatLonInKm,
  calcDuration,
} from '../../../helpers/index';
// selectors
import { getUserDataState } from '../../auth/redux/selectors';

export default function MapDeliveryComponent({
  deliverieId,
  deliveries,
  admin,
  close,
}) {
  const userData = useSelector(getUserDataState);
  const [socket, setSocket] = useState(null);
  const [showInfo, setShowInfo] = useState(true);
  const [filterByWarehouse, setFilterByWarehouse] = useState(false);
  const [changes, setChanges] = useState(false);
  const [warehouse, setWarehouse] = useState('');
  const [delivery, setDeliverie] = useState({});
  const [path, setPath] = useState({});
  const [clientPath, setClientPath] = useState({});
  const [deliveryManPath, setDeliveryManPath] = useState({});
  const [infoBoxData, setInfoBoxData] = useState({});
  const [zoom, setZoom] = useState(13);
  const [deliveryManAssigned, setDeliveryManAssigned] = useState({});
  const [deliveryInCity, setDeliverInCity] = useState([]);
  const [value, setValue] = useState(1);
  const [polylineForThisDelivery, setPolylineForThisDelivery] = useState([]);
  const [clientsLocationWhenHover, setClientsLocationWhenHover] = useState([]);
  const [wareHousesLocationWhenHover, setWareHousesLocationWhenHover] =
    useState([]);
  const [polyLineForOneDelivery, setPolyLineForOneDelivery] = useState([]);
  const handleChange = (e, newValue) => {
    setValue(newValue);
  };
  const drawLine = (deliveryMan, initPath) => {
    initPath &&
      setPath({
        lat: parseFloat(delivery.pickupAddress?.latitude),
        lng: parseFloat(delivery.pickupAddress?.longitude),
      });
    setDeliveryManPath({
      lat: deliveryMan?.location[0],
      lng: deliveryMan?.location[1],
    });
    // setZoom(13);
    const polyLineArray = [];
    const pathCoordinates = [];
    const polyLineObj = {};
    polyLineObj.color = 'black';
    deliveryMan &&
      Object.keys(deliveryMan).length &&
      pathCoordinates.push({
        lat: parseFloat(deliveryMan.location[0]),
        lng: parseFloat(deliveryMan.location[1]),
      });
    pathCoordinates.push({
      lat: parseFloat(delivery.pickupAddress.latitude),
      lng: parseFloat(delivery.pickupAddress.longitude),
    });

    pathCoordinates.push({
      lat: parseFloat(delivery.destinationAddress.latitude),
      lng: parseFloat(delivery.destinationAddress.longitude),
    });

    polyLineObj.pathCoordinates = pathCoordinates;
    polyLineArray.push(polyLineObj);

    setPolylineForThisDelivery(polyLineArray);
  };
  const getInfoAboutDelivery = (deliveryInCity) => {
    const counts = {};
    deliveries.forEach(function (x) {
      counts[x?.deliveryMan?._id] = (counts[x?.deliveryMan?._id] || 0) + 1;
    });

    (deliveryInCity || []).map((deliveryMan) => {
      deliveryMan['deliveries'] = counts[deliveryMan._id] || 0;
      if (deliveryMan.location && deliveryMan.location.length > 0) {
        deliveryMan['distanceToWarehouse'] = getDistanceFromLatLonInKm(
          delivery.pickupAddress.latitude,
          delivery.pickupAddress.longitude,
          deliveryMan.location[0],
          deliveryMan.location[1],
        );
        deliveryMan['distanceToClient'] = getDistanceFromLatLonInKm(
          delivery?.destinationAddress?.latitude,
          delivery?.destinationAddress?.longitude,
          deliveryMan.location[0],
          deliveryMan.location[1],
        );
      }
    });
    setClientPath({
      lat: delivery?.destinationAddress?.latitude,
      lng: delivery?.destinationAddress.longitude,
    });

    const findDeliveryManInThisDelivery = deliveryInCity.find(
      (w) => w._id == delivery.deliveryMan?._id,
    );
    setDeliveryManAssigned(
      findDeliveryManInThisDelivery || delivery?.deliveryMan,
    );
    setDeliverInCity(deliveryInCity);
    drawLine(findDeliveryManInThisDelivery || delivery?.deliveryMan, true);
  };
  const getDeliveryManList = (cityId) => {
    getPartnerDeliveryManInCities([cityId])
      .then(({ data }) => {
        data.deliveryMen &&
          data.deliveryMen.length > 0 &&
          getInfoAboutDelivery(data.deliveryMen);
      })
      .catch((e) => {
        console.log(e);
        getInfoAboutDelivery([]);
      });
  };
  const displayDetails = async (id) => {
    !showInfo && setShowInfo(true);
    const infoObj = {};
    const storesArray = [];
    const deliveryManStatusArray = [];
    const cratedAtArray = [];
    var deliveryIndex = deliveryInCity.findIndex((x) => x._id == id);
    if (deliveryIndex >= 0) {
      infoObj._id = deliveryInCity[deliveryIndex]?._id;
      infoObj.name = deliveryInCity[deliveryIndex]?.name;
      infoObj.location = deliveryInCity[deliveryIndex]?.location;
      infoObj.phone = deliveryInCity[deliveryIndex]?.phone;
      infoObj.deliveries = deliveryInCity[deliveryIndex]?.deliveries;
      infoObj.distanceToWarehouse =
        deliveryInCity[deliveryIndex]?.distanceToWarehouse;
      deliveries &&
        deliveries
          .filter((item) => item.deliveryMan && item.deliveryMan._id == id)
          .map((x) => {
            storesArray.push(x.pickupAddress?.libeleMagasin);
            deliveryManStatusArray.push(x.deliveryManStatus.substring(0, 7));
            cratedAtArray.push(calcDuration(x.createdAt));
          });

      infoObj.stores = storesArray;
      infoObj.status = deliveryManStatusArray;
      infoObj.createdAt = cratedAtArray;
      setInfoBoxData({ ...infoObj });
    }
  };
  const assign = (deliveryManId) => {
    if (!!deliveryManId) {
      AssignDeliverie(delivery._id, {
        deliveryManId: deliveryManId,
        admin,
      })
        .then(({ data }) => {
          close();
        })
        .catch((error) => console.log(error));
    }
  };
  const drawLineForSpecificeDelivery = (id) => {
    const deliveryManGoToStores = [];
    const deliveryManGoToClient = [];
    const deliveryManRoutes = [];
    const findDelivery = (deliveryInCity || []).find(
      (deliveryMan) => deliveryMan?._id == id,
    );
    findDelivery &&
      deliveries &&
      deliveries.length &&
      deliveries
        .filter((item) => item.deliveryMan?._id == id)
        .map((order) => {
          var randomColor =
            '#' + Math.floor(Math.random() * 16777215).toString(16);
          const locationClient = {
            lat: JSON.parse(order?.destinationLocation.coordinates[0]),
            lng: JSON.parse(order?.destinationLocation.coordinates[1]),
          };
          const locationWarehouse = {
            lat: JSON.parse(order?.pickupAddress.latitude),
            lng: JSON.parse(order?.pickupAddress.longitude),
          };

          deliveryManRoutes.push({
            color: randomColor,
            pathCoordinates: [
              {
                lat: JSON.parse(findDelivery?.location[0]),
                lng: JSON.parse(findDelivery?.location[1]),
              },
              { ...locationWarehouse },
              { ...locationClient },
            ],
          });
          deliveryManGoToStores.push({ ...locationWarehouse });
          deliveryManGoToClient.push({ ...locationClient });
        });
    setPolyLineForOneDelivery(deliveryManRoutes);
    setWareHousesLocationWhenHover(deliveryManGoToStores);
    setClientsLocationWhenHover(deliveryManGoToClient);
  };
  const initialState = () => {
    setPolyLineForOneDelivery([]);
    setClientsLocationWhenHover([]);
    setWareHousesLocationWhenHover([]);
  };

  const changeLocation = (data) => {
    const findDeliveryManIndex = deliveryInCity.findIndex(
      (x) => x._id === data.deliveryManId,
    );
    if (findDeliveryManIndex != -1) {
      deliveryInCity[findDeliveryManIndex].location = data.location;
      setDeliverInCity(deliveryInCity);
      setChanges(!changes);
    }
    if (delivery?.deliveryMan?._id == data.deliveryManId) {
      drawLine(deliveryInCity[findDeliveryManIndex], false);
    }
  };
  useEffect(() => {
    if (!socket) {
      setSocket(socketConnect(userData.accessToken));
    }
    if (socket) {
      socket.emit('JOIN_ROOM', 'ADMIN_DISPATCHING');
    }
    return () => {
      socket && socket.disconnect();
    };
  }, [socket]);
  useEffect(() => {
    socket &&
      deliveryInCity &&
      socket.removeAllListeners() &&
      socket.on('DELIVERYMAN_LOCATION_CHANGED', function (data) {
        changeLocation(data);
      });
  }, [socket, deliveryInCity?.length]);
  useEffect(() => {
    setDeliverie((deliveries || []).find((x) => x._id == deliverieId));
  }, []);
  useEffect(() => {
    if (delivery && Object.keys(delivery).length > 0) {
      getDeliveryManList(delivery.cityId);
      setWarehouse(delivery.pickupAddress?.libeleMagasin);
    }
  }, [delivery]);
  return (
    <div>
      <Grid container className="mb-2">
        <Grid item xs={6} style={{ display: 'flex' }}>
          deliveryId : <Typography>{delivery?.shortId}</Typography>
        </Grid>
        <Grid item xs={6} style={{ display: 'flex' }}>
          warehouse :
          <div style={{ display: 'flex' }}>
            <Typography>{delivery?.pickupAddress?.libeleMagasin}</Typography>
            <Switch
              checked={filterByWarehouse}
              onChange={(e) => setFilterByWarehouse(e.target.checked)}
            />
          </div>
        </Grid>
        <Grid item xs={6} style={{ display: 'flex' }}>
          customer :{' '}
          <Typography>{delivery?.destinationAddress?.contactPerson}</Typography>
        </Grid>
        <Grid item xs={6} style={{ display: 'flex' }}>
          deliveryMan : <Typography>{delivery?.deliveryMan?.name}</Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography gutterBottom>
            Max distance to store : {value} Km
          </Typography>
          <Slider
            value={value}
            min={0}
            step={0.5}
            max={5}
            onChange={handleChange}
            valueLabelDisplay="auto"
            aria-labelledby="non-linear-slider"
          />
        </Grid>
      </Grid>
      <Map path={path} zoom={zoom}>
        <CustomMarker position={path} icon={iconsList.store} size={35} />
        <CustomMarker
          position={deliveryManPath}
          icon={iconsList.deliveryManWithOrder}
          size={40}
        />
        <CustomMarker position={clientPath} icon={iconsList.client} size={40} />
        {filterByWarehouse
          ? deliveryInCity.filter(
              (x) => x.warehouses.libeleMagasin == warehouse,
            )
          : deliveryInCity.map(
              (deliveryMan) =>
                deliveryMan.distanceToStore <= value && (
                  <CustomMarker
                    icon={
                      deliveryMan.deliveries
                        ? iconsList.deliveryManWithOrder
                        : iconsList.deliverManWithoutOrde
                    }
                    size={40}
                    key={deliveryMan._id}
                    zIndex={100}
                    onClick={() => displayDetails(deliveryMan?._id)}
                    position={{
                      lat: parseFloat(deliveryMan?.location[0]),
                      lng: parseFloat(deliveryMan?.location[1]),
                    }}
                    onMouseOver={() =>
                      deliveryMan.deliveries > 0 &&
                      drawLineForSpecificeDelivery(deliveryMan?._id)
                    }
                    onMouseOut={() => initialState()}
                  />
                ),
              // ),
            )}
        {polylineForThisDelivery.length &&
          polylineForThisDelivery.map((line) => (
            <Polyline
              path={line.pathCoordinates}
              geodesic={true}
              options={{
                strokeColor: line.color,
                strokeOpacity: 1,
                strokeWeight: 2,
              }}
            />
          ))}
        {showInfo && infoBoxData && Object.keys(infoBoxData).length !== 0 && (
          <InfoWindow
            onCloseClick={() => {
              setShowInfo(false);
            }}
            position={{
              lat: parseFloat(infoBoxData.location[0]),
              lng: parseFloat(infoBoxData.location[1]),
            }}
          >
            <div>
              <div style={{ fontSize: 12, fontColor: `#08233B` }}>
                name: {infoBoxData?.name}
                <br />
                phone : {infoBoxData.phone}
                <br />
                deliveries : {infoBoxData.deliveries}{' '}
                {infoBoxData.stores && infoBoxData.stores.length
                  ? infoBoxData.stores.map((store) => `/ ${store}`)
                  : ''}
                <br />
                status :{' '}
                {infoBoxData.status && infoBoxData.status.length
                  ? infoBoxData.status.map((status) => `${status} /`)
                  : ''}
                <br />
                created :{' '}
                {infoBoxData.createdAt && infoBoxData.createdAt.length
                  ? infoBoxData.createdAt.map(
                      (createdAt) => `${createdAt} min/`,
                    )
                  : ''}
                <br />
                distance : {infoBoxData.distanceToWarehouse} km
                <br />
                <Box justifyContent="center" display="flex">
                  <ButtonComponent
                    size="sm"
                    color="primary"
                    onClick={() => {
                      assign(infoBoxData?._id);
                    }}
                  >
                    assigned
                  </ButtonComponent>
                </Box>
              </div>
            </div>
          </InfoWindow>
        )}
        {clientsLocationWhenHover &&
          clientsLocationWhenHover.length &&
          clientsLocationWhenHover.map((clientLocation, index) => (
            // store?.location &&
            <CustomMarker
              icon={iconsList.client}
              size={35}
              key={`${JSON.stringify(clientLocation) + index}`}
              position={clientLocation}
            />
          ))}
        {wareHousesLocationWhenHover &&
          wareHousesLocationWhenHover.length &&
          wareHousesLocationWhenHover.map((storeLocation, index) => (
            <CustomMarker
              icon={iconsList.store}
              size={35}
              key={`${JSON.stringify(storeLocation) + index}`}
              position={storeLocation}
            />
          ))}
        {polyLineForOneDelivery.length &&
          polyLineForOneDelivery.map((line) => (
            <Polyline
              path={line.pathCoordinates}
              geodesic={true}
              options={{
                strokeColor: line.color,
                strokeOpacity: 1,
                strokeWeight: 2,
              }}
            />
          ))}
      </Map>
    </div>
  );
}
