import {
  GET_ALLPARTNER_ORDER_REQUEST,
  GET_ALLPARTNER_ORDER_SUCCESS,
  GET_ALLPARTNER_ORDER_FAIL,
  ALLPARTNER_INITIAL_STATE,
  ALLPARTNER_CHANGE_DELIVERIE_STATUS,
  ALLPARTNER_NEW_DELIVERIE,
  ALLPARTNER_COMPLETED_DELIVERIE,
  ALLPARTNER_ASSIGN_DELIVERIE,
  ALLPARTNER_UPDATED_DELIVERIE,
  ALLPARTNER_CANCEL_DELIVERIE,
  ALLPARTNER_CHANGE_DELIVERY_ALARMS
} from '../constants/order.constants';
import { store } from '../../../../redux/store'
// import { filterSelector } from '../selectors'
// import { useDispatch, useSelector } from 'react-redux';


const initialState = {
  deliveries: [],
  deliveriesChangeCounter: 1,
  error: '',
  loading: false,
};
const filterDeliveries = (deliveries, cities, warehouses) => {
  if (cities.includes('all')) {
    return (deliveries || []).filter(delivery => (warehouses.length > 0 ? warehouses.includes(delivery.pickupAddress.libeleMagasin) : true)).sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
    )
  }
  return (deliveries || []).filter(delivery => cities.includes(delivery.cityId) && (warehouses.length > 0 ? warehouses.includes(delivery.pickupAddress.libeleMagasin) : true)).sort(
    (a, b) =>
      new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
  )
}
export default function deliveriesReducer(state = initialState, action) {
  let deliveries = state.deliveries || []

  switch (action.type) {
    case ALLPARTNER_CHANGE_DELIVERIE_STATUS:
    case ALLPARTNER_ASSIGN_DELIVERIE:
    case ALLPARTNER_UPDATED_DELIVERIE:
      const findIndex = deliveries.findIndex(
        (x) => x._id == action.deliverie._id,
      );
      findIndex != -1 ? deliveries[findIndex] = action.deliverie : null
      break;

    case ALLPARTNER_NEW_DELIVERIE:
      const findIndexForNewDelivery = deliveries.findIndex(x => x._id == action.deliverie?._id)
      findIndexForNewDelivery == -1 && deliveries.push(action.deliverie);
      break;
    case ALLPARTNER_COMPLETED_DELIVERIE:
    case ALLPARTNER_CANCEL_DELIVERIE:
      const findCompletedDeliverie = deliveries.findIndex(
        (x) => x._id == action.deliverieId,
      );
      findCompletedDeliverie != -1 && deliveries.splice(findCompletedDeliverie, 1);
      break;
    case ALLPARTNER_CHANGE_DELIVERY_ALARMS:
      // orders = state.deliveries
      const findIndexForAlarms = deliveries.findIndex(
        (x) => x._id == action.id,
      );
      if (findIndexForAlarms != -1) {
        deliveries[findIndexForAlarms].alarms = action.alarms;
      }
    default:
      break;
  }
  switch (action.type) {
    case GET_ALLPARTNER_ORDER_REQUEST:
      return {
        loading: true,
        deliveriesChangeCounter: (state.deliveriesChangeCounter + 1) % 100,
      };

    case GET_ALLPARTNER_ORDER_SUCCESS:
      // if (action.deliveries && action.deliveries.length > 1) action.deliveries[1].cityId = '615df194d1908f002d1da083'
      return {
        ...state,
        loading: false,
        deliveriesChangeCounter: (state.deliveriesChangeCounter + 1) % 100,
        deliveries: filterDeliveries(action.deliveries, action.cities, action.warehouses)
      };
    case GET_ALLPARTNER_ORDER_FAIL:
      return {
        ...state,
        loading: false,
        error:
          action.error?.message ||
          (action?.error?.errors || [{ message: '' }])[0].message,
      };
    case ALLPARTNER_CHANGE_DELIVERIE_STATUS:
    case ALLPARTNER_NEW_DELIVERIE:
    case ALLPARTNER_COMPLETED_DELIVERIE:
    case ALLPARTNER_CANCEL_DELIVERIE:
    case ALLPARTNER_ASSIGN_DELIVERIE:
    case ALLPARTNER_UPDATED_DELIVERIE:
    case ALLPARTNER_CHANGE_DELIVERY_ALARMS:
      return {
        ...state,
        loading: false,
        deliveries: deliveries.sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
        ),
        deliveriesChangeCounter: (state.deliveriesChangeCounter + 1) % 100,
      };
    case ALLPARTNER_INITIAL_STATE:
      return {
        ...state,
        loading: false,
        deliveries: [],
      };

    default:
      return state;
  }
}
