import React from 'react';
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import { googleMapsApiKey } from '../config/backendServer.config';

export default function MapComponent(props) {
  return (
    <LoadScript
      id="script-loader"
      googleMapsApiKey={googleMapsApiKey}
      language="en"
      region="ma"
    >
      <GoogleMap
        mapContainerClassName="map"
        center={props.path && props.path}
        zoom={props.zoom}
        version="weekly"
        on
        options={{
          styles: [
            {
              featureType: 'icon',
              elementType: 'labels',
              stylers: [{ visibility: 'off' }],
            },
          ],
        }}
        mapContainerStyle={{ ...props.mapContainerStyle }}
      >
        {props.children}
      </GoogleMap>
    </LoadScript>
  );
}
