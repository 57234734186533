/* global google */
import React, { useEffect, useState } from 'react';
import { GoogleMap, LoadScript, InfoWindow } from '@react-google-maps/api';
import { googleMapsApiKey } from '../../../config/backendServer.config';
import CustomMarker from '../../../components/Markers.component';
import { iconsList } from '../../../config/status.config';
import { orderServiceType } from '../mock';
import {
  socketConnect,
  socketGpsUpdateConnect,
  getDeliveryManDispatchedById,
} from '../services/serverApi.service';
import {
  getlocationsHistoryForDeliverManApi,
} from '../../Provider/service/serverAPI.service';
import {formatTime} from '../../../helpers/index';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
const columns = [
  { id: 'deliveryManId', label: 'deliveryManId', minWidth: 70 },
  { id: 'name', label: 'name', minWidth: 70 },
  { id: 'location', label: 'location', minWidth: 70 },
  { id: 'timestamp', label: 'timestamp', minWidth: 100 },
  { id: 'seconds', label: 'seconds', minWidth: 70 },
  { id: 'batteryLevel', label: 'batteryLevel', minWidth: 70 },
  { id: 'gpsStatus', label: 'gpsStatus', minWidth: 70 },
  { id: 'appState', label: 'appState', minWidth: 70 },
  { id: 'appVersion', label: 'appVersion', minWidth: 70 },
  { id: 'isMocked', label: 'isMocked', minWidth: 70 },
  { id: 'fromNewApp', label: 'fromNewApp', minWidth: 70 },
  { id: 'phone', label: 'phone', minWidth: 70 },
];

const rows = [];
const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  filter: {
    padding: 10,
  },
  text: {
    textAlign: 'center',
  },
});

export default function DisplayOrderInMap({
  order,
  orders,
  close,
  admin,
  token,
  //   socket,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [path, setPath] = useState({});
  const [customerPath, setCustomerPath] = useState({});
  const [deliveryManPathFromDB, setDeliveryManPathFromDB] = useState({});
  const [zoom, setZoom] = useState(13);
  const [infoStore, setInfoStore] = useState({});
  const [showInfoStore, setShowInfoStore] = useState(true);
  const [infoCustomer, setInfoCustomer] = useState({});
  const [showInfoCustomer, setShowInfoCustomer] = useState(true);
  const [deliverManInfo, setDeliverManInfo] = useState({});
  const [showInfoDelivery, setShowInfoDelivery] = useState(true);
  const [socketDeliveryMan, setSocketDeliveryMan] = useState([]);
  const [socket, setSocket] = useState(null);
  const [data, setData] = useState([]);
  const [newData, setNewData] = useState([]);
  const [lastnewData, setLastNewData] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const classes = useStyles();

  const getDeliveryManInThisOrder = () => {
    if (order.deliveryManId) {
      getDeliveryManDispatchedById(order.deliveryManId)
        .then(({ data }) => {
          data.deliveryMan &&
            data.deliveryMan.location &&
            data.deliveryMan.location.length > 0 &&
            setDeliveryManPathFromDB({
              lat: parseFloat(data.deliveryMan.location[0]),
              lng: parseFloat(data.deliveryMan.location[1]),
            });
        })
        .catch((err) => console.log(err));
    }
  };
  const getCostomerLocation = () => {
    switch (order.payment.serviceType) {
      case orderServiceType.Stores:
        setCustomerPath({
          lat: parseFloat(order.payment?.destinationAddress?.location[0]),
          lng: parseFloat(order.payment?.destinationAddress?.location[1]),
        });
        break;
      case orderServiceType.Choubik:
      case orderServiceType.Drive:
      case orderServiceType.Express:
        setCustomerPath({
          lat: parseFloat(order?.payment?.pickupAddress?.location[0]),
          lng: parseFloat(order?.payment?.pickupAddress?.location[1]),
        });
      default:
        break;
    }
  };


  const _getlocationsHistoryForDeliverManApi = (deliveryManId) => {

    getlocationsHistoryForDeliverManApi(
      page,
      rowsPerPage,
      true,
      deliveryManId,
    ).then(({ data }) => {
      setData(data.deliveryMan);
    });
  };
  useEffect(() => {
    if (order?.deliveryManId) {
      //   console.log(provider);
      //   setData([]);
      _getlocationsHistoryForDeliverManApi(order?.deliveryManId);
      // socket && socket.disconnect();
      //   socket && socket.disconnect();
      //   setSocket(null);
      //   socket.emit('JOIN_ROOM', provider._id);
    }
  }, []);
  const displayDetailstore = (id) => {
    !showInfoStore && setShowInfoStore(true);
    const infoObj = {};
    if (order?.store?._id == id) {
      infoObj._id = order?.store?._id;
      infoObj.name = order?.store?.name;
      infoObj.phone = order?.store?.phone;
      infoObj.location = order?.store?.location;
      setInfoStore({ ...infoObj });
    }
  };

  const displayDetailCustomer = (id) => {
    !showInfoCustomer && setShowInfoCustomer(true);
    const infoObj = {};
    if (order?.customer?._id == id) {
      infoObj._id = order?.customer?._id;
      infoObj.name = order?.customer?.name;
      infoObj.phone = order?.customer?.phone;
      infoObj.email = order?.customer?.email;
      infoObj.city = order?.city?.name;
      infoObj.adress = order?.payment?.destinationAddress?.address.substring();
      if (order.payment.serviceType === orderServiceType.Stores) {
        infoObj.location = order?.payment?.destinationAddress?.location;
      } else {
        infoObj.location = order?.payment?.pickupAddress?.location;
      }
      setInfoCustomer({ ...infoObj });
    }
  };
  const displayDetailDelevary = () => {
    !showInfoDelivery && setShowInfoDelivery(true);
    const infoObj = {};
    infoObj.name = order?.deliveryMan?.name;
    infoObj.phone = order?.deliveryMan?.phone;
    infoObj.email = order?.deliveryMan?.email;
    infoObj.location = order?.deliveryMan?.location;

    setDeliverManInfo({ ...infoObj });
  };

  useEffect(() => {
    if (order) {
      getDeliveryManInThisOrder();
      if (order.payment.serviceType == orderServiceType.Stores) {
        setPath({
          lat: parseFloat(order.store.location[0]),
          lng: parseFloat(order.store.location[1]),
        });
      } else {
        setPath({
          lat: parseFloat(order?.payment?.pickupAddress?.location[0]),
          lng: parseFloat(order?.payment?.pickupAddress?.location[1]),
        });
      }
      getCostomerLocation();
      // displayDeliveryGoToSameStore()
    }
  }, [order]);


  useEffect(() => {
    console.log('runing ...');
    if (!socket) {
      // setSocket(socketConnect(token)); //old socket connect
      setSocket(socketGpsUpdateConnect(token)); //new socket connection only for GPS
    }
    function subscribeToAdminRoom() {
      if (socket) {
        socket.emit('JOIN_ROOM', order.deliveryManId);
      }
    }
    if (socket) {
      subscribeToAdminRoom();
    }
    socket &&
      socket.on('DELIVERYMAN_LOCATION_CHANGED', function (data) {
        if (data.deliveryManId == order.deliveryManId) {
          setSocketDeliveryMan(data.location);

        }
        if (data.deliveryManId + '' == order.deliveryManId + '') {

          const {
            deliveryManId,
            name,
            location,
            timestamp,
            batteryLevel,
            gpsStatus,
            appState,
            appVersion,
            socketName,
            isMocked,
            fromNewApp,
            phone,
          } = data;
          let dataToUpdate = {
            deliveryManId: deliveryManId,
            name: name,
            location: location,
            timestamp: timestamp,
            batteryLevel: parseInt((batteryLevel * 100).toString()),
            gpsStatus: gpsStatus + '',
            appVersion: appVersion + '',
            appState: appState + '',
            isMocked: isMocked + '',
            fromNewApp: fromNewApp + '',
            phone: phone,
            dataNew: true,
          };

          setNewData(dataToUpdate);
        }
      });
    return () => {
      socket && socket.disconnect();
    };
  }, [socket]);

  useEffect(() => {
    if (newData) {
      const d = data;
      const exist = d.find((e) => {
        // console.log('asa**********', new Date(e.timestamp).getTime() ,
        //     new Date(newData.timestamp).getTime())
        return (
          new Date(e.timestamp).getTime() ===
          new Date(newData.timestamp).getTime()
        );
      });
      if (!exist) {
        let dd = lastnewData;
        let i = data.length;
        var startDate = dd ? new Date(dd['timestamp']) : new Date();
        var endDate = new Date(newData.timestamp);
        // console.log('startDate', new Date(startDate), new Date(endDate));
        newData['seconds'] = (endDate.getTime() - startDate.getTime()) / 1000;
        d.unshift(newData);
        setData(d);
        setLastNewData({ ...newData });
      } else {
        // console.log('duplicate socket');
      }
    }
  }, [newData]);
  return (
    <div>
    {data && data.length > 0 && (
     <Paper >
     <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.slice(0, 1).map((row) => {
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.code}
                  style={{
                    backgroundColor: row.newData ? '#c1ffbe' : '#ffffff',
                  }}
                >
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align} className={classes.text}  >
                        {value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      </Paper>
 )}






      <LoadScript
        id="script-loader"
        googleMapsApiKey={googleMapsApiKey}
        language="en"
        region="ma"
      >
        <GoogleMap
          mapContainerClassName="map"
          center={path && path}
          zoom={zoom}
          version="weekly"
          on
          options={{
            styles: [
              {
                featureType: 'icon',
                elementType: 'labels',
                stylers: [{ visibility: 'off' }],
              },
            ],
          }}
        >
          {order && order?.payment.serviceType == orderServiceType.Stores && (
            <CustomMarker
              icon={iconsList.store}
              onClick={() => displayDetailstore(order.store._id)}
              size={40}
              key={order.store._id}
              position={{
                lat: parseFloat(
                  order.store.location ? order.store.location[0] : '',
                ),
                lng: parseFloat(
                  order.store.location ? order.store.location[1] : '',
                ),
              }}
            />
          )}

          {order && order?.deliveryMan && (
            <CustomMarker
              onClick={() => displayDetailDelevary()}
              icon={iconsList.deliveryManWithOrder}
              size={40}
              key={order.deliveryMan._id}
              position={deliveryManPathFromDB}
            />
          )}

          {order && order?.deliveryMan && (
            <CustomMarker
              icon={iconsList.deliverManWithoutOrde}
              size={40}
              key={order.deliveryMan._id}
              position={{
                lat: parseFloat(socketDeliveryMan ? socketDeliveryMan[0] : ''),
                lng: parseFloat(socketDeliveryMan ? socketDeliveryMan[1] : ''),
              }}
            />
          )}

          {customerPath && order?.customer && (
            <CustomMarker
              onClick={() => displayDetailCustomer(order.customer._id)}
              icon={iconsList.client}
              size={50}
              key={order.customer._id}
              position={customerPath && customerPath}
            />
          )}

          {showInfoStore && infoStore && Object.keys(infoStore).length !== 0 && (
            <InfoWindow
              onCloseClick={() => {
                setShowInfoStore(false);
              }}
              position={{
                lat: parseFloat(infoStore.location[0]),
                lng: parseFloat(infoStore.location[1]),
              }}
            >
              <div>
                <div style={{ fontSize: 12, fontColor: `#08233B` }}>
                  name: {infoStore?.name}
                  <br />
                  phone : {infoStore.phone}
                  <br />
                </div>
              </div>
            </InfoWindow>
          )}

          {showInfoCustomer &&
            infoCustomer &&
            Object.keys(infoCustomer).length !== 0 && (
              <InfoWindow
                onCloseClick={() => {
                  setShowInfoCustomer(false);
                }}
                position={{
                  lat: parseFloat(infoCustomer.location[0]),
                  lng: parseFloat(infoCustomer.location[1]),
                }}
              >
                <div>
                  <div style={{ fontSize: 12, fontColor: `#08233B` }}>
                    name: {infoCustomer?.name}
                    <br />
                    phone : {infoCustomer.phone}
                    <br />
                    email: {infoCustomer?.email}
                    <br />
                    adress : {infoCustomer.adress}
                    <br />
                    city : {infoCustomer.city}
                    <br />
                  </div>
                </div>
              </InfoWindow>
            )}

          {showInfoDelivery &&
            deliverManInfo &&
            Object.keys(deliverManInfo).length !== 0 && (
              <InfoWindow
                onCloseClick={() => {
                  setShowInfoDelivery(false);
                }}
                position={{
                  lat: parseFloat(deliverManInfo.location[0]),
                  lng: parseFloat(deliverManInfo.location[1]),
                }}
              >
                <div>
                  <div style={{ fontSize: 12, fontColor: `#08233B` }}>
                    name: {deliverManInfo?.name}
                    <br />
                    phone : {deliverManInfo.phone}
                    <br />
                    email: {deliverManInfo?.email}
                    <br />
                  </div>
                </div>
              </InfoWindow>
            )}
        </GoogleMap>
      </LoadScript>
    </div>
  );
}
