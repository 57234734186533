import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Typography,
  Paper,
  Button,
  Grid,
} from '@mui/material';

// selectors
import { getAllPartnerDeliveriesDataState } from '../redux/selectors';

// mock
import { dispatchingInfoTableHeaders } from '../mock';

export default function DispatchingInfoComponenet({
  setSelectedDispatchingStatus,
  selectedDispatchingStatus,
  filterBy,
  setFilterBy,
}) {
  const { deliveries, loading, error, deliveriesChangeCounter } = useSelector(
    getAllPartnerDeliveriesDataState,
  );
  const [totalOrders, setTotalOrders] = useState({});
  const [selectedWarehousseOrders, setSelectedWarehousseOrders] = useState([]);

  const selectedCell = {
    backgroundColor: '#3496ff',
    borderRadius: '50%/50%',
    padding: '5px',
    color: '#FFFFFF',
    cursor: 'pointer',
    textAlign: 'center',
  };
  const checkIfWarehouseSelected = (warehouse) => {
    if ((selectedWarehousseOrders || []).find((x) => x == warehouse))
      return { ...selectedCell };
    return { cursor: 'pointer' };
  };
  const checkIfSelected = (warehouse, status) => {
    if ((selectedDispatchingStatus[warehouse] || []).find((x) => x == status))
      return { ...selectedCell };
    return { cursor: 'pointer' };
  };
  const totalOrdersNumber = () => {
    const totalOrdersByCity = {};
    (deliveries || []).map((order) => {
      totalOrdersByCity[order?.wareHouse?.wareHouseLabel] = {
        total:
          (totalOrdersByCity[order?.wareHouse?.wareHouseLabel]?.total || 0) + 1,
        status: {
          ...totalOrdersByCity[order?.wareHouse?.wareHouseLabel]?.status,
          [order.dispatchingStatus]:
            (totalOrdersByCity[order?.wareHouse?.wareHouseLabel]?.status[
              order.dispatchingStatus
            ] || 0) + 1,
        },
      };
    });
    // console.log(totalOrdersByCity);
    setTotalOrders(totalOrdersByCity);
  };
  const selectOrders = (warehouse, status) => {
    const tempselectedDispatchingStatus = { ...selectedDispatchingStatus };
    const tempTotalOrders = { ...totalOrders };
    const tempSelectedWarehousseyOrders = [...selectedWarehousseOrders];
    filterBy != 'dispatchingStatus' && setFilterBy('dispatchingStatus');
    const selectedWarehousseyIndex = (
      tempSelectedWarehousseyOrders || []
    ).findIndex((x) => x == warehouse);
    // console.log(tempSelectedWarehousseyOrders);
    // console.log(selectedWarehousseyIndex);
    if (status) {
      if (tempselectedDispatchingStatus[warehouse]) {
        if (tempselectedDispatchingStatus[warehouse].includes(status)) {
          const findIndex = tempselectedDispatchingStatus[warehouse].findIndex(
            (x) => x == status,
          );
          findIndex != -1 &&
            tempselectedDispatchingStatus[warehouse].splice(findIndex, 1);
          tempselectedDispatchingStatus[warehouse].length == 0 &&
            delete tempselectedDispatchingStatus[warehouse];
        } else {
          tempselectedDispatchingStatus[warehouse].push(status);
        }
      } else {
        tempselectedDispatchingStatus[warehouse] = [status];
      }
    } else {
      if (selectedWarehousseyIndex != -1) {
        tempSelectedWarehousseyOrders.splice(selectedWarehousseyIndex, 1);
        delete tempselectedDispatchingStatus[warehouse];
      } else {
        tempSelectedWarehousseyOrders.push(warehouse);
        tempselectedDispatchingStatus[warehouse] = Object.keys(
          tempTotalOrders[warehouse].status,
        );
      }
    }
    // console.log('tempselectedDispatchingStatus', tempselectedDispatchingStatus);
    // console.log('tempSelectedWarehousseyOrders', tempSelectedWarehousseyOrders);
    setSelectedDispatchingStatus(tempselectedDispatchingStatus);
    setSelectedWarehousseOrders(tempSelectedWarehousseyOrders);
  };
  useEffect(() => {
    totalOrdersNumber();
  }, [deliveriesChangeCounter]);
  // useEffect(() => {
  //   deliveries && totalOrderInWarehouse();
  // }, [(deliveries || []).length]);
  return (
    <div className="mb-4">
      <TableContainer component={Paper}>
        <Typography className="mt-2 ml-4">Dispatching info</Typography>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              {dispatchingInfoTableHeaders.map((item) => (
                <TableCell align="center">{item}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {(Object.keys(totalOrders) || []).map((item, index) => (
              <TableRow key={item + index}>
                <TableCell align="center">{item}</TableCell>
                <TableCell align="center">dispo</TableCell>
                <TableCell
                  align="center"
                  onClick={(e) =>
                    totalOrders[item]?.total && selectOrders(item, '')
                  }
                >
                  <span style={checkIfWarehouseSelected(item)}>
                    {totalOrders[item]?.total}
                  </span>
                </TableCell>
                <TableCell
                  align="center"
                  onClick={(e) =>
                    totalOrders[item]?.status?.ON_HOLD &&
                    selectOrders(item, 'ON_HOLD')
                  }
                >
                  <span style={checkIfSelected(item, 'ON_HOLD')}>
                    {totalOrders[item]?.status?.ON_HOLD || 0}
                  </span>
                </TableCell>
                <TableCell
                  align="center"
                  onClick={(e) =>
                    totalOrders[item]?.status?.DISPATCHING &&
                    selectOrders(item, 'DISPATCHING')
                  }
                >
                  <span style={checkIfSelected(item, 'DISPATCHING')}>
                    {totalOrders[item]?.status?.DISPATCHING || 0}
                  </span>
                </TableCell>

                <TableCell
                  align="center"
                  onClick={(e) =>
                    totalOrders[item]?.status?.DISPATCHED &&
                    selectOrders(item, 'DISPATCHED')
                  }
                >
                  <span style={checkIfSelected(item, 'DISPATCHED')}>
                    {totalOrders[item]?.status?.DISPATCHED || 0}
                  </span>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
