import React from 'react';
import { useSelector } from 'react-redux';
import { getAdminsListDataState } from '../redux/selectors';
export default function orderValidatorComponent({ validator }) {
  const { adminsList } = useSelector(getAdminsListDataState);
  return (
    <div>
      {(adminsList || []).find((x) => x.userId == validator)?.username ||
        validator}
    </div>
  );
}
