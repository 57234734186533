import React, { useState, useEffect } from 'react'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {useAlert} from 'react-alert'
import { makeStyles } from "@material-ui/core/styles";
import { Collapse, Box, Grid } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import Checkbox from "@material-ui/core/Checkbox";
import GridContainer from '../../../components/material-dashboard-pro-react/Grid/GridContainer';
import Card from '../../../components/material-dashboard-pro-react/Card/Card';
import CardHeader from '../../../components/material-dashboard-pro-react/Card/CardHeader';
import CardBody from '../../../components/material-dashboard-pro-react/Card/CardBody';
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
// import ItemListComponent from '../components/ItemList.componenet'
import ProductCategoryListComponent from '../components/ProductCategoryList.component'
import { getProduct , updateProduct} from '../services/serverAPI.service'
import { staticData } from './staticData'

export default function ProductsPage() {
  const alert = useAlert();
  const storeId = localStorage.getItem('storeId')
  const [isLoading, setIsLoading] = useState(false);
  const [product, setProduct] = useState({
    storeId: "",
    products: []
  })
  const [openModule, setOpenModule] = useState({});
  const [openModuleGlobal, setOpenModuleGlobal] = useState(false);
  const useRowStyles = makeStyles({
    root: {
      "& > *": {
        borderBottom: "unset"
      }
    }
  });
  const classes = useRowStyles();
  const getProductFromAPI = () => {
    getProduct(storeId)
      .then((({ data }) => {
        console.log(data)
        setProduct({
          storeId: data.storeId,
          products: data.products
        })
      }))
      .catch(error => console.log(error))
  }
  const onEdit = () => {
    let payload=product.products.length > 0 && product.products.map((item,index)=>{
      
      let products=[];
      item.products.length > 0 && item.products.map((product)=>{
        const itemArray = [];
        product.items.length> 0 && product.items.map(item=>(
          itemArray.push(item._id)
          // console.log(item._id)
        ));
        //  products = [{
        //   productId:product.productId,
        //   items:itemArray
        // }];
        products.push({
          productId:product.productId,
          items:itemArray
        })
      })
      return {
        productCategoryId:item?.productCategoryId,
        products:products
      }
    });
    console.log('payload',payload)
    setIsLoading(true)
    updateProduct(product.storeId,payload)
    .then(({data}) => {
      console.log(data);
      alert.show('changes saved', {
          type: 'success',
      });
      getProductFromAPI()
      
  })
  .catch((error) => {
    console.log(error.message);
      // alert.show(error.message, {
      //     type: 'error',
      // });
  })
  .finally(() => {
      setIsLoading(false)
  })
  }
  useEffect(() => {
    getProductFromAPI()
    // setProduct({
    //   storeId: staticData.storeId,
    //   products: staticData.products
    // })
    let newState = {...openModule}
    product.products.map((item,index)=>(
      newState[index] = false
      ))
      setOpenModule(newState)
  }, [])
  
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };
  const grid = 8;

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? "lightgreen" : "",

    // styles we need to apply on draggables
    ...draggableStyle
  });
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    if (result.type === "droppableItem") {
      const items = reorder(
        product.products,
        result.source.index,
        result.destination.index
      );
      // console.log(items,'items');
      setProduct({
        storeId:product.storeId,
        products: items
      })
      // this.setState({
      //   items
      // });
    } else if (result.type.includes("droppableSubItem")) {
      // console.log(result);
      const parentId = result.type.split('-')[1]
      // console.log(product.products);
      console.log('parentId', parentId);
      const itemSubItemMap = product.products.reduce((acc, item) => {
        // console.log(acc,'acc', item,'item');
        acc[item.productCategoryId] = item.products;
        return acc;
      }, {});
      const subItemsForCorrespondingParent = itemSubItemMap[parentId];
      // console.log(subItemsForCorrespondingParent);
      const reorderedSubItems = reorder(
        subItemsForCorrespondingParent,
        // product.products.products.product,
        result.source.index,
        result.destination.index
      );
      console.log(reorderedSubItems);
      let newItems = [...product.products];
      console.log('before', newItems);
      newItems = newItems.map(item => {
        if (item.productCategoryId === parentId) {
          item.products = reorderedSubItems;
        }
        return item;
      });
      console.log('after', newItems);
      setProduct({
        storeId:product.storeId,
        products: newItems
      })
    }
    else if (result.type.includes("droppableSubSubItem")) {
      
      const parentId = result.type.split('-')[1]
      const index = result.type.split('-')[2]
      const parentIndex = result.type.split('-')[3]
      const parentID = result.type.split('-')[4]
      // console.log('parentID', parentID);
      console.log("parentIndex",index);
      const itemSubItemMap = product.products.reduce((acc, item) => {
        // console.log(acc,'acc', item,'item');
        acc[item.productCategoryId] = item.products[index];
        return acc;
      }, {});
      const subItemsForCorrespondingParent = itemSubItemMap[parentID];
      console.log(subItemsForCorrespondingParent.items);
      const reorderedSubItems = reorder(
        subItemsForCorrespondingParent.items,
        // product.products.products.product,
        result.source.index,
        result.destination.index
      );
      // console.log(reorderedSubItems);
      let newItems = [...product.products];
      // console.log('before', newItems);
      if (newItems[parentIndex].products[index].items) {
        newItems[parentIndex].products[index].items = reorderedSubItems;
      }
      setOpenModuleGlobal(!openModuleGlobal)
      // console.log('after', newItems);
      // setOpenModuleProductList({...openModuleProductList,[index]:!openModuleProductList[index]})
      // newItems = newItems.products.map(item => {
      //   console.log(item)
      //   // item.length>0&&item.map((element)=>(
      //   //   console.log(element)
      //   // ))
      //   // console.log(parentId);
      //   // console.log(parentId);
      //   // console.log(reorderedSubItems);
      //   if (item.productId === parentId) {
      //     console.log(item);
      //     console.log('hereeee');
      //     item.products = reorderedSubItems;
      //   }
      //   // newItems=item
      //   return item;
      // });
      // console.log('newItems',newItems)
      setProduct({
        storeId:product.storeId,
        products: newItems
      })
    }
  }
  console.log("product state ",product);
  const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "" : "",
    padding: grid,
    width: 700
  });
  // console.log(product);
  // const [openModuleProductList, setOpenModuleProductList] = useState({});
  const ProductList = ({ type, subItems, parentIndex }) => {
    // console.log(subItems,'subItems');
    // console.log(subItems[0],'subItems');
    const [openModuleProductList, setOpenModuleProductList] = useState({});
    useEffect(() =>{
      let newState = {...openModuleProductList}
      subItems.map((item,index)=>(
      newState[index] = false
      ))
      Object.keys(openModuleProductList).length === 0&&console.log('condition');
      setOpenModuleProductList(newState)
    },[])
    // console.log("openModuleProductList",openModuleProductList);
    return (
      <Droppable
        droppableId={type}
        type={`droppableSubItem-${type}`}
      >
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {subItems.map((item, index) => (
              <Draggable key={item.product._id} draggableId={item.product._id} index={index}>
                {(provided, snapshot) => (
                  <div style={{ display: "block", }}>
                    <Box className={classes.root} style={{ background: "grey", }} boxShadow={3}>
                       
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                              >
                                  <Grid container spacing={3}>
                                <Grid item xs={1}>
                                  <IconButton
                                    aria-label="expand row"
                                    size="small"
                                    onClick={() => setOpenModuleProductList({...openModuleProductList,[index]:!openModuleProductList[index]})}
                                    style={{ color: "white" }}
                                  >
                                    {openModuleProductList[index] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                  </IconButton>
                                </Grid>
                                <Grid item xs={10}>
                                    <span style={{ color: "white" }} >{item.product.name}</span>
                                  </Grid>
                                  <Grid item xs={1}>
                              
                                  <div {...provided.dragHandleProps}>

                                    <DragHandleIcon style={{ color: "white" }}/>
                                  </div>
                               
                                </Grid>
                              </Grid>
                                </div>
                            </Box>
                   
                    {provided.placeholder}
                    <TableRow>
                              <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                <Collapse in={openModuleProductList[index]} timeout="auto" unmountOnExit>
                                  <Box margin={1}>
                                    <Table size="small" aria-label="purchases">
                                      <TableBody>
                                      <ItemList
                                        subItems={item.items}
                                        type={item.productId}
                                        index={index}
                                        parentIndex={parentIndex}
                                        parentId={type}
                                        openModuleProductList={openModuleProductList}
                                        setOpenModuleProductList={setOpenModuleProductList}
                                      />
                                        
                                      </TableBody>
                                    </Table>
                                  </Box>
                                </Collapse>
                              </TableCell>
                            </TableRow>
                    
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    )
  }
  const ItemList = ({ type, subItems, index, parentIndex, parentId,openModuleProductList,setOpenModuleProductList }) => {
    
    useEffect(() => {
      // console.log('hererrr');
      return ()=>{
        setOpenModuleProductList(openModuleProductList)

      }
    },[openModuleGlobal])
    return (
      <Droppable
        droppableId={type}
        type={`droppableSubSubItem-${type}-${index}-${parentIndex}-${parentId}`}
      >
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {subItems.map((item, i) => (
              <Draggable key={item._id} draggableId={item._id} index={i}>
                {(provided, snapshot) => (
                  <div style={{ display: "block", }}>
                    <Box className={classes.root} style={{ background: "grey",marginLeft:"50px" }} boxShadow={3}>
                              
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                                <Grid container spacing={3}>
                             
                              <Grid item xs={10}>
                                  <span style={{ color: "white" }} >{item.name}</span>
                                </Grid>
                                <Grid item xs={1}>
                            
                                <div {...provided.dragHandleProps}>

                                  <DragHandleIcon style={{ color: "white" }}/>
                                </div>
                             
                              </Grid>
                            </Grid>
                              </div>
                          </Box>
                    {provided.placeholder}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    )
  }
  return (
    <GridContainer>
      <Card>
        <CardHeader>product</CardHeader>
        <CardBody>
        <Box textAlign="right">
          <Button
          round
          type="submit"
          color="success"
          // disabled={!formik.dirty || !formik.isValid || isLoading}
          loading={isLoading}
          loaderColor="white"
          onClick={onEdit}
        >
          save
        </Button>
          </Box>
          <React.Fragment>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable" type="droppableItem">
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                  // style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {product.products && product.products.length > 0 && product.products.map((item, index) => (
                      
                      <Draggable key={item.productCategoryId} draggableId={item.productCategoryId} index={index}>
                        {(provided, snapshot) => (
                          <div>
                            <Box className={classes.root} style={{ background: "grey", }} boxShadow={3}>
                              
                              
                              <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  style={getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style
                                  )}
                                >
                                  <Grid container spacing={3}>
                                <Grid item xs={1}>
                                  <IconButton
                                    aria-label="expand row"
                                    size="small"
                                    onClick={() => setOpenModule({...openModule,[index]:!openModule[index]})}
                                    style={{ color: "white" }}
                                  >
                                    {openModule[index] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                  </IconButton>
                                </Grid>
                                <Grid item xs={10}>
                                    <span style={{ color: "white" }} >{item.productCategory.name}</span>
                                  </Grid>
                                  <Grid item xs={1}>
                              
                                  <div {...provided.dragHandleProps}>

                                    <DragHandleIcon style={{ color: "white" }}/>
                                  </div>
                               
                                </Grid>
                              </Grid>
                                </div>
                            </Box>
                            <TableRow>
                              <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                <Collapse in={openModule[index]} timeout="auto" unmountOnExit>
                                  <Box margin={1}>
                                    <Table size="small" aria-label="purchases">
                                      <TableBody>
                                        <ProductList
                                          subItems={item.products}
                                          type={item.productCategoryId}
                                          parentIndex={index}
                                        />
                                      </TableBody>
                                    </Table>
                                  </Box>
                                </Collapse>
                              </TableCell>
                            </TableRow>
                            {provided.placeholder}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </React.Fragment>
          
        </CardBody>
      </Card>
    </GridContainer>
  )
}