import { GET_SETTING_REQUEST, GET_SETTING_SUCCESS, GET_SETTING_FAIL } from '../constants/setting.constants'

export const getSettingRequestAction = () => ({
    type: GET_SETTING_REQUEST,
})
export const getSettingSuccessAction = (setting) => ({

    type: GET_SETTING_SUCCESS,
    setting
})
export const getSettingFailAction = (error) => ({
    type: GET_SETTING_FAIL,
    error
})
