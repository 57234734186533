import axios from 'axios';
import { MICROSERVICE_BASE_URL } from '../../../config/backendServer.config';

export function getParners(page, limit) {
    return axios.get(
        `${MICROSERVICE_BASE_URL.ALLPARTNERS}/organisation?page=${page || 0}&limit=${limit || 20}&paged${true}&paged=${true}`,
    );
}
export function addParners(payload) {
    return axios.post(
        `${MICROSERVICE_BASE_URL.ALLPARTNERS}/organisation`, payload
    );
}
export function editOrganisation(_id, payload) {
    return axios.put(
        `${MICROSERVICE_BASE_URL.ALLPARTNERS}/organisation/${_id}`, payload
    );
}
export function getPartnerDeliveryManInCities(cities) {
    return axios.post(
        `${MICROSERVICE_BASE_URL.ALLPARTNERS}/deliveryman/cities`,
        { cities: cities },
    );
}
export function getPartnerDeliveryManInCity(cityId) {
    return axios.get(
        `${MICROSERVICE_BASE_URL.ALLPARTNERS}/deliveryman/city/${cityId}`,
    );
}
export function assignDeliveryMan(deliveryManId, obj) {
    return axios.post(
        `${MICROSERVICE_BASE_URL.ALLPARTNERS}/deliveryman/${deliveryManId}/warehouse`, { warehouse: obj }
    );
}
export function removeWareHouseFromDeliveryManList(deliveryManId, warehouseId) {
    return axios.delete(
        `${MICROSERVICE_BASE_URL.ALLPARTNERS}/deliveryman/${deliveryManId}/warehouse/${warehouseId}`,
    );
}
export function getDisponibility(libeleMagasin) {
    return axios.get(
        `${MICROSERVICE_BASE_URL.ALLPARTNERS}/deliveryman/disponibility?libeleMagasin=${libeleMagasin}`,
    );
}
export function getConfigCallBackStatus(organisationId) {
    return axios.get(
        `${MICROSERVICE_BASE_URL.ALLPARTNERS}/organisation/${organisationId}/callbacks-config`,
    );
}
export function editConfigCallBackStatus(payload) {
    return axios.put(
        `${MICROSERVICE_BASE_URL.ALLPARTNERS}/organisation/${payload.organisationId}/callbacks-config`, payload
    );
}
export function getCommissionConfiForWarehous(wareHouseId) {
    return axios.get(
        `${MICROSERVICE_BASE_URL.ALLPARTNERS}/warehouse/${wareHouseId}/config`,
    );
}
export function editCommissionConfiForWarehous(payload) {
    return axios.put(
        `${MICROSERVICE_BASE_URL.ALLPARTNERS}/warehouse/${payload._id}/config`, payload
    );
}
export function preferredWareHouse(deliveryManId, warehouse) {
    return axios.post(
        `${MICROSERVICE_BASE_URL.ALLPARTNERS}/deliveryman/${deliveryManId}/warehouse/prefered`, { warehouse: warehouse }
    );
}
export function getCallApi(page, limit, status, call, marjaneId, kaalixId, startDate, endDate, useDate, source, marjaneStatus) {
    return axios.get(
        `${MICROSERVICE_BASE_URL.ALLPARTNERS}/callapi?page=${page || 0}&limit=${limit || 20}&${marjaneStatus && `marjaneStatus=${marjaneStatus || ''}`}&${status && `status=${status}`}&call=${call || ''}&marjaneId=${marjaneId || ''}&kaalixId=${kaalixId || ''}&${useDate && `dateFrom=${startDate || ''}&dateTo=${endDate || ''}`}&source=${source || ''}`,
    );
}
export function getCallApiForLogin(page, limit, startDate, endDate, useDate, status) {
    // console.log('startDate', page, limit, startDate, endDate, useDate);
    return axios.get(
        `${MICROSERVICE_BASE_URL.AUTH_SERV}/auth_partner?page=${page || 0}&limit=${limit || 20}&${status && `status=${status || ''}`}&${useDate && `dateFrom=${startDate || ''}&dateTo=${endDate || ''}`}`,
    );
}
export function getApprouvedProvider(
    page,
    limit,
    cityId,
    txt,
) {
    return axios.get(
        `${MICROSERVICE_BASE_URL.DELIVERY_MAN}/deliveryMan-all-partner?page=${page || 0
        }&limit=${limit || 5}&paged=true&approved=true&cityId=${cityId || ''
        }&search_text=${txt || ''}`,
    );
}
export function getApprouvedProviderByIds(
    page,
    limit,
    DeliveryMenIds,
) {
    return axios.post(
        `${MICROSERVICE_BASE_URL.DELIVERY_MAN}/?page=${page || 0
        }&limit=${limit || 5}&paged=false&approved=true`, { DeliveryMenIds: DeliveryMenIds }
    );
}
export function getDeliveryMenBackup(
    page,
    limit,
    DeliveryMenIds,
) {
    return axios.post(
        `${MICROSERVICE_BASE_URL.ALLPARTNERS}/deliveryman/deliveryMenBackup?page=${page || 0
        }&limit=${limit || 5}&paged=false`, { DeliveryMenIds: DeliveryMenIds }
    );
}
export function getDeliveryMenBackupUsingLabel(
    page,
    limit,
    wareHouseLabels,
) {
    return axios.post(
        `${MICROSERVICE_BASE_URL.ALLPARTNERS}/deliveryman/deliveryMen-backup?page=${page || 0
        }&limit=${limit || 5}&paged=true`, { wareHouseLabels: wareHouseLabels }
    );
}
export function editProvider(id, payload) {
    return axios.put(`${MICROSERVICE_BASE_URL.DELIVERY_MAN}/${id}/available`, {
        ...payload,
    });
}