import React, { useState, useEffect } from 'react';
import SelectBoxComponent from '../../../components/SelectBox.component';
import { useFormik } from 'formik';
import { useAlert } from 'react-alert';
import * as yup from 'yup';
import { Box } from '@material-ui/core';
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import {
  addFlagToProduct,
  addFlagToProductCategory,
  addFlagToStore,
} from '../services/serverAPI.service';
import { addFlagSrc } from '../mock';
import SwitchComponent from '../../../components/Switch.component'


const validationSchema = yup.object({
  flag: yup.string().required(),
});
export default function addFlagComponent({
  src,
  flags,
  index,
  result,
  close,
  setState,
  state,
}) {
  const alert = useAlert();
  const [isLoading, setIsLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      flag: '',
      withItems:false,

    },
    validationSchema,
  });
  const setValue = () => {
    if (result) {
      formik.setValues({
        flag: result?.flag || '',
        withItems: result?.withItems || false,
      });
    }
  };
  const onEdit = () => {
    setIsLoading(true);
    switch (src) {
      case addFlagSrc.product:
        addFlagToProduct(result._id, formik.values)
          .then(({ data }) => {
            let tempData = state;
            tempData[index] = data.product;
            setState(tempData);
            close();
          })
          .catch((error) => {
            alert.show(error?.message, {
              type: 'error',
            });
            console.log(error);
          })
          .finally(() => {
            setIsLoading(false);
          });
        break;
      case addFlagSrc.productCategory:
        addFlagToProductCategory(result._id, formik.values)
          .then(({ data }) => {
            let tempData = state;
            tempData[index] = data.product;
            setState(tempData);
            close();
          })
          .catch((error) => {
            alert.show(error?.message, {
              type: 'error',
            });
            console.log(error);
          })
          .finally(() => {
            setIsLoading(false);
          });
        break;
      case addFlagSrc.store:
        addFlagToStore(result._id, formik.values)
          .then(({ data }) => {
            let tempData = state;
            tempData[index] = data.store;
            setState(tempData);
            close();
          })
          .catch((error) => {
            alert.show(error?.message, {
              type: 'error',
            });
            console.log(error);
          })
          .finally(() => {
            setIsLoading(false);
          });
        break;

      default:
        setIsLoading(false);
        break;
    }
  };

  useEffect(() => {
    setValue();
  }, [result]);
  return (
    <div>
      <Box style={{ width: '100%' }}>
        <SelectBoxComponent
          items={(flags || []).map((item) => ({
            value: item.name,
            label: item.name,
          }))}
          inputLabel="flag list"
          onValueChange={(items) => {
            formik.setFieldValue('flag', items);
          }}
          value={formik.values.flag}
        />
         <SwitchComponent
                onChange={(value) => formik.setFieldValue('withItems', value)}
                checked={formik.values.withItems}
                error={formik.touched.withItems && Boolean(formik.errors.withItems)}
                label="WithItems"
            />
      </Box>

      <Box textAlign="center">
        <Button
          round
          type="submit"
          color="success"
          disabled={!formik.dirty || !formik.isValid || isLoading}
          loading={isLoading}
          loaderColor="white"
          onClick={onEdit}
        >
          Edit Flag
        </Button>
      </Box>
    </div>
  );
}
