import React, { useState, useEffect } from 'react';
import { useAlert } from 'react-alert';
import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import GridContainer from '../../../components/material-dashboard-pro-react/Grid/GridContainer';
import GridItem from '../../../components/material-dashboard-pro-react/Grid/GridItem.js';
import Card from '../../../components/material-dashboard-pro-react/Card/Card';
import CardHeader from '../../../components/material-dashboard-pro-react/Card/CardHeader';
import CardBody from '../../../components/material-dashboard-pro-react/Card/CardBody';
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import AsyncSelect from 'react-select/async';

import Checkbox from '@material-ui/core/Checkbox';

import { dashStore, dashboardRoutes } from '../../../routes';
import {
  getAdmins,
  getAdminPermission,
  setAdminPermissions,
} from '../services/serverApi.service';

export default function AdminPermissionPage() {
  const routes = dashboardRoutes.concat(dashStore);
  const alert = useAlert();
  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [admins, setAdmins] = useState([]);
  const [admin, setAdmin] = useState({});
  const [permission, setPermission] = useState([]);
  const [all, setAll] = useState(false);
  const useRowStyles = makeStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
  });

  const getPermmission = (adminId) => {
    getAdminPermission(adminId)
      .then(({ data }) => {
        setPermission(data.adminPermissions || []);
      })
      .catch((err) => setPermission([]));
  };
  const selectAll = () => {
    const paths = routes.flatMap((route) =>
      route.views.map((view) => view.layout + view.path),
    );
    setAll(true);
    setPermission(paths);
  };
  const savePermission = () => {
    setAdminPermissions(admin.userId, permission)
      .then(({ data }) => {
        alert.show('saved', {
          type: 'success',
        });
      })
      .catch((err) =>
        alert.show('error', {
          type: 'error',
        }),
      );
  };
  const classes = useRowStyles();
  let timer1;
  const fetchData = (inputValue, callback) => {
    clearTimeout(timer1);
    timer1 = setTimeout(() => {
      inputValue &&
        getAdmins(inputValue)
          .then(({ data }) => {
            const tempArray = [];
            if (data.admins.length) {
              data.admins.forEach((element) => {
                tempArray.push({
                  label: `${element.username} / ${element?.email}`,
                  name: `${element.username.substring(0, 27)}`,
                  _id: element?._id,
                  email: element?.email,
                  userId: element?.userId,
                });
              });
            }

            callback(tempArray);
          })
          .catch((error) => {
            console.log(error);
          });
    }, 2000);
  };

  const setAdminPermission = (path) => {
    const tempPermission = permission;
    const findIndex = tempPermission.indexOf(path);
    findIndex === -1
      ? tempPermission.push(path)
      : tempPermission.splice(findIndex, 1);
    setPermission([...tempPermission]);
  };
  // useEffect(() => {}, []);
  // useEffect(() => {}, [...admins]);
  return (
    <GridContainer>
      <Card>
        <CardHeader>Admins WhiteList</CardHeader>
        <CardBody>
          <GridContainer className={classes.gridContainer}>
            <GridItem xs={10} sm={6} md={8}>
              <AsyncSelect
                single
                isClearable
                defaultValue={inputValue}
                loadOptions={fetchData}
                placeholder="find Admin"
                onChange={(e) => {
                  if (e.userId) {
                    setAll(false);
                    setAdmin(e);
                    getPermmission(e.userId);
                  } else {
                    setStore(null);
                  }
                }}
              />
            </GridItem>
            <GridItem xs={2} sm={6} md={4}>
              <Box textAlign="right">
                <Button
                  round
                  type="submit"
                  color="success"
                  disabled={isLoading}
                  loading={isLoading}
                  loaderColor="white"
                  onClick={savePermission}
                >
                  save
                </Button>
              </Box>
            </GridItem>
            {admin && Object.keys(admin).length !== 0 && (
              <>
                {`${admin.name} / ${admin.email}`}
                <Grid container spacing={3} style={{ marginTop: 8 }}>
                  <GridItem xs={1} sm={6} md={1} style={{ marginRight: 15 }}>
                    <Checkbox
                      // checked={(permission || []).includes(view.layout + view.path)}
                      checked={false}
                      onChange={(e) => {
                        selectAll();
                      }}
                    />
                  </GridItem>
                  <GridItem
                    xs={1}
                    sm={6}
                    md={3}
                    style={{ marginRight: 15, marginTop: 5 }}
                  >
                    ALL
                  </GridItem>
                </Grid>
                {routes.map((route) => (
                  <GridItem xs={2} sm={6} md={12} style={{ marginTop: 10 }}>
                    <span style={{ marginTop: 75 }}>{route.name}</span>
                    {(route.views || []).map((view) => (
                      <Grid container spacing={3} style={{ marginTop: 8 }}>
                        <GridItem
                          xs={1}
                          sm={6}
                          md={1}
                          style={{ marginRight: 15 }}
                        >
                          <Checkbox
                            checked={(permission || []).includes(
                              view.layout + view.path,
                            )}
                            onChange={(e) => {
                              setAdminPermission(view.layout + view.path);
                            }}
                          />
                        </GridItem>
                        <GridItem
                          xs={1}
                          sm={6}
                          md={3}
                          style={{ marginRight: 15, marginTop: 5 }}
                        >
                          {view?.name}
                        </GridItem>
                      </Grid>
                    ))}
                  </GridItem>
                ))}
              </>
            )}
          </GridContainer>
        </CardBody>
      </Card>
    </GridContainer>
  );
}
