import React, { useEffect, useState } from 'react'
import * as yup from 'yup';
import { useFormik } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import { FormLabel, Box } from '@material-ui/core';
import CustomInput from '../../../components/material-dashboard-pro-react/CustomInput/CustomInput'
import SelectBoxComponent from '../../../components/SelectBox.component'
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button'
import { commissionTypes } from '../mocks/index';
import { updateStore } from '../services/serverApi.service';
import styles from '../../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle';

const useStyles = makeStyles(styles);

const validationSchema = yup.object({
  commission: yup.number().required(),
  commissionType: yup.string().required(),
})

export default function StoreCommisionComponent({ store, index, stores, setStores, close }) {

  const [isLoading, setIsLoading] = useState(false)
  const classes = useStyles();
  const formik = useFormik({
    initialValues: {
      commission: 0,
      commissionType: '',
    },
    validationSchema,
  });
  const editStore = () => {
    store.commission = formik.values.commission
    store.commissionType = formik.values.commissionType
    setIsLoading(true)
    updateStore(store._id, store)
      .then(({ data }) => {
        console.log(data);
        let newStores = [...stores]
        newStores[index] = data.store
        setStores(newStores)
        close()
      })
      .catch((error) => {
        console.log(error.message, {
          type: 'error',
        });
      })
      .finally(() => setIsLoading(true))
  }
  const setValues = () => {
    formik.setValues({
      commission: store.commission,
      commissionType: store.commissionType,
    })
  }

  useEffect(() => {
    setValues()
  }, [])
  return (
    <div>
      <FormLabel className={classes.labelHorizontal}>
        Commission
      </FormLabel>
      <CustomInput
        formControlProps={{
          fullWidth: true,
        }}
        type="number"
        error={
          formik.touched.commission && Boolean(formik.errors.commission)
        }
        inputProps={{
          type: 'number',
          value: formik.values.commission,
          onBlur: formik.handleBlur('commission'),
          onChange: formik.handleChange('commission'),
        }}
      />
      <FormLabel className={classes.labelHorizontal}>
        Commission type
      </FormLabel>
      <SelectBoxComponent
        items={commissionTypes.map((commissionType) => ({
          value: commissionType.value,
          label: commissionType.label,
        }))}
        onValueChange={formik.handleChange('commissionType')}
        value={formik.values.commissionType}
      />
      <Box textAlign="center">
        <Button
          round
          type="submit"
          color="success"
          disabled={!formik.dirty || !formik.isValid || isLoading}
          loading={isLoading}
          loaderColor="white"
          onClick={editStore}
        >
          save commission
        </Button>
      </Box>
    </div>
  )
}
