import React,{useState,useEffect} from 'react';
import DataTableContainerComponent from '../../../components/DataTableSortingContainer.component';
import ViewItemComponent from '../components/ViewPublicity.component';
import CreatePublicityComponent from '../components/CreatePublicity.component';
import DeletePublicityComponent from '../components/DeletePublicity.component';
import EditPublicityComponent from '../components/EditPublicity.component';
import {formatTime} from '../../../helpers/index'
import {getPublicity,getCities, getServiceCategories,} from '../services/serverApi.service'

export default function PublicityPage() {
  const [cities, setCities] = useState([]);
  const [serviceCategories, setServiceCategories] = useState([]);
  const [resultsData, setResultsData] = useState({
    total: 0,
    items: [],
    tableItems: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const pages = [100, 250, 500];
  let numberOfRows = [50,100,250];
  // const classes = useStyles();
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(pages[page])


  const loadPublicityList = (skip,limit) => {
    setIsLoading(true);
    getPublicity(skip,limit)
      .then(({ data }) => {
        console.log(data);
        // data.publicityGroups.length = 0&&
        setResultsData({
          total: data.publicityGroups.length,
          // items: data,
          items: data.publicityGroups.map((item) => {
            return {
              ...item,
              // name: item.name,
              // description: item.description,
              cityCol: item.city?item.city.name:'--',
              // externalLink: item.externalLink?item.externalLink:'--',
              // assignedTo: item.assignedTo?<span publicitySubGroups={item.publicitySubGroups} id={item._id}>{item.assignedTo}</span>:'--',
              // format: item.format?item.format:'--',
              // email: item.email,
              createdAt:item.createdAt ?formatTime(item.createdAt):'--',
              deletedAt:item.deletedAt ?formatTime(item.deletedAt):'--',
              
            };
          })
        });
      })
      .catch(error => console.log(error))
      .finally(() => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    loadPublicityList(page, rowsPerPage)
    getCities()
            .then(({ data}) => {
                setCities(data);
            })
            .catch((error) => {
                alert.show(error.message, {
                    type: 'error',
                });
            });
        // getStoresBySearch(0,5, true,text)
        //     .then(({ data: { stores: storeData } }) => {
        //         setStores(storeData);
        //     })
        //     .catch((error) => {
        //         alert.show(error.message, {
        //             type: 'error',
        //         });
        //     });

        getServiceCategories()
            .then(({ data }) => {
                setServiceCategories(data);
            })
            .catch((error) => {
                alert.show(error.message, {
                    type: 'error',
                });
            });
  }, [])
  const handlePaginate = (val) => {
    // console.log(val,'val');
    console.log(val.pageSelect, val.numberOfRows);
    setResultsData({
      items: [],
      tableItems: [],
    });
    loadPublicityList(val.pageSelect, val.numberOfRows);
  };
  return (
    <DataTableContainerComponent
    total={resultsData.total}
        resultsData={resultsData}
        initData={resultsData.items}
        onPaginate={handlePaginate}
            isPaginate={true}
        columns={[
          {
            Header: 'Name',
            accessor: 'name',
          },
          {
            Header: 'description',
            accessor: 'description',
          },
          {
            Header: 'city',
            accessor: 'cityCol',
          },
          {
            Header: 'externalLink',
            accessor: 'externalLink',
          },
          {
            Header: 'assignedTo',
            accessor: 'assignedTo',
          },
          {
            Header: 'format',
            accessor: 'format',
          },
          {
            Header: 'createdAt',
            accessor: 'createdAt',
          },
          {
            Header: 'deletedAt',
            accessor: 'deletedAt',
          },
          {
            Header: 'actions',
            accessor: 'actions',
          },
        ]}
        // tableHead={[
        //   '_id',
        //   'Name',
        //   'email',
        //   'action',

        // ]}
        title="Publicity List"
      isLoading={isLoading}
      loadItemsList={loadPublicityList}
        // CreateItemFormComponent={CreatePublicityComponent}
        actionButtons={{
              view: {
                color: 'info',
                type: 'view',
                component: ViewItemComponent,
              },
              edit: {
                color: 'success',
                type: 'edit',
                component: EditPublicityComponent,
                props : {cities,serviceCategories}
              },
              delete: { 
                color: 'danger',
               type: 'delete' ,
               component:DeletePublicityComponent
              },
            }}
      />
  );
}
