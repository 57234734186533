import React,{useState,useEffect} from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux';
import {useAlert} from 'react-alert'
import {useFormik} from 'formik'
import * as yup from 'yup'
import {Box} from '@material-ui/core'
import Switch from '@material-ui/core/Switch';
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button'
import CustomInput from '../../../components/material-dashboard-pro-react/CustomInput/CustomInput'
import {defaultImagesAction} from '../../../redux/uploadRedux/upload.action'
import SwitchComponent from '../../../components/Switch.component'
import ImageUpload from '../../../components/material-dashboard-pro-react/CustomUpload/ImageUpload';
import {updateCategory,getCategoryList} from '../services/serverAPI.service'
import SelectBoxComponent from '../../../components/SelectBox.component'

const validationSchema = yup.object({
    name: yup.string().required(),
    description: yup.string().required(),
    storeId: yup.string().required(),
    inStock: yup.bool().required(),
    imageUrl: yup.string(),
  });

export default function EditeCategory({data,close,onItemEdited}) {
    
    const [isLoading, setIsLoading] = useState(false);
    const [displayImage, setDisplayImage] = useState(false);
    const [categoryList, setCategoryList] = useState([]);
    const dispatch = useDispatch();
    const alert = useAlert();
    const upload = useSelector((state) => state.uploadReducer)
  const { iconUrl, error, loading } = upload
    const storeId = localStorage.getItem('storeId')
    // console.log(data);
    // console.log(data?.name?.props?.children);
    const formik = useFormik({
        initialValues: {
            name: '',
            description: '',
            storeId: '',
            inStock: '',
            imageUrl: '',
            _id:'',
            productCategoryId:''
        },
        validationSchema,
      });
      const onCreate = ()=>{
        // console.log(formik.values);
        setIsLoading(true)
        updateCategory(formik.values)
        .then(() => {
            alert.show('category updated', {
                type: 'success',
            });
            formik.resetForm();
            setDisplayImage(false);
            close()
            onItemEdited();
        })
        .catch((error) => {
            alert.show(error.message, {
                type: 'error',
            });
        })
        .finally(() => {
            setIsLoading(false)
          })
      }
      const setValue=()=>{
        if (data) {
    
          !iconUrl&& dispatch(defaultImagesAction(data.imageUrl.props.src))
          
            formik.setValues({
            name: data.name,
            _id: data._id,
            description: data.description,
            imageUrl:iconUrl,
            inStock:data.inStock.props.checked,
            productCategoryId:data.productCategoryId,
            storeId
          })}
        
      }
      const getCategorysList=() => {
        getCategoryList(storeId)
        .then(({ data }) => {
            setCategoryList(data);
          })
        .catch((error) => {
            alert.show(error.message, {
                type: 'error',
            });
        })
    }
      useEffect(() => {
        setValue()
        getCategorysList()
      },[data])
      useEffect(() => {
        iconUrl&&formik.setFieldValue('imageUrl', iconUrl)
      },[iconUrl])
    return (
        <div>
            <div>{!displayImage && <span>you need to upload a new image</span>}
          <Switch
            color='primary'
            checked={displayImage}
            value={displayImage}
            onChange={e => { displayImage ? setDisplayImage(false) : setDisplayImage(true) }}
          />
        </div>
      <Box display="flex" justifyContent="center">
        {Boolean(data) && (
          !displayImage ?

            <img src={data.imageUrl.props.src} alt="dqs" height="100" width="120" />

            : <ImageUpload
              // defaultImage={data.iconUrl}
              // onSubmit={onUploadMedia}
              // onRemove={onRemoveImage}
              label="Select icon"
              avatar
              folder="service"
            />
        )}
      </Box>
            <CustomInput
            labelText="Name"
            formControlProps={{
              fullWidth: true,
            }}
            type="text"
            error={formik.touched.name && Boolean(formik.errors.name)}
            inputProps={{
              value: formik.values.name,
              onBlur: formik.handleBlur('name'),
              onChange: formik.handleChange('name'),
            }}
          />
            <CustomInput
            labelText="Description"
            formControlProps={{
              fullWidth: true,
            }}
            type="text"
            error={formik.touched.description && Boolean(formik.errors.description)}
            inputProps={{
              value: formik.values.description,
              onBlur: formik.handleBlur('description'),
              onChange: formik.handleChange('description'),
            }}
          />
          <SelectBoxComponent
                items={categoryList.map((element) => ({
                value: element._id,
                label: element.name,
                }))}
                inputLabel="Category list"
                onValueChange={(items) => {
                formik.setFieldValue('productCategoryId', items);
                }}
                value={formik.values.productCategoryId}
            />
          <SwitchComponent
        onChange={(value) => formik.setFieldValue('inStock', value)}
        checked={formik.values.inStock}
        error={formik.touched.inStock && Boolean(formik.errors.inStock)}
        label="inStock"
      />
      <Box textAlign="center">
        <Button
          round
          type="submit"
          color="success"
          disabled={!formik.dirty || !formik.isValid || isLoading}
          loading={isLoading}
          loaderColor="white"
          onClick={onCreate}
        >
          save
        </Button>
      </Box>
        </div>
    )
}

EditeCategory.propTypes = {
    data: PropTypes.any,
    close: PropTypes.any,
    onItemEdited: PropTypes.func.isRequired,
}
