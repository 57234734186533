export const DispatchingStatus = {
  // {
  //   label: 'Nouveau Client',
  //   value: 'NEW_USER',
  // },
  // {
  //   label: 'Non Valid',
  //   value: 'NOT_VALID',
  // },
  // {
  //   label: 'En Attente',
  //   value: 'ON_HOLD',
  // },
  // {
  //   label: 'Programmé',
  //   value: 'SCHEDULED',
  // },
  // {
  //   label: 'Dispatch En Cours',
  //   value: 'DISPATCHING',
  // },
  // {
  //   label: '‘Dispatché',
  //   value: 'DISPATCHED',
  // },
  // NOT_VALID: 'NOT_VALID',
  // ON_HOLD: 'ON_HOLD',
  // SCHEDULED: 'SCHEDULED',
  // DISPATCHING: 'DISPATCHING',
  // DISPATCHED: 'DISPATCHED',

  NEW_USER: 'Nouveau Client',
  NOT_VALID: 'Non Valid',
  ON_HOLD: 'En Attente',
  SCHEDULED: 'Programmé',
  DISPATCHING: 'Dispatch En Cours',
  DISPATCHED: 'Dispatché',
};
export const orderDispatchingStatus = {
  NEW_USER: 'NEW_USER',
  INVALID: 'INVALID',
  ON_HOLD: 'ON_HOLD',
  SCHEDULED: 'SCHEDULED',
  DISPATCHING: 'DISPATCHING',
  DISPATCHED: 'DISPATCHED',
};
export const DeliveryManStatus = [
  {
    label: 'Accept en Attente',
    value: 'WAITING_FOR_ACCEPT',
  },
  {
    label: 'Accepté',
    value: 'ACCEPTED',
  },
  {
    label: 'Début Prépa',
    value: 'START_PREPARATION',
  },
  {
    label: 'En Route Store',
    value: 'COMING_FOR_DELIVERY',
  },
  {
    label: 'Ramassage Arrivée',
    value: 'ARRIVED_TO_PICKUP',
  },
  {
    label: 'Facture',
    value: 'UPLOAD_INVOICE',
  },
  {
    label: 'Début Livraison',
    value: 'STARTED_DELIVERY',
  },
  {
    label: 'Destination arrivée',
    value: 'ARRIVED_TO_DESTINATION',
  },
  {
    label: 'Livré',
    value: 'DELIVERED',
  },
  // WAITING_FOR_ACCEPT: 'WAITING_FOR_ACCEPT',
  // ACCEPTED: 'ACCEPTED',
  // START_PREPARATION: 'START_PREPARATION',
  // COMING_FOR_DELIVERY: 'COMING_FOR_DELIVERY',
  // ARRIVED_TO_PICKUP: 'ARRIVED_TO_PICKUP',
  // STARTED_DELIVERY: 'STARTED_DELIVERY',
  // ARRIVED_TO_DESTINATION: 'ARRIVED_TO_DESTINATION',
  // DELIVERED: 'DELIVERED',

  //   WAITING_FOR_ACCEPT = ‘Accept en Attente’,
  //  ACCEPTED = ‘Accepté’,
  //  START_PREPARATION = ‘Début Prépa’,
  //  COMING_FOR_DELIVERY = ‘En Route Store’,
  //  ARRIVED_TO_PICKUP = ‘Ramassage Arrivée’,
  //  UPLOAD_INVOICE = ‘Facture’,
  //  STARTED_DELIVERY = ‘Début Livraison’,
  //  ARRIVED_TO_DESTINATION = ‘Destination arrivée’,
  //  DELIVERED = ‘Livré’,
];
export const driverStatus = [
  {
    label: 'Accept en Attente',
    value: 'WAITING_FOR_ACCEPT',
  },
  {
    label: 'Accepté',
    value: 'ACCEPTED',
  },
  {
    label: 'En Route client',
    value: 'COMING_TO_CUSTOMER',
  },
  {
    label: 'ARRIVED_TO_CUSTOMER',
    value: 'ARRIVED_TO_CUSTOMER',
  },
  {
    label: 'Destination arrivée',
    value: 'ARRIVED_TO_DESTINATION',
  },
  {
    label: 'Livré',
    value: 'COMPLETED',
  },
  //   ACCEPTED
  // COMING_TO_CUSTOMER
  // ARRIVED_TO_CUSTOMER
  // ARRIVED_TO_DESTINATION
  // COMPLETED
];

export const deliveryStatus = {
  WAITING_FOR_ACCEPT: 'Attente Acceptation',
  ACCEPTED: 'Acceptée',
  START_PREPARATION: 'Début Prépa',
  COMING_FOR_DELIVERY: 'En Route Store',
  ARRIVED_TO_PICKUP: 'Arrivé au Store',
  UPLOAD_INVOICE: 'Facture',
  STARTED_DELIVERY: 'Début Livraison',
  ARRIVED_TO_DESTINATION: 'arrivé à destination',
  DELIVERED: 'Livrée',
};
export const StoreStatus = {
  // WAITING_FOR_ACCEPT: 'WAITING_FOR_ACCEPT',
  // ACCEPTED: 'ACCEPTED',
  // START_PREPARATION: 'START_PREPARATION',
  // END_PREPARATION: 'END_PREPARATION',
  WAITING_FOR_ACCEPT: 'Accept en attente',
  ACCEPTED: 'Accepté',
  START_PREPARATION: 'Début Prépa',
  END_PREPARATION: 'Fin Prépa',
};

export const CustomerStatus = {
  WAITING_FOR_ACCEPT: 'Accept en attente',
  ACCEPTED: 'Accepté',
  START_PREPARATION: 'Début Prépa',
  STARTED_DELIVERY: 'Début Livraison',
  ARRIVED_TO_DESTINATION: 'Destination arrivée',
  DELIVERED: 'Livré',
  CANCELED: 'Annulé',
  //   WAITING_FOR_ACCEPT: 'WAITING_FOR_ACCEPT',
  //   ACCEPTED: 'ACCEPTED',
  //   START_PREPARATION: 'START_PREPARATION',
  //   STARTED_DELIVERY: 'STARTED_DELIVERY',
  //   ARRIVED_TO_DESTINATION: 'ARRIVED_TO_DESTINATION',
  //   DELIVERED: 'DELIVERED',
  //   CANCELED: 'CANCELED',
};
export const deliveryManStatusObj = {
  WAITING_FOR_ACCEPT: 'WAITING_FOR_ACCEPT',
  ACCEPTED: 'ACCEPTED',
  START_PREPARATION: 'START_PREPARATION',
  COMING_FOR_DELIVERY: 'COMING_FOR_DELIVERY',
  ARRIVED_TO_PICKUP: 'ARRIVED_TO_PICKUP',
  UPLOAD_INVOICE: 'UPLOAD_INVOICE',
  STARTED_DELIVERY: 'STARTED_DELIVERY',
  ARRIVED_TO_DESTINATION: 'ARRIVED_TO_DESTINATION',
  DELIVERED: 'DELIVERED',
};

export const modesList = [
  'Normal',
  'Preventif',
  'Partner',
  'Blocked',
  'Closed',
];
export const iconsList = {
  client: 'client',
  store: 'store',
  deliveryManWithOrder: 'deliveryManWithOrder',
  deliverManWithoutOrde: 'deliverManWithoutOrde',
};
