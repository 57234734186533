import React, { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { useFormik } from 'formik';
import * as yup from 'yup';
import CustomInput from '../../../components/material-dashboard-pro-react/CustomInput/CustomInput';
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import { Box } from '@mui/material';
import {
  updatePartnerSetting,
  getPartnerSetting,
} from '../services/serverApi.service';
import SwitchComponent from '../../../components/Switch.component';

const validationSchema = yup.object({
  ds_warehouse_provider: yup.number().required(),
  ds_provider_hybrid: yup.number().required(),
});

export default function PartnerSettingComponent() {
  const alert = useAlert();
  const [isLoading, setIsLoading] = useState(false);
  const [setting, setSetting] = useState({});
  const formik = useFormik({
    initialValues: {
      ds_warehouse_provider: '',
      ds_provider_hybrid: '',
      onlyHybrid: '',
    },
    validationSchema,
  });
  const saveSetting = () => {
    const { ds_warehouse_provider, ds_provider_hybrid, onlyHybrid } =
      formik.values;
    const body = {
      dispatchingCronConfig: {
        ds_warehouse_provider,
        ds_provider_hybrid,
        onlyHybrid,
      },
    };
    // body.ds_warehouse_provider = ds_warehouse_provider;
    // body.ds_provider_hybrid = ds_provider_hybrid;
    updatePartnerSetting(setting._id, body)
      .then(({ data }) => {
        alert.show('saved', {
          type: 'success',
        });
      })
      .catch((error) => console.log(error));
  };
  const setValues = (data) => {
    data &&
      formik.setValues({
        ds_warehouse_provider: data?.ds_warehouse_provider,
        ds_provider_hybrid: data?.ds_provider_hybrid,
        onlyHybrid: data?.onlyHybrid,
      });
  };
  const loadSettingFromDb = () => {
    getPartnerSetting()
      .then(({ data }) => {
        console.log('st', data);
        setSetting(data);
        setValues(data.dispatchingCronConfig);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    // setting && setting.length > 0 && setValues();
    loadSettingFromDb();
  }, []);
  return (
    <div>
      <CustomInput
        labelText="ds warehouse provider "
        formControlProps={{
          fullWidth: true,
        }}
        type="text"
        error={
          formik.touched.ds_warehouse_provider &&
          Boolean(formik.errors.ds_warehouse_provider)
        }
        inputProps={{
          value: formik.values.ds_warehouse_provider,
          onBlur: formik.handleBlur('ds_warehouse_provider'),
          onChange: formik.handleChange('ds_warehouse_provider'),
        }}
      />
      <CustomInput
        labelText="ds provider hybrid"
        formControlProps={{
          fullWidth: true,
        }}
        type="text"
        error={
          formik.touched.ds_provider_hybrid &&
          Boolean(formik.errors.ds_provider_hybrid)
        }
        inputProps={{
          value: formik.values.ds_provider_hybrid,
          onBlur: formik.handleBlur('ds_provider_hybrid'),
          onChange: formik.handleChange('ds_provider_hybrid'),
        }}
      />
      <SwitchComponent
        onChange={(val) => formik.setFieldValue('onlyHybrid', val)}
        value={formik.values.onlyHybrid}
        checked={formik.values.onlyHybrid}
        label={'onlyHybrid'}
      />
      <Box textAlign="right">
        <Button
          round
          type="submit"
          color="success"
          disabled={!formik.dirty || !formik.isValid || isLoading}
          loading={isLoading}
          loaderColor="white"
          onClick={saveSetting}
        >
          save
        </Button>
      </Box>
    </div>
  );
}
