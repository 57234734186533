import React from 'react';
import {
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Typography,
  Paper,
  Button,
  Grid,
} from '@mui/material';

// mock
import { alarmInfoTableHeaders } from '../mock';

export default function AlarmsInfoComponenet() {
  return (
    <div className="mb-4">
      <TableContainer component={Paper}>
        <Typography className="mt-2 ml-4">Alarm info</Typography>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              {alarmInfoTableHeaders.map((item) => (
                <TableCell align="center">{item}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {/* {
              (Object.keys(totalOrdersByCity) || []).map(
                (item, index) =>
                  totalOrdersByCity[item] && (
                    <Row key={index} row={item} index={index} />
                  ),
              )
            } */}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
