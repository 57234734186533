import React, { useState, useEffect } from 'react';
import { Provider } from 'react-redux';
import {
  BrowserRouter,
  Switch,
  HashRouter,
  useHistory,
} from 'react-router-dom';
import { Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';
import { PersistGate } from 'redux-persist/integration/react';
import { LoadScript } from '@react-google-maps/api';
import LoginFormComponent from './features/auth/components/LoginForm.component';
import RegisterFormComponent from './features/auth/components/RegisterForm.component';
import { persistor, store } from './redux/store';
import AuthRoute from './wrappers/AuthRoute';
import Admin from './layouts/Admin';
import { alertConfig } from './config/alert.config';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import {
  getAdminWithList,
  getAdminPermission,
} from './features/delivery-info/services/serverApi.service';

function App() {
  // const [admin, setAdmin] = useState([]);
  const [nav, setNav] = useState([]);
  const [ifAdminWithListed, setIfAdminWithListed] = useState(true);
  const userData = localStorage.getItem('persist:root');
  const parsedJson = JSON.parse(userData);
  const authReducerData = parsedJson ? JSON.parse(parsedJson?.authReducer) : {};
  const adminID = authReducerData.userData?.user?._id || '';
  const logoutFunction = () => {
    localStorage.clear();
    window.location.reload();
  };
  const clearSessions = () => {
    localStorage.setItem('lastConnection', new Date().toISOString());
    localStorage.removeItem('persist:root');
    window.location.reload();
  };
  function isValidDateFormat(dateString) {
    const dateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{1,3})?Z?$/;

    return dateRegex.test(dateString);
  }
  const fetchWithListedAdmin = () => {
    const lastConnection = localStorage.getItem('lastConnection');
    if (
      !lastConnection ||
      lastConnection == null ||
      !isValidDateFormat(lastConnection)
    ) {
      clearSessions();
    }
    getAdminWithList()
      .then(({ data }) => {
        const date1 = data?.adminWhiteList?.clearSession;
        if (adminID && data?.adminWhiteList?.enable) {
          if (date1 > lastConnection) {
            logoutFunction();
          }
          const ifAdminList =
            (data?.adminWhiteList?.witheList || []).find((x) => x == adminID) ||
            (authReducerData.userData?.user?.scope || []).find(
              (x) => x == 'superAdmin',
            );
          setIfAdminWithListed(ifAdminList);
        } else {
          setIfAdminWithListed(true);
        }
        // setAdmin(data);
      })
      .catch((err) => console.log(err));
  };
  const fetchAdminPermission = () => {
    const ifSupreAdmin = (authReducerData.userData?.user?.scope || []).find(
      (x) => x == 'superAdmin',
    );

    ifSupreAdmin
      ? setNav(['all'])
      : authReducerData.userData?.user?._id &&
        getAdminPermission(authReducerData.userData?.user?._id)
          .then(({ data }) => {
            if (
              window.location.pathname !== '/admin' &&
              window.location.pathname !== '/dashboard-store'
            ) {
              if (
                data &&
                (data?.adminPermissions || []).includes(
                  window.location.pathname,
                )
              ) {
                setNav(data?.adminPermissions);
              } else {
                setNav(data?.adminPermissions);
                window.history.pushState({}, '', '/admin');
                const newURL =
                  window.location.protocol +
                  '//' +
                  window.location.host +
                  '/admin';
                window.location.href = newURL;
              }
            } else {
              setNav(data?.adminPermissions);
            }
          })
          .catch((err) => {
            console.log(err);
            setIfAdminWithListed(false);
          });
  };
  useEffect(() => {
    fetchWithListedAdmin();
    fetchAdminPermission();
    return () => {};
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AlertProvider template={AlertTemplate} {...alertConfig}>
          {ifAdminWithListed ? (
            <BrowserRouter>
              <Switch>
                <AuthRoute type="guest" path="/login">
                  <LoginFormComponent />
                </AuthRoute>
                <AuthRoute type="private" path="/">
                  <Admin nav={nav} />
                </AuthRoute>
                <AuthRoute type="private" path="/dashboard-store">
                  <Admin nav={nav} />
                </AuthRoute>
                <AuthRoute type="private" path="/setting">
                  <Admin nav={nav} />
                </AuthRoute>
              </Switch>
            </BrowserRouter>
          ) : (
            // <BrowserRouter>
            //   <Switch>
            //     {/* If clear is true, render the LoginFormComponent */}
            //     {clearSession ? (
            //       <AuthRoute type="guest" path="/login">
            //         <LoginFormComponent />
            //       </AuthRoute>
            //     ) : (
            //       /* If clear is false, render the Admin component */
            //       <>
            //         <AuthRoute type="private" path="/">
            //           <Admin />
            //         </AuthRoute>
            //         <AuthRoute type="private" path="/dashboard-store">
            //           <Admin />
            //         </AuthRoute>
            //         <AuthRoute type="private" path="/setting">
            //           <Admin />
            //         </AuthRoute>
            //       </>
            //     )}
            //   </Switch>
            // </BrowserRouter>
            ''
          )}
        </AlertProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
