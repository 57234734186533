import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export default function napsPayloadComponent({ payload }) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">idcommande</TableCell>
            <TableCell align="center">montant</TableCell>
            <TableCell align="center">repauto</TableCell>
            <TableCell align="center">numCarte</TableCell>
            <TableCell align="center">typecarte</TableCell>
            <TableCell align="center">numAuto</TableCell>
            <TableCell align="center">token</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell align="center">
              {payload?.id_commande}
            </TableCell>
            <TableCell align="center">
              {payload?.montant}
            </TableCell>
            <TableCell align="center">
              {payload?.repauto}
            </TableCell>
            <TableCell align="center">
              {payload?.numCarte}
            </TableCell>
            <TableCell align="center">
              {payload?.typecarte}
            </TableCell>
            <TableCell align="center">
              {payload?.numAuto}
            </TableCell>
            <TableCell align="center">
              {payload?.token||'--'}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}
