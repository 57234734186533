import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
// import TableCell from '@material-ui/core/TableCell';
// import TableHead from '@material-ui/core/TableHead';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { Box, Button } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import ModalComponent from '../../../components/Modal.component';
import EditWarehoussComponenet from './Edit.warehouss.componenet';
import WarehouseCommissionConfig from './Warehouse.commission.config';
import CustomButton from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import SelectBoxComponent from '../../../components/SelectBox.component';

// Icons
import EditIcon from '@material-ui/icons/EditOutlined';
import SettingsIcon from '@mui/icons-material/Settings';

export default function EditOrganisationComponent({
  item,
  indexOrganisation,
  cities,
  close,
  loadData,
}) {
  //   console.log(item);
  const [openModal, setOpenModal] = useState(null);
  const [itemRow, setItemRow] = useState(null);
  const [selectedCities, setSelectedCities] = useState([]);
  const [indexWarehouss, setIndexWarehouss] = useState(-1);
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      marginTop: theme.spacing(2),
      overflowX: 'auto',
    },
    table: {
      minWidth: 650,
    },
    selectTableCell: {
      width: 60,
    },
    tableCell: {
      width: 130,
      height: 40,
    },
    input: {
      width: 130,
      height: 40,
    },
  }));
  const tableHeaderArray = [
    'Name',
    'city',
    'wareHouseId',
    'wareHouseLabel',
    'contact',
    'lat',
    'lng',
    'edit',
    'Commission config',
  ];
  const handleModal = (item, index, type) => {
    // handleCloseModal();
    setItemRow(item);
    setIndexWarehouss(index);
    setOpenModal(type);
  };
  const handleCloseModal = () => {
    setOpenModal('');
  };
  const classes = useStyles();
  return (
    <div>
      <Box textAlign="right">
        <CustomButton
          round
          type="submit"
          color="success"
          loaderColor="white"
          onClick={() => handleModal('', '', 'editWarehouss')}
        >
          add warehouse
        </CustomButton>
      </Box>
      <Paper
        className={classes.root}
        sx={{ width: '100%', overflow: 'hidden' }}
      >
        <SelectBoxComponent
          items={cities.map((item) => ({
            value: item._id,
            label: item.name,
          }))}
          inputLabel="filter by cities"
          onValueChange={(items) => {
            setSelectedCities(items);
          }}
          value={selectedCities}
          multiple
        />
        <Table
          className={classes.table}
          stickyHeader
          aria-label="sticky table"
          sx={{ maxHeight: 440 }}
        >
          <TableHead>
            <TableRow>
              {tableHeaderArray.map((item) => (
                <TableCell align="left">{item}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {(item?.warehouses || [])
              .filter((x) =>
                selectedCities.length
                  ? selectedCities.includes(x.cityId)
                  : true,
              )
              .map((row, index) => (
                <TableRow key={row._id}>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>
                    {cities.find((x) => x._id == row.cityId)?.name}
                  </TableCell>
                  <TableCell>{row?.wareHouseId}</TableCell>
                  <TableCell>{row.wareHouseLabel}</TableCell>
                  <TableCell>{row.contact}</TableCell>
                  <TableCell>
                    {row.location && row.location.length > 0 && row.location[0]}
                  </TableCell>
                  <TableCell>
                    {row.location && row.location.length > 0 && row.location[1]}
                  </TableCell>
                  <TableCell>
                    <EditIcon
                      style={{ color: '#4caf50' }}
                      onClick={() => handleModal(row, index, 'editWarehouss')}
                    />
                  </TableCell>
                  <TableCell>
                    <div>
                      <Button
                        color="primary"
                        onClick={() => {
                          handleModal(row, index, 'commiConfigClient');
                        }}
                      >
                        WareHouse/Client
                      </Button>
                      <Button
                        color="secondary"
                        onClick={() => {
                          handleModal(row, index, 'commiConfigDeliveryMan');
                        }}
                      >
                        WareHouse/DeliveryMan
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Paper>
      {openModal && openModal == 'editWarehouss' && (
        <ModalComponent
          maxWidth={'md'}
          close={handleCloseModal}
          visible={openModal === 'editWarehouss'}
          title="edit"
        >
          <EditWarehoussComponenet
            item={itemRow}
            indexOrganisation={indexOrganisation}
            cities={cities}
            organisation={item}
            indexWarehouss={indexWarehouss}
            close={handleCloseModal}
            loadData={loadData}
          />
        </ModalComponent>
      )}
      {openModal &&
        (openModal == 'commiConfigClient' ||
          openModal == 'commiConfigDeliveryMan') && (
          <ModalComponent
            maxWidth={'md'}
            close={handleCloseModal}
            visible={true}
            title={
              openModal == 'commiConfigClient'
                ? 'commission Client'
                : 'commission DeliveryMan'
            }
          >
            <WarehouseCommissionConfig
              item={itemRow}
              indexOrganisation={indexOrganisation}
              cities={cities}
              organisation={item}
              indexWarehouss={indexWarehouss}
              close={handleCloseModal}
              loadData={loadData}
              commissionType={
                openModal == 'commiConfigClient'
                  ? 'commissionWareHouseClient'
                  : 'commissionWareHouseDeliveryMan'
              }
              otherCommissionType={
                openModal == 'commiConfigClient'
                  ? 'commissionWareHouseDeliveryMan'
                  : 'commissionWareHouseClient'
              }
            />
          </ModalComponent>
        )}
    </div>
  );
}
