import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';
import CustomInput from '../../../components/material-dashboard-pro-react/CustomInput/CustomInput';
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import {
  getDistancePartner,
  setDistancePartner,
} from '../services/serverApi.service';
import { useAlert } from 'react-alert';

const validationSchema = yup.object({
  distanceForBlockArrivedToDestination: yup.number().required(),
  distanceForBlockDelivred: yup.number().required(),
});

export default function ConfigDistancePartner() {
  const [isLoading, setIsLoading] = useState(false);
  const alert = useAlert();

  const formik = useFormik({
    initialValues: {
      distanceForBlockArrivedToDestination: '',
      distanceForBlockDelivred: '',
    },
    validationSchema,
  });
  const fetchConfigContent = () => {
    getDistancePartner()
      .then(({ data }) => {
        putSearchLimit(data?.distanceCnfig);
      })
      .catch((err) => console.log(err));
  };
  const saveValueSearchLimit = () => {
    setDistancePartner(formik.values)
      .then(({ data }) => {
        putSearchLimit(data?.distanceDb);
        alert.show('updated', {
          type: 'success',
        });
        // formik.resetForm();
        //  console.log(data)
      })
      .catch((error) => console.log(error));
  };
  const putSearchLimit = (data) => {
    formik.setValues({
      distanceForBlockArrivedToDestination:
        data?.distanceForBlockArrivedToDestination,
      distanceForBlockDelivred: data?.distanceForBlockDelivred,
    });
  };
  useEffect(() => {
    fetchConfigContent();
  }, []);

  return (
    <div>
      <Box>
        <CustomInput
          labelText="distanceForBlockArrivedToDestination (par m)"
          formControlProps={{
            fullWidth: true,
          }}
          type="text"
          error={
            formik.touched.distanceForBlockArrivedToDestination &&
            Boolean(formik.errors.distanceForBlockArrivedToDestination)
          }
          inputProps={{
            value: formik.values.distanceForBlockArrivedToDestination,
            onBlur: formik.handleBlur('distanceForBlockArrivedToDestination'),
            onChange: formik.handleChange(
              'distanceForBlockArrivedToDestination',
            ),
          }}
        />
        <CustomInput
          labelText="distanceForBlockDelivred (par m)"
          formControlProps={{
            fullWidth: true,
          }}
          type="text"
          error={
            formik.touched.distanceForBlockDelivred &&
            Boolean(formik.errors.distanceForBlockDelivred)
          }
          inputProps={{
            value: formik.values.distanceForBlockDelivred,
            onBlur: formik.handleBlur('distanceForBlockDelivred'),
            onChange: formik.handleChange('distanceForBlockDelivred'),
          }}
        />
      </Box>
      <Box textAlign="center">
        <Button
          round
          type="submit"
          color="success"
          disabled={isLoading}
          loading={isLoading}
          loaderColor="white"
          onClick={saveValueSearchLimit}
        >
          save
        </Button>
      </Box>
    </div>
  );
}
