import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useAlert } from 'react-alert';
import TagsInput from 'react-tagsinput';
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import CustomInput from '../../../components/material-dashboard-pro-react/CustomInput/CustomInput';
import {
  createService,
  getCities,
  getServiceCategories,
  getTag,
} from '../services/serverApi.service';
import SelectBoxComponent from '../../../components/SelectBox.component';
import ImageUpload from '../../../components/material-dashboard-pro-react/CustomUpload/ImageUpload';
import { serviceTypes } from '../mocks';
import { uploadImageResetAction } from '../../../redux/uploadRedux/upload.action'


const validationSchema = yup.object({
  name: yup.string().required(),
  type: yup.string().required(),
  iconUrl: yup.string().required(),
  cityList: yup.array().required(),
  serviceCategoryId: yup.string().required(),
  tags: yup.array(),
});

export default function CreateServiceComponent({ onItemCreated,close }) {
  const alert = useAlert();
  const dispatch = useDispatch();
  const upload = useSelector((state) => state.uploadReducer)
  const { iconUrl, error, loading } = upload
  // console.log(iconUrl, error, loading);
  const [isLoading, setIsLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      name: '',
      type: '',
      iconUrl: '',
      cityList: [],
      serviceCategoryId: '',
      tags: "",
    },
    validationSchema,
  });
  // console.log(formik.errors);
  const [tags, setTags] = useState([]);
  const [cities, setCities] = useState([]);
  const [serviceCategories, setServiceCategories] = useState([]);
  const [tagSelected, setTagSelected] = useState('');
  const onCreate = () => {
    console.log(formik.values);
    setIsLoading(true);
    createService(formik.values)
      .then(() => {
        alert.show('Service has been created', {
          type: 'success',
        });
        formik.resetForm();
        close()
        onItemCreated();
        dispatch(uploadImageResetAction())
      })
      .catch((error) => {
        alert.show(error.message, {
          type: 'error',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const onChangeTags = (items) => {
    items[items.length - 1]

    console.log(items);
    formik.setFieldValue('tags', items);
    formik.setFieldValue('iconUrl', iconUrl);
  };



  // const onRemoveImage = () => {
  //   formik.setFieldValue('iconUrl', '');
  // };

  useEffect(() => {
    iconUrl&&formik.setFieldValue('iconUrl',iconUrl)
    getCities()
      .then(({ data }) => {
        setCities(data);
      })
      .catch((error) => {
        alert.show(error.message, {
          type: 'error',
        });
      });

    getServiceCategories()
      // eslint-disable-next-line no-shadow
      .then(({ data }) => {
        setServiceCategories(data);
      })
      .catch((error) => {
        alert.show(error.message, {
          type: 'error',
        });
      });
      getTag()
      // eslint-disable-next-line no-shadow
      .then(({ data }) => {
        console.log('tag',data);
        setTags([{name:'none',_id:'none',tags:[]}].concat(data));
      })
      .catch((error) => {
        alert.show(error.message, {
          type: 'error',
        });
      });
      displayTagsList()
  }, [iconUrl,formik.values.tags,tagSelected]);
  const displayTagsList = ()=>{
    formik.values.tags&&tags.find((item,index)=>item._id==formik.values.tags&&setTagSelected(index));
    formik.setFieldValue('tags', tags[tagSelected]?.tags);

  }

  return (
    <div>
      <Box display="flex" justifyContent="center">
        <ImageUpload
          // onSubmit={onUploadMedia}
          // onRemove={onRemoveImage}
          label="Select icon"
          folder="service"
          avatar
        />
      </Box>
      <CustomInput
        labelText="Name"
        formControlProps={{
          fullWidth: true,
        }}
        type="text"
        error={formik.touched.name && Boolean(formik.errors.name)}
        inputProps={{
          value: formik.values.name,
          onBlur: formik.handleBlur('name'),
          onChange: formik.handleChange('name'),
        }}
      />
      <SelectBoxComponent
        items={cities.map((city) => ({
          value: city._id,
          label: city.name,
        }))}
        inputLabel="City list"
        onValueChange={(items) => {
          formik.setFieldValue('cityList', items);
        }}
        value={formik.values.cityList}
        multiple
      />
      <SelectBoxComponent
        items={serviceTypes.map((item) => ({
          value: item.value,
          label: item.label,
        }))}
        inputLabel="Type"
        onValueChange={formik.handleChange('type')}
        
        value={formik.values.type}
      />
      <SelectBoxComponent
        items={serviceCategories.map((item) => ({
          value: item._id,
          label: item.name,
        }))}
        inputLabel="Service category"
        onValueChange={formik.handleChange('serviceCategoryId')}
        value={formik.values.serviceCategoryId}
      />
      {tags&&<SelectBoxComponent
        items={tags.map((item) => ({
          value: item._id,
          label: item.name,
        }))}
        inputLabel="tag"
        onValueChange={formik.handleChange('tags')}
        value={tags[tagSelected]?.name}
        // value={formik.values.tags}
      />}
      {tags&&tags[tagSelected]?.tags.length>0&&tags[tagSelected].tags.map((item)=>(
        <Button color='twitter' size="sm" round >{item.name}</Button>
        // console.log(item,tagSelected)
      ))}
      {/* <TagsInput
        value={formik.values.tags}
        onChange={onChangeTags}
        onBlur={formik.handleBlur('tags')}
        // inputProps={{
        //   // value: formik.values.tags,
        //   // onBlur: formik.handleBlur('tags'),
        //   onChange: {onChangeTags}
        // }}
        tagProps={{ className: 'react-tagsinput-tag info' }}
        tagDisplayProp="name"
      /> */}
      <Box textAlign="center">
        <Button
          round
          type="submit"
          color="success"
          disabled={!formik.dirty || !formik.isValid || isLoading}
          loading={isLoading}
          loaderColor="white"
          onClick={onCreate}
        >
          Create
        </Button>
      </Box>
    </div>
  );
}

CreateServiceComponent.propTypes = {
  onItemCreated: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
};
