import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import styles from '../assets/jss/material-dashboard-pro-react/customSelectStyle';

const useStyles = makeStyles(styles);

export default function SelectBoxComponent({
  items,
  inputLabel,
  onValueChange,
  value,
  name,
  ...props
}) {
  const classes = useStyles();

  const onChange = (event) => {
    onValueChange(event.target.value);
  };

  return (
    <FormControl fullWidth className={classes.selectFormControl}>
      <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
        {inputLabel}
      </InputLabel>
      <Select
        MenuProps={{
          className: classes.selectMenu,
        }}
        classes={{
          select: classes.select,
        }}
        value={value}
        onChange={onChange}
        name={name}
        {...props}
      >
        <MenuItem
          disabled
          classes={{
            root: classes.selectMenuItem,
          }}
          value={''}
        >
          Select
        </MenuItem>
        {items.map((item) => (
          <MenuItem
            key={item.value}
            classes={{
              root: classes.selectMenuItem,
              selected: classes.selectMenuItemSelected,
            }}
            value={item.value}
          >
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

SelectBoxComponent.propTypes = {
  items: PropTypes.array.isRequired,
  inputLabel: PropTypes.string,
  name: PropTypes.string,
  onValueChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};
