import {takeEvery,takeLatest,put,all,fork} from 'redux-saga/effects'
import axios from 'axios'
// import { useAlert } from 'react-alert';
import { UPLOAD_IMAGE_REQUEST,UPLOAD_IMAGE_RESET } from './upload.constant';
import { uploadImageSuccessAction,uploadImageFailtAction,uploadImageResetAction } from './upload.action';
import { uploadMediaApi } from '../../services/serverApi.service'
import { MICROSERVICE_BASE_URL } from '../../config/backendServer.config';
import { generateRandomString } from '../../helpers';

// const alert = useAlert();

const uploadImageToAPI = async(payload)=>{
    const formData = new FormData();

  formData.append('media', payload.file);
  formData.append('type', payload.folder);
  formData.append('name', generateRandomString(15));
  // console.log(payload);
  const data = axios.post(
    `${MICROSERVICE_BASE_URL.UPLOAD_IMAGE}`,
    formData,
  );
  return data
}
function* uploadImage({payload}){
  // console.log(payload);
    try {
        const uploadImage= {} =  yield uploadImageToAPI(payload)
        yield put(uploadImageSuccessAction(uploadImage.data))
        
      } catch (error) {
        //   console.log('error.response.data');
        yield put(uploadImageFailtAction(error?.response?.data))
        
    
      }
}
function* uploadImageReset(){
    yield put(uploadImageResetAction)
    
}


export default function* watcherSage(){
    yield takeLatest(UPLOAD_IMAGE_REQUEST,uploadImage)
    
      
}