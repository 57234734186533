import { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import loginPageStyles from '../assets/jss/pages/authStyle';

const useStyles = makeStyles(loginPageStyles);

export default function useAuth() {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState({
    message: null,
    isError: false,
  });

  return {
    isLoading,
    setIsLoading,
    classes,
    responseMessage,
    setResponseMessage,
  };
}
