import React,{ useEffect, useState} from 'react'
import {Box} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import {useFormik} from 'formik'
import * as yup from 'yup';
import CustomInput from '../../../components/material-dashboard-pro-react/CustomInput/CustomInput'
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button'
import { getRatingList,setRatingList } from '../services/serverApi.service'
import SwitchComponent from '../../../components/Switch.component';
import SelectBoxComponent from '../../../components/SelectBox.component'
import { selectNumbers } from '../mocks';
const validationSchema = yup.object({
    name: yup.string().required()
  });
export default function deliveryManRatingList() {
    const [dataRatingDelivery, setDataRatingDelivery] = useState({ "deliveryManRatingList": {},"kaalixAppRatingList":{},"storeRatingList":{}});
    const [isLoading,setIsLoading]= useState(false)
    const [appStores,setAppStores]= useState('')
    const [displays,setDisplays]= useState('')
    const [selectNumber,setSelectNumber]= useState('')

   

    const formik = useFormik({
        initialValues: {
            dlvROne: '',
            dlvRTwo: '',
            dlvRThree: '',
            dlvRFour: '',
            dlvRFive: '',
            numberSelected: '',
           
          
          },
          validationSchema,
    })
    const getRating = ()=>{
        getRatingList()
        .then(({ data }) => {
        setDataRatingDelivery({ "deliveryManRatingList": data.ratingList.deliveryManRatingList, "kaalixAppRatingList": data.ratingList.kaalixAppRatingList ,"storeRatingList": data.ratingList.storeRatingList });
        setAppStores(data.ratingList.deliveryManRatingList.appStore)
        setDisplays(data.ratingList.deliveryManRatingList.display)
        setSelectNumber(data.ratingList.deliveryManRatingList.starsNumber);

        formik.setValues({
            numberSelected: data.ratingList.deliveryManRatingList.starsNumber,
           
          })
        })
        .catch((err) => console.log(err));
    }
    const saveRating = (type,val)=>{
     const tempSatate = dataRatingDelivery;
    if(type === 'dlvROne' && formik.values.dlvROne !== ''){
        tempSatate.deliveryManRatingList['1'].push(formik.values.dlvROne );
        setRatingList(tempSatate).then(({ data }) => {
            formik.setFieldValue('dlvROne', '');
            formik.resetForm();
        }
        ).catch((error) => console.log(error));
     
    } else if(type === 'dlvRTwo' && formik.values.dlvRTwo !== ''){
        tempSatate.deliveryManRatingList['2'].push(formik.values.dlvRTwo );
        setRatingList(tempSatate).then(({ data }) => {
            formik.setFieldValue('dlvRTwo', '');
            formik.resetForm();
        }
        ).catch((error) => console.log(error));

    } else if(type === 'dlvRThree' && formik.values.dlvRThree !== ''){
        tempSatate.deliveryManRatingList['3'].push(formik.values.dlvRThree );
        setRatingList(tempSatate).then(({ data }) => {
            formik.setFieldValue('dlvRThree', '');
            formik.resetForm();
        }
        ).catch((error) => console.log(error));
    
    }else if(type === 'dlvRFour' && formik.values.dlvRFour !== ''){
        tempSatate.deliveryManRatingList['4'].push(formik.values.dlvRFour );
        setRatingList(tempSatate).then(({ data }) => {
            formik.setFieldValue('dlvRFour', '');
            formik.resetForm();
        }
        ).catch((error) => console.log(error));

    }else if(type === 'dlvRFive' && formik.values.dlvRFive !== ''){
        tempSatate.deliveryManRatingList['5'].push(formik.values.dlvRFive );
        setRatingList(tempSatate).then(({ data }) => {
            formik.setFieldValue('dlvRFive', '');
            formik.resetForm();
                        

        }
        ).catch((error) => console.log(error));

    }else if(type === 'numberSelected' && formik.values.numberSelected !== ''){
        tempSatate.deliveryManRatingList.starsNumber = formik.values.numberSelected;
         setRatingList(tempSatate).then(({ data }) => {
            formik.setValues({
                numberSelected: formik.values.numberSelected,
         })
         setSelectNumber(formik.values.numberSelected);
            formik.resetForm();          
        
        }
        ).catch((error) => console.log(error));
    
    }else if(type === 'appStores'){
        setAppStores(val)
        tempSatate.deliveryManRatingList.appStore = val;
         setRatingList(tempSatate).then(({ data }) => {
        formik.resetForm();          

        }
        ).catch((error) => console.log(error));
    }else if(type === 'displays'){
        setDisplays(val)
        tempSatate.deliveryManRatingList.display = val;
         setRatingList(tempSatate).then(({ data }) => {
        formik.resetForm();          

        }
        ).catch((error) => console.log(error));
    
    }

    
    }
    useEffect(() => {
        getRating()
    }, []);
    const deleteRating = (index,type) =>{
        const tempSatate = dataRatingDelivery;
   if(type === 'dlvROne' && index !== -1){
       tempSatate.deliveryManRatingList['1'].splice(index,1);
       setRatingList(tempSatate).then(({ data }) => {
              formik.resetForm();
         }
            ).catch((error) => console.log(error));

    } else if(type === 'dlvRTwo' && index !== -1){
        tempSatate.deliveryManRatingList['2'].splice(index,1);
        setRatingList(tempSatate).then(({ data }) => {
            formik.resetForm();
        }
        ).catch((error) => console.log(error));

    } else if(type === 'dlvRThree' && index !== -1){
        tempSatate.deliveryManRatingList['3'].splice(index,1);
        setRatingList(tempSatate).then(({ data }) => {
            formik.resetForm();
        }
        ).catch((error) => console.log(error));
         
    } else if(type === 'dlvRFour' && index !== -1){

        tempSatate.deliveryManRatingList['4'].splice(index,1);
        setRatingList(tempSatate).then(({ data }) => {
            formik.resetForm();
        }
        ).catch((error) => console.log(error));
    }else if(type === 'dlvRFive' && index !== -1){

        tempSatate.deliveryManRatingList['5'].splice(index,1);
        setRatingList(tempSatate).then(({ data }) => {
            formik.resetForm();
        }
        ).catch((error) => console.log(error));
    }
}

    return (
        <div>
        <div className="d-flex justify-content-between">
            <div>
                <h6 className="font-weight-bold">DeliveryManRating 1</h6>
            {(dataRatingDelivery?.deliveryManRatingList['1']|| []).map((item,index)=>(
                <p>
                    <CloseIcon onClick={()=>deleteRating(index,'dlvROne')} fontSize="small" style={{ color: 'red',cursor:'pointer' }}/>
                
                {item}
                    </p>
            ))}

           
            </div>


            <div>
            <h6 className="font-weight-bold">DeliveryManRating 2</h6>
            {(dataRatingDelivery?.deliveryManRatingList['2'] || []).map((item,index)=>(
                <p>
                    <CloseIcon onClick={()=>deleteRating(index,'dlvRTwo')} fontSize="small" style={{ color: 'red',cursor:'pointer' }}/>
                
                {item}
                    </p>
            ))}
           
            </div>
            <div>
            <h6 className="font-weight-bold">DeliveryManRating 3</h6>
            {(dataRatingDelivery?.deliveryManRatingList['3']|| []).map((item,index)=>(
                <p>
                    <CloseIcon onClick={()=>deleteRating(index,'dlvRThree')} fontSize="small" style={{ color: 'red',cursor:'pointer' }}/>
                
                {item}
                    </p>
            ))}
            
            </div>

            </div>

           <div className="d-flex justify-content-between">

             <div >
             <CustomInput
                labelText="Name"
                formControlProps={{
                    fullWidth: true,
                }}
                type="text"
                error={formik.touched.dlvROne && Boolean(formik.errors.dlvROne)}
                inputProps={{
                    value: formik.values.dlvROne,
                    onBlur: formik.handleBlur('dlvROne'),
                    onChange: formik.handleChange('dlvROne'),
                }}
            />
            
            <div>
            <Box textAlign="center">
                <Button
                    round
                    type="submit"
                    color="success"
                    disabled={isLoading}
                    loading={isLoading}
                    size="sm"
                    loaderColor="white"
                    onClick={ ()=>saveRating('dlvROne')}
                >
                    save
                </Button>
            </Box>
        </div>
            </div>
             
            <div >
             <CustomInput
                labelText="Name"
                formControlProps={{
                    fullWidth: true,
                }}
                type="text"
                error={formik.touched.dlvRTwo && Boolean(formik.errors.dlvRTwo)}
                inputProps={{
                    value: formik.values.dlvRTwo,
                    onBlur: formik.handleBlur('dlvRTwo'),
                    onChange: formik.handleChange('dlvRTwo'),
                }}
            />
            
            <div >
            <Box textAlign="center">
                <Button
                    round
                    type="submit"
                    color="success"
                    disabled={isLoading}
                    loading={isLoading}
                    size="sm"
                    loaderColor="white"
                    onClick={ ()=>saveRating('dlvRTwo')}

                >
                    save
                </Button>
            </Box>
        </div>
            </div>
            
            <div >
             <CustomInput
                labelText="Name"
                formControlProps={{
                    fullWidth: true,
                }}
                type="text"
                error={formik.touched.dlvRThree && Boolean(formik.errors.dlvRThree)}
                inputProps={{
                    value: formik.values.dlvRThree,
                    onBlur: formik.handleBlur('dlvRThree'),
                    onChange: formik.handleChange('dlvRThree'),
                }}
            />
            
            <div>
            <Box textAlign="center">
                <Button
                    round
                    type="submit"
                    color="success"
                    disabled={isLoading}
                    loading={isLoading}
                    size="sm"
                    loaderColor="white"
                    onClick={ ()=>saveRating('dlvRThree')}
                >
                    save
                </Button>
            </Box>
        </div>
            </div>
           

            </div>

           {/* start part two   */}
           <div className="d-flex justify-content-between mt-5">
            <div >
                <h6 className="font-weight-bold">DeliveryManRating 4</h6>
            {(dataRatingDelivery?.deliveryManRatingList['4']|| []).map((item,index)=>(
                <p>
                    <CloseIcon onClick={()=>deleteRating(index,'dlvRFour')} fontSize="small" style={{ color: 'red',cursor:'pointer' }}/>
                
                {item}
                    </p>
            ))}

           
            </div>


            <div >
            <h6 className="font-weight-bold">DeliveryManRating 5</h6>
            {(dataRatingDelivery?.deliveryManRatingList['5'] || []).map((item,index)=>(
                <p>
                    <CloseIcon onClick={()=>deleteRating(index,'dlvRFive')} fontSize="small" style={{ color: 'red',cursor:'pointer' }}/>
                
                {item}
                    </p>
            ))}
           
            </div>
            
             
           <div>
           <SwitchComponent
        onChange={(val)=>saveRating('appStores',val)}
        value="checked"
        checked={appStores}
        label={'appStore'}
      />
           <SwitchComponent
        onChange={(val)=>saveRating('displays',val)}
        value="checked"
        checked={displays}
        label={'display'}
      />
           </div>

            </div>




           <div className="d-flex justify-content-between">

             <div >
             <CustomInput
                labelText="Name"
                formControlProps={{
                    fullWidth: true,
                }}
                type="text"
                error={formik.touched.dlvRFour && Boolean(formik.errors.dlvRFour)}
                inputProps={{
                    value: formik.values.dlvRFour,
                    onBlur: formik.handleBlur('dlvRFour'),
                    onChange: formik.handleChange('dlvRFour'),
                }}
            />
            
            <div>
            <Box textAlign="center">
                <Button
                    round
                    type="submit"
                    color="success"
                    disabled={isLoading}
                    loading={isLoading}
                    size="sm"
                    loaderColor="white"
                    onClick={ ()=>saveRating('dlvRFour')}
                >
                    save
                </Button>
            </Box>
        </div>
            </div>
             
            <div >
             <CustomInput
                labelText="Name"
                formControlProps={{
                    fullWidth: true,
                }}
                type="text"
                error={formik.touched.dlvRFive && Boolean(formik.errors.dlvRFive)}
                inputProps={{
                    value: formik.values.dlvRFive,
                    onBlur: formik.handleBlur('dlvRFive'),
                    onChange: formik.handleChange('dlvRFive'),
                }}
            />
            
            <div>
            <Box textAlign="center">
                <Button
                    round
                    type="submit"
                    color="success"
                    disabled={isLoading}
                    loading={isLoading}
                    size="sm"
                    loaderColor="white"
                    onClick={ ()=>saveRating('dlvRFive')}

                >
                    save
                </Button>
            </Box>
        </div>
            </div>
            
          
            <div>
                <span className="font-weight-bold">Start number</span>
            <SelectBoxComponent
        items={selectNumbers.map((type) => ({
          value: type.label,
          label: type.label,
        }))}
        onValueChange={formik.handleChange('numberSelected')}
        value={formik.values.numberSelected !==''?formik.values.numberSelected :selectNumber}
      />
      <Box textAlign="center">
        <Button
          round
          type="submit"
          color="success"
          size="sm"
          loading={isLoading}
          loaderColor="white"
          onClick={()=>saveRating('numberSelected')}
        >
          save 
        </Button>
        </Box>
           </div>
            </div>
        {/* end part button */}
        </div>
    )
}

