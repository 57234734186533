import React, { useEffect, useState } from 'react';
import TablePagination from '@material-ui/core/TablePagination';
import {
  getCities,
  getDispatchingModes,
  logModeDispatching,
  updateCity,
} from '../services/serverApi.service';
import DataTableContainerComponent from '../../../components/DataTableContainer.component';
import CreateCityComponent from '../components/CreateCity.component';
import EditCityComponent from '../components/EditCity.component';
import ViewItemComponent from '../components/ViewCities.component';
import PolygonMapViewComponent from '../components/PolygonMapView.component';
import { Button } from '@material-ui/core';
import ModalComponent from '../../../components/Modal.component';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import { useAlert } from 'react-alert';
import CustomButtons from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import { modes, modesList } from '../../../config/status.config';
import { useSelector } from 'react-redux';
import { getUserDataState } from '../../auth/redux/selectors';
import { DATA } from '../../../utils/logAudit';
import { resetObj } from 'utils/resetObj';

export default function CitiesPage() {
  const alert = useAlert();
  const [resultsData, setResultsData] = useState({
    total: 0,
    items: [],
    tableItems: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const pages = [25, 50, 100];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(pages[page]);
  const [dispatchingMessage, setDispatchingMessage] = useState([]);
  const [city, setCity] = useState(null);
  const [selectDispatchingMessage, setSelectDispatchingMessage] = useState('');
  const [selectDispatchingMode, setSelectDispatchingMode] = useState('');
  const adminData = useSelector(getUserDataState);
  const [admin, setAdmin] = useState({
    _id: adminData.user._id,
    email: adminData.user.email,
    username: adminData.user.username,
  });
  const [previous, setPrevious] = useState('');

  // console.log(adminData);

  const loadCitiesList = () => {
    setIsLoading(true);
    getCities(page, rowsPerPage, true)
      .then(({ data }) => {
        console.log(data);
        setResultsData({
          total: data.total,
          items: data.cities,
          tableItems: data.cities.map((item) => [
            // eslint-disable-next-line no-underscore-dangle
            item._id,
            item.name,
            item.timeZone,
            item.country?.name,
            <Button
              onClick={() => {
                setSelectDispatchingMessage(
                  item?.dispatchingMessage?._id || '',
                );
                setSelectDispatchingMode(item.dispatchingMode || '');
                handleOpenModal(item);
              }}
            >
              {item?.dispatchingMode || '+'}
            </Button>,
            item?.paymentGateway
              ? item?.paymentGateway[0]?.enabled
                ? 'YES'
                : 'NO'
              : '--',
            item?.paymentGateway
              ? item?.paymentGateway[1]?.enabled
                ? 'YES'
                : 'NO'
              : '--',
            item?.paymentGateway
              ? item?.paymentGateway[2]?.enabled
                ? 'YES'
                : 'NO'
              : '--',
            item?.paymentGateway
              ? item?.paymentGateway[3]?.enabled
                ? 'YES'
                : 'NO'
              : '--',
            item?.MAS ? 'YES' : 'NO',
            item?.displayPhone,
            item?.partners,
          ]),
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  useEffect(() => {
    loadCitiesList();
    get_Dispatching_Modes();
  }, [page, rowsPerPage]);

  const [openModal, setOpenModal] = useState('');
  const handleOpenModal = (city) => {
    setCity(city);
    setOpenModal('modes');
  };
  const closeModal = () => {
    setOpenModal('');
  };
  const get_Dispatching_Modes = () => {
    getDispatchingModes()
      .then(({ data }) => {
        setDispatchingMessage(data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const handleDispatchingModesMessage = (event) => {
    setSelectDispatchingMessage(event.target.value);
  };
  const handleDispatchingMode = (event) => {
    // console.log(city.dispatchingMode);
    setPrevious(city.dispatchingMode);
    setSelectDispatchingMode(event.target.value);
  };

  // console.log(previous);

  const onEdit = () => {
    // edit city :
    setIsLoading(true);
    let newCity = city;
    const newDispatchingMessage = dispatchingMessage.find(
      (item) => item._id === selectDispatchingMessage,
    );
    newCity.dispatchingMessage = newDispatchingMessage;
    newCity.dispatchingMode = selectDispatchingMode;
    const { _id, ...values } = newCity;
    // set logger function here :
    // reset object before setting data :
    resetObj(DATA);
    // for logger audit data :
    DATA.admin = admin;
    DATA.actionType = 'Mode dispatching';
    DATA.targetId = newCity._id;
    DATA.target = 'Delivery-Info';
    DATA.loggerDetails = {
      beforeValue: previous,
    };
    // DATA.beforeValue = previous;
    // console.log(DATA);
    // our post here :
    logModeDispatching(DATA)
      .then(({ data }) => {
        console.log(data);
      })
      .catch((error) => {
        console.log(error.message, {
          type: 'error',
        });
      });
    console.log(DATA);
    updateCity(_id, values)
      .then(() => {
        alert.show('City has been edited', {
          type: 'success',
        });
        loadCitiesList();
      })
      .catch((error) => {
        alert.show(error.message, {
          type: 'error',
        });
      })
      .finally(() => {
        setIsLoading(false);
        closeModal();
        loadCitiesList();
      });
  };
  return (
    <>
      {/*<TablePagination*/}
      {/*  component="div"*/}
      {/*  page={page}*/}
      {/*  rowsPerPageOptions={pages}*/}
      {/*  rowsPerPage={rowsPerPage}*/}
      {/*  count={resultsData.total}*/}
      {/*  onPageChange={handleChangePage}*/}
      {/*  onRowsPerPageChange={handleChangeRowsPerPage}*/}
      {/*/>*/}
      <DataTableContainerComponent
        resultsData={resultsData}
        tableHead={[
          'Name',
          'TimeZone',
          'Country',
          'mode',
          'Cash',
          'Card',
          'KaalixUP',
          'KaalixPay',
          'MAS',
          'displayPhone',
          'partners',
          'Actions',
        ]}
        title="Cities"
        isLoading={isLoading}
        loadItemsList={loadCitiesList}
        CreateItemFormComponent={CreateCityComponent}
        actionButtons={{
          map: {
            color: 'info',
            type: 'map',
            width: 'lg',
            component: PolygonMapViewComponent,
          },
          view: {
            color: 'info',
            type: 'view',
            component: ViewItemComponent,
          },
          edit: {
            color: 'success',
            type: 'edit',
            advanceEdit: true,
            props: { dispatchingMessage: dispatchingMessage },
            component: EditCityComponent,
          },
          // delete: { color: 'danger', type: 'delete' },
        }}
      />
      {openModal && (
        <ModalComponent
          maxWidth={'md'}
          close={closeModal}
          visible={openModal === 'modes'}
          title="Dispatching Message"
          actions={
            <div className="row float-right p-2">
              <CustomButtons
                color="success"
                loaderColor="white"
                onClick={onEdit}
              >
                Update
              </CustomButtons>
            </div>
          }
        >
          <div className={'p-3'} style={{}}>
            <div className="row">
              <h5>{city.name}</h5>
            </div>
            <div
              className="row"
              style={{
                border: '1px solid #e8e8e8',
                margin: '5px 0px',
                padding: '5px',
              }}
            >
              <FormControl component="fieldset">
                <FormLabel component="legend">modes</FormLabel>
                <RadioGroup
                  aria-label="gender"
                  name="gender1"
                  value={selectDispatchingMode}
                  onChange={handleDispatchingMode}
                  row
                >
                  {modesList.map((item, index) => (
                    <FormControlLabel
                      value={item}
                      control={<Radio />}
                      label={item}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </div>
            <div
              className="row"
              style={{
                border: '1px solid #e8e8e8',
                margin: '5px 0px',
                padding: '5px',
              }}
            >
              <FormControl component="fieldset">
                <FormLabel component="legend">Affichage</FormLabel>
                <RadioGroup
                  aria-label="gender"
                  name="gender1"
                  value={selectDispatchingMessage}
                  onChange={handleDispatchingModesMessage}
                  row
                >
                  {dispatchingMessage.map((item) => (
                    <FormControlLabel
                      value={item._id + ''}
                      control={<Radio />}
                      label={item.name}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </div>
          </div>
        </ModalComponent>
      )}
    </>
  );
}
