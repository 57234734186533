import React, { useEffect, useState } from 'react';
import {
  Grid,
  Box,
  Switch,
  CircularProgress,
  FormControl,
} from '@mui/material';
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import SelectBoxComponent from '../../../components/SelectBox.component';
import {
  editConfigCallBackS,
  getConfigCallBackS,
} from '../services/server.api.service';
import { timeOutInterval, maxRetry } from '../mock';

export default function ConfigOrganisationComponent({ organisationId, close }) {
  const [change, setChange] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [callbackConfig, setCallbackConfig] = useState({});

  const editConfig = (key, subKey, value) => {
    if (key) {
      callbackConfig.config[key][subKey] = value;
    } else {
      callbackConfig.API_KEY = value;
    }
    setCallbackConfig(callbackConfig);
    setChange(!change);
  };
  const editConfigAppId = (key, subKey, value) => {
    if (key) {
      callbackConfig.config[key][subKey] = value;
    } else {
      callbackConfig.APP_ID = value;
    }
    setCallbackConfig(callbackConfig);
    setChange(!change);
  };
  const onEdit = () => {
    setIsLoading(true);
    editConfigCallBackS(callbackConfig)
      .then(({ data }) => {
        setIsLoading(false);
        close();
      })
      .catch((err) => console.log(err));
  };
  const getConfigForOrganisation = (id) => {
    getConfigCallBackS(id)
      .then(({ data }) => {
        setCallbackConfig(data.callbackConfig);
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    organisationId && getConfigForOrganisation(organisationId);
  }, [organisationId]);
  return (
    <div>
      {callbackConfig && Object.keys(callbackConfig).length > 0 ? (
        <div>
          API_KEY
          <input
            type="text"
            className="form-control mb-2"
            placeholder="API_KEY"
            defaultValue={callbackConfig.API_KEY || ''}
            onChange={(e) => {
              editConfig('', '', e.target.value);
            }}
          />
          APP_ID
          <input
            type="text"
            className="form-control mb-2"
            placeholder="APP_ID"
            defaultValue={callbackConfig.APP_ID || ''}
            onChange={(e) => {
              editConfigAppId('', '', e.target.value);
            }}
          />
          <Grid container spacing={2}>
            <Grid item xs={2}>
              name
            </Grid>
            <Grid item xs={6}>
              url
            </Grid>
            <Grid item xs={1.5}>
              timeOut
            </Grid>
            <Grid item xs={1.5}>
              maxRetry
            </Grid>
            <Grid item xs={1}>
              enabled
            </Grid>
          </Grid>
          {callbackConfig?.config &&
            Object.keys(callbackConfig.config).length > 0 &&
            Object.keys(callbackConfig.config).map((item) => (
              <Grid container spacing={2} className="mb-2">
                <Grid item xs={2}>
                  {item}
                </Grid>
                <Grid item xs={6}>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="endPoint"
                    defaultValue={callbackConfig?.config[item]?.endPoint || ''}
                    onChange={(e) => {
                      editConfig(item, 'endPoint', e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={1.5}>
                  <FormControl style={{ marginTop: -22 }}>
                    <SelectBoxComponent
                      items={(timeOutInterval || []).map((time) => ({
                        value: time.value,
                        label: time.label,
                      }))}
                      inputLabel=""
                      onValueChange={(value) =>
                        editConfig(item, 'timeout', value)
                      }
                      value={callbackConfig?.config[item]?.timeout || ''}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={1.5}>
                  <FormControl style={{ marginTop: -22 }}>
                    <SelectBoxComponent
                      items={(maxRetry || []).map((element) => ({
                        value: element,
                        label: element,
                      }))}
                      inputLabel=""
                      onValueChange={(value) =>
                        editConfig(item, 'maxRetry', value)
                      }
                      value={callbackConfig?.config[item]?.maxRetry || ''}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={1}>
                  <Switch
                    onChange={(value) =>
                      editConfig(item, 'enabled', value.target.checked)
                    }
                    checked={callbackConfig?.config[item].enabled}
                    inputProps={{ 'aria-label': 'controlled' }}
                    label="enabled"
                  />
                </Grid>
              </Grid>
            ))}
          <Box textAlign="center">
            <Button
              round
              type="submit"
              color="success"
              loading={isLoading}
              disabled={isLoading}
              loaderColor="white"
              onClick={onEdit}
            >
              Save
            </Button>
          </Box>
        </div>
      ) : (
        <Box textAlign="center">
          <CircularProgress />
        </Box>
      )}
    </div>
  );
}
