import React, { useState, useEffect , useRef } from 'react'
import { useAlert } from 'react-alert';
import { Box, Grid  } from '@material-ui/core';
import {getCommissionHistory, getApprouvedProvider, getProviderDetails} from '../service/serverAPI.service';
import DataTableContainerComponent from '../../../components/DataTableSortingContainer.component';
import { formatTime } from '../../../helpers/index';
import CustomButton from '../../../components/material-dashboard-pro-react/CustomButtons/Button'
import AsyncSelect from 'react-select/async';
import SelectBoxComponent from '../../../components/SelectBox.component'
import {commissionListTypes} from '../mock'
import { changesMock } from '../../customers/mock'
import {convertIdToShortId} from '../../../helpers'


export default function CommissionHistoryPage() {

    const [resultsData, setResultsData] = useState({
        total: 0,
        items: [],
        tableItems: [],
    });
    const [isLoading, setIsLoading] = useState(false);
    const inpRef = useRef();
    const [commissions, setCommissions] = useState([]);
    const [page, setPage] = useState(0)
    let numberOfRows = [100, 500, 1000];
    const [rowsPerPage, setRowsPerPage] = useState(numberOfRows[0])
    // const [searchText,setSearchText] = useState('a')
    const [total,setTotal] = useState('')
    const alert = useAlert()
    const [deliveryMen, setDeliveryMen] = useState([])
    const [deliveryId,setDeliveryId] = useState('')
    const [inputValue,setInputValue] = useState('')
    const [type,setType] = useState('')
    // console.log(page+'page ',rowsPerPage);

    const prepareShowCommissionHistory =(item,index)=>{
        return {
            ...item,
            createdAtCol: item.createdAt ? formatTime(item.createdAt) : '--',
            orderIdCol:item.orderId &&convertIdToShortId(item.orderId),

        };
    }

    const loadCommissionList = (skip, limit,txt,type) => {
      // console.log(txt);
        setIsLoading(true);
        getCommissionHistory(skip, limit,true,txt,type)
            .then(({ data }) => {
                setTotal(data.total)
                setCommissions(data.Commission)
            })
            .catch(error => {
                alert.show((error.response.data||{message:''}).message, {
                    type: 'info',
                }),
                    setResultsData({
                        total: 0,
                        items: [],
                        tableItems: [],
                    })
                    setTotal(1)
            })

            .finally(() => {
                setIsLoading(false);
            });
    };
    let timer1;
    const fetchData = (inputValue, callback) => {
        clearTimeout(timer1);
        timer1 = setTimeout(() => {
        inputValue && getProviderDetails(0,10,'',inputValue)
          .then(({data}) => {
            const tempArray = [];
            if (data.deliveryMan) {
              if (data.deliveryMan.length) {
                data.deliveryMan.forEach((element) => {
                  tempArray.push({
                    label: `${element.name}`,
                    _id: element._id,
                  });
                });
              }
            }
            callback(tempArray);
          })
          .catch((error) => {
            console.log(error);
          });
      }, 1000);
    };

    const onSearchChange = (selectedOption,whoChange) => {
      page > 0 && setPage(0)
    switch (whoChange) {
      case changesMock.changeId:
        if (selectedOption) {

          setDeliveryId(selectedOption._id);
          loadCommissionList(page > 0 ? 0 : page, rowsPerPage,selectedOption._id,type)

        }
        break;
      case changesMock.changeType:
        if (selectedOption) {

          setType(selectedOption == commissionListTypes[0] ? '' : selectedOption);
          loadCommissionList(page > 0 ? 0 : page, rowsPerPage, deliveryId,selectedOption == commissionListTypes[0] ? '' : selectedOption )

        }
        break;

      default:
        break;
    }
      // if (selectedOption) {
      //   // console.log(selectedOption);
      //   // setDeliveryId(selectedOption._id);
      //   // page>0&&setPage(0)
      //   loadCommissionList(page>0?0:page, rowsPerPage,selectedOption._id)
      // }
    };
    const SearchComponent = () => {
        return (
          <Grid container alignItems="center" spacing={2}>
          <Grid item xs={5}>
            <AsyncSelect
            single
            value={inputValue}
            loadOptions={fetchData}
            placeholder="find deliveryMan"
            onChange={(e) => {
              onSearchChange(e,changesMock.changeId);
            }}
            />
            </Grid>
            <Grid item xs={5} className="pb-3">
          <SelectBoxComponent
            items={commissionListTypes.map((type) => ({
              value: type,
              label: type,
            }))}
            inputLabel="Type"
            onValueChange={(item) => onSearchChange(item, changesMock.changeType)}
            value={type}
          />
        </Grid>
            <Grid item xs={2} container justify="flex-end"
      >
            <Box textAlign="left">
              <CustomButton
                round
                type="submit"
                color="google"
                size="sm"
                loaderColor="white"
                onClick={() => {
                  setDeliveryId('')
                  setType('')
                  page>0&&setPage(0)
                  loadCommissionList(page>0?0:page, rowsPerPage,"","")
                }}
              >
                clear
              </CustomButton>
            </Box>
            </Grid>
          </Grid>
        )
      }
    const handlePaginate = (val) => {
        setResultsData({
          items: [],
          tableItems: [],
        });
        loadCommissionList(val.pageSelect,val.numberOfRows,deliveryId,type);
        setPage(val.pageSelect);
        setRowsPerPage(val.numberOfRows);
      };
    useEffect(() => {
        commissions && commissions.length > 0 && setResultsData({
          items: commissions.map((item, index) => {
            return prepareShowCommissionHistory(item, index);
          }),
        })
      }, [commissions])
    return (
        <>
            <DataTableContainerComponent
                total={total}
                resultsData={resultsData}
                initData={resultsData.items}
                page={page+1}
                columns={[

                    {
                        Header: 'order Id',
                        accessor: 'orderIdCol',
                    },
                    {
                        Header: 'Name',
                        accessor: 'deliveryManDetail.name',
                    },
                    {
                      Header: 'commission Before',
                      accessor: 'commissionBefore',
                    },
                    {
                        Header: 'amount',
                        accessor: 'amount',
                    },
                    {
                        Header: 'commission After',
                        accessor: 'commissionAfter',
                    },

                    {
                        Header: 'tips',
                        accessor: 'deliveryManDetail.tips',
                    },
                    {
                        Header: 'type',
                        accessor: 'type',
                    },
                    {
                        Header: 'createdAt',
                        accessor: 'createdAtCol',
                    },


                ]}
                title="Commission History List"
                isLoading={isLoading}
                loadItemsList={loadCommissionList}
                onPaginate={handlePaginate}
                isPaginate={true}
                searchInp={true}
                SearchComponent={SearchComponent}
            />
        </>
    );
}
