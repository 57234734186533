import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useAlert } from 'react-alert';
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import CustomInput from '../../../components/material-dashboard-pro-react/CustomInput/CustomInput';
import { updateCountry } from '../services/serverApi.service';

const validationSchema = yup.object({
  name: yup.string().required(),
  currency: yup.string().required(),
  _id: yup.string().required(),
});

export default function EditCountryComponent({ data, onItemEdited }) {
  // console.log(data);
  const alert = useAlert();
  const [isLoading, setIsLoading] = useState(false);
  // console.log(alert,setIsLoading,updateCountry,onItemEdited);
  // const [info, setInfo] = useState({
  //   name: "",
  //   currency: "",
  //   _id: "",
  // });
  // // setName(data[1])
  // console.log(info);
  const formik = useFormik({
    initialValues: {
      name: '',
      currency: '',
      _id: '',
    },
    validationSchema,
  });

  const onEdit = () => {
    console.log(formik.values);
    setIsLoading(true);

    updateCountry(formik.values)
      .then(() => {
        alert.show('Country has been edited', {
          type: 'success',
          position:'top right'
        });
        onItemEdited();
      })
      .catch((error) => {
        alert.show(error.message, {
          type: 'error',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (data) {
      // setInfo({
      //   name:data[1],
      //   currency:data[2],
      //   _id:data[0],
        
      // })
      formik.setValues({
        name:data[1],
        currency:data[2],
        _id:data[0],
      });
    }
  }, [data]);
  return (
    <div>
      {data &&
        <>

          <CustomInput
            labelText="Name"
            formControlProps={{
              fullWidth: true,
            }}
            type="text"
            error={formik.touched.name && Boolean(formik.errors.name)}
            inputProps={{

              value: formik.values.name,
              onBlur: formik.handleBlur('name'),
              // onChange: e=>setInfo({name:e.target.value,_id:data[0]}),
              onChange: formik.handleChange('name'),
            }}
          />
          <CustomInput
            labelText="Currency"
            formControlProps={{
              fullWidth: true,
            }}
            type="text"
            error={formik.touched.currency && Boolean(formik.errors.currency)}
            inputProps={{
              value: formik.values.currency,
              onBlur: formik.handleBlur('currency'),
              // onChange: e=>setInfo({currency:e.target.value}),
              onChange: formik.handleChange('currency'),
            }}
          />
          <Box textAlign="center">
            <Button
              round
              type="submit"
              color="success"
              disabled={ !formik.isValid || isLoading}
              loading={isLoading}
              loaderColor="white"
              onClick={onEdit}
            >
              Edit
            </Button>
          </Box>
        </>
      }
    </div>
  );
}

EditCountryComponent.propTypes = {
  data: PropTypes.any,
  onItemEdited: PropTypes.func.isRequired,
};
