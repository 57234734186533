import React from 'react';
import ButtonMat from '@mui/material/Button';
import { switchForPaymentMethod } from '../mock';

export default function orderPaymentColComponent({ payment }) {
  return (
    <div>
      {(payment?.payments || []).map(
        (mode) =>
          mode.amount > 0 &&
          (mode.gateway == 'Cash' ? (
            <p>{mode.gateway}</p>
          ) : (
            <ButtonMat
              variant={'contained'}
              className="mt-1"
              style={{
                maxWidth: '70px',
                maxHeight: '40px',
                minWidth: '70px',
                minHeight: '30px',
                fontSize: '10px',
              }}
              color={switchForPaymentMethod(mode.gateway)}
              onClick={() => {
                mode.gateway == 'Card' &&
                  payment?.napsPayload &&
                  handleModal(item, index, 'napsPayload');
              }}
            >
              {mode.gateway}
              {mode.gateway == 'Card' ? (
                <>
                  <br></br>
                  <p>{payment?.napsPayload ? '' : 'Error'}</p>
                </>
              ) : (
                <p></p>
              )}
            </ButtonMat>
          )),
      )}
    </div>
  );
}
