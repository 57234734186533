import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getUserDataState } from '../../auth/redux/selectors';
import { getModelDataState, getOrdersDataState } from '../redux/selectors';
import { closeModelAction } from '../../orders/redux/actions/order.actions';

import ModalComponent from '../../../components/Modal.component';

// component
import AssignOrdersComponent from './Assign.orderV2.component';
import ChangeStatusComponent from './Change.status.component';
import CancelOrderComponent from './order.cancel.component';
import DetailOrderComponent from './OrderDetailV2.component';
import ValidateBlockedOrderComponent from './Validate.blocked.component';
// import MapDeliveryComponent from './Map.delivery.component';
// import TrackingDeliveryMen from './Tracking.deliveryMen.component';
// import DeliveryReportComponent from './delivery.report.component';
// import CommentDeliveryComponent from './Comment.delivery.component';
// import AlarmsComponenet from './Alarms.componenet';
// import DisplayPositionsMapFromSocketUpdate from './DisplayPositionsMapFromSocketUpdate';
// import DisplayPositionsMap from './DisplayPositionsMap';
import { fetchSetting } from '../../setting/services/serverApi.service';

export default function ModalComponentsDelivries() {
  const dispatch = useDispatch();
  const [cancel, setCancel] = useState([]);
  const [openAlarm, setOpenAlarm] = useState(true);
  const { model, itemId, index } = useSelector(getModelDataState);
  const { orders, loading, error } = useSelector(getOrdersDataState);
  const userData = useSelector(getUserDataState);
  const [admin, setAdmin] = useState({
    _id: userData.user._id,
    email: userData.user.email,
    userName: userData.user.username,
  });
  const fetchSettingFromAPI = () => {
    fetchSetting()
      .then(({ data }) => {
        data && data.length && setCancel(data[0].admin_cancel_reasons);
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    fetchSettingFromAPI();
  }, []);
  return (
    <div>
      {model && model == 'Assign' && (
        <ModalComponent
          maxWidth={'lg'}
          close={() => dispatch(closeModelAction())}
          visible={model == 'Assign'}
          title="Assign"
        >
          <AssignOrdersComponent
            orderId={itemId}
            orders={orders}
            admin={admin}
            close={() => dispatch(closeModelAction())}
          />
        </ModalComponent>
      )}
      {model && model == 'detail' && (
        <ModalComponent
          maxWidth={'lg'}
          close={() => dispatch(closeModelAction())}
          visible={model == 'detail'}
          title="detail delivery"
        >
          <DetailOrderComponent
            orderId={itemId}
            orders={orders}
            close={() => dispatch(closeModelAction())}
          />
        </ModalComponent>
      )}
      {model && model == 'cancel' && (
        <ModalComponent
          maxWidth={'sm'}
          close={() => dispatch(closeModelAction())}
          visible={model == 'cancel'}
          title="Cancel deliverie"
        >
          <CancelOrderComponent
            orderId={itemId}
            orders={orders}
            cancel={cancel}
            admin={admin}
            close={() => dispatch(closeModelAction())}
          />
        </ModalComponent>
      )}
      {model && model == 'changeStatus' && (
        <ModalComponent
          maxWidth={'sm'}
          close={() => dispatch(closeModelAction())}
          visible={model == 'changeStatus'}
          title="Change Status"
        >
          <ChangeStatusComponent
            orderId={itemId}
            orders={orders}
            admin={admin}
            close={() => dispatch(closeModelAction())}
          />
        </ModalComponent>
      )}
      {model && model == 'blocked' && (
        <ModalComponent
          maxWidth={'sm'}
          close={() => dispatch(closeModelAction())}
          visible={model == 'blocked'}
          title="Change Status"
        >
          <ValidateBlockedOrderComponent
            orderId={itemId}
            orders={orders}
            admin={admin}
            close={() => dispatch(closeModelAction())}
          />
        </ModalComponent>
      )}
      {/* 
      
      {model && model == 'Map' && (
        <ModalComponent
          maxWidth={'lg'}
          close={() => dispatch(closeModelAction())}
          visible={model == 'Map'}
          title="Map for delivery"
        >
          <MapDeliveryComponent
            deliverieId={itemId}
            orders={orders}
            admin={admin}
            cancel={cancel}
            close={() => dispatch(closeModelAction())}
          />
        </ModalComponent>
      )}
      {model && model == 'trackingDeliveryMen' && (
        <ModalComponent
          maxWidth={'lg'}
          close={() => dispatch(closeModelAction())}
          visible={model == 'trackingDeliveryMen'}
          title="Tracking DeliveryMen"
        >
          <TrackingDeliveryMen
            admin={admin}
            cancel={cancel}
            close={() => dispatch(closeModelAction())}
          />
        </ModalComponent>
      )}
      {model && model == 'report' && (
        <ModalComponent
          maxWidth={'lg'}
          close={() => dispatch(closeModelAction())}
          visible={model == 'report'}
          title="Delivery report"
        >
          <DeliveryReportComponent
            deliveryId={itemId}
            close={() => dispatch(closeModelAction())}
          />
        </ModalComponent>
      )}
      {model && model == 'comment' && (
        <ModalComponent
          maxWidth={'md'}
          close={() => dispatch(closeModelAction())}
          visible={model == 'comment'}
          title="Delivery Comments"
        >
          <CommentDeliveryComponent
            deliveryId={itemId}
            admin={admin}
            close={() => dispatch(closeModelAction())}
          />
        </ModalComponent>
      )}
      {model && model == 'Alarm' && (
        <ModalComponent
          maxWidth={'md'}
          close={() => dispatch(closeModelAction())}
          visible={model == 'Alarm' && openAlarm}
          title="Delivery Alarm"
        >
          <AlarmsComponenet
            orderId={itemId}
            setOpenAlarm={setOpenAlarm}
            admin={admin}
          />
        </ModalComponent>
      )}
      {model && model == 'currentPosition' && (
        <ModalComponent
          maxWidth={'md'}
          close={() => dispatch(closeModelAction())}
          visible={model == 'currentPosition'}
          title="Delivery  Position "
        >
          <DisplayPositionsMap
            orderId={itemId}
            close={() => dispatch(closeModelAction())}
            admin={admin}
          />
        </ModalComponent>
      )}
      {model && model == 'my location' && (
        <ModalComponent
          maxWidth={'md'}
          close={() => dispatch(closeModelAction())}
          visible={model == 'my location'}
          title="Delivery  location"
        >
          <DisplayPositionsMapFromSocketUpdate
            orderId={itemId}
            close={() => dispatch(closeModelAction())}
            admin={admin}
          />
        </ModalComponent>
      )} */}
    </div>
  );
}
