import { createStore,combineReducers,
  applyMiddleware } from 'redux';
  import createSagaMiddleware from 'redux-saga'
  import {composeWithDevTools} from 'redux-devtools-extension'
import { persistReducer, persistStore } from 'redux-persist';
import AsyncStorage from '@react-native-community/async-storage';
import rootReducer from './reducers';
// import watcherSage from './saga'
import rootSaga  from './saga'
// import watcherSageMultiUpload from './multiUploadRedux/saga'

const sagaMiddleware = createSagaMiddleware()
const middleware = [sagaMiddleware]


const persistConfig = {
  key: 'root',
  storage: AsyncStorage,
  whitelist: ['authReducer'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// const store = createStore(persistedReducer);
// const reducer = combineReducers({
//   user:userReducer
// })
const store = createStore(persistedReducer,{},composeWithDevTools(applyMiddleware(...middleware)))
sagaMiddleware.run(rootSaga )
const persistor = persistStore(store);

export { store, persistor };
