import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import styles from '../../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle';
import { addPricing, updateStore } from '../services/serverApi.service';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles(styles);

export default function StoreDeliveryPriceComponent({
  pricing,
  store,
  close,
  onItemUpdated,
}) {
  console.log(pricing,
    store,);
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [pricingList, setPricingList] = useState([]);
  const [selectedPricing, setSelectedPricing] = useState(
    // store.deliveryPrice || '',
    (pricing||[]).find(x=>x.name==store?.priceConfig?.name)?._id
  );
// console.log('selectedPricing',selectedPricing);
  useEffect(() => {
    preparePricings();
  }, []);

  const onEdit = async () => {
    setIsLoading(true);
    // store.deliveryPrice = selectedPricing;
    let { _id, ...newStore } = store;
    // console.log(_id, selectedPricing);
      addPricing(_id, selectedPricing)
      .then(({ data }) => {
        console.log('Store has been updated', {
          type: 'success',
        });
      })
      .catch((error) => {
        console.log(error.message, {
          type: 'error',
        });
      })
      .finally(() => {
        setIsLoading(false);
        close();
        //onItemUpdated();
      });
  };
  const preparePricings = async () => {
    let newPricingList = await Array.from(pricing.values()).map((item) => {
      return {
        ...item,
      };
    });
    setPricingList(newPricingList);
  };
  const handleChange = (val) => {
    console.log(val);
    setSelectedPricing(val);
  };
  return (
    <div>
      <Box sx={{ display: 'flex' }} style={{ padding: '15px' }}>
        <FormControl className={classes.formControl} style={{ width: '100%' }}>
          <InputLabel htmlFor="age-native-simple">Delivery Prices</InputLabel>
          <Select
            native
            value={selectedPricing}
            onChange={($event) => {
              handleChange($event.target.value);
            }}
          >
            {pricingList.map((item) => (
              <option value={item._id}>{item.name}</option>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box textAlign="center">
        <Button variant="contained" color="success" onClick={onEdit}>
          Edit
        </Button>
      </Box>
    </div>
  );
}

StoreDeliveryPriceComponent.propTypes = {
  pricing: PropTypes.any.isRequired,
  store: PropTypes.any.isRequired,
  close: PropTypes.func,
};
