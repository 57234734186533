export const columns = [
    {
        Header: 'Id Cmd',
        accessor: 'shortId',
        customWidth: '70px',
    },
    {
        Header: 'Date',
        accessor: 'createdAtInp',
        customWidth: '65px',
    },
    {
        Header: 'Ville',
        accessor: 'cityName',
        customWidth: '60px',
    },
    {
        Header: 'Organisation',
        accessor: 'orderData.code',
        customWidth: '100px',
    },
    {
        Header: 'Warehouse',
        accessor: 'warehouseName',
        customWidth: '100px',
    },
    {
        Header: 'Client',
        accessor: 'clientName',
        customWidth: '100px',
    },
    {
        Header: 'Provider',
        accessor: 'deliveryManInp',
        customWidth: '100px',
    },
    {
        Header: 'status',
        accessor: 'allStatus',
        customWidth: '120px',
    },
    {
        Header: 'DeliveryManSta',
        accessor: 'DeliveryManStatusCell',
        customWidth: '120px',
    },
    {
        Header: 'Total',
        accessor: 'orderPriceCol',
        customWidth: '60px',
    },
    {
        Header: 'Payment',
        accessor: 'paymentMode',
        customWidth: '75px',
    },

    {
        Header: 'duration',
        accessor: 'duration',
        customWidth: '75px',
    },
    {
        Header: 'comment',
        accessor: 'lastComment',
        customWidth: '110px',
    },
    {
        Header: 'Alarm',
        accessor: 'alarmCell',
        customWidth: '110px',
    },
    {
        Header: 'Actions',
        accessor: 'menu',
        customWidth: '100px',
    },
];
export const columnsDispatched = [
    {
        Header: 'Id Cmd',
        accessor: 'shortId',
        customWidth: '70px',
    },
    {
        Header: 'Date',
        accessor: 'createdAtInp',
        customWidth: '65px',
    },
    {
        Header: 'Ville',
        accessor: 'cityName',
        customWidth: '60px',
    },
    {
        Header: 'Organisation',
        accessor: 'orderData.code',
        customWidth: '100px',
    },
    {
        Header: 'Warehouse',
        accessor: 'warehouseName',
        customWidth: '100px',
    },
    {
        Header: 'Client',
        accessor: 'clientName',
        customWidth: '100px',
    },
    {
        Header: 'Provider',
        accessor: 'deliveryManInp',
        customWidth: '100px',
    },
    {
        Header: 'status Time',
        accessor: 'allStatus',
        customWidth: '120px',
    },
    {
        Header: 'status',
        accessor: 'statusPartner',
        customWidth: '120px',
    },
    {
        Header: 'DeliveryManSta',
        accessor: 'DeliveryManStatusCell',
        customWidth: '120px',
    },
    {
        Header: 'Total',
        accessor: 'orderPriceCol',
        customWidth: '60px',
    },
    {
        Header: 'Payment',
        accessor: 'paymentMode',
        customWidth: '75px',
    },

    {
        Header: 'duration',
        accessor: 'duration',
        customWidth: '75px',
    },
    {
        Header: 'comment',
        accessor: 'lastComment',
        customWidth: '110px',
    },
    {
        Header: 'Alarm',
        accessor: 'alarmCell',
        customWidth: '110px',
    },
    {
        Header: 'Actions',
        accessor: 'menu',
        customWidth: '100px',
    },
];
export const DeliveryManStatus = [
    'CREATED',
    'WAITING_FOR_ACCEPT',
    'ACCEPTED',
    'ARRIVED_TO_WAREHOUSE',
    'STARTED_PICKING',
    'END_PICKING',
    'STARTED_DELIVERY',
    'ARRIVED_TO_DESTINATION',
    'DELIVERED',
]
export const deliveriesSearchType = ['wareHouse', 'deliveryMan', 'customer', 'shortId', 'partnerId']
export const allArray = ['all'];
export const partnerStatusBackgroundColor = {
    // ON_HOLD: '#f246f4',
    // INVALID: 'red',
    // WAITING_FOR_ACCEPT: '#ffc107',
    // ACCEPTED: '#ff7986',
    // START_PREPARATION: '#3496ff',

    // COMING_FOR_DELIVERY: '#3496ff',
    // ARRIVED_TO_PICKUP: '#3496ff',
    // UPLOAD_INVOICE: '#46e06a',
    // STARTED_DELIVERY: '#46e06a',
    // ARRIVED_TO_DESTINATION: '#46e06a',
    // DELIVERED: '#46e06a',
    default: '#000',

    END_PREPARATION: '#ff91ae',
    STATUS_CANCELLED: 'red',
    STATUS_DELIVERED: 'rgba(70,224,106,0.55)',
    STATUS_DELIVERING: '#3496ff',
    STATUS_ERROR_PAYMENT: '#f246f4',
    STATUS_FINISHED: '#46e06a',
    STATUS_OPEN_PENDING: 'red',
    STATUS_PREPARING: '#ffc107',
    STATUS_READY: 'rgba(0,207,255,0.35)',
    STATUS_REFUSED: '#f246f4',
    STATUS_VALIDATED: '#46e06a',
}
export const deliveryStatusBackgroundColor = {
    CREATED: '#f246f4',
    WAITING_FOR_ACCEPT: '#ffc107',
    ACCEPTED: '#ff7986',
    ARRIVED_TO_WAREHOUSE: '#3496ff',
    STARTED_PICKING: '#46e06a',
    END_PICKING: '#46e06a',
    STARTED_DELIVERY: '#46e06a',
    ARRIVED_TO_DESTINATION: '#46e06a',
    default: '#000',
    CANCELLED: 'red',
    DELIVERING: '#3496ff',
    DELIVERED: '#46e06a',

}
export const alarmInfoTableHeaders = [
    'Warehousses',
    'Orders',
    'Manual',
    'WAP',
    'Accepted',
    'AWT',
    'AWP',
    'SP',
    'EP',
    'Delivering',
    'ADT',
    'ADP'];
export const dispatchingInfoTableHeaders = [
    'Warehousses',
    'Dispo',
    'Deliveries',
    'ON_HOLD',
    'DISPATCHING',
    'DISPATCHED'
    // 'Created',
    // 'Manual',
    // 'Dispatching',
    // 'Assigned',
    // 'Dispatched'
]
export const driveInfoTableHeaders = [
    'Warehousses',
    'Dispo',
    'Deliveries',
    'CREATED',
    'WFA',
    'ACCEPTED',
    'ATW',
    'SP',
    'EP',
    'SD',
    'ATD',

]
export const callApicolumns = [
    'Created',
    'type',
    'source',
    'call/retries',
    'status',
    'errors',
    'kaalix ID',
    'marjane ID',
    // 'warehouse',
    'deliveryMan',
    'Marjane Status',
    'kaalix Status',
];
