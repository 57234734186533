import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel/InputLabel';
import Select from '@material-ui/core/Select/Select';
import MenuItem from '@material-ui/core/MenuItem';
import AsyncSelect from 'react-select/async';
import {
  getApprouvedProvider,
  getlocationsHistoryForDeliverManApi,
} from '../service/serverAPI.service';
import { getCities } from '../../delivery-info/services/serverApi.service';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { getUserDataState } from '../../auth/redux/selectors';
import { socketConnectPertnerGps } from '../../PartnersOrders/services/server.api.service';

const columns = [
  { id: 'deliveryManId', label: 'deliveryManId', minWidth: 70 },
  { id: 'name', label: 'name', minWidth: 70 },
  { id: 'location', label: 'location', minWidth: 70 },
  { id: 'timestamp', label: 'timestamp', minWidth: 100 },
  { id: 'seconds', label: 'seconds', minWidth: 70 },
  { id: 'batteryLevel', label: 'batteryLevel', minWidth: 70 },
  { id: 'gpsStatus', label: 'gpsStatus', minWidth: 70 },
  { id: 'appState', label: 'appState', minWidth: 70 },
  { id: 'isMocked', label: 'isMocked', minWidth: 70 },
  { id: 'fromNewApp', label: 'fromNewApp', minWidth: 70 },
  { id: 'phone', label: 'phone', minWidth: 70 },
  { id: 'socketName', label: 'socketName', minWidth: 70 },
];

const rows = [];

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  filter: {
    padding: 10,
  },
});

export default function ProviderLocation() {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  // const [deliveryManId, setDeliveryManId] = useState('');
  const [provider, setProvider] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [cities, setCities] = useState([]);
  const [data, setData] = useState([]);
  const [newData, setNewData] = useState([]);
  const [lastnewData, setLastNewData] = useState();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const _getlocationsHistoryForDeliverManApi = (deliveryManId) => {
    getlocationsHistoryForDeliverManApi(
      page,
      rowsPerPage,
      true,
      deliveryManId,
    ).then(({ data }) => {
      console.log(data.deliveryMan);
      setData(data.deliveryMan);
    });
  };
  const userData = useSelector(getUserDataState);
  const [socket, setSocket] = useState(null);
  const [newConnection, setNewConection] = useState(false);

  //   useEffect(() => {
  //     provider?._id && setNewConection(!newConnection);
  //   }, [provider]);
  useEffect(() => {
    if (!socket) {
      setSocket(socketConnectPertnerGps(userData.accessToken));
    }
    function subscribeToAdminRoom() {
      if (socket && provider?._id) {
        socket.emit('JOIN_ROOM', provider._id);
      }
    }
    if (socket) {
      subscribeToAdminRoom();
    }
  }, [socket, provider]);
  useEffect(() => {
    provider &&
      socket &&
      socket.on('DELIVERYMAN_LOCATION_CHANGED', function (newData) {
        // console.log('DELIVERYMAN_LOCATION_CHANGED', newData);
        if (newData.deliveryManId + '' == provider._id + '') {
          const {
            deliveryManId,
            name,
            location,
            timestamp,
            batteryLevel,
            gpsStatus,
            appState,
            socketName,
            isMocked,
            fromNewApp,
            phone,
          } = newData;
          let dataToUpdate = {
            deliveryManId: deliveryManId,
            name: name,
            location: location,
            timestamp: timestamp,
            batteryLevel: parseInt((batteryLevel * 100).toString()),
            gpsStatus: gpsStatus + '',
            appState: appState + '',
            socketName: socketName + '',
            isMocked: isMocked + '',
            fromNewApp: fromNewApp + '',
            phone: phone,
            newData: true,
          };

          setNewData(dataToUpdate);
        }
      });
  }, [socket, provider]);
  useEffect(() => {
    if (newData) {
      const d = data;
      const exist = d.find((e) => {
        // console.log('asa**********', new Date(e.timestamp).getTime() ,
        //     new Date(newData.timestamp).getTime())
        return (
          new Date(e.timestamp).getTime() ===
          new Date(newData.timestamp).getTime()
        );
      });
      if (!exist) {
        let dd = lastnewData;
        let i = data.length;
        var startDate = dd ? new Date(dd['timestamp']) : new Date();
        var endDate = new Date(newData.timestamp);
        // console.log('startDate', new Date(startDate), new Date(endDate));
        newData['seconds'] = (endDate.getTime() - startDate.getTime()) / 1000;
        d.unshift(newData);
        setData(d);
        setLastNewData({ ...newData });
      } else {
        // console.log('duplicate socket');
      }
    }
  }, [newData]);
  useEffect(() => {
    if (provider) {
      //   console.log(provider);
      //   setData([]);
      _getlocationsHistoryForDeliverManApi(provider._id);
      // socket && socket.disconnect();
      //   socket && socket.disconnect();
      //   setSocket(null);
      //   socket.emit('JOIN_ROOM', provider._id);
    }
  }, [provider, page, rowsPerPage]);
  useEffect(() => {
    loadCitiesList();
  }, []);
  const [selectIdCity, setSelectIdCity] = useState('');
  let timer1;
  const fetchData = (inputValue, callback) => {
    clearTimeout(timer1);
    // setInputValue('');
    timer1 = setTimeout(() => {
      inputValue &&
        getApprouvedProvider(0, 10, selectIdCity, inputValue)
          .then(({ data }) => {
            const tempArray = [];
            if (data.deliveryMan) {
              if (data.deliveryMan.length) {
                data.deliveryMan.forEach((element) => {
                  tempArray.push({
                    label: `${element.name}`,
                    ...element,
                  });
                });
              }
            }
            callback(tempArray);
          })
          .catch((error) => {
            console.log(error);
          });
    }, 1000);
  };
  const loadCitiesList = () => {
    getCities(page, rowsPerPage, true)
      .then(({ data }) => {
        data = data.cities;
        setCities([...data]);
      })
      .finally(() => {});
  };
  return (
    <Paper>
      <div className="row p-3">
        <div className="col-6">
          <div className="row">
            {/* <div > */}
            <FormControl style={{ minWidth: '300px', padding: 10 }}>
              <InputLabel>Cities</InputLabel>
              <Select
                value={selectIdCity}
                onChange={($event) => {
                  // TODO CHANGE CITIES
                  setSelectIdCity($event.target.value);
                  // loading();
                }}
              >
                {cities.map((item, index) => (
                  <MenuItem value={item._id}>{item.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="col-6">
          <div className="row">
            {selectIdCity !== '' && (
              <div className="col-12">
                <FormControl style={{ minWidth: '300px', padding: 10 }}>
                  <InputLabel>Provider</InputLabel>
                  <AsyncSelect
                    single
                    isClearable
                    defaultValue={inputValue}
                    loadOptions={fetchData}
                    placeholder="find deliveryMan"
                    onChange={(e) => {
                      console.log([e]);
                      if (e) {
                        setProvider(e);
                      } else {
                        setProvider(null);
                        //setIsLoading(true);
                        //loadApprouvedProviderList(0, 25);
                      }
                    }}
                  />
                </FormControl>
              </div>
            )}
          </div>
        </div>
      </div>

      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => {
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.code}
                  style={{
                    backgroundColor: row.newData ? '#c1ffbe' : '#ffffff',
                  }}
                >
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
