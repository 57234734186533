import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import styles from '../../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle';
import { updateStore } from '../services/serverApi.service';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles(styles);

export default function StoreEditDispatchingModeComponent({
  modes,
  store,
  close,
}) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [modeList, setModeList] = useState(modes);
  const [selectedMode, setSelectedMode] = useState([]);

  const selectMode = async (index) => {
    console.log(index);
    let newModeList = modeList;
    newModeList[index].checked = !newModeList[index].checked;
    setSelectedMode([...newModeList]);
  };
  const prepareModes = async () => {
    let newModeList = await modes.map((item) => {
      return {
        name: item,
        checked:
          store.selectedDispatchingModes.findIndex((val) => {
            return val === item;
          }) > -1,
      };
    });
    console.log('newModeList', newModeList);
    setModeList(newModeList);
  };
  useEffect(() => {
    prepareModes();
  }, []);

  const onEdit = async () => {
    setIsLoading(true);
    let modesList = await selectedMode
      .filter((item) => item.checked)
      .map((item) => item.name);
    store.selectedDispatchingModes = modesList;
    let { _id, ...newStore } = store;
    updateStore(_id, newStore)
      .then(({ data }) => {
        console.log('Store has been updated', {
          type: 'success',
        });
      })
      .catch((error) => {
        console.log(error.message, {
          type: 'error',
        });
      })
      .finally(() => {
        setIsLoading(false);
        close();
      });
  };

  const checked = (item) => {
    let newSelectedMode = selectedMode;
    return newSelectedMode.findIndex((val) => val === item) > -1;
  };
  return (
    <div>
      {JSON.stringify(selectedMode)}
      <Box sx={{ display: 'flex' }}>
        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
          <FormGroup>
            {modeList.map((item, index) => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!item.checked}
                    value={item.name}
                    onChange={() => selectMode(index)}
                  />
                }
                label={item.name}
              />
            ))}
          </FormGroup>
        </FormControl>
      </Box>
      <Box textAlign="center">
        <Button variant="contained" color="success" onClick={onEdit}>
          Edit
        </Button>
      </Box>
    </div>
  );
}

StoreEditDispatchingModeComponent.propTypes = {
  modes: PropTypes.any.isRequired,
  store: PropTypes.any.isRequired,
  close: PropTypes.func,
};
