import React, { useState, useEffect } from 'react';
import { useAlert } from 'react-alert';
import {
  createMoneyTransfer,
  getApprouvedProvider,
  getMoneyTransfer,
  getProviderDetails,
  logProvider,
  setProvider,
  validerMoneyTransfer,
} from '../service/serverAPI.service';
import DataTableContainerComponent from '../../../components/DataTableSortingContainer.component';
import SwitchComponent from '../../../components/Switch.component';
import { formatTime } from '../../../helpers/index';
import DeleteProvider from '../components/DeleteProvider';
import EditProvider from '../components/EditProvider';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu/Menu';
import ModalComponent from '../../../components/Modal.component';
import OrderDetail from '../../orders/components/OrderDetail.component';
import TextField from '@material-ui/core/TextField';
import { Box, FormControl, Input, Grid } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import ButtonPro from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import { replace } from 'formik';
import { store } from '../../../redux/store';
import { virementListTypes } from '../mock';
import { changesMock } from '../../customers/mock';
import AsyncSelect from 'react-select/async';
import SelectBoxComponent from '../../../components/SelectBox.component';
import CustomButton from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import { DATA } from '../../../utils/logAudit';
import { resetObj } from 'utils/resetObj';
import { useSelector } from 'react-redux';
import { getUserDataState } from 'features/auth/redux/selectors';

export default function VirementProvider() {
  const alert = useAlert();
  const [resultsData, setResultsData] = useState({
    total: 0,
    items: [],
    tableItems: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState('');
  const [page, setPage] = useState(0);
  const [provider, setProvider] = useState(null);
  const [moneyTransferData, setMoneyTransferData] = useState(null);
  let numberOfRows = [100, 500, 1000];
  const [rowsPerPage, setRowsPerPage] = useState(numberOfRows[0]);
  const [transfers, setTransfers] = useState([]);
  const [total, setTotal] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [deliveryManId, setDeliveryManId] = useState('');
  const [type, setType] = useState('');
  const adminData = useSelector(getUserDataState);
  const [admin, setAdmin] = useState({
    _id: adminData.user._id,
    email: adminData.user.email,
    username: adminData.user.username
  })

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const prepareShowTransfers = (item, index) => {
    return {
      ...item,
      createdAt: item.createdAt ? formatTime(item.createdAt) : '--',
      menu:
        item.status === 'PENDING' ? (
          <div>
            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={($event) => {
                setMoneyTransferData(item);
                handleClick($event);
              }}
            >
              Open Menu
            </Button>
          </div>
        ) : (
          ''
        ),
    };
  };
  const loadMoneyTransferList = (skip, limit, id, type) => {
    setIsLoading(true);
    getMoneyTransfer(skip, limit, true, id, type)
      .then(({ data }) => {
        setTotal(data.total);
        setTransfers(data.MoneyTransfer);
        // setResultsData({
        //   total: data.total,
        //   // items: data,
        //   items: data.MoneyTransfer.map((item) => {
        //     return {
        //       ...item,
        //       createdAt: item.createdAt ? formatTime(item.createdAt) : '--',
        //       menu:
        //         item.status === 'PENDING' ? (
        //           <div>
        //             <Button
        //               aria-controls="simple-menu"
        //               aria-haspopup="true"
        //               onClick={($event) => {
        //                 setMoneyTransferData(item);
        //                 handleClick($event);
        //               }}
        //             >
        //               Open Menu
        //             </Button>
        //           </div>
        //         ) : (
        //           ''
        //         ),
        //     };
        //   }),
        // });
      })
      .catch((error) => {
        alert.show(error.response.data?.message, {
          type: 'info',
        }),
          setResultsData({
            total: 0,
            items: [],
            tableItems: [],
          });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handlePaginate = (val) => {
    setResultsData({
      items: [],
      tableItems: [],
    });
    loadMoneyTransferList(
      val.pageSelect,
      val.numberOfRows,
      deliveryManId,
      type,
    );
    setPage(val.pageSelect);
    setRowsPerPage(val.numberOfRows);
  };
  const onSearchChange = (selectedOption, whoChange) => {
    page > 0 && setPage(0);
    switch (whoChange) {
      case changesMock.changeId:
        if (selectedOption) {
          setDeliveryManId(selectedOption);
          loadMoneyTransferList(
            page > 0 ? 0 : page,
            rowsPerPage,
            selectedOption,
            type,
          );
        }
        break;
      case changesMock.changeType:
        if (selectedOption) {
          setType(selectedOption == virementListTypes[0] ? '' : selectedOption);
          loadMoneyTransferList(
            page > 0 ? 0 : page,
            rowsPerPage,
            deliveryManId,
            selectedOption == virementListTypes[0] ? '' : selectedOption,
          );
        }
        break;

      default:
        break;
    }
  };
  let timer1;
  const fetchData = (inputValue, callback) => {
    clearTimeout(timer1);
    timer1 = setTimeout(() => {
      inputValue &&
        getProviderDetails(0, 10, '', inputValue)
          .then(({ data }) => {
            const tempArray = [];
            if (data.deliveryMan) {
              if (data.deliveryMan.length) {
                data.deliveryMan.forEach((element) => {
                  tempArray.push({
                    label: `${element.name}`,
                    _id: element._id,
                  });
                });
              }
            }
            callback(tempArray);
          })
          .catch((error) => {
            console.log(error);
          });
    }, 1000);
  };
  const SearchComponent = () => {
    return (
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={5}>
          <AsyncSelect
            single
            defaultValue={inputValue}
            loadOptions={fetchData}
            placeholder="find deliveryMan"
            onChange={(e) => {
              onSearchChange(e._id, changesMock.changeId);
            }}
          />
        </Grid>
        <Grid item xs={5} className="pb-3">
          <SelectBoxComponent
            items={virementListTypes.map((type) => ({
              value: type,
              label: type,
            }))}
            inputLabel="Type"
            onValueChange={(item) =>
              onSearchChange(item, changesMock.changeType)
            }
            value={type}
          />
        </Grid>
        <Grid item xs={2} container justify="flex-end">
          <Box textAlign="left">
            <CustomButton
              round
              type="submit"
              color="google"
              size="sm"
              loaderColor="white"
              onClick={() => {
                setType('');
                setDeliveryManId('');
                page > 0 && setPage(0);
                loadMoneyTransferList(page > 0 ? 0 : page, rowsPerPage, '', '');
              }}
            >
              clear
            </CustomButton>
          </Box>
        </Grid>
      </Grid>
    );
  };
  const handleOpenModal = (type) => {
    handleClose();
    setOpen(type);
  };
  const handleCloseModal = () => {
    setOpen('');
  };
  const createVirementBalance = () => {
    const moneyTransfer = {
      amount: montant,
      type: 'TRANSFERT_BALANCE',
      status: 'PENDING',
      initiatorId: provider._id,
    };
    createMoneyTransfer(moneyTransfer).then(({ data }) => {
      if (data) handleCloseModal();
    });
  };

  const createVirementCommission = () => {
    const moneyTransfer = {
      amount: montant,
      type: 'TRANSFER_COMMISSION',
      status: 'PENDING',
      initiatorId: provider._id,
    };
    createMoneyTransfer(moneyTransfer).then(({ data }) => {
      if (data) handleCloseModal();
    });
  };

  const createVirementBalanceToZero = () => {
    const moneyTransfer = {
      amount: montant,
      type: 'TRANSFERT_BALANCE_TO_ZERO',
      status: 'PENDING',
      initiatorId: provider._id,
    };
    createMoneyTransfer(moneyTransfer).then(({ data }) => {
      if (data) handleCloseModal();
    });
  };

  const createVirementSalaire = () => {
    const moneyTransfer = {
      amount: montant,
      type: 'TRANSFERT_SALARY',
      status: 'PENDING',
      initiatorId: provider._id,
    };
    createMoneyTransfer(moneyTransfer).then(({ data }) => {
      if (data) handleCloseModal();
    });
  };
  const validerOrCancelVirement = (status) => {
    const reduxState = store.getState();
    let transferData = moneyTransferData;
    transferData.status = status;
    const validator = reduxState.authReducer.userData
      ? reduxState.authReducer.userData.user
      : null;
    const { username, _id, email, entityId } = validator;
    moneyTransferData.validator = {
      username: username,
      _id: _id,
      email: email,
      entityId: entityId,
    };
    validerMoneyTransfer(moneyTransferData).then(({ data }) => {
      handleCloseModal();
      loadMoneyTransferList();
    });
  };

  useEffect(() => {
    transfers &&
      transfers.length > 0 &&
      setResultsData({
        items: transfers.map((item, index) => {
          return prepareShowTransfers(item, index);
        }),
      });
  }, [transfers]);
  return (
    <>
      <DataTableContainerComponent
        total={total}
        page={page + 1}
        resultsData={resultsData}
        initData={resultsData.items}
        columns={[
          {
            Header: 'Date',
            accessor: 'createdAt',
          },
          {
            Header: 'Provider',
            accessor: 'deliveryMan.name',
          },
          {
            Header: 'type',
            accessor: 'type',
          },
          {
            Header: 'Sous type',
            accessor: 'sous_type',
          },
          {
            Header: 'Montant',
            accessor: 'amount',
          },
          {
            Header: 'Create By',
            accessor: 'initiator.username',
          },
          {
            Header: 'Validateur',
            accessor: 'validator.username',
          },
          {
            Header: 'Validation Date ',
            accessor: 'updatedAt',
          },
          {
            Header: 'Status',
            accessor: 'status',
          },
          {
            Header: 'Motif',
            accessor: 'motif',
          },
          {
            Header: 'Comment',
            accessor: 'comment',
          },
          {
            Header: '',
            accessor: 'menu',
          },
        ]}
        title=" Transfers List"
        isLoading={isLoading}
        loadItemsList={loadMoneyTransferList}
        onPaginate={handlePaginate}
        isPaginate={true}
        searchInp={true}
        SearchComponent={SearchComponent}
      />
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            handleOpenModal('valider');
          }}
        >
          Valider Virement
        </MenuItem>
      </Menu>
      <ModalComponent
        maxWidth={'sm'}
        close={handleCloseModal}
        visible={open === 'valider'}
        title="Valider Virement"
      >
        {moneyTransferData && (
          <div style={{ 'font-size': '16px', 'font-weight': '600' }}>
            <div style={{ border: '1px solid #2196f3', margin: '5px 0px' }}>
              <div className="row p-2">
                <div className="col-4">Livreur :</div>
                <div className="col-8">
                  {moneyTransferData.deliveryMan
                    ? moneyTransferData.deliveryMan.name
                    : 0}
                </div>
              </div>
              <div className="row  p-2">
                <div className="col-4">Commission :</div>
                <div className="col-8">
                  {moneyTransferData.deliveryMan
                    ? moneyTransferData.deliveryMan.commission + ' DH'
                    : ''}
                </div>
              </div>
              <div className="row  p-2">
                <div className="col-4">Balance :</div>
                <div className="col-8">
                  {moneyTransferData.deliveryMan
                    ? moneyTransferData.deliveryMan.balance + ' DH'
                    : 0}
                </div>
              </div>
            </div>
            <div style={{ border: '1px solid red', margin: '5px 0px' }}>
              <div className="row  p-2">
                <div className="col-4">Type Virement :</div>
                <div className="col-8">
                  {moneyTransferData.type
                    ? moneyTransferData.type.replace('_', ' ')
                    : ''}
                </div>
              </div>
              <div className="row  p-2">
                <div className="col-4">Montant</div>
                <div className="col-8">{moneyTransferData.amount + ' DH'}</div>
              </div>
            </div>
            {moneyTransferData.type === 'TRANSFERT_BALANCE' && (
              <div style={{ border: '1px solid #ff9800', margin: '5px 0px' }}>
                <div className="row  p-2">
                  <div className="col-4">New Commission :</div>
                  <div className="col-8">
                    {moneyTransferData.deliveryMan
                      ? moneyTransferData.deliveryMan.commission + ' DH'
                      : 0}
                  </div>
                </div>
                <div className="row  p-2">
                  <div className="col-4">New Balance :</div>
                  <div className="col-8">
                    {moneyTransferData.deliveryMan
                      ? parseFloat(
                          moneyTransferData.deliveryMan.balance +
                            moneyTransferData.amount,
                        ) + ' DH'
                      : 0}
                  </div>
                </div>
              </div>
            )}
            {moneyTransferData.type === 'TRANSFER_COMMISSION' && (
              <div style={{ border: '1px solid #ff9800', margin: '5px 0px' }}>
                <div className="row  p-2">
                  <div className="col-4">New Commission :</div>
                  <div className="col-8">
                    {moneyTransferData.deliveryMan
                      ? parseFloat(
                          moneyTransferData.deliveryMan.commission -
                            moneyTransferData.amount,
                        ) + ' DH'
                      : 0}
                  </div>
                </div>
                <div className="row  p-2">
                  <div className="col-4">New Balance :</div>
                  <div className="col-8">
                    {moneyTransferData.deliveryMan
                      ? moneyTransferData.deliveryMan.balance + ' DH'
                      : 0}
                  </div>
                </div>
              </div>
            )}
            {moneyTransferData.type === 'TRANSFERT_SALARY' && (
              <div style={{ border: '1px solid #ff9800', margin: '5px 0px' }}>
                <div className="row  p-2">
                  <div className="col-4">New Commission :</div>
                  <div className="col-8">0</div>
                </div>
                <div className="row  p-2">
                  <div className="col-4">New Balance :</div>
                  <div className="col-8">0</div>
                </div>
              </div>
            )}
            <div className="p-2">
              <Box textAlign="center">
                <ButtonPro
                  round
                  type="button"
                  color="danger"
                  loading={isLoading}
                  loaderColor="white"
                  onClick={() => {
                    validerOrCancelVirement('CANCELLED');
                  }}
                >
                  annuler la virement
                </ButtonPro>
                <ButtonPro
                  round
                  type="button"
                  color="success"
                  loading={isLoading}
                  loaderColor="white"
                  onClick={() => {
                    // reset object before setting data :
                    resetObj(DATA);
                    // for logger audit data :
                    DATA.admin = admin;
                    DATA.actionType = 'Validation virment';
                    DATA.targetId = moneyTransferData.deliveryManId;
                    DATA.target = 'Delivery-man';
                    DATA.loggerDetails = {
                      virmentType: moneyTransferData.type,
                      amount: moneyTransferData.amount
                    }
                    // console.log(DATA);
                    logProvider(DATA)
                      .then(({ data }) => {
                        console.log(data);
                      })
                      .catch((error) => {
                        console.log(error.message, {
                          type: 'error',
                        });
                      })
                    validerOrCancelVirement('DONE');
                  }}
                >
                  valider la virement
                </ButtonPro>
              </Box>
            </div>
          </div>
        )}
      </ModalComponent>
    </>
  );
}
