import React, { useEffect, useState } from 'react';
import {
  getChoubikCommissionSetting,
  getChoubikPriceSetting,
  getCities,
  getDispatchingModes,
  updateChoubikCommissionSetting,
  updateChoubikPriceSetting,
  updateCity,
} from '../services/serverApi.service';
import DataTableContainerComponent from '../../../components/DataTableContainer.component';
import { Button, makeStyles } from '@material-ui/core';
import ModalComponent from '../../../components/Modal.component';
import { useAlert } from 'react-alert';
import CreateCommissionConfig from '../components/CreateCommissionConfig.component';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';
import SaveIcon from '@material-ui/icons/Save';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export default function ChoubikCommissionConfig() {
  const alert = useAlert();
  const [resultsData, setResultsData] = useState({
    total: 0,
    items: [],
    tableItems: [],
  });
  const [isLoading, setIsLoading] = useState(true);
  const pages = [25, 50, 100];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(pages[page]);
  const [dispatchingMessage, setDispatchingMessage] = useState([]);
  const [cityConfig, setCityConfig] = useState([]);
  const [addCityConfig, setAddCityConfig] = useState(false);
  const [modalName, setModalName] = useState('');
  const [cities, setCities] = useState([]);
  const [selectIndexCity, setSelectIndexCity] = useState([]);
  const [choubikPriceSetting, setChoubikPriceSetting] = useState([]);
  const [choubikcommissionSetting, setChoubikCommissionSetting] = useState([]);
  const useStyles = makeStyles({
    table: {
      width: '100%',
    },
    input_edit: {
      width: 80,
    },
    border: {
      borderRight: '1px solid black',
    },
  });
  const classes = useStyles();

  const loadCitiesList = () => {
    setIsLoading(true);
    getCities(page, rowsPerPage, true)
      .then(({ data }) => {
        data = data.cities;
        prepareData(data).then((res) => setCities([...res]));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const prepareData = (newCities) => {
    return new Promise((resolve) => {
      getChoubikPriceSetting().then(({ data }) => {
        data = data.pricings;
        setChoubikPriceSetting(data);
        if (data) {
          newCities = newCities.map((city) => {
            city.choubikPriceSetting =
              data.find((elem) => elem.cityId === city._id) || {};
            return city;
          });
        }

        getChoubikCommissionSetting().then(({ data }) => {
          data = data.pricings;
          setChoubikCommissionSetting(data);
          if (data) {
            newCities = newCities.map((city) => {
              city.choubikCommissionSetting =
                data.find((elem) => elem.cityId === city._id) || {};
              return city;
            });
          }
          resolve(newCities);
        });
      });
    });
  };

  useEffect(() => {
    if (isLoading) {
      setIsLoading(false);
      console.log('Loading ....');
      loadCitiesList();
    }
  }, [isLoading]);

  const [openModal, setOpenModal] = useState('');

  const closeModal = () => {
    setOpenModal('');
  };
  const handleOpenModal = async (index) => {
    setSelectIndexCity(index);
    const choubikPriceSetting = cities[index].choubikPriceSetting;
    console.log(
      '//////////*****************//////////',
      cities[index].choubikCommissionSetting,
    );
    const choubikCommissionSetting = cities[index].choubikCommissionSetting;
    let data = [];
    if (choubikPriceSetting.driveDistricts) {
      data = await choubikPriceSetting.driveDistricts.map((item, index) => {
        return {
          name: item.name,
          priceClient: item.price,
          priceDriver: choubikCommissionSetting
            ? choubikCommissionSetting.driveDistricts.find(
                (elem) => elem.name === item.name,
              ).price
            : 0,
        };
      });
    }
    setCityConfig(data);
    setOpenModal('open');
  };
  // console.log(cityConfig);
  const deleteItem = (index)=>{
    // console.log(cityConfig[index]);
    const tempCityConfig = [...cityConfig]
    tempCityConfig.splice(index, 1)
    setCityConfig(tempCityConfig);

  }
  const editCities = (val, index, parentTag, childTag) => {
    let newCities = cities;
    newCities[index][parentTag][childTag] = val;
    setCities([...newCities]);
  };
  const saveConfig = (index, tag) => {
    const newCities = cities;
    const cityId = newCities[index]['_id'];
    const newConfig = newCities[index][tag];
    switch (tag) {
      case 'choubikPriceSetting':
        updateChoubikPriceSetting(cityId, newConfig).then(({ data }) => {
          console.log(data);
          setIsLoading(true);
        });
        break;
      case 'choubikCommissionSetting':
        updateChoubikCommissionSetting(cityId, newConfig).then(({ data }) => {
          console.log(data);
          setIsLoading(true);
        });
        break;
      default:
        break;
    }
  };

  const setCartierDetail = (val, index, tag) => {
    let newCityConfig = cityConfig;
    console.log(newCityConfig[index][tag], index, val, tag);
    newCityConfig[index][tag] = val;
    setCityConfig([...newCityConfig]);
  };
  const saveCartier = async () => {
    let newCityConfig = cityConfig;
    const city = cities[selectIndexCity];
    const cityId = cities[selectIndexCity]['_id'];
    const choubikPriceSetting = city.choubikPriceSetting;
    const choubikCommissionSetting = city.choubikCommissionSetting;
    choubikPriceSetting.driveDistricts = await newCityConfig.map((item) => {
      return {
        name: item.name,
        price: parseFloat(item.priceClient),
      };
    });
    choubikCommissionSetting.driveDistricts = await newCityConfig.map(
      (item) => {
        return {
          name: item.name,
          price: parseFloat(item.priceDriver),
        };
      },
    );
      console.log('choubikPriceSetting',choubikPriceSetting);
    updateChoubikPriceSetting(cityId, choubikPriceSetting).then(() => {
      updateChoubikCommissionSetting(cityId, choubikCommissionSetting).then(
        ({ data }) => {
          setIsLoading(true);
          console.log(data);
          closeModal()
        },
      );
    });
  };
  return (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="spanning table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell align="center" colSpan={6}>
                Client
              </TableCell>
              <TableCell className={classes.border} />
              <TableCell align="center" colSpan={8}>
                Driver
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>City</TableCell>
              <TableCell align="center">Chobik Loubik</TableCell>
              <TableCell align="center">Base Express</TableCell>
              <TableCell align="center">Prix Km</TableCell>
              <TableCell align="center">Multiple Coefficient</TableCell>
              <TableCell align="center">Min Distance</TableCell>
              <TableCell align="center">accompagnement Price</TableCell>
              <TableCell className={classes.border} />
              <TableCell align="center">Chobik Loubik</TableCell>
              <TableCell align="center">Base Express</TableCell>
              <TableCell align="center">Prix Km</TableCell>
              <TableCell align="center">Multiple Coefficient</TableCell>
              <TableCell align="center">Multiple Min</TableCell>
              <TableCell align="center">accompagnement Service Price</TableCell>
              <TableCell align="center" />
              <TableCell align="center">Districts</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!isLoading &&
              cities.map((city, index) => (
                <TableRow key={index}>
                  <TableCell>{city.name}</TableCell>
                  <TableCell align="center">
                    <input
                      type="number"
                      name="price"
                      id=""
                      value={city.choubikPriceSetting?.price || 0}
                      className={classes.input_edit}
                      onChange={(e) =>
                        editCities(
                          e.target.value,
                          index,
                          'choubikPriceSetting',
                          'price',
                        )
                      }
                    />
                  </TableCell>
                  <TableCell align="center">
                    <input
                      type="number"
                      name="expressBasePrice"
                      id=""
                      value={city.choubikPriceSetting?.expressBasePrice || 0}
                      className={classes.input_edit}
                      onChange={(e) =>
                        editCities(
                          e.target.value,
                          index,
                          'choubikPriceSetting',
                          'expressBasePrice',
                        )
                      }
                    />
                  </TableCell>
                  <TableCell align="center">
                    <input
                      type="number"
                      name="expressPricePerKM"
                      id=""
                      value={city.choubikPriceSetting?.expressPricePerKM || 0}
                      className={classes.input_edit}
                      onChange={(e) =>
                        editCities(
                          e.target.value,
                          index,
                          'choubikPriceSetting',
                          'expressPricePerKM',
                        )
                      }
                    />
                  </TableCell>
                  <TableCell align="center">
                    <input
                      type="number"
                      name="expressAllerRetourMultiplyFactor"
                      id=""
                      value={
                        city.choubikPriceSetting
                          ?.expressAllerRetourMultiplyFactor || 0
                      }
                      className={classes.input_edit}
                      onChange={(e) =>
                        editCities(
                          e.target.value,
                          index,
                          'choubikPriceSetting',
                          'expressAllerRetourMultiplyFactor',
                        )
                      }
                    />
                  </TableCell>
                  <TableCell align="center">
                    <input
                      type="number"
                      name="expressBasPriceMinDistance"
                      id=""
                      value={
                        city.choubikPriceSetting?.expressBasPriceMinDistance ||
                        0
                      }
                      className={classes.input_edit}
                      onChange={(e) =>
                        editCities(
                          e.target.value,
                          index,
                          'choubikPriceSetting',
                          'expressBasPriceMinDistance',
                        )
                      }
                    />
                  </TableCell>
                  <TableCell align="center">
                    <input
                      type="number"
                      step="0.1"
                      name="accompagnementServicePrice"
                      id=""
                      value={
                        city.choubikPriceSetting?.accompagnementServicePrice ||
                        0
                      }
                      className={classes.input_edit}
                      onChange={(e) =>
                        editCities(
                          e.target.value||0,
                          index,
                          'choubikPriceSetting',
                          'accompagnementServicePrice',
                        )
                      }
                    />
                  </TableCell>
                  <TableCell align="left" className={classes.border}>
                    <IconButton
                      color="primary"
                      aria-label="save"
                      onClick={() => saveConfig(index, 'choubikPriceSetting')}
                    >
                      <SaveIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell align="center">
                    <input
                      type="number"
                      name="price2"
                      id=""
                      value={city.choubikCommissionSetting?.price || 0}
                      className={classes.input_edit}
                      onChange={(e) =>
                        editCities(
                          e.target.value,
                          index,
                          'choubikCommissionSetting',
                          'price',
                        )
                      }
                    />
                  </TableCell>
                  <TableCell align="center">
                    <input
                      type="number"
                      name="expressBasePrice2"
                      id=""
                      value={
                        city.choubikCommissionSetting?.expressBasePrice || 0
                      }
                      className={classes.input_edit}
                      onChange={(e) =>
                        editCities(
                          e.target.value,
                          index,
                          'choubikCommissionSetting',
                          'expressBasePrice',
                        )
                      }
                    />
                  </TableCell>
                  <TableCell align="center">
                    <input
                      type="number"
                      name="expressPricePerKM2"
                      id=""
                      value={
                        city.choubikCommissionSetting?.expressPricePerKM || 0
                      }
                      className={classes.input_edit}
                      onChange={(e) =>
                        editCities(
                          e.target.value,
                          index,
                          'choubikCommissionSetting',
                          'expressPricePerKM',
                        )
                      }
                    />
                  </TableCell>
                  <TableCell align="center">
                    <input
                      type="number"
                      name="expressAllerRetourMultiplyFactor2"
                      id=""
                      value={
                        city.choubikCommissionSetting
                          ?.expressAllerRetourMultiplyFactor || 0
                      }
                      className={classes.input_edit}
                      onChange={(e) =>
                        editCities(
                          e.target.value,
                          index,
                          'choubikCommissionSetting',
                          'expressAllerRetourMultiplyFactor',
                        )
                      }
                    />
                  </TableCell>
                  <TableCell align="center">
                    <input
                      type="number"
                      name="expressBasPriceMinDistance2"
                      id=""
                      value={
                        city.choubikCommissionSetting
                          ?.expressBasPriceMinDistance || 0
                      }
                      className={classes.input_edit}
                      onChange={(e) =>
                        editCities(
                          e.target.value,
                          index,
                          'choubikCommissionSetting',
                          'expressBasPriceMinDistance',
                        )
                      }
                    />
                  </TableCell>
                  <TableCell align="center">
                    <input
                      type="number"
                      step="0.1"
                      name="accompagnementCommissionPrice"
                      id=""
                      value={
                        city.choubikCommissionSetting
                          ?.accompagnementCommissionPrice || 0
                      }
                      className={classes.input_edit}
                      onChange={(e) =>
                        editCities(
                          e.target.value||0,
                          index,
                          'choubikCommissionSetting',
                          'accompagnementCommissionPrice',
                        )
                      }
                    />
                  </TableCell>
                  <TableCell align="left">
                    <IconButton
                      color="primary"
                      aria-label="save"
                      onClick={() =>
                        saveConfig(index, 'choubikCommissionSetting')
                      }
                    >
                      <SaveIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell align="center">
                    <Button
                      disabled={
                        !city.choubikPriceSetting ||
                        !city.choubikCommissionSetting
                      }
                      variant="contained"
                      onClick={() => {
                        handleOpenModal(index);
                      }}
                      color={'primary'}
                    >
                      {city.choubikPriceSetting.driveDistricts
                        ? city.choubikPriceSetting.driveDistricts.length
                        : 0}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {openModal && (
        <ModalComponent
          maxWidth={'lg'}
          close={closeModal}
          visible={openModal}
          title={modalName}
        >
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Cartier</TableCell>
                  <TableCell align="center">Client Price</TableCell>
                  <TableCell align="center">Driver Price</TableCell>
                  <TableCell align="center">Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {cityConfig &&
                  cityConfig.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell align="center">
                        <input
                          type="text"
                          name="name"
                          id=""
                          value={row.name}
                          // className={classes.input_edit}
                          onChange={(e) =>
                            setCartierDetail(e.target.value, index, 'name')
                          }
                        />
                      </TableCell>
                      <TableCell align="center">
                        <input
                          type="number"
                          name="priceClient"
                          id=""
                          value={row.priceClient || 0}
                          onChange={(e) =>
                            setCartierDetail(
                              e.target.value,
                              index,
                              'priceClient',
                            )
                          }
                        />
                      </TableCell>
                      <TableCell align="center">
                        <input
                          type="number"
                          name="priceDriver"
                          id=""
                          value={row.priceDriver || 0}
                          onChange={(e) =>
                            setCartierDetail(
                              e.target.value,
                              index,
                              'priceDriver',
                            )
                          }
                        />
                      </TableCell>
                      <TableCell align="center">
                        <CloseIcon style={{cursor:'pointer',color : 'red'}} onClick={()=>deleteItem(index)}/>
                        {/* <input
                          type="number"
                          name="priceDriver"
                          id=""
                          value={row.priceDriver || 0}
                          onChange={(e) =>
                            setCartierDetail(
                              e.target.value,
                              index,
                              'priceDriver',
                            )
                          }
                        /> */}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <IconButton
            color="primary"
            aria-label="add"
            onClick={() => {
              setAddCityConfig(true);
              setCityConfig([
                ...cityConfig,
                {
                  name: '',
                  priceClient: 0,
                  priceDriver: 0,
                },
              ]);
            }}
          >
            <AddIcon />
          </IconButton>

          <IconButton
            color="primary"
            aria-label="add"
            onClick={() => {
              setAddCityConfig(false);
              saveCartier();
            }}
          >
            <SaveIcon />
          </IconButton>
        </ModalComponent>
      )}
    </>
  );
}
