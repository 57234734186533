import React, { useState, useEffect, useRef } from 'react';
import DataTableSortingContainer from '../../../components/DataTableSortingContainer.component';
import { getCallApi } from '../services/server.api.service';
import moment from 'moment';
import SelectBoxComponent from '../../../components/SelectBox.component';
import {
  callApiSearchType,
  callApiSearchCall,
  callApiSearchStatus,
  callApiSearchId,
  callApiSearchBySource,
  callApiSearchMarjaneStatus,
} from '../mock';

import { Box, Grid, TextField } from '@material-ui/core';
import { DateTimePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import CustomButton from '../../../components/material-dashboard-pro-react/CustomButtons/Button';

export default function CallApiPage() {
  const inpRef = useRef();
  // const alert = useAlert();
  const [resultsData, setResultsData] = useState({
    total: 0,
    items: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState(null);
  const [item, setItem] = useState({});
  const [page, setPage] = useState(0);
  let numberOfRows = [20, 50, 100];
  const [rowsPerPage, setRowsPerPage] = useState(numberOfRows[0]);
  const [total, setTotal] = useState(0);
  const [callHistory, setCallHistory] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchBy, setSearchBy] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [status, setStatus] = useState('');
  const [call, setCall] = useState('');
  const [marjaneStatus, setMarjaneStatus] = useState('');
  const [source, setSource] = useState('');
  const [searchId, setSearchId] = useState('');
  const [marjaneId, setMarjaneId] = useState('');
  const [kaalixId, setKaalixId] = useState('');
  const [useDate, setUseDate] = useState(false);
  const [subPagination, setSubPagination] = useState([]);

  const columns = [
    {
      Header: 'Created',
      accessor: 'createdAtCell',
    },
    {
      Header: 'type',
      accessor: 'data.type',
    },
    {
      Header: 'source',
      accessor: 'data.source',
    },
    {
      Header: 'call/retries',
      accessor: 'callCell',
    },
    {
      Header: 'status',
      accessor: 'data.status',
    },
    {
      Header: 'errors',
      accessor: 'errorsCell',
    },

    {
      Header: 'token',
      accessor: 'data.headers',
    },
    {
      Header: 'kaalix ID',
      accessor: 'data.kaalixId',
    },
    {
      Header: 'marjane ID',
      accessor: 'marjaneIdCell',
    },
    {
      Header: 'warehouse',
      accessor: 'warehouseCell',
    },
    {
      Header: 'deliveryMan',
      accessor: 'deliveryManCell',
    },
    {
      Header: 'deliveryManId',
      accessor: 'deliveryManIdCell',
    },
    {
      Header: 'Marjane Status',
      accessor: 'data.marjaneStatus',
    },
    {
      Header: 'kaalix Status',
      accessor: 'data.kaalixStatus',
    },
  ];
  const rowColor = (status, success, stsusCode) => {
    if (status && status === 'STATUS_ERROR_PAYMENT') {
      return '#ffcc00';
    }
    if (
      success == false ||
      (stsusCode && JSON.stringify(stsusCode).startsWith(4))
    ) {
      return 'red';
    }
  };
  const prepareToShowHistory = (item, index) => {
    return {
      ...item,
      createdAtCell:
        item.createdAt && moment(item.createdAt).format('MM/DD hh:mm:ss'),
      deliveryManCell:
        item?.data?.deliveryMan?.name ||
        item?.data?.deliveryMan ||
        item?.data?.body?.deliveryManId,
      deliveryManIdCell:
        item?.data?.deliveryManId || item?.data?.deliveryMan?._id,
      Rowcolor: rowColor(
        item?.data?.marjaneStatus,
        item.data?.success,
        item.data?.status,
      ),
      // (item.data?.success == false ||
      //   (item.data?.status &&
      //     JSON.stringify(item.data?.status).startsWith(4))) &&
      // 'red'
      errorsCell:
        (item.data?.args?.errors &&
          item.data?.args?.errors.length > 0 &&
          item.data?.args?.errors[0]?.message) ||
        item?.data?.errors,
      callCell: (
        <span>
          {item?.data?.call}
          {item?.data?.retries && (
            <span style={{ color: '#4caf50' }}> / {item?.data?.retries}</span>
          )}
        </span>
      ),
      warehouseCell:
        item?.data?.warehouse || item?.data?.args?.pickupAddress?.libeleMagasin,
      marjaneIdCell:
        item?.data?.data?.marjaneId ||
        item?.data?.args?.orderData?.code ||
        item?.data?.marjaneId,
    };
  };
  const SearchComponent = () => {
    return (
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={4} className="pb-3">
          <SelectBoxComponent
            items={callApiSearchBySource.map((type) => ({
              value: type,
              label: type,
            }))}
            inputLabel="Source"
            onValueChange={(items) => {
              // setSearchText(inpRef.current.value);
              // setSearchBy(items);
              setSource(items == 'none' ? '' : items);
              getCall(
                0,
                rowsPerPage,
                status,
                call,
                marjaneId,
                kaalixId,
                startDate,
                endDate,
                useDate,
                items == 'none' ? '' : items,
                marjaneStatus,
              );
            }}
            value={source}
          />
        </Grid>
        <Grid item xs={4} className="pb-3">
          <SelectBoxComponent
            items={callApiSearchMarjaneStatus.map((type) => ({
              value: type,
              label: type,
            }))}
            inputLabel="marjane ST"
            onValueChange={(items) => {
              // setSearchText(inpRef.current.value);
              // setSearchBy(items);
              setMarjaneStatus(items == 'none' ? '' : items);
              getCall(
                0,
                rowsPerPage,
                status,
                call,
                marjaneId,
                kaalixId,
                startDate,
                endDate,
                useDate,
                source,
                items == 'none' ? '' : items,
              );
            }}
            value={marjaneStatus}
          />
        </Grid>
        <Grid item xs={4} className="pb-3">
          <SelectBoxComponent
            items={callApiSearchCall.map((type) => ({
              value: type,
              label: type,
            }))}
            inputLabel="Call"
            onValueChange={(items) => {
              // setSearchText(inpRef.current.value);
              // setSearchBy(items);
              setCall(items == 'none' ? '' : items);
              getCall(
                0,
                rowsPerPage,
                status,
                items == 'none' ? '' : items,
                marjaneId,
                kaalixId,
                startDate,
                endDate,
                useDate,
                source,
                marjaneStatus,
              );
            }}
            value={call}
          />
        </Grid>
        <Grid item xs={4} className="pb-3">
          <SelectBoxComponent
            items={callApiSearchStatus.map((type) => ({
              value: type,
              label: type,
            }))}
            inputLabel="Staus Code"
            onValueChange={(items) => {
              // setSearchText(inpRef.current.value);
              // setSearchBy(items);
              setStatus(items);
              getCall(
                0,
                rowsPerPage,
                items,
                call,
                marjaneId,
                kaalixId,
                startDate,
                endDate,
                useDate,
                source,
                marjaneStatus,
              );
            }}
            value={status}
          />
        </Grid>
        <Grid item xs={4}>
          <input
            type="text"
            ref={inpRef}
            placeholder="search"
            style={{
              border: 'none',
              borderBottom: '1px solid black',
            }}
            defaultValue={searchText}
          />
        </Grid>
        <Grid item xs={5} className="pb-3">
          <SelectBoxComponent
            items={callApiSearchId.map((type) => ({
              value: type,
              label: type,
            }))}
            inputLabel="search By"
            onValueChange={(items) => {
              // setSearchText(inpRef.current.value);
              setSearchId(items);
            }}
            value={searchId}
          />
        </Grid>

        <Grid item xs={4} className="mb-3">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              renderInput={(props) => <TextField {...props} />}
              label="Start date"
              ampm={false}
              value={startDate}
              onChange={(newValue) => {
                setStartDate(moment(newValue).format().split('+')[0]);
                !useDate && setUseDate(true);
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={4} className="mb-3">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              renderInput={(props) => <TextField {...props} />}
              label="End Date"
              ampm={false}
              value={endDate}
              onChange={(newValue) => {
                setEndDate(moment(newValue).format().split('+')[0]);
                !useDate && setUseDate(true);
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={4} container justify="flex-end">
          <Box textAlign="left">
            <CustomButton
              round
              type="submit"
              color="success"
              size="sm"
              loaderColor="white"
              onClick={() => {
                // setSearchText(inpRef.current.value);
                // setShortId(inpRefForShortId.current.value);
                page > 0 && setPage(0);
                searchId &&
                  searchId === 'kaalixId' &&
                  setKaalixId(inpRef.current.value);
                searchId &&
                  searchId === 'marjaneId' &&
                  setMarjaneId(inpRef.current.value);
                getCall(
                  page > 0 ? 0 : page,
                  rowsPerPage,
                  status,
                  call,
                  // marjaneId,
                  searchId && searchId === 'marjaneId'
                    ? inpRef.current.value
                    : '',
                  // kaalixId,
                  searchId && searchId === 'kaalixId'
                    ? inpRef.current.value
                    : '',
                  startDate,
                  endDate,
                  useDate,
                  source,
                  marjaneStatus,
                );
              }}
            >
              find
            </CustomButton>
          </Box>
          <Box textAlign="left">
            <CustomButton
              round
              type="submit"
              color="google"
              size="sm"
              loaderColor="white"
              onClick={() => {
                setSearchText('');
                setSearchBy('');
                setUseDate(false);
                setStartDate('');
                setEndDate('');
                page > 0 && setPage(0);
                setStatus(''),
                  setCall(''),
                  setSource(''),
                  setMarjaneStatus(''),
                  setMarjaneId(''),
                  setKaalixId(''),
                  getCall(
                    0,
                    rowsPerPage,
                    '',
                    '',
                    '',
                    '',
                    '',
                    '',
                    false,
                    '',
                    '',
                  );
              }}
            >
              clear
            </CustomButton>
          </Box>
        </Grid>
      </Grid>
    );
  };
  const getCall = (
    skip,
    limit,
    status,
    call,
    marjaneId,
    kaalixId,
    startDate,
    endDate,
    useDate,
    src,
    marjaneStatus,
  ) => {
    getCallApi(
      skip,
      limit,
      status,
      call,
      marjaneId,
      kaalixId,
      startDate,
      endDate,
      useDate,
      src,
      marjaneStatus,
    )
      .then(({ data }) => {
        console.log(data);
        setTotal(data.total);
        setCallHistory(data.callApi);
        // useDate && setDisplayTotal(true);
      })
      .catch((error) => {
        console.log(error);
        setResultsData({ items: [] });
      });
  };

  const handlePaginate = (val) => {
    setResultsData({
      items: [],
      tableItems: [],
    });
    getCall(
      val.pageSelect,
      val.numberOfRows,
      status,
      call,
      marjaneId,
      kaalixId,
      useDate,
      source,
      marjaneStatus,
    );
    setPage(val.pageSelect);
    setRowsPerPage(val.numberOfRows);
  };
  // useEffect(() => {
  //   getCall();
  // }, []);
  useEffect(() => {
    callHistory &&
      callHistory.length > 0 &&
      setResultsData({
        items: callHistory.map((item, index) => {
          return prepareToShowHistory(item, index);
        }),
      });
  }, [callHistory]);
  useEffect(() => {
    if (total) {
      const pages = Math.ceil(total / rowsPerPage);
      if (total > 2000) {
        console.log(total);
        const newPages = [];
        // const start = page==0?page:Number(page-1)
        let start;
        let end;
        const handelStart = () => {
          let startNumber;
          if (page == 0) startNumber = page;
          else if (page > 0) {
            if (page - 100 < 0) startNumber = 0;
            else startNumber = Number(page - 100);
          }
          return startNumber;
        };
        const handelEnd = () => {
          let endNumber;
          if (page + 100 < pages) endNumber = page + 100;
          else endNumber = page + (pages - page);
          return endNumber;
        };
        start = handelStart();
        end = handelEnd();
        for (let i = start; i < end; i++) {
          newPages.push(i);
        }
        setSubPagination(newPages);
      } else {
        setSubPagination([]);
      }
    }
  }, [total, page]);
  return (
    <div>
      <DataTableSortingContainer
        resultsData={resultsData}
        total={total}
        initData={resultsData.items}
        columns={columns}
        title={<strong>Call Api</strong>}
        isLoading={isLoading}
        page={page + 1}
        // customColumnStyle={customColumnStyle}
        onPaginate={handlePaginate}
        isPaginate={true}
        searchInp={true}
        SearchComponent={SearchComponent}
        subPagination={subPagination}
      />
    </div>
  );
}
