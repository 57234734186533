import React from 'react'
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useForm, useFieldArray,Controller } from "react-hook-form";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { useFormik, ErrorMessage, Field, FieldArray, Form, Formik } from 'formik';
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";


import styles from "assets/jss/material-dashboard-pro-react/components/customInputStyle.js";

const useStyles = makeStyles(styles);

export default function CustomControllerField(props) {
    const classes = useStyles();
  const {
    formControlProps,
    labelText,
    id,
    labelProps,
    inputProps,
    error,
    white,
    inputRootCustomClasses,
    success,
    helperText,
    rtlActive,
    name,
    value,
    defaultValue,
    ref,
    control,
    disabled,
    type,
    // ref
    validation,
    hidden
  } = props;
  // console.log(inputProps);
  const labelClasses = classNames({
    [" " + classes.labelRootError]: error,
    [" " + classes.labelRootSuccess]: success && !error,
    [classes.labelRTL]: rtlActive,
  });
  const underlineClasses = classNames({
    [classes.underlineError]: error,
    [classes.underlineSuccess]: success && !error,
    [classes.underline]: true,
    [classes.whiteUnderline]: white,
  });
  const marginTop = classNames({
    [inputRootCustomClasses]: inputRootCustomClasses !== undefined,
  });
  const inputClasses = classNames({
    [classes.input]: true,
    [classes.whiteInput]: white,
  });
  var formControlClasses;
  if (formControlProps !== undefined) {
    formControlClasses = classNames(
      formControlProps.className,
      classes.formControl
    );
  } else {
    formControlClasses = classes.formControl;
  }
  var helpTextClasses = classNames({
    [classes.labelRootError]: error,
    [classes.labelRootSuccess]: success && !error,
  });
  let newInputProps = {
    maxLength:
      inputProps && inputProps.maxLength ? inputProps.maxLength : undefined,
    minLength:
      inputProps && inputProps.minLength ? inputProps.minLength : undefined,
    step: inputProps && inputProps.step ? inputProps.step : undefined,
  };
    return (
        <FormControl {...formControlProps} className={formControlClasses}>
      {labelText !== undefined ? (
        <InputLabel
          className={classes.labelRoot + " " + labelClasses}
          htmlFor={id}
          {...labelProps}
        >
          {!hidden&&labelText}
        </InputLabel>
      ) : null}
     
      <Controller 
        as={Input} 
        name={name||null}
        classes={{
            input: inputClasses,
            root: marginTop,
            disabled: classes.disabled,
            underline: underlineClasses,
          }}
          id={id}
          disabled={disabled}
        //   inputProps={newInputProps}
        control={control} 
        type={type}
        {...validation}
        // {...ref}
        // {...inputProps}
        // ref={inputProps}
        // {...inputProps}
        // rules={ required: true } 
        // defaultValue=""
        // labelText="Name"
        //         formControlProps={{
        //             fullWidth: true,
        //         }}
        hidden={hidden||false}
      />
      {helperText !== undefined ? (
        <FormHelperText id={id + "-text"} className={helpTextClasses} style={{color: 'red'}}>
          {helperText}
        </FormHelperText>
      ) : null}
    </FormControl>
    )
}

CustomControllerField.propTypes = {
    labelText: PropTypes.node,
    labelProps: PropTypes.object,
    id: PropTypes.string,
    inputProps: PropTypes.object,
    formControlProps: PropTypes.object,
    inputRootCustomClasses: PropTypes.string,
    error: PropTypes.bool,
    success: PropTypes.bool,
    white: PropTypes.bool,
    helperText: PropTypes.node,
    rtlActive: PropTypes.bool,
    name: PropTypes.string,
    value: PropTypes.string,
    defaultValue: PropTypes.func,
    // control: PropTypes.any,
    
  };
