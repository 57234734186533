import React, { useEffect, useState } from 'react';
import {useHistory} from 'react-router-dom';
import Assignment from '@material-ui/icons/Assignment';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Box } from '@material-ui/core';
import Person from '@material-ui/icons/Person';
import Edit from '@material-ui/icons/Edit';
import Close from '@material-ui/icons/Close';
import Map from '@material-ui/icons/Map';
import Update from '@material-ui/icons/Update';
// import { DataGrid } from '@material-ui/data-grid';
import GridContainer from './material-dashboard-pro-react/Grid/GridContainer';
import GridItem from './material-dashboard-pro-react/Grid/GridItem';
import Card from './material-dashboard-pro-react/Card/Card';
import CardHeader from './material-dashboard-pro-react/Card/CardHeader';
import CardIcon from './material-dashboard-pro-react/Card/CardIcon';
import CardBody from './material-dashboard-pro-react/Card/CardBody';
// import Table from './material-dashboard-pro-react/Table/Table';
import ReactTable from './material-dashboard-pro-react/ReactTable/ReactTable';
import Button from './material-dashboard-pro-react/CustomButtons/Button';
import ModalComponent from './Modal.component';
import tableDataStyles from '../assets/jss/material-dashboard-pro-react/views/extendedTablesStyle';
import dataTableContainerStyles from '../assets/jss/table-data-container';
// import Dvr from '@material-ui/icons/Dvr';
// import Favorite from '@material-ui/icons/Favorite';

// import styles from '../assets/jss/material-dashboard-pro-react/customSelectStyle';
const useTableDataStyles = makeStyles(tableDataStyles);
const useDataTableContainerStyles = makeStyles(dataTableContainerStyles);
export default function DataTableContainerComponent({
  resultsData,
  initData,
  columns,
//   tableHead,
  title,
  isLoading,
  loadItemsList,
  actionButtons,
  CreateItemFormComponent,
  numberOfRowsProps,
  total,
  isPaginate,
  onPaginate,
}) {
  const history = useHistory()
  const path = window.location.pathname
  const classes = useTableDataStyles();
  const tableDataClasses = useDataTableContainerStyles();
  const [modal, setModal] = useState({
    visible: false,
    type: 'view',
  });
  const [selectedItemIndex, setSelectedItemIndex] = useState(null);

  const EditItemFormComponent = actionButtons?.edit?.component;
  const MiniEditItemFormComponent = actionButtons?.miniEdit?.component;
  const ViewItemComponent = actionButtons?.view?.component;
  const MapViewComponent = actionButtons?.map?.component;
  const DeleteComponent = actionButtons?.delete?.component;

  const closeModal = () => {
    setSelectedItemIndex(null);
    setModal({ visible: false, type: '' });
  };

  const [data, setData] = useState([]);
  
  useEffect(() => {
    initData&&setData(
      initData.map((prop, index) => {
        return {
          ...prop,
          actions: (
            // we've added some custom button actions
            <div className="actions-right">
              {actionButtons&&Object.values(actionButtons).map((prop) => (
                <Button
                  color={prop.color}
                  simple
                  className={classes.actionButton}
                  key={prop.type}
                  onClick={() => {
                    setSelectedItemIndex(index);
                    setModal({ type: prop.type, visible: true });
                  }}
                >
                  {prop.type === 'view' && <Person className={classes.icon} />}
                  {prop.type === 'edit' && <Edit className={classes.icon} />}
                  {prop.type === 'delete' && <Close className={classes.icon} />}
                  {prop.type === 'map' && <Map className={classes.icon} />}
                  {prop.type ==="miniEdit"&&(
                        <Update className={classes.icon} />
                      )}
                </Button>
              ))}
            </div>
          ),
        };
      }),
    );
  }, [isLoading]);
  

  return (
    <GridContainer>
      <GridItem xs={12}>
      {CreateItemFormComponent&&
        <Box display="flex" justifyContent="flex-end">
          <Button
            onClick={() => path=="/admin/delivery-info/publicity"?history.push('/admin/delivery-info/create-publicity'):setModal({ visible: true, type: 'create' })
              }
            color="success"
          >
            Create
          </Button>
        </Box>
      }
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>{title}</h4>
          </CardHeader>
          <CardBody>
            {isLoading && (
              <Box display="flex" justifyContent="center">
                <CircularProgress size={30} />
              </Box>
            )}
            {!isLoading && 
            <ReactTable columns={columns} data={data} 
            total={total || 100}
              onPaginate={(val) => {
                onPaginate(val);
              }}
              isPaginate={isPaginate}
              numberOfRowsProps={numberOfRowsProps}
            
            />
            }
             
          </CardBody>
        </Card>
      </GridItem>
      {path && path!="/admin/customers"&&
      <ModalComponent
        close={() => setModal({ visible: false, type: '' })}
        visible={modal.visible && modal.type === 'create'}
        title="Create"
      >
        <CreateItemFormComponent onItemCreated={loadItemsList} close={() => setModal({ visible: false, type: '' })} />
      </ModalComponent>}
      {EditItemFormComponent&&Boolean(EditItemFormComponent) && (
        <ModalComponent
          close={closeModal}
          visible={modal.visible && modal.type === 'edit'}
          title="Edit"
        >
          <EditItemFormComponent
            data={resultsData.items[selectedItemIndex]}
            onItemEdited={loadItemsList}
            close={() => setModal({ visible: false, type: '' })}
          />
        </ModalComponent>
      )}
         {Boolean(MiniEditItemFormComponent) && (
        <ModalComponent
          close={closeModal}
          visible={modal.visible && modal.type === 'miniEdit'}
          title="Edit Promo"
        >
          <MiniEditItemFormComponent
            data={resultsData.items[selectedItemIndex]}
            onItemEdited={loadItemsList}
            close={closeModal}
          />
        </ModalComponent>
      )}
      {ViewItemComponent&&Boolean(ViewItemComponent) && (
        <ModalComponent
          close={closeModal}
          visible={modal.visible && modal.type === 'view'}
          title="Detail"
        >
          <ViewItemComponent item={resultsData.items[selectedItemIndex]} />
        </ModalComponent>
      )}
      {MapViewComponent && Boolean(MapViewComponent) && (
        <ModalComponent
          close={closeModal}
          visible={modal.visible && modal.type === 'map'}
          title="Map"
          classes={{
            paper: tableDataClasses.paper,
          }}
          maxWidth={actionButtons.map.width}
        >
          {selectedItemIndex !== null && (
            <MapViewComponent item={resultsData.items[selectedItemIndex]} />
          )}
        </ModalComponent>
      )}
      {DeleteComponent && Boolean(DeleteComponent) && (
        <ModalComponent
          close={closeModal}
          visible={modal.visible && modal.type === 'delete'}
          title="Delete"
          classes={{
            paper: tableDataClasses.paper,
          }}
          maxWidth={actionButtons.delete.width}
        >
          {selectedItemIndex !== null && (
            <DeleteComponent 
            // item={resultsData.items[selectedItemIndex]} 
            data={resultsData.items[selectedItemIndex]}
            onItemEdited={loadItemsList}
            close={closeModal}
            />
          )}
        </ModalComponent>
      )}
     
    </GridContainer>
  );
}

DataTableContainerComponent.propTypes = {
  resultsData: PropTypes.any.isRequired,
  initData: PropTypes.any,
  columns: PropTypes.any,
//   tableHead: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  loadItemsList: PropTypes.any.isRequired,
  actionButtons: PropTypes.object,
  CreateItemFormComponent: PropTypes.any,
  numberOfRowsProps: PropTypes.any.isRequired,
};
