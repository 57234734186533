
const scope = 'EcomDelivriesPages'
export const GET_PARTNER_ORDER_REQUEST = `${scope}/GET_ORDER_REQUEST`;
export const GET_PARTNER_ORDER_SUCCESS = `${scope}/GET_ORDER_SUCCESS`;
export const GET_PARTNER_ORDER_FAIL = `${scope}/GET_ORDER_FAIL`;
export const CHANGE_DELIVERIE_STATUS = `${scope}/CHANGE_DELIVERIE_STATUS`;
export const ACCEPTED_DELIVERIE = `${scope}/ACCEPTED_DELIVERIE`;
export const CANCEL_DELIVERIE = `${scope}/CANCEL_DELIVERIE`;
export const COMPLETED_DELIVERIE = `${scope}/COMPLETED_DELIVERIE`;
export const ASSIGN_DELIVERIE = `${scope}/ASSIGN_DELIVERIE`;
export const UPDATED_DELIVERIE = `${scope}/UPDATED_DELIVERIE`;
export const NEW_DELIVERIE = `${scope}/NEW_DELIVERIE`;
export const VALIDATED_DELIVERIE = `${scope}/VALIDATED_DELIVERIE`;
export const INITIAL_STATE = `${scope}/INITIAL_STATE`;
export const CHANGE_DELIVERY_ALARMS = `${scope}/CHANGE_DELIVERY_ALARMS`;
// ********************** v2 ******************
export const GET_PARTNER_ORDER_REQUESTV2 = `${scope}/GET_ORDER_REQUESTV2`;
export const GET_PARTNER_ORDER_SUCCESSV2 = `${scope}/GET_ORDER_SUCCESSV2`;
export const GET_PARTNER_ORDER_FAILV2 = `${scope}/GET_ORDER_FAILV2`;
export const CHANGE_DELIVERIE_STATUSV2 = `${scope}/CHANGE_DELIVERIE_STATUSV2`;
export const ACCEPTED_DELIVERIEV2 = `${scope}/ACCEPTED_DELIVERIEV2`;
export const CANCEL_DELIVERIEV2 = `${scope}/CANCEL_DELIVERIEV2`;
export const COMPLETED_DELIVERIEV2 = `${scope}/COMPLETED_DELIVERIEV2`;
export const ASSIGN_DELIVERIEV2 = `${scope}/ASSIGN_DELIVERIEV2`;
export const UPDATED_DELIVERIEV2 = `${scope}/UPDATED_DELIVERIEV2`;
export const NEW_DELIVERIEV2 = `${scope}/NEW_DELIVERIEV2`;
export const INITIAL_STATEV2 = `${scope}/INITIAL_STATEV2`;
export const CHANGE_DELIVERY_ALARMSV2 = `${scope}/CHANGE_DELIVERY_ALARMSV2`;
export const CHANGE_DELIVERYMAN_LOCATIONV2 = `${scope}/CHANGE_DELIVERYMAN_LOCATIONV2`;