import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useFormik } from 'formik';
import * as yup from 'yup';
import CustomInput from '../../../components/material-dashboard-pro-react/CustomInput/CustomInput';
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import {
  getOpningConfig,
  setOpningConfig,
  updateSetting,
} from '../services/serverApi.service';
import SwitchComponent from '../../../components/Switch.component';

const validationSchema = yup.object({
  locationSocket: yup.object(),
});

export default function SocketConfigComponent({ setting }) {
  const [isLoading, setIsLoading] = useState(false);
  const [locationSocket, setLocationSocket] = useState({
    beforeStartDelivery: {
      enable: false,
      timerSocket: 0,
    },
    afterStartDelivery: {
      enable: false,
      timerSocket: 0,
    },
  });

  const fetachOpningText = () => {
    getOpningConfig()
      .then(({ data }) => {
        setTextCreneau({ textList: data.opening.textList || [] });
      })
      .catch((err) => console.log(err));
  };
  const saveTextCreneau = () => {
    const tempSatate = textCreneau;
    tempSatate.textList.push({ text: formik.values.name });
    console.log(tempSatate);

    setOpningConfig(tempSatate)
      .then(({ data }) => {
        console.log(data);
        setTextCreneau({ textList: data.opening.textList || [] });
        // formik.setFieldValue('name', '');
        formik.resetForm();
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    //fetachOpningText();
    if (setting && setting.length > 0 && setting[0]?.socketConfig?.locationSocket)
      setLocationSocket({...setting[0].socketConfig?.locationSocket});
  }, []);

  const _updateSetting = (data) => {
    let _setting = setting[0];
    _setting.socketConfig['locationSocket'] = data;
    console.log('+++++++++++++++++++++++++++++', _setting);
    console.log('+++++++++++++++++++++++++111', data);
    updateSetting(_setting._id, { ..._setting })
      .then(({ data }) => {
        console.log(data);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  };
  const [loading, setLoading] = useState(false);
  const update_location_socket = (item1, item2, value) => {
    setLoading(true);
    let _locationSocket = locationSocket;
    _locationSocket[item1 + ''][item2 + ''] = value;
    console.log('*********', _locationSocket);
    setLocationSocket({ ..._locationSocket });
    _updateSetting({ ..._locationSocket });
  };
  return (
    <div>
      {/*<div>locationSocket : {JSON.stringify(locationSocket)}</div>*/}
      <table className={'table table-bordered'}>
        <tr>
          <td>before Start Delivery</td>
          <td>
            {!loading && (
              <SwitchComponent
                key={1}
                onChange={(value) => {
                  update_location_socket(
                    'beforeStartDelivery',
                    'enable',
                    value,
                  );
                }}
                value="checked"
                checked={locationSocket['beforeStartDelivery']['enable']}
                label={'enable'}
              />
            )}
          </td>
          <td>
            timerSocket(second) :
            <input
              type="number"
              placeholder="minute"
              style={{
                border: 'none',
                borderBottom: '1px solid black',
              }}
              value={ locationSocket.beforeStartDelivery.timerSocket || 0}
              onChange={(value) => {
                console.log();
                if (value.target.value) {
                  update_location_socket(
                    'beforeStartDelivery',
                    'timerSocket',
                    parseInt(value.target.value),
                  );
                }
              }}
            />
          </td>
        </tr>
        <tr>
          <td>After Start Delivery</td>
          <td>
            <SwitchComponent
              key={1}
              onChange={(value) => {
                update_location_socket('afterStartDelivery', 'enable', value);
              }}
              value="checked"
              checked={locationSocket['afterStartDelivery']['enable']}
              label={'enable'}
            />
          </td>
          <td>
            timerSocket (second) :
            <input
              type="number"
              placeholder="minute"
              style={{
                border: 'none',
                borderBottom: '1px solid black',
              }}
              value={
                locationSocket['afterStartDelivery']['timerSocket'] || 0
              }
              onChange={(value) => {
                if (value.target.value) {
                  update_location_socket(
                    'afterStartDelivery',
                    'timerSocket',
                    parseInt(value.target.value),
                  );
                }
              }}
            />
          </td>
        </tr>
      </table>
      {/*{(textCreneau.textList || []).map((item, index) => (*/}
      {/*  <p>*/}
      {/*    /!* <CloseIcon fontSize="small" style={{ color: 'red' }} /> *!/*/}

      {/*    {item?.text}*/}
      {/*  </p>*/}
      {/*))}*/}
      {/*<CustomInput*/}
      {/*  labelText="Name"*/}
      {/*  formControlProps={{*/}
      {/*    fullWidth: true,*/}
      {/*  }}*/}
      {/*  type="text"*/}
      {/*  error={formik.touched.name && Boolean(formik.errors.name)}*/}
      {/*  inputProps={{*/}
      {/*    value: formik.values.name,*/}
      {/*    onBlur: formik.handleBlur('name'),*/}
      {/*    onChange: formik.handleChange('name'),*/}
      {/*  }}*/}
      {/*/>*/}
      {/*<Box textAlign="right">*/}
      {/*  <Button*/}
      {/*    round*/}
      {/*    type="submit"*/}
      {/*    color="success"*/}
      {/*    disabled={isLoading}*/}
      {/*    loading={isLoading}*/}
      {/*    loaderColor="white"*/}
      {/*    onClick={saveTextCreneau}*/}
      {/*  >*/}
      {/*    save*/}
      {/*  </Button>*/}
      {/*</Box>*/}
    </div>
  );
}

SocketConfigComponent.propTypes = {
  setting: PropTypes.any.isRequired,
};
