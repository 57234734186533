import React, { useEffect, useState } from 'react';
// lib
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Box } from '@material-ui/core';
import { useAlert } from 'react-alert';
// compo
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import CustomInput from '../../../components/material-dashboard-pro-react/CustomInput/CustomInput';

// call api
import { addParners } from '../services/server.api.service';

const validationSchema = yup.object({
  name: yup.string().required(),
  email: yup.string().required(),
});
export default function CreateOrganisationComponent({ onItemCreated, close }) {
  const alert = useAlert();
  const [isLoading, setIsLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      warehouses: [],
    },
    validationSchema,
  });

  const onCreate = () => {
    console.log(formik.values);
    setIsLoading(true);

    addParners(formik.values)
      .then(() => {
        alert.show('organisation has been created', {
          type: 'success',
        });
        formik.resetForm();
        onItemCreated();
        close();
      })
      .catch((error) => {
        alert.show(error.message, {
          type: 'error',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div>
      <CustomInput
        labelText="Name"
        formControlProps={{
          fullWidth: true,
        }}
        type="text"
        error={formik.touched.name && Boolean(formik.errors.name)}
        inputProps={{
          value: formik.values.name,
          onBlur: formik.handleBlur('name'),
          onChange: formik.handleChange('name'),
        }}
      />
      <CustomInput
        labelText="Email"
        formControlProps={{
          fullWidth: true,
        }}
        type="text"
        error={formik.touched.email && Boolean(formik.errors.email)}
        inputProps={{
          value: formik.values.email,
          onBlur: formik.handleBlur('email'),
          onChange: formik.handleChange('email'),
        }}
      />
      <Box textAlign="center">
        <Button
          round
          type="submit"
          color="success"
          disabled={!formik.dirty || !formik.isValid || isLoading}
          loading={isLoading}
          loaderColor="white"
          onClick={onCreate}
        >
          Create
        </Button>
      </Box>
    </div>
  );
}
