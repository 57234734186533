import React, { useEffect, useState } from 'react';
// import { useSelector } from 'react-redux';
// import { useAlert } from 'react-alert';
// import { getUserDataState } from '../../auth/redux/selectors'
// import TablePagination from '@material-ui/core/TablePagination';
import { Box, Grid } from '@material-ui/core';
// import { getCustmore, approuvedCustmore } from '../services/serverApi.customer';
import DataTableContainerComponent from '../../../components/DataTableSortingContainer.component';
import { formatTime } from '../../../helpers/index';

import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import CustomButton from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import SwitchComponent from '../../../components/Switch.component';
import { getAudit } from '../services/serverApi.service';

export default function AuditPage() {
  // const alert = useAlert();
  // const userData = useSelector(getUserDataState);
  const inpRef = React.useRef();
  const [resultsData, setResultsData] = useState({
    total: 0,
    items: [],
    tableItems: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  // const classes = useStyles();
  // const pages = [10, 250, 500]
  const [page, setPage] = useState(0);
  let numberOfRows = [20, 50, 100];
  const [rowsPerPage, setRowsPerPage] = useState(numberOfRows[0]);
  const [openModal, setOpenModal] = useState('');
  const [customers, setCustomers] = useState([]);
  const [audit, setAudit] = useState([]);
  const [indexCustomer, setIndexCustomer] = useState(-1);
  const [searchText, setSearchText] = useState('');
  const [total, setTotal] = useState(0);
  const [subPagination, setSubPagination] = useState([]);

  const prepareShowAuditList = (item, index) => {
    return {
      ...item,
      createdAtCol: item.createdAt ? formatTime(item.createdAt) : '--',
      adminCol: item.admin.username,
      actionTypeCol: item.actionType,
      targetIdCol:
        item.target != 'Order' ? item.targetId : item.targetId.slice(0, 8),
      // beforeValueCol: item.beforeValue === null ? item.loggerDetails.beforeValue : item.beforeValue,
      // afterValueCol: item.afterValue,
      reasonCol: item.reason,
      commentCol: item.comment,
      descriptionCol: item.description,
      // beforeBalanceCol: item.beforeBalance, // == 0 ? '' : item.beforeBalance,
      // afterBalanceCol: item.afterBalance,
      // beforeCommissionCol: item.commissionBefore,
      // afterCommissionCol: item.commissionAfter,
      // amountCol: item.amount,
      // oldDateValidateCol: item.oldDateValidate,
      loggerDetails: {
        beforeValueCol: item?.beforeValue || item?.loggerDetails?.beforeValue,
        afterValueCol: item?.afterValue || item?.loggerDetails?.afterValue,
        beforeBalanceCol:
          item?.beforeBalance || item?.loggerDetails?.beforeBalance, // == 0 ? '' : item.beforeBalance,
        afterBalanceCol:
          item?.afterBalance || item?.loggerDetails?.afterBalance,
        beforeCommissionCol:
          item?.commissionBefore || item?.loggerDetails?.commissionBefore,
        afterCommissionCol:
          item?.commissionAfter || item?.loggerDetails?.commissionAfter,
        amountCol: item?.amount || item?.loggerDetails?.amount,
        // oldDateValidateCol: item.oldDateValidate,
        virmentTypeCol: item?.loggerDetails?.virmentType,
      },
    };
  };

  const loadAuditList = (page, limit, txt) => {
    setIsLoading(true);
    getAudit(page || 0, limit || 20, true, txt)
      .then(({ data }) => {
        console.log(data);
        setTotal(data.total);
        setAudit(data.loggers);
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setIsLoading(false);
      });
  };
  const SearchComponent = () => {
    return (
      <Grid container>
        <Grid item xs={8}>
          <input
            type="text"
            ref={inpRef}
            placeholder="search"
            style={{
              border: 'none',
              borderBottom: '1px solid black',
            }}
            defaultValue={searchText}
          />
        </Grid>
        <Grid item xs={4} container justify="flex-end">
          <Box textAlign="left">
            <CustomButton
              round
              type="submit"
              color="success"
              size="sm"
              loaderColor="white"
              // onClick={storeSearch}
              onClick={() => {
                setSearchText(inpRef.current.value);
                page > 0 && setPage(0);
                loadAuditList(
                  page > 0 ? 0 : page,
                  rowsPerPage,
                  inpRef.current.value,
                );
              }}
            >
              find
            </CustomButton>
          </Box>
          <Box textAlign="left">
            <CustomButton
              round
              type="submit"
              color="google"
              size="sm"
              loaderColor="white"
              // onClick={loadAuditList}
              onClick={() => {
                setSearchText('');
                page > 0 && setPage(0);
                loadAuditList(page > 0 ? 0 : page, rowsPerPage, '');
              }}
            >
              clear
            </CustomButton>
          </Box>
        </Grid>
      </Grid>
    );
  };

  useEffect(() => {
    // loadAuditList();
    // console.log(audit);
    audit &&
      setResultsData({
        items: audit.map((item, index) => {
          console.log(item);
          return prepareShowAuditList(item, index);
        }),
      });
  }, [audit]);
  const handlePaginate = (val) => {
    // console.log(val,'val');
    // console.log(val.pageSelect, val.numberOfRows);
    setResultsData({
      items: [],
      tableItems: [],
    });
    loadAuditList(val.pageSelect, val.numberOfRows, searchText);
    setPage(val.pageSelect);
    setRowsPerPage(val.numberOfRows);
  };

  useEffect(() => {
    if (total) {
      const pages = Math.ceil(total / rowsPerPage);
      if (total > 2000) {
        console.log(total);
        const newPages = [];
        // const start = page==0?page:Number(page-1)
        let start;
        let end;
        const handelStart = () => {
          let startNumber;
          if (page == 0) startNumber = page;
          else if (page > 0) {
            if (page - 100 < 0) startNumber = 0;
            else startNumber = Number(page - 100);
          }
          return startNumber;
        };
        const handelEnd = () => {
          let endNumber;
          if (page + 100 < pages) endNumber = page + 100;
          else endNumber = page + (pages - page);
          return endNumber;
        };
        start = handelStart();
        end = handelEnd();
        for (let i = start; i < end; i++) {
          newPages.push(i);
        }
        setSubPagination(newPages);
      } else {
        setSubPagination([]);
      }
    }
  }, [total, page]);
  // console.log(subPagination);
  return (
    <>
      {subPagination && (
        <DataTableContainerComponent
          total={total}
          subPagination={subPagination}
          // numberOfRowsProps={rowsPerPage}
          resultsData={resultsData}
          page={page + 1}
          initData={resultsData.items}
          searchInp={true}
          SearchComponent={SearchComponent}
          columns={[
            {
              Header: 'CreatedAt',
              accessor: 'createdAtCol',
            },
            {
              Header: 'TargetID',
              accessor: 'targetIdCol',
            },
            {
              Header: 'Responsable',
              accessor: 'adminCol',
            },
            {
              Header: 'Type',
              accessor: 'actionTypeCol',
            },
            {
              Header: 'Valeur avant',
              accessor: 'loggerDetails.beforeValueCol',
            },
            {
              Header: 'Valeur apres',
              accessor: 'loggerDetails.afterValueCol',
            },
            {
              Header: 'Raison',
              accessor: 'reasonCol',
            },
            {
              Header: 'Commentaire',
              accessor: 'commentCol',
            },
            {
              Header: 'Description',
              accessor: 'descriptionCol',
            },
            {
              Header: 'Balance avant',
              accessor: 'loggerDetails.beforeBalanceCol',
            },
            {
              Header: 'Balance apres',
              accessor: 'loggerDetails.afterBalanceCol',
            },
            {
              Header: 'Commission avant',
              accessor: 'loggerDetails.beforeCommissionCol',
            },
            {
              Header: 'Commission apres',
              accessor: 'loggerDetails.afterCommissionCol',
            },
            {
              Header: 'Montant',
              accessor: 'loggerDetails.amountCol',
            },
            // {
            //   Header: 'dernier modification',
            //   accessor: 'loggerDetails.oldDateValidateCol'
            // },
            {
              Header: 'Type de virment',
              accessor: 'loggerDetails.virmentTypeCol',
            },
          ]}
          title="Audit Log List"
          isLoading={isLoading}
          loadItemsList={loadAuditList}
          // actionButtons={{
          // actionButtons={{
          //     view: {
          //       color: 'info',
          //       type: 'view',
          //       component: ViewItemComponent,
          //     },
          //     edit: {
          //       color: 'success',
          //       type: 'edit',
          //       component: EditServiceCategoryComponent,
          //     },
          //     delete: { color: 'danger', type: 'delete' },
          //   }}
          onPaginate={handlePaginate}
          isPaginate={true}
        />
      )}
    </>
  );
}
