import React, { useState, useEffect } from 'react';
import { useAlert } from 'react-alert';
import DataTableContainerComponent from '../../../components/DataTableSortingContainer.component';
import {
  getCategoryList,
  updateStockInCategoryList,
} from '../services/serverAPI.service';
import CreatePublicityComponent from '../components/CreateCategoryList';
import SwitchComponent from '../../../components/Switch.component';
import DeleteCategoryListComponent from '../components/DeleteCategoryList.component';
import EditeCategoryListComponent from '../components/EditeCategoryList.component';
import { getFlags } from '../../delivery-info/services/serverApi.service';
import ModalComponent from '../../../components/Modal.component';
import AddFlagComponent from '../components/addFlag.component';
import { addFlagSrc } from '../mock';

export default function CategoryList() {
  const alert = useAlert();
  const id = localStorage.getItem('storeId');
  const [resultsData, setResultsData] = useState({
    total: 0,
    items: [],
    tableItems: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [flag, setFlag] = useState([]);
  const [productCategory, setProductCategory] = useState([]);
  const [openModal, setOpenModal] = useState('');
  const [item, setItem] = useState([]);
  const [IndexItem, setIndexItem] = useState(-1);

  const [page, setPage] = useState(0);
  // const pages = [100, 250, 500];
  let numberOfRows = [10, 50, 100];
  const [rowsPerPage, setRowsPerPage] = useState(numberOfRows[0]);

  const featchFalg = () => {
    getFlags(0, 0, false).then(({ data }) => {
      setFlag(data);
    });
  };
  const handleOpenModal = (item, index, type) => {
    setItem(item);
    setIndexItem(index);
    setOpenModal(type);
  };
  const closeModal = () => {
    setOpenModal('');
  };
  const showProductCategory = (item, index) => {
    return {
      ...item,
      name: item.name || '--',
      description: item.description ? item.description : '--',
      inStock: (
        <SwitchComponent
          onChange={(value) => updateStocks(value, item)}
          value="checked"
          checked={item.inStock}
          label="InStock"
        />
      ),
      imageUrl: item.imageUrl ? (
        <img src={item.imageUrl} alt="" border="3" height="50" width="60" />
      ) : (
        '--'
      ),
      flagCell: (
        <div
          style={{ cursor: 'pointer', textAlign: 'center' }}
          onClick={() => {
            handleOpenModal(item, index, 'flag');
          }}
        >
          {item?.flag || '--'}
        </div>
      ),
    };
  };
  const loadCategoryList = (skip, limit) => {
    setIsLoading(true);
    getCategoryList(id, skip, limit)
      .then(({ data }) => {
        setProductCategory(data);
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setIsLoading(false);
      });
  };
  const updateStocks = (value, item) => {
    (item.inStock = value),
      updateStockInCategoryList(item)
        .then(() => {
          alert.show('stock updated', {
            type: 'success',
          });
          loadCategoryList();
        })
        .catch((error) => {
          alert.show(error.message, {
            type: 'error',
          });
        });
  };
  useEffect(() => {
    featchFalg();
    loadCategoryList();
  }, []);
  useEffect(() => {
    productCategory &&
      setResultsData({
        items: (productCategory || []).map((item, index) => {
          return showProductCategory(item, index);
        }),
      });
  }, [JSON.stringify(productCategory)]);
  return (
    <>
      <DataTableContainerComponent
        total={resultsData.total}
        resultsData={resultsData}
        initData={resultsData.items}
        isPaginate={false}
        columns={[
          {
            Header: 'Name',
            accessor: 'name',
          },
          {
            Header: 'Image',
            accessor: 'imageUrl',
          },
          {
            Header: 'Description',
            accessor: 'description',
          },
          {
            Header: 'Stock',
            accessor: 'inStock',
          },
          {
            Header: 'flag',
            accessor: 'flagCell',
          },
          {
            Header: 'actions',
            accessor: 'actions',
          },
        ]}
        title="category List"
        isLoading={isLoading}
        loadItemsList={loadCategoryList}
        CreateItemFormComponent={CreatePublicityComponent}
        actionButtons={{
          edit: {
            color: 'success',
            type: 'edit',
            component: EditeCategoryListComponent,
          },
          delete: {
            color: 'danger',
            type: 'delete',
            component: DeleteCategoryListComponent,
          },
        }}
      />
      {openModal && openModal === 'flag' && (
        <ModalComponent
          maxWidth={'sm'}
          close={closeModal}
          visible={openModal === 'flag'}
          title="flag"
        >
          <AddFlagComponent
            result={item}
            // row={item}
            setState={setProductCategory}
            state={productCategory}
            close={closeModal}
            index={IndexItem}
            flags={flag}
            src={addFlagSrc.productCategory}
          />
        </ModalComponent>
      )}
    </>
  );
}
