import React, { useState, useEffect } from 'react';
import { Box, Grid, TextField } from '@material-ui/core';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import CustomButton from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import Card from '../../../components/material-dashboard-pro-react/Card/Card';
import CardHeader from '../../../components/material-dashboard-pro-react/Card/CardHeader';
import CardBody from '../../../components/material-dashboard-pro-react/Card/CardBody';
import AsyncSelect from 'react-select/async';
import { getStores, getOrders } from '../services/serverApi.service';
import FormControl from '@material-ui/core/FormControl';
import moment from 'moment';
import DataTableSortingContainer from '../../../components/DataTableSortingContainer.component';
import { useAlert } from 'react-alert';
import { statusCode } from '../mock';
import * as XLSX from 'xlsx';

export default function ExelHistory() {
  const alert = useAlert();
  const [orders, setOrders] = useState([]);
  const [dataExel, setDataExel] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [store, setStore] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [resultsData, setResultsData] = useState({
    total: 0,
    items: [],
  });

  const columns = [
    {
      Header: 'Id Cmd',
      accessor: 'shortId',
    },
    {
      Header: 'Store',
      accessor: 'storeName',
    },
    {
      Header: 'Client',
      accessor: 'clientName',
    },
    {
      Header: 'Ville',
      accessor: 'city',
    },
    {
      Header: 'Customer.S',
      accessor: 'customerStatusInp',
    },
  ];

  const prepareToShowHistory = (item, index) => {
    return {
      ...item,
      storeName: item?.store?.name,
      clientName: item?.payment?.customer?.name,
      deliveryManInp: item?.deliveryMan && item?.deliveryMan.name,
      city: item?.city?.name,
      customerStatusInp: item?.customerStatus,
    };
  };

  let timer1;
  const fetchData = (inputValue, callback) => {
    clearTimeout(timer1);
    timer1 = setTimeout(() => {
      inputValue &&
        getStores(inputValue)
          .then(({ data }) => {
            const tempArray = [];
            if (data.stores.length) {
              data.stores.forEach((element) => {
                tempArray.push({
                  label: `${element.name} / ${element?.phone} / ${element?.city_detail?.name}`,
                  name: `${element.name.substring(0,27)}`,
                  _id: element._id,
                });
              });
            }

            callback(tempArray);
          })
          .catch((error) => {
            console.log(error);
          });
    }, 2000);
  };

  const dataCsv = (data) => {
    let ModifyData = data.orders.map((item) => {
      return {
        Date: moment(item.createdAt).format('DD/MM/YYYY'),
        StoreName: item?.store?.name,
        Phone: item?.store?.phone,
        City: item?.city?.name,
        ID: item?._id,
        CustomerStatus: item?.customerStatus,
        Commission: item?.payment?.storeCommissionType,
        PricePaid: item?.payment?.pricePaid,
        OriginaPrice: item?.payment?.originalOrderPrice,
      };
    });
    setDataExel(ModifyData);
  };
  const getOrdersCompleted = (storeId, dateStart, dateEnd) => {
    let dateStartFormat = dateStart + 'T00:00:00';
    let dateEndFormat = dateEnd + 'T23:59:59';
    getOrders(storeId, dateStartFormat, dateEndFormat)
      .then(({ data }) => {
        setIsLoading(true);
        setOrders(data.orders);
        dataCsv(data);
      })
      .catch((error) => {
        if (error?.response?.status == statusCode.NOT_FOUND) {
          alert.show('Aucune Store trouvée', {
            type: 'error',
          });
        }
      })
      .finally(() => setIsLoading(false));
  };
  useEffect(() => {
    orders &&
      orders.length > 0 &&
      setResultsData({
        items: orders.map((item, index) => {
          return prepareToShowHistory(item, index);
        }),
      });
  }, [orders]);
  const EportExel = () => {
    let worksheet = XLSX.utils.json_to_sheet(dataExel);
    let exl = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      exl,
      worksheet,
      `${JSON.stringify(store?.name)}`,
    );
    var wscols = [
      { wch: 20 }, // "characters"
      { wch: 20 }, // "characters"
      { wch: 20 }, // "characters"
      { wch: 20 }, // "characters"
      { wch: 25 }, // "characters"
      { wch: 15 }, // "characters"
      { wch: 15 }, // "characters"
      { wch: 15 }, // "characters"
      { wch: 15 }, // "characters"
    ];
    worksheet['!cols'] = wscols;
    XLSX.writeFile(exl, `${store?.name}_${startDate}_${endDate}.xlsx`);
  };
  return (
    <>
      {resultsData?.items && resultsData?.items.length > 0 && (
        <div
          onClick={() => {
            EportExel();
          }}
          style={{
            float: 'right',
            backgroundColor: 'green',
            padding: '10px',
            borderRadius: '30px',
            marginBottom: '10px',
            cursor: 'pointer',
          }}
        >
          <span style={{ color: 'white' }}>Exel Rapport</span>
        </div>
      )}

      <Card>
        <CardBody>
          <Grid container alignItems="center">
            <Grid item xs={3} className="mb-3">
              <AsyncSelect
                single
                isClearable
                defaultValue={inputValue}
                loadOptions={fetchData}
                placeholder="find Store"
                onChange={(e) => {
                  console.log(e);
                  if (e) {
                    setStore(e);
                  } else {
                    setStore(null);
                  }
                }}
              />
            </Grid>

            <Grid item xs={3} className="mb-3 text-center">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  renderInput={(props) => <TextField {...props} />}
                  label="Start date"
                  ampm={false}
                  value={startDate}
                  onChange={(newValue) => {
                    setStartDate(
                      moment(newValue).format().split('+')[0].split('T')[0],
                    );
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={3} className="mb-3 text-center">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  renderInput={(props) => <TextField {...props} />}
                  label="End Date"
                  ampm={false}
                  value={endDate}
                  onChange={(newValue) => {
                    setEndDate(
                      moment(newValue).format().split('+')[0].split('T')[0],
                    );
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={2} container className="float-right">
              <Box textAlign="right">
                <CustomButton
                  round
                  type="submit"
                  color="success"
                  size="sm"
                  loaderColor="white"
                  onClick={() => {
                    getOrdersCompleted(store?._id, startDate, endDate);
                  }}
                >
                  find
                </CustomButton>
              </Box>
              <Box textAlign="right">
                <CustomButton
                  round
                  type="submit"
                  color="google"
                  size="sm"
                  loaderColor="white"
                  onClick={() => {
                    setStartDate(null);
                    setEndDate(null);
                    setResultsData({
                      items: [],
                    });
                  }}
                >
                  clear
                </CustomButton>
              </Box>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <DataTableSortingContainer
              resultsData={resultsData}
              initData={resultsData.items}
              columns={columns}
              isLoading={isLoading}
            />
          </Grid>
        </CardBody>
      </Card>
    </>
  );
}
