import {GET_DELIVERY_MEN_REQUEST,GET_DELIVERY_MEN_SUCCESS,GET_DELIVERY_MEN_FAIL,CHANGE_DELIVERY_MEN_LOCATION,RESET_DELIVERY_MEN} from '../constants/deliveryMan.constants'

export const getDeliveryRequestAction = (cityId) =>({
    type:GET_DELIVERY_MEN_REQUEST,
    cityId
})
export const getDeliverySuccessAction = (deliveryMen) =>({
    type:GET_DELIVERY_MEN_SUCCESS,
    deliveryMen
})
export const getDeliveryFailAction = (error) =>({
    type:GET_DELIVERY_MEN_FAIL,
    error
})
export const changeDeliveryStatusAction = (delivery) =>({
    type:CHANGE_DELIVERY_MEN_LOCATION,
    delivery
})
export const resetDeliveryMenAction = () =>({
    type:RESET_DELIVERY_MEN,
})
