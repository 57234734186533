import {
  takeEvery,
  takeLatest,
  put,
  all,
  fork,
  call,
} from 'redux-saga/effects';
import axios from 'axios';
import {
  getOrderSuccessAction,
  getOrderFailAction,
  getOrderFailV2Action,
  getOrderSuccessV2Action,
} from './actions/order.actions';
import {
  GET_ORDER_REQUEST,
  GET_ORDER_REQUESTV2,
} from './constants/order.constants';
import { getAdminsListSuccessAction, getAdminsListFailAction } from './actions/adminsList.actions'
import { GET_ADMINS_LIST_REQUEST } from './constants/adminsList.constants'
import { MICROSERVICE_BASE_URL } from '../../../config/backendServer.config';
import { getCurrentOrders } from '../services/serverApi.service';

let dataOrders = [];
let dataOrdersObj = {};
function* getAdminsListFromAPI() {
  const { data } = yield axios.get(
    `${MICROSERVICE_BASE_URL.ADMIN}/admin?page=${0}&limit=${0
    }&paged=${false}`,
  );

  return data
}
function* getOrdersAllFromAPI(cityId) {
  const { data } = yield axios.get(
    `${MICROSERVICE_BASE_URL.DISPATCHING}/order/city/${cityId}/current-orders`,
  );

  dataOrders.push(...data);
  // // const tempObj = {};
  // dataOrdersObj[cityId] = data
  // // dataOrdersObj.push(tempObj);
  // console.log(dataOrdersObj);
  return data;
}
function* getOrdersFromAPI(cities) {
  const { data } = yield axios.post(
    `${MICROSERVICE_BASE_URL.DISPATCHING}/order/city/current-orders`,
    { cities: cities },
  );

  dataOrders.push(...data);
  // // const tempObj = {};
  // dataOrdersObj[cityId] = data
  // // dataOrdersObj.push(tempObj);
  // console.log(dataOrdersObj);
  return data;
}
function* getOrders(payload) {
  // console.log(payload);
  const { cities } = payload;
  console.log('************cities***************', cities);
  try {
    let data;
    if (cities.includes('all')) {
      data = yield getOrdersAllFromAPI('all');
    } else {
      data = yield getOrdersFromAPI(cities);
    }
    // yield all(cities.map(cityId => call(getOrdersFromAPI, cityId)))
    // const data = yield getOrdersFromAPI(cities)
    // console.log(data);
    yield put(getOrderSuccessAction(data));
    // yield dataOrders = []
  } catch (error) {
    console.log(error.message);
    yield put(getOrderFailAction(error));
  }
}
function* getOrdersV2(payload) {
  const { cities } = payload;
  try {
    let data;
    if (cities.includes('all')) {
      data = yield getOrdersAllFromAPI('all');
    } else {
      data = yield getOrdersFromAPI(cities);
    }
    yield put(getOrderSuccessV2Action(data));
  } catch (error) {
    console.log(error.message);
    yield put(getOrderFailV2Action(error));
  }
}
function* getAdminsList() {
  try {
    let data = yield getAdminsListFromAPI();

    yield put(getAdminsListSuccessAction(data));
  } catch (error) {
    console.log(error.message);
    yield put(getAdminsListFailAction(error));
  }
}

export default function* watcherSage() {
  yield takeLatest(GET_ORDER_REQUEST, getOrders);
  yield takeLatest(GET_ORDER_REQUESTV2, getOrdersV2);
  yield takeLatest(GET_ADMINS_LIST_REQUEST, getAdminsList);
}
