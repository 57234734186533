import React from 'react';
import { statusBackgroundColor, sharedStyleForStatus } from '../mock';
import {
  CustomerStatus,
  deliveryStatus,
  orderDispatchingStatus,
  StoreStatus,
} from '../../../config/status.config';
import { styled } from '@mui/material/styles';
import { TableCell, TableRow } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip arrow {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));
export default function orderStatusComponent({
  dispatchingStatus,
  deliveryManStatus,
  startedPreparation,
  storeStatus,
  customerStatus,
}) {
  const renderStatus = (
    dispatchingStatus,
    deliveryManStatus,
    startedPreparation,
  ) => {
    const colors = {
      WAITING_FOR_ACCEPT: '#ffc107',
      ACCEPTED: '#ff7986',
      START_PREPARATION: startedPreparation ? '#3496ff' : '#6c757d',
      COMING_FOR_DELIVERY: startedPreparation ? '#3496ff' : '#6c757d',
      ARRIVED_TO_PICKUP: startedPreparation ? '#3496ff' : '#6c757d',
      UPLOAD_INVOICE: '#46e06a',
      STARTED_DELIVERY: '#46e06a',
      ARRIVED_TO_DESTINATION: '#46e06a',
      DELIVERED: '#46e06a',
      VALID: '',
      ON_HOLD: '#f246f4',
      DISPATCHING: '',
      DISPATCHED: '#3496ff',
      ASSIGNED: '#3496ff',
      SYNC_PREPARATION_TIME: '#6c757d',
      WAITING_FOR_ACCEPT_STORE: '#ffc107',
      MANUEL: '#6c757d',
    };

    return (
      <span
        style={{
          backgroundColor:
            colors[
              dispatchingStatus === 'DISPATCHED'
                ? deliveryManStatus
                : dispatchingStatus
            ] || 'red',
          width: '120px',
          fontSize: '11px',
          padding: '5px',
          borderRadius: '4px',
          color: 'white',
        }}
      >
        {dispatchingStatus === 'DISPATCHED'
          ? deliveryStatus[deliveryManStatus] || deliveryManStatus
          : orderDispatchingStatus[dispatchingStatus] || dispatchingStatus}
        {!startedPreparation &&
          (deliveryManStatus == 'COMING_FOR_DELIVERY' ||
            deliveryManStatus == 'ARRIVED_TO_PICKUP' ||
            deliveryManStatus == 'UPLOAD_INVOICE') &&
          ' NP'}
      </span>
    );
  };
  return (
    <div>
      <HtmlTooltip
        title={
          <React.Fragment>
            <TableRow>
              <TableCell align="center">dispatching</TableCell>
              <TableCell align="center">
                <span
                  style={{
                    backgroundColor:
                      dispatchingStatus == 'ON_HOLD'
                        ? statusBackgroundColor.ON_HOLD
                        : statusBackgroundColor.INVALID,
                    ...sharedStyleForStatus,
                  }}
                >
                  {orderDispatchingStatus[dispatchingStatus] ||
                    dispatchingStatus ||
                    ''}
                </span>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center">deliveryMan</TableCell>
              <TableCell align="center">
                <span
                  style={{
                    backgroundColor:
                      statusBackgroundColor[deliveryManStatus] || 'red',
                    ...sharedStyleForStatus,
                  }}
                >
                  {deliveryStatus[deliveryManStatus] || deliveryManStatus || ''}
                </span>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center">store</TableCell>
              <TableCell align="center">
                <span
                  style={{
                    backgroundColor:
                      statusBackgroundColor[storeStatus] || 'red',
                    ...sharedStyleForStatus,
                  }}
                >
                  {StoreStatus[storeStatus] || storeStatus || ''}
                </span>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center">customer</TableCell>
              <TableCell align="center">
                <span
                  style={{
                    backgroundColor:
                      statusBackgroundColor[customerStatus] || 'red',
                    ...sharedStyleForStatus,
                  }}
                >
                  {CustomerStatus[customerStatus] || customerStatus || ''}
                </span>
              </TableCell>
            </TableRow>
          </React.Fragment>
        }
      >
        {renderStatus(dispatchingStatus, deliveryManStatus, startedPreparation)}
      </HtmlTooltip>
    </div>
  );
}
