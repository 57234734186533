import React, { useState, useEffect } from 'react'
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import Switch from '@material-ui/core/Switch';
import { makeStyles } from '@material-ui/core/styles';
import { FormLabel, Box } from '@material-ui/core';
import { defaultImagesAction } from '../../../redux/uploadRedux/upload.action'
import CustomInput from '../../../components/material-dashboard-pro-react/CustomInput/CustomInput'
import ImageUpload from '../../../components/material-dashboard-pro-react/CustomUpload/ImageUpload'
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button'
import { updateStore } from '../services/serverApi.service';
import styles from '../../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle';

const useStyles = makeStyles(styles);

const validationSchema = yup.object({
  location: yup.array().required(),
  name: yup.string().required(),
  displayName: yup.string().required(),
  phone: yup.string().required(),
  email: yup.string().required(),
  isBusiness: yup.bool().required(),
  open: yup.bool().required(),
  approved: yup.bool().required(),
  isPartner: yup.bool().required(),
  cityId: yup.string().required(),
  serviceId: yup.string().required(),
  address: yup.string().required(),
  commission: yup.number().required(),
  rewardPoints: yup.number().required(),
  storeRewardPoints: yup.number().required(),
  preparationTimeMin: yup.number().required(),
  preparationTimeMax: yup.number().required(),
  maxDisatnce: yup.number().required(),
  kaalixLoyalty: yup.number().required(),
  storeLoyalty: yup.number().required(),
  commissionType: yup.string().required(),
  imageUrl: yup.string().required(),
})

export default function EditStoreImgComponent({ store, index, stores, setStores, close }) {
  const [isLoading, setIsLoading] = useState(false)
  const [displayImage, setDisplayImage] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const upload = useSelector((state) => state.uploadReducer)
  const { iconUrl, error, loading } = upload
  const formik = useFormik({
    initialValues: {
      location: [],
      name: '',
      displayName: '',
      phone: '',
      contact: '',
      email: '',
      isBusiness: false,
      open: false,
      approved: false,
      isPartner: false,
      cityId: '',
      serviceId: '',
      address: '',
      commission: 0,
      rewardPoints: 0,
      storeRewardPoints: 0,
      preparationTimeMin: 0,
      preparationTimeMax: 0,
      maxDisatnce: 0,
      kaalixLoyalty: 0,
      storeLoyalty: 0,
      commissionType: '',
      imageUrl: ""
    },
    validationSchema,
  });
  const editStore = () => {
    console.log(formik.values)
    // store.location = formik.values.location
    setIsLoading(true)
    updateStore(store._id, formik.values)
      .then(({ data }) => {
        console.log(data);
        let newStores = [...stores]
        newStores[index] = data.store
        // newStores[index].city_detail = store?.city_detail
        setStores(newStores)
        close()
      })
      .catch((error) => {
        console.log(error.message, {
          type: 'error',
        });
      })
      .finally(() => setIsLoading(true))
  }
  console.log("store", store);
  const setValues = () => {
    !iconUrl && dispatch(defaultImagesAction(store?.imageUrl))
    formik.setValues({
      location: store.location,
      name: store?.name,
      displayName: store.displayName,
      phone: store.phone,
      email: store.email,
      contact: store.contact,
      isBusiness: store.isBusiness,
      open: store.open,
      approved: store.approved,
      isPartner: store.isPartner,
      cityId: store.cityId,
      serviceId: store.serviceId,
      address: store.address,
      commission: store.commission,
      rewardPoints: store.rewardPoints,
      storeRewardPoints: store.storeRewardPoints,
      preparationTimeMin: store.preparationTimeMin,
      preparationTimeMax: store.preparationTimeMax,
      maxDisatnce: store.maxDisatnce,
      kaalixLoyalty: store.kaalixLoyalty,
      storeLoyalty: store.storeLoyalty,
      commissionType: store.commissionType,
      imageUrl: iconUrl
    })
  }

  useEffect(() => {
    setValues()
  }, [iconUrl])
  // console.log(formik.values.location);
  console.log(formik.errors);
  console.log(formik.values.imageUrl);
  return (
    <div>
      <div>{!displayImage && <span>you need to upload a new image</span>}
        <Switch
          color='primary'
          checked={displayImage}
          value={displayImage}
          onChange={e => { displayImage ? setDisplayImage(false) : setDisplayImage(true) }}
        />
      </div>
      <Box display="flex" justifyContent="center">
        {Boolean(store) && (
          !displayImage ?

            <img
              src={store?.imageUrl || ''}
              alt="img" height="100" width="120" />

            : <ImageUpload
              label="Select icon"
              avatar
              folder="service"
            />
        )}
      </Box>
      <FormLabel className={classes.labelHorizontal}>name</FormLabel>
      {/* </GridItem> */}
      {/* <GridItem xs={12} sm={12} md={9}> */}
      <CustomInput
        formControlProps={{
          fullWidth: true,
        }}
        type="text"
        error={formik.touched.name && Boolean(formik.errors.name)}
        inputProps={{
          value: formik.values.name,
          onBlur: formik.handleBlur('name'),
          onChange: formik.handleChange('name'),
        }}
      />
      <FormLabel className={classes.labelHorizontal}>email</FormLabel>
      <CustomInput
        formControlProps={{
          fullWidth: true,
        }}
        type="text"
        error={formik.touched.email && Boolean(formik.errors.email)}
        inputProps={{
          value: formik.values.email,
          onBlur: formik.handleBlur('email'),
          onChange: formik.handleChange('email'),
        }}
      />
      <FormLabel className={classes.labelHorizontal}>contact</FormLabel>
      <CustomInput
        formControlProps={{
          fullWidth: true,
        }}
        type="text"
        error={formik.touched.contact && Boolean(formik.errors.contact)}
        inputProps={{
          value: formik.values.contact,
          onBlur: formik.handleBlur('contact'),
          onChange: formik.handleChange('contact'),
        }}
      />
      <FormLabel className={classes.labelHorizontal}>address</FormLabel>
      <CustomInput
        formControlProps={{
          fullWidth: true,
        }}
        type="text"
        error={formik.touched.address && Boolean(formik.errors.address)}
        inputProps={{
          value: formik.values.address,
          onBlur: formik.handleBlur('address'),
          onChange: formik.handleChange('address'),
        }}
      />
      {/* </GridItem>
      </GridContainer> */}
      <FormLabel className={classes.labelHorizontal}>
        latitude
      </FormLabel>
      <CustomInput
        formControlProps={{
          fullWidth: true,
        }}
        type="number"
        error={
          formik.touched.location && Boolean(formik.errors.location)
        }
        inputProps={{
          value: formik.values.location[0],
          // onBlur: formik.handleBlur('name'),
          // onChange: formik.handleChange('name'),
          onChange: e => { e.target.value != 0 && formik.setFieldValue('location[0]', e.target.value) }
        }}
      />
      <FormLabel className={classes.labelHorizontal}>
        longitude
      </FormLabel>
      <CustomInput
        formControlProps={{
          fullWidth: true,
        }}
        type="number"
        error={
          formik.touched.location && Boolean(formik.errors.location)
        }
        inputProps={{
          value: formik.values.location[1],
          // onBlur: formik.handleBlur('name'),
          // onChange: formik.handleChange('name'),
          onChange: e => { e.target.value != 0 && formik.setFieldValue('location[1]', e.target.value) }
        }}
      />

      <Box textAlign="center">
        <Button
          round
          type="submit"
          color="success"
          disabled={!formik.dirty || !formik.isValid || isLoading}
          loading={isLoading}
          loaderColor="white"
          onClick={editStore}
        >
          save
        </Button>
      </Box>
    </div>
  )
}
