import React, { useEffect, useState } from 'react';
// import { useSelector } from 'react-redux';
// import { useAlert } from 'react-alert';
// import { getUserDataState } from '../../auth/redux/selectors'
// import TablePagination from '@material-ui/core/TablePagination';
import { Box, Grid, Modal } from '@material-ui/core';
// import { getCustmore, approuvedCustmore } from '../services/serverApi.customer';
import DataTableContainerComponent from '../../../components/DataTableSortingContainer.component';
import { formatTime } from '../../../helpers/index';

import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import CustomButton from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import SwitchComponent from '../../../components/Switch.component';
import { getTemplateNonQualities } from '../services/serverApi.service';
import CreateTemplate from '../components/CreateTemplate.component';
import EditTemplate from '../components/EditTemplate.component';
import DeleteTemplate from '../components/DeleteTemplate.component';

export default function TemplatePage() {
  // const alert = useAlert();
  // const userData = useSelector(getUserDataState);
  const inpRef = React.useRef();
  const [resultsData, setResultsData] = useState({
    total: 0,
    items: [],
    tableItems: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  // const classes = useStyles();
  // const pages = [10, 250, 500]
  const [page, setPage] = useState(0);
  let numberOfRows = [20, 50, 100];
  const [rowsPerPage, setRowsPerPage] = useState(numberOfRows[0])
  const [openModal, setOpenModal] = useState('');
  const [customers, setCustomers] = useState([]);
  const [template, setTemplate] = useState([]);
  const [indexCustomer, setIndexCustomer] = useState(-1);
  const [searchText, setSearchText] = useState('');
  const [total, setTotal] = useState(0);
  const [subPagination, setSubPagination] = useState([]);


  const prepareShowTemplateList = (item, index) => {
    return {
      ...item,
      createdAtCol: item.createdAt ? formatTime(item.createdAt) : '--',
      templateNameCol: item.templateName,
      responsableCol: item.templateResponsable,
      destinationCol: item.templateDestination,
      typeCol: item.templateType,
      amountCol: item.templateAmount,
      directionCol: item.templateDirection,
      // actionsCol: <Button>test</Button>
    }
  }

  const loadTemplateList = (page, limit, txt) => {
    setIsLoading(true);
    getTemplateNonQualities(page || 0, limit || 20, true, txt)
      .then(({ data }) => {
        // console.log(data);
        setTotal(data.total);
        setTemplate(data.templates);
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setIsLoading(false);
      });
  };

  const SearchComponent = () => {
    return (
      <Grid container>
        <Grid item xs={8}>
          <input type='text'
            ref={inpRef}
            placeholder='search'
            style={{
              border: "none",
              borderBottom: '1px solid black'
            }}
            defaultValue={searchText}

          />
        </Grid>
        <Grid item xs={4} container justify="flex-end"
        >

          <Box textAlign="left">
            <CustomButton
              round
              type="submit"
              color="success"
              size="sm"
              loaderColor="white"
              // onClick={storeSearch}
              onClick={() => {
                setSearchText(inpRef.current.value)
                page > 0 && setPage(0)
                loadTemplateList(page > 0 ? 0 : page, rowsPerPage, inpRef.current.value)
              }
              }
            >
              find
            </CustomButton>
          </Box>
          <Box textAlign="left">
            <CustomButton
              round
              type="submit"
              color="google"
              size="sm"
              loaderColor="white"
              // onClick={loadTemplateList}
              onClick={() => {
                setSearchText('')
                page > 0 && setPage(0)
                loadTemplateList(page > 0 ? 0 : page, rowsPerPage, "")
              }}
            >
              clear
            </CustomButton>
          </Box>
        </Grid>
      </Grid>
    )
  }

  useEffect(() => {
    template && setResultsData({
      items: (template || []).map((item, index) => {
        // console.log(item);
        return prepareShowTemplateList(item, index);
      }),
    })
  }, [template])
  const handlePaginate = (val) => {
    // console.log(val,'val');
    // console.log(val.pageSelect, val.numberOfRows);
    setResultsData({
      items: [],
      tableItems: [],
    });
    loadTemplateList(val.pageSelect, val.numberOfRows, searchText);
    setPage(val.pageSelect);
    setRowsPerPage(val.numberOfRows);
  };


  useEffect(() => {
    if (total) {
      const pages = Math.ceil(total / rowsPerPage);
      if (total > 2000) {
        console.log(total);
        const newPages = [];
        // const start = page==0?page:Number(page-1)
        let start;
        let end;
        const handelStart = () => {
          let startNumber;
          if (page == 0) startNumber = page
          else if (page > 0) {
            if (page - 100 < 0) startNumber = 0
            else startNumber = Number(page - 100)
          }
          return startNumber
        }
        const handelEnd = () => {
          let endNumber;
          if ((page + 100) < pages) endNumber = page + 100
          else endNumber = page + (pages - page)
          return endNumber
        }
        start = handelStart()
        end = handelEnd()
        for (let i = start; i < end; i++) {
          newPages.push(i)
        }
        setSubPagination(newPages);
      } else {
        setSubPagination([])
      }
    }
  }, [total, page]);
  // console.log(subPagination);

  // const editComponent = () => {}

  useEffect(() => {
    loadTemplateList();
  }, [])

  return (
    <>
      {subPagination &&
        <DataTableContainerComponent
          total={total}
          subPagination={subPagination}
          // numberOfRowsProps={rowsPerPage}
          resultsData={resultsData}
          page={page + 1}
          initData={resultsData.items}
          searchInp={true}
          SearchComponent={SearchComponent}
          columns={[
            {
              Header: 'CreatedAt',
              accessor: 'createdAtCol',
            },
            {
              Header: 'Nom de template',
              accessor: 'templateNameCol'
            },
            {
              Header: 'Responsable',
              accessor: 'responsableCol',
            },
            {
              Header: 'Destination',
              accessor: 'destinationCol',
            },
            {
              Header: 'Type',
              accessor: 'typeCol',
            },
            {
              Header: 'Montant',
              accessor: 'amountCol'
            },
            {
              Header: 'Direction remboursement',
              accessor: 'directionCol',
            },
            {
              Header: 'Actions',
              accessor: 'actions'
            }
          ]}
          title="Template Non Qualities List"
          isLoading={isLoading}
          loadItemsList={loadTemplateList}
          onPaginate={handlePaginate}
          isPaginate={true}
          CreateItemFormComponent={CreateTemplate}
          actionButtons={{
            edit: {
              color: 'success',
              type: 'edit',
              component: EditTemplate
            },
            delete: {
              color: 'danger',
              type: 'delete',
              component: DeleteTemplate
            }
          }}
        />
      }

    </>
  );
}