import React,{ useEffect, useState} from 'react'
import {Box} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import {useFormik} from 'formik'
import * as yup from 'yup';
import CustomInput from '../../../components/material-dashboard-pro-react/CustomInput/CustomInput'
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button'
import { getRatingList,setRatingList } from '../services/serverApi.service'
import SwitchComponent from '../../../components/Switch.component';
import SelectBoxComponent from '../../../components/SelectBox.component'
import { selectNumbers } from '../mocks';
const validationSchema = yup.object({
    name: yup.string().required()
  });
export default function KaalixAppRatingList() {
    const [dataRatingDelivery, setDataRatingDelivery] = useState({ "deliveryManRatingList": {},"kaalixAppRatingList":{},"storeRatingList":{}});
    const [isLoading,setIsLoading]= useState(false)
    const [appStores,setAppStores]= useState('')
    const [displays,setDisplays]= useState('')
    const [selectNumber,setSelectNumber]= useState('')

   

    const formik = useFormik({
        initialValues: {
            kaalixROne: '',
            kaalixRTwo: '',
            kaalixRThree: '',
            kaalixRFour: '',
            kaalixRFive: '',
            numberSelected: '',
           
          
          },
          validationSchema,
    })
    const getRating = ()=>{
        getRatingList()
        .then(({ data }) => {
        setDataRatingDelivery({ "deliveryManRatingList": data.ratingList.deliveryManRatingList, "kaalixAppRatingList": data.ratingList.kaalixAppRatingList ,"storeRatingList": data.ratingList.storeRatingList });
        setAppStores(data.ratingList.kaalixAppRatingList.appStore)
        setDisplays(data.ratingList.kaalixAppRatingList.display)
        setSelectNumber(data.ratingList.kaalixAppRatingList.starsNumber);

        formik.setValues({
            numberSelected: data.ratingList.kaalixAppRatingList.starsNumber,
           
          })
        })
        .catch((err) => console.log(err));
    }
    const saveRating = (type,val)=>{
     const tempSatate = dataRatingDelivery;
    if(type === 'kaalixROne' && formik.values.kaalixROne !== ''){
        tempSatate.kaalixAppRatingList['1'].push(formik.values.kaalixROne );
        setRatingList(tempSatate).then(({ data }) => {
            formik.setFieldValue('kaalixROne', '');
            formik.resetForm();
        }
        ).catch((error) => console.log(error));
     
    } else if(type === 'kaalixRTwo' && formik.values.kaalixRTwo !== ''){
        tempSatate.kaalixAppRatingList['2'].push(formik.values.kaalixRTwo );
        setRatingList(tempSatate).then(({ data }) => {
            formik.setFieldValue('kaalixRTwo', '');
            formik.resetForm();
        }
        ).catch((error) => console.log(error));

    } else if(type === 'kaalixRThree' && formik.values.kaalixRThree !== ''){
        tempSatate.kaalixAppRatingList['3'].push(formik.values.kaalixRThree );
        setRatingList(tempSatate).then(({ data }) => {
            formik.setFieldValue('kaalixRThree', '');
            formik.resetForm();
        }
        ).catch((error) => console.log(error));
    
    }else if(type === 'kaalixRFour' && formik.values.kaalixRFour !== ''){
        tempSatate.kaalixAppRatingList['4'].push(formik.values.kaalixRFour );
        setRatingList(tempSatate).then(({ data }) => {
            formik.setFieldValue('kaalixRFour', '');
            formik.resetForm();
        }
        ).catch((error) => console.log(error));

    }else if(type === 'kaalixRFive' && formik.values.kaalixRFive !== ''){
        tempSatate.kaalixAppRatingList['5'].push(formik.values.kaalixRFive );
        setRatingList(tempSatate).then(({ data }) => {
            formik.setFieldValue('kaalixRFive', '');
            formik.resetForm();
                        

        }
        ).catch((error) => console.log(error));

    }else if(type === 'numberSelected' && formik.values.numberSelected !== ''){
        tempSatate.kaalixAppRatingList.starsNumber = formik.values.numberSelected;
         setRatingList(tempSatate).then(({ data }) => {
            formik.setValues({
                numberSelected: formik.values.numberSelected,
         })
         setSelectNumber(formik.values.numberSelected);
            formik.resetForm();          
        
        }
        ).catch((error) => console.log(error));
    
    }else if(type === 'appStores'){
        setAppStores(val)
        tempSatate.kaalixAppRatingList.appStore = val;
         setRatingList(tempSatate).then(({ data }) => {
        formik.resetForm();          

        }
        ).catch((error) => console.log(error));
    }else if(type === 'displays'){
        setDisplays(val)
        tempSatate.kaalixAppRatingList.display = val;
         setRatingList(tempSatate).then(({ data }) => {
        formik.resetForm();          

        }
        ).catch((error) => console.log(error));
    
    }

    
    }
    useEffect(() => {
        getRating()
    }, []);
    const deleteRating = (index,type) =>{
        const tempSatate = dataRatingDelivery;
   if(type === 'kaalixROne' && index !== -1){
       tempSatate.kaalixAppRatingList['1'].splice(index,1);
       setRatingList(tempSatate).then(({ data }) => {
              formik.resetForm();
         }
            ).catch((error) => console.log(error));

    } else if(type === 'kaalixRTwo' && index !== -1){
        tempSatate.kaalixAppRatingList['2'].splice(index,1);
        setRatingList(tempSatate).then(({ data }) => {
            formik.resetForm();
        }
        ).catch((error) => console.log(error));

    } else if(type === 'kaalixRThree' && index !== -1){
        tempSatate.kaalixAppRatingList['3'].splice(index,1);
        setRatingList(tempSatate).then(({ data }) => {
            formik.resetForm();
        }
        ).catch((error) => console.log(error));
         
    } else if(type === 'kaalixRFour' && index !== -1){

        tempSatate.kaalixAppRatingList['4'].splice(index,1);
        setRatingList(tempSatate).then(({ data }) => {
            formik.resetForm();
        }
        ).catch((error) => console.log(error));
    }else if(type === 'kaalixRFive' && index !== -1){

        tempSatate.kaalixAppRatingList['5'].splice(index,1);
        setRatingList(tempSatate).then(({ data }) => {
            formik.resetForm();
        }
        ).catch((error) => console.log(error));
    }
}

    return (
        <div>
        <div className="d-flex justify-content-between">
            <div>
                <h6 className="font-weight-bold">kaalixAppRating 1</h6>
            {(dataRatingDelivery?.kaalixAppRatingList['1']|| []).map((item,index)=>(
                <p>
                    <CloseIcon onClick={()=>deleteRating(index,'kaalixROne')} fontSize="small" style={{ color: 'red',cursor:'pointer' }}/>
                
                {item}
                    </p>
            ))}

           
            </div>


            <div>
            <h6 className="font-weight-bold">kaalixAppRating 2</h6>
            {(dataRatingDelivery?.kaalixAppRatingList['2'] || []).map((item,index)=>(
                <p>
                    <CloseIcon onClick={()=>deleteRating(index,'kaalixRTwo')} fontSize="small" style={{ color: 'red',cursor:'pointer' }}/>
                
                {item}
                    </p>
            ))}
           
            </div>
            <div>
            <h6 className="font-weight-bold">kaalixAppRating 3</h6>
            {(dataRatingDelivery?.kaalixAppRatingList['3']|| []).map((item,index)=>(
                <p>
                    <CloseIcon onClick={()=>deleteRating(index,'kaalixRThree')} fontSize="small" style={{ color: 'red',cursor:'pointer' }}/>
                
                {item}
                    </p>
            ))}
            
            </div>

            </div>

           <div className="d-flex justify-content-between">

             <div >
             <CustomInput
                labelText="Name"
                formControlProps={{
                    fullWidth: true,
                }}
                type="text"
                error={formik.touched.kaalixROne && Boolean(formik.errors.kaalixROne)}
                inputProps={{
                    value: formik.values.kaalixROne,
                    onBlur: formik.handleBlur('kaalixROne'),
                    onChange: formik.handleChange('kaalixROne'),
                }}
            />
            
            <div>
            <Box textAlign="center">
                <Button
                    round
                    type="submit"
                    color="success"
                    disabled={isLoading}
                    loading={isLoading}
                    size="sm"
                    loaderColor="white"
                    onClick={ ()=>saveRating('kaalixROne')}
                >
                    save
                </Button>
            </Box>
        </div>
            </div>
             
            <div >
             <CustomInput
                labelText="Name"
                formControlProps={{
                    fullWidth: true,
                }}
                type="text"
                error={formik.touched.kaalixRTwo && Boolean(formik.errors.kaalixRTwo)}
                inputProps={{
                    value: formik.values.kaalixRTwo,
                    onBlur: formik.handleBlur('kaalixRTwo'),
                    onChange: formik.handleChange('kaalixRTwo'),
                }}
            />
            
            <div >
            <Box textAlign="center">
                <Button
                    round
                    type="submit"
                    color="success"
                    disabled={isLoading}
                    loading={isLoading}
                    size="sm"
                    loaderColor="white"
                    onClick={ ()=>saveRating('kaalixRTwo')}

                >
                    save
                </Button>
            </Box>
        </div>
            </div>
            
            <div >
             <CustomInput
                labelText="Name"
                formControlProps={{
                    fullWidth: true,
                }}
                type="text"
                error={formik.touched.kaalixRThree && Boolean(formik.errors.kaalixRThree)}
                inputProps={{
                    value: formik.values.kaalixRThree,
                    onBlur: formik.handleBlur('kaalixRThree'),
                    onChange: formik.handleChange('kaalixRThree'),
                }}
            />
            
            <div>
            <Box textAlign="center">
                <Button
                    round
                    type="submit"
                    color="success"
                    disabled={isLoading}
                    loading={isLoading}
                    size="sm"
                    loaderColor="white"
                    onClick={ ()=>saveRating('kaalixRThree')}
                >
                    save
                </Button>
            </Box>
        </div>
            </div>
           

            </div>

           {/* start part two   */}
           <div className="d-flex justify-content-between mt-5">
            <div >
                <h6 className="font-weight-bold">kaalixAppRating 4</h6>
            {(dataRatingDelivery?.kaalixAppRatingList['4']|| []).map((item,index)=>(
                <p>
                    <CloseIcon onClick={()=>deleteRating(index,'kaalixRFour')} fontSize="small" style={{ color: 'red',cursor:'pointer' }}/>
                
                {item}
                    </p>
            ))}

           
            </div>


            <div >
            <h6 className="font-weight-bold">kaalixAppRating 5</h6>
            {(dataRatingDelivery?.kaalixAppRatingList['5'] || []).map((item,index)=>(
                <p>
                    <CloseIcon onClick={()=>deleteRating(index,'kaalixRFive')} fontSize="small" style={{ color: 'red',cursor:'pointer' }}/>
                
                {item}
                    </p>
            ))}
           
            </div>
            
             
           <div>
           <SwitchComponent
        onChange={(val)=>saveRating('appStores',val)}
        value="checked"
        checked={appStores}
        label={'appStore'}
      />
           <SwitchComponent
        onChange={(val)=>saveRating('displays',val)}
        value="checked"
        checked={displays}
        label={'display'}
      />
           </div>

            </div>




           <div className="d-flex justify-content-between">

             <div >
             <CustomInput
                labelText="Name"
                formControlProps={{
                    fullWidth: true,
                }}
                type="text"
                error={formik.touched.kaalixRFour && Boolean(formik.errors.kaalixRFour)}
                inputProps={{
                    value: formik.values.kaalixRFour,
                    onBlur: formik.handleBlur('kaalixRFour'),
                    onChange: formik.handleChange('kaalixRFour'),
                }}
            />
            
            <div>
            <Box textAlign="center">
                <Button
                    round
                    type="submit"
                    color="success"
                    disabled={isLoading}
                    loading={isLoading}
                    size="sm"
                    loaderColor="white"
                    onClick={ ()=>saveRating('kaalixRFour')}
                >
                    save
                </Button>
            </Box>
        </div>
            </div>
             
            <div >
             <CustomInput
                labelText="Name"
                formControlProps={{
                    fullWidth: true,
                }}
                type="text"
                error={formik.touched.kaalixRFive && Boolean(formik.errors.kaalixRFive)}
                inputProps={{
                    value: formik.values.kaalixRFive,
                    onBlur: formik.handleBlur('kaalixRFive'),
                    onChange: formik.handleChange('kaalixRFive'),
                }}
            />
            
            <div>
            <Box textAlign="center">
                <Button
                    round
                    type="submit"
                    color="success"
                    disabled={isLoading}
                    loading={isLoading}
                    size="sm"
                    loaderColor="white"
                    onClick={ ()=>saveRating('kaalixRFive')}

                >
                    save
                </Button>
            </Box>
        </div>
            </div>
            
          
            <div>
            <span className="font-weight-bold">Start number</span>
            <SelectBoxComponent
        items={selectNumbers.map((type) => ({
          value: type.label,
          label: type.label,
        }))}
        onValueChange={formik.handleChange('numberSelected')}
        value={formik.values.numberSelected !==''?formik.values.numberSelected :selectNumber}
      />
      <Box textAlign="center">
        <Button
          round
          type="submit"
          color="success"
          size="sm"
          loading={isLoading}
          loaderColor="white"
          onClick={()=>saveRating('numberSelected')}
        >
          save
        </Button>
        </Box>
           </div>
            </div>
        {/* end part button */}
        </div>
    )
}

