import React , { useEffect, useState } from 'react'
import * as yup from 'yup';
import { useFormik } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import { FormLabel, Box } from '@material-ui/core';
import CustomInput from '../../../components/material-dashboard-pro-react/CustomInput/CustomInput'
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button'
import { editKaalixPay, logCustomer } from '../services/serverApi.customer';
import styles from '../../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle';
import { useSelector } from 'react-redux';
import { getUserDataState } from 'features/auth/redux/selectors';
import { DATA } from '../../../utils/logAudit';
import { resetObj } from 'utils/resetObj';

const useStyles = makeStyles(styles);

const validationSchema = yup.object({
    amount: yup.number().required(),
    comment : yup.string().required(),
})

export default function EditKaalixPayComponent({ customer,close,adminId,index,customers,setCustomers}) {
    // console.log(customer);
    // const persistRoot = JSON.parse(localStorage.getItem('persist:root'))
    // console.log(persistRoot);
    const [isLoading, setIsLoading] = useState(false)
    const classes = useStyles();
    const formik = useFormik({
        initialValues: {
            amount: '',
            comment : '',
            customerId : '',
            adminId : '',
        },
        validationSchema,
    });
    // admin data :
    const adminData = useSelector(getUserDataState);
    const [admin, setAdmin] = useState({
        _id: adminData.user._id,
        email: adminData.user.email,
        username: adminData.user.username
    });
    const editCustomer = () => {
        // reset object before setting data :
        resetObj(DATA);
        // for logger audit data :
        DATA.admin = admin;
        DATA.actionType = 'KaalixPAY';
        DATA.target = 'Customer';
        DATA.comment = formik.values.comment;
        DATA.targetId = formik.values.customerId;
        DATA.loggerDetails = {
            beforeValue: customer.kaalixPay,
            afterValue: parseInt(customer.kaalixPay) + parseInt(formik.values.amount)
        };
        // DATA.beforeValue = (customer.kaalixPay).toString();
        console.log(DATA);
        // our post here :
        logCustomer(DATA)
            .then(({ data }) => {
                console.log(data);
            })
            .catch((error) => {
                console.log(error.message, {
                    type: 'error',
                });
            })

        // console.log(formik.initialValues);
        // store.kaalixPay = formik.values.kaalixPay
        setIsLoading(true)
        editKaalixPay(formik.values)
          .then(({ data }) => {
            let newCustomers = [...customers]
            newCustomers[index].kaalixPay = Number(newCustomers[index].kaalixPay)+Number(formik.values.amount)
            setCustomers(newCustomers)
            close()
          })
          .catch((error) => {
            console.log(error.message, {
              type: 'error',
            });
          })
          .finally(() => setIsLoading(true))
    }
    const setValues = () => {
        formik.setValues({
            // amount: customer.kaalixPay,
            // comment : customer?.comment ,
            customerId : customer?._id ,
            adminId :adminId,
        })
    }

    useEffect(() => {
        setValues()
    }, [])
    return (
        <div>
            <FormLabel className={classes.labelHorizontal}>
                customer kaalixPay
            </FormLabel>
            <CustomInput
                formControlProps={{
                    fullWidth: true,
                }}
                type="text"
                error={
                    formik.touched.amount && Boolean(formik.errors.amount)
                }
                inputProps={{
                    value: formik.values.amount,
                    onBlur: formik.handleBlur('amount'),
                    onChange: formik.handleChange('amount'),
                }}
            />
            <FormLabel className={classes.labelHorizontal}>
            comment 
            </FormLabel>
            <CustomInput
                formControlProps={{
                    fullWidth: true,
                }}
                type="text"
                error={
                    formik.touched.comment  && Boolean(formik.errors.comment )
                }
                inputProps={{
                    value: formik.values.comment ,
                    onBlur: formik.handleBlur('comment'),
                    onChange: formik.handleChange('comment'),
                }}
            />


            <Box textAlign="center">
                <Button
                    round
                    type="submit"
                    color="success"
                    disabled={!formik.dirty || !formik.isValid || isLoading}
                    loading={isLoading}
                    loaderColor="white"
                    onClick={editCustomer}
                >
                    save
                </Button>
            </Box>
        </div>
    )
}
