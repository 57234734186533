import React, { useState } from 'react';

export default function DeliveriesTable({ deliveries, columns, title }) {
  const [collapsed, setCollapsed] = useState(true);
  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };
  return (
    <div style={{ overflowX: 'auto', maxWidth: '100%' }}>
      <table
        style={{
          backgroundColor: '#fff',
          borderCollapse: 'collapse',
          width: '100%',
        }}
      >
        <caption
          style={{
            captionSide: 'top',
            textAlign: 'center',
            marginBottom: '10px',
            fontWeight: 'bold',
          }}
          onClick={toggleCollapse}
        >
          {title}
        </caption>
        <thead>
          <tr>
            {columns &&
              columns.map((item) => (
                <th
                  style={{
                    padding: '8px',
                    textAlign: 'left',
                    borderBottom: '1px solid #ddd',
                  }}
                >
                  {item.Header}
                </th>
              ))}
          </tr>
        </thead>
        {collapsed ? (
          <tbody>
            {([...deliveries] || []).map((item) => (
              <tr key={item._id} style={{ borderBottom: '1px solid #ddd' }}>
                {columns &&
                  columns.map((x) => (
                    <th
                      style={{
                        // padding: '8px',
                        textAlign: 'left',
                        borderBottom: '1px solid #ddd',
                        color: item?.Rowcolor || '',
                        ...x?.style,
                        // maxWidth: '60px',
                        // overflow: 'hidden',
                        // textOverflow: 'ellipsis',
                      }}
                    >
                      {item[x.accessor] || ''}
                    </th>
                  ))}
              </tr>
            ))}
          </tbody>
        ) : (
          'cliquez sur le titre de tableau pour affiche data'
        )}
      </table>
    </div>
  );
}
