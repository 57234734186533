import {
  GET_RIDE_REQUEST,
  GET_RIDE_SUCCESS,
  GET_RIDE_FAIL,
  CHANGE_RIDE_STATUS,
  NEW_RIDE,
  ACCEPTED_RIDE,
  VALIDATED_RIDE,
  CANCEL_RIDE,
  INITIAL_STATE,
  CHANGE_RIDE_ALARMS,
  CHANGE_RIDE_ALARM,
  SET_RIDE_ALARMS,
  CHANGE_RIDE_ALARMS_DATA,
  // V2 
  GET_RIDE_SUCCESSV2,
  GET_RIDE_FAILV2,
  CHANGE_RIDE_STATUSV2,
  NEW_RIDEV2,
  CANCEL_RIDEV2,
  VALIDATED_RIDEV2,
  GET_RIDE_REQUESTV2
} from '../constants/order.constants';
import { deliveryManStatusObj } from '../../../../config/status.config';
import { orderFormat, rowColor } from '../../utils'


const initialState = {
  rides: [], // dispatchingStatus != 'INVALID' && deliveryManStatus != 'DELIVERED'
  invalidRides: [], // dispatchingStatus == 'INVALID'
  error: '',
  loading: false,
  watcherCounter: 1,
  alarms: {},
  customerIdObject: {},
};

export default function ridesReducer(state = initialState, action) {
  let rides = state.rides || [];
  let invalidRides = state.invalidRides || [];
  let customerIdObject = state.customerIdObject || {};

  switch (action.type) {

    case CHANGE_RIDE_ALARMS:
      rides = state.rides || [];
      invalidRides = state.invalidRides || [];
      const findIndexForAlarms = (rides || []).findIndex(
        (x) => x._id == action.id,
      );
      if (findIndexForAlarms >= 0) {
        rides[findIndexForAlarms].alarms = action.alarms;
      } else {
        const findIndexForAlarmsInInvalid = (invalidRides || []).findIndex(
          (x) => x._id == action.id,
        );
        if (findIndexForAlarmsInInvalid != -1)
          invalidRides[findIndexForAlarmsInInvalid].alarms = action.alarms;
      }
      break;
    case CHANGE_RIDE_ALARMS_DATA:
      rides = state.rides || [];
      invalidRides = state.invalidRides || [];
      const orderIndex = (rides || []).findIndex((x) => x._id === action.id);
      if (rides[orderIndex]?.alarms?.length > 0) {
        let orderUpdated = rides[orderIndex];
        let AlarmIndex = (orderUpdated?.alarms || []).findIndex(
          (x) => x._id === action.alarm._id,
        );
        if (AlarmIndex !== -1) {
          orderUpdated.alarms[AlarmIndex] = action.alarm;
        } else {
          orderUpdated?.alarms.push(action.alarm);
        }
        rides[orderIndex] = orderUpdated;
      }
      console.log('asa', rides);
      return { rides: rides };
      break;
    case CHANGE_RIDE_ALARM:
      let alarms = state.alarms || [];

      if (alarms[action.alarm.orderId]) {
        let index = alarms[action.alarm.orderId].findIndex(
          (a) => a._id + '' === action.alarm._id + '',
        );
        if (index !== -1) {
          alarms[action.alarm.orderId][index] = action.alarm;
        } else {
          alarms[action.alarm.orderId].push(action.alarm);
        }
        return { alarms: alarms };
      } else {
        alarms[action.alarm.orderId] = [action.alarm];
        return { alarms: alarms };
      }
      break;
    case SET_RIDE_ALARMS:
      return { alarms: action.alarms };
      break;


    // ***************** V2 ***************
    case GET_RIDE_SUCCESSV2:
      (action?.rides || []).forEach((item) => {
        customerIdObject[item?.customer?._id] =
          (customerIdObject[item?.customer?._id] || 0) + 1;
      });
      console.log('GET_RIDE_SUCCESSV2');
      (action?.rides || []).forEach((order) => {
        order.nbrOfOrders = customerIdObject[order.customer?._id]
        if (order?.dispatchingStatus == 'INVALID') {
          invalidRides.push(orderFormat(order));
        } else {
          rides.push(orderFormat(order));
        }
      });
      break;
    case CHANGE_RIDE_STATUSV2:
      const findIndexV2 = (rides || []).findIndex(
        (x) => x._id == action.ride._id,
      );
      if (findIndexV2 >= 0) {
        if (action.ride.driverStatus !== 'COMPLETED') {
          // let order = rides[findIndexV2];
          rides[findIndexV2] = orderFormat({ ...action.ride, nbrOfOrders: customerIdObject[action.ride?.customer?._id] });
        } else {
          rides.splice(findIndexV2, 1);
          customerIdObject[action.ride?.customer?._id] > 1 ? customerIdObject[action.ride?.customer?._id] -= 1 : delete customerIdObject[action.ride?.customer?._id]
          if ((customerIdObject[action.ride?.customer?._id] || 0) > 0) {
            console.log('if change status');
            rides.filter(order => order?.customer?._id == action.ride?.customer?._id).map(x => {

              x.Rowcolor = rowColor(x?.deliveryMan,
                x?.cityId || x?.city?._id,
                x?.payment?.cityId,
                x?.deliveryMan?.cityId,
                customerIdObject[action.ride?.customer?._id] || 0,
                x.blocked),
                x.nbrOfOrders = customerIdObject[action.ride?.customer?._id]
            }
            )
            invalidRides.filter(order => order?.customer?._id == action.ride?.customer?._id).map(x => {

              x.Rowcolor = (customerIdObject[action.ride?.customer?._id] || 0) > 1 ? 'red' : '',
                x.nbrOfOrders = customerIdObject[action.ride?.customer?._id]
            }
            )
          }
        }
      } else {
        const findInvalidOrderIndex = (invalidRides || []).findIndex(
          (x) => x._id == action.ride._id,
        );
        if (findInvalidOrderIndex >= 0) {
          if (action.ride?.dispatchingStatus == 'INVALID') {
            let order = invalidRides[findInvalidOrderIndex];
            invalidRides[findInvalidOrderIndex] = orderFormat(action.ride);
          } else {
            invalidRides.splice(findInvalidOrderIndex, 1);
            rides.push(orderFormat(action.ride));
          }
        }
      }
      break;
    case NEW_RIDEV2:
      customerIdObject[action.ride?.customer?._id] =
        (customerIdObject[action.ride?.customer?._id] || 0) + 1;
      action.ride.nbrOfOrders = customerIdObject[action.ride?.customer?._id]
      if (customerIdObject[action.ride?.customer?._id] > 1) {
        console.log('if new Order');
        rides.filter(order => order?.customer?._id == action.ride?.customer?._id).map(x => {

          x.Rowcolor = rowColor(x?.deliveryMan,
            x?.cityId || x?.city?._id,
            x?.payment?.cityId,
            x?.deliveryMan?.cityId,
            customerIdObject[action.ride?.customer?._id] || 0,
            x.blocked),
            x.nbrOfOrders = customerIdObject[action.ride?.customer?._id]
        }
        )
        invalidRides.filter(order => order?.customer?._id == action.ride?.customer?._id).map(x => {

          x.Rowcolor = (customerIdObject[action.ride?.customer?._id] || 0) > 1 ? 'red' : '',
            x.nbrOfOrders = customerIdObject[action.ride?.customer?._id]
        }
        )
      }
      if (action.ride?.dispatchingStatus == 'INVALID') {
        invalidRides.push(orderFormat(action.ride));
      } else {
        rides.push(orderFormat(action.ride));
      }
      break;
    case VALIDATED_RIDEV2:
      // rides = state.rides || [];
      // invalidRides = state.invalidRides;
      const findIndexInInvaidOrdersV2 = (invalidRides || []).findIndex(
        (x) => x._id == action.ride._id,
      );
      if (findIndexInInvaidOrdersV2 >= 0) {
        invalidRides.splice(findIndexInInvaidOrdersV2, 1);
      }
      break;
    case CANCEL_RIDEV2:
      // console.log('customerIdObject before', customerIdObject[action?.customerId]);
      customerIdObject[action?.customerId] > 1 ? customerIdObject[action?.customerId] -= 1 : delete customerIdObject[action?.customerId]
      // console.log('customerIdObject after', customerIdObject[action?.customerId]);
      // console.log('customerIdObject', customerIdObject);
      const findIndexInOrderForCancelV2 = rides.findIndex(
        (x) => x._id == action._id,
      );
      if (findIndexInOrderForCancelV2 >= 0) {
        rides.splice(findIndexInOrderForCancelV2, 1);
      } else {

        const findIndexInInvalidOrderForCancel = invalidRides.findIndex(
          (x) => x._id == action._id,
        );
        if (findIndexInInvalidOrderForCancel >= 0) {
          invalidRides.splice(findIndexInInvalidOrderForCancel, 1);
        }
      }
      // rides.filter(order => order?.customer?._id == action?.customerId).map(x => x.nbrOfOrders = customerIdObject[x?.customer?._id])
      // invalidRides.filter(order => order?.customer?._id == action?.customerId).map(x => x.nbrOfOrders = customerIdObject[x?.customer?._id])
      if ((customerIdObject[action?.customerId] || 0) > 0) {
        console.log('calcel if');
        rides.filter(order => order?.customer?._id == action?.customerId).map(x => {

          x.Rowcolor = rowColor(x?.deliveryMan,
            x?.cityId || x?.city?._id,
            x?.payment?.cityId,
            x?.deliveryMan?.cityId,
            customerIdObject[action?.customerId] || 0,
            x.blocked),
            x.nbrOfOrders = customerIdObject[action?.customerId] || 0
        }
        )
        invalidRides.filter(order => order?.customer?._id == action?.customerId).map(x => {

          x.Rowcolor = (customerIdObject[action?.customerId] || 0) > 1 ? 'red' : '',
            x.nbrOfOrders = customerIdObject[action?.customerId] || 0
        }
        )
      }
      break;
    default:
      break;
  }

  switch (action.type) {
    case GET_RIDE_REQUEST:
      return {
        loading: true,
        watcherCounter: state.watcherCounter,
        rides: [],
        invalidRides: [],
      };
    case GET_RIDE_SUCCESS:
      return {
        ...state,
        loading: false,
        rides: rides.sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
        ),
        invalidRides: invalidRides.sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
        ),
        watcherCounter: (state.watcherCounter + 1) % 100,
      };
    case GET_RIDE_FAIL:
      return {
        ...state,
        loading: false,
        error:
          action.error?.message ||
          (action?.error?.errors || [{ message: '' }])[0].message,
      };
    case CHANGE_RIDE_STATUS:
      return {
        ...state,
        loading: false,
        rides: rides.sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
        ),
        watcherCounter: (state.watcherCounter + 1) % 100,
      };
    case CHANGE_RIDE_ALARMS:
      return {
        ...state,
        loading: false,
        rides: rides.sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
        ),
        invalidRides: invalidRides.sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
        ),
        watcherCounter: (state.watcherCounter + 1) % 100,
      };
    case INITIAL_STATE:
      return {
        ...state,
        loading: false,
        rides: [],
        invalidRides: [],
        watcherCounter: 1,
      };
    // ************************ V2 ******************  
    case GET_RIDE_REQUESTV2:
      return {
        loading: true,
        watcherCounter: state.watcherCounter,
        rides: [],
        invalidRides: [],
        customerIdObject: {},
      };
    case GET_RIDE_SUCCESSV2:
      return {
        ...state,
        loading: false,
        rides: rides.sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
        ),
        invalidRides: invalidRides.sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
        ),
        watcherCounter: (state.watcherCounter + 1) % 100,
        customerIdObject: customerIdObject,
      };
    case GET_RIDE_FAILV2:
      return {
        ...state,
        loading: false,
        error:
          action.error?.message ||
          (action?.error?.errors || [{ message: '' }])[0].message,
        customerIdObject: customerIdObject,
      };
    case CHANGE_RIDE_STATUSV2:
      return {
        ...state,
        loading: false,
        rides: rides.sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
        ),
        watcherCounter: (state.watcherCounter + 1) % 100,
        customerIdObject: customerIdObject,
      };
    case NEW_RIDEV2:
      return {
        ...state,
        loading: false,
        rides: rides.sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
        ),
        invalidRides: invalidRides.sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
        ),
        watcherCounter: (state.watcherCounter + 1) % 100,
        customerIdObject: customerIdObject,
      };
    case CANCEL_RIDEV2:
      return {
        ...state,
        loading: false,
        rides: rides,
        invalidRides: invalidRides,
        watcherCounter: (state.watcherCounter + 1) % 100,
        customerIdObject: customerIdObject,
      };
    case VALIDATED_RIDEV2:
      return {
        ...state,
        loading: false,
        rides: rides,
        invalidRides: invalidRides,
        watcherCounter: (state.watcherCounter + 1) % 100,
      };
    default:
      return state;
  }
}
