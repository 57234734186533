import React, { useEffect, useState } from 'react';
// import { useSelector } from 'react-redux';
// import { useAlert } from 'react-alert';
// import { getUserDataState } from '../../auth/redux/selectors'
// import TablePagination from '@material-ui/core/TablePagination';
import { Box, Grid, Modal } from '@material-ui/core';
// import { getCustmore, approuvedCustmore } from '../services/serverApi.customer';
import DataTableContainerComponent from '../../../components/DataTableSortingContainer.component';
import { formatTime } from '../../../helpers/index';

import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import CustomButton from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import SwitchComponent from '../../../components/Switch.component';
import { getNonQualities } from '../services/serverApi.service';

export default function NonQualityCustomerPage () {

    const inpRef = React.useRef();
    const [resultsData, setResultsData] = useState({
        total: 0,
        items: [],
        tableItems: [],
    });
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(0);
    let numberOfRows = [20, 50, 100];
    const [rowsPerPage, setRowsPerPage] = useState(numberOfRows[0]);
    const [nonQualities, setNonQualities] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [total, setTotal] = useState(0);
    const [subPagination, setSubPagination] = useState([]);

    const prepareShowNonQuality = (item, index) => {
        return {
            ...item,
            createdAtCol: item.createdAt ? formatTime(item.createdAt) : '--',
            orderIdCol: item.orderId.slice(0,8),
            adminNameCol: item.admin.username,
            customerNameCol: item?.customer?.name,
            deliveryNameCol: item?.delivery?.name,
            storeNameCol: item?.store?.name,
            nonQualityNameCol: item.nonQualityName,
            nonQualityTypeCol: item.nonQualityType,
            nonQualityResName: item.responsable,
            nonQualityAmountCol: item.amount,
            nonQualityResCol: item.responsable,
            deliveryCostCol: item.deliveryCosts,
            articlePriceCol: item?.articePrice,
            destinationCol: item.destination,
            deliveryCommissionCol: item?.deliveryManCommission,
        }
    }

    const loadNonQualities = (page, limit, txt, type) => {
        setIsLoading(true);
        getNonQualities(page || 0, limit || 20, true, txt, type || 'Client')
            .then(({ data }) => {
                // console.log(data);
                setTotal(data.total);
                setNonQualities(data.qualities);
            })
            .catch((error) => console.log(error))
            .finally(() => {
                setIsLoading(false);
        });
    }

    const SearchComponent = () => {
        return (
          <Grid container>
            <Grid item xs={8}>
              <input type='text'
                ref={inpRef}
                placeholder='search'
                style={{
                  border: "none",
                  borderBottom: '1px solid black'
                }}
                defaultValue={searchText}
    
              />
            </Grid>
            <Grid item xs={4} container justify="flex-end"
            >
    
              <Box textAlign="left">
                <CustomButton
                  round
                  type="submit"
                  color="success"
                  size="sm"
                  loaderColor="white"
                  // onClick={storeSearch}
                  onClick={() => {
                    setSearchText(inpRef.current.value)
                    page > 0 && setPage(0)
                    loadNonQualities(page > 0 ? 0 : page, rowsPerPage, inpRef.current.value)
                  }
                  }
                >
                  find
                </CustomButton>
              </Box>
              <Box textAlign="left">
                <CustomButton
                  round
                  type="submit"
                  color="google"
                  size="sm"
                  loaderColor="white"
                  // onClick={loadTemplateList}
                  onClick={() => {
                    setSearchText('')
                    page > 0 && setPage(0)
                    loadTemplateList(page > 0 ? 0 : page, rowsPerPage, "")
                  }}
                >
                  clear
                </CustomButton>
              </Box>
            </Grid>
          </Grid>
        )
    }

    useEffect(() => {
        nonQualities && setResultsData({
            items: (nonQualities || []).map((item, index) => {
                return prepareShowNonQuality(item, index);
            })
        })
    }, [nonQualities]);

    const handlePaginate = (val) => {
        // console.log(val,'val');
        // console.log(val.pageSelect, val.numberOfRows);
        setResultsData({
          items: [],
          tableItems: [],
        });
        loadNonQualities(val.pageSelect, val.numberOfRows, searchText);
        setPage(val.pageSelect);
        setRowsPerPage(val.numberOfRows);
    };

    useEffect(() => {
        if (total) {
          const pages = Math.ceil(total / rowsPerPage);
          if (total > 2000) {
            console.log(total);
            const newPages = [];
            // const start = page==0?page:Number(page-1)
            let start;
            let end;
            const handelStart = () => {
              let startNumber;
              if (page == 0) startNumber = page
              else if (page > 0) {
                if (page - 100 < 0) startNumber = 0
                else startNumber = Number(page - 100)
              }
              return startNumber
            }
            const handelEnd = () => {
              let endNumber;
              if ((page + 100) < pages) endNumber = page + 100
              else endNumber = page + (pages - page)
              return endNumber
            }
            start = handelStart()
            end = handelEnd()
            for (let i = start; i < end; i++) {
              newPages.push(i)
            }
            setSubPagination(newPages);
          } else {
            setSubPagination([])
          }
        }
    }, [total, page]);

    useEffect(() => {
        loadNonQualities();
    }, [])

    return (
        <>
        {subPagination &&
            <DataTableContainerComponent 
            total={total}
            subPagination={subPagination}
            // numberOfRowsProps={rowsPerPage}
            resultsData={resultsData}
            page={page + 1}
            initData={resultsData.items}
            searchInp={true}
            SearchComponent={SearchComponent}
            columns={[
                {
                    Header: 'CreatedAt',
                    accessor: 'createdAtCol',
                },
                {
                  Header: 'Non Quality Name',
                  accessor: 'nonQualityNameCol'
                },
                {
                    Header: 'Order ID',
                    accessor: 'orderIdCol'
                },
                {
                  Header: 'Customer',
                  accessor: 'customerNameCol'
                },
                {
                  Header: 'Driver',
                  accessor: 'deliveryNameCol'
                },
                {
                  Header: 'Store',
                  accessor: 'storeNameCol'
                },
                {
                  Header: 'Article Price',
                  accessor: 'articlePrice'
                },
                {
                  Header: 'Frais de livraison',
                  accessor: 'deliveryCostCol'
                },
                {
                  Header: 'Commission livreur',
                  accessor: 'deliveryCommissionCol'
                },
                {
                  Header: 'Non Quality Responsable',
                  accessor: 'nonQualityResCol'
                },
                {
                    Header: 'Destination',
                    accessor: 'destinationCol'
                },
                {
                  Header: 'Non Quality Amount',
                  accessor: 'nonQualityAmountCol'
                },
                {
                  Header: 'Non Quality Type',
                  accessor: 'nonQualityTypeCol'
                },
                {
                    Header: 'Admin',
                    accessor: 'adminNameCol'
                },
            ]}
            title="Non Qualities Customer List"
            isLoading={isLoading}
            loadItemsList={loadNonQualities}
            onPaginate={handlePaginate}
            isPaginate={true}
            />
        }
        </>
    );
}