import {takeEvery,takeLatest,put,all,fork} from 'redux-saga/effects'
import axios from 'axios'
// import { useAlert } from 'react-alert';
import { MULTI_UPLOAD_IMAGE_REQUEST,MULTI_UPLOAD_IMAGE_RESET } from './upload.constant';
import { multiUploadImageSuccessAction,multiUploadImageFailtAction,multiUploadImageResetAction } from './upload.action';
import { uploadMediaApi } from '../../services/serverApi.service'
import { MICROSERVICE_BASE_URL } from '../../config/backendServer.config';
import { generateRandomString } from '../../helpers';

// const alert = useAlert();

const uploadImageToAPI = async(payload)=>{
    const formData = new FormData();

  formData.append('media', payload.file);
  formData.append('type', payload.folder);
  formData.append('name', generateRandomString(15));

  const data = axios.post(
    `${MICROSERVICE_BASE_URL.UPLOAD_IMAGE}`,
    formData,
  );
  return data
}
export function* multiUploadImage({payload}){
  // console.log(payload);
    try {
        const uploadImage =  yield uploadImageToAPI(payload)
        // console.log(uploadImage);
        yield put(multiUploadImageSuccessAction(uploadImage.data,payload.multiIconUrl,payload.index))
        
      } catch (error) {
          // console.log(error);
        yield put(multiUploadImageFailtAction(error?.response?.data))
        
    
      }
}
function* uploadImageReset(){
    yield put(multiUploadImageResetAction)
    
}


export default function* watcherSage(){
    yield takeLatest(MULTI_UPLOAD_IMAGE_REQUEST,multiUploadImage)
    
      
}