import React, { useState } from 'react';
// material api
import { Button, Grid, Typography, Box } from '@mui/material';
// component
import SelectBoxComponent from '../../../components/SelectBox.component';
import DeliveryHeaderInfoComponenet from './Order.header.info.componenet';

// mock
import { driverStatus } from '../../../config/status.config';
// api
import { updateOrderStatus } from '../service/serverAPI.service';

export default function ChangeStatusComponent({
  orders,
  orderId,
  admin,
  close,
}) {
  const [order, setOrder] = useState(
    (orders || []).find((x) => x._id == orderId),
  );
  const [selectedStatus, setSelectedStatus] = useState(
    order.driverStatus || '',
  );
  const handleStatus = () => {
    const deliveryManId = !!order.driver ? order.driver._id : null;

    if (deliveryManId) {
      updateOrderStatus(order._id, {
        driverId: deliveryManId,
        status: selectedStatus,
        // invoiceUrl: 'url:///',
        // pricePaid: 0,
        admin,
      })
        .then(({ data }) => {
          close();
        })
        .catch((err) => console.log(err));
    }
  };
  return (
    <div>
      <DeliveryHeaderInfoComponenet order={order} />
      <Box sx={{ display: 'flex' }} style={{ padding: '15px' }}>
        <SelectBoxComponent
          items={driverStatus.map((item) => ({
            value: item.value,
            label: item.label,
          }))}
          inputLabel="status"
          onValueChange={(item) => {
            setSelectedStatus(item);
          }}
          value={selectedStatus}
        />
        <div style={{ float: 'right', margin: '15px' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              handleStatus();
            }}
          >
            save
          </Button>
        </div>
      </Box>
    </div>
  );
}
