import React, { useState } from 'react';
import Assignment from '@material-ui/icons/Assignment';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Box } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import Edit from '@material-ui/icons/Edit';
import Close from '@material-ui/icons/Close';
import Map from '@material-ui/icons/Map';

import GridContainer from './material-dashboard-pro-react/Grid/GridContainer';
import GridItem from './material-dashboard-pro-react/Grid/GridItem';
import Card from './material-dashboard-pro-react/Card/Card';
import CardHeader from './material-dashboard-pro-react/Card/CardHeader';
import CardIcon from './material-dashboard-pro-react/Card/CardIcon';
import CardBody from './material-dashboard-pro-react/Card/CardBody';
import Table from './material-dashboard-pro-react/Table/Table';
import Button from './material-dashboard-pro-react/CustomButtons/Button';
import ModalComponent from './Modal.component';
import tableDataStyles from '../assets/jss/material-dashboard-pro-react/views/extendedTablesStyle';
import dataTableContainerStyles from '../assets/jss/table-data-container';
import Dialog from '@material-ui/core/Dialog/Dialog';
import { uploadImageResetAction } from '../redux/uploadRedux/upload.action';
import { useDispatch } from 'react-redux';
import ReactTable from './material-dashboard-pro-react/ReactTable/ReactTable';

const useTableDataStyles = makeStyles(tableDataStyles);
const useDataTableContainerStyles = makeStyles(dataTableContainerStyles);

export default function DataTableContainerComponent({
  resultsData,
  items,
  tableHead,
  title,
  isLoading,
  loadItemsList,
  actionButtons,
  CreateItemFormComponent,
  modalWidth,
  isPaginate,
  onPaginate,
  itemCreated,
  itemEdited,
  itemDelete,
}) {
  const classes = useTableDataStyles();
  const tableDataClasses = useDataTableContainerStyles();
  const [modal, setModal] = useState({
    visible: false,
    type: 'view',
  });
  // console.log(modal);
  const dispatch = useDispatch();
  const [selectedItemIndex, setSelectedItemIndex] = useState(null);

  const EditItemFormComponent = actionButtons?.edit?.component;
  const ViewItemComponent = actionButtons?.view?.component;
  const MapViewComponent = actionButtons?.map?.component;
  const DeleteComponent = actionButtons?.delete?.component;
  // console.log(resultsData.tableItems);

  const closeModal = () => {
    setSelectedItemIndex(null);
    setModal({ visible: false, type: '' });
    dispatch(uploadImageResetAction());
  };
  const path = window.location.pathname;
  const onClickBtnCreate = () => {
    setModal({ visible: true, type: 'create' }),
      dispatch(uploadImageResetAction());
  };
  return (
    <GridContainer>
      <GridItem xs={12}>
        {CreateItemFormComponent && (
          <Box display="flex" justifyContent="flex-end">
            <Button onClick={() => onClickBtnCreate()} color="success">
              Create
            </Button>
          </Box>
        )}
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>{title}</h4>
          </CardHeader>
          <CardBody>
            {isLoading && (
              <Box display="flex" justifyContent="center">
                <CircularProgress size={30} />
              </Box>
            )}
            {!isLoading && (
              <Table
                tableHead={tableHead}
                tableData={resultsData.tableItems.map((item, index) => [
                  ...item,
                  actionButtons &&
                    Object.values(actionButtons).map((prop) => (
                      <Button
                        color={prop.color}
                        simple
                        className={classes.actionButton}
                        key={prop.type}
                        onClick={() => {
                          setSelectedItemIndex(index);
                          dispatch(uploadImageResetAction());
                          setModal({ type: prop.type, visible: true });
                        }}
                      >
                        {prop.type === 'view' && (
                          <Visibility className={classes.icon} />
                        )}
                        {prop.type === 'edit' && (
                          <Edit className={classes.icon} />
                        )}
                        {prop.type === 'delete' && (
                          <Close className={classes.icon} />
                        )}
                        {prop.type === 'map' && (
                          <Map className={classes.icon} />
                        )}
                      </Button>
                    )),
                ])}
                customCellClasses={[
                  classes.firstColumnInTable,
                  classes.center,
                  classes.center,
                  classes.center,
                  classes.center,
                  classes.center,
                  classes.center,
                  classes.center,
                  classes.center,
                  classes.center,
                  // classes.right,
                  // classes.right,
                ]}
                customClassesForCells={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9]}
                customHeadCellClasses={[
                  classes.center,
                  classes.center,
                  classes.center,
                  classes.center,
                  classes.center,
                  classes.center,
                  classes.center,
                  classes.center,
                ]}
                customHeadClassesForCells={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9]}
                total={resultsData.total}
                onPaginate={(val) => (onPaginate ? onPaginate(val) : '')}
                isPaginate={isPaginate}
              />
            )}
          </CardBody>
        </Card>
      </GridItem>

      {/*{path !== '/admin/customers' && */}
      <ModalComponent
        maxWidth={modalWidth || 'md'}
        close={() => setModal({ visible: false, type: '' })}
        visible={modal.visible && modal.type === 'create'}
        title="Create"
      >
        {CreateItemFormComponent && (
          <CreateItemFormComponent
            onItemCreated={loadItemsList}
            itemCreated={(val) => itemCreated(val)}
            close={() => setModal({ visible: false, type: '' })}
          />
        )}
      </ModalComponent>
      {/*}*/}
      {EditItemFormComponent && (
        <ModalComponent
          close={closeModal}
          visible={modal.visible && modal.type === 'edit'}
          title="Edit"
          maxWidth={actionButtons.edit.Modalwidth}
        >
          <EditItemFormComponent
            data={
              !!actionButtons.edit.advanceEdit
                ? resultsData.items[selectedItemIndex]
                : resultsData.tableItems[selectedItemIndex]
            }
            index={selectedItemIndex}
            props={actionButtons?.edit?.props || null}
            onItemEdited={loadItemsList}
            itemEdited={itemEdited}
            close={() => setModal({ visible: false, type: '' })}
          />
        </ModalComponent>
      )}

      {ViewItemComponent && (
        <ModalComponent
          close={closeModal}
          visible={modal.visible && modal.type === 'view'}
          title="Detail"
        >
          <ViewItemComponent item={resultsData.tableItems[selectedItemIndex]} />
        </ModalComponent>
      )}
      {MapViewComponent &&
        MapViewComponent !== undefined &&
        Boolean(MapViewComponent) && (
          <ModalComponent
            close={closeModal}
            visible={modal.visible && modal.type === 'map'}
            title="Map"
            classes={{
              paper: tableDataClasses.paper,
            }}
            maxWidth={actionButtons.map.width}
          >
            {selectedItemIndex !== null && (
              <MapViewComponent item={resultsData.items[selectedItemIndex]} close={closeModal}/>
            )}
          </ModalComponent>
        )}
      {actionButtons && Boolean(actionButtons.delete) && (
        <ModalComponent
          // close={closeModal}
          visible={modal.visible && modal.type === 'delete'}
          title="Delete"
          close={closeModal}
          // actions={
          //   <>
          //     <Button onClick={closeModal}>Cancel</Button>
          //     <Button onClick={closeModal} color="success">
          //       Yes
          // </Button>
          //   </>
          // }
        >
          {DeleteComponent && (
            <DeleteComponent
              dataToDelete={resultsData.items[selectedItemIndex]}
              action={actionButtons?.delete?.action || null}
              data={resultsData.tableItems[selectedItemIndex]}
              onItemEdited={loadItemsList}
              itemDelete={itemDelete}
              index={selectedItemIndex}
              close={() => setModal({ visible: false, type: '' })}
            />
          )}
        </ModalComponent>
      )}
    </GridContainer>
  );
}

DataTableContainerComponent.propTypes = {
  resultsData: PropTypes.any.isRequired,
  items: PropTypes.any,
  tableHead: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  loadItemsList: PropTypes.any,
  actionButtons: PropTypes.object.isRequired,
  CreateItemFormComponent: PropTypes.any,
  modalWidth: PropTypes.string,
  isPaginate: PropTypes.boolean,
  onPaginate: PropTypes.func,
  itemCreated: PropTypes.func,
};

// QuickSearchToolbar.propTypes = {
//   clearSearch: PropTypes.func.isRequired,
//   onChange: PropTypes.func.isRequired,
//   value: PropTypes.string.isRequired
// };
