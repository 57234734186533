import React,{ useState, useEffect} from 'react'
import { useAlert } from 'react-alert';
import { Box, Grid } from '@material-ui/core';
import CustomButton from '../../../components/material-dashboard-pro-react/CustomButtons/Button'
import { getCustmore ,approuvedCustmore, logCustomer} from '../services/serverApi.customer';
import DataTableContainerComponent from '../../../components/DataTableSortingContainer.component';
import {formatTime} from '../../../helpers/index'
import SwitchComponent from '../../../components/Switch.component'
import { useSelector } from 'react-redux';
import { getUserDataState } from 'features/auth/redux/selectors';
import { DATA } from '../../../utils/logAudit';
import { resetObj } from 'utils/resetObj';

export default function UnapprouvedCustomersPage() {
  const alert = useAlert();
  const inpRef = React.useRef();
    const [resultsData, setResultsData] = useState({
        total: 0,
        items: [],
        tableItems: [],
      });
      const [customers, setCustomers] = useState([]);
      const [isLoading, setIsLoading] = useState(false);
      const [page, setPage] = useState(0)
      let numberOfRows = [20, 50, 100];
      const [rowsPerPage, setRowsPerPage] = useState(numberOfRows[0])
      const [subPagination,setSubPagination] = useState([])
      const [searchText, setSearchText] = useState('')
      const [total, setTotal] = useState(0);
      // console.log(page+'page ',rowsPerPage);
      // admin data :
      const adminData = useSelector(getUserDataState);
      const [admin, setAdmin] = useState({
        _id: adminData.user._id,
        email: adminData.user.email,
        username: adminData.user.username
      });
      // handle de-lock a consumer action :
      const consumerDelockLog = (value, item) => {
        // reset object before setting data :
        resetObj(DATA);
        // for logger audit data :
        DATA.admin = admin;
        DATA.actionType = 'Bloquer/Debloquer Client';
        DATA.targetId = item._id;
        DATA.target = 'Customer';
        DATA.loggerDetails = {
          beforeValue: 'Bloquer',
          afterValue: 'Debloquer'
        }
        
        console.log(DATA);
        // our post here :
        logCustomer(DATA)
          .then(({ data }) => {
            console.log(data);
          })
          .catch((error) => {
            console.log(error.message, {
              type: 'error',
            });
          })
      }
      const loadCustomerList = (page,limit,searchTxt) => {
        setIsLoading(true);
        // getStores(page || 0, limit || 20, true,searchTxt)
        getCustmore(page || 0, limit || 20, false,searchTxt )
          .then(({ data }) => {
            console.log(data);
            setTotal(data.total)
            setCustomers(data.customers)
        })
        .catch((error)=>{
          console.log(error)
          
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
    const prepareShowStore = (item, index) => {
      return {
        ...item,
        name: item.name,
        city: item.city?item.city:'--',
        phone: item.phone?item.phone:'--',
        status: item.status?item.status:'--',
        nbOrders:item.nbOrders ?item.nbOrders:'0',
        email: item.email,
        createdAt:item.createdAt ?formatTime(item.createdAt):'--',
        lastOrderdate:item.lastOrderdate ?formatTime(item.lastOrderdate):'--',
        card: item.cards?item.cards.length:'--',
        Adresses: item.addresses?item.addresses.length:'--',
        approved: <SwitchComponent
        onChange={(value) => {
          approveCustomer('approved',value, item._id,index)
          consumerDelockLog(value, item);
        }}
        value="checked"
        checked={item.approved=='true'}
        label="enabled"
        />,
        Reward: item.Reward?item.Reward:'--',
        dispatchingMode: item.dispatchingMode?item.dispatchingMode:'--',
        ReferralCode: item.ReferralCode?item.ReferralCode:'--',
      }
    }
    const approveCustomer = (key,value,_id,index)=>{
      const body = {};
      body[key] = value;
      approuvedCustmore(_id,body)
      .then(({ data }) => {
        // console.log(data);
        alert.show(data.message, {
          type: 'success',
        })
        const tempResultData = [...customers];
        tempResultData.splice(index, 1);
        console.log(tempResultData);
        const emptyArray = []
        tempResultData.length>0?setCustomers(tempResultData):setCustomers([]);

      })
      .catch((error)=>console.log(error))
    };
    console.log('customers',customers);
      const SearchComponent = () => {
        return (
          <Grid container>
          <Grid item xs={8}>
            <input type='text'
              ref={inpRef}
              placeholder='client search'
              style={{
                border: "none",
                borderBottom: '1px solid black'
              }}
              defaultValue={searchText}
              
            />
            </Grid>
            <Grid item xs={4} container justify="flex-end"
      >
            
            <Box textAlign="left">
              <CustomButton
                round
                type="submit"
                color="success"
                size="sm"
                loaderColor="white"
                // onClick={storeSearch}
                onClick={()=>{
                  setSearchText(inpRef.current.value)
                  page>0&&setPage(0)
                  loadCustomerList(page>0?0:page, rowsPerPage,inpRef.current.value)
                }
                }
              >
                find Client
              </CustomButton>
            </Box>
            <Box textAlign="left">
              <CustomButton
                round
                type="submit"
                color="google"
                size="sm"
                loaderColor="white"
                // onClick={loadCustomerList}
                onClick={() => {
                  setSearchText('')
                  page>0&&setPage(0)
                  loadCustomerList(page>0?0:page, rowsPerPage,"")
                }}
              >
                clear
              </CustomButton>
            </Box>
            </Grid>
          </Grid>
        )
      }
      
      useEffect(() => {
        customers && customers.length > 0 && setResultsData({
          items: customers.map((item, index) => {
            console.log(item);
            return prepareShowStore(item, index);
          }),
        })
        customers && customers.length == 0 && setResultsData({items:[]})
      }, [customers])

      const handlePaginate = (val) => {
        // console.log(val,'val');
        setResultsData({
          items: [],
          tableItems: [],
        });
        loadCustomerList(val.pageSelect,val.numberOfRows,searchText);
      };
    
      useEffect(() => {
        if(total){
          const pages = Math.ceil(total / rowsPerPage);
        if(total>2000){
          console.log(total);
          const newPages = [];
          // const start = page==0?page:Number(page-1)
          let start ;
          let end ;
          const handelStart = ()=>{
            let startNumber ;
                if(page==0)startNumber = page
                else if(page>0){
                  if(page-100<0)startNumber = 0
                  else startNumber = Number(page-100)
                }
            return startNumber
          }
          const handelEnd = ()=>{
            let endNumber ;
            if((page+100)<pages)endNumber=page+100
            else endNumber=page+(pages-page)
            return endNumber
          }
          start = handelStart()
          end = handelEnd()
          for (let i = start; i < end; i++) {
            newPages.push(i)
          }
          setSubPagination(newPages);
        }else{
          setSubPagination([])
        }
        }
      },[total,page])
      return (
        <>
        {subPagination&&
       <DataTableContainerComponent
            total={total}
            searchInp={true}
            SearchComponent={SearchComponent}
            resultsData={resultsData}
            initData={resultsData.items}
            columns={[
              
              {
                Header: 'Name',
                accessor: 'name',
              },
              {
                Header: 'city',
                accessor: 'city',
              },
              {
                Header: 'phone',
                accessor: 'phone',
              },
              {
                Header: 'email',
                accessor: 'email',
              },
              {
                Header: 'status',
                accessor: 'status',
              },
              {
                Header: 'number of Orders',
                accessor: 'nbOrders',
              },
              {
                Header: 'created At',
                accessor: 'createdAt',
              },
              {
                Header: 'last Order date',
                accessor: 'lastOrderdate',
              },
              {
                Header: 'card',
                accessor: 'card',
              },
              {
                Header: 'Adresses',
                accessor: 'Adresses',
              },
              {
                Header: 'Approved',
                accessor: 'approved',
              },
              {
                Header: 'Reward',
                accessor: 'Reward',
              },
              {
                Header: 'mode',
                accessor: 'dispatchingMode',
              },
              {
                Header: 'Referral Code',
                accessor: 'ReferralCode',
              },
              
              
            ]}
            title="Unapproved Customer List"
          isLoading={isLoading}
          loadItemsList={loadCustomerList}
            // actionButtons={{
              // actionButtons={{
              //     view: {
              //       color: 'info',
              //       type: 'view',
              //       component: ViewItemComponent,
              //     },
              //     edit: {
              //       color: 'success',
              //       type: 'edit',
              //       component: EditServiceCategoryComponent,
              //     },
              //     delete: { color: 'danger', type: 'delete' },
              //   }}
              onPaginate={handlePaginate}
                isPaginate={true}
          />
          }
          </>
      );
}
