import React, { useEffect, useState, useRef } from 'react';
import DataTableSortingContainer from '../../../components/DataTableSortingContainer.component';
import { useDispatch, useSelector } from 'react-redux';
import { getUserDataState } from 'features/auth/redux/selectors';
import DeleteIcon from '@material-ui/icons/Delete';
import DeliveryDetails from '../components/Detail.delivery.component';
import SelectBoxComponent from '../../../components/SelectBox.component';
import { Box, Grid, TextField } from '@material-ui/core';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DateTimePicker, LocalizationProvider } from '@mui/lab';
import moment from 'moment';
import { useAlert } from 'react-alert';
import { openModelAction } from '../../orders/redux/actions/order.actions';
import ModalComponentsDelivries from '../components/Modal.components.delivries';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import DetailDeliveryComponent from '../components/Detail.delivery.component';

import CustomButton from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import { getPartnerOrdersHistory } from '../services/server.api.service';
import {
  DeliveryManStatus,
  DispatchingStatus,
  StoreStatus,
  CustomerStatus,
} from '../../../config/status.config';
import CommentHistoryComponent from '../components/Comment.delivery.history.component';
import ModalComponent from '../../../components/Modal.component';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { formatTimeForOrder, calcDuration } from '../../../helpers/index';
import { deliveriesSearchType } from '../mock';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import IconButton from '@material-ui/core/IconButton';
const iconsSharedStyle = {
  cursor: 'pointer',
  width: 21,
  height: 21,
  margin: 2,
  borderRadius: 2,
  padding: 2,
};
const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip arrow {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));
export default function OrderHistoryPage() {
  const dispatch = useDispatch();
  const inpRef = useRef();
  const alert = useAlert();
  const [resultsData, setResultsData] = useState({
    total: 0,
    items: [],
  });
  const adminData = useSelector(getUserDataState);
  const [admin, setAdmin] = useState({
    _id: adminData.user._id,
    email: adminData.user.email,
    username: adminData.user.username,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState(null);
  const [item, setItem] = useState({});
  const [page, setPage] = useState(0);
  let numberOfRows = [20, 50, 100];
  const [rowsPerPage, setRowsPerPage] = useState(numberOfRows[0]);
  const [total, setTotal] = useState(0);
  const [orderHistory, setOrderHistory] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchBy, setSearchBy] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [shortId, setShortId] = useState('');
  const [useDate, setUseDate] = useState(false);
  const [displayTotal, setDisplayTotal] = useState(false);
  const customColumnStyle = {
    maxWidth: '90px',
    minWidth: '90px',
    fontSize: '13px',
  };
  const columns = [
    {
      Header: 'Id Cmd',
      accessor: 'shortId',
    },
    {
      Header: 'organisation',
      accessor: 'warehouseName',
    },
    {
      Header: 'Client',
      accessor: 'clientName',
    },
    {
      Header: 'Provider',
      accessor: 'deliveryManInp',
    },
    {
      Header: 'IdMarjane',
      accessor: 'orderData.code',
    },
    {
      Header: 'Ville',
      accessor: 'cityName',
    },

    {
      Header: 'Provider.S',
      accessor: 'deliveryManStatusInp',
    },
    {
      Header: 'Price',
      accessor: 'orderPriceCol',
    },
    {
      Header: 'payment',
      accessor: 'paymentMode',
    },
    {
      Header: 'Created',
      accessor: 'createdAtInp',
    },
    {
      Header: 'Comments',
      accessor: 'lastComment',
    },
    {
      Header: 'cmdTime',
      accessor: 'cmdTime',
    },
    {
      Header: 'timing',
      accessor: 'timing',
    },
    {
      Header: 'Actions',
      accessor: 'menu',
    },
  ];
  const timingTextType = [];
  const timingDetail = [
    'dispatching_Time',
    'driver_Accepted',
    'driver_ATW',
    'driver_SP',
    'driver_EP',
    'driver_EC',
    'driver_SD',
    'driver_ATD',
    'driver_waiting_customer',
    'beofre_picking',
    'picking_Time',
    'caisse_time',
    'delivery_time',
    'order_time',
  ];
  const transTime = (totalSeconds = 0) => {
    let hours = Math.floor(totalSeconds / 3600);
    totalSeconds %= 3600;
    let minutes = Math.floor(totalSeconds / 60);
    let seconds = totalSeconds % 60;

    console.log('hours: ' + hours);
    console.log('minutes: ' + minutes);
    console.log('seconds: ' + seconds);

    // If you want strings with leading zeroes:
    minutes = String(minutes).padStart(2, '0');
    hours = String(hours).padStart(2, '0');
    seconds = String(seconds).padStart(2, '0');
    return hours + ':' + minutes + ':' + seconds;
  };
  const handleCloseModal = () => {
    setModal('');
    setItem({});
  };
  const handleOpenModal = (item, type) => {
    // console.log(type, index, item)
    setItem(item);
    setModal(type);
  };
  const prepareToShowHistory = (item, index) => {
    return {
      ...item,
      warehouseName: item?.pickupAddress?.libeleMagasin || item?.organisationId,
      clientName: item?.destinationAddress?.contactPerson,
      deliveryManInp: item?.deliveryMan && item?.deliveryMan.name,

      cityName: item?.cityName?.substring(0, 4) || item?.cityId,
      deliveryManStatusInp: item?.deliveryStatus,

      createdAtInp:
        item.createdAt && moment(item.createdAt).format('DD/MM hh:mm'),

      orderPriceCol: item?.payment?.price && item?.payment?.price.toFixed(2),
      paymentMode: item?.payment?.method,
      menu: (
        <div>
          <Tooltip arrow title="Detail Commande" placement="top">
            <AspectRatioIcon
              style={{
                ...iconsSharedStyle,
                color: '#0705f7',
                backgroundColor: '#0705f730',
              }}
              onClick={() => {
                // dispatch(openModelAction(item._id, index, 'detail'));
                setModal('detail');
                setItem(item);
              }}
            />
          </Tooltip>
          <Tooltip arrow title="report" placement="bottom">
            <AssessmentIcon
              style={{
                ...iconsSharedStyle,
                color: '#000000',
                backgroundColor: '#00000030',
              }}
              onClick={() => {
                dispatch(openModelAction(item._id, index, 'report'));
              }}
            />
          </Tooltip>
        </div>
      ),
      timing: (
        <div>
          <IconButton
            onClick={() => {
              handleOpenModal(item, 'timing');
            }}
          >
            <AccessTimeIcon />
          </IconButton>
        </div>
      ),
      cmdTime:
        item?.timing?.order_time &&
        Math.floor(item?.timing?.order_time / 60) + ' min',
      lastComment: (
        <HtmlTooltip
          title={
            <React.Fragment>
              <CommentHistoryComponent
                close={() => {}}
                admin={admin}
                comments={item.comments || []}
              />
            </React.Fragment>
          }
        >
          {item?.comments && item.comments.length ? (
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => {
                handleOpenModal(item, 'Comment');
                // setOrderIdForComments(item._id);
              }}
            >
              {' '}
              {item.comments[item.comments.length - 1]?.adminName}
              {' // '}
              {item.comments[item.comments.length - 1]?.comment}{' '}
            </span>
          ) : (
            <span></span>
          )}
        </HtmlTooltip>
      ),
    };
  };
  const getOrdersCompleted = (
    skip,
    limit,
    paged,
    txt,
    using,
    start,
    end,
    useDate,
  ) => {
    if (using == 'shortId' && txt.length !== 8) {
      console.log('if cond');
      alert.show('shortId must be 8 chars', {
        type: 'error',
      });
      return;
    }
    getPartnerOrdersHistory(skip, limit, paged, start, end, useDate, txt, using)
      .then(({ data }) => {
        setTotal(data.total);
        setOrderHistory(data.deliveries);
        useDate && setDisplayTotal(true);
      })
      .catch((error) => {
        console.log(error);
        setResultsData({ items: [] });
      });
  };
  const handlePaginate = (val) => {
    setResultsData({
      items: [],
      tableItems: [],
    });
    getOrdersCompleted(
      val.pageSelect,
      val.numberOfRows,
      true,
      searchText,
      searchBy,
      startDate,
      endDate,
      useDate,
      shortId,
    );
    setPage(val.pageSelect);
    setRowsPerPage(val.numberOfRows);
  };
  const SearchComponent = () => {
    return (
      <Grid container alignItems="center">
        <Grid item xs={4}>
          <input
            type="text"
            ref={inpRef}
            placeholder="search"
            style={{
              border: 'none',
              borderBottom: '1px solid black',
            }}
            defaultValue={searchText}
          />
        </Grid>
        <Grid item xs={5} className="pb-3">
          <SelectBoxComponent
            items={deliveriesSearchType.map((type) => ({
              value: type,
              label: type,
            }))}
            inputLabel="search By"
            onValueChange={(items) => {
              setSearchText(inpRef.current.value);
              setSearchBy(items);
            }}
            value={searchBy}
          />
        </Grid>

        <Grid item xs={4} className="mb-3">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              renderInput={(props) => <TextField {...props} />}
              label="Start date"
              ampm={false}
              value={startDate}
              onChange={(newValue) => {
                setStartDate(moment(newValue).format().split('+')[0]);
                !useDate && setUseDate(true);
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={4} className="mb-3">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              renderInput={(props) => <TextField {...props} />}
              label="End Date"
              ampm={false}
              value={endDate}
              onChange={(newValue) => {
                setEndDate(moment(newValue).format().split('+')[0]);
                !useDate && setUseDate(true);
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={4} container justify="flex-end">
          <Box textAlign="left">
            <CustomButton
              round
              type="submit"
              color="success"
              size="sm"
              loaderColor="white"
              onClick={() => {
                setSearchText(inpRef.current.value);
                // setShortId(inpRefForShortId.current.value);
                page > 0 && setPage(0);
                getOrdersCompleted(
                  page > 0 ? 0 : page,
                  rowsPerPage,
                  true,
                  inpRef.current.value,
                  searchBy,
                  startDate,
                  endDate,
                  useDate,
                  // inpRefForShortId.current.value,
                );
              }}
            >
              find
            </CustomButton>
          </Box>
          <Box textAlign="left">
            <CustomButton
              round
              type="submit"
              color="google"
              size="sm"
              loaderColor="white"
              onClick={() => {
                setSearchText('');
                setSearchBy('');
                setUseDate(false);
                setDisplayTotal(false);
                setDisplayTotal(false);
                setStartDate('');
                setEndDate('');
                setShortId('');
                page > 0 && setPage(0);
                getOrdersCompleted(
                  page > 0 ? 0 : page,
                  rowsPerPage,
                  true,
                  '',
                  '',
                  false,
                  '',
                  false,
                  '',
                );
              }}
            >
              clear
            </CustomButton>
          </Box>
        </Grid>
      </Grid>
    );
  };
  useEffect(() => {
    orderHistory &&
      orderHistory.length > 0 &&
      setResultsData({
        items: orderHistory.map((item, index) => {
          return prepareToShowHistory(item, index);
        }),
      });
  }, [orderHistory]);
  return (
    <div>
      <DataTableSortingContainer
        resultsData={resultsData}
        total={total}
        initData={resultsData.items}
        columns={columns}
        title={
          <strong>
            Deliveries History{displayTotal && ' ( ' + total + ' )'}
          </strong>
        }
        isLoading={isLoading}
        page={page + 1}
        customColumnStyle={customColumnStyle}
        onPaginate={handlePaginate}
        isPaginate={true}
        searchInp={true}
        SearchComponent={SearchComponent}
      />
      <ModalComponentsDelivries />
      {modal && modal == 'detail' && (
        <ModalComponent
          maxWidth={'lg'}
          close={handleCloseModal}
          visible={modal == 'detail'}
          title="detail delivery"
        >
          <DetailDeliveryComponent item={item} />
        </ModalComponent>
      )}
      {modal && modal === 'timing' && (
        <ModalComponent
          close={handleCloseModal}
          visible={modal === 'timing'}
          title="Timing"
        >
          <div>
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">name</th>
                  <th scope="col">value ( HH:MM:SS)</th>
                </tr>
              </thead>
              <tbody>
                {timingDetail.map((i, index) => (
                  <tr>
                    <th scope="row">{index + 1}</th>
                    <td>{i}</td>
                    <td>
                      {item.timing
                        ? JSON.stringify(transTime(item.timing[i + '']))
                        : '--'}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </ModalComponent>
      )}
      {modal && modal === 'Comment' && (
        <ModalComponent
          maxWidth={'lg'}
          close={handleCloseModal}
          visible={modal === 'Comment'}
          title="comments "
        >
          <CommentHistoryComponent
            comments={item.comments || []}
            admin={admin}
          />
        </ModalComponent>
      )}
    </div>
  );
}
