import React,{useState,useEffect} from 'react'
import { useAlert } from 'react-alert';
import DataTableContainerComponent from '../../../components/DataTableSortingContainer.component';
import { getSpecification,updateSpecification } from '../services/serverAPI.service'
import CreateSpecification from '../components/CreateSpecification'
import SwitchComponent from '../../../components/Switch.component'
import DeleteSpecification from '../components/DeleteSpecification'
import EditeSpecification from '../components/EditeSpecification'

export default function Specification() {
    const alert = useAlert();
    const id = localStorage.getItem('storeId')
    const [resultsData, setResultsData] = useState({
        total: 0,
        items: [],
        tableItems: [],
    });
    const [isLoading, setIsLoading] = useState(false);
    const pages = [100, 250, 500];
    let numberOfRows = [10, 50, 100];

    const updateSpecificationFromSwitch=(key,value, item)=>{
        item[key] = value,
        updateSpecification(item)
            .then(() => {
                alert.show('specification updated', {
                    type: 'success',
                });
                loadSpecificationList()
            })
            .catch((error) => {
                alert.show(error.message, {
                    type: 'error',
                });
            })
    }
    const loadSpecificationList = () => {
        setIsLoading(true);
        getSpecification(id)
            .then(({ data }) => {
                // console.log(data);
                data&&data.length>0
                ?setResultsData({
                    total: data.total,
                    items: data.map((item, index) => {
                        return {
                            ...item,
                            name: item.name||'--',
                            type: item.type?item.type:'--',
                            priceconfig: item.priceconfig?item.priceconfig:'--',
                            maxChoices: item.maxChoices?item.maxChoices:'--',
                            openInp: 
                                <SwitchComponent
                                onChange={(value) => updateSpecificationFromSwitch('open',value, item)}
                                value="checked"
                                checked={item.open}
                                label="open"
                                />,
                            requiredCol: 
                                <SwitchComponent
                                onChange={(value) => updateSpecificationFromSwitch('required',value, item)}
                                value="checked"
                                checked={item.required}
                                label="required"
                                />,
                                listInp:item.list.length > 0&&item.list.map(itemFromList=>(
                                <p>{itemFromList.name}( {itemFromList.price} dh)</p>
                            ))
                            

                        };
                    }),
                    
                })
                :setResultsData({items:[]})
            })
            .catch(error => console.log(error))
            .finally(() => {
                setIsLoading(false);
            });
    };
    const handlePaginate = (val) => {
        // console.log(val,'val');
        // console.log(val.pageSelect, val.numberOfRows);
        setResultsData({
          items: [],
          tableItems: [],
        });
        loadSpecificationList(val.pageSelect, val.numberOfRows);
      };
    useEffect(() => {
        loadSpecificationList()
    },[])
    return (
        <DataTableContainerComponent
            numberOfRowsProps={numberOfRows}
            resultsData={resultsData}
            initData={resultsData.items}
            onPaginate={handlePaginate}
            isPaginate={false}
            columns={[
                {
                    Header: 'Name',
                    accessor: 'name',
                },
                {
                    Header: 'Type',
                    accessor: 'type',
                },
                {
                    Header: 'priceconfig',
                    accessor: 'priceconfig',
                },
                {
                    Header: 'Max Choices',
                    accessor: 'maxChoices',
                },
                {
                    Header: 'Visible',
                    accessor: 'openInp',
                },
                {
                    Header: 'Required',
                    accessor: 'requiredCol',
                },
                {
                    Header: 'List',
                    accessor: 'listInp',
                },
                {
                    Header: 'actions',
                    accessor: 'actions',
                },
            ]}
            title="Specifications List"
            isLoading={isLoading}
            loadItemsList={loadSpecificationList}
            CreateItemFormComponent={CreateSpecification}
            actionButtons={{
                
                    edit: {
                      color: 'success',
                      type: 'edit',
                      component: EditeSpecification,
                    },
                delete: {
                    color: 'danger',
                    type: 'delete',
                       component:DeleteSpecification
                },
            }}
        />
    )
}
