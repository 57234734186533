import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { Box } from '@material-ui/core';
import ModalComponent from '../../../components/Modal.component';
import EditIntervalConfigComponent from './Edit.interval.config.component';
import PropTypes from 'prop-types';
import { useAlert } from 'react-alert';
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import CustomInput from '../../../components/material-dashboard-pro-react/CustomInput/CustomInput';
import SwitchComponent from '../../../components/Switch.component';

import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import AddTransportationComponent from './Add.transportation.component';
import {
  createDriverConfigPrice,
  updateDriverConfigPrice,
} from '../service/serverAPI.service';

const validationSchema = yup.object({
  baseCommission: yup.number().required(),
  meansOfTransportation: yup.array().of(
    yup.object().shape({
      name: yup.string(),
      info: yup.object().shape({
        enable: yup.boolean(),
      }),
    }),
  ),
  // cityId: yup.string(),
});

export default function EditDeriverConfigByCity({
  data,
  transportation,
  onItemEdited,
  close,
  cityId,
  driverConfig,
  setRefeatchData,
  refeatchData,
}) {
  const alert = useAlert();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [maxObject, setMaxObject] = useState({});
  const [displayImage, setDisplayImage] = useState(false);
  const [openModal, setOpenModal] = useState('');

  const [configId, setConfigId] = useState(data?._id);
  const [intervalId, setIntarvalId] = useState('');
  const [indexItem, setIndexItem] = useState('');
  const upload = useSelector((state) => state.uploadReducer);
  const { iconUrl, error, loading } = upload;
  const initialValues = {
    baseCommission: data?.baseCommission,
    meansOfTransportation: data?.meansOfTransportation,
    // cityId: cityId,
  };
  const formik = useFormik({
    initialValues:
      // initialValues,
      {
        baseCommission: '',
        meansOfTransportation: '',
        // iconUrl: '',
        // cityList: [],
        // oneServiceCategory: false,
        // oneStoreCategory: false,
      },
    validationSchema,
  });
  const [cities, setCities] = useState([]);

  const addTransportation = () => {
    setOpenModal('addTransportaions');
  };
  const handleCloseModal = () => {
    setOpenModal('');
  };
  const onUpdate = () => {
    console.log('value', formik.values);
    setIsLoading(true);
    if (configId) {
      console.log('update');
      updateDriverConfigPrice(configId, { ...formik.values, cityId: cityId })
        .then(() => {
          setRefeatchData(!refeatchData);
          close();
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      console.log('create', formik.values);

      createDriverConfigPrice({ ...formik.values, cityId: cityId })
        .then(() => {
          setRefeatchData(!refeatchData);
          close();
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  function setValue() {
    if (data) {
      // console.log(data);
      // !iconUrl && dispatch(defaultImagesAction(data?.iconUrl));
      // if(iconUrl){
      formik.setValues({
        baseCommission: data?.baseCommission,
        meansOfTransportation: data?.meansOfTransportation,
      });
    }
    const maxObj = driverConfig.reduce((prev, current) => {
      return prev.meansOfTransportation.length >
        current.meansOfTransportation.length
        ? prev
        : current;
    });

    setMaxObject(maxObj);
  }
  useEffect(() => {
    setValue();
  }, [data]);

  return (
    <div>
      <CustomInput
        labelText="baseCommission"
        formControlProps={{
          fullWidth: true,
        }}
        type="text"
        error={
          formik.touched.baseCommission && Boolean(formik.errors.baseCommission)
        }
        inputProps={{
          value: formik.values.baseCommission,
          onBlur: formik.handleBlur('baseCommission'),
          onChange: formik.handleChange('baseCommission'),
        }}
      />
      {(formik.values.meansOfTransportation || []).map((item, index) => (
        <div key={index}>
          <label htmlFor={`meansOfTransportation[${index}].name`}>
            item {index + 1}:
          </label>
          <br />
          <CustomInput
            labelText="Name"
            formControlProps={{
              fullWidth: true,
            }}
            disable={true}
            type="text"
            inputProps={{
              value: item?.name,
              onBlur: formik.handleBlur(`meansOfTransportation[${index}].name`),
            }}
          />
          <SwitchComponent
            onChange={(value) =>
              formik.setFieldValue(
                `meansOfTransportation[${index}].info.enable`,
                value,
              )
            }
            checked={item?.info?.enable}
            label="enable"
          />
          <Button
            round
            type="submit"
            color="success"
            size="sm"
            loaderColor="white"
            onClick={() => {
              setOpenModal('interval');
              setIntarvalId(item?._id);
              setIndexItem(index);
            }}
          >
            interval
          </Button>
        </div>
      ))}
      {(formik.values.meansOfTransportation || []).length <
      (transportation || []).length ? (
        <Fab color="primary" aria-label="add" onClick={addTransportation}>
          <AddIcon />
        </Fab>
      ) : (
        ''
      )}

      <Box textAlign="center">
        <Button
          round
          type="submit"
          color="success"
          disabled={!formik.dirty || !formik.isValid || isLoading}
          loading={isLoading}
          loaderColor="white"
          onClick={onUpdate}
        >
          {configId ? 'Edit' : 'create'}
        </Button>
      </Box>
      {openModal && openModal === 'interval' && (
        <ModalComponent
          maxWidth={false}
          close={handleCloseModal}
          visible={openModal === 'interval'}
          title="interval"
        >
          <EditIntervalConfigComponent
            formik={formik}
            intervalId={intervalId}
            indexItem={indexItem}
          />
        </ModalComponent>
      )}
      {openModal && openModal === 'addTransportaions' && (
        <ModalComponent
          maxWidth={false}
          close={handleCloseModal}
          visible={openModal === 'addTransportaions'}
          title="add Transportaions"
        >
          <AddTransportationComponent
            transportation={transportation}
            formik={formik}
            maxObject={maxObject}
            close={handleCloseModal}
          />
        </ModalComponent>
      )}
    </div>
  );
}

EditDeriverConfigByCity.propTypes = {
  data: PropTypes.any,
  close: PropTypes.func,
};
