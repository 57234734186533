export const DATA = {
    admin: {
        _id: '',
        email: '',
        username: ''
    },
    nonQualityName: '',
    orderId: '',
    customer: {
        _id: '',
        name: ''
    },
    store: {
        _id: '',
        name: ''
    },
    delivery: {
        _id: '',
        name: ''
    },
    articlePrice: 0,
    responsable: '',
    destination: '',
    nonQualityType: '',
    deliveryCosts: 0,
    amount: 0,
    deliveryManCommission: 0,
}