import React, { useState } from 'react';
import ModalComponent from '../../../components/Modal.component';
import { useDispatch, useSelector } from 'react-redux';

// selector
import { getOrdersDataState, getModelDataState } from '../redux/selectors';
import { getUserDataState } from '../../auth/redux/selectors';

// Actions
import {
  openModelAction,
  closeModelAction,
} from '../redux/actions/order.actions';

// component
import AlarmsComponenet from '../components/Alarms.componenet';

export default function OrdersModelPage({}) {
  const dispatch = useDispatch();
  const { orders, loading, error, invalidOrders } =
    useSelector(getOrdersDataState);
  const { itemId, index, model } = useSelector(getModelDataState);
  const userData = useSelector(getUserDataState);
  const [openAlarm, setOpenAlarm] = useState(true);
  const [admin, setAdmin] = useState({
    _id: userData.user._id,
    email: userData.user.email,
    username: userData.user.username,
    userName: userData.user.username,
    entityId: userData.user.entityId,
    scope: userData.user.scope,
  });
  return (
    <div>
      {model && model == 'Alarm' && (
        <ModalComponent
          maxWidth={'lg'}
          close={() => dispatch(closeModelAction())}
          visible={model == 'Alarm' && openAlarm}
          title="Alarms"
        >
          <AlarmsComponenet
            orderId={itemId}
            setOpenAlarm={setOpenAlarm}
            admin={admin}
          />
        </ModalComponent>
      )}
    </div>
  );
}
