import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useAlert } from 'react-alert';
// import moment from 'moment-timezone';
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import CustomInput from '../../../components/material-dashboard-pro-react/CustomInput/CustomInput';
import SelectBoxComponent from '../../../components/SelectBox.component';
import {
  logModeDispatching,
  updateDispatchingMode,
} from '../services/serverApi.service';
import ImageUpload from '../../../components/material-dashboard-pro-react/CustomUpload/ImageUpload';
import { useDispatch, useSelector } from 'react-redux';
import Switch from '@material-ui/core/Switch/Switch';
import { getUserDataState } from 'features/auth/redux/selectors';
import { DATA } from '../../../utils/logAudit'
import { resetObj } from 'utils/resetObj';

const validationSchema = yup.object({
  name: yup.string().required(),
  message: yup.string().required(),
  imageUrl: yup.string(),
  showImage: yup.string().required(),
  showMessage: yup.string().required(),
});
export default function EditDispatchingModesComponent({
  itemEdited,
  close,
  index,
  data,
}) {
  const alert = useAlert();
  const [isLoading, setIsLoading] = useState(false);
  // const [countries, setCountries] = useState([]);
  // console.log(onItemCreated);
  const dispatch = useDispatch();
  const upload = useSelector((state) => state.uploadReducer);
  const [displayImage, setDisplayImage] = useState(false);
  const { iconUrl, error, loading } = upload;
  // admin data :
  const adminData = useSelector(getUserDataState);
  const [admin, setAdmin] = useState({
    _id: adminData.user._id,
    email: adminData.user.email,
    username: adminData.user.username
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      message: '',
      imageUrl: '',
      showImage: false,
      showMessage: false,
    },
    validationSchema,
  });

  // console.log(data._id)

  const onEdit = () => {
    // reset object before setting data :
    resetObj(DATA);
    // for logger audit data :
    DATA.admin = admin;
    DATA.actionType = 'Mode affichage';
    DATA.targetId = data._id;
    DATA.target = 'Delivery-Info';
    DATA.loggerDetails = {
      beforeValue: data.message,
    }
    // DATA.beforeValue = data.message;
    // DATA.afterValue = formik.values.message;
    // console.log(DATA);
    logModeDispatching(DATA)
      .then(({ data }) => {
        console.log(data);
      })
      .catch((error) => {
        console.log(error.message, {
          type: 'error',
        });
      })
    // console.log(formik.isValid , formik.values);
    if (formik.isValid) {
      setIsLoading(true);
      updateDispatchingMode(data._id, formik.values)
        .then(({ data }) => {
          alert.show('City has been created', {
            type: 'success',
          });
          formik.resetForm();
          //onItemCreated();
          itemEdited({ ...data, index: index });
        })
        .catch((error) => {
          alert.show(error.message, {
            type: 'error',
          });
        })
        .finally(() => {
          setIsLoading(false);
          setDisplayImage(false);
          close();
        });
    }
  };

  useEffect(() => {
    if (data) {
      console.log(iconUrl)
      formik.setValues({
        name: data.name,
        message: data.message,
        imageUrl: iconUrl ? iconUrl : data.imageUrl,
        showImage: data.showImage,
        showMessage: data.showMessage,
      });
    }
  }, [data, displayImage, iconUrl]);

  return (
    <div>
      <div>
        {!displayImage && <span>you need to upload a new image</span>}
        <Switch
          color="primary"
          checked={displayImage}
          value={displayImage}
          onChange={(e) => {
            displayImage ? setDisplayImage(false) : setDisplayImage(true);
          }}
        />
      </div>
      <Box display="flex" justifyContent="center">
        {Boolean(data) &&
          (!displayImage ? (
            <img src={data.imageUrl || ''} alt="dqs" height="100" width="120" />
          ) : (
            <ImageUpload label="Select icon" avatar folder="service" />
          ))}
      </Box>
      <CustomInput
        labelText="Name"
        formControlProps={{
          fullWidth: true,
        }}
        type="text"
        error={formik.touched.name && Boolean(formik.errors.name)}
        inputProps={{
          value: formik.values.name,
          onBlur: formik.handleBlur('name'),
          onChange: formik.handleChange('name'),
        }}
      />
      <CustomInput
        labelText="message"
        formControlProps={{
          fullWidth: true,
        }}
        type="text"
        error={formik.touched.message && Boolean(formik.errors.message)}
        inputProps={{
          value: formik.values.message,
          onBlur: formik.handleBlur('message'),
          onChange: formik.handleChange('message'),
        }}
      />
      <SelectBoxComponent
        items={['true', 'false'].map((item) => ({
          value: item,
          label: item == 'true' ? 'Yes' : 'No',
        }))}
        inputLabel="show Image"
        onValueChange={formik.handleChange('showImage')}
        value={formik.values.showImage}
      />
      <SelectBoxComponent
        items={['true', 'false'].map((item) => ({
          value: item,
          label: item == 'true' ? 'Yes' : 'No',
        }))}
        inputLabel="show Message"
        onValueChange={formik.handleChange('showMessage')}
        value={formik.values.showMessage}
      />
      <Box textAlign="center">
        <Button
          round
          type="submit"
          color="success"
          // disabled={!formik.dirty || !formik.isValid || isLoading}
          loading={isLoading}
          loaderColor="white"
          onClick={onEdit}
        >
          Create
        </Button>
      </Box>
    </div>
  );
}

EditDispatchingModesComponent.propTypes = {
  // onItemCreated: PropTypes.func.isRequired,
  itemEdited: PropTypes.func,
  index: PropTypes.number,
  close: PropTypes.func,
  data: PropTypes.array,
};
