import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useFormik } from 'formik';
import * as yup from 'yup';
import CustomInput from '../../../components/material-dashboard-pro-react/CustomInput/CustomInput';
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import {
  getCategoryTypeList,
  setCategoryTypeList,
} from '../services/serverApi.service';

const validationSchema = yup.object({
  name: yup.string().required(),
  baseName: yup.string().required(),
});

export default function StoreCreneauTextComponent({ setting }) {
  const [isLoading, setIsLoading] = useState(false);
  const [categoryType, setCategoryType] = useState({
    categoryTypeList: [],
    baseNames: [],
  });
  const formik = useFormik({
    initialValues: {
      name: '',
      baseName: '',
    },
    validationSchema,
  });

  const fetachCategoryType = () => {
    getCategoryTypeList()
      .then(({ data }) => {
        setCategoryType({
          categoryTypeList: data.categoryType.categoryTypeList || [],
          baseNames: data.categoryType.baseNames || [],
        });
      })
      .catch((err) => console.log(err));
  };
  const saveCategoryTypeList = () => {
    console.log('formik.values.name', formik.values.name);
    console.log('formik.values.baseName', formik.values.baseName);
    const tempSatate = categoryType;
    formik.values.name != '' &&
      tempSatate.categoryTypeList.push(formik.values.name);
    formik.values.baseName != '' &&
      tempSatate.baseNames.push(formik.values.baseName);
    setCategoryTypeList(tempSatate)
      .then(({ data }) => {
        setCategoryTypeList({
          categoryTypeList: data.categoryType.categoryTypeList || [],
          baseNames: data.categoryType.baseNames || [],
        });
        formik.setFieldValue('name', '');
        formik.setFieldValue('baseName', '');
        formik.resetForm();
      })
      .catch((error) => console.log(error));
  };
  const deleteRating = (index, arr) => {
    const tempSatate = categoryType;
    if (index !== -1) {
      switch (arr) {
        case 'categoryTypeList':
          tempSatate.categoryTypeList.splice(index, 1);
          break;
        case 'baseNames':
          tempSatate.baseNames.splice(index, 1);
          break;

        default:
          break;
      }

      setCategoryTypeList(tempSatate)
        .then(({ data }) => {
          formik.resetForm();
        })
        .catch((error) => console.log(error));
    }
  };

  useEffect(() => {
    fetachCategoryType();
  }, []);

  return (
    <div>
      <div className="d-flex ">
        <div>
          {(categoryType.categoryTypeList || []).map((item, index) => (
            <p>
              <CloseIcon
                onClick={() => deleteRating(index, 'categoryTypeList')}
                fontSize="small"
                style={{ color: 'red', cursor: 'pointer' }}
              />
              {item}
            </p>
          ))}
          <CustomInput
            labelText="Name"
            formControlProps={{
              fullWidth: true,
            }}
            type="text"
            error={formik.touched.name && Boolean(formik.errors.name)}
            inputProps={{
              value: formik.values.name,
              onBlur: formik.handleBlur('name'),
              onChange: formik.handleChange('name'),
            }}
          />
        </div>
        <div className="ml-5">
          {(categoryType.baseNames || []).map((item, index) => (
            <p>
              <CloseIcon
                onClick={() => deleteRating(index, 'baseNames')}
                fontSize="small"
                style={{ color: 'red', cursor: 'pointer' }}
              />
              {item}
            </p>
          ))}
          <CustomInput
            labelText="baseName"
            formControlProps={{
              fullWidth: true,
            }}
            type="text"
            error={formik.touched.baseName && Boolean(formik.errors.baseName)}
            inputProps={{
              value: formik.values.baseName,
              onBlur: formik.handleBlur('baseName'),
              onChange: formik.handleChange('baseName'),
            }}
          />
        </div>
      </div>
      <Box textAlign="right">
        <Button
          round
          type="submit"
          color="success"
          disabled={isLoading}
          loading={isLoading}
          loaderColor="white"
          onClick={saveCategoryTypeList}
        >
          save
        </Button>
      </Box>
    </div>
  );
}

StoreCreneauTextComponent.propTypes = {
  setting: PropTypes.any.isRequired,
};
