import React, { useEffect, useState } from 'react';
import { updateSetting } from '../services/serverApi.service';
import SwitchComponent from '../../../components/Switch.component';

export default function KallixPayTab({ setting }) {
  const [chargeKaalixPay, setChargeKaalixPay] = useState(
    setting[0]?.chargeKaalixPay || false,
  );
  const editKaalixPay = (value) => {
    setting[0].chargeKaalixPay = value;
    updateSetting(setting[0]._id, setting[0])
      .then(({ data }) => {
        setChargeKaalixPay(data.data.chargeKaalixPay);
      })
      .catch((error) => console.log(error));
  };
  return (
    <div>
      <SwitchComponent
        onChange={(value) => editKaalixPay(value)}
        value="checked"
        checked={chargeKaalixPay}
        label={'chargeKaalixPay'}
      />
    </div>
  );
}
