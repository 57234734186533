import React, { useState, useEffect, useRef } from 'react';
import DataTableSortingContainer from '../../../components/DataTableSortingContainer.component';
import { getCallApiForLogin } from '../services/server.api.service';
import moment from 'moment';
import SelectBoxComponent from '../../../components/SelectBox.component';
import { callApiSearchStatusForLogin } from '../mock';

import { Box, Grid, TextField } from '@material-ui/core';
import { DateTimePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import CustomButton from '../../../components/material-dashboard-pro-react/CustomButtons/Button';

export default function CallApiPage() {
  const inpRef = useRef();
  // const alert = useAlert();
  const [resultsData, setResultsData] = useState({
    total: 0,
    items: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState(null);
  const [item, setItem] = useState({});
  const [page, setPage] = useState(0);
  let numberOfRows = [20, 50, 100];
  const [rowsPerPage, setRowsPerPage] = useState(numberOfRows[0]);
  const [total, setTotal] = useState(0);
  const [callHistory, setCallHistory] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchBy, setSearchBy] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [status, setStatus] = useState('');
  const [call, setCall] = useState('');
  const [useDate, setUseDate] = useState(false);
  const columns = [
    {
      Header: 'Created',
      accessor: 'createdAtCell',
    },
    {
      Header: 'status',
      accessor: 'data.status',
    },
    {
      Header: 'errors',
      accessor: 'data.err',
    },
    {
      Header: 'src',
      accessor: 'loginSrc',
    },
    {
      Header: 'deliveryMan email',
      accessor: 'data.body.username',
    },
    {
      Header: 'deliveryMan',
      accessor: 'data.response.user.username',
    },
    {
      Header: 'deliveryManID',
      accessor: 'deliveryManId',
    },
  ];
  const prepareToShowHistory = (item, index) => {
    return {
      ...item,
      createdAtCell:
        item.createdAt && moment(item.createdAt).format('MM/DD hh:mm:ss'),
      deliveryManId: item.data?.response?.provider?.id,
      Rowcolor: item.data && item.data.err && 'red',
      loginSrc: howIsloged(item?.data?.body),
    };
  };
  const howIsloged = (body) => {
    if (!body) {
      return '--';
    } else {
      if (body.grant_type === 'client_credentials') {
        return 'Marjene';
      } else if (body.grant_type === 'password') {
        return 'DeliveryMan';
      }
    }
  };
  const SearchComponent = () => {
    return (
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={4} className="pb-3">
          <SelectBoxComponent
            items={callApiSearchStatusForLogin.map((type) => ({
              value: type,
              label: type,
            }))}
            inputLabel="Staus"
            onValueChange={(items) => {
              // setSearchText(inpRef.current.value);
              // setSearchBy(items);
              setStatus(items);
              getCall(0, rowsPerPage, startDate, endDate, useDate, items);
            }}
            value={status}
          />
        </Grid>
        {/* <Grid item xs={4}>
          <input
            type="text"
            ref={inpRef}
            placeholder="search"
            style={{
              border: 'none',
              borderBottom: '1px solid black',
            }}
            defaultValue={searchText}
          />
        </Grid> */}

        <Grid item xs={4} className="mb-3">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              renderInput={(props) => <TextField {...props} />}
              label="Start date"
              ampm={false}
              value={startDate}
              onChange={(newValue) => {
                setStartDate(moment(newValue).format().split('+')[0]);
                !useDate && setUseDate(true);
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={4} className="mb-3">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              renderInput={(props) => <TextField {...props} />}
              label="End Date"
              ampm={false}
              value={endDate}
              onChange={(newValue) => {
                setEndDate(moment(newValue).format().split('+')[0]);
                !useDate && setUseDate(true);
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} container justify="flex-end">
          <Box textAlign="right">
            <CustomButton
              round
              type="submit"
              color="success"
              size="sm"
              loaderColor="white"
              onClick={() => {
                // setSearchText(inpRef.current.value);
                // setShortId(inpRefForShortId.current.value);
                page > 0 && setPage(0);

                getCall(
                  page > 0 ? 0 : page,
                  rowsPerPage,
                  startDate,
                  endDate,
                  useDate,
                  status,
                );
              }}
            >
              find
            </CustomButton>
          </Box>
          <Box textAlign="left">
            <CustomButton
              round
              type="submit"
              color="google"
              size="sm"
              loaderColor="white"
              onClick={() => {
                setSearchText('');
                setSearchBy('');
                setUseDate(false);
                setStartDate('');
                setEndDate('');
                page > 0 && setPage(0);
                setStatus(''), getCall(0, rowsPerPage, '', '', false, '', '');
              }}
            >
              clear
            </CustomButton>
          </Box>
        </Grid>
      </Grid>
    );
  };
  const getCall = (skip, limit, startDate, endDate, useDate, status) => {
    getCallApiForLogin(skip, limit, startDate, endDate, useDate, status)
      .then(({ data }) => {
        setTotal(data.total);
        setCallHistory(data.callApi);
        // useDate && setDisplayTotal(true);
      })
      .catch((error) => {
        console.log(error);
        setResultsData({ items: [] });
      });
  };

  const handlePaginate = (val) => {
    setResultsData({
      items: [],
      tableItems: [],
    });
    getCall(
      val.pageSelect,
      val.numberOfRows,
      startDate,
      endDate,
      useDate,
      status,
    );
    setPage(val.pageSelect);
    setRowsPerPage(val.numberOfRows);
  };
  useEffect(() => {
    callHistory &&
      callHistory.length > 0 &&
      setResultsData({
        items: callHistory.map((item, index) => {
          return prepareToShowHistory(item, index);
        }),
      });
  }, [callHistory]);
  return (
    <div>
      <DataTableSortingContainer
        resultsData={resultsData}
        total={total}
        initData={resultsData.items}
        columns={columns}
        title={<strong>Call Api</strong>}
        isLoading={isLoading}
        page={page + 1}
        // customColumnStyle={customColumnStyle}
        onPaginate={handlePaginate}
        isPaginate={true}
        searchInp={true}
        SearchComponent={SearchComponent}
      />
    </div>
  );
}
