import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// compon
import GridContainer from '../../../components/material-dashboard-pro-react/Grid/GridContainer';
import Card from '../../../components/material-dashboard-pro-react/Card/Card';
import CardHeader from '../../../components/material-dashboard-pro-react/Card/CardHeader';
import CardBody from '../../../components/material-dashboard-pro-react/Card/CardBody';
// mat component
import SelectBoxComponent from '../../../components/SelectBox.component';
import CustomeButton from '../../../components/material-dashboard-pro-react/CustomButtons/Button';

// material componenet
import {
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Typography,
  Paper,
  Button,
  Grid,
} from '@mui/material';

import MapIcon from '@mui/icons-material/Map';

// call api
import { getCities } from '../../orders/services/serverApi.service';
import { getParners } from '../../Partners/services/server.api.service';

// Actions
import {
  defaultSelectedCities,
  selectCityAction,
  selectWareHouseAction,
  totalDeliveriesByWarehouseUsingDeliveryManStatusAction,
  changeFilterByAction,
  selectDeliveriesUsingDeliveryManStatusAction,
} from '../redux/actions/filter.actions';
import {
  changeOrderAlarmAction,
  openModelAction,
} from '../../orders/redux/actions/order.actions';
import {
  getPartnerDeliveriesDataState,
  filterSelector,
} from '../redux/selectors';
import { allArray, driveInfoTableHeaders } from '../mock';
import {
  socketConnect,
  socketConnectPertnerGps,
} from '../services/server.api.service';
import { getUserDataState } from '../../auth/redux/selectors';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

export default function DeliveryMenInfoComponent(
  {
    // setSelectedOrders,
    // selectedOrders,
    // filterBy,
    // setFilterBy,
    // alarm,
  },
) {
  const dispatch = useDispatch();
  // const [cities, setCities] = useState([]);
  const [totalOrdersByCity, setTotalOrdersByCity] = useState({});
  const { allDeliveries, loading, error, deliveriesChangeCounter } =
    useSelector(getPartnerDeliveriesDataState);
  const {
    totalDeliveriesUsingDeliveryManStatus,
    filterBy,
    selectedDeliveryManStatus,
    selectedWarehousseyOrdersDeliveryManStatus,
    selectedCities,
  } = useSelector(filterSelector);
  function Row({ row, index }) {
    return (
      <React.Fragment>
        <TableRow>
          <TableCell component="th" scope="row">
            {(cities || [])
              .find((item) => item._id == row)
              ?.name?.substring(0, 4)}
          </TableCell>
          <TableCell align="center">
            <span style={{ width: '15px' }}>
              {totalOrdersByCity[row] || '--'}
            </span>
          </TableCell>
          {/* <TableCell
            align="center"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setOpen(true);
              setCityId(row._id);
              setSpecificCell(['occupiedDeliveryMen', 'freeDeliveryMen']);
              setCellOpen('occupied');
            }}
          >
            <span style={{ width: '35px' }}>
              {row?.occupiedDeliveryMenCount +
                ' | ' +
                row?.freeDeliveryMenCount}
            </span>
          </TableCell>
          <TableCell
            align="center"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setOpen(true);
              setCityId(row._id);
              setSpecificCell(['planifiedDeliveryMen']);
              setCellOpen('planifiedDeliveryMen');
            }}
          >
            <span style={{ width: '15px' }}>
              {row?.planifiedDeliveryMenCount}
            </span>
          </TableCell>
          <TableCell
            align="center"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setOpen(true);
              setCityId(row._id);
              setSpecificCell(['availableDeliveryMen']);
              setCellOpen('availableDeliveryMen');
            }}
          >
            {row?.availableDeliveryMenCount}
          </TableCell>
          <TableCell
            align="center"
            style={{ cursor: 'pointer', borderRight: '1px solid black' }}
            onClick={() => {
              setOpen(true);
              setCityId(row._id);
              setSpecificCell([
                'dispatchableDeliveryMen',
                'nonDispatchableDeliveryMen',
              ]);
              setCellOpen('dispatchable');
            }}
          >
            <span style={{ width: '15px' }}>
              {row?.dispatchableDeliveryMenCount +
                ' | ' +
                row?.nonDispatchableDeliveryMenCount}
            </span>
          </TableCell>
          {citiesPlaning &&
            citiesPlaning.length > 0 &&
            (Object.keys(citiesPlaning[0].times) || [])
              .slice(8)
              .map((time) => (
                <TableCell align="center">
                  {citiesPlaning.find((x) => x.cityId == row._id) &&
                  Object.keys(
                    citiesPlaning.find((x) => x.cityId == row._id)?.times,
                  )
                    ? citiesPlaning.find((x) => x.cityId == row._id)?.times[
                        time
                      ]
                    : '--'}
                </TableCell>
              ))} */}
        </TableRow>
      </React.Fragment>
    );
  }
  // const [totalOrders, setTotalOrders] = useState({});
  // const [selectedWarehousseOrders, setSelectedWarehousseOrders] = useState([]);

  const selectedCell = {
    backgroundColor: '#3496ff',
    borderRadius: '50%/50%',
    padding: '5px',
    color: '#FFFFFF',
    cursor: 'pointer',
    textAlign: 'center',
  };

  const checkIfWarehouseSelected = (warehouse) => {
    if (
      (selectedWarehousseyOrdersDeliveryManStatus || []).find(
        (x) => x == warehouse,
      )
    )
      return { ...selectedCell };
    return { cursor: 'pointer' };
  };
  const checkIfSelected = (warehouse, status) => {
    if ((selectedDeliveryManStatus[warehouse] || []).find((x) => x == status))
      return { ...selectedCell };
    return { cursor: 'pointer' };
  };
  const totalOrdersNumber = () => {
    const totalOrdersByCity = {};
    (allDeliveries || []).map((order) => {
      totalOrdersByCity[order?.wareHouse?.wareHouseLabel] = {
        total:
          (totalOrdersByCity[order?.wareHouse?.wareHouseLabel]?.total || 0) + 1,
        status: {
          ...totalOrdersByCity[order?.wareHouse?.wareHouseLabel]?.status,
          [order.deliveryManStatus]:
            (totalOrdersByCity[order?.wareHouse?.wareHouseLabel]?.status[
              order.deliveryManStatus
            ] || 0) + 1,
        },
      };
    });
    // console.log(totalOrdersByCity);
    setTotalOrders(totalOrdersByCity);
  };
  const selectOrders = (warehouse, status) => {
    const tempselectedDeliveryManStatus = { ...selectedDeliveryManStatus };
    const tempTotalOrders = { ...totalDeliveriesUsingDeliveryManStatus };
    const tempSelectedWarehousseyOrders = [
      ...selectedWarehousseyOrdersDeliveryManStatus,
    ];
    filterBy != 'deliveryManStatus' &&
      dispatch(changeFilterByAction('deliveryManStatus'));
    const selectedWarehousseyIndex = (
      tempSelectedWarehousseyOrders || []
    ).findIndex((x) => x == warehouse);
    // console.log(tempSelectedWarehousseyOrders);
    // console.log(selectedWarehousseyIndex);
    if (status) {
      if (tempselectedDeliveryManStatus[warehouse]) {
        if (tempselectedDeliveryManStatus[warehouse].includes(status)) {
          const findIndex = tempselectedDeliveryManStatus[warehouse].findIndex(
            (x) => x == status,
          );
          findIndex != -1 &&
            tempselectedDeliveryManStatus[warehouse].splice(findIndex, 1);
          tempselectedDeliveryManStatus[warehouse].length == 0 &&
            delete tempselectedDeliveryManStatus[warehouse];
        } else {
          tempselectedDeliveryManStatus[warehouse].push(status);
        }
      } else {
        tempselectedDeliveryManStatus[warehouse] = [status];
      }
    } else {
      if (selectedWarehousseyIndex != -1) {
        tempSelectedWarehousseyOrders.splice(selectedWarehousseyIndex, 1);
        delete tempselectedDeliveryManStatus[warehouse];
      } else {
        tempSelectedWarehousseyOrders.push(warehouse);
        tempselectedDeliveryManStatus[warehouse] = Object.keys(
          tempTotalOrders[warehouse].status,
        );
      }
    }
    // console.log('tempselectedDeliveryManStatus', tempselectedDeliveryManStatus);
    // console.log('tempSelectedWarehousseyOrders', tempSelectedWarehousseyOrders);
    // setSelectedOrders(tempselectedDeliveryManStatus);
    // setSelectedWarehousseOrders(tempSelectedWarehousseyOrders);
    dispatch(
      selectDeliveriesUsingDeliveryManStatusAction(
        tempselectedDeliveryManStatus,
        tempSelectedWarehousseyOrders,
      ),
    );
  };
  useEffect(() => {
    // totalOrdersNumber();
    dispatch(
      totalDeliveriesByWarehouseUsingDeliveryManStatusAction(
        allDeliveries,
        selectedCities,
      ),
    );
  }, [deliveriesChangeCounter]);
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const handleClickAway = () => {
    setOpen(false);
  };
  // console.log('totalOrders', totalOrders);
  // console.log('selectedWarehousseOrders', selectedWarehousseOrders);
  // console.log('selectedOrders', selectedOrders);
  return (
    <div className="mb-4">
      <TableContainer component={Paper}>
        <Typography className="mt-2 ml-4">Driver info</Typography>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              {driveInfoTableHeaders.map((item) => (
                <TableCell align="center">{item}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {(Object.keys(totalDeliveriesUsingDeliveryManStatus) || []).map(
              (item, index) => (
                <TableRow key={item + index}>
                  <TableCell align="center">{item}</TableCell>
                  {/* <TableCell align="center">dispo</TableCell> */}
                  <TableCell
                    align="center"
                    onClick={(e) =>
                      totalDeliveriesUsingDeliveryManStatus[item]?.total &&
                      selectOrders(item, '')
                    }
                  >
                    <span style={checkIfWarehouseSelected(item)}>
                      {totalDeliveriesUsingDeliveryManStatus[item]?.total}
                    </span>
                  </TableCell>
                  <TableCell
                    align="center"
                    onClick={(e) =>
                      totalDeliveriesUsingDeliveryManStatus[item]?.status
                        ?.CREATED && selectOrders(item, 'CREATED')
                    }
                  >
                    <span style={checkIfSelected(item, 'CREATED')}>
                      {totalDeliveriesUsingDeliveryManStatus[item]?.status
                        ?.CREATED || 0}
                    </span>
                  </TableCell>
                  <TableCell
                    align="center"
                    onClick={(e) =>
                      totalDeliveriesUsingDeliveryManStatus[item]?.status
                        ?.WAITING_FOR_ACCEPT &&
                      selectOrders(item, 'WAITING_FOR_ACCEPT')
                    }
                  >
                    <span style={checkIfSelected(item, 'WAITING_FOR_ACCEPT')}>
                      {totalDeliveriesUsingDeliveryManStatus[item]?.status
                        ?.WAITING_FOR_ACCEPT || 0}
                    </span>
                  </TableCell>

                  <TableCell
                    align="center"
                    onClick={(e) =>
                      totalDeliveriesUsingDeliveryManStatus[item]?.status
                        ?.ACCEPTED && selectOrders(item, 'ACCEPTED')
                    }
                  >
                    <span style={checkIfSelected(item, 'ACCEPTED')}>
                      {totalDeliveriesUsingDeliveryManStatus[item]?.status
                        ?.ACCEPTED || 0}
                    </span>
                  </TableCell>
                  <TableCell
                    align="center"
                    onClick={(e) =>
                      totalDeliveriesUsingDeliveryManStatus[item]?.status
                        ?.ARRIVED_TO_WAREHOUSE &&
                      selectOrders(item, 'ARRIVED_TO_WAREHOUSE')
                    }
                  >
                    <span style={checkIfSelected(item, 'ARRIVED_TO_WAREHOUSE')}>
                      {totalDeliveriesUsingDeliveryManStatus[item]?.status
                        ?.ARRIVED_TO_WAREHOUSE || 0}
                    </span>
                  </TableCell>
                  <TableCell
                    align="center"
                    onClick={(e) =>
                      totalDeliveriesUsingDeliveryManStatus[item]?.status
                        ?.STARTED_PICKING &&
                      selectOrders(item, 'STARTED_PICKING')
                    }
                  >
                    <span style={checkIfSelected(item, 'STARTED_PICKING')}>
                      {totalDeliveriesUsingDeliveryManStatus[item]?.status
                        ?.STARTED_PICKING || 0}
                    </span>
                  </TableCell>
                  <TableCell
                    align="center"
                    onClick={(e) =>
                      totalDeliveriesUsingDeliveryManStatus[item]?.status
                        ?.END_PICKING && selectOrders(item, 'END_PICKING')
                    }
                  >
                    <span style={checkIfSelected(item, 'END_PICKING')}>
                      {totalDeliveriesUsingDeliveryManStatus[item]?.status
                        ?.END_PICKING || 0}
                    </span>
                  </TableCell>
                  <TableCell
                    align="center"
                    onClick={(e) =>
                      totalDeliveriesUsingDeliveryManStatus[item]?.status
                        ?.STARTED_DELIVERY &&
                      selectOrders(item, 'STARTED_DELIVERY')
                    }
                  >
                    <span style={checkIfSelected(item, 'STARTED_DELIVERY')}>
                      {totalDeliveriesUsingDeliveryManStatus[item]?.status
                        ?.STARTED_DELIVERY || 0}
                    </span>
                  </TableCell>
                  <TableCell
                    align="center"
                    onClick={(e) =>
                      totalDeliveriesUsingDeliveryManStatus[item]?.status
                        ?.ARRIVED_TO_DESTINATION &&
                      selectOrders(item, 'ARRIVED_TO_DESTINATION')
                    }
                  >
                    <span
                      style={checkIfSelected(item, 'ARRIVED_TO_DESTINATION')}
                    >
                      {totalDeliveriesUsingDeliveryManStatus[item]?.status
                        ?.ARRIVED_TO_DESTINATION || 0}
                    </span>
                  </TableCell>
                </TableRow>
              ),
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
