import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useAlert } from 'react-alert';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Box } from '@material-ui/core';
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import SelectBoxComponent from '../../../components/SelectBox.component';
import { defaultImagesAction } from '../../../redux/uploadRedux/upload.action';
import { updateItem } from '../services/serverAPI.service';

const validationSchema = yup.object({
  flag: yup.string().required(),
});
export default function EditeItem({
  result,
  close,
  setItems,
  items,
  //   row,
  flags,
  index,
}) {
  const alert = useAlert();
  console.log('index', index);
  const storeId = localStorage.getItem('storeId');
  const upload = useSelector((state) => state.uploadReducer);
  const dispatch = useDispatch();
  const { iconUrl, error, loading } = upload;
  // console.log(iconUrl, error, loading);
  const [isLoading, setIsLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      flag: '',
    },
    validationSchema,
  });

  const setValue = () => {
    if (result) {
      // console.log('data',data);
      !iconUrl && dispatch(defaultImagesAction(result?.imageUrl?.props?.src));

      formik.setValues({
        name: result?.name,
        storeId: result?.storeId,
        productId: result?.productId,
        description: result?.description,
        price: result?.price,
        imageUrl: result?.imageUrl,
        inStock: result?.inStock,
        specifications: result?.specifications,
        isMainPromo: result?.isMainPromo,
        _id: result?._id,
        promoSetting: result?.promoSetting,
        flag: result?.flag || '',
      });
    }
  };
  const onEdit = () => {
    // console.log(formik.values);
    setIsLoading(true);
    updateItem(formik.values)
      .then(({ data }) => {
        // console.log('eeeeee',data);

        alert.show('flag updated', {
          type: 'success',
        });
        // console.log('heeeey',data);
        let tempData = items;
        tempData[index] = data.Item;
        // console.log();
        setItems(tempData);
        close();
      })
      .catch((error) => {
        alert.show(error.message, {
          type: 'error',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setValue();
  }, [result]);

  // useEffect(() => {
  //   iconUrl && formik.setFieldValue('imageUrl', iconUrl);
  // }, [iconUrl]);

  return (
    <div>
      <Box style={{ width: '100%' }}>
        <SelectBoxComponent
          items={(flags || []).map((item) => ({
            value: item.name,
            label: item.name,
          }))}
          inputLabel="flag list"
          onValueChange={(items) => {
            formik.setFieldValue('flag', items);
          }}
          value={formik.values.flag}
        />
      </Box>

      <Box textAlign="center">
        <Button
          round
          type="submit"
          color="success"
          disabled={!formik.dirty || !formik.isValid || isLoading}
          loading={isLoading}
          loaderColor="white"
          onClick={onEdit}
        >
          Edit Flag
        </Button>
      </Box>
    </div>
  );
}
