import React, { useEffect, useState } from 'react';
// lib
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Box } from '@material-ui/core';
import { useAlert } from 'react-alert';

// compo
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import CustomInput from '../../../components/material-dashboard-pro-react/CustomInput/CustomInput';
import SelectBoxComponent from '../../../components/SelectBox.component';
import ModalComponent from '../../../components/Modal.component';
import InputAdornment from '@material-ui/core/InputAdornment';
import RoomIcon from '@mui/icons-material/Room';
import Map from '../../../components/Map.component';
import { Marker } from '@react-google-maps/api';

// call api
import { addParners, editOrganisation } from '../services/server.api.service';

const validationSchema = yup.object({
  name: yup.string().required(),
  cityId: yup.string().required(),
  organisationName: yup.string().required(),
  contact: yup.string().required(),
  wareHouseLabel: yup.string().required(),
  wareHouseId: yup.string().required(),
  lat: yup.number().required(),
  lng: yup.number().required(),
});
export default function EditOrganisationComponent({
  close,
  cities,
  item,
  organisation,
  indexWarehouss,
  loadData,
}) {
  //   console.log(item);
  const alert = useAlert();
  const [isLoading, setIsLoading] = useState(false);
  const [modalName, setModalName] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [path, setPath] = useState({});
  const [markerPosition, setMarkerPosition] = useState({});

  const formik = useFormik({
    initialValues: {
      name: '',
      cityId: '',
      organisationName: '',
      contact: '',
      wareHouseLabel: '',
      wareHouseId: '',
      lat: '',
      lng: '',
    },
    validationSchema,
  });
  const changeCityInMap = (cityId) => {
    const findCityLocation = cities.find((city) => city._id == cityId);
    findCityLocation &&
      findCityLocation.hasOwnProperty('cityLocations') &&
      setPath({
        lat: findCityLocation.cityLocations[0][1],
        lng: findCityLocation.cityLocations[0][0],
      });
  };
  const handleOpenModal = (type) => {
    setModalName(type);
    if (formik.values?.cityId) {
      setSelectedCity(formik.values?.cityId);
      changeCityInMap(formik.values?.cityId);
    }
    formik.values?.lat &&
      formik.values?.lng &&
      setMarkerPosition({
        lat: formik.values?.lat,
        lng: formik.values?.lng,
      });
  };
  const handleClose = () => {
    setModalName('');
    setPath('');
    setSelectedCity('');
    setMarkerPosition({});
  };
  const onMarkerDragEnd = (coord) => {
    const { latLng } = coord;
    const lat = latLng.lat();
    const lng = latLng.lng();
    formik.setFieldValue('lat', lat);
    formik.setFieldValue('lng', lng);
  };
  const onEdit = () => {
    const {
      cityId,
      name,
      organisationName,
      contact,
      wareHouseLabel,
      wareHouseId,
      lat,
      lng,
    } = formik.values;
    const body = {
      cityId,
      name,
      organisationName,
      contact,
      wareHouseLabel,
      wareHouseId,
      location: [lat, lng],
    };
    item
      ? (organisation.warehouses[indexWarehouss] = body)
      : organisation.warehouses.push(body);
    setIsLoading(true);

    editOrganisation(organisation._id, organisation)
      .then(() => {
        alert.show('organisation has been saved', {
          type: 'success',
        });
        formik.resetForm();
        loadData();
        close();
      })
      .catch((error) => {
        alert.show(error.message, {
          type: 'error',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  async function setValue() {
    if (item) {
      formik.setValues({
        name: item?.name,
        cityId: item?.cityId,
        organisationName: item?.organisationName,
        contact: item?.contact,
        wareHouseLabel: item?.wareHouseLabel,
        wareHouseId: item?.wareHouseId,
        lat: item?.location && item.location.length > 0 && item.location[0],
        lng: item?.location && item.location.length > 0 && item.location[1],
      });
    } else {
      formik.setValues({
        organisationName: organisation.name,
      });
    }
  }
  useEffect(() => {
    setValue();
  }, []);
  return (
    <div>
      <CustomInput
        labelText="Name"
        formControlProps={{
          fullWidth: true,
        }}
        type="text"
        error={formik.touched.name && Boolean(formik.errors.name)}
        inputProps={{
          value: formik.values.name,
          onBlur: formik.handleBlur('name'),
          onChange: formik.handleChange('name'),
        }}
      />
      <SelectBoxComponent
        items={cities.map((item) => ({
          value: item._id,
          label: item.name,
        }))}
        inputLabel="Cities"
        onValueChange={(item) => {
          formik.setFieldValue('cityId', item);
        }}
        value={formik.values.cityId}
      />
      <CustomInput
        labelText="Organisation Name"
        formControlProps={{
          fullWidth: true,
        }}
        type="text"
        error={
          formik.touched.organisationName &&
          Boolean(formik.errors.organisationName)
        }
        inputProps={{
          value: formik.values.organisationName,
          onBlur: formik.handleBlur('organisationName'),
          onChange: formik.handleChange('organisationName'),
        }}
        disabled={true}
      />
      <CustomInput
        labelText="contact"
        formControlProps={{
          fullWidth: true,
        }}
        type="text"
        error={formik.touched.contact && Boolean(formik.errors.contact)}
        inputProps={{
          value: formik.values.contact,
          onBlur: formik.handleBlur('contact'),
          onChange: formik.handleChange('contact'),
        }}
      />
      <CustomInput
        labelText="wareHouseLabel"
        formControlProps={{
          fullWidth: true,
        }}
        type="text"
        error={
          formik.touched.wareHouseLabel && Boolean(formik.errors.wareHouseLabel)
        }
        inputProps={{
          value: formik.values.wareHouseLabel,
          onBlur: formik.handleBlur('wareHouseLabel'),
          onChange: formik.handleChange('wareHouseLabel'),
        }}
      />
      <CustomInput
        labelText="wareHouseId"
        formControlProps={{
          fullWidth: true,
        }}
        type="text"
        error={formik.touched.wareHouseId && Boolean(formik.errors.wareHouseId)}
        inputProps={{
          value: formik.values.wareHouseId,
          onBlur: formik.handleBlur('wareHouseId'),
          onChange: formik.handleChange('wareHouseId'),
        }}
      />
      <CustomInput
        labelText="lat"
        formControlProps={{
          fullWidth: true,
        }}
        type="number"
        error={formik.touched.lat && Boolean(formik.errors.lat)}
        inputProps={{
          value: formik.values.lat,
          onBlur: formik.handleBlur('lat'),
          onChange: formik.handleChange('lat'),
          endAdornment: (
            <InputAdornment
              position="end"
              onClick={() => handleOpenModal('getPositionMap')}
            >
              <RoomIcon style={{ color: '#4caf50' }} />
            </InputAdornment>
          ),
        }}
      />
      <CustomInput
        labelText="lng"
        formControlProps={{
          fullWidth: true,
        }}
        type="number"
        error={formik.touched.lng && Boolean(formik.errors.lng)}
        inputProps={{
          value: formik.values.lng,
          onBlur: formik.handleBlur('lng'),
          onChange: formik.handleChange('lng'),
        }}
      />
      <Box textAlign="center">
        <Button
          round
          type="submit"
          color="success"
          disabled={!formik.dirty || !formik.isValid || isLoading}
          loading={isLoading}
          loaderColor="white"
          onClick={onEdit}
        >
          save
        </Button>
      </Box>
      {modalName && modalName == 'getPositionMap' && (
        <ModalComponent
          maxWidth={'md'}
          close={handleClose}
          visible={modalName === 'getPositionMap'}
          title="edit"
        >
          <SelectBoxComponent
            items={cities.map((item) => ({
              value: item._id,
              label: item.name,
            }))}
            inputLabel="Cities"
            onValueChange={(item) => {
              changeCityInMap(item);
            }}
            value={selectedCity}
          />
          <Map path={path} zoom={13}>
            <Marker
              position={
                Object.keys(markerPosition).length > 0 ? markerPosition : path
              }
              draggable={true}
              onDragEnd={(e) => onMarkerDragEnd(e)}
            />
          </Map>
        </ModalComponent>
      )}
    </div>
  );
}
