import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useFormik } from 'formik';
import * as yup from 'yup';
import CustomInput from '../../../components/material-dashboard-pro-react/CustomInput/CustomInput';
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import { getAppVersion, setAppVersion } from '../services/serverApi.service';

const validationSchema = yup.object({
  appVersion: yup.string().required(),
});

export default function ConfigAppVersion() {
  const [isLoading, setIsLoading] = useState(false);
  // const [nearToCustomer, setSearchLimit] = useState({});

  const formik = useFormik({
    initialValues: {
      appVersion: '',
    },
    validationSchema,
  });
  const fetchConfigContent = () => {
    getAppVersion()
      .then(({ data }) => {
        putSearchLimit(data?.findAppversion);
      })
      .catch((err) => console.log(err));
  };
  const saveValueSearchLimit = () => {
    setAppVersion(formik.values)
      .then(({ data }) => {
        putSearchLimit(data?.findAppVersion);
        // formik.resetForm();
        //  console.log(data)
      })
      .catch((error) => console.log(error));
  };
  const putSearchLimit = (data) => {
    formik.setValues({
      appVersion: data?.appVersion,
    });
  };
  useEffect(() => {
    fetchConfigContent();
  }, []);

  return (
    <div>
      <Box>
        <CustomInput
          labelText="appVersion"
          formControlProps={{
            fullWidth: true,
          }}
          type="text"
          error={formik.touched.appVersion && Boolean(formik.errors.appVersion)}
          inputProps={{
            value: formik.values.appVersion,
            onBlur: formik.handleBlur('appVersion'),
            onChange: formik.handleChange('appVersion'),
          }}
        />
      </Box>
      <Box textAlign="center">
        <Button
          round
          type="submit"
          color="success"
          disabled={isLoading}
          loading={isLoading}
          loaderColor="white"
          onClick={saveValueSearchLimit}
        >
          save
        </Button>
      </Box>
    </div>
  );
}
