import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  cancelOrderV2Action,
  changeOrderStatusV2Action,
  changeOrderAlarmAction,
  getOrderRequestV2Action,
  initialOrderAction,
  newOrderV2Action,
  openModelAction,
  closeModelAction,
  changeOrderAlarmDataAction,
} from '../redux/actions/order.actions';
import { getAdminsListRequestAction } from '../redux/actions/adminsList.actions';
import { getRidesDataState } from '../redux/selectors';
import { getUserDataState } from '../../auth/redux/selectors';
// formik
import * as yup from 'yup';
import { useFormik } from 'formik';

import moment from 'moment';

import {
  acceptOrRejectOrder,
  cancelOrder,
  getCities,
  socketDriverConnect,
  updateAlarm,
  updateOrderStatus,
} from '../service/serverAPI.service';
import {
  fetchSetting,
  getAdmins,
} from '../../setting/services/serverApi.service';

import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TableCell, TableRow } from '@mui/material';
import '@progress/kendo-theme-default/dist/all.css';
import DataTableSortingContainer from '../../../components/DataTableSortingContainer.component';
import ButtonComponent from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import SelectBoxComponent from '../../../components/SelectBox.component';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import StoreIcon from '@material-ui/icons/Store';
import CommentIcon from '@mui/icons-material/Comment';
import PersonIcon from '@material-ui/icons/Person';
import LocationOn from '@material-ui/icons/LocationOn';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import SportsMotorsportsIcon from '@material-ui/icons/SportsMotorsports';
import MotorcycleIcon from '@material-ui/icons/Motorcycle';
import CloseleIcon from '@material-ui/icons/Close';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import BlockIcon from '@mui/icons-material/Block';

import OrderDetail from '../components/OrderDetail.component';
import { useAlert } from 'react-alert';
import StoreDetailsComponent from '../components/StoreDetails.component';
import ClientDetailsComponent from '../components/ClientDetail.component';
import ProviderDetailsComponent from '../components/ProviderDetails.component';
import DisplayOrderInMap from '../components/DisplayOrderInMap';
import DisplayPositionsMap from '../components/DisplayPositionsMap';
import DisplayPositionsMapFromSocketUpdate from '../components/DisplayPositionsMapFromSocketUpdate';
import ChangeDuration from '../components/ChangeDuration.components';
import CommentComponent from '../components/Comment.component';
import NapsPayloadComponent from '../components/naps.payload.component';
import AssignOrderComponent from '../components/Assign.order.component';
import OrdersModelPage from './Orders.model.page';
import DeliveryMenTableComponent from '../components/DeliveryMen.table.component';
// import InfoAlarmComponent from '../components/Info.alarm.component';
import InfoOrdersComponent from '../components/Info.orders.component';
import InfoDispatchingComponent from '../components/Info.dispatching.component';
import ModalComponent from '../../../components/Modal.component';
import OrderModalComponent from '../components/modal.component';
import CustomInput from '../../../components/material-dashboard-pro-react/CustomInput/CustomInput';
// status
import { styled } from '@mui/material/styles';

import {
  CustomerStatus,
  DeliveryManStatus,
  deliveryStatus,
  orderDispatchingStatus,
  StoreStatus,
} from '../../../config/status.config';
import ButtonMat from '@mui/material/Button';
import { formatTimeWithoutYear } from '../../../helpers/index';
import {
  accptedToArrivedStausArray,
  allArray,
  regroupmentOrderTypes,
  startDeliveryToDelivredStausArray,
  statusTypes,
  switchForAvatarColor,
  statusBackgroundColor,
  statusBackgroundColorNp,
  switchForPaymentMethod,
  columns,
  dispatchedArray,
  customColumnStyle,
  sharedStyleForStatus,
  iconsSharedStyle,
} from '../mock';
import AvatarGroup from 'react-avatar-group';
import notifSong from '../../../assets/audio/pristine-609.mp3';
import { notification } from '../utils';

export default function currentOrdersV2() {
  const dispatch = useDispatch();
  const alert = useAlert();

  const userData = useSelector(getUserDataState);
  //   start states
  const { rides, loading, error, invalidRides, watcherCounter } =
    useSelector(getRidesDataState);

  const [socket, setSocket] = useState(null);
  //   End states

  useEffect(() => {
    // fetchAdmins();

    dispatch(initialOrderAction());
    // fetchSettingFromAPI();
    if (!socket) {
      setSocket(socketDriverConnect(userData.accessToken));
    }
    function subscribeToAdminRoom() {
      if (socket) {
        socket.emit('JOIN_ROOM', 'ADMIN_DISPATCHING');
      }
    }
    if (socket) {
      subscribeToAdminRoom();
    }
    // getCitiesList();
  }, [socket]);
  useEffect(() => {
    dispatch(getAdminsListRequestAction());
    dispatch(getOrderRequestV2Action(['all']));
    // !loading && rides && setOrderToState;
  }, []);
  // useEffect(() => {
  //   // !loading && rides && displayOrderUsingFilter();
  // }, [watcherCounter]);
  useEffect(() => {
    socket &&
      socket.on('RIDE_STATUS_CHANGED', function (data) {
        // if (
        //   selectedCityArry.includes('all') ||
        //   selectedCityArry.includes(data.city._id)
        // ) {
        dispatch(changeOrderStatusV2Action(data));
        // }
      });
    socket &&
      socket.on('NEW_RIDE', function (data) {
        dispatch(newOrderV2Action(data));
        if (data.dispatchingStatus !== 'INVALID') {
          notification();
          alert.show('new order', {
            type: 'success',
          });
        }
      });

    socket &&
      userData &&
      socket.on('RIDE_CANCELLED', function (data) {
        dispatch(cancelOrderV2Action(data._id, data?.customer?._id));
      });

    return () => {
      socket && socket.disconnect();
    };
  }, [socket]);
  return (
    <div>
      <ButtonComponent
        round
        type="submit"
        color="success"
        loading={loading}
        loaderColor="white"
        onClick={() => {
          dispatch(getOrderRequestV2Action(['all']));
        }}
        size="sm"
      >
        reload
      </ButtonComponent>
      {invalidRides && invalidRides.length ? (
        <DataTableSortingContainer
          resultsData={[...invalidRides]}
          initData={[...invalidRides] || []}
          columns={columns.slice(0, -1)}
          // columns={columns}
          title={<strong>invalid Orders</strong>}
          customColumnStyle={customColumnStyle}
        />
      ) : (
        ''
      )}
      <DataTableSortingContainer
        resultsData={([...rides] || []).filter(
          (x) => x.dispatchingStatus !== 'DISPATCHED',
        )}
        initData={
          // resultsData.items
          ([...rides] || []).filter((x) => x.dispatchingStatus !== 'DISPATCHED')
        }
        columns={columns}
        title={<strong>Dispatching service</strong>}
        customColumnStyle={customColumnStyle}
      />
      <DataTableSortingContainer
        resultsData={([...rides] || []).filter(
          (x) => x.dispatchingStatus !== 'DISPATCHED',
        )}
        initData={
          // resultsData.items
          ([...rides] || []).filter((x) => x.dispatchingStatus === 'DISPATCHED')
        }
        columns={columns}
        title={<strong>Livreur service</strong>}
        customColumnStyle={customColumnStyle}
      />
      <OrderModalComponent />
    </div>
  );
}
