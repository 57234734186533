
const scope = 'OrdersPages'
export const GET_RIDE_REQUEST = `${scope}/GET_RIDE_REQUEST`;
export const GET_RIDE_SUCCESS = `${scope}/GET_RIDE_SUCCESS`;
export const GET_RIDE_FAIL = `${scope}/GET_RIDE_FAIL`;
export const CHANGE_RIDE_STATUS = `${scope}/CHANGE_RIDE_STATUS`;
export const CHANGE_RIDE_ALARMS = `${scope}/CHANGE_RIDE_ALARMS`;
export const CHANGE_RIDE_ALARMS_DATA = `${scope}/CHANGE_RIDE_ALARMS_DATA`;
export const CHANGE_RIDE_ALARM = `${scope}/CHANGE_RIDE_ALARM`;
export const SET_RIDE_ALARMS = `${scope}/SET_RIDE_ALARMS`;
export const ACCEPTED_RIDE = `${scope}/ACCEPTED_RIDE`;
export const CANCEL_RIDE = `${scope}/CANCEL_RIDE`;
export const NEW_RIDE = `${scope}/NEW_RIDE`;
export const VALIDATED_RIDE = `${scope}/VALIDATED_RIDE`;
export const INITIAL_STATE = `${scope}/INITIAL_STATE`;
export const OPEN_MODEL = `${scope}/OPEN_MODEL`;
export const CLOSE_MODEL = `${scope}/CLOSE_MODEL`;
// ************************** V2 ***************************   

export const GET_RIDE_REQUESTV2 = `${scope}/GET_RIDE_REQUESTV2`;
export const GET_RIDE_SUCCESSV2 = `${scope}/GET_RIDE_SUCCESSV2`;
export const GET_RIDE_FAILV2 = `${scope}/GET_RIDE_FAIV2`;
export const CHANGE_RIDE_STATUSV2 = `${scope}/CHANGE_RIDE_STATUSV2`;
export const CANCEL_RIDEV2 = `${scope}/CANCEL_RIDEV2`;
export const ACCEPTED_RIDEV2 = `${scope}/ACCEPTED_RIDEV2`;
export const NEW_RIDEV2 = `${scope}/NEW_RIDEV2`;
export const VALIDATED_RIDEV2 = `${scope}/VALIDATED_RIDEV2`;
