import React, { useState } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useAlert } from 'react-alert';
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import CustomInput from '../../../components/material-dashboard-pro-react/CustomInput/CustomInput';
import { createCountry } from '../services/serverApi.service';

const validationSchema = yup.object({
  name: yup.string().required(),
  currency: yup.string().required(),
});

export default function CreateCountryComponent({ onItemCreated }) {
  const alert = useAlert();
  // console.log(onItemCreated);
  const [isLoading, setIsLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      name: '',
      currency: '',
    },
    validationSchema,
  });

  const onCreate = () => {
    setIsLoading(true);
// console.log(formik.values);
    createCountry(formik.values)
      .then(() => {
        alert.show('Country has been created', {
          type: 'success',
        });
        formik.resetForm();
        onItemCreated();
      })
      .catch((error) => {
        alert.show(error.message, {
          type: 'error',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div>
      <CustomInput
        labelText="Name"
        formControlProps={{
          fullWidth: true,
        }}
        type="text"
        error={formik.touched.name && Boolean(formik.errors.name)}
        inputProps={{
          value: formik.values.name,
          onBlur: formik.handleBlur('name'),
          onChange: formik.handleChange('name'),
        }}
      />
      <CustomInput
        labelText="Currency"
        formControlProps={{
          fullWidth: true,
        }}
        type="text"
        error={formik.touched.currency && Boolean(formik.errors.currency)}
        inputProps={{
          value: formik.values.currency,
          onBlur: formik.handleBlur('currency'),
          onChange: formik.handleChange('currency'),
        }}
      />
      <Box textAlign="center">
        <Button
          round
          type="submit"
          color="success"
          disabled={!formik.dirty || !formik.isValid || isLoading}
          loading={isLoading}
          loaderColor="white"
          onClick={onCreate}
        >
          Add
        </Button>
      </Box>
    </div>
  );
}

CreateCountryComponent.propTypes = {
  onItemCreated: PropTypes.func.isRequired,
};
