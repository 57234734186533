import React, { useState, useEffect } from 'react';
import ModalComponent from '../../../components/Modal.component';
import DataTableContainerComponent from '../../../components/DataTableSortingContainer.component';

import { getCities } from '../../delivery-info/services/serverApi.service';
import { allDriverConfig } from '../service/serverAPI.service';
import { transpList } from '../../setting/services/serverApi.service';
import EditDriverConfigComponent from '../components/EditDeriverConfigByCity.component';

import { Box } from '@material-ui/core';
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';

import * as yup from 'yup';
import { useFormik } from 'formik';
const validationSchema = yup.object({});

export default function DriverConfigPage() {
  const [resultsData, setResultsData] = useState({
    items: [],
  });

  const [cities, setCities] = useState([]);
  const [transports, setTransports] = useState([]);
  const [driverConfig, setDriverConfig] = useState([]);
  const [cityId, setCityId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [refeatchData, setRefeatchData] = useState(false);
  const [openModal, setOpenModal] = useState('');

  const columns = [
    {
      Header: 'name',
      accessor: 'name',
    },
    {
      Header: 'transpo',
      accessor: 'enabledMeansOfTransp',
    },
    {
      Header: 'configure',
      accessor: 'configure',
    },
  ];
  const getTranspo = () => {
    transpList()
      .then(({ data }) => {
        setTransports(data?.transportationList?.transportations);
      })
      .catch((err) => console.log(err));
  };
  const getAllCities = () => {
    getCities(0, 0, false)
      .then(({ data }) => {
        setCities(data);
      })
      .catch((error) => console.log(error));
  };
  const getAllDriverConfig = () => {
    allDriverConfig()
      .then(({ data }) => {
        setDriverConfig(data.driverConfig);
        const result = cities.reduce(
          (a, v) => [
            ...a,
            {
              ...v,
              driverConfig: data?.driverConfig.find((x) => x.cityId === v._id),
            },
          ],
          [],
        );
        setResultsData({
          items: result.map((city, index) => {
            return prepareToShowDeliveryMen(city, index);
          }),
        });
      })
      .catch((error) => console.log(error));
  };
  const prepareToShowDeliveryMen = (city, index) => {
    return {
      ...city,
      enabledMeansOfTransp: city?.driverConfig?.meansOfTransportation.map(
        (item) => (
          <>
            <span style={{ color: item?.info.enable ? 'green' : 'red' }}>
              {item.name}
            </span>
            <br />
          </>
        ),
      ),
      configure: (
        <Box textAlign="center">
          <Button
            size="sm"
            round
            type="submit"
            color="success"
            loaderColor="white"
            onClick={() => handleModal(city._id, 'configure')}
          >
            configure
          </Button>
        </Box>
      ),
    };
  };
  const handleModal = (cityId, type) => {
    setCityId(cityId);
    setOpenModal(type);
  };
  const handleCloseModal = () => {
    setCityId();
    setOpenModal('');
  };
  useEffect(() => {
    getTranspo();
    getAllCities();
  }, []);
  useEffect(() => {
    cities && cities.length > 0 && getAllDriverConfig();
  }, [cities, refeatchData]);
  return (
    <div>
      <>
        <DataTableContainerComponent
          resultsData={resultsData.items || []}
          initData={resultsData.items || []}
          columns={columns || []}
          title="config srv Drive by city"
          isLoading={isLoading}
          isPaginate={false}
        />
        {openModal && openModal === 'configure' && (
          <ModalComponent
            maxWidth={false}
            close={handleCloseModal}
            visible={openModal === 'configure'}
            title="config"
          >
            <EditDriverConfigComponent
              data={(driverConfig || []).find(
                (config) => config.cityId === cityId,
              )}
              close={handleCloseModal}
              transportation={transports}
              cityId={cityId}
              driverConfig={driverConfig}
              setRefeatchData={setRefeatchData}
              refeatchData={refeatchData}
            />
          </ModalComponent>
        )}
      </>
    </div>
  );
}
