import moment from 'moment'
import { formatTimeWithoutYear } from '../../../helpers/index';
import ChangeDuration from '../components/ChangeDuration.components';
import OrderStatus from '../components/order.status.component';
import OrderPayments from '../components/order.payment.col.component';
import OrderMenuCompenent from '../components/order.menu.compenent';
import OrderCommentComponenet from '../components/order.comment.componenet';
import ValidationComponenet from '../components/order.validator.component';
import notifSong from '../../../assets/audio/pristine-609.mp3';
const day = moment(new Date()).format('DD');

export const orderFormat = (item) => {
    return {
        ...item,
        cityName: item.city.name.substring(0, 4),
        validator: <ValidationComponenet validator={item?.validatorId} />,
        lastComment: <OrderCommentComponenet comments={item.comments || []} _id={item._id} />,
        deliveryManStatusInp: <OrderStatus dispatchingStatus={item?.dispatchingStatus}
            deliveryManStatus={item?.driverStatus}
            customerStatus={item?.customerStatus} />,
        clientName: item?.customer?.name,
        deliveryManInp: (<>
            <span>{item?.driver && item?.driver?.name}</span>
            <span>{item?.driver && item?.driver?.transportation !== item?.payment?.driverDetail?.meansOfTransportation && ' ' + item?.driver?.transportation}</span>
        </>),
        createdAtInp: item.createdAt && (
            <div style={{ fontSize: '11px' }}>
                {moment(item.createdAt).format('DD') == day
                    ? moment(item.createdAt).format('hh:mm:ss')
                    : moment(item.createdAt).format('hh:mm:ss DD/MM')}
                <br />
                <span style={{ color: 'red' }}>
                    {item.payment.scheduled &&
                        item.payment.shceduleDate !== null &&
                        formatTimeWithoutYear(item.payment.shceduleDate)}
                </span>
            </div>
        ),
        meanOfTrans: item?.payment?.driverDetail?.meansOfTransportation,
        duration: item?.driverStatusTimes && (
            <ChangeDuration
                deliveryManStatusTime={
                    item?.driverStatusTimes &&
                    item?.driverStatusTimes.length &&
                    item?.driverStatusTimes[
                        item?.driverStatusTimes.length - 1
                    ]?.date
                }
                time={item.createdAt}
            />
        ),
        orderPriceCol: item?.payment?.total && item?.payment?.total.toFixed(2),
        paymentMode: <OrderPayments payment={item?.payment} />,
        menu: <OrderMenuCompenent _id={item._id} blocked={item.blocked} />,
        Rowcolor: rowColor(
            item?.driver,
            item?.cityId || item?.city?._id,
            item?.payment?.cityId,
            item?.driver?.cityId,
            item?.nbrOfOrders || 0,
            item.blocked,
            item?.payment?.driverDetail?.meansOfTransportation
        ),
        // nbrOfOrders: item?.nbrOfOrders || 0,
    };
}
export const notification = () => {
    const audio = new Audio(notifSong);
    audio.play().catch((error) => {
        console.log('Audio error::', { error });
    });
};
export const rowColor = (deliveryMan, val1, val2, val3, nbrOfOrders, blocked, transport) => {
    if (transport && deliveryMan?.transportation && transport !== deliveryMan?.transportation) {

        return '#645CBB';
    }
    if (blocked) {
        return '#735392';
    }
    if (deliveryMan) {
        if (val1 === val2 && val1 === val3 && val2 === val3) {
            return nbrOfOrders > 1 ? 'red' : '';
        } else {
            return '#FFCC00';
        }
    } else {
        return nbrOfOrders > 1 ? 'red' : '';
    }
};
export const rowColorForInvalidOrders = (nbrOfOrders) => {
    if (blocked) {
        return '#735392';
    }
    if (deliveryMan) {
        if (val1 === val2 && val1 === val3 && val2 === val3) {
            return nbrOfOrders > 1 ? 'red' : '';
        } else {
            return '#FFCC00';
        }
    } else {
        return nbrOfOrders > 1 ? 'red' : '';
    }
};
