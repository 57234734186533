import React from 'react';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { TableCell, TableRow, Grid } from '@mui/material';
import { statusBackgroundColor } from '../../orders/mock';
import {
  deliveryStatus,
  orderDispatchingStatus,
  StoreStatus,
} from '../../../config/status.config';
import {
  columns,
  columnsDispatched,
  partnerStatusBackgroundColor,
  deliveryStatusBackgroundColor,
} from '../mock';

import { renderStatus } from '../helper/index';
import { styled } from '@mui/material/styles';

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip arrow {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));
const sharedStyleForStatus = {
  width: '120px',
  fontSize: '11px',
  padding: '5px',
  borderRadius: '4px',
  color: 'white',
};
export default function AllStatusComponent({ item }) {
  return (
    <HtmlTooltip
      title={
        <React.Fragment>
          <TableRow>
            <TableCell align="center">dispatching</TableCell>
            <TableCell align="center">
              <span
                style={{
                  backgroundColor:
                    item.dispatchingStatus == 'ON_HOLD'
                      ? statusBackgroundColor.ON_HOLD
                      : statusBackgroundColor.INVALID,
                  ...sharedStyleForStatus,
                }}
              >
                {orderDispatchingStatus[item.dispatchingStatus] ||
                  item.dispatchingStatus}
              </span>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center">deliveryMan</TableCell>
            <TableCell align="center">
              <span
                style={{
                  backgroundColor:
                    deliveryStatusBackgroundColor[item.deliveryManStatus] ||
                    '#ff9b3b',
                  ...sharedStyleForStatus,
                }}
              >
                {/*{*/}
                {/*  item.deliveryManStatusTimes[*/}
                {/*    item.deliveryManStatusTimes.length - 1*/}
                {/*  ]*/}
                {/*}*/}
                {JSON.stringify(
                  item.deliveryManStatusTimes[
                    (item.deliveryManStatusTimes || []).length - 1
                  ]['status'],
                )}
              </span>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center">delivery</TableCell>
            <TableCell align="center">
              <span
                style={{
                  backgroundColor:
                    deliveryStatusBackgroundColor[item.deliveryStatus] ||
                    '#ccc',
                  ...sharedStyleForStatus,
                }}
              >
                {StoreStatus[item.storeStatus] || item?.deliveryStatus}
              </span>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center">Partner</TableCell>
            <TableCell align="center">
              <span
                style={{
                  backgroundColor:
                    partnerStatusBackgroundColor[item?.orderData?.state] ||
                    '#ccc',
                  ...sharedStyleForStatus,
                }}
              >
                {item?.orderData?.state}
              </span>
            </TableCell>
          </TableRow>
        </React.Fragment>
      }
    >
      {renderStatus(
        item.dispatchingStatus,
        item.deliveryManStatus,
        item.deliveryManStatusTimes,
      )}
    </HtmlTooltip>
  );
}
