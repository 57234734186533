import React, { useState, useEffect } from 'react';
import { calcDuration } from '../../../helpers/index';

export default function ChangeDurationComponents({
  time,
  deliveryManStatusTime,
}) {
  const [value, setValue] = useState(0); // integer state
  // setInterval(() => {
  //   setValue((value) => (value + 1) % 60);
  // }, 20000);
  useEffect(() => {
    setTimeout(() => {
      setValue((value) => (value + 1) % 60);
    }, 20000);
  }, [value]);
  const ChangetimeColor = (time) => {
    if (time < 15) {
      return '#007fff';
    } else if (time >= 15 && time < 35) {
      return '#5b5';
    } else if (time >= 35) {
      return '#f44336';
    }
    return null;
  };

  const timer = (time) => {
    const duration = calcDuration(time);
    const timeColor = ChangetimeColor(duration);

    return (
      <div
        style={{
          fontSize: '12px',
          backgroundColor: timeColor,
          color: timeColor ? 'white' : 'black',
          width: '60px',
          padding: '0.5px',
          marginBottom: 2,
          borderRadius: 2,
          textAlign: 'center',
        }}
      >
        {duration} min
      </div>
    );
  };

  return (
    <>
      {timer(time)}
      {timer(deliveryManStatusTime)}
    </>
  );
}
