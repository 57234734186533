import React, { useState, useEffect } from 'react'
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useAlert } from 'react-alert';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { FormControlLabel, Checkbox, Grid, Select, MenuItem, TextField, FormControl } from '@material-ui/core';
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button'
import Input from "@material-ui/core/Input";
import SpecificationList from './SpecificationList.component'
import CustomControllerField from '../../../components/material-dashboard-pro-react/customControllerField/customControllerField';
import SwitchComponent from '../../../components/Switch.component'
import CustomeControllSelect from '../../../components/material-dashboard-pro-react/CustomeControllSelect/CustomeControllSelect';
import { updateSpecification } from '../services/serverAPI.service'

export default function EditeSpecification({ onItemEdited, close, data }) {
    // console.log(data);
    const alert = useAlert();
    const storeId = localStorage.getItem('storeId')
    const [isLoading, setIsLoading] = useState(false);
    const [change, setChange] = useState(false);
    const [formErrorsName, setFormErrorsName] = useState(false)
    const [formErrorsMaxChoice, setFormErrorsMaxChoice] = useState(false)
    // const [formErrorsRequired, setFormErrorsRequired] = useState(false)
    const [formErrorsType, setFormErrorsType] = useState(false)
    const [formErrorsPriceconfig, setFormErrorsPriceconfig] = useState(false)
    const [formErrorsList, setFormErrorsList] = useState(false)
    const [formErrorsListPrice, setFormErrorsListPrice] = useState(false)
    // const [formErrors, setFormErrors] = useState(false)
    // console.log(formErrors);

    const typeList = [
        { value: "single", text: "single" },
        { value: "multiple", text: "multiple" },
    ];
    const priceConfigList = [
        { value: "add", text: "add" },
        { value: "override", text: "override" }
    ];


    const { register, control, getValues, setValue, errors, watch, handleSubmit, reset } = useForm({
        defaultValues: {
            // open:"",
            // required: "",
            name: data?.name,
            // type: "",
            // priceconfig: "",
            maxChoices: 1,
            storeId: storeId,
            list: Array(data?.list.length).fill(
                {
                    name: '',
                    price: '',
                    defaultselected: false,
                    acceptMultiple: false
                },
            )

        }
    });

    useEffect(() => {

    }, [errors, formErrorsName])
    // console.log(errors);


    const { fields, append, remove, swap } = useFieldArray({
        control,
        name: "list"
    });
    const setAllAddressData = (data, index) => {
        setValue(`list[${index}].name`, data.name);
        setValue(`list[${index}].price`, data.price);
        setValue(`list[${index}].defaultselected`, data.defaultselected);
        setValue(`list[${index}].acceptMultiple`, data.acceptMultiple);

    };

    const handlePanelDragEnd = result => {
        const { source, destination } = result;
        // console.log(source, destination);
        if (!destination) return;
        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        )
            return;

        //swap the fields
        swap(source.index, destination.index);
        console.log("after swap: ", getValues().list);
    };

    const addItemToList = () => {
        append({
            name: "",
            price: "",
            defaultselected: false,
            acceptMultiple: false,
        });
    };

    const handleClick = () => {
        // console.log("vals", getValues({ nest: true }).list);
        // console.log(getValues());
    };


    const setError = (setState, condition) => {
        // console.log(varLabel,'varLabel')
        var newLabel = false
        if (condition) {
            newLabel = true;
            setState(true)
        }
        else {
            newLabel = false;
            setState(false)
        }
        return newLabel;
    }
    const dataProps = data
    const onSubmit = async (data) => {
        data['storeId'] = storeId;
        data['_id'] = dataProps._id;
        var errorName = false;
        var errorType = false;
        var errorMaxChoices = false;
        var errorPriceconfig = false;
        var errorList = false;

        errorName = setError(setFormErrorsName, data.name == "" || data.name == undefined)
        errorType = setError(setFormErrorsType, data.type == "" || data.type == undefined)
        errorMaxChoices = setError(setFormErrorsMaxChoice, data.maxChoices < 1)
        errorPriceconfig = setError(setFormErrorsPriceconfig, data.priceconfig == "" || data.priceconfig == undefined)

        if (data?.list.length > 0) {
            setFormErrorsList(false)
            setFormErrorsListPrice(false)
            errorList = false
            data.list.map((item) => {
                // if (item.name == "" || item.name == undefined || item.price == "" || item.price == undefined) {
                //     setFormErrorsList(true)
                //     errorList = true
                // }
                if (isNaN(item.price)) {
                    setFormErrorsListPrice(true)
                    errorList = true
                }
            }

            )
        } else {
            setFormErrorsList(true)
            errorList = true
        }
        if (!errorName && !errorType && !errorMaxChoices && !errorPriceconfig && !errorList) {

            // console.log('data send', data)
                setIsLoading(true);
                updateSpecification(data)
                    .then(({ data }) => {
                        // console.log(data);
                        alert.show(data.message, {
                            type: 'success',
                        });
                        // formik.resetForm();
                        close()
                        onItemEdited();
                    })
                    .catch((error) => {
                        alert.show(error.message, {
                            type: 'error',
                        });
                    })
                    .finally(() => {
                        setIsLoading(false);
                    })
        }


    };
    const renderPanels = () => {
        return fields.map((field, index) => (

            <SpecificationList
                key={field.id}
                index={index}
                length={fields.length}
                setAllAddressData={setAllAddressData}
                form={{ field, register, control, setValue, getValues, remove }}
            />
        ));
    };
    const setDataValue = () => {
        setValue('name', data.name);
        setValue('required', data.required);
        setValue('maxChoices', data.maxChoices);
        setValue('open', data.open);
        setValue('type', data.type);
        data.list.length > 0 && data.list.map((item, index) => (

            setValue(`list[${index}].name`, item.name),
            setValue(`list[${index}].price`, item.price),
            setValue(`list[${index}].defaultselected`, item.defaultselected),
            setValue(`list[${index}].acceptMultiple`, item.acceptMultiple)
        ))

        reset(data)
    }
    useEffect(() => {

        data && setDataValue()

    }, [data])
    return (
        <div>
            {data && <form onSubmit={handleSubmit(onSubmit)}>
                <DragDropContext onDragEnd={handlePanelDragEnd}>

                    <div>
                        <CustomControllerField
                            labelText="Name"
                            formControlProps={{
                                fullWidth: true,
                            }}
                            type="text"
                            name="name"
                            control={control}
                        />
                        {formErrorsName && <span style={{ color: 'red' }}>This field is required</span>}

                        <Droppable droppableId="panel-dropzone">
                            {provided => (
                                <div
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    className="panels-wrapper"
                                >
                                    {renderPanels()}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                        {formErrorsList && <span style={{ color: 'red' }}>This field is required</span>}
                        {formErrorsListPrice && <span style={{ color: 'red' }}>price must not be less than 0</span>}
                        <Box textAlign="left">
                            <Button
                                round
                                type="submit"
                                color="twitter"
                                size="sm"
                                loaderColor="white"
                                onClick={addItemToList}
                            >
                                Add new item
                            </Button>
                        </Box>
                        <FormControlLabel
                            control={<Checkbox color="primary" defaultChecked={data.required} />}
                            label='obligatory choice for the customer'

                            name='required'
                            inputRef={register}
                        />
                        {/* <FormControlLabel
              control={<Checkbox color="primary" onChange={(evt) => setChecked(evt.target.checked)} />}
              label='max choice'
            /> */}

                        <CustomControllerField
                            labelText="choice number"
                            formControlProps={{
                                fullWidth: false,
                            }}
                            type="number"
                            name="maxChoices"
                            control={control}
                        // disabled={!checked}
                        // ref={
                        //   register({
                        //     validate: {
                        //       positive: value => parseInt(value) > 0,
                        //       lessThanTen: value => parseInt(value) < 10,
                        //       asyncValidate: async () => await fetch(url)
                        //     }
                        //   })
                        // }
                        />
                        {formErrorsMaxChoice && <span style={{ color: 'red' }}>max Choices must not be less than 1</span>}
                        <Grid component="label" container alignItems="center" spacing={1}>
                            <Grid item>visible to the customer</Grid>
                            <Grid item>
                                <Controller
                                    as={SwitchComponent}
                                    name='open'
                                    label=""
                                    control={control}
                                    defaultValue={true}

                                />
                            </Grid>
                            <Grid item>always visible</Grid>
                        </Grid>
                        <CustomeControllSelect
                            list={typeList}
                            name='type'
                            label='type'
                            inputProps={{
                                inputRef: (ref) => {
                                    if (!ref) return;
                                    register({
                                        name: "type",
                                        value: ref.value,
                                    });
                                },
                                value: data.type,
                                onChange: (e) => {
                                    data.type = e.target.value
                                    setChange(!change)
                                }

                            }}
                        // defaultValue={data && data.type}
                        />
                        {formErrorsType && <span style={{ color: 'red' }}>This field is required</span>}
                        <CustomeControllSelect
                            list={priceConfigList}
                            name='priceconfig'
                            label='price config'
                            inputProps={{
                                inputRef: (ref) => {
                                    if (!ref) return;
                                    register({
                                        name: "priceconfig",
                                        value: ref.value,
                                    });
                                },
                                value: data.priceconfig,
                                onChange: (e) => {
                                    data.priceconfig = e.target.value
                                    setChange(!change)
                                }
                            }}
                        />

                        {formErrorsPriceconfig && <span style={{ color: 'red' }}>This field is required</span>}
                        <Box textAlign="center">
                            <Button
                                round
                                type="submit"
                                color="success"
                                // size="sm"
                                disabled={isLoading}
                                loading={isLoading}
                                loaderColor="white"
                                onClick={handleClick}
                            >
                                save
                            </Button>
                        </Box>
                    </div>
                </DragDropContext>
            </form>}
        </div>
    )
}
