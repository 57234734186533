export const initialFormData = [
  {
    name: 'DeliveryMan Accepted',
    key: 'DELIVERY_MAN_ACCEPTED',
    send_notif: false,
    message: '',
  },
  {
    name: 'DeliveryMan Comming',
    key: 'DELIVERY_MAN_COMING',
    send_notif: false,
    message: '',
  },
  {
    name: 'Start Preparation',
    key: 'ORDER_START_PREPARATION',
    send_notif: false,
    message: '',
  },
  {
    name: 'DeliveryMan Arrived',
    key: 'DELIVERY_MAN_ARRIVED',
    send_notif: false,
    message: '',
  },
  {
    name: 'DeliveryMan Picked Order',
    key: 'DELIVERY_MAN_PICKED_ORDER',
    send_notif: false,
    message: '',
  },
  {
    name: 'DeliveryMan Started delivery',
    key: 'DELIVERY_MAN_STARTED_DELIVERY',
    send_notif: false,
    message: '',
  },
  {
    name: 'DeliveryMan At Destination',
    key: 'DELIVERY_MAN_ARRIVED_AT_DESTINATION',
    send_notif: false,
    message: '',
  },
  {
    name: 'Completed',
    key: 'DELIVERY_MAN_COMPLETE_DELIVERY',
    send_notif: false,
    message: '',
  },
  {
    name: 'Order created',
    key: 'ORDER_CREATED',
    send_notif: false,
    message: '',
  }
]
export const canCancelConfigTypes = [
  'NEVER_CANCEL',
  'CANCEL_BEFORE_ACCEPT',
  'CANCEL_BEFORE_START_PREPARATION',
]
export const selectNumbers = [
  {
    label: '1',
    value: '1',
  },
  {
    label: '2',
    value: '3',
  },
  {
    label: '3',
    value: '3',
  },
  {
    label: '4',
    value: '4',
  },
  {
    label: '5',
    value: '5',
  },
];