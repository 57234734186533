import React from 'react';
import { Grid, Typography } from '@mui/material';

export default function DeliveryHeaderInfoComponenet({ delivery }) {
  return (
    <div>
      <Grid container className="mb-2">
        <Grid item xs={6} style={{ display: 'flex' }}>
          deliveryId : <Typography>{delivery?.shortId}</Typography>
        </Grid>
        <Grid item xs={6} style={{ display: 'flex' }}>
          organisation :
          <Typography>{delivery?.pickupAddress?.libeleMagasin}</Typography>
        </Grid>
        <Grid item xs={6} style={{ display: 'flex' }}>
          customer :{' '}
          <Typography>{delivery?.destinationAddress?.contactPerson}</Typography>
        </Grid>
        <Grid item xs={6} style={{ display: 'flex' }}>
          deliveryMan : <Typography>{delivery?.deliveryMan?.name}</Typography>
        </Grid>
      </Grid>
    </div>
  );
}
