import { GET_DELIVERY_MEN_REQUEST, GET_DELIVERY_MEN_SUCCESS, GET_DELIVERY_MEN_FAIL, CHANGE_DELIVERY_MEN_LOCATION, RESET_DELIVERY_MEN } from '../constants/deliveryMen.constants'


const initialState = {
    deliveryMen: [],
    error: "",
    loading: false,
};

export default function ordersReducer(state = initialState, action) {

    let deliveryMen = [];

    switch (action.type) {
        case GET_DELIVERY_MEN_REQUEST:
            return {
                loading: true
            }

        case GET_DELIVERY_MEN_SUCCESS:
            return {
                ...state,
                loading: false,
                deliveryMen: action.deliveryMen.deliveryMen,
            };
        case GET_DELIVERY_MEN_FAIL:
            return {
                ...state,
                loading: false,
                deliveryMen: [],
                error: action.error?.message || (action?.error?.errors || [{ message: "" }])[0].message,
            };
        case CHANGE_DELIVERY_MEN_LOCATION:
            deliveryMen = state.deliveryMen;
            const findIndexDelivery = (deliveryMen || []).findIndex(x => x._id == action?.deliveryManId);
            if (findIndexDelivery >= 0) {
                deliveryMen[findIndexDelivery].location = action?.location
            }
            return {
                ...state,
                loading: false,
                deliveryMen: deliveryMen,
            };
        case RESET_DELIVERY_MEN:
            return {
                ...state,
                loading: false,
                deliveryMen: [],
            };
            break;
        default:
            return state;
    }
}