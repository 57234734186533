import moment from 'moment'
import ChangeDuration from '../../orders/components/ChangeDuration.components';
import MenuComponent from '../components/Menu.component';
import StatusPartnerComponent from '../components/Status.partner.component';
import AllStatusComponent from '../components/All.status.component';
import CommentPartnerComponent from '../components/Comment.partner.component';

export const columns = [
    {
        Header: 'Id Cmd',
        accessor: 'shortId',
        customWidth: '70px',
    },
    {
        Header: 'Date',
        accessor: 'createdAtInp',
        customWidth: '65px',
    },
    {
        Header: 'Ville',
        accessor: 'cityName',
        customWidth: '60px',
    },
    {
        Header: 'Organisation',
        accessor: 'orderData.code',
        customWidth: '100px',
    },
    {
        Header: 'Warehouse',
        accessor: 'warehouseName',
        customWidth: '100px',
    },
    {
        Header: 'Client',
        accessor: 'clientName',
        customWidth: '100px',
    },
    {
        Header: 'Provider',
        accessor: 'deliveryManInp',
        customWidth: '100px',
    },
    {
        Header: 'status',
        accessor: 'allStatus',
        customWidth: '120px',
    },
    {
        Header: 'Total',
        accessor: 'orderPriceCol',
        customWidth: '60px',
    },
    {
        Header: 'Payment',
        accessor: 'paymentMode',
        customWidth: '75px',
    },

    {
        Header: 'duration',
        accessor: 'duration',
        customWidth: '75px',
    },
    {
        Header: 'comment',
        accessor: 'lastComment',
        customWidth: '110px',
    },
    {
        Header: 'Alarm',
        accessor: 'alarmCell',
        customWidth: '110px',
    },
    {
        Header: 'Actions',
        accessor: 'menu',
        customWidth: '100px',
    },
];
export const columnsDispatched = [
    {
        Header: 'Id Cmd',
        accessor: 'shortId',
        customWidth: '70px',
    },
    {
        Header: 'Date',
        accessor: 'createdAtInp',
        customWidth: '65px',
    },
    {
        Header: 'Ville',
        accessor: 'cityName',
        customWidth: '60px',
    },
    {
        Header: 'Organisation',
        accessor: 'orderData.code',
        customWidth: '100px',
    },
    {
        Header: 'Warehouse',
        accessor: 'warehouseName',
        customWidth: '100px',
    },
    {
        Header: 'Client',
        accessor: 'clientName',
        customWidth: '100px',
    },
    {
        Header: 'Provider',
        accessor: 'deliveryManInp',
        customWidth: '100px',
    },
    {
        Header: 'status Time',
        accessor: 'allStatus',
        customWidth: '120px',
    },
    {
        Header: 'status',
        accessor: 'statusPartner',
        customWidth: '120px',
    },
    {
        Header: 'DeliveryManSta',
        accessor: 'DeliveryManStatusCell',
        customWidth: '120px',
    },
    {
        Header: 'Total',
        accessor: 'orderPriceCol',
        customWidth: '60px',
    },
    {
        Header: 'Payment',
        accessor: 'paymentMode',
        customWidth: '75px',
    },

    {
        Header: 'duration',
        accessor: 'duration',
        customWidth: '75px',
    },
    {
        Header: 'comment',
        accessor: 'lastComment',
        customWidth: '110px',
    },
    {
        Header: 'Alarm',
        accessor: 'alarmCell',
        customWidth: '110px',
    },
    {
        Header: 'Actions',
        accessor: 'menu',
        customWidth: '100px',
    },
];
export const columnsV2 = [
    {
        Header: 'Id Cmd',
        accessor: 'shortId',
        customWidth: '70px',
    },
    {
        Header: 'Date',
        accessor: 'createdAtInp',
        customWidth: '65px',
    },
    {
        Header: 'Ville',
        accessor: 'cityName',
        customWidth: '60px',
    },
    {
        Header: 'Adrs',
        accessor: 'destinationAddress.address',
        customWidth: '100px',
    },
    {
        Header: 'Organisation',
        accessor: 'orderData.code',
        customWidth: '100px',
    },
    {
        Header: 'Warehouse',
        accessor: 'warehouseName',
        customWidth: '100px',
    },
    {
        Header: 'Client',
        accessor: 'clientName',
        customWidth: '100px',
    },
    {
        Header: 'Provider',
        accessor: 'deliveryManInp',
        customWidth: '100px',
    },
    {
        Header: 'status',
        accessor: 'allStatus',
        customWidth: '120px',
    },
    {
        Header: 'Total',
        accessor: 'orderPriceCol',
        customWidth: '60px',
    },
    {
        Header: 'Payment',
        accessor: 'paymentMode',
        customWidth: '75px',
    },

    {
        Header: 'duration',
        accessor: 'duration',
        customWidth: '75px',
    },
    {
        Header: 'clientDist',
        accessor: 'clientDistanceCell',
        customWidth: '75px',
    },
    {
        Header: 'wrhosDis',
        accessor: 'wareHouseDistanceCell',
        customWidth: '75px',
    },
    {
        Header: 'comment',
        accessor: 'lastComment',
        customWidth: '110px',
    },
    {
        Header: 'Alarm',
        accessor: 'alarmCell',
        customWidth: '110px',
    },
    {
        Header: 'Actions',
        accessor: 'menu',
        customWidth: '100px',
    },
];
export const columnsDispatchedV2 = [
    {
        Header: 'Id Cmd',
        accessor: 'shortId',
        customWidth: '70px',
    },
    {
        Header: 'Date',
        accessor: 'createdAtInp',
        customWidth: '65px',
    },
    {
        Header: 'Ville',
        accessor: 'cityName',
        customWidth: '60px',
    },
    {
        Header: 'Adrs',
        accessor: 'destinationAddress.address',
        customWidth: '100px',
    },
    {
        Header: 'Organisation',
        accessor: 'orderData.code',
        customWidth: '100px',
    },
    {
        Header: 'Warehouse',
        accessor: 'warehouseName',
        customWidth: '100px',
    },
    {
        Header: 'Client',
        accessor: 'clientName',
        customWidth: '100px',
    },
    {
        Header: 'Provider',
        accessor: 'deliveryManInp',
        customWidth: '100px',
    },
    {
        Header: 'status Time',
        accessor: 'allStatus',
        customWidth: '120px',
    },
    {
        Header: 'status',
        accessor: 'statusPartner',
        customWidth: '120px',
    },
    {
        Header: 'DeliveryManSta',
        accessor: 'DeliveryManStatusCell',
        customWidth: '120px',
    },
    {
        Header: 'Total',
        accessor: 'orderPriceCol',
        customWidth: '60px',
    },
    {
        Header: 'Payment',
        accessor: 'paymentMode',
        customWidth: '75px',
    },

    {
        Header: 'duration',
        accessor: 'duration',
        customWidth: '75px',
    },
    {
        Header: 'clientDist',
        accessor: 'clientDistanceCell',
        customWidth: '75px',
    },
    {
        Header: 'wrhosDis',
        accessor: 'wareHouseDistanceCell',
        customWidth: '75px',
    },
    {
        Header: 'comment',
        accessor: 'lastComment',
        customWidth: '110px',
    },
    {
        Header: 'Alarm',
        accessor: 'alarmCell',
        customWidth: '110px',
    },
    {
        Header: 'Actions',
        accessor: 'menu',
        customWidth: '100px',
    },
];
export const columnsV3 = [
    {
        Header: 'Id Cmd',
        accessor: 'shortId',
        customWidth: '70px',
    },
    {
        Header: 'Date',
        accessor: 'createdAtInp',
        customWidth: '65px',
    },
    {
        Header: 'Ville',
        accessor: 'cityName',
        customWidth: '60px',
    },
    {
        Header: 'Adrs',
        accessor: 'destinationAddressAddress',
        customWidth: '100px',
        style: {
            maxWidth: '140px', overflow: 'hidden', textOverflow: 'ellipsis'
        }
    },
    {
        Header: 'Organisation',
        accessor: 'orderDataCode',
        customWidth: '100px',
    },
    {
        Header: 'Warehouse',
        accessor: 'warehouseName',
        customWidth: '100px',
    },
    {
        Header: 'Client',
        accessor: 'clientName',
        customWidth: '100px',
    },
    {
        Header: 'Provider',
        accessor: 'deliveryManInp',
        customWidth: '100px',
    },
    {
        Header: 'status',
        accessor: 'allStatus',
        customWidth: '120px',
    },
    {
        Header: 'Total',
        accessor: 'orderPriceCol',
        customWidth: '60px',
    },
    {
        Header: 'Payment',
        accessor: 'paymentMode',
        customWidth: '75px',
    },

    {
        Header: 'duration',
        accessor: 'duration',
        customWidth: '75px',
    },
    {
        Header: 'clientDist',
        accessor: 'clientDistanceCell',
        customWidth: '75px',
    },
    {
        Header: 'wrhosDis',
        accessor: 'wareHouseDistanceCell',
        customWidth: '75px',
    },
    {
        Header: 'comment',
        accessor: 'lastComment',
        customWidth: '110px',
        style: {
            maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis'
        }
    },
    // {
    //     Header: 'Alarm',
    //     accessor: 'alarmCell',
    //     customWidth: '110px',
    // },
    {
        Header: 'Actions',
        accessor: 'menu',
        customWidth: '100px',
    },
];
export const columnsDispatchedV3 = [
    {
        Header: 'Id Cmd',
        accessor: 'shortId',
        customWidth: '70px',
    },
    {
        Header: 'Date',
        accessor: 'createdAtInp',
        customWidth: '65px',
    },
    {
        Header: 'Ville',
        accessor: 'cityName',
        customWidth: '60px',
    },
    {
        Header: 'Adrs',
        accessor: 'destinationAddressAddress',
        customWidth: '100px',
        style: {
            maxWidth: '140px', overflow: 'hidden', textOverflow: 'ellipsis'
        }
    },
    {
        Header: 'Organisation',
        accessor: 'orderDataCode',
        customWidth: '100px',
    },
    {
        Header: 'Warehouse',
        accessor: 'warehouseName',
        customWidth: '100px',
    },
    {
        Header: 'Client',
        accessor: 'clientName',
        customWidth: '100px',
        style: {
            maxWidth: '120px', overflow: 'hidden', textOverflow: 'ellipsis'
        }
    },
    {
        Header: 'Provider',
        accessor: 'deliveryManInp',
        customWidth: '100px',
    },
    {
        Header: 'status Time',
        accessor: 'allStatus',
        customWidth: '120px',
    },
    {
        Header: 'status',
        accessor: 'statusPartner',
        customWidth: '120px',
    },
    {
        Header: 'DeliveryManSta',
        accessor: 'DeliveryManStatusCell',
        customWidth: '120px',
        style: {
            maxWidth: '130px', overflow: 'hidden', textOverflow: 'ellipsis'
        }
    },
    {
        Header: 'Total',
        accessor: 'orderPriceCol',
        customWidth: '60px',
    },
    {
        Header: 'Payment',
        accessor: 'paymentMode',
        customWidth: '75px',
    },

    {
        Header: 'duration',
        accessor: 'duration',
        customWidth: '75px',
    },
    {
        Header: 'clientDist',
        accessor: 'clientDistanceCell',
        customWidth: '75px',
    },
    {
        Header: 'wrhosDis',
        accessor: 'wareHouseDistanceCell',
        customWidth: '75px',
    },
    {
        Header: 'comment',
        accessor: 'lastComment',
        customWidth: '110px',
        style: {
            maxWidth: '130px', overflow: 'hidden', textOverflow: 'ellipsis'
        }
    },
    // {
    //     Header: 'Alarm',
    //     accessor: 'alarmCell',
    //     customWidth: '110px',
    // },
    {
        Header: 'Actions',
        accessor: 'menu',
        customWidth: '100px',
    },
];
export const DeliveryManStatus = [
    'CREATED',
    'WAITING_FOR_ACCEPT',
    'ACCEPTED',
    'ARRIVED_TO_WAREHOUSE',
    'STARTED_PICKING',
    'END_PICKING',
    'STARTED_DELIVERY',
    'ARRIVED_TO_DESTINATION',
    'DELIVERED',
]
export const deliveriesSearchType = ['wareHouse', 'deliveryMan', 'customer', 'shortId', 'partnerId']
export const allArray = ['all'];
export const partnerStatusBackgroundColor = {
    // ON_HOLD: '#f246f4',
    // INVALID: 'red',
    // WAITING_FOR_ACCEPT: '#ffc107',
    // ACCEPTED: '#ff7986',
    // START_PREPARATION: '#3496ff',

    // COMING_FOR_DELIVERY: '#3496ff',
    // ARRIVED_TO_PICKUP: '#3496ff',
    // UPLOAD_INVOICE: '#46e06a',
    // STARTED_DELIVERY: '#46e06a',
    // ARRIVED_TO_DESTINATION: '#46e06a',
    // DELIVERED: '#46e06a',
    default: '#000',

    END_PREPARATION: '#ff91ae',
    STATUS_CANCELLED: 'red',
    STATUS_DELIVERED: 'rgba(70,224,106,0.55)',
    STATUS_DELIVERING: '#3496ff',
    STATUS_ERROR_PAYMENT: '#f246f4',
    STATUS_FINISHED: '#46e06a',
    STATUS_OPEN_PENDING: 'red',
    STATUS_PREPARING: '#ffc107',
    STATUS_READY: 'rgba(0,207,255,0.35)',
    STATUS_REFUSED: '#f246f4',
    STATUS_VALIDATED: '#46e06a',
}
export const deliveryStatusBackgroundColor = {
    CREATED: '#f246f4',
    WAITING_FOR_ACCEPT: '#ffc107',
    ACCEPTED: '#ff7986',
    ARRIVED_TO_WAREHOUSE: '#3496ff',
    STARTED_PICKING: '#46e06a',
    END_PICKING: '#46e06a',
    STARTED_DELIVERY: '#46e06a',
    ARRIVED_TO_DESTINATION: '#46e06a',
    default: '#000',
    CANCELLED: 'red',
    DELIVERING: '#3496ff',
    DELIVERED: '#46e06a',

}
export const alarmInfoTableHeaders = [
    'Warehousses',
    'Orders',
    'Manual',
    'WAP',
    'Accepted',
    'AWT',
    'AWP',
    'SP',
    'EP',
    'Delivering',
    'ADT',
    'ADP'];
export const dispatchingInfoTableHeaders = [
    'Warehousses',
    // 'Dispo',
    'Deliveries',
    'ON_HOLD',
    'MANUEL',
    'DISPATCHING',
    'DISPATCHED'
    // 'Created',
    // 'Manual',
    // 'Dispatching',
    // 'Assigned',
    // 'Dispatched'
]
export const driveInfoTableHeaders = [
    'Warehousses',
    // 'Dispo',
    'Deliveries',
    'CREATED',
    'WFA',
    'ACCEPTED',
    'ATW',
    'SP',
    'EP',
    'SD',
    'ATD',

]
export const callApicolumns = [
    'Created',
    'type',
    'source',
    'call/retries',
    'status',
    'errors',
    'kaalix ID',
    'marjane ID',
    // 'warehouse',
    'deliveryMan',
    'Marjane Status',
    'kaalix Status',
];
export const orderFormat = (item, index) => {
    return {
        ...item,
        cityName: item?.cityName?.substring(0, 4) || item?.cityId,
        deliveryManStatusInp: item?.deliveryManStatus,

        warehouseName:
            item?.pickupAddress?.libeleMagasin || item?.organisationId,
        clientName: item?.destinationAddress?.contactPerson,
        deliveryManInp: item?.deliveryMan && item?.deliveryMan.name,
        createdAtInp:
            item.createdAt && moment(item.createdAt).format('hh:mm:ss'),

        duration: item?.deliveryManStatusTimes && (
            <>
                <ChangeDuration
                    deliveryManStatusTime={
                        item?.deliveryManStatusTimes &&
                        item?.deliveryManStatusTimes.length &&
                        item?.deliveryManStatusTimes[
                            item?.deliveryManStatusTimes.length - 1
                        ]?.date
                    }
                    time={item.createdAt}
                />
            </>
        ),
        orderPriceCol: item?.payment?.price && item?.payment?.price.toFixed(2),
        paymentMode: (
            <div style={{ textAlign: 'center' }}>
                {item?.payment?.method}
                <p style={{ color: 'red' }}>{item?.isPicking && item?.EstimatedTime + 'min'}</p>
            </div>
        ),
        menu: <MenuComponent _id={item._id} index={index} blocked={item?.blocked} />,

        statusPartner: <StatusPartnerComponent deliveryStateTimes={item?.deliveryStateTimes || []} />,

        allStatus: <AllStatusComponent item={item} />,

        lastComment: <CommentPartnerComponent _id={item._id} index={index} comments={item?.comments || []} />,

        DeliveryManStatusCell: item?.deliveryManStatus,
        // alarmCell: item.alarms && item.alarms.length > 0 && (
        //     <div>
        //         <Grid container spacing={1}>
        //             {item.alarms.map(
        //                 (alarm) =>
        //                     alarm?.visible && (
        //                         <Grid
        //                             item
        //                             xs={4}
        //                             onClick={() => {
        //                                 dispatch(openModelAction(item._id, index, 'Alarm'));
        //                             }}
        //                         >
        //                             <AvatarGroup
        //                                 avatars={[alarm?.name.split('_').join(' ')]}
        //                                 initialCharacters={alarm?.name.split('_').length}
        //                                 max={3}
        //                                 size={25}
        //                                 displayAllOnHover
        //                                 shadow={2}
        //                                 fontSize={0.4}
        //                                 backgroundColor={switchForAvatarColor(alarm?.status)}
        //                             />
        //                         </Grid>
        //                     ),
        //             )}
        //         </Grid>
        //     </div>
        // ),
        Rowcolor: rowColor(item?.orderData?.state, item?.orderNbr, item?.blocked,
            item?.rupture,
        ),
        destinationAddressAddress: item?.destinationAddress?.address,
        orderDataCode: item?.orderData?.code
        // clientDistanceCell: <DistanceComponent deliveryManLocation={item?.deliveryManLocation}
        // otherLocation={[item?.destinationAddress?.latitude, item?.destinationAddress?.longitude]} />,
        // clientDistanceCell: JSON.stringify(item?.deliveryManLocation),
        // wareHouseDistanceCell: 11,
    };
}
export const rowColor = (state, OrdeNbr, blocked, rupture) => {
    // if (state && state === 'STATUS_ERROR_PAYMENT') {
    //     return '#BF40BF';
    // } else if (OrdeNbr > 1) {
    //     return 'red';
    // }
    if (blocked) {
        return '#A4D0A4';
    }
    if (rupture) {
        return '#0A4D68';
    }
    if (state && state === 'STATUS_ERROR_PAYMENT') {
        return '#BF40BF';
    } else if (OrdeNbr > 1) {
        return 'red';
    }

};