import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';
import CustomInput from '../../../components/material-dashboard-pro-react/CustomInput/CustomInput';
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import SwitchComponent from '../../../components/Switch.component';
import {
  getSocketInterval,
  setSocketInterval,
} from '../services/serverApi.service';
import { useAlert } from 'react-alert';

const validationSchema = yup.object({
  socketGpsSd: yup.number().required(),
  socketGpsUpdate: yup.number().required(),
  socketAllPartner: yup.number().required(),
  socketPartner: yup.number().required(),
  socketGpsDriver: yup.number().required(),
  distanceAppUser: yup.number().required(),
  useApi: yup.boolean().required(),
});

export default function ConfigSocketInterval() {
  const alert = useAlert();
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      socketGpsSd: '',
      socketGpsUpdate: '',
      socketAllPartner: '',
      socketPartner: '',
      socketGpsDriver: '',
      distanceAppUser: '',
      useApi: '',
    },
    validationSchema,
  });
  const fetchConfigContent = () => {
    getSocketInterval()
      .then(({ data }) => {
        putSearchLimit(data?.interval);
      })
      .catch((err) => console.log(err));
  };
  const saveValueSearchLimit = () => {
    setSocketInterval(formik.values)
      .then(({ data }) => {
        putSearchLimit(data?.intervalDb);
        alert.show('saved', {
          type: 'success',
        });
      })
      .catch((error) => console.log(error));
  };
  const updateUseApi = (value) => {
    setSocketInterval({ ...formik.values, useApi: value })
      .then(({ data }) => {
        putSearchLimit(data?.intervalDb);
        alert.show('saved', {
          type: 'success',
        });
      })
      .catch((error) => console.log(error));
  };
  const putSearchLimit = (data) => {
    formik.setValues({
      socketGpsSd: data?.socketGpsSd,
      socketGpsUpdate: data?.socketGpsUpdate,
      socketAllPartner: data?.socketAllPartner,
      socketPartner: data?.socketPartner,
      socketGpsDriver: data?.socketGpsDriver,
      distanceAppUser: data?.distanceAppUser,
      useApi: data?.useApi,
    });
  };
  useEffect(() => {
    fetchConfigContent();
  }, []);

  return (
    <div>
      <Box>
        <CustomInput
          labelText="socketGpsSd"
          formControlProps={{
            fullWidth: true,
          }}
          type="number"
          error={
            formik.touched.socketGpsSd && Boolean(formik.errors.socketGpsSd)
          }
          inputProps={{
            value: formik.values.socketGpsSd,
            onBlur: formik.handleBlur('socketGpsSd'),
            onChange: formik.handleChange('socketGpsSd'),
          }}
        />
        <CustomInput
          labelText="socketGpsUpdate"
          formControlProps={{
            fullWidth: true,
          }}
          type="number"
          error={
            formik.touched.socketGpsUpdate &&
            Boolean(formik.errors.socketGpsUpdate)
          }
          inputProps={{
            value: formik.values.socketGpsUpdate,
            onBlur: formik.handleBlur('socketGpsUpdate'),
            onChange: formik.handleChange('socketGpsUpdate'),
          }}
        />
        <CustomInput
          labelText="socketAllPartner"
          formControlProps={{
            fullWidth: true,
          }}
          type="number"
          error={
            formik.touched.socketAllPartner &&
            Boolean(formik.errors.socketAllPartner)
          }
          inputProps={{
            value: formik.values.socketAllPartner,
            onBlur: formik.handleBlur('socketAllPartner'),
            onChange: formik.handleChange('socketAllPartner'),
          }}
        />
        <CustomInput
          labelText="socketPartner"
          formControlProps={{
            fullWidth: true,
          }}
          type="number"
          error={
            formik.touched.socketPartner && Boolean(formik.errors.socketPartner)
          }
          inputProps={{
            value: formik.values.socketPartner,
            onBlur: formik.handleBlur('socketPartner'),
            onChange: formik.handleChange('socketPartner'),
          }}
        />
        <CustomInput
          labelText="socketGpsDriver"
          formControlProps={{
            fullWidth: true,
          }}
          type="number"
          error={
            formik.touched.socketGpsDriver &&
            Boolean(formik.errors.socketGpsDriver)
          }
          inputProps={{
            value: formik.values.socketGpsDriver,
            onBlur: formik.handleBlur('socketGpsDriver'),
            onChange: formik.handleChange('socketGpsDriver'),
          }}
        />
        <CustomInput
          labelText="distanceAppUser"
          formControlProps={{
            fullWidth: true,
          }}
          type="number"
          error={
            formik.touched.distanceAppUser &&
            Boolean(formik.errors.distanceAppUser)
          }
          inputProps={{
            value: formik.values.distanceAppUser,
            onBlur: formik.handleBlur('distanceAppUser'),
            onChange: formik.handleChange('distanceAppUser'),
          }}
        />
        <SwitchComponent
          style={{ marginLeft: 15 }}
          onChange={(value) => updateUseApi(value)}
          checked={formik.values.useApi}
          error={formik.touched.useApi && Boolean(formik.errors.useApi)}
          label="UseApi"
        />
      </Box>
      <Box textAlign="center">
        <Button
          round
          type="submit"
          color="success"
          disabled={isLoading}
          loading={isLoading}
          loaderColor="white"
          onClick={saveValueSearchLimit}
        >
          save
        </Button>
      </Box>
    </div>
  );
}
