import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useAlert } from 'react-alert';
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import CustomInput from '../../../components/material-dashboard-pro-react/CustomInput/CustomInput';
import { useDispatch, useSelector } from 'react-redux';
import InputAdornment from '@material-ui/core/InputAdornment';
import PersonIcon from '@material-ui/core/SvgIcon/SvgIcon';
import { grayColor } from '../../../assets/jss/material-dashboard-pro-react';
import { registerApi, UpdateUser } from '../services/serverApi.service';
import MailIcon from '@material-ui/icons/Mail';
import LockOpenIcon from '@material-ui/icons/LockOpen';

const validationSchema = yup.object({
  username: yup.string().required(),
  email: yup.string().email().required(),
  password: yup.string().required(),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
});

export default function CreateAdminComponent({
  itemCreated,
  itemEdited,
  props,
  data,
  index,
  close,
}) {
  const alert = useAlert();
  const [isLoading, setIsLoading] = useState(false);
  const [isEdited, setIsEdited] = useState(props?.isEdited ? true : false);

  const formik = useFormik({
    initialValues: {
      email: '',
      username: '',
      password: '',
      passwordVerification: '',
    },
    validationSchema,
  });

  const onSubmit = () => {
    console.log(formik.isValid);
    if (formik.isValid) {
      setIsLoading(true);
      if (isEdited) {
        onEdit();
      } else {
        onCreate();
      }
    }
  };

  const onEdit = () => {
    data.username = formik.values.username;
    data.email = formik.values.email;
    UpdateUser(data.userId, data)
      .then(({ data }) => {
        alert.show('City has been created', {
          type: 'success',
        });
        formik.resetForm();
        itemEdited({ newData: data.user, index: index });
      })
      .catch((error) => {
        alert.show(error.message, {
          type: 'error',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onCreate = () => {
    registerApi(formik.values)
      .then(({ data }) => {
        alert.show('City has been created', {
          type: 'success',
        });
        formik.resetForm();
        itemCreated(data);
      })
      .catch((error) => {
        alert.show(error.message, {
          type: 'error',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    console.log(data);
    if (data) {
      formik.setValues({
        email: data.email,
        username: data.username,
        password: '',
        passwordVerification: '',
      });
    }
  }, [data]);

  return (
    <div>
      <CustomInput
        labelText="Username"
        id="username"
        formControlProps={{
          fullWidth: true,
        }}
        type="text"
        error={formik.touched.username && Boolean(formik.errors.username)}
        inputProps={{
          value: formik.values.username,
          onBlur: formik.handleBlur('username'),
          onChange: formik.handleChange('username'),
          endAdornment: (
            <InputAdornment position="end">
              <PersonIcon style={{ color: grayColor[2] }} />
            </InputAdornment>
          ),
        }}
      />
      <CustomInput
        labelText="Email"
        id="email"
        formControlProps={{
          fullWidth: true,
        }}
        type="email"
        error={formik.touched.email && Boolean(formik.errors.email)}
        inputProps={{
          value: formik.values.email,
          onBlur: formik.handleBlur('email'),
          onChange: formik.handleChange('email'),
          endAdornment: (
            <InputAdornment position="end">
              <MailIcon style={{ color: grayColor[2] }} />
            </InputAdornment>
          ),
        }}
      />
      <CustomInput
        labelText="Mot de passe"
        id="password"
        formControlProps={{
          fullWidth: true,
        }}
        error={formik.touched.password && Boolean(formik.errors.password)}
        inputProps={{
          value: formik.values.password,
          onBlur: formik.handleBlur('password'),
          onChange: formik.handleChange('password'),
          type: 'password',
          endAdornment: (
            <InputAdornment position="end">
              <LockOpenIcon style={{ color: grayColor[2] }} />
            </InputAdornment>
          ),
        }}
      />
      <CustomInput
        labelText="Confirmation de mot de passe"
        id="confirmPassword"
        formControlProps={{
          fullWidth: true,
        }}
        error={
          formik.touched.confirmPassword &&
          Boolean(formik.errors.confirmPassword)
        }
        inputProps={{
          value: formik.values.confirmPassword,
          onBlur: formik.handleBlur('confirmPassword'),
          onChange: formik.handleChange('confirmPassword'),
          type: 'password',
          endAdornment: (
            <InputAdornment position="end">
              <LockOpenIcon style={{ color: grayColor[2] }} />
            </InputAdornment>
          ),
        }}
      />
      <Box textAlign="center">
        <Button
          round
          type="submit"
          color="success"
          loading={isLoading}
          loaderColor="white"
          onClick={onSubmit}
        >
          {isEdited ? 'Edit' : 'Create'}
        </Button>
      </Box>
    </div>
  );
}

CreateAdminComponent.propTypes = {
  data: PropTypes.array,
  props: PropTypes.any,
  index: PropTypes.any,
  itemCreated: PropTypes.func,
  itemEdited: PropTypes.func,
  close: PropTypes.func,
};
