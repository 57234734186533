import {
    deliveryStatus,
} from '../../../config/status.config';
import { deliveryStatusBackgroundColor } from '../mock'
const sharedStyleForStatus = {
  width: '120px',
  fontSize: '11px',
  padding: '5px',
  borderRadius: '4px',
  color: 'white',
};
export const renderStatus = (
    dispatchingStatus,
    deliveryManStatus,
    deliveryManStatusTimes,

) => {
    if (dispatchingStatus == 'INVALID' || dispatchingStatus == 'ON_HOLD') {
        return (
            <span
                style={{
                    backgroundColor: dispatchingStatus == 'ON_HOLD' ? '#f246f4' : 'red',
                    ...sharedStyleForStatus,
                }}
            >
                {dispatchingStatus}
            </span>
        );
    }
    return (
        <span
            style={{
                backgroundColor: deliveryStatusBackgroundColor[deliveryManStatus] || 'red',
                width: '120px',
                fontSize: '11px',
                padding: '5px',
                borderRadius: '4px',
                color: 'white',
            }}
        >
            {deliveryManStatusTimes[deliveryManStatusTimes.length - 1]['status']}
        </span>
    );
};
