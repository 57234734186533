import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAlert } from 'react-alert';
import { getUserDataState } from '../../auth/redux/selectors'
import TablePagination from '@material-ui/core/TablePagination';
import { Box, Grid } from '@material-ui/core';
import { getCustmorePayment, approuvedCustmore } from '../services/serverApi.customer';
import DataTableContainerComponent from '../../../components/DataTableSortingContainer.component';
import { formatTime , convertIdToShortId} from '../../../helpers/index'
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button'
import CustomButton from '../../../components/material-dashboard-pro-react/CustomButtons/Button'


export default function CustomersPaymentPage() {
    const alert = useAlert();
    const userData = useSelector(getUserDataState);
    const inpRef = React.useRef();
    const [resultsData, setResultsData] = useState({
      total: 0,
      items: [],
      tableItems: [],
    });
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(0)
    let numberOfRows = [20, 50, 100];
    const [rowsPerPage, setRowsPerPage] = useState(numberOfRows[0])
    const [openModal, setOpenModal] = useState('');
    const [customers, setCustomers] = useState([]);
    const [customer, setCustomer] = useState({});
    const [indexCustomer, setIndexCustomer] = useState(-1);
    const [searchText, setSearchText] = useState('')
    const [total, setTotal] = useState(0);
    const [subPagination,setSubPagination] = useState([])

    const prepareShowStore = (item, index) => {
      return {
        ...item,
        idConvertor:item?.orderId&&convertIdToShortId(item.orderId),
        createdAtCol: formatTime(item.createdAt),
        paymentCol : (
            <div>
                {item.payments&&item.payments.length&&item.payments.map(payment=>(
                    payment.amount>0&&<p>{payment.gateway+"-->"+payment.amount}</p>
                ))}
            </div>
        ),
        napsPayload:(<p>{item.napsPayload ? JSON.stringify(item.napsPayload) : "null"}</p>),
      }
    }
    
    const loadCustomerPayment = (skip,limit,txt) => {
      setIsLoading(true);
      getCustmorePayment(skip || 0, limit || 20,txt )
        .then(({ data }) => {
          setTotal(data.total)
          setCustomers(data.payments)
      })
      .catch((error)=>console.log(error))
      .finally(() => {
        setIsLoading(false);
      });
  };
    const SearchComponent = () => {
      return (
        <Grid container>
        <Grid item xs={8}>
          <input type='text'
            ref={inpRef}
            placeholder='client search'
            style={{
              border: "none",
              borderBottom: '1px solid black'
            }}
            defaultValue={searchText}
            
          />
          </Grid>
          <Grid item xs={4} container justify="flex-end"
    >
          
          <Box textAlign="left">
            <CustomButton
              round
              type="submit"
              color="success"
              size="sm"
              loaderColor="white"
              onClick={()=>{
                setSearchText(inpRef.current.value)
                page>0&&setPage(0)
                loadCustomerPayment(page>0?0:page, rowsPerPage,inpRef.current.value)
              }
              }
            >
              find Client
            </CustomButton>
          </Box>
          <Box textAlign="left">
            <CustomButton
              round
              type="submit"
              color="google"
              size="sm"
              loaderColor="white"
              onClick={() => {
                setSearchText('')
                page>0&&setPage(0)
                loadCustomerPayment(page>0?0:page, rowsPerPage,"")
              }}
            >
              clear
            </CustomButton>
          </Box>
          </Grid>
        </Grid>
      )
    }
    
    useEffect(() => {
      customers && customers.length > 0 && setResultsData({
        items: customers.map((item, index) => {
          return prepareShowStore(item, index);
        }),
      })
    }, [customers])
    const handlePaginate = (val) => {
      setResultsData({
        items: [],
        tableItems: [],
      });
      loadCustomerPayment(val.pageSelect,val.numberOfRows,searchText);
      setPage(val.pageSelect);
      setRowsPerPage(val.numberOfRows);
    };
  
    
    useEffect(() => {
      if(total){
        const pages = Math.ceil(total / rowsPerPage);
      if(total>2000){
        console.log(total);
        const newPages = [];
        // const start = page==0?page:Number(page-1)
        let start ;
        let end ;
        const handelStart = ()=>{
          let startNumber ;
              if(page==0)startNumber = page
              else if(page>0){
                if(page-100<0)startNumber = 0
                else startNumber = Number(page-100)
              }
          return startNumber
        }
        const handelEnd = ()=>{
          let endNumber ;
          if((page+100)<pages)endNumber=page+100
          else endNumber=page+(pages-page)
          return endNumber
        }
        start = handelStart()
        end = handelEnd()
        for (let i = start; i < end; i++) {
          newPages.push(i)
        }
        setSubPagination(newPages);
      }else{
        setSubPagination([])
      }
      }
    },[total,page])
    // console.log(subPagination);
    return (
      <>
        {subPagination&&
          <DataTableContainerComponent
            total={total}
            subPagination={subPagination}
            resultsData={resultsData}
            page={page+1}
            initData={resultsData.items}
            searchInp={true}
            SearchComponent={SearchComponent}
            columns={[
  
              {
                Header: 'orderId',
                accessor: 'idConvertor',
              },
              {
                Header: 'Name',
                accessor: 'destinationAddress.name',
              },
              {
                Header: 'store',
                accessor: 'store_detail.name',
              },
              {
                Header: 'city',
                accessor: 'city_detail.name',
              },
              {
                Header: 'orderPrice',
                accessor: 'orderPrice',
              },
              {
                Header: 'deliveryPrice',
                accessor: 'deliveryPrice',
              },
              {
                Header: 'total',
                accessor: 'total',
              },
              {
                Header: 'payment',
                accessor: 'paymentCol',
              },
              {
                Header: 'status',
                accessor: 'status',
              },
              {
                Header: 'promo',
                accessor: 'promo',
              },
              {
                Header: 'storeCommissType',
                accessor: 'storeCommissionType',
              },
              {
                Header: 'storeCommission',
                accessor: 'storeCommission',
              },
              {
                Header: 'naps Payload',
                accessor: 'napsPayload',
              },
              {
                Header: 'created At',
                accessor: 'createdAtCol',
              },
            ]}
            title="Customer Payment List"
            isLoading={isLoading}
            loadItemsList={loadCustomerPayment}
            onPaginate={handlePaginate}
            isPaginate={true}
          />
        }
        </>
    )
}
