import React, { useState, useEffect } from 'react';
import { useAlert } from 'react-alert';
import {
  createMoneyTransfer,
  getApprouvedProvider,
  getMoneyTransfer,
  getProviderDetails,
  getTips,
  logProvider,
  setProvider,
  validerMoneyTransfer,
  validerTips,
} from '../service/serverAPI.service';
import DataTableContainerComponent from '../../../components/DataTableSortingContainer.component';
import SwitchComponent from '../../../components/Switch.component';
import { formatTime } from '../../../helpers/index';
import DeleteProvider from '../components/DeleteProvider';
import EditProvider from '../components/EditProvider';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu/Menu';
import ModalComponent from '../../../components/Modal.component';
import OrderDetail from '../../orders/components/OrderDetail.component';
import TextField from '@material-ui/core/TextField';
import { Box, FormControl, Input, Grid } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import ButtonPro from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import { replace } from 'formik';
import { store } from '../../../redux/store';
import { virementListTypes } from '../mock';
import { changesMock } from '../../customers/mock';
import AsyncSelect from 'react-select/async';
import SelectBoxComponent from '../../../components/SelectBox.component';
import CustomButton from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import { DATA } from '../../../utils/logAudit';
import { resetObj } from 'utils/resetObj';
import { useSelector } from 'react-redux';
import { getUserDataState } from 'features/auth/redux/selectors';

export default function ValidationTips() {
  const alert = useAlert();
  const [resultsData, setResultsData] = useState({
    total: 0,
    items: [],
    tableItems: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState('');
  const [page, setPage] = useState(0);
  const [provider, setProvider] = useState(null);
  const [TipsData, setTipsData] = useState(null);
  let numberOfRows = [100, 500, 1000];
  const [rowsPerPage, setRowsPerPage] = useState(numberOfRows[0]);
  const [transfers, setTransfers] = useState([]);
  const [total, setTotal] = useState('');
  const [comment, setComment] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [deliveryManId, setDeliveryManId] = useState('');
  const [type, setType] = useState('');
  const adminData = useSelector(getUserDataState);
  const [admin, setAdmin] = useState({
    _id: adminData.user._id,
    email: adminData.user.email,
    username: adminData.user.username
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const prepareShowTransfers = (item, index) => {
    return {
      ...item,
      createdAt: item.createdAt ? formatTime(item.createdAt) : '--',
      validAt: item.validAt ? formatTime(item.validAt) : '--',
      menu:
        item.status === 'PENDING' ? (
          <div>
            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={($event) => {
                setTipsData(item);
                handleClick($event);
              }}
            >
              Open Menu
            </Button>
          </div>
        ) : (
          ''
        ),
    };
  };
  const loadTipsList = (skip, limit, id, type) => {
    setIsLoading(true);
    getTips(skip, limit, true, id, type)
      .then(({ data }) => {
        setTotal(data.total);
        setTransfers(data.tips);
        // setResultsData({
        //   total: data.total,
        //   // items: data,
        //   items: data.Tips.map((item) => {
        //     return {
        //       ...item,
        //       createdAt: item.createdAt ? formatTime(item.createdAt) : '--',
        //       menu:
        //         item.status === 'PENDING' ? (
        //           <div>
        //             <Button
        //               aria-controls="simple-menu"
        //               aria-haspopup="true"
        //               onClick={($event) => {
        //                 setTipsData(item);
        //                 handleClick($event);
        //               }}
        //             >
        //               Open Menu
        //             </Button>
        //           </div>
        //         ) : (
        //           ''
        //         ),
        //     };
        //   }),
        // });
      })
      .catch((error) => {
        alert.show(error.response.data?.message, {
          type: 'info',
        }),
          setResultsData({
            total: 0,
            items: [],
            tableItems: [],
          });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handlePaginate = (val) => {
    setResultsData({
      items: [],
      tableItems: [],
    });
    loadTipsList(val.pageSelect, val.numberOfRows, deliveryManId, type);
    setPage(val.pageSelect);
    setRowsPerPage(val.numberOfRows);
  };

  const onSearchChange = (selectedOption, whoChange) => {
    page > 0 && setPage(0);
    switch (whoChange) {
      case changesMock.changeId:
        if (selectedOption) {
          setDeliveryManId(selectedOption);
          loadTipsList(page > 0 ? 0 : page, rowsPerPage, selectedOption, type);
        }
        break;
      case changesMock.changeType:
        if (selectedOption) {
          setType(selectedOption == virementListTypes[0] ? '' : selectedOption);
          loadTipsList(
            page > 0 ? 0 : page,
            rowsPerPage,
            deliveryManId,
            selectedOption == virementListTypes[0] ? '' : selectedOption,
          );
        }
        break;

      default:
        break;
    }
  };
  let timer1;
  const fetchData = (inputValue, callback) => {
    clearTimeout(timer1);
    timer1 = setTimeout(() => {
      inputValue &&
        getProviderDetails(0, 10, '', inputValue)
          .then(({ data }) => {
            const tempArray = [];
            if (data.deliveryMan) {
              if (data.deliveryMan.length) {
                data.deliveryMan.forEach((element) => {
                  tempArray.push({
                    label: `${element.name}`,
                    _id: element._id,
                  });
                });
              }
            }
            callback(tempArray);
          })
          .catch((error) => {
            console.log(error);
          });
    }, 1000);
  };
  const SearchComponent = () => {
    return (
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={5}>
          <AsyncSelect
            single
            defaultValue={inputValue}
            loadOptions={fetchData}
            placeholder="find deliveryMan"
            onChange={(e) => {
              onSearchChange(e._id, changesMock.changeId);
            }}
          />
        </Grid>
        <Grid item xs={2} container justify="flex-end">
          <Box textAlign="left">
            <CustomButton
              round
              type="submit"
              color="google"
              size="sm"
              loaderColor="white"
              onClick={() => {
                setType('');
                setDeliveryManId('');
                page > 0 && setPage(0);
                loadTipsList(page > 0 ? 0 : page, rowsPerPage, '', '');
              }}
            >
              clear
            </CustomButton>
          </Box>
        </Grid>
      </Grid>
    );
  };
  const handleOpenModal = (type) => {
    handleClose();
    setOpen(type);
  };
  const handleCloseModal = () => {
    setOpen('');
  };
  const validerOrCancelTips = (status) => {
    const reduxState = store.getState();
    let transferData = TipsData;
    transferData.status = status;
    transferData.comment = comment;
    const validator = reduxState.authReducer.userData
      ? reduxState.authReducer.userData.user
      : null;
    const { username, _id, email, entityId } = validator;
    TipsData.validator = {
      username: username,
      _id: _id,
      email: email,
      entityId: entityId,
    };
    validerTips(TipsData).then(({ data }) => {
      handleCloseModal();
      loadTipsList();
    });
  };

  useEffect(() => {
    transfers &&
      transfers.length > 0 &&
      setResultsData({
        items: transfers.map((item, index) => {
          return prepareShowTransfers(item, index);
        }),
      });
  }, [transfers]);
  return (
    <>
      <DataTableContainerComponent
        total={total}
        page={page + 1}
        resultsData={resultsData}
        initData={resultsData.items}
        columns={[
          {
            Header: 'Date',
            accessor: 'createdAt',
          },
          {
            Header: 'Provider',
            accessor: 'deliveryMan.name',
          },
          // {
          //   Header: 'type',
          //   accessor: 'type',
          // },
          {
            Header: 'Montant',
            accessor: 'amount',
          },
          {
            Header: 'customer',
            accessor: 'orderDetail.customer.name',
          },
          {
            Header: 'Validateur',
            accessor: 'validator.username',
          },
          {
            Header: 'Validation Date ',
            accessor: 'validAt',
          },
          {
            Header: 'Status',
            accessor: 'status',
          },
          {
            Header: 'Comment',
            accessor: 'comment',
          },
          {
            Header: '',
            accessor: 'menu',
          },
        ]}
        title="Gestion des Pourboires"
        isLoading={isLoading}
        loadItemsList={loadTipsList}
        onPaginate={handlePaginate}
        isPaginate={true}
        searchInp={true}
        SearchComponent={SearchComponent}
      />
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            handleOpenModal('valider');
          }}
        >
          Valider
        </MenuItem>
      </Menu>
      <ModalComponent
        maxWidth={'sm'}
        close={handleCloseModal}
        visible={open === 'valider'}
        title="Valider Pourboire"
      >
        {TipsData && (
          <div style={{ 'font-size': '16px', 'font-weight': '600' }}>
            <div style={{ border: '1px solid #2196f3', margin: '5px 0px' }}>
              <div className="row p-2">
                <div className="col-4">Livreur :</div>
                <div className="col-8">
                  {TipsData.deliveryMan ? TipsData.deliveryMan.name : 0}
                </div>
              </div>
              <div className="row  p-2">
                <div className="col-4">Pourboire :</div>
                <div className="col-8">
                  {TipsData.deliveryMan ? TipsData.deliveryMan.tips + ' DH' : 0}
                </div>
              </div>
            </div>
            <div style={{ border: '1px solid red', margin: '5px 0px' }}>
              <div className="row  p-2">
                <div className="col-4">Type Virement :</div>
                <div className="col-8">{'VALIDATION PORBOIRE'}</div>
              </div>
              <div className="row  p-2">
                <div className="col-4">Montant</div>
                <div className="col-8">{TipsData.amount + ' DH'}</div>
              </div>
              <div className="row  p-2">
                <div className="col-4">nouvelle pourboire</div>
                <div className="col-8">
                  {parseFloat(
                    TipsData.deliveryMan ? TipsData.deliveryMan.tips : 0,
                  ) +
                    TipsData.amount +
                    ' DH'}
                </div>
              </div>
              <div className="row  p-2">
                <div className="col-4">comment</div>
                <div className="col-8">
                  <TextField
                    style={{ width: '100%' }}
                    id="outlined-multiline-static"
                    label=""
                    multiline
                    rows={4}
                    defaultValue=""
                    variant="outlined"
                    onChange={(e) => setComment(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="p-2">
              <Box textAlign="center">
                <ButtonPro
                  round
                  type="button"
                  color="danger"
                  loading={isLoading}
                  loaderColor="white"
                  onClick={() => {
                    validerOrCancelTips('CANCELLED');
                  }}
                >
                  CANCELLED
                </ButtonPro>
                <ButtonPro
                  round
                  type="button"
                  color="success"
                  loading={isLoading}
                  loaderColor="white"
                  onClick={() => {
                    // reset object before setting data :
                    resetObj(DATA);
                    // for logger audit data :
                    DATA.admin = admin;
                    DATA.actionType = 'Validation tips';
                    DATA.targetId = TipsData.deliveryManId;
                    DATA.target = 'Delivery-man';
                    DATA.comment = comment;
                    DATA.loggerDetails = {
                      amount: TipsData.amount
                    }
                    logProvider(DATA)
                      .then(({ data }) => {
                        console.log(data);
                      })
                      .catch((error) => {
                        console.log(error.message, {
                          type: 'error',
                        });
                      })
                    // console.log(DATA);
                    validerOrCancelTips('DONE');
                  }}
                >
                  DONE
                </ButtonPro>
              </Box>
            </div>
          </div>
        )}
      </ModalComponent>
    </>
  );
}
