import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useAlert } from 'react-alert';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Box } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import CustomInput from '../../../components/material-dashboard-pro-react/CustomInput/CustomInput';
import { defaultImagesAction } from '../../../redux/uploadRedux/upload.action';
import SwitchComponent from '../../../components/Switch.component';
import ImageUpload from '../../../components/material-dashboard-pro-react/CustomUpload/ImageUpload';
import {
  updateItem,
  getSpecification,
  getCategory,
} from '../services/serverAPI.service';
import SelectBoxComponent from '../../../components/SelectBox.component';
import CustomBtn from '../../../components/material-dashboard-pro-react/CustomButtons/Button';

const validationSchema = yup.object({
  name: yup.string().required(),
  storeId: yup.string().required(),
  productId: yup.string().required(),
  description: yup.string().required(),
  price: yup.number().typeError('value is number').required(),
  imageUrl: yup.string().nullable(),
  // inStock: yup.bool().required(),
  specifications: yup.array().of(yup.string()).required(),
  partnerId: yup.string(),
  // isMainPromo: yup.bool().required(),
  // promoSetting: yup.object().shape({
  //     type: yup.string().required(),
  //     // value: yup.number().required(),
  //     value: yup.number().when('type',{
  //     is:value=>value=='reduction',
  //     then:yup.number().typeError('value is number').required('value is required when choise reduction'),
  //     // otherwise: yup.number().typeError('value is number')
  //     otherwise: ''
  //     }),
  //     discountType: yup.string().when('type',{
  //     is:value=>value=='reduction',
  //     then:yup.string().required(),
  //     // otherwise: yup.number().typeError('value is number')
  //     otherwise: yup.string().nullable()
  //     }),
  //     // discountType: yup.string().required(),
  //     // minCartPrice: yup.number().when('type',{
  //     // is:value=>value=='offered product'||value=='1 buy = 1 free',
  //     // then:yup.number().typeError('minCartPrice is number').required(),
  //     // // otherwise: yup.number().typeError('value is number')
  //     // otherwise: ''
  //     // }),
  //     minCartPrice: yup.number(),
  //     // enabled: yup.bool().required(),
  // }),
});
export default function EditeItem({ data, close, onItemEdited, props }) {
  const alert = useAlert();
  // console.log(props);
  const dataProps = data;
  const { resultsData, setResultsData } = props;
  // console.log(resultsData);
  const storeId = localStorage.getItem('storeId');
  const upload = useSelector((state) => state.uploadReducer);
  const dispatch = useDispatch();
  const { iconUrl, error, loading } = upload;
  // console.log(iconUrl, error, loading);
  const [isLoading, setIsLoading] = useState(false);
  const [displayImage, setDisplayImage] = useState(false);
  const [specification, setSpecification] = useState([]);
  const [products, setProducts] = useState([]);
  const formik = useFormik({
    initialValues: {
      name: '',
      storeId: '',
      productId: '',
      description: '',
      price: '',
      imageUrl: '',
      inStock: '',
      specifications: [],
      isMainPromo: '',
      _id: '',
      partnerId: '',
      promoSetting: {
        // type: '',
        // // type: radioGroupData[0].name,
        // value: 0,
        // discountType: "Percentage",
        // minCartPrice: 0,
        // enabled: true,
      },
      flag: '',
    },
    validationSchema,
  });

  const setValue = () => {
    if (data) {
      // console.log('data',data);
      !iconUrl && dispatch(defaultImagesAction(data?.imageUrl));

      formik.setValues({
        name: data?.name,
        storeId: data?.storeId,
        productId: data?.productId,
        description: data?.description,
        price: data?.price,
        imageUrl: iconUrl,
        inStock: data?.inStock,
        specifications: data?.specifications,
        isMainPromo: data?.isMainPromo,
        _id: data?._id,
        promoSetting: data?.promoSetting,
        partnerId: data?.partnerId,
        flag: '',
        // {
        //     type: data?.name?.props?.promoSetting?.type,
        // // type: radioGroupData[0].name,
        // value: data?.name?.props?.promoSetting?.value,
        // discountType: data?.name?.props?.promoSetting?.discountType||"Percentage",
        // minCartPrice: data?.name?.props?.promoSetting?.minCartPrice,
        // // enabled: true,
        // }
      });
    }
  };
  const onEdit = () => {
    // console.log(formik.values);
    setIsLoading(true);
    updateItem(formik.values)
      .then(({ data }) => {
        // console.log(data);
        alert.show('item updated', {
          type: 'success',
        });
        close();
        // let data = [...resultsData]
        // //     data[data.index] = data.Item
        // //     data[data.index].total = resultsData?.total
        // //     setResultsData(data)
        close();
        onItemEdited(0, 10);
      })
      .catch((error) => {
        alert.show(error.message, {
          type: 'error',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setValue();
    const newSpecificationClassmenetArray = [];
    dataProps &&
      getSpecification(storeId)
        .then(({ data }) => {
          const newSpecificationClassmenetArray = [];
          dataProps.specifications.length > 0 &&
            dataProps.specifications.map((id) => {
              const findIndex = data.findIndex((item) => item._id == id);
              findIndex >= 0 &&
                newSpecificationClassmenetArray.push(data[findIndex]);
            });

          data.length > 0 &&
            data.map((specification) => {
              const findIndex = newSpecificationClassmenetArray.find(
                (item) => item._id == specification._id,
              );
              !findIndex && newSpecificationClassmenetArray.push(specification);
            });
          // console.log('newSpecificationClassmenetArray',newSpecificationClassmenetArray);
          setSpecification(newSpecificationClassmenetArray);
        })
        .catch((error) => {
          alert.show(error.message, {
            type: 'error',
          });
        });

    getCategory(storeId)
      // eslint-disable-next-line no-shadow
      .then(({ data }) => {
        setProducts(data);
      })
      .catch((error) => {
        alert.show(error.message, {
          type: 'error',
        });
      });
  }, [data]);
  useEffect(() => {
    iconUrl && formik.setFieldValue('imageUrl', iconUrl);
  }, [iconUrl]);
  // console.log('iconUrl',iconUrl);
  return (
    <div>
      <div>
        {!displayImage && <span>you need to upload a new image</span>}
        <Switch
          color="primary"
          checked={displayImage}
          value={displayImage}
          onChange={(e) => {
            displayImage ? setDisplayImage(false) : setDisplayImage(true);
          }}
        />
      </div>
      <Box display="flex" justifyContent="center">
        {Boolean(data) &&
          (!displayImage ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img
                src={formik.values.imageUrl}
                alt="no img item"
                height="100"
                width="120"
              />
              {formik.values.imageUrl && (
                <Box>
                  <CustomBtn
                    color="google"
                    size="sm"
                    onClick={() => formik.setFieldValue('imageUrl', '')}
                  >
                    delete img
                  </CustomBtn>
                </Box>
              )}
            </div>
          ) : (
            <ImageUpload
              // defaultImage={data.iconUrl}
              // onSubmit={onUploadMedia}
              // onRemove={onRemoveImage}
              label="Select icon"
              avatar
              folder="service"
            />
          ))}
      </Box>
      <CustomInput
        labelText="Name"
        formControlProps={{
          fullWidth: true,
        }}
        type="text"
        error={formik.touched.name && Boolean(formik.errors.name)}
        helperText={formik.errors.name && formik.errors.name}
        inputProps={{
          value: formik.values.name,
          onBlur: formik.handleBlur('name'),
          onChange: formik.handleChange('name'),
        }}
      />
      <CustomInput
        labelText="Description"
        formControlProps={{
          fullWidth: true,
        }}
        type="text"
        error={formik.touched.description && Boolean(formik.errors.description)}
        helperText={formik.errors.description && formik.errors.description}
        inputProps={{
          value: formik.values.description,
          onBlur: formik.handleBlur('description'),
          onChange: formik.handleChange('description'),
        }}
      />

      <CustomInput
        labelText="price"
        formControlProps={{
          fullWidth: true,
        }}
        type="text"
        error={formik.touched.price && Boolean(formik.errors.price)}
        inputProps={{
          value: formik.values.price,
          onBlur: formik.handleBlur('price'),
          onChange: formik.handleChange('price'),
        }}
      />
      <SelectBoxComponent
        items={specification.map((item) => ({
          value: item._id,
          label: item.name,
        }))}
        inputLabel="specification list"
        onValueChange={(items) => {
          formik.setFieldValue('specifications', items);
        }}
        value={formik.values.specifications}
        multiple
      />

      <SelectBoxComponent
        items={products.map((product) => ({
          value: product._id,
          label: product.name,
        }))}
        inputLabel="Category"
        onValueChange={formik.handleChange('productId')}
        value={formik.values.productId}
      />
      <CustomInput
        labelText="partnerId"
        formControlProps={{
          fullWidth: true,
        }}
        type="text"
        error={formik.touched.partnerId && Boolean(formik.errors.partnerId)}
        inputProps={{
          value: formik.values.partnerId,
          onBlur: formik.handleBlur('partnerId'),
          onChange: formik.handleChange('partnerId'),
        }}
      />
      <Box textAlign="center">
        <Button
          round
          type="submit"
          color="success"
          disabled={!formik.dirty || !formik.isValid || isLoading}
          loading={isLoading}
          loaderColor="white"
          onClick={onEdit}
        >
          Edit Item
        </Button>
      </Box>
    </div>
  );
}
EditeItem.propTypes = {
  data: PropTypes.any,
  close: PropTypes.any,
  onItemEdited: PropTypes.func.isRequired,
};
