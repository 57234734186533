import React, { useEffect, useState } from 'react';
import { Box, Grid ,Switch} from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import styles from '../../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle';
import { updateStore } from '../services/serverApi.service';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import { getTag } from '../../delivery-info/services/serverApi.service'

const useStyles = makeStyles(styles);
const storeTagsStyle = {
  // list:{
  //   float: 'left'
  // },
  flexContainer: {

    display: 'flex',
    flexDirection: 'column',
    padding: 0,
  },
  img: {
    height: '50px',
    width: '50px',
  }
};

export default function StoreTagsComponent({ services, store, close, tag, id }) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  // const [Alltag, setAllTag] = useState([]);
  const [tags, setTags] = useState(
    services.find((item) => item._id == store.serviceId)?.tags ,
  );
  // const [service, setService] = useState(
  //   services.find((item) => item._id == store.serviceId)?.tags ,
  // );
  // console.log(services);
  // console.log(service);
  console.log(store);
  console.log(tags);
  const selectTags = (index) => {
    let newTags = tags;
    console.log(newTags[index].checked, index);
    newTags[index].checked = !newTags[index].checked;
    setTags([...newTags]);
  };
  const swicthTags = (index) => {
    let newTags = tags;
    console.log(newTags[index].mainTag, index);
    newTags[index].mainTag = !newTags[index].mainTag;
    setTags([...newTags]);
  };

  const onEdit = async () => {
    setIsLoading(true);
    const tagsList = await tags
      .filter((item) => {
        return item.checked;
      })
      .map((item) => item.name);
    const mainTags = await tags
      .filter((item) => {
        return item.mainTag;
      })
      .map((item) => item.name);
    store.tags = tagsList;
    store.mainTags = mainTags;
    let { _id, ...newStore } = store;
    updateStore(_id, newStore)
      .then(({ data }) => {
        console.log('Store has been updated', {
          type: 'success',
        });
      })
      .catch((error) => {
        console.log(error.message, {
          type: 'error',
        });
      })
      .finally((res) => {
        close();
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const tagList = tags ? tags.map((item) => {
      const checked =
        store.tags.findIndex((val) => {
          return val.toLowerCase() === item.name.toLowerCase();
        }) > -1;
      const mainTag =
        store.mainTags.findIndex((val) => {
          return val.toLowerCase() === item.name.toLowerCase();
        }) > -1;
      return {
        ...item,
        checked: checked,
        mainTag:mainTag
      };
    }) : [];
    setTags([...tagList]);
    // getTagFromAPI()
  }, []);
console.log(tags);

  return (
    <div>

      <List
        dense
      // sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
      // style={storeTagsStyle.list}
      >
        <Grid container spacing={16} justify="flex-start">
          {tags && tags.map((item, index) => {
            return (
              <Grid item xs={3} sm={3} md={3} lg={3} >
                <ListItem
                  style={storeTagsStyle.flexContainer}
                >
                  <ListItemAvatar>
                    <img src={item.iconUrl || 'img not found'} style={storeTagsStyle.img} />
                  </ListItemAvatar>
                  <ListItemText primary={item.name || 'name not found'} secondary="" />
                  <div>
                    <Checkbox
                      checked={!!item.checked}
                      onChange={() => {
                        selectTags(index);
                      }}
                    />
                    <Switch 
                    checked={!!item.mainTag}
                    // defaultValue={false}
                    label="Label"
                    onChange={() => {swicthTags(index)}}
                    />
                  </div>
                </ListItem>
              </Grid>
            );
          })}
        </Grid>
      </List>
      <Box textAlign="center">
        <Button variant="contained" color="success" disabled={isLoading} loading={isLoading} onClick={onEdit}>
          save
        </Button>
      </Box>
    </div>
  );
}

StoreTagsComponent.propTypes = {
  services: PropTypes.any.isRequired,
  store: PropTypes.any.isRequired,
  tag: PropTypes.any.isRequired,
  id: PropTypes.any.isRequired,
  close: PropTypes.func,
};
