import { DEFAULT_SELECTED_CITIES, SELECT_CITY, SELECT_WAREHOUSE } from '../constants/filter.constants';
import { allArray } from '../../mock';


const initialState = {
    selectedCities: [],
    selectedWarehouse: [],
    filterChangeCounter: 1,
    filterWarehouseChangeCounter: 1,
    error: '',
    loading: false,
};
export default function filterReducers(state = initialState, action) {
    let selectedCities = state?.selectedCities || [];
    let selectedWarehouse = state?.selectedWarehouse || [];
    switch (action.type) {
        case DEFAULT_SELECTED_CITIES:
            const selectedCityArray = JSON.parse(
                localStorage.getItem('selectedCityArray'),
            );
            if (!selectedCityArray) {
                localStorage.setItem('selectedCityArray', JSON.stringify(allArray));
                selectedCities = allArray;
            } else {
                selectedCities = selectedCityArray;
            }
            return {
                ...state,
                selectedCities: selectedCities,
                filterChangeCounter: (state.filterChangeCounter + 1) % 30,
                loading: false,
            }
        case SELECT_CITY:

            localStorage.setItem('selectedCityArray', JSON.stringify(action.cities));
            return {
                ...state,
                selectedCities: action.cities,
                filterChangeCounter: (state.filterChangeCounter + 1) % 30,
                filterWarehouseChangeCounter: state.filterWarehouseChangeCounter,
                loading: false,
            }
        case SELECT_WAREHOUSE:
            if (action.select) {
                selectedWarehouse.push(action?.value)
            } else {
                const findIndex = selectedWarehouse.indexOf(action.value)
                findIndex != -1 && selectedWarehouse.splice(findIndex, 1)
            }
            return {
                ...state,
                selectedWarehouse: selectedWarehouse,
                filterChangeCounter: state.filterChangeCounter,
                filterWarehouseChangeCounter: (state.filterWarehouseChangeCounter + 1) % 30,
                loading: false,
            }
        default:
            return state
    }
}