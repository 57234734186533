 import React,{ useEffect, useState} from 'react'
 import {Box} from '@material-ui/core'
 import CloseIcon from '@material-ui/icons/Close';
 import {useFormik} from 'formik'
 import * as yup from 'yup';
 import CustomInput from '../../../components/material-dashboard-pro-react/CustomInput/CustomInput'
 import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button'
 import { getRatingList,setRatingList } from '../services/serverApi.service'
 import SwitchComponent from '../../../components/Switch.component';
 import SelectBoxComponent from '../../../components/SelectBox.component'
 import { selectNumbers } from '../mocks';
 const validationSchema = yup.object({
     name: yup.string().required()
   });
 export default function StoreRatingList() {
     const [dataRatingDelivery, setDataRatingDelivery] = useState({ "deliveryManRatingList": {},"kaalixAppRatingList":{},"storeRatingList":{}});
     const [isLoading,setIsLoading]= useState(false)
     const [appStores,setAppStores]= useState('')
     const [displays,setDisplays]= useState('')
     const [selectNumber,setSelectNumber]= useState('')
 
    
 
     const formik = useFormik({
         initialValues: {
             storeROne: '',
             storeRTwo: '',
             storeRThree: '',
             storeRFour: '',
             storeRFive: '',
             numberSelected: '',
            
           
           },
           validationSchema,
     })
     const getRating = ()=>{
         getRatingList()
         .then(({ data }) => {
         setDataRatingDelivery({ "deliveryManRatingList": data.ratingList.deliveryManRatingList, "kaalixAppRatingList": data.ratingList.kaalixAppRatingList ,"storeRatingList": data.ratingList.storeRatingList });
         setAppStores(data.ratingList.storeRatingList.appStore)
         setDisplays(data.ratingList.storeRatingList.display)
         setSelectNumber(data.ratingList.storeRatingList.starsNumber);
 
         formik.setValues({
             numberSelected: data.ratingList.storeRatingList.starsNumber,
            
           })
         })
         .catch((err) => console.log(err));
     }
     const saveRating = (type,val)=>{
      const tempSatate = dataRatingDelivery;
     if(type === 'storeROne' && formik.values.storeROne !== ''){
         tempSatate.storeRatingList['1'].push(formik.values.storeROne );
         setRatingList(tempSatate).then(({ data }) => {
             formik.setFieldValue('storeROne', '');
             formik.resetForm();
         }
         ).catch((error) => console.log(error));
      
     } else if(type === 'storeRTwo' && formik.values.storeRTwo !== ''){
         tempSatate.storeRatingList['2'].push(formik.values.storeRTwo );
         setRatingList(tempSatate).then(({ data }) => {
             formik.setFieldValue('storeRTwo', '');
             formik.resetForm();
         }
         ).catch((error) => console.log(error));
 
     } else if(type === 'storeRThree' && formik.values.storeRThree !== ''){
         tempSatate.storeRatingList['3'].push(formik.values.storeRThree );
         setRatingList(tempSatate).then(({ data }) => {
             formik.setFieldValue('storeRThree', '');
             formik.resetForm();
         }
         ).catch((error) => console.log(error));
     
     }else if(type === 'storeRFour' && formik.values.storeRFour !== ''){
         tempSatate.storeRatingList['4'].push(formik.values.storeRFour );
         setRatingList(tempSatate).then(({ data }) => {
             formik.setFieldValue('storeRFour', '');
             formik.resetForm();
         }
         ).catch((error) => console.log(error));
 
     }else if(type === 'storeRFive' && formik.values.storeRFive !== ''){
         tempSatate.storeRatingList['5'].push(formik.values.storeRFive );
         setRatingList(tempSatate).then(({ data }) => {
             formik.setFieldValue('storeRFive', '');
             formik.resetForm();
                         
 
         }
         ).catch((error) => console.log(error));
 
     }else if(type === 'numberSelected' && formik.values.numberSelected !== ''){
         tempSatate.storeRatingList.starsNumber = formik.values.numberSelected;
          setRatingList(tempSatate).then(({ data }) => {
             formik.setValues({
                 numberSelected: formik.values.numberSelected,
          })
          setSelectNumber(formik.values.numberSelected);
             formik.resetForm();          
         
         }
         ).catch((error) => console.log(error));
     
     }else if(type === 'appStores'){
         setAppStores(val)
         tempSatate.storeRatingList.appStore = val;
          setRatingList(tempSatate).then(({ data }) => {
         formik.resetForm();          
 
         }
         ).catch((error) => console.log(error));
     }else if(type === 'displays'){
         setDisplays(val)
         tempSatate.storeRatingList.display = val;
          setRatingList(tempSatate).then(({ data }) => {
         formik.resetForm();          
 
         }
         ).catch((error) => console.log(error));
     
     }
 
     
     }
     useEffect(() => {
         getRating()
     }, []);
     const deleteRating = (index,type) =>{
         const tempSatate = dataRatingDelivery;
    if(type === 'storeROne' && index !== -1){
        tempSatate.storeRatingList['1'].splice(index,1);
        setRatingList(tempSatate).then(({ data }) => {
               formik.resetForm();
          }
             ).catch((error) => console.log(error));
 
     } else if(type === 'storeRTwo' && index !== -1){
         tempSatate.storeRatingList['2'].splice(index,1);
         setRatingList(tempSatate).then(({ data }) => {
             formik.resetForm();
         }
         ).catch((error) => console.log(error));
 
     } else if(type === 'storeRThree' && index !== -1){
         tempSatate.storeRatingList['3'].splice(index,1);
         setRatingList(tempSatate).then(({ data }) => {
             formik.resetForm();
         }
         ).catch((error) => console.log(error));
          
     } else if(type === 'storeRFour' && index !== -1){
 
         tempSatate.storeRatingList['4'].splice(index,1);
         setRatingList(tempSatate).then(({ data }) => {
             formik.resetForm();
         }
         ).catch((error) => console.log(error));
     }else if(type === 'storeRFive' && index !== -1){
 
         tempSatate.storeRatingList['5'].splice(index,1);
         setRatingList(tempSatate).then(({ data }) => {
             formik.resetForm();
         }
         ).catch((error) => console.log(error));
     }
 }
 
     return (
         <div>
         <div className="d-flex justify-content-between">
             <div>  
                 <h6 className="font-weight-bold">StoreRating 1</h6>
             {(dataRatingDelivery?.storeRatingList['1']|| []).map((item,index)=>(
                 <p>
                     <CloseIcon onClick={()=>deleteRating(index,'storeROne')} fontSize="small" style={{ color: 'red',cursor:'pointer' }}/>
                 
                 {item}
                     </p>
             ))}
 
            
             </div>
 
 
             <div>
             <h6 className="font-weight-bold">StoreRating 2</h6>
             {(dataRatingDelivery?.storeRatingList['2'] || []).map((item,index)=>(
                 <p>
                     <CloseIcon onClick={()=>deleteRating(index,'storeRTwo')} fontSize="small" style={{ color: 'red',cursor:'pointer' }}/>
                 
                 {item}
                     </p>
             ))}
            
             </div>
             <div>
             <h6 className="font-weight-bold">StoreRating 3</h6>
             {(dataRatingDelivery?.storeRatingList['3']|| []).map((item,index)=>(
                 <p>
                     <CloseIcon onClick={()=>deleteRating(index,'storeRThree')} fontSize="small" style={{ color: 'red',cursor:'pointer' }}/>
                 
                 {item}
                     </p>
             ))}
             
             </div>
 
             </div>
 
            <div className="d-flex justify-content-between">
 
              <div >
              <CustomInput
                 labelText="Name"
                 formControlProps={{
                     fullWidth: true,
                 }}
                 type="text"
                 error={formik.touched.storeROne && Boolean(formik.errors.storeROne)}
                 inputProps={{
                     value: formik.values.storeROne,
                     onBlur: formik.handleBlur('storeROne'),
                     onChange: formik.handleChange('storeROne'),
                 }}
             />
             
             <div>
             <Box textAlign="center">
                 <Button
                     round
                     type="submit"
                     color="success"
                     disabled={isLoading}
                     loading={isLoading}
                     size="sm"
                     loaderColor="white"
                     onClick={ ()=>saveRating('storeROne')}
                 >
                     save
                 </Button>
             </Box>
         </div>
             </div>
              
             <div >
              <CustomInput
                 labelText="Name"
                 formControlProps={{
                     fullWidth: true,
                 }}
                 type="text"
                 error={formik.touched.storeRTwo && Boolean(formik.errors.storeRTwo)}
                 inputProps={{
                     value: formik.values.storeRTwo,
                     onBlur: formik.handleBlur('storeRTwo'),
                     onChange: formik.handleChange('storeRTwo'),
                 }}
             />
             
             <div >
             <Box textAlign="center">
                 <Button
                     round
                     type="submit"
                     color="success"
                     disabled={isLoading}
                     loading={isLoading}
                     size="sm"
                     loaderColor="white"
                     onClick={ ()=>saveRating('storeRTwo')}
 
                 >
                     save
                 </Button>
             </Box>
         </div>
             </div>
             
             <div >
              <CustomInput
                 labelText="Name"
                 formControlProps={{
                     fullWidth: true,
                 }}
                 type="text"
                 error={formik.touched.storeRThree && Boolean(formik.errors.storeRThree)}
                 inputProps={{
                     value: formik.values.storeRThree,
                     onBlur: formik.handleBlur('storeRThree'),
                     onChange: formik.handleChange('storeRThree'),
                 }}
             />
             
             <div>
             <Box textAlign="center">
                 <Button
                     round
                     type="submit"
                     color="success"
                     disabled={isLoading}
                     loading={isLoading}
                     size="sm"
                     loaderColor="white"
                     onClick={ ()=>saveRating('storeRThree')}
                 >
                     save
                 </Button>
             </Box>
         </div>
             </div>
            
 
             </div>
 
            {/* start part two   */}
            <div className="d-flex justify-content-between mt-5">
             <div >
                 <h6 className="font-weight-bold">StoreRating 4</h6>
             {(dataRatingDelivery?.storeRatingList['4']|| []).map((item,index)=>(
                 <p>
                     <CloseIcon onClick={()=>deleteRating(index,'storeRFour')} fontSize="small" style={{ color: 'red',cursor:'pointer' }}/>
                 
                 {item}
                     </p>
             ))}
 
            
             </div>
 
 
             <div >
             <h6 className="font-weight-bold">StoreRating 5</h6>
             {(dataRatingDelivery?.storeRatingList['5'] || []).map((item,index)=>(
                 <p>
                     <CloseIcon onClick={()=>deleteRating(index,'storeRFive')} fontSize="small" style={{ color: 'red',cursor:'pointer' }}/>
                 
                 {item}
                     </p>
             ))}
            
             </div>
             
              
            <div>
            <SwitchComponent
         onChange={(val)=>saveRating('appStores',val)}
         value="checked"
         checked={appStores}
         label={'appStore'}
       />
            <SwitchComponent
         onChange={(val)=>saveRating('displays',val)}
         value="checked"
         checked={displays}
         label={'display'}
       />
            </div>
 
             </div>
 
 
 
 
            <div className="d-flex justify-content-between">
 
              <div >
              <CustomInput
                 labelText="Name"
                 formControlProps={{
                     fullWidth: true,
                 }}
                 type="text"
                 error={formik.touched.storeRFour && Boolean(formik.errors.storeRFour)}
                 inputProps={{
                     value: formik.values.storeRFour,
                     onBlur: formik.handleBlur('storeRFour'),
                     onChange: formik.handleChange('storeRFour'),
                 }}
             />
             
             <div>
             <Box textAlign="center">
                 <Button
                     round
                     type="submit"
                     color="success"
                     disabled={isLoading}
                     loading={isLoading}
                     size="sm"
                     loaderColor="white"
                     onClick={ ()=>saveRating('storeRFour')}
                 >
                     save
                 </Button>
             </Box>
         </div>
             </div>
              
             <div >
              <CustomInput
                 labelText="Name"
                 formControlProps={{
                     fullWidth: true,
                 }}
                 type="text"
                 error={formik.touched.storeRFive && Boolean(formik.errors.storeRFive)}
                 inputProps={{
                     value: formik.values.storeRFive,
                     onBlur: formik.handleBlur('storeRFive'),
                     onChange: formik.handleChange('storeRFive'),
                 }}
             />
             
             <div>
             <Box textAlign="center">
                 <Button
                     round
                     type="submit"
                     color="success"
                     disabled={isLoading}
                     loading={isLoading}
                     size="sm"
                     loaderColor="white"
                     onClick={ ()=>saveRating('storeRFive')}
 
                 >
                     save
                 </Button>
             </Box>
         </div>
             </div>
             
           
             <div>
             <span className="font-weight-bold">Start number</span>
             <SelectBoxComponent
         items={selectNumbers.map((type) => ({
           value: type.label,
           label: type.label,
         }))}
         onValueChange={formik.handleChange('numberSelected')}
         value={formik.values.numberSelected !==''?formik.values.numberSelected :selectNumber}
       />
       <Box textAlign="center">
         <Button
           round
           type="submit"
           color="success"
           size="sm"
           loading={isLoading}
           loaderColor="white"
           onClick={()=>saveRating('numberSelected')}
         >
           save 
         </Button>
         </Box>
            </div>
             </div>
         {/* end part button */}
         </div>
     )
 }
 
 


