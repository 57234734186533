import React from 'react';
import PropTypes from 'prop-types';
import CustomInput from '../../../components/material-dashboard-pro-react/CustomInput/CustomInput';


export default function ViewItemComponent({ item }) {
  
  return (
    <>
      {item &&
        <>
          <CustomInput
            labelText="Name"
            formControlProps={{
              fullWidth: true,
            }}
            type="text"
            inputProps={{
              value: item[1],
            }}
          />
          <CustomInput
            labelText="Currency"
            formControlProps={{
              fullWidth: true,
            }}
            type="text"
            inputProps={{
              value: item[2],
            }}
          />
        </>
      }
    </>
  )

}

ViewItemComponent.propTypes = {
  item: PropTypes.any,
};
