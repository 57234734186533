import React , { useEffect, useState } from 'react'
import DataTableSortingContainer from '../../../components/DataTableSortingContainer.component';

import {getOrderById} from '../service/serverAPI.service'

export default function OrderDetailsComponent({item}) {
    // console.log('item',item);
    const [order,setOrder]  = useState({})
    const [isLoading,setIsLoading]  = useState(false)
    const [resultsData, setResultsData] = useState({
        total: 0,
        items: [],
        tableItems: [],
      });
      const columns = [
        {
          Header: 'delivery Price',
          accessor: 'deliveryPrice',
        },
        {
          Header: 'Order Price',
          accessor: 'orderPrice',
        },
        {
          Header: 'Paid to store',
          accessor: 'paidToStore',
        },
        {
          Header: 'paid by customer',
          accessor: 'paidByCustomer',
        },
        {
          Header: 'Cash',
          accessor: 'cash',
        },
        {
          Header: 'Card',
          accessor: 'card',
        },
        {
          Header: 'KaalixPay',
          accessor: 'kaalixPay',
        },
        {
          Header: 'KaalixUp',
          accessor: 'kaalixUp',
        },
        {
          Header: 'Promo',
          accessor: 'promo',
        },
        {
          Header: 'Commission source',
          accessor: 'commisionCol',
        },
      ]
      const customColumnStyle = {
        // wordWrap: "break-word",
        maxWidth: '250px',
        minWidth: '120px',
        fontSize: '13px',
      };
      const getOrderInfo = ()=>{
        setIsLoading(true)
        getOrderById(item.orderId)
        .then(({data})=>{
            console.log('data',data);
            // setOrder(data);
            const dataArray = [data]
            setResultsData({
                // items : [data]
                items: [data].map(item=>{
                    return {
                        ...item,
                        deliveryPrice : item.payment?.deliveryPrice.toFixed(2),
                        orderPrice : item.payment?.orderPrice.toFixed(2),
                        cash : item.payment.payments[0].amount,
                        card:item.payment.payments[1].amount,
                        kaalixUp:item.payment.payments[2].amount,
                        kaalixPay:item.payment.payments[3].amount,
                        commissionSource : item.commission,
                        promo : item?.payment?.promo,
                        paidToStore : item?.payment?.pricePaid,
                        paidByCustomer : item?.payment?.priceInvoice,
                        commisionCol : item?.payment?.storeCommission+'%',

                    }
                }),
            })
        })
        .catch((error) => {console.log(error)})
        .finally(() =>setIsLoading(false))
      }
    useEffect(() => {
        item?.orderId&&getOrderInfo()
    },[item])
    console.log('resultsData',resultsData);
    return (
        <div>
           { item&&<DataTableSortingContainer
          resultsData={resultsData}
          initData={resultsData.items}
        //   total={resultsData.total}
          columns={columns}
        //   title="order details"
          isLoading={isLoading}
        //   isPaginate={true}
        //   onPaginate={handlePaginate}
        //   actionButtons={{
        //     view: {
        //       color: 'info',
        //       type: 'view',
        //       // props: { services: services, cities: cities },
        //       component: OrderDetailsComponent,
        //     },
        //   }}
          // loadItemsList={loadBalanceList}
          customColumnStyle={customColumnStyle}
        />}
        </div>
    )
}
