import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Box } from '@material-ui/core';
import { useAlert } from 'react-alert';

import ImageUpload from '../../../components/material-dashboard-pro-react/CustomUpload/ImageUpload';
import CustomInput from '../../../components/material-dashboard-pro-react/CustomInput/CustomInput';
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import SelectBoxComponent from '../../../components/SelectBox.component';
import SwitchComponent from '../../../components/Switch.component';

import { updateTemplateNonQualities, getSingleTemplate } from '../services/serverApi.service';

const RESPONSABLE = [
  {"name" : "DeliveryMan", "value": "Livreur"},
  {"name" : "Store", "value": "Store"},
  {"name" : "Customer", "value": "Client"},
  {"name" : "Kaalix", "value": "Kaalix"}
];

const DESTINATION = [
  {"name" : "Customer", "value" : "Client"},
  {"name" : "DeliveryMan", "value": "Livreur"}
];

const TYPES = [
  {"name" : "Fixed", "value" : "Fixed"},
  {"name" : "Pourcentage frais livraison", "value" : "Pourcentage frais livraison"},
  {"name" : "Pourcentage commission livreur", "value" : "Pourcentage commission livreur"},
  {"name" : "Pourcentage montant des articles", "value" : "Pourcentage montant des articles"},
];

const DIRECTION = [
  {"name" : "Kaalix-OUT", "value" : "Kaalix-OUT"},
  {"name" : "Kaalix-IN", "value" : "Kaalix-IN"}
];

const validationSchema = yup.object({
  templateName: yup.string().required(),
  templateResponsable: yup.string().required(),
  templateDestination: yup.string().required(),
  templateType: yup.string().required(),
  templateAmount: yup.number().required(),
  templateDirection: yup.string().required(),
});

export default function EditTemplate({ data, close, onItemEdited }) {

  const alert = useAlert();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [template, setTemplate] = useState([]);
  const formik = useFormik({
    initialValues: {
      templateName: '',
      templateResponsable: '',
      templateDestination: '',
      templateType: '',
      templateAmount: '',
      templateDirection: '',
    },
    validationSchema,
  });

  const onCreate = () => {
    setIsLoading(true);
    updateTemplateNonQualities(data._id, formik.values)
      .then(() => {
        alert.show('Template has been updated', {
          type: 'success',
        });
        formik.resetForm();
        onItemEdited();
        close();
      })
      .catch((error) => {
        alert.show(error.message, {
          type: 'error',
        });
      })
      .finally(() => {
        setIsLoading(false);
      })
  }

  const setValue = () => {
    if(data) {
      formik.setValues({
        templateName: data.templateName,
        templateResponsable: data.templateResponsable,
        templateDestination: data.templateDestination,
        templateType: data.templateType,
        templateAmount: data.templateAmount,
        templateDirection: data.templateDirection,
      })
    }
  }

  useEffect(() => {
    setValue();
  }, [data]);

  return(
    <div>
      <CustomInput 
      labelText="nom de template"
      type="text"
      error={formik.touched.templateName && Boolean(formik.errors.templateName)}
      formControlProps={{
        fullWidth: true,
      }}
      inputProps={{
        value: formik.values.templateName,
        onBlur: formik.handleBlur('templateName'),
        onChange: formik.handleChange('templateName'),
      }}
      />
      <SelectBoxComponent 
      items={RESPONSABLE.map((element) => ({
        value: element.value,
        label: element.name,
      }))}
      inputLabel="Responsable List"
      onValueChange={(items) => {
        formik.setFieldValue('templateResponsable', items);
      }}
      value={formik.values.templateResponsable}
      />
      {/* <CustomInput 
      labelText="Responsable"
      type="text"
      error={formik.touched.templateResponsable && Boolean(formik.errors.templateResponsable)}
      formControlProps={{
        fullWidth: true,
      }}
      inputProps={{
        value: formik.values.templateResponsable,
        onBlur: formik.handleBlur('templateResponsable'),
        onChange: formik.handleChange('templateResponsable'),
      }}
      /> */}
      <SelectBoxComponent 
      items={DESTINATION.map((element) => ({
        value: element.value,
        label: element.name
      }))}
      inputLabel="Destination List"
      onValueChange={(items) => {
        formik.setFieldValue('templateDestination', items);
      }}
      value={formik.values.templateDestination}
      />
      {/* <CustomInput 
      labelText="Destination"
      type="text"
      error={formik.touched.templateDestination && Boolean(formik.errors.templateDestination)}
      formControlProps={{
        fullWidth: true,
      }}
      inputProps={{
        value: formik.values.templateDestination,
        onBlur: formik.handleBlur('templateDestination'),
        onChange: formik.handleChange('templateDestination'),
      }}
      /> */}
      <SelectBoxComponent 
      items={TYPES.map((element) => ({
        value: element.value,
        label: element.name,
      }))}
      inputLabel="Types List"
      onValueChange={(items) => {
        formik.setFieldValue('templateType', items);
      }}
      value={formik.values.templateType}
      />
      {/* <CustomInput 
      labelText="Type"
      type="text"
      error={formik.touched.templateType && Boolean(formik.errors.templateType)}
      formControlProps={{
        fullWidth: true,
      }}
      inputProps={{
        value: formik.values.templateType,
        onBlur: formik.handleBlur('templateType'),
        onChange: formik.handleChange('templateType'),
      }}
      /> */}
      <CustomInput 
      labelText="Montant"
      type="number"
      error={formik.touched.templateAmount && Boolean(formik.errors.templateAmount)}
      formControlProps={{
        fullWidth: true,
      }}
      inputProps={{
        value: formik.values.templateAmount,
        onBlur: formik.handleBlur('templateAmount'),
        onChange: formik.handleChange('templateAmount'),
      }}
      />
      <SelectBoxComponent 
      items={DIRECTION.map((element) => ({
        value: element.value,
        label: element.name
      }))}
      inputLabel="Direction List"
      onValueChange={(items) => {
        formik.setFieldValue('templateDirection', items);
      }}
      value={formik.values.templateDirection}
      />
      <Box textAlign="center">
        <Button
        round
        type="submit"
        color="success"
        disabled={!formik.dirty || !formik.isValid || isLoading}
        laoderColor="white"
        onClick={onCreate}
        >Edit</Button>
      </Box>
    </div>
  )
}

EditTemplate.propType = {
  data: PropTypes.any,
  close: PropTypes.any,
  data: PropTypes.func.isRequired,
}