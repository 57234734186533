export const DATA = {
    // actionAt: Date.now(),
    targetId: '',
    admin: {
        _id: '',
        email: '',
        username: ''
    },
    actionType: '',
    target: '',
    loggerDetails: {
        // beforeValue: '',
        // afterValue: '',
        // beforeBalance: 0,
        // afterBalance: 0,
        // commissionBefore: 0,
        // commissionAfter: 0,
        // amount: 0,
        // oldDateValidate: '',
        // virmentType: '',
    },
    reason: '',
    comment: '',
    description: ''
};

export const QUALITY = {
    // actionAt: Date.now(),
    targetId: '',
    admin: {
        _id: '',
        email: '',
        username: ''
    },
    actionType: '',
    target: '',
    loggerDetails: {
        // beforeValue: '',
        // afterValue: '',
        // beforeBalance: 0,
        // afterBalance: 0,
        // commissionBefore: 0,
        // commissionAfter: 0,
        // amount: 0,
        // oldDateValidate: '',
        // virmentType: '',
    },
    reason: '',
    comment: '',
    description: ''
}