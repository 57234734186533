import React from 'react'
import CustomInput from '../../../components/material-dashboard-pro-react/CustomInput/CustomInput'

export default function StoreDetailsComponent({ order }) {
    return (
        <div>
            {order &&
            <>
                <CustomInput
                    labelText="store name"
                    formControlProps={{
                        fullWidth: true,
                    }}
                    type="text"
                    inputProps={{
                        value: order?.store?.name,
                    }}
                    disabled={true}
                />
                <CustomInput
                    labelText="phone"
                    formControlProps={{
                        fullWidth: true,
                    }}
                    type="text"
                    inputProps={{
                        value: order?.store?.phone,
                    }}
                    disabled={true}
                />
                <CustomInput
                    labelText="contact"
                    formControlProps={{
                        fullWidth: true,
                    }}
                    type="text"
                    inputProps={{
                        value: order?.store?.contact,
                    }}
                    disabled={true}
                />
                </>
            }
            

        </div>
    )
}
