import axios from 'axios';
import io from 'socket.io-client';
import {
  backendServerConfig,
  backendSocketConfig,
  SOCKET_URL,
  MICROSERVICE_BASE_URL,
} from '../../../config/backendServer.config';

export function getApprouvedProvider(
  page,
  limit,
  cityId,
  txt,
  deliveryType = '',
) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.DELIVERY_MAN}/deliveryMan?page=${page || 0
    }&limit=${limit || 5}&paged=true&approved=true&cityId=${cityId || ''
    }&search_text=${txt || ''}&delivery_type=${JSON.stringify(
      deliveryType || '',
    )}`,
  );
}
export function getProviderDetails(page, limit, cityId, txt) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.DELIVERY_MAN_PAYMENT}/delivery-man?page=${page || 0
    }&limit=${limit || 5}&paged=true&approved=true&cityId=${cityId || ''
    }&search_text=${txt || ''}`,
  );
}
export function getDeliveryMenDetails(page, limit, cityId, approved, txt) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.DELIVERY_MAN_PAYMENT}/delivery-man?page=${page || 0
    }&limit=${limit || 5}&paged=true&approved=${approved}&cityId=${cityId || ''
    }&search_text=${txt || ''}`,
  );
}
export function getUnapprouvedProvider(page, limit, txt, cityId, deliveryType = '',) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.DELIVERY_MAN}/deliveryMan?page=${page || 0
    }&limit=${limit || 5}&paged=true&approved=false&search_text=${txt || ''
    }&cityId=${cityId || ''}&delivery_type=${JSON.stringify(
      deliveryType || '',
    )}`,
  );
}
export function addProvider(payload) {
  return axios.post(`${MICROSERVICE_BASE_URL.AUTH_SERV}/auth/register`, {
    ...payload,
  });
}
export function setProvider(id, payload, type) {
  return axios.put(`${MICROSERVICE_BASE_URL.PROVIDER}/${id}/${type}`, {
    ...payload,
  });
}
export function editProvider(id, payload) {
  return axios.put(`${MICROSERVICE_BASE_URL.DELIVERY_MAN}/${id}/available`, {
    ...payload,
  });
}
export function setCityToProvider(id, cityId) {
  return axios.put(`${MICROSERVICE_BASE_URL.PROVIDER}/${id}/cityId/${cityId}`);
}
export function getBalance(page, limit, deliveryManId, type) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.DELIVERY_MAN_PAYMENT}/balance?page=${page || 0
    }&limit=${limit || 20}&paged=true${deliveryManId && `&deliveryManId=${deliveryManId}`
    }&type=${type}`,
  );
}
export function createMoneyTransfer(data) {
  return axios.post(
    `${MICROSERVICE_BASE_URL.DELIVERY_MAN_PAYMENT}/money-transfer/`,
    data,
  );
}
export function createTransferBalance(data) {
  return axios.post(
    `${MICROSERVICE_BASE_URL.DELIVERY_MAN_PAYMENT}/money-transfer/create_balance_transfer`,
    data,
  );
}
export function createTransferSalaryGeneral(data) {
  return axios.post(
    `${MICROSERVICE_BASE_URL.DELIVERY_MAN_PAYMENT}/money-transfer/create_salary_transfer`,
    data,
  );
}
export function getMoneyTransfer(page, limit, paged, deliveryManId, type) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.DELIVERY_MAN_PAYMENT}/money-transfer?page=${page || 0
    }&limit=${limit || 20}&paged=${paged || true}&deliveryManId=${deliveryManId || ''
    }&type=${type || ''}`,
  );
}

export function getTips(page, limit, paged, deliveryManId, type) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.DELIVERY_MAN_PAYMENT}/tip/?page=${page || 0
    }&limit=${limit || 20}&paged=${paged || true}&deliveryManId=${deliveryManId || ''
    }&type=${type || ''}`,
  );
}
export function validerMoneyTransfer(data) {
  return axios.post(
    `${MICROSERVICE_BASE_URL.DELIVERY_MAN_PAYMENT}/money-transfer/valider`,
    data,
  );
}
export function validerTips(data) {
  return axios.put(
    `${MICROSERVICE_BASE_URL.DELIVERY_MAN_PAYMENT}/tip/valider`,
    data,
  );
}

export function getWorkingCalendar(cityId, day) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.DELIVERY_MAN}/workingCalendar/get_working_calendar?cityId=${cityId}&day=${day}`,
  );
}

export function updateWorkingCalendar(id, data) {
  return axios.put(
    `${MICROSERVICE_BASE_URL.DELIVERY_MAN}/workingCalendar/${id}`,
    data,
  );
}

export function pastWorkingCalendar(data) {
  return axios.post(
    `${MICROSERVICE_BASE_URL.DELIVERY_MAN}/workingCalendar/planning/past`,
    data,
  );
}

export function insertWorkingCalendar(data) {
  return axios.post(
    `${MICROSERVICE_BASE_URL.DELIVERY_MAN}/workingCalendar`,
    data,
  );
}
export function getOrderById(id) {
  return axios.get(`${MICROSERVICE_BASE_URL.DELIVERY_MAN_PAYMENT}/order/${id}`);
}
export function getCommissionHistory(page, limit, paged, txt, type) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.DELIVERY_MAN_PAYMENT}/commission?page=${page || 0
    }&limit=${limit || 20}&paged=${paged || true}${txt && `&deliveryManId=${txt}`
    }&type=${type}`,
  );
}
export function getDeliveryManInCities(cities) {
  return axios.post(
    `${MICROSERVICE_BASE_URL.DRIVER}/driver/cities`,
    { cities: cities },
  );
}
export function getlocationsHistoryForDeliverMan(
  page,
  limit,
  paged,
  deliveryManId,
  date,
) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.DELIVERY_MAN}/${deliveryManId}/locations?page=${page || 0
    }&limit=${limit || 20}&paged=${paged || true}&date=${date}`,
  );
}
export function getlocationsHistoryForDeliverManApi(
  page,
  limit,
  paged,
  deliveryManId,
) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.DELIVERY_MAN}/${deliveryManId}/locations?page=${page || 0
    }&limit=${limit || 20}&paged=${paged || true}`,
  );
}
// for logger audit
export function logProvider(body) {
  return axios.post(`${MICROSERVICE_BASE_URL.ADMIN}/logger`, { ...body });
}
export function getTransportations(body) {
  return axios.get(`${MICROSERVICE_BASE_URL.DRIVER}/transportations`);
}
// ******************* part 2 **********************


export function getCurrentOrders(cityId) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.DRIVER}/ride/city/${cityId}/current-orders`,
  );
}
export function validateOrder(orderId, adminId) {
  return axios.put(
    `${MICROSERVICE_BASE_URL.DRIVER}/ride/${orderId}/validate`,
    { ...adminId },
  );
}
export function getHistoryOrders(
  skip,
  limit,
  paged,
  txt,
  searchBy,
  startDate,
  endDate,
  useDate,
  shortId,
) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.DRIVER}/ride/completed?page=${skip || 0}&limit=${limit || 5
    }&paged=${paged || false}&searchText=${txt || ''}&searchBy=${searchBy || ''
    }${shortId && `&shortId=${shortId || ''}`}&${useDate && `startDate=${startDate || ''}&endDate=${endDate || ''}`
    }`,
  );
}
export function getDeliveryMan(skip, limit, paged) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.DELIVERY_MAN}/deliveryMan?page=${skip || 0
    }&limit=${limit || 0}&paged=${paged || false}`,
  );
}

export function getCities(skip, limit, paged) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.ADMIN}/city?page=${skip || 0}&limit=${limit || 0
    }&paged=${paged || false}`,
  );
}
export function getOrdersByCities(cities) {
  return axios.post(
    `${MICROSERVICE_BASE_URL.DRIVER}/ride/city/current-orders`,
    { cities: cities },
  );
}
export function getDeliveryManByCities(cities) {
  return axios.post(`${MICROSERVICE_BASE_URL.DISPATCHING}/delivery-man/cities`, {
    cities: cities,
  });
}
export function acceptOrRejectOrder(rideId, data) {
  // const reduxState = store.getState();
  // data.admin =reduxState.authReducer.userData ? reduxState.authReducer.userData.user : null;
  return axios.put(
    `${MICROSERVICE_BASE_URL.DRIVER}/ride/${rideId}/accept`,
    data,
  );
}

export function updateOrderStatus(orderId, data) {
  return axios.put(
    `${MICROSERVICE_BASE_URL.DRIVER}/ride/${orderId}/status`,
    { ...data, byAdmin: true },
  );
}
export function cancelOrder(orderId, data) {
  return axios.put(
    `${MICROSERVICE_BASE_URL.DRIVER}/ride/${orderId}/cancel`,
    data,
  );
}
export function addComment(orderId, data) {
  return axios.put(
    `${MICROSERVICE_BASE_URL.DRIVER}/ride/${orderId}/comment`,
    data,
  );
}

// start socket
export function socketConnect(id) {
  return io(backendSocketConfig.appUrl, {
    path: '/api/v1/sockets/connect',
    query: { token: 'Bearer ' + id },
    transports: ['websocket'],
  });
}
export function socketDriverConnect(id) {
  return io(backendSocketConfig.socketsDriver, {
    path: '/api/v1/socketsdriver/connect',
    query: { token: 'Bearer ' + id },
    transports: ['websocket'],
  });
}
export function socketPartnerConnect(id) {
  return io(backendSocketConfig.appUrl, {
    path: '/api/v1/socketspartners/connect',
    query: { token: 'Bearer ' + id },
    transports: ['websocket'],
  });
}
export function socketGpsSdConnect(id) {
  console.log('her', id);
  return io(backendSocketConfig.socketGpsSd, {
    path: '/api/v1/sockets-gps-sd/connect',
    query: { token: 'Bearer ccc4d35706c9bab63815f38bf78e4a22246bcedc' },
    // query: { token: 'Bearer ' + id },
    transports: ['websocket'],
  });
}
export function socketGpsUpdateConnect(id) {
  console.log('her', id);
  return io(backendSocketConfig.appUrl, {
    path: '/api/v1/sockets-gps-update/connect',
    query: { token: 'Bearer ccc4d35706c9bab63815f38bf78e4a22246bcedc' },
    // query: { token: 'Bearer ' + id },
    transports: ['websocket'],
  });
}

export function getCustomerAddresses(customerId) {
  return axios.get(`${MICROSERVICE_BASE_URL.CUSTOMER}/${customerId}/address`);
}
export function editCustomerAddresses(customerId, addressId, body) {
  return axios.put(
    `${MICROSERVICE_BASE_URL.CUSTOMER}/${customerId}/address/${addressId}`,
    body,
  );
}
export function getDispatchingStatus() {
  return axios.get(`${MICROSERVICE_BASE_URL.DISPATCHING}/dispatching-state`);
}
export function getDeliveryManDispatchedById(id) {
  return axios.get(`${MICROSERVICE_BASE_URL.DISPATCHING}//delivery-man/${id}`);
}
export function getOrderRating(skip, limit) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.ORDERS}/order-rating?page=${skip || 0}&limit=${limit || 0
    }&paged=true`,
  );
}
export function addCommentInOrderRating(orderId, payload) {
  return axios.put(
    `${MICROSERVICE_BASE_URL.ORDERS}/order-rating/valid/${orderId}`,
    payload,
  );
}
export function getAlarms(skip, limit, deliveryManId, shortId, name) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.DISPATCHING}/alarm/get_alarms?page=${skip || 0
    }&limit=${limit || 0}&paged=true&deliveryManId=${deliveryManId || ''
    }&name=${name || ''}&shortId=${shortId || ''}`,
  );
}
export function updateAlarm(alarmId, data) {
  return axios.put(
    `${MICROSERVICE_BASE_URL.DISPATCHING}/alarm/${alarmId}`,
    data,
  );
}
export function getAlarmConfig() {
  return axios.get(`${MICROSERVICE_BASE_URL.DISPATCHING}/alarm-config`);
}

export function updateAlarmConfig(data) {
  return axios.put(`${MICROSERVICE_BASE_URL.DISPATCHING}/alarm-config`, data);
}
export function citiesPlaningByDay(day) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.DELIVERY_MAN}/workingcalendar/citiesplanning?day=${day}`,
  );
}
// for logger audit
export function logOrder(body) {
  return axios.post(`${MICROSERVICE_BASE_URL.ADMIN}/logger`, { ...body });
}

// for non qualities :

// get data for none qualities :
export function getTemplateNonQualities(page, limit, paged) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.ADMIN}/template-non-quality?page=${page || 0
    }&limit=${limit || 0}&paged=${paged || false}`,
  );
}
// get single template by ID :
export function getSingleTemplate(id, limit, paged) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.ADMIN}/template-non-quality?limit=${limit || 0
    }&templateId=${id}&paged=${paged || false}`,
  );
}

// post new non quality :
export function createNonQuality(body) {
  return axios.post(`${MICROSERVICE_BASE_URL.ADMIN}/non-quality`, { ...body });
}

// edit on customer kaalixUP :
export function editKaalixUp(body) {
  return axios.post(`${MICROSERVICE_BASE_URL.CUSTOMER_PAYMENT}/kaalixup`, {
    ...body,
  });
}

// edit on delivryMan balance :
export function createTransferSalaryFromQuality(data) {
  return axios.post(
    `${MICROSERVICE_BASE_URL.DELIVERY_MAN_PAYMENT}/money-transfer/create_salary_transfer_from_quality`,
    data,
  );
}
export function getStores(searchText) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.STORES
    }/store?page=0&limit=50&paged=true&searchText=${searchText || ''}`,
  );
}
export function getOrders(storeId, startDate, endDate) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.ORDERS}/order/completedByStore?storeId=${storeId || ''
    }&startDate=${startDate || ''}&endDate=${endDate || ''}`,
  );
}
export function refreshRedis() {
  return axios.get(`${MICROSERVICE_BASE_URL.DISPATCHING}/redis/refresh`);
}

export function getAlarmsCurrentOrders(cities) {
  return axios.post(
    `${MICROSERVICE_BASE_URL.DISPATCHING}/alarm/alarms_current_orders`,
    { cities: cities },
  );
}
export function getDeliveryManInCity(cityId, meansOfTransportation) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.DRIVER}/driver/city/${cityId}?transportType=${meansOfTransportation || ''}`,
  );
}
export function driverConfig(cityId) {
  return axios.get(`${MICROSERVICE_BASE_URL.CUSTOMER_PAYMENT}/driver-config/city/${cityId}`);
}
export function allDriverConfig() {
  return axios.get(`${MICROSERVICE_BASE_URL.CUSTOMER_PAYMENT}/driver-config/`);
}
export function setDriverConfig(payload) {
  return axios.post(`${MICROSERVICE_BASE_URL.CUSTOMER_PAYMENT}/driver-config`, { transportations: payload });
}
export function createDriverConfigPrice(payload) {
  return axios.post(`${MICROSERVICE_BASE_URL.CUSTOMER_PAYMENT}/driver-config`, payload);
}
export function updateDriverConfigPrice(configId, payload) {
  return axios.post(`${MICROSERVICE_BASE_URL.CUSTOMER_PAYMENT}/driver-config?configId=${configId}`, payload);
}



