export const commissionTypes = [
  {
    label: 'Monthly',
    value: 'MONTHLY',
  },
  {
    label: 'Source',
    value: 'SOURCE',
  },
];
export const deliveryManPayStoreTypes = [
  {
    label: 'Always Pay',
    value: 'AlwaysPay',
  },
  {
    label: 'Never Pay',
    value: 'NeverPay',
  },
  {
    label: 'Pay If Cash',
    value: 'PayIfCash',
  },
  {
    label: 'Card',
    value: 'Card',
  },
];
export const Types = [
  {
    label: 'Product_Category-Product-Item',
    value: 'Product_Category-Product-Item',
  },
  {
    label: 'Product_Category-Item',
    value: 'Product_Category-Item',
  },
  {
    label: 'Product-Item',
    value: 'Product-Item',
  },
  {
    label: 'Item',
    value: 'Item',
  },
];
export const ClassTypes = [
  {
    label: 'High',
    value: 'High',
  },
  {
    label: 'Medium',
    value: 'Medium',
  },
  {
    label: 'Low',
    value: 'Low',
  },
];
export const deliveredByTypes = [
  {
    label: 'only kaalix',
    value: 'only kaalix',
  },
  {
    label: 'only livry',
    value: 'only livry',
  },
  {
    label: 'kaalix & livry',
    value: 'kaalix & livry',
  },
];
export const PrimaryTextes = [
  { value: 'Discount', label: 'Discount' },
  { value: 'DiscountUpTo', label: 'DiscountUpTo' },
  { value: 'OfferedAboveMinCartPrice', label: 'OfferedAboveMinCartPrice' },
  { value: 'OneBoughtOneOffered', label: 'OneBoughtOneOffered' },
  { value: 'FreeDelivery', label: 'FreeDelivery' },
  { value: '', label: 'none' },
]
export const deliveryManPayStoreStatus = {
  AlwaysPay: 'AlwaysPay',
  NeverPay: 'NeverPay',
  PayIfCash: 'PayIfCash',
}
export const searchTypes = {
  All: 'all',
  searchByName: 'searchByName',
}
