import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useAlert } from 'react-alert';

import ImageUpload from '../../../components/material-dashboard-pro-react/CustomUpload/ImageUpload';
import CustomInput from '../../../components/material-dashboard-pro-react/CustomInput/CustomInput';
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import SelectBoxComponent from '../../../components/SelectBox.component';
import SwitchComponent from '../../../components/Switch.component';

import { deleteTemplateNonQualities } from '../services/serverApi.service';

const validationSchema = yup.object({
  templateName: yup.string().required(),
})

export default function DeleteTemplate({ data, close, onItemEdited }) {

  const [isLoading, setIsLoading] = useState(false);
  const alert = useAlert();
  const deleteItem = () => {
    setIsLoading(true);
    deleteTemplateNonQualities(data._id)
      .then(() => {
        alert.show('Template has been deleted', {
          type: 'success',
        });
        onItemEdited();
        close();
      })
      .catch((error) => {
        alert.show(error?.response?.data?.errors[0]?.message, {
          type: 'error',
        });
      })
      .finally(() => {
        setIsLoading(false);
      })
  }

  return(
    <div>
      <div>Are you sure do delete this item ?</div>
      {data && data.templateName}
      <br />
      <div style={{float: 'right',margin:'15px'}}>
        <Button onClick={close}>Cancel</Button >
        <Button onClick={deleteItem} color="success" loading={isLoading} disabled={isLoading}>
          Yes
        </Button>
      </div>
    </div>
  )
}