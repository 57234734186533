
const scope = 'OrdersPages'
export const GET_ORDER_REQUEST = `${scope}/GET_ORDER_REQUEST`;
export const GET_ORDER_SUCCESS = `${scope}/GET_ORDER_SUCCESS`;
export const GET_ORDER_FAIL = `${scope}/GET_ORDER_FAIL`;
export const CHANGE_ORDER_STATUS = `${scope}/CHANGE_ORDER_STATUS`;
export const CHANGE_ORDER_ALARMS = `${scope}/CHANGE_ORDER_ALARMS`;
export const CHANGE_ORDER_ALARMS_DATA = `${scope}/CHANGE_ORDER_ALARMS_DATA`;
export const CHANGE_ORDER_ALARM = `${scope}/CHANGE_ORDER_ALARM`;
export const SET_ORDER_ALARMS = `${scope}/SET_ORDER_ALARMS`;
export const ACCEPTED_ORDER = `${scope}/ACCEPTED_ORDER`;
export const CANCEL_ORDER = `${scope}/CANCEL_ORDER`;
export const NEW_ORDER = `${scope}/NEW_ORDER`;
export const VALIDATED_ORDER = `${scope}/VALIDATED_ORDER`;
export const INITIAL_STATE = `${scope}/INITIAL_STATE`;
export const OPEN_MODEL = `${scope}/OPEN_MODEL`;
export const CLOSE_MODEL = `${scope}/CLOSE_MODEL`;
// ************************** V2 ***************************   

export const GET_ORDER_REQUESTV2 = `${scope}/GET_ORDER_REQUESTV2`;
export const GET_ORDER_SUCCESSV2 = `${scope}/GET_ORDER_SUCCESSV2`;
export const GET_ORDER_FAILV2 = `${scope}/GET_ORDER_FAIV2`;
export const CHANGE_ORDER_STATUSV2 = `${scope}/CHANGE_ORDER_STATUSV2`;
export const CANCEL_ORDERV2 = `${scope}/CANCEL_ORDERV2`;
export const ACCEPTED_ORDERV2 = `${scope}/ACCEPTED_ORDERV2`;
export const NEW_ORDERV2 = `${scope}/NEW_ORDERV2`;
export const VALIDATED_ORDERV2 = `${scope}/VALIDATED_ORDERV2`;
