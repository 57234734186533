import React, { useEffect, useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import {
  useFormik,
  ErrorMessage,
  Field,
  FieldArray,
  Form,
  Formik,
} from 'formik';
import { Box, Grid } from '@material-ui/core';
import CustomInput from '../../../components/material-dashboard-pro-react/CustomInput/CustomInput';
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';

export default function EditeAdvantageComponent({
  item,
  close,
  allData,
  index,
  setKaalixUpConfig,
  saveChanges,
}) {
  const [change, setChange] = useState(false);
  const addNewAdvantage = () => {
    item.advantages.push({ title: '', description: '' });
    setChange(!change);
  };
  const deleteAdvantage = (i) => {
    console.log(i);
    item.advantages.splice(i, 1);
    setChange(!change);
  };
  useEffect(() => {}, [change]);
  return (
    <div>
      <Grid container spacing={3}>
        {item.advantages.length > 0 &&
          item.advantages.map((element, i) => (
            <Grid item xs={3}>
              <div
                style={{
                  border: '1px solid #000',
                  borderRadius: '10px',
                  padding: '10px',
                  marginBottom: '5px',
                }}
              >
                <CustomInput
                  labelText="titel"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    defaultValue: element.title,
                    onChange: (e) => {
                      allData.statusList[index].advantages[i].title =
                        e.target.value;
                      setKaalixUpConfig(allData);
                    },
                  }}
                  type="text"
                  name="name"
                />
                <CustomInput
                  labelText="description"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    defaultValue: element.description,
                    onChange: (e) => {
                      allData.statusList[index].advantages[i].description =
                        e.target.value;
                      setKaalixUpConfig(allData);
                    },
                  }}
                  type="text"
                  name="name"
                />
                <Box textAlign="right">
                  <Button
                    round
                    size="sm"
                    type="submit"
                    color="google"
                    // disabled={isLoading}
                    // loading={isLoading}
                    loaderColor="white"
                    onClick={() => deleteAdvantage(i)}
                  >
                    delete
                  </Button>
                </Box>
              </div>
            </Grid>
          ))}
        <Grid container>
          <Grid item xs={12}>
            <Box textAlign="left">
              <Button
                round
                type="submit"
                color="success"
                // disabled={isLoading}
                // loading={isLoading}
                loaderColor="white"
                onClick={addNewAdvantage}
              >
                <AddIcon />
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12}>
            {allData.statusList[index].advantages.length > 0 && (
              <Box textAlign="right">
                <Button
                  round
                  type="submit"
                  color="success"
                  // disabled={isLoading}
                  // loading={isLoading}
                  loaderColor="white"
                  onClick={saveChanges}
                >
                  save
                </Button>
              </Box>
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
