import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// compon
import GridContainer from '../../../components/material-dashboard-pro-react/Grid/GridContainer';
import Card from '../../../components/material-dashboard-pro-react/Card/Card';
import CardHeader from '../../../components/material-dashboard-pro-react/Card/CardHeader';
import CardBody from '../../../components/material-dashboard-pro-react/Card/CardBody';
// mat component
import SelectBoxComponent from '../../../components/SelectBox.component';
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';

// mat apis
import {
  Grid,
  FormControl,
  Checkbox,
  FormControlLabel,
  Box,
} from '@mui/material';
import MapIcon from '@mui/icons-material/Map';

// call api
import { getCities } from '../../orders/services/serverApi.service';
import { getParners } from '../../AllPatners/services/server.api.service';

// Actions
import {
  defaultSelectedCities,
  selectCityAction,
  selectWareHouseAction,
} from '../redux/actions/filter.actions';
import { openModelAction } from '../../orders/redux/actions/order.actions';
import { allPartnersfilterSelector } from '../redux/selectors';
import { allArray } from '../mock';

export default function DelivriesFilterComponent() {
  const dispatch = useDispatch();
  const {
    selectedCities,
    filterChangeCounter,
    error,
    loading,
    selectedWarehouse,
  } = useSelector(allPartnersfilterSelector);
  const [cities, setCities] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [organisations, setOrganisations] = useState([]);
  const [organisationsSelected, setOrganisationsSelected] = useState([]);

  const getCitiesList = () => {
    getCities()
      .then(({ data }) => {
        const cityArray = [{ name: 'All', _id: 'all' }].concat(data);
        setCities(cityArray);
      })
      .catch((error) => console.log(error));
  };
  const getWarehouses = () => {
    getParners(0, 10)
      .then(({ data }) => {
        setOrganisations(data.organisations);
      })
      .catch(({ error }) => console.log(error));
  };
  const onChangeSelectBox = (items) => {
    if (
      items.length > 1 &&
      items.includes('all') &&
      selectedCities.includes('all')
    ) {
      const index = items.indexOf('all');
      index >= 0 && items.splice(index, 1);
    } else if (
      items.length &&
      items.includes('all') &&
      !selectedCities.includes('all')
    ) {
      items = allArray;
    }
    dispatch(selectCityAction(items));
  };
  const organisationChanges = (items) => {
    setOrganisationsSelected(items);
    const tempWareHouses = [];
    items.length > 0 &&
      items.map((item) => {
        tempWareHouses.push(
          ...organisations.find((x) => x._id == item)?.warehouses,
        );
      });
    setWarehouses(tempWareHouses);
  };

  useEffect(() => {
    getCitiesList();
    getWarehouses();
  }, []);
  useEffect(() => {
    cities && cities.length > 0 && dispatch(defaultSelectedCities());
  }, [cities]);
  return (
    <div style={{ marginTop: -50 }}>
      <GridContainer>
        <Card>
          <CardBody>
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <FormControl style={{ minWidth: '200px' }}>
                  <SelectBoxComponent
                    items={(cities || []).map((city) => ({
                      value: city._id,
                      label: city.name,
                    }))}
                    inputLabel="cities"
                    onValueChange={(items) => onChangeSelectBox(items)}
                    value={selectedCities}
                    multiple
                  />
                </FormControl>
              </Grid>
              <Grid item xs={5}>
                <FormControl style={{ minWidth: '200px' }}>
                  <SelectBoxComponent
                    items={(organisations || []).map((organisation) => ({
                      value: organisation._id,
                      label: organisation.name,
                    }))}
                    inputLabel="organisation"
                    onValueChange={(items) => organisationChanges(items)}
                    value={organisationsSelected}
                    multiple
                  />
                </FormControl>
              </Grid>
              <Grid item xs={2}>
                <Box textAlign="center">
                  <Button
                    size="sm"
                    round
                    type="submit"
                    color="success"
                    loaderColor="white"
                    onClick={() =>
                      dispatch(openModelAction('', '', 'trackingDeliveryMen'))
                    }
                  >
                    <MapIcon />
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={12}>
                {(warehouses || []).map((item, index) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{
                          '& .MuiSvgIcon-root': {
                            fontSize: 20,
                          },
                        }}
                        color="primary"
                        checked={selectedWarehouse.includes(
                          item.wareHouseLabel,
                        )}
                        onChange={(e) =>
                          dispatch(
                            selectWareHouseAction(
                              e.target.checked,
                              item.wareHouseLabel,
                            ),
                          )
                        }
                      />
                    }
                    label={item.wareHouseLabel?.toLowerCase()}
                  />
                ))}
              </Grid>
            </Grid>
          </CardBody>
        </Card>
      </GridContainer>
    </div>
  );
}
