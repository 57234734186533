import React, { useState, useEffect } from 'react';
// import { useSelector, useDispatch } from 'react-redux';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useAlert } from 'react-alert';

import CustomInput from '../../../components/material-dashboard-pro-react/CustomInput/CustomInput';
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import SelectBoxComponent from '../../../components/SelectBox.component';
import SwitchComponent from '../../../components/Switch.component';
import GridContainer from '../../../components/material-dashboard-pro-react/Grid/GridContainer';
import Card from '../../../components/material-dashboard-pro-react/Card/Card';
import CardHeader from '../../../components/material-dashboard-pro-react/Card/CardHeader';
import CardBody from '../../../components/material-dashboard-pro-react/Card/CardBody';
import { getCities } from '../../delivery-info/services/serverApi.service';
import {
  addProvider,
  setCityToProvider,
  createDeliveryMan,
} from '../service/serverAPI.service';
import { deliveryManTypes } from '../mock';

const validationSchema = yup.object({
  username: yup.string().required(),
  email: yup.string().email().required(),
  password: yup
    .string()
    .min(6, 'password" length must be at least 6 characters long')
    .required(),
  // phone: yup.string().matches(/(?=.*(6|7))[0-9]{9}/, 'Invalid phone format').required(),
  deliveryType: yup.string().required(),
  // phone: yup
  //   .string()
  //   .matches(/^[6||7][0-9]{8}$/, 'Invalid phone format')
  //   .required(),
  phone: yup
    .string()
    .when('deliveryType', {
      is: 'Food',
      then: yup.string().matches(
        /^[67][0-9]{8}$/,
        // /^0[67][0-9]{8}$/,
        'Invalid phone format for deliveryMan',
      ),
      otherwise: yup
        .string()
        .matches(
          /^0[67][0-9]{8}$/,
          'Invalid phone format for Partner deliveryMan',
        ),
    })
    .required(),
  cityId: yup.string().required(),
  entityType: yup.string(),
});

export default function AddProvider() {
  const alert = useAlert();
  const [isLoading, setIsLoading] = useState(false);
  const [cities, setCities] = useState([]);
  const formik = useFormik({
    initialValues: {
      username: '',
      email: '',
      password: '',
      entityType: 'deliveryMan',
      deliveryType: '',
      approved: false,
      cityId: '',
      phone: '',
    },
    validationSchema,
  });

  const onCreate = () => {
    setIsLoading(true);
    console.log('formik.values', formik.values);
    createDeliveryMan(formik.values)
      .then(({ data }) => {
        alert.show('provider has been created', {
          type: 'success',
        });
        formik.resetForm();
      })
      .catch((error) => {
        alert.show(error?.response?.data?.errors[0].message || '', {
          type: 'error',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
    // Object.values(formik.values).every((x) => x != '')
    //   ? console.log('formik.values', formik.values)
    //   : // addProvider(formik.values)
    //     //     .then(({ data }) => {
    //     //       setCityToProvider(data.user.entityId, formik.values.city)
    //     //         .then(() => {
    //     //           alert.show('provider has been created', {
    //     //             type: 'success',
    //     //           });
    //     //           formik.resetForm();
    //     //         })
    //     //         .catch((error) => console.log(error));
    //     //     })
    //     //     .catch((error) => {
    //     //       alert.show(error?.response?.data?.errors[0].message, {
    //     //         type: 'error',
    //     //       });
    //     //     })
    //     //     .finally(() => {
    //     //       setIsLoading(false);
    //     //     })
    setIsLoading(false);
  };
  const getCity = () => {
    getCities()
      .then(({ data }) => {
        setCities(data);
      })
      .catch(() =>
        alert.show(error.message, {
          type: 'error',
        }),
      );
  };
  useEffect(() => {
    getCity();
  }, []);
  const [p, setP] = useState([]);
  // const ok = () => {
  //   let xx = JSON.parse(p);
  //   xx.forEach((i, index) => {
  //     let data = {
  //       city: formik.values.city,
  //       email: 'marjan_' + i.email,
  //       entityType: 'deliveryMan',
  //       password: '123456',
  //       phone: i.phone,
  //       username: i.first_name + ' ' + i.last_name,
  //     };
  //     setTimeout(() => {
  //       onCreateV2(data);
  //     }, 1000 * index);
  //   });
  //   // console.log(p)
  // };
  // const onCreateV2 = (p) => {
  //   addProvider(p)
  //     .then(({ data }) => {
  //       console.log('asa', data);
  //       setTimeout(() => {
  //         setCityToProvider(data.user.entityId, p.city)
  //           .then(() => {
  //             console.log('provider has been created');
  //           })
  //           .catch((error) => console.log(error));
  //       }, 4000);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     })
  //     .finally(() => {});
  // };
  return (
    <GridContainer>
      <Card>
        <CardHeader>create Provider</CardHeader>
        <CardBody>
          <div>
            <CustomInput
              labelText="User Name"
              formControlProps={{
                fullWidth: true,
              }}
              type="text"
              error={formik.touched.username && Boolean(formik.errors.username)}
              helperText={formik.errors.username}
              inputProps={{
                value: formik.values.username,
                onBlur: formik.handleBlur('username'),
                onChange: formik.handleChange('username'),
              }}
            />
            <CustomInput
              labelText="Email"
              formControlProps={{
                fullWidth: true,
              }}
              type="text"
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.errors.email}
              inputProps={{
                value: formik.values.email,
                onBlur: formik.handleBlur('email'),
                onChange: formik.handleChange('email'),
              }}
            />
            <CustomInput
              labelText="password"
              formControlProps={{
                fullWidth: true,
              }}
              type="password"
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.errors.password}
              inputProps={{
                value: formik.values.password,
                onBlur: formik.handleBlur('password'),
                onChange: formik.handleChange('password'),
              }}
            />
            <CustomInput
              labelText="phone"
              formControlProps={{
                fullWidth: true,
              }}
              type="text"
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={formik.errors.phone}
              inputProps={{
                value: formik.values.phone,
                onBlur: formik.handleBlur('phone'),
                onChange: formik.handleChange('phone'),
              }}
            />
            <SelectBoxComponent
              helperText={formik.errors.cityId}
              items={cities.map((city) => ({
                value: city._id,
                label: city.name,
              }))}
              inputLabel="City"
              onValueChange={formik.handleChange('cityId')}
              value={formik.values.cityId}
            />
            <SelectBoxComponent
              helperText={formik.errors.deliveryType}
              items={deliveryManTypes}
              inputLabel="Delivery Type"
              onValueChange={formik.handleChange('deliveryType')}
              value={formik.values.deliveryType}
            />
            <SwitchComponent
              // onChange={(value) => {
              //   // updateProvider(item._id, { approved: value }, 'approve');
              //   // deliveryManLock(value, item);
              //   formik.setValues('approved', value);
              //   console.log(value);
              // }}
              value="checked"
              label="approved"
              onChange={(value) => formik.setFieldValue('approved', value)}
              checked={formik.values.approved}
              error={formik.touched.approved && Boolean(formik.errors.approved)}
            />
          </div>
          <Box textAlign="center">
            <Button
              round
              type="submit"
              color="success"
              disabled={!formik.dirty || !formik.isValid || isLoading}
              loading={isLoading}
              loaderColor="white"
              onClick={onCreate}
            >
              Create
            </Button>
          </Box>
        </CardBody>
      </Card>
      {/*<div className={'w-100'}>*/}
      {/*  <div className="form-group w-100">*/}
      {/*    <label htmlFor="exampleFormControlTextarea1">Example textarea</label>*/}
      {/*    <textarea*/}
      {/*      className="form-control"*/}
      {/*      id="exampleFormControlTextarea1"*/}
      {/*      rows="10"*/}
      {/*      onChange={(t) => setP(t.target.value)}*/}
      {/*    />*/}
      {/*    <button type="submit" onClick={ok}>*/}
      {/*      ok*/}
      {/*    </button>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </GridContainer>
  );
}
