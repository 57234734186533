/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import {
  useTable,
  useFilters,
  useAsyncDebounce,
  useSortBy,
  usePagination,
} from 'react-table';
import classnames from 'classnames';
// A great library for fuzzy filtering/sorting items
import { matchSorter } from 'match-sorter';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Switch from '@material-ui/core/Switch';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
// core components
import CustomInput from '../CustomInput/CustomInput.js';
import GridContainer from '../Grid/GridContainer.js';
import GridItem from '../Grid/GridItem.js';

import styles from '../../../assets/jss/material-dashboard-pro-react/customSelectStyle';

const newStyles = {
  ...styles,
  formControlMargins: {
    margin: '3px 0 !important',
  },
  gridContainer: {
    justifyContent: 'center',
  },
  thead: {
    display: 'inline-table !important',
    minWidth: '500px',
  },
  tbody: {
    overflow: 'hidden',
  },
  table: {
    display: 'inline-table !important',
    width: '180px',
  },
  td: {
    minWidth: '180px',
    // margin:0
  },
  ReactTable: {
    overflowX: 'scroll',
  },
};

const useStyles = makeStyles(newStyles);

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <CustomInput
      formControlProps={{
        fullWidth: true,
      }}
      inputProps={{
        value: filterValue || '',
        onChange: (e) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        },
        // placeholder: `Search ${count} records...`,
      }}
    />
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

// Our table component
function Table({
  isPaginate,
  columns,
  data,
  total,
  onPaginate,
  customColumnStyle,
  page,
  numberOfRowsProps,
  subPagination,
}) {
  // const [numberOfRows, setNumberOfRows] = React.useState(
  //   numberOfRowsProps ? numberOfRowsProps[0] : [],
  // );

  const [is_Paginate, setIsPaginate] = useState(isPaginate);
  const classes = useStyles();
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    [],
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    [],
  );
  const handlePaginate = () => {
    onPaginate({ pageSelect: pageSelect, numberOfRows: numberOfRows });
  };
  const [pageSelect, setPageSelect] = useState(page - 1 || 0);
  const [numberOfRows, setNumberOfRows] = useState(numberOfRowsProps || 20);
  const handleChangePage = (event) => {
    setPageSelect(event.target.value);
  };

  const handleChangeRowsPerPage = (event) => {
    // console.log('handleChangeRowsPerPage');
    setNumberOfRows(parseInt(event.target.value, 10));
    setPageSelect(0);
  };
  const nextPage = () => {
    if (pageSelect + 1 < Math.ceil(total / numberOfRows)) {
      setPageSelect(pageSelect + 1);
    }
  };
  const previousPage = () => {
    if (pageSelect - 1 > -1) {
      setPageSelect(pageSelect - 1);
    }
  };
  // const customColumnStyle = {
  //   // wordWrap: "break-word",
  //   maxWidth: "100px",
  //   minWidth: "100px",
  // };
  useEffect(() => {
    handlePaginate();
  }, [pageSelect, numberOfRows]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    visibleColumns,
    pageOptions,
    pageCount,
    setPageSize,
    gotoPage,
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      //initialState: { pageSize: 10, pageIndex: 0 },
    },
    useFilters, // useFilters!
    useSortBy,
    //usePagination,
  );

  // We don't want to render all of the rows for this example, so cap
  // it for this use case
  // const firstPageRows = rows.slice(0, 10);
  // let pageSelectData = Array.apply(null, Array(pageOptions.length)).map(
  //   function () {},
  // );
  const [numberOfRowsData, setNumberOfRowsData] = useState([20, 50, 100]);
  // useEffect(() => {
  //   if(total>2000){
  //     setNumberOfRowsData([100, 500, 1000]);
  //   }
  //   // } else{
  //   //   setNumberOfRowsData([20, 25, 50, 100]);
  //   // }
  // },[total])

  // const [numberOfRowsData,setNumberOfRowData]= useState(numberOfRowsProps)
  const styleObjectMerge = (color, custCol) => {
    return {
      ...color,
      ...custCol,
    };
  };
  return (
    <>
      <div className={'ReactTable -striped -highlight ' + classes.ReactTable}>
        {!!is_Paginate && (
          <div className="pagination-top">
            <div className="-pagination">
              <div className="-previous">
                <button
                  type="button"
                  onClick={() => previousPage()}
                  disabled={!(pageSelect - 1 > -1)}
                  className="-btn"
                >
                  Previous
                </button>
              </div>
              <div className="-center">
                <GridContainer className={classes.gridContainer}>
                  <GridItem xs={12} sm={6} md={4}>
                    <FormControl
                      fullWidth
                      className={
                        classes.selectFormControl +
                        ' ' +
                        classes.formControlMargins
                      }
                    >
                      <Select
                        MenuProps={{
                          className: classes.selectMenu,
                        }}
                        classes={{
                          select: classes.select,
                        }}
                        value={numberOfRows}
                        onChange={handleChangeRowsPerPage}
                        inputProps={{
                          name: 'numberOfRows',
                          id: 'number-of-rows',
                        }}
                      >
                        {numberOfRowsData &&
                          numberOfRowsData.length > 0 &&
                          numberOfRowsData.map((prop) => {
                            return (
                              <MenuItem
                                key={prop}
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                value={prop}
                              >
                                {prop} rows
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={4}>
                    <FormControl
                      fullWidth
                      className={
                        classes.selectFormControl +
                        ' ' +
                        classes.formControlMargins
                      }
                    >
                      <Select
                        MenuProps={{
                          className: classes.selectMenu,
                        }}
                        classes={{
                          select: classes.select,
                        }}
                        value={page ? Number(page) - 1 : pageSelect}
                        onChange={(event) => handleChangePage(event)}
                        inputProps={{
                          name: 'pageSelect',
                          id: 'page-select',
                        }}
                      >
                        {/* {!subPagination&&[...Array(Math.ceil(total / numberOfRows)).keys()].map(
                          (prop, key) => {
                            return (
                              <MenuItem
                                key={key}
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                value={key}
                              >
                                Page {key + 1}
                              </MenuItem>
                            );
                          },
                        )} */}
                        {
                          // Array.apply(null, {length: (total / numberOfRows)}).map((value, index) => (
                          subPagination && subPagination.length > 0
                            ? subPagination.map((value, index) => (
                                <MenuItem
                                  key={value}
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected,
                                  }}
                                  value={value}
                                >
                                  Page {value + 1}
                                </MenuItem>
                              ))
                            : [
                                ...Array(
                                  Math.ceil(total / numberOfRows),
                                ).keys(),
                              ].map((prop, key) => {
                                return (
                                  <MenuItem
                                    key={key}
                                    classes={{
                                      root: classes.selectMenuItem,
                                      selected: classes.selectMenuItemSelected,
                                    }}
                                    value={key}
                                  >
                                    Page {key + 1}
                                  </MenuItem>
                                );
                              })
                        }
                      </Select>
                    </FormControl>
                  </GridItem>
                </GridContainer>
              </div>
              <div className="-next">
                <button
                  type="button"
                  onClick={() => nextPage()}
                  disabled={!(pageSelect + 1 < Math.ceil(total / numberOfRows))}
                  className="-btn"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        )}
        <table {...getTableProps()} className={'rt-table ' + classes.table}>
          <thead className={'rt-thead -header ' + classes.thead}>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} className="rt-tr">
                {headerGroup.headers.map((column, key) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={classnames('rt-th rt-resizable-header', {
                      '-cursor-pointer': headerGroup.headers.length - 1 !== key,
                      '-sort-asc': column.isSorted && !column.isSortedDesc,
                      '-sort-desc': column.isSorted && column.isSortedDesc,
                    })}
                    // style={{ 'min-width': '180px' }}
                    style={
                      column.customWidth
                        ? {
                            maxWidth: column.customWidth,
                            minWidth: column.customWidth,
                            fontSize: '13px',
                          }
                        : customColumnStyle && customColumnStyle
                    }
                  >
                    <div className="rt-resizable-header-content">
                      {column.render('Header')}
                    </div>
                    {/* Render the columns filter UI */}
                    <div>
                      {headerGroup.headers.length - 1 === key
                        ? null
                        : column.canFilter
                        ? column.render('Filter')
                        : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody
            {...getTableBodyProps()}
            className={'rt-tbody ' + classes.tbody}
          >
            {rows.map((row, i) => {
              // console.log('row', row);
              prepareRow(row);
              return (
                <tr
                  key={
                    row.original?._id ||
                    JSON.stringify(row.original).substring(0, 8)
                  }
                  style={{
                    backgroundColor: row.original.backgroundColor || '',
                  }}
                  {...row.getRowProps()}
                  className={classnames(
                    'rt-tr',
                    { ' -odd': i % 2 === 0 },
                    { ' -even': i % 2 === 1 },
                  )}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className={'rt-td ' + classes.td}
                        style={styleObjectMerge(
                          {
                            color: cell.row.original.Rowcolor || '',
                            // backgoundColor: 'red',
                            // backgound: 'red',
                            // backgroundColor: 'blue',
                          },
                          cell.column.customWidth
                            ? {
                                maxWidth: cell.column.customWidth,
                                minWidth: cell.column.customWidth,
                                fontSize: '13px',
                                maxHeight: '90px',
                              }
                            : customColumnStyle
                            ? {
                                ...customColumnStyle,
                                maxHeight: '90px',
                              }
                            : {},
                        )}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="pagination-bottom"></div>
      </div>
    </>
  );
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== 'number';

export default Table;
