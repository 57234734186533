
export function generateRandomString(length) {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function formatTime(time) {
  time = new Date(time)
  const timeFormat = `${time.getDate()}/${(time.getMonth() + 1)}/${time.getFullYear()} (${time.getHours()}:${time.getMinutes()})`
  return timeFormat
}
export function formatTimeWithoutYear(time) {
  time = new Date(time)
  const timeFormat = `${time.getDate()}/${(time.getMonth() + 1)} (${time.getHours()}:${time.getMinutes()})`
  return timeFormat
}
export function formatTimeForOrder(time) {
  time = new Date(time)
  const timeFormat = `${time.getHours()}:${time.getMinutes()}:${time.getSeconds()}(${time.getDate()}/${(time.getMonth() + 1)})`
  return timeFormat
}
export function formatTimeForOrderOnlyTime(time) {
  time = new Date(time)
  const timeFormat = `${time.getHours()}:${time.getMinutes()}:${time.getSeconds()}`
  return timeFormat
}
export function formatTimeForDelivery(time) {
  time = new Date(time)
  const timeFormat = `${time.getDate()}/${(time.getMonth() + 1)}/${(time.getFullYear())}(${time.getHours()}:${time.getMinutes()})`
  return timeFormat
}
export function calcDuration(time) {
  var diff;
  time = new Date(time).getTime()
  const timeNow = new Date().getTime();
  diff = Math.ceil(((timeNow - time) / 1000) / 60)
  return diff
}
export function calcDurationbetweenTwoTimes(time1, time2) {
  var diff;
  time1 = new Date(time1).getTime()
  time2 = new Date(time2).getTime()
  diff = Math.ceil(((time2 - time1) / 1000) / 60)
  return diff
}
export function calcDurationWithSecondbetweenTwoTimes(time1, time2) {
  var diff;
  time1 = new Date(time1).getTime()
  time2 = new Date(time2).getTime()
  diff = Math.ceil((((time1 - time2) / 1000) / 60) * 60)

  return diff
}
// calc distance 
export const getDistanceFromLatLonInKm = (lat1, lon1, lat2, lon2) => {
  // console.log(lat1,lon1,lat2,lon2);
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat2 - lat1);  // deg2rad below
  var dLon = deg2rad(lon2 - lon1);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
    Math.sin(dLon / 2) * Math.sin(dLon / 2)
    ;
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = (R * c).toFixed(2); // Distance in km
  return d;
}

function deg2rad(deg) {
  return deg * (Math.PI / 180)
}
export function abbreviation(value, splitBy) {
  var result = "";
  var txt = value.split(splitBy);
  for (var i = 0; i < txt.length; i++) {
    result += txt[i].substring(0, 1).toUpperCase();
  }
  return result;
}
export const calculatTime = async (statusTime) => {
  console.log(statusTime);
  // const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  // fetch("http://worldtimeapi.org/api/timezone/"+tz)

  fetch("http://worldtimeapi.org/api/timezone/Africa/Casablanca")
    .then(response => response.json())
    .then(data => {
      // console.log(data.datetime)
      var diff;
      const time1 = new Date(data.datetime).getTime()
      statusTime = new Date(statusTime).getTime()
      diff = Math.ceil((((time1 - statusTime) / 1000) / 60))

      return diff
    })
    .catch((error) => console.log(error))
}
export const convertIdToShortId = (id) => id.toString().substring(0, 8)