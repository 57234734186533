export const serviceTypes = [
  {
    label: 'Stores',
    value: 'stores',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

export const cityConfigTypes = [
  {
    value: "SHARE"
  },
  {
    value: "REFERRAL"
  },
]
export const paymentGateway = ['Cash', 'Card', 'KaalixUP', 'KaalixPay'];
export const diplayPhoneUsingDeliveryManStatus = [
  'all', 'WAITING_FOR_ACCEPT', 'ACCEPTED', 'START_PREPARATION', 'COMING_FOR_DELIVERY', 'ARRIVED_TO_PICKUP',
  'UPLOAD_INVOICE', 'STARTED_DELIVERY', 'ARRIVED_TO_DESTINATION', 'DELIVERED'
];
export const partnersList = ['livry', 'kaalix', 'hybrid']