import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel/InputLabel';
import Select from '@material-ui/core/Select/Select';
import MenuItem from '@material-ui/core/MenuItem';
import AsyncSelect from 'react-select/async';
import {
  getApprouvedProvider,
  getlocationsHistoryForDeliverManApi,
} from '../service/serverAPI.service';
import { getCities } from '../../delivery-info/services/serverApi.service';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { getUserDataState } from '../../auth/redux/selectors';
import { socketGpsSdConnect } from '../../orders/services/serverApi.service';
import {
  getCurrentPartnerOrders,
  socketConnect,
  socketConnectPertner,
  socketConnectPertnerGps,
  socketConnectPertners,
} from '../../PartnersOrders/services/server.api.service';
import { getPartnerDeliveryManInCity } from '../../Partners/services/server.api.service';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup/RadioGroup';
import { modesList } from '../../../config/status.config';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import Radio from '@material-ui/core/Radio/Radio';
import { getDeliveryManInCity } from '../../dashboard/services/serverAPI.service';
import CustomInput from '../../../components/material-dashboard-pro-react/CustomInput/CustomInput';
import SwitchComponent from '../../../components/Switch.component';

const columns = [
  { id: '_id', label: 'deliveryManId', minWidth: 70 },
  { id: 'name', label: 'name', minWidth: 70 },
  { id: 'appVersion', label: 'app VR', minWidth: 70 },
  { id: 'systemVersion', label: 'S VR', minWidth: 70 },
  { id: 'nbcmd', label: 'nb-cmd-P', minWidth: 70 },
  { id: 'detailCmd', label: 'cmd-Partner', minWidth: 70 },
  { id: 'nbcmdFood', label: 'nb-cmd-F', minWidth: 70 },
  { id: 'detailCmdFood', label: 'cmd-Food', minWidth: 70 },
  // { id: 'haveAcceptCommande', label: 'cmd socket', minWidth: 70 },
  { id: 'location', label: 'location', minWidth: 70 },
  { id: 'lastPositionDate', label: 'LPS DATE', minWidth: 70 },
  { id: 'seconds', label: 'TIMER', minWidth: 70 },
  { id: 'batteryLevel', label: 'BAT', minWidth: 70 },
  { id: 'GPSOn', label: 'GPS', minWidth: 70 },
  { id: 'available', label: 'AV', minWidth: 70 },
  { id: 'planified', label: 'PL', minWidth: 70 },
  { id: 'dispatchable', label: 'DIS', minWidth: 70 },
  // { id: 'nbCmdSupZero', label: 'nbCmdSupZero', minWidth: 70 },
  { id: 'appState', label: 'APP STAT', minWidth: 70 },
  { id: 'isMocked', label: 'FGPS', minWidth: 70 },
  // { id: 'fromNewApp', label: 'fromNewApp', minWidth: 70 },
  { id: 'phone', label: 'phone', minWidth: 70 },
  // { id: 'socketName', label: 'socketName', minWidth: 70 },
];

const rows = [];

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: '60%',
  },
  filter: {
    padding: 10,
  },
});

export default function deliveryManLocationStatus() {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [providers, setProviders] = useState([]);
  const [providersInit, setProvidersInit] = useState([]);
  const [isfilterprovider, setIsfilterprovider] = useState(false);
  const [newProviders, setNewProviders] = useState([]);
  const [cities, setCities] = useState([]);
  const [newData, setNewData] = useState();
  const [socketMode, setSocketMode] = useState([
    'SD_FOOD',
    'PARTNER',
    'STEP',
    'SRV_UPDATE',
  ]);
  const [selectedSocketMode, setSelectedSocketMode] = useState('PARTNER');
  const [dispatchingType, setdispatchingType] = useState(['FOOD', 'PARTNER']);
  const [selecteddispatchingType, setSelecteddispatchingType] =
    useState('PARTNER');
  const [providerListId, setProviderListId] = useState([]);
  const [selectIdCity, setSelectIdCity] = useState('');
  const [nbCommande, setNbCommande] = useState(null);
  const [detailOrders, setdetailOrders] = useState(null);
  const [filters, setFilters] = useState([
    // { name: 'GPSOn', checked: true },
    // { name: 'available', checked: true },
    // { name: 'planified', checked: true },
    // { name: 'dispatchable', checked: true },
    { name: 'nbCmdSupZero', checked: false },
  ]);
  const [filtersValue, setFiltersValue] = useState({
    // available: true,
    // GPSOn: true,
    // planified: true,
    // dispatchable: true,
    nbCmdSupZero: false,
  });
  const userData = useSelector(getUserDataState);
  const [socket, setSocket] = useState(null);

  // useEffect(() => {
  //   if (selectIdCity) {
  //     // getCurrentPartnerOrders().then(async ({ data }) => {
  //     //   console.log(data);
  //     //   let dd = {};
  //     //   let _detailOrders = {};
  //     //   data.forEach((e, index) => {
  //     //     if (e?.deliveryMan?._id) {
  //     //       if (dd[e.deliveryMan._id + '']) {
  //     //         dd[e.deliveryMan._id + ''] = dd[e.deliveryMan._id + ''] + 1;
  //     //       }
  //     //       dd[e.deliveryMan._id + ''] = 1;
  //     //
  //     //       if (_detailOrders[e.deliveryMan._id + '']) {
  //     //         _detailOrders[e.deliveryMan._id + ''].push({
  //     //           orderId: e?._id,
  //     //           shortId: e?.shortId,
  //     //           status: e?.deliveryStateTimes,
  //     //         });
  //     //       } else {
  //     //         _detailOrders[e.deliveryMan._id + ''] = [
  //     //           {
  //     //             orderId: e?._id,
  //     //             shortId: e?.shortId,
  //     //             status: e?.deliveryStateTimes,
  //     //           },
  //     //         ];
  //     //       }
  //     //     }
  //     //     if (index === data.length - 1) {
  //     //       console.log('asa 1+++++++++++++++++++', dd);
  //     //       console.log('asa 2+++++++++++++++++++', _detailOrders);
  //     //       setNbCommande(dd);
  //     //       setdetailOrders({ ..._detailOrders });
  //     //     }
  //     //   });
  //     // });
  //   }
  // }, [selectIdCity]);

  useEffect(() => {
    //selectedSocketMode
    if (selectIdCity) {
      if (socket) {
        socket.close();
      }
      switch (selectedSocketMode) {
        case 'PARTNER':
          setSocket(socketConnectPertners(userData.accessToken).open());
          break;
        case 'SD_FOOD':
          setSocket(socketGpsSdConnect(userData.accessToken).open());
          break;
        case 'STEP':
          setSocket(socketConnect(userData.accessToken).open());
          break;
        case 'SRV_UPDATE':
          setSocket(socketConnectPertnerGps(userData.accessToken).open());
          break;
      }
    }
  }, [selectedSocketMode, selectIdCity]);
  useEffect(() => {
    if (selecteddispatchingType) {
      if (socket) {
        socket.close();
      }
      setSelectIdCity('');
      setProviders([]);
    }
  }, [selecteddispatchingType]);
  useEffect(() => {
    if (providerListId.length > 0 && socket) {
      providerListId.forEach((id) => {
        console.log('--', id);
        socket.emit('JOIN_ROOM', id + '');
      });
    }
  }, [providerListId, socket]);

  useEffect(() => {
    if (providers && socket && providerListId) {
      socket.on('DELIVERYMAN_LOCATION_CHANGED', function (newData) {
        let data = Object.assign(newData);
        setNewData(data);
      });
    }
  }, [socket, providers, providerListId]);
  const updateData = async () => {
    if (!newData) {
      return;
    }

    let pr = [...providers];
    let index = await pr.findIndex(
      (e) => e._id + '' === newData.deliveryManId + '',
    );

    if (index !== -1) {
      pr[index] = {
        ...pr[index],
        nbcmd: newData?.ordersIds?.length || 0,
        nbcmdFood: newData?.ordersFoodIds?.length || 0,
        _id: newData.deliveryManId,
        lastPositionDate: new Date(),
        GPSOn: newData.gpsStatus,
        available: newData.available_,
        seconds: getSeconds(pr[index]),
        location: newData.location,
        planified: newData.planified,
        haveAcceptCommande: newData.haveAcceptCommande,
        dispatchable: newData.dispatchable,
        appVersion: newData.appVersion,
        appState: newData.appState,
        batteryLevel: newData.batteryLevel,
        systemVersion: newData.systemVersion,
        ordersIds: newData.ordersIds,
        ordersStatus: newData.ordersStatus,
        ordersFoodIds: newData.ordersFoodIds,
        ordersFoodStatus: newData.ordersFoodStatus,
        isMocked: newData.isMocked,
      };
      setNewProviders([...pr]);
    }
  };
  const getSeconds = (item) => {
    var startDate = item.lastPositionDate
      ? new Date(item['lastPositionDate'])
      : new Date();
    var endDate = new Date();
    return (endDate.getTime() - startDate.getTime()) / 1000;
  };
  useEffect(() => {
    if (newData) updateData();
  }, [newData]);
  let interval;
  useEffect(() => {
    if (newProviders) {
      let dd = newProviders.sort(function (a, b) {
        a = a.available && a.GPSOn;
        b = b.available && b.GPSOn;
        return b - a;
      });
      setProviders([...dd]);
    }
  }, [newProviders]);

  useEffect(() => {
    loadCitiesList();
  }, []);
  useEffect(() => {
    if (selectIdCity && selecteddispatchingType) {
      switch (selecteddispatchingType) {
        case 'FOOD':
          getDeliveryMenList();
          break;
        case 'PARTNER':
          getDeliveryMenListPartner();
          break;
      }
    }
  }, [selectIdCity, selecteddispatchingType]);
  let timer1;
  const fetchData = (inputValue, callback) => {
    clearTimeout(timer1);
    // setInputValue('');
    timer1 = setTimeout(() => {
      inputValue &&
        getApprouvedProvider(0, 10, selectIdCity, inputValue)
          .then(({ data }) => {
            const tempArray = [];
            if (data.deliveryMan) {
              if (data.deliveryMan.length) {
                data.deliveryMan.forEach((element) => {
                  tempArray.push({
                    label: `${element.name}`,
                    ...element,
                  });
                });
              }
            }
            callback(tempArray);
          })
          .catch((error) => {
            console.log(error);
          });
    }, 1000);
  };
  const loadCitiesList = () => {
    getCities(page, rowsPerPage, true)
      .then(({ data }) => {
        data = data.cities;
        setCities([...data]);
      })
      .finally(() => {});
  };
  const handleSocketMode = (event) => {
    setSelectedSocketMode(event.target.value);
  };
  const getDeliveryMenList = () => {
    getDeliveryManInCity(selectIdCity)
      .then(({ data }) => {
        console.log('*****************asa************', data);
        let dd = data.deliveryMen;
        // dd = dd.map((e) => {
        //   e.seconds = 0;
        //   // e['nbcmd'] = nbCommande[e._id + ''] ? nbCommande[e._id + ''] : 0;
        //   // e['detailCmd'] = detailOrders[e._id + ''] ? detailOrders[e._id + ''] : 0;
        //   return e;
        // });
        dd = dd.sort(function (a, b) {
          a = a.lastPositionDate ? new Date(a.lastPositionDate) : new Date();
          b = b.lastPositionDate ? new Date(b.lastPositionDate) : new Date();
          return b - a;
        });
        //setProviderListId([...dd.map((e) => e._id + '')]);
        console.log('***************************asa', dd);
        setProviders([...dd]);
      })
      .finally(() => {})
      .catch(() => {
        setProviders([]);
      });
  };
  const getDeliveryMenListPartner = () => {
    getPartnerDeliveryManInCity(selectIdCity)
      .then(({ data }) => {
        let dd = data.deliveryMen;
        dd = dd.map((e) => {
          e.lastPositionDate = e.lastPositionDate
            ? e.lastPositionDate
            : new Date();
          e.seconds = 0;
          e.nbcmd = 0;
          return e;
        });
        dd = dd.sort(function (a, b) {
          a = a.lastPositionDate ? new Date(a.lastPositionDate) : new Date();
          b = b.lastPositionDate ? new Date(b.lastPositionDate) : new Date();
          return b - a;
        });
        setProviderListId([...dd.map((e) => e._id + '')]);
        setProviders([...dd]);
      })
      .finally(() => {});
  };
  const check = (driver, elem) => {
    if (elem == 'appVersion' && !['1.0.50'].includes(driver.appVersion)) {
      return 'red';
    }
    if (elem == 'GPSOn' && !driver.GPSOn) {
      return 'red';
    }
    if (elem == 'available' && !driver.available) {
      return 'red';
    }
    if (elem == 'planified' && !driver.planified) {
      return 'red';
    }
  };
  const checkGlobal = (row) => {
    if (
      row.nbcmd !== 0 &&
      (!row.GPSOn || !row.available || !row.planified || !row.seconds)
    ) {
      return '#ff9b3b';
    } else {
    }
    return '#ffffff';
  };
  useEffect(() => {
    if (filtersValue) {
      console.log('asa655555555555555555', filtersValue);
      let pp = providersInit;
      if (!pp?.length > 0) {
        return;
      }
      pp = pp.filter((item) => {
        if (filtersValue['nbCmdSupZero'] && item['nbcmd'] == 0) {
          return false;
        }

        return true;
      });
      console.log(pp);
      setProviders([...pp]);
    }
  }, [filtersValue]);

  async function filters_provider(value, index, field) {
    if (providersInit.length == 0) {
      setProvidersInit([...providers]);
    }
    filters[index].checked = value;
    setFilters([...filters]);
    let ff = filtersValue;
    ff[field] = value;
    setFiltersValue({ ...ff });
    console.log(filtersValue);
  }
  return (
    <Paper>
      {/*{JSON.stringify(providersInit)}*/}
      <div className="row p-3">
        <div className="col-6">
          <div>
            <FormControl component="fieldset">
              <FormLabel component="legend">dispatching services</FormLabel>
              <RadioGroup
                aria-label="gender"
                name="gender1"
                value={selecteddispatchingType}
                onChange={(e) => setSelecteddispatchingType(e.target.value)}
                row
              >
                {dispatchingType.map((item, index) => (
                  <FormControlLabel
                    value={item}
                    control={<Radio />}
                    label={item}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </div>
          <div className="row">
            <FormControl style={{ minWidth: '300px', padding: 10 }}>
              <InputLabel>Cities</InputLabel>
              <Select
                value={selectIdCity}
                onChange={($event) => {
                  // TODO CHANGE CITIES
                  setSelectIdCity($event.target.value);
                  setNbCommande(null);
                  // loading();
                }}
              >
                {cities.map((item, index) => (
                  <MenuItem value={item._id}>{item.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div>
            <FormControl component="fieldset">
              <FormLabel component="legend">socket services</FormLabel>
              <RadioGroup
                aria-label="gender"
                name="gender1"
                value={selectedSocketMode}
                onChange={handleSocketMode}
                row
              >
                {socketMode.map((item, index) => (
                  <FormControlLabel
                    value={item}
                    control={<Radio />}
                    label={item}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </div>
        </div>
        <div className="col-6">
          {filters.map((e, index) => (
            <SwitchComponent
              // style={{ marginLeft: 15 }}
              onChange={(value) => filters_provider(value, index, e.name)}
              checked={e.checked}
              label={e.name}
            />
          ))}
        </div>
      </div>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {providers &&
              providers.map((row, key, map) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.code}
                    style={{
                      backgroundColor: checkGlobal(row),
                    }}
                  >
                    {columns.map((c) => {
                      if (c.id === 'lastPositionDate') {
                        return (
                          <TableCell
                            key={c.id}
                            align={c.align}
                            style={{ backgroundColor: check(row, c.id) }}
                          >
                            {moment(row.lastPositionDate).format(
                              'MM-DD / hh-mm-ss',
                            )}
                          </TableCell>
                        );
                      }
                      if (c.id === 'seconds') {
                        const s = row.seconds || 0;
                        return (
                          <TableCell
                            key={c.id}
                            align={c.align}
                            style={{
                              backgroundColor:
                                s > 10 ? '#ff7b6c' : s == 0 ? 'red' : '#c8ffb3',
                            }}
                          >
                            {s}
                          </TableCell>
                        );
                      }
                      if (c.id === 'batteryLevel') {
                        const s = row.batteryLevel || 0;
                        return (
                          <TableCell
                            key={c.id}
                            align={c.align}
                            style={{
                              backgroundColor:
                                parseFloat((s || 0) * 100) < 15
                                  ? '#ff761a'
                                  : '#ffffff',
                            }}
                          >
                            {(parseFloat(s || 0) * 100).toFixed(2)}
                          </TableCell>
                        );
                      }
                      // if (c.id === 'detailCmd') {
                      //   if (detailOrders && detailOrders[row._id + '']) {
                      //     return detailOrders[row._id + ''].map((i) => (
                      //       <div>
                      //         <span style={{ whiteSpace: 'nowrap' }}>
                      //           <span style={{ fontWeight: 700 }}>
                      //             _id :
                      //           </span>
                      //           <span>{i._id}</span>
                      //         </span>
                      //         <br />
                      //         <span style={{ whiteSpace: 'nowrap' }}>
                      //           <span style={{ fontWeight: 700 }}>
                      //             status :
                      //           </span>
                      //           <span>
                      //             {i.status[i.status.length - 1].status}
                      //           </span>
                      //         </span>
                      //         <span></span>
                      //       </div>
                      //     ));
                      //   } else {
                      //     return 'No orders';
                      //   }
                      // }
                      if (c.id === 'detailCmd') {
                        return row?.ordersIds?.length > 0 ? (
                          <td>
                            {row?.ordersIds?.map((elem, index) => (
                              <div style={{ borderBottom: '1px solid' }}>
                                <span style={{ whiteSpace: 'nowrap' }}>
                                  <span style={{ fontWeight: 700 }}>_id :</span>
                                  <span>{elem}</span>
                                </span>
                                <br />
                                <span style={{ whiteSpace: 'nowrap' }}>
                                  <span style={{ fontWeight: 700 }}>
                                    status :
                                  </span>
                                  <span>{row.ordersStatus[elem]}</span>
                                </span>
                              </div>
                            ))}
                          </td>
                        ) : (
                          <td>
                            <span>no orders</span>
                          </td>
                        );
                      }
                      if (c.id === 'detailCmdFood') {
                        {
                          return row?.ordersFoodIds?.length > 0 ? (
                            <td>
                              {row?.ordersFoodIds?.map((elem, index) => (
                                <div style={{ borderBottom: '1px solid' }}>
                                  <span style={{ whiteSpace: 'nowrap' }}>
                                    <span style={{ fontWeight: 700 }}>
                                      _id :
                                    </span>
                                    <span>{elem}</span>
                                  </span>
                                  <br />
                                  <span style={{ whiteSpace: 'nowrap' }}>
                                    <span style={{ fontWeight: 700 }}>
                                      status :
                                    </span>
                                    <span>{row.ordersFoodStatus[elem]}</span>
                                  </span>
                                </div>
                              ))}
                            </td>
                          ) : (
                            <td>
                              <span> no orders</span>
                            </td>
                          );
                        }
                      }
                      return (
                        <TableCell
                          key={c.id}
                          align={c.align}
                          style={{ backgroundColor: check(row, c.id) }}
                        >
                          {row[c.id] + ''}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
