import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useAlert } from 'react-alert'
import { Collapse, Box, Grid,Table,TableBody,TableCell,TableRow } from '@material-ui/core'
import { makeStyles } from "@material-ui/core/styles";
import ServiceListComponent from '../components/ServiceList.component'
import GridContainer from '../../../components/material-dashboard-pro-react/Grid/GridContainer';
import Card from '../../../components/material-dashboard-pro-react/Card/Card';
import CardHeader from '../../../components/material-dashboard-pro-react/Card/CardHeader';
import CardBody from '../../../components/material-dashboard-pro-react/Card/CardBody';
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import SelectBoxComponent from '../../../components/SelectBox.component';
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import Checkbox from "@material-ui/core/Checkbox";
// import { getStoresMapping, updateStoresMapping } from '../services/serverApi.service'
import { getCities,getCitiesMapping,editCitiesMapping } from '../../delivery-info/services/serverApi.service'

export default function CityServiceMappingPage() {
  const alert = useAlert()
  const [cities, setCities] = useState([])
  const [cityId, setCityId] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [services, setServices] = useState([])
  const [openModule, setOpenModule] = useState({});

  const useRowStyles = makeStyles({
    root: {
      "& > *": {
        borderBottom: "unset"
      }
    }
  });
  const classes = useRowStyles();

  const getAllCities = () => {
    getCities(0, 0, false)
      .then(({ data }) => setCities(data))
      .catch((error) => console.log(error))
  }
  const changeCity = (id) => {
    setCityId(id)
    getCitiesMapping(id)
    .then(({data})=>{
      // console.log(data);
      setServices(data?.services)})
    .catch((error)=>console.log(error?.response?.data?.errors?.message) )
  }
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };
  const grid = 8;

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? "lightgreen" : "",

    // styles we need to apply on draggables
    ...draggableStyle
  });
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    if (result.type === "droppableItem") {
      const items = reorder(
        services,
        result.source.index,
        result.destination.index
      );
      // console.log(items,'items');
      setServices(items)
    }
     else if (result.type.includes("droppableSubItem")) {
      // console.log('result');
      const parentId = result.type.split('-')[1]
      // console.log(product.products);
      // console.log('parentId', parentId);
      const itemSubItemMap = services.reduce((acc, item) => {
        // console.log(acc,'acc', item,'item');
        acc[item.serviceCategoryId] = item.services;
        return acc;
      }, {});
      const subItemsForCorrespondingParent = itemSubItemMap[parentId];
      // console.log(subItemsForCorrespondingParent);
      const reorderedSubItems = reorder(
        subItemsForCorrespondingParent,
        result.source.index,
        result.destination.index
      );
      // console.log(reorderedSubItems);
      let newItems = [...services];
      console.log('before', newItems);
      newItems = newItems.map(item => {
        if (item.serviceCategoryId === parentId) {
          item.services = reorderedSubItems;
        }
        return item;
      });
      // console.log('after', newItems);
      setServices(newItems)
    }
    
  }

  console.log('services',services);
  const onEdit = () => {
    let payload=services.length > 0 && services.map((item,index)=>{
      
      let arrayServices = []
    item.services && item.services.length > 0 && item.services.map(service => (
      arrayServices.push(service._id)
    ))
      // item.services.length > 0 && item.services.map((service)=>{
      //   const servicesArray = [];
      //   service.items.length> 0&&service.items.map(item=>(
      //     servicesArray.push(item._id)
      //   ));
      //   services = [{
      //     productId:service.serviceCategoryId,
      //     items:servicesArray
      //   }];
      // })
      return {
        serviceCategoryId:item?.serviceCategoryId,
        services:arrayServices
      }
    });
    console.log('payload',payload)
    setIsLoading(true)
    editCitiesMapping(cityId, payload)
    .then(({data})=>{
      console.log(data);
      alert.show('saved', {
        type: 'success',
      });
    })
    .catch(error=>console.log(error))
    .finally(()=>setIsLoading(false))
  }
  console.log(services);
  useEffect(() => {
    getAllCities()
    let newState = {...openModule}
    services&&services.length>0&&services.map((item,index)=>(
      newState[index] = false
      ))
      setOpenModule(newState)
  }, [])
  return (
    <GridContainer>
      <Card>
        <CardHeader>store mapping</CardHeader>
        <CardBody>
          {cities &&
            <SelectBoxComponent
              items={cities.map((city) => ({
                value: city._id,
                label: city.name,
              }))}
              inputLabel="city list"
              onValueChange={(items) => {
                changeCity(items)
                //   console.log(items);
                //   setServiceId(items)
                // formik.setFieldValue('cityList', items);
              }}
              value={cities.find((item) => item._id == cityId)?.name}
            />}
            
          <React.Fragment>
            {/* {services&&services.length>0&& */}
            <Box textAlign="right">
            <Button
            round
            type="submit"
            color="success"
            disabled={isLoading}
            loading={isLoading}
            loaderColor="white"
            onClick={onEdit}
          >
            save
          </Button>
            </Box>
            {/* // } */}
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable" type="droppableItem">
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                  // style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {services && services.length > 0 && services.map((item, index) => (
                      
                      <Draggable key={item.serviceCategoryId} draggableId={item.serviceCategoryId} index={index}>
                        {(provided, snapshot) => (
                          <div>
                            <Box className={classes.root} style={{ background: "grey", }} boxShadow={3}>
                              
                              
                              <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  style={getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style
                                  )}
                                >
                                  <Grid container spacing={3}>
                                <Grid item xs={1}>
                                  <IconButton
                                    aria-label="expand row"
                                    size="small"
                                    onClick={() => setOpenModule({...openModule,[index]:!openModule[index]})}
                                    style={{ color: "white" }}
                                  >
                                    {openModule[index] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                  </IconButton>
                                </Grid>
                                <Grid item xs={10}>
                                    <span style={{ color: "white" }} >{item.serviceCategory.name}</span>
                                  </Grid>
                                  <Grid item xs={1}>
                              
                                  <div {...provided.dragHandleProps}>

                                    <DragHandleIcon style={{ color: "white" }}/>
                                  </div>
                               
                                </Grid>
                              </Grid>
                                </div>
                            </Box>
                            <TableRow>
                              <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                <Collapse in={openModule[index]} timeout="auto" unmountOnExit>
                                  <Box margin={1}>
                                    <Table size="small" aria-label="purchases">
                                      <TableBody>
                                        <ServiceListComponent
                                          subItems={item.services}
                                          type={item.serviceCategoryId}
                                          parentIndex={index}
                                          classes={classes}
                                          getItemStyle={getItemStyle}
                                        />
                                      </TableBody>
                                    </Table>
                                  </Box>
                                </Collapse>
                              </TableCell>
                            </TableRow>
                            {provided.placeholder}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </React.Fragment>
        </CardBody>
      </Card>
    </GridContainer>
  );
}
