import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TabRatingList from './TabRatingList.component';
import SwitchComponent from '../../../components/Switch.component';
import { getRedisConfig, setUseRedisDb } from '../services/serverApi.service';
import ReactJson from 'react-json-view';
import {
  getAlarmsCurrentOrders,
  getCities,
  getDeliveryManByCities,
  getOrdersByCities,
  refreshRedis,
} from '../../orders/services/serverApi.service';
import { Button } from '@mui/material';
import Swal from 'sweetalert2';

export default function RedisConfig({ setting }) {
  const [cities, setCities] = useState([]);
  const [citiesFilter, SetCitiesFilter] = useState([]);
  const [orders, setOrders] = useState([]);
  const [ordersFilter, setOrdersFilter] = useState([]);
  const [deliveryMen, setDeliveryMen] = useState([]);
  const [deliveryMenFilter, setDeliveryMenFilter] = useState([]);
  const [alarms, setAlarms] = useState([]);
  const [alarmsFilter, setAlarmsFilter] = useState([]);
  const [loading, setLoadig] = useState(true);

  function useRedisDb(value) {
    console.log(value);
    setUseRedisDb(value).then((res) => {
      setUseRedis(!useRedis);
    });
  }

  const [useRedis, setUseRedis] = useState(false);
  useEffect(() => {
    getRedisConfig().then((res) => {
      setUseRedis(res?.data?.useRedis);
    });
  }, []);

  const getCitiesList = () => {
    getCities()
      .then(({ data }) => {
        setCities(data);
        setLoadig(false);
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    if (loading) getCitiesList();
  }, [loading]);

  useEffect(() => {
    if (cities.length > 0) {
      let ordersIds = cities.map((city) => city._id);
      console.log(ordersIds);
      getOrdersByCities(ordersIds)
        .then(({ data }) => {
          setOrders(data);
        })
        .catch((error) => console.log(error));
      getDeliveryManByCities(ordersIds)
        .then(({ data }) => {
          setDeliveryMen(data?.deliveryMen);
        })
        .catch((error) => console.log(error));
      getAlarmsCurrentOrders(cities)
        .then(({ data }) => {
          setAlarms(data);
        })
        .catch((error) => console.log(error));
    }
  }, [cities]);

  function filterData(val, data) {
    val = val.target.value;
    val = val.split(':');
    try {
      if (val.length === 2) {
        const id = val[0].replaceAll('"', '').trim();
        const elem = val[1].replaceAll('"', '').trim();
        if (!(id + '' !== '' && elem + '' !== '')) {
          return [];
        }
        console.log(id, elem, data[0][id + ''] + '');
        let res = data.filter(
          (item) =>
            item[id + ''] !== undefined && item[id + ''] + '' === elem + '',
        );
        return res;
      } else {
        return [];
      }
    } catch (e) {
      return [];
    }
  }
  function filterCities(val) {
    SetCitiesFilter(filterData(val, cities));
  }

  function filterOrders(val) {
    setOrdersFilter(filterData(val, orders));
  }

  function filterAlarms(val) {
    setAlarmsFilter(filterData(val, alarms));
  }

  function filterDeliveryMan(val) {
    setDeliveryMenFilter(filterData(val, deliveryMen));
  }

  function reloadData() {
    setLoadig(true);
  }

  function checkpass() {
    Swal.fire({
      title: 'password ?',
      input: 'text',
      showCancelButton: true,
      confirmButtonText: 'ok',
      showLoaderOnConfirm: true,
      preConfirm: (text) => {
        if (text) {
          console.log(text);
          return text === 'dev';
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result) {
        refreshRedis().then(() => reloadData());
      } else {
        Swal.showValidationMessage(`Request failed`);
      }
    });
  }

  return (
    <div>
      <table className={'table table-bordered'}>
        <tr>
          <td>USE REDIS DB</td>
          <td>
            <SwitchComponent
              key={1}
              onChange={(value) => {
                useRedisDb(value);
              }}
              checked={useRedis}
              value="checked"
              label={'enable'}
            />
          </td>
          <td>
            <Button
              onClick={() => {
                reloadData();
              }}
            >
              reload
            </Button>
          </td>
          <td>
            <Button
              onClick={() => {
                checkpass();
              }}
            >
              refresh Redis
            </Button>
          </td>
        </tr>
        <tr>
          <td>cities</td>
          <td>
            <input
              className={'w-100'}
              type="text"
              onChange={(e) => filterCities(e)}
            />
          </td>
          <td>{citiesFilter.length + ' elements'}</td>
          <td>
            <ReactJson
              src={citiesFilter}
              displayDataTypes={false}
              displayObjectSize={false}
              collapsed={true}
              name="data"
            />
          </td>
          <td>
            <ReactJson
              src={{ cities: cities }}
              displayDataTypes={false}
              displayObjectSize={false}
              collapsed={true}
              name="data"
            />
          </td>
        </tr>
        <tr>
          <td>orders</td>
          <td>
            <input
              className={'w-100'}
              type="text"
              onChange={(e) => filterOrders(e)}
            />
          </td>
          <td>{ordersFilter.length + ' elements'}</td>
          <td>
            <ReactJson
              src={ordersFilter}
              displayDataTypes={false}
              displayObjectSize={false}
              collapsed={true}
              name="data"
            />
          </td>
          <td>
            <ReactJson
              src={{ orders: orders }}
              displayDataTypes={false}
              displayObjectSize={false}
              collapsed={true}
              name="data"
            />
          </td>
        </tr>
        <tr>
          <td>deliveryman</td>
          <td>
            <input
              className={'w-100'}
              type="text"
              onChange={(e) => filterDeliveryMan(e)}
            />
          </td>
          <td>{deliveryMenFilter.length + ' elements'}</td>
          <td>
            <ReactJson
              src={deliveryMenFilter}
              displayDataTypes={false}
              displayObjectSize={false}
              collapsed={true}
              name="data"
            />
          </td>
          <td>
            <ReactJson
              src={{ deliveryMen: deliveryMen }}
              displayDataTypes={false}
              displayObjectSize={false}
              collapsed={true}
              name="data"
            />
          </td>
        </tr>
        <tr>
          <td>alarms</td>
          <td>
            <input
              className={'w-100'}
              type="text"
              onChange={(e) => filterAlarms(e)}
            />
          </td>
          <td>{alarmsFilter.length + ' elements'}</td>
          <td>
            <ReactJson
              src={alarmsFilter}
              displayDataTypes={false}
              displayObjectSize={false}
              collapsed={true}
              name="data"
            />
          </td>
          <td>
            <ReactJson
              src={{ alarms: alarms }}
              displayDataTypes={false}
              displayObjectSize={false}
              collapsed={true}
              name="data"
            />
          </td>
        </tr>
      </table>
    </div>
  );
}

RedisConfig.propTypes = {
  setting: PropTypes.any.isRequired,
};
