import React, { useEffect, useState } from 'react';
import {
  deleteDispatchingMessage,
  getDispatchingModes,
} from '../services/serverApi.service';
import DataTableContainerComponent from '../../../components/DataTableContainer.component';
import CreateDispatchingModes from '../components/CreateDispatchingModes.component';
import ViewItemComponent from '../components/ViewItem.component';
import EditDispatchingModesComponent from '../components/EditDispatchingModes.component';
import DeleteComponent from '../components/Delete.component';

export default function DispatchingModesPage() {
  const [resultsData, setResultsData] = useState({
    total: 0,
    items: [],
    tableItems: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  // const pages = [5, 10, 25];
  // const [page, setPage] = useState(0);
  // const [rowsPerPage, setRowsPerPage] = useState(pages[page]);

  const loadDispatchingModesList = (page, limit) => {
    //setIsLoading(true);
    getDispatchingModes(page || 0, limit || 20, true)
      .then(({ data }) => {
        setResultsData({
          total: data.total,
          items: data.dispatchingModes,
          tableItems: data.dispatchingModes.map((item) => {
            item.image = item.imageUrl ? (
              <img src={item.imageUrl || ''} alt="dqs" height="50" width="50" />
            ) : (
              ''
            );
            return [
              item._id,
              item.name,
              item.message,
              item.image,
              item.showImage ? 'oui' : 'non',
              item.showMessage ? 'oui' : 'non',
            ];
          }),
        });
      })
      .finally(() => {});
  };

  const [pageSelect, setPageSelect] = useState(0);
  const [numberOfRows, setNumberOfRows] = useState(0);
  const handlePaginate = (val) => {
    setResultsData({
      total: 0,
      items: [],
      tableItems: [],
    });
    console.log('handlePaginate', val);
    loadDispatchingModesList(val.pageSelect, val.numberOfRows);
  };

  const onItemCreated = (newData) => {
    if (newData) {
      newData = newData.dispatchingMode;
      newData.image = newData.imageUrl && (
        <img src={newData.imageUrl || ''} alt="dqs" height="50" width="50" />
      );
      let data = resultsData.items;
      data = [...data, newData];
      setResultsData({
        total: resultsData.total + 1,
        items: data,
        tableItems: [
          ...resultsData.tableItems,
          [
            newData._id,
            newData.name,
            newData.message,
            newData.image,
            newData.showImage ? 'oui' : 'non',
            newData.showMessage ? 'oui' : 'non',
          ],
        ],
      });
    }
  };

  const onItemUpdated = async (newData) => {
    console.log('itemUpdated', newData);
    const index = newData.index;
    newData = newData.dispatchingMode;
    console.log('itemUpdated', index);
    newData.image = newData.imageUrl && (
      <img src={newData.imageUrl || ''} alt="dqs" height="50" width="50" />
    );
    let tableItems = resultsData.tableItems;
    tableItems[index] = [
      newData._id,
      newData.name,
      newData.message,
      newData.image,
      newData.showImage ? 'oui' : 'non',
      newData.showMessage ? 'oui' : 'non',
    ];
    let items = resultsData.items;
    items[index] = newData;
    console.log(tableItems);
    setResultsData({
      ...resultsData,
      items: items,
      tableItems: tableItems,
    });
  };

  const onItemDeleted = async ({index}) => {
    console.log(index)
    const items =  resultsData.items;
    items.splice(index, 1);
    let tableItems = resultsData.tableItems;
    tableItems.splice(index, 1);
    setResultsData({
      ...resultsData,
      items: items,
      tableItems: tableItems,
    });
  };

  const delete_Dispatching_Message = (id) => {
    return deleteDispatchingMessage(id);
  };
  return (
    <>
      <DataTableContainerComponent
        resultsData={resultsData}
        tableHead={[
          'name',
          'message',
          'image',
          'showImage',
          'showMessage',
          'Action',
        ]}
        title="Dispatching Message"
        isLoading={isLoading}
        loadItemsList={() => {}}
        CreateItemFormComponent={CreateDispatchingModes}
        actionButtons={{
          view: {
            color: 'info',
            type: 'view',
            component: ViewItemComponent,
          },
          edit: {
            color: 'success',
            type: 'edit',
            advanceEdit: true,
            component: EditDispatchingModesComponent,
          },
          delete: {
            color: 'danger',
            type: 'delete',
            action: delete_Dispatching_Message,
            component: DeleteComponent,
          },
        }}
        onPaginate={handlePaginate}
        isPaginate={true}
        itemCreated={onItemCreated}
        itemEdited={onItemUpdated}
        itemDelete={onItemDeleted}
      />
    </>
  );
}
