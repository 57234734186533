import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
// selector
import {
  getPartnerDeliveriesDataState,
  // getModelDataState,
} from '../redux/selectors';
import { getModelDataState } from '../../orders/redux/selectors';

// material
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Grid,
  Typography,
  Paper,
  Stepper,
  StepLabel,
  Step,
  StepContent,
} from '@mui/material';
import { styled } from '@mui/material/styles';
// call api
import { updateAlarm } from '../services/server.api.service';
// componenet
import ModalComponent from '../../../components/Modal.component';
import DeliveryHeaderInfoComponenet from './Delivery.header.info.componenet';

import MapAlarmsComponent from './Map.alarms.component';
import {
  calcDurationbetweenTwoTimes,
  formatTimeForOrder,
} from '../../../helpers';
// icon
import PersonIcon from '@mui/icons-material/Person';
import SportsMotorsportsIcon from '@mui/icons-material/SportsMotorsports';

export default function AlarmsComponenet({ orderId, setOpenAlarm, admin }) {
  const { deliveries, loading, error, invalidOrders } = useSelector(
    getPartnerDeliveriesDataState,
  );
  const [delivery, setDelivery] = useState({});

  const [alarms, setAlarms] = useState([]);
  const [modal, setModal] = useState(null);
  const [allAlarms, setAllAlarms] = useState(false);
  const tableHeader = [
    {
      label: 'Name',
      width: '70px',
    },
    {
      label: 'Apparition',
      width: '70px',
    },
    {
      label: 'disparition',
      width: '70px',
    },
    {
      label: 'Acquittement',
      width: '200px',
    },
    {
      label: 'Description',
      width: '600px',
    },
    {
      label: 'action',
      width: '50px',
    },
  ];

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  const MySwal = withReactContent(Swal);
  const openModalAlarm = (alarm) => {
    setOpenAlarm(false);
    MySwal.fire({
      title: 'Acquittement Alarm',
      icon: 'warning',
      html: alarm_message(alarm),
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui !',
      cancelButtonText: 'Non',
      input: 'textarea',
      inputLabel: 'comment',
      inputValue: '',
      inputPlaceholder: 'Type your comment',
      closeOnConfirm: false,
      animation: 'slide-from-top',
    }).then((result) => {
      if (result.isConfirmed) {
        setOpenAlarm(true);

        updateAlarm(alarm._id, { acquitterBy: admin, comment: result?.value })
          .then((res) => {
            Swal.fire('Success!', '', 'success');
            setOpenAlarm(true);
          })
          .catch((e) => {
            Swal.fire('error!', '', 'error');
            setOpenAlarm(true);
          });
      } else setOpenAlarm(true);
    });
  };
  const alarm_message = (alarm) => {
    return (
      <div>
        <label htmlFor="basic-url" style={{ color: 'black', fontWeight: 700 }}>
          ALARM : <span style={{ color: '#55bb55' }}>{alarm.name}</span>
        </label>
        <br />
        <label htmlFor="basic-url" style={{ color: 'black', fontWeight: 700 }}>
          STATUS : <span style={{ color: '#55bb55' }}>{alarm.status}</span>
        </label>
        <label htmlFor="basic-url" style={{ color: 'black', fontWeight: 700 }}>
          description :{' '}
          <span style={{ color: '#55bb55' }}>{alarm.description}</span>
        </label>
        <label htmlFor="basic-url" style={{ color: 'black', fontWeight: 700 }}>
          date :{' '}
          <span style={{ color: '#55bb55' }}>
            {alarm?.createdAt && moment(alarm?.createdAt).format('hh:mm:ss')}
          </span>
        </label>
      </div>
    );
  };
  const cellStyle = {
    display: 'block',
    maxWidth: '160px',
    minWidth: '70px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  };
  const findOrder = () => {
    setDelivery((deliveries || []).find((x) => x._id == orderId));
  };
  useEffect(() => {
    findOrder();
  }, [orderId, (deliveries || []).find((x) => x._id == orderId)?.alarms]);
  useEffect(() => {
    if (delivery && delivery?.alarms && delivery.alarms.length > 0) {
      const retardAlarmIndex = delivery.alarms.findIndex(
        (x) => x.name == 'RETARD' && x.visible,
      );
      if (retardAlarmIndex >= 0) {
        setAlarms(delivery.alarms);
        setAllAlarms(true);
      } else {
        setAlarms(delivery.alarms.filter((x) => x?.visible));
        setAllAlarms(false);
      }
    }
  }, [delivery]);
  return (
    <div>
      <DeliveryHeaderInfoComponenet delivery={delivery} />
      <Grid container spacing={1}>
        <Grid
          item
          xs={allAlarms ? 9 : 12}
          container
          className="mb-2"
          style={{ maxHeight: '600px' }}
        >
          <Button
            variant="contained"
            color="primary"
            className="my-2"
            onClick={() => setModal('allAlarm')}
            style={{ height: '40px' }}
          >
            Alarm History
          </Button>
          <TableContainer sx={{ maxHeight: 440 }} component={Paper}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {tableHeader &&
                    tableHeader.map((item) => (
                      <TableCell
                        align="center"
                        style={{ maxWidth: item.width, padding: '0px 8px' }}
                      >
                        {item?.label}
                      </TableCell>
                    ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {(alarms || []).map((row) => (
                  <StyledTableRow key={row._id}>
                    <TableCell align="center">
                      <span
                        style={{
                          ...cellStyle,
                          color: allAlarms && row.visible ? 'red' : '',
                        }}
                      >
                        {row.name}
                      </span>
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ color: allAlarms && row.visible ? 'red' : '' }}
                    >
                      {row.createdAt && moment(row.createdAt).format('hh:mm')}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ color: allAlarms && row.visible ? 'red' : '' }}
                    >
                      {row.dateDisparition &&
                        moment(row.dateDisparition).format('hh:mm')}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        width: '500px',
                        color: allAlarms && row.visible ? 'red' : '',
                      }}
                    >
                      {row?.acquitterBy?.name}{' '}
                      {row.dateAcquittement &&
                        moment(row.dateAcquittement).format('hh:mm')}{' '}
                      {row?.comment}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        width: '600px',
                        color: allAlarms && row.visible ? 'red' : '',
                      }}
                    >
                      {row.description}
                    </TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center">
                      <Button
                        style={{ width: '50px' }}
                        disabled={
                          row?.status == 'Acquittement' ||
                          row?.name == 'INVALID_ORDER' ||
                          !row.visible
                        }
                        onClick={() => {
                          row?.status != 'Acquittement' &&
                            row?.name != 'INVALID_ORDER' &&
                            row.visible &&
                            openModalAlarm(row);
                        }}
                      >
                        acquitter
                      </Button>
                      <Button color="primary" onClick={() => setModal('map')}>
                        map
                      </Button>
                    </TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        {allAlarms && (
          <div
            className="col-3"
            style={{ maxHeight: '440px', overflowY: 'scroll' }}
          >
            <Grid item xs={3}>
              <Stepper orientation="vertical">
                {(delivery?.deliveryManStatusTimes || [])
                  .filter(
                    (item) =>
                      item?.deliveryMan?.location &&
                      item?.deliveryMan?.location.length,
                  )
                  .map((step, index) => (
                    <Step
                      key={`${step?.status + index}`}
                      active={true}
                      // active={selectedStep.includes(index)}
                    >
                      <StepLabel
                        // onClick={() => handleStepClick(index)}
                        icon={index + 1}
                      >
                        {step.status}
                      </StepLabel>
                      <StepContent>
                        <strong style={{ fontSize: '12px' }}>
                          {formatTimeForOrder(step.date)}{' '}
                        </strong>
                        <br />
                        {step?.admin &&
                          Object.keys(step.admin).length &&
                          step?.admin?.userName && (
                            <div
                              style={{ display: 'flex', flexDirection: 'row' }}
                            >
                              <PersonIcon
                                style={{ color: '#3f51b5', fontSize: '20px' }}
                              />
                              <strong>{step?.admin?.userName}</strong>
                              <br />
                            </div>
                          )}
                        {step?.deliveryMan && step?.deliveryMan.name && (
                          <div
                            style={{ display: 'flex', flexDirection: 'row' }}
                          >
                            <SportsMotorsportsIcon
                              style={{ color: '#3f51b5', fontSize: '20px' }}
                            />{' '}
                            <strong>{step?.deliveryMan.name}</strong>
                            <br />
                          </div>
                        )}

                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <span>
                            {index < delivery.deliveryManStatusTimes.length &&
                            index !== 0
                              ? calcDurationbetweenTwoTimes(
                                  delivery.deliveryManStatusTimes[index - 1]
                                    .date,
                                  step.date,
                                )
                              : calcDurationbetweenTwoTimes(
                                  delivery.createdAt,
                                  step.date,
                                )}
                          </span>
                          min
                        </div>
                      </StepContent>
                    </Step>
                  ))}
              </Stepper>
            </Grid>
          </div>
        )}
      </Grid>
      {modal && modal == 'allAlarm' && (
        <ModalComponent
          maxWidth={'lg'}
          close={() => setModal(false)}
          visible={modal == 'allAlarm'}
          title="Alarms history"
        >
          <TableContainer sx={{ maxHeight: 440 }} component={Paper}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {tableHeader &&
                    tableHeader.slice(0, tableHeader.length - 1).map((item) => (
                      <TableCell
                        align="center"
                        style={{ maxWidth: item.width, padding: '0px 8px' }}
                      >
                        {item?.label}
                      </TableCell>
                    ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {(delivery?.alarms || []).map((row) => (
                  <StyledTableRow key={row._id}>
                    <TableCell align="center ">
                      <span
                        style={{
                          ...cellStyle,
                        }}
                      >
                        {row.name}
                      </span>
                    </TableCell>
                    <TableCell align="center">
                      {row.createdAt && moment(row.createdAt).format('hh:mm')}
                    </TableCell>
                    <TableCell align="center">
                      {row.dateDisparition &&
                        moment(row.dateDisparition).format('hh:mm')}
                    </TableCell>
                    <TableCell align="center" style={{ width: '500px' }}>
                      {row?.acquitterBy?.name}{' '}
                      {row.dateAcquittement &&
                        moment(row.dateAcquittement).format('hh:mm')}{' '}
                      {row?.comment}
                    </TableCell>
                    <TableCell align="center" style={{ width: '600px' }}>
                      {row.description}
                    </TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </ModalComponent>
      )}
      {modal && modal == 'map' && (
        <ModalComponent
          maxWidth={'lg'}
          close={() => setModal('')}
          visible={modal == 'map'}
          title="Alarms history"
        >
          <MapAlarmsComponent order={delivery} />
        </ModalComponent>
      )}
    </div>
  );
}
