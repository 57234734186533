
const scope = 'DelivriesPages'
export const GET_ALLPARTNER_ORDER_REQUEST = `${scope}/GET_ALLPARTNER_ORDER_REQUEST`;
export const GET_ALLPARTNER_ORDER_SUCCESS = `${scope}/GET_ALLPARTNER_ORDER_SUCCESS`;
export const GET_ALLPARTNER_ORDER_FAIL = `${scope}/GET_ALLPARTNER_ORDER_FAIL`;
export const ALLPARTNER_CHANGE_DELIVERIE_STATUS = `${scope}/ALLPARTNER_CHANGE_DELIVERIE_STATUS`;
export const ALLPARTNER_ACCEPTED_DELIVERIE = `${scope}/ALLPARTNER_ACCEPTED_DELIVERIE`;
export const ALLPARTNER_CANCEL_DELIVERIE = `${scope}/ALLPARTNER_CANCEL_DELIVERIE`;
export const ALLPARTNER_COMPLETED_DELIVERIE = `${scope}/ALLPARTNER_COMPLETED_DELIVERIE`;
export const ALLPARTNER_ASSIGN_DELIVERIE = `${scope}/ALLPARTNER_ASSIGN_DELIVERIE`;
export const ALLPARTNER_UPDATED_DELIVERIE = `${scope}/ALLPARTNER_UPDATED_DELIVERIE`;
export const ALLPARTNER_NEW_DELIVERIE = `${scope}/ALLPARTNER_NEW_DELIVERIE`;
export const ALLPARTNER_VALIDATED_DELIVERIE = `${scope}/ALLPARTNER_VALIDATED_DELIVERIE`;
export const ALLPARTNER_INITIAL_STATE = `${scope}/ALLPARTNER_INITIAL_STATE`;
export const ALLPARTNER_CHANGE_DELIVERY_ALARMS = `${scope}/ALLPARTNER_CHANGE_DELIVERY_ALARMS`;
