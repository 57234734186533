import {
    GET_PARTNER_ORDER_REQUEST, GET_PARTNER_ORDER_SUCCESS, GET_PARTNER_ORDER_FAIL, CHANGE_DELIVERIE_STATUS, NEW_DELIVERIE, UPDATED_DELIVERIE, ASSIGN_DELIVERIE, COMPLETED_DELIVERIE, CANCEL_DELIVERIE, INITIAL_STATE, CHANGE_DELIVERY_ALARMS
    , GET_PARTNER_ORDER_REQUESTV2,
    GET_PARTNER_ORDER_SUCCESSV2,
    GET_PARTNER_ORDER_FAILV2,
    CHANGE_DELIVERIE_STATUSV2,
    ACCEPTED_DELIVERIEV2,
    CANCEL_DELIVERIEV2,
    COMPLETED_DELIVERIEV2,
    ASSIGN_DELIVERIEV2,
    UPDATED_DELIVERIEV2,
    NEW_DELIVERIEV2,
    INITIAL_STATEV2,
    CHANGE_DELIVERY_ALARMSV2,
    CHANGE_DELIVERYMAN_LOCATIONV2
} from '../constants/order.constants'

export const getPartnerOrderRequestAction = (cities, warehouses) => ({
    type: GET_PARTNER_ORDER_REQUEST,
    cities, warehouses
})
export const getPartnerOrderSuccessAction = (deliveries, cities, warehouses) => ({
    type: GET_PARTNER_ORDER_SUCCESS,
    deliveries,
    cities, warehouses
})
export const getPartnerOrderFailAction = (error) => ({
    type: GET_PARTNER_ORDER_FAIL,
    error
})
export const deliverieStatusChangeAction = (deliverie) => ({
    type: CHANGE_DELIVERIE_STATUS,
    deliverie,
})
export const newDeliverieAction = (deliverie) => ({
    type: NEW_DELIVERIE,
    deliverie,
})
export const AssignDeliverieAction = (deliverie) => ({
    type: ASSIGN_DELIVERIE,
    deliverie,
})
export const completedDeliverieAction = (deliverieId) => ({
    type: COMPLETED_DELIVERIE,
    deliverieId,
})
export const cancelDeliverieAction = (deliverieId) => ({
    type: CANCEL_DELIVERIE,
    deliverieId
})
export const updatedDeliverieAction = (deliverie) => ({
    type: UPDATED_DELIVERIE,
    deliverie,
})

export const initialPartnerOrderAction = () => ({
    type: INITIAL_STATE
})
export const changeDeliveryAlarmAction = (id, alarms) => ({
    type: CHANGE_DELIVERY_ALARMS,
    id,
    alarms
})
// ************************* V2 ***************
export const getPartnerOrderRequestV2Action = (cities, warehouses, selectedDeliveryManStatus,
    selectedDispatchingStatus,
    filterBy,) => ({
        type: GET_PARTNER_ORDER_REQUESTV2,
        cities, warehouses, selectedDeliveryManStatus,
        selectedDispatchingStatus,
        filterBy,
    })
export const getPartnerOrderSuccessV2Action = (deliveries, cities, warehouses, selectedDeliveryManStatus,
    selectedDispatchingStatus, filterBy) => ({
        type: GET_PARTNER_ORDER_SUCCESSV2,
        deliveries,
        cities, warehouses, selectedDeliveryManStatus,
        selectedDispatchingStatus,
        filterBy,
    })
export const getPartnerOrderFailV2Action = (error) => ({
    type: GET_PARTNER_ORDER_FAILV2,
    error
})
export const deliverieStatusChangeV2Action = (deliverie, selectedDeliveryManStatus,
    selectedDispatchingStatus,
    filterBy,) => ({
        type: CHANGE_DELIVERIE_STATUSV2,
        deliverie,
        selectedDeliveryManStatus,
        selectedDispatchingStatus,
        filterBy,
    })
export const newDeliverieV2Action = (deliverie, selectedDeliveryManStatus,
    selectedDispatchingStatus,
    filterBy,) => ({
        type: NEW_DELIVERIEV2,
        deliverie,
        selectedDeliveryManStatus,
        selectedDispatchingStatus,
        filterBy,
    })
export const AssignDeliverieV2Action = (deliverie, selectedDeliveryManStatus,
    selectedDispatchingStatus,
    filterBy,) => ({
        type: ASSIGN_DELIVERIEV2,
        deliverie,
        selectedDeliveryManStatus,
        selectedDispatchingStatus,
        filterBy,
    })
export const completedDeliverieV2Action = (deliverieId, contact) => ({
    type: COMPLETED_DELIVERIEV2,
    deliverieId,
    contact
})
export const cancelDeliverieV2Action = (deliverieId, contact) => ({
    type: CANCEL_DELIVERIEV2,
    deliverieId,
    contact
})
export const updatedDeliverieV2Action = (deliverie, selectedDeliveryManStatus,
    selectedDispatchingStatus,
    filterBy,) => ({
        type: UPDATED_DELIVERIEV2,
        deliverie,
        selectedDeliveryManStatus,
        selectedDispatchingStatus,
        filterBy,
    })

export const initialPartnerOrderV2Action = () => ({
    type: INITIAL_STATEV2
})
export const changeDeliveryAlarmV2Action = (id, alarms) => ({
    type: CHANGE_DELIVERY_ALARMSV2,
    id,
    alarms
})
export const changeDeliveryManLocationV2Action = (deliveryManId, location) => ({
    type: CHANGE_DELIVERYMAN_LOCATIONV2,
    deliveryManId,
    location
})
// export const filterDeliveriesAction = (deliveryManId, location) => ({
//     type: CHANGE_DELIVERYMAN_LOCATIONV2,
//     deliveryManId,
//     location
// })