/* global google */
import React, { useEffect, useState } from 'react';
import { GoogleMap, LoadScript, InfoWindow } from '@react-google-maps/api';
import { googleMapsApiKey } from '../../../config/backendServer.config';
import CustomMarker from '../../../components/Markers.component';
import { iconsList } from '../../../config/status.config';
import {
  getDeliveryManDispatchedById,
  socketConnectPertnerGps,
} from '../services/server.api.service';
import { useSelector } from 'react-redux';
// selector
import { getPartnerDeliveriesDataState } from '../redux/selectors';
import {
  getlocationsHistoryForDeliverManApi,
} from '../../Provider/service/serverAPI.service';
import {formatTime} from '../../../helpers/index';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
const columns = [
  { id: 'deliveryManId', label: 'deliveryManId', minWidth: 70 },
  { id: 'name', label: 'name', minWidth: 70 },
  { id: 'location', label: 'location', minWidth: 70 },
  { id: 'timestamp', label: 'timestamp', minWidth: 100 },
  { id: 'seconds', label: 'seconds', minWidth: 70 },
  { id: 'batteryLevel', label: 'batteryLevel', minWidth: 70 },
  { id: 'gpsStatus', label: 'gpsStatus', minWidth: 70 },
  { id: 'appState', label: 'appState', minWidth: 70 },
  { id: 'appVersion', label: 'appVersion', minWidth: 70 },
  { id: 'isMocked', label: 'isMocked', minWidth: 70 },
  { id: 'fromNewApp', label: 'fromNewApp', minWidth: 70 },
  { id: 'phone', label: 'phone', minWidth: 70 },
];

const rows = [];
const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  filter: {
    padding: 10,
  },
  text: {
    textAlign: 'center',
  },
});

export default function DisplayOrderInMap({
  orderId,
  setOpenAlarm,
  admin,
  token,
}) {
  const { deliveries, loading, error, invalidOrders } = useSelector(
    getPartnerDeliveriesDataState,
  );
  const [delivery, setDelivery] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [path, setPath] = useState({});
  const [customerPath, setCustomerPath] = useState({});
  const [deliveryManPathFromDB, setDeliveryManPathFromDB] = useState({});
  const [zoom, setZoom] = useState(13);
  const [infoStore, setInfoStore] = useState({});
  const [showInfoStore, setShowInfoStore] = useState(true);
  const [infoCustomer, setInfoCustomer] = useState({});
  const [showInfoCustomer, setShowInfoCustomer] = useState(true);
  const [deliverManInfo, setDeliverManInfo] = useState({});
  const [showInfoDelivery, setShowInfoDelivery] = useState(true);
  const [socketDeliveryMan, setSocketDeliveryMan] = useState([]);
  const [socket, setSocket] = useState(null);
  const [data, setData] = useState([]);
  const [newData, setNewData] = useState([]);
  const [lastnewData, setLastNewData] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const classes = useStyles();
  const findOrder = () => {
    setDelivery((deliveries || []).find((x) => x._id == orderId));
  };
  const getDeliveryManInThisOrder = () => {
    if (delivery?.deliveryMan?._id) {
      getDeliveryManDispatchedById(delivery.deliveryMan._id)
        .then(({ data }) => {
          data &&
            data.location &&
            data.location.latitude &&
            data.location.longitude &&
            setDeliveryManPathFromDB({
              lat: parseFloat(data.location.latitude),
              lng: parseFloat(data.location.longitude),
            });
        })
        .catch((err) => console.log(err));
    }
  };
  const getCostomerLocation = () => {
    setCustomerPath({
      lat: parseFloat(delivery.destinationAddress?.latitude),
      lng: parseFloat(delivery.destinationAddress?.longitude),
    });
  };

  const _getlocationsHistoryForDeliverManApi = (deliveryManId) => {

    getlocationsHistoryForDeliverManApi(
      page,
      rowsPerPage,
      true,
      deliveryManId,
    ).then(({ data }) => {
      setData(data.deliveryMan);
    });
  };
  useEffect(() => {
    if (delivery?.deliveryMan?._id) {
      //   console.log(provider);
      //   setData([]);
      _getlocationsHistoryForDeliverManApi(delivery?.deliveryMan?._id);
      // socket && socket.disconnect();
      //   socket && socket.disconnect();
      //   setSocket(null);
      //   socket.emit('JOIN_ROOM', provider._id);
    }
  }, []);
  const displayDetailstore = (id) => {
    !showInfoStore && setShowInfoStore(true);
    const infoObj = {};
    if (order?.store?._id == id) {
      infoObj._id = order?.store?._id;
      infoObj.name = order?.store?.name;
      infoObj.phone = order?.store?.phone;
      infoObj.location = order?.store?.location;
      setInfoStore({ ...infoObj });
    }
  };

  const displayDetailCustomer = () => {
    !showInfoCustomer && setShowInfoCustomer(true);
    const infoObj = {};
    infoObj.adress = delivery?.destinationAddress?.address.substring();
    infoObj.name = delivery?.destinationAddress?.contactPerson;
    infoObj.phone = delivery?.destinationAddress?.contactPhone;
    infoObj.location = [
      delivery?.destinationAddress?.latitude,
      delivery?.destinationAddress?.longitude,
    ];
    setInfoCustomer({ ...infoObj });
  };
  const displayDetailDelevary = () => {
    !showInfoDelivery && setShowInfoDelivery(true);
    const infoObj = {};
    infoObj.name = delivery?.deliveryMan?.name;
    infoObj.phone = delivery?.deliveryMan?.phone;
    infoObj.email = delivery?.deliveryMan?.email;
    infoObj.location = delivery?.deliveryMan?.location;

    setDeliverManInfo({ ...infoObj });
  };

  useEffect(() => {
    findOrder();
  }, []);
  useEffect(() => {
    if (delivery && (Object.keys(delivery).length || []) > 0) {
      getDeliveryManInThisOrder();
      setPath({
        lat: parseFloat(delivery?.pickupAddress?.latitude),
        lng: parseFloat(delivery?.pickupAddress?.longitude),
      });
      getCostomerLocation();
      // displayDeliveryGoToSameStore()
    }
  }, [delivery]);
  useEffect(() => {
    if (!socket && (Object.keys(delivery).length || []) > 0) {
      // setSocket(socketConnect(token)); //old socket connect
      setSocket(socketConnectPertnerGps(token)); //new socket connection only for GPS
    }
    function subscribeToAdminRoom() {
      if (socket) {
        socket.emit('JOIN_ROOM', delivery.deliveryMan._id);
      }
    }
    if (socket) {
      subscribeToAdminRoom();
    }
    socket &&
      socket.on('DELIVERYMAN_LOCATION_CHANGED', function (data) {
        if (data.deliveryManId == delivery.deliveryMan._id) {
          setSocketDeliveryMan(data.location);
        }

        if (data.deliveryManId + '' == delivery.deliveryMan._id + '') {

          const {
            deliveryManId,
            name,
            location,
            timestamp,
            batteryLevel,
            gpsStatus,
            appState,
            appVersion,
            socketName,
            isMocked,
            fromNewApp,
            phone,
          } = data;
          let dataToUpdate = {
            deliveryManId: deliveryManId,
            name: name,
            location: location,
            timestamp: timestamp,
            batteryLevel: parseInt((batteryLevel * 100).toString()),
            gpsStatus: gpsStatus + '',
            appState: appState + '',
            appVersion: appVersion + '',
            isMocked: isMocked + '',
            fromNewApp: fromNewApp + '',
            phone: phone,
            dataNew: true,
          };

          setNewData(dataToUpdate);
        }
      });
    return () => {
      socket && socket.disconnect();
    };
  }, [socket, delivery]);
  useEffect(() => {
    if (newData) {
      const d = data;
      const exist = d.find((e) => {
        // console.log('asa**********', new Date(e.timestamp).getTime() ,
        //     new Date(newData.timestamp).getTime())
        return (
          new Date(e.timestamp).getTime() ===
          new Date(newData.timestamp).getTime()
        );
      });
      if (!exist) {
        let dd = lastnewData;
        let i = data.length;
        var startDate = dd ? new Date(dd['timestamp']) : new Date();
        var endDate = new Date(newData.timestamp);
        // console.log('startDate', new Date(startDate), new Date(endDate));
        newData['seconds'] = (endDate.getTime() - startDate.getTime()) / 1000;
        d.unshift(newData);
        setData(d);
        setLastNewData({ ...newData });
      } else {
        // console.log('duplicate socket');
      }
    }
  }, [newData]);
  return (
    <div>

{data && data.length > 0 && (
     <Paper >
     <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.slice(0, 1).map((row) => {
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.code}
                  style={{
                    backgroundColor: row.newData ? '#c1ffbe' : '#ffffff',
                  }}
                >
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align} className={classes.text}  >
                        {value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      </Paper>
 )}



      <LoadScript
        id="script-loader"
        googleMapsApiKey={googleMapsApiKey}
        language="en"
        region="ma"
      >
        <GoogleMap
          mapContainerClassName="map"
          center={path && path}
          zoom={zoom}
          version="weekly"
          on
          options={{
            styles: [
              {
                featureType: 'icon',
                elementType: 'labels',
                stylers: [{ visibility: 'off' }],
              },
            ],
          }}
        >
          {delivery && (Object.keys(delivery).length || []) > 0 && (
            <CustomMarker
              icon={iconsList.store}
              // onClick={() => displayDetailstore(order.store._id)}
              size={40}
              key={delivery.pickupAddress.idMagasin}
              position={path}
            />
          )}

          {delivery &&
            (Object.keys(delivery).length || []) > 0 &&
            delivery?.deliveryMan?._id &&
            deliveryManPathFromDB && (
              <CustomMarker
                onClick={() => displayDetailDelevary()}
                icon={iconsList.deliveryManWithOrder}
                size={40}
                key={delivery.deliveryMan._id}
                position={deliveryManPathFromDB}
              />
            )}

          {delivery && (Object.keys(delivery).length || []) > 0 && (
            <CustomMarker
              icon={iconsList.deliverManWithoutOrde}
              size={40}
              key={iconsList.deliverManWithoutOrde}
              position={{
                lat: parseFloat(socketDeliveryMan ? socketDeliveryMan[0] : ''),
                lng: parseFloat(socketDeliveryMan ? socketDeliveryMan[1] : ''),
              }}
            />
          )}

          {customerPath && (
            <CustomMarker
              onClick={() => displayDetailCustomer()}
              icon={iconsList.client}
              size={50}
              key={delivery?.destinationAddress?.address.substring}
              position={customerPath && customerPath}
            />
          )}

          {showInfoStore && infoStore && Object.keys(infoStore).length !== 0 && (
            <InfoWindow
              onCloseClick={() => {
                setShowInfoStore(false);
              }}
              position={{
                lat: parseFloat(infoStore.location[0]),
                lng: parseFloat(infoStore.location[1]),
              }}
            >
              <div>
                <div style={{ fontSize: 12, fontColor: `#08233B` }}>
                  name: {infoStore?.name}
                  <br />
                  phone : {infoStore.phone}
                  <br />
                </div>
              </div>
            </InfoWindow>
          )}

          {showInfoCustomer &&
            infoCustomer &&
            Object.keys(infoCustomer).length !== 0 && (
              <InfoWindow
                onCloseClick={() => {
                  setShowInfoCustomer(false);
                }}
                position={{
                  lat: parseFloat(infoCustomer.location[0]),
                  lng: parseFloat(infoCustomer.location[1]),
                }}
              >
                <div>
                  <div style={{ fontSize: 12, fontColor: `#08233B` }}>
                    name: {infoCustomer?.name}
                    <br />
                    phone : {infoCustomer.phone}
                    <br />
                    adress : {infoCustomer.adress}
                  </div>
                </div>
              </InfoWindow>
            )}

          {showInfoDelivery &&
            deliverManInfo &&
            Object.keys(deliverManInfo).length !== 0 && (
              <InfoWindow
                onCloseClick={() => {
                  setShowInfoDelivery(false);
                }}
                position={{
                  lat: parseFloat(deliverManInfo.location[0]),
                  lng: parseFloat(deliverManInfo.location[1]),
                }}
              >
                <div>
                  <div style={{ fontSize: 12, fontColor: `#08233B` }}>
                    name: {deliverManInfo?.name}
                    <br />
                    phone : {deliverManInfo.phone}
                    <br />
                    email: {deliverManInfo?.email}
                    <br />
                  </div>
                </div>
              </InfoWindow>
            )}
        </GoogleMap>
      </LoadScript>
    </div>
  );
}
