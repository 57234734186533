import React, { useCallback, useEffect, useRef, useState } from 'react';
import { GoogleMap, LoadScript, Polygon } from '@react-google-maps/api';
import PropTypes from 'prop-types';
import { useAlert } from 'react-alert';
import { Box } from '@material-ui/core';
import { updateCity } from '../services/serverApi.service';
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import { BrowserRouter } from 'react-router-dom';

export default function PolygonMapViewComponent({ item,close }) {
  const alert = useAlert();
  // Store Polygon path in state
  const [path, setPath] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);

  // Define refs for Polygon instance and listeners
  const polygonRef = useRef(null);
  const listenersRef = useRef([]);

  const onUpdate = () => {
    setIsUpdating(true);
    item.cityLocations = path.map((pathItem) => [pathItem.lng, pathItem.lat]);
    updateCity(item._id, { cityLocations: item.cityLocations ,countryId:item.countryId })
      .then(() => {
        // alert.show('City locations has been updated', {
        //   type: 'success',
        // });
        close()
      })
      .catch((error) => {
        alert.show(error.message, {
          type: 'error',
        });
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  // Call setPath with new edited path
  const onEdit = useCallback(() => {
    if (polygonRef.current) {
      const nextPath = polygonRef.current
        .getPath()
        .getArray()
        .map((latLng) => ({ lat: latLng.lat(), lng: latLng.lng() }));
      setPath(nextPath);
    }
  }, []);

  // Bind refs to current Polygon and listeners
  const onLoad = useCallback(
    (polygon) => {
      polygonRef.current = polygon;
      // eslint-disable-next-line no-shadow
      const path = polygon.getPath();
      listenersRef.current.push(
        path.addListener('set_at', onEdit),
        path.addListener('insert_at', onEdit),
        path.addListener('remove_at', onEdit),
      );
    },
    [onEdit],
  );

  // Clean up refs
  const onUnmount = useCallback(() => {
    listenersRef.current.forEach((lis) => lis.remove());
    polygonRef.current = null;
  }, []);

  const ondblclick = useCallback(
    (e) => {
      if (path.length === 0) {
        const latLng = { lat: e.latLng.lat(), lng: e.latLng.lng() };
        setPath([latLng, latLng, latLng]);
      }
    },
    [path],
  );

  useEffect(() => {
    if (item) {
      setPath(
        item.cityLocations.map((locationItem) => ({
          lng: locationItem[0],
          lat: locationItem[1],
        })),
      );
    }
  }, [item]);

  return (
    <div>
      <LoadScript
        id="script-loader"
        googleMapsApiKey="AIzaSyDL_vUKHazFEXiQHtnGJMFXKgKSyuexpXM"
        language="en"
        region="us"
      >
        <GoogleMap
          mapContainerClassName="map"
          center={
            path.length > 0 ? path[0] : { lat: 33.589886, lng: -7.603869 }
          }
          zoom={12}
          version="weekly"
          on
          onDblClick={ondblclick}
        >
          <Polygon
            // Make the Polygon editable / draggable
            editable
            draggable
            path={path}
            // Event used when manipulating and adding points
            onMouseUp={onEdit}
            // Event used when dragging the whole Polygon
            onDragEnd={onEdit}
            onLoad={onLoad}
            onUnmount={onUnmount}
          />
        </GoogleMap>
      </LoadScript>
      <Box mt="1rem" display="flex" justifyContent="flex-end">
        <Button
          disabled={isUpdating || path.length < 3}
          loading={isUpdating}
          onClick={onUpdate}
          color="primary"
        >
          Update
        </Button>
      </Box>
    </div>
  );
}

PolygonMapViewComponent.propTypes = {
  item: PropTypes.any,
};
