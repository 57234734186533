import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { openModelAction } from '../../orders/redux/actions/order.actions';
// icons
import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import LocationOn from '@material-ui/icons/LocationOn';
import MotorcycleIcon from '@material-ui/icons/Motorcycle';
import CloseleIcon from '@material-ui/icons/Close';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import AssessmentIcon from '@mui/icons-material/Assessment';
import CommentIcon from '@mui/icons-material/Comment';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import BlockIcon from '@mui/icons-material/Block';

export default function MenuComponent({ _id, index, blocked }) {
  const dispatch = useDispatch();
  const iconsSharedStyle = {
    cursor: 'pointer',
    width: 21,
    height: 21,
    margin: 2,
    borderRadius: 2,
    padding: 2,
  };
  const sharedStyleForStatus = {
    width: '120px',
    fontSize: '11px',
    padding: '5px',
    borderRadius: '4px',
    color: 'white',
  };
  return (
    <div>
      <Tooltip arrow title="Detail Commande" placement="top">
        <AspectRatioIcon
          style={{
            ...iconsSharedStyle,
            color: '#0705f7',
            backgroundColor: '#0705f730',
          }}
          onClick={() => {
            dispatch(openModelAction(_id, index, 'detail'));
          }}
        />
      </Tooltip>
      <Tooltip arrow title="Assigner Commande" placement="top">
        <MotorcycleIcon
          style={{
            ...iconsSharedStyle,
            color: '#1fd62e',
            backgroundColor: '#1fd62e30',
          }}
          onClick={() => {
            dispatch(openModelAction(_id, index, 'Assign'));
          }}
        />
      </Tooltip>
      <Tooltip arrow title="Annuler la commande" placement="top">
        <CloseleIcon
          style={{
            ...iconsSharedStyle,
            color: '#ff0000',
            backgroundColor: '#ff000030',
          }}
          onClick={() => {
            dispatch(openModelAction(_id, index, 'Cancel'));
          }}
        />
      </Tooltip>
      <Tooltip arrow title="Aide a la decision" placement="top">
        <LocationOn
          style={{
            ...iconsSharedStyle,
            color: '#000000',
            backgroundColor: '#00000030',
          }}
          onClick={() => {
            dispatch(openModelAction(_id, index, 'Map'));
          }}
        />
      </Tooltip>
      <Tooltip arrow title="Changer Status Livreur" placement="bottom">
        <SyncAltIcon
          style={{
            ...iconsSharedStyle,
            color: '#000000',
            backgroundColor: '#00000030',
          }}
          onClick={() => {
            dispatch(openModelAction(_id, index, 'changeStatus'));
          }}
        />
      </Tooltip>
      <Tooltip arrow title="report" placement="bottom">
        <AssessmentIcon
          style={{
            ...iconsSharedStyle,
            color: '#000000',
            backgroundColor: '#00000030',
          }}
          onClick={() => {
            dispatch(openModelAction(_id, index, 'report'));
          }}
        />
      </Tooltip>
      <Tooltip arrow title="position livreur" placement="top">
        <MyLocationIcon
          style={{
            ...iconsSharedStyle,
            color: '#000000',
            backgroundColor: '#00000030',
          }}
          onClick={() => {
            dispatch(openModelAction(_id, index, 'currentPosition'));
          }}
        />
      </Tooltip>
      <Tooltip arrow title="position livreur" placement="top">
        <MyLocationIcon
          style={{
            ...iconsSharedStyle,
            color: 'red',
            backgroundColor: '#00000030',
          }}
          onClick={() => {
            dispatch(openModelAction(_id, index, 'my location'));
          }}
        />
      </Tooltip>
      {blocked && (
        <Tooltip arrow title="Blocked CMD" placement="bottom">
          <BlockIcon
            style={{
              ...iconsSharedStyle,
              color: '#735392',
              backgroundColor: '#00000030',
            }}
            onClick={() => {
              // handleOpenModal('blocked');
              dispatch(openModelAction(_id, index, 'blocked'));
            }}
          />
        </Tooltip>
      )}
    </div>
  );
}
