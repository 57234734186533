import { UPLOAD_IMAGE_REQUEST,UPLOAD_IMAGE_SUCCESS,UPLOAD_IMAGE_FAIL,UPLOAD_IMAGE_RESET,DEFAULT_IMAGE } from './upload.constant';

const initialState = {
  iconUrl: "",
  error:"",
  loading: false,
  newUpload:false
};

export default function uploadReducer(state = initialState, action) {
  // console.log(action.url);
  switch (action.type) {
      case UPLOAD_IMAGE_REQUEST:
        return{
            loading:true
        }
    
    case UPLOAD_IMAGE_SUCCESS:
      return {
        ...state,
        loading:false,
        iconUrl: action.iconUrl.url,
        newUpload:true
      };
    case UPLOAD_IMAGE_FAIL:
      return {
        ...state,
        loading:false,
        error: action?.error.errors[0].message,
      };
    case UPLOAD_IMAGE_RESET:
      return {
        ...state,
        loading:false,
        error: "",
        iconUrl:""
      };
    case DEFAULT_IMAGE:
      return {
        ...state,
        loading:false,
        error: "",
        iconUrl:action.url,
        newUpload:false
      };
    default:
      return state;
  }
}
