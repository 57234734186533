import React, { useEffect, useState } from 'react'
import * as yup from 'yup';
import { useFormik } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import { FormLabel, Box } from '@material-ui/core';
import SelectBoxComponent from '../../../components/SelectBox.component'
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button'
import { updateStore } from '../services/serverApi.service';
import styles from '../../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle';

const useStyles = makeStyles(styles);

const validationSchema = yup.object({
  service: yup.string().required(),
})

export default function StoreServiceComponent({ store, index, stores, setStores, close, services }) {
  console.log(services);
  console.log(store);
  const [isLoading, setIsLoading] = useState(false)
  const classes = useStyles();
  const formik = useFormik({
    initialValues: {
      service: '',
    },
    validationSchema,
  });
  const editStore = () => {
    store.serviceId = formik.values.service
    setIsLoading(true)
    updateStore(store._id, store)
      .then(({ data }) => {
        let newStores = [...stores]
        newStores[index] = data.store
        setStores(newStores)
        close()
      })
      .catch((error) => {
        console.log(error.message);
      })
      .finally(() => setIsLoading(true))
  }
  const setValues = () => {
    formik.setValues({
      service: store.serviceId,
    })
  }

  useEffect(() => {
    setValues()
  }, [])
  return (
    <div>

      <FormLabel className={classes.labelHorizontal}>
        service
      </FormLabel>
      <SelectBoxComponent
        items={services.map((service) => ({
          value: service._id,
          label: service.name,
        }))}
        onValueChange={formik.handleChange('service')}
        value={formik.values.service}
      />
      <Box textAlign="center">
        <Button
          round
          type="submit"
          color="success"
          disabled={!formik.dirty || !formik.isValid || isLoading}
          loading={isLoading}
          loaderColor="white"
          onClick={editStore}
        >
          save service
        </Button>
      </Box>
    </div>
  )
}