import React, { useState, useEffect } from 'react'
import * as yup from 'yup';
import { useFormik } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import { FormLabel, Box } from '@material-ui/core';
import CustomInput from '../../../components/material-dashboard-pro-react/CustomInput/CustomInput'
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button'
import { updateStore } from '../services/serverApi.service';
import styles from '../../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle';

const useStyles = makeStyles(styles);

const validationSchema = yup.object({
  location: yup.array().required(),
})

export default function StoreNameComponent({ store, index, stores, setStores, close }) {
  const [isLoading, setIsLoading] = useState(false)
  const classes = useStyles();
  const formik = useFormik({
    initialValues: {
      location: []
    },
    validationSchema,
  });
  const editStore = () => {
    store.location = formik.values.location
    setIsLoading(true)
    updateStore(store._id, store)
      .then(({ data }) => {
        console.log(data);
        let newStores = [...stores]
        newStores[index] = data.store
        setStores(newStores)
        close()
      })
      .catch((error) => {
        console.log(error.message, {
          type: 'error',
        });
      })
      .finally(() => setIsLoading(true))
  }
  const setValues = () => {
    formik.setValues({
      location: store.location,
    })
  }

  useEffect(() => {
    setValues()
  }, [])
  console.log(formik.values.location);
  console.log(formik.errors);
  return (
    <div>
      <FormLabel className={classes.labelHorizontal}>
        latitude
      </FormLabel>
      <CustomInput
        formControlProps={{
          fullWidth: true,
        }}
        type="number"
        error={
          formik.touched.location && Boolean(formik.errors.location)
        }
        inputProps={{
          value: formik.values.location[0],
          // onBlur: formik.handleBlur('name'),
          // onChange: formik.handleChange('name'),
          onChange: e => { e.target.value != 0 && formik.setFieldValue('location[0]', e.target.value) }
        }}
      />
      <FormLabel className={classes.labelHorizontal}>
        longitude
      </FormLabel>
      <CustomInput
        formControlProps={{
          fullWidth: true,
        }}
        type="number"
        error={
          formik.touched.location && Boolean(formik.errors.location)
        }
        inputProps={{
          value: formik.values.location[1],
          // onBlur: formik.handleBlur('name'),
          // onChange: formik.handleChange('name'),
          onChange: e => { e.target.value != 0 && formik.setFieldValue('location[1]', e.target.value) }
        }}
      />

      <Box textAlign="center">
        <Button
          round
          type="submit"
          color="success"
          disabled={!formik.dirty || !formik.isValid || isLoading}
          loading={isLoading}
          loaderColor="white"
          onClick={editStore}
        >
          save
        </Button>
      </Box>
    </div>
  )
}
