import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useAlert } from 'react-alert';
import moment from 'moment-timezone';
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import CustomInput from '../../../components/material-dashboard-pro-react/CustomInput/CustomInput';
import { createCity, getCountries } from '../services/serverApi.service';
import SelectBoxComponent from '../../../components/SelectBox.component';

const validationSchema = yup.object({
  name: yup.string().required(),
  countryId: yup.string().required(),
  cityLocations: yup.array().required(),
  timeZone: yup.string().required(),
  // paymentGateway: yup.array().of(
  //   yup.object().shape({
  //     name: yup.string().required(),
  //     enabled: yup.bool().required(),
  //   }).required(),
  // )
});

export default function CreateCityComponent({ onItemCreated }) {
  const alert = useAlert();
  const [isLoading, setIsLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const formik = useFormik({
    initialValues: {
      name: '',
      countryId: '',
      cityLocations: [],
      timeZone: moment.tz.guess(),
    //   paymentGateway:[{
    //     name: '',
    //     enabled : ''
    // }]
    },
    validationSchema,
  });

  const onCreate = () => {
    setIsLoading(true);

    createCity(formik.values)
      .then(() => {
        alert.show('City has been created', {
          type: 'success',
        });
        formik.resetForm();
        onItemCreated();
      })
      .catch((error) => {
        alert.show(error.message, {
          type: 'error',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getCountries().then(({ data }) => {
      console.log(data);
      setCountries(data);
    });
  }, []);

  return (
    <div>
      <CustomInput
        labelText="Name"
        formControlProps={{
          fullWidth: true,
        }}
        type="text"
        error={formik.touched.name && Boolean(formik.errors.name)}
        inputProps={{
          value: formik.values.name,
          onBlur: formik.handleBlur('name'),
          onChange: formik.handleChange('name'),
        }}
      />
      <CustomInput
        labelText="Timezone"
        formControlProps={{
          fullWidth: true,
        }}
        type="text"
        error={formik.touched.timeZone && Boolean(formik.errors.timeZone)}
        inputProps={{
          value: formik.values.timeZone,
          onBlur: formik.handleBlur('timeZone'),
          onChange: formik.handleChange('timeZone'),
        }}
      />
      <SelectBoxComponent
        items={countries.map((country) => ({
          value: country._id,
          label: country.name,
        }))}
        inputLabel="Country"
        onValueChange={formik.handleChange('countryId')}
        value={formik.values.countryId}
      />
      <Box textAlign="center">
        <Button
          round
          type="submit"
          color="success"
          disabled={!formik.dirty || !formik.isValid || isLoading}
          loading={isLoading}
          loaderColor="white"
          onClick={onCreate}
        >
          Create
        </Button>
      </Box>
    </div>
  );
}

CreateCityComponent.propTypes = {
  onItemCreated: PropTypes.func.isRequired,
};
