import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useAlert } from 'react-alert';
import { Grid, TextField, Checkbox, Box, Typography } from '@material-ui/core';
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import { updateSetting } from '../services/serverApi.service';

import { initialFormData } from '../mocks/index';

export default function UserNotificationComponent({ setting }) {
  const alert = useAlert();
  const [initialForm, setInitialForm] = useState(initialFormData);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFromData] = useState([]);

  const conctatDataFromApiWithInitialForm = () => {
    if (setting) {
      const user_notifications_settingsApi =
        setting[0]?.user_notifications_settings;
      if (user_notifications_settingsApi.length == 0) {
        setting[0].user_notifications_settings = initialForm;
        updateSetting(setting[0]._id, setting[0])
          .then(({ data }) => {
            console.log(data);
            setFromData(data?.data?.user_notifications_settings);
          })
          .catch((error) => console.log(error));
      } else {
        setFromData(user_notifications_settingsApi);
      }
    } else {
      console.log('setting not found');
    }
  };
  const saveUserParams = () => {
    setting[0].user_notifications_settings = formData;
    updateSetting(setting[0]._id, setting[0])
      .then(({ data }) => {
        setFromData(data?.data?.user_notifications_settings);
        alert.show('saved', {
          type: 'success',
        });
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    setting[0] && conctatDataFromApiWithInitialForm();
  }, [setting, formData]);
  return (
    <div>
      <Grid container spacing={3} justify="center" className="mb-2">
        <Grid item xs={5}></Grid>
        <Grid item xs={3}>
          <Typography> name</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography>notifications</Typography>
        </Grid>
      </Grid>
      {formData &&
        formData.length > 0 &&
        formData.map((item, index) => (
          <Grid container spacing={3}>
            <Grid item xs={2}>
              <Checkbox
                checked={item.send_notif}
                onChange={(e) => {
                  const tempFormData = [...formData];
                  tempFormData[index].send_notif = e.target.checked;
                  setFromData(tempFormData);
                  console.log(e.target.checked);
                }}
              />
            </Grid>
            <Grid item xs={3}>
              {item.key}
            </Grid>
            <Grid item xs={3}>
              <TextField
                type={'text'}
                defaultValue={item.name}
                onChange={(event) => {
                  const tempFormData = [...formData];
                  tempFormData[index].name = event.target.value;
                  setFromData(tempFormData);
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                type={'text'}
                defaultValue={item.message}
                onChange={(event) => {
                  const tempFormData = [...formData];
                  tempFormData[index].message = event.target.value;
                  setFromData(tempFormData);
                }}
              />
            </Grid>
          </Grid>
        ))}
      <Box textAlign="right">
        <Button
          round
          type="submit"
          color="success"
          disabled={isLoading}
          loading={isLoading}
          loaderColor="white"
          onClick={saveUserParams}
        >
          save
        </Button>
      </Box>
    </div>
  );
}

UserNotificationComponent.propTypes = {
  setting: PropTypes.any.isRequired,
};
