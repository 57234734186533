import React, { useEffect, useState } from 'react';
// api
import { getReport, getCallApi } from '../services/server.api.service';
// comp
import ReactJson from 'react-json-view';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Grid,
  Typography,
  Paper,
} from '@mui/material';
import moment from 'moment';
import { callApicolumns } from '../mock';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  table: {
    minWidth: 50,
  },
});

export default function deliveryReportComponent({ deliveryId }) {
  const classes = useStyles();
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  const [report, setReport] = useState({});
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [callApi, setCallApi] = useState([]);
  useEffect(() => {
    setIsLoading(true);
    getReport(deliveryId)
      .then(({ data }) => setReport(data.webHooksReport))
      .catch(
        (err) =>
          err.response.status === 404 && setError(err.response?.data?.message),
      )
      .finally(() => setIsLoading(false));
    getCallApi(deliveryId)
      .then(({ data }) => {
        setCallApi(data.callApi);
      })
      .catch(
        (err) =>
          err.response.status === 404 && setError(err.response?.data?.message),
      );
  }, []);
  return (
    <div>
      {!isLoading && error ? (
        error
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={2}>
            Name
          </Grid>
          <Grid item xs={1}>
            Attempts
          </Grid>
          <Grid item xs={1}>
            date
          </Grid>
          <Grid item xs={1}>
            Initiated
          </Grid>
          <Grid item xs={1}>
            success
          </Grid>
          <Grid item xs={3}>
            Data Sent
          </Grid>
          <Grid item xs={3}>
            finalResult
          </Grid>
          {(Object.keys(report) || []).map(
            (item) =>
              report[item].constructor.name === 'Object' && (
                <>
                  <Grid item xs={2} style={{ overflowWrap: 'break-word' }}>
                    {item}
                  </Grid>
                  <Grid item xs={1}>
                    {report[item]?.attempts}
                  </Grid>
                  <Grid item xs={1}>
                    {report[item]?.date &&
                      moment(report[item].date).format('hh:mm')}
                  </Grid>
                  <Grid item xs={1}>
                    {report[item]?.initiated ? 'yes' : 'no'}
                  </Grid>
                  <Grid item xs={1}>
                    {report[item]?.success ? 'yes' : 'no'}
                  </Grid>
                  <Grid item xs={3}>
                    {report[item]?.data && (
                      <ReactJson
                        src={JSON.parse(report[item]?.data)}
                        displayDataTypes={false}
                        displayObjectSize={false}
                        collapsed={true}
                        name="data"
                      />
                    )}
                  </Grid>
                  <Grid item xs={3}>
                    {report[item]?.finalResult && (
                      <ReactJson
                        src={JSON.parse(report[item]?.finalResult)}
                        displayDataTypes={false}
                        displayObjectSize={false}
                        collapsed={true}
                        name="finalResult"
                      />
                    )}
                  </Grid>
                </>
              ),
          )}
        </Grid>
      )}
      <div className=" mt-2">
        <Paper sx={{ width: '100%' }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table
              className={classes.table}
              stickyHeader
              aria-label="sticky table"
            >
              <TableHead>
                <TableRow>
                  {callApicolumns &&
                    callApicolumns.map((item) => (
                      <TableCell align="center">{item}</TableCell>
                    ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {(callApi || []).map((item) => (
                  <StyledTableRow key={item._id}>
                    <TableCell align="center">
                      {item.createdAt &&
                        moment(item.createdAt).format('MM/DD hh:mm:ss')}
                    </TableCell>
                    <TableCell>{item?.data?.type}</TableCell>
                    <TableCell>{item?.data?.source}</TableCell>
                    <TableCell>
                      {item?.data?.call}
                      {item?.data?.retries && (
                        <span style={{ color: '#4caf50' }}>
                          {' '}
                          / {item?.data?.retries}
                        </span>
                      )}
                    </TableCell>
                    <TableCell>{item?.data?.status}</TableCell>
                    <TableCell>
                      {(item.data?.args?.errors &&
                        item.data?.args?.errors.length > 0 &&
                        item.data?.args?.errors[0]?.message) ||
                        item?.data?.errors}
                    </TableCell>
                    <TableCell>{item?.data?.kaalixId}</TableCell>
                    <TableCell>
                      {item?.data?.data?.marjaneId ||
                        item?.data?.args?.orderData?.code ||
                        item?.data?.marjaneId}
                    </TableCell>
                    <TableCell>
                      {item?.data?.deliveryMan?.name +
                        ' ' +
                        (item?.data?.deliveryManId ||
                          item?.data?.deliveryMan?._id) ||
                        item?.data?.deliveryMan ||
                        item?.data?.body?.deliveryManId ||
                        ''}
                    </TableCell>
                    <TableCell>{item?.data?.marjaneStatus}</TableCell>
                    <TableCell>{item?.data?.kaalixStatus}</TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </div>
  );
}
