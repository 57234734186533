import axios from 'axios';
import { MICROSERVICE_BASE_URL } from '../../../config/backendServer.config';

export function getStores(page, limit, paged, isBusiness, searchText, cityId, serviceId,
  commissionType) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.STORES}/store?page=${page || 0}&limit=${limit || 0
    }&paged=${paged || false}&isBusiness=${isBusiness}&searchText=${searchText || ''}&cityId=${cityId || ""}&serviceId=${serviceId || ''}&commissionType=${commissionType || ''}`,
  );
}

export function updateStore(id, data) {
  return axios.put(`${MICROSERVICE_BASE_URL.STORES}/store/${id}`, data);
}

export function updateStoreTime(id, data) {
  return axios.put(`${MICROSERVICE_BASE_URL.STORES}/store/${id}/timing`, data);
}

export function createStore(data) {
  return axios.post(`${MICROSERVICE_BASE_URL.STORES}/store/`, data);
}

export function duplicateStore(data) {
  return axios.post(`${MICROSERVICE_BASE_URL.STORES}/store/duplicate`, data);
}

export function addPricing(storeId, pricingId) {
  return axios.put(`${MICROSERVICE_BASE_URL.CUSTOMER_PAYMENT}/store/${storeId}/add_pricing/${pricingId}`);
}

// *************************start store mapping
export function getStoresMapping(serviceId) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.CONTENT}/mapping/service/${serviceId}`,
  );
}
export function updateStoresMapping(serviceId, stores, cityId) {
  console.log(serviceId, cityId, stores, 'before axios');
  return axios.post(
    `${MICROSERVICE_BASE_URL.CONTENT}/mapping/service/${serviceId}/stores`, { stores, cityId }
  );
}
export function mappingRebuild(storeId) {
  return axios.post(`${MICROSERVICE_BASE_URL.CONTENT}/mapping/rebuild/store-to-product/${storeId}`)
}
// *************************end store mapping

// for logger audit
export function logStore(body) {
  return axios.post(`${MICROSERVICE_BASE_URL.ADMIN}/logger`, { ...body });
}
export function getOpningConfig() {
  return axios.get(`${MICROSERVICE_BASE_URL.ADMIN}/opning`);
}