import React from 'react';
import styles from '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
} from '@chatscope/chat-ui-kit-react';
import moment from 'moment';

export default function CommentComponent({ order, close, admin }) {
  return (
    <div>
      <MainContainer>
        <ChatContainer>
          <MessageList>
            {(order?.comments || []).map((comment) => (
              <Message
                key={JSON.stringify(comment.date)}
                model={{
                  message: comment.comment,
                  sentTime: moment(comment.date).format('hh:mm'),
                  direction:
                    comment.adminId == admin._id ? 'outgoing' : 'inComming',
                }}
              >
                <Message.Footer
                  sender={comment?.adminName}
                  sentTime={moment(comment.date).format('hh:mm')}
                />
              </Message>
            ))}
          </MessageList>
        </ChatContainer>
      </MainContainer>
    </div>
  );
}
