import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAlert } from 'react-alert';
import { getUserDataState } from '../../auth/redux/selectors';
import TablePagination from '@material-ui/core/TablePagination';
import { Box, Grid } from '@material-ui/core';
import { getCustmore, approuvedCustmore, logCustomer } from '../services/serverApi.customer';
import DataTableContainerComponent from '../../../components/DataTableSortingContainer.component';
import { formatTime } from '../../../helpers/index';
import ModalComponent from '../../../components/Modal.component';
import EditXpPointsComponent from '../components/EditXpPoints.component';
import EditKaalixPayComponent from '../components/EditKaalixPay.component';
import ClientAddressesComponent from '../components/ClientAddresses.component';
import EditPhoneComponent from '../components/EditPhone.component';
import ClientCardComponent from '../components/ClientCard.component';
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import CustomButton from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import SwitchComponent from '../../../components/Switch.component';
import { DATA } from '../../../utils/logAudit';
import { resetObj } from 'utils/resetObj';

export default function CustomersPage() {
  const alert = useAlert();
  const userData = useSelector(getUserDataState);
  const inpRef = React.useRef();
  const [resultsData, setResultsData] = useState({
    total: 0,
    items: [],
    tableItems: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  // const classes = useStyles();
  // const pages = [10, 250, 500]
  const [page, setPage] = useState(0);
  let numberOfRows = [20, 50, 100];
  const [rowsPerPage, setRowsPerPage] = useState(numberOfRows[0]);
  const [openModal, setOpenModal] = useState('');
  const [customers, setCustomers] = useState([]);
  const [customer, setCustomer] = useState({});
  const [indexCustomer, setIndexCustomer] = useState(-1);
  const [searchText, setSearchText] = useState('');
  const [total, setTotal] = useState(0);
  const [subPagination, setSubPagination] = useState([]);
  // admin data :
  const adminData = useSelector(getUserDataState);
  // console.log(adminData);
  const [admin, setAdmin] = useState({
    _id: adminData.user._id,
    email: adminData.user.email,
    username: adminData.user.username
  });
  // handle lock a consumer action :
  const consumerLockLog = (value, item) => {
    // reset object before setting data :
    resetObj(DATA);
    // for logger audit data :
    DATA.admin = admin;
    DATA.actionType = 'Bloquer/Debloquer Client';
    DATA.targetId = item._id;
    DATA.target = 'Customer';
    DATA.loggerDetails = {
      beforeValue: 'Debloquer',
      afterValue: 'Bloquer'
    };
    console.log(DATA);
    // our post here :
    logCustomer(DATA)
      .then(({ data }) => {
        console.log(data);
      })
      .catch((error) => {
        console.log(error.message, {
          type: 'error',
        });
      })
  }

  const handleOpenModal = (item, index, type) => {
    // console.log('type', type, index, item);
    setCustomer(item);
    setIndexCustomer(index);
    setOpenModal(type);
  };
  const closeModal = () => {
    setOpenModal('');
  };

  const prepareShowStore = (item, index) => {
    return {
      ...item,
      name: item.name,
      city: item.city ? item.city : '--',
      phone: (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            handleOpenModal(item, index, 'phone');
          }}
        >
          {item.phone ? item.phone : '--'}
        </div>
      ),
      // item.phone ? item.phone : '--',
      status: item.status ? item.status : '--',
      nbOrders: item.nbOrders ? item.nbOrders : '0',
      // email: item.email,
      createdAt: item.createdAt ? formatTime(item.createdAt) : '--',
      lastOrderdate: item.lastOrderdate ? formatTime(item.lastOrderdate) : '--',
      // card: item.card ? item.card : '--',
      // AdressesCol: item.Adresses ? item.Adresses : '--',
      cardCol: (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            handleOpenModal(item, index, 'card');
          }}
        >
          {item.cards && item.cards.length}
        </div>
      ),
      AdressesCol: (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            handleOpenModal(item, index, 'addresses');
          }}
        >
          {item?.addresses && item.addresses.length}
        </div>
      ),
      Reward: item.Reward ? item.Reward : '--',
      dispatchingMode: item.dispatchingMode ? item.dispatchingMode : '--',
      ReferralCode: item.ReferralCode ? item.ReferralCode : '--',
      xpPointsCol: (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            handleOpenModal(item, index, 'xpPoints');
          }}
        >
          {item.rewardPoints ? item.rewardPoints : '0'}
        </div>
      ),
      kaalixPayCol: (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            handleOpenModal(item, index, 'kaalixPay');
          }}
        >
          {item.kaalixPay ? item.kaalixPay : '0'}
        </div>
      ),
      approved: (
        <SwitchComponent
          onChange={(value) => {
            // console.log(value, item, admin)
            unApproveCustomer('approved', value, item._id, index)
            consumerLockLog(value, item)
          }}
          value="checked"
          checked={item.approved == 'true'}
          label="enabled"
        />
      ),
    };
  };
  const unApproveCustomer = (key, value, _id, index) => {
    // console.log(key,value,_id);
    const body = {};
    body[key] = value;
    approuvedCustmore(_id, body)
      .then(({ data }) => {
        // console.log(data);
        alert.show(data.message, {
          type: 'success',
        });
        const tempResultData = [...customers];
        tempResultData.splice(index, 1);
        setCustomers(tempResultData);
      })
      .catch((error) => {
        setCustomer([]);
      });
  };
  const loadCustomerList = (page, limit, searchTxt) => {
    setIsLoading(true);
    // getStores(page || 0, limit || 20, true,searchTxt)
    getCustmore(page || 0, limit || 20, true, searchTxt)
      .then(({ data }) => {
        // console.log(data.customers);
        setTotal(data.total);
        setCustomers(data.customers);
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setIsLoading(false);
      });
  };
  const SearchComponent = () => {
    return (
      <Grid container>
        <Grid item xs={8}>
          <input
            type="text"
            ref={inpRef}
            placeholder="client search"
            style={{
              border: 'none',
              borderBottom: '1px solid black',
            }}
            defaultValue={searchText}
          />
        </Grid>
        <Grid item xs={4} container justify="flex-end">
          <Box textAlign="left">
            <CustomButton
              round
              type="submit"
              color="success"
              size="sm"
              loaderColor="white"
              // onClick={storeSearch}
              onClick={() => {
                setSearchText(inpRef.current.value);
                page > 0 && setPage(0);
                loadCustomerList(
                  page > 0 ? 0 : page,
                  rowsPerPage,
                  inpRef.current.value,
                );
              }}
            >
              find Client
            </CustomButton>
          </Box>
          {/* <Box textAlign="left">
          <CustomButton
            round
            type="submit"
            color="google"
            size="sm"
            loaderColor="white"
            // onClick={loadCustomerList}
            onClick={() => {
              setSearchText('')
              page>0&&setPage(0)
              loadCustomerList(page>0?0:page, rowsPerPage,"")
            }}
          >
            clear
          </CustomButton>
        </Box> */}
        </Grid>
      </Grid>
    );
  };
  // const loadCustomerList = (page,limit) => {
  //   setIsLoading(true);
  //   // console.log(skip+'aa', limit);
  //   getCustmore(page,limit,true )
  //     .then(({ data }) => {
  //       // console.log(data.customers);
  //       setTotal(data.total)
  //       setCustomers(data.customers)
  //       // setResultsData({
  //       //   total: data.total,
  //       //   // items: data,
  //       //   items: data.customers.map((item, index) => {
  //       //     return {
  //       //       ...item,
  //       //       name: item.name,
  //       //       city: item.city ? item.city : '--',
  //       //       phone: item.phone ? item.phone : '--',
  //       //       status: item.status ? item.status : '--',
  //       //       nbOrders: item.nbOrders ? item.nbOrders : '0',
  //       //       // email: item.email,
  //       //       createdAt: item.createdAt ? formatTime(item.createdAt) : '--',
  //       //       lastOrderdate: item.lastOrderdate ? formatTime(item.lastOrderdate) : '--',
  //       //       cardCol: <div
  //       //       style={{ cursor: 'pointer' }}
  //       //       onClick={() => {
  //       //         handleOpenModal(item, index, 'card');
  //       //       }}
  //       //     >
  //       //       {item.cards&&item.cards.length}
  //       //     </div>,
  //       //       AdressesCol: <div
  //       //         style={{ cursor: 'pointer' }}
  //       //         onClick={() => {
  //       //           handleOpenModal(item, index, 'addresses');
  //       //         }}
  //       //       >
  //       //         {item.addresses.length}
  //       //       </div>,
  //       //       Reward: item.Reward ? item.Reward : '--',
  //       //       dispatchingMode: item.dispatchingMode ? item.dispatchingMode : '--',
  //       //       ReferralCode: item.ReferralCode ? item.ReferralCode : '--',
  //       //       // kaalixPayCol: item.kaalixPay?item.kaalixPay:'--',
  //       //       // xpPointsCol: item.xpPoints?item.xpPoints:'--',
  //       //       xpPointsCol: <div
  //       //         style={{ cursor: 'pointer' }}
  //       //         onClick={() => {
  //       //           handleOpenModal(item, index, 'xpPoints');
  //       //         }}
  //       //       >
  //       //         {item.rewardPoints ? item.rewardPoints : '0'}
  //       //       </div>,
  //       //       kaalixPayCol: <div
  //       //         style={{ cursor: 'pointer' }}
  //       //         onClick={() => {
  //       //           handleOpenModal(item, index, 'kaalixPay');
  //       //         }}
  //       //       >
  //       //         {item.kaalixPay ? item.kaalixPay : '0'}
  //       //       </div>,

  //       //       // _id: item._id?item._id:'--',
  //       //     };
  //       //   }),
  //       //   tableItems: data.customers.map((item) => [
  //       //     // eslint-disable-next-line no-underscore-dangle
  //       //     item.name,
  //       //     item.email,
  //       //     item.currentOrders,
  //       //     item.favoriteStores,
  //       //     item.createdAt ? formatTime(item.createdAt) : '--'
  //       //   ]),
  //       // });
  //     })
  //     .catch(error => console.log(error))
  //     .finally(() => {
  //       setIsLoading(false);
  //     });
  // };

  // useEffect(() => {
  //   loadCustomerList()
  // }, [page, rowsPerPage]);
  useEffect(() => {
    customers &&
      setResultsData({
        items: (customers || []).map((item, index) => {
          return prepareShowStore(item, index);
        }),
      });
  }, [customers]);
  const handlePaginate = (val) => {
    setResultsData({
      items: [],
      tableItems: [],
    });
    // searchText&&
    loadCustomerList(val.pageSelect, val.numberOfRows, searchText);
    setPage(val.pageSelect);
    setRowsPerPage(val.numberOfRows);
  };

  // console.log(customers);

  useEffect(() => {
    if (total) {
      const pages = Math.ceil(total / rowsPerPage);
      if (total > 2000) {
        console.log(total);
        const newPages = [];
        // const start = page==0?page:Number(page-1)
        let start;
        let end;
        const handelStart = () => {
          let startNumber;
          if (page == 0) startNumber = page;
          else if (page > 0) {
            if (page - 100 < 0) startNumber = 0;
            else startNumber = Number(page - 100);
          }
          return startNumber;
        };
        const handelEnd = () => {
          let endNumber;
          if (page + 100 < pages) endNumber = page + 100;
          else endNumber = page + (pages - page);
          return endNumber;
        };
        start = handelStart();
        end = handelEnd();
        for (let i = start; i < end; i++) {
          newPages.push(i);
        }
        setSubPagination(newPages);
      } else {
        setSubPagination([]);
      }
    }
  }, [total, page]);
  // console.log(subPagination);
  return (
    <>
      {subPagination && (
        <DataTableContainerComponent
          total={total}
          subPagination={subPagination}
          // numberOfRowsProps={rowsPerPage}
          resultsData={resultsData}
          page={page + 1}
          initData={resultsData.items}
          searchInp={true}
          SearchComponent={SearchComponent}
          columns={[
            {
              Header: 'Name',
              accessor: 'name',
            },
            {
              Header: 'city',
              accessor: 'city',
            },
            {
              Header: 'phone',
              accessor: 'phone',
            },
            {
              Header: 'email',
              accessor: 'email',
            },
            {
              Header: 'approved',
              accessor: 'approved',
            },
            {
              Header: 'status',
              accessor: 'status',
            },
            {
              Header: 'number of Orders',
              accessor: 'nbOrders',
            },
            {
              Header: 'created At',
              accessor: 'createdAt',
            },
            {
              Header: 'last Order date',
              accessor: 'lastOrderdate',
            },
            {
              Header: 'card',
              accessor: 'cardCol',
            },
            {
              Header: 'Adresses',
              accessor: 'AdressesCol',
            },
            {
              Header: 'Reward',
              accessor: 'Reward',
            },
            {
              Header: 'mode',
              accessor: 'dispatchingMode',
            },
            {
              Header: 'Referral Code',
              accessor: 'ReferralCode',
            },
            {
              Header: 'kaalixPay',
              accessor: 'kaalixPayCol',
            },
            {
              Header: 'KaalixUp',
              accessor: 'xpPointsCol',
            },
            // {
            //   Header: '#',
            //   accessor: '_id',
            // },
          ]}
          title="Customer List"
          isLoading={isLoading}
          loadItemsList={loadCustomerList}
          // actionButtons={{
          // actionButtons={{
          //     view: {
          //       color: 'info',
          //       type: 'view',
          //       component: ViewItemComponent,
          //     },
          //     edit: {
          //       color: 'success',
          //       type: 'edit',
          //       component: EditServiceCategoryComponent,
          //     },
          //     delete: { color: 'danger', type: 'delete' },
          //   }}
          onPaginate={handlePaginate}
          isPaginate={true}
        />
      )}
      {openModal && (
        <ModalComponent
          maxWidth={false}
          close={closeModal}
          visible={openModal === 'xpPoints'}
          title="kaalixUp"
        >
          <EditXpPointsComponent
            customer={customer}
            close={closeModal}
            adminId={userData.accessToken}
            index={indexCustomer}
            customers={customers}
            setCustomers={setCustomers}
          />
        </ModalComponent>
      )}
      {openModal && (
        <ModalComponent
          maxWidth={false}
          close={closeModal}
          visible={openModal === 'kaalixPay'}
          title="kaalixPay"
        >
          <EditKaalixPayComponent
            customer={customer}
            close={closeModal}
            adminId={userData.accessToken}
            index={indexCustomer}
            customers={customers}
            setCustomers={setCustomers}
          />
        </ModalComponent>
      )}
      {openModal && (
        <ModalComponent
          maxWidth={false}
          close={closeModal}
          visible={openModal === 'addresses'}
          title="Client Addresses"
        >
          <ClientAddressesComponent
            addresses={customer.addresses}
            // close={closeModal}
            // adminId={userData.accessToken}
            // index={indexCustomer}
            // customers={customers}
            // setCustomers={setCustomers}
          />
        </ModalComponent>
      )}
      {openModal && (
        <ModalComponent
          maxWidth={false}
          close={closeModal}
          visible={openModal === 'card'}
          title="Client Cards"
        >
          <ClientCardComponent
            cards={customer.cards}
            // close={closeModal}
            // adminId={userData.accessToken}
            // index={indexCustomer}
            // customers={customers}
            // setCustomers={setCustomers}
          />
        </ModalComponent>
      )}
      {openModal && (
        <ModalComponent
          maxWidth={false}
          close={closeModal}
          visible={openModal === 'phone'}
          title="Client Phone"
        >
          <EditPhoneComponent
            phone={customer.phone}
            index={indexCustomer}
            customer={customer}
            setCustomers={setCustomers}
            customers={customers}
            close={closeModal}
          />
        </ModalComponent>
      )}
    </>
  );
}
