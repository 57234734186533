import React, { useEffect, useState } from 'react'
import { Draggable } from "react-beautiful-dnd";
import DragHandleIcon from '@material-ui/icons/DragHandle';
import CloseIcon from '@material-ui/icons/Close';
import { useForm, useFieldArray, Controller } from "react-hook-form";
import Box from '@material-ui/core/Box';
import CustomControllerField from '../../../components/material-dashboard-pro-react/customControllerField/customControllerField'
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button'
import SwitchComponent from '../../../components/Switch.component'

export default function SpecificationListComponent(props) {
  const {
    index,
    length,
    setAllAddressData,
    form: { field, register, control, setValue, getValues, remove },
    dataLength,
    lengthOfList,
setLengthOfList
  } = props;
  // console.log(field);
  const removeWaypoint = () => {
    // setLengthOfList(lengthOfList-1)
    remove(index);
  };
  const removeWaypointNow = (index) => {
    remove(index);
  };

  const handleContactInputChange = evt => {
    const nums = evt.target.value.replace(/[^\d]/g, "");
    setValue(evt.target.name, nums.slice(0, 10));
  };
  useEffect(() => {
    // remove(3);
    // return ()=> {
    // }
  }, [])

  return (
    <Draggable draggableId={field.id} index={index}>
      {provided => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className="panel"
        >
          {
          // index<dataLength&&
          // index&&
            <>
            <div className="panel-head">
            <span>item {index + 1}</span>
          </div>
          <div className="panel-body">
            {/* <div className="waypoint-address">
              
              
            </div> */}
            <div className="waypoint-info" style={{ display: "flex", borderRadius: "10px", border: '1px solid #000', paddingLeft: '10px', justifyContent: 'space-around' }}>
              <DragHandleIcon />
              <div>
                {/* <label htmlFor={`nameInput[${index}]`}>name</label> */}
                <CustomControllerField
                  labelText="name"
                  formControlProps={{
                    fullWidth: false,
                  }}
                  // type="text"
                  name={`list[${index}].name`}
                  control={control}
                  id={`nameInput[${index}]`}
                  defaultValue={field.name}

                  ref={register()}
                  //   onChange={sanitizeInputs}
                  onChange={(evt) => setValue(evt.target.name, evt.target.value)}
                  autoComplete="off"
                  spellCheck="false"
                />


              </div>
              <div>
                {/* <label htmlFor={`priceInput[${index}]`}>price</label> */}
                <CustomControllerField
                  labelText="price (DH)"
                  formControlProps={{
                    fullWidth: false,
                  }}
                  // type="text"
                  name={`list[${index}].price`}
                  control={control}
                  id={`priceInput[${index}]`}
                  defaultValue={field.price}

                  ref={register()}
                  onChange={(evt) => setValue(evt.target.name, evt.target.value)}
                  autoComplete="off"
                  spellCheck="false"
                />

              </div>
              {/* <SwitchComponent
              name={`list[${index}].defaultselected`}
              defaultValue={field.name}
                onChange={(evt)=> setValue(field.defaultselected,evt)}
                // checked={field.defaultselected}
                // error={formik.touched.inStock && Boolean(formik.errors.inStock)}
                label="defaultselected"
                // control={control}
                id={`selectedInput[${index}]`}
                inputRef={register}
                // defaultValue={field.name}
            /> */}
              <Controller
                as={SwitchComponent}
                name={`list[${index}].acceptMultiple`}
                label="acceptMultiple"
                control={control}
                defaultValue={field.acceptMultiple}

              />

              <Controller
                as={SwitchComponent}
                name={`list[${index}].defaultselected`}
                label="defaultselected"
                control={control}
                defaultValue={field.defaultselected}

              />

              {/* </div> */}
              <Box textAlign="center">
                <Button
                  // round
                  type="submit"
                  color="danger"
                  size="sm"
                  // disabled={!formik.dirty || !formik.isValid || isLoading}
                  // loading={isLoading}
                  loaderColor="white"
                  onClick={removeWaypoint}
                >
                  delete
                </Button>
              </Box>
            </div>

          </div>
          </>}
        </div>
      )}
    </Draggable>
  )
}
