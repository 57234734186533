import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getOrdersDataState } from '../redux/selectors';
// material componenet
import {
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Typography,
  Paper,
  Button,
  Tooltip,
  Box,
} from '@mui/material';

export default function InfoOrdersComponent({
  cities,
  setSelectedOrders,
  selectedOrders,
  filterBy,
  setFilterBy,
}) {
  const { orders, loading, error, watcherCounter } =
    useSelector(getOrdersDataState);
  const [totalOrders, setTotalOrders] = useState({});
  const [selectedCityOrders, setSelectedCityOrders] = useState([]);
  const [totalOrdersByCity, setTotalOrdersByCity] = useState({});

  const selectedCell = {
    backgroundColor: '#3496ff',
    borderRadius: '50%/50%',
    padding: '5px',
    color: '#FFFFFF',
    cursor: 'pointer',
    textAlign: 'center',
  };
  const checkIfCitySelected = (cityId) => {
    if ((selectedCityOrders || []).find((x) => x == cityId))
      return { ...selectedCell };
    return { cursor: 'pointer' };
  };
  const checkIfSelected = (cityId, status) => {
    if ((selectedOrders[cityId] || []).find((x) => x == status))
      return { ...selectedCell };
    return { cursor: 'pointer' };
  };

  const selectOrders = (cityId, status) => {
    const tempSelectedOrders = { ...selectedOrders };
    const tempTotalOrders = { ...totalOrders };
    const tempSelectedCityOrders = [...selectedCityOrders];
    filterBy != 'orders' && setFilterBy('orders');
    const selectedCityIndex = (tempSelectedCityOrders || []).findIndex(
      (x) => x == cityId,
    );
    if (status) {
      if (tempSelectedOrders[cityId]) {
        if (tempSelectedOrders[cityId].includes(status)) {
          const findIndex = tempSelectedOrders[cityId].findIndex(
            (x) => x == status,
          );
          findIndex != -1 && tempSelectedOrders[cityId].splice(findIndex, 1);
          tempSelectedOrders[cityId].length == 0 &&
            delete tempSelectedOrders[cityId];
        } else {
          tempSelectedOrders[cityId].push(status);
        }
      } else {
        tempSelectedOrders[cityId] = [status];
      }
    } else {
      if (selectedCityIndex != -1) {
        tempSelectedCityOrders.splice(selectedCityIndex, 1);
        delete tempSelectedOrders[cityId];
      } else {
        tempSelectedCityOrders.push(cityId);
        tempSelectedOrders[cityId] = Object.keys(
          tempTotalOrders[cityId].status,
        );
      }
    }
    setSelectedOrders(tempSelectedOrders);
    setSelectedCityOrders(tempSelectedCityOrders);
  };
  const totalOrdersNumber = () => {
    const totalOrdersByCity = {};
    (orders || []).map((order) => {
      totalOrdersByCity[order.city._id] = {
        total: (totalOrdersByCity[order.city._id]?.total || 0) + 1,
        status: {
          ...totalOrdersByCity[order.city._id]?.status,
          [order.deliveryManStatus]:
            (totalOrdersByCity[order.city._id]?.status[
              order.deliveryManStatus
            ] || 0) + 1,
        },
      };
    });
    setTotalOrders(totalOrdersByCity);
  };
  const totalOrderInCity = () => {
    const TotalOrdersByCityObj = {};
    const allOrders = orders;
    (allOrders || []).map((order) => {
      TotalOrdersByCityObj[order.city._id] =
        (TotalOrdersByCityObj[order.city._id] || 0) + 1;
    });
    setTotalOrdersByCity(TotalOrdersByCityObj);
  };
  useEffect(() => {
    if (filterBy != 'orders') {
      setSelectedOrders({});
      setSelectedCityOrders([]);
    }
  }, [filterBy]);
  useEffect(() => {
    totalOrdersNumber();
  }, [watcherCounter]);
  useEffect(() => {
    orders && totalOrderInCity();
  }, [(orders || []).length]);
  return (
    <div>
      <TableContainer component={Paper}>
        <Typography className="mt-2 ml-4">Livreur info</Typography>
        <Table aria-label="collapsible table" style={{width: 'auto',tableLayout:'auto'}}>
          <TableHead scope='row' colSpan='4'>
            <TableRow>
              <TableCell align="center" >Ville</TableCell>
              <TableCell align="center">Orders</TableCell>
              <TableCell align="center" width={200}>Mode</TableCell>
              <TableCell align="center" width={200}>WAP</TableCell>
              <TableCell align="center" width={200}>AC</TableCell>
              <TableCell align="center" width={200}>CFD</TableCell>
              <TableCell align="center" width={200}>ATP</TableCell>
              <TableCell align="center" width={200}>UI</TableCell>
              <TableCell align="center" width={200}>SD</TableCell>
              <TableCell align="center" width={200}>ATD</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cities.map((city) => {
              return (
                totalOrdersByCity[city._id] && (
                  <TableRow>
                    <TableCell align="center">{city?.name?.substring(0, 4)}</TableCell>
                    <TableCell
                      align="center"
                      onClick={(e) =>
                        totalOrders[city._id]?.total &&
                        selectOrders(city._id, '')
                      }
                    >
                      <span style={checkIfCitySelected(city._id)}>
                        {totalOrdersByCity[city?._id] || '--'}
                      </span>
                    </TableCell>
                    <TableCell align="center">{city?.dispatchingMode}</TableCell>

                    <TableCell
                      align="center"
                      onClick={(e) =>
                        totalOrders[city._id]?.status?.WAITING_FOR_ACCEPT &&
                        selectOrders(city._id, 'WAITING_FOR_ACCEPT')
                      }
                    >
                      {' '}
                      <span
                        style={checkIfSelected(city._id, 'WAITING_FOR_ACCEPT')}
                      >
                        {totalOrders[city._id]?.status?.WAITING_FOR_ACCEPT ||
                          '--'}
                      </span>
                    </TableCell>
                    <TableCell
                    align="center"
                      onClick={(e) =>
                        totalOrders[city._id]?.status?.ACCEPTED &&
                        selectOrders(city._id, 'ACCEPTED')
                      }
                    >
                      <span style={checkIfSelected(city._id, 'ACCEPTED')}>
                        {totalOrders[city._id]?.status?.ACCEPTED || '--'}
                      </span>
                    </TableCell>
                    <TableCell
                      align="center"
                      onClick={(e) =>
                        totalOrders[city._id]?.status?.COMING_FOR_DELIVERY &&
                        selectOrders(city._id, 'COMING_FOR_DELIVERY')
                      }
                    >
                      <span
                        style={checkIfSelected(city._id, 'COMING_FOR_DELIVERY')}
                      >
                        {totalOrders[city._id]?.status?.COMING_FOR_DELIVERY ||
                          '--'}
                      </span>
                    </TableCell>
                    <TableCell
                      align="center"
                      onClick={(e) =>
                        totalOrders[city._id]?.status?.ARRIVED_TO_PICKUP &&
                        selectOrders(city._id, 'ARRIVED_TO_PICKUP')
                      }
                    >
                      <span
                        style={checkIfSelected(city._id, 'ARRIVED_TO_PICKUP')}
                      >
                        {totalOrders[city._id]?.status?.ARRIVED_TO_PICKUP ||
                          '--'}
                      </span>
                    </TableCell>
                    <TableCell
                      align="center"
                      onClick={(e) =>
                        totalOrders[city._id]?.status?.UPLOAD_INVOICE &&
                        selectOrders(city._id, 'UPLOAD_INVOICE')
                      }
                    >
                      <span style={checkIfSelected(city._id, 'UPLOAD_INVOICE')}>
                        {totalOrders[city._id]?.status?.UPLOAD_INVOICE || '--'}
                      </span>
                    </TableCell>
                    <TableCell
                      align="center"
                      onClick={(e) =>
                        totalOrders[city._id]?.status?.STARTED_DELIVERY &&
                        selectOrders(city._id, 'STARTED_DELIVERY')
                      }
                    >
                      <span
                        style={checkIfSelected(city._id, 'STARTED_DELIVERY')}
                      >
                        {totalOrders[city._id]?.status?.STARTED_DELIVERY ||
                          '--'}
                      </span>
                    </TableCell>
                    <TableCell
                      align="center"
                      onClick={(e) =>
                        totalOrders[city._id]?.status?.ARRIVED_TO_DESTINATION &&
                        selectOrders(city._id, 'ARRIVED_TO_DESTINATION')
                      }
                    >
                      <span
                        style={checkIfSelected(
                          city._id,
                          'ARRIVED_TO_DESTINATION',
                        )}
                      >
                        {totalOrders[city._id]?.status
                          ?.ARRIVED_TO_DESTINATION || '--'}
                      </span>
                    </TableCell>
                  </TableRow>
                )
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
