import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useAlert } from 'react-alert';
import {
  Collapse,
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ServiceListComponent from '../components/ServiceList.component';
import GridContainer from '../../../components/material-dashboard-pro-react/Grid/GridContainer';
import GridItem from '../../../components/material-dashboard-pro-react/Grid/GridItem.js';
import Card from '../../../components/material-dashboard-pro-react/Card/Card';
import CardHeader from '../../../components/material-dashboard-pro-react/Card/CardHeader';
import CardBody from '../../../components/material-dashboard-pro-react/Card/CardBody';
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import AsyncSelect from 'react-select/async';

import SelectBoxComponent from '../../../components/SelectBox.component';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import Checkbox from '@material-ui/core/Checkbox';
import CloseIcon from '@material-ui/icons/Close';

// import { getStoresMapping, updateStoresMapping } from '../services/serverApi.service'
import {
  getCities,
  getCitiesMapping,
  editCitiesMapping,
} from '../services/serverApi.service';
import {
  clearSession,
  getAdmins,
  getAdminDatatWithList,
  setAdminWithList,
} from '../services/serverApi.service';

export default function AdminWitheLisPage() {
  const alert = useAlert();
  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [admins, setAdmins] = useState([]);
  const [witheList, setWitheList] = useState([]);

  const useRowStyles = makeStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
  });
  const fetchWhiteListed = () => {
    getAdminDatatWithList()
      .then(({ data }) => {
        setAdmins(data?.listOfAdmin || []);
        setWitheList(data?.adminWhiteList?.witheList || []);
      })
      .catch((err) => console.log(err));
  };
  const addAdminToWhiteList = (userId, admin) => {
    console.log('userId', userId);
    const findAdmin = witheList.find((x) => x == userId);
    if (!findAdmin) {
      // console.log('we can added');
      // console.log('witheList', witheList);
      const dataToSend = witheList;
      dataToSend.push(userId);
      // console.log('dataToSend', dataToSend);
      setAdminWithList(dataToSend)
        .then(({ data }) => {
          setWitheList(data?.adminWhiteList?.witheList || []);
          const tempAdmin = admins;
          tempAdmin.push(admin);
          console.log('tempAdmin', tempAdmin);
          setAdmins([...tempAdmin]);
        })
        .catch((err) => console.log(err));
    } else {
      alert.show('already added', {
        type: 'info',
      });
    }
  };
  const classes = useRowStyles();
  let timer1;
  const fetchData = (inputValue, callback) => {
    clearTimeout(timer1);
    timer1 = setTimeout(() => {
      inputValue &&
        getAdmins(inputValue)
          .then(({ data }) => {
            const tempArray = [];
            if (data.admins.length) {
              data.admins.forEach((element) => {
                tempArray.push({
                  label: `${element.username} / ${element?.email}`,
                  name: `${element.username.substring(0, 27)}`,
                  _id: element?._id,
                  email: element?.email,
                  userId: element?.userId,
                });
              });
            }

            callback(tempArray);
          })
          .catch((error) => {
            console.log(error);
          });
    }, 2000);
  };

  const clearSessions = () => {
    clearSession()
      .then(({ data }) =>
        alert.show('session cleared to all admins', {
          type: 'success',
        }),
      )
      .catch((err) => console.log(err));
  };
  const removeAdminFromWitheList = (id) => {
    const wihteListTemp = witheList.filter((x) => x !== id);
    setAdminWithList(wihteListTemp)
      .then(({ data }) => {
        setWitheList(data?.adminWhiteList?.witheList || []);
        setAdmins(admins.filter((x) => x.userId !== id));
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    fetchWhiteListed();
  }, []);
  useEffect(() => {}, [...admins]);
  return (
    <GridContainer>
      <Card>
        <CardHeader>Admins WhiteList</CardHeader>
        <CardBody>
          <GridContainer className={classes.gridContainer}>
            <GridItem xs={10} sm={6} md={8}>
              <AsyncSelect
                single
                isClearable
                defaultValue={inputValue}
                loadOptions={fetchData}
                placeholder="find Admin"
                onChange={(e) => {
                  console.log(e);
                  if (e.userId) {
                    addAdminToWhiteList(e.userId, e);
                    // setStore(e);
                  } else {
                    setStore(null);
                  }
                }}
              />
            </GridItem>
            <GridItem xs={2} sm={6} md={4}>
              <Box textAlign="right">
                <Button
                  round
                  type="submit"
                  color="success"
                  disabled={isLoading}
                  loading={isLoading}
                  loaderColor="white"
                  onClick={clearSessions}
                >
                  clear Session
                </Button>
              </Box>
            </GridItem>
            {(admins || []).map((x) => (
              <>
                {/* <GridItem xs={2} sm={6} md={0.2}>
                  <CloseIcon
                    onClick={() => removeAdminFromWitheList(x?.userId)}
                    fontSize="small"
                    style={{ color: 'red' }}
                  />
                </GridItem> */}
                <GridItem xs={2} sm={6} md={12} style={{ marginTop: 10 }}>
                  <CloseIcon
                    onClick={() => removeAdminFromWitheList(x?.userId)}
                    fontSize="small"
                    style={{ color: 'red', marginRight: 10 }}
                  />
                  {x?.email}
                </GridItem>
              </>
            ))}
          </GridContainer>

          {/* {services&&services.length>0&& */}
          {/* <Box textAlign="right">
            <Button
              round
              type="submit"
              color="success"
              disabled={isLoading}
              loading={isLoading}
              loaderColor="white"
              onClick={onEdit}
            >
              save
            </Button>
          </Box> */}
        </CardBody>
      </Card>
    </GridContainer>
  );
}
