import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import CutomeButton from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import { mappingRebuild } from '../services/serverApi.service';

export default function RecreateComponenet({ store, close }) {
  console.log(store.name, store._id);
  const [isLoading, setIsLoading] = useState(false);

  const recreateStore = () => {
    setIsLoading(true);
    mappingRebuild(store._id)
      .then(({ data }) => {
        close();
      })
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
  };
  return (
    <div>
      <Typography>
        NB: cette action va changer le classement des (Products, Items) ,
        utiliser en cas de probleme de mapping seulement
      </Typography>
      <Box textAlign="center">
        <CutomeButton
          round
          type="submit"
          color="success"
          disabled={isLoading}
          loading={isLoading}
          loaderColor="white"
          onClick={recreateStore}
        >
          OK
        </CutomeButton>
      </Box>
    </div>
  );
}
