import React, { useEffect, useState } from 'react'
import { TextField, Box } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { getKaalixUpConfig,updateKaalixUpConfig } from '../services/serverApi.service'
import DataTableSortingContainer from '../../../components/DataTableSortingContainer.component'
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import ModalComponent from '../../../components/Modal.component';
import EditeAdvantageComponent from '../components/EditeAdvantage.component'


export default function kaalixUpConfigPage() {
    const [kaalixUpConfig, setKaalixUpConfig] = useState([])
    const [openModal, setOpenModal] = useState('');
    const [kaalixUpId, setKaalixUpId] = useState('');
    const [item, setItem] = useState([]);
    const [indexitem, setIndexItem] = useState(-1);
    const [resultsData, setResultsData] = useState({
        statusList: [],
    });
    const [isLoading, setIsLoading] = useState(false)
    const columns = [
        {
            Header: 'Name',
            accessor: 'name',
        },
        {
            Header: 'MultiplyFactor',
            accessor: 'rewardMultiplyFactor',
        },
        {
            Header: 'Treshold',
            accessor: 'tresholdInp',
        },
        {
            Header: 'advantages',
            accessor: 'advantagesInp',
        },
        {
            Header: 'Intervals',
            accessor: 'intervalsInp',
        },
        // {
        //     Header: 'XPpoints',
        //     accessor: 'xpInpMember',
        // }
    ]
    const xpColumns = [
        {
            Header :'name',
            accessor: 'range'
        },
        {
            Header :'XP Member',
            accessor: 'xpMember'
        },
        {
            Header :'XP Silver',
            accessor: 'xpSilver'
        },
        {
            Header :'XP Gold',
            accessor: 'xpGold'
        },
    ]
    const setDataToState = (data) => {
        console.log("data", data);
        let statusListArray = data?.kaalixUpConfig?.statusList || data.kaalixUpConfig
        let intervalsArray = data?.kaalixUpConfig?.intervals || data.kaalixUpConfig
        setResultsData({
            statusList: data?.kaalixUpConfig?.statusList.length > 0 && data?.kaalixUpConfig.statusList.map((item, index) => {
                return {
                    ...item,
                    name: (
                        <div>
                            <TextField
                                type={'text'}
                                defaultValue={item.name || resultsData.statusList[index].name || statusListArray[index].name}
                                onChange={(event) => {
                                    console.log(statusListArray);
                                    statusListArray[index].name = event.target.value
                                    setDataToState({ kaalixUpConfig: { statusList: statusListArray,intervals: data?.kaalixUpConfig?.intervals} })
                                }}
                                InputProps={{ disableUnderline: true }}
                            />
                        </div>
                    ),
                    rewardMultiplyFactor: (
                        <div>
                            <TextField
                                type={'number'}
                                defaultValue={
                                    !item.rewardMultiplyFactor ? '0'
                                        : item.rewardMultiplyFactor == '' ? ''
                                            :
                                            item.rewardMultiplyFactor || resultsData.statusList[index].rewardMultiplyFactor || statusListArray[index].rewardMultiplyFactor}
                                onChange={(event) => {
                                    console.log(statusListArray);
                                    statusListArray[index].rewardMultiplyFactor = parseFloat(event.target.value)
                                    setDataToState({ kaalixUpConfig: { statusList: statusListArray,intervals: data?.kaalixUpConfig?.intervals } })
                                }}
                                InputProps={{ disableUnderline: true }}
                            />
                        </div>
                    ),
                    tresholdInp: (
                        <div>
                            <TextField
                                type={'number'}
                                defaultValue={
                                    !item.treshold ? '0'
                                        : item.treshold == '' ? ''
                                            :
                                            item?.treshold || resultsData?.statusList[index].treshold || statusListArray[index].treshold}
                                onChange={(event) => {
                                    console.log(statusListArray);
                                    statusListArray[index].treshold = parseFloat(event.target.value)
                                    setDataToState({ kaalixUpConfig: { statusList: statusListArray,intervals: data?.kaalixUpConfig?.intervals } })
                                }}
                                InputProps={{ disableUnderline: true }}
                            />
                        </div>
                    ),
                    intervalsInp: (
                        <div style={{display: 'flex'}}>
                            <TextField
                                type={'number'}
                                defaultValue={
                                    !data?.kaalixUpConfig?.intervals[index].start ? '0'
                                        : data?.kaalixUpConfig?.intervals[index].start == '' ? ''
                                            :
                                            data?.kaalixUpConfig?.intervals[index].start || kaalixUpConfig?.intervals[index].start }
                                onChange={(event) => {
                                    console.log(statusListArray);
                                    intervalsArray[index].start = parseFloat(event.target.value)
                                    setDataToState({ kaalixUpConfig: { statusList: statusListArray,intervals: intervalsArray } })
                                }}
                                InputProps={{ disableUnderline: true }}
                            />
                            <TextField
                                type={'number'}
                                defaultValue={
                                    !data?.kaalixUpConfig?.intervals[index].end ? '0'
                                        : data?.kaalixUpConfig?.intervals[index].end == '' ? ''
                                            :
                                            data?.kaalixUpConfig?.intervals[index].end || kaalixUpConfig?.intervals[index].end }
                                    onChange={(event) => {
                                        console.log(statusListArray);
                                        intervalsArray[index].end = parseFloat(event.target.value)
                                        setDataToState({ kaalixUpConfig: { statusList: statusListArray,intervals: intervalsArray } })
                                    }}
                                InputProps={{ disableUnderline: true }}
                            />
                        </div>
                    ),
                    // xpInpMember:(
                    //     <div>
                    //         <TextField
                    //             type={'number'}
                    //             defaultValue={
                    //                 findXPValues()
                    //                 // const row =data.kaalixUpConfig?.intervals.find((element) => element.value==data?.kaalixUpConfig?.intervals[index])
                    //                 // const aa ='ee'
                    //                 // !data?.kaalixUpConfig?.intervals[index].xpGain[index].value ? '0'
                    //                 //     : data?.kaalixUpConfig?.intervals[index].xpGain[index].value == '' ? ''
                    //                 //         :
                    //                 //         data?.kaalixUpConfig?.intervals[index].xpGain[index].value || kaalixUpConfig?.intervals[index].xpGain[index].value
                    //                      }
                    //                 onChange={(event) => {
                    //                     console.log(statusListArray);
                    //                     intervalsArray[index].end = parseFloat(event.target.value)
                    //                     setDataToState({ kaalixUpConfig: { statusList: statusListArray,intervals: intervalsArray } })
                    //                 }}
                    //             InputProps={{ disableUnderline: true }}
                    //         />
                    //     </div>
                    // ),
                    advantagesInp: (
                        <div
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                handleOpenModal(item, index, 'Editeadvantge');
                            }}>
                            {item.advantages.length}
                        </div>
                    )
                }
            }),
            intervals:data?.kaalixUpConfig?.intervals.length > 0 && data?.kaalixUpConfig.intervals.map((item, index) => {
                return{
                    ...item,
                    range:item.start+'-'+item.end,
                    xpMember:(
                        <div>
                             <TextField
                                type={'number'}
                                defaultValue={
                                    !item.xpGain[0].value ? '0'
                                        : item.xpGain[0].value == '' ? ''
                                            :
                                            item.xpGain[0].value || kaalixUpConfig?.intervals[index].xpGain[0].value }
                                    onChange={(event) => {
                                        console.log(statusListArray);
                                        intervalsArray[index].xpGain[0].value = parseFloat(event.target.value)
                                        setDataToState({ kaalixUpConfig: { statusList: statusListArray,intervals: intervalsArray } })
                                    }}
                                InputProps={{ disableUnderline: true }}
                            />
                        </div>
                    ),
                    // {/* item.xpGain[0].value, */} 
                    xpSilver:(
                        <div>
                             <TextField
                                type={'number'}
                                defaultValue={
                                    !item.xpGain[1].value ? '0'
                                        : item.xpGain[1].value == '' ? ''
                                            :
                                            item.xpGain[1].value || kaalixUpConfig?.intervals[index].xpGain[1].value }
                                    onChange={(event) => {
                                        console.log(statusListArray);
                                        intervalsArray[index].xpGain[1].value = parseFloat(event.target.value)
                                        setDataToState({ kaalixUpConfig: { statusList: statusListArray,intervals: intervalsArray } })
                                    }}
                                InputProps={{ disableUnderline: true }}
                            />
                        </div>
                    ),
                    xpGold:(
                        <div>
                             <TextField
                                type={'number'}
                                defaultValue={
                                    !item.xpGain[2].value ? '0'
                                        : item.xpGain[2].value == '' ? ''
                                            :
                                            item.xpGain[2].value || kaalixUpConfig?.intervals[index].xpGain[2].value }
                                    onChange={(event) => {
                                        console.log(statusListArray);
                                        intervalsArray[index].xpGain[2].value = parseFloat(event.target.value)
                                        setDataToState({ kaalixUpConfig: { statusList: statusListArray,intervals: intervalsArray } })
                                    }}
                                InputProps={{ disableUnderline: true }}
                            />
                        </div>
                    ),
                }
            })
        })
    }
    const findXPValues =()=>{
        return '12'
    }
    const getConfig = () => {
        getKaalixUpConfig()
            .then(({ data }) => {
                console.log(data.kaalixUpConfig);
                // const statusListArray = data.kaalixUpConfig
                // let statusListArray = data.kaalixUpConfig.statusList
                setKaalixUpConfig(data.kaalixUpConfig)
                setDataToState(data)
            })
            .catch((error) => console.log(error))
    }
    const saveChanges = () => {
        // console.log(resultsData)
        // console.log(kaalixUpConfig)
        setIsLoading(true)
        updateKaalixUpConfig(kaalixUpConfig)
        .then(({data}) =>{
            closeModal()
            getConfig()
            console.log(data)
        })
        .catch((error) => console.log(error))
        .finally(() => setIsLoading(false))
    }
    console.log(kaalixUpConfig);
    const closeModal = () => {
        // console.log('closed');
        // getConfig()
        setOpenModal('');
    };
    const handleOpenModal = (item, index, type) => {
        console.log('type', type, index, item);
        setItem(item);
        setIndexItem(index);
        setOpenModal(type);
    };
    const addNewRow = ()=>{
        console.log(kaalixUpConfig);
    }
    useEffect(() => {
        getConfig()
    }, [])
    // console.log(kaalixUpConfig);
    return (
        <div>
            {resultsData?.statusList.length > 0 &&
                <>
                    <Box textAlign="right">
                        <Button
                            round
                            type="submit"
                            color="success"
                            disabled={isLoading}
                            loading={isLoading}
                            loaderColor="white"
                            onClick={saveChanges}
                        >
                            save
                        </Button>
                    </Box>
                    <DataTableSortingContainer
                        resultsData={resultsData}
                        initData={resultsData.statusList}
                        columns={columns}
                        title="Kaalix UP Config"
                        isLoading={isLoading}
                        loadItemsList={getConfig}
                    // customColumnStyle={customColumnStyle}
                    />
                    <DataTableSortingContainer
                        resultsData={resultsData}
                        initData={resultsData.intervals}
                        columns={xpColumns}
                        title="XP Points"
                        isLoading={isLoading}
                        loadItemsList={getConfig}
                    // customColumnStyle={customColumnStyle}
                    />
                    {openModal && (
                        <ModalComponent
                            maxWidth={false}
                            close={closeModal}
                            visible={openModal === 'Editeadvantge'}
                            title="advantages"
                        >
                            <EditeAdvantageComponent item={item} allData={kaalixUpConfig} setKaalixUpConfig={setKaalixUpConfig} close={closeModal} index={indexitem} saveChanges={saveChanges}/>
                        </ModalComponent>
                    )}
                    
                </>
            }
        </div>

    )
}
