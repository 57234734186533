import React, { useState, useRef, useEffect } from 'react'
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { ErrorMessage, FieldArray, Form, Formik,Field } from 'formik';
// import { Box, InputLabel } from '@material-ui/core'
import { Box,FormControlLabel, Checkbox, Grid, Select, MenuItem, TextField, FormControl } from '@material-ui/core';
import { useAlert } from 'react-alert';
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import AddIcon from '@material-ui/icons/Add';
import {updateTag} from '../services/serverApi.service'

import TagList from './TagsList.component'

import CustomControllerField from '../../../components/material-dashboard-pro-react/customControllerField/customControllerField';

import CustomInput from '../../../components/material-dashboard-pro-react/CustomField/CustomField';
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import ImageUpload from '../../../components/material-dashboard-pro-react/CustomUpload/CustomMultiUpload';


const validationSchema = yup.object({
    name: yup.string().required('name is required'),
    tags: yup.array().of(
        yup.object().shape({
            name: yup.string().required('name is required'),
            iconUrl: yup.string().required('image is required'),
        })
    )
})
export default function EditTagsComponent({data, onItemEdited,close }) {
    const alert = useAlert();
    const MyForm = useRef()
    // console.log(data);
    const tagData = data
    const upload = useSelector((state) => state.multiUploadReducer)
    const { multiIconUrl, error, loading, newUpload } = upload
    const [isLoading, setIsLoading] = useState(false)
    const [visible, setVisible] = useState(false)
    const [tags, setTags] = useState([])
    const [errorNameState, setErrorNameState] = useState(false)
    const [errorTagState, setErrorTagState] = useState(false)
    // const initialValues = {
    //     name: "",
    //     tags: [
    //         { name: "", iconUrl: "" }
    //     ]

    // }
    const { register, control, getValues, setValue, errors, watch, handleSubmit, reset } = useForm({
        defaultValues: {
            name: data?.name,
            // maxChoices: 1,
            // storeId: storeId,
            tags: Array(data?.tags.length).fill(
                {
                    name: '',
                    iconUrl: '',
                    // defaultselected: false,
                    // acceptMultiple: false
                },
            )

        }
    });
    const { fields, append, remove, swap } = useFieldArray({
        control,
        name: "tags"
    });
    const handlePanelDragEnd = result => {
        const { source, destination } = result;
        console.log(source, destination);
        console.log(source, destination);
        if (!destination) return;
        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        )
            return;

        //swap the fields
        swap(source.index, destination.index);
        console.log("after swap: ", getValues().tags);
    };
    const setAllAddressData = (data, index) => {
        setValue(`tags[${index}].name`, data.name),
        setValue(`tags[${index}].iconUrl`, data.iconUrl)
        // setValue(`list[${index}].price`, data.price);
        // setValue(`list[${index}].defaultselected`, data.defaultselected);
        // setValue(`list[${index}].acceptMultiple`, data.acceptMultiple);

    };
    const renderPanels = () => {
        // console.log('fields',fields);
        return  <div style={{ display: 'flex', overflowX: 'auto' }}>
                { fields.map((field, index) => (
            <TagList
                key={field.id}
                index={index}
                length={fields.length}
                setAllAddressData={setAllAddressData}
                iconUrl={field.iconUrl}
                form={{ field, register, control, setValue, getValues, remove }}
            />
            ))}
            </div>
    };
    const onSubmit = async (data) => {
        // console.log('fields on submiit',fields);
        // data.tags = fields
        console.log('data send',data);
        let errorName = '';
        let errorTag = [];
        if(data?.name==""||data?.name==undefined){
            errorName=true
            setErrorNameState(true)
        }else{
            errorName=false
            setErrorNameState(false)
        }
        data.tags&&data.tags.length&& data.tags.map((tag)=>{
            if(tag?.name==""||tag.name==undefined||tag?.iconUrl==""||tag.iconUrl==undefined){
                errorTag.push(true)
                // setErrorTagState(true)
            }else{
                errorTag.push(false)
                // setErrorTagState(false)
            }
            
        })
        errorTag.every(x=>x==false)?setErrorTagState(false):setErrorTagState(true)
       
        if(!errorName&&errorTag.every(x=>x==false)){
            setIsLoading(true)
            updateTag(tagData._id,data)
            .then(({data})=>{
                console.log(data);
                onItemEdited()
                close()
            })
            .catch(error=>console.log(error))
        }
    }
    const addItemToList = () => {
        append({
            name: "",
            iconUrl: "",
            // defaultselected: false,
            // acceptMultiple: false,
        });
    };
    const setDataValue = () => {

        setValue('name', data.name);
        // setValue('required', data.required.props.checked);
        // setValue('maxChoices', data.maxChoices);
        // setValue('open', data.open);
        // setValue('type', data.type);
        data.tags.length > 0 && data.tags.map((item, index) => (

            setValue(`tags[${index}].name`, item.name),
            setValue(`tags[${index}].iconUrl`, item.iconUrl)
            // setValue(`tags[${index}].defaultselected`, item.defaultselected),
            // setValue(`tags[${index}].acceptMultiple`, item.acceptMultiple)
        ))

        reset(data)
    }
    useEffect(() => {
        data && setDataValue()
    }, [data])
    console.log('multiIconUrl',multiIconUrl);
    return (
        <div>
            {data&&<form onSubmit={handleSubmit(onSubmit)}>
                <DragDropContext onDragEnd={handlePanelDragEnd}>

                    <div>
                        <CustomControllerField
                            labelText="Name"
                            formControlProps={{
                                fullWidth: true,
                            }}
                            type="text"
                            name="name"
                            control={control}
                        />
                        {errorNameState && <span style={{ color: 'red' }}>This field is required</span>}

                        <Droppable droppableId="panel-dropzone" direction='horizantal'>
                            {provided => (
                                <div
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    className="panels-wrapper"
                                >
                                    {renderPanels()}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                        {errorTagState && <span style={{ color: 'red' }}>Some field in tags is empty</span>}
                        <Box textAlign="left">
                            <Button
                                round
                                type="submit"
                                color="twitter"
                                size="sm"
                                loaderColor="white"
                                onClick={addItemToList}
                            >
                                Add new item
                            </Button>
                        </Box>
                        {/* <FormControlLabel
                            control={<Checkbox color="primary" defaultChecked={data.required.props.checked} />}
                            label='obligatory choice for the customer'

                            name='required'
                            inputRef={register}
                        />

                        <CustomControllerField
                            labelText="choice number"
                            formControlProps={{
                                fullWidth: false,
                            }}
                            type="number"
                            name="maxChoices"
                            control={control}
                        />
                        {formErrorsMaxChoice && <span style={{ color: 'red' }}>max Choices must not be less than 1</span>}
                        <Grid component="label" container alignItems="center" spacing={1}>
                            <Grid item>visible to the customer</Grid>
                            <Grid item>
                                <Controller
                                    as={SwitchComponent}
                                    name='open'
                                    label=""
                                    control={control}
                                    defaultValue={true}

                                />
                            </Grid>
                            <Grid item>always visible</Grid>
                        </Grid>
                        <CustomeControllSelect
                            list={typeList}
                            name='type'
                            label='type'
                            inputProps={{
                                inputRef: (ref) => {
                                    if (!ref) return;
                                    register({
                                        name: "type",
                                        value: ref.value,
                                    });
                                },
                                value: data.type,
                                onChange: (e) => {
                                    data.type = e.target.value
                                    setChange(!change)
                                }

                            }}
                        />
                        {formErrorsType && <span style={{ color: 'red' }}>This field is required</span>}
                        <CustomeControllSelect
                            list={priceConfigList}
                            name='priceconfig'
                            label='price config'
                            inputProps={{
                                inputRef: (ref) => {
                                    if (!ref) return;
                                    register({
                                        name: "priceconfig",
                                        value: ref.value,
                                    });
                                },
                                value: data.priceconfig,
                                onChange: (e) => {
                                    data.priceconfig = e.target.value
                                    setChange(!change)
                                }
                            }}
                        /> */}

                        {/* {formErrorsPriceconfig && <span style={{ color: 'red' }}>This field is required</span>} */}
                        <Box textAlign="center">
                            <Button
                                round
                                type="submit"
                                color="success"
                                // disabled={isLoading}
                                // loading={isLoading}
                                loaderColor="white"
                                // onClick={handleClick}
                            >
                                save
                            </Button>
                        </Box>
                    </div>
                </DragDropContext>
            </form>}
        </div>
    )
}
