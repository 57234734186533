import React,{useState,useEffect} from 'react'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Collapse, Box, Grid,Table,TableBody,TableCell,TableRow } from '@material-ui/core'
import DragHandleIcon from "@material-ui/icons/DragHandle";



export default function ServiceListComponent ({ type, subItems, parentIndex,classes,getItemStyle }){
    // console.log(subItems,'subItems');
    // console.log(subItems[0],'subItems');
    const [openModuleProductList, setOpenModuleProductList] = useState({});
    const grid = 8;
    const getListStyle = isDraggingOver => ({
        background: isDraggingOver ? "" : "",
        padding: grid,
        width: 700
      });
    useEffect(() =>{
      let newState = {...openModuleProductList}
      subItems.map((item,index)=>(
      newState[index] = false
      ))
      Object.keys(openModuleProductList).length === 0&&console.log('condition');
      setOpenModuleProductList(newState)
    },[])
    // console.log("openModuleProductList",openModuleProductList);
    // console.log('subItems',subItems);
    return (
      <Droppable
        droppableId={type}
        type={`droppableSubItem-${type}`}
      >
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {subItems.map((item, index) => (
              <Draggable key={item._id} draggableId={item._id} index={index}>
                {(provided, snapshot) => (
                  <div style={{ display: "block", }}>
                    <Box className={classes.root} style={{ background: "grey", }} boxShadow={3}>
                       
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                              >
                                  <Grid container spacing={3}>
                                {/* <Grid item xs={1}>
                                  <IconButton
                                    aria-label="expand row"
                                    size="small"
                                    onClick={() => setOpenModuleProductList({...openModuleProductList,[index]:!openModuleProductList[index]})}
                                    style={{ color: "white" }}
                                  >
                                    {openModuleProductList[index] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                  </IconButton>
                                </Grid> */}
                                <Grid item xs={10}>
                                    <span style={{ color: "white" }} >{item.name}</span>
                                  </Grid>
                                  <Grid item xs={1}>
                              
                                  <div {...provided.dragHandleProps}>

                                    <DragHandleIcon style={{ color: "white" }}/>
                                  </div>
                               
                                </Grid>
                              </Grid>
                                </div>
                            </Box>
                   
                    {provided.placeholder}
                    {/* <TableRow>
                              <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                <Collapse in={openModuleProductList[index]} timeout="auto" unmountOnExit>
                                  <Box margin={1}>
                                    <Table size="small" aria-label="purchases">
                                      <TableBody>
                                      <ItemList
                                        subItems={item.items}
                                        type={item.productId}
                                        index={index}
                                        parentIndex={parentIndex}
                                        parentId={type}
                                        openModuleProductList={openModuleProductList}
                                        setOpenModuleProductList={setOpenModuleProductList}
                                      />
                                        
                                      </TableBody>
                                    </Table>
                                  </Box>
                                </Collapse>
                              </TableCell>
                            </TableRow> */}
                    
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    // <div>aaa</div>
    )
  }
