import React, { useEffect, useState } from 'react';
import { Grid, TextField, Box } from '@material-ui/core'
import {getlocationsHistoryForDeliverMan} from '../service/serverAPI.service'
import DataTableContainerComponent from '../../../components/DataTableSortingContainer.component';
import CustomButton from '../../../components/material-dashboard-pro-react/CustomButtons/Button'
import {formatTimeForOrder,calcDurationWithSecondbetweenTwoTimes} from '../../../helpers';

export default function HistoryLocationComponent({item}) {
    // state
    const [date,setDate] = useState('');
    const [history,setHistory] = useState([]);
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(100)
    const [total,setTotal] = useState('')
    const [resultsData, setResultsData] = useState({items: []});

    const prepareToShowHistoryLocations = (item,index)=>{
        return {
            ...item,
            createdAtCol:item?.createdAt&&formatTimeForOrder(item.createdAt),
            time : index>0&&<span style={{color: calcDurationWithSecondbetweenTwoTimes(item.createdAt,history[index-1].createdAt)>20&&'red'}}>{calcDurationWithSecondbetweenTwoTimes(item.createdAt,history[index-1].createdAt)}</span>
        }
    }
    // call api 
    const fetchHistoryLocation =(skip,limit)=>{
        date&&getlocationsHistoryForDeliverMan(skip,limit,true,item._id,date)
        .then(({data})=>{
            data.total&& setTotal(data.total)
            data.deliveryMan&& setHistory(data.deliveryMan)
            console.log(data);
        })
        .catch((error)=>{
            console.log(error);
        })
    } 
    // pagination fuc 
    const handlePaginate = (val) => {
        setResultsData({
          items: [],
          tableItems: [],
        });
        fetchHistoryLocation(val.pageSelect,val.numberOfRows);
        setPage(val.pageSelect);
        setRowsPerPage(val.numberOfRows);
      };
    // useEffect  
    useEffect(()=>{
        history&&history.length&&setResultsData({
            items : history.map((item,index)=>{
                return prepareToShowHistoryLocations(item,index)
            })
        })
    },[history]);
    

    return (
        <div>
            <Grid container justify="center">
                <Grid item xs={10} >

                    <TextField
                        id="datetime-local"
                        label="date"
                        type="datetime-local"
                        // defaultValue="2017-05-24T10:30"
                        sx={{ width: 250 }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={(e) => setDate(e.target.value)}
                    />
                </Grid>
                <Grid item xs={2} >

                    <Box textAlign="left">
                        <CustomButton
                            round
                            type="submit"
                            color="success"
                            size="sm"
                            loaderColor="white"
                            // onClick={loadCustomerList}
                            onClick={() => {
                                //   setSearchText('')
                                  setPage(0)
                                  fetchHistoryLocation(0,20)
                            }}
                        >
                            get location history
                        </CustomButton>
                    </Box>
                </Grid>
            </Grid>
            <DataTableContainerComponent
        total={total}
        resultsData={resultsData}
        initData={resultsData.items}
        page={page+1}
        columns={[

          {
            Header: 'lat',
            accessor: 'location[0]',
          },
          {
            Header: 'lng',
            accessor: 'location[1]',
          },
          {
            Header: 'created At',
            accessor: 'createdAtCol',
          },
          {
            Header: 'Time',
            accessor: 'time',
          },
          


        ]}
        title="location history List"
        // isLoading={isLoading}
        // loadItemsList={loadApprouvedProviderList}
        onPaginate={handlePaginate}
        isPaginate={true}
      />
        </div>
    )
}
