import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getOrdersDataState } from '../redux/selectors';

// material componenet
import {
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Typography,
  Paper,
  Button,
  Box,
} from '@mui/material';
import moment from 'moment';

// api call functions
import {
  getDispatchingStatus,
  citiesPlaningByDay,
} from '../services/serverApi.service';
import ModalComponent from '../../../components/Modal.component';

export default function DeliveryMenTableComponent({
  cities,
  selectedCities,
  socket,
}) {
  const { orders, invalidOrders, watcherCounter } =
    useSelector(getOrdersDataState);
  const [dispatchingStatus, setDispatchingStatus] = useState([]);
  const [initailDispatchingStatus, setInitailDispatchingStatus] = useState([]);
  const [lastUpdatedDate, setLastUpdatedDate] = useState('');
  const [cityId, setCityId] = useState(null);
  const [CellOpen, setCellOpen] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [specificCell, setSpecificCell] = useState([]);
  const [day, setDay] = useState(moment().format('DD-MM-YYYY').toString());
  const [citiesPlaning, setCitiesPlaning] = useState([]);
  const [dataForCity, setDataForCity] = useState({});
  const [totalOrdersByCity, setTotalOrdersByCity] = useState({});

  const handleCloseModal = () => {
    setCityId('');
    setCellOpen('');
    setOpen(false);
    setSpecificCell([]);
  };
  const totalOrderInCity = () => {
    const TotalOrdersByCityObj = {};
    const allOrders = orders.concat(invalidOrders);
    (allOrders || []).map((order) => {
      TotalOrdersByCityObj[order.city._id] =
        (TotalOrdersByCityObj[order.city._id] || 0) + 1;
    });
    setTotalOrdersByCity(TotalOrdersByCityObj);
  };
  function Row({ row, index }) {
    return (
      <React.Fragment>
        <TableRow>
          <TableCell  component="th" scope="row"   >
            {(cities || [])
              .find((item) => item._id == row._id)
              ?.name?.substring(0, 4)}
          </TableCell>
          <TableCell align="center" >
          
            <span style={{ width: '15px' }}>
              {totalOrdersByCity[row?._id] || '--'}
            </span>
          </TableCell>
          <TableCell
            align="center"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setOpen(true);
              setCityId(row._id);
              setSpecificCell(['occupiedDeliveryMen', 'freeDeliveryMen']);
              setCellOpen('occupied');
            }}
          >
            <span style={{ width: '35px' }}>
              {row?.occupiedDeliveryMenCount +
                ' | ' +
                row?.freeDeliveryMenCount}
            </span>
          </TableCell>
          <TableCell
            align="center"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setOpen(true);
              setCityId(row._id);
              setSpecificCell(['planifiedDeliveryMen']);
              setCellOpen('planifiedDeliveryMen');
            }}
          >
            <span style={{ width: '15px' }}>
              {row?.planifiedDeliveryMenCount}
            </span>
          </TableCell>
          <TableCell
            align="center"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setOpen(true);
              setCityId(row._id);
              setSpecificCell(['availableDeliveryMen']);
              setCellOpen('availableDeliveryMen');
            }}
          >
            {row?.availableDeliveryMenCount}
          </TableCell>
          <TableCell
            align="center"
            style={{ cursor: 'pointer', borderRight: '1px solid black' }}
            onClick={() => {
              setOpen(true);
              setCityId(row._id);
              setSpecificCell([
                'dispatchableDeliveryMen',
                'nonDispatchableDeliveryMen',
              ]);
              setCellOpen('dispatchable');
            }}
          >
            <span style={{ width: '15px' }}>
              {row?.dispatchableDeliveryMenCount +
                ' | ' +
                row?.nonDispatchableDeliveryMenCount}
            </span>
          </TableCell>
          {citiesPlaning &&
            citiesPlaning.length > 0 &&
            (Object.keys(citiesPlaning[0].times) || [])
              .slice(8)
              .map((time) => (
                <TableCell align="center">
                  {citiesPlaning.find((x) => x.cityId == row._id) &&
                  Object.keys(
                    citiesPlaning.find((x) => x.cityId == row._id)?.times,
                  )
                    ? citiesPlaning.find((x) => x.cityId == row._id)?.times[
                        time
                      ]
                    : '--'}
                </TableCell>
              ))}
        </TableRow>
      </React.Fragment>
    );
  }
  const setDataToState = (data) => {
    selectedCities == 'all'
      ? setInitailDispatchingStatus(data)
      : setInitailDispatchingStatus(
          data.filter((item) => selectedCities.includes(item._id)),
        );
  };
  const fetchDispatchingStatus = () => {
    getDispatchingStatus()
      .then(({ data }) => {
        setLastUpdatedDate(data.cityDispatchingStatus.lastRebuildTime);
        setDispatchingStatus([]);
        setDataToState(data.cityDispatchingStatus.cities);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const prepareDataForEveryCity = () => {
    const totalObj = {};
    !selectedCities.includes('all')
      ? selectedCities.every((x) => (totalObj[x] = {}))
      : cities.every((x) => (totalObj[x._id] = {}));
    setDataForCity(totalObj);
  };

  useEffect(() => {
    selectedCities && fetchDispatchingStatus();
    selectedCities && cities && prepareDataForEveryCity();
  }, [selectedCities, cities]);

  const setDataToStateForSocket = (data) => {
    setDispatchingStatus(
      data.sort((x, y) =>
        initailDispatchingStatus.findIndex((a) => a._id == x._id) >
        initailDispatchingStatus.findIndex((b) => b._id == y._id)
          ? 1
          : -1,
      ),
    );
  };
  useEffect(() => {
    citiesPlaningByDay(day)
      .then(({ data }) => setCitiesPlaning(data.citiesPlanning?.cities))
      .catch((error) => console.log(error));
  }, []);
  useEffect(() => {
    initailDispatchingStatus.length &&
      socket &&
      socket.on('DISPATCHING_STATUS_CHANGED', function (data) {
        setDataToStateForSocket(
          selectedCities == 'all'
            ? data.cityDispatchingStatus.cities
            : data.cityDispatchingStatus.cities.filter((item) =>
                selectedCities.includes(item._id),
              ),
        );
        setLastUpdatedDate(data.cityDispatchingStatus.lastRebuildTime);
      });
  }, [socket, initailDispatchingStatus, selectedCities]);
  useEffect(() => {
    invalidOrders && orders && totalOrderInCity();
  }, [(invalidOrders || []).length, (orders || []).length]);
  return (
    <React.Fragment>
      <TableContainer component={Paper}>
        <Typography className="mt-2 ml-4">DeliveryMen info</Typography>
        <Table aria-label="collapsible table" style={{width: 'auto',tableLayout:'auto'}}>
          <TableHead scope='row' colSpan='4'>
            <TableRow>
              <TableCell align="center" >Ville</TableCell>
              <TableCell align="center" >Orders</TableCell>
              <TableCell align="center" width={200}>Occupé Y|N</TableCell>
              <TableCell align="center" width={200}>Planifié</TableCell>
              <TableCell align="center" width={200}>Accept</TableCell>
              <TableCell
                width={200}
                align="center"
                style={{ borderRight: '1px solid black' }}
              >
                DispatY|N
              </TableCell>
              {citiesPlaning &&
                citiesPlaning.length > 0 &&
                (Object.keys(citiesPlaning[0].times) || [])
                  .slice(8)
                  .map((time) => <TableCell align="center">{time}h</TableCell>)}
            </TableRow>
          </TableHead>
          <TableBody>
            {(
              (dispatchingStatus.length && dispatchingStatus) ||
              (initailDispatchingStatus.length && initailDispatchingStatus) ||
              []
            ).map(
              (item, index) =>
                totalOrdersByCity[item._id] && (
                  <Row key={index} row={item} index={index} />
                ),
            )}
          </TableBody>
        </Table>

        <Box
          sx={{ justifyContent: 'space-between' }}
          display="flex"
          p={0.5}
          m={1}
          bgcolor="background.paper"
        >
          <span>
            {lastUpdatedDate &&
              'last rebuild Time : ' +
                moment(lastUpdatedDate).format('hh:mm:ss')}
          </span>
        </Box>
      </TableContainer>
      <ModalComponent
        maxWidth={'lg'}
        close={handleCloseModal}
        visible={open}
        title={CellOpen}
      >
        {specificCell.map((item) => (
          <>
            {specificCell.length > 1 && (
              <Typography variant="h6" component="div">
                {item}
              </Typography>
            )}
            {(
              (dispatchingStatus.length && dispatchingStatus) ||
              (initailDispatchingStatus.length && initailDispatchingStatus) ||
              []
            )
              .find((x) => x._id == cityId)
              [item].map((delivery) => (
                <Button>{delivery?.name}</Button>
              ))}
          </>
        ))}
      </ModalComponent>
    </React.Fragment>
  );
}
