import React, { useEffect, useState } from 'react';
import DataTableContainerComponent from '../../../components/DataTableContainer.component';
import { getAdmins, editPasswordAdmin } from '../services/serverApi.service';
import CreateAdminComponent from '../components/CreateAdmin.component';
import { Button, Box } from '@mui/material';
import ModalComponent from '../../../components/Modal.component';
import CustomInput from '../../../components/material-dashboard-pro-react/CustomInput/CustomInput';
import CutomeButton from '../../../components/material-dashboard-pro-react/CustomButtons/Button';

import * as yup from 'yup';
import { useFormik } from 'formik';


const validationSchema = yup.object({
  userId: yup.string().required(),
  entityObject: yup.object().required(),
  entityType: yup.string().required(),
  password: yup.string().min(6, 'password length must be at least 6 characters long').required(),
});
export default function AdminPage() {
  const [resultsData, setResultsData] = useState({
    total: 0,
    items: [],
    tableItems: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [userId, setUserId] = useState(null);
  const [open, setOpen] = useState(false);

  const formik = useFormik({
    initialValues: {
      userId: '',
      password: '',
      entityType: '',
      entityObject: '',
    },
    validationSchema,
  });
  const loadAdminList = (page, limit) => {
    getAdmins(page || 0, limit || 20, true)
      .then(({ data }) => {
        // console.log(data.admins);
        setResultsData({
          total: data.total,
          items: data.admins,
          tableItems: data.admins.map((item) => {
            return [item._id, item.username, item.email, <Button onClick={() => {
              setUserId(item?.userId);
              formik.setValues({ userId: item?.userId,entityObject:item,entityType:'admin' });
              setOpen(true);
            }}>reset</Button>];
          }),
        });
      })
      .finally(() => { });
  };
  const [pageSelect, setPageSelect] = useState(0);
  const [numberOfRows, setNumberOfRows] = useState(0);
  const handlePaginate = (val) => {
    setResultsData({
      total: 0,
      items: [],
      tableItems: [],
    });
    loadAdminList(val.pageSelect, val.numberOfRows);
  };
  const onItemCreated = (newData) => {
    if (newData) {
      newData = newData.user;
      let data = resultsData.items;
      data = [newData, ...data];
      setResultsData({
        total: resultsData.total + 1,
        items: data,
        tableItems: [
          [newData._id, newData.username, newData.email],
          ...resultsData.tableItems,
        ],
      });
    }
  };
  const onItemUpdated = async (data) => {
    const index = data.index;
    data = data.newData;
    let items = resultsData.items;
    items[index].email = data.email;
    items[index].username = data.username;
    let tableItems = resultsData.tableItems;
    tableItems[index] = [data._id, data.username, data.email];
    setResultsData({
      ...resultsData,
      items: items,
      tableItems: tableItems,
    });
  };
  const onItemDeleted = async ({ index }) => {
    // console.log(index)
    // const items =  resultsData.items;
    // items.splice(index, 1);
    // let tableItems = resultsData.tableItems;
    // tableItems.splice(index, 1);
    // setResultsData({
    //   ...resultsData,
    //   items: items,
    //   tableItems: tableItems,
    // });
  };
  const deleteAdmin = (id) => {
    // return deleteDispatchingMessage(id);
  };
  const handleCloseModal = () => {
    setUserId('');
    formik.setValues({ userId: '', password: '',entityType:'',entityObject:'' });
    setOpen(false);
  }
  const changePassword = () => {
    editPasswordAdmin(formik.values)
      .then(({ data }) => {
        console.log(data)
        handleCloseModal();
      })
      .catch((error) => console.log(error));
  }
  return (
    <>
      <DataTableContainerComponent
        resultsData={resultsData}
        tableHead={['username', 'email', 'reset password', 'Action']}
        title="admin"
        isLoading={isLoading}
        loadItemsList={() => { }}
        CreateItemFormComponent={CreateAdminComponent}
        actionButtons={{
          view: {
            color: 'info',
            type: 'view',
            // component: ViewItemComponent,
          },
          edit: {
            color: 'success',
            type: 'edit',
            advanceEdit: true,
            props: { isEdited: true },
            component: CreateAdminComponent,
          },
          delete: {
            color: 'danger',
            type: 'delete',
            action: deleteAdmin,
            //component: DeleteComponent,
          },
        }}
        onPaginate={handlePaginate}
        isPaginate={true}
        itemCreated={onItemCreated}
        itemEdited={onItemUpdated}
        itemDelete={onItemDeleted}
      />
      <ModalComponent
        maxWidth={'sm'}
        close={handleCloseModal}
        visible={open}
        title={'reaset password : ' + (resultsData.items || []).find(x => x.userId == userId)?.username}
      >
        <div >
          <CustomInput
            labelText="password"
            formControlProps={{
              fullWidth: true,
            }}
            type="text"
            error={
              formik.touched.password &&
              Boolean(formik.errors.password)
            }
            inputProps={{
              value: formik.values.password,
              onBlur: formik.handleBlur('password'),
              onChange: formik.handleChange('password'),
            }}
          />
          <Box textAlign="center">
            <CutomeButton
              round
              type="submit"
              color="success"
              disabled={!formik.dirty || !formik.isValid || isLoading}
              loading={isLoading}
              loaderColor="white"
              onClick={changePassword}
            >
              save
            </CutomeButton>
          </Box>
        </div>
      </ModalComponent>
    </>
  );
}
