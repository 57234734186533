import React, { useState, useRef, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import * as yup from 'yup';
import { ErrorMessage, FieldArray, Form, Formik,Field } from 'formik';
import { Box, InputLabel } from '@material-ui/core'
import { useAlert } from 'react-alert';
import AddIcon from '@material-ui/icons/Add';
import GridContainer from '../../../components/material-dashboard-pro-react/Grid/GridContainer';
import Card from '../../../components/material-dashboard-pro-react/Card/Card';
import CardHeader from '../../../components/material-dashboard-pro-react/Card/CardHeader';
import CardBody from '../../../components/material-dashboard-pro-react/Card/CardBody';
import CustomInput from '../../../components/material-dashboard-pro-react/CustomField/CustomField';
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import ImageUpload from '../../../components/material-dashboard-pro-react/CustomUpload/CustomMultiUpload';
import { addTag, getTag } from '../services/serverApi.service'
import {multiUploadImageResetAction} from '../../../redux/multiUploadRedux/upload.action'
import { json } from 'body-parser';

const validationSchema = yup.object({
    name: yup.string().required('name is required'),
    tags: yup.array().of(
        yup.object().shape({
            name: yup.string().required('name is required'),
            iconUrl: yup.string().required('image is required'),
        })
    )
})
export default function CreateTagPage() {

    const dispatch = useDispatch()
    const alert = useAlert();
    const MyForm = useRef()
    console.log(MyForm?.current);
    const upload = useSelector((state) => state.multiUploadReducer)
    const { multiIconUrl, error, loading, newUpload } = upload
    const [isLoading, setIsLoading] = useState(false)
    const [visible, setVisible] = useState(false)
    const [tags, setTags] = useState([])
    const initialValues = {
        name: "",
        tags: [
            { name: "", iconUrl: "" }
        ]

    }
    const loadTagsList = () => {
        setIsLoading(true);
        getTag(0, 0, false)
            .then(({ data }) => {
                setTags(data)
                // setResultsData({
                //   total: data.total,
                //   items: data.tagCategories.map((item) => {
                //     return {
                //       name: item.name,
                //       nbrOfTags: <span id={item._id}>{item.tags.length}</span>,
                //     };
                //   }),

                // });
            })
            .catch(error => console.log(error))
            .finally(() => {
                setIsLoading(false);
            });
    };

    const setValue = () => {
        visible&&MyForm.current.values.tags && MyForm.current.values.tags.length > 0 &&
            MyForm.current.values.tags.map((item, index) => (
                item.iconUrl = multiIconUrl[index]
            ))
    }
    useEffect(() => {
        multiIconUrl && setValue()
        loadTagsList()
    }, [multiIconUrl])
    // console.log(tags, 'tags');
    const submitForm = (values) => {
        console.log(values);
        setIsLoading(true);
        addTag(values)
            .then(({ data }) =>{
                alert.show('tag created', {
                    type: 'success',
                }),
                loadTagsList()
                MyForm?.current?.resetForm()
                dispatch(multiUploadImageResetAction())
                setVisible(false)
            }
            )
            .catch(({ error }) =>
                alert.show(error, {
                    type: 'error',
                })
            )
            .finally(() => {
                setIsLoading(false);
            });
    }
    console.log(MyForm?.current);
    const da = [
        {iconUrl: "https://kaalixzone1.s3.eu-west-3.amazonaws.com/MS/images/service/8jGgIMULDEr6EZU.png",
        name: "American "},
        {iconUrl: "https://kaalixzone1.s3.eu-west-3.amazonaws.com/MS/images/service/8jGgIMULDEr6EZU.png",
        name: "American "},
        {iconUrl: "https://kaalixzone1.s3.eu-west-3.amazonaws.com/MS/images/service/8jGgIMULDEr6EZU.png",
        name: "American "},
        {iconUrl: "https://kaalixzone1.s3.eu-west-3.amazonaws.com/MS/images/service/8jGgIMULDEr6EZU.png",
        name: "American "},
        {iconUrl: "https://kaalixzone1.s3.eu-west-3.amazonaws.com/MS/images/service/8jGgIMULDEr6EZU.png",
        name: "American "},
        {iconUrl: "https://kaalixzone1.s3.eu-west-3.amazonaws.com/MS/images/service/8jGgIMULDEr6EZU.png",
        name: "American "},
        {iconUrl: "https://kaalixzone1.s3.eu-west-3.amazonaws.com/MS/images/service/8jGgIMULDEr6EZU.png",
        name: "American "},
        {iconUrl: "https://kaalixzone1.s3.eu-west-3.amazonaws.com/MS/images/service/8jGgIMULDEr6EZU.png",
        name: "American "},
        {iconUrl: "https://kaalixzone1.s3.eu-west-3.amazonaws.com/MS/images/service/8jGgIMULDEr6EZU.png",
        name: "American "},
    ]
    return (
        <GridContainer>
            <Card>
                <CardHeader>create Tag</CardHeader>
                <CardBody>
                    {tags && tags.length > 0 && tags.map((tag, index) => (
                        <>
                        <div style={{display: 'block',clear:"both",}}>
                            <InputLabel style={{ marginTop: '15px',marginBottom: '0',padding:"10px"}}>{tag.name} </InputLabel>
                            <div style={{  padding: " 0 15px", margin: '0', }}>
                                {tag.tags && tag.tags.length > 0 && tag.tags.map((item) => (

                                    <div style={{border: "1px solid #000", borderRadius: "10px", padding: " 0 15px", margin: '0px 3px',width: "100px",textAlign: "center",float: "left"}}>

                                        <img src={item.iconUrl} style={{ width: '30px', height: '30px' }} /><br/>
                                        <span>{item.name}</span>
                                    </div>
                                    
                                ))}
                            </div>
                        </div><br/>
                        </>
                    ))}
                    
                    {visible&&
                        <Formik
                        innerRef={MyForm}
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={values => submitForm(values)}
                        render={({ values, isValid, errors, dirty, touched }) => (
                            <>
                                <Form>
                                    <CustomInput
                                        labelText="name"
                                        name='name'
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        type="text"
                                        helperText={<ErrorMessage name='name' />}
                                    />




                                    <FieldArray
                                        name={`tags`}
                                        render={arrayHelpers => {
                                            const Pubs = values.tags;

                                            return (
                                                <>
                                                    {Pubs && Pubs.length > 0 && <div style={{ display: 'flex', overflowX: 'auto' }}>
                                                        {Pubs && Pubs.length > 0
                                                            ? Pubs.map((pub, index) => (

                                                                <div style={{ flex: '0 0 20%', border: "1px solid #000", borderRadius: "10px", padding: " 0 15px", margin: '0 2px', }}>
                                                                    {/* <InputLabel style={{ marginTop: '0' }}>tag :{index + 1} </InputLabel> */}

                                                                    <Box display="flex" justifyContent="center">
                                                                        <ImageUpload
                                                                            label="Select icon"
                                                                            folder="service"
                                                                            index={`${index}`}
                                                                            style={{ img: { width: '30px', height: '30px' } }}
                                                                            btnSize="sm"
                                                                            // errorMessage={errorsImage[`${index}`]}
                                                                            avatar
                                                                        />
                                                                    </Box>
                                                                    {/* <CustomInput
                                                                        labelText="name"
                                                                        name={`tags.${index}.name`}
                                                                        formControlProps={{
                                                                            fullWidth: true,
                                                                        }}
                                                                        type="text"
                                                                    // helperText={<ErrorMessage name='name' />}
                                                                    /> */}
                                                                    <Field name={`tags.${index}.name`} style={{border:'none'}} placeholder="name" />

                                                                    <Box textAlign="right">
                                                                        <Button
                                                                        round
                                                                            size="sm"
                                                                            color="danger"
                                                                            loaderColor="white"
                                                                            onClick={() => arrayHelpers.remove(index, 1)}
                                                                        >
                                                                            delete tag
                                                                        </Button>
                                                                    </Box>
                                                                </div>
                                                            ))
                                                            : null

                                                        }

                                                    </div>}
                                                    <Box textAlign="left" >
                                                        <Button
                                                            size="sm"
                                                            color="twitter"
                                                            loaderColor="white"
                                                            onClick={() =>
                                                                // isValid &&
                                                                arrayHelpers.push({})
                                                                // console.log(arrayHelpers[index].Pubs)
                                                            }
                                                        >
                                                            <AddIcon />
                                                        </Button>
                                                    </Box>

                                                </>
                                            );
                                        }
                                        } />
                                    <Box textAlign="right">
                                        <Button
                                            round
                                            type="submit"
                                            color="success"
                                            disabled={(!dirty || isLoading || !isValid) ? true : false}
                                            loading={isLoading}
                                            loaderColor="white"
                                        // onClick={() => submitForm(values)}
                                        >
                                            submit
                                        </Button>
                                    </Box>
                                </Form>
                                
                            </>
                        )}
                    />}
                    <br/>
                    {!visible&&<Box textAlign="left" style={{clear:'both'}}>
                        <Button
                            // size="sm"
                            color="primary"
                            loaderColor="white"
                            onClick={() => setVisible(true)}
                        >
                            add Tag list
                        </Button>
                    </Box>}
                </CardBody>
            </Card>
        </GridContainer>
    )
}



