import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Button, TextField } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { editOrganisation } from '../services/server.api.service';
import { useAlert } from 'react-alert';

const validationSchema = yup.object({
  maxCommissionPrice: yup.number(),
});
export default function CreateCommissionConfig({
  organisation,
  close,
  commissionType,
  loadItemsList,
}) {
  const alert = useAlert();
  const [interval, setInterval] = useState(
    (organisation[commissionType]?.commissionInterval || []).map((item) => {
      return {
        ds: item?.ds || 0,
        de: item?.de || 1,
        basePrice: item?.basePrice || 0,
        distancePrice: item?.distancePrice || 0,
      };
    }),
  );
  const [isLoading, setIsLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      maxCommissionPrice: 0,
    },
    validationSchema,
  });
  const addInterval = () => {
    const newInterval = interval;
    newInterval.push({
      ds: newInterval[newInterval.length - 1]?.de || 0,
      de: (newInterval[newInterval.length - 1]?.de || 1) + 1,
      basePrice: 0,
      distancePrice: 0,
    });
    setInterval([...newInterval]);
  };
  const editInterval = (index, item) => {
    const newInterval = interval;
    newInterval[index] = item;
    setInterval([...newInterval]);
  };
  const deleteInterval = (index) => {
    const newInterval = interval;
    newInterval.splice(index, 1);
    setInterval([...newInterval]);
  };
  const saveCommissionConfig = () => {
    const commissionInterval = { commissionInterval: [...interval] };
    organisation[commissionType] = commissionInterval;
    organisation[commissionType].maxCommissionPrice =
      formik.values.maxCommissionPrice;
    editOrganisation(organisation._id, organisation)
      .then(() => {
        alert.show('organisation has been edited', {
          type: 'success',
        });
        formik.resetForm();
        loadItemsList();
        close();
      })
      .catch((error) => {
        alert.show(error?.message || 'fail', {
          type: 'error',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    formik.setValues({
      maxCommissionPrice: organisation[commissionType]?.maxCommissionPrice || 0,
    });
  }, []);

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className="card">
          <div style={{ padding: '10px 20px', 'font-size': '16px' }}>
            <div className="form-group row">
              <label
                className="col-sm-4 col-form-label"
                style={{ color: 'black' }}
              >
                max Commission Price
              </label>
              <div className="col-sm-8">
                <TextField
                  className={'w-25'}
                  id={'outlined-basic-price'}
                  label=""
                  variant="outlined"
                  type={'number'}
                  onChange={formik.handleChange}
                  value={formik.values.maxCommissionPrice}
                  name="maxCommissionPrice"
                />
              </div>
            </div>
          </div>
          <div className="px-2">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">DS</th>
                  <th scope="col">DE</th>
                  <th scope="col">Price</th>
                  <th scope="col">Price of distance</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {interval.map((item, index) => (
                  <tr key={index}>
                    <th scope="row">Interval {index + 1}</th>
                    <td>
                      <div>
                        <TextField
                          id={'outlined-basic-ds' + index}
                          label=""
                          variant="outlined"
                          type={'number'}
                          value={item.ds}
                          InputProps={
                            {
                              // readOnly: true,
                            }
                          }
                          onChange={($event) => {
                            item.ds = parseFloat($event.target.value);
                            editInterval(index, item);
                          }}
                        />
                      </div>
                    </td>
                    <td>
                      <div style={{ display: 'inline-flex' }}>
                        <TextField
                          id={'outlined-basic-de' + index}
                          label=""
                          variant="outlined"
                          type={'number'}
                          value={item.de}
                          InputProps={{
                            readOnly: interval.length !== index + 1,
                            inputProps: { min: item.ds },
                          }}
                          onChange={($event) => {
                            item.de = parseFloat($event.target.value);
                            editInterval(index, item);
                          }}
                        />
                      </div>
                    </td>
                    <td>
                      <div style={{ display: 'inline-flex' }}>
                        <TextField
                          id={'outlined-basic-' + index}
                          label=""
                          variant="outlined"
                          type={'number'}
                          value={item.basePrice}
                          onChange={($event) => {
                            item.basePrice = parseFloat($event.target.value);
                            editInterval(index, item);
                          }}
                        />
                      </div>
                    </td>
                    <td>
                      <div style={{ display: 'inline-flex' }}>
                        <TextField
                          id={'outlined-basic-' + index}
                          label=""
                          variant="outlined"
                          type={'number'}
                          value={item.distancePrice}
                          onChange={($event) => {
                            item.distancePrice = parseFloat(
                              $event.target.value,
                            );
                            editInterval(index, item);
                          }}
                        />
                      </div>
                    </td>{' '}
                    <td>
                      {interval.length > 1 && interval.length == index + 1 && (
                        <Fab
                          size="medium"
                          color="secondary"
                          aria-label="add"
                          onClick={deleteInterval}
                        >
                          <DeleteIcon />
                        </Fab>
                      )}
                    </td>
                  </tr>
                ))}
                <tr>
                  <td colSpan={6}>
                    <Fab color="primary" aria-label="add" onClick={addInterval}>
                      <AddIcon />
                    </Fab>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {/* <div className="row px-3">
            <div className="col-6">
              <div className="row">
                <div className="col-6">
                  <h5 style={{ 'font-weight': 'bold' }}>distance (km)</h5>
                </div>
                <div className="col-6">
                  <TextField
                    fullWidth={true}
                    type={'number'}
                    className={'w-50'}
                    variant="outlined"
                    id="d_StoreUser"
                    value={distanceStoreUser}
                    onChange={($event) => {
                      setDistanceStoreUser($event.target.value);
                    }}
                    InputProps={{
                      onBlur: testInterval,
                      inputProps: { min: 0 },
                    }}
                    label={'KM'}
                  />
                </div>
              </div>
            </div>
            <div className="col-4">
              <h5 style={{ 'font-weight': 'bold' }}>
                commission : {deliveryPrice + ' DH'}
              </h5>
            </div>
          </div> */}
        </div>
        <div className="card">
          <div className={'d-flex justify-content-end p-2'}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={'mx-2'}
              onClick={() => saveCommissionConfig()}
            >
              save
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
}
