import React, { useState, useEffect } from 'react';
import ModalComponent from '../../../components/Modal.component';
import DataTableContainerComponent from '../../../components/DataTableSortingContainer.component';
import GridContainer from '../../../components/material-dashboard-pro-react/Grid/GridContainer';
import Card from '../../../components/material-dashboard-pro-react/Card/Card';
import CardHeader from '../../../components/material-dashboard-pro-react/Card/CardHeader';
import CardBody from '../../../components/material-dashboard-pro-react/Card/CardBody';
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import SelectBoxComponent from '../../../components/SelectBox.component';
import { getCities } from '../../delivery-info/services/serverApi.service';
import { getDeliveryManInCity } from '../../dashboard/services/serverAPI.service';
import { getDeliveryManInCities } from '../service/serverAPI.service';
import LastKnowLocationComponent from '../components/LastKnowLocation.component';
import {
  formatTimeForDelivery,
  formatTimeForOrder,
} from '../../../helpers/index';
import {
  Grid,
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  FormControlLabel,
  Menu,
  MenuItem,
  Select,
} from '@material-ui/core';
import ButtonComponent from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import { filtersProviders} from '../mock';
import * as yup from 'yup';
import { useFormik } from 'formik';
const validationSchema = yup.object({});

export default function ProviderDispatchingPage() {
  const [resultsData, setResultsData] = useState({
    items: [],
  });

  const [cities, setCities] = useState([]);
  const [tbaleCities, setTableCities] = useState([]);
  const [deliveryMen, setDeliveryMen] = useState([]);
  const [initDeliveryMen, setInitDeliveryMen] = useState([]);
  const [deliveryMan, setDeliveryMan] = useState({});
  const [cityId, setCityId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState('');
  const [checkedObj, setCheckedObj] = useState({});
  const [selectedCity, setSelectedCity] = useState([]);
  const customColumnStyle = {
    // wordWrap: "break-word",
    maxWidth: '300px',
    minWidth: '90px',
    fontSize: '13px',
  };
  const formik = useFormik({
    initialValues: {
      cityList: [],
      filtertype: '',
    },
    validationSchema,
  });
  const columns = [
    {
      Header: 'ville',
      accessor: 'ville',
    },
    {
      Header: ' Version',
      accessor: 'appVersion',
    },
    {
      Header: 'name',
      accessor: 'name',
    },
    {
      Header: 'email',
      accessor: 'email',
    },
    {
      Header: 'phone',
      accessor: 'phone',
    },
    {
      Header: 'dispatchable',
      accessor: 'dispatchableCol',
    },
    {
      Header: 'planified',
      accessor: 'planified',
    },
    {
      Header: 'available',
      accessor: 'availableCol',
    },
    {
      Header: 'GPS',
      accessor: 'GPS',
    },
    {
      Header: 'Location',
      accessor: 'location',
    },
    {
      Header: 'Batterie',
      accessor: 'batteryLevel',
    },
    {
      Header: 'App',
      accessor: 'appState',
    },
    {
      Header: 'last position date',
      accessor: 'lastPositionDate',
    },
    {
      Header: 'lastHeartBeat',
      accessor: 'lastHeartBeatDateCol',
    },
    {
      Header: 'lastPosition',
      accessor: 'lastKnownLocationsCol',
    },
    {
      Header: 'Andriod',
      accessor: 'andriod',
    },
    // {
    //   Header: 'approved',
    //   accessor: 'approvedCol',
    // },
   
    // {
    //   Header: 'Raison dispatchable',
    //   accessor: 'dispatchableRaisonCol',
    // },
    // {
    //   Header: 'Raison Battery',
    //   accessor: 'dispatchableRaisonBatteryCol',
    // },
    // {
    //   Header: 'Raison GPS',
    //   accessor: 'dispatchableRaisonGpsCol',
    // },
    // {
    //   Header: 'Raison Planning',
    //   accessor: 'dispatchableRaisonPlanningAcceptrCol',
    // },
    // {
    //   Header: 'Raison accept',
    //   accessor: 'dispatchableRaisonAcceptCol',
    // },
    // {
    //   Header: 'location',
    //   accessor: 'locationCol',
    // },
    // {
    //   Header: 'app version',
    //   accessor: 'appVersion',
    // },
    // {
    //   Header: 'lastHeartBeatDate',
    //   accessor: 'lastHeartBeatDateCol',
    // },
    // {
    //   Header: 'lastKnownLocations',
    //   accessor: 'lastKnownLocationsCol',
    // },
    // {
    //   Header: 'createdAt',
    //   accessor: 'createdAtCol',
    // },
    // {
    //   Header: 'updatedAt',
    //   accessor: 'updatedAtCol',
    // },
  ];
  const filters = ()=>{

    return (
    
        <CardBody>
          <Grid container justify="flex-end" className="mb-2">
          <ButtonComponent
            round
            type="submit"
            color="success"
            loading={isLoading}
            loaderColor="white"
            onClick={() => {
              window.location.reload(false)
            }}
            size="sm"
          >
             reload
         </ButtonComponent>
          </Grid>

        <Grid container justify="flex-start">  
          <div style={{width:'200px'}}>
          {cities && (
            <SelectBoxComponent
              items={cities.map((city) => ({
                value: city._id,
                label: city.name,
              }))}
              inputLabel="Villes"
              onValueChange={(items) => {
                changeCity(items);
                console.log(items);
                //   setServiceId(items)
                formik.setFieldValue('cityList', items);
              }}
              // value={cities.find((item) => item._id == cityId)?.name}
              value={formik.values.cityList}
              multiple
            />
          )}
          </div>
          <div style={{width:'200px'}}>
            <SelectBoxComponent
              items={Object.values(filtersProviders).map((item) => ({
                value: item,
                label: item,
              }))}
              inputLabel="Filters"
              onValueChange={formik.handleChange('filtertype')}
              value={formik.values.filtertype}
            />
            </div>
        </Grid>
          {/* <Grid container justify="flex-end">
            <ButtonComponent
              round
              type="submit"
              color="success"
              loading={isLoading}
              loaderColor="white"
              onClick={() => {
                changeCity(selectedCity.length ? selectedCity : ['all']);
              }}
            >
              reload
            </ButtonComponent>
          </Grid> */}
          
          </CardBody>
     
   
    )
      

  }

  const getAllCities = () => {
    getCities(0, 0, false)
      .then(({ data }) => {
        setTableCities(data);
        const TemCityObj = {
          all: true,
        };
        data.map((city) => {
          TemCityObj[city.name] = true;
        });
        data.unshift({ _id: 'all', name: 'all' });
        const tempSelectedCityArray = [];
        data.map((city) => {
          tempSelectedCityArray.push(city._id);
        });
        setSelectedCity(tempSelectedCityArray);
        setCities(data);
        setCheckedObj(TemCityObj);
        changeCity(['all']);
      })
      .catch((error) => console.log(error));
  };
  const handleModal = (item, type) => {
    // console.log('type', type, index, item);
    // handleClose();
    setDeliveryMan(item);
    // setIndexStore(index);
    setOpenModal(type);
  };
  const handleCloseModal = () => {
    // setOpen('');
    setOpenModal('');
  };
  const resetDataTabale = () => {
    setResultsData({ items: [] });
  };
  const changeCity = (citiesId) => {
    // console.log(citiesId);
    if (citiesId.includes('all')) {
      getDeliveryManInCity('all')
        .then(({ data }) => {
          // console.log(data);
          setDeliveryMen(data.deliveryMen);
          setInitDeliveryMen(data.deliveryMen);
        })
        .catch((error) => {
          setDeliveryMen([]);
          setInitDeliveryMen([]);
          resetDataTabale();
        });
    } else {         
      getDeliveryManInCities(citiesId)
        .then(({ data }) => {
          setDeliveryMen(data.deliveryMen);
          setInitDeliveryMen(data.deliveryMen);
        })
        .catch((error) => {
          setDeliveryMen([]);
          setInitDeliveryMen([]);
          resetDataTabale();
        });
    }
  };
  useEffect(() => {
    getAllCities();
  }, []);
 console.log(deliveryMen)
  const prepareToShowDeliveryMen = (delivery, index) => {
   const url = `https://maps.google.com?q=${delivery.location[0]},${delivery.location[1]}`
    return {
      ...delivery,
      locationCol: JSON.stringify(delivery.location || ''),
      availableCol: delivery.available ? 'YES' : 'NO',
      approvedCol: delivery.approved ? 'YES' : 'NO',
      dispatchableCol: delivery.dispatchable ? 'YES' : 'NO',
      lastKnownLocationsCol: (
        <div
          style={{ cursor: 'pointer' }}
          fontSize="small"
          onClick={() => {
            handleModal(delivery, 'lastLocation');
          }}
        >
          {delivery.lastKnownLocations.length}
        </div>
      ),
      createdAtCol: formatTimeForDelivery(delivery.createdAt),
      updatedAtCol: formatTimeForDelivery(delivery.updatedAt),
      ville:
        cities.find((e) => e._id == delivery.cityId)?.name || delivery.cityId,
      planified: delivery.planified ? 'Yes' : 'No',
      location: <div >
        <a href={url} target='_blank'>{delivery.location}</a>
      </div>,
      GPS:delivery.GPSOn ? 'ON' : 'OFF',
      lastPositionDate:delivery?.lastPositionDate ? formatTimeForDelivery(delivery.lastPositionDate) : '',
      batteryLevel:delivery?.batteryLevel ? parseFloat(delivery.batteryLevel).toFixed(2) : '',
      

      // dispatchableRaisonCol : (
      //   <div>
      //     B : {delivery.batteryCritical?'Yes':'No'}{' '}
      //     G : {delivery.GPSOn?'Yes':'No'}{' '}
      //     P : {delivery.planified?'Yes':'No'}{' '}
      //     A : {delivery.available?'Yes':'No'}
      //   </div>
      // ),
      dispatchableRaisonAcceptCol: delivery.available ? 'Yes' : 'No',
      dispatchableRaisonBatteryCol: delivery.batteryCritical ? 'Yes' : 'No',
      dispatchableRaisonGpsCol: delivery.GPSOn ? 'Yes' : 'No',
      dispatchableRaisonPlanningAcceptrCol: delivery.planified ? 'Yes' : 'No',
      lastHeartBeatDateCol:
        delivery.lastHeartBeatDate &&
        formatTimeForOrder(delivery.lastHeartBeatDate),
    };
  };
  // console.log(checkedObj);
  // console.log(cities);
  const handleChangeFilter = () => {
    let delivreyManStatus = initDeliveryMen;
    switch (formik.values.filtertype) {
      case filtersProviders.dispatchable:
        delivreyManStatus = delivreyManStatus.filter((e) => e.dispatchable);
        setDeliveryMen(delivreyManStatus);
        break;
      case filtersProviders.Ndispatchable:
        delivreyManStatus = delivreyManStatus.filter((e) => !e.dispatchable);
        setDeliveryMen(delivreyManStatus);
        break;
        case filtersProviders.planified:
          delivreyManStatus = delivreyManStatus.filter((e) => e.planified);
          setDeliveryMen(delivreyManStatus);
          break;
        case filtersProviders.Nplanified:
          delivreyManStatus = delivreyManStatus.filter((e) => !e.planified);
          setDeliveryMen(delivreyManStatus);
          break;
          case filtersProviders.available:
            delivreyManStatus = delivreyManStatus.filter((e) => e.available);
            setDeliveryMen(delivreyManStatus);
            break;
          case filtersProviders.Navailable:
            delivreyManStatus = delivreyManStatus.filter((e) => !e.available);
            setDeliveryMen(delivreyManStatus);
            break;
            case filtersProviders.GPSOn:
              delivreyManStatus = delivreyManStatus.filter((e) => e.GPSOn);
              setDeliveryMen(delivreyManStatus);
              break;
            case filtersProviders.GPSOff:
              delivreyManStatus = delivreyManStatus.filter((e) => !e.GPSOn);
              setDeliveryMen(delivreyManStatus);
              break;
              case filtersProviders.AppActive:
              delivreyManStatus = delivreyManStatus.filter((e) => e.appState == "active");
              setDeliveryMen(delivreyManStatus);
              break;
            case filtersProviders.AppBackgound:
              delivreyManStatus = delivreyManStatus.filter((e) => e.appState == "background");
              setDeliveryMen(delivreyManStatus);
              break;
            case filtersProviders.AppInactive:
              delivreyManStatus = delivreyManStatus.filter((e) => e.appState == "inactive");
              setDeliveryMen(delivreyManStatus);
                break;
            
      case filtersProviders.All:
        setDeliveryMen(delivreyManStatus);
        break;

      default:
        break;
    }
  };
  useEffect(() => {
    deliveryMen &&
      deliveryMen.length > 0 &&
      setResultsData({
        items: deliveryMen.map((delivery, index) => {
          return prepareToShowDeliveryMen(delivery, index);
        }),
      });
  }, [JSON.stringify(deliveryMen)]);

  useEffect(() => {
    handleChangeFilter();
  }, [formik.values.filtertype]);
  // console.log(selectedCity);
  return (
    <div>
      {/* <GridContainer>
        <Card>
          <CardHeader>DeliveryMen In :</CardHeader>
          <CardBody>
          <Grid container justify="flex-start">  
            <div style={{width:'200px'}}>
            {cities && (
              <SelectBoxComponent
                items={cities.map((city) => ({
                  value: city._id,
                  label: city.name,
                }))}
                inputLabel="Villes"
                onValueChange={(items) => {
                  changeCity(items);
                  console.log(items);
                  //   setServiceId(items)
                  formik.setFieldValue('cityList', items);
                }}
                // value={cities.find((item) => item._id == cityId)?.name}
                value={formik.values.cityList}
                multiple
              />
            )}
            </div> */}
            {/* <div style={{width:'200px'}}>
            <SelectBoxComponent
              items={Object.values(filtersProviders).map((item) => ({
                value: item,
                label: item,
              }))}
              inputLabel="dispatchable"
              onValueChange={formik.handleChange('filtertype')}
              value={formik.values.filtertype}
            />
            </div> */}
        
          {/* </Grid>
            <Grid container justify="flex-end">
              <ButtonComponent
                round
                type="submit"
                color="success"
                loading={isLoading}
                loaderColor="white"
                onClick={() => {
                  changeCity(selectedCity.length ? selectedCity : ['all']);
                }}
              >
                reload
              </ButtonComponent>
            </Grid>
            <Grid container> */}
              {/* <FormControl style={{ minWidth: '200px' }}>
          <InputLabel>Cities</InputLabel>
          <Select
            value={selectedCity}
            onChange={($event) => {
              setSelectedCity($event.target.value);
              loadOrderList($event.target.value);
            }}
          >
            {cities.map((item, index) => (
              <MenuItem value={item._id}>{item.name}</MenuItem>
            ))}
          </Select>
        </FormControl> */}
              {/* {
            // selectedCheckbox.length > 0 
            checkedObj&&Object.keys(checkedObj).length && cities.map((item, index) => (
              <FormControlLabel
                control={<Checkbox color="primary" 
                  checked={checkedObj[item.name]}
                  onChange={(e) => {
                    if (e.target.checked) {


                      let tempSelectedCityArray = [...selectedCity];
                      tempSelectedCityArray.includes(item._id) ? tempSelectedCityArray.map((elem, index) => (elem == item._id && tempSelectedCityArray.splice(index, 1))) : tempSelectedCityArray.push(item._id)
                      // tempSelectedCityArray.length == cities.length-1&&tempSelectedCityArray.push("all")
                      if (item._id == cities[0]._id) {
                        tempSelectedCityArray.length = 0
                        const newCheckedObj = { ...checkedObj }
                        for (const key in newCheckedObj) {
                          newCheckedObj[key] = true
                        }
                        setCheckedObj(newCheckedObj)
                        cities.map(e => tempSelectedCityArray.push(e._id));
                      } else {
                        const newCheckedObj = { ...checkedObj }
                        newCheckedObj[item.name] = true
                        setCheckedObj(newCheckedObj)
                      }

                      setSelectedCity(tempSelectedCityArray);
                      changeCity(tempSelectedCityArray)
                    } else {
                      if (item._id !== cities[0]._id) {
                        const tempSelectedCityArray = [...selectedCity];
                        const newCheckedObj = { ...checkedObj }

                        newCheckedObj[cities[0].name] = false
                        newCheckedObj[item.name] = false
                        setCheckedObj(newCheckedObj)
                        const findCityIndex = tempSelectedCityArray.indexOf(item._id)
                        if (findCityIndex >= 0) {
                          tempSelectedCityArray.splice(findCityIndex, 1);
                          const findIndexForAll = tempSelectedCityArray.indexOf("all")
                          findIndexForAll>=0&&tempSelectedCityArray.splice(findIndexForAll, 1);
                          setSelectedCity(tempSelectedCityArray);
                          tempSelectedCityArray.length && changeCity(tempSelectedCityArray);
                        }
                      } else {
                        const newCheckedObj = { ...checkedObj }
                        for (const key in newCheckedObj) {
                          newCheckedObj[key] = false
                        }
                        setCheckedObj(newCheckedObj)
                        setSelectedCity([])
                        setResultsData({ items: [] })
                      }
                    }
                  }}
                />}
                label={item.name}
              />
            ))} */}
            {/* </Grid>
          </CardBody>
        </Card>
      </GridContainer> */}
      {/* {(deliveryMen&&deliveryMen.length)? */}
      <>
      
        <DataTableContainerComponent
          // total={resultsData.total}
          resultsData={resultsData.items}
          initData={resultsData.items}
          columns={columns}
          title="dispatched Provider List"
          isLoading={isLoading}
          // loadItemsList={loadApprouvedProviderList}
          // onPaginate={handlePaginate}
          isPaginate={false}
          // customColumnStyle={customColumnStyle}
          searchInp={true}
          SearchComponent={filters}
        />
        {openModal && (
          <ModalComponent
            maxWidth={false}
            close={handleCloseModal}
            visible={openModal === 'lastLocation'}
            title="provider last location"
          >
            <LastKnowLocationComponent
              lastLocation={deliveryMan.lastKnownLocations}
              close={handleCloseModal}
            />
          </ModalComponent>
        )}
      </>
      {/* :'' */}
      {/* } */}
    </div>
  );
}
