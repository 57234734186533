import React, { useEffect, useState } from 'react';
// material api
import { Button, Grid, Typography, Box } from '@mui/material';
// component
import SelectBoxComponent from '../../../components/SelectBox.component';
import DeliveryHeaderInfoComponenet from './Delivery.header.info.componenet';

// mock
import { DeliveryManStatus } from '../mock';
// api
import { changeStatus } from '../services/server.api.service';

export default function ChangeStatusComponent({
  deliveries,
  deliverieId,
  admin,
  close,
}) {
  const [deliverie, setDeliverie] = useState({});
  const [selectedStatus, setSelectedStatus] = useState('');
  const handleStatus = () => {
    const deliveryManId = !!deliverie.deliveryMan
      ? deliverie.deliveryMan._id
      : null;
    if (deliveryManId) {
      changeStatus(deliverie._id, {
        deliveryManId: deliveryManId,
        status: selectedStatus,
        admin,
      })
        .then(({ data }) => {
          close();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  useEffect(() => {
    setDeliverie((deliveries || []).find((x) => x._id == deliverieId));
  }, []);
  useEffect(() => {
    deliverie && setSelectedStatus(deliverie.deliveryManStatus);
  }, [deliverie]);
  return (
    <div>
      <DeliveryHeaderInfoComponenet delivery={deliverie} />
      <Box sx={{ display: 'flex' }} style={{ padding: '15px' }}>
        <SelectBoxComponent
          items={DeliveryManStatus.map((item) => ({
            value: item,
            label: item,
          }))}
          inputLabel="status"
          onValueChange={(item) => {
            setSelectedStatus(item);
          }}
          value={selectedStatus}
        />
        <div style={{ float: 'right', margin: '15px' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              handleStatus();
            }}
          >
            save
          </Button>
        </div>
      </Box>
    </div>
  );
}
