import React , { useEffect, useState } from 'react'
import { Draggable } from "react-beautiful-dnd";
import { useSelector,useDispatch } from 'react-redux';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import CloseIcon from '@material-ui/icons/Close';
import { useForm, useFieldArray, Controller } from "react-hook-form";
import Box from '@material-ui/core/Box';
import CustomControllerField from '../../../components/material-dashboard-pro-react/customControllerField/customControllerField'
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button'
import SwitchComponent from '../../../components/Switch.component'
import ImageUpload from '../../../components/material-dashboard-pro-react/CustomUpload/CustomMultiUpload';
import {multiUploadImageResetAction} from '../../../redux/multiUploadRedux/upload.action'


export default function TagsListComponent(props) {
    const {
        index,
        length,
        setAllAddressData,
        iconUrl,
        form: { field, register, control, setValue, getValues, remove },
        dataLength,
        lengthOfList,
    setLengthOfList
      } = props;
      // console.log(field);
      const [imgUrl,setImgUrl] = useState('')
      const dispatch = useDispatch()
      const upload = useSelector((state) => state.multiUploadReducer)
    const { multiIconUrl, error, loading, newUpload } = upload
      const removeWaypoint = () => {
        // setLengthOfList(lengthOfList-1)
        remove(index);
      };
      const removeWaypointNow = (index) => {
        remove(index);
      };
    
      const handleContactInputChange = evt => {
        const nums = evt.target.value.replace(/[^\d]/g, "");
        setValue(evt.target.name, nums.slice(0, 10));
      };
   
      useEffect(() => {
        if(!loading&&multiIconUrl&&multiIconUrl[field._id]){
          setValue(`tags[${index}].iconUrl`, multiIconUrl[field._id])
          field.iconUrl = multiIconUrl[field._id]
          setImgUrl(multiIconUrl[field._id])
        }else{
          setValue(`tags[${index}].iconUrl`, field.iconUrl)
        }
      }, [multiIconUrl])
      return (
        <Draggable draggableId={field.id} index={index}>
          {provided => (
              // console.log(`tags[${index}].name`),
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            //   className="panel"
            >
                {/* <div style={{ display: 'flex', overflowX: 'auto' }}> */}
                <div style={{ flex: '0 0 20%', border: "1px solid #000", borderRadius: "10px", padding: " 0 15px", margin: '0 2px', }}>
                    {/* <InputLabel style={{ marginTop: '0' }}>tag :{index + 1} </InputLabel> */}
                    
                    <Box display="flex" justifyContent="center">
                       { !loading&&<ImageUpload
                            label="Select icon"
                            folder="service"
                            // index={`${index}`}
                            index={field._id}
                            style={{ img: { width: '30px', height: '30px' } }}
                            btnSize="sm"
                            // errorMessage={errorsImage[`${index}`]}
                            // defaultImage={`tags[${index}].iconUrl`}
                            defaultImage={multiIconUrl[field._id]||field.iconUrl}
                            displayMessage = {true}
                        />}
                    </Box>
                    {/* <img src={iconUrl} alt="aaa" /> */}
                    {/* <Field name={`tags.${index}.name`} style={{border:'none'}} placeholder="name" /> */}
                    <CustomControllerField
                    labelText="name"
                    formControlProps={{
                        fullWidth: false,
                    }}
                    // type="text"
                    name={`tags[${index}].name`}
                    control={control}
                    id={`nameInput[${index}]`}
                    defaultValue={field.name}
    
                    ref={register()}
                    //   onChange={sanitizeInputs}
                    onChange={(evt) => setValue(evt.target.name, evt.target.value)}
                    autoComplete="off"
                    spellCheck="false"
                    
                    />
                    <CustomControllerField
                    labelText="name"
                    formControlProps={{
                        fullWidth: false,
                    }}
                    // type="text"
                    name={`tags[${index}].iconUrl`}
                    control={control}
                    id={`nameInput[${index}]`}
                    defaultValue={field.iconUrl}
    
                    ref={register()}
                    //   onChange={sanitizeInputs}
                    onChange={(evt) => setValue(evt.target.iconUrl, evt.target.value)}
                    autoComplete="off"
                    spellCheck="false"
                    hidden={true}
                    />
                    <Box textAlign="right">
                        <Button
                        round
                            size="sm"
                            color="danger"
                            loaderColor="white"
                            onClick={removeWaypoint}
                        >
                            delete tag
                        </Button>
                    </Box>
                </div>
                {/* </div> */}
              
            </div>
          )}
        </Draggable>
      )
    }
    
    // {
    //     // index<dataLength&&
    //     // index&&
    //       <>
    //       <div className="panel-head">
    //       <span>item {index + 1}</span>
    //     </div>
    //     <div className="panel-body">
    //       {/* <div className="waypoint-address">
            
            
    //       </div> */}
    //       <div className="waypoint-info" style={{ display: "flex", borderRadius: "10px", border: '1px solid #000', paddingLeft: '10px', justifyContent: 'space-around' }}>
    //         <DragHandleIcon />
    //         <div>
    //           {/* <label htmlFor={`nameInput[${index}]`}>name</label> */}


              



              


    //         </div>
    //         {/* <div> */}
    //           {/* <label htmlFor={`priceInput[${index}]`}>price</label> */}
    //           {/* <CustomControllerField
    //             labelText="price (DH)"
    //             formControlProps={{
    //               fullWidth: false,
    //             }}
    //             name={`list[${index}].price`}
    //             control={control}
    //             id={`priceInput[${index}]`}
    //             defaultValue={field.price}

    //             ref={register()}
    //             onChange={(evt) => setValue(evt.target.name, evt.target.value)}
    //             autoComplete="off"
    //             spellCheck="false"
    //           /> */}

    //         {/* </div> */}
            
    //         {/* <Controller
    //           as={SwitchComponent}
    //           name={`list[${index}].acceptMultiple`}
    //           label="acceptMultiple"
    //           control={control}
    //           defaultValue={field.acceptMultiple}

    //         /> */}

    //         {/* <Controller
    //           as={SwitchComponent}
    //           name={`list[${index}].defaultselected`}
    //           label="defaultselected"
    //           control={control}
    //           defaultValue={field.defaultselected}

    //         /> */}

    //         {/* <Box textAlign="center">
    //           <Button
    //             // round
    //             type="submit"
    //             color="danger"
    //             size="sm"
    //             loaderColor="white"
    //             onClick={removeWaypoint}
    //           >
    //             delete
    //           </Button>
    //         </Box> */}
    //       </div>

    //     </div>
    //     </>}