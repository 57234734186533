import { GET_SETTING_REQUEST, GET_SETTING_SUCCESS, GET_SETTING_FAIL, } from '../constants/setting.constants'


const initialState = {
    setting: [],
    error: "",
    loading: false,
};

export default function ordersReducer(state = initialState, action) {

    let setting = [];

    switch (action.type) {
        case GET_SETTING_REQUEST:
            return {
                loading: true
            }

        case GET_SETTING_SUCCESS:
            return {
                ...state,
                loading: false,
                setting: action.setting,
            };
        case GET_SETTING_FAIL:
            return {
                ...state,
                loading: false,
                setting: [],
                error: action.error?.message || (action?.error?.errors || [{ message: "" }])[0].message,
            };

        default:
            return state;
    }
}