import React from 'react';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { renderStatus } from '../helper/index';
import { TableCell, TableRow, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import {
  columns,
  columnsDispatched,
  partnerStatusBackgroundColor,
  deliveryStatusBackgroundColor,
} from '../mock';

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip arrow {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));
export default function StatusPartnerComponent({ deliveryStateTimes }) {
  return (
    <div>
      {deliveryStateTimes && deliveryStateTimes.length > 0 && (
        <HtmlTooltip
          title={
            <React.Fragment>
              {deliveryStateTimes.map((r) => (
                <TableRow>
                  <TableCell align="center">{r.status}</TableCell>
                  <TableCell align="center">
                    {moment(r.date).format('hh:mm:ss')}
                  </TableCell>
                </TableRow>
              ))}
            </React.Fragment>
          }
        >
          <span
            style={{
              backgroundColor:
                partnerStatusBackgroundColor[
                  deliveryStateTimes[deliveryStateTimes.length - 1]['status']
                ],
              width: '100px',
              fontSize: '11px',
              padding: '5px',
              borderRadius: '4px',
              color: 'white',
              whiteSpace: 'nowrap',
            }}
          >
            {/*{item.deliveryStateTimes}*/}
            {/*{JSON.stringify(item.deliveryStateTimes)}*/}
            {deliveryStateTimes[deliveryStateTimes.length - 1]['status']}
            {/*{*/}
            {/*  partnerStatusBackgroundColor[deliveryStateTimes[*/}
            {/*  deliveryStateTimes.length - 1*/}
            {/*      ]['status']]*/}
            {/*}*/}
          </span>
        </HtmlTooltip>
      )}
    </div>
  );
}
