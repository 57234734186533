import React, { useEffect } from 'react';
import Slider from 'nouislider';
import PropTypes from 'prop-types';

export default function SliderComponent({ startValue, onChange, ...props }) {
  useEffect(() => {
    if (
      !document
        .getElementById('sliderRegular')
        .classList.contains('noUi-target')
    ) {
      const slider = Slider.create(document.getElementById('sliderRegular'), {
        start: [startValue || 0],
        connect: [true, false],
        step: 1,
        range: { min: 0, max: 100 },
      });

      slider.on('change', (value) => onChange(Number(value[0])));
    }
  }, []);

  return <div {...props} id="sliderRegular" className="slider-primary" />;
}

SliderComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
  startValue: PropTypes.number,
};
