import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useAlert } from 'react-alert';
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import CustomInput from '../../../components/material-dashboard-pro-react/CustomInput/CustomInput';
import {
  getCities,
  updateCategoryAppUser,
} from '../services/serverApi.service';
import SelectBoxComponent from '../../../components/SelectBox.component';
import ImageUpload from '../../../components/material-dashboard-pro-react/CustomUpload/ImageUpload';
import {
  defaultImagesAction,
  uploadImageResetAction,
} from '../../../redux/uploadRedux/upload.action';
import Switch from '@material-ui/core/Switch';

const validationSchema = yup.object({
  name: yup.string().required(),
  cityList: yup.array().required(),
  iconUrl: yup.string().optional(),
});

export default function EditCategoryAppUserComponent({
  data,
  onItemEdited,
  close,
}) {
  const dispatch = useDispatch();
  const alert = useAlert();
  const upload = useSelector((state) => state.uploadReducer);
  const { iconUrl, error, loading } = upload;
  const [isLoading, setIsLoading] = useState(false);
  const [displayImage, setDisplayImage] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: '',
      baseName: '',
      cityList: [],
      iconUrl: '',
    },
    validationSchema,
  });
  const [cities, setCities] = useState([]);

  const onUpdate = () => {
    setIsLoading(true);

    console.log(JSON.stringify(formik.values));
    updateCategoryAppUser(data._id, formik.values)
      .then(() => {
        alert.show('Service category has been updated', {
          type: 'success',
        });
        dispatch(uploadImageResetAction());
        close();
        onItemEdited();
      })
      .catch((error) => {
        alert.show(error.message, {
          type: 'error',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  function setData() {
    if (data) {
      !iconUrl && dispatch(defaultImagesAction(data?.iconUrl));
      formik.setValues({
        name: data?.name,
        baseName: data?.baseName,
        cityList: data?.cityList.map((city) => city?._id),
        iconUrl: iconUrl,
      });
    }
  }

  useEffect(() => {
    iconUrl && formik.setFieldValue('iconUrl', iconUrl);
  }, [iconUrl]);
  useEffect(() => {
    setData();

    getCities()
      .then(({ data }) => {
        setCities(data);
      })
      .catch((error) => {
        alert.show(error.message, {
          type: 'error',
        });
      });
  }, [data]);

  return (
    <div>
      <div>
        {!displayImage && <span>you need to upload a new image</span>}
        <Switch
          color="primary"
          checked={displayImage}
          value={displayImage}
          onChange={(e) => {
            displayImage ? setDisplayImage(false) : setDisplayImage(true);
          }}
        />
      </div>
      <Box display="flex" justifyContent="center">
        {Boolean(data) &&
          (!displayImage ? (
            <img src={data.iconUrl || ''} alt="dqs" height="100" width="120" />
          ) : (
            <ImageUpload label="Select icon" avatar folder="service" />
          ))}
      </Box>
      <CustomInput
        labelText="Name"
        formControlProps={{
          fullWidth: true,
        }}
        type="text"
        error={formik.touched.name && Boolean(formik.errors.name)}
        inputProps={{
          value: formik.values.name,
          onBlur: formik.handleBlur('name'),
          onChange: formik.handleChange('name'),
        }}
      />
      <SelectBoxComponent
        items={cities.map((city) => ({
          value: city._id,
          label: city.name,
        }))}
        inputLabel="City list"
        onValueChange={(items) => {
          formik.setFieldValue('cityList', items);
        }}
        value={formik.values.cityList || []}
        multiple
      />
      <CustomInput
        labelText="baseName"
        formControlProps={{
          fullWidth: true,
          disabled: true,
        }}
        type="text"
        error={formik.touched.baseName && Boolean(formik.errors.baseName)}
        inputProps={{
          value: formik.values.baseName,
          onBlur: formik.handleBlur('baseName'),
          onChange: formik.handleChange('baseName'),
        }}
      />

      <Box textAlign="center">
        <Button
          round
          type="submit"
          color="success"
          disabled={!formik.dirty || !formik.isValid || isLoading}
          loading={isLoading}
          loaderColor="white"
          onClick={onUpdate}
        >
          Edit
        </Button>
      </Box>
    </div>
  );
}

EditCategoryAppUserComponent.propTypes = {
  data: PropTypes.any,
  onItemEdited: PropTypes.func.isRequired,
  close: PropTypes.func,
};
