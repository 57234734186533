import {
  GET_RIDE_REQUEST,
  GET_RIDE_SUCCESS,
  GET_RIDE_FAIL,
  CHANGE_RIDE_STATUS,
  NEW_RIDE,
  ACCEPTED_RIDE,
  VALIDATED_RIDE,
  CANCEL_RIDE,
  INITIAL_STATE,
  OPEN_MODEL,
  CLOSE_MODEL,
  CHANGE_RIDE_ALARMS,
  CHANGE_RIDE_ALARM,
  SET_RIDE_ALARMS, CHANGE_RIDE_ALARMS_DATA,
  // V2  
  GET_RIDE_REQUESTV2,
  GET_RIDE_SUCCESSV2,
  GET_RIDE_FAILV2,
  CHANGE_RIDE_STATUSV2,
  CANCEL_RIDEV2,
  ACCEPTED_RIDEV2,
  NEW_RIDEV2,
  VALIDATED_RIDEV2,
} from '../constants/order.constants';

export const getOrderRequestAction = (cities) => ({
  type: GET_RIDE_REQUEST,
  cities
});

export const getOrderSuccessAction = (orders) => ({
  type: GET_RIDE_SUCCESS,
  orders,
});

export const getOrderFailAction = (error) => ({
  type: GET_RIDE_FAIL,
  error,
});

export const changeOrderStatusAction = (order) => ({
  type: CHANGE_RIDE_STATUS,
  order,
});
export const changeOrderAlarmAction = (id, alarms) => ({
  type: CHANGE_RIDE_ALARMS,
  id,
  alarms,
});
export const changeOrderAlarmDataAction = (id, alarm) => ({
  type: CHANGE_RIDE_ALARMS_DATA,
  id,
  alarm,
});
export const changeAlarmAction = (alarm) => ({
  type: CHANGE_RIDE_ALARM,
  alarm,
});
export const setAlarmsAction = (alarms) => ({
  type: SET_RIDE_ALARMS,
  alarms,
});
export const acceptedOrdersAction = (order) => ({
  type: ACCEPTED_RIDE,
  order,
});
export const newOrderAction = (order) => ({
  type: NEW_RIDE,
  order,
});
export const validatOrderAction = (order) => ({
  type: VALIDATED_RIDE,
  order,
});
export const cancelOrderAction = (_id) => ({
  type: CANCEL_RIDE,
  _id,
});
export const initialOrderAction = () => ({
  type: INITIAL_STATE,
});
export const openModelAction = (itemId, index, model) => ({
  type: OPEN_MODEL,
  itemId,
  index,
  model,
});
export const closeModelAction = () => ({
  type: CLOSE_MODEL,
});
// ********************* V2 ******************* 
export const changeOrderStatusV2Action = (ride) => ({
  type: CHANGE_RIDE_STATUSV2,
  ride,
});
export const cancelOrderV2Action = (_id, customerId) => ({
  type: CANCEL_RIDEV2,
  _id,
  customerId
});
export const acceptedOrdersV2Action = (ride) => ({
  type: ACCEPTED_RIDEV2,
  ride,
});
export const newOrderV2Action = (ride) => ({
  type: NEW_RIDEV2,
  ride,
});
export const validatOrderV2Action = (ride) => ({
  type: VALIDATED_RIDEV2,
  ride,
});
export const getOrderRequestV2Action = (cities) => ({
  type: GET_RIDE_REQUESTV2,
  cities
});
export const getOrderSuccessV2Action = (rides) => ({
  type: GET_RIDE_SUCCESSV2,
  rides,
});
export const getOrderFailV2Action = (error) => ({
  type: GET_RIDE_FAILV2,
  error,
});
