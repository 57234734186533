import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useFormik, Field } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useAlert } from 'react-alert';
import { makeStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import CustomInput from '../../../components/material-dashboard-pro-react/CustomInput/CustomInput';
import SelectBoxComponent from '../../../components/SelectBox.component';
import { createStore } from '../services/serverApi.service';
import {
  getCities,
  getServices,
} from '../../delivery-info/services/serverApi.service';
import { uploadImageResetAction } from '../../../redux/uploadRedux/upload.action';
import GridContainer from '../../../components/material-dashboard-pro-react/Grid/GridContainer';
import GridItem from '../../../components/material-dashboard-pro-react/Grid/GridItem';
import ImageUpload from '../../../components/material-dashboard-pro-react/CustomUpload/ImageUpload';
import { commissionTypes,deliveryManPayStoreStatus } from '../mocks';
import SliderComponent from '../../../components/Slider.component';
import SwitchComponent from '../../../components/Switch.component';
import styles from '../../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle';

const useStyles = makeStyles(styles);

const validationSchema = yup.object({
  name: yup.string().required(),
  displayName: yup.string().required(),
  phone: yup.string().required(),
  email: yup.string().required(),
  isBusiness: yup.bool().required(),
  open: yup.bool().required(),
  approved: yup.bool().required(),
  isPartner: yup.bool().required(),
  cityId: yup.string().required(),
  // serviceId: yup.string().required(),
  address: yup.string().required(),
  commission: yup.number().required(),
  rewardPoints: yup.number().required(),
  storeRewardPoints: yup.number().required(),
  preparationTimeMin: yup.number().required(),
  preparationTimeMax: yup.number().required(),
  maxDisatnce: yup.number().required(),
  kaalixLoyalty: yup.number().required(),
  storeLoyalty: yup.number().required(),
  commissionType: yup.string().required(),
  location: yup.array().min(2).required(),
  imageUrl: yup.string().optional(),
  deliveryManPayStore: yup.string().required(),
});

export default function CreateStoreComponent({ onItemCreated, close }) {
  const classes = useStyles();
  const alert = useAlert();
  const [isLoading, setIsLoading] = useState(false);
  const [cities, setCities] = useState([]);
  const [services, setServices] = useState([]);
  const dispatch = useDispatch();
  const upload = useSelector((state) => state.uploadReducer);
  const { iconUrl, error, loading } = upload;
  const formik = useFormik({
    initialValues: {
      location: [],
      name: '',
      displayName: '',
      phone: '',
      contact: '',
      email: '',
      isBusiness: false,
      open: false,
      approved: false,
      isPartner: false,
      cityId: '',
      serviceId: '',
      address: '',
      commission: 0,
      rewardPoints: 0,
      storeRewardPoints: 0,
      preparationTimeMin: 0,
      preparationTimeMax: 0,
      maxDisatnce: 0,
      kaalixLoyalty: 0,
      storeLoyalty: 0,
      commissionType: '',
      imageUrl: '',
      deliveryManPayStore:deliveryManPayStoreStatus.AlwaysPay
    },
    validationSchema,
  });

  const onCreate = () => {
    console.log(formik.values, formik.errors);
    if (formik.isValid) {
      setIsLoading(true);
      createStore(formik.values)
        .then(() => {
          alert.show('Store has been created', {
            type: 'success',
          });
          formik.resetForm();
          close();
          onItemCreated();
          dispatch(uploadImageResetAction());
        })
        .catch((error) => {
          alert.show(error.message, {
            type: 'error',
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };
  // const setLocationValue = ()=>{
  //   const arrayLocation = [];
  //   formik.values.latitude&&(arrayLocation[0]=formik.values.latitude);
  //   formik.values.longitude && (arrayLocation[1]=formik.values.longitude);
  //   formik.setFieldValue('location',arrayLocation)
  // }
  useEffect(() => {
    iconUrl && formik.setFieldValue('imageUrl', iconUrl);
    getCities().then(({ data }) => {
      setCities(data);
    });

    getServices().then(({ data }) => {
      setServices(data);
    });
    // setLocationValue()
  }, [iconUrl]);
  // console.log(formik.errors);
  // console.log(formik.values.location);
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Box justifyContent="center" display="flex">
            <ImageUpload
              // onSubmit={onUploadMedia}
              // onRemove={onRemoveImage}
              label="Select icon"
              folder="service"
              avatar
            />
          </Box>
        </GridItem>
        <GridItem xs={12} sm={12} md={3}>
          <FormLabel className={classes.labelHorizontal}>Name</FormLabel>
        </GridItem>
        <GridItem xs={12} sm={12} md={9}>
          <CustomInput
            formControlProps={{
              fullWidth: true,
            }}
            type="text"
            error={formik.touched.name && Boolean(formik.errors.name)}
            inputProps={{
              value: formik.values.name,
              onBlur: formik.handleBlur('name'),
              onChange: formik.handleChange('name'),
            }}
          />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={3}>
          <FormLabel className={classes.labelHorizontal}>Address</FormLabel>
        </GridItem>
        <GridItem xs={12} sm={12} md={9}>
          <CustomInput
            formControlProps={{
              fullWidth: true,
            }}
            type="text"
            error={formik.touched.address && Boolean(formik.errors.address)}
            inputProps={{
              value: formik.values.address,
              onBlur: formik.handleBlur('address'),
              onChange: formik.handleChange('address'),
            }}
          />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={3}>
          <FormLabel className={classes.labelHorizontal}>Phone</FormLabel>
        </GridItem>
        <GridItem xs={12} sm={12} md={9}>
          <CustomInput
            formControlProps={{
              fullWidth: true,
            }}
            type="text"
            error={formik.touched.phone && Boolean(formik.errors.phone)}
            inputProps={{
              value: formik.values.phone,
              onBlur: formik.handleBlur('phone'),
              onChange: formik.handleChange('phone'),
            }}
          />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={3}>
          <FormLabel className={classes.labelHorizontal}>contact</FormLabel>
        </GridItem>
        <GridItem xs={12} sm={12} md={9}>
          <CustomInput
            formControlProps={{
              fullWidth: true,
            }}
            type="text"
            error={formik.touched.contact && Boolean(formik.errors.contact)}
            inputProps={{
              value: formik.values.contact,
              onBlur: formik.handleBlur('contact'),
              onChange: formik.handleChange('contact'),
            }}
          />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={3}>
          <FormLabel className={classes.labelHorizontal}>
            Display name
          </FormLabel>
        </GridItem>
        <GridItem xs={12} sm={12} md={9}>
          <CustomInput
            formControlProps={{
              fullWidth: true,
            }}
            type="text"
            error={
              formik.touched.displayName && Boolean(formik.errors.displayName)
            }
            inputProps={{
              value: formik.values.displayName,
              onBlur: formik.handleBlur('displayName'),
              onChange: formik.handleChange('displayName'),
            }}
          />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={3}>
          <FormLabel className={classes.labelHorizontal}>Email</FormLabel>
        </GridItem>
        <GridItem xs={12} sm={12} md={9}>
          <CustomInput
            formControlProps={{
              fullWidth: true,
            }}
            type="email"
            error={formik.touched.email && Boolean(formik.errors.email)}
            inputProps={{
              value: formik.values.email,
              onBlur: formik.handleBlur('email'),
              onChange: formik.handleChange('email'),
            }}
          />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={4} sm={4} md={3}>
          <FormLabel className={classes.labelHorizontal}>longitude</FormLabel>
        </GridItem>
        <GridItem xs={4} sm={4} md={9}>
          <CustomInput
            formControlProps={{
              fullWidth: false,
            }}
            type="number"
            error={formik.touched.location && Boolean(formik.errors.location)}
            inputProps={{
              // value: formik.values.longitude,
              // onBlur: formik.handleBlur('longitude'),
              // onChange: formik.handleChange('longitude'),
              onChange: (e) => {
                e.target.value != 0 &&
                  formik.setFieldValue('location[1]', e.target.value);
              },
            }}
          />
        </GridItem>
        <GridItem xs={4} sm={4} md={3}>
          <FormLabel className={classes.labelHorizontal}>latitude</FormLabel>
        </GridItem>
        <GridItem xs={4} sm={4} md={9}>
          <CustomInput
            formControlProps={{
              fullWidth: false,
            }}
            type="number"
            // error={formik.touched.location && Boolean(formik.errors.location)}
            inputProps={{
              // value: formik.values.latitude,
              // onBlur: formik.handleBlur('latitude'),
              // onChange: formik.handleChange('latitude'),
              onChange: (e) => {
                e.target.value != 0 &&
                  formik.setFieldValue('location[0]', e.target.value);
              },
            }}
          />
          {/* <input onChange={(e)=>console.log(e)}/> */}
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={3}>
          <FormLabel className={classes.labelHorizontal}>Commission</FormLabel>
        </GridItem>
        <GridItem xs={12} sm={12} md={9}>
          <CustomInput
            formControlProps={{
              fullWidth: true,
            }}
            type="number"
            error={
              formik.touched.commission && Boolean(formik.errors.commission)
            }
            inputProps={{
              type: 'number',
              value: formik.values.commission,
              onBlur: formik.handleBlur('commission'),
              onChange: formik.handleChange('commission'),
            }}
          />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={3}>
          <FormLabel className={classes.labelHorizontal}>
            Commission type
          </FormLabel>
        </GridItem>
        <GridItem xs={12} sm={12} md={9}>
          <SelectBoxComponent
            items={commissionTypes.map((commissionType) => ({
              value: commissionType.value,
              label: commissionType.label,
            }))}
            onValueChange={formik.handleChange('commissionType')}
            value={formik.values.commissionType}
          />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={3}>
          <FormLabel className={classes.labelHorizontal}>Service</FormLabel>
        </GridItem>
        <GridItem xs={12} sm={12} md={9}>
          <SelectBoxComponent
            items={services.map((service) => ({
              value: service._id,
              label: service.name,
            }))}
            onValueChange={formik.handleChange('serviceId')}
            value={formik.values.serviceId}
          />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={3}>
          <FormLabel className={classes.labelHorizontal}>deliveryMan PayStore</FormLabel>
        </GridItem>
        <GridItem xs={12} sm={12} md={9}>
          <SelectBoxComponent
            items={Object.keys(deliveryManPayStoreStatus).map((item) => ({
              value: item,
              label: item,
            }))}
            onValueChange={formik.handleChange('deliveryManPayStore')}
            value={formik.values.deliveryManPayStore}
          />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={3}>
          <FormLabel
            className={`${classes.labelHorizontal} ${classes.labelHorizontalRadioCheckbox}`}
          >
            Max distance
          </FormLabel>
        </GridItem>
        <GridItem xs={12} sm={12} md={9}>
          <SliderComponent
            onChange={(value) => formik.setFieldValue('maxDisatnce', value)}
            style={{ marginTop: '34px' }}
          />
          <Box mt={1} align="right">
            {formik.values.maxDisatnce}
          </Box>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={3}>
          <FormLabel className={classes.labelHorizontal}>
            Reward points
          </FormLabel>
        </GridItem>
        <GridItem xs={12} sm={12} md={9}>
          <CustomInput
            formControlProps={{
              fullWidth: true,
            }}
            type="number"
            error={
              formik.touched.rewardPoints && Boolean(formik.errors.rewardPoints)
            }
            inputProps={{
              type: 'number',
              value: formik.values.rewardPoints,
              onBlur: formik.handleBlur('rewardPoints'),
              onChange: formik.handleChange('rewardPoints'),
            }}
          />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={3}>
          <FormLabel className={classes.labelHorizontal}>
            Store reward points
          </FormLabel>
        </GridItem>
        <GridItem xs={12} sm={12} md={9}>
          <CustomInput
            formControlProps={{
              fullWidth: true,
            }}
            type="number"
            error={
              formik.touched.storeRewardPoints &&
              Boolean(formik.errors.storeRewardPoints)
            }
            inputProps={{
              type: 'number',
              value: formik.values.storeRewardPoints,
              onBlur: formik.handleBlur('storeRewardPoints'),
              onChange: formik.handleChange('storeRewardPoints'),
            }}
          />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={3}>
          <FormLabel className={classes.labelHorizontal}>
            Preparation time min
          </FormLabel>
        </GridItem>
        <GridItem xs={12} sm={12} md={9}>
          <CustomInput
            formControlProps={{
              fullWidth: true,
            }}
            type="number"
            error={
              formik.touched.preparationTimeMin &&
              Boolean(formik.errors.preparationTimeMin)
            }
            inputProps={{
              type: 'number',
              value: formik.values.preparationTimeMin,
              onBlur: formik.handleBlur('preparationTimeMin'),
              onChange: formik.handleChange('preparationTimeMin'),
            }}
          />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={3}>
          <FormLabel className={classes.labelHorizontal}>
            Preparation time max
          </FormLabel>
        </GridItem>
        <GridItem xs={12} sm={12} md={9}>
          <CustomInput
            formControlProps={{
              fullWidth: true,
            }}
            type="number"
            error={
              formik.touched.preparationTimeMax &&
              Boolean(formik.errors.preparationTimeMax)
            }
            inputProps={{
              type: 'number',
              value: formik.values.preparationTimeMax,
              onBlur: formik.handleBlur('preparationTimeMax'),
              onChange: formik.handleChange('preparationTimeMax'),
            }}
          />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={3}>
          <FormLabel className={classes.labelHorizontal}>City</FormLabel>
        </GridItem>
        <GridItem xs={12} sm={12} md={9}>
          <SelectBoxComponent
            items={cities.map((city) => ({
              value: city._id,
              label: city.name,
            }))}
            onValueChange={formik.handleChange('cityId')}
            value={formik.values.cityId}
          />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
          <SwitchComponent
            onChange={(value) => formik.setFieldValue('approved', value)}
            checked={formik.values.approved}
            error={formik.touched.approved && Boolean(formik.errors.approved)}
            label="Approved"
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <SwitchComponent
            onChange={(value) => formik.setFieldValue('isPartner', value)}
            checked={formik.values.isPartner}
            error={formik.touched.isPartner && Boolean(formik.errors.isPartner)}
            label="Is partner"
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <SwitchComponent
            onChange={(value) => formik.setFieldValue('open', value)}
            checked={formik.values.open}
            error={formik.touched.open && Boolean(formik.errors.open)}
            label="Open"
          />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
          <SwitchComponent
            onChange={(value) => formik.setFieldValue('isBusiness', value)}
            checked={formik.values.isBusiness}
            error={
              formik.touched.isBusiness && Boolean(formik.errors.isBusiness)
            }
            label="Is business"
          />
        </GridItem>
      </GridContainer>
      <Box textAlign="center">
        <Button
          round
          type="submit"
          color="success"
          //disabled={!formik.dirty || !formik.isValid || isLoading}
          loading={isLoading}
          loaderColor="white"
          onClick={onCreate}
        >
          Create
        </Button>
      </Box>
    </div>
  );
}

CreateStoreComponent.propTypes = {
  onItemCreated: PropTypes.func.isRequired,
};
