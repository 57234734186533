import {Map} from 'immutable'
// const { Map, List } = require('immutable');
import { MULTI_UPLOAD_IMAGE_REQUEST,MULTI_UPLOAD_IMAGE_SUCCESS,MULTI_UPLOAD_IMAGE_FAIL,MULTI_UPLOAD_IMAGE_RESET,MULTI_DEFAULT_IMAGE } from './upload.constant';

const initialState = {
  multiIconUrl: {},
  error:"",
  loading: false,
  multiNewUpload:false
};

export default function uploadReducer(state = initialState, action) {
  
  const {oldObject} = action
  switch (action.type) {
      case MULTI_UPLOAD_IMAGE_REQUEST:
        return{
            loading:true
        }
    
    case MULTI_UPLOAD_IMAGE_SUCCESS:
      const {index} = action
      // const newObj = (action.oldObject['1-1']=action.multiIconUrl.url);
      return {
        ...state,
        loading:false,
        multiIconUrl:{...oldObject, [index]:action.multiIconUrl.url},
        multiNewUpload:true
      };
    case MULTI_UPLOAD_IMAGE_FAIL:
      return {
        ...state,
        loading:false,
        error: action?.error.errors[0].message,
      };
    case MULTI_UPLOAD_IMAGE_RESET:
      return {
        ...state,
        loading:false,
        error: "",
        multiIconUrl:{}
      };
    case MULTI_DEFAULT_IMAGE:
      return {
        ...state,
        loading:false,
        error: "",
        multiIconUrl:action.url,
        multiNewUpload:false
      };
    default:
      return state;
  }
}
