import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import { FormLabel, Box } from '@material-ui/core';
import { defaultImagesAction } from '../../../redux/uploadRedux/upload.action';
import SelectBoxComponent from '../../../components/SelectBox.component';
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import { updateStore } from '../services/serverApi.service';
import styles from '../../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle';
import ImageUpload from '../../../components/material-dashboard-pro-react/CustomUpload/ImageUpload';

const useStyles = makeStyles(styles);

const validationSchema = yup.object({
  imageAppUrl: yup.string().required(),
});

export default function EditImgStore({
  store,
  index,
  stores,
  setStores,
  close,
  onItemEdited,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const upload = useSelector((state) => state.uploadReducer);
  const { iconUrl, error, loading } = upload;
  const classes = useStyles();
  const formik = useFormik({
    initialValues: {
      imageAppUrl: '',
    },
    validationSchema,
  });
  const editStore = () => {
    store.imageAppUrl = formik.values.imageAppUrl;
    setIsLoading(true);
    updateStore(store._id, store)
      .then(({ data }) => {
        let newStores = [...stores];
        newStores[index] = data.store;
        setStores(newStores);
        // onItemEdited()
        close();
      })
      .catch((error) => {
        console.log(error.message);
      })
      .finally(() => setIsLoading(true));
  };
  const setValues = () => {
    formik.setValues({
      imageAppUrl: iconUrl,
    });
  };

  useEffect(() => {
    !iconUrl && dispatch(defaultImagesAction(store?.imageAppUrl));
    setValues();
  }, [iconUrl]);
  return (
    <div>
      <Box display="flex" justifyContent="center">
        {Boolean(store) && (
          <ImageUpload
            label="Select img"
            avatar
            folder="service"
            defaultImage={store.imageAppUrl}
          />
        )}
      </Box>
      <Box textAlign="center">
        <Button
          round
          type="submit"
          color="success"
          disabled={!formik.dirty || !formik.isValid || isLoading}
          loading={isLoading}
          loaderColor="white"
          onClick={editStore}
        >
          save
        </Button>
      </Box>
    </div>
  );
}
