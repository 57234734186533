import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getUserDataState } from '../../auth/redux/selectors';
import {
  GoogleMap,
  LoadScript,
  Polygon,
  Marker,
  InfoWindow,
  InfoBox,
  Polyline,
} from '@react-google-maps/api';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Grid,
  Switch,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import GridContainer from '../../../components/material-dashboard-pro-react/Grid/GridContainer';
import Card from '../../../components/material-dashboard-pro-react/Card/Card';
import CardHeader from '../../../components/material-dashboard-pro-react/Card/CardHeader';
import CardBody from '../../../components/material-dashboard-pro-react/Card/CardBody';

import { getDeliveryManInCity } from '../services/serverAPI.service';
import { getCities } from '../../delivery-info/services/serverApi.service';
import { googleMapsApiKey } from '../../../config/backendServer.config';
import { socketConnect } from '../../orders/services/serverApi.service';

import DeleiveryManIcon from '../../../assets/img/mapIcons/deliveryManPin.png';
import {
  getDeliveryRequestAction,
  changeDeliveryStatusAction,
  resetDeliveryMenAction,
} from '../redux/actions/deliveryMan.actions';
import { getOrdersDataState } from '../redux/selectors.map';

const google = window.google;

var deliveryManArray = [];
export default function MapViewDeliveryManSocketPage({ deliveryMan }) {
  const userData = useSelector(getUserDataState);
  const deliveryMenSelectors = useSelector(getOrdersDataState);
  // const {deliveryMen,error,loading} = deliveryMenSelectors
  // console.log(deliveryMen);
  const dispatch = useDispatch();

  const [deliveryMenSocket, setDeliveryMenSocket] = useState([]);
  const [coordinates, setCoordinates] = useState([]);
  // const [cities, setCities] = useState([])
  // const [selectedCity, setSelectedCity] = useState({})
  const [path, setPath] = useState({});
  const [infoBoxData, setInfoBoxData] = useState({});
  const [socket, setSocket] = useState(null);
  // lat: 33.56633768022972, lng: -7.658855561167002
  const [zoom, setZoom] = useState(13);

  // const getCitiesFromAPI = () => {
  //     getCities(0, 0, false)
  //         .then(({ data }) => setCities(data))
  //         .catch((error) => console.log(error))
  // }

  // const getDeliveryMenInCity = (cityId, cityObj) => {
  //   // getDeliveryManInCity(cityId)
  //   //   .then(({ data }) => {
  //   //     console.log('delivery', data)
  //   //     setDeliveryMen(data.deliveryMen)
  //   //     deliveryManArray=data.deliveryMen
  //   //   })
  //   //   .catch((e) => console.log(e));
  //   dispatch(getDeliveryRequestAction(cityId));
  //   setPath({
  //     lat: parseFloat(cityObj?.cityLocations[0][1]),
  //     lng: parseFloat(cityObj?.cityLocations[0][0]),
  //   });
  //   setZoom(13);
  // };
  // const setInfoForInfoBox = (id) => {
  //   const infoObj = {};
  //   var deliveryIndex = deliveryMen.findIndex((x) => x._id == id);
  //   if (deliveryIndex >= 0) {
  //     infoObj.name = deliveryMen[deliveryIndex]?.name;
  //     infoObj.location = deliveryMen[deliveryIndex]?.location;
  //     infoObj.phone = deliveryMen[deliveryIndex]?.phone;
  //     setInfoBoxData({ ...infoObj });
  //   }
  // };
  // const searchSpecificDelivery = (txt) => {
  //   deliveryMen &&
  //     deliveryMen.length &&
  //     deliveryMen.map((delivery) => {
  //       if (delivery.name.toLowerCase().startsWith(txt.toLowerCase())) {
  //         setPath({
  //           lat: parseFloat(delivery.location[0]),
  //           lng: parseFloat(delivery.location[1]),
  //         });
  //         setInfoForInfoBox(delivery?._id);
  //       }
  //     });
  // };
  // useEffect(() => {
  //   getCitiesFromAPI();
  // }, []);
  // useEffect(() => {
  //   if (!socket) {
  //     setSocket(socketConnect(userData.accessToken));
  //   }
  //   function subscribeToAdminRoom() {
  //     if (socket) {
  //       socket.emit('JOIN_ROOM', 'ADMIN_DISPATCHING');
  //     }
  //   }
  //   if (socket) {
  //     subscribeToAdminRoom();
  //   }
  // }, [socket]);
  // useEffect(() => {
  //   deliveryManArray = deliveryMen;
  // }, [deliveryMen]);
  // useEffect(() => {
  //   console.log('inside useEffect');
  //   console.log(deliveryMen);
  //   deliveryMen &&
  //     deliveryMen.length &&
  //     socket &&
  //     socket.on('DELIVERYMAN_LOCATION_CHANGED', function (data) {
  //       // deliveryMen&&deliveryMen.length&&
  //       // console.log(data);
  //       // console.log(deliveryMen);
  //       if (deliveryMen.find((x) => x._id == data.deliveryManId)) {
  //         // console.log('DELIVERYMAN_LOCATION_CHANGED', data);
  //         // const newItems = [...deliveryMen]
  //         // console.log('deliveryMen',deliveryMen);
  //         //   console.log('deliveryMen',deliveryMen);
  //         if (deliveryMen.find((x) => x._id === data.deliveryManId)) {
  //           console.log('socket id', socket.id);
  //           dispatch(changeDeliveryStatusAction(data));
  //         }
  //       }
  //     });
  // }, [socket, deliveryMen]);
  useEffect(() => {
    if (deliveryMan) {
      let d = deliveryMenSocket;
      if (d.length === 0) {
        setDeliveryMenSocket([deliveryMan]);
        generate_coord();
      } else {
        setDeliveryMenSocket([...deliveryMenSocket, deliveryMan]);
        generate_coord();
      }
    }
  }, [deliveryMan]);

  const generate_coord = async () => {
    let coord = await deliveryMenSocket.map((d) => {
      return {
        latitude: d.location[0],
        longitude: d.location[1],
        latitudeDelta: 0.01,
        longitudeDelta: 0.01,
      };
    });
    if (coord) setCoordinates([...coord]);
  };
  useEffect(() => {
    return () => {
      socket && socket.disconnect();
    };
  }, []);
  // console.log(deliveryMen);

  const tokyoRegion = {
    lat: 33.603635431809835,
    lng: -7.6397320254330925,
    latitudeDelta: 0.01,
    longitudeDelta: 0.01,
  };
  const chibaRegion = {
    lat: 33.6020053,
    lng: -7.6455669,
    latitudeDelta: 0.01,
    longitudeDelta: 0.01,
  };
  return (
    <div>
      {/*{'=====================>' + JSON.stringify(coordinates)}*/}
      {/*<GridContainer>*/}
      {/*    <Card>*/}
      {/*        <CardHeader>DeliveryMen</CardHeader>*/}
      {/*        <CardBody>*/}
      {/*            <Grid container spacing={0}>*/}
      {/*                <Grid item xs={3}>*/}
      {/*                    <FormControl style={{ minWidth: '180px' }}>*/}
      {/*                        <InputLabel>Cities</InputLabel>*/}
      {/*                        <Select*/}
      {/*                            value={selectedCity._id}*/}
      {/*                            onChange={($event) => {*/}
      {/*                                // console.log($event.target.value);*/}
      {/*                                const citySelected = cities.find(e => e._id == $event.target.value)*/}
      {/*                                //   console.log(citySelected);*/}
      {/*                                setSelectedCity(citySelected);*/}
      {/*                                //   getDeliveryManList($event.target.value)*/}
      {/*                                getDeliveryMenInCity($event.target.value, citySelected)*/}
      {/*                            }}*/}
      {/*                        >*/}
      {/*                            {cities.map((item, index) => (*/}
      {/*                                <MenuItem value={item._id}>{item.name}</MenuItem>*/}
      {/*                            ))}*/}
      {/*                        </Select>*/}

      {/*                    </FormControl>*/}
      {/*                </Grid>*/}
      {/*                <Grid item xs={3}>*/}
      {/*                <Autocomplete*/}
      {/*        id="combo-box-demo"*/}
      {/*        options={deliveryMen || []}*/}
      {/*        getOptionLabel={option => option.name}*/}
      {/*        // style={{ marginTop: '10%' }}*/}
      {/*        // disableUnderline={true}*/}
      {/*        // variant="outlined"*/}
      {/*        onChange={(e, newValue) => {*/}
      {/*          // console.log('chnage',e.target.value);*/}
      {/*          // console.log('chnage',newValue?.name);*/}
      {/*          newValue?.name !== '' && newValue?.name !== undefined && searchSpecificDelivery(newValue?.name)*/}
      {/*        }}*/}
      {/*        renderInput={(specificSelect) => <TextField {...specificSelect} label="find deliveryMan" variant="outlined"*/}
      {/*          // onChange={(e)=>setText(e.target.value)}*/}
      {/*          onChange={(e) => e.target.value !== '' && searchSpecificDelivery(e.target.value)}*/}

      {/*        />*/}
      {/*    }*/}
      {/*    />*/}
      {/*                </Grid>*/}
      {/*            </Grid>*/}
      {/*        </CardBody>*/}
      {/*    </Card>*/}
      {/*</GridContainer>*/}
      <LoadScript
        id="script-loader"
        googleMapsApiKey={googleMapsApiKey}
        language="en"
        region="en"
      >
        <GoogleMap
          mapContainerClassName="map"
          center={{
            lat: parseFloat(
              deliveryMan.location
                ? deliveryMan.location[0]
                : '33.56633768022972',
            ),
            lng: parseFloat(
              deliveryMan.location
                ? deliveryMan.location[1]
                : '-7.658855561167002',
            ),
          }}
          zoom={zoom}
          version="weekly"
          // on
          // onDblClick={ondblclick}
        >
          {/*{deliveryMen &&*/}
          {/*  deliveryMen.length &&*/}
          {/*  deliveryMen.map((delivery) => (*/}
          {deliveryMenSocket &&
            deliveryMenSocket?.length > 0 &&
            deliveryMenSocket.map((d, index) => (
              <Marker
                key={d?.deliveryManId}
                icon={{
                  path: 'M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z',
                  // url: DeleiveryManIcon,
                  fillColor:
                    index === deliveryMenSocket.length - 1
                      ? '#64be67'
                      : '#0032ff',
                  fillOpacity: 1,
                  scale: 0.05, //to reduce the size of icons
                }}
                position={{
                  lat: parseFloat(d.location ? d.location[0] : ''),
                  lng: parseFloat(d.location ? d.location[1] : ''),
                }}
                // onMouseOver={() => drawLine(delivery?._id)}
                // onMouseOut={() => setPolylineDrawer([])}
                //onClick={() => setInfoForInfoBox(deliveryMan?._id)}
                // onDblClick={() => setPolylineForSpecificOrder([])}
              />
            ))}
          {deliveryMenSocket && coordinates && deliveryMenSocket?.length > 0 && (
            <Polyline
              coordinates={coordinates} //specify our coordinates
              strokeColor={'#000'}
              strokeWidth={3}
              lineDashPattern={[1]}
            />
          )}
          ))}
          {/*{infoBoxData && Object.keys(infoBoxData).length !== 0 && (*/}
          {/*  <InfoBox*/}
          {/*    position={{*/}
          {/*      lat: parseFloat(infoBoxData.location[0]),*/}
          {/*      lng: parseFloat(infoBoxData.location[1]),*/}
          {/*    }}*/}
          {/*    // options={{ closeBoxURL: '', enableEventPropagation: true }}*/}
          {/*  >*/}
          {/*    <div*/}
          {/*      style={{*/}
          {/*        backgroundColor: 'white',*/}
          {/*        opacity: 1,*/}
          {/*        padding: 12,*/}
          {/*        borderRadius: '10px',*/}
          {/*      }}*/}
          {/*      onClick={() => setInfoBoxData({})}*/}
          {/*    >*/}
          {/*      <div style={{ fontSize: 16, fontColor: `#08233B` }}>*/}
          {/*        {infoBoxData.name}*/}
          {/*        <br />*/}
          {/*        {infoBoxData.phone}*/}
          {/*        <br />*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  </InfoBox>*/}
          {/*)}*/}
        </GoogleMap>
      </LoadScript>
    </div>
  );
}
