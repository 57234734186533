import {
    OPEN_MODEL, CLOSE_MODEL
} from '../constants/order.constants';

const initialState = {
    itemId: '',
    index: '',
    model: '',
};

export default function ordersReducer(state = initialState, action) {

    switch (action.type) {
        case OPEN_MODEL:
            return {
                ...state,
                itemId: action?.itemId,
                index: action?.index,
                model: action?.model,
            };

        case CLOSE_MODEL:
            return {
                ...state,
                itemId: '',
                index: '',
                model: '',
            };

        default:
            return state;
    }
}
