import React, { useEffect, useState } from 'react';
import { getPricings } from '../services/serverApi.service';
import DataTableContainerComponent from '../../../components/DataTableContainer.component';
import PolygonMapViewComponent from '../components/PolygonMapView.component';
import ViewItemComponent from '../components/ViewCities.component';
import EditCityComponent from '../components/EditCity.component';
import CreateDeliveryPrice from '../components/CreateDeliveryPrice.component';
import { TextField } from '@material-ui/core';
import GridContainer from '../../../components/material-dashboard-pro-react/Grid/GridContainer';

export default function DeliveryPricesPage({ props }) {
  const [resultsData, setResultsData] = useState({
    total: 0,
    items: [],
    tableItems: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState([]);
  const pages = [ 25,50,100];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(pages[page]);
  const [pricing, setPricing] = useState(null);

  const changeDistanceStoreUser = async (index, val) => {
    let newData = items;
    newData[index].distanceStoreUser = val;
    newData[index].commission = await testInterval(
      val,
      newData[index].priceIntervals,
      newData[index].maxDeliveryPrice,
    );
    console.log(val, newData[index].commission);
    setItems([...newData]);
    setResultsData({
      tableItems: newData.map((item, index) => prepareData(item, index)),
    });
  };
  const testInterval = async (
    distanceStoreUser,
    interval,
    max_delivery_price,
  ) => {
    let distance = distanceStoreUser;
    let delivery_price = 0;
    await interval.forEach((item) => {
      if (distance > 0) {
        if (distance > item.de - item.ds) {
          distance -= item.de - item.ds;
          delivery_price +=
            parseFloat(item.basePrice) +
            (item.de - item.ds) * parseFloat(item.distancePrice);
        } else {
          delivery_price +=
            parseFloat(item.basePrice) +
            distance * parseFloat(item.distancePrice);
          distance -= item.de - item.ds;
        }
      }
    });
    delivery_price = Math.min(delivery_price, max_delivery_price);
    return delivery_price;
  };

  const loadPricingList = () => {
    setIsLoading(true);
    getPricings()
      .then(async ({ data }) => {
        setItems(
          await data.map((item) => {
            item.commission = 0;
            item.distanceStoreUser = 0;
            return item;
          }),
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const prepareData = (item, index) => {
    return [
      item._id,
      item.name,
      item.maxDeliveryPrice,
      <TextField
        type={'number'}
        className={'w-50'}
        variant="outlined"
        id={'d_Store_User' + index}
        value={item.distanceStoreUser}
        onChange={($event) => {
          changeDistanceStoreUser(index, parseFloat($event.target.value));
        }}
        InputProps={{
          //onBlur: testInterval,
          inputProps: { min: 0 },
        }}
        label={'KM'}
      />,
      item.commission,
    ];
  };
  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // }
  // const handleChangeRowsPerPage = event => {
  //   setPage(0);
  //   setRowsPerPage(parseInt(event.target.value,10))
  // }
  // console.log(resultsData);
  useEffect(() => {
    loadPricingList();
  }, []);

  useEffect(() => {
    setResultsData({
      total: 0,
      items: items,
      tableItems: items.map((item, index) => prepareData(item, index)),
    });
  }, [items]);

  return (
    <>
      <DataTableContainerComponent
        resultsData={resultsData}
        tableHead={[
          'Name',
          'Max_DeliveryPrice',
          'distance StoreUser',
          'commission',
          'Actions',
        ]}
        title="Pricing"
        isLoading={isLoading}
        loadItemsList={loadPricingList}
        CreateItemFormComponent={CreateDeliveryPrice}
        modalWidth={'false'}
        actionButtons={{
          view: {
            color: 'info',
            type: 'view',
            component: ViewItemComponent,
          },
          edit: {
            color: 'success',
            type: 'edit',
            Modalwidth: 'false',
            advanceEdit: true,
            component: CreateDeliveryPrice,
          },
        }}
      />
    </>
  );
}
