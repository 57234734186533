import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getOrdersDataState } from '../redux/selectors';

// material componenet
import {
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Typography,
  Paper,
  Button,
  Tooltip,
  Box,
  Collapse,
} from '@mui/material';
import moment from 'moment';
import { abbreviationForAlarmsName } from '../mock';

export default function InfoAlarmComponent({
  cities,
  selectedCities,
  setSelectedAlarm,
  selectedAlarm,
  setSelectedInvalidAlarm,
  selectedInvalidAlarm,
  filterBy,
  setFilterBy,
}) {
  const [cityForDisplay, setCityForDisplay] = useState([]);
  const [totalAlarms, setTotalAlarms] = useState({});
  const [totalInvalidAlarms, setTotalInvalidAlarms] = useState({});
  const [totalOrdersByCity, setTotalOrdersByCity] = useState({});
  const [dataForCity, setDataForCity] = useState({});
  const [selectedCityAlarms, setSelectedCityAlarms] = useState([]);
  const { orders, invalidOrders, watcherCounter } =
    useSelector(getOrdersDataState);

  const selectedCell = {
    backgroundColor: '#3496ff',
    borderRadius: '50%/50%',
    padding: '5px',
    color: '#FFFFFF',
    cursor: 'pointer',
  };
  const NotSelectedCell = (status) => {
    return {
      backgroundColor: status == 'apparition' ? '#f44336' : '#ff7a04',
      borderRadius: '8px',
      padding: '5px',
      color: '#FFFFFF',
      cursor: 'pointer',
    };
  };
  const checkIfInvalidSelected = (cityId) => {
    if ((selectedInvalidAlarm || []).includes(cityId))
      return { ...selectedCell };
  };
  const checkIfSelected = (cityId, name, status) => {
    if (
      (selectedAlarm[cityId] || []).find(
        (x) => x.type == name && x.status == status,
      )
    )
      return { ...selectedCell };
    return { ...NotSelectedCell(status) };
  };
  const checkIfCitySelected = (cityId, status) => {
    if (
      (selectedCityAlarms || []).find(
        (x) => x.cityId == cityId && x.status == status,
      )
    )
      return { ...selectedCell };
    return { ...NotSelectedCell(status) };
  };
  const statusCell = {
    display: 'inline-block',
    width: '55px',
  };

  const reducer = (previousValue, currentValue) => previousValue + currentValue;
  function AlarmRow({ row, index }) {
    return (
      <React.Fragment>
        <TableRow>
          <TableCell component="th" scope="row" align="center">
            {(cities || [])
              .find((item) => item._id == row._id)
              ?.name?.substring(0, 4)}
          </TableCell>
          <TableCell align="center">
            {totalOrdersByCity[row._id] || '--'}
          </TableCell>
          <TableCell align="center">
            {totalAlarms[row._id]?.app?.RETARD ? (
              <span
                onClick={() => {
                  totalAlarms[row._id]?.app?.RETARD &&
                    selectAlarms(
                      row._id,
                      abbreviationForAlarmsName.Retard,
                      'apparition',
                    );
                }}
                style={checkIfSelected(
                  row._id,
                  abbreviationForAlarmsName.Retard,
                  'apparition',
                )}
              >
                {totalAlarms[row._id]?.app?.RETARD}
              </span>
            ) : (
              '--'
            )}
            {' | '}
            {totalAlarms[row._id]?.acqui?.RETARD ? (
              <span
                onClick={() => {
                  totalAlarms[row._id]?.acqui?.RETARD &&
                    selectAlarms(
                      row._id,
                      abbreviationForAlarmsName.Retard,
                      'Acquittement',
                    );
                }}
                style={checkIfSelected(
                  row._id,
                  abbreviationForAlarmsName.Retard,
                  'Acquittement',
                )}
              >
                {totalAlarms[row._id]?.acqui?.RETARD}
              </span>
            ) : (
              '--'
            )}
          </TableCell>
          <TableCell
            align="center"
            onClick={() => selectInvalidAlarms(row._id)}
          >
            {totalInvalidAlarms[row._id]?.INVALID_ORDER ? (
              <span style={checkIfInvalidSelected(row._id)}>
                {totalInvalidAlarms[row._id]?.INVALID_ORDER}
              </span>
            ) : (
              '--'
            )}
          </TableCell>

          <TableCell
            align="center"
            // onClick={() => {
            //   totalAlarms[row._id]?.app?.MANUEL &&
            //     selectAlarms(row._id, abbreviationForAlarmsName.MANUEL);
            // }}
          >
            <span style={{ ...statusCell }}>
              {totalAlarms[row._id]?.app?.MANUEL ? (
                <span
                  onClick={() => {
                    totalAlarms[row._id]?.app?.MANUEL &&
                      selectAlarms(
                        row._id,
                        abbreviationForAlarmsName.MANUEL,
                        'apparition',
                      );
                  }}
                  style={checkIfSelected(
                    row._id,
                    abbreviationForAlarmsName.MANUEL,
                    'apparition',
                  )}
                >
                  {totalAlarms[row._id]?.app?.MANUEL}
                </span>
              ) : (
                '--'
              )}
              {' | '}
              {totalAlarms[row._id]?.acqui?.MANUEL ? (
                <span
                  onClick={() => {
                    totalAlarms[row._id]?.acqui?.MANUEL &&
                      selectAlarms(
                        row._id,
                        abbreviationForAlarmsName.MANUEL,
                        'Acquittement',
                      );
                  }}
                  style={checkIfSelected(
                    row._id,
                    abbreviationForAlarmsName.MANUEL,
                    'Acquittement',
                  )}
                >
                  {totalAlarms[row._id]?.acqui?.MANUEL}
                </span>
              ) : (
                '--'
              )}
            </span>
          </TableCell>
          <TableCell
            align="center"
            // onClick={() => {
            //   totalAlarms[row._id]?.app?.WAITING_FOR_ACCEPT_STORE &&
            //     selectAlarms(row._id, abbreviationForAlarmsName.WAS);
            // }}
          >
            <span style={{ ...statusCell }}>
              {totalAlarms[row._id]?.app?.WAITING_FOR_ACCEPT_STORE ? (
                <span
                  onClick={() => {
                    totalAlarms[row._id]?.app?.WAITING_FOR_ACCEPT_STORE &&
                      selectAlarms(
                        row._id,
                        abbreviationForAlarmsName.WAS,
                        'apparition',
                      );
                  }}
                  style={checkIfSelected(
                    row._id,
                    abbreviationForAlarmsName.WAS,
                    'apparition',
                  )}
                >
                  {totalAlarms[row._id]?.app?.WAITING_FOR_ACCEPT_STORE}
                </span>
              ) : (
                '--'
              )}
              {' | '}
              {totalAlarms[row._id]?.acqui?.WAITING_FOR_ACCEPT_STORE ? (
                <span
                  onClick={() => {
                    totalAlarms[row._id]?.acqui?.WAITING_FOR_ACCEPT_STORE &&
                      selectAlarms(
                        row._id,
                        abbreviationForAlarmsName.WAS,
                        'Acquittement',
                      );
                  }}
                  style={checkIfSelected(
                    row._id,
                    abbreviationForAlarmsName.WAS,
                    'Acquittement',
                  )}
                >
                  {totalAlarms[row._id]?.acqui?.WAITING_FOR_ACCEPT_STORE}
                </span>
              ) : (
                '--'
              )}
            </span>
          </TableCell>
          <TableCell
            align="center"
            // onClick={() => {
            //   totalAlarms[row._id]?.app?.WAITING_FOR_ACCEPT &&
            //     selectAlarms(row._id, abbreviationForAlarmsName.WFA);
            // }}
          >
            <span style={{ ...statusCell }}>
              {totalAlarms[row._id]?.app?.WAITING_FOR_ACCEPT ? (
                <span
                  onClick={() => {
                    totalAlarms[row._id]?.app?.WAITING_FOR_ACCEPT &&
                      selectAlarms(
                        row._id,
                        abbreviationForAlarmsName.WFA,
                        'apparition',
                      );
                  }}
                  style={checkIfSelected(
                    row._id,
                    abbreviationForAlarmsName.WFA,
                    'apparition',
                  )}
                >
                  {totalAlarms[row._id]?.app?.WAITING_FOR_ACCEPT}
                </span>
              ) : (
                '--'
              )}
              {' | '}
              {totalAlarms[row._id]?.acqui?.WAITING_FOR_ACCEPT ? (
                <span
                  onClick={() => {
                    totalAlarms[row._id]?.acqui?.WAITING_FOR_ACCEPT &&
                      selectAlarms(
                        row._id,
                        abbreviationForAlarmsName.WFA,
                        'Acquittement',
                      );
                  }}
                  style={checkIfSelected(
                    row._id,
                    abbreviationForAlarmsName.WFA,
                    'Acquittement',
                  )}
                >
                  {totalAlarms[row._id]?.acqui?.WAITING_FOR_ACCEPT}
                </span>
              ) : (
                '--'
              )}
            </span>
          </TableCell>
          <TableCell align="center">
            <span style={{ ...statusCell }}>
              {totalAlarms[row._id]?.app?.ACCEPTED ? (
                <span
                  onClick={() => {
                    totalAlarms[row._id]?.app?.ACCEPTED &&
                      selectAlarms(
                        row._id,
                        abbreviationForAlarmsName.AC,
                        'apparition',
                      );
                  }}
                  style={checkIfSelected(
                    row._id,
                    abbreviationForAlarmsName.AC,
                    'apparition',
                  )}
                >
                  {totalAlarms[row._id]?.app?.ACCEPTED}
                </span>
              ) : (
                '--'
              )}
              {' | '}
              {totalAlarms[row._id]?.acqui?.ACCEPTED ? (
                <span
                  onClick={() => {
                    totalAlarms[row._id]?.acqui?.ACCEPTED &&
                      selectAlarms(
                        row._id,
                        abbreviationForAlarmsName.AC,
                        'Acquittement',
                      );
                  }}
                  style={checkIfSelected(
                    row._id,
                    abbreviationForAlarmsName.AC,
                    'Acquittement',
                  )}
                >
                  {totalAlarms[row._id]?.acqui?.ACCEPTED}
                </span>
              ) : (
                '--'
              )}
            </span>
          </TableCell>
          <TableCell align="center">
            <span style={{ ...statusCell }}>
              {totalAlarms[row._id]?.app?.COMING_FOR_DELIVERY ? (
                <span
                  onClick={() => {
                    totalAlarms[row._id]?.app?.COMING_FOR_DELIVERY &&
                      selectAlarms(
                        row._id,
                        abbreviationForAlarmsName.CFD,
                        'apparition',
                      );
                  }}
                  style={checkIfSelected(
                    row._id,
                    abbreviationForAlarmsName.CFD,
                    'apparition',
                  )}
                >
                  {totalAlarms[row._id]?.app?.COMING_FOR_DELIVERY}
                </span>
              ) : (
                '--'
              )}
              {' | '}
              {totalAlarms[row._id]?.acqui?.COMING_FOR_DELIVERY ? (
                <span
                  onClick={() => {
                    totalAlarms[row._id]?.app?.COMING_FOR_DELIVERY &&
                      selectAlarms(
                        row._id,
                        abbreviationForAlarmsName.CFD,
                        'Acquittement',
                      );
                  }}
                  style={checkIfSelected(
                    row._id,
                    abbreviationForAlarmsName.CFD,
                    'Acquittement',
                  )}
                >
                  {totalAlarms[row._id]?.acqui?.COMING_FOR_DELIVERY}
                </span>
              ) : (
                '--'
              )}
            </span>
          </TableCell>
          <TableCell align="center">
            <span style={{ ...statusCell }}>
              {totalAlarms[row._id]?.app?.ARRIVED_TO_PICKUP ? (
                <span
                  onClick={() => {
                    totalAlarms[row._id]?.app?.ARRIVED_TO_PICKUP &&
                      selectAlarms(
                        row._id,
                        abbreviationForAlarmsName.ATP,
                        'apparition',
                      );
                  }}
                  style={checkIfSelected(
                    row._id,
                    abbreviationForAlarmsName.ATP,
                    'apparition',
                  )}
                >
                  {totalAlarms[row._id]?.app?.ARRIVED_TO_PICKUP}
                </span>
              ) : (
                '--'
              )}
              {' | '}
              {totalAlarms[row._id]?.acqui?.ARRIVED_TO_PICKUP ? (
                <span
                  onClick={() => {
                    totalAlarms[row._id]?.acqui?.ARRIVED_TO_PICKUP &&
                      selectAlarms(
                        row._id,
                        abbreviationForAlarmsName.ATP,
                        'Acquittement',
                      );
                  }}
                  style={checkIfSelected(
                    row._id,
                    abbreviationForAlarmsName.ATP,
                    'Acquittement',
                  )}
                >
                  {totalAlarms[row._id]?.acqui?.ARRIVED_TO_PICKUP}
                </span>
              ) : (
                '--'
              )}
            </span>
          </TableCell>
          <TableCell align="center">
            <span style={{ ...statusCell }}>
              {totalAlarms[row._id]?.app?.UPLOAD_INVOICE ? (
                <span
                  onClick={() => {
                    totalAlarms[row._id]?.app?.UPLOAD_INVOICE &&
                      selectAlarms(
                        row._id,
                        abbreviationForAlarmsName.UI,
                        'apparition',
                      );
                  }}
                  style={checkIfSelected(
                    row._id,
                    abbreviationForAlarmsName.UI,
                    'apparition',
                  )}
                >
                  {totalAlarms[row._id]?.app?.UPLOAD_INVOICE}
                </span>
              ) : (
                '--'
              )}
              {' | '}
              {totalAlarms[row._id]?.acqui?.UPLOAD_INVOICE ? (
                <span
                  onClick={() => {
                    totalAlarms[row._id]?.acqui?.UPLOAD_INVOICE &&
                      selectAlarms(
                        row._id,
                        abbreviationForAlarmsName.UI,
                        'Acquittement',
                      );
                  }}
                  style={checkIfSelected(
                    row._id,
                    abbreviationForAlarmsName.UI,
                    'Acquittement',
                  )}
                >
                  {totalAlarms[row._id]?.acqui?.UPLOAD_INVOICE}
                </span>
              ) : (
                '--'
              )}
            </span>
          </TableCell>
          <TableCell align="center">
            <span style={{ ...statusCell }}>
              {totalAlarms[row._id]?.app?.STARTED_DELIVERY ? (
                <span
                  onClick={() => {
                    totalAlarms[row._id]?.acqui?.STARTED_DELIVERY &&
                      selectAlarms(
                        row._id,
                        abbreviationForAlarmsName.SD,
                        'apparition',
                      );
                  }}
                  style={checkIfSelected(
                    row._id,
                    abbreviationForAlarmsName.SD,
                    'apparition',
                  )}
                >
                  {totalAlarms[row._id]?.app?.STARTED_DELIVERY}
                </span>
              ) : (
                '--'
              )}
              {' | '}
              {totalAlarms[row._id]?.acqui?.STARTED_DELIVERY ? (
                <span
                  onClick={() => {
                    totalAlarms[row._id]?.acqui?.STARTED_DELIVERY &&
                      selectAlarms(
                        row._id,
                        abbreviationForAlarmsName.SD,
                        'Acquittement',
                      );
                  }}
                  style={checkIfSelected(
                    row._id,
                    abbreviationForAlarmsName.SD,
                    'Acquittement',
                  )}
                >
                  {totalAlarms[row._id]?.acqui?.STARTED_DELIVERY}
                </span>
              ) : (
                '--'
              )}
            </span>
          </TableCell>
          <TableCell align="center">
            <span style={{ ...statusCell }}>
              {totalAlarms[row._id]?.app?.ARRIVED_TO_DESTINATION_POSITION ? (
                <span
                  onClick={() => {
                    totalAlarms[row._id]?.app
                      ?.ARRIVED_TO_DESTINATION_POSITION &&
                      selectAlarms(
                        row._id,
                        abbreviationForAlarmsName.ATDP,
                        'apparition',
                      );
                  }}
                  style={checkIfSelected(
                    row._id,
                    abbreviationForAlarmsName.ATDP,
                    'apparition',
                  )}
                >
                  {totalAlarms[row._id]?.app?.ARRIVED_TO_DESTINATION_POSITION}
                </span>
              ) : (
                '--'
              )}
              {' | '}
              {totalAlarms[row._id]?.acqui?.ARRIVED_TO_DESTINATION_POSITION ? (
                <span
                  onClick={() => {
                    totalAlarms[row._id]?.acqui
                      ?.ARRIVED_TO_DESTINATION_POSITION &&
                      selectAlarms(
                        row._id,
                        abbreviationForAlarmsName.ATDP,
                        'Acquittement',
                      );
                  }}
                  style={checkIfSelected(
                    row._id,
                    abbreviationForAlarmsName.ATDP,
                    'Acquittement',
                  )}
                >
                  {totalAlarms[row._id]?.acqui?.ARRIVED_TO_DESTINATION_POSITION}
                </span>
              ) : (
                '--'
              )}
            </span>
          </TableCell>
          <TableCell align="center">
            <span style={{ ...statusCell }}>
              {totalAlarms[row._id]?.app?.ARRIVED_TO_DESTINATION_TIME ? (
                <span
                  onClick={() => {
                    totalAlarms[row._id]?.app?.ARRIVED_TO_DESTINATION_TIME &&
                      selectAlarms(
                        row._id,
                        abbreviationForAlarmsName.ATDT,
                        'apparition',
                      );
                  }}
                  style={checkIfSelected(
                    row._id,
                    abbreviationForAlarmsName.ATDT,
                    'apparition',
                  )}
                >
                  {totalAlarms[row._id]?.app?.ARRIVED_TO_DESTINATION_TIME}
                </span>
              ) : (
                '--'
              )}
              {' | '}
              {totalAlarms[row._id]?.acqui?.ARRIVED_TO_DESTINATION_TIME ? (
                <span
                  onClick={() => {
                    totalAlarms[row._id]?.acqui?.ARRIVED_TO_DESTINATION_TIME &&
                      selectAlarms(
                        row._id,
                        abbreviationForAlarmsName.ATDT,
                        'Acquittement',
                      );
                  }}
                  style={checkIfSelected(
                    row._id,
                    abbreviationForAlarmsName.ATDT,
                    'Acquittement',
                  )}
                >
                  {totalAlarms[row._id]?.acqui?.ARRIVED_TO_DESTINATION_TIME}
                </span>
              ) : (
                '--'
              )}
            </span>
          </TableCell>
          <TableCell align="center">
            <span style={{ ...statusCell }}>
              {totalAlarms[row._id]?.app?.START_PREPARATION ? (
                <span
                  onClick={() => {
                    totalAlarms[row._id]?.app?.START_PREPARATION &&
                      selectAlarms(
                        row._id,
                        abbreviationForAlarmsName.SP,
                        'apparition',
                      );
                  }}
                  style={checkIfSelected(
                    row._id,
                    abbreviationForAlarmsName.SP,
                    'apparition',
                  )}
                >
                  {totalAlarms[row._id]?.app?.START_PREPARATION}
                </span>
              ) : (
                '--'
              )}
              {' | '}
              {totalAlarms[row._id]?.acqui?.START_PREPARATION ? (
                <span
                  style={checkIfSelected(
                    row._id,
                    abbreviationForAlarmsName.SP,
                    'Acquittement',
                  )}
                  onClick={() => {
                    totalAlarms[row._id]?.acqui?.START_PREPARATION &&
                      selectAlarms(
                        row._id,
                        abbreviationForAlarmsName.SP,
                        'Acquittement',
                      );
                  }}
                >
                  {totalAlarms[row._id]?.acqui?.START_PREPARATION}
                </span>
              ) : (
                '--'
              )}
            </span>
          </TableCell>
          <TableCell align="center">
            <span style={{ ...statusCell }}>
              {totalAlarms[row._id]?.app &&
              Object.keys(totalAlarms[row._id]?.app).length > 0 &&
              Object.values(totalAlarms[row._id]?.app).length > 0 ? (
                <span
                  style={checkIfCitySelected(row._id, 'apparition')}
                  onClick={() => {
                    Object.values(totalAlarms[row._id]?.app)?.reduce(
                      reducer,
                      0,
                    ) && selectAlarms(row._id, '', 'apparition');
                  }}
                >
                  {Object.values(totalAlarms[row._id]?.app)?.reduce(reducer, 0)}
                </span>
              ) : (
                '--'
              )}
              {' | '}
              {totalAlarms[row._id]?.acqui &&
              Object.keys(totalAlarms[row._id]?.acqui).length > 0 &&
              Object.values(totalAlarms[row._id]?.acqui)?.length > 0 ? (
                <span
                  style={checkIfCitySelected(row._id, 'Acquittement')}
                  onClick={() => {
                    Object.values(totalAlarms[row._id]?.acqui)?.reduce(
                      reducer,
                      0,
                    ) && selectAlarms(row._id, '', 'Acquittement');
                  }}
                >
                  {Object.values(totalAlarms[row._id]?.acqui)?.reduce(
                    reducer,
                    0,
                  )}
                </span>
              ) : (
                '--'
              )}
            </span>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  const selectInvalidAlarms = (cityId) => {
    filterBy != 'alarms' && setFilterBy('alarms');
    const tempSelectedAlarm = [...selectedInvalidAlarm];
    if ((tempSelectedAlarm || []).includes(cityId)) {
      const index = (tempSelectedAlarm || []).indexOf(cityId);
      index > -1 && tempSelectedAlarm.splice(index, 1);
    } else {
      tempSelectedAlarm.push(cityId);
    }
    setSelectedInvalidAlarm(tempSelectedAlarm);
  };
  const selectAlarms = (cityId, type, status) => {
    filterBy != 'alarms' && setFilterBy('alarms');
    const tempSelectedAlarm = { ...selectedAlarm };
    const tempSelectedCityAlarms = [...selectedCityAlarms];
    const selectedCityIndex = (tempSelectedCityAlarms || []).findIndex(
      (x) => x.cityId == cityId && status == x.status,
    );

    if (type) {
      selectedCityIndex != -1 &&
        tempSelectedCityAlarms.splice(selectedCityIndex, 1);

      if (tempSelectedAlarm[cityId] && tempSelectedAlarm[cityId].length > 0) {
        if (
          tempSelectedAlarm[cityId].findIndex(
            (w) => w?.type == type && w?.status != status,
          ) == -1
        ) {
          const index = (tempSelectedAlarm[cityId] || []).findIndex(
            (w) => w?.type == type && w?.status == status,
          );
          index >= 0
            ? tempSelectedAlarm[cityId].splice(index, 1)
            : tempSelectedAlarm[cityId].push({ type: type, status: status });

          tempSelectedAlarm[cityId].length == 0 &&
            delete tempSelectedAlarm[cityId];
        }
      } else tempSelectedAlarm[cityId] = [{ type: type, status: status }];
    } else {
      // const selectedCityIndex = (tempSelectedCityAlarms || []).indexOf(cityId);

      if (selectedCityIndex != -1) {
        tempSelectedCityAlarms.splice(selectedCityIndex, 1);
        delete tempSelectedAlarm[cityId];
      } else {
        const selectedCityIdIndex = (tempSelectedCityAlarms || []).findIndex(
          (x) =>
            x.cityId == cityId &&
            x.status ==
              (status == 'apparition' ? 'Acquittement' : 'apparition'),
        );
        selectedCityIdIndex != -1 &&
          tempSelectedCityAlarms.splice(selectedCityIndex, 1);

        tempSelectedCityAlarms.push({ cityId: cityId, status: status });

        switch (status) {
          case 'apparition':
            if (selectedCityIndex) {
            }
            const appArray =
              totalAlarms[cityId]?.app &&
              (Object.keys(totalAlarms[cityId]?.app) || []).map((x) => {
                return { type: x, status: status };
              });
            tempSelectedAlarm[cityId] = appArray;
            break;
          case 'Acquittement':
            const acquiArray =
              totalAlarms[cityId]?.acqui &&
              (Object.keys(totalAlarms[cityId]?.acqui) || []).map((x) => {
                return { type: x, status: status };
              });
            tempSelectedAlarm[cityId] = acquiArray;
            break;

          default:
            break;
        }
      }
    }
    setSelectedCityAlarms(tempSelectedCityAlarms);
    setSelectedAlarm(tempSelectedAlarm);
  };
  const prepareDataForEveryCity = () => {
    const totalObj = {};
    !selectedCities.includes('all')
      ? selectedCities.every((x) => (totalObj[x] = {}))
      : cities.every((x) => (totalObj[x._id] = {}));
    setDataForCity(totalObj);
  };
  const alarmsTotal = () => {
    const totalObj = {};
    !selectedCities.includes('all')
      ? selectedCities.every((x) => (totalObj[x] = { app: {}, acqui: {} }))
      : cities.every((x) => (totalObj[x._id] = { app: {}, acqui: {} }));
    Object.keys(totalObj).map((item) => {
      (orders || [])
        .filter((x) => x.cityId == item && x?.alarms && x?.alarms.length > 0)
        .map((order) =>
          order.alarms
            .filter((x) => x?.visible)
            .forEach((alarm) => {
              if (alarm.status == 'apparition') {
                totalObj[order.cityId]['app'][alarm.name] =
                  (totalObj[order.cityId]['app'][alarm.name] || 0) + 1;
              } else if (alarm.status == 'Acquittement') {
                totalObj[order.cityId]['acqui'][alarm.name] =
                  (totalObj[order.cityId]['acqui'][alarm.name] || 0) + 1;
              }
            }),
        );
    });
    setTotalAlarms(totalObj);
  };
  const alarmsTotalForInvalid = () => {
    const totalObj = {};
    !selectedCities.includes('all')
      ? selectedCities.every((x) => (totalObj[x] = { app: {}, acqui: {} }))
      : cities.every((x) => (totalObj[x._id] = { app: {}, acqui: {} }));
    Object.keys(totalObj).map((item) => {
      (invalidOrders || [])
        .filter((x) => x.cityId == item && x?.alarms && x?.alarms.length > 0)
        .map((order) =>
          order.alarms
            .filter((x) => x?.visible)
            .forEach((alarm) => {
              if (alarm?.name == 'INVALID_ORDER') {
                totalObj[order.cityId][alarm.name] =
                  (totalObj[order.cityId][alarm.name] || 0) + 1;
              }
            }),
        );
    });
    setTotalInvalidAlarms(totalObj);
  };
  const totalOrderInCity = () => {
    const TotalOrdersByCityObj = {};
    const allOrders = orders.concat(invalidOrders);
    (allOrders || []).map((order) => {
      TotalOrdersByCityObj[order.city._id] =
        (TotalOrdersByCityObj[order.city._id] || 0) + 1;
    });
    setTotalOrdersByCity(TotalOrdersByCityObj);
  };
  useEffect(() => {
    selectedCities && cities && prepareDataForEveryCity();
  }, [selectedCities, cities]);

  useEffect(() => {
    if (
      dataForCity &&
      Object.keys(dataForCity).length > 0 &&
      orders &&
      orders.length > 0
    ) {
      alarmsTotal();
      cityInTable();
    }
  }, [watcherCounter, dataForCity]);
  useEffect(() => {
    dataForCity &&
      Object.keys(dataForCity).length > 0 &&
      invalidOrders &&
      invalidOrders.length > 0 &&
      alarmsTotalForInvalid();
  }, [watcherCounter, dataForCity]);
  useEffect(() => {
    if (filterBy != 'alarms') {
      setSelectedAlarm({});
      setSelectedCityAlarms([]);
    }
  }, [filterBy]);
  useEffect(() => {
    invalidOrders && orders && totalOrderInCity();
  }, [(invalidOrders || []).length, (orders || []).length]);

  const cityInTable = () => {
    if (selectedCities.includes('all')) {
      const tempCityForTable = [];
      cities.map((city) => {
        const findIndex = (orders.concat(invalidOrders) || [])
          .filter((d) => d)
          .findIndex((x) => {
            return x?.cityId == city?._id;
          });
        findIndex != -1 && tempCityForTable.push(city._id);
      });
      setCityForDisplay(tempCityForTable);
    } else {
      setCityForDisplay(selectedCities);
    }
  };
  return (
    <React.Fragment>
      <TableContainer component={Paper}>
        <Typography className="mt-2 ml-4">Alarms info</Typography>
        <Table
          aria-label="collapsible table"
          style={{ width: 'auto', tableLayout: 'auto' }}
        >
          <TableHead scope="row" colSpan="4">
            <TableRow>
              <TableCell align="center">Ville</TableCell>
              <TableCell align="center">Orders</TableCell>
              <TableCell align="center" width={100}>
                Retard
              </TableCell>
              <TableCell align="center" width={80}>
                Invali
              </TableCell>
              <TableCell align="center" width={100}>
                Manuel
              </TableCell>
              <TableCell align="center" width={100}>
                WAS
              </TableCell>
              <TableCell align="center" width={100}>
                <Tooltip
                  title={abbreviationForAlarmsName.WFA}
                  placement="top-start"
                >
                  <span> WAP</span>
                </Tooltip>
              </TableCell>
              <TableCell align="center" width={100}>
                <Tooltip
                  title={abbreviationForAlarmsName.AC}
                  placement="top-start"
                >
                  <span> AC </span>
                </Tooltip>
              </TableCell>
              <TableCell align="center" width={100}>
                <Tooltip
                  title={abbreviationForAlarmsName.CFD}
                  placement="top-start"
                >
                  <span>CFD</span>
                </Tooltip>
              </TableCell>
              <TableCell align="center" width={100}>
                <Tooltip
                  title={abbreviationForAlarmsName.ATP}
                  placement="top-start"
                >
                  <span>ATP</span>
                </Tooltip>
              </TableCell>
              <TableCell align="center" width={100}>
                <Tooltip
                  title={abbreviationForAlarmsName.UI}
                  placement="top-start"
                >
                  <span>UI</span>
                </Tooltip>
              </TableCell>
              <TableCell align="center" width={100}>
                <Tooltip
                  title={abbreviationForAlarmsName.SD}
                  placement="top-start"
                >
                  <span>SD</span>
                </Tooltip>
              </TableCell>
              <TableCell align="center" width={100}>
                <Tooltip
                  title={abbreviationForAlarmsName.ATDP}
                  placement="top-start"
                >
                  <span>ATDP</span>
                </Tooltip>
              </TableCell>
              <TableCell align="center" width={110}>
                <Tooltip
                  title={abbreviationForAlarmsName.ATDT}
                  placement="top-start"
                >
                  <span>ATDT</span>
                </Tooltip>
              </TableCell>
              <TableCell align="center" width={120}>
                Prepa
              </TableCell>
              <TableCell align="center" width={120}>
                total
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cities.map((item, index) => {
              return (
                cityForDisplay.includes(item._id) && (
                  <AlarmRow key={index} row={item} index={index} />
                )
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
}
