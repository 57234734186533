import React, { useEffect, useState } from 'react';
// import { useSelector ,useDispatch} from 'react-redux';
// import { getUserDataState } from '../../auth/redux/selectors'
import {
  GoogleMap,
  LoadScript,
  Polygon,
  Marker,
  Polyline,
  InfoWindow,
  InfoBox,
} from '@react-google-maps/api';
// import { FormControl, InputLabel, Select, MenuItem, TextField, Grid, Switch } from '@material-ui/core'
// import { Autocomplete } from '@material-ui/lab'
import { Grid, Box } from '@material-ui/core';
import * as yup from 'yup';
import { useFormik } from 'formik';
import CustomInput from '../../../components/material-dashboard-pro-react/CustomInput/CustomInput';
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';

// import GridContainer from '../../../components/material-dashboard-pro-react/Grid/GridContainer';
// import Card from '../../../components/material-dashboard-pro-react/Card/Card';
// import CardHeader from '../../../components/material-dashboard-pro-react/Card/CardHeader';
// import CardBody from '../../../components/material-dashboard-pro-react/Card/CardBody';

// import { getDeliveryManInCity } from '../services/serverAPI.service'
// import { getCities } from '../../delivery-info/services/serverApi.service';
import { googleMapsApiKey } from '../../../config/backendServer.config';
import { editCustomerAddresses } from '../service/serverAPI.service';

const validationSchema = yup.object({
  address: yup.string().required(),
  details: yup.string().required(),
});
export default function EditAddressMapComponent({
  index,
  address,
  setAddresse,
  close,
}) {
  const [path, setPath] = useState({});
  const [zoom, setZoom] = useState(13);
  const [isLoading, setIsLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      address: '',
      details: '',
    },
    validationSchema,
  });
  const setValue = (data) => {
    if (data) {
      formik.setValues({
        address: data.address,
        details: data.details,
      });
    }
  };
  const setInitalMarker = () => {
    // console.log(address[index]);
    setPath({
      lat: parseFloat(address[index].location[0]),
      lng: parseFloat(address[index].location[1]),
    });
  };
  const changeMarkerPosition = (coord) => {
    const { latLng } = coord;
    const lat = latLng.lat();
    const lng = latLng.lng();
    console.log(lat);
    address[index].location = [lat, lng];
    setAddresse(address);
  };
  const saveAddress = () => {
    address[index].address = formik.values.address;
    address[index].details = formik.values.details;
    setAddresse(address);
    setIsLoading(true);
    editCustomerAddresses(
      address[index].customerId,
      address[index]._id,
      address[index],
    )
      .then(({ data }) => {
        console.log(data);
        close();
      })
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
  };
  useEffect(() => {
    address && setInitalMarker();
    address && setValue(address[index]);
  }, [address]);
  // console.log('path',path);
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <CustomInput
            labelText="address"
            formControlProps={{
              fullWidth: true,
            }}
            type="text"
            // defaultValue= {address[index].address}
            // error={formik.touched.name && Boolean(formik.errors.name)}
            inputProps={{
              value: formik.values.address,
              // onBlur: formik.handleBlur('name'),
              onChange: formik.handleChange('address'),
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <CustomInput
            labelText="address"
            formControlProps={{
              fullWidth: true,
            }}
            type="text"
            // error={formik.touched.name && Boolean(formik.errors.name)}
            inputProps={{
              value: formik.values.details,
              // onBlur: formik.handleBlur('name'),
              onChange: formik.handleChange('details'),
            }}
          />
        </Grid>
      </Grid>
      <LoadScript
        id="script-loader"
        googleMapsApiKey={googleMapsApiKey}
        language="en"
        region="en"
      >
        <GoogleMap
          mapContainerClassName="map"
          center={
            // selectedCity ? { lat: selectedCity.cityLocations[0][1], lng: selectedCity.cityLocations[0][0] } : { lat: 33.589886, lng: -7.603869 }
            Object.keys(path).length
              ? path
              : { lat: 33.56633768022972, lng: -7.658855561167002 }
            // : { lat: 33.589886, lng: -7.603869 }
          }
          zoom={zoom}
          version="weekly"
          on
          // onDblClick={ondblclick}
        >
          {path && (
            <Marker
              key={address[index]?._id}
              // icon={{
              //     url: DeleiveryManIcon,
              //     // url: orders.find(e => e.deliveryMan?._id == delivery._id) ? DeliveryManIcon : DeliveryManWithoutOrderIcon,
              //     // anchor: new google.maps.Point(17, 46),
              //     scaledSize: new google.maps.Size(50, 50)

              // }}
              position={path}
              draggable={true}
              // onDragend={(e) => console.log(e)}
              onDragEnd={(e) => changeMarkerPosition(e)}

              // position={{ lat: parseFloat(delivery.location ? delivery.location[0] : ''), lng: parseFloat(delivery.location ? delivery.location[1] : "") }}
              // onMouseOver={() => drawLine(delivery?._id)}
              // onMouseOut={() => setPolylineDrawer([])}
              // onClick={() => setInfoForInfoBox(delivery?._id)}
              // onDblClick={() => setPolylineForSpecificOrder([])}
            />
          )}
        </GoogleMap>
      </LoadScript>
      <Box textAlign="center">
        <Button
          round
          type="submit"
          color="success"
          disabled={!formik.dirty || !formik.isValid || isLoading}
          loading={isLoading}
          loaderColor="white"
          onClick={saveAddress}
        >
          save
        </Button>
      </Box>
    </div>
  );
}
