/* global google */
import React, { useEffect, useState } from 'react';
import {
  GoogleMap,
  LoadScript,
  Polyline,
  InfoBox,
  InfoWindow,
} from '@react-google-maps/api';
import { googleMapsApiKey } from '../../../config/backendServer.config';
import { arrayForDeliveryManCommingToStore } from '../../dashboard/mocks/index';
import { Typography, Slider, Box, Grid } from '@mui/material';
import CustomMarker from '../../../components/Markers.component';
import { iconsList } from '../../../config/status.config';
import { getDeliveryManInCity } from '../../dashboard/services/serverAPI.service';
import {
  getDistanceFromLatLonInKm,
  calculatTime,
  calcDurationbetweenTwoTimes,
  calcDuration,
} from '../../../helpers/index';
import {
  orderServiceType,
  deliveryManStatusBeforeArrivedToStore,
  deliveryManStatusAfterArrivedToStore,
} from '../mock';
import ButtonComponent from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import { acceptOrRejectOrder } from '../service/serverAPI.service';

export default function DisplayOrderInMap({ order, orders, close, admin }) {
  const [isLoading, setIsLoading] = useState(false);
  const [path, setPath] = useState({});
  const [customerPath, setCustomerPath] = useState({});
  const [deliveryPath, setDeliveryPath] = useState({});
  const [zoom, setZoom] = useState(13);
  const [polylineForSpecificOrder, setPolylineForSpecificOrder] = useState([]);
  const [deliveryManGoToSameStore, setDeliveryManGoToSameStore] = useState([]);
  const [deliveryInCity, setDeliverInCity] = useState([]);
  const [value, setValue] = useState(1);
  const [infoBoxData, setInfoBoxData] = useState({});
  const [showInfo, setShowInfo] = useState(true);
  const [clientsLocationWhenHover, setClientsLocationWhenHover] = useState([]);
  const [storesLocationWhenHover, setStoresLocationWhenHover] = useState([]);
  const [polyLineForOneDelivery, setPolyLineForOneDelivery] = useState([]);

  const getCostomerLocation = () => {
    switch (order.payment.serviceType) {
      case orderServiceType.Stores:
        setCustomerPath({
          lat: parseFloat(order.payment?.destinationAddress?.location[0]),
          lng: parseFloat(order.payment?.destinationAddress?.location[1]),
        });
        break;
      case orderServiceType.Choubik:
      case orderServiceType.Drive:
      case orderServiceType.Express:
        setCustomerPath({
          lat: parseFloat(order?.payment?.pickupAddress?.location[0]),
          lng: parseFloat(order?.payment?.pickupAddress?.location[1]),
        });
      default:
        break;
    }
  };
  const drawLine = () => {
    const polyLineArray = [];
    const pathCoordinates = [];
    const polyLineObj = {};
    const findDelivery = (deliveryInCity || []).find(
      (deliveryMan) => deliveryMan?._id == order?.deliveryMan?._id,
    );

    polyLineObj.color = 'black';
    const status = order.driverStatusTimes.filter(
      (item) =>
        item?.deliveryMan?.location && item?.deliveryMan?.location.length,
    );
    // order?.deliveryMan?.location && order.deliveryMan.location.length && pathCoordinates.push({ lat: parseFloat((order.deliveryMan.location[0])), lng: parseFloat(order.deliveryMan.location[1]) })
    findDelivery &&
      Object.keys(findDelivery).length &&
      pathCoordinates.push({
        lat: parseFloat(findDelivery.location[0]),
        lng: parseFloat(findDelivery.location[1]),
      });
    // status&&status.length && status.map(item=>deliveryManStatusBeforeArrivedToStore.includes(item.status)&&pathCoordinates.push({ lat: parseFloat((item.deliveryMan.location[0])), lng: parseFloat(item.deliveryMan.location[1]) }))
    order.payment.serviceType == orderServiceType.Stores &&
      pathCoordinates.push({
        lat: parseFloat(order.store?.location[0]),
        lng: parseFloat(order.store?.location[1]),
      });
    // status&&status.length && status.map(item=>deliveryManStatusAfterArrivedToStore.includes(item.status)&&pathCoordinates.push({ lat: parseFloat((item.deliveryMan.location[0])), lng: parseFloat(item.deliveryMan.location[1]) }))
    order.payment.serviceType == orderServiceType.Stores
      ? pathCoordinates.push({
          lat: parseFloat(order.payment.destinationAddress.location[0]),
          lng: parseFloat(order.payment.destinationAddress.location[1]),
        })
      : pathCoordinates.push({
          lat: parseFloat(order.payment.pickupAddress.location[0]),
          lng: parseFloat(order.payment.pickupAddress.location[1]),
        });
    polyLineObj.pathCoordinates = pathCoordinates;
    polyLineArray.push(polyLineObj);
    // setPath({
    //   lat: parseFloat(order.store.location[0]),
    //   lng: parseFloat(order.store.location[1]),
    // });
    setZoom(13);
    //   setInfoForInfoBox(findOrder.store?._id, destinationTypes.store)
    //   filter && setOneOrder(findOrder)
    // );
    // }
    // break;
    setPolylineForSpecificOrder(polyLineArray);
  };

  const displayDeliveryGoToSameStore = () => {
    const deliveryGoToSameStoreArray = [];
    orders.map((item) => {
      // item.city?._id==order.city?._id&&item.store?._id==order.store?._id&&arrayForDeliveryManCommingToStore.includes(item.deliveryManStatus)&&!deliveryGoToSameStoreArray.find(x=>x._id==order.deliveryMan?._id)&&deliveryGoToSameStoreArray.push(item.deliveryMan)
      if (item?.deliveryMan) {
        item.city?._id == order.city?._id &&
          item.store?._id == order.store?._id &&
          arrayForDeliveryManCommingToStore.includes(item.driverStatus) &&
          !deliveryGoToSameStoreArray.find(
            (x) => x._id == order.deliveryMan?._id,
          ) &&
          deliveryGoToSameStoreArray.push(item.deliveryMan);
      }
    });
    setDeliveryManGoToSameStore(deliveryGoToSameStoreArray);
  };
  const handleChange = (e, newValue) => {
    typeof newValue == 'number' && setValue(newValue);
  };
  const getInfoAboutDelivery = (deliveryInCity) => {
    const counts = {};
    setIsLoading(true);
    orders.forEach(function (x) {
      counts[x?.deliveryMan?._id] = (counts[x?.deliveryMan?._id] || 0) + 1;
    });

    order &&
      deliveryInCity &&
      deliveryInCity.length > 0 &&
      deliveryInCity.map((delivery) => {
        delivery['orders'] = counts[delivery._id] || 0;
        if (delivery.location) {
          switch (order.payment.serviceType) {
            case orderServiceType.Stores:
              delivery['distanceToStore'] = getDistanceFromLatLonInKm(
                order.store.location[0],
                order.store.location[1],
                delivery.location[0],
                delivery.location[1],
              );
              delivery['distanceToClient'] = getDistanceFromLatLonInKm(
                order.payment.destinationAddress.location[0],
                order.payment.destinationAddress.location[1],
                delivery.location[0],
                delivery.location[1],
              );

              break;

            default:
              break;
          }
        }
      });
    // 618b9dca74bb2f002e30339f
    // const findDEli = deliveryInCity.find(w=>w._id=="618b9dca74bb2f002e30339f")
    const findDEli = deliveryInCity.find(
      (w) => w._id == order.deliveryMan?._id,
    );

    setDeliverInCity(deliveryInCity);
    setIsLoading(false);
  };
  const getDeliveryManList = (id) => {
    id !== 'all' && order && setIsLoading(true);
    getDeliveryManInCity(order.city._id)
      .then(({ data }) => {
        getInfoAboutDelivery(data.deliveryMen);
        const findDelivery = (data.deliveryMen || []).find(
          (x) => x._id == order.deliveryMan?._id,
        );
        if (
          findDelivery &&
          Object.keys(findDelivery).length &&
          findDelivery.location.length
        ) {
          order.deliveryMan.location = findDelivery.location;
          setDeliveryPath({
            lat: findDelivery.location[0],
            lng: findDelivery.location[1],
          });
        } else {
          setDeliveryPath({
            lat: order.deliver?.location[0],
            lng: order.deliver?.location[1],
          });
        }
      })
      .catch((e) => console.log(e))
      .finally(() => setIsLoading(false));
  };
  const displayDetails = async (id) => {
    !showInfo && setShowInfo(true);
    const infoObj = {};
    const storesArray = [];
    const deliveryManStatusArray = [];
    const cratedAtArray = [];
    var deliveryIndex = deliveryInCity.findIndex((x) => x._id == id);
    if (deliveryIndex >= 0) {
      infoObj._id = deliveryInCity[deliveryIndex]?._id;
      infoObj.name = deliveryInCity[deliveryIndex]?.name;
      infoObj.location = deliveryInCity[deliveryIndex]?.location;
      infoObj.phone = deliveryInCity[deliveryIndex]?.phone;
      infoObj.orders = deliveryInCity[deliveryIndex]?.orders;
      infoObj.distanceToStore = deliveryInCity[deliveryIndex]?.distanceToStore;
      orders &&
        orders
          .filter(
            (item) =>
              item.deliveryMan &&
              item.deliveryMan._id == id &&
              item.payment.serviceType == orderServiceType.Stores,
          )
          .map((x) => {
            storesArray.push(x.store.name);
            deliveryManStatusArray.push(x.driverStatus.substring(0, 7));
            cratedAtArray.push(calcDuration(x.createdAt));
          });

      infoObj.stores = storesArray;
      infoObj.status = deliveryManStatusArray;
      infoObj.createdAt = cratedAtArray;
      setInfoBoxData({ ...infoObj });
    }
  };
  // const trackingDeliveryStatus = ()=>{
  //     const trackinArray = [];
  //     const status = order.driverStatusTimes.filter(item=>item?.deliveryMan?.location && item?.deliveryMan?.location.length)
  //     status.map((item,index)=>{
  //         trackinArray.push({
  //             location: {lat:JSON.parse(item?.deliveryMan.location[0]),lng:JSON.parse(item?.deliveryMan.location[1])},
  //             status: item?.status,
  //             time : calcDurationbetweenTwoTimes(index==0?order.createdAt:status[index-1]?.date,item.date)
  //         })
  //     })
  //     setTrackingSataus(trackinArray)
  // }
  const accept_Or_Reject_Order = (deliveryId, accept) => {
    if (!!deliveryId) {
      setIsLoading(true);
      acceptOrRejectOrder(order._id, {
        deliveryManId: deliveryId,
        accept: accept,
        admin,
      })
        .then(({ data }) => {
          close();
        })
        .catch((error) => console.log(error))
        .finally(() => setIsLoading(false));
    }
  };
  const drawLineForSpecificeDelivery = (id) => {
    const deliveryManGoToStores = [];
    const deliveryManGoToClient = [];
    const deliveryManRoutes = [];
    const findDelivery = (deliveryInCity || []).find(
      (deliveryMan) => deliveryMan?._id == id,
    );
    findDelivery &&
      orders &&
      orders.length &&
      orders
        .filter((item) => item.deliveryMan?._id == id)
        .map((order) => {
          var randomColor =
            '#' + Math.floor(Math.random() * 16777215).toString(16);

          switch (order.payment.serviceType) {
            case orderServiceType.Stores:
              const locationClient = {
                lat: JSON.parse(order?.payment?.destinationAddress.location[0]),
                lng: JSON.parse(order?.payment?.destinationAddress.location[1]),
              };
              const locationStore = {
                lat: JSON.parse(order?.store?.location[0]),
                lng: JSON.parse(order?.store?.location[1]),
              };

              deliveryManRoutes.push({
                color: randomColor,
                pathCoordinates: [
                  {
                    lat: JSON.parse(findDelivery?.location[0]),
                    lng: JSON.parse(findDelivery?.location[1]),
                  },
                  { ...locationStore },
                  { ...locationClient },
                ],
              });
              deliveryManGoToStores.push({ ...locationStore });
              deliveryManGoToClient.push({ ...locationClient });
              break;
            case orderServiceType.Choubik:
            case orderServiceType.Express:
            case orderServiceType.Drive:
              const locationclientException = {
                lat: order?.payment?.pickupAddress.location[0],
                lng: order?.payment?.pickupAddress.location[1],
              };
              deliveryManGoToClient.push({
                clientLocation: locationclientException,
              });
              deliveryManRoutes.push({
                color: randomColor,
                pathCoordinates: [
                  {
                    lat: JSON.parse(findDelivery?.location[0]),
                    lng: JSON.parse(findDelivery?.location[1]),
                  },
                  { ...locationclientException },
                ],
              });
              break;

            default:
              break;
          }
        });
    setPolyLineForOneDelivery(deliveryManRoutes);
    setStoresLocationWhenHover(deliveryManGoToStores);
    setClientsLocationWhenHover(deliveryManGoToClient);
  };
  const initialState = () => {
    setPolyLineForOneDelivery([]);
    setClientsLocationWhenHover([]);
    setStoresLocationWhenHover([]);
  };
  useEffect(() => {
    if (order) {
      if (order.payment.serviceType == orderServiceType.Stores) {
        setPath({
          lat: parseFloat(order.store.location[0]),
          lng: parseFloat(order.store.location[1]),
        });
      } else {
        setPath({
          lat: parseFloat(order?.payment?.pickupAddress?.location[0]),
          lng: parseFloat(order?.payment?.pickupAddress?.location[1]),
        });
      }
      getCostomerLocation();

      // displayDeliveryGoToSameStore()
      getDeliveryManList(order?.city?._id);
    }
  }, [order]);
  useEffect(() => {
    deliveryInCity && drawLine();
  }, [deliveryInCity]);
  return (
    <div>
      <Box>
        <Grid container className="mb-2">
          <Grid item xs={6} style={{ display: 'flex' }}>
            orderID : <Typography>{order?.shortId}</Typography>
          </Grid>
          <Grid item xs={6} style={{ display: 'flex' }}>
            store : <Typography>{order?.store?.name}</Typography>
          </Grid>
          <Grid item xs={6} style={{ display: 'flex' }}>
            customer : <Typography>{order?.customer?.name}</Typography>
          </Grid>
          <Grid item xs={6} style={{ display: 'flex' }}>
            deliveryMan : <Typography>{order?.deliveryMan?.name}</Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography gutterBottom>
              Max distance to store : {value} Km
            </Typography>
            <Slider
              value={value}
              min={0}
              step={0.5}
              max={5}
              onChange={handleChange}
              valueLabelDisplay="auto"
              aria-labelledby="non-linear-slider"
            />
          </Grid>
        </Grid>
      </Box>
      <LoadScript
        id="script-loader"
        googleMapsApiKey={googleMapsApiKey}
        language="en"
        region="ma"
      >
        <GoogleMap
          mapContainerClassName="map"
          center={path && path}
          zoom={zoom}
          version="weekly"
          on
          options={{
            styles: [
              {
                featureType: 'icon',
                elementType: 'labels',
                stylers: [{ visibility: 'off' }],
              },
            ],
          }}
        >
          {order && order?.payment.serviceType == orderServiceType.Stores && (
            <CustomMarker
              icon={iconsList.store}
              size={40}
              key={order.store._id}
              position={{
                lat: parseFloat(
                  order.store.location ? order.store.location[0] : '',
                ),
                lng: parseFloat(
                  order.store.location ? order.store.location[1] : '',
                ),
              }}
            />
          )}
          {deliveryPath && Object.keys(deliveryPath).length > 0 && (
            <CustomMarker
              icon={iconsList.deliveryManWithOrder}
              size={50}
              key={JSON.stringify(deliveryPath)}
              position={deliveryPath}
            />
          )}

          {customerPath && order.customer && (
            <CustomMarker
              icon={iconsList.client}
              size={50}
              key={order.customer._id}
              position={customerPath && customerPath}
            />
          )}

          {/* {deliveryManGoToSameStore && deliveryManGoToSameStore.length && deliveryManGoToSameStore.map(delivery => (
                        delivery?.location &&
                        <CustomMarker
                            icon={iconsList.deliveryManWithOrder}
                            size={40}
                            key={delivery?._id}
                            position={{ lat: parseFloat(delivery?.location[0]), lng: parseFloat(delivery?.location[1]) }}
                        />
                    ))} */}
          {clientsLocationWhenHover &&
            clientsLocationWhenHover.length &&
            clientsLocationWhenHover.map((clientLocation, index) => (
              // store?.location &&
              <CustomMarker
                icon={iconsList.client}
                size={35}
                key={`${JSON.stringify(clientLocation) + index}`}
                position={clientLocation}
              />
            ))}
          {storesLocationWhenHover &&
            storesLocationWhenHover.length &&
            storesLocationWhenHover.map((storeLocation, index) => (
              <CustomMarker
                icon={iconsList.store}
                size={35}
                key={`${JSON.stringify(storeLocation) + index}`}
                position={storeLocation}
              />
            ))}

          {deliveryInCity &&
            deliveryInCity.length &&
            deliveryInCity.map((delivery) =>
              delivery?.location && delivery.distanceToStore <= value ? (
                <CustomMarker
                  icon={
                    delivery.orders > 0
                      ? iconsList.deliveryManWithOrder
                      : iconsList.deliverManWithoutOrde
                  }
                  size={50}
                  key={delivery?._id}
                  onClick={() => displayDetails(delivery?._id)}
                  position={{
                    lat: parseFloat(delivery?.location[0]),
                    lng: parseFloat(delivery?.location[1]),
                  }}
                  onMouseOver={() =>
                    delivery.orders > 0 &&
                    drawLineForSpecificeDelivery(delivery?._id)
                  }
                  onMouseOut={() => initialState()}
                  zIndex={100}
                />
              ) : (
                delivery?.location &&
                delivery?._id == order.deliveryMan?._id && (
                  <CustomMarker
                    icon={iconsList.deliveryManWithOrder}
                    size={50}
                    key={delivery?._id}
                    onClick={() => displayDetails(delivery?._id)}
                    position={{
                      lat: parseFloat(delivery?.location[0]),
                      lng: parseFloat(delivery?.location[1]),
                    }}
                    zIndex={100}
                  />
                )
              ),
            )}
          {polylineForSpecificOrder.length &&
            polylineForSpecificOrder.map((line) => (
              <Polyline
                path={line.pathCoordinates}
                geodesic={true}
                options={{
                  strokeColor: line.color,
                  strokeOpacity: 1,
                  strokeWeight: 2,
                }}
              />
            ))}
          {polyLineForOneDelivery.length &&
            polyLineForOneDelivery.map((line) => (
              <Polyline
                path={line.pathCoordinates}
                geodesic={true}
                options={{
                  strokeColor: line.color,
                  strokeOpacity: 1,
                  strokeWeight: 2,
                }}
              />
            ))}
          {showInfo && infoBoxData && Object.keys(infoBoxData).length !== 0 && (
            <InfoWindow
              onCloseClick={() => {
                setShowInfo(false);
              }}
              position={{
                lat: parseFloat(infoBoxData.location[0]),
                lng: parseFloat(infoBoxData.location[1]),
              }}
            >
              <div>
                <div style={{ fontSize: 12, fontColor: `#08233B` }}>
                  name: {infoBoxData?.name}
                  <br />
                  phone : {infoBoxData.phone}
                  <br />
                  orders : {infoBoxData.orders}{' '}
                  {infoBoxData.stores && infoBoxData.stores.length
                    ? infoBoxData.stores.map((store) => `/ ${store}`)
                    : ''}
                  <br />
                  status :{' '}
                  {infoBoxData.status && infoBoxData.status.length
                    ? infoBoxData.status.map((status) => `${status} /`)
                    : ''}
                  <br />
                  created :{' '}
                  {infoBoxData.createdAt && infoBoxData.createdAt.length
                    ? infoBoxData.createdAt.map(
                        (createdAt) => `${createdAt} min/`,
                      )
                    : ''}
                  <br />
                  distance : {infoBoxData.distanceToStore} km
                  <br />
                  <Box justifyContent="center" display="flex">
                    <ButtonComponent
                      size="sm"
                      color="primary"
                      onClick={() => {
                        accept_Or_Reject_Order(infoBoxData?._id, true);
                      }}
                    >
                      assigned
                    </ButtonComponent>
                  </Box>
                </div>
              </div>
            </InfoWindow>
          )}
        </GoogleMap>
      </LoadScript>
    </div>
  );
}
