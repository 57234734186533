import React, { useEffect, useState } from 'react';
// import { useSelector } from 'react-redux';
// import { useAlert } from 'react-alert';
// import { getUserDataState } from '../../auth/redux/selectors'
// import TablePagination from '@material-ui/core/TablePagination';
import { Box, Grid } from '@material-ui/core';
// import { getCustmore, approuvedCustmore } from '../services/serverApi.customer';
import DataTableContainerComponent from '../../../components/DataTableSortingContainer.component';
import { formatTime } from '../../../helpers/index'

import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button'
import CustomButton from '../../../components/material-dashboard-pro-react/CustomButtons/Button'
import SwitchComponent from '../../../components/Switch.component'
import { getNapsResponse } from '../services/serverApi.service'

export default function NapsPaymentPage() {
  // const alert = useAlert();
  // const userData = useSelector(getUserDataState);
  const inpRef = React.useRef();
  const [resultsData, setResultsData] = useState({
    total: 0,
    items: [],
    tableItems: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  // const classes = useStyles();
  // const pages = [10, 250, 500]
  const [page, setPage] = useState(0)
  let numberOfRows = [20, 50, 100];
  const [rowsPerPage, setRowsPerPage] = useState(numberOfRows[0])
  const [openModal, setOpenModal] = useState('');
  const [customers, setCustomers] = useState([]);
  const [payments, setPayments] = useState([]);
  const [indexCustomer, setIndexCustomer] = useState(-1);
  const [searchText, setSearchText] = useState('')
  const [total, setTotal] = useState(0);
  const [subPagination, setSubPagination] = useState([])


  const repautoString = (repauto) => {
    switch (repauto) {
      case '00':
        return 'SUCCESS'
      case '05':
        return 'Ne pas honorer'
      case "82":
        return 'Negative CAM, dCVV, iCVV, or CVV results'
      case "51":
        return 'Not sufficient funds'
      case "57":
        return 'Transaction non permise à ce porteur'
      case "96":
        return 'Mauvais fonctionnement du système'
      case "12":
        return 'Transaction invalide'
      case "54":
        return 'Date de validité de la carte dépassée'
      case "04":
        return 'Conserver la carte'
      case "79":
        return 'Already reversed (by Switch)'
      case "01":
        return 'Contacter l’émetteur de carte'
      case "61":
        return 'Dépasse la limite du montant de retrait'
      case "78":
        return 'Intervene, Bank Approval Required For Partial Amount'
      case "59":
        return 'Suspicion de fraude'
      case "inconnu":
        return 'X.repauto'

      default:
        return repauto
    }
  }
  const prepareShowNapsList = (item, index) => {
    return {
      ...item,
      createdAtCol: item.createdAt ? formatTime(item.createdAt) : '--',
      repautoCell:repautoString(item.repauto),
    }
  }

  const loadNapsList = (page, limit, txt) => {
    setIsLoading(true);
    getNapsResponse(page || 0, limit || 20, true, txt)
      .then(({ data }) => {
        console.log(data);
        setTotal(data.total)
        setPayments(data.payments)
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setIsLoading(false);
      });
  };
  const SearchComponent = () => {
    return (
      <Grid container>
        <Grid item xs={8}>
          <input type='text'
            ref={inpRef}
            placeholder='client search'
            style={{
              border: "none",
              borderBottom: '1px solid black'
            }}
            defaultValue={searchText}

          />
        </Grid>
        <Grid item xs={4} container justify="flex-end"
        >

          <Box textAlign="left">
            <CustomButton
              round
              type="submit"
              color="success"
              size="sm"
              loaderColor="white"
              // onClick={storeSearch}
              onClick={() => {
                setSearchText(inpRef.current.value)
                page > 0 && setPage(0)
                loadNapsList(page > 0 ? 0 : page, rowsPerPage, inpRef.current.value)
              }
              }
            >
              find Client
            </CustomButton>
          </Box>
          <Box textAlign="left">
            <CustomButton
              round
              type="submit"
              color="google"
              size="sm"
              loaderColor="white"
              // onClick={loadNapsList}
              onClick={() => {
                setSearchText('')
                page > 0 && setPage(0)
                loadNapsList(page > 0 ? 0 : page, rowsPerPage, "")
              }}
            >
              clear
            </CustomButton>
          </Box>
        </Grid>
      </Grid>
    )
  }

  useEffect(() => {
    // console.log(payments);
    payments && setResultsData({
      items: payments.map((item, index) => {
        return prepareShowNapsList(item, index);
      }),
    })
  }, [payments])
  const handlePaginate = (val) => {
    // console.log(val,'val');
    // console.log(val.pageSelect, val.numberOfRows);
    setResultsData({
      items: [],
      tableItems: [],
    });
    loadNapsList(val.pageSelect, val.numberOfRows, searchText);
    setPage(val.pageSelect);
    setRowsPerPage(val.numberOfRows);
  };


  useEffect(() => {
    if (total) {
      const pages = Math.ceil(total / rowsPerPage);
      if (total > 2000) {
        console.log(total);
        const newPages = [];
        // const start = page==0?page:Number(page-1)
        let start;
        let end;
        const handelStart = () => {
          let startNumber;
          if (page == 0) startNumber = page
          else if (page > 0) {
            if (page - 100 < 0) startNumber = 0
            else startNumber = Number(page - 100)
          }
          return startNumber
        }
        const handelEnd = () => {
          let endNumber;
          if ((page + 100) < pages) endNumber = page + 100
          else endNumber = page + (pages - page)
          return endNumber
        }
        start = handelStart()
        end = handelEnd()
        for (let i = start; i < end; i++) {
          newPages.push(i)
        }
        setSubPagination(newPages);
      } else {
        setSubPagination([])
      }
    }
  }, [total, page])
  // console.log(subPagination);
  return (
    <>
      {subPagination &&
        <DataTableContainerComponent
          total={total}
          subPagination={subPagination}
          // numberOfRowsProps={rowsPerPage}
          resultsData={resultsData}
          page={page + 1}
          initData={resultsData.items}
          searchInp={true}
          SearchComponent={SearchComponent}
          columns={[

            {
              Header: 'Order Id',
              accessor: 'id_commande',
            },
            {
              Header: 'Client',
              accessor: 'nomprenom',
            },
            {
              Header: 'repauto',
              accessor: 'repautoCell',
            },
            {
              Header: 'Amount',
              accessor: 'montant',
            },
            {
              Header: 'numTrans',
              accessor: 'numTrans',
            },
            {
              Header: 'Type Carte',
              accessor: 'typecarte',
            },
            {
              Header: 'Num Carte',
              accessor: 'numCarte',
            },
            {
              Header: 'createdAt',
              accessor: 'createdAtCol',
            },
          ]}
          title="Naps payment List"
          isLoading={isLoading}
          loadItemsList={loadNapsList}
          // actionButtons={{
          // actionButtons={{
          //     view: {
          //       color: 'info',
          //       type: 'view',
          //       component: ViewItemComponent,
          //     },
          //     edit: {
          //       color: 'success',
          //       type: 'edit',
          //       component: EditServiceCategoryComponent,
          //     },
          //     delete: { color: 'danger', type: 'delete' },
          //   }}
          onPaginate={handlePaginate}
          isPaginate={true}
        />
      }

    </>
  );
}