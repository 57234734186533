import React, { useEffect } from 'react';
// used for making the prop types of this component
import PropTypes from 'prop-types';

// core components
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import Button from 'components/material-dashboard-pro-react/CustomButtons/Button.js';

import defaultImage from 'assets/img/image_placeholder.jpg';
import defaultAvatar from 'assets/img/placeholder.jpg';
import { Box } from '@material-ui/core';
import { uploadMediaApi } from '../../../services/serverApi.service';
import { useAlert } from 'react-alert';
import { useSelector, useDispatch } from 'react-redux';
import { uploadImageResetAction, uploadImageRequestAction } from '../../../redux/uploadRedux/upload.action'
import SelectBoxComponent from '../../../components/SelectBox.component';
import * as yup from 'yup';
import { useFormik } from 'formik';

const validationSchema = yup.object({
  file: yup.string().required(),
});
export default function ImageUpload(props) {
  const alert = useAlert();
  const dispatch = useDispatch();
  const upload = useSelector((state) => state.uploadReducer)
  const { iconUrl, error, loading,newUpload } = upload
  // console.log(iconUrl,error,loading);
  let { avatar, addButtonProps, changeButtonProps, removeButtonProps,folder,inputProps,style,btnSize } = props;
  
  const [file, setFile] = React.useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = React.useState('');
  let fileInput = React.createRef();
  const formik = useFormik({
    initialValues: {
      file: '',
    },
    validationSchema,
  });
  inputProps&& console.log(inputProps);
  
  const handleImageChange = (e) => {
    // inputProps
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setFile(file);
      
      setImagePreviewUrl(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
      formik.setValues({'file':file})
    }
  };
  // console.log(imagePreviewUrl);
  const handleClick = () => {
    fileInput.current.click();
  };

  const handleRemove = () => {
    setFile(null);
    setImagePreviewUrl(props.avatar ? defaultAvatar : defaultImage);
    fileInput.current.value = null;
    dispatch(uploadImageResetAction())
    // props.onRemove();
  };

  useEffect(() => {
    dispatch(uploadImageResetAction())
    setImagePreviewUrl(
      props.defaultImage && !file
        ? props.defaultImage
        : props.avatar
          ? defaultAvatar
          : defaultImage,
    );
    
  }, []);
  
  //  if (iconUrl) {
  //   alert.show('Image has been uploaded', {
  //     type: 'success',
  //   })
  // };
  //  if(error){
  //   alert.show(error, {
  //           type: 'error',
  //   });
  // }
  // console.log(formik.values.folder);
  // var folder = ['store', 'service', 'service_category', 'item', 'product', 'product_category']
  const onUploadMedia = (file) => {
    dispatch(uploadImageRequestAction({ file, folder }))  
  };
  return (
    <div className="fileinput text-center">
      <input type="file" onChange={handleImageChange} ref={fileInput}/>
      <div className={'thumbnail' + (avatar ? ' img-circle' : '')}>
        <img src={imagePreviewUrl} alt="..." style={style?.img?style?.img:{}}/>
      </div>
      <div>
        {file === null ? (
          <Button {...addButtonProps} onClick={() => handleClick()} size={btnSize||''} >
            {props.label ? props.label : avatar ? 'Add Photo' : 'Select image'}
          </Button>
        ) : (
          <>
          {/* <SelectBoxComponent
        items={folder.map((name) => ({
          value: name,
          label: name,
        }))}
        inputLabel="Category list"
        onValueChange={(items) => {
          formik.setFieldValue('folder', items);
        }}
        value={formik.values.folder}
        
      /> */}
          <Box display="flex" justifyContent="center">
            <Button
              {...changeButtonProps}
              onClick={() => handleClick()}
              justIcon
              round
              color={loading?'':'info'}
              size={btnSize||''}
              
            >
              <EditIcon />
            </Button>

            <Box mx={0.5}>
              <Button
                {...removeButtonProps}
                onClick={() => handleRemove()}
                justIcon
                round
                color="danger"
              >
                <CloseIcon />
              </Button>
            </Box>

            <Button
              {...removeButtonProps}
              // onClick={() => props.onSubmit(file)}
              onClick={() => onUploadMedia(file)}
              justIcon
              round
              color={(!formik.dirty || !formik.isValid || loading)?'':'success'}
              disabled={(!formik.dirty || !formik.isValid || loading)?true:false}
            >
              <CheckIcon />
            </Button>
          </Box>
          </>
        )}
        {error&&<h5 style={{color:'#ca0b00'}}>{error}</h5 >}
        {iconUrl&&newUpload&&<h5 style={{color:'#4BB543'}}>Image has been uploaded</h5 >}
      </div>
    </div>
  );
}

ImageUpload.propTypes = {
  avatar: PropTypes.bool,
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object,
  defaultImage: PropTypes.string,
  folder: PropTypes.string,
  onSubmit: PropTypes.func,
  onRemove: PropTypes.func,
};
