import { takeLatest, put } from 'redux-saga/effects'
import axios from 'axios'
import { getPartnerOrderSuccessAction, getPartnerOrderFailAction, getPartnerOrderSuccessV2Action, getPartnerOrderFailV2Action } from './actions/order.actions'
import { GET_PARTNER_ORDER_REQUEST, GET_PARTNER_ORDER_REQUESTV2 } from './constants/order.constants'
import { MICROSERVICE_BASE_URL } from '../../../config/backendServer.config'

function* getOrdersFromAPI() {
  const { data } = yield axios.get(
    `${MICROSERVICE_BASE_URL.PARTNERS}/deliveries/current`,
  );

  return data
}
function* getPartnerOrders({ cities, warehouses }) {
  try {
    const data = yield getOrdersFromAPI();
    yield put(getPartnerOrderSuccessAction(data, cities, warehouses))

  }
  catch (error) {
    console.log(error);
    yield put(getPartnerOrderFailAction(error))
  }

}
function* getPartnerOrdersV2({ cities, warehouses, selectedDeliveryManStatus,
  selectedDispatchingStatus,
  filterBy, }) {
  try {
    const data = yield getOrdersFromAPI();
    yield put(getPartnerOrderSuccessV2Action(data, cities, warehouses, selectedDeliveryManStatus,
      selectedDispatchingStatus,
      filterBy,))

  }
  catch (error) {
    console.log(error);
    yield put(getPartnerOrderFailV2Action(error))
  }

}


export default function* watcherSage() {
  yield takeLatest(GET_PARTNER_ORDER_REQUEST, getPartnerOrders)
  yield takeLatest(GET_PARTNER_ORDER_REQUESTV2, getPartnerOrdersV2)

}