import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Actions
import {
  getPartnerOrderRequestV2Action,
  initialPartnerOrderV2Action,
  deliverieStatusChangeV2Action,
  newDeliverieV2Action,
  completedDeliverieV2Action,
  cancelDeliverieV2Action,
  AssignDeliverieV2Action,
  updatedDeliverieV2Action,
  changeDeliveryManLocationV2Action,
} from '../redux/actions/order.actions';

// Selector
import {
  getPartnerDeliveriesDataState,
  filterSelector,
} from '../redux/selectors';
import { getUserDataState } from '../../auth/redux/selectors';

import DeliveriesTable from '../../../components/DeliveriesTable';
import ModalComponentsDelivries from '../components/Modal.components.delivries';
import DelivriesFilterComponent from '../components/Delivries.filter.component';
import DeliveryMenInfoComponent from '../components/DeliveryMen.info.v3.component';
import DispatchingInfoComponenet from '../components/Dispatching.info.v3.componenet';

// mock
import { columnsV3, columnsDispatchedV3 } from '../mock';
// api
import {
  socketConnectPertnerGps,
  socketConnect,
} from '../services/server.api.service';

export default function CurrentPartnerOrdersPage() {
  const dispatch = useDispatch();
  const { deliveries, loading, error, deliveriesChangeCounter } = useSelector(
    getPartnerDeliveriesDataState,
  );

  const [selectedOrders, setSelectedOrders] = useState({});

  const {
    selectedCities,
    filterChangeCounter,
    filterWarehouseChangeCounter,
    selectedWarehouse,
    selectedDeliveryManStatus,
    filterBy,
    selectedDispatchingStatus,
  } = useSelector(filterSelector);

  const userData = useSelector(getUserDataState);

  const [socket, setSocket] = useState(null);
  const [socketGPS, setSocketGPS] = useState(null);
  useEffect(() => {
    // console.log('filterBy', filterBy);
    dispatch(initialPartnerOrderV2Action());
    selectedCities &&
      selectedCities.length > 0 &&
      dispatch(
        getPartnerOrderRequestV2Action(
          selectedCities,
          selectedWarehouse,
          selectedDeliveryManStatus,
          selectedDispatchingStatus,
          filterBy,
        ),
      );
  }, [filterChangeCounter, filterWarehouseChangeCounter]);
  useEffect(() => {
    if (!socket) {
      setSocket(socketConnect(userData.accessToken));
    }
    if (socket) {
      socket.emit('JOIN_ROOM', 'ADMIN_DISPATCHING_ECOM_PARTNER');
    }
  }, [selectedCities]);
  useEffect(() => {
    if (!socketGPS) {
      // setSocketGPS(socketConnectPertnerGps(userData.accessToken));
    }
    if (socketGPS) {
      socketGPS.emit('JOIN_ROOM', 'ADMIN_DISPATCHING');
    }
  }, [selectedCities]);
  const socketEvents = () => {
    socket.on('DELIVERY_STATUS_CHANGED', function (data) {
      if (
        (selectedCities.includes('all') ||
          selectedCities.includes(data?.cityId)) &&
        (selectedWarehouse.length > 0
          ? selectedWarehouse.includes(data?.pickupAddress.libeleMagasin)
          : true)
      ) {
        dispatch(
          deliverieStatusChangeV2Action(
            data,
            selectedDeliveryManStatus,
            selectedDispatchingStatus,
            filterBy,
          ),
        );
      }
    });
    socket.on('DELIVERY_CREATED', function (data) {
      if (
        (selectedCities.includes('all') ||
          selectedCities.includes(data?.cityId)) &&
        (selectedWarehouse.length > 0
          ? selectedWarehouse.includes(data?.pickupAddress.libeleMagasin)
          : true)
      )
        dispatch(
          newDeliverieV2Action(
            data,
            selectedDeliveryManStatus,
            selectedDispatchingStatus,
            filterBy,
          ),
        );
    });
    socket.on('DELIVERY_DISPATCHED_TO_DELIVERYMAN', function (data) {
      if (
        (selectedCities.includes('all') ||
          selectedCities.includes(data?.cityId)) &&
        (selectedWarehouse.length > 0
          ? selectedWarehouse.includes(data?.pickupAddress.libeleMagasin)
          : true)
      )
        dispatch(
          AssignDeliverieV2Action(
            data,
            selectedDeliveryManStatus,
            selectedDispatchingStatus,
            filterBy,
          ),
        );
    });
    socket.on('DELIVERY_ASSIGNED_TO_DELIVERYMAN', function (data) {
      if (
        (selectedCities.includes('all') ||
          selectedCities.includes(data?.cityId)) &&
        (selectedWarehouse.length > 0
          ? selectedWarehouse.includes(data?.pickupAddress.libeleMagasin)
          : true)
      )
        dispatch(
          AssignDeliverieV2Action(
            data,
            selectedDeliveryManStatus,
            selectedDispatchingStatus,
            filterBy,
          ),
        );
    });
    socket.on('DELIVERY_COMPLETED', function (data) {
      if (
        (selectedCities.includes('all') ||
          selectedCities.includes(data?.cityId)) &&
        (selectedWarehouse.length > 0
          ? selectedWarehouse.includes(data?.pickupAddress.libeleMagasin)
          : true)
      )
        dispatch(
          completedDeliverieV2Action(
            data._id,
            data?.destinationAddress?.contactPhone,
          ),
        );
    });
    socket.on('DELIVERY_CANCELLED', function (data) {
      if (
        (selectedCities.includes('all') ||
          selectedCities.includes(data?.cityId)) &&
        (selectedWarehouse.length > 0
          ? selectedWarehouse.includes(data?.pickupAddress.libeleMagasin)
          : true)
      )
        dispatch(
          cancelDeliverieV2Action(
            data._id,
            data?.destinationAddress?.contactPhone,
          ),
        );
    });
    socket.on('DELIVERY_UPDATED_BY_ORGANISATION', function (data) {
      if (
        (selectedCities.includes('all') ||
          selectedCities.includes(data?.cityId)) &&
        (selectedWarehouse.length > 0
          ? selectedWarehouse.includes(data?.pickupAddress.libeleMagasin)
          : true)
      )
        dispatch(
          updatedDeliverieV2Action(
            data,
            selectedDeliveryManStatus,
            selectedDispatchingStatus,
            filterBy,
          ),
        );
    });
  };
  const socketGPSEvents = () => {
    socketGPS.on('DELIVERYMAN_LOCATION_CHANGED', function (data) {
      // if (
      //   selectedCities.includes('all') ||
      //   selectedCities.includes(data?.cityId)
      // ) {
      dispatch(
        changeDeliveryManLocationV2Action(data.deliveryManId, data.location),
      );
      // }
    });
  };
  useEffect(() => {
    if (socket) {
      socket.removeAllListeners();
      socketEvents();
    }
  }, [
    selectedCities,
    selectedWarehouse,
    selectedDeliveryManStatus,
    selectedDispatchingStatus,
    filterBy,
  ]);

  // useEffect(() => {
  //   if (socketGPS) {
  //     socketGPS.removeAllListeners();
  //     socketGPSEvents();
  //   }
  // }, [selectedCities, selectedWarehouse]);

  return (
    <dev>
      <DelivriesFilterComponent />
      <DispatchingInfoComponenet />
      {socket && <DeliveryMenInfoComponent />}

      <div style={{ marginTop: 40 }}>
        {/* ************************* DISPATCHING *****************************  */}
        <DeliveriesTable
          deliveries={([...deliveries] || []).filter(
            (x) => x.dispatchingStatus !== 'DISPATCHED',
          )}
          columns={columnsV3}
          title={'Dispatching service'}
        />
        {/* ************************* DISPATCHED *****************************  */}
        <DeliveriesTable
          deliveries={([...deliveries] || []).filter(
            (x) => x.dispatchingStatus === 'DISPATCHED',
          )}
          columns={columnsDispatchedV3}
          title={'Livreur service'}
        />
      </div>
      <ModalComponentsDelivries />
    </dev>
  );
}
