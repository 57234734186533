import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { GoogleMap, LoadScript, Polygon, Marker, Polyline, InfoWindow, InfoBox } from '@react-google-maps/api';
import { FormControl, InputLabel, Select, MenuItem, TextField, Grid, Switch } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import SportsMotorsportsIcon from '@material-ui/icons/SportsMotorsports';
import { getCities } from '../../delivery-info/services/serverApi.service';
import { getCurrentOrders } from '../../orders/services/serverApi.service'
import GridContainer from '../../../components/material-dashboard-pro-react/Grid/GridContainer';
import Card from '../../../components/material-dashboard-pro-react/Card/Card';
import CardHeader from '../../../components/material-dashboard-pro-react/Card/CardHeader';
import CardBody from '../../../components/material-dashboard-pro-react/Card/CardBody';
import DeliveryManIcon from '../../../assets/img/mapIcons/deliveryManPin.png'
import DeliveryManWithoutOrderIcon from '../../../assets/img/mapIcons/deliveryManWithoutOrderPin.png'
import StoreIcon from '../../../assets/img/mapIcons/storeIcon.png'
import StoreIconWithOrder from '../../../assets/img/mapIcons/storePinWithorder.png'
import ClientIcon from '../../../assets/img/mapIcons/clientIcon.png'
import { googleMapsApiKey } from '../../../config/backendServer.config'
import { destinationTypes, displayDeliveryMan, seachBy, filterDeliveryManStatus, arrayForDeliveryManCommingToStore, arrayForDeliveryManArrivedToStore, arrayForDeliveryManStartDelivery } from '../mocks/index';
import { getDeliveryMan,socketConnect } from '../../orders/services/serverApi.service'
import { getStores } from '../../stores/services/serverApi.service'
import { getDeliveryManInCity } from '../services/serverAPI.service'

var socket;
export default function MapViewStoresPage() {
  const auth = useSelector((state) => state.authReducer);
  const { userData } = auth;
  const [cities, setCities] = useState([])
  const [orders, setOrders] = useState([])
  const [polylineDrawer, setPolylineDrawer] = useState([])
  const [polylineForSpecificOrder, setPolylineForSpecificOrder] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [selectedCity, setSelectedCity] = useState('')
  const [desplayDelivryMan, setDesplayDelivryMan] = useState('')
  const [delivryManStatus, setDelivryManStatus] = useState('')
  const [searchBy, setSearchBy] = useState('')
  const [filter, setFilter] = useState(false)
  // [33.56633768022972, -7.658855561167002]
  const [path, setPath] = useState({ lat: 33.56633768022972, lng: -7.658855561167002 });
  // { lat: 33.589886, lng: -7.603869 }
  const [zoom, setZoom] = useState(13);
  const [deliveryMan, setDeliveryMan] = useState([]);
  const [specificDeliveryMan, setSpecificDeliveryMan] = useState([]);
  const [specificSelect, setSpecifiSelect] = useState([]);
  const [stores, setStores] = useState([]);
  const [infoBoxData, setInfoBoxData] = useState({});
  const [oneDeliveryMan, setOneDeliveryMan] = useState({});
  const [oneStore, setOneStore] = useState({});
  const [oneClient, setOneClient] = useState({});
  const [oneOrder, setOneOrder] = useState({});

  const loadOrderList = (cityId) => {
    getCurrentOrders(cityId)
      .then(({ data }) => {
        console.log(data);
        setOrders(data)
        setPath(
          cities.find(e => e._id == cityId).cityLocations.map((locationItem) => ({
            lat: locationItem[1],
            lng: locationItem[0],
          })),
        );
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setIsLoading(false);
      });
  };
  console.log('delivryManStatus', delivryManStatus);
  const getDeliveryManList = (cityId) => {
    // getDeliveryMan()
    getDeliveryManInCity(cityId)
      .then(({ data }) => {
        // console.log('delivery DATA', data);
        // data.deliveryMen[1].location = [
        //   33.5939208,
        //   -7.5939208
        // ]
        // const deliveryManArray = []
        // data.length && data.map(delivery => {
        //   if (delivery.cityId == cityId) {
        //     orders.length && orders.map(order => {
        //       if (order.deliveryMan?._id == delivery?._id) {
        //         delivery.location = order.deliveryMan.location;
        //         return false
        //       }
        //     })
        //     deliveryManArray.push(delivery)
        //   }
        // })
        setDeliveryMan(data.deliveryMen);
        setSpecificDeliveryMan(data.deliveryMen);
      })
      .catch((e) => console.log(e));
  };
  const getStoresInCity = (cityId) => {
    getStores(0, 0, false)
      .then(({ data }) => {
        const storesArray = []
        // const storesArrayId = []
        data.length && data.map(store => {
          store.cityId == cityId && storesArray.push(store)
        })
        // console.log('store', data)
        setStores(storesArray)
      })
      .catch((e) => console.log(e));
    // orders.find.length&&orders.map(order=>{
    //   !storesArrayId.includes(order.store?._id)&&storesArray.push(order.store),storesArrayId.push(order.store._id)
    // })
    // console.log('store inside func', storesArray);
    // setStores(storesArray)
  }

  const getCitiesFromAPI = () => {
    getCities(0, 0, false)
      .then(({ data }) => setCities(data))
      .catch((error) => console.log(error))
  }
  useEffect(() => {
    socket = socketConnect(userData.accessToken);
    function subscribeToAdminRoom() {
      socket.emit('JOIN_ROOM', 'ADMIN_DISPATCHING');

      // socket.on('NEW_ORDER', function (data) {
      //   console.log('deliveryMan New Order', data);
      // });
    }
    subscribeToAdminRoom();
    getCitiesFromAPI()

  }, [])
  console.log('deliveryMan',deliveryMan);
  useEffect(() => {
    orders && getDeliveryManList(selectedCity._id)
    orders && getStoresInCity(selectedCity._id)
    socket.on('ORDER_STATUS_CHANGED', function (data) {
      console.log('status change', data);
      if (orders.find((item) => item._id == data._id)) {
        // console.log('find some item');
        // data._id=='614a5068d402c300227a6c7d'&&console.log('orderStatusChanged', data);
        const index = orders.findIndex((item) => item._id == data._id)
        var newItems = [...orders]
        var newItemsSpecific = [...specificSelect]
        let indexForSpecificSelect = '';
        if(specificSelect.length) indexForSpecificSelect = orders.findIndex((item) => item._id == data._id)
        if (data.deliveryManStatus == "DELIVERED") {
          newItems.splice(index, 1);
          indexForSpecificSelect&&newItemsSpecific.splice(indexForSpecificSelect, 1)
        } else {

          newItems[index] = data
          if(indexForSpecificSelect)newItemsSpecific[indexForSpecificSelect] = data
        }
        // console.log(newItems);
        // setIsNewData(true)
        setOrders(newItems);
        setSpecifiSelect(newItemsSpecific);
        //   isNewData&&setInterval(() => {
        //     setOrderToState(data)
        // },60000)
      }
    });

    socket.on('NEW_ORDER', function (data) {
      console.log('New Order', data);
      if (!orders.find((item) => item._id == data._id)) {
        const newItems = [...orders]
        newItems.push(data)
        console.log('newItems', newItems);
        // setIsNewData(true)
        setOrders(newItems);
      }
    });
    socket.on('DELIVERYMAN_LOCATION_CHANGED', function (data) {
      console.log('DELIVERYMAN_LOCATION_CHANGED', data);
      const newItems = [...specificDeliveryMan]
      // console.log('newItems',newItems);
      if(newItems.find(x=>x._id === data.deliveryManId)){
        const deliveryIndex = newItems.findIndex(x=>x._id==data.deliveryManId)
        // console.log('deliveryIndex',deliveryIndex);
        if(deliveryIndex>=0){
          const delivery = newItems[deliveryIndex];
          // console.log('delivery',delivery);
          if((parseFloat(delivery.location[0])!==parseFloat(data.location[0]))||(parseFloat(delivery.location[1])!==parseFloat(data.location[1]))){
            delivery.location = data.location
            newItems[deliveryIndex] = delivery
            setSpecificDeliveryMan(newItems)
          }
        }
        
      }
    });

  }, [orders])
  console.log('deliveryMan',deliveryMan);
  const drawLine = (id, destination) => {
    var polyLineArray = []
    orders.map((order) => {
      const pathCoordinates = []
      const polyLineObj = {}
      var randomColor = '#' + Math.floor(Math.random() * 16777215).toString(16);
      polyLineObj.color = randomColor

      switch (destination) {
        case destinationTypes.deliveryMan:
          // console.log('here');
          if (order.deliveryMan?._id == id) {
            order.deliveryMan?._id && pathCoordinates.push({ lat: parseFloat((order.deliveryMan.location[0])), lng: parseFloat(order.deliveryMan.location[1]) })
            pathCoordinates.push({ lat: parseFloat((order.store.location[0])), lng: parseFloat((order.store.location[1])) })
            order.payment.serviceType == 'Stores' && pathCoordinates.push({ lat: parseFloat((order.payment.destinationAddress.location[0])), lng: parseFloat((order.payment.destinationAddress.location[1])) })
            polyLineObj.pathCoordinates = pathCoordinates
            polyLineArray.push(polyLineObj)
          }
          break;
        case destinationTypes.store:
          if (order.store?._id == id) {
            order?.deliveryMan?.location && pathCoordinates.push({ lat: parseFloat((order.deliveryMan.location[0])), lng: parseFloat(order.deliveryMan.location[1]) })
            pathCoordinates.push({ lat: parseFloat((order.store.location[0])), lng: parseFloat((order.store.location[1])) })
            order.payment.serviceType == 'Stores' && pathCoordinates.push({ lat: parseFloat((order.payment.destinationAddress.location[0])), lng: parseFloat((order.payment.destinationAddress.location[1])) })
            polyLineObj.pathCoordinates = pathCoordinates
            polyLineArray.push(polyLineObj)
          }
          break;
        case destinationTypes.client:
          if (order.customer?._id == id) {
            order.deliveryMan?.location && pathCoordinates.push({ lat: parseFloat((order.deliveryMan.location[0])), lng: parseFloat(order.deliveryMan.location[1]) })
            pathCoordinates.push({ lat: parseFloat((order.store.location[0])), lng: parseFloat((order.store.location[1])) })
            order.payment.serviceType == 'Stores' && pathCoordinates.push({ lat: parseFloat((order.payment.destinationAddress.location[0])), lng: parseFloat((order.payment.destinationAddress.location[1])) })
            polyLineObj.pathCoordinates = pathCoordinates
            polyLineArray.push(polyLineObj)
          }
          break;

        default:
          break;
      }

    })
    // console.log('polyLineArray', polyLineArray);
    setPolylineDrawer(polyLineArray)
  }

  const drawLinFromSearch = (id) => {
    const polyLineArray = []
    const pathCoordinates = []
    const polyLineObj = {}
    var randomColor = '#' + Math.floor(Math.random() * 16777215).toString(16);
    polyLineObj.color = randomColor
    const findOrder = orders.find(x => x?.shortId == id)
    if (findOrder) {
      console.log('find order', findOrder);
      findOrder?.deliveryMan?.location && findOrder.deliveryMan.location.length && pathCoordinates.push({ lat: parseFloat((findOrder.deliveryMan.location[0])), lng: parseFloat(findOrder.deliveryMan.location[1]) })
      pathCoordinates.push({ lat: parseFloat((findOrder.store?.location[0])), lng: parseFloat((findOrder.store?.location[1])) })
      findOrder.payment.serviceType == 'Stores' && pathCoordinates.push({ lat: parseFloat((findOrder.payment.destinationAddress.location[0])), lng: parseFloat((findOrder.payment.destinationAddress.location[1])) })
      polyLineObj.pathCoordinates = pathCoordinates
      polyLineArray.push(polyLineObj)
      setPath({
        lat: parseFloat(findOrder.store.location[0]),
        lng: parseFloat(findOrder.store.location[1])
      })
      setZoom(13)
      setInfoForInfoBox(findOrder.store?._id, destinationTypes.store)
      filter && setOneOrder(findOrder)
      // );
    }
    // break;
    // console.log('polyLineArray', polyLineArray);
    setPolylineForSpecificOrder(polyLineArray)


  }
  // console.log('OneOrder',oneOrder);

  const setInfoForInfoBox = (id, destination) => {
    const infoObj = {};
    // const infoArray = [];
    switch (destination) {
      case destinationTypes.deliveryMan:
        deliveryMan.length && deliveryMan.map(delivery => {
          if (delivery._id == id) {
            infoObj.name = delivery.name
            infoObj.location = delivery.location
            infoObj.balance = delivery.balance
            infoObj.phone = delivery.phone
            const orderArray = []
            orders.length && orders.map(order => {
              order?.deliveryMan?._id == id && orderArray.push(order?._id)
            })
            infoObj.orders = orderArray.length
            setInfoBoxData(infoObj)
          }
        })
        break;
      case destinationTypes.store:
        orders.length && orders.map(order => {
          if (order.store._id == id) {
            infoObj.name = order.store.name
            infoObj.location = order.store.location
            infoObj.phone = order.store.phone
            const orderArray = []
            orders.length && orders.map(order => {
              order?.store?._id == id && orderArray.push(order?._id)
            })
            infoObj.orders = orderArray.length
            setInfoBoxData(infoObj)
          }
        })
        break;
      case destinationTypes.client:
        orders.length && orders.map(order => {
          if (order.customer?._id == id) {
            if (order.payment.serviceType == 'Stores') {
              infoObj.name = order.customer.name
              if (order.payment.serviceType == 'Stores') infoObj.location = order.payment?.destinationAddress.location
              infoObj.phone = order.customer.phone
              const orderArray = []
              // orders.length && orders.map(order => {
              //   order?.store?._id == id && orderArray.push(order?._id)
              // })
              // infoObj.orders = orderArray.length
              setInfoBoxData(infoObj)
            }

          }
        })
        break;

      default:
        break;
    }

  }
  // console.log('InfoBoxData',infoBoxData);
  const filterDeliveryForDisplay = (displayType) => {

    switch (displayType) {
      case 'withoutOrder':
        // console.log('type is withoutOrder');
        const specificDeliverArray = []
        const boolenArray = []
        deliveryMan.length && deliveryMan.map(delivery => {
          orders.length && orders.map(order => {
            // order?.deliveryMan?._id !== delivery?._id && !specificDeliverArray.find(x => x._id == delivery?._id) && specificDeliverArray.push(delivery);
            order?.deliveryMan?._id !== delivery?._id ? boolenArray.push(true) : boolenArray.push(false)
            // specificDeliverArray.push(delivery)
          })
          boolenArray.every(x => x == true) && specificDeliverArray.push(delivery)
        })
        // console.log('specificDeliverArray', specificDeliverArray);
        // console.log('boolenArray', boolenArray);
        setSpecificDeliveryMan(specificDeliverArray)
        break;
      case 'withOrder':
        const specificDeliverArray1 = [];
        // console.log('type is withOrder');

        deliveryMan.length && deliveryMan.map(delivery => {
          orders.length && orders.map(order => {
            order.deliveryMan?._id == delivery._id && !specificDeliverArray1.find(x => x?._id == delivery?._id) && specificDeliverArray1.push(delivery)
          })
        })
        setSpecificDeliveryMan(specificDeliverArray1)

        break;

      default:
        setSpecificDeliveryMan(deliveryMan)
        break;
    }
  }
  // console.log('specificDeliveryMan',specificDeliveryMan);
  console.log('orders', orders);
  const filterDeliveryManWithStatus = (status) => {
    let deliveryManArray = []
    switch (status) {
      case filterDeliveryManStatus.COMING_FOR_DELIVERY:
        deliveryManArray = []
        orders && orders.length && orders.map(order => {
          order.deliveryMan && arrayForDeliveryManCommingToStore.includes(order.deliveryManStatus) && !deliveryManArray.find(x => x._id == order?.deliveryMan?._id) && deliveryManArray.push(order?.deliveryMan)

        })
        console.log('deliveryManArray', deliveryManArray);
        setSpecificDeliveryMan(deliveryManArray)
        break;
      case filterDeliveryManStatus.ARRIVED_TO_STORE:
        deliveryManArray = []
        orders && orders.length && orders.map(order => {
          order.deliveryMan && arrayForDeliveryManArrivedToStore.includes(order.deliveryManStatus) && !deliveryManArray.find(x => x._id == order?.deliveryMan?._id) && deliveryManArray.push(order?.deliveryMan)

        })
        // console.log('deliveryManArray',deliveryManArray);
        setSpecificDeliveryMan(deliveryManArray)
        break;
      case filterDeliveryManStatus.STARTED_DELIVERY:
        deliveryManArray = []
        orders && orders.length && orders.map(order => {
          order.deliveryMan && arrayForDeliveryManStartDelivery.includes(order.deliveryManStatus) && !deliveryManArray.find(x => x._id == order?.deliveryMan?._id) && deliveryManArray.push(order?.deliveryMan)

        })
        // console.log('deliveryManArray',deliveryManArray);
        setSpecificDeliveryMan(deliveryManArray)
        break;


      default:
        setSpecificDeliveryMan(deliveryMan)
        break;
    }
  }
  const searchUsing = (txt) => {
    // console.log(searchBy);
    if (txt !== "") {
      if (!filter) {

        switch (searchBy) {
          case 'delivery':
            // console.log('delivery');
            specificDeliveryMan && specificDeliveryMan.length && specificDeliveryMan.map(delivery => {
              if (delivery.name.toLowerCase().startsWith(txt.toLowerCase())) {
                if (delivery.location) {

                  setPath({
                    lat: parseFloat(delivery.location[0]),
                    lng: parseFloat(delivery.location[1]),
                  });
                  setInfoForInfoBox(delivery?._id, destinationTypes.deliveryMan)
                }
              }
            })
            break;
          case 'store':
            stores && stores.length && stores.map(store => {
              if (store.name.toLowerCase().startsWith(txt.toLowerCase())) {
                setPath({
                  lat: parseFloat(store.location[0]),
                  lng: parseFloat(store.location[1]),
                });
                setInfoForInfoBox(store?._id, destinationTypes.store)
              }
            })
            break;
          case 'client':
            orders && orders.length && orders.map(order => {
              if (order?.customer?.name.toLowerCase().startsWith(txt.toLowerCase())) {
                order.customer?.payment?.serviceType == "Stores" &&
                  setPath({
                    lat: parseFloat(order.customer?.payment?.destinationAddress.location[0]),
                    lng: parseFloat(order.customer?.payment?.destinationAddress.location[1]),
                  });
                setInfoForInfoBox(order.customer?._id, destinationTypes.client)
              }
            })
            break;
          case 'order':
            // console.log('order');
            drawLinFromSearch(txt)
            break;

          default:
            break;
        }
      } else {
        switch (searchBy) {
          case 'delivery':
            specificDeliveryMan && specificDeliveryMan.length && specificDeliveryMan.map(delivery => {
              if (delivery.name.toLowerCase().startsWith(txt.toLowerCase())) {
                if (delivery.location) {

                  setPath({
                    lat: parseFloat(delivery.location[0]),
                    lng: parseFloat(delivery.location[1]),
                  });
                  setInfoForInfoBox(delivery?._id, destinationTypes.deliveryMan)
                }
                setOneClient({})
                setOneStore({})
                setOneDeliveryMan(delivery)
              }
            })
            break;
          case 'store':
            stores && stores.length && stores.map(store => {
              if (store.name.toLowerCase().startsWith(txt.toLowerCase())) {
                setPath({
                  lat: parseFloat(store.location[0]),
                  lng: parseFloat(store.location[1]),
                });
                setInfoForInfoBox(store?._id, destinationTypes.store)
                setOneClient({})
                setOneDeliveryMan({})
                setOneStore(store)
              }
            })
            break;
          case 'client':
            let clientObj = {};
            orders && orders.length && orders.map(order => {
              if (order?.customer?.name.toLowerCase().startsWith(txt.toLowerCase())) {
                order.customer?.payment?.serviceType == "Stores" &&
                  setPath({
                    lat: parseFloat(order.customer?.payment?.destinationAddress.location[0]),
                    lng: parseFloat(order.customer?.payment?.destinationAddress.location[1]),
                  });
                setInfoForInfoBox(order.customer?._id, destinationTypes.client)
                clientObj = order.customer
                console.log(order);
                if (order.payment?.serviceType == "Stores") {
                  console.log("location");
                  clientObj.location = order?.payment?.destinationAddress.location
                  console.log(clientObj)
                  console.log(clientObj)
                  setOneDeliveryMan({})
                  setOneStore({})
                  setOneClient(clientObj)
                  return true
                }
                if (clientObj?.location) return false
              }
            })

            break;
          case 'order':
            // console.log('order');
            drawLinFromSearch(txt)
            break;
          default:
            break;
        }
      }
    }
  }
  const initialState = () => {
    setOneClient({})
    setOneStore({})
    setOneOrder({})
    setOneDeliveryMan({})
    setPolylineForSpecificOrder([])
    setInfoBoxData({})
  }
  const handelSelect = (e) => {
    setSearchBy(e);
    // setFilter(false)
    switch (e) {
      case 'delivery':
        initialState()
        setSpecifiSelect(deliveryMan)
        break;
      case 'store':
        initialState()
        setSpecifiSelect(stores)
        break;
      case 'client':
        initialState()
        setSpecifiSelect([])
        break;
      case 'order':
        initialState()
        setSpecifiSelect(orders)
        break;

      default:
        break;
    }
  }
  useEffect(() => {

  }, [infoBoxData])
  console.log('specificSelect',specificSelect);
  return (
    <div>
      <GridContainer>
        <Card>
          <CardHeader>Current Orders</CardHeader>
          <CardBody>

            <Grid container spacing={0}>
              <Grid item xs={3}>
                <FormControl style={{ minWidth: '180px' }}>
                  <InputLabel>Cities</InputLabel>
                  <Select
                    value={selectedCity._id}
                    onChange={($event) => {
                      console.log($event.target.value);
                      const citySelected = cities.find(e => e._id == $event.target.value)
                      console.log(citySelected);
                      setSelectedCity(citySelected);
                      getDeliveryManList($event.target.value)
                      loadOrderList($event.target.value)
                    }}
                  >
                    {cities.map((item, index) => (
                      <MenuItem value={item._id}>{item.name}</MenuItem>
                    ))}
                  </Select>

                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl style={{ minWidth: '200px', marginLeft: "2%" }}>

                  <InputLabel>display DeliveryMan</InputLabel>
                  <Select
                    value={selectedCity._id}
                    onChange={($event) => {
                      console.log($event.target.value);
                      setDesplayDelivryMan($event.target.value)
                      filterDeliveryForDisplay($event.target.value)
                    }}
                  >
                    {displayDeliveryMan.map((item, index) => (
                      <MenuItem value={item.name}>{item.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl style={{ minWidth: '200px', marginLeft: "2%" }}>

                  <InputLabel>DeliveryMan Status</InputLabel>
                  <Select
                    value={selectedCity._id}
                    onChange={($event) => {
                      // console.log($event.target.value);
                      setDelivryManStatus($event.target.value)
                      // filterDeliveryForDisplay($event.target.value)
                      filterDeliveryManWithStatus($event.target.value)
                    }}
                  >
                    {Object.keys(filterDeliveryManStatus).map((item, index) => (
                      <MenuItem value={item}>{item}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl style={{ minWidth: '200px', marginLeft: "2%" }}>
                  <InputLabel>search by</InputLabel>
                  <Select
                    value={searchBy || ''}
                    onChange={($event) => handelSelect($event.target.value)}
                  >
                    {seachBy.map((item, index) => (
                      <MenuItem value={item.name}>{item.name}</MenuItem>
                    ))}
                  </Select>

                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <Grid component="label" container alignItems="center" spacing={1}>
                  <Grid item>search</Grid>
                  <Grid item>
                    <Switch
                      onChange={(value) => {
                        setFilter(value.target.checked),
                        setPolylineForSpecificOrder([])
                        setOneOrder({})
                      }}
                      value="checked"
                      checked={filter}
                      defaultValue={false}
                    />
                  </Grid>
                  <Grid item>filter</Grid>
                </Grid>
                {/* <FormControl style={{ minWidth: '200px', marginLeft: "2%" }}>
                <InputLabel>Filter by</InputLabel>
                <Select
                  value={searchBy || ''}
                  onChange={($event) => handelFilter($event.target.value)}
                >
                  {seachBy.map((item, index) => (
                    <MenuItem value={item.name}>{item.name}</MenuItem>
                  ))}
                </Select>

              </FormControl> */}
              </Grid>
              <Grid item xs={3}>
                {specificSelect &&
                  <Autocomplete
                    id="combo-box-demo"
                    options={specificSelect || []}
                    // defaultValue={[stores[3]]}
                    getOptionLabel={option => option.name || option.shortId || option._id}
                    style={{ marginTop: '10%' }}
                    // disableUnderline={true}
                    // variant="outlined"
                    onChange={(e, newValue) => {
                      // console.log('chnage',e.target.value);
                      // console.log('chnage',newValue?.name);
                      newValue?.name !== '' && newValue?.name !== undefined && searchUsing(newValue?.name)
                      newValue?.shortId !== '' && newValue?.shortId !== undefined && searchUsing(newValue?.shortId)
                    }}
                    renderInput={(specificSelect) => <TextField {...specificSelect} label="find one" variant="outlined"
                      // onChange={(e)=>setText(e.target.value)}
                      onChange={(e) => e.target.value !== '' && searchUsing(e.target.value)}

                    />
                    }
                  />}
              </Grid>

            </Grid>



            {/* <TextField onChange={(e)=>searchUsing(e.target.value)}/> */}


          </CardBody>
        </Card>
      </GridContainer>
      <LoadScript
        id="script-loader"
        googleMapsApiKey={googleMapsApiKey}
        language="en"
        region="ma"
      >
        <GoogleMap
          mapContainerClassName="map"
          center={
            // selectedCity ? { lat: selectedCity.cityLocations[0][1], lng: selectedCity.cityLocations[0][0] } : { lat: 33.589886, lng: -7.603869 }
            path && path
            // : { lat: 33.589886, lng: -7.603869 }
          }
          zoom={zoom}
          version="weekly"
          on
        // onDblClick={ondblclick}
        >
          <Polygon
            path={path}
            options={{
              strokeWeight: 2,
              fillOpacity: 0
            }}
          />
          {!filter ?
            specificDeliveryMan.length && specificDeliveryMan.map(delivery => (
              <Marker
                key={delivery._id}
                icon={{
                  url: orders.find(e => e.deliveryMan?._id == delivery._id) ? DeliveryManIcon : DeliveryManWithoutOrderIcon,
                  // anchor: new google.maps.Point(17, 46),
                  scaledSize: new google.maps.Size(50, 50)

                }}
                position={{ lat: parseFloat(delivery.location ? delivery.location[0] : ''), lng: parseFloat(delivery.location ? delivery.location[1] : "") }}
                onMouseOver={() => drawLine(delivery?._id, destinationTypes.deliveryMan)}
                onMouseOut={() => setPolylineDrawer([])}
                onClick={() => setInfoForInfoBox(delivery?._id, destinationTypes.deliveryMan)}
                onDblClick={() => setPolylineForSpecificOrder([])}
              />
            )) :
            oneDeliveryMan && <Marker
              key={oneDeliveryMan._id}
              icon={{
                url: orders.find(e => e.deliveryMan?._id == oneDeliveryMan._id) ? DeliveryManIcon : DeliveryManWithoutOrderIcon,
                // anchor: new google.maps.Point(17, 46),
                scaledSize: new google.maps.Size(50, 50)

              }}
              position={{ lat: parseFloat(oneDeliveryMan.location ? oneDeliveryMan.location[0] : ''), lng: parseFloat(oneDeliveryMan.location ? oneDeliveryMan.location[1] : "") }}
              onMouseOver={() => drawLine(oneDeliveryMan?._id, destinationTypes.deliveryMan)}
              onMouseOut={() => setPolylineDrawer([])}
              onClick={() => setInfoForInfoBox(oneDeliveryMan?._id, destinationTypes.deliveryMan)}
              onDblClick={() => setPolylineForSpecificOrder([])}
            />

          }
          {/* {stores.length && stores.map(store => (
            <Marker
              key={store._id}
              icon={{
                url: StoreIcon,
                // anchor: new google.maps.Point(17, 46),
                scaledSize: new google.maps.Size(50, 50)

              }}
              position={{ lat: parseFloat(store.location ? store.location[0] : ''), lng: parseFloat(store.location ? store.location[1] : "") }}
              onMouseOver={() => drawLine(store?._id, destinationTypes.store)}
              onMouseOut={() => setPolylineDrawer([])}
              onClick={() => setInfoForInfoBox(store?._id, destinationTypes.store)}
            />
          ))} */}
          {orders && orders.length && orders.map((order, index) => (
            <>
              {/* {order.deliveryMan && <Marker
                icon={{
                  url: DeliveryManIcon,
                  // anchor: new google.maps.Point(17, 46),
                  scaledSize: new google.maps.Size(50, 50)
                }}

                key={order.deliveryMan._id}
                position={{ lat: parseFloat(order.deliveryMan.location[0]), lng: parseFloat(order.deliveryMan.location[1]) }}
                onMouseOver={() => drawLine(order.deliveryMan._id, destinationTypes.deliveryMan)}
                onMouseOut={() => setPolylineDrawer([])}
              />} */}
              {!filter ?
                <Marker
                  key={order.store._id}
                  icon={{
                    url: StoreIcon,
                    // anchor: new google.maps.Point(17, 46),
                    scaledSize: new google.maps.Size(35, 35)

                  }}
                  position={{ lat: parseFloat(order.store.location[0]), lng: parseFloat(order.store.location[1]) }}
                  onMouseOver={() => drawLine(order.store?._id, destinationTypes.store)}
                  onMouseOut={() => setPolylineDrawer([])}
                  onClick={() => setInfoForInfoBox(order.store?._id, destinationTypes.store)}
                  onDblClick={() => setPolylineForSpecificOrder([])}
                />
                :
                Object.keys(oneStore).length > 0 && <Marker
                  key={oneStore._id}
                  icon={{
                    url: StoreIcon,
                    // anchor: new google.maps.Point(17, 46),
                    scaledSize: new google.maps.Size(35, 35)

                  }}
                  position={{ lat: parseFloat(oneStore?.location[0]), lng: parseFloat(oneStore?.location[1]) }}
                  onMouseOver={() => drawLine(oneStore?._id, destinationTypes.store)}
                  onMouseOut={() => setPolylineDrawer([])}
                  onClick={() => setInfoForInfoBox(oneStore?._id, destinationTypes.store)}
                  onDblClick={() => setPolylineForSpecificOrder([])}
                />
              }
              {!filter ?
                (order.payment.serviceType == 'Stores' &&
                  <Marker
                    key={order.customer._id}
                    icon={{
                      url: ClientIcon,
                      // anchor: new google.maps.Point(17, 46),
                      scaledSize: new google.maps.Size(50, 50)
                    }}
                    position={{ lat: parseFloat(order.payment.destinationAddress.location[0]), lng: parseFloat(order.payment.destinationAddress.location[1]) }}
                    onMouseOver={() => drawLine(order.customer?._id, destinationTypes.client)}
                    onMouseOut={() => setPolylineDrawer([])}
                    onClick={() => setInfoForInfoBox(order.customer?._id, destinationTypes.client)}
                    onDblClick={() => setPolylineForSpecificOrder([])}

                  />)
                : Object.keys(oneClient).length > 0 && <Marker
                  key={oneClient._id}
                  icon={{
                    url: StoreIcon,
                    // anchor: new google.maps.Point(17, 46),
                    scaledSize: new google.maps.Size(35, 35)

                  }}
                  position={{ lat: parseFloat(oneClient?.location[0]), lng: parseFloat(oneClient?.location[1]) }}
                  onMouseOver={() => drawLine(oneClient?._id, destinationTypes.client)}
                  onMouseOut={() => setPolylineDrawer([])}
                  onClick={() => setInfoForInfoBox(oneClient?._id, destinationTypes.client)}
                  onDblClick={() => setPolylineForSpecificOrder([])}
                />
              }

            </>
          ))
          }
          {polylineDrawer.length && polylineDrawer.map(line => (
            // console.log('line',line)
            <Polyline
              path={line.pathCoordinates}
              geodesic={true}
              options={{
                strokeColor: line.color,
                strokeOpacity: 1,
                strokeWeight: 2,
              }}
            />
          ))
          }
          {polylineForSpecificOrder.length && polylineForSpecificOrder.map(line => (
            // console.log('line',line)
            <Polyline
              path={line.pathCoordinates}
              geodesic={true}
              options={{
                strokeColor: line.color,
                strokeOpacity: 1,
                strokeWeight: 2,
              }}
            />
          ))
          }
          {Object.keys(infoBoxData).length !== 0 && infoBoxData.location && infoBoxData.location.length &&
            <InfoBox
              position={{ lat: parseFloat(infoBoxData.location[0]), lng: parseFloat(infoBoxData.location[1]) }}
            // options={{ closeBoxURL: '', enableEventPropagation: true }}

            >
              <div style={{ backgroundColor: 'white', opacity: 1, padding: 12, borderRadius: "10px" }} onClick={() => setInfoBoxData({})}>
                <div style={{ fontSize: 16, fontColor: `#08233B`, }}>
                  {infoBoxData.name}<br />
                  {infoBoxData.phone}<br />
                  {infoBoxData.orders && <span>orders : {infoBoxData.orders}<br /></span>}
                  {infoBoxData.balance <= 0 && <span>balance : {JSON.stringify(infoBoxData.balance)} dh</span>}

                </div>
              </div>

            </InfoBox  >}
          {/* filter order  */}
          {filter && Object.keys(oneOrder).length &&
            <>
              <Marker
                key={oneOrder.store?._id}
                icon={{
                  url: StoreIcon,
                  // url: orders.find(e => e.deliveryMan?._id == delivery._id) ? DeliveryManIcon : DeliveryManWithoutOrderIcon,
                  // anchor: new google.maps.Point(17, 46),
                  scaledSize: new google.maps.Size(35, 35)

                }}
                position={{ lat: parseFloat(oneOrder.store.location ? oneOrder.store.location[0] : ''), lng: parseFloat(oneOrder.store.location ? oneOrder.store.location[1] : "") }}
                // onMouseOver={() => drawLine(oneOrder.store?._id, destinationTypes.store)}
                // onMouseOut={() => setPolylineDrawer([])}
                onClick={() => setInfoForInfoBox(oneOrder?.store?._id, destinationTypes.store)}
              // onDblClick={() => setPolylineForSpecificOrder([])}
              />
              {oneOrder.deliveryMan && <Marker
                key={oneOrder.deliveryMan?._id}
                icon={{
                  // url: StoreIcon,
                  url: DeliveryManIcon,
                  // anchor: new google.maps.Point(17, 46),
                  scaledSize: new google.maps.Size(50, 50)

                }}
                position={{ lat: parseFloat(oneOrder.deliveryMan.location ? oneOrder.deliveryMan.location[0] : ''), lng: parseFloat(oneOrder.deliveryMan.location ? oneOrder.deliveryMan.location[1] : "") }}
                // onMouseOver={() => drawLine(oneOrder.deliveryMan?._id, destinationTypes.deliveryMan)}
                // onMouseOut={() => setPolylineDrawer([])}
                onClick={() => setInfoForInfoBox(oneOrder.deliveryMan?._id, destinationTypes.deliveryMan)}
              // onDblClick={() => setPolylineForSpecificOrder([])}
              />}
              {oneOrder.customer && <Marker
                key={oneOrder.customer?._id}
                icon={{
                  // url: StoreIcon,
                  url: ClientIcon,
                  // anchor: new google.maps.Point(17, 46),
                  scaledSize: new google.maps.Size(50, 50)

                }}
                position={polylineForSpecificOrder[0].pathCoordinates[2] ? polylineForSpecificOrder[0].pathCoordinates[2] : ''}
                // position={{ lat: parseFloat(polylineDrawer.pathCoordinates[2] ? polylineDrawer.pathCoordinates[2][0] : ''), lng: parseFloat(polylineForSpecificOrder ?  polylineDrawer.pathCoordinates[2][1] : "") }}
                // onMouseOver={() => drawLine(oneOrder.customer?._id, destinationTypes.deliveryMan)}
                // onMouseOut={() => setPolylineDrawer([])}
                onClick={() => setInfoForInfoBox(oneOrder?.customer?._id, destinationTypes.client)}
              // onDblClick={() => setPolylineForSpecificOrder([])}
              />}
            </>
          }
        </GoogleMap>
      </LoadScript>
    </div>
  );
}
