import React, { useState, useEffect } from 'react';
import { useAlert } from 'react-alert';
import DataTableContainerComponent from '../../../components/DataTableSortingContainer.component';
import {
  getCategory,
  updateCategory,
  getCategoryList,
} from '../services/serverAPI.service';
import CreateCategory from '../components/CreateCategory';
import SwitchComponent from '../../../components/Switch.component';
import DeleteCategory from '../components/DeleteCategory';
import EditeCategoryComponent from '../components/EditeCategory';
import { getFlags } from '../../delivery-info/services/serverApi.service';
import ModalComponent from '../../../components/Modal.component';
import AddFlagComponent from '../components/addFlag.component';
import { addFlagSrc } from '../mock';

export default function Category() {
  const alert = useAlert();
  const id = localStorage.getItem('storeId');
  const [flag, setFlag] = useState([]);
  const [productCategory, setProductCategory] = useState([]);
  const [product, setProduct] = useState([]);
  const [item, setItem] = useState([]);
  const [IndexItem, setIndexItem] = useState(-1);
  const [resultsData, setResultsData] = useState({
    total: 0,
    items: [],
    tableItems: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState('');

  const pages = [100, 250, 500];
  let numberOfRows = [10, 50, 100];
  const getProductCategory = () => {
    getCategoryList(id, false, 0)
      .then(({ data }) => setProductCategory(data))
      .catch((err) => console.log(err));
  };
  const handleOpenModal = (item, index, type) => {
    setItem(item);
    setIndexItem(index);
    setOpenModal(type);
  };
  const closeModal = () => {
    setOpenModal('');
  };
  const showCategory = (item, index) => {
    return {
      ...item,
      name: item.name || '--',
      description: item.description ? item.description : '--',
      inStock: (
        <SwitchComponent
          onChange={(value) => updateStocks(value, item)}
          value="checked"
          checked={item.inStock}
          label="InStock"
        />
      ),
      imageUrl: item.imageUrl ? (
        <img src={item.imageUrl} alt="" border="3" height="50" width="60" />
      ) : (
        '--'
      ),
      productCategoryIdCell: productCategory.find(
        (x) => x._id == item.productCategoryId,
      )?.name,
      flagCell: (
        <div
          style={{ cursor: 'pointer', textAlign: 'center' }}
          onClick={() => {
            handleOpenModal(item, index, 'flag');
          }}
        >
          {item?.flag || '--'}
        </div>
      ),
    };
  };
  const loadCategoryList = () => {
    setIsLoading(true);
    getCategory(id)
      .then(({ data }) => {
        setProduct(data);
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setIsLoading(false);
      });
  };
  const featchFalg = () => {
    getFlags(0, 0, false).then(({ data }) => {
      setFlag(data);
    });
  };
  const updateStocks = (value, item) => {
    (item.inStock = value),
      updateCategory(item)
        .then(() => {
          alert.show('stock updated', {
            type: 'success',
          });
          loadCategoryList();
        })
        .catch((error) => {
          alert.show(error.message, {
            type: 'error',
          });
        });
  };
  useEffect(() => {
    getProductCategory();
    featchFalg();
  }, []);

  useEffect(() => {
    productCategory && loadCategoryList();
  }, [productCategory]);
  useEffect(() => {
    product &&
      setResultsData({
        items: (product || []).map((item, index) => {
          return showCategory(item, index);
        }),
      });
  }, [JSON.stringify(product)]);
  return (
    <>
      <DataTableContainerComponent
        numberOfRowsProps={numberOfRows}
        resultsData={resultsData}
        initData={resultsData.items}
        // onPaginate={handlePaginate}
        isPaginate={false}
        columns={[
          {
            Header: 'Name',
            accessor: 'name',
          },
          {
            Header: 'Description',
            accessor: 'description',
          },
          {
            Header: 'inStock',
            accessor: 'inStock',
          },
          {
            Header: 'Image',
            accessor: 'imageUrl',
          },
          {
            Header: 'product Category',
            accessor: 'productCategoryIdCell',
          },
          {
            Header: 'flag',
            accessor: 'flagCell',
          },
          {
            Header: 'actions',
            accessor: 'actions',
          },
        ]}
        title="Category List"
        isLoading={isLoading}
        loadItemsList={loadCategoryList}
        CreateItemFormComponent={CreateCategory}
        actionButtons={{
          edit: {
            color: 'success',
            type: 'edit',
            component: EditeCategoryComponent,
          },
          delete: {
            color: 'danger',
            type: 'delete',
            component: DeleteCategory,
          },
        }}
      />
      {openModal && openModal === 'flag' && (
        <ModalComponent
          maxWidth={'sm'}
          close={closeModal}
          visible={openModal === 'flag'}
          title="flag"
        >
          <AddFlagComponent
            result={item}
            // row={item}
            setState={setProduct}
            state={product}
            close={closeModal}
            index={IndexItem}
            flags={flag}
            src={addFlagSrc.product}
          />
        </ModalComponent>
      )}
    </>
  );
}
