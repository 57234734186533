import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Actions
import {
  getPartnerOrderRequestV2Action,
  initialPartnerOrderV2Action,
  deliverieStatusChangeV2Action,
  newDeliverieV2Action,
  completedDeliverieV2Action,
  cancelDeliverieV2Action,
  AssignDeliverieV2Action,
  updatedDeliverieV2Action,
  changeDeliveryAlarmV2Action,
  changeDeliveryManLocationV2Action,
} from '../redux/actions/order.actions';

// Selector
import {
  getPartnerDeliveriesDataState,
  filterSelector,
} from '../redux/selectors';
import { getUserDataState } from '../../auth/redux/selectors';

import DataTableSortingContainer from '../../../components/DataTableSortingContainer.component';
import ModalComponentsDelivries from '../components/Modal.components.delivries';
import DelivriesFilterComponent from '../components/Delivries.filter.component';
import DeliveryMenInfoComponent from '../components/DeliveryMen.info.component';
import AlarmsInfoComponenet from '../components/Alarms.info.componenet';
import DispatchingInfoComponenet from '../components/Dispatching.info.componenet';

// mock
import { columnsV2, columnsDispatchedV2 } from '../mock';
// api
import {
  socketConnectPertner,
  socketConnectPertnerGps,
  socketConnect,
} from '../services/server.api.service';

export default function CurrentPartnerOrdersPage() {
  const dispatch = useDispatch();
  const { deliveries, loading, error, deliveriesChangeCounter } = useSelector(
    getPartnerDeliveriesDataState,
  );
  const [filterBy, setFilterBy] = useState(null);
  const [alertPosition, setAlertPosition] = useState(null);
  const [selectedAlarm, setSelectedAlarm] = useState({});
  const [selectedDispatchingStatus, setSelectedDispatchingStatus] = useState(
    {},
  );
  const [selectedOrders, setSelectedOrders] = useState({});

  const {
    selectedCities,
    filterChangeCounter,
    filterWarehouseChangeCounter,
    selectedWarehouse,
  } = useSelector(filterSelector);
  const userData = useSelector(getUserDataState);
  const [resultsData, setResultsData] = useState({
    total: 0,
    items: [],
  });
  const [socket, setSocket] = useState(null);
  const [socketGPS, setSocketGPS] = useState(null);
  useEffect(() => {
    dispatch(initialPartnerOrderV2Action());
    selectedCities &&
      selectedCities.length > 0 &&
      dispatch(
        getPartnerOrderRequestV2Action(selectedCities, selectedWarehouse),
      );
  }, [filterChangeCounter, filterWarehouseChangeCounter]);
  useEffect(() => {
    if (!socket) {
      // setSocket(socketConnectPertner(userData.accessToken)); //old socket partner GPS + step
      setSocket(socketConnect(userData.accessToken)); // socket etapes Food + partner
    }
    if (socket) {
      socket.emit('JOIN_ROOM', 'ADMIN_DISPATCHING_PARTNER');
    }
  }, [selectedCities]);
  useEffect(() => {
    // if (deliveries) {
    if (!socketGPS) {
      // setSocket(socketConnectPertner(userData.accessToken)); //old socket partner GPS + step
      setSocketGPS(socketConnectPertnerGps(userData.accessToken)); // socket etapes Food + partner
    }
    if (socketGPS) {
      socketGPS.emit('JOIN_ROOM', 'ADMIN_DISPATCHING');
    }
    // }
  }, [selectedCities, deliveries]);
  const socketEvents = () => {
    socket.on('DELIVERY_STATUS_CHANGED', function (data) {
      if (
        (selectedCities.includes('all') ||
          selectedCities.includes(data?.cityId)) &&
        (selectedWarehouse.length > 0
          ? selectedWarehouse.includes(data?.pickupAddress.libeleMagasin)
          : true)
      ) {
        // console.log(data.shortId);
        dispatch(deliverieStatusChangeV2Action(data));
      }
    });
    socket.on('DELIVERY_CREATED', function (data) {
      if (
        (selectedCities.includes('all') ||
          selectedCities.includes(data?.cityId)) &&
        (selectedWarehouse.length > 0
          ? selectedWarehouse.includes(data?.pickupAddress.libeleMagasin)
          : true)
      )
        dispatch(newDeliverieV2Action(data));
    });
    socket.on('DELIVERY_DISPATCHED_TO_DELIVERYMAN', function (data) {
      if (
        (selectedCities.includes('all') ||
          selectedCities.includes(data?.cityId)) &&
        (selectedWarehouse.length > 0
          ? selectedWarehouse.includes(data?.pickupAddress.libeleMagasin)
          : true)
      )
        dispatch(AssignDeliverieV2Action(data));
    });
    socket.on('DELIVERY_ASSIGNED_TO_DELIVERYMAN', function (data) {
      if (
        (selectedCities.includes('all') ||
          selectedCities.includes(data?.cityId)) &&
        (selectedWarehouse.length > 0
          ? selectedWarehouse.includes(data?.pickupAddress.libeleMagasin)
          : true)
      )
        dispatch(AssignDeliverieV2Action(data));
    });
    socket.on('DELIVERY_COMPLETED', function (data) {
      if (
        (selectedCities.includes('all') ||
          selectedCities.includes(data?.cityId)) &&
        (selectedWarehouse.length > 0
          ? selectedWarehouse.includes(data?.pickupAddress.libeleMagasin)
          : true)
      )
        dispatch(
          completedDeliverieV2Action(
            data._id,
            data?.destinationAddress?.contactPhone,
          ),
        );
    });
    socket.on('DELIVERY_CANCELLED', function (data) {
      if (
        (selectedCities.includes('all') ||
          selectedCities.includes(data?.cityId)) &&
        (selectedWarehouse.length > 0
          ? selectedWarehouse.includes(data?.pickupAddress.libeleMagasin)
          : true)
      )
        dispatch(
          cancelDeliverieV2Action(
            data._id,
            data?.destinationAddress?.contactPhone,
          ),
        );
    });
    socket.on('DELIVERY_UPDATED_BY_ORGANISATION', function (data) {
      if (
        (selectedCities.includes('all') ||
          selectedCities.includes(data?.cityId)) &&
        (selectedWarehouse.length > 0
          ? selectedWarehouse.includes(data?.pickupAddress.libeleMagasin)
          : true)
      )
        dispatch(updatedDeliverieV2Action(data));
    });
    // socket.on('ALARM_UPDATED', async function (data) {
    //   if (
    //     (selectedCities.includes('all') ||
    //       selectedCities.includes(data?.cityId)) &&
    //     (selectedWarehouse.length > 0
    //       ? selectedWarehouse.includes(data?.pickupAddress.libeleMagasin)
    //       : true)
    //   ) {
    //     // dispatch(changeOrderStatusAction(data));
    //     // console.log('alarm socket', data);
    //     dispatch(changeDeliveryAlarmAction(data._id, data.alarms));
    //   }
    //   // console.log('ALARM_UPDATED', data);
    //   if (data.name === 'GET_POSITION') {
    //     if (!alertPosition) {
    //       setAlertPosition([data].filter((e) => e.visible));
    //     } else {
    //       let dd = alertPosition;
    //       const index = await dd.findIndex(
    //         (e) => e.deliveryManId + '' === data.deliveryManId + '',
    //       );
    //       if (index !== -1) {
    //         dd[index] = data;
    //         setAlertPosition([...dd].filter((e) => e.visible));
    //       } else {
    //         dd.push(data);
    //         setAlertPosition([...dd].filter((e) => e.visible));
    //       }
    //     }
    //   }
    // });
  };
  const socketGPSEvents = () => {
    socketGPS.on('DELIVERYMAN_LOCATION_CHANGED', function (data) {
      if (
        selectedCities.includes('all') ||
        selectedCities.includes(data?.cityId)
      ) {
        dispatch(
          changeDeliveryManLocationV2Action(data.deliveryManId, data.location),
        );
      }
    });
  };
  useEffect(() => {
    if (socket) {
      socket.removeAllListeners();
      socketEvents();
    }
  }, [selectedCities, selectedWarehouse]);
  useEffect(() => {
    if (socketGPS) {
      socketGPS.removeAllListeners();
      socketGPSEvents();
    }
  }, [selectedCities, selectedWarehouse]);

  return (
    <div>
      <DelivriesFilterComponent />
      <AlarmsInfoComponenet />
      <DispatchingInfoComponenet
        setSelectedDispatchingStatus={setSelectedDispatchingStatus}
        selectedDispatchingStatus={selectedDispatchingStatus}
        filterBy={filterBy}
        setFilterBy={setFilterBy}
      />
      {socket && (
        <DeliveryMenInfoComponent
          setSelectedOrders={setSelectedOrders}
          selectedOrders={selectedOrders}
          filterBy={filterBy}
          setFilterBy={setFilterBy}
          alarm={alertPosition}
        />
      )}

      <div style={{ marginTop: -40 }}>
        <DataTableSortingContainer
          resultsData={([...deliveries] || []).filter(
            (x) => x.dispatchingStatus !== 'DISPATCHED',
          )}
          initData={([...deliveries] || []).filter(
            (x) => x.dispatchingStatus !== 'DISPATCHED',
          )}
          columns={columnsV2}
          title={<strong>Dispatching service</strong>}
        />
        <DataTableSortingContainer
          resultsData={([...deliveries] || []).filter(
            (x) => x.dispatchingStatus === 'DISPATCHED',
          )}
          initData={([...deliveries] || []).filter(
            (x) => x.dispatchingStatus === 'DISPATCHED',
          )}
          columns={columnsDispatchedV2}
          title={<strong>Livreur service</strong>}
        />
      </div>
      <ModalComponentsDelivries />
    </div>
  );
}
