import React from 'react';
// material-ui components
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
// @material-ui/icons
import Close from '@material-ui/icons/Close';
// core components
import Button from './material-dashboard-pro-react/CustomButtons/Button';

import styles from '../assets/jss/material-dashboard-pro-react/modalStyle';

const useStyles = makeStyles(styles);

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

export default function ModalComponent({
  visible,
  close,
  title,
  maxWidth,
  actions,
  children,
  fullHeight,
  ...props
}) {
  const classes = useStyles();
  const stl = {
    dialogPaper: {
      height: '90% !important',
      height: '90% !important',
      color:'red'
  },
  }
  const useStyle = makeStyles({
    dialogPaper: {
        height : '90%'
    },
});
const classes1 = useStyle();
  return (
    <Dialog
      classes={{
        root: classes.center,
        paper: classes.modal,
      }}
      PaperProps={{...props.PaperProps}}
    
      open={visible}
      transition={Transition}
      keepMounted
      onClose={close}
      fullWidth
      aria-labelledby="modal-slide-title"
      aria-describedby="modal-slide-description"
      scroll="body"
      maxWidth={maxWidth || 'md'}
      classes={fullHeight&&{ paper : classes1.dialogPaper}}
      {...props}
      >
      <DialogTitle
        id="classic-modal-slide-title"
        disableTypography
        className={classes.modalHeader}
      >
        <Button
          justIcon
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          color="transparent"
          onClick={close}
        >
          <Close className={classes.modalClose} />
        </Button>
        <h4 className={classes.modalTitle}>{title}</h4>
      </DialogTitle>
      <DialogContent id="modal-slide-description" >
      {/* className={classes.modalBody} */}
        {children}
      </DialogContent>
      {actions && (
        <DialogActions
          className={`${classes.modalFooter} ${classes.modalFooterCenter}`}
        >
          {actions}
        </DialogActions>
      )}
    </Dialog>
  );
}

ModalComponent.propTypes = {
  title: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  maxWidth: PropTypes.any,
  actions: PropTypes.any,
  children: PropTypes.any.isRequired,
};
