import React, { useEffect, useState, useRef } from 'react';
import { getCodePromo, editCodePromo } from '../services/serverApi.customer';
import { getCities } from '../../delivery-info/services/serverApi.service';
import DataTableContainerComponent from '../../../components/DataTableSortingContainer.component';
import ModalComponent from '../../../components/Modal.component';

import EditCodePromoComponent from '../components/Edit.promo.component';
import CustomButton from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import { Box, Grid } from '@material-ui/core';
import { getCustmore } from '../services/serverApi.customer';
import AsyncSelect from 'react-select/async';
import SelectBoxComponent from '../../../components/SelectBox.component';
import { changesMock, kaalixPayHistoryTypes } from '../mock';
import moment from 'moment';
import SwitchComponent from '../../../components/Switch.component';
import { useAlert } from 'react-alert';
import EditIcon from '@material-ui/icons/Edit';

export default function CodePromoPage() {
  const columns = [
    {
      Header: 'Code',
      accessor: 'code',
      customWidth: '80px',
    },
    {
      Header: 'cityId',
      accessor: 'cityCell',
      customWidth: '70px',
    },
    {
      Header: 'Value',
      accessor: 'value',
      customWidth: '70px',
    },
    {
      Header: 'minCartPrice',
      accessor: 'minCartPrice',
      customWidth: '110px',
    },
    {
      Header: 'maxUseByUser',
      accessor: 'maxUseByUser',
      customWidth: '110px',
    },
    {
      Header: 'type',
      accessor: 'type',
      customWidth: '80px',
    },
    {
      Header: 'Enable',
      accessor: 'enableCell',
      customWidth: '115px',
    },
    {
      Header: 'For new User',
      accessor: 'newUserCell',
      customWidth: '110px',
    },
    {
      Header: 'expired At',
      accessor: 'expiredAtCell',
      customWidth: '80px',
    },
    {
      Header: 'Created At',
      accessor: 'createdCell',
      customWidth: '80px',
    },
    {
      Header: 'actions',
      accessor: 'actionsCell',
      customWidth: '70px',
    },
  ];
  const alert = useAlert();
  const inpRef = useRef();
  const [codePromo, setCodePromo] = useState([]);
  const [promo, setPromo] = useState({});
  const [resultsData, setResultsData] = useState({
    total: 0,
    item: [],
  });
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [cities, setCities] = useState([]);
  const [page, setPage] = useState(0);
  let numberOfRows = [100, 500, 1000];
  const [rowsPerPage, setRowsPerPage] = useState(numberOfRows[0]);
  const [openModal, setOpenModal] = useState('');
  const [indexForSelectedItem, setIndexForSelectedItem] = useState(-1);
  const handleOpenModal = (item, index, type) => {
    // console.log('type', type, index, item);
    setPromo(item);
    setIndexForSelectedItem(index);
    setOpenModal(type);
  };
  const closeModal = () => {
    setOpenModal('');
  };
  const editFromSwitch = (key, value, item, index) => {
    item[key] = value;
    editCodePromo(item?._id, item)
      .then(({ data }) => {
        alert.show(data.message, {
          type: 'success',
        });
        const tempResultData = [...codePromo];
        tempResultData[index] = data.promo;
        setCodePromo(tempResultData);
      })
      .catch((error) => console.log(error));
  };
  const prepareShowDataInTable = (item, index) => {
    return {
      ...item,
      cityCell:
        (cities || []).find((x) => x._id == item.cityId)?.name || item?.cityId,
      createdCell:
        item.createdAt && moment(item.createdAt).format('DD/MM hh:mm'),
      expiredAtCell:
        item.expiredAt && moment(item.expiredAt).format('DD/MM hh:mm'),
      enableCell: (
        <SwitchComponent
          onChange={(value) => editFromSwitch('enable', value, item, index)}
          value="checked"
          checked={item.enable == 'true' || item.enable == true}
          label="enable"
        />
      ),
      newUserCell: (
        <SwitchComponent
          onChange={(value) => editFromSwitch('newUser', value, item, index)}
          value="checked"
          checked={item.newUser == 'true' || item.newUser == true}
          label="newUser"
        />
      ),
      actionsCell: (
        <div style={{ textAlign: 'center', color: '#4caf50' }}>
          <EditIcon
            onClick={() => handleOpenModal(item, index, 'editPromo')}
            style={{ cursor: 'pointer' }}
          />
        </div>
      ),
    };
  };
  const fetchCodePromoFromApi = (skip, limit, searchTxt) => {
    closeModal();
    setIsLoading(true);
    getCodePromo(skip, limit, searchTxt)
      .then(({ data }) => {
        setTotal(data.total);
        setCodePromo(data.Promo);
      })
      .catch((error) =>
        setResultsData({
          total: 0,
          items: [],
          tableItems: [],
        }),
      )
      .finally(() => {
        setIsLoading(false);
      });
  };
  const handlePaginate = (val) => {
    setResultsData({
      items: [],
      tableItems: [],
    });
    fetchCodePromoFromApi(val.pageSelect, val.numberOfRows, searchText);
    setPage(val.pageSelect);
    setRowsPerPage(val.numberOfRows);
  };
  const SearchComponent = () => {
    return (
      <Grid container alignItems="center">
        <Grid item xs={8}>
          <input
            type="text"
            ref={inpRef}
            placeholder="code xxx"
            style={{
              border: 'none',
              borderBottom: '1px solid black',
            }}
            defaultValue={searchText}
          />
        </Grid>
        <Grid item xs={4} container justify="flex-end">
          <Box textAlign="left">
            <CustomButton
              round
              type="submit"
              color="success"
              size="sm"
              loaderColor="white"
              onClick={() => {
                setSearchText(inpRef.current.value);
                page > 0 && setPage(0);
                fetchCodePromoFromApi(
                  page > 0 ? 0 : page,
                  20,
                  inpRef.current.value,
                );
              }}
            >
              find
            </CustomButton>
          </Box>
          <Box textAlign="left">
            <CustomButton
              round
              type="submit"
              color="google"
              size="sm"
              loaderColor="white"
              onClick={() => {
                setSearchText('');
                page > 0 && setPage(0);
                fetchCodePromoFromApi(page > 0 ? 0 : page, 20, '');
              }}
            >
              clear
            </CustomButton>
          </Box>
        </Grid>
      </Grid>
    );
  };
  useEffect(() => {
    getCities()
      .then(({ data }) => setCities(data))
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    codePromo &&
      codePromo.length > 0 &&
      setResultsData({
        items: codePromo.map((item, index) => {
          return prepareShowDataInTable(item, index);
        }),
      });
  }, [JSON.stringify(codePromo), cities]);
  return (
    <div>
      <DataTableContainerComponent
        total={total}
        page={page + 1}
        resultsData={resultsData}
        initData={resultsData.items || []}
        columns={columns}
        title="code promo List"
        isLoading={isLoading}
        loadItemsList={fetchCodePromoFromApi}
        onPaginate={handlePaginate}
        isPaginate={true}
        searchInp={true}
        SearchComponent={SearchComponent}
        CreateItemFormComponent={EditCodePromoComponent}
        props={{
          cities: cities,
          codePromo: codePromo,
          setCodePromo: setCodePromo,
          // close: closeModal(),
          fetchCodePromoFromApi: fetchCodePromoFromApi,
        }}
      />
      {openModal && openModal == 'editPromo' && (
        <ModalComponent
          maxWidth={false}
          close={closeModal}
          visible={true}
          title="editPromo"
        >
          <EditCodePromoComponent
            promo={promo}
            close={closeModal}
            index={indexForSelectedItem}
            codePromo={codePromo}
            setCodePromo={setCodePromo}
            cities={cities}
          />
        </ModalComponent>
      )}
    </div>
  );
}
