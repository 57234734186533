import React, { useState, useEffect } from 'react';
import { Button, Box } from '@mui/material';
import CustomInput from '../../../components/material-dashboard-pro-react/CustomInput/CustomInput';
import CutomeButton from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import { editPasswordAdmin } from '../../setting/services/serverApi.service';

import * as yup from 'yup';
import { useFormik } from 'formik';

const validationSchema = yup.object({
  userId: yup.string().required(),
  entityObject: yup.object().required(),
  entityType: yup.string().required(),
  password: yup
    .string()
    .min(6, 'password length must be at least 6 characters long')
    .required(),
});

export default function resetPasswordComponenet({ store, close }) {
  // console.log(store);
  const [isLoading, setIsLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      userId: '',
      password: '',
      entityObject: '',
      entityType: '',
    },
    validationSchema,
  });

  const changePassword = () => {
    editPasswordAdmin(formik.values)
      .then(({ data }) => {
        console.log(data);
        close();
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    formik.setValues({
      userId: store._id || '',
      entityObject: { ...store },
      entityType: 'store',
    });
  }, []);
  return (
    <div>
      <CustomInput
        labelText="password"
        formControlProps={{
          fullWidth: true,
        }}
        type="text"
        error={formik.touched.password && Boolean(formik.errors.password)}
        inputProps={{
          value: formik.values.password,
          onBlur: formik.handleBlur('password'),
          onChange: formik.handleChange('password'),
        }}
      />
      <Box textAlign="center">
        <CutomeButton
          round
          type="submit"
          color="success"
          disabled={!formik.dirty || !formik.isValid || isLoading}
          loading={isLoading}
          loaderColor="white"
          onClick={changePassword}
        >
          save
        </CutomeButton>
      </Box>
    </div>
  );
}
