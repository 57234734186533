import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useAlert } from 'react-alert';
import { Collapse, Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import GridContainer from '../../../components/material-dashboard-pro-react/Grid/GridContainer';
import Card from '../../../components/material-dashboard-pro-react/Card/Card';
import CardHeader from '../../../components/material-dashboard-pro-react/Card/CardHeader';
import CardBody from '../../../components/material-dashboard-pro-react/Card/CardBody';
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import SelectBoxComponent from '../../../components/SelectBox.component';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import Checkbox from '@material-ui/core/Checkbox';
import {
  getStoresMapping,
  updateStoresMapping,
} from '../services/serverApi.service';
import { getServices } from '../../delivery-info/services/serverApi.service';

export default function StoreMappingPage() {
  const alert = useAlert();
  const [services, setServices] = useState([]);
  const [storeMapping, setStoreMapping] = useState([]);
  const [storeMappingUsingCity, setStoreMappingUsingCity] = useState([]);
  const [cities, setCities] = useState([]);
  const [serviceId, setServiceId] = useState('');
  const [cityId, setCityId] = useState('');
  const grid = 8;

  const useRowStyles = makeStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
  });
  const classes = useRowStyles();
  const getAllServices = () => {
    getServices(0, 0, false)
      .then(({ data }) => {
        // console.log(data);
        setServices(data);
      })
      .catch(({ error }) => console.log(error));
  };
  const getStoreMapp = (id) => {
    console.log('getStoreMapping');
    getStoresMapping(id)
      .then(({ data }) => {
        console.log(data);
        console.log('store mapping', data?.serviceStoreMappings);
        let cityArray = [];
        data?.serviceStoreMappings &&
          data?.serviceStoreMappings.length > 0 &&
          data?.serviceStoreMappings.map((city) =>
            cityArray.push({ name: city.city.name, _id: city.cityId }),
          );
        setCities(cityArray);
        setStoreMapping(data?.serviceStoreMappings);
      })
      .catch(({ error }) => console.log(error));
  };
  const serviceChange = (id) => {
    // console.log(id,'from func');
    setServiceId(id);
    getStoreMapp(id);
    setStoreMappingUsingCity('');
  };
  const selectCity = (item) => {
    setCityId(item);
    // console.log('item',item)
    console.log('storeMapping', storeMapping);
    storeMapping.map((store) => {
      //   // console.log(store),
      //   // console.log(store.cityId),
      if (store.cityId == item) {
        var tempStoreMappingUsingCity = [];
        store.cityId == item &&
          store?.stores.map((singelStore) => {
            singelStore.cityId == item &&
              tempStoreMappingUsingCity.push(singelStore);
          });
        // console.log('tempStoreMappingUsingCity',tempStoreMappingUsingCity);
        setStoreMappingUsingCity(tempStoreMappingUsingCity);
      }

      // setStoreMappingUsingCity(store?.stores)
    });
  };
  const onEdit = () => {
    let arrayStores = [];
    storeMappingUsingCity &&
      storeMappingUsingCity.length > 0 &&
      storeMappingUsingCity.map((store) => arrayStores.push(store._id)),
      // console.log(arrayStores);
      // console.log(arrayStores.length);
      // console.log(storeMappingUsingCity.length,'aaa');
      updateStoresMapping(serviceId, arrayStores, cityId)
        .then(({ data }) => {
          console.log(data);
          alert.show('store saved', {
            type: 'success',
          });
        })
        .catch((error) => console.log(error));
  };
  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? 'lightgreen' : '',

    // styles we need to apply on draggables
    ...draggableStyle,
  });
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    if (result.type === 'droppableItem') {
      const items = reorder(
        // product.products,
        storeMappingUsingCity,
        result.source.index,
        result.destination.index,
      );
      // console.log(items,'items');
      setStoreMappingUsingCity(items);
    }
  };
  useEffect(() => {
    getAllServices();
  }, []);
  console.log('storeMapping', storeMapping);
  console.log('storeMappingUsingCity', storeMappingUsingCity);
  return (
    <GridContainer>
      <Card>
        <CardHeader>store mapping</CardHeader>
        <CardBody>
          {services && (
            <SelectBoxComponent
              items={services.map((service) => ({
                value: service._id,
                label: service.name,
              }))}
              inputLabel="service list"
              onValueChange={(items) => {
                serviceChange(items);
                //   console.log(items);
                //   setServiceId(items)
                // formik.setFieldValue('cityList', items);
              }}
              value={services.find((item) => item._id == serviceId)?.name}
              //   multiple
            />
          )}
          {cities && (
            <SelectBoxComponent
              items={cities.map((city) => ({
                value: city._id,
                label: city.name,
              }))}
              inputLabel="city list"
              onValueChange={(item) => selectCity(item)}
              value={cities.find((item) => item._id == cityId)?.name}
            />
          )}
          {storeMappingUsingCity && (
            <React.Fragment>
              <Box textAlign="right">
                <Button
                  round
                  type="submit"
                  color="success"
                  // disabled={!formik.dirty || !formik.isValid || isLoading}
                  // loading={isLoading}
                  loaderColor="white"
                  onClick={onEdit}
                >
                  save
                </Button>
              </Box>

              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable" type="droppableItem">
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      // style={getListStyle(snapshot.isDraggingOver)}
                    >
                      {storeMappingUsingCity &&
                        storeMappingUsingCity.length > 0 &&
                        storeMappingUsingCity.map((item, index) => (
                          <Draggable
                            key={item._id}
                            draggableId={item._id}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div>
                                <Box
                                  className={classes.root}
                                  style={{ background: 'grey' }}
                                  boxShadow={3}
                                >
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    style={getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style,
                                    )}
                                  >
                                    <Grid container spacing={3}>
                                      <Grid item xs={10}>
                                        <span style={{ color: 'white' }}>
                                          {item.name}
                                        </span>
                                      </Grid>
                                      <Grid item xs={1}>
                                        <div {...provided.dragHandleProps}>
                                          <DragHandleIcon
                                            style={{ color: 'white' }}
                                          />
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Box>
                                {provided.placeholder}
                              </div>
                            )}
                          </Draggable>
                        ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </React.Fragment>
          )}
        </CardBody>
      </Card>
    </GridContainer>
  );
}
