import React, { useEffect, useState, useRef } from 'react';
import DataTableSortingContainer from '../../../components/DataTableSortingContainer.component';
import OrderDetail from '../components/OrderDetail.component';
import CommentHistoryComponent from '../components/Comment.history.component';
import SelectBoxComponent from '../../../components/SelectBox.component';
import { Box, Grid, TextField } from '@material-ui/core';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DateTimePicker, LocalizationProvider } from '@mui/lab';
import moment from 'moment';
import { store } from '../../../redux/store';
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import CustomButton from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import {
  createNonQuality,
  getHistoryOrders,
  getTemplateNonQualities,
  editKaalixUp,
  createTransferSalaryFromQuality,
} from '../services/serverApi.service';
import ModalComponent from '../../../components/Modal.component';
import {
  DeliveryManStatus,
  DispatchingStatus,
  StoreStatus,
  CustomerStatus,
} from '../../../config/status.config';
import { formatTimeForOrder, calcDuration } from '../../../helpers/index';
import { searchOrdersHistoryType } from '../mock';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useAlert } from 'react-alert';
import { DATA } from '../../../utils/templateData';
import { resetObj } from 'utils/resetObj';
import { useSelector } from 'react-redux';
import { getUserDataState } from 'features/auth/redux/selectors';
import { QUALITY } from '../../../utils/logAudit';
import { logOrder } from '../services/serverApi.service';
import IconButton from '@material-ui/core/IconButton';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import { styled } from '@mui/material/styles';

const validationSchema = yup.object({
  template: yup.object({}).required(),
});

const validationSchemaUP = yup.object({
  amount: yup.number().required(),
});

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip arrow {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

export default function OrderHistoryPage() {
  const inpRef = useRef();
  const alert = useAlert();
  const inpRefForShortId = useRef();
  // console.log(inpRef.current.value);
  // console.log(inpRefForShortId.current.value);
  const [resultsData, setResultsData] = useState({
    total: 0,
    items: [],
  });
  const adminData = useSelector(getUserDataState);
  const [admin, setAdmin] = useState({
    _id: adminData.user._id,
    email: adminData.user.email,
    username: adminData.user.username,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  let numberOfRows = [20, 50, 100];
  const [rowsPerPage, setRowsPerPage] = useState(numberOfRows[0]);
  const [total, setTotal] = useState(0);
  const [orderHistory, setOrderHistory] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchBy, setSearchBy] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [shortId, setShortId] = useState('');
  const [useDate, setUseDate] = useState(false);
  const [displayTotal, setDisplayTotal] = useState(false);
  const [openModal, setOpenModal] = useState('');
  const [templates, setTemplate] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [row, setRow] = useState(null);
  const customColumnStyle = {
    // wordWrap: "break-word",
    maxWidth: '90px',
    minWidth: '90px',
    fontSize: '13px',
  };
  const columns = [
    {
      Header: 'Id Cmd',
      accessor: 'shortId',
    },
    {
      Header: 'Store',
      accessor: 'storeName',
    },
    {
      Header: 'Client',
      accessor: 'clientName',
    },
    {
      Header: 'Provider',
      accessor: 'deliveryManInp',
    },
    {
      Header: 'Ville',
      accessor: 'city.name',
    },

    {
      Header: 'Provider.S',
      accessor: 'deliveryManStatusInp',
    },
    {
      Header: 'Store.S',
      accessor: 'storeStatusInp',
    },
    {
      Header: 'Dispatch.S',
      accessor: 'dispatchingStatusInp',
    },
    {
      Header: 'Customer.S',
      accessor: 'customerStatusInp',
    },
    {
      Header: 'canceledBy',
      accessor: 'canceledByCell',
    },
    {
      Header: 'reason',
      accessor: 'cancelReason',
    },
    {
      Header: 'Validator',
      accessor: 'validator',
    },
    {
      Header: 'comment',
      accessor: 'lastComment',
    },
    {
      Header: 'Created',
      accessor: 'createdAtInp',
    },
    {
      Header: 'CMDtime',
      accessor: 'cmdTime',
    },
    {
      Header: 'timing',
      accessor: 'timing',
    },
    {
      Header: 'Actions',
      accessor: 'actions',
    },
    {
      Header: 'Non Quality',
      accessor: 'nonQuality',
    },
  ];
  const [startIterval, setStartIterval] = useState(0);
  const [endIterval, setEndIterval] = useState(0);
  const timingTextType = ['dispatchingType', 'deliveryManChange'];
  const timingDetail = [
    'dispatchingType',
    'deliveryManChange',
    'validationTime',
    'dispatchingTime_AC_WFA',
    'preparationTime',
    'delivered',
    'deliveryTime',
    'cmdDuration',
    'durationAfterAccept',
    'validation',
    'storeAccepted',
    'SPPT',
    'dispatchingTime_ONHOLD_DISPATCHING',
    'driver_Accepted',
    'driver_ATP',
    'driver_CFD',
    'driver_UI',
    'driver_SD',
    'driver_ATD',
    'driver_delivered',
    'driver_Store',
    'driver_waiting_store',
    'driver_delivery',
    'order_time',
  ];

  const formik = useFormik({
    initialValues: {
      template: {},
    },
    validationSchema,
  });

  const formikUP = useFormik({
    initialValues: {
      amount: '',
      customerId: '',
    },
    validationSchemaUP,
  });

  const handleOpenModal = (item, type) => {
    // console.log(type, index, item)
    setRow(item);
    setOpenModal(type);
  };

  const prepareToShowHistory = (item, index) => {
    return {
      ...item,
      deliveryManStatusInp:
        DeliveryManStatus.find((x) => x.value == item?.deliveryManStatus)
          ?.label || item?.deliveryManStatus,
      storeStatusInp: StoreStatus[item.storeStatus] || item?.storeStatus,
      dispatchingStatusInp:
        DispatchingStatus[item.dispatchingStatus] || item?.dispatchingStatus,
      customerStatusInp:
        CustomerStatus[item.customerStatus] || item?.customerStatus,
      storeName: item?.store?.name,
      clientName: item?.customer?.name,
      deliveryManInp: item?.deliveryMan && item?.deliveryMan.name,
      createdAtInp: item.createdAt && (
        // <ChangeDuration time={item.createdAt}/>
        <div style={{ fontSize: '11px' }}>
          <span>{formatTimeForOrder(item.createdAt).split('(')[0]}</span>
          <span>
            {`(`}
            {formatTimeForOrder(item.createdAt).split('(')[1]}
          </span>
        </div>
      ),
      nonQuality: (
        <div>
          <Button
            size="medium"
            style={
              {
                // color: '#03a9f4 '
              }
            }
            onClick={() => {
              handleOpenModal(item, 'order');
            }}
          >
            Open Modal
          </Button>
        </div>
      ),
      timing: (
        <div>
          <IconButton
            onClick={() => {
              handleOpenModal(item, 'timing');
            }}
          >
            <AccessTimeIcon />
          </IconButton>
        </div>
      ),
      canceledByCell: (
        <div>
          {item?.canceledBy && item?.canceledBy?.by}
          {item?.canceledBy?.admin && '(' + item?.canceledBy?.admin + ')'}
        </div>
      ),
      lastComment: (
        <HtmlTooltip
          title={
            <React.Fragment>
              <CommentHistoryComponent
                close={() => {}}
                admin={admin}
                order={item}
              />
            </React.Fragment>
          }
        >
          {
            item?.comments && item.comments.length ? (
              <span
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  handleOpenModal(item, 'Comment');
                  // setOrderIdForComments(item._id);
                }}
              >
                {' '}
                {item.comments[item.comments.length - 1]?.adminName}
                {' // '}
                {item.comments[item.comments.length - 1]?.comment}{' '}
              </span>
            ) : (
              <span></span>
            )
            // <CommentIcon
            //   style={{ cursor: 'pointer', fontSize: '22px' }}
            //   onClick={() => {
            //     handleModal(item, index, 'Comment');
            //     setOrderIdForComments(item._id);
            //   }}
            // />
          }
        </HtmlTooltip>
      ),
      cmdTime:
        item?.timing?.order_time &&
        Math.floor(item?.timing?.order_time / 60) + ' min',
      // item?.customerStatus === 'DELIVERED' &&
      // moment(new Date()).diff(moment(item.createdAt), 'minutes'),
    };
  };
  const getOrdersCompleted = (
    skip,
    limit,
    paged,
    txt,
    using,
    start,
    end,
    useDate,
    id,
  ) => {
    getHistoryOrders(skip, limit, paged, txt, using, start, end, useDate, id)
      .then(({ data }) => {
        setTotal(data.total);
        setOrderHistory(data.orders);
        useDate && setDisplayTotal(true);
      })
      .catch((error) => console.log(error));
  };
  // get template data :
  const getTemplates = (page, limit, txt) => {
    setIsLoading(true);
    getTemplateNonQualities(page || 0, limit || 20, true, txt)
      .then(({ data }) => {
        console.log(data);
        setTotal(data.total);
        setTemplate(data.templates);
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setIsLoading(false);
      });
  };
  // handle click on modal :
  const handleClick = () => {
    // console.log(row);
    if (formik.values.template.templateType === 'Fixed') {
      // the amount will be the same with no calcul :
      // alert.show(formik.values.template.templateAmount);
      // saving data to non quality :
      resetObj(DATA);
      DATA.admin = admin;
      DATA.orderId = row._id;
      DATA.delivery = {
        _id: row?.deliveryMan?._id || 'System',
        name: row?.deliveryMan?.name || 'System',
      };
      DATA.store = {
        _id: row.store._id,
        name: row.store.name,
      };
      DATA.customer = {
        _id: row.customer._id,
        name: row.customer.name,
      };
      DATA.articlePrice = row.payment.orderPrice;
      DATA.deliveryCosts = row.payment.deliveryPrice;
      DATA.amount =
        formik.values.template.templateDirection === 'Kaalix-IN'
          ? -formik.values.template.templateAmount
          : formik.values.template.templateAmount;
      DATA.nonQualityName =
        formik.values.template.templateDestination === 'Client'
          ? 'Remboursement KaalixUP'
          : 'Regularisation';
      DATA.nonQualityType = formik.values.template.templateType;
      DATA.responsable = formik.values.template.templateResponsable;
      DATA.destination = formik.values.template.templateDestination;
      DATA.deliveryManCommission = row.deliveryMan.commission;
      // handle condition :
      if (formik.values.template.templateDestination === 'Client') {
        // add log audit :
        resetObj(QUALITY);
        QUALITY.targetId = formik.values.template._id;
        QUALITY.admin = admin;
        QUALITY.actionType = 'Quality-Customer';
        QUALITY.target = 'QUALITY';
        QUALITY.reason = formik.values.template.templateName;
        QUALITY.loggerDetails = {
          afterValue:
            formik.values.template.templateDirection === 'Kaalix-IN'
              ? -formik.values.template.templateAmount
              : formik.values.template.templateAmount,
        };
        // console.log(formik.values, row, QUALITY);
        // add on kaalixUP customer :
        editKaalixUp({
          customerId: row.customer._id,
          amount:
            formik.values.template.templateDirection === 'Kaalix-IN'
              ? -formik.values.template.templateAmount
              : formik.values.template.templateAmount,
        })
          .then(({ data }) => {
            console.log(data);
          })
          .catch((error) => {
            console.log(error.message, {
              type: 'error',
            });
          });
        logOrder(QUALITY)
          .then(({ data }) => {
            console.log(data);
          })
          .catch((error) => {
            console.log(error.message, {
              type: 'error',
            });
          });
        // console.log(DATA, row.deliveryMan.commission);
      } else if (formik.values.template.templateDestination === 'Livreur') {
        // add on delivery balance :
        // const reduxState = store.getState();
        const money = {
          amount:
            formik.values.template.templateDirection === 'Kaalix-IN'
              ? -formik.values.template.templateAmount
              : formik.values.template.templateAmount,
          sous_type: formik.values.template.templateName,
          deliveryManId: row?.deliveryMan?._id,
        };
        resetObj(QUALITY);
        QUALITY.targetId = formik.values.template._id;
        QUALITY.admin = admin;
        QUALITY.actionType = 'Quality-Driver';
        QUALITY.target = 'QUALITY';
        QUALITY.reason = formik.values.template.templateName;
        QUALITY.loggerDetails = {
          afterValue:
            formik.values.template.templateDirection === 'Kaalix-IN'
              ? -formik.values.template.templateAmount
              : formik.values.template.templateAmount,
        };
        createTransferSalaryFromQuality(money)
          .then(({ data }) => {
            if (data) {
              console.log(data);
            }
          })
          .catch((error) => {
            console.log(error.message, {
              type: 'error',
            });
          });
        logOrder(QUALITY)
          .then(({ data }) => {
            console.log(data);
          })
          .catch((error) => {
            console.log(error.message, {
              type: 'error',
            });
          });
      }
      // post data to collection :
      createNonQuality(DATA)
        .then(({ data }) => {
          console.log(data);
        })
        .catch((error) => {
          console.log(error.message, {
            type: 'error',
          });
        });
      setOpenModal('');
    } else if (
      formik.values.template.templateType === 'Pourcentage frais livraison'
    ) {
      // some calcul :
      const newAmount =
        formik.values.template.templateDirection === 'Kaalix-IN'
          ? -formik.values.template.templateAmount
          : formik.values.template.templateAmount;
      const point = (row.payment.deliveryPrice * newAmount) / 100;
      // saving data to non quality :
      resetObj(DATA);
      DATA.admin = admin;
      DATA.orderId = row._id;
      DATA.delivery = {
        _id: row?.deliveryMan?._id || 'System',
        name: row?.deliveryMan?.name || 'System',
      };
      DATA.store = {
        _id: row.store._id,
        name: row.store.name,
      };
      DATA.customer = {
        _id: row.customer._id,
        name: row.customer.name,
      };
      DATA.articlePrice = row.payment.orderPrice;
      DATA.deliveryCosts = row.payment.deliveryPrice;
      DATA.amount = point;
      DATA.nonQualityName =
        formik.values.template.templateDestination === 'Client'
          ? 'Remboursement KaalixUP'
          : 'Regularisation';
      DATA.nonQualityType = formik.values.template.templateType;
      DATA.responsable = formik.values.template.templateResponsable;
      DATA.destination = formik.values.template.templateDestination;
      DATA.deliveryManCommission = row.deliveryMan.commission;
      // handle condition :
      if (formik.values.template.templateDestination === 'Client') {
        // log audit :
        resetObj(QUALITY);
        QUALITY.targetId = formik.values.template._id;
        QUALITY.admin = admin;
        QUALITY.actionType = 'Quality-Customer';
        QUALITY.target = 'QUALITY';
        QUALITY.reason = formik.values.template.templateName;
        QUALITY.loggerDetails = {
          afterValue: point,
        };
        // add on kaalixUP customer :
        editKaalixUp({ customerId: row.customer._id, amount: point })
          .then(({ data }) => {
            console.log(data);
          })
          .catch((error) => {
            console.log(error.message, {
              type: 'error',
            });
          });
        logOrder(QUALITY)
          .then(({ data }) => {
            console.log(data);
          })
          .catch((error) => {
            console.log(error.message, {
              type: 'error',
            });
          });
        // console.log(row, DATA);
      } else if (formik.values.template.templateDestination === 'Livreur') {
        // log audit :
        resetObj(QUALITY);
        QUALITY.targetId = formik.values.template._id;
        QUALITY.admin = admin;
        QUALITY.actionType = 'Quality-Driver';
        QUALITY.target = 'QUALITY';
        QUALITY.reason = formik.values.template.templateName;
        QUALITY.loggerDetails = {
          afterValue: point,
        };
        // add on delivery balance :
        // const reduxState = store.getState();
        const money = {
          amount: point,
          sous_type: formik.values.template.templateName,
          deliveryManId: row?.deliveryMan?._id,
        };
        createTransferSalaryFromQuality(money)
          .then(({ data }) => {
            if (data) {
              console.log(data);
            }
          })
          .catch((error) => {
            console.log(error.message, {
              type: 'error',
            });
          });
        logOrder(QUALITY)
          .then(({ data }) => {
            console.log(data);
          })
          .catch((error) => {
            console.log(error.message, {
              type: 'error',
            });
          });
        // console.log(money);
      }
      // post data to collection :
      createNonQuality(DATA)
        .then(({ data }) => {
          console.log(data);
        })
        .catch((error) => {
          console.log(error.message, {
            type: 'error',
          });
        });
      // alert.show(point);
      setOpenModal('');
    } else if (
      formik.values.template.templateType === 'Pourcentage montant des articles'
    ) {
      // some calcul :
      const newAmount =
        formik.values.template.templateDirection === 'Kaalix-IN'
          ? -formik.values.template.templateAmount
          : formik.values.template.templateAmount;
      const point = (row.payment.orderPrice * newAmount) / 100;
      // alert.show(point);
      // saving data to non quality :
      resetObj(DATA);
      DATA.admin = admin;
      DATA.orderId = row._id;
      DATA.delivery = {
        _id: row?.deliveryMan?._id || 'System',
        name: row?.deliveryMan?.name || 'System',
      };
      DATA.store = {
        _id: row.store._id,
        name: row.store.name,
      };
      DATA.customer = {
        _id: row.customer._id,
        name: row.customer.name,
      };
      DATA.articlePrice = row.payment.orderPrice;
      DATA.deliveryCosts = row.payment.deliveryPrice;
      DATA.amount = point;
      DATA.nonQualityName =
        formik.values.template.templateDestination == 'Client'
          ? 'Remboursement KaalixUP'
          : 'Regularisation';
      DATA.nonQualityType = formik.values.template.templateType;
      DATA.responsable = formik.values.template.templateResponsable;
      DATA.destination = formik.values.template.templateDestination;
      DATA.deliveryManCommission = row.deliveryMan.commission;
      // console.log(DATA);
      // handle condition :
      if (formik.values.template.templateDestination === 'Client') {
        // audit log :
        resetObj(QUALITY);
        QUALITY.targetId = formik.values.template._id;
        QUALITY.admin = admin;
        QUALITY.actionType = 'Quality-Customer';
        QUALITY.target = 'QUALITY';
        QUALITY.reason = formik.values.template.templateName;
        QUALITY.loggerDetails = {
          afterValue: point,
        };
        // add on kaalixUP customer :
        editKaalixUp({ customerId: row.customer._id, amount: point })
          .then(({ data }) => {
            console.log(data);
          })
          .catch((error) => {
            console.log(error.message, {
              type: 'error',
            });
          });
        logOrder(QUALITY)
          .then(({ data }) => {
            console.log(data);
          })
          .catch((error) => {
            console.log(error.message, {
              type: 'error',
            });
          });
        // console.log(row, DATA);
      } else if (formik.values.template.templateDestination === 'Livreur') {
        // log audit :
        resetObj(QUALITY);
        QUALITY.targetId = formik.values.template._id;
        QUALITY.admin = admin;
        QUALITY.actionType = 'Quality-Driver';
        QUALITY.target = 'QUALITY';
        QUALITY.reason = formik.values.template.templateName;
        QUALITY.loggerDetails = {
          afterValue: point,
        };
        // add on delivery balance :
        // const reduxState = store.getState();
        const money = {
          amount: point,
          sous_type: formik.values.template.templateName,
          deliveryManId: row?.deliveryMan?._id,
        };
        // console.log(row, money);
        createTransferSalaryFromQuality(money)
          .then(({ data }) => {
            if (data) {
              console.log(data);
            }
          })
          .catch((error) => {
            console.log(error.message, {
              type: 'error',
            });
          });
        logOrder(QUALITY)
          .then(({ data }) => {
            console.log(data);
          })
          .catch((error) => {
            console.log(error.message, {
              type: 'error',
            });
          });
      }
      // post data to collection :
      createNonQuality(DATA)
        .then(({ data }) => {
          console.log(data);
        })
        .catch((error) => {
          console.log(error.message, {
            type: 'error',
          });
        });
      setOpenModal('');
    } else if (
      formik.values.template.templateType === 'Pourcentage commission livreur'
    ) {
      // some calcul :
      const newAmount =
        formik.values.template.templateDirection === 'Kaalix-IN'
          ? -formik.values.template.templateAmount
          : formik.values.template.templateAmount;
      const point = (row.deliveryMan.commission * newAmount) / 100;
      // alert.show(point);
      // saving data to non quality :
      resetObj(DATA);
      DATA.admin = admin;
      DATA.orderId = row._id;
      DATA.delivery = {
        _id: row?.deliveryMan?._id || 'System',
        name: row?.deliveryMan?.name || 'System',
      };
      DATA.store = {
        _id: row.store._id,
        name: row.store.name,
      };
      DATA.customer = {
        _id: row.customer._id,
        name: row.customer.name,
      };
      DATA.articlePrice = row.payment.orderPrice;
      DATA.deliveryCosts = row.payment.deliveryPrice;
      DATA.amount = point;
      DATA.nonQualityName =
        formik.values.template.templateDestination === 'Client'
          ? 'Remboursement KaalixUP'
          : 'Regularisation';
      DATA.nonQualityType = formik.values.template.templateType;
      DATA.responsable = formik.values.template.templateResponsable;
      DATA.destination = formik.values.template.templateDestination;
      DATA.deliveryManCommission = row.deliveryMan.commission;
      // console.log(row, DATA);
      // handle condition :
      if (formik.values.template.templateDestination === 'Client') {
        // log audit :
        resetObj(QUALITY);
        QUALITY.targetId = formik.values.template._id;
        QUALITY.admin = admin;
        QUALITY.actionType = 'Quality-Customer';
        QUALITY.target = 'QUALITY';
        QUALITY.reason = formik.values.template.templateName;
        QUALITY.loggerDetails = {
          afterValue: point,
        };
        // add on kaalixUP customer :
        editKaalixUp({ customerId: row.customer._id, amount: point })
          .then(({ data }) => {
            console.log(data);
          })
          .catch((error) => {
            console.log(error.message, {
              type: 'error',
            });
          });
        logOrder(QUALITY)
          .then(({ data }) => {
            console.log(data);
          })
          .catch((error) => {
            console.log(error.message, {
              type: 'error',
            });
          });
      } else if (formik.values.template.templateDestination === 'Livreur') {
        // log audit :
        resetObj(QUALITY);
        QUALITY.targetId = formik.values.template._id;
        QUALITY.admin = admin;
        QUALITY.actionType = 'Quality-Driver';
        QUALITY.target = 'QUALITY';
        QUALITY.reason = formik.values.template.templateName;
        QUALITY.loggerDetails = {
          afterValue: point,
        };
        // add on delivery balance :
        // const reduxState = store.getState();
        const money = {
          amount: point,
          sous_type: formik.values.template.templateName,
          deliveryManId: row?.deliveryMan?._id,
        };
        createTransferSalaryFromQuality(money)
          .then(({ data }) => {
            if (data) {
              console.log(data);
            }
          })
          .catch((error) => {
            console.log(error.message, {
              type: 'error',
            });
          });
        logOrder(QUALITY)
          .then(({ data }) => {
            console.log(data);
          })
          .catch((error) => {
            console.log(error.message, {
              type: 'error',
            });
          });
      }
      // post data to collection :
      createNonQuality(DATA)
        .then(({ data }) => {
          console.log(data);
        })
        .catch((error) => {
          console.log(error.message, {
            type: 'error',
          });
        });
      setOpenModal('');
    }
  };
  const handlePaginate = (val) => {
    setResultsData({
      items: [],
      tableItems: [],
    });
    getOrdersCompleted(
      val.pageSelect,
      val.numberOfRows,
      true,
      searchText,
      searchBy,
      startDate,
      endDate,
      useDate,
      shortId,
    );
    setPage(val.pageSelect);
    setRowsPerPage(val.numberOfRows);
  };
  const closeModal = () => {
    setOpenModal('');
  };
  const SearchComponent = () => {
    return (
      <Grid container alignItems="center">
        <Grid item xs={4}>
          <input
            type="text"
            ref={inpRef}
            placeholder="search"
            style={{
              border: 'none',
              borderBottom: '1px solid black',
            }}
            defaultValue={searchText}
          />
        </Grid>
        <Grid item xs={4}>
          <input
            type="text"
            ref={inpRefForShortId}
            placeholder="shortId"
            style={{
              border: 'none',
              borderBottom: '1px solid black',
            }}
            defaultValue={shortId}
          />
        </Grid>

        <Grid item xs={4} className="pb-3">
          <SelectBoxComponent
            items={searchOrdersHistoryType.map((type) => ({
              value: type,
              label: type,
            }))}
            inputLabel="search By"
            onValueChange={(items) => {
              setSearchText(inpRef.current.value);
              setSearchBy(items);
            }}
            value={searchBy}
          />
        </Grid>

        <Grid item xs={4} className="mb-3">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              renderInput={(props) => <TextField {...props} />}
              label="Start date"
              ampm={false}
              value={startDate}
              onChange={(newValue) => {
                setStartDate(moment(newValue).format().split('+')[0]);
                !useDate && setUseDate(true);
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={4} className="mb-3">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              renderInput={(props) => <TextField {...props} />}
              label="End Date"
              ampm={false}
              value={endDate}
              onChange={(newValue) => {
                setEndDate(moment(newValue).format().split('+')[0]);
                !useDate && setUseDate(true);
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={4} container justify="flex-end">
          <Box textAlign="left">
            <CustomButton
              round
              type="submit"
              color="success"
              size="sm"
              loaderColor="white"
              onClick={() => {
                setSearchText(inpRef.current.value);
                setShortId(inpRefForShortId.current.value);
                page > 0 && setPage(0);
                getOrdersCompleted(
                  page > 0 ? 0 : page,
                  rowsPerPage,
                  true,
                  inpRef.current.value,
                  searchBy,
                  startDate,
                  endDate,
                  useDate,
                  inpRefForShortId.current.value,
                );
              }}
            >
              find
            </CustomButton>
          </Box>
          <Box textAlign="left">
            <CustomButton
              round
              type="submit"
              color="google"
              size="sm"
              loaderColor="white"
              onClick={() => {
                setSearchText('');
                setSearchBy('');
                setUseDate(false);
                setDisplayTotal(false);
                setDisplayTotal(false);
                setStartDate('');
                setEndDate('');
                setShortId('');
                page > 0 && setPage(0);
                getOrdersCompleted(
                  page > 0 ? 0 : page,
                  rowsPerPage,
                  true,
                  '',
                  '',
                  false,
                  '',
                  false,
                  '',
                );
              }}
            >
              clear
            </CustomButton>
          </Box>
        </Grid>
      </Grid>
    );
  };
  const transTime = (totalSeconds = 0) => {
    let hours = Math.floor(totalSeconds / 3600);
    totalSeconds %= 3600;
    let minutes = Math.floor(totalSeconds / 60);
    let seconds = totalSeconds % 60;

    console.log('hours: ' + hours);
    console.log('minutes: ' + minutes);
    console.log('seconds: ' + seconds);

    // If you want strings with leading zeroes:
    minutes = String(minutes).padStart(2, '0');
    hours = String(hours).padStart(2, '0');
    seconds = String(seconds).padStart(2, '0');
    return hours + ':' + minutes + ':' + seconds;
  };
  useEffect(() => {
    getTemplates();
    orderHistory &&
      orderHistory.length > 0 &&
      setResultsData({
        items: orderHistory.map((item, index) => {
          return prepareToShowHistory(item, index);
        }),
      });
  }, [orderHistory]);
  return (
    <div>
      <DataTableSortingContainer
        resultsData={resultsData}
        total={total}
        initData={resultsData.items}
        columns={columns}
        title={
          <strong>Orders History{displayTotal && ' ( ' + total + ' )'}</strong>
        }
        isLoading={isLoading}
        page={page + 1}
        customColumnStyle={customColumnStyle}
        onPaginate={handlePaginate}
        isPaginate={true}
        searchInp={true}
        SearchComponent={SearchComponent}
        actionButtons={{
          view: {
            color: 'info',
            type: 'view',
            component: OrderDetail,
          },
        }}
      />
      {openModal && (
        <ModalComponent
          maxWidth={'false'}
          close={closeModal}
          visible={openModal === 'order'}
          title="Non Quality"
        >
          <SelectBoxComponent
            items={templates.map((element) => ({
              value: element,
              label: element.templateName,
            }))}
            inputLabel="Non Quality list"
            onValueChange={(items) => {
              // console.log(items);
              formik.setFieldValue('template', items);
            }}
            value={formik.values.template}
          />
          <Box textAlign="center">
            <Button
              round
              type="submit"
              color="success"
              // disabled={!formik.dirty || !formik.isValid || isLoading}
              loading={isLoading}
              loaderColor="white"
              onClick={handleClick}
            >
              Apply
            </Button>
          </Box>
        </ModalComponent>
      )}
      {openModal && (
        <ModalComponent
          close={closeModal}
          visible={openModal === 'timing'}
          title="Timing"
        >
          <div>
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">name</th>
                  <th scope="col">value</th>
                </tr>
              </thead>
              <tbody>
                {timingDetail.map((i, index) => (
                  <tr>
                    <th scope="row">{index + 1}</th>
                    <td>{i}</td>
                    {
                      <td>
                        {row.timing
                          ? timingTextType.includes(i)
                            ? JSON.stringify(row.timing[i + ''])
                            : transTime(row.timing[i + ''])
                          : transTime(0)}
                      </td>
                    }
                    {/*<td>{row.timing[i + ''] ? row.timing[i + ''] : 0}</td>*/}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </ModalComponent>
      )}
      {openModal && (
        <ModalComponent
          maxWidth={'false'}
          close={closeModal}
          visible={openModal === 'Comment'}
          title="comment"
        >
          <CommentHistoryComponent
            close={closeModal}
            admin={admin}
            order={row}
            // order={
            //   (orders || []).find((x) => x._id == orderIdForComments)
            //     ? (orders || []).find((x) => x._id == orderIdForComments)
            //     : (invalidOrders || []).find((x) => x._id == orderIdForComments)
            // }
          />
        </ModalComponent>
      )}
    </div>
  );
}
