import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useAlert } from 'react-alert';

import CustomInput from '../../../components/material-dashboard-pro-react/CustomInput/CustomInput';
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import SelectBoxComponent from '../../../components/SelectBox.component';
import SwitchComponent from '../../../components/Switch.component';
import GridContainer from '../../../components/material-dashboard-pro-react/Grid/GridContainer';
import Card from '../../../components/material-dashboard-pro-react/Card/Card';
import CardHeader from '../../../components/material-dashboard-pro-react/Card/CardHeader';
import CardBody from '../../../components/material-dashboard-pro-react/Card/CardBody';
import { getCities } from '../../delivery-info/services/serverApi.service';
import { editProvider } from '../service/serverAPI.service';

const validationSchema = yup.object({
  name: yup.string().required(),
  email: yup.string().email().required(),
  // password: yup.string().min(6,'password" length must be at least 6 characters long').required(),
  // phone: yup.string().matches(/(?=.*(05|06|07))[0-9]{10}/, 'Invalid phone format').required(),
  // phone: yup.string().required(),
  // phone: yup
  //   .string()
  //   .matches(/^[6||7][0-9]{8}$/, 'Invalid phone format')
  //   .required(),
  deliveryType: yup.string().required(),
  phone: yup
    .string()
    .when('deliveryType', {
      is: 'Food',
      then: yup.string().matches(
        /^[67][0-9]{8}$/,
        // /^0[67][0-9]{8}$/,
        'Invalid phone format for deliveryMan',
      ),
      otherwise: yup
        .string()
        .matches(
          /^0[67][0-9]{8}$/,
          'Invalid phone format for Partner deliveryMan',
        ),
    })
    .required(),
  cityId: yup.string().required(),
  transportation: yup.string(),
  available: yup.string().required(),
  // entityType: yup.string()
});
/**deliveryType: {
      type: {
        type: String,
        default: 'Food',
        enum: ['Food', 'Partners', 'Hybrid'],
      },
    }, */
export default function EditProvider({ data, close, onItemEdited, props }) {
  // console.log(data);
  const { cities, transportation } = props;
  const alert = useAlert();
  const [isLoading, setIsLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      password: '',
      available: '',
      cityId: '',
      deliveryType: 'Food',
      transportation: '',
    },
    validationSchema,
  });
  const setValues = () => {
    data &&
      formik.setValues({
        name: data.name,
        email: data.email,
        phone: data.phone,
        cityId: data.cityId,
        deliveryType: data.deliveryType,
        available: data.available,
        transportation: data.transportation || '',
      });
  };
  const onEdit = () => {
    editProvider(data._id, formik.values)
      .then(({ data }) => {
        onItemEdited();
        alert.show('provider has been updated', {
          type: 'success',
        });
        formik.resetForm();
        close();
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    setValues();
  }, [data]);
  return (
    <div>
      <div>
        <CustomInput
          labelText="User Name"
          formControlProps={{
            fullWidth: true,
          }}
          type="text"
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.errors.name}
          inputProps={{
            value: formik.values.name,
            onBlur: formik.handleBlur('name'),
            onChange: formik.handleChange('name'),
          }}
        />
        <CustomInput
          labelText="Email"
          formControlProps={{
            fullWidth: true,
          }}
          type="text"
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.errors.email}
          inputProps={{
            value: formik.values.email,
            onBlur: formik.handleBlur('email'),
            onChange: formik.handleChange('email'),
          }}
        />
        {/* <CustomInput
                    labelText="password"
                    formControlProps={{
                        fullWidth: true,
                    }}
                    type="password"
                    error={formik.touched.password && Boolean(formik.errors.password)}
                    helperText={formik.errors.password}
                    inputProps={{
                        value: formik.values.password,
                        onBlur: formik.handleBlur('password'),
                        onChange: formik.handleChange('password'),
                    }}
                /> */}
        <CustomInput
          labelText="phone"
          formControlProps={{
            fullWidth: true,
          }}
          type="text"
          error={formik.touched.phone && Boolean(formik.errors.phone)}
          helperText={formik.errors.phone}
          inputProps={{
            value: formik.values.phone,
            onBlur: formik.handleBlur('phone'),
            onChange: formik.handleChange('phone'),
          }}
        />
        <SelectBoxComponent
          helperText={formik.errors.cityId}
          items={cities.map((city) => ({
            value: city._id,
            label: city.name,
          }))}
          inputLabel="City"
          onValueChange={formik.handleChange('cityId')}
          value={formik.values.cityId}
        />
        <SelectBoxComponent
          helperText={formik.errors.deliveryType}
          items={[
            {
              value: 'Food',
              label: 'Food',
            },
            {
              value: 'Partners',
              label: 'Partners',
            },
            {
              value: 'Hybrid',
              label: 'Hybrid',
            },
            {
              value: 'allPartners',
              label: 'allPartners',
            },
            {
              value: 'Driver',
              label: 'Driver',
            },
          ]}
          inputLabel="Delivery Type"
          onValueChange={formik.handleChange('deliveryType')}
          value={formik.values.deliveryType}
        />
        <SelectBoxComponent
          helperText={formik.errors.transportation}
          items={(transportation || []).map((item) => ({
            value: item,
            label: item,
          }))}
          inputLabel="transportation Type"
          onValueChange={formik.handleChange('transportation')}
          value={formik.values.transportation}
        />
      </div>
      <Box textAlign="center">
        <Button
          round
          type="submit"
          color="success"
          disabled={!formik.dirty || !formik.isValid || isLoading}
          loading={isLoading}
          loaderColor="white"
          onClick={onEdit}
        >
          SAVE
        </Button>
      </Box>
    </div>
  );
}
