import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useFormik } from 'formik';
import * as yup from 'yup';
import CustomInput from '../../../components/material-dashboard-pro-react/CustomInput/CustomInput';
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';

import { updateSetting } from '../services/serverApi.service';

const validationSchema = yup.object({
  name: yup.string().required(),
});
export default function UserCancelComponent({ setting }) {
  const [userCancelR, setUserCancel] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [userCancelArray, setUserCancelArray] = useState([]);
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
  });
  const getuserCancel = () => {
    setUserCancel(setting[0].comments);
    setUserCancelArray(setting[0].comments);
  };
  const saveUserCancel = () => {
    console.log(formik.values.name);
    const userCancelArr = [...userCancelR];
    userCancelArr.push(formik.values.name);
    setUserCancel(userCancelArr);
    setting[0].comments = userCancelArr;
    updateSetting(setting[0]._id, setting[0])
      .then(({ data }) => {
        console.log(data);
        setUserCancel(data?.data?.comments);
        formik.setFieldValue('name', '');
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    setting[0] && getuserCancel();
    // setting.user_notifications_settings=initialForm
    // console.log(setting);
    // updateSetting(setting._id,setting)
    // .then(({data}) =>console.log(data))
    // .catch((error) =>console.log(error))
  }, [setting]);
  const deleteUserCancel = (index) => {
    console.log('deleted');
    const userCancelArr = [...userCancelR];
    userCancelArr.splice(index, 1);
    setUserCancel(userCancelArr);
    setting[0].comments = userCancelArr;
    updateSetting(setting[0]._id, setting[0])
      .then(({ data }) => {
        console.log(data);
        setUserCancel(data?.data?.comments);
        formik.resetForm();
      })
      .catch((error) => console.log(error));
  };
  console.log(userCancelR);
  return (
    <div>
      {userCancelR &&
        userCancelR.length > 0 &&
        userCancelR.map((item, index) => (
          <p>
            <CloseIcon
              onClick={() => deleteUserCancel(index)}
              fontSize="small"
              style={{ color: 'red' }}
            />

            {item}
          </p>
        ))}
      <CustomInput
        labelText="Name"
        formControlProps={{
          fullWidth: true,
        }}
        type="text"
        error={formik.touched.name && Boolean(formik.errors.name)}
        inputProps={{
          value: formik.values.name,
          onBlur: formik.handleBlur('name'),
          onChange: formik.handleChange('name'),
        }}
      />
      <Box textAlign="right">
        <Button
          round
          type="submit"
          color="success"
          disabled={isLoading}
          loading={isLoading}
          loaderColor="white"
          onClick={saveUserCancel}
        >
          save
        </Button>
      </Box>
    </div>
  );
}

UserCancelComponent.propTypes = {
  setting: PropTypes.any.isRequired,
};
