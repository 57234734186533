import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useFormik } from 'formik';
import * as yup from 'yup';
import CustomInput from '../../../components/material-dashboard-pro-react/CustomInput/CustomInput';
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import {
  getSocketsPath,
  setSocketsPath,
} from '../services/serverApi.service';

const validationSchema = yup.object({
  name: yup.string().required(),
});

export default function StoreCreneauTextComponent({ setting }) {
  const [isLoading, setIsLoading] = useState(false);
  const [socketPath, setSocketPath] = useState({ });

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema,
  });
  const fetchSocketPath = () => {
    getSocketsPath()
      .then(({ data }) => {
        putSocketPath(data.socketPath.socketGpsSd)
         setSocketPath({ socketGpsSd: data.socketPath.socketGpsSd });
      })
      .catch((err) => console.log(err));
  };
  const saveValueSocketPath = () => {
    let soccketValue ={socketGpsSd: formik.values.name }

    setSocketsPath(soccketValue)
      .then(({ data }) => {
        setSocketPath({socketGpsSd: data.socketPathDb.socketGpsSd});
       formik.setFieldValue('name', '');
        formik.resetForm();
      })
      .catch((error) => console.log(error));
  };
     const putSocketPath = (text) => {
      formik.setFieldValue('name', text);
     }
  useEffect(() => {
    fetchSocketPath();
  }, []);

  return (
    <div>
      {/* { Object.keys(socketPath).length > 0 && Object.values(socketPath).map((item, index) => (
        <p>
          /* <CloseIcon fontSize="small" style={{ color: 'red' }} /> 

          {item}
        </p>
      ))} */}
      <CustomInput
        labelText="Name"
        formControlProps={{
          fullWidth: true,
        }}
        type="text"
        error={formik.touched.name && Boolean(formik.errors.name)}
        inputProps={{
          value: formik.values.name,
          onBlur: formik.handleBlur('name'),
          onChange: formik.handleChange('name'),
        }}
      />
      <Box textAlign="right">
        <Button
          round
          type="submit"
          color="success"
          disabled={isLoading}
          loading={isLoading}
          loaderColor="white"
          onClick={saveValueSocketPath}
        >
          save
        </Button>
      </Box>
    </div>
  );
}

StoreCreneauTextComponent.propTypes = {
  setting: PropTypes.any.isRequired,
};
