import React, { useEffect, useState } from 'react';
import DataTableSortingContainer from '../../../components/DataTableSortingContainer.component';
import {
  getAlarmConfig,
  getAlarms,
  updateAlarm,
  updateAlarmConfig,
} from '../services/serverApi.service';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Grid, TextField } from '@material-ui/core';
import { store } from '../../../redux/store';
import ModalComponent from '../../../components/Modal.component';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '../../../components/material-dashboard-pro-react/ReactTable/ReactTable';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import moment from 'moment';

export default function OrderAlarmsPage() {
  const [resultsData, setResultsData] = useState({
    total: 0,
    items: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  let numberOfRows = [20, 50, 100];
  const [rowsPerPage, setRowsPerPage] = useState(numberOfRows[0]);
  const [total, setTotal] = useState(0);
  const [alarms, setAlarms] = useState([]);
  const [alarm, setAlarm] = useState([]);
  const [shortId, setShortId] = useState('');
  const [deliveryManId, setDeliveryManId] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const config = {
    INVALID_ORDER: { time: 2, active: false },
    WAITING_FOR_ACCEPT: { time: 2, active: false },
    ACCEPTED: { time: 2, active: false },
    COMING_FOR_DELIVERY: {
      time: 2,
      speed: 30,
      active: false,
    },
    ARRIVED_TO_PICKUP: {
      distance: 200,
      active: false,
    },
    UPLOAD_INVOICE: {
      diffPrice: 1,
      active: false,
    },
    STARTED_DELIVERY: { time: 2, active: false, speed: 30 },
    ARRIVED_TO_DESTINATION: {
      time: 2,
      distance: 200,
      active: false,
    },
    START_PREPARATION: {
      preparationTime: 2,
      active: false,
    },
    RETARD: {
      time: 2,
      active: false,
    },
  };
  const [alarmConfig, setAlarmConfig] = useState(null);
  const [alarmComment, setAlarmComment] = useState('');
  const [showAlertConfigModal, setShowAlertConfigModal] = useState(false);
  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Value',
      accessor: 'value',
    },
    // {
    //   Header: 'Type',
    //   accessor: 'type',
    // },
    {
      Header: 'Short Id',
      accessor: 'shortId',
    },
    // {
    //   Header: 'DeliveryMan',
    //   accessor: 'deliveryMan',
    // },
    {
      Header: 'Status',
      accessor: 'status',
    },
    {
      Header: 'Description',
      accessor: 'description',
    },
    {
      Header: 'date creation',
      accessor: 'createdAt',
    },
    {
      Header: 'date Disparition',
      accessor: 'dateDisparition',
    },
    {
      Header: 'acquitter By',
      accessor: 'acquitterBy.name',
    },
    {
      Header: 'date Acquittement',
      accessor: 'dateAcquittement',
    },
    {
      Header: 'Menu',
      accessor: 'menu',
    },
  ];
  const prepareData = (item, index) => {
    return {
      ...item,
      menu:
        item.status !== 'Acquittement' ? (
          <div>
            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={($event) => {
                setAlarm(item);
                handleClick($event);
              }}
            >
              Menu
            </Button>
          </div>
        ) : (
          <></>
        ),
      value:
        item.value.toFixed(2) +
        (item.name === 'UPLOAD_INVOICE'
          ? ' dh'
          : item.type === 'time'
          ? ' min'
          : ' m'),
      createdAt: item.createdAt
        ? moment(item.createdAt).format('DD/MM hh:mm')
        : '--:--',
      dateDisparition: item.dateDisparition
        ? moment(item.dateDisparition).format('DD/MM hh:mm')
        : '--:--',
      dateAcquittement: item.dateAcquittement
        ? moment(item.dateAcquittement).format('DD/MM hh:mm')
        : '--:--',
    };
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const get_Alarms = (skip, limit, deliveryManId, shortId, name) => {
    console.log(shortId + '');
    setResultsData({ total: 0, items: [] });
    setIsLoading(true);
    getAlarms(skip, limit, deliveryManId, shortId, name)
      .then(({ data }) => {
        setTotal(data.total);
        setAlarms(data.alarms);
      })
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
  };
  const handlePaginate = (val) => {
    setResultsData({
      items: [],
      tableItems: [],
    });
    get_Alarms(val.pageSelect, val.numberOfRows, deliveryManId, shortId, '');
    setPage(val.pageSelect);
    setRowsPerPage(val.numberOfRows);
  };
  useEffect(() => {
    get_alarm_config();
  }, []);
  useEffect(() => {
    alarms &&
      alarms.length > 0 &&
      setResultsData({
        items: alarms.map((item, index) => {
          return prepareData(item, index);
        }),
      });
  }, [alarms]);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const MySwal = withReactContent(Swal);
  const openModal = () => {
    MySwal.fire({
      title: 'Acquittement Alarm',
      text: 'name : ' + alarm.name,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui !',
      cancelButtonText: 'Non',
    }).then((result) => {
      if (result.isConfirmed) {
        const reduxState = store.getState();
        const acquitterBy = reduxState.authReducer.userData
          ? reduxState.authReducer.userData.user
          : null;
        updateAlarm(alarm._id, {
          acquitterBy: acquitterBy,
          comment: alarmComment,
        })
          .then((res) => {
            Swal.fire('Success!', '', 'success');
          })
          .catch((e) => Swal.fire('error!', '', 'error'))
          .finally(() => get_Alarms(0, 100, deliveryManId, shortId, ''));
      }
    });
  };
  const alarm_message = (alarm) => {
    return (
      <div>
        <label
          htmlFor="basic-url"
          className="form-label"
          style={{ color: 'black', fontWeight: 700 }}
        >
          ALARM : {alarm.name}
        </label>
        <div className="input-group">
          <span className="input-group-text">Commentaire</span>
          <textarea
            className="form-control"
            aria-label="With textarea"
            onChange={(e) => {
              setAlarmComment(e.target.value);
            }}
          />
        </div>
      </div>
    );
  };
  const showAlarmConfig = () => {
    get_alarm_config();
    setShowAlertConfigModal(!showAlertConfigModal);
  };
  const steps = [
    'WAITING_FOR_ACCEPT',
    'ACCEPTED',
    'COMING_FOR_DELIVERY',
    'ARRIVED_TO_PICKUP',
    'UPLOAD_INVOICE',
    'STARTED_DELIVERY',
    'ARRIVED_TO_DESTINATION',
    'START_PREPARATION',
  ];
  const get_alarm_config = () => {
    getAlarmConfig()
      .then(({ data }) => {
        console.log(data);
        if (data.alarm_config) {
          setAlarmConfig(data.alarm_config);
        } else {
          setAlarmConfig(config);
        }
      })
      .catch((e) => {
        setAlarmConfig(config);
      });
  };

  const update_alarm_config = () => {
    console.log(alarmConfig);
    const { _id = 'null', ...data } = alarmConfig;
    updateAlarmConfig({ alarmConfig: data })
      .then((res) => {
        get_alarm_config();
        setShowAlertConfigModal(false);
      })
      .catch((e) => console.log(e));
  };
  const editAlarmConfig = (step, item, value) => {
    let data = Object.assign({}, alarmConfig);
    if (value === '') {
      value = 0;
    }
    if (data[step]) {
      data[step][item] = value;
      setAlarmConfig(data);
    } else {
      data[step + ''] = {};
      data[step + ''][`${item}`] = value;
      setAlarmConfig(data);
    }
  };
  return (
    <div>
      <div className={'row'} style={{ backgroundColor: 'white', padding: 40 }}>
        <div className={'clo-6'}>
          <TextField
            style={{ margin: 10, width: 300 }}
            label="Find By short Id"
            variant="outlined"
            onChange={(e) => {
              setShortId(e.target.value.trim() + '');
            }}
          />
          <button
            style={{ margin: 10 }}
            onClick={() => get_Alarms(0, 100, deliveryManId, shortId, '')}
          >
            refresh
          </button>
        </div>
        <div className={'clo-6'}>
          <button style={{ margin: 10 }} onClick={showAlarmConfig}>
            Alarm Config
          </button>
        </div>
      </div>
      <div>
        <DataTableSortingContainer
          resultsData={resultsData}
          total={total}
          initData={resultsData.items}
          columns={columns}
          title="Orders Rating"
          isLoading={isLoading}
          page={page + 1}
          onPaginate={handlePaginate}
          isPaginate={true}
        />
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={openModal}>acquitter</MenuItem>
        </Menu>
        <ModalComponent
          maxWidth={false}
          close={() => setShowAlertConfigModal(false)}
          visible={showAlertConfigModal}
          title="Alert Config Popup"
        >
          {!!alarmConfig && (
            <table className="table">
              <thead className="thead-light">
                <tr>
                  <th scope="col">Steps</th>
                  <th scope="col" colSpan={3}>
                    config
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">INVALID_ORDER</th>
                  <td>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Time (min)"
                      value={
                        alarmConfig['INVALID_ORDER']
                          ? alarmConfig['INVALID_ORDER']['time']
                          : 0
                      }
                      onChange={(e) => {
                        editAlarmConfig(
                          'INVALID_ORDER',
                          'time',
                          parseFloat(e.target.value),
                        );
                      }}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={
                            alarmConfig['INVALID_ORDER']
                              ? alarmConfig['INVALID_ORDER']['active']
                              : false
                          }
                          onChange={(e) =>
                            editAlarmConfig(
                              'INVALID_ORDER',
                              'active',
                              e.target.checked,
                            )
                          }
                          name="active"
                          color="primary"
                        />
                      }
                      label="Active"
                    />
                  </td>
                </tr>
                <tr>
                  <th scope="row">WAITING_FOR_ACCEPT</th>
                  <td>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Time (min)"
                      value={alarmConfig['WAITING_FOR_ACCEPT']['time']}
                      onChange={(e) => {
                        editAlarmConfig(
                          'WAITING_FOR_ACCEPT',
                          'time',
                          parseFloat(e.target.value),
                        );
                      }}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={alarmConfig['WAITING_FOR_ACCEPT']['active']}
                          onChange={(e) =>
                            editAlarmConfig(
                              'WAITING_FOR_ACCEPT',
                              'active',
                              e.target.checked,
                            )
                          }
                          name="active"
                          color="primary"
                        />
                      }
                      label="Active"
                    />
                  </td>
                </tr>
                <tr>
                  <th scope="row">ACCEPTED</th>
                  <td>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Time (min)"
                      value={alarmConfig['ACCEPTED']['time']}
                      onChange={(e) =>
                        editAlarmConfig(
                          'ACCEPTED',
                          'time',
                          parseFloat(e.target.value),
                        )
                      }
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={alarmConfig['ACCEPTED']['active']}
                          onChange={(e) =>
                            editAlarmConfig(
                              'ACCEPTED',
                              'active',
                              e.target.checked,
                            )
                          }
                          name="active"
                          color="primary"
                        />
                      }
                      label="Active"
                    />
                  </td>
                </tr>
                <tr>
                  <th scope="row">COMING_FOR_DELIVERY</th>
                  {/*<td>*/}
                  {/*  <input*/}
                  {/*    type="number"*/}
                  {/*    className="form-control"*/}
                  {/*    placeholder="Time (min)"*/}
                  {/*    value={alarmConfig['COMING_FOR_DELIVERY']['time']}*/}
                  {/*    onChange={(e) =>*/}
                  {/*      editAlarmConfig(*/}
                  {/*        'COMING_FOR_DELIVERY',*/}
                  {/*        'time',*/}
                  {/*        parseFloat(e.target.value),*/}
                  {/*      )*/}
                  {/*    }*/}
                  {/*  />*/}
                  {/*</td>*/}
                  <td>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="speed (km/h)"
                      value={alarmConfig['COMING_FOR_DELIVERY']['speed']}
                      onChange={(e) =>
                        editAlarmConfig(
                          'COMING_FOR_DELIVERY',
                          'speed',
                          parseFloat(e.target.value),
                        )
                      }
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={alarmConfig['COMING_FOR_DELIVERY']['active']}
                          onChange={(e) =>
                            editAlarmConfig(
                              'COMING_FOR_DELIVERY',
                              'active',
                              e.target.checked,
                            )
                          }
                          name="active"
                          color="primary"
                        />
                      }
                      label="Active"
                    />
                  </td>
                </tr>
                <tr>
                  <th scope="row">ARRIVED_TO_PICKUP</th>
                  <td>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="distance (m)"
                      value={alarmConfig['ARRIVED_TO_PICKUP']['distance']}
                      onChange={(e) =>
                        editAlarmConfig(
                          'ARRIVED_TO_PICKUP',
                          'distance',
                          parseFloat(e.target.value),
                        )
                      }
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={alarmConfig['ARRIVED_TO_PICKUP']['active']}
                          onChange={(e) =>
                            editAlarmConfig(
                              'ARRIVED_TO_PICKUP',
                              'active',
                              e.target.checked,
                            )
                          }
                          name="active"
                          color="primary"
                        />
                      }
                      label="Active"
                    />
                  </td>
                </tr>
                <tr>
                  <th scope="row">UPLOAD_INVOICE</th>
                  <td>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="diff price"
                      value={alarmConfig['UPLOAD_INVOICE']['diffPrice']}
                      onChange={(e) =>
                        editAlarmConfig(
                          'UPLOAD_INVOICE',
                          'diffPrice',
                          parseFloat(e.target.value),
                        )
                      }
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={alarmConfig['UPLOAD_INVOICE']['active']}
                          onChange={(e) =>
                            editAlarmConfig(
                              'UPLOAD_INVOICE',
                              'active',
                              e.target.checked,
                            )
                          }
                          name="active"
                          color="primary"
                        />
                      }
                      label="Active"
                    />
                  </td>
                </tr>
                <tr>
                  <th scope="row">STARTED_DELIVERY</th>
                  <td>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="speed"
                      value={alarmConfig['STARTED_DELIVERY']['speed']}
                      onChange={(e) =>
                        editAlarmConfig(
                          'STARTED_DELIVERY',
                          'speed',
                          parseFloat(e.target.value),
                        )
                      }
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={alarmConfig['STARTED_DELIVERY']['active']}
                          onChange={(e) =>
                            editAlarmConfig(
                              'STARTED_DELIVERY',
                              'active',
                              e.target.checked,
                            )
                          }
                          name="active"
                          color="primary"
                        />
                      }
                      label="Active"
                    />
                  </td>
                  <td colSpan={4} />
                </tr>
                <tr>
                  <th scope="row">ARRIVED_TO_DESTINATION</th>
                  <td>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Time (min)"
                      value={alarmConfig['ARRIVED_TO_DESTINATION']['time']}
                      onChange={(e) =>
                        editAlarmConfig(
                          'ARRIVED_TO_DESTINATION',
                          'time',
                          parseFloat(e.target.value),
                        )
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="distance"
                      value={alarmConfig['ARRIVED_TO_DESTINATION']['distance']}
                      onChange={(e) =>
                        editAlarmConfig(
                          'ARRIVED_TO_DESTINATION',
                          'distance',
                          parseFloat(e.target.value),
                        )
                      }
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={
                            alarmConfig['ARRIVED_TO_DESTINATION']['active']
                          }
                          onChange={(e) =>
                            editAlarmConfig(
                              'ARRIVED_TO_DESTINATION',
                              'active',
                              e.target.checked,
                            )
                          }
                          name="active"
                          color="primary"
                        />
                      }
                      label="Active"
                    />
                  </td>
                </tr>
                <tr>
                  <th scope="row">START_PREPARATION</th>
                  <td>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="preparationTime (min)"
                      value={
                        alarmConfig['START_PREPARATION']['preparationTime']
                      }
                      onChange={(e) =>
                        editAlarmConfig(
                          'START_PREPARATION',
                          'preparationTime',
                          parseFloat(e.target.value),
                        )
                      }
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={alarmConfig['START_PREPARATION']['active']}
                          onChange={(e) =>
                            editAlarmConfig(
                              'START_PREPARATION',
                              'active',
                              e.target.checked,
                            )
                          }
                          name="active"
                          color="primary"
                        />
                      }
                      label="Active"
                    />
                  </td>
                  {/*<td>*/}
                  {/*  <Button*/}
                  {/*    variant="contained"*/}
                  {/*    color="primary"*/}
                  {/*    onClick={update_alarm_config}*/}
                  {/*  >*/}
                  {/*    save*/}
                  {/*  </Button>*/}
                  {/*</td>*/}
                </tr>
                <tr>
                  <th scope="row">MANUEL</th>
                  <td>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={
                            alarmConfig['MANUEL']
                              ? alarmConfig['MANUEL']['active']
                              : false
                          }
                          onChange={(e) =>
                            editAlarmConfig(
                              'MANUEL',
                              'active',
                              e.target.checked,
                            )
                          }
                          name="active"
                          color="primary"
                        />
                      }
                      label="Active"
                    />
                  </td>
                </tr>
                <tr>
                  <th scope="row">WAITING TO ACCEPT STORE</th>
                  <td>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={
                            alarmConfig['WAITING_FOR_ACCEPT_STORE']
                              ? alarmConfig['WAITING_FOR_ACCEPT_STORE'][
                                  'active'
                                ]
                              : false
                          }
                          onChange={(e) =>
                            editAlarmConfig(
                              'WAITING_FOR_ACCEPT_STORE',
                              'active',
                              e.target.checked,
                            )
                          }
                          name="active"
                          color="primary"
                        />
                      }
                      label="Active"
                    />
                  </td>
                </tr>
                <tr>
                  <th scope="row">RETARD</th>
                  <td>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="retard time (min)"
                      value={
                        alarmConfig['RETARD']
                          ? alarmConfig['RETARD']['time']
                          : 0
                      }
                      onChange={(e) =>
                        editAlarmConfig(
                          'RETARD',
                          'time',
                          parseFloat(e.target.value),
                        )
                      }
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={
                            alarmConfig['RETARD']
                              ? alarmConfig['RETARD']['active']
                              : false
                          }
                          onChange={(e) =>
                            editAlarmConfig(
                              'RETARD',
                              'active',
                              e.target.checked,
                            )
                          }
                          name="active"
                          color="primary"
                        />
                      }
                      label="Active"
                    />
                  </td>
                  <td>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={update_alarm_config}
                    >
                      save
                    </Button>
                  </td>
                </tr>
                <tr>
                  <th scope="row">priceDifference</th>
                  <td>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="retard time (min)"
                      value={
                        alarmConfig['priceDifference']
                          ? alarmConfig['priceDifference']['price']
                          : 0
                      }
                      onChange={(e) =>
                        editAlarmConfig(
                          'priceDifference',
                          'price',
                          parseFloat(e.target.value),
                        )
                      }
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={
                            alarmConfig['priceDifference']
                              ? alarmConfig['priceDifference']['active']
                              : false
                          }
                          onChange={(e) =>
                            editAlarmConfig(
                              'priceDifference',
                              'active',
                              e.target.checked,
                            )
                          }
                          name="active"
                          color="primary"
                        />
                      }
                      label="Active"
                    />
                  </td>
                  <td>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={update_alarm_config}
                    >
                      save
                    </Button>
                  </td>
                </tr>
                <tr>
                  <th scope="row">priceDifferenceMax</th>
                  <td>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="retard time (min)"
                      value={
                        alarmConfig['priceDifferenceMax']
                          ? alarmConfig['priceDifferenceMax']['price']
                          : 0
                      }
                      onChange={(e) =>
                        editAlarmConfig(
                          'priceDifferenceMax',
                          'price',
                          parseFloat(e.target.value),
                        )
                      }
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={
                            alarmConfig['priceDifferenceMax']
                              ? alarmConfig['priceDifferenceMax']['active']
                              : false
                          }
                          onChange={(e) =>
                            editAlarmConfig(
                              'priceDifferenceMax',
                              'active',
                              e.target.checked,
                            )
                          }
                          name="active"
                          color="primary"
                        />
                      }
                      label="Active"
                    />
                  </td>
                  <td>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={update_alarm_config}
                    >
                      save
                    </Button>
                  </td>
                </tr>
              </tbody>
            </table>
          )}
        </ModalComponent>
      </div>
    </div>
  );
}
