import React from 'react';
import {
  GoogleMap,
  LoadScript,
  Polygon,
  Marker,
  OverlayView,
  Polyline,
  InfoWindow,
  InfoBox,
} from '@react-google-maps/api';
import { iconsList } from '../config/status.config';
import StoreIcon from '../assets/img/mapIcons/storeIcon.png';
import DeliveryManIcon from '../assets/img/mapIcons/deliveryManPin.png';
import DeliveryManWithoutOrderIcon from '../assets/img/mapIcons/deliveryManWithoutOrderPin.png';
import StoreIconWithOrder from '../assets/img/mapIcons/storePinWithorder.png';
import ClientIcon from '../assets/img/mapIcons/clientIcon.png';
import { ReactReduxContext } from 'react-redux';
// import {
//   MarkerWithLabel,
// } from('react-google-maps/lib/components/addons/');

export default function MarkersComponent({
  key,
  position,
  icon,
  size,
  onClick,
  onMouseOver,
  onMouseOut,
  zIndex,
  label,
  labelPosition,
  smallLabel,
}) {
  const GetIcon = () => {
    switch (icon) {
      case iconsList.store:
        return StoreIcon;
      case iconsList.client:
        return ClientIcon;
      case iconsList.deliveryManWithOrder:
        return DeliveryManIcon;
      case iconsList.deliverManWithoutOrde:
        return DeliveryManWithoutOrderIcon;

      default:
        break;
    }
  };
  const getPixelPositionOffset = (width, height) => ({
    x: -(width / 2),
    y: labelPosition === 'top' ? -(height / 2) - 57 : -(height / 2) + 15,
  });
  return (
    <>
      <Marker
        key={key}
        position={position && position}
        icon={{
          url: GetIcon(),
          // anchor: new google.maps.Point(17, 46),
          scaledSize: new google.maps.Size(size, size),
        }}
        onClick={onClick}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        zIndex={zIndex}
        label={smallLabel}
      />
      {label && (
        <OverlayView
          key={key}
          position={position && position}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          getPixelPositionOffset={getPixelPositionOffset}
          // zIndex={zIndex + 1}
        >
          <div
            style={{
              background: `#203254`,
              padding: `7px 12px`,
              fontSize: '11px',
              color: `white`,
              borderRadius: '4px',
            }}
          >
            {label}
          </div>
        </OverlayView>
      )}
    </>
  );
}
